import React, { useState, useEffect, SetStateAction } from 'react';
import './QCScaleScreen.scss';
import PropTypes from 'prop-types';
import { Accordion, AccordionDetails, AccordionSummary, Container, Grid } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

type ChangeEvent = React.ChangeEvent<HTMLFormElement | HTMLInputElement>;

const QCScaleScreen = ({ scaleId }: { scaleId: string }) => {
  const [scaleQCReportParams, setScaleQCReportParams] = useState('');

  const [isQCScreenExpanded, setIsQCScreenExpanded] = useState(false);

  const handleAccordionStateChange: any = (event: ChangeEvent, value: SetStateAction<boolean>) =>
    setIsQCScreenExpanded(value as SetStateAction<boolean>);

  useEffect(() => {
    if (!scaleId) {
      return;
    }
    setScaleQCReportParams(encodeURIComponent(JSON.stringify({ 'ds0.scaleid': scaleId })));
  }, [scaleId]);

  const reportUrl = `https://datastudio.google.com/embed/u/0/reporting/7e6462a9-e300-4b95-8c6c-931ada88b4fe/page/xuZJC?params=${scaleQCReportParams}`;

  return (
    <Grid item sm={12}>
      <Accordion expanded={isQCScreenExpanded} onChange={handleAccordionStateChange}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1bh-content">
          QC Information
        </AccordionSummary>
        <AccordionDetails aria-controls="panel1bh-content">
          <Container className="QCScaleScreen QCScaleScreen__data-studio">
            <iframe
              className="lg-iframe"
              title="Inline Frame Example"
              src={reportUrl}
              allowFullScreen
            />
          </Container>
        </AccordionDetails>
      </Accordion>
    </Grid>
  );
};

QCScaleScreen.propTypes = {
  scaleId: PropTypes.string.isRequired,
};

export default QCScaleScreen;
