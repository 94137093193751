import React, { useState, useContext } from 'react';
import { thunkDispatch } from 'store/store';
import { useSelector } from 'react-redux';
import { upsertTruck, deleteTruck } from '../../redux/actions';
import { Box, Grid, DialogContent, MenuItem, Typography } from '@mui/material';
import ModalHeader from 'sharedComponents/ModalHeader';
import truckSchema from 'modules/civilAndTrucking/trucking/schemas/TruckSchema';
import DialogActionContainer from 'sharedComponents/DialogActionContainer';
import { ValidationError } from 'yup';
import { selectDivisions } from 'modules/civilAndTrucking/trucking/features/Divisions/redux/divisionsStoreSlice';
import { selectTruckTypes } from 'modules/civilAndTrucking/trucking/features/TruckTypes/redux/truckTypesStoreSlice';
import { ModalContext } from 'sharedComponents/ModalContext';
import { extractFieldsByTuple } from 'modules/civilAndTrucking/trucking/utils';
import { LoadingOverlay as Loading } from 'sharedComponents/LoadingOverlay';
import { FormTextField } from 'sharedComponents/FormTextField';
import { ITruckFlattenedCapacities } from './Trucks';
import { ITruckingTruck } from 'lg-helpers/dist/trucking/interfaces/ITruckingTruck';
import { IHelpers } from 'lg-helpers/dist/trucking/interfaces/IHelpers';
import { KTruckingTruck } from 'lg-helpers/dist/trucking/interfaces/KTruckingTruck';

type TruckFormProps = {
  editObj?: ITruckFlattenedCapacities;
};

export const TruckForm = ({ editObj }: TruckFormProps) => {
  const [truck, setTruck] = useState(
    editObj ? extractFieldsByTuple<ITruckingTruck>(editObj, KTruckingTruck) : ({} as ITruckingTruck)
  );
  const [truckHelpers, setTruckHelpers] = useState({} as IHelpers);
  const { divisions, divisionsStatus } = useSelector(selectDivisions);
  const { truckTypes, truckTypesStatus } = useSelector(selectTruckTypes);
  const { handleModal } = useContext(ModalContext);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setTruck({ ...truck, [e.target.name]: e.target.value });
  };

  const handleInvalidSubmit = () => {
    truckSchema.validate(truck, { abortEarly: false }).catch((valErrors: ValidationError) => {
      const helpers = {} as IHelpers;
      valErrors.inner.forEach(err => {
        helpers[err.path] = err.message;
      });
      setTruckHelpers(helpers);
    });
  };

  const onSubmit = async () => {
    const truckReq: ITruckingTruck = {
      ...truck,
    };
    const isValid = await truckSchema.isValid(truckReq);
    if (!isValid) {
      handleInvalidSubmit();
    } else {
      thunkDispatch(upsertTruck(truckReq));
      handleModal();
    }
  };

  const handleDeleteTruck = () => {
    thunkDispatch(deleteTruck(editObj?.idTruckingTruck!));
    handleModal();
  };

  return (
    <Box sx={{ p: '0px 16px' }}>
      <ModalHeader title={'Add Truck'} />
      <DialogContent>
        <Grid container columnSpacing={2}>
          <Grid item xs={12}>
            {divisionsStatus === 'pending' ? (
              <Loading height={24} />
            ) : (
              <FormTextField
                required
                name="divisionIdTruckingTruck"
                label="Truck Division"
                value={truck?.divisionIdTruckingTruck}
                helperField={truckHelpers.divisionIdTruckingTruck}
                onChange={handleChange}
                selectOptions={divisions.map(division => (
                  <MenuItem
                    key={division.idTruckingTruckDivision}
                    value={division.idTruckingTruckDivision}
                  >
                    {division.nameTruckingTruckDivision}
                  </MenuItem>
                ))}
              />
            )}
          </Grid>
          <Grid item xs={6}>
            <FormTextField
              required
              name="truckNumberTruckingTruck"
              label="Truck No."
              value={truck?.truckNumberTruckingTruck}
              helperField={truckHelpers.truckNumberTruckingTruck}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={6}>
            {truckTypesStatus === 'pending' ? (
              <Loading height={24} />
            ) : (
              <FormTextField
                required
                name="truckTypeIdTruckingTruck"
                label="Truck Type"
                value={truck?.truckTypeIdTruckingTruck}
                helperField={truckHelpers.truckTypeIdTruckingTruck}
                onChange={handleChange}
                selectOptions={truckTypes.map(truckType => (
                  <MenuItem
                    key={truckType.idTruckingTruckType}
                    value={truckType.idTruckingTruckType}
                  >
                    {truckType.nameTruckingTruckType}
                  </MenuItem>
                ))}
              />
            )}
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h6">Capacities</Typography>
          </Grid>
          {truck?.truckTypeIdTruckingTruck && (
            <>
              {truckTypes
                ?.find(
                  truckType => truckType.idTruckingTruckType === truck?.truckTypeIdTruckingTruck
                )
                ?.capacitiesTruckingTruckType?.map(capacity => (
                  <Grid item xs={3} key={capacity.unitType}>
                    <FormTextField
                      disabled
                      name="capacity.unitType"
                      label={capacity.unitType}
                      value={capacity?.maxVolume}
                    />
                  </Grid>
                ))}
            </>
          )}
        </Grid>
      </DialogContent>
      <DialogActionContainer
        saveHandler={onSubmit}
        deleteHandler={handleDeleteTruck}
        editing={!!editObj}
      />
    </Box>
  );
};
