import React, { useContext } from 'react';
import { DialogActions, Button, Box, Theme, SxProps } from '@mui/material';
import { ModalContext } from 'sharedComponents/ModalContext';

interface IDialogActionContainerProps {
  deleteHandler?: Function;
  closeHandler?: () => void;
  saveHandler: () => void;
  editing?: boolean;
  isSaving?: boolean;
  style?: SxProps<Theme>;
}

const DialogActionContainer = ({
  saveHandler,
  closeHandler,
  deleteHandler,
  editing,
  isSaving,
  style = {},
}: IDialogActionContainerProps) => {
  const { handleModal } = useContext(ModalContext);
  return (
    <>
      <DialogActions sx={style}>
        <Button
          disabled={isSaving ? isSaving : false}
          onClick={() => {
            saveHandler();
          }}
          type="submit"
          color="primary"
          variant="contained"
        >
          Save
        </Button>
        <Button
          color="primary"
          onClick={() => {
            closeHandler ? closeHandler() : handleModal();
          }}
        >
          Cancel
        </Button>
        <Box flexGrow={1}></Box>
        {editing && deleteHandler && (
          <Button
            color="error"
            variant="outlined"
            onClick={() => {
              deleteHandler();
              handleModal();
            }}
          >
            Delete
          </Button>
        )}
      </DialogActions>
    </>
  );
};

export default DialogActionContainer;
