enum Tables {
  Companies = 'companies',
  CompaniesCustomData = 'companies_custom_data',
  CivilProjects = 'civil_projects',
  TruckingDispatch = 'trucking_dispatch',
  TruckingTruckType = 'trucking_truck_type',
  TruckingTruck = 'trucking_truck',
  TruckingTruckDivision = 'trucking_truck_division',
  TruckingLoad = 'trucking_load',
  TruckingProjectMaterial = 'trucking_project_material',
  TruckingProject = 'trucking_project',
  TruckingCompanyMaterial = 'trucking_company_material',
  DispatchBrokerCompany = 'dispatch_broker_company',
  TruckingTicket = 'trucking_ticket',
  TruckingTicketNotes = 'trucking_ticket_notes',
  Locations = 'civil_locations',
  Licenses = 'licenses',
  Users = 'users',
  UserLicenses = 'user_licenses',
  ManifestApiLog = 'manifest_api_log',
}

export default Tables;
