import { createSlice } from '@reduxjs/toolkit';
import { getProjectsByCompanyId, getProjectByProjectId } from './actions';
import { RootState } from 'store/store';
import { ITruckingProject } from 'lg-helpers/dist/trucking/interfaces/ITruckingProject';
import { TFormStatus } from 'lg-helpers/dist/shared/types/TFormStatus';

export interface IProjectsState {
  data: ITruckingProject[];
  project: ITruckingProject;
  status: TFormStatus;
}

const initialStateProjects: IProjectsState = {
  data: [] as ITruckingProject[],
  project: {} as ITruckingProject,
  status: null,
};

export const projectsStoreSlice = createSlice({
  name: 'projects',
  reducers: {},
  initialState: initialStateProjects,
  extraReducers: builder => {
    builder
      .addCase(getProjectsByCompanyId.pending, state => {
        state.status = 'pending';
      })
      .addCase(getProjectsByCompanyId.fulfilled, (state, { payload }) => {
        state.status = 'success';
        state.data = payload;
      })
      .addCase(getProjectsByCompanyId.rejected, (state, { error }) => {
        state.status = 'failure';
        state.data = [] as ITruckingProject[];
        console.error('getProjectsByCompanyId error: ', error);
      })
      .addCase(getProjectByProjectId.pending, state => {
        state.status = 'pending';
      })
      .addCase(getProjectByProjectId.fulfilled, (state, { payload }) => {
        state.status = 'success';
        state.project = payload;
      })
      .addCase(getProjectByProjectId.rejected, (state, { error }) => {
        state.status = 'failure';
        state.project = {} as ITruckingProject;
        console.error('getProjectByProjectId error: ', error);
      });
  },
});

export const selectJoinedProjects = (state: RootState) => {
  return {
    joinedProjects: state.trucking.projects.data,
    joinedProjectsStatus: state.trucking.projects.status,
  };
};

export const selectProject = (state: RootState) => {
  return {
    project: state.trucking.projects.project,
    projectStatus: state.trucking.projects.status,
  };
};
