import React, { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { ModalContext } from 'sharedComponents/ModalContext';
import { useSelector } from 'react-redux';
import { thunkDispatch } from 'store/store';
import { getJoinedDispatchByDispatchId } from '../../redux/actions';
import { selectJoinedDispatch } from '../../redux/dispatchStoreSlice';
import { AddLoadsToDispatchForm } from './AddLoadsToDispatchForm';
import { getLoadsByDispatchId } from 'modules/civilAndTrucking/trucking/features/Loads/redux/actions';
import { DispatchToolbar } from './components/DispatchToolbar';
import { DispatchLoadsList } from './components/DispatchLoadsList';
import { DispatchTicketsList } from './components/DispatchTicketsList';
import { getTicketsByDispatchId } from 'modules/civilAndTrucking/trucking/features/Tickets/redux/actions';
import { ITruckingLoad } from 'lg-helpers/dist/trucking/interfaces/ITruckingLoad';
import { upsertLoad, deleteLoad } from '../../../Loads/redux/actions';

export const DispatchView = () => {
  let { dispatchId } = useParams();
  const { handleModal } = useContext(ModalContext);
  const { joinedDispatch } = useSelector(selectJoinedDispatch);
  const [toggleIndex, setToggleIndex] = useState<0 | 1>(0);

  useEffect(() => {
    if (!dispatchId) return;
    thunkDispatch(getLoadsByDispatchId(Number(dispatchId)));
    thunkDispatch(getTicketsByDispatchId(Number(dispatchId)));
    thunkDispatch(getJoinedDispatchByDispatchId(Number(dispatchId)));
  }, [dispatchId]);

  const showModal = () => {
    handleModal(<AddLoadsToDispatchForm joinedDispatch={joinedDispatch} />);
  };

  const handleUpdateLoad = async (loadId: number, loadRepeat?: boolean, lastLoad?: boolean) => {
    if (!dispatchId) return;

    const upsertLoadReq = {
      idTruckingLoad: loadId,
      ...(loadRepeat !== undefined && { canRepeatTruckingLoad: loadRepeat }),
      ...(lastLoad !== undefined && { isLastLoad: lastLoad }),
    } as Partial<ITruckingLoad>;

    await thunkDispatch(upsertLoad(upsertLoadReq));
    thunkDispatch(getLoadsByDispatchId(Number(dispatchId)));
  };

  const handleDeleteLoad = async (loadId: number) => {
    if (!dispatchId) return;
    await thunkDispatch(deleteLoad(loadId));
    thunkDispatch(getLoadsByDispatchId(Number(dispatchId)));
  };

  return (
    <>
      <DispatchToolbar
        showModal={showModal}
        toggleIndex={toggleIndex}
        setToggleIndex={setToggleIndex}
      />
      {toggleIndex ? (
        <DispatchTicketsList />
      ) : (
        <DispatchLoadsList
          handleUpdateLoad={handleUpdateLoad}
          handleDeleteLoad={handleDeleteLoad}
        />
      )}
    </>
  );
};
