import React, { useEffect, useState } from 'react';
import { Avatar, Button, Typography, Menu, MenuItem, useTheme, useMediaQuery } from '@mui/material';
import { KeyboardArrowDown } from '@mui/icons-material';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { selectAuthUser } from 'modules/auth/storeSliceAuth';

interface IUserDropdDownProps {
  handleLogout: () => void;
  settingsPath: string;
}

const UserDropDown = ({ handleLogout, settingsPath }: IUserDropdDownProps) => {
  const authUser = useSelector(selectAuthUser);
  const theme = useTheme();
  const isWidthXSorSM = useMediaQuery(theme.breakpoints.down('md'));
  const [user, setUser] = useState('');
  const [menuAnchor, setMenuAnchor] = React.useState<null | HTMLElement>(null);
  const showMenu = Boolean(menuAnchor);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setMenuAnchor(event.currentTarget);
  };

  useEffect(() => {
    setUser(authUser.firstName + ' ' + authUser.lastName);
  }, [authUser.firstName, authUser.lastName]);

  const handleClose = () => {
    setMenuAnchor(null);
  };

  const stringAvatar = (fullName: string) => {
    return user
      ? {
          children: `${fullName.split(' ')[0][0]}${fullName.split(' ')[1][0]}`,
        }
      : null;
  };

  return (
    <>
      <Button
        sx={{
          borderLeft: '1px solid',
          borderColor: 'neutral.light',
          // width: '200px',
          maxWidth: '200px',
          marginLeft: '0.5em',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'flex-start',
          borderRadius: 0,
          textTransform: 'none',
          textOverflow: 'ellipsis',
        }}
        onClick={handleClick}
        endIcon={<KeyboardArrowDown />}
      >
        <Avatar
          sx={{
            height: '25px',
            width: '25px',
            marginRight: '5px',
            marginLeft: '0.5em',
            fontSize: '10px',
            fontWeight: 'bold',
            backgroundColor: 'default.dark',
          }}
          {...stringAvatar(user)}
        />
        {!isWidthXSorSM && (
          <Typography
            noWrap
            sx={{
              marginRight: '5px',
              marginLeft: '5px',
            }}
            color="grey"
          >
            {user}
          </Typography>
        )}
      </Button>
      <Menu
        elevation={0}
        anchorEl={menuAnchor}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={showMenu}
        onClose={handleClose}
      >
        <MenuItem
          sx={{
            textDecoration: 'none',
            color: '#000',
            '&:hover': {
              textDecoration: 'none',
              color: '#000',
            },
          }}
        >
          <Link
            to={settingsPath}
            style={{
              textDecoration: 'none',
              color: '#000',
            }}
          >
            Settings
          </Link>
        </MenuItem>
        <MenuItem onClick={handleLogout}>Logout</MenuItem>
      </Menu>
    </>
  );
};

export default UserDropDown;
