// TODO: this file needs refactoring (too complex logic with useEffects and state)
import React, { useState, useEffect } from 'react';
import 'sass/globals.scss';
import './ScanManifestView.scss';
import { CircularProgress, Grid, MenuItem, Select, SvgIcon } from '@mui/material';
import Page from 'sharedComponents/Page';
import { useDispatch, useSelector } from 'react-redux';
import { selectSelectedManifest } from 'modules/scales/storeSliceScales';
import { selectAuthUser } from 'modules/auth/storeSliceAuth';
import Query from 'services/firebase/Query';
import Command from 'services/firebase/Command';
import Executer from 'services/firebase/Executer';
import ScanManifestView from './ScanManifestView';
import SignManifestView from './SignManifestView';
import { CheckBox, CheckBoxOutlineBlank } from '@mui/icons-material';
import { setSelectedManifest } from 'modules/scales/storeSliceScales';
import { Manifest } from 'lg-helpers/dist/shared/types/manifests';
import IBatchManifests from 'lg-helpers/dist/shared/interfaces/IBatchManifests';

const query = new Query();
const command = new Command();
const executer = new Executer();

export enum VerifyManifestCurrentPage {
  ScanManifest,
  SignManifest,
}

const VerifyManifestView = () => {
  const selectedManifest = useSelector(selectSelectedManifest);
  const [batchedManifests, setBatchedManifests] = useState<Manifest[]>();
  const [batch, setBatch] = useState<IBatchManifests>();
  const authUser = useSelector(selectAuthUser);
  const [isLoading, setIsLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(VerifyManifestCurrentPage.ScanManifest);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!selectedManifest) {
      return;
    }

    command.manifests.setManifestStartedProcessingAtByScaleAttendant(selectedManifest.id);
    // eslint-disable-next-line
  }, [selectedManifest && selectedManifest.id]);

  useEffect(() => {
    if (!selectedManifest || !authUser || !selectedManifest.batchId) return;

    setIsLoading(true);

    const getAllBatchedManifests = async () => {
      return executer.watchMultipleDocuments(
        query.manifests.getManifestsInBatch(selectedManifest.batchId),
        data => {
          setBatchedManifests(data as Manifest[]);
          setIsLoading(false);
        },
        err => {
          console.error(err);
          // showError({
          //   title: 'Failed to load batches',
          //   duration: 10000,
          // });
          setIsLoading(false);
        }
      );
    };

    const getBatchData = async () => {
      return executer.watchSingleDocument(
        query.batchManifests.getBatchById(selectedManifest.batchId),
        data => {
          setBatch(data as IBatchManifests);
        },
        err => {
          console.error(err);
        }
      );
    };

    getAllBatchedManifests();
    getBatchData();
    // eslint-disable-next-line
  }, [selectedManifest, authUser]);

  const onPageChange = (page: VerifyManifestCurrentPage) => {
    // Transition to sign manifest component
    setCurrentPage(page);
  };

  if (isLoading) {
    return <CircularProgress color="inherit" size={30} />;
  } else {
    return (
      <Page>
        {batchedManifests && (
          <Grid xs={12} style={{ padding: 10 }}>
            <Select
              style={{ width: 400 }}
              defaultValue="void"
              disabled={currentPage !== VerifyManifestCurrentPage.ScanManifest}
              onChange={v => {
                if (v.target.value !== 'void')
                  dispatch(setSelectedManifest(batchedManifests[Number(v.target.value)]));
              }}
            >
              <MenuItem value="void" disabled={true}>
                VERIFY MULTIPLE MANIFEST
              </MenuItem>

              {batchedManifests?.map((m, index) => {
                return (
                  <MenuItem
                    disabled={m.signatureScale.isSigned}
                    key={'manifest-' + m.number}
                    value={index}
                  >
                    <>
                      {m.signatureScale.isSigned ? (
                        <SvgIcon>
                          <CheckBox />
                        </SvgIcon>
                      ) : (
                        <SvgIcon>
                          <CheckBoxOutlineBlank />
                        </SvgIcon>
                      )}
                      Manifest #: {m.number}
                    </>
                  </MenuItem>
                );
              })}
            </Select>
          </Grid>
        )}
        {currentPage == VerifyManifestCurrentPage.ScanManifest ? (
          <ScanManifestView selectedManifest={selectedManifest} onPageChange={onPageChange} />
        ) : (
          <SignManifestView
            selectedManifest={selectedManifest}
            batchManifests={batchedManifests}
            batch={batch}
            onPageChange={onPageChange}
          />
        )}
      </Page>
    );
  }
};

export default VerifyManifestView;
