import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Card,
  CardHeader,
  CardContent,
  Grid,
  Checkbox,
  FormControlLabel,
  Box,
  List,
  Divider,
  Button,
} from '@mui/material';
import { selectScaleForEdit, setScaleForEdit } from 'modules/scales/storeSliceScales';
import { useSelector, useDispatch } from 'react-redux';
import ProfileType from './ProfileType';

const ProfileTypes = () => {
  const dispatch = useDispatch();
  const scaleForEdit = useSelector(selectScaleForEdit);

  const handleHideProfileTypes = event => {
    dispatch(
      setScaleForEdit({
        ...scaleForEdit,
        profileTypes: {
          ...scaleForEdit.profileTypes,
          hide: event.target.checked,
        },
      })
    );
  };

  useEffect(() => {
    if (!scaleForEdit) {
      return;
    }

    if (!scaleForEdit.profileTypes) {
      dispatch(
        setScaleForEdit({
          ...scaleForEdit,
          profileTypes: {
            hide: false,
            list: [],
          },
        })
      );
    }
  }, [scaleForEdit, dispatch]);

  if (!scaleForEdit || !scaleForEdit.profileTypes) {
    return null;
  }

  const addNewProfileType = () => {
    dispatch(
      setScaleForEdit({
        ...scaleForEdit,
        profileTypes: {
          ...scaleForEdit.profileTypes,
          list: [
            ...scaleForEdit.profileTypes.list,
            {
              id:
                (!scaleForEdit.profileTypes.list.length
                  ? 0
                  : Math.max(...scaleForEdit.profileTypes.list.map(({ id }) => id))) + 1,
              display: '',
              value: '',
              sync: true,
            },
          ],
        },
      })
    );
  };

  const deleteProfileType = id => {
    dispatch(
      setScaleForEdit({
        ...scaleForEdit,
        profileTypes: {
          ...scaleForEdit.profileTypes,
          list: [...scaleForEdit.profileTypes.list.filter(profileType => profileType.id !== id)],
        },
      })
    );
  };

  const updateProfileType = updatedProfileType => {
    dispatch(
      setScaleForEdit({
        ...scaleForEdit,
        profileTypes: {
          ...scaleForEdit.profileTypes,
          list: [
            ...scaleForEdit.profileTypes.list.map(profileType => {
              if (profileType.id !== updatedProfileType.id) {
                return profileType;
              } else {
                return updatedProfileType;
              }
            }),
          ],
        },
      })
    );
  };

  return (
    <Card width="100%">
      <CardHeader
        title="Profile types"
        action={
          <FormControlLabel
            control={
              <Checkbox
                checked={scaleForEdit.profileTypes.hide}
                onChange={handleHideProfileTypes}
                color="primary"
              />
            }
            label="Hide"
          />
        }
      />
      <Divider />
      <CardContent>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <List dense={true}>
              {scaleForEdit.profileTypes.list.map(profileType => (
                <ProfileType
                  key={profileType.id}
                  profileType={profileType}
                  onUpdatedProfileType={updateProfileType}
                  onDeleteProfileType={deleteProfileType}
                />
              ))}
            </List>
          </Grid>
          <Grid item xs={12}>
            <Box display="flex" justifyContent="flex-end">
              <Button
                color="primary"
                variant="outlined"
                onClick={addNewProfileType}
                disabled={scaleForEdit.profileTypes.list.find(
                  ({ display, value }) => !display && !value
                )}
              >
                Add
              </Button>
            </Box>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

ProfileTypes.propTypes = {
  className: PropTypes.string,
  lane: PropTypes.object,
};

export default ProfileTypes;
