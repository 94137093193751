import React, { useState, useCallback } from 'react';
import { Button, Typography, Menu, MenuItem, MenuList, Paper, Box, Divider } from '@mui/material';
import { MoreHoriz } from '@mui/icons-material';

type MenuOption = {
  title?: string;
  description?: string;
  onClick?: Function;
  color?: string;
  divider?: boolean;
  hide?: boolean;
};

export interface ButtonOptionsMenuProps {
  options: MenuOption[];
}

export const ButtonOptionsMenu = ({ options }: ButtonOptionsMenuProps) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = useCallback(
    (e: React.MouseEvent<HTMLButtonElement>) => {
      setAnchorEl(e.currentTarget);
    },
    [setAnchorEl]
  );
  const handleClose = useCallback(() => {
    setAnchorEl(null);
  }, [setAnchorEl]);

  return (
    <>
      <Button variant="contained" color="secondary" sx={{ width: '42px' }} onClick={handleClick}>
        <MoreHoriz />
      </Button>
      <Paper sx={{ maxWidth: '100%' }}>
        <Menu
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            'aria-labelledby': 'basic-button',
          }}
        >
          <MenuList>
            {options.map(
              (option, i) =>
                !option?.hide &&
                (option.divider ? (
                  <Divider key={i} />
                ) : (
                  <MenuItem
                    key={i}
                    onClick={() => {
                      if (option.onClick) option.onClick();
                      handleClose();
                    }}
                  >
                    <Box>
                      <Typography variant="body1">{option.title}</Typography>
                      {option.description && (
                        <Typography variant="caption" color="neutral.main">
                          {option.description}
                        </Typography>
                      )}
                    </Box>
                  </MenuItem>
                ))
            )}
          </MenuList>
        </Menu>
      </Paper>
    </>
  );
};
