import { IconButton } from '@mui/material';
import { NotificationsNoneOutlined } from '@mui/icons-material';
import React from 'react';
import { useNavigate } from 'react-router';

export const NotificationButton = () => {
  const navigate = useNavigate();

  return (
    <IconButton color="inherit" onClick={() => navigate('/notifications')}>
      <NotificationsNoneOutlined className="Manifest--icon__notifications" />
    </IconButton>
  );
};
