import axios from 'axios';
import { auth } from 'services/firebase';
axios.defaults.baseURL = process.env.REACT_APP_API_BASE_URL;

export const callV1ApiUrl = async (v1ApiCompletedCallBackUrl: string) => {
  const token = await auth.currentUser?.getIdToken(true);

  const response = await axios.post(
    '/v1-api/civil-and-trucking/call-manifest-completed-callback-url',
    {
      v1ApiCompletedCallBackUrl,
    },
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
  return response.data;
};

export const activateCompany = async (companyId: string) => {
  const token = await auth.currentUser?.getIdToken(true);

  const response = await axios.post(
    `/civil/companies/activate/${companyId}`,
    {},
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
  return response.data;
};

export const fetchManifestTemplateHTML = async (
  manifestId: string,
  options: { [any: string]: any }
) => {
  try {
    const token = await auth.currentUser?.getIdToken(true);
    const response = await axios.post(
      `/template/manifest/${manifestId}`,
      { ...options },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error(error);
    return null;
  }
};

export const fetchTicketTemplateHTML = async (
  ticketId: string,
  companyId: string,
  options: { [any: string]: any }
) => {
  try {
    const token = await auth.currentUser?.getIdToken(true);
    const response = await axios.post(
      `/template/trucking-ticket/${ticketId}`,
      { ...options, companyId },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error(error);
    return null;
  }
};

export const fetchCivilTicketTemplateHTML = async (
  ticketId: string,
  companyId: string,
  options: { [any: string]: any }
) => {
  try {
    const token = await auth.currentUser?.getIdToken(true);
    const response = await axios.post(
      `/template/civil-ticket/${ticketId}`,
      { ...options, companyId },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error(error);
    return null;
  }
};

export const fetchTemplateDisplayHTML = async (
  template: { [any: string]: any },
  options?: { [any: string]: any }
) => {
  try {
    const token = await auth.currentUser?.getIdToken(true);
    const response = await axios.post(
      `/template/`,
      { ...(options || {}), template, templateDisplay: true },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error(error);
    return null;
  }
};
