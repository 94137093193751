import React, { useContext, useEffect, useMemo, useState } from 'react';
import {
  TextField,
  FormControl,
  FormHelperText,
  InputLabel,
  Grid,
  MenuItem,
  Box,
  Typography,
  Autocomplete,
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { useFormik } from 'formik';
import { invoiceSchema } from 'modules/civilAndTrucking/civil/Invoices/AddInvoiceForm/schema';
import DialogActionContainer from 'sharedComponents/DialogActions';
import { RootState, thunkDispatch } from 'store/store';
import { ModalContext } from 'sharedComponents/ModalContext';
import { addInvoice, deleteInvoice } from '../redux/actions';
import moment from 'moment';
import { getProjects, setActiveProject } from '../../Projects/redux/actions';
import { IInvoice } from 'lg-helpers/dist/shared/interfaces/IInvoice';
import { mixPanel } from 'services/mixpanel';
import { selectAuthUser } from 'modules/auth/storeSliceAuth';
import api, {
  ConnectedCompanyType,
  CompanyConnectionWithCompany,
} from 'services/api/autogenerated';

import useApiGetRequest from 'services/api/useApiGetRequest';
import fuzzyFilterOptions from 'utils/fuzzyFilterOptions';

interface IInvoiceFormProps {
  selected?: IInvoice;
  setInvoice?: Function;
}

const companiesFilterOptions = fuzzyFilterOptions<CompanyConnectionWithCompany>([
  'connected_company.name',
]);

const InvoiceForm = ({ selected, setInvoice }: IInvoiceFormProps) => {
  const { handleModal } = useContext(ModalContext);
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [isTransporter, setIsTransporter] = useState<boolean>(selected?.isTransporter ?? true);
  const [hasTickets, setHasTickets] = useState<boolean>(false);

  const projectState = useSelector((state: RootState) => state.civil.projects.data);

  // TODO: this list could be large (optimize in future)
  const [transporterState] = useApiGetRequest(
    () => api.companiesConnections.getConnectedCompaniesByTypes([ConnectedCompanyType.Transporter]),
    () => ({ title: 'Failed to load transporters' })
  );

  // TODO: this list could be large (optimize in future)
  const [sourcesState] = useApiGetRequest(
    () =>
      api.companiesConnections.getConnectedCompaniesByTypes([
        ConnectedCompanyType.SourceSupplier,
        ConnectedCompanyType.TruckingSourceSupplier,
      ]),
    () => ({ title: 'Failed to load source suppliers' })
  );

  const ticketState = useSelector((state: RootState) => state.civil.tickets.data);

  const authUser = useSelector(selectAuthUser);

  useEffect(() => {
    thunkDispatch(getProjects());
    // redux state has tickets from searching with this invoice already
    if (
      ticketState.some(
        ticket =>
          ticket.transporterInvoiceId === selected?.id ||
          ticket.sourceSupplierInvoiceId === selected?.id
      )
    ) {
      setHasTickets(true);
    }
  }, [dispatch, ticketState, selected]);

  function convertUTCtoLocalDate(date: Date) {
    var newDate = new Date(date.getTime() + date.getTimezoneOffset() * 60 * 1000);
    return newDate;
  }

  const handleSubmit = async (values: IInvoice) => {
    const { projectName, transporter, ...newInvoiceData } = values; // eslint-disable-line
    newInvoiceData.isTransporter = isTransporter;
    newInvoiceData.invoiceDate = convertUTCtoLocalDate(new Date(values.invoiceDate!));
    newInvoiceData.number = (values?.number || '').trim();

    try {
      setIsSaving(true);
      const result = await thunkDispatch(addInvoice(newInvoiceData));
      if ((result as any).error) {
        setIsSaving(false);
        return;
      }
      if (result.meta.requestStatus === 'fulfilled') {
        const invoice = result.payload as IInvoice;
        mixPanel(authUser, 'New Invoice Created', {
          'Project ID': invoice?.projectId || '',
          'Transporter Company': invoice?.transporter || '',
          'Target Company ID': invoice?.targetCompanyId || '',
          'Project Name': invoice?.projectName || '',
          'Invoice Number': invoice?.number || '',
        });
        formik.resetForm();
        if (setInvoice) {
          setInvoice(invoice);
        }
        if (!setInvoice || location.pathname !== '/civil/accounting/reconciliation') {
          await thunkDispatch(setActiveProject(invoice?.projectId!));
          navigate('/civil/accounting/reconciliation', {
            state: { payload: invoice },
          });
        }
        handleModal();
        setIsSaving(false);
        return;
      }
      setIsSaving(false);
    } catch (err) {
      setIsSaving(false);
      console.error('Unable to create invoice: ', err);
    }
  };

  const formik = useFormik({
    validationSchema: invoiceSchema,
    initialValues: selected
      ? {
          ...selected,
          invoiceDate: moment(selected.invoiceDate).format('YYYY-MM-DD'),
          isTransporter: selected.isTransporter ? selected.isTransporter : true,
        }
      : ({
          number: '',
          isTransporter: true,
          originatingCompanyId: '',
        } as IInvoice),
    onSubmit: handleSubmit,
  });

  const originatingCompany = useMemo(
    () =>
      (isTransporter ? transporterState : sourcesState)?.find(
        c => c.connected_company_id === formik.values.originatingCompanyId
      ),
    [formik.values.originatingCompanyId, isTransporter, transporterState, sourcesState]
  );

  const handleToggle = () => {
    if (!hasTickets) {
      setIsTransporter(!isTransporter);
    }
  };

  const handleDelete = async () => {
    if (selected && setInvoice) {
      await thunkDispatch(deleteInvoice(selected));
      setInvoice(undefined);
    }
  };

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <FormControl fullWidth>
            <InputLabel htmlFor="projectId" shrink>
              Project
            </InputLabel>
            <TextField
              select
              name="projectId"
              disabled={hasTickets}
              value={formik.values.projectId || ''}
              error={formik.touched.projectId && !!formik.errors.projectId}
              helperText={formik.touched.projectId && formik.errors.projectId}
              onChange={formik.handleChange}
              variant="outlined"
              autoFocus
            >
              {projectState.map(project => (
                <MenuItem value={project.id} key={project.id}>
                  {project.name}
                </MenuItem>
              ))}
            </TextField>
            <FormHelperText />
          </FormControl>
        </Grid>
        <Grid item xs={hasTickets ? 12 : 6}>
          <FormControl fullWidth>
            <InputLabel htmlFor="number" shrink>
              Number
            </InputLabel>
            <TextField
              name="number"
              value={formik.values.number || ''}
              error={formik.touched.number && !!formik.errors.number}
              helperText={formik.touched.number && formik.errors.number}
              onChange={formik.handleChange}
              variant="outlined"
              autoFocus
            />
            <FormHelperText />
          </FormControl>
        </Grid>
        {!hasTickets && (
          <Grid item xs={6}>
            <Box
              display="flex"
              bgcolor="#BFC8C4"
              maxWidth={270}
              borderRadius={100}
              alignItems="center"
              justifyContent="space-evenly"
              paddingY={1}
              paddingX={1}
              marginTop={3}
            >
              <Box
                width="100%"
                color={isTransporter ? '#FFF' : '#989898'}
                bgcolor={isTransporter ? 'secondary.main' : '#efefef'}
                marginY={0.02}
                paddingY={1}
                paddingX={0.8}
                borderRadius={100}
                sx={{ cursor: 'pointer' }}
                onClick={handleToggle}
              >
                <Typography variant="body2" align="center">
                  Transporter
                </Typography>
              </Box>
              <Box
                width="100%"
                color={!isTransporter ? '#FFF' : '#9C9E98'}
                bgcolor={!isTransporter ? 'secondary.main' : '#F0F4F6'}
                marginY={0.02}
                paddingY={1}
                paddingX={0.8}
                borderRadius={100}
                sx={{ cursor: 'pointer' }}
                onClick={handleToggle}
              >
                <Typography variant="body2" align="center">
                  Supplier
                </Typography>
              </Box>
            </Box>
          </Grid>
        )}
        <Grid item xs={6}>
          <FormControl fullWidth>
            <InputLabel htmlFor="origintatingCompanyId" shrink>
              Originating Company
            </InputLabel>
            <Autocomplete
              freeSolo
              disabled={hasTickets}
              value={originatingCompany || ''}
              options={(isTransporter ? transporterState : sourcesState) || []}
              isOptionEqualToValue={(option, value: any) =>
                option.connected_company_id === value.connected_company_id
              }
              filterOptions={companiesFilterOptions}
              getOptionLabel={option =>
                typeof option === 'string' ? option : option.connected_company.name!
              }
              onChange={(event, val: any) => {
                formik.setFieldValue('originatingCompanyId', val?.connected_company_id || '');
              }}
              renderInput={params => (
                <TextField
                  {...params}
                  placeholder="Search company"
                  variant="outlined"
                  error={
                    (formik.touched?.originatingCompanyId || !!formik.submitCount) &&
                    !!formik.errors.originatingCompanyId
                  }
                  helperText={
                    (formik.touched?.originatingCompanyId || !!formik.submitCount) &&
                    formik.errors.originatingCompanyId
                  }
                  inputProps={{
                    ...params.inputProps,
                    onKeyDown: e => {
                      if (e.key === 'Enter') {
                        e.stopPropagation();
                      }
                    },
                  }}
                />
              )}
              renderOption={(props, option) => (
                <li {...props} key={option.id}>
                  <Grid container alignItems="center" justifyContent="flex-start" wrap="nowrap">
                    <Grid item xs={6}>
                      <Typography noWrap>{option.connected_company.name}</Typography>
                    </Grid>
                  </Grid>
                </li>
              )}
            />
            <FormHelperText />
          </FormControl>
        </Grid>
        <Grid item xs={6}>
          <FormControl fullWidth>
            <InputLabel htmlFor="invoiceDate" shrink>
              Invoice Date
            </InputLabel>
            <TextField
              variant="outlined"
              type="date"
              name="invoiceDate"
              value={formik.values.invoiceDate || ''}
              error={!!formik.errors.invoiceDate}
              helperText={formik.errors.invoiceDate}
              onChange={formik.handleChange}
            />
          </FormControl>
        </Grid>
        {hasTickets && (
          <Grid item xs={12}>
            <Typography variant="body2">
              There are tickets reconciled against this invoice. Remove those tickets from the
              invoice in order to change the Originating Company or Project.
            </Typography>
          </Grid>
        )}
      </Grid>
      <DialogActionContainer
        saveHandler={formik.handleSubmit}
        editing={selected ? true : false}
        isSaving={isSaving}
        deleteHandler={selected && !hasTickets ? handleDelete : undefined}
      />
    </>
  );
};

export default InvoiceForm;
