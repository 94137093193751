import React, { useState, useEffect, ChangeEvent } from 'react';
import { Grid, TextField, Button } from '@mui/material';
import { Add, Remove } from '@mui/icons-material';
import { hasErrorRequired } from 'services/logic/formValidation';
import ErrorMessages from 'services/constants/errorMessages';
import { ICustomInputListItem } from 'services/api/autogenerated-code';
const { isRequired } = ErrorMessages;

interface ListItemDefinitionProps {
  listItem: ICustomInputListItem;
  onSetListItem: (item: ICustomInputListItem) => void;
  onAddListItem: () => void;
  onRemoveListItem: (id: string) => void;
  canValidate: boolean;
  showRemoveButton: boolean;
  showAddButton: boolean;
  userCanEdit?: boolean;
}

const ListItemDefinition = ({
  listItem,
  onSetListItem,
  onAddListItem,
  onRemoveListItem,
  canValidate,
  showRemoveButton,
  showAddButton,
  userCanEdit = true,
}: ListItemDefinitionProps) => {
  const [localListItem, setLocalListItem] = useState<ICustomInputListItem>({
    id: '',
    label: '',
    value: '',
  });

  useEffect(() => {
    setLocalListItem(listItem);
    // eslint-disable-next-line
  }, []);

  const handleChange = (event: ChangeEvent) => {
    const { name, value } = event.currentTarget as HTMLInputElement;
    const changedLocalListItem = {
      ...localListItem,
      [name]: value,
    };

    setLocalListItem(changedLocalListItem);
  };

  const handleBlur = () => {
    if (onSetListItem) onSetListItem(localListItem);
  };

  const handleAddNewListItem = () => {
    if (onAddListItem) onAddListItem();
  };

  return (
    <Grid container spacing={2}>
      <Grid item sm={5} xs={12}>
        <TextField
          disabled={!userCanEdit}
          fullWidth
          label="Label"
          name="label"
          onChange={handleChange}
          onBlur={handleBlur}
          value={localListItem.label || ''}
          variant="outlined"
          error={hasErrorRequired(localListItem.label, canValidate)}
          helperText={hasErrorRequired(localListItem.label, canValidate) ? isRequired : ''}
          required
        />
      </Grid>
      <Grid item sm={5} xs={12}>
        <TextField
          disabled={!userCanEdit}
          fullWidth
          label="Value"
          name="value"
          onChange={handleChange}
          onBlur={handleBlur}
          value={localListItem.value || ''}
          variant="outlined"
          error={hasErrorRequired(localListItem.value, canValidate)}
          helperText={hasErrorRequired(localListItem.value, canValidate) ? isRequired : ''}
          required
        />
      </Grid>
      <Grid item sm={1} xs={12} display="flex">
        {showRemoveButton && (
          <Button
            disabled={!userCanEdit}
            variant="text"
            color="secondary"
            onClick={() => {
              if (onRemoveListItem) onRemoveListItem(localListItem.id || '');
            }}
          >
            <Remove style={{ cursor: 'pointer' }} color="inherit" />
          </Button>
        )}
        {showAddButton && (
          <Button
            variant="text"
            color="primary"
            onClick={handleAddNewListItem}
            disabled={!userCanEdit}
          >
            <Add style={{ cursor: 'pointer' }} color="inherit" />
          </Button>
        )}
      </Grid>
    </Grid>
  );
};

export default ListItemDefinition;
