import React, { useEffect, useContext } from 'react';
import { thunkDispatch } from 'store/store';
import { getDivisionsByCompanyId } from 'modules/civilAndTrucking/trucking/features/Divisions/redux/actions';
import { getTruckTypesByCompanyId } from 'modules/civilAndTrucking/trucking/features/TruckTypes/redux/actions';
import { IJoinTruck } from 'lg-helpers/dist/trucking/interfaces/IJoinTruck';
import { getJoinedTrucksByCompanyId } from '../../redux/actions';
import { ManageHeader } from 'sharedComponents/ManageHeader';
import { ModalContext } from 'sharedComponents/ModalContext';
import { TruckForm } from './TruckForm';
import { TrucksList } from './components/TrucksList';
import { IFlattenedCapacities } from 'lg-helpers/dist/shared/interfaces/IFlattenedCapabilities';

export type ITruckFlattenedCapacities = IJoinTruck & Partial<IFlattenedCapacities>;

export const Trucks = () => {
  const { handleModal } = useContext(ModalContext);

  useEffect(() => {
    thunkDispatch(getJoinedTrucksByCompanyId());
    thunkDispatch(getDivisionsByCompanyId());
    thunkDispatch(getTruckTypesByCompanyId());
  }, []);

  const showModal = (joinedTruck?: ITruckFlattenedCapacities) => {
    handleModal(<TruckForm editObj={joinedTruck} />);
  };

  return (
    <>
      <ManageHeader
        headerTitle={'Manage Trucks'}
        buttonText={'Add Truck'}
        onButtonClick={showModal}
      />
      <TrucksList showModal={showModal} />
    </>
  );
};
