import React from 'react';
import { createSvgIcon } from '@mui/material';

export const Envelope = createSvgIcon(
  <path
    fillRule="evenodd"
    clipRule="evenodd"
    d="M21.5832 5.57344c.1676-.13321.4168-.0086.4168.20195v8.78711c0 1.1387-.9238 2.0625-2.0625 2.0625H2.0625C.92383 16.625 0 15.7012 0 14.5625V5.77969c0-.21485.24492-.33516.4168-.20196.9625.74766 2.23867 1.69727 6.62148 4.88127.90664.6617 2.43633 2.0539 3.96172 2.0453 1.534.0129 3.0937-1.4094 3.966-2.0453 4.3828-3.184 5.6547-4.13791 6.6172-4.88556ZM11 11.125c.9969.0172 2.432-1.25469 3.1539-1.77891 5.702-4.13789 6.1359-4.49882 7.4508-5.53007.2492-.19336.3953-.49414.3953-.81211V2.1875C22 1.04883 21.0762.125 19.9375.125H2.0625C.92383.125 0 1.04883 0 2.1875v.81641c0 .31797.1461.61445.39531.81211C1.71016 4.84297 2.14414 5.2082 7.8461 9.34609 8.56797 9.87031 10.0031 11.1422 11 11.125Z"
  />,
  'Envelope'
);
