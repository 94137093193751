import React from 'react';
import { SvgIcon } from '@mui/material';
import { ReactComponent as Svg } from './raw/truck.svg';
import PropTypes from 'prop-types';

const IncomingManifests = () => {
  return (
    <SvgIcon width={'100%'}>
      <Svg />
    </SvgIcon>
  );
};

IncomingManifests.propTypes = {
  width: PropTypes.any,
};

export default IncomingManifests;
