import React, { useState, useEffect, DragEvent, MouseEventHandler } from 'react';
import { Grid, Box, Card, CardContent, List, ListItem, Divider, Button } from '@mui/material';
import CustomInputListDefinition from './CustomInputListDefinition';
import CustomInputSignatureDefinition from './CustomInputSignatureDefinition';
import CustomInputTextDefinition from './CustomInputTextDefinition';
import CustomInputCheckboxDefinition from './CustomInputCheckboxDefinition';
import { ICustomInputDefinitions } from 'lg-helpers/dist/shared/interfaces/ICustomInputDefinitions';
import { CustomInputsDefinitionsCrudCardHeader } from './CustomInputsDefinitionsCrudCardHeader';

const componentsInputDefinitions = {
  ListCustomInputDefinition: CustomInputListDefinition,
  TextCustomInputDefinition: CustomInputTextDefinition,
  CheckboxCustomInputDefinition: CustomInputCheckboxDefinition,
  SignatureCustomInputDefinition: CustomInputSignatureDefinition,
};

interface CustomInputsDefinitionsCrudProps {
  customInputsDefinitions: ICustomInputDefinitions[];
  setCustomInputsDefinitions: (customInputsDefinitions: ICustomInputDefinitions[]) => void;
  wasSubmitted: boolean;
  userCanEdit?: boolean;
  generatorCompanyId?: string;
  handleSave?: MouseEventHandler<HTMLButtonElement> | null;
  isSaving?: boolean;
  hasManifests?: boolean;
}

const CustomInputsDefinitionsCrud = ({
  customInputsDefinitions,
  setCustomInputsDefinitions,
  wasSubmitted,
  userCanEdit = true,
  generatorCompanyId,
  handleSave = null,
  isSaving = false,
  hasManifests = true,
}: CustomInputsDefinitionsCrudProps) => {
  const [dragId, setDragId] = useState<string | null>(null);
  const [canValidate, setCanValidate] = useState(false);
  const [currentInputDefinitionId, setCurrentInputDefinitionId] = useState<string | null>(null);
  const [isTypesSelectDisabled, setIsTypesSelectDisabled] = useState(true);

  useEffect(() => {
    if (!customInputsDefinitions) {
      return;
    }

    setIsTypesSelectDisabled(
      !!customInputsDefinitions.find(
        item => !item.label || !item.propertyPathInManifestCustomInputs
      )
    );
  }, [customInputsDefinitions]);

  const hideItem = (hiddenItem: ICustomInputDefinitions) => {
    setInputDefinition({
      ...hiddenItem,
      isHidden: !hiddenItem.isHidden,
    });
  };

  const setInputDefinition = (inputDefinition: ICustomInputDefinitions) => {
    setCustomInputsDefinitions(
      customInputsDefinitions.map(oldInputDefinition => {
        if (oldInputDefinition.id === inputDefinition.id) {
          return inputDefinition;
        }
        return oldInputDefinition;
      })
    );
  };

  const removeCustomInput = (id: string) => {
    setCustomInputsDefinitions(customInputsDefinitions.filter(input => input.id !== id));
  };

  const handleDrag = (ev: DragEvent) => {
    setDragId(ev.currentTarget.id);
  };

  const handleDrop = (ev: DragEvent) => {
    const dragInputDefnition = customInputsDefinitions.find(
      inputDefinition => inputDefinition.id === dragId
    );
    const dropInputDefnition = customInputsDefinitions.find(
      inputDefinition => inputDefinition.id === ev.currentTarget.id
    );

    const dragInputDefnitionOrder = (dragInputDefnition as ICustomInputDefinitions).order;
    const dropInputDefnitionOrder = (dropInputDefnition as ICustomInputDefinitions).order;

    let reorderedCustomInputsDefinitions = customInputsDefinitions.map(inputDefinition => {
      if (inputDefinition.id === dragId) {
        inputDefinition.order = dropInputDefnitionOrder;
      }
      if (inputDefinition.id === ev.currentTarget.id) {
        inputDefinition.order = dragInputDefnitionOrder;
      }
      return inputDefinition;
    });

    setCustomInputsDefinitions(reorderedCustomInputsDefinitions);
  };

  return (
    <Box marginTop={3}>
      <Card>
        <CustomInputsDefinitionsCrudCardHeader
          customInputsDefinitions={customInputsDefinitions}
          setCustomInputsDefinitions={setCustomInputsDefinitions}
          setCurrentInputDefinitionId={setCurrentInputDefinitionId}
          setCanValidate={setCanValidate}
          isTypesSelectDisabled={isTypesSelectDisabled}
          setIsTypesSelectDisabled={setIsTypesSelectDisabled}
          generatorCompanyId={generatorCompanyId}
          handleSave={handleSave}
          isSaving={isSaving}
        />
        <Divider />
        <CardContent>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <List>
                {customInputsDefinitions
                  .sort((a, b) => a.order - b.order)
                  .map(inputDefinition => {
                    const InputDefinitionComponent = (componentsInputDefinitions as any)[
                      `${inputDefinition.type}CustomInputDefinition`
                    ];
                    return (
                      <ListItem
                        id={inputDefinition.id}
                        key={inputDefinition.id}
                        draggable={true}
                        onDragOver={ev => ev.preventDefault()}
                        onDragStart={handleDrag}
                        onDrop={handleDrop}
                      >
                        <Box display="flex" flexDirection="row" flexWrap="wrap" width="100%">
                          <InputDefinitionComponent
                            userCanEdit={userCanEdit}
                            inputDefinition={inputDefinition}
                            setInputDefinition={setInputDefinition}
                            canValidate={
                              wasSubmitted ||
                              (canValidate &&
                                currentInputDefinitionId &&
                                currentInputDefinitionId !== inputDefinition.id)
                            }
                            setCanValidate={setCanValidate}
                            hideCallback={hideItem}
                          />
                          {!hasManifests && (
                            <Button
                              sx={{ margin: '10px 0' }}
                              color="error"
                              variant="contained"
                              fullWidth
                              onClick={() => removeCustomInput(inputDefinition.id)}
                            >
                              Remove Custom Input #{inputDefinition.order}
                            </Button>
                          )}
                        </Box>
                      </ListItem>
                    );
                  })}
              </List>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </Box>
  );
};

export default CustomInputsDefinitionsCrud;
