import React, { useState } from 'react';
import './Auth.scss';
import { Grid, Button, Typography, CircularProgress, Box, TextField } from '@mui/material';
import withWidth, { WithWidth } from '@mui/material/Hidden/withWidth';
import LivegisticsLogoLarge from 'sharedComponents/pictograms/LivegisticsLogoLarge';
import LivegisticsLogoSmall from 'sharedComponents/pictograms/LivegisticsLogoSmall';
import { Link } from 'react-router-dom';
import { hasErrorRequired, hasErrorValidEmail } from 'services/logic/formValidation';
import { auth } from 'services/firebase';
import useShowError from 'modules/errors';
import ErrorMessages from 'services/constants/errorMessages';
const { isRequired, emailNotValid } = ErrorMessages;

const ForgotPasswordView = ({ width }: WithWidth) => {
  const [email, setEmail] = useState('');
  const showError = useShowError();
  const [isResetInProgress, setIsResetInProgress] = useState(false);
  const [wasSubmitted, setWasSubmitted] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);

  const handleForgotPassword = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setIsResetInProgress(true);
    try {
      await auth.sendPasswordResetEmail(email);
      setIsSuccess(true);
    } catch (err: any) {
      if (err && err.code !== 'auth/argument-error' && err.code !== 'auth/invalid-email') {
        showError({ title: 'Reset password error', text: err.message });
      }
      showError({ title: 'Reset password error' });
    }
    setIsResetInProgress(false);
    setWasSubmitted(true);
  };

  return (
    <Grid container className="Auth">
      <Grid item md={4} xs={12}>
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          height="100%"
          margin={2}
        >
          {isSuccess ? (
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Box marginTop={2}>
                  {width === 'lg' || width === 'xl' ? (
                    <LivegisticsLogoLarge />
                  ) : (
                    <LivegisticsLogoSmall />
                  )}
                </Box>
              </Grid>
              <Grid item xs={12}>
                <Typography>
                  Your reset password request was successful! Check your email for a link to reset
                  the password!
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Link to="/login">
                  <Button color="primary" fullWidth type="submit" variant="contained">
                    <Typography variant="h6">Okay!</Typography>
                  </Button>
                </Link>
              </Grid>
            </Grid>
          ) : (
            <form onSubmit={handleForgotPassword}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Box marginTop={2}>
                    {width === 'lg' || width === 'xl' ? (
                      <LivegisticsLogoLarge />
                    ) : (
                      <LivegisticsLogoSmall />
                    )}
                  </Box>
                </Grid>
                <Grid item xs={12}>
                  <Typography>
                    Enter your email and an activation link will be sent to reset your password
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label="Email address"
                    name="email"
                    onChange={event => setEmail(event.target.value)}
                    type="text"
                    value={email}
                    variant="outlined"
                    error={
                      hasErrorRequired(email, wasSubmitted) ||
                      hasErrorValidEmail(email, wasSubmitted)
                    }
                    helperText={
                      hasErrorRequired(email, wasSubmitted)
                        ? isRequired
                        : hasErrorValidEmail(email, wasSubmitted)
                        ? emailNotValid
                        : ''
                    }
                  />
                </Grid>
                <Grid item xs={12}>
                  <Button color="primary" fullWidth type="submit" variant="contained">
                    <Typography variant="h6">Reset</Typography>
                    {isResetInProgress && (
                      <Box marginLeft={2}>
                        <CircularProgress color="inherit" size={25} />
                      </Box>
                    )}
                  </Button>
                </Grid>
              </Grid>
            </form>
          )}
        </Box>
      </Grid>
      <Grid item md={8} xs={12} className="Auth__video">
        <video className="Auth__video--player" autoPlay loop muted>
          <source src="/static/videos/loginBackground.mp4" type="video/mp4" />
        </video>
      </Grid>
    </Grid>
  );
};

export default withWidth()(ForgotPasswordView);
