import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { DataGrid, GridColDef, GridColumnVisibilityModel } from '@mui/x-data-grid';
import { CheckBoxOutlineBlank, CheckBox } from '@mui/icons-material';
import { selectJoinedTrucks } from 'modules/civilAndTrucking/trucking/features/Trucks/redux/trucksStoreSlice';
import {
  flattenCapacities,
  getColumnVisibilitiesByCapacities,
} from 'modules/civilAndTrucking/trucking/utils';
import { LoadingOverlay as Loading } from 'sharedComponents/LoadingOverlay';
import { ITruckFlattenedCapacities } from '../../../../Trucks/views/TrucksView/Trucks';
import { unitsOfMeasure } from 'lg-helpers/dist/constants/global-constants';

type AddTrucksToDivisionProps = {
  addTruck: (id: number) => void;
  removeTruck: (id: number) => void;
  truckIds: number[];
};

export const TrucksOutsideDivisionList = ({
  addTruck,
  removeTruck,
  truckIds,
}: AddTrucksToDivisionProps) => {
  const { joinedTrucksByDivisions, joinedTrucksStatusByDivisions } =
    useSelector(selectJoinedTrucks);
  const [joinedTrucksWithCapacities, setJoinedTrucksWithCapacities] = useState(
    [] as ITruckFlattenedCapacities[]
  );
  const [visibleColumns, setVisibleColumns] = useState<GridColumnVisibilityModel>({});

  useEffect(() => {
    if (!joinedTrucksByDivisions.length) return;

    const trucksWithCaps = joinedTrucksByDivisions.map(truck => {
      return {
        ...truck,
        ...flattenCapacities(truck.capacitiesTruckingTruckType),
      } as ITruckFlattenedCapacities;
    });
    const columnsRemoveVisibility = getColumnVisibilitiesByCapacities(trucksWithCaps);

    setJoinedTrucksWithCapacities(trucksWithCaps);
    setVisibleColumns(columnsRemoveVisibility);
  }, [joinedTrucksByDivisions]);

  const columns: GridColDef[] = [
    {
      field: 'add',
      headerName: ' ',
      width: 30,
      sortable: false,
      renderCell: ({ row }) =>
        truckIds.includes(row?.idTruckingTruck) ? (
          <CheckBox onClick={() => removeTruck(Number(row?.idTruckingTruck))} />
        ) : (
          <CheckBoxOutlineBlank onClick={() => addTruck(Number(row?.idTruckingTruck))} />
        ),
    },
    {
      field: 'nameTruckingTruckDivision',
      headerName: 'Current Division',
      width: 180,
    },
    {
      field: 'numberTruckingTruck',
      headerName: 'Truck Number',
      width: 180,
    },
    {
      field: 'nameTruckingTruckType',
      headerName: 'Truck Type',
      width: 180,
    },
    ...unitsOfMeasure.map(e => ({
      field: e,
      headerName: e,
    })),
  ];

  if (joinedTrucksStatusByDivisions === 'pending') {
    return <Loading />;
  }

  return (
    <DataGrid
      columns={columns}
      columnVisibilityModel={visibleColumns}
      rows={joinedTrucksWithCapacities || []}
      getRowId={row => row.idTruckingTruck}
      hideFooterPagination={true}
      disableSelectionOnClick
      disableColumnMenu
      autoHeight
      components={{
        ColumnResizeIcon: () => <div></div>,
      }}
    />
  );
};
