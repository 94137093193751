/*
 * For downloading/exporting a comma-delimeted string as a CSV
 * Reference link: https://stackoverflow.com/a/68146412
 */

export const downloadBlob = (content: string, filename: string, contentType: string) => {
  // Create a blob
  var blob = new Blob([content], { type: contentType });
  var url = URL.createObjectURL(blob);

  // Create a link to download it
  var pom = document.createElement('a');
  pom.href = url;
  pom.setAttribute('download', filename);
  pom.click();
};
