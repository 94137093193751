import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

export const HomeView: React.FC = () => {
  const navigate = useNavigate();

  useEffect(() => {
    navigate('/trucking-broker/dispatches');
  }, [navigate]);
  return null;
};

export default HomeView;
