import React from 'react';
import { Box, Button, Typography } from '@mui/material';
import ButtonLink from 'sharedComponents/ButtonLink';

interface ManageHeaderProps {
  headerTitle: string;
  buttonText: string;
  onButtonClick: Function;
  centerComponent?: JSX.Element;
  disabled?: boolean;
}

export const ManageHeader = ({
  headerTitle,
  buttonText,
  onButtonClick,
  centerComponent,
  disabled,
}: ManageHeaderProps) => {
  return (
    <>
      <ButtonLink text="Back" color="inherit" variant="text" />
      <Box display="flex" alignItems="center" justifyContent="space-between" mb={2}>
        <Typography variant="h5">{headerTitle}</Typography>
        {centerComponent && centerComponent}
        <Button
          variant="contained"
          color="primary"
          onClick={() => onButtonClick()}
          sx={{ width: '200px', ml: 2 }}
          disabled={disabled || false}
        >
          {buttonText}
        </Button>
      </Box>
    </>
  );
};
