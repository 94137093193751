import { createAsyncThunk } from '@reduxjs/toolkit';
import Tables from 'services/postgres/Tables';
import ApiBase from 'services/postgres/ApiBase';
import { INotification } from 'modules/civilAndTrucking/shared/NotificationSnackbar/INotification';
import { pushNotification } from 'modules/civilAndTrucking/shared/NotificationSnackbar/redux/actions';
import { ITruckingProject } from 'lg-helpers/dist/trucking/interfaces/ITruckingProject';
import { RootState } from 'store/store';
import { pushError } from 'modules/errors/storeSliceErrors';
import { addCacheItem, deleteCacheItem, getCacheItem } from 'utils/memoryCache';

const apiBase = new ApiBase();
const PROJECT_TABLE = Tables.TruckingProject;

type activeOrInactive = 'active' | 'inactive' | 'all';

// TODO: drop it (cache and actions). requires rewrite with TSOA
export const getProjectsByCompanyId = createAsyncThunk(
  'projects/get-projects',
  async (status: activeOrInactive, thunkApi) => {
    try {
      const method = `${PROJECT_TABLE}/${status}`;
      const cacheKey = ['get-trucking-projects', method].join('_');
      const fromCache = getCacheItem(cacheKey);
      if (fromCache) return fromCache as ITruckingProject[];
      const data = await apiBase.getList(method);
      addCacheItem(cacheKey, data);
      return data as ITruckingProject[];
    } catch (err) {
      thunkApi.dispatch(pushError({ title: 'Error: Unable to get projects' }));
      throw err;
    }
  }
);

export const getProjectByProjectId = createAsyncThunk(
  'project/get-project',
  async (projectId: number, thunkApi) => {
    try {
      const data = await apiBase.getById(projectId, Tables.TruckingProject);

      return data as ITruckingProject;
    } catch (err) {
      thunkApi.dispatch(pushError({ title: 'Error: Unable to get project materials' }));
      throw err;
    }
  }
);

export const upsertCompanyProject = createAsyncThunk(
  'project/set-project',
  async (project: ITruckingProject, thunkApi) => {
    const state = thunkApi.getState() as RootState;
    const companyId = state.civil.companies.activeCompany?.id;

    const projectReq = {
      ...project,
      companyIdTruckingProject: companyId,
    } as ITruckingProject;

    try {
      const projectData = await apiBase.insertOrUpdate(projectReq, PROJECT_TABLE);
      deleteCacheItem(`get-trucking-projects_${PROJECT_TABLE}/active`);
      deleteCacheItem(`get-trucking-projects_${PROJECT_TABLE}/inactive`);
      const successNotification: INotification = {
        status: 'success',
        message: `Success: Project has been ${
          projectReq.idTruckingProject ? 'updated' : 'created'
        }`,
      };
      thunkApi.dispatch(pushNotification(successNotification));
      return projectData;
    } catch (err) {
      thunkApi.dispatch(pushError({ title: 'Error: Unable to create new company project' }));
      throw err;
    }
  }
);
