import { createAsyncThunk } from '@reduxjs/toolkit';
import Tables from 'services/postgres/Tables';
import ApiBase from 'services/postgres/ApiBase';
import { INotification } from 'modules/civilAndTrucking/shared/NotificationSnackbar/INotification';
import { pushNotification } from 'modules/civilAndTrucking/shared/NotificationSnackbar/redux/actions';
import { ITruckingLoad } from 'lg-helpers/dist/trucking/interfaces/ITruckingLoad';
import { RootState } from 'store/store';
import { pushError } from 'modules/errors/storeSliceErrors';

const apiBase = new ApiBase();
const LOAD_TABLE = Tables.TruckingLoad;

export const getLoadsByDispatchId = createAsyncThunk(
  'loads/get-loads-and-tickets',
  async (dispatchId: number, thunkApi) => {
    try {
      const data = await apiBase.getList(`${LOAD_TABLE}/dispatch/${dispatchId}`);
      return data as ITruckingLoad[];
    } catch (err) {
      thunkApi.dispatch(pushError({ title: 'Error getting loads' }));
      throw err;
    }
  }
);

export const getBrokerLoadsByDispatchId = createAsyncThunk(
  'loads/get-broker-loads-and-tickets',
  async (dispatchId: number, thunkApi) => {
    try {
      const state: RootState = thunkApi.getState() as RootState;
      const companyId = state.civil.companies.activeCompany?.id;

      const data = await apiBase.getList(
        `${LOAD_TABLE}/dispatch/${dispatchId}/brokerCompanyId/${companyId}`
      );
      return data as ITruckingLoad[];
    } catch (err) {
      thunkApi.dispatch(pushError({ title: 'Error getting loads' }));
      throw err;
    }
  }
);

export const upsertLoad = createAsyncThunk(
  'truckng-loads/upsert-load',
  async (load: Partial<ITruckingLoad>, thunkApi) => {
    try {
      const loadId = await apiBase.insertOrUpdate(load, LOAD_TABLE);
      const successNotification: INotification = {
        status: 'success',
        message: 'Success: Load has been created or updated',
      };
      thunkApi.dispatch(pushNotification(successNotification));
      return loadId as number;
    } catch (err) {
      thunkApi.dispatch(pushError({ title: 'Error: Unable to create or update load' }));
      throw err;
    }
  }
);

export const deleteLoad = createAsyncThunk(
  'truckng-loads/delete-load',
  async (loadId: number, thunkApi) => {
    try {
      await apiBase.deleteById(loadId, LOAD_TABLE);
    } catch (err) {
      thunkApi.dispatch(pushError({ title: 'Error: Unable to delete the load' }));
    }
  }
);
