import React, { useEffect, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { isFunction } from 'lodash';
import {
  Grid,
  CircularProgress,
  Typography,
  Box,
  TextField,
  Button,
  Autocomplete,
  FormControlLabel,
  Switch,
} from '@mui/material';
import GetAppIcon from '@mui/icons-material/GetApp';
import BorderColorIcon from '@mui/icons-material/BorderColor';
import { selectAuthUser } from 'modules/auth/storeSliceAuth';
import { unitsOfMeasure } from 'lg-helpers/dist/constants/global-constants';
import ManifestDisplayWithTemplateDataGathering from 'sharedComponents/ManifestDisplayWithTemplateDataGathering';
import ErrorMessages from 'services/constants/errorMessages';
import { hasErrorRequired } from 'services/logic/formValidation';
import useShowError from 'modules/errors';
import useShowSuccess from 'modules/successMessage';

import Query from 'services/firebase/Query';
import Command from 'services/firebase/Command';
import InputSignature from 'sharedComponents/InputSignature';
import CustomInputsRenderer from 'sharedComponents/CustomInputsRenderer';
import { isProjectExpiring } from 'utils/isProjectExpiring';

import { useConfirm } from 'material-ui-confirm';
import { useSelector } from 'react-redux';
import usersCanAssignManfest from 'services/constants/usersCanAssignManfest';
import { mixPanel } from 'services/mixpanel';
import api from '../services/api/autogenerated';

const query = new Query();
const command = new Command();

const AssignManifestToDriver = ({ project }) => {
  const authUser = useSelector(selectAuthUser);
  const [wasSubmitted, setWasSubmitted] = useState(false);
  const [isLoadingManifest, setIsLoadingManifest] = useState(false);
  const [manifest, setManifest] = useState(null);
  const showError = useShowError();
  const showSuccess = useShowSuccess();
  const [isSigning, setIsSigning] = useState(false);
  const [signatureDriverData, setSignatureDriverData] = useState({});
  const [isSignComplete, setIsSignComplete] = useState(false);
  const [seccondsLeftToSign, setSeccondsLeftToSign] = useState(null);
  const [isSignDisabled, setIsSignDisabled] = useState(null);
  const [isGetNewManifestDisabled, setIsGetNewManifestDisabled] = useState(null);
  const [customInputsSaved, setCustomInputsSaved] = useState(false);
  const [isManifestSuccessfullyDisplayed, setIsManifestSuccessfullyDisplayed] = useState(false);
  const [selectedScale, setSelectedScale] = useState(null);
  const [signatureBase64, setSignatureBase64] = useState(null);
  const [isValidSectionCustomInputs, setIsValidSectionCustomInputs] = useState(false);
  const [temporaryDriverNames, setTemporaryDriverNames] = useState([]);
  const [temporaryTruckNumbers, setTemporaryTruckNumbers] = useState([]);
  const [driverSignAtScale, setDriverSignAtScale] = useState(false);
  const confirm = useConfirm();

  const shouldShowDriverSignOption = project?.scales?.some(scale => {
    // return true if scale.id is C7WbgCSm2UFv4KwmXtIM
    return scale.id === 'C7WbgCSm2UFv4KwmXtIM';
  });

  const resetManifestRequestedAtCountdown = useCallback(async () => {
    if (!manifest) {
      return;
    }
    try {
      await command.manifests.markManifestWasRequestedNowByDriver(manifest.id);
      setSeccondsLeftToSign(60);
    } catch (err) {
      console.error(err);
      showError({ title: 'Error at manifest reset countdown' });
    }
    // eslint-disable-next-line
  }, [manifest, setSeccondsLeftToSign]);

  useEffect(() => {
    if (!authUser) {
      return;
    }

    if (usersCanAssignManfest.includes(authUser.actingAsType)) {
      if (authUser.temporaryData) {
        const tempData = authUser.temporaryData;
        if (tempData.lastUpdatedAt) {
          const lastUpdatedAt = tempData.lastUpdatedAt;
          const date = isFunction(lastUpdatedAt.toDate)
            ? lastUpdatedAt.toDate()
            : new Date(lastUpdatedAt.seconds * 1000);

          if (new Date().getDate() !== date.getDate()) {
            command.users.updateUserTemporaryData(authUser.id, {
              driverNames: [],
              truckNumbers: [],
            });
            return;
          }
        }
        if (tempData.driverNames) {
          setTemporaryDriverNames(tempData.driverNames);
        }

        if (tempData.truckNumbers) {
          setTemporaryTruckNumbers(tempData.truckNumbers);
        }
      }
    }
  }, [authUser]);

  useEffect(() => {
    if (project && project.scales && project.scales.length === 1) {
      setSelectedScale(project.scales[0]);
    }
    if (project.transporterCompanies?.length === 1 && !signatureDriverData.truckingCompany) {
      setSignatureDriverData({
        ...signatureDriverData,
        truckingCompany: project.transporterCompanies[0],
      });
    }
  }, [project, signatureDriverData]);

  useEffect(() => {
    setIsGetNewManifestDisabled(
      !selectedScale ||
        !signatureDriverData.truckingCompany ||
        !signatureDriverData.truckNumber ||
        !signatureDriverData.truckCapacity ||
        !signatureDriverData.truckCapacityUnit
    );
  }, [selectedScale, signatureDriverData]);

  useEffect(() => {
    setIsSignDisabled(
      isGetNewManifestDisabled ||
        isSignComplete ||
        !manifest ||
        !seccondsLeftToSign ||
        !signatureDriverData.driverFirstName ||
        !signatureDriverData.driverLastName ||
        !signatureBase64
    );
  }, [
    isGetNewManifestDisabled,
    manifest,
    seccondsLeftToSign,
    isSignComplete,
    signatureDriverData,
    signatureBase64,
  ]);

  useEffect(() => {
    if (seccondsLeftToSign === null) {
      return;
    }
    if (seccondsLeftToSign === 15) {
      resetManifestRequestedAtCountdown();
      setSeccondsLeftToSign(60);
      return;
    }

    const timeout = setTimeout(() => setSeccondsLeftToSign(seccondsLeftToSign - 1), 1000);
    return () => clearTimeout(timeout);
  }, [seccondsLeftToSign, resetManifestRequestedAtCountdown]);

  const handleChange = event => {
    setSignatureDriverData({
      ...signatureDriverData,
      [event.target.name]: event.target.value,
    });
    setWasSubmitted(false);
  };

  const getNextAvailableManifest = () => {
    setIsManifestSuccessfullyDisplayed(false);
    setIsLoadingManifest(true);

    // fix for bug LM-573
    setIsValidSectionCustomInputs(false);
    setCustomInputsSaved(false);

    query.manifests
      .getNextUnsignedManifestForDriver(project.id)
      .get()
      .then(({ docs }) => {
        if (docs.length) {
          const doc = docs[0];
          if (driverSignAtScale) {
            const { truckingCompany: truckCompany, ...restSignatureDriver } = signatureDriverData;
            command.manifests
              .claimManifestForDriver(doc.id, {
                ...restSignatureDriver,
                scale: selectedScale,
                truckingCompany: truckCompany.name,
                truckingCompanyId: truckCompany.id,
              })
              .then(() => {
                showSuccess({
                  title: `Manifest has been claimed`,
                  duration: 10000,
                });
                setTimeout(() => {
                  setSignatureDriverData({});
                  setTemporaryTruckNumbers([]);
                  setSelectedScale(null);
                }, 1000);
              })
              .catch(error => {
                console.error('Error', error);
                showError({
                  title: 'Error update requested manifest',
                  duration: 10000,
                });
              });
          } else {
            setManifest({
              ...doc.data(),
              id: doc.id,
            });
            setIsSignComplete(false);

            command.manifests
              .markManifestWasRequestedNowByDriver(doc.id, authUser.id)
              .then(() => {
                setIsLoadingManifest(false);
                setSeccondsLeftToSign(60);
              })
              .catch(error => {
                console.error('Error', error);
                showError({
                  title: 'Error update requested manifest',
                  duration: 10000,
                });
                setIsLoadingManifest(false);
              });
          }
        } else {
          setManifest(null);
          showError({ title: 'No available manifests!', duration: 10000 });
          setIsLoadingManifest(false);
        }
      })
      .catch(error => {
        console.error('Error', error);
        showError({ title: 'Error get manifest', duration: 10000 });
      })
      .then(() => setIsLoadingManifest(false));
  };

  const handleClickSign = async () => {
    if (isProjectExpiring(project)) {
      try {
        const expiryDate = project.expiryDate.toDate();

        await confirm({
          title: '',
          content: `This profile expires on ${expiryDate.toDateString()}.  Click Ok to continue.`,
        });
      } catch (err) {
        console.error(err);
        return;
      }
    }

    setIsSigning(true);

    try {
      const temporaryData = {
        driverNames: temporaryDriverNames.find(
          name =>
            (name || '').toLowerCase() === (signatureDriverData.driverName || '').toLowerCase()
        )
          ? temporaryDriverNames
          : [
              ...temporaryDriverNames,
              `${signatureDriverData.driverFirstName} ${signatureDriverData.driverLastName}`,
            ],
        truckNumbers: temporaryTruckNumbers.find(
          truck => truck.toLowerCase() === signatureDriverData.truckNumber.toLowerCase()
        )
          ? temporaryTruckNumbers
          : [...temporaryTruckNumbers, signatureDriverData.truckNumber],
      };
      await command.users.updateUserTemporaryData(authUser.id, temporaryData);
    } catch (err) {
      console.error(err);
    }

    try {
      let driverSignatureUrl = null;
      if (signatureBase64) {
        driverSignatureUrl = await command.uploadUserSignatureAndGetUrl(
          authUser.id,
          signatureBase64
        );
      }

      await command.manifests.signAssignManifestToDriver(
        manifest.id,
        driverSignatureUrl,
        signatureDriverData,
        selectedScale
      );

      mixPanel(authUser, `Manifest Assigned to Driver`, {
        'Manifest Id': manifest?.id || '',
        'Driver Name': [signatureDriverData?.driverFirstName, signatureDriverData?.driverLastName]
          .filter(e => e)
          .join(' '),
        'Truck Number': signatureDriverData?.truckNumber || '',
        'Scale Id': selectedScale?.id || '',
        'Scale Name': selectedScale?.name || '',
        'Scale Address': selectedScale?.address || '',
        'Scale State': selectedScale?.state?.name || '',
      });

      setIsSignComplete(true);
      setTimeout(() => {
        setManifest(null);
        setSignatureDriverData({
          ...signatureDriverData,
          truckNumber: '',
          truckCapacity: '',
          driverFirstName: '',
          driverLastName: '',
        });
        setSignatureBase64(null);
      }, 2000);
      setSeccondsLeftToSign(null);
    } catch (err) {
      console.error(err);
      showError({ title: 'Sign error' });
    }

    setIsSigning(false);
  };

  const CustomInputsRendererComponent = React.useMemo(
    () => (
      <CustomInputsRenderer
        section="Assign Manifest to Driver"
        manifest={manifest}
        setIsValid={setIsValidSectionCustomInputs}
        onSaved={() => setCustomInputsSaved(true)}
      />
    ),
    [manifest]
  );

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Autocomplete
          options={project.transporterCompanies}
          value={signatureDriverData.truckingCompany || null}
          isOptionEqualToValue={(option, value) => (value ? option === value : false)}
          getOptionLabel={option => option.name}
          onChange={(event, value) =>
            handleChange({
              target: {
                name: 'truckingCompany',
                value: value,
              },
            })
          }
          renderInput={params => (
            <TextField
              {...params}
              variant="outlined"
              required
              label="Trucking Company"
              placeholder="Select Trucking Company"
              error={hasErrorRequired(signatureDriverData.truckingCompany, wasSubmitted)}
              helperText={
                hasErrorRequired(signatureDriverData.truckingCompany, wasSubmitted)
                  ? ErrorMessages.isRequired
                  : ''
              }
              InputProps={{
                ...params.InputProps,
                endAdornment: <>{params.InputProps.endAdornment}</>,
              }}
            />
          )}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          fullWidth
          label="Carrier (Optional)"
          variant="outlined"
          onChange={handleChange}
          name="secondaryTruckingCompany"
          value={signatureDriverData.secondaryTruckingCompany || ''}
        />
      </Grid>

      <Grid item xs={12}>
        <Autocomplete
          freeSolo
          autoSelect
          options={temporaryTruckNumbers}
          isOptionEqualToValue={(option, value) => (value ? option === value : false)}
          value={signatureDriverData.truckNumber || ''}
          onChange={(event, value) => {
            handleChange({
              target: {
                name: 'truckNumber',
                value: value,
              },
            });
          }}
          renderInput={params => (
            <TextField
              {...params}
              variant="outlined"
              required
              label="Truck number"
              placeholder="Truck number"
              error={hasErrorRequired(signatureDriverData.truckNumber, wasSubmitted)}
              helperText={
                hasErrorRequired(signatureDriverData.truckNumber, wasSubmitted)
                  ? ErrorMessages.isRequired
                  : ''
              }
              InputProps={{
                ...params.InputProps,
                endAdornment: <>{params.InputProps.endAdornment}</>,
              }}
            />
          )}
        />
      </Grid>
      <Grid item xs={6}>
        <TextField
          required
          fullWidth
          label="Capacity"
          variant="outlined"
          type="number"
          onChange={handleChange}
          name="truckCapacity"
          value={signatureDriverData.truckCapacity || ''}
          error={hasErrorRequired(signatureDriverData.truckCapacity, wasSubmitted)}
          helperText={
            hasErrorRequired(signatureDriverData.truckCapacity, wasSubmitted)
              ? ErrorMessages.isRequired
              : ''
          }
        />
      </Grid>
      <Grid item xs={6}>
        <Autocomplete
          fullWidth
          required
          options={unitsOfMeasure || []}
          isOptionEqualToValue={(option, value) => (value ? option === value : false)}
          value={signatureDriverData.truckCapacityUnit || null}
          onChange={(event, value) =>
            handleChange({
              target: {
                name: 'truckCapacityUnit',
                value: value,
              },
            })
          }
          renderInput={params => (
            <TextField
              {...params}
              label="Units"
              required
              variant="outlined"
              error={hasErrorRequired(signatureDriverData.truckCapacityUnit, wasSubmitted)}
              helperText={
                hasErrorRequired(signatureDriverData.truckCapacityUnit, wasSubmitted)
                  ? ErrorMessages.isRequired
                  : ''
              }
              InputProps={{
                ...params.InputProps,
                endAdornment: <>{params.InputProps.endAdornment}</>,
              }}
            />
          )}
        />
      </Grid>
      <Grid item xs={12}>
        <Autocomplete
          options={project.scales}
          value={selectedScale}
          isOptionEqualToValue={(option, value) => (value ? option.id === value.id : false)}
          getOptionLabel={option => `${option.name}`}
          onChange={(event, value) =>
            setSelectedScale({
              ...value,
              selectedLaneId: null,
            })
          }
          renderInput={params => (
            <TextField
              {...params}
              variant="outlined"
              required
              label="Scale"
              placeholder="Select scale"
              error={hasErrorRequired(selectedScale, wasSubmitted)}
              InputProps={{
                ...params.InputProps,
                endAdornment: <>{params.InputProps.endAdornment}</>,
              }}
            />
          )}
        />
      </Grid>
      {/*Only show if project scale is: C7WbgCSm2UFv4KwmXtIM*/}
      {shouldShowDriverSignOption && (
        <Grid item xs={12}>
          <Box display="flex" alignItems="center">
            <FormControlLabel
              control={
                <Switch
                  checked={driverSignAtScale}
                  onChange={() => {
                    setDriverSignAtScale(!driverSignAtScale);
                  }}
                  name="driverSignAtScale"
                  color="primary"
                />
              }
              label="Have Driver Sign At Scale"
            />
          </Box>
        </Grid>
      )}
      <Grid item xs={12} sx={{ marginBottom: '24px' }}>
        <Box display="flex" justifyContent="center" alignItems="center">
          <Button
            onClick={getNextAvailableManifest}
            disabled={isGetNewManifestDisabled || (!!manifest && !isSignComplete)}
            variant="contained"
            color="primary"
            fullWidth
          >
            <Grid container spacing={3}>
              <Grid item xs={1}>
                <Box
                  display="flex"
                  flexDirection="column"
                  justifyContent="center"
                  style={{ height: '100%' }}
                >
                  {isLoadingManifest ? (
                    <CircularProgress color="inherit" />
                  ) : (
                    <GetAppIcon fontSize="large" />
                  )}
                </Box>
              </Grid>
              <Grid item xs={11}>
                {driverSignAtScale ? (
                  <>
                    <Typography variant="p">Assign Manifest</Typography>
                    <br />
                    <Typography variant="p"> Asignar Manifesto</Typography>
                  </>
                ) : (
                  <>
                    <Typography variant="p">Get New Manifest</Typography>
                    <br />
                    <Typography variant="p">Obtener Nuevo Manifesto</Typography>
                  </>
                )}
              </Grid>
            </Grid>
          </Button>
        </Box>
      </Grid>

      {manifest && (
        <>
          <Grid item xs={12}>
            {CustomInputsRendererComponent}
          </Grid>
          {customInputsSaved && (
            <Grid item xs={12}>
              <ManifestDisplayWithTemplateDataGathering
                hideReceipt={true}
                manifestId={manifest.id}
                onSucessfullDisplayCallback={setIsManifestSuccessfullyDisplayed}
                onManifestUpdateReceivedCallback={setManifest}
              />
            </Grid>
          )}

          {customInputsSaved && isManifestSuccessfullyDisplayed && isValidSectionCustomInputs && (
            <>
              <Grid item xs={6}>
                <Autocomplete
                  freeSolo
                  autoSelect
                  options={temporaryDriverNames
                    .map(driverName => {
                      let parts = driverName.split(' ');
                      return parts.length < 1 ? '' : parts[0];
                    })
                    .filter(firstName => firstName)}
                  value={signatureDriverData.driverFirstName || null}
                  onChange={(event, value) =>
                    handleChange({
                      target: {
                        name: 'driverFirstName',
                        value: value,
                      },
                    })
                  }
                  renderInput={params => (
                    <TextField
                      {...params}
                      variant="outlined"
                      required
                      label="Driver first name"
                      placeholder="Driver first name"
                      error={hasErrorRequired(signatureDriverData.driverFirstName, wasSubmitted)}
                      helperText={
                        hasErrorRequired(signatureDriverData.driverFirstName, wasSubmitted)
                          ? ErrorMessages.isRequired
                          : ''
                      }
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: <>{params.InputProps.endAdornment}</>,
                      }}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={6}>
                <Autocomplete
                  freeSolo
                  autoSelect
                  options={temporaryDriverNames
                    .map(driverName => {
                      let parts = driverName.split(' ');
                      return parts.length < 2 ? '' : parts[1];
                    })
                    .filter(lastName => lastName)}
                  value={signatureDriverData.driverLastName || null}
                  onChange={(event, value) =>
                    handleChange({
                      target: {
                        name: 'driverLastName',
                        value: value,
                      },
                    })
                  }
                  renderInput={params => (
                    <TextField
                      {...params}
                      variant="outlined"
                      required
                      label="Driver last name"
                      placeholder="Driver last name"
                      error={hasErrorRequired(signatureDriverData.driverLastName, wasSubmitted)}
                      helperText={
                        hasErrorRequired(signatureDriverData.driverLastName, wasSubmitted)
                          ? ErrorMessages.isRequired
                          : ''
                      }
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: <>{params.InputProps.endAdornment}</>,
                      }}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <InputSignature
                  signatureBase64={signatureBase64}
                  onSignatureBase64Update={setSignatureBase64}
                  isLoading={isSigning}
                />
              </Grid>
              <Grid item xs={12}>
                <Box display="flex" justifyContent="flex-end" alignItems="center">
                  <Button
                    onClick={handleClickSign}
                    variant="contained"
                    color="primary"
                    disabled={isSignDisabled}
                    fullWidth
                  >
                    <Box display="flex" alignItems="center" marginRight={2}>
                      <Box display="flex" alignItems="center" marginRight={1}>
                        {isSigning ? <CircularProgress color="inherit" /> : <BorderColorIcon />}
                      </Box>
                      {isSignComplete ? 'Done' : 'Sign'}
                    </Box>
                  </Button>
                </Box>
              </Grid>
            </>
          )}
        </>
      )}
    </Grid>
  );
};

AssignManifestToDriver.propTypes = {
  project: PropTypes.object,
};

export default AssignManifestToDriver;
