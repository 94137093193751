import React from 'react';
import { Projects } from './views/ProjectsView/Projects';
import { ProjectView } from './views/ProjectView';
import { Routes, Route } from 'react-router-dom';

export const ProjectsViews = () => {
  return (
    <Routes>
      <Route path="/" element={<Projects />} />
      <Route path="/project" element={<ProjectView />} />
      <Route path="/project/:projectId" element={<ProjectView />} />
    </Routes>
  );
};
