import React, { useState } from 'react';
import './ticketColumns.scss';
import { GridColDef } from '@mui/x-data-grid';
import moment from 'moment';
import TextField from '@mui/material/TextField';
import { ButtonOptionsMenu } from 'sharedComponents/ButtonOptionsMenu';
import { IJoinTruckingDispatch } from 'lg-helpers/dist/trucking/interfaces/IJoinTruckingDispatch';
import { ITruckingTicket } from 'lg-helpers/dist/trucking/interfaces/ITruckingTicket';

type TicketAndDispatch = ITruckingTicket & IJoinTruckingDispatch;

interface DescriptionCellProps {
  joinedDispatch: IJoinTruckingDispatch;
  updateDescription: (joinedDispatch: IJoinTruckingDispatch, description: string) => void;
}

const DescriptionCell = ({ joinedDispatch, updateDescription }: DescriptionCellProps) => {
  const [description, setDescription] = useState(joinedDispatch.descriptionTruckingTicket);

  return (
    <TextField
      fullWidth
      className="table-input"
      type="text"
      value={description || ''}
      variant="outlined"
      onChange={event => {
        event.preventDefault();
        event.stopPropagation();
        setDescription(event.target.value);
      }}
      onBlur={event => {
        if (joinedDispatch.descriptionTruckingTicket !== event.target.value) {
          updateDescription(joinedDispatch, event.target.value);
        }
      }}
    />
  );
};

export const generateTicketsColumns = (
  generateMenuOptions: (ticketAndDispatch: TicketAndDispatch) => any[],
  updateDescription: (joinedDispatch: IJoinTruckingDispatch, description: string) => void
) => {
  const ticketColumns = [] as any[];

  return [
    ...ticketColumns,
    {
      field: 'nameTruckingProject',
      headerName: `Project \u2014 Material`,
      width: 250,
      valueFormatter: data => {
        const row = data.api.getRow(data.id!);
        if (row.isManualTruckingDispatch) {
          return `${row.manualProjectNameTruckingDispatch} - ${row.manualMaterialNameTruckingDispatch}`;
        } else {
          return `${row.nameTruckingProject} - ${row.nameTruckingCompanyMaterial}`;
        }
      },
    },
    {
      field: 'contractorName',
      headerName: 'Contractor',
      width: 150,
      valueFormatter: data => {
        const row = data.api.getRow(data.id!);
        return `${row.name || row.manualContractorNameTruckingDispatch}`;
      },
    },
    {
      field: 'deliveredAtTruckingTicket',
      headerName: 'Time',
      width: 120,
      valueFormatter: ({ value }) => {
        return moment(value).toLocaleString();
      },
      renderCell: ({ row }) =>
        moment(row?.deliveredAtTruckingTicketWithTimezone)
          .tz(row.timeZone || 'EST')
          .format('LT'),
    },
    {
      field: 'ticketNumberTruckingTicket',
      headerName: 'Ticket No.',
      width: 120,
    },
    {
      field: 'pitTicketNumberTruckingTicket',
      headerName: 'Pit Ticket No.',
      width: 120,
    },
    {
      field: 'truckNumberTruckingTicket',
      headerName: 'Truck No.',
      width: 120,
    },
    {
      field: 'manifestNumberTruckingTicket',
      headerName: 'Manifest No.',
      width: 120,
    },
    {
      field: 'source supplier',
      headerName: 'Source Supplier',
      width: 150,
      valueFormatter: data => {
        const row = data.api.getRow(data.id!);
        return `${
          row.nameTruckingCompanySourceSupplier || row.manualMaterialSourceTruckingDispatch
        }`;
      },
    },
    {
      field: 'materialQuantityTruckingTicket',
      headerName: 'Loaded Qty',
      width: 120,
      valueFormatter: data => {
        const row = data.api.getRow(data.id!);
        return `${row.materialQuantityTruckingTicket} ${
          row.uomTruckingProjectMaterial || row.manualMaterialUomTruckingDispatch
        }`;
      },
    },
    {
      field: 'descriptionTruckingTicket',
      headerName: 'Description',
      width: 150,
      renderCell: ({ row }) => (
        <DescriptionCell joinedDispatch={row} updateDescription={updateDescription} />
      ),
    },
    {
      field: 'options',
      headerName: ' ',
      flex: 1,
      minWidth: 80,
      align: 'right',
      renderCell: ({ row }) => <ButtonOptionsMenu options={generateMenuOptions(row)} />,
    },
  ] as GridColDef[];
};
