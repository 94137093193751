import React from 'react';
import { ClickAwayListener, Grow, MenuItem, MenuList, Paper, Popper } from '@mui/material';
import { UserTypesArray } from 'lg-helpers/dist/constants/user/UserTypes';
import { useSelector } from 'react-redux';
import { selectAuthUser } from 'modules/auth/storeSliceAuth';

interface SwitcherDropdownProps {
  isOpen: boolean;
  anchorRef: any;
  onClose(evt: any): void;
  onSelect(userType: string): void;
}

const SwitcherDropdown = ({ isOpen, anchorRef, onClose, onSelect }: SwitcherDropdownProps) => {
  const authUser = useSelector(selectAuthUser);

  return (
    <Popper open={isOpen} anchorEl={anchorRef} role={undefined} transition disablePortal>
      {({ TransitionProps, placement }) => (
        <Grow
          {...TransitionProps}
          style={{
            transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
          }}
        >
          <Paper>
            <ClickAwayListener onClickAway={evt => onClose(evt)}>
              <MenuList id="split-button-menu" autoFocusItem>
                {authUser &&
                  (authUser.type === 'Super Admin'
                    ? UserTypesArray
                    : authUser.allowedUserTypes || []
                  ).map((userType: any, index: number) => (
                    <MenuItem
                      key={index}
                      selected={userType === authUser.actingAsType}
                      onClick={() => onSelect(userType)}
                    >
                      {userType}
                    </MenuItem>
                  ))}
              </MenuList>
            </ClickAwayListener>
          </Paper>
        </Grow>
      )}
    </Popper>
  );
};

export default SwitcherDropdown;
