import React, { useState, useMemo } from 'react';
import { SelectChangeEvent, MenuItem } from '@mui/material';
import { useSelector } from 'react-redux';
import { DataGrid } from '@mui/x-data-grid';
import { getCapacityVolumeByUnit } from 'modules/civilAndTrucking/trucking/utils';
import { FormTextField } from 'sharedComponents/FormTextField';
import { generateTrucksInDivisionColumns } from './trucksInDivisionColumns';
import { Loading } from 'sharedComponents/Loading';
import { selectDivisions } from 'modules/civilAndTrucking/trucking/features/Divisions/redux/divisionsStoreSlice';
import { selectJoinedTrucks } from 'modules/civilAndTrucking/trucking/features/Trucks/redux/trucksStoreSlice';
import { ILoadTruck } from '../AddLoadsToDispatchForm';
import { UnitTruckCapacity } from 'lg-helpers/dist/shared/types/global-types';

type IDispatchFormProps = {
  handleAddTruck: (truck: ILoadTruck) => void;
  unitOfMeasure: UnitTruckCapacity;
  addedTrucks: ILoadTruck[];
  getTrucksInDivisionId: (divisionId: string) => void;
};

export const TrucksInDivisionList = ({
  handleAddTruck,
  unitOfMeasure,
  addedTrucks,
  getTrucksInDivisionId,
}: IDispatchFormProps) => {
  const { divisions, divisionsStatus } = useSelector(selectDivisions);
  const { joinedTrucksByDivisions, joinedTrucksStatusByDivisions } =
    useSelector(selectJoinedTrucks);
  const [divisionId, setDivisionId] = useState('');

  const columns = useMemo(
    () => generateTrucksInDivisionColumns(handleAddTruck, unitOfMeasure, addedTrucks),
    [handleAddTruck, unitOfMeasure, addedTrucks]
  );

  const handleSelectDivision = (d: string) => {
    setDivisionId(d);
    getTrucksInDivisionId(d);
  };

  if (divisionsStatus === 'pending' || joinedTrucksStatusByDivisions === 'pending') {
    return <Loading />;
  }

  return (
    <>
      <FormTextField
        required
        name="divisionId"
        label="Division"
        value={divisionId}
        onChange={(e: SelectChangeEvent) => handleSelectDivision(e.target.value)}
        selectOptions={divisions.map(division => (
          <MenuItem value={division.idTruckingTruckDivision} key={division.idTruckingTruckDivision}>
            {division.nameTruckingTruckDivision}
          </MenuItem>
        ))}
      />
      <DataGrid
        columns={columns}
        rows={
          joinedTrucksByDivisions.filter(truck =>
            getCapacityVolumeByUnit(unitOfMeasure, truck.capacitiesTruckingTruckType)
          ) || []
        }
        getRowId={row => row.idTruckingTruck}
        showColumnRightBorder={false}
        disableColumnMenu
        disableSelectionOnClick
        hideFooterPagination
        autoHeight
        components={{
          ColumnResizeIcon: () => <div></div>,
        }}
      />
    </>
  );
};
