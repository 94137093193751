import React, { Fragment, useEffect } from 'react';
import 'sass/globals.scss';
import { Container, Box, Typography } from '@mui/material';
import EventNoteIcon from '@mui/icons-material/EventNote';
import Page from 'sharedComponents/Page';
import { useSelector } from 'react-redux';
import { selectAuthUser } from 'modules/auth/storeSliceAuth';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import ManifestsViewPerScale from 'sharedComponents/ManifestsViewPerScale';
import { getManifestDefaultColumns } from 'utils/manifestUtil';
import HtmlTooltip from 'sharedComponents/HtmlTooltip';

const noteColumn = {
  path: 'signatureScale.rejectionNote',
  label: 'Note',
  align: 'center',
  getCellValueCallback: row => {
    return (
      <HtmlTooltip
        placement="top"
        title={
          <Fragment>
            <Typography align="center">{row.signatureScale?.rejectionNote || 'N/A'}</Typography>
          </Fragment>
        }
      >
        <EventNoteIcon fontSize="medium" style={{ color: '#f44336', marginTop: 2 }} />
      </HtmlTooltip>
    );
  },
};

let manifestColumns = getManifestDefaultColumns(true, true, false);
manifestColumns = [...manifestColumns, noteColumn];

const IncomingManifestsView = () => {
  const authUser = useSelector(selectAuthUser);
  const navigate = useNavigate();

  useEffect(() => {
    if (!authUser) {
      return;
    }

    if (!authUser.selectedScaleId) {
      navigate('/scales/account');
    }
  }, [authUser, navigate]);

  return (
    <Page className="min-padding-and-height" title="Incoming Manifests">
      <Container>
        <Box display="flex" justifyContent="center" alignItems="center" marginBottom={4}>
          <Typography variant="h3">Rejected Manifests</Typography>
        </Box>
        {authUser && authUser.selectedScaleId && (
          <ManifestsViewPerScale
            manifestColumns={manifestColumns}
            showOnlyRejected={true}
            authUser={authUser}
            showOnlyIncoming={true}
            scaleId={authUser.selectedScaleId}
            defaultFilterIsSigned={true}
            defaultFilterIsUsedBetween={[
              moment().subtract(1, 'days').startOf('day').toDate(),
              moment().endOf('day').toDate(),
            ]}
          />
        )}
      </Container>
    </Page>
  );
};

export default IncomingManifestsView;
