import { createReducer } from '@reduxjs/toolkit';
import { INotification } from 'modules/civilAndTrucking/shared/NotificationSnackbar/INotification';
import { pushNotification, popNotification } from './actions';

const initialState: INotification[] = [];

export const notificationsReducer = createReducer(initialState, builder => {
  builder.addCase(pushNotification, (state: INotification[], action) => {
    state.push(action.payload as INotification);
  });
  builder.addCase(popNotification, state => {
    state.shift();
  });
});
