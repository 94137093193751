import React, { useState, useEffect } from 'react';
import { Grid, TextField, Button } from '@mui/material';
import { Add, Remove } from '@mui/icons-material';
import { hasErrorRequired } from 'services/logic/formValidation';
import ErrorMessages from 'services/constants/errorMessages';
import { CustomInputListItem } from 'lg-helpers/dist/shared/types/custom-inputs';
const { isRequired } = ErrorMessages;

export interface CheckboxItemDefinitionProps {
  listItem: CustomInputListItem;
  onSetListItem?: (item: CustomInputListItem) => any;
  onAddListItem?: () => any;
  onRemoveListItem?: (id: string) => any;
  canValidate: boolean;
  showRemoveButton: boolean;
  showAddButton: boolean;
  userCanEdit?: boolean;
}

const CheckboxItemDefinition = ({
  listItem,
  onSetListItem,
  onAddListItem,
  onRemoveListItem,
  canValidate,
  showRemoveButton,
  showAddButton,
  userCanEdit = true,
}: CheckboxItemDefinitionProps) => {
  const [localListItem, setLocalListItem] = useState<CustomInputListItem>(listItem);

  useEffect(() => {
    setLocalListItem(listItem);
  }, [listItem]);

  // useEffect(() => {
  //   if (!localListItem) {
  //     setLocalListItem({});
  //   }
  // }, [localListItem]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const changedLocalListItem = {
      ...localListItem,
      [event.target.name]: event.target.value,
    };

    setLocalListItem(changedLocalListItem);
  };

  const handleBlur = () => {
    onSetListItem && onSetListItem(localListItem);
  };

  const handleAddNewListItem = () => {
    onAddListItem && onAddListItem();
  };

  return (
    <Grid container spacing={2}>
      <Grid item sm={5} xs={12}>
        <TextField
          disabled={!userCanEdit}
          fullWidth
          label="Label"
          name="label"
          onChange={handleChange}
          onBlur={handleBlur}
          value={localListItem.label || ''}
          variant="outlined"
          error={hasErrorRequired(localListItem.label, canValidate)}
          helperText={hasErrorRequired(localListItem.label, canValidate) ? isRequired : ''}
          required
        />
      </Grid>
      <Grid item sm={5} xs={12}>
        <TextField
          disabled={!userCanEdit}
          fullWidth
          label="Value"
          name="value"
          onChange={handleChange}
          onBlur={handleBlur}
          value={localListItem.value || ''}
          variant="outlined"
          error={hasErrorRequired(localListItem.value, canValidate)}
          helperText={hasErrorRequired(localListItem.value, canValidate) ? isRequired : ''}
          required
        />
      </Grid>
      <Grid item sm={1} xs={12} display="flex">
        {showRemoveButton && (
          <Button
            disabled={!userCanEdit}
            variant="text"
            color="secondary"
            onClick={() => {
              onRemoveListItem && localListItem.id && onRemoveListItem(localListItem.id);
            }}
          >
            <Remove style={{ cursor: 'pointer' }} color="inherit" />
          </Button>
        )}
        {showAddButton && (
          <Button
            variant="text"
            color="primary"
            onClick={handleAddNewListItem}
            disabled={!userCanEdit}
          >
            <Add style={{ cursor: 'pointer' }} color="inherit" />
          </Button>
        )}
      </Grid>
    </Grid>
  );
};

export default CheckboxItemDefinition;
