import { number, object, string, array } from 'yup';
import { UserTypeGenerated } from 'modules/auth/logicDependentOnUserRole';
import { IJoinCompany } from 'lg-helpers/dist/shared/interfaces/IJoinCompany';

const requiredMessage = 'Required';

export const companyDetailsSchema = object<IJoinCompany>({
  name: string().required(requiredMessage),
  addressOne: string().required(requiredMessage),
  addressTwo: string().nullable(),
  city: string().required(requiredMessage),
  state: string().required(requiredMessage),
  postalCode: string().required(requiredMessage),
  email: string().required(requiredMessage),
  phone: string().required(requiredMessage),
  nextAutoTicketNumber: number().required(requiredMessage).positive('Must be a positive number'),
  types: array<UserTypeGenerated>().required(requiredMessage).min(1, requiredMessage),
});
