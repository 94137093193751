import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { thunkDispatch } from 'store/store';
import { getJoinedDispatchByDispatchId } from 'modules/civilAndTrucking/trucking/features/Dispatches/redux/actions';
import { getBrokerLoadsByDispatchId } from 'modules/civilAndTrucking/trucking/features/Loads/redux/actions';
import { DispatchToolbar } from 'modules/civilAndTrucking/trucking/features/Dispatches/views/DispatchView/components/DispatchToolbar';
import { DispatchTicketsList } from 'modules/civilAndTrucking/trucking/features/Dispatches/views/DispatchView/components/DispatchTicketsList';
import { getTicketsByDispatchId } from 'modules/civilAndTrucking/trucking/features/Tickets/redux/actions';
import { ITruckingLoad } from 'lg-helpers/dist/trucking/interfaces/ITruckingLoad';
import {
  upsertLoad,
  deleteLoad,
} from 'modules/civilAndTrucking/trucking/features/Loads/redux/actions';
import { BrokerLoadsList } from './BrokerLoadsList';
import { getJoinedTrucksByCompanyId } from 'modules/civilAndTrucking/trucking/features/Trucks/redux/actions';

export const BrokerDispatchView = () => {
  let { dispatchId } = useParams();
  const [toggleIndex, setToggleIndex] = useState<0 | 1>(0);

  useEffect(() => {
    if (!dispatchId) return;
    thunkDispatch(getBrokerLoadsByDispatchId(Number(dispatchId)));
    thunkDispatch(getTicketsByDispatchId(Number(dispatchId)));
    thunkDispatch(getJoinedDispatchByDispatchId(Number(dispatchId)));
    thunkDispatch(getJoinedTrucksByCompanyId());
  }, [dispatchId]);

  const handleUpdateLoad = async (
    loadId: number,
    loadRepeat?: boolean,
    lastLoad?: boolean,
    truckNumber?: string
  ) => {
    if (!dispatchId) return;

    const upsertLoadReq = {
      idTruckingLoad: loadId,
      ...(loadRepeat !== undefined && { canRepeatTruckingLoad: loadRepeat }),
      ...(lastLoad !== undefined && { isLastLoad: lastLoad }),
      ...(truckNumber !== undefined && {
        truckNumberTruckingLoad: truckNumber,
      }),
    } as Partial<ITruckingLoad>;

    await thunkDispatch(upsertLoad(upsertLoadReq));
    thunkDispatch(getBrokerLoadsByDispatchId(Number(dispatchId)));
  };

  const handleDeleteLoad = async (loadId: number) => {
    if (!dispatchId) return;
    await thunkDispatch(deleteLoad(loadId));
    thunkDispatch(getBrokerLoadsByDispatchId(Number(dispatchId)));
  };

  return (
    <>
      <DispatchToolbar toggleIndex={toggleIndex} setToggleIndex={setToggleIndex} />
      {toggleIndex ? (
        <DispatchTicketsList />
      ) : (
        <BrokerLoadsList handleUpdateLoad={handleUpdateLoad} handleDeleteLoad={handleDeleteLoad} />
      )}
    </>
  );
};
