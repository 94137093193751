import { createTheme } from '@mui/material/styles';
// todo: https://mui.com/material-ui/customization/palette/#adding-new-colors

const theme = createTheme({
  components: {
    MuiTextField: {
      defaultProps: {
        margin: 'normal',
      },
    },
    MuiSwitch: {
      defaultProps: {
        color: 'secondary',
      },
    },
  },
  palette: {
    // common: {},
    error: {
      light: 'rgba(162,0,18, 0.7)',
      main: 'rgba(162,0,18,0.85)',
      dark: 'rgba(162,0,18, 1)',
    },
    // info: {},
    primary: {
      light: 'rgba(65, 121, 58, .8)',
      main: 'rgba(65, 121, 58, 1)',
      dark: 'rgba(50, 101, 44, 1)',
    },
    secondary: {
      light: 'rgba(109, 153, 56, .7)',
      main: 'rgba(109, 153, 56, .85)',
      dark: 'rgba(109, 153, 56, 1)',
    },
    success: {
      light: 'rgba(140, 214, 50, .7)',
      main: 'rgba(140, 214, 50, .85)',
      dark: 'rgba(140, 214, 50, 1)',
    },
    // warning: {}
  },
});

export default theme;
