import React, { useState } from 'react';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  InputLabel,
  TextField,
} from '@mui/material';
import DialogActionContainer from 'sharedComponents/DialogActionContainer';

interface CivilCalculatorProps {
  isOpen: boolean;
  setShowCalculator(open: boolean): void;
  unit: string;
  grossQuantity?: number | null;
  tareQuantity?: number | null;
  setQuantity: (grossQuantity: number, tareQuantity: number, quantity: number) => void;
}

const calculatePoundsToUnit = (unit: string, quantity?: number) => {
  if (!quantity) {
    return 0;
  } else if (unit === 'Tons') {
    return quantity / 2000;
  } else if (unit === 'Gross Tons') {
    return quantity / 2240;
  } else {
    return quantity;
  }
};

const calculateUnitToPounds = (unit: string, quantity?: number) => {
  if (!quantity) {
    return 0;
  } else if (unit === 'Tons') {
    return quantity * 2000;
  } else if (unit === 'Gross Tons') {
    return quantity * 2240;
  } else {
    return quantity;
  }
};

const CivilWeightCalculatorModal = ({
  isOpen,
  setShowCalculator,
  unit,
  grossQuantity,
  tareQuantity,
  setQuantity,
}: CivilCalculatorProps) => {
  const [grossWeight, setGrossWeight] = useState<number>(
    calculateUnitToPounds(unit, grossQuantity || 0)
  );
  const [tareWeight, setTareWeight] = useState<number>(
    calculateUnitToPounds(unit, tareQuantity || 0)
  );

  const netWeight: number = (grossWeight || 0) - (tareWeight! || 0);

  const handleCalculateAndSave = () => {
    setQuantity(
      calculatePoundsToUnit(unit, grossWeight || 0),
      calculatePoundsToUnit(unit, tareWeight || 0),
      calculatePoundsToUnit(unit, netWeight || 0)
    );
    setShowCalculator(false);
  };

  return (
    <Dialog fullWidth open={isOpen} onClose={() => setShowCalculator(false)}>
      <DialogTitle>Calculate quantity in {unit || 'No Unit'}</DialogTitle>
      <DialogContent>
        <Grid container spacing={2} sx={{ mt: 2 }}>
          <Grid item xs={12}>
            <FormControl fullWidth>
              <InputLabel shrink htmlFor="grossWeight">
                Gross Weights (lbs)
              </InputLabel>
              <TextField
                type="number"
                name="grossWeight"
                value={grossWeight || ''}
                onChange={e => setGrossWeight(Number(e.target.value))}
                variant="outlined"
                inputProps={{
                  inputMode: 'numeric',
                  pattern: '[0-9]*',
                }}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <FormControl fullWidth>
              <InputLabel shrink htmlFor="tareWeight">
                Tare Weight (lbs)
              </InputLabel>
              <TextField
                type="number"
                name="tareWeight"
                value={tareWeight || ''}
                onChange={e => setTareWeight(Number(e.target.value))}
                variant="outlined"
              />
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <FormControl fullWidth>
              <InputLabel shrink htmlFor="netWeight">
                Net Weight ({unit})
              </InputLabel>
              <TextField
                type="number"
                name="netWeight"
                value={calculatePoundsToUnit(unit, netWeight || 0)}
                disabled
                variant="outlined"
              />
            </FormControl>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActionContainer
        saveHandler={handleCalculateAndSave}
        saveText={'Save'}
        cancelHandler={() => setShowCalculator(false)}
      />
    </Dialog>
  );
};

export default CivilWeightCalculatorModal;
