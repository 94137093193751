import React, { useEffect, useState } from 'react';
import 'moment-timezone';

import api, { WorkCodes } from 'services/api/autogenerated';
import { Box, Grid, IconButton, SvgIcon, TextField } from '@mui/material';
import { Delete, Edit } from '@mui/icons-material';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import moment from 'moment';
import { workCodeFormattedDate } from './CancelWorkCode';
import DeleteConfirmationModal from './DeleteWorkCodeModal';
import { Refresh } from '../../civilAndTrucking/shared/CustomIcons';

interface WorkCodeViewProps {
  workCodes: WorkCodes[];
  refreshWorkCodes: () => void;
  setSelectedWorkCode: (workCode: WorkCodes) => void;
}

const WorkCodeList: React.FC<WorkCodeViewProps> = ({
  workCodes,
  refreshWorkCodes,
  setSelectedWorkCode,
}) => {
  const cancelWorkCode = async (workCodeId: string) => {
    await api.workCode.cancelWorkCodeRequest({ workCodeId });
    refreshWorkCodes();
  };

  const selectWorkCode = (workCode: WorkCodes) => {
    setSelectedWorkCode(workCode);
  };

  // Check if any work code has a non-null nickname
  const hasNickname = workCodes.some(workCode => workCode.nickname);

  // make work code id big and bold
  const columns: GridColDef[] = [
    {
      field: 'work_code_id',
      headerName: 'Work Code #',
      flex: 1,
      renderCell: (params: any) => {
        return <h4>{params.row.work_code_id}</h4>;
      },
    },
    {
      field: 'drivers',
      headerName: 'Active Drivers',
      flex: 1,
      renderCell: (params: any) => {
        return `${params.row?.drivers?.length} Active Drivers`;
      },
    },
    {
      field: 'expiration_date',
      headerName: 'Expiration Date',
      flex: 2,
      renderCell: (params: any) => {
        return `${workCodeFormattedDate(params.row.expiration_date)} (${moment(
          params.row.expiration_date
        ).fromNow()})`;
      },
    },
    {
      field: 'actions',
      headerName: 'Actions',
      flex: 1,
      renderCell: (params: any) => {
        const handleDelete = () => {
          const confirmDelete = window.confirm(
            `Are you sure you want to delete ${params.row.work_code_id}?
            \nThis code is connected to ${params.row?.drivers?.length} active drivers.`
          );
          if (confirmDelete) {
            cancelWorkCode(params.row.work_code_id);
          }
        };

        return (
          <>
            <Grid item>
              <IconButton
                sx={{ marginRight: '5px' }}
                onClick={() => {
                  setSelectedWorkCode(params.row);
                }}
              >
                <SvgIcon sx={{ fontSize: 24, marginRight: '-2px', marginBottom: '-2px' }}>
                  <Edit color="secondary" />
                </SvgIcon>
              </IconButton>
              <IconButton sx={{ marginRight: '5px' }} onClick={handleDelete}>
                <SvgIcon sx={{ fontSize: 24, marginRight: '-2px', marginBottom: '-2px' }}>
                  <Delete color="secondary" />
                </SvgIcon>
              </IconButton>
            </Grid>
          </>
        );
      },
    },
  ];

  // Conditionally add the nickname column if any work code has a nickname
  if (hasNickname) {
    columns.splice(0, 0, {
      field: 'nickname',
      headerName: 'Nickname',
      flex: 2,
      renderCell: (params: any) => {
        return params.row.nickname || '';
      },
    });
  }

  return (
    <Grid container>
      <DataGrid
        getRowId={row => row.work_code_id}
        rows={workCodes}
        columns={columns}
        pageSize={25}
        style={{ minHeight: 450, height: '100%' }}
      />
    </Grid>
  );
};

export default WorkCodeList;
