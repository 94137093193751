import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Box from '@mui/material/Box';
import emailRegex from 'email-regex';

import ButtonWithLoading from 'sharedComponents/ButtonWithLoading';
import useShowError from 'modules/errors';

const EmailDialog = ({ open, handleClose, handleSubmit }) => {
  const [emailsValue, setEmailsValue] = useState('');
  const [isCopyToMe, setIsCopyToMe] = useState(false);
  const [errorMsg, setErrorMsg] = useState('');
  const showError = useShowError();

  useEffect(() => {
    if (!open) {
      initForm();
    }
  }, [open]);

  const initForm = () => {
    setErrorMsg('');
    setEmailsValue('');
    setIsCopyToMe(false);
  };

  const handleEmailsValueChange = value => {
    setEmailsValue(value);
  };

  const handleSend = async () => {
    if (!emailsValue && !isCopyToMe) {
      handleClose();
      return;
    }

    const emails = emailsValue ? emailsValue.split(';') : [];

    if (emails.length > 0) {
      for (const email of emails) {
        if (!emailRegex({ exact: true }).test(email)) {
          setErrorMsg('There is an invalid email.');
          return;
        }
      }
    }

    try {
      await handleSubmit({ emails, isCopyToMe });
      handleClose();
    } catch (err) {
      let text = '';
      if (err && err.response && err.response.data && err.response.data.message) {
        text = err.response.data.message;
      }

      showError({ title: 'Error sending email!', text });
    }
  };

  return (
    <Dialog onClose={handleClose} open={open}>
      <DialogTitle>Send to Email Address</DialogTitle>
      <DialogContent dividers={true}>
        <Grid container>
          <Grid item xs={12}>
            <TextField
              label="Emails"
              fullWidth
              type="email"
              multiple
              value={emailsValue}
              error={!!errorMsg}
              helperText={errorMsg}
              onChange={event => handleEmailsValueChange(event.target.value)}
            />
          </Grid>
          <Grid item xs={12} mt={3}>
            <Box display="flex" justifyContent="space-between">
              <FormControlLabel
                control={
                  <Checkbox
                    color="success"
                    checked={isCopyToMe}
                    onChange={event => setIsCopyToMe(event.target.checked)}
                  />
                }
                label="Send copy to me"
              />
              <ButtonWithLoading
                text="Send"
                variant="contained"
                color="primary"
                onClickCallback={handleSend}
                isLarge={false}
                notFullWith
              />
            </Box>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

EmailDialog.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func,
  handleSubmit: PropTypes.func,
};

export default EmailDialog;
