/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useCallback } from 'react';
import {
  Box,
  Button,
  Typography,
  Grid,
  Container,
  CircularProgress,
  Avatar,
  TextField,
  Select,
  MenuItem,
} from '@mui/material';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import Page from 'sharedComponents/Page';
import Notes from 'sharedComponents/Notes';
import { useNavigate } from 'react-router-dom';
import useGetDriverCurrentManifest from 'services/hooks/useGetDriverCurrentManifest';
import useWatchManifestIfSignedByScaleReleaseDriver from 'services/hooks/useWatchManifestIfSignedByScaleReleaseDriver';
import useIsUserOfType from 'services/hooks/useIsUserOfType';
import Command from 'services/firebase/Command';
import Executer from 'services/firebase/Executer';
import Query from 'services/firebase/Query';
import useShowError from 'modules/errors';
import PleaseWaitScreen from './PleaseWaitScreen';
import './SelectScaleLaneView.scss';
import QueryBase from 'lg-helpers/dist/firestore/query/QueryBase';

const command = new Command();
const executer = new Executer();
const query = new Query();

// const useStyles = makeStyles(theme => ({
//   root: {
//     minHeight: '100%',
//     paddingBottom: theme.spacing(0),
//     paddingTop: theme.spacing(0),
//   },
//   trafficLights: {
//     width: '220px',
//   },
//   welcomeMessage: {
//     borderRadius: 3,
//     border: 0,
//     padding: '30px',
//     fontWeight: 'bold',
//     textAlign: 'center',
//   },
//   lanesWrapper: {
//     flex: 1,
//     display: 'flex',
//     justifyContent: 'space-between',
//   },
//   lane: {
//     display: 'flex',
//     flexDirection: 'column',
//     texAlign: 'center',
//     alignItems: 'center',
//     '-webkit-text-stroke': '0.5px black',
//   },
//   laneImage: {
//     maxWidth: '100%',
//     maxHeight: '300px',
//   },
//   avatar: {
//     marginTop: 20,
//   },
// }));

const useGetScale = scaleId => {
  const [scale, setScale] = useState(null);

  useEffect(() => {
    const findScale = async () => {
      try {
        const scaleResponse = await executer.getSingleDocument(
          query.base.getById(QueryBase.SCALES_COLLECTION(), scaleId)
        );
        setScale(scaleResponse);
      } catch (e) {
        console.error('Error loading scale: ', e);
      }
    };

    if (scaleId) {
      findScale(scaleId);
    }
  }, [scaleId]);

  return [scale];
};

const SelectScaleLaneView = () => {
  const { isLoadingCurrentManifest, currentManifest } = useGetDriverCurrentManifest();
  useWatchManifestIfSignedByScaleReleaseDriver();
  const isUserBrokerDriverOrAdmin = useIsUserOfType('trucking_driver', 'trucking_dispatcher');
  const [selectedLane, setSelectedLane] = useState(null);
  const showError = useShowError();
  const [isNotesOpen, setIsNotesOpen] = useState(false);
  const [scaleId, setScaleId] = useState(undefined);
  const [selectedScale, setSelectedScale] = useState(scaleId);
  const [scale] = useGetScale(scaleId);
  const [passcode, setPasscode] = useState('');
  const [isReleasing, setIsReleasing] = useState(false);
  const navigate = useNavigate();

  const handleClickBack = useCallback(() => {
    navigate('/drivers/current-manifest');
  }, [navigate]);

  useEffect(() => {
    if (isUserBrokerDriverOrAdmin || isLoadingCurrentManifest) {
      return;
    }

    if (!currentManifest) {
      return navigate('/drivers/projects');
    }
  }, [isUserBrokerDriverOrAdmin, currentManifest, isLoadingCurrentManifest, navigate]);

  useEffect(() => {
    if (isLoadingCurrentManifest) {
      return;
    }

    if (
      !currentManifest ||
      !currentManifest.signatureDriver ||
      !currentManifest.signatureDriver.scale
    ) {
      handleClickBack();
      return;
    }

    if (currentManifest.signatureScale.isSigned) {
      setIsNotesOpen(false);
    }

    setScaleId(currentManifest.signatureDriver.scale.id);
    setSelectedScale(currentManifest.signatureDriver.scale.id);
  }, [isLoadingCurrentManifest, currentManifest, handleClickBack]);

  useEffect(() => {
    if (scaleId !== selectedScale) {
      setScaleId(selectedScale);
    }
  }, [selectedScale, scaleId]);

  useEffect(() => {
    if (!currentManifest || !scale) {
      return;
    }

    if (!scale.lanes) {
      console.log('NO LANES! for ', scale);
      return;
    }

    if (!currentManifest.signatureDriver || !currentManifest.signatureDriver.lane) {
      return;
    }

    const { lane } = currentManifest.signatureDriver;
    if (lane && lane.id) {
      setSelectedLane(scale.lanes.find(l => l.id === lane.id));
    }
  }, [currentManifest, scale]);

  const onLaneClick = async lane => {
    try {
      await command.manifests.setManifestLane(currentManifest.id, lane.id);
      setSelectedLane(lane);
    } catch (err) {
      showError({ title: 'Error set manifest lane' });
    }
  };

  const handleCloseNotes = () => {
    setIsNotesOpen(false);
  };

  const handleClickManualRelease = async () => {
    if (!passcode) {
      showError({ title: 'Passcode required' });
      return;
    }
    if (passcode.length < 4) {
      showError({ title: 'Minimun 4 digits' });
      return;
    }
    if (passcode !== scale?.offHoursPasscode) {
      showError({ title: 'Passcode incorrect!' });
      return;
    }

    try {
      // manual release
      setIsReleasing(true);
      await command.manifests.releaseManifestManually(currentManifest.id, passcode);
      setIsReleasing(false);
      navigate('/drivers/go');
    } catch (err) {
      console.error(err);
      showError({ title: 'Error release manifest' });
      setIsReleasing(false);
    }
  };

  const changeSelectedScale = async selectedScaleId => {
    const scaleToUpdate = currentManifest.project.scales.find(s => s.id === selectedScaleId);
    const updatedSignatureDriver = {
      ...currentManifest?.signatureDriver,
      scale: scaleToUpdate,
    };
    await command.update('manifests', currentManifest.id, {
      signatureDriver: updatedSignatureDriver,
    });
    setSelectedScale(selectedScaleId);
  };

  const handleClickBackToScales = async () => {
    try {
      await command.manifests.setManifestLane(currentManifest.id, null);
      setSelectedLane(null);
    } catch (err) {
      showError({ title: 'Error set manifest lane' });
    }
  };

  return (
    <Page title="Scale Lanes">
      <Container>
        <Grid container>
          {!selectedLane ||
          (currentManifest &&
            (!currentManifest.signatureDriver ||
              !currentManifest.signatureDriver.lane ||
              !currentManifest.signatureDriver.lane.id)) ? (
            <>
              {!isLoadingCurrentManifest ? (
                <Grid item xs={12}>
                  <Box marginTop={1} marginBottom={1} display="flex" alignItems="center">
                    <Button onClick={handleClickBack} variant="outlined">
                      <Typography variant="h4">
                        <Box display="flex" alignItems="center">
                          <NavigateBeforeIcon fontSize="small" /> Back
                        </Box>
                      </Typography>
                    </Button>
                  </Box>
                </Grid>
              ) : null}
              <Grid item xs={12}>
                {isLoadingCurrentManifest ? (
                  <Box display="flex" justifyContent="center" alignItems="center" marginTop={2}>
                    <CircularProgress color="inherit" />
                  </Box>
                ) : (
                  <Box display="flex" flexDirection="column" justifyContent="center">
                    {scale ? (
                      <>
                        <Box
                          marginTop={3}
                          marginBottom={3}
                          display="flex"
                          justifyContent="center"
                          alignItems="center"
                        >
                          <Grid item xs={6}>
                            <Select
                              labelId="scale-label"
                              value={selectedScale}
                              fullWidth
                              variant="outlined"
                              onChange={e => changeSelectedScale(e.target.value)}
                            >
                              {currentManifest?.project?.scales.map(scaleItem => (
                                <MenuItem key={scaleItem.id} value={scaleItem.id}>
                                  {scaleItem.name}
                                </MenuItem>
                              ))}
                            </Select>
                          </Grid>
                        </Box>
                        <div className="lanesWrapper">
                          {scale.lanes.map(lane => (
                            <div className="lane" key={lane.id}>
                              {lane.type === 'lane' ? (
                                <img
                                  className="laneImage"
                                  src={`/static/images/lanes/lane-${lane.color}.png`}
                                  alt={`Lane ${lane.id}`}
                                  onClick={() => onLaneClick(lane)}
                                />
                              ) : (
                                <img
                                  className="laneImage"
                                  src={`/static/images/lanes/scale.png`}
                                  alt={'Scale'}
                                />
                              )}

                              {lane.type === 'lane' ? (
                                <Avatar className="avatar" style={{ backgroundColor: lane.color }}>
                                  <Typography variant="h2" className="laneNumber">
                                    {lane.label}
                                  </Typography>
                                </Avatar>
                              ) : (
                                <Typography variant="h2" className="laneNumber">
                                  Scale
                                </Typography>
                              )}
                            </div>
                          ))}
                        </div>
                        <Box
                          marginTop={3}
                          display="flex"
                          justifyContent="center"
                          alignItems="center"
                        >
                          <Typography variant="h4" className="welcomeMessage">
                            Please select your lane
                            <br />
                            Seleccione carril
                          </Typography>
                        </Box>
                        {scale.isOffHours && (
                          <Box
                            marginTop={3}
                            display="flex"
                            justifyContent="flex-end"
                            alignItems="center"
                          >
                            <Box
                              padding={3}
                              border="1px solid red"
                              borderRadius={4}
                              marginBottom={3}
                              display="flex"
                              justifyContent="center"
                              alignItems="flex-start"
                            >
                              <TextField
                                name="passcode"
                                label="Passcode"
                                variant="outlined"
                                value={passcode}
                                onChange={e => {
                                  setPasscode(e.target.value);
                                }}
                              />
                              <Button
                                onClick={handleClickManualRelease}
                                variant="contained"
                                color="primary"
                                style={{ marginLeft: 24 }}
                                disabled={isReleasing}
                              >
                                {isReleasing && <CircularProgress color="inherit" />}
                                <Typography variant="h4">Manual Release</Typography>
                              </Button>
                            </Box>
                          </Box>
                        )}
                      </>
                    ) : undefined}
                  </Box>
                )}
              </Grid>
            </>
          ) : (
            <PleaseWaitScreen
              currentManifest={currentManifest}
              selectedLane={selectedLane}
              onHandleClickNotes={() => setIsNotesOpen(true)}
              onClickBackToScales={() => handleClickBackToScales()}
            />
          )}
        </Grid>
      </Container>
      {currentManifest ? (
        <Notes
          manifest={currentManifest}
          isNotesOpen={isNotesOpen}
          handleCloseNotes={handleCloseNotes}
        />
      ) : null}
    </Page>
  );
};

export default SelectScaleLaneView;
