import React, { Fragment } from 'react';
import moment from 'moment';
import 'moment-timezone';
import HtmlTooltip from './HtmlTooltip';
import { Typography } from '@mui/material';
import './SignatureTooltip.scss';

const dateFormat = 'MM/DD/YYYY hh:mm:ss';

const getSignDate = (manifest, signer) => {
  try {
    let signedAt =
      !!manifest[signer].isSigned && !!manifest[signer].signedAt
        ? manifest[signer].signedAt.toDate()
        : false;

    if (signedAt) {
      signedAt = moment.tz(signedAt, manifest.project.timeZone || 'EST');
    }

    return `${signedAt.format(dateFormat)} ${signedAt.zoneAbbr()}`;
  } catch {
    return null;
  }
};

const getSignerName = (manifest, pathSigner, pathSignerName) => {
  try {
    return manifest[pathSigner][pathSignerName];
  } catch {
    return null;
  }
};

const SignatureTooltip = ({ manifest, path, children, customMessage }) => {
  if (!path) return children;

  const [pathSigner, pathSignerName] = (path || []).split('.');

  const signedAt = getSignDate(manifest, pathSigner);
  const name = getSignerName(manifest, pathSigner, pathSignerName);
  return (
    <HtmlTooltip
      placement="top"
      wrapperClass={`signature-check signature-check__${pathSigner}`}
      title={
        <Fragment>
          <Typography align="center">{name || ''}</Typography>
          <Typography align="center">{signedAt || ''}</Typography>
          {customMessage ? <Typography align="center">{customMessage}</Typography> : ''}
        </Fragment>
      }
    >
      {children}
    </HtmlTooltip>
  );
};

export default SignatureTooltip;
