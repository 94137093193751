import React from 'react';
import { createSvgIcon } from '@mui/material';

export const SmallTruck = createSvgIcon(
  <path
    fillRule="evenodd"
    clipRule="evenodd"
    d="M0 1.5c0-.55229.44771-1 1-1h14.5c.5523 0 1 .44771 1 1V4h3.2857c.3133 0 .6086.14687.7976.39679l3.2143 4.25c.1313.17367.2024.38546.2024.60321v4.25c0 .5523-.4477 1-1 1h-2.6707c-.4119 1.1652-1.5231 2-2.8293 2s-2.4174-.8348-2.8293-2H8.82929c-.41184 1.1652-1.52309 2-2.82929 2-1.30622 0-2.41745-.8348-2.82929-2H1c-.55229 0-1-.4477-1-1v-12Zm3.17071 11c.41184-1.1652 1.52307-2 2.82929-2 1.3062 0 2.41745.8348 2.82929 2H14.5v-10H2v10h1.17071ZM16.5 10.6707V6h2.7882L22 9.58557V12.5h-1.6707c-.4119-1.1652-1.5231-2-2.8293-2-.3506 0-.6872.0602-1 .1707ZM6 12.5c-.55228 0-1 .4477-1 1s.44772 1 1 1c.55227 0 1-.4477 1-1s-.44773-1-1-1Zm11.5 0c-.5523 0-1 .4477-1 1s.4477 1 1 1 1-.4477 1-1-.4477-1-1-1Z"
  />,
  'Small Truck'
);
