import { Dialog } from '@mui/material';
import React, { useContext } from 'react';
import { ModalContext } from './ModalContext';

type TProps = React.PropsWithChildren<{
  width?: TWidth;
}>;

type TWidth = 'lg' | 'md' | 'sm' | 'xs' | 'xl' | false | undefined;

const Modal = (props: TProps) => {
  const { modal, modalContent } = useContext(ModalContext);

  return (
    <Dialog disableEnforceFocus open={modal} fullWidth maxWidth={props.width ? props.width : 'lg'}>
      {props.children}
      {modalContent}
    </Dialog>
  );
};

export default Modal;
