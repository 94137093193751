import React from 'react';
import './pictograms.scss';

const LivegisticsLogoLarge = () => (
  <div className="Logo__large">
    <img className="Logo__large--image" alt="Logo" src="/static/images/logo-large.png" />
  </div>
);

export default LivegisticsLogoLarge;
