import { createSlice } from '@reduxjs/toolkit';
import { getJoinedProjectMaterialsByProjectId, getProjectMaterialsByProjectId } from './actions';
import { RootState } from 'store/store';
import { TFormStatus } from 'lg-helpers/dist/shared/types/TFormStatus';
import { IJoinProjectMaterial } from 'lg-helpers/dist/shared/interfaces/IJoinProjectMaterial';
import { ITruckingProjectMaterial } from 'lg-helpers/dist/trucking/interfaces/ITruckingProjectMaterial';

export interface IProjectMaterialsState {
  joinedMaterials: IJoinProjectMaterial[];
  materials: ITruckingProjectMaterial[];
  status: TFormStatus;
}
const initialProjectMaterialsState: IProjectMaterialsState = {
  joinedMaterials: [],
  materials: [],
  status: null,
};

export const projectMaterialsSlice = createSlice({
  name: 'projectMaterials',
  reducers: {},
  initialState: initialProjectMaterialsState,
  extraReducers: builder => {
    builder
      .addCase(getJoinedProjectMaterialsByProjectId.pending, state => {
        state.status = 'pending';
      })
      .addCase(getJoinedProjectMaterialsByProjectId.fulfilled, (state, { payload }) => {
        state.status = 'success';
        state.joinedMaterials = payload as IJoinProjectMaterial[];
      })
      .addCase(getJoinedProjectMaterialsByProjectId.rejected, (state, { error }) => {
        state.status = 'failure';
        state.joinedMaterials = [] as IJoinProjectMaterial[];
        console.error('getJoinedProjectMaterialsByProjectId error: ', error);
      })
      .addCase(getProjectMaterialsByProjectId.pending, state => {
        state.status = 'pending';
      })
      .addCase(getProjectMaterialsByProjectId.fulfilled, (state, { payload }) => {
        state.status = 'success';
        state.materials = payload as ITruckingProjectMaterial[];
      })
      .addCase(getProjectMaterialsByProjectId.rejected, (state, { error }) => {
        state.status = 'failure';
        state.materials = [] as ITruckingProjectMaterial[];
        console.error('getProjectMaterialsByProjectId error: ', error);
      });
  },
});

export const selectJoinedProjectMaterials = (state: RootState) => {
  return {
    joinedProjectMaterials: state.trucking.projectMaterials.joinedMaterials,
    joinedProjectMaterialsStatus: state.trucking.projectMaterials.status,
  };
};

export const selectProjectMaterials = (state: RootState) => {
  return {
    projectMaterials: state.trucking.projectMaterials.materials,
    projectMaterialsStatus: state.trucking.projectMaterials.status,
  };
};
