import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';
import firebase from 'firebase/compat/app';
import { storage } from 'services/firebase';
// eslint-disable-next-line no-undef
axios.defaults.baseURL = process.env.REACT_APP_API_BASE_URL;
const staticApiBaseUrl = process.env.REACT_APP_STATIC_API_BASE_URL;

export const callV1ApiLoadCompletedCallBackUrl = async (v1ApiCompletedCallBackUrl: string) => {
  const token = await firebase.auth().currentUser?.getIdToken(true);

  const response = await axios.post(
    '/v1-api/broker/call-load-completed-callback-url',
    {
      v1ApiCompletedCallBackUrl,
    },
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
  return response.data;
};

export const uploadImageAndGetUrl = (
  userId: string,
  loadId: string,
  imageSection: string,
  base64: string
) =>
  new Promise((resolve, reject) => {
    const uploadTask = storage
      .ref()
      .child(`v1-api/broker/users/${userId}/loads/${loadId}/${imageSection}/${uuidv4()}.png`)
      .putString(base64, 'data_url');
    uploadTask
      .then(async () => {
        const url = await uploadTask.snapshot.ref.getDownloadURL();
        resolve(url);
      })
      .catch(() => reject());
  });

export const uploadNotesImageAndGetUrl = (userId: string, ticketId: string, base64: string) =>
  new Promise((resolve, reject) => {
    if (ticketId.length > 21) ticketId = ticketId.substring(0, 20); // varchar(255) size limit
    const uploadTask = storage
      .ref()
      .child(`v1-api/broker/users/${userId}/${ticketId}/${uuidv4()}.png`)
      .putString(base64, 'data_url');
    uploadTask
      .then(async () => {
        const url = await uploadTask.snapshot.ref.getDownloadURL();
        resolve(url);
      })
      .catch(() => reject());
  });

export interface DownloadBrokerLoadsProps {
  startDate: string;
  endDate: string;
  timeZone?: string; // TODO: maybe it's wrong way (look at LoadsView)
  userId?: string;
  companyId: string;
}

export const createDownloadBrokerLoads = async ({
  startDate,
  endDate,
  timeZone,
  userId,
  companyId,
}: DownloadBrokerLoadsProps) => {
  const token = await firebase.auth().currentUser?.getIdToken(true);
  const response = await axios.post(
    `${staticApiBaseUrl}/pub-sub/download-broker-loads`,
    {
      startDate: startDate,
      endDate: endDate,
      timeZone,
      userId,
      companyId,
      perDay: true,
    },
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
  return response.data;
};
