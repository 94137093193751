import React from 'react';
import { Box, CircularProgress } from '@mui/material';

type LoadingProps = {
  height?: number;
};

export const Loading = ({ height = 80 }: LoadingProps) => {
  return (
    <Box display="flex" justifyContent="center" alignItems="center" sx={{ height: height }}>
      <CircularProgress size={80} />
    </Box>
  );
};
