import React from 'react';
import { GridColDef } from '@mui/x-data-grid';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { LocalShipping, AssignmentTurnedIn } from '@mui/icons-material';
import { Box } from '@mui/material';
import LocationOnIcon from '@mui/icons-material/LocationOn';

export const generateDispatchTicketsColumns = () => {
  return [
    {
      field: 'status',
      headerName: 'Status',
      width: 60,
      align: 'center',
      renderCell: ({ row }) =>
        row.deliveredAtTruckingTicket ? (
          <AssignmentTurnedIn sx={{ color: 'secondary.main' }} />
        ) : (
          <LocalShipping sx={{ color: 'secondary.main' }} />
        ),
    },
    {
      field: 'brokerCompanyName',
      headerName: 'Broker Co.',
      width: 150,
    },
    {
      field: 'materialQuantityTruckingTicket',
      headerName: 'Loaded Quantity',
      width: 150,
    },
    {
      field: 'truckNumberTruckingTicket',
      headerName: 'Truck No.',
      width: 158,
    },
    {
      field: 'pitTicketNumberTruckingTicket',
      headerName: 'Pit Ticket No.',
      width: 150,
    },
    {
      field: 'ticketNumberTruckingTicket',
      headerName: 'Ticket No.',
      width: 150,
    },
    {
      field: 'Picked up At',
      headerName: 'Loaded At',
      width: 150,
      renderCell: ({ row }) =>
        row.assignedAtTruckingTicket ? moment(row.assignedAtTruckingTicket).format('LT') : '',
    },
    {
      field: 'Dumped At',
      headerName: 'Dumped At',
      width: 150,
      renderCell: ({ row }) =>
        row.deliveredAtTruckingTicket
          ? moment(row.deliveredAtTruckingTicket)
              .tz(row.timeZone || 'EST')
              .format('LT')
          : '',
    },
    {
      field: 'map',
      headerName: 'Map',
      align: 'right',
      headerAlign: 'right',
      flex: 1,
      sortable: false,
      renderCell: data => {
        return (
          <Link to={`/trucking/tickets/${data.id}/map`}>
            <Box display="flex" alignItems="center">
              <LocationOnIcon fontSize="medium" />
            </Box>
          </Link>
        );
      },
    },
  ] as GridColDef[];
};
