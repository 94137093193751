import React from 'react';
import { GridColDef } from '@mui/x-data-grid';
import { Button } from '@mui/material';
import { EditIcon } from 'modules/civilAndTrucking/shared/CustomIcons';
import { ITruckingCompanyMaterial } from 'lg-helpers/dist/trucking/interfaces/ITruckingCompanyMaterial';

export const generateCompanyMaterialsColumns = (
  showModal: (companyMaterial: ITruckingCompanyMaterial) => void
) => {
  return [
    {
      field: 'nameTruckingCompanyMaterial',
      headerName: 'Material',
      flex: 1,
    },
    {
      field: 'edit',
      headerName: ' ',
      flex: 1,
      align: 'right',
      sortable: false,
      renderCell: ({ row }) => (
        <Button color="success" onClick={() => showModal(row)}>
          <EditIcon />
        </Button>
      ),
    },
  ] as GridColDef[];
};
