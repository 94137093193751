import React from 'react';
import { DialogContent } from '@mui/material';
import ModalHeader from 'sharedComponents/DialogHeader';
import ProjectTemplateList from './ProjectTemplateList';

interface LoadTemplateModalContainerProps {
  setTasks?: Function;
}

const ModalContainer = ({ setTasks }: LoadTemplateModalContainerProps) => {
  return (
    <>
      <ModalHeader title="Project Templates" />
      <DialogContent>
        <ProjectTemplateList setTasks={setTasks} />
      </DialogContent>
    </>
  );
};

export default ModalContainer;
