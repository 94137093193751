import React, { useEffect, useRef, useState } from 'react';
import 'mapbox-gl/dist/mapbox-gl.css';
import Mapbox from 'sharedComponents/Mapbox';
import MapboxGeocoder from '@mapbox/mapbox-gl-geocoder';
import '@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Box,
  Button,
  Typography,
  TextField,
} from '@mui/material';
import LocationSearchingIcon from '@mui/icons-material/LocationSearching';
import { ChangeEventHandler, useCallback } from 'react';
import styled from 'styled-components';

export interface Coordinates {
  lat: number;
  lon: number;
}

export interface InputGeocodingAddressProps {
  onChange: (newValue: string) => void;
  onCoordinatesChange: (newCoords: Coordinates) => void;
  value: string;
  label: string;

  disabled?: boolean;
}

export const InputGeocodingAddress = ({
  disabled,
  onChange,
  onCoordinatesChange,
  value,
  label,
}: InputGeocodingAddressProps) => {
  const geocoderRef = useRef<MapboxGeocoder>();
  const currentSelectionRef = useRef<string>();
  const [inFocus, setInFocus] = useState(false);
  const [geocodedCoords, setGeocodedCoords] = useState<Coordinates | null>(null);
  const currentCoordsRef = useRef<Coordinates | null>();

  const handleChange: ChangeEventHandler<HTMLInputElement> = useCallback(
    e => onChange(e.target.value),
    [onChange]
  );

  const handleSelectCoords = () => {
    onCoordinatesChange(currentCoordsRef.current!);
    setGeocodedCoords(null);
  };

  useEffect(() => {
    currentCoordsRef.current = geocodedCoords;
  }, [geocodedCoords]);

  useEffect(() => {
    geocoderRef.current = new MapboxGeocoder({
      accessToken: process.env.REACT_APP_MAPBOX_TOKEN!,
      countries: 'us',
      language: 'en',
      marker: false,
      types: 'address',
    });

    geocoderRef.current.addTo('#geocoder');
  }, []);

  useEffect(() => {
    const geocoder = geocoderRef.current!;

    const handler = (e: any) => {
      currentSelectionRef.current = e.result.place_name;
      onChange(e.result.place_name);

      const [lon, lat] = e.result.center;
      setGeocodedCoords({ lat, lon });
    };

    geocoder.on('result', handler);

    return () => {
      geocoder.off('result', handler);
    };
  }, [onChange, onCoordinatesChange]);

  useEffect(() => {
    const geocoder = geocoderRef.current!;
    if (!value || value === currentSelectionRef.current) return;
    geocoder.setInput(value);
  }, [value]);

  return (
    <GeocodingWrapper>
      <TextField
        disabled={disabled}
        onChange={handleChange}
        fullWidth
        label={label}
        value={value}
        variant="outlined"
        onFocus={() => setInFocus(true)}
        onBlur={() => setTimeout(() => setInFocus(false), 100)}
      />
      <div id="geocoder" style={{ visibility: inFocus ? 'visible' : 'hidden' }} />

      <Dialog open={!!geocodedCoords}>
        <DialogTitle>
          <div>
            <Typography variant="h3">Is it looking correct?</Typography>
          </div>
        </DialogTitle>

        <DialogContent>
          <MakerOverlay>
            <Mapbox
              zoom={12}
              center={geocodedCoords ? [geocodedCoords.lon, geocodedCoords.lat] : [-83.045, 42.331]}
              onMove={e => {
                const { lat, lng } = e.target.getCenter();
                currentCoordsRef.current = { lat, lon: lng };
              }}
              style={{
                width: '600px',
                maxWidth: '100%',
                height: '400px',
                maxHeight: '70vh',
              }}
            />
            <LocationSearchingIcon className="location-icon" />
          </MakerOverlay>
        </DialogContent>
        <DialogActions>
          <Box display="flex" justifyContent="space-between" alignItems="center">
            <Button onClick={handleSelectCoords} variant="outlined" color="primary">
              Ok
            </Button>
          </Box>
        </DialogActions>
      </Dialog>
    </GeocodingWrapper>
  );
};

export default InputGeocodingAddress;

const GeocodingWrapper = styled.div`
  .mapboxgl-ctrl-geocoder {
    min-width: 100%;
    height: 0;
    z-index: 10000;
  }

  .mapboxgl-ctrl-geocoder--icon-search,
  .mapboxgl-ctrl-geocoder--pin-right,
  .mapboxgl-ctrl-geocoder--input {
    display: none;
  }
`;

const MakerOverlay = styled.div`
  position: relative;

  .location-icon {
    position: absolute;
    top: calc(50% - 20px);
    left: calc(50% - 20px);
    width: 40px;
    height: 40px;
  }
`;
