import React, { useEffect, useState, useContext } from 'react';
import { Button, Grid, IconButton, SvgIcon, Typography, Tooltip } from '@mui/material';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { ArrowDropDown, ArrowDropUp, CloudDownloadOutlined, Check } from '@mui/icons-material';
import { RootState, thunkDispatch } from 'store/store';
import { useSelector } from 'react-redux';
import {
  getProjectTemplateDetails,
  getProjectTemplates,
  loadProjectTemplate,
} from '../redux/actions';
import { ModalContext } from 'sharedComponents/ModalContext';
import { useHasAccess } from 'services/permissions';
import { LoadingComponent } from 'sharedComponents/LoadingComponent';
import { IProjectTemplate } from 'lg-helpers/dist/shared/interfaces/IProjectTemplate';
import { AlertCircle } from '../../../shared/CustomIcons/AlertCircle';
import { IProjectTemplateDetail } from 'lg-helpers/dist/shared/interfaces/IProjectTemplateDetail';

interface ProjectTemplateListProps {
  setTasks?: Function;
}

export const ProjectTemplateList = ({ setTasks }: ProjectTemplateListProps) => {
  const auth = useHasAccess(['projects:create', 'projects:update']);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [templates, setTemplates] = useState<IProjectTemplate[]>([]);
  const [template, setTemplate] = useState<IProjectTemplate>();
  const [templateDetails, setTemplateDetails] = useState<IProjectTemplateDetail[]>([]);
  const [showDetails, setShowDetails] = useState<boolean>(false);
  const { handleModal } = useContext(ModalContext);

  const columns: GridColDef[] = [
    {
      field: 'name',
      headerName: 'Name',
      flex: 1,
    },
    {
      field: ' ',
      headerName: ' ',
      renderCell: data => {
        const editElement = auth.hasAccess && (
          <>
            <IconButton onClick={() => handleClick(data.row)}>
              <SvgIcon color="secondary">
                <CloudDownloadOutlined />
              </SvgIcon>
            </IconButton>
          </>
        );
        return <>{editElement}</>;
      },
    },
  ];

  const detailColumns: GridColDef[] = [
    {
      field: 'workCategory',
      headerName: 'Work Category',
      flex: 1,
      renderCell: data => {
        return (
          <>
            {data.row.workCategoryExists ? (
              <Tooltip
                title={`${data.row.workCategoryName} already exists on the project and will not be added.`}
              >
                <SvgIcon color="secondary">
                  <Check />
                </SvgIcon>
              </Tooltip>
            ) : (
              <Tooltip
                title={`${data.row.workCategoryName} does not exist on the project and will be added.`}
              >
                <AlertCircle viewBox="0 0 35 35" />
              </Tooltip>
            )}{' '}
            {data.row.workCategoryName}
          </>
        );
      },
    },
    {
      field: 'activity',
      headerName: 'Activity',
      flex: 1,
      renderCell: data => {
        return (
          <>
            {data.row.activityExists ? (
              <Tooltip
                title={`${data.row.activityName} already exists on the project and will not be added.`}
              >
                <SvgIcon color="secondary">
                  <Check />
                </SvgIcon>
              </Tooltip>
            ) : (
              <Tooltip
                title={`${data.row.activityName} does not exist on the project and will be added.`}
              >
                <AlertCircle viewBox="0 0 35 35" />
              </Tooltip>
            )}{' '}
            {data.row.activityName}
          </>
        );
      },
    },
    {
      field: 'material',
      headerName: 'Material',
      flex: 1,
      renderCell: data => {
        return (
          <>
            {data.row.materialExists ? (
              <Tooltip title={`${data.row.materialName} already exists and will not be added.`}>
                <SvgIcon color="secondary">
                  <Check />
                </SvgIcon>
              </Tooltip>
            ) : (
              <Tooltip
                title={`${data.row.materialName} does not exist and will be added for your company.`}
              >
                <AlertCircle viewBox="0 0 35 35" />
              </Tooltip>
            )}{' '}
            {data.row.materialName}
          </>
        );
      },
    },
    {
      field: 'sourceSupplier',
      headerName: 'Source Supplier',
      flex: 1,
      renderCell: data => {
        return (
          <>
            {data.row.sourceSupplierExists ? (
              <Tooltip
                title={`${data.row.sourceSupplierName} already exists and will not be added.`}
              >
                <SvgIcon color="secondary">
                  <Check />
                </SvgIcon>
              </Tooltip>
            ) : (
              <Tooltip
                title={`${data.row.sourceSupplierName} does not exist and will be added for your company.`}
              >
                <AlertCircle viewBox="0 0 35 35" />
              </Tooltip>
            )}{' '}
            {data.row.sourceSupplierName}
          </>
        );
      },
    },
    {
      field: 'projectMaterial',
      headerName: 'Project Material',
      flex: 1,
      renderCell: data => {
        const tipText = `${data.row.materialName} & ${data.row.sourceSupplierName} as a project material does not exist on the project and will be added.\n\n The price will be set at $${data.row.totalCost}. \n\nPlease create a new project material with this material and source supplier after the tasks have been created. `;
        return (
          <>
            {data.row.workCategoryExists ? (
              <Tooltip
                title={`${data.row.materialName} & ${data.row.sourceSupplierName} already exists on the project and will not be added.`}
              >
                <SvgIcon color="secondary">
                  <Check />
                </SvgIcon>
              </Tooltip>
            ) : (
              <Tooltip title={<span style={{ whiteSpace: 'pre-line' }}>{tipText}</span>}>
                <AlertCircle viewBox="0 0 35 35" />
              </Tooltip>
            )}
          </>
        );
      },
    },
    {
      field: 'totalAmount',
      headerName: 'Total',
      flex: 1,
    },
    {
      field: 'dailyTarget',
      headerName: 'Daily Target',
      flex: 1,
    },
    {
      field: 'autoTicketNumber',
      headerName: 'Auto Ticket #',
      flex: 1,
    },
  ];

  const activeProject = useSelector((state: RootState) => state.civil.projects.activeProject);

  const projectTemplateState = useSelector((state: RootState) => state.civil.projects.templates);

  const projectTemplateDetailsState = useSelector(
    (state: RootState) => state.civil.projects.templateDetails
  );

  const handleClick = async (data: IProjectTemplate) => {
    setTemplate(data as IProjectTemplate);
    if (data?.id) {
      try {
        setIsLoading(true);
        await thunkDispatch(
          getProjectTemplateDetails({
            template: data as IProjectTemplate,
            projectId: activeProject?.id as number,
          })
        );
        setIsLoading(false);
      } catch (err) {
        setIsLoading(false);

        console.error(err);
      }
    }
  };

  const handleBack = () => {
    setShowDetails(false);
  };

  const handleApply = async () => {
    try {
      setIsLoading(true);
      await thunkDispatch(
        loadProjectTemplate({
          details: templateDetails as IProjectTemplateDetail[],
          projectId: activeProject?.id as number,
        })
      );
      setIsLoading(false);
      setShowDetails(false);
      handleModal();
      if (setTasks) setTasks();
    } catch (err) {
      setIsLoading(false);
      setShowDetails(false);
      handleModal();
      console.error(err);
    }
  };

  useEffect(() => {
    (async () => {
      if (activeProject) {
        try {
          setIsLoading(true);
          await thunkDispatch(getProjectTemplates(activeProject.id as number));

          setIsLoading(false);
        } catch (err) {
          setIsLoading(false);
          console.error(err);
        }
      }
    })();
  }, [activeProject]);

  useEffect(() => {
    setTemplates(projectTemplateState);
  }, [projectTemplateState]);

  useEffect(() => {
    setTemplateDetails(projectTemplateDetailsState);
    if (templateDetails && templateDetails.length > 0) {
      setShowDetails(true);
    }
  }, [projectTemplateDetailsState, templateDetails]);

  return (
    <>
      {showDetails ? (
        <Grid container spacing={2}>
          <Grid item md={8}>
            <Typography variant="h5">Project Tasks for {template?.name}</Typography>
          </Grid>
          <Grid item md={4}>
            <Button onClick={() => handleBack()}>Back</Button>
            <Button variant="contained" color="primary" onClick={() => handleApply()}>
              Apply
            </Button>
          </Grid>
          <Grid item xs={12}>
            <DataGrid
              columns={detailColumns}
              rows={templateDetails}
              showColumnRightBorder={false}
              autoHeight
              loading={isLoading}
              rowHeight={50}
              disableColumnMenu
              sx={{ backgroundColor: '#FFF' }}
              components={{
                ColumnSortedAscendingIcon: ArrowDropUp,
                ColumnSortedDescendingIcon: ArrowDropDown,
                ColumnResizeIcon: () => <div></div>,
                Header: () => <Typography variant="h1" />,
              }}
            />
          </Grid>
        </Grid>
      ) : (
        <DataGrid
          columns={columns}
          rows={templates}
          showColumnRightBorder={false}
          autoHeight
          loading={isLoading}
          rowHeight={50}
          disableColumnMenu
          sx={{ backgroundColor: '#FFF' }}
          components={{
            ColumnSortedAscendingIcon: ArrowDropUp,
            ColumnSortedDescendingIcon: ArrowDropDown,
            ColumnResizeIcon: () => <div></div>,
            Header: () => <Typography variant="h1" />,
          }}
        />
      )}
      <LoadingComponent isLoading={isLoading} />
    </>
  );
};

export default ProjectTemplateList;
