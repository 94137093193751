import React from 'react';
import { Grid, Typography, Button } from '@mui/material';
import { useSelector } from 'react-redux';
import { selectJoinedDispatch } from '../../../redux/dispatchStoreSlice';
import { LoadingOverlay as Loading } from 'sharedComponents/LoadingOverlay';
import ButtonLink from 'sharedComponents/ButtonLink';
import ButtonToggle from 'sharedComponents/ButtonToggle';
import { selectTickets } from 'modules/civilAndTrucking/trucking/features/Tickets/redux/ticketsStoreSlice';
import { selectLoads } from 'modules/civilAndTrucking/trucking/features/Loads/redux/loadsStoreSlice';

type DispatchToolbarProps = {
  showModal?: () => void;
  toggleIndex: 0 | 1;
  setToggleIndex: (index: 0 | 1) => void;
};

export const DispatchToolbar = ({
  showModal,
  toggleIndex,
  setToggleIndex,
}: DispatchToolbarProps) => {
  const { joinedDispatch, joinedDispatchStatus } = useSelector(selectJoinedDispatch);
  const { loads } = useSelector(selectLoads);
  const { tickets } = useSelector(selectTickets);

  if (joinedDispatchStatus === 'pending') {
    return <Loading />;
  }

  return (
    <>
      <ButtonLink />
      <Grid container justifyContent="space-between">
        <Typography variant="h5">Dispatch Info</Typography>
        <ButtonToggle
          firstVal={`Available Loads (${loads.length || 0})`}
          secondVal={`Generated Tickets (${tickets.length || 0})`}
          toggleIndex={toggleIndex}
          setToggleIndex={setToggleIndex}
        />
        {showModal && (
          <Button onClick={showModal} variant="contained" color="primary">
            Assign Loads to Trucks
          </Button>
        )}
      </Grid>
      <Grid
        container
        justifyContent="space-between"
        alignItems="flex-start"
        direction="row"
        spacing={0.5}
        sx={{ my: 2 }}
      >
        <Grid item md={2} xs={6}>
          <Typography variant="body2" sx={{ color: 'secondary.main' }}>
            Project
          </Typography>
          <Typography variant="body1">
            {joinedDispatch?.nameTruckingProject ||
              joinedDispatch?.manualProjectNameTruckingDispatch}
          </Typography>
        </Grid>
        <Grid item md={2} xs={6}>
          <Typography variant="body2" sx={{ color: 'secondary.main' }}>
            Location
          </Typography>
          <Typography variant="body1">{joinedDispatch?.addressString}</Typography>
        </Grid>
        <Grid item md={2} xs={6}>
          <Typography variant="body2" sx={{ color: 'secondary.main' }}>
            Contractor
          </Typography>
          <Typography variant="body1">
            {joinedDispatch?.name || joinedDispatch?.manualContractorNameTruckingDispatch}
          </Typography>
        </Grid>
        <Grid item md={2} xs={6}>
          <Typography variant="body2" sx={{ color: 'secondary.main' }}>
            PO No.
          </Typography>
          <Typography variant="body1">{joinedDispatch?.poNumberTruckingDispatch}</Typography>
        </Grid>
        <Grid item md={2} xs={6}>
          <Typography variant="body2" sx={{ color: 'secondary.main' }}>
            Material
          </Typography>
          <Typography variant="body1">
            {joinedDispatch?.nameTruckingCompanyMaterial ||
              joinedDispatch?.manualMaterialNameTruckingDispatch}
          </Typography>
        </Grid>
        <Grid item md={2} xs={6}>
          <Typography variant="body2" sx={{ color: 'secondary.main' }}>
            Material Source
          </Typography>
          <Typography variant="body1">
            {joinedDispatch?.nameTruckingCompanySourceSupplier ||
              joinedDispatch?.manualMaterialSourceTruckingDispatch}
          </Typography>
        </Grid>
      </Grid>
    </>
  );
};
