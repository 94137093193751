import React, { useCallback, useEffect, useState } from 'react';
import {
  Grid,
  Box,
  TextField,
  InputLabel,
  FormControl,
  MenuItem,
  Switch,
  Typography,
  useTheme,
  useMediaQuery,
  SvgIcon,
  Button,
} from '@mui/material';
import { Search, Sync } from '@mui/icons-material';
import { useDispatch, useSelector } from 'react-redux';
import {
  getProjects,
  setActiveProject,
} from 'modules/civilAndTrucking/civil/Projects/redux/actions';
import { RootState, thunkDispatch } from 'store/store';
import { searchTickets, ITicketSearch } from 'modules/civilAndTrucking/civil/Tickets/redux/actions';
import moment from 'moment';
import { getUtcEndOfDay, getUtcStartOfDay } from 'modules/civilAndTrucking/civil/utils/time';
import MultipleSitesAutocomplete from 'sharedComponents/ProjectSitesAutocomplete';
import { AutocompleteRenderInputParams } from '@mui/material';
import { IInvoice } from 'lg-helpers/dist/shared/interfaces/IInvoice';
import { IProject } from 'lg-helpers/dist/shared/interfaces/IProject';
import { ILocation } from 'lg-helpers/dist/shared/interfaces/ILocation';
import api from 'services/api/autogenerated';
import { INotification } from 'modules/civilAndTrucking/shared/NotificationSnackbar/INotification';
import { pushNotification } from 'modules/civilAndTrucking/shared/NotificationSnackbar/redux/actions';

interface ISearch {
  search?: string;
  startDate?: string | Date;
  endDate?: string | Date;
  filterBy?: number;
  projectId?: number;
  isAccepted?: boolean;
  locationId?: number;
  showDeleted?: boolean;
  showCompleted?: boolean;
}

interface ITicketSearchProps {
  isAccepted?: boolean;
  invoiceId?: number;
  isTransporter?: boolean;
  isFromTrucking?: boolean;
  canShowDeleted?: boolean;
  canShowCompleted?: boolean;
  setIsLoading: Function;
  setInvoice?: React.Dispatch<React.SetStateAction<IInvoice | undefined>>;
  setShowingDeleted?: Function;
  setShowingCompleted?: Function;
}

const TicketSearch = (props: ITicketSearchProps) => {
  const dispatch = useDispatch();
  const {
    setIsLoading,
    setInvoice,
    canShowDeleted,
    setShowingDeleted,
    canShowCompleted,
    setShowingCompleted,
  } = props;
  const [projectId, setProjectId] = useState<number>();
  const [project, setProject] = useState<IProject>();
  const [startDate, setStartDate] = useState<string>(
    moment
      .utc(new Date(new Date().getTime() - 7 * 24 * 60 * 60 * 1000))
      .startOf('day')
      .format('YYYY-MM-DD')
  );
  const [endDate, setEndDate] = useState<string>(
    moment.utc(new Date()).endOf('day').format('YYYY-MM-DD')
  );
  const [locationSearch, setLocationSearch] = useState<string>('');
  const [location, setLocation] = useState<ILocation | null>(null);
  const [isSyncingManifest, setIsSyncingManifest] = useState(false);
  const companyState = useSelector((state: RootState) => state.civil.companies.activeCompany);

  const [search, setSearch] = useState<ISearch>({
    search: undefined,
    startDate: undefined,
    endDate: undefined,
    filterBy: undefined,
    projectId: undefined,
    locationId: undefined,
    isAccepted: props.isAccepted ?? undefined,
    showDeleted: false,
    showCompleted: false,
  });

  const theme = useTheme();
  const isWidthXSorSM = useMediaQuery(theme.breakpoints.down('md'));

  const activeProject = useSelector((state: RootState) => state.civil.projects.activeProject);

  const projectsState = useSelector((state: RootState) => state.civil.projects.data);

  const getReceiptFromManifestStatus = useSelector(
    (state: RootState) => state.civil.tickets.getTicketReceiptStatus
  );

  const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  const handleChange = async (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    setSearch(prev => ({ ...prev, [event.target.name]: event.target.value }));
    return;
  };

  useEffect(() => {
    if (!props.invoiceId) {
      return;
    }
    searchTicketsByForm();
    // eslint-disable-next-line
  }, [props.invoiceId]);

  useEffect(() => {
    const utcStart = getUtcStartOfDay(startDate, timeZone);
    const utcEnd = getUtcEndOfDay(endDate, timeZone);
    setSearch(prev => ({
      ...prev,
      startDate: startDate ? utcStart : undefined,
      endDate: endDate ? utcEnd : undefined,
    }));
  }, [startDate, endDate, timeZone]);

  async function updateProject(id: number) {
    setIsLoading(true);
    await thunkDispatch(setActiveProject(Number(id)));
    if (setInvoice) {
      setInvoice(undefined);
    }
    setIsLoading(false);
  }

  const searchTicketsByForm = useCallback(async () => {
    if (!projectId) {
      return;
    }
    setIsLoading(true);
    let ticketSearchArray: ITicketSearch[] = [];

    Object.keys(search).forEach((key: string) => {
      if (
        search[key as keyof ISearch] !== undefined &&
        search[key as keyof ISearch] !== 'Invalid Date'
      ) {
        ticketSearchArray.push({
          item: key,
          value: search[key as keyof ISearch],
        });
      }
    });
    ticketSearchArray.push(
      { item: 'invoiceId', value: props.invoiceId },
      { item: 'isTransporter', value: props.isTransporter },
      { item: 'isFromTrucking', value: props.isFromTrucking }
    );

    await thunkDispatch(searchTickets(ticketSearchArray));
    setIsLoading(false);
  }, [projectId, search, props.invoiceId, props.isTransporter, props.isFromTrucking, setIsLoading]);

  useEffect(() => {
    searchTicketsByForm();
    // eslint-disable-next-line
  }, [search, props.invoiceId]);

  useEffect(() => {
    if (getReceiptFromManifestStatus === 'success') {
      searchTicketsByForm();
    }
    // eslint-disable-next-line
  }, [getReceiptFromManifestStatus])

  useEffect(() => {
    thunkDispatch(getProjects());
  }, [dispatch]);

  useEffect(() => {
    if (!activeProject) {
      return;
    }
    setProjectId(activeProject.id as number);
    setProject(activeProject);
    setSearch(prev => ({
      ...prev,
      projectId: activeProject.id as number,
    }));
    setLocation(null);
    setLocationSearch('');
    // eslint-disable-next-line
  }, [activeProject]);

  useEffect(() => {
    setSearch(prev => ({
      ...prev,
      locationId: location ? location.id : undefined,
    }));
  }, [location]);

  const pullTicketsFromManifests = useCallback(async () => {
    if (projectId) {
      setIsSyncingManifest(true);
      const company = {
        id: companyState?.id ?? '',
        name: companyState?.name ?? '',
        types: companyState?.types ?? [],
      };
      try {
        const result = await api.civilTickets.addTicketsFromManifest({ projectId, company });
        const successNotification: INotification = {
          status: 'success',
          message: result.data
            ? `Success: Synced (${result.data}) manifests into tickets`
            : `No Manifests matched for sync`,
        };
        thunkDispatch(pushNotification(successNotification));
        setIsSyncingManifest(false);
        if (result.data) searchTicketsByForm();
      } catch (error) {
        console.error('Error syncing manifest: ', error);
        const errorNotification: INotification = {
          status: 'error',
          message: 'Error: Unable to create tickets from manifest',
        };
        thunkDispatch(pushNotification(errorNotification));
        setIsSyncingManifest(false);
      }
    }
  }, [projectId, companyState, searchTicketsByForm]);

  return (
    <>
      <Grid container>
        <Grid item xs={12} md={5}>
          <Box
            borderRight={!isWidthXSorSM ? '#555 solid 1px' : ''}
            sx={!isWidthXSorSM ? { pr: 2, my: 2, maxHeight: 100 } : { maxHeight: 100 }}
          >
            <Grid container>
              <Grid item xs={project && project.hasLocations ? 6 : 12}>
                <FormControl fullWidth>
                  <InputLabel shrink>Project</InputLabel>
                  <TextField
                    select
                    variant="outlined"
                    value={project?.id || ''}
                    onChange={event => {
                      updateProject(Number(event.target.value));
                      if (setInvoice) {
                        setInvoice(undefined);
                      }
                    }}
                    name="projectId"
                  >
                    {projectsState.length ? (
                      projectsState.map(p => (
                        <MenuItem value={p.id} key={p.id}>
                          {p.name}
                        </MenuItem>
                      ))
                    ) : activeProject ? (
                      <MenuItem value={activeProject.id} key={activeProject.id}>
                        {activeProject.name}
                      </MenuItem>
                    ) : (
                      <MenuItem value={0} key={0}>
                        No projects found
                      </MenuItem>
                    )}
                  </TextField>
                </FormControl>
              </Grid>
              {project && project.hasLocations && (
                <Grid item xs={6}>
                  <FormControl fullWidth>
                    <InputLabel shrink htmlFor="location">
                      Location
                    </InputLabel>
                    <MultipleSitesAutocomplete
                      renderInput={(params: AutocompleteRenderInputParams) => (
                        <TextField
                          {...params}
                          name="location"
                          onChange={event => {
                            setLocationSearch(event.target.value);
                          }}
                          variant="outlined"
                        />
                      )}
                      options={[]}
                      inputValue={locationSearch}
                      projectId={projectId!}
                      searchRequirement={3}
                      handleSelect={(value?: ILocation) => {
                        setLocation(value || null);
                        setLocationSearch(value?.name || value?.addressString || '');
                      }}
                    />
                  </FormControl>
                </Grid>
              )}
            </Grid>
          </Box>
        </Grid>
        <Grid
          item
          xs={12}
          md={7}
          display="flex"
          alignItems="center"
          justifyContent="space-evenly"
          sx={!isWidthXSorSM ? { pl: 2, my: 2, maxHeight: 100 } : { maxHeight: 100 }}
        >
          <FormControl fullWidth>
            <InputLabel shrink>Search</InputLabel>
            <TextField
              InputProps={{ endAdornment: <Search /> }}
              variant="outlined"
              name="search"
              value={search.search || ''}
              onChange={event => {
                event.persist();
                handleChange(event);
              }}
            />
          </FormControl>
          <FormControl fullWidth>
            <InputLabel shrink>Start Date</InputLabel>
            <TextField
              variant="outlined"
              type="date"
              placeholder="From"
              name="startDate"
              value={startDate || ''}
              onChange={event => {
                if (event.target.value) {
                  setStartDate(event.target.value);
                } else {
                  setStartDate(
                    moment(new Date(new Date().getTime() - 7 * 24 * 60 * 60 * 1000)).format(
                      'YYYY-MM-DD'
                    )
                  );
                }
              }}
            />
          </FormControl>
          <FormControl fullWidth>
            <InputLabel shrink>End Date</InputLabel>
            <TextField
              variant="outlined"
              type="date"
              placeholder="From"
              name="endDate"
              value={endDate || ''}
              onChange={event => {
                if (event.target.value) {
                  setEndDate(event.target.value);
                } else {
                  setEndDate(moment(new Date()).format('YYYY-MM-DD'));
                }
              }}
            />
          </FormControl>
        </Grid>
        <Grid container justifyContent="flex-end">
          {projectId && (
            <Grid item xs={12} md={6} sx={{ marginBottom: 2 }}>
              <Button onClick={() => pullTicketsFromManifests()}>
                <Typography sx={{ color: 'neutral.main' }}>Sync Manifest on Project</Typography>
                <Box
                  sx={{ lineHeight: 1 }}
                  className={isSyncingManifest ? 'circular-progress__reverse' : ''}
                >
                  <SvgIcon sx={{ frontSize: 24, color: 'neutral.main' }}>
                    <Sync />
                  </SvgIcon>
                </Box>
              </Button>
            </Grid>
          )}
          <Grid item xs={12} md={6}>
            <Grid container justifyContent="flex-end">
              {canShowCompleted && (
                <Grid
                  item
                  xs="auto"
                  height="100%"
                  display="flex"
                  alignItems="center"
                  justifyContent="flex-end"
                >
                  <Typography variant="subtitle1">Show Completed</Typography>
                  <Switch
                    color="secondary"
                    checked={search.showCompleted}
                    onChange={() => {
                      setSearch(prev => ({
                        ...prev,
                        showCompleted: !search.showCompleted,
                      }));
                      if (setShowingCompleted) {
                        setShowingCompleted(!search.showCompleted);
                      }
                    }}
                  />
                </Grid>
              )}
              {canShowDeleted && (
                <Grid
                  item
                  xs="auto"
                  height="100%"
                  display="flex"
                  alignItems="center"
                  justifyContent="flex-end"
                >
                  <Typography variant="subtitle1">Show Deleted</Typography>
                  <Switch
                    color="secondary"
                    onChange={() => {
                      setSearch(prev => ({
                        ...prev,
                        showDeleted: !search.showDeleted,
                      }));
                      if (setShowingDeleted) {
                        setShowingDeleted(!search.showDeleted);
                      }
                    }}
                  />
                </Grid>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default TicketSearch;
