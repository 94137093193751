import React, { useContext, useEffect, useState } from 'react';
import { Grid, Typography, Button } from '@mui/material';
import { Add } from '@mui/icons-material';
import TicketsTable from './TicketsTable';
import { useDispatch, useSelector } from 'react-redux';
import AddTicketModal from 'modules/civilAndTrucking/civil/Tickets/AddTicketModal';
import { ModalContext } from 'sharedComponents/ModalContext';
import { getProjects } from 'modules/civilAndTrucking/civil/Projects/redux/actions';
import { getProjectMaterials } from 'modules/civilAndTrucking/civil/ProjectMaterials/redux/actions';
import { RootState, thunkDispatch } from 'store/store';
import { useHasAccess } from 'services/permissions';
import DownloadDropdown from 'sharedComponents/DownloadDropdown';
import TicketSearch from 'sharedComponents/TicketSearch';
import { constants } from 'lg-helpers';
import { ITicketForm } from 'lg-helpers/dist/shared/interfaces/ITicketForm';

const {
  pubSub: { DownloadType },
} = constants;

const TicketsTableContainer = () => {
  const dispatch = useDispatch();
  const { handleModal } = useContext(ModalContext);
  const [projectId, setProjectId] = useState<number>();
  const [isLoading, setIsLoading] = useState(false);
  const [showingDeleted, setShowingDeleted] = useState(false);
  const [showingCompleted, setShowingCompleted] = useState(true);
  const [canShowCompleted, setCanShowCompleted] = useState(false);
  const { innerWidth } = window;

  const auth = useHasAccess(['tickets:create', 'tickets:update']);

  const ticketsState = useSelector((state: RootState) => state.civil.tickets.data);

  const activeProject = useSelector((state: RootState) => state.civil.projects.activeProject);

  const projectMaterialsState = useSelector(
    (state: RootState) => state.civil.projectMaterials.data
  );

  const showModal = (value: ITicketForm) => {
    handleModal(<AddTicketModal selected={value} />);
  };

  useEffect(() => {
    thunkDispatch(getProjects());
  }, [dispatch]);

  useEffect(() => {
    if (activeProject) {
      setProjectId(activeProject.id as number);
    }
    if (activeProject?.id) {
      thunkDispatch(
        getProjectMaterials({
          projectId: activeProject?.id as number,
          type: 'active',
        })
      );
    }
  }, [activeProject, dispatch]);

  useEffect(() => {
    const hasActiveSuppliers = !!projectMaterialsState.filter(
      material => material.supplierMaterialId
    ).length;
    setCanShowCompleted(hasActiveSuppliers);
  }, [projectMaterialsState]);

  const showForm = () => {
    handleModal(<AddTicketModal activeProjectId={Number(projectId)} />);
  };

  return (
    <>
      <Grid container maxWidth={innerWidth * 0.85}>
        <Grid item xs={9} display="flex" alignItems="center">
          <Typography
            variant="h5"
            sx={{
              color: 'neutral.main',
              typography: { textTransform: 'uppercase' },
            }}
          >
            Tickets
            {projectId && auth.hasAccess && (
              <Button
                color="primary"
                variant="contained"
                onClick={showForm}
                sx={{ borderRadius: 5, width: 75, marginLeft: 2 }}
              >
                <Add />
              </Button>
            )}
          </Typography>
        </Grid>
        <Grid item xs={3}>
          <DownloadDropdown
            projectId={Number(projectId)}
            downloadType={DownloadType.TICKETS_CIVIL()}
          />
        </Grid>
        <Grid item xs={12}>
          <TicketSearch
            setIsLoading={setIsLoading}
            canShowDeleted={true}
            canShowCompleted={canShowCompleted}
            setShowingDeleted={setShowingDeleted}
            setShowingCompleted={setShowingCompleted}
          />
        </Grid>
      </Grid>
      <TicketsTable
        isLoading={isLoading}
        handleSelection={showModal}
        tickets={ticketsState && projectId ? ticketsState : []}
        projectId={projectId}
        showingDeleted={showingDeleted}
        showingCompleted={showingCompleted}
      />
    </>
  );
};

export default TicketsTableContainer;
