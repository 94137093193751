import React from 'react';
import { GridColDef } from '@mui/x-data-grid';
import { CheckBox, CheckBoxOutlineBlank } from '@mui/icons-material';

export const generateDeletedTicketsColumns = (
  addTruckToAddingToDivision: (id: number) => void,
  removeTruckFromAddingToDivision: (id: number) => void,
  idsToRenew: number[]
) => {
  return [
    {
      field: 'add',
      headerName: ' ',
      width: 30,
      sortable: false,
      renderCell: params =>
        idsToRenew.includes(params.row?.idTruckingTicket) ? (
          <CheckBox
            onClick={() => removeTruckFromAddingToDivision(Number(params.row?.idTruckingTicket))}
          />
        ) : (
          <CheckBoxOutlineBlank
            onClick={() => addTruckToAddingToDivision(Number(params.row?.idTruckingTicket))}
          />
        ),
    },
    {
      field: 'nameTruckingProject',
      headerName: `Project \u2014 Material`,
      width: 250,
      valueFormatter: data => {
        const row = data.api.getRow(data.id!);
        if (row.isManualTruckingDispatch) {
          return `${row.manualProjectNameTruckingDispatch} - ${row.manualMaterialNameTruckingDispatch}`;
        } else {
          return `${row.nameTruckingProject} - ${row.nameTruckingCompanyMaterial}`;
        }
      },
    },
    {
      field: 'contractorName',
      headerName: 'Contractor',
      width: 150,
      valueFormatter: data => {
        const row = data.api.getRow(data.id!);
        return `${row.name || row.manualContractorNameTruckingDispatch}`;
      },
    },
    {
      field: 'dateTruckingTicket',
      headerName: 'Date',
      width: 120,
      renderCell: ({ row }) => row.dateTruckingTicket,
    },
    {
      field: 'ticketNumberTruckingTicket',
      headerName: 'Ticket No.',
      width: 120,
    },
    {
      field: 'pitTicketNumberTruckingTicket',
      headerName: 'Pit Ticket No.',
      width: 120,
    },
    {
      field: 'truckNumberTruckingTicket',
      headerName: 'Truck No.',
      width: 120,
    },
    {
      field: 'manifestNumberTruckingTicket',
      headerName: 'Manifest No.',
      width: 120,
    },
    {
      field: 'source supplier',
      headerName: 'Source Supplier',
      width: 150,
      valueFormatter: data => {
        const row = data.api.getRow(data.id!);
        return `${
          row.nameTruckingCompanySourceSupplier || row.manualMaterialSourceTruckingDispatch
        }`;
      },
    },
    {
      field: 'materialQuantityTruckingTicket',
      headerName: 'Loaded Qty',
      width: 120,
      valueFormatter: data => {
        const row = data.api.getRow(data.id!);
        return `${row.materialQuantityTruckingTicket} ${
          row.uomTruckingProjectMaterial || row.manualMaterialUomTruckingDispatch
        }`;
      },
    },
  ] as GridColDef[];
};
