import React from 'react';
import { createSvgIcon } from '@mui/material';

export const AlertPlus = createSvgIcon(
  <>
    <path
      d="M35 28.3265c0 2.547-2.0475 4.6147-4.5763 4.6147H4.57332c-2.52583 0-4.57625-2.0647-4.57625-4.6147 0-.8294.22167-1.6471.64458-2.3589L13.09 2.52647C14.0175.95882 15.6917 0 17.5 0s3.4825.95882 4.4071 2.52647L34.3554 25.9676c.4229.7147.6446 1.5295.6446 2.3589Z"
      fill="#C61C1C"
    />
    <path
      d="M2.33333 28.3294c0 1.2471 1.00333 2.2588 2.24 2.2588H30.4267c1.2366 0 2.24-1.0117 2.24-2.2588 0-.4118-.105-.8118-.315-1.1529L19.8567 3.63529c-.455-.75294-1.3767-1.28236-2.3567-1.28236s-1.9017.52942-2.4033 1.37647L2.695 27.0823c-.25667.4353-.36167.8353-.36167 1.2471Z"
      fill="#fff"
    />
    <path
      d="M17.5001 28.2353c-1.2887 0-2.3334-1.0534-2.3334-2.3529s1.0447-2.353 2.3334-2.353c1.2886 0 2.3333 1.0535 2.3333 2.353s-1.0447 2.3529-2.3333 2.3529ZM19.2501 22.3529h-3.5l-.4784-10.5912c-.0583-1.2794.9567-2.34996 2.2284-2.34996 1.2716 0 2.2837 1.07056 2.2283 2.34996l-.4783 10.5912Z"
      fill="#000"
    />
  </>,
  'Alert Plus'
);
