import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { v4 as uuidv4 } from 'uuid';

export interface ISuccess {
  title: string;
  duration?: number;
  text?: string;
}

export interface IFullSuccess extends ISuccess {
  id: string;
  duration: number;
  time: number;
}

export const storeSlice = createSlice({
  name: 'successes',
  initialState: {
    successesList: [] as IFullSuccess[],
  },
  reducers: {
    pushSuccess: (state, action: PayloadAction<ISuccess>) => {
      const time = Date.now();
      const id = uuidv4();
      state.successesList = [
        ...state.successesList.slice(-20), // limit size of array
        { duration: 10000, ...action.payload, time, id },
      ];
    },
  },
});

export const { pushSuccess } = storeSlice.actions;

export const selectSuccesses = (state: any) => state.successes.successesList as IFullSuccess[];

export default storeSlice.reducer;
