import React, { useEffect } from 'react';
import { Typography } from '@mui/material';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { RootState, thunkDispatch } from 'store/store';

import { ArrowDropDown, ArrowDropUp, Visibility, StopRounded } from '@mui/icons-material';

import { useSelector, useDispatch } from 'react-redux';

import { getProjects } from 'modules/civilAndTrucking/civil/Projects/redux/actions';

import { Link } from 'react-router-dom';
import { IProject } from 'lg-helpers/dist/shared/interfaces/IProject';

const projectColumns: GridColDef[] = [
  {
    field: 'status',
    headerName: 'Status',
    width: 100,
    renderCell: params => (
      <StopRounded color={params.value === 'active' ? 'secondary' : 'error'} fontSize="large" />
    ),
  },
  {
    field: 'projectNumber',
    headerName: 'No.',
    width: 90,
  },
  {
    field: 'name',
    headerName: 'Name',
    width: 250,
  },
  {
    field: 'show',
    headerName: ' ',
    width: 80,
    renderCell: data => (
      <Link to={`/civil/projects/${data.id}`} state={data}>
        <Visibility />
      </Link>
    ),
  },
];

export const ProjectsList = () => {
  const dispatch = useDispatch();
  const projectData: IProject[] = useSelector((state: RootState) => state.civil.projects.data);

  useEffect(() => {
    thunkDispatch(getProjects());
  }, [dispatch]);

  return (
    <DataGrid
      columns={projectColumns}
      rows={projectData || []}
      showColumnRightBorder={false}
      disableColumnMenu
      components={{
        ColumnSortedAscendingIcon: ArrowDropUp,
        ColumnSortedDescendingIcon: ArrowDropDown,
        ColumnResizeIcon: () => <div></div>,
        Header: () => <Typography variant="h1" />,
      }}
    />
  );
};

export default ProjectsList;
