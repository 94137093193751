import React from 'react';
import { TextField } from '@mui/material';

interface InputSearchProps {
  value: string;
  disabled?: boolean;
  onChange(e: any): void;
  placeholder?: string;
}

const InputSearch = ({ onChange, disabled, value, placeholder }: InputSearchProps) => (
  <TextField
    defaultValue={value}
    variant="outlined"
    fullWidth
    placeholder={placeholder || 'Search'}
    disabled={disabled || false}
    onChange={onChange}
    style={{
      fontSize: '12px',
      lineHeight: '10px',
      letterSpacing: '-0.05px',
    }}
  />
);

export default InputSearch;
