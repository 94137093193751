import React, { useEffect, useState } from 'react';
import './Auth.scss';
import useStickyState from 'services/hooks/useStickyState';
import { Grid, Box } from '@mui/material';
import EmailSignInView from './EmailSignInView';
import PhoneSignInView from './PhoneSignInView';
import DefaultSignInView from './DefaultSignInView';
import LivegisticsLogoLarge from '../../sharedComponents/pictograms/LivegisticsLogoLarge';
import SingleEmailSignInView from './SingleEmailSignInView';

// TODO: Auth0: revert this
// export type LoginMethod = 'email' | 'single_email' | 'phone' | '';
export type LoginMethod = 'email' | 'phone' | '';

const LoginView = () => {
  const [preferredLoginMethod, setPreferredLoginMethod] = useStickyState<LoginMethod>(
    '',
    'preferredLoginMethod'
  );
  const [email, setEmail] = useState('');

  useEffect(() => {
    let url = new URL(window.location.href);
    const userEmail = url.searchParams.get('userEmail') || '';
    const userPhone = url.searchParams.get('userPhone') || '';
    if (userEmail) {
      // TODO: Auth0: revert this
      // setPreferredLoginMethod('single_email');
      setPreferredLoginMethod('email');
      setEmail(userEmail);
    }
    if (userPhone) {
      setPreferredLoginMethod('phone');
    }
  }, [setPreferredLoginMethod]);

  const showingLoginView = () => {
    switch (preferredLoginMethod) {
      // TODO: Auth0: revert this
      // case 'single_email':
      //   return (
      //     <SingleEmailSignInView
      //       setPreferredLoginMethod={setPreferredLoginMethod}
      //       setEmail={setEmail}
      //     />
      //   );
      case 'email':
        return <EmailSignInView setPreferredLoginMethod={setPreferredLoginMethod} email={email} />;
      case 'phone':
        return <PhoneSignInView setPreferredLoginMethod={setPreferredLoginMethod} />;
      default:
        return <DefaultSignInView setPreferredLoginMethod={setPreferredLoginMethod} />;
    }
  };

  return (
    <Grid container className="Auth">
      <Grid item lg={4} md={6} xs={12} sx={{ height: '100%' }}>
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          height="100%"
          padding={4}
        >
          <Grid
            container
            spacing={2}
            justifyContent="center"
            alignItems="center"
            style={{ margin: 10, padding: 20 }}
          >
            <Grid item xs={12} md={10} style={{ flex: 1 }}>
              <Box
                display="flex"
                flexDirection="column"
                justifyContent="center"
                alignItems="center"
              >
                <LivegisticsLogoLarge />
              </Box>
            </Grid>

            {showingLoginView()}
          </Grid>
        </Box>
      </Grid>
      <Grid item lg={8} md={6} xs={12} className="Auth__video">
        <video className="Auth__video--player" autoPlay loop muted>
          <source src="/static/videos/loginBackground.mp4" type="video/mp4" />
        </video>
      </Grid>
    </Grid>
  );
};

export default LoginView;
