import { logic } from 'lg-helpers';
import moment from 'moment';

const {
  dateTime: {
    datesAreInTheSameDay,
    getOnlyDateString,
    getDateStringForTimezone,
    getDateStringWithoutTimezone,
  },
} = logic;

const convertTimezone = (
  time: string,
  tzFrom: string,
  tzTo: string = Intl.DateTimeFormat().resolvedOptions().timeZone
): string => {
  const currentDate = moment().format('YYYY-MM-DD');
  const dateFrom = moment.tz(`${currentDate} ${time}`, tzFrom);
  const dateTo = dateFrom.clone().tz(tzTo);
  const timeString = dateTo.format('hh:mm');
  return timeString;
};

export {
  datesAreInTheSameDay,
  getOnlyDateString,
  getDateStringForTimezone,
  getDateStringWithoutTimezone,
  convertTimezone,
};
