import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectAuthUser, setAuthUser } from 'modules/auth/storeSliceAuth';
import Query from 'services/firebase/Query';
import Executer from 'services/firebase/Executer';
import useShowError from 'modules/errors';

const query = new Query();
const executer = new Executer();

const useGetTruckNumbersForUserCompany = () => {
  const dispatch = useDispatch();
  const [isLoadingTruckNumbers, setIsLoadingTruckNumbers] = useState(true);
  const [truckNumbers, setTruckNumbers] = useState<string[]>([]);
  const showError = useShowError();
  const authUser = useSelector(selectAuthUser);

  useEffect(() => {
    if (!authUser) {
      return;
    }

    if (authUser.computed?.truckNumbersInUserCompany) {
      setTruckNumbers(authUser.computed.truckNumbersInUserCompany);
      setIsLoadingTruckNumbers(false);
      return;
    }

    const loadTruckNumbers = async () => {
      setIsLoadingTruckNumbers(true);

      try {
        const users = await executer.getMultipleDocuments(
          query.users.getUsersInCompanies(authUser.companiesIds)
        );
        const truckNumbersSet = new Set<string>([]);
        users.forEach(user => {
          const { customData } = user;
          let truckNumber = (customData || {}).truckNumber as string;
          truckNumber = (truckNumber || '').trim();
          if (truckNumber && !truckNumbersSet.has(truckNumber)) {
            truckNumbersSet.add(truckNumber);
          }
        });

        const truckNrArray = [...truckNumbersSet];
        truckNrArray.sort();
        setTruckNumbers(truckNrArray);
        dispatch(
          setAuthUser({
            ...authUser,
            computed: {
              truckNumbersInUserCompany: truckNrArray,
            },
          })
        );
        setIsLoadingTruckNumbers(false);
      } catch (error) {
        console.error('Error', error);
        showError({
          title: 'Error while fetching the truck numbers',
          duration: 10000,
        });
        setIsLoadingTruckNumbers(false);
      }
    };

    loadTruckNumbers();
    // eslint-disable-next-line
  }, [authUser, dispatch]);

  return {
    isLoadingTruckNumbers,
    truckNumbers,
  };
};

export default useGetTruckNumbersForUserCompany;
