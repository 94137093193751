import React from 'react';
import { createSvgIcon } from '@mui/material';

export const Delete = createSvgIcon(
  <>
    <path d="M9 8c.55229 0 1 .44771 1 1v6.5c0 .5523-.44771 1-1 1s-1-.4477-1-1V9c0-.55229.44771-1 1-1ZM14 9c0-.55229-.4477-1-1-1s-1 .44771-1 1v6.5c0 .5523.4477 1 1 1s1-.4477 1-1V9Z" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.6445 0c-.3652 0-.70135.19908-.87689.51932L6.40778 3H1c-.55229 0-1 .44772-1 1s.44771 1 1 1h1.5v16c0 .5523.44772 1 1 1h15c.5523 0 1-.4477 1-1V5H21c.5523 0 1-.44772 1-1s-.4477-1-1-1h-5.402L14.2695.5268C14.0952.20239 13.7568 0 13.3885 0h-4.744Zm4.6832 3-.5371-1H9.23672l-.54816 1h4.63914ZM4.5 5v15h13V5h-13Z"
    />
  </>,
  'Delete'
);
