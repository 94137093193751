import React, { useState, useContext } from 'react';
import { Box, DialogContent } from '@mui/material';
import ModalHeader from 'sharedComponents/ModalHeader';
import DialogActionContainer from 'sharedComponents/DialogActionContainer';
import { upsertDivision, deleteDivision } from '../../redux/actions';
import { ITruckingTruckDivision } from 'lg-helpers/dist/trucking/interfaces/ITruckingTruckDivision';
import { IHelpers } from 'lg-helpers/dist/trucking/interfaces/IHelpers';
import { ModalContext } from 'sharedComponents/ModalContext';
import { FormTextField } from 'sharedComponents/FormTextField';
import { thunkDispatch } from 'store/store';

type DivisionFormProps = {
  editObj?: ITruckingTruckDivision;
};

export const DivisionForm = ({ editObj }: DivisionFormProps) => {
  const [division, setDivision] = useState({
    ...editObj,
  } as ITruckingTruckDivision);
  const [helpers, setHelpers] = useState<IHelpers>({});
  const { handleModal } = useContext(ModalContext);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setDivision({ ...division, [e.target.name]: e.target.value });
  };

  const onSubmit = async () => {
    if (!division.nameTruckingTruckDivision) {
      setHelpers({ nameTruckingTruckDivision: 'Name is required!' });
    } else {
      thunkDispatch(upsertDivision(division));
      handleModal();
    }
  };

  const handleDeleteCompanyMaterial = () => {
    if (editObj?.idTruckingTruckDivision) {
      thunkDispatch(deleteDivision(editObj.idTruckingTruckDivision));
      handleModal();
    }
  };

  return (
    <Box sx={{ p: '0px 8px' }}>
      <ModalHeader title={'Add Division'} />
      <DialogContent>
        <FormTextField
          name="nameTruckingTruckDivision"
          label="Division Name"
          value={division?.nameTruckingTruckDivision}
          onChange={handleChange}
          helperField={helpers.nameTruckingTruckDivision}
        />
        <FormTextField
          name="customIdTruckingTruckDivision"
          label="Division ID"
          value={division?.customIdTruckingTruckDivision}
          onChange={handleChange}
          helperField={helpers.customIdTruckingTruckDivision}
        />
        <DialogActionContainer
          saveHandler={onSubmit}
          deleteHandler={handleDeleteCompanyMaterial}
          editing={!!editObj}
        />
      </DialogContent>
    </Box>
  );
};
