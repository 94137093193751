import React, { useContext, useEffect, useMemo, useState } from 'react';
import {
  Grid,
  InputLabel,
  TextField,
  FormControl,
  FormHelperText,
  MenuItem,
  Box,
  Button,
  Tabs,
  Tab,
  Typography,
  Alert,
  AutocompleteRenderInputParams,
  IconButton,
} from '@mui/material';
import { useFormik } from 'formik';
import { mixPanel } from 'services/mixpanel';
import { ticketSchema } from './schemas';
import DialogActionContainer from 'sharedComponents/DialogActions';
import { useSelector } from 'react-redux';
import { RootState, thunkDispatch } from 'store/store';
import { getActivities } from '../Activities/redux/actions';
import { getProjectMaterials } from '../ProjectMaterials/redux/actions';
import { getCompanyMaterials } from '../CompanyMaterials/redux/actions';
import { getProjects } from '../Projects/redux/actions';
import { getProjectTasks } from '../ProjectTask/redux/actions';
import { INotification } from 'modules/civilAndTrucking/shared/NotificationSnackbar/INotification';
import { pushNotification } from 'modules/civilAndTrucking/shared/NotificationSnackbar/redux/actions';
import {
  addTicket,
  getManifestByNumber,
  updateTicket,
  getManifestExists,
  addTicketPhoto,
  getTransportersForProfile,
} from './redux/actions';
import { selectAuthUser } from 'modules/auth/storeSliceAuth';
import { ModalContext } from 'sharedComponents/ModalContext';
import { LoadingComponent } from 'sharedComponents/LoadingComponent';
import ManifestDisplayWithTemplateDataGathering from 'sharedComponents/ManifestDisplayWithTemplateDataGathering';
import { useHasAccess } from 'services/permissions';
import civilUploadImageAndGetUrl from 'utils/civilUploadImageAndGetUrl';
import { TicketPhotos } from './TicketPhotos';
import MultipleSitesAutocomplete from 'sharedComponents/ProjectSitesAutocomplete';
import { v4 as uuidv4 } from 'uuid';
import { getJoinedTrucksByCompanyId } from 'modules/civilAndTrucking/trucking/features/Trucks/redux/actions';
import { getTruckTypesByCompanyId } from 'modules/civilAndTrucking/trucking/features/TruckTypes/redux/actions';
import CivilCalculatorModal from 'sharedComponents/CivilWeightCalculator';
import CalculateIcon from '@mui/icons-material/Calculate';
import { IPhoto } from 'lg-helpers/dist/shared/interfaces/IPhoto';
import { IJoinTruck } from 'lg-helpers/dist/trucking/interfaces/IJoinTruck';
import { IJoinCompany } from 'lg-helpers/dist/shared/interfaces/IJoinCompany';
import { IActivityJoin } from 'lg-helpers/dist/shared/interfaces/IActivityJoin';
import { IProjectTask } from 'lg-helpers/dist/shared/interfaces/IProjectTask';
import { IProjectMaterial } from 'lg-helpers/dist/shared/interfaces/IProjectMaterial';
import { ITruckingTruckType } from 'lg-helpers/dist/trucking/interfaces/ITruckingTruckType';
import { ITicket } from 'lg-helpers/dist/shared/interfaces/ITicket';
import { IProject } from 'lg-helpers/dist/shared/interfaces/IProject';
import { ILocation } from 'lg-helpers/dist/shared/interfaces/ILocation';
import { ITicketForm } from 'lg-helpers/dist/shared/interfaces/ITicketForm';
import moment from 'moment';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { getDateStringWithoutTimezone, getOnlyDateString } from 'services/logic/dateTime';
import api, {
  ConnectedCompanyType,
  CompanyConnectionWithCompany,
  IActivityType,
} from 'services/api/autogenerated';
import useApiGetRequest from 'services/api/useApiGetRequest';
import { getActivityTypes } from '../ActivityTypes/redux/actions';
import ClearIcon from '@mui/icons-material/Clear';

// TODO ---> remove default Signature and put real signature in place.
const defaultSignature =
  'https://firebasestorage.googleapis.com/v0/b/wmmanifest-development.appspot.com/o/users%2FYLy4ymUUrieRMZIG8dXiPsk1VpJ3%2Fsignatures%2F20d5105d-114f-4295-8ced-cbe833324e1a.png?alt=media&token=b8a6cdd5-aaaa-43e0-b4d1-5005aad0a565';

export interface ICivilTicketFormWithDateValidation extends ITicketForm {
  projectStart?: Date;
  projectEnd?: Date;
}

interface AddTicketFormProps {
  selected?: ITicketForm;
  activeProjectId?: number;
}

export interface ITicketPhoto extends IPhoto {
  isNew?: boolean;
}

interface IProjectTaskState {
  id?: number;
  projectId?: number;
  activity?: string;
  activityId?: number;
  activityTypeName?: string;
  material?: string;
  materialId?: number;
  sourceSupplier?: string;
  sourceSupplierId?: string;
  autoTicketNumber?: boolean;
}

// TODO: logic with transporters was too complex to make changes, so it will be without refactoring
const AddTicketForm = ({ selected, activeProjectId }: AddTicketFormProps) => {
  const { handleModal } = useContext(ModalContext);
  const [projectTasks, setProjectTasks] = useState<IProjectTaskState[]>([]);
  const [limitedTransporters, setLimitedTransporters] = useState<CompanyConnectionWithCompany[]>();
  const [transporters, setTransporters] = useState<CompanyConnectionWithCompany[]>([]);
  const [ticketPhotos, setTicketPhotos] = useState<ITicketPhoto[]>([]);
  const [signature, setSignature] = useState<boolean>(false);
  const [projects, setProjects] = useState<IProject[]>();
  const [project, setProject] = useState<IProject>();
  const [manifest, setManifest] = useState<any>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [showManifest, setShowManifest] = useState<boolean>(false);
  const [showCalculator, setShowCalculator] = useState<boolean>(false);
  const [tabValue, setTabValue] = useState<string>('0');
  const [selectedCategory, setSelectedCategory] = useState<number | null>(null);
  const auth = useHasAccess(['tickets:create', 'tickets:update']);
  const [locationSearch, setLocationSearch] = useState<string>(
    selected?.addressName ?? (selected?.locationString || '')
  );
  const [location, setLocation] = useState<ILocation | null>();
  const [truck, setTruck] = useState<IJoinTruck>();
  const [truckTypes, setTruckTypes] = useState<string[]>(['Flat Bed', 'Train', 'Semi', 'Tanker']);

  const activityState: IActivityJoin[] = useSelector(
    (state: RootState) => state.civil.activities.data
  );

  const companyState = useSelector((state: RootState) => state.civil.companies.activeCompany);

  const materialState = useSelector((state: RootState) => state.civil.companyMaterials.data);

  const [transporterState] = useApiGetRequest(
    () => api.companiesConnections.getConnectedCompaniesByTypes([ConnectedCompanyType.Transporter]),
    () => ({ title: 'Failed to load transporters' })
  );

  const projectState: IProject[] = useSelector((state: RootState) => state.civil.projects.data);

  const projectTaskState: IProjectTask[] = useSelector(
    (state: RootState) => state.civil.projectTask.data
  );

  const projectActivityTypeState: IActivityType[] = useSelector(
    (state: RootState) => state.civil.activityTypes.data
  );

  const projectMaterialState: IProjectMaterial[] = useSelector(
    (state: RootState) => state.civil.projectMaterials.data
  );

  const companyTrucks: IJoinTruck[] = useSelector(
    (state: RootState) => state.trucking.trucks.dataByCompanies
  );

  const companyTruckTypes: ITruckingTruckType[] = useSelector(
    (state: RootState) => state.trucking.truckTypes.data
  );

  const authUser = useSelector(selectAuthUser);

  const handleSubmit = async (values: ICivilTicketFormWithDateValidation) => {
    // removing properties on values that ticket tables doesn't save
    if ((!selected && !signature) || (selected && !selected?.gateUserSignature && !signature)) {
      const errorNotification: INotification = {
        status: 'warning',
        message: 'Please sign the ticket before submitting.',
      };
      thunkDispatch(pushNotification(errorNotification));
      return;
    }

    if (project?.hasLocations) {
      if ((selected && !selected?.civilLocationId && !location) || (!selected && !location)) {
        const errorNotification: INotification = {
          status: 'warning',
          message: 'Please select a valid location.',
        };
        thunkDispatch(pushNotification(errorNotification));
        return;
      }
    }

    if (projectMaterial?.profileId) {
      if (!formik.values.manifestId) {
        const errorNotification: INotification = {
          status: 'warning',
          message: 'This Project Material has a Profile Number. You must assign a Manifest. ',
        };
        thunkDispatch(pushNotification(errorNotification));
        return;
      }
    }

    setIsLoading(true);
    // Same problem as here client/src/modules/civilAndTrucking/civil/views/Reconciliation/ReconciliationTable.tsx (line 100)
    const { createdByType, estimatedArrivalTime, ...ticketFields } = values as any; // eslint-disable-line

    const {
      materialId, // eslint-disable-line
      sourceSupplierId, // eslint-disable-line
      activity, // eslint-disable-line
      material, // eslint-disable-line
      sourceSupplier, // eslint-disable-line
      needsTicketNumber, // eslint-disable-line
      ticketImage, // eslint-disable-line
      price, // eslint-disable-line
      transporter, // eslint-disable-line
      manifestSearchValue, // eslint-disable-line
      supplierInvoiceNumber, // eslint-disable-line
      transporterInvoiceNumber, // eslint-disable-line
      totalPrice, // eslint-disable-line
      projectName, // eslint-disable-line
      pmMaterialId, // eslint-disable-line
      locationString, // eslint-disable-line
      truck: formTruck, // eslint-disable-line
      shouldTest, // eslint-disable-line
      cost, // eslint-disable-line
      totalCost, // eslint-disable-line
      quantityWithUnit, // eslint-disable-line
      count, // eslint-disable-line
      addressName, // eslint-disable-line
      projectStart, //eslint-disable-line
      projectEnd, //eslint-disable-line

      ...ticketData
    } = ticketFields as ICivilTicketFormWithDateValidation;
    if (!ticketData.projectTaskId) {
      ticketData.projectTaskId = projectTaskState.find(
        a =>
          a.materialId === formik.values.materialId &&
          a.sourceSupplierId === formik.values.sourceSupplierId &&
          a.activityId === formik.values.activityId
      )?.id;
    }
    if (!ticketData.id) {
      ticketData.createdByUserId = authUser.id;
      ticketData.createdByCompanyId = companyState?.id;
    }
    ticketData.gateUserSignature = authUser.signature ? authUser.signature?.url : defaultSignature;
    ticketData.civilLocationId = location?.id;

    // set manifest values
    ticketData.deleted = false; // if we're adding or updating a ticket, let's mark it not deleted.
    ticketData.isAccepted = ticketData?.isAccepted || false;
    const payload = {
      ticketData: ticketData,
      company: {
        id: companyState?.id ?? '',
        name: companyState?.name ?? '',
        types: companyState?.types ?? [],
      },
    };

    const result = ticketData.id
      ? await thunkDispatch(updateTicket(ticketData))
      : await thunkDispatch(addTicket(payload));
    if (result.meta.requestStatus === 'fulfilled') {
      const newTicket = result.payload as ITicket;

      if (newTicket.shouldTest) {
        const testNotification: INotification = {
          status: 'warning',
          message: `Be sure to take a sample from Ticket # ${newTicket.ticketNumber} for testing.`,
        };
        thunkDispatch(pushNotification(testNotification));
      }

      if (newTicket.id && authUser.id && ticketPhotos && ticketPhotos.some(img => img.isNew)) {
        try {
          const newPhotos: IPhoto[] = await Promise.all(
            ticketPhotos.filter(img => img.isNew).map(a => saveNewPhoto(a, newTicket.id!))
          );
          const tasks = newPhotos.map(photo =>
            thunkDispatch(
              addTicketPhoto({
                newPhotoData: photo,
                civilTicketId: newTicket.id!,
              })
            )
          );

          await Promise.all(tasks);
        } catch (error) {
          console.error('error saving photo', error);
          const errorNotification: INotification = {
            status: 'error',
            message: 'Error: Unable to add photo to ticket',
          };
          thunkDispatch(pushNotification(errorNotification));
        }
      }

      formik.resetForm();
      mixPanel(authUser, 'New Ticket Created', {
        Activity: newTicket?.activity || '',
        'Source Supplier': newTicket?.sourceSupplier || '',
        'Project ID': newTicket?.projectId || '',
        'Transporter Company': newTicket?.transporter || '',
        'Project Name': newTicket?.projectName || '',
      });
    }
    setTicketPhotos([]);
    setIsLoading(false);
    handleModal();
  };

  const formik = useFormik({
    initialValues: selected
      ? selected
      : activeProjectId
      ? ({ projectId: activeProjectId } as ICivilTicketFormWithDateValidation)
      : ({} as ICivilTicketFormWithDateValidation),
    validationSchema: ticketSchema,
    onSubmit: handleSubmit,
  });

  // DON'T WRITE CODE IN HOOKS. WRITE DECLARATIVE CODE!!!
  const projectMaterial = useMemo(
    () => projectMaterialState.find(pm => pm.id === formik.values.projectMaterialId),
    [formik.values.projectMaterialId, projectMaterialState]
  );

  const projectTask = useMemo(
    () =>
      projectTasks.find(
        pt =>
          pt.projectId === formik.values.projectId &&
          pt.materialId === formik.values.materialId &&
          pt.sourceSupplierId === formik.values.sourceSupplierId &&
          pt.activityId === formik.values.activityId
      ),
    [
      projectTasks,
      formik.values.projectId,
      formik.values.materialId,
      formik.values.sourceSupplierId,
      formik.values.activityId,
    ]
  );

  useEffect(() => {
    if (!companyTruckTypes || !companyTruckTypes.length) {
      return;
    }
    setTruckTypes(companyTruckTypes.map(t => t.nameTruckingTruckType).sort());
  }, [companyTruckTypes]);

  useEffect(() => {
    const arrayForSorting = [...projectState];
    setProjects(
      arrayForSorting.sort((a, b) => {
        if (!a || !a.name || !b || !b.name) {
          return 0;
        }
        return a.name.localeCompare(b.name);
      })
    );
  }, [projectState]);

  useEffect(() => {
    (async () => {
      setIsLoading(true);
      await Promise.all([
        thunkDispatch(getProjects()),
        thunkDispatch(getCompanyMaterials('all')),
        thunkDispatch(getJoinedTrucksByCompanyId()),
        thunkDispatch(getTruckTypesByCompanyId()),
      ]);
      setIsLoading(false);
    })();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (projectTaskState) {
      setProjectTasks(
        projectTaskState.map(pt => ({
          activity: activityState.find(activity => activity.id === pt.activityId)?.name,
          activityTypeName: activityState.find(activity => activity.id === pt.activityId)
            ?.activityTypeName,
          material: materialState.find(material => material.id === pt.materialId)?.name,
          ...pt,
        }))
      );
    }
  }, [projectTaskState, activityState, materialState]);

  const saveNewPhoto = async (ticketPhoto: ITicketPhoto, ticketId: number) => {
    const photoUrl = await civilUploadImageAndGetUrl(
      ticketPhoto.photoUrl!, //for new images, this will be base64
      `civil/users/${authUser.id}/tickets/${ticketId}/civil_ticket/${uuidv4()}`
    );

    const NewPhoto: IPhoto = {
      civilTicketId: ticketId,
      photoUrl: photoUrl as string,
      category: ticketPhoto.category,
      createdByCompanyId: companyState?.id!,
      createdByUserId: authUser.id,
    };

    return NewPhoto;
  };

  useEffect(() => {
    (async () => {
      if (formik.values.projectId) {
        setProject(projectState.filter(x => x.id === formik.values.projectId)[0]);
        try {
          setIsLoading(true);
          await Promise.all([
            thunkDispatch(getProjectTasks(formik.values.projectId as number)),
            thunkDispatch(getActivityTypes({ projectId: formik.values.projectId as number })),
            thunkDispatch(
              getActivities({
                type: 'all',
                projectId: Number(formik.values.projectId),
              })
            ),

            thunkDispatch(
              getProjectMaterials({
                projectId: formik.values.projectId as number,
                type: 'active',
              })
            ),
          ]);
          setIsLoading(false);
        } catch (err) {
          console.error('error:', err);
          setIsLoading(false);
        }
      }
    })();
  }, [formik.values.projectId, projectState]);

  useEffect(() => {
    if (!project) return;

    const projectStart = project.startDate
      ? moment(getOnlyDateString(project.startDate, project.timeZone || 'EST')).toDate()
      : new Date(0);
    const projectEnd = project.endDate
      ? moment(getOnlyDateString(project.endDate, project.timeZone || 'EST')).toDate()
      : new Date();

    formik.setFieldValue('projectStart', projectStart ?? undefined);
    formik.setFieldValue('projectEnd', projectEnd ?? undefined);

    if (selected) return;

    // note: time zone name is _not_ nullable in the db
    const now = moment.tz(project.timeZone || 'EST');
    const defaultDateCreated = moment
      .max(
        moment.min(project.endDate ? moment.tz(project.endDate, project.timeZone!) : now, now),
        moment.tz(project.startDate || 0, project.timeZone!)
      )
      .toISOString();
    formik.setFieldValue('dateCreated', defaultDateCreated);
  }, [project]); //eslint-disable-line

  useEffect(() => {
    if (!projectMaterial) {
      return;
    }

    formik.setFieldValue('materialId', projectMaterial?.materialId!);
    formik.setFieldValue('sourceSupplierId', projectMaterial?.sourceSupplierId!);

    if (projectMaterial?.profileId) {
      // try to get the new transporters from the transporter end point.

      const fetchData = async () => {
        setIsLoading(true);
        try {
          const result = await thunkDispatch(
            getTransportersForProfile({
              profileId: projectMaterial?.profileId,
              searchText: '',
            })
          );
          if (result.meta.requestStatus === 'fulfilled') {
            const verifiedTransporters = result.payload as IJoinCompany[];
            const transporterArray = [] as CompanyConnectionWithCompany[];

            // TODO: Look into how we can refactor this to be cleaner https://bitbucket.org/livegistics/manifest/pull-requests/1051/on-returned-profile-ids-only-show-the#comment-286896924
            verifiedTransporters.forEach(verifiedTransporter => {
              const limitedTransporter = (transporterState || []).filter(
                x => x.connected_company_id === verifiedTransporter.id
              )[0];
              if (limitedTransporter) {
                transporterArray.push(limitedTransporter);
              }
            });
            setLimitedTransporters(transporterArray);
          }
        } catch (error) {
          //console.error(error);
        } finally {
          setIsLoading(false);
        }
      };
      fetchData();
    }
    // eslint-disable-next-line
  }, [projectMaterial]);

  useEffect(() => {
    formik.setFieldValue('projectTaskId', projectTask?.id);
    if (projectTask && projectTask.autoTicketNumber) {
      formik.setFieldValue('needsTicketNumber', false);
    } else if (projectTask && !projectTask.autoTicketNumber) {
      formik.setFieldValue('needsTicketNumber', true);
    }
    // eslint-disable-next-line
  }, [projectTask]);

  const filterProjectMaterials = () => {
    let eligibleProjectMaterials: IProjectMaterial[] = [];
    if (!projectMaterialState.length || !projectTaskState.length) {
      return [];
    } else if (selected && selected.materialId) {
      eligibleProjectMaterials = projectMaterialState.filter(
        pm => pm.materialId === selected.materialId
      );
    } else if (selected && selected.projectMaterialId) {
      eligibleProjectMaterials = projectMaterialState.filter(
        pm => pm.id === selected.projectMaterialId
      );
    } else {
      eligibleProjectMaterials = projectMaterialState.filter(pm =>
        projectTaskState.some(
          pt =>
            pt.materialId === pm.materialId &&
            pt.sourceSupplierId === pm.sourceSupplierId &&
            pt.activityId === formik.values.activityId
        )
      );
    }
    const uniqueEligibleProjectMaterials = eligibleProjectMaterials.filter(
      (v, i, a) =>
        a.findIndex(
          t => t.materialId === v.materialId && t.sourceSupplierId === v.sourceSupplierId
        ) === i
    );

    return uniqueEligibleProjectMaterials;
  };

  const filterActivities = (category = selectedCategory) => {
    let eligibleTasks: IProjectTaskState[] = [];
    if (!projectTaskState.length || !projectMaterialState.length) {
      return [];
    } else if (selected && selected.materialId) {
      eligibleTasks = projectTasks.filter(
        pt =>
          // only show activities for project tasks of this project
          pt.projectId === formik.values.projectId &&
          // only show activities from tasks that have an active project material
          projectMaterialState
            .filter(pm => pm.materialId === selected.materialId)
            .some(
              pm => pm.materialId === pt.materialId && pm.sourceSupplierId === pt.sourceSupplierId
            )
      );
    } else if (selected && selected.projectMaterialId) {
      eligibleTasks = projectTasks.filter(
        pt =>
          pt.projectId === formik.values.projectId &&
          pt.materialId === selected.pmMaterialId &&
          pt.sourceSupplierId === selected.sourceSupplierId
      );
    } else {
      eligibleTasks = projectTasks.filter(
        pt =>
          // only show activities for project tasks of this project
          pt.projectId === formik.values.projectId &&
          // only show activities from tasks that have an active project material
          projectMaterialState.some(
            pm => pm.materialId === pt.materialId && pm.sourceSupplierId === pt.sourceSupplierId
          )
      );
    }

    if (category)
      eligibleTasks = eligibleTasks.filter(
        et => activityState.find(a => a.id === et.activityId)?.activityTypeId === category
      );

    const uniqueEligibleTasks = eligibleTasks
      .filter((pt, index, arr) => arr.findIndex(t => t?.activityId === pt?.activityId) === index)
      .sort((a, b) => {
        if (!a || !b) {
          return 0;
        }
        return (
          (a?.activityTypeName || '').localeCompare(b?.activityTypeName || '') ||
          (a?.activity || '').localeCompare(b?.activity || '')
        );
      });

    return uniqueEligibleTasks;
  };

  const filterCategories = () => {
    let eligibleCategories: IActivityType[] = [];
    const taskIds = filterActivities(null).map(
      pt => activityState.find(a => a.id === pt.activityId)?.activityTypeId
    );
    if (!projectActivityTypeState.length || !projectMaterialState.length) {
      return [];
    } else {
      eligibleCategories = projectActivityTypeState.filter(
        pc => pc.projectId === formik.values.projectId && pc?.count != 0 && taskIds.includes(pc.id)
      );
    }

    const uniqueEligibleCategories = eligibleCategories
      .filter((pc, index, arr) => arr.findIndex(t => t?.id === pc?.id) === index)
      .sort((a, b) => {
        if (!a || !b) {
          return 0;
        }
        return (a?.name || '').localeCompare(b?.name || '');
      });

    return uniqueEligibleCategories;
  };

  /* // not in use on web, but is in use on mobile.
  const getNextUnsignedManifestClick = async () => {
    setIsLoading(true);
    const result = await thunkDispatch(
      getNextUnsignedManifest({
        profileId: projectMaterial?.profileId,
        searchText: '',
      })
    );
    if (result.meta.requestStatus === 'fulfilled') {
      setManifest(result.payload);
    }
    setIsLoading(false);
  }; */

  const getManifestByNumberClick = async () => {
    setIsLoading(true);
    const result = await thunkDispatch(
      getManifestByNumber({
        profileId: projectMaterial?.profileId,
        searchText: formik.values.manifestSearchValue,
      })
    );
    if (result.meta.requestStatus === 'fulfilled') {
      setManifest(result.payload);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    if (manifest) {
      formik.values.manifestId = manifest.id;
      formik.values.manifestNumber = manifest.number;
      formik.values.wmWeighTicketNumber = (manifest?.receipt?.id || '').toString();
      if (manifest?.receipt?.weight?.rounded_net) {
        formik.setFieldValue('quantity', manifest?.receipt?.weight?.rounded_net);
      }
      if (manifest?.signatureDriver?.truckNumber) {
        formik.setFieldValue('truckNumber', manifest?.signatureDriver?.truckNumber);
      }
      // formik.setFieldValue('manifestNumber', null);
      thunkDispatch(
        getManifestExists({
          profileId: '',
          searchText: formik.values.manifestId,
        })
      );
    }
    // eslint-disable-next-line
  }, [
    manifest,
    formik.values.manifestId,
    formik.values.manifestNumber,
    formik.values.wmWeighTicketNumber,
  ]);

  const clearManifest = async () => {
    setManifest(undefined);
    formik.setFieldValue('manifestId', null);
    formik.setFieldValue('manifestNumber', null);
    formik.setFieldValue('quantity', null);
  };

  useEffect(() => {
    if (!transporterState) {
      return;
    }
    if (companyTrucks && companyTrucks.length) {
      if (selected && selected.transporterId === companyState?.id) {
        setTruck(
          companyTrucks.find(
            a =>
              a.nameTruckingTruckType === selected.equipment &&
              a.truckNumberTruckingTruck === selected.truckNumber
          )
        );
      }
      const currentCompany = {
        // TODO: wtf?
        connected_company_id: companyState?.id,
        connected_company: {
          transporterId: companyState?.id,
          types: ['broker'],
          name: companyState?.name,
        },
      } as unknown as CompanyConnectionWithCompany;
      setTransporters([currentCompany, ...transporterState]);
    } else {
      setTransporters(transporterState);
    }
  }, [transporterState, companyTrucks, companyState, selected]);

  useEffect(() => {
    if (!truck) {
      return;
    }
    formik.setFieldValue('equipment', truck.nameTruckingTruckType);
    formik.setFieldValue('truckNumber', truck.truckNumberTruckingTruck);
    // eslint-disable-next-line
  }, [truck]);

  return (
    <>
      {showManifest && formik.values.manifestId ? (
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Button
              color="secondary"
              onClick={() => {
                setShowManifest(false);
              }}
            >
              Close Manifest
            </Button>
            <ManifestDisplayWithTemplateDataGathering manifestId={formik.values.manifestId} />
            <Button
              color="secondary"
              onClick={() => {
                setShowManifest(false);
              }}
            >
              Close Manifest
            </Button>
          </Grid>
        </Grid>
      ) : (
        <>
          <Grid container spacing={2}>
            <Grid item md={4}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <FormControl fullWidth>
                    <InputLabel shrink htmlFor="projectId">
                      Project
                    </InputLabel>
                    <TextField
                      select
                      variant="outlined"
                      name="projectId"
                      value={formik.values.projectId || ''}
                      error={!!formik.errors.projectId}
                      helperText={formik.errors.projectId}
                      onChange={event => {
                        formik.setFieldValue('activityId', undefined);
                        formik.setFieldValue('materialId', undefined);
                        formik.setFieldValue('sourceSupplierId', undefined);
                        formik.handleChange(event);
                        setSelectedCategory(null);
                      }}
                      disabled={!auth.hasAccess}
                    >
                      {projects && projects.length ? (
                        projects.map(p => (
                          <MenuItem value={p.id} key={p.id}>
                            {p.name}
                          </MenuItem>
                        ))
                      ) : (
                        <MenuItem>No Options Available</MenuItem>
                      )}
                    </TextField>
                  </FormControl>
                </Grid>

                <Grid item xs={12}>
                  <FormControl fullWidth>
                    <InputLabel shrink>Ticket Date</InputLabel>
                    <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale="en-us">
                      <DatePicker
                        value={
                          formik.values.dateCreated
                            ? moment(
                                getOnlyDateString(
                                  formik.values.dateCreated,
                                  project?.timeZone || 'EST'
                                )
                              )
                            : null
                        }
                        onChange={val =>
                          formik.setFieldValue(
                            'dateCreated',
                            moment
                              .tz(
                                getDateStringWithoutTimezone(val),
                                'YYYY-MM-DD',
                                project?.timeZone || 'EST'
                              )
                              .toISOString()
                          )
                        }
                        slotProps={{
                          textField: {
                            helperText: formik.errors.dateCreated,
                          },
                        }}
                        minDate={moment(formik.values.projectStart)}
                        maxDate={moment(formik.values.projectEnd)}
                      />
                    </LocalizationProvider>
                  </FormControl>
                </Grid>

                {project && project.hasLocations && (
                  <Grid item xs={12}>
                    <FormControl fullWidth>
                      <InputLabel shrink htmlFor="location">
                        Location
                      </InputLabel>
                      <MultipleSitesAutocomplete
                        renderInput={(params: AutocompleteRenderInputParams) => (
                          <TextField
                            {...params}
                            name="location"
                            placeholder="Search requires at least 3 characters"
                            onChange={event => {
                              setLocationSearch(event.target.value);
                            }}
                            variant="outlined"
                            error={!location && !locationSearch}
                            helperText={!location && !locationSearch ? 'Required' : null}
                          />
                        )}
                        options={[]}
                        inputValue={locationSearch || ''}
                        projectId={formik.values.projectId as number}
                        searchRequirement={3}
                        handleSelect={(value?: ILocation) => {
                          setLocation(value || null);
                          setLocationSearch(value?.name || value?.addressString || '');
                        }}
                      />
                    </FormControl>
                  </Grid>
                )}

                <Grid item xs={12}>
                  <FormControl fullWidth>
                    <InputLabel shrink htmlFor="projectId">
                      Category
                    </InputLabel>
                    <TextField
                      select
                      disabled={!projectActivityTypeState?.length || !auth.hasAccess}
                      variant="outlined"
                      name="activityType"
                      value={selectedCategory || ''}
                      onChange={event => {
                        setSelectedCategory(parseInt(event.target.value));
                        formik.setFieldValue('activityId', undefined);
                      }}
                      InputProps={{
                        endAdornment: selectedCategory ? (
                          <IconButton
                            aria-label="clear category"
                            onClick={() => {
                              setSelectedCategory(null);
                            }}
                            onMouseDown={() => {
                              setSelectedCategory(null);
                            }}
                          >
                            <ClearIcon />
                          </IconButton>
                        ) : (
                          ''
                        ),
                      }}
                    >
                      {projectActivityTypeState.some(
                        pt => pt.projectId === formik.values.projectId
                      ) ? (
                        filterCategories().length ? (
                          filterCategories().map(filtered => (
                            <MenuItem value={filtered.id} key={filtered.id}>
                              {filtered.name}
                            </MenuItem>
                          ))
                        ) : (
                          <MenuItem>
                            No options found associated with the selected project material
                          </MenuItem>
                        )
                      ) : (
                        <MenuItem>No options available</MenuItem>
                      )}
                    </TextField>
                  </FormControl>
                </Grid>

                <Grid item xs={12}>
                  <FormControl fullWidth>
                    <InputLabel shrink htmlFor="projectId">
                      Activity
                    </InputLabel>
                    <TextField
                      select
                      disabled={!formik.values.projectId || !auth.hasAccess}
                      variant="outlined"
                      name="activityId"
                      value={formik.values.activityId || ''}
                      error={!!formik.errors.activityId}
                      helperText={formik.errors.activityId}
                      onChange={event => {
                        // Only reset project material while editing
                        // if ticket is not created by webhook
                        if (!formik.values.supplierPitTicketId) {
                          formik.setFieldValue('activityId', undefined);
                          formik.setFieldValue('materialId', undefined);
                          formik.setFieldValue('sourceSupplierId', undefined);
                        }
                        formik.handleChange(event);
                      }}
                    >
                      {projectTasks.some(pt => pt.projectId === formik.values.projectId) ? (
                        filterActivities().length ? (
                          filterActivities().map(filtered => (
                            <MenuItem value={filtered.activityId} key={filtered.id}>
                              {filtered.activityTypeName} - {filtered.activity}
                            </MenuItem>
                          ))
                        ) : (
                          <MenuItem>
                            No options found associated with the selected project material
                          </MenuItem>
                        )
                      ) : (
                        <MenuItem>No options available</MenuItem>
                      )}
                    </TextField>
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <FormControl fullWidth>
                    <InputLabel shrink htmlFor="projectMaterialId">
                      Project Material
                    </InputLabel>
                    <TextField
                      select
                      variant="outlined"
                      disabled={!formik.values.projectId || !auth.hasAccess}
                      value={formik.values.projectMaterialId || ''}
                      error={!!formik.errors.projectMaterialId}
                      helperText={formik.errors.projectMaterialId}
                      name="projectMaterialId"
                      onChange={event => {
                        formik.handleChange(event);
                      }}
                    >
                      {projectMaterialState.length ? (
                        filterProjectMaterials().map(material => (
                          <MenuItem value={material.id} key={material.id}>
                            {(() => {
                              // TODO: remove this? why do we have materialState (???)
                              // Hm, we don't need this materialObject here
                              // const materialObj = materialState.find(
                              //   item => item.id === material.materialId
                              // );
                              return `${material?.material} - ${material?.sourceSupplier}`;
                            })()}
                          </MenuItem>
                        ))
                      ) : (
                        <MenuItem>No project materials exist</MenuItem>
                      )}
                    </TextField>
                  </FormControl>
                </Grid>

                <Grid item xs={12}>
                  <FormControl fullWidth>
                    <InputLabel shrink htmlFor="transporterId">
                      {projectMaterial && projectMaterial.profileId
                        ? 'Verified Transporter'
                        : 'Transporter'}
                    </InputLabel>
                    <TextField
                      select
                      name="transporterId"
                      value={formik.values.transporterId || ''}
                      error={!!formik.errors.transporterId}
                      helperText={formik.errors.transporterId}
                      onChange={formik.handleChange}
                      variant="outlined"
                      disabled={!auth.hasAccess}
                    >
                      {projectMaterial && projectMaterial.profileId ? ( // wtf?
                        limitedTransporters && limitedTransporters.length > 0 ? (
                          limitedTransporters.map(transporter => (
                            <MenuItem
                              value={transporter.connected_company_id}
                              key={transporter.connected_company_id}
                            >
                              {transporter.connected_company.name}
                            </MenuItem>
                          ))
                        ) : (
                          <MenuItem value="" key="">
                            {` No Verified Transporters for this Source are on your company's preferred transporter list. Contact a PM. `}
                          </MenuItem>
                        )
                      ) : (
                        transporters.map(transporter => (
                          <MenuItem
                            value={transporter.connected_company_id}
                            key={transporter.connected_company_id}
                          >
                            {transporter.connected_company.name}
                          </MenuItem>
                        ))
                      )}
                    </TextField>
                    <FormHelperText />
                  </FormControl>
                </Grid>
                {formik.values.transporterId &&
                  (transporterState || [])
                    .find(a => a.connected_company_id === formik.values.transporterId)
                    ?.connected_company.types.includes('trucking') && (
                    <Grid item xs={12}>
                      <Typography variant="caption">
                        You should not create a ticket for a Trucking company. Please inform the
                        dispatcher from{' '}
                        {
                          (transporterState || []).find(
                            a => a.connected_company_id === formik.values.transporterId
                          )?.connected_company.name
                        }{' '}
                        that their driver needs to create a ticket for you to accept
                      </Typography>
                    </Grid>
                  )}
                <Grid item xs={6}>
                  <FormControl fullWidth>
                    <InputLabel shrink htmlFor="brokerCarrier">
                      Carrier
                    </InputLabel>
                    <TextField
                      name="brokerCarrier"
                      value={formik.values.brokerCarrier || ''}
                      error={formik.touched.brokerCarrier && !!formik.errors.brokerCarrier}
                      helperText={formik.touched.brokerCarrier && formik.errors.brokerCarrier}
                      onChange={formik.handleChange}
                      variant="outlined"
                      disabled={!auth.hasAccess}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={6}>
                  <FormControl fullWidth>
                    <InputLabel shrink htmlFor="trailerNumber">
                      Trailer Number
                    </InputLabel>
                    <TextField
                      name="trailerNumber"
                      value={formik.values.trailerNumber || ''}
                      error={formik.touched.trailerNumber && !!formik.errors.trailerNumber}
                      helperText={formik.touched.trailerNumber && formik.errors.trailerNumber}
                      onChange={formik.handleChange}
                      variant="outlined"
                      disabled={!auth.hasAccess}
                    />
                  </FormControl>
                </Grid>
                {formik.values.transporterId !== companyState?.id ? (
                  <>
                    <Grid item xs={6}>
                      <FormControl fullWidth>
                        <InputLabel shrink htmlFor="truckNumber">
                          Truck Number
                        </InputLabel>
                        <TextField
                          name="truckNumber"
                          value={formik.values.truckNumber || ''}
                          error={!!formik.errors.truckNumber}
                          helperText={formik.errors.truckNumber}
                          onChange={formik.handleChange}
                          variant="outlined"
                          disabled={!auth.hasAccess || manifest?.signatureDriver?.truckNumber}
                        ></TextField>
                      </FormControl>
                    </Grid>
                    <Grid item xs={6}>
                      <FormControl fullWidth>
                        <InputLabel shrink htmlFor="equipment">
                          Truck Type
                        </InputLabel>
                        <TextField
                          select
                          name="equipment"
                          value={formik.values.equipment || ''}
                          error={!!formik.errors.equipment}
                          helperText={formik.errors.equipment}
                          onChange={formik.handleChange}
                          variant="outlined"
                          disabled={!auth.hasAccess}
                        >
                          {truckTypes.map((equipment, index) => (
                            <MenuItem value={equipment} key={index}>
                              {equipment}
                            </MenuItem>
                          ))}
                        </TextField>
                      </FormControl>
                    </Grid>
                  </>
                ) : (
                  <Grid item xs={12}>
                    <FormControl fullWidth>
                      <InputLabel shrink htmlFor="truck">
                        Truck
                      </InputLabel>
                      <TextField
                        select
                        variant="outlined"
                        disabled={!formik.values.projectId || !auth.hasAccess}
                        value={truck?.idTruckingTruck || 0}
                        error={!!formik.errors.truckNumber || !!formik.errors.equipment}
                        helperText={formik.errors.truckNumber || formik.errors.equipment}
                        name="truck"
                        onChange={event => {
                          if (event.target.value) {
                            const t = companyTrucks.find(
                              a => a.idTruckingTruck === Number(event.target.value)
                            );
                            setTruck(t);
                          }
                        }}
                      >
                        {companyTrucks.map(t => (
                          <MenuItem key={t.idTruckingTruck} value={t.idTruckingTruck}>
                            {`${t.truckNumberTruckingTruck} - ${t.nameTruckingTruckType}`}
                          </MenuItem>
                        ))}
                      </TextField>
                    </FormControl>
                  </Grid>
                )}
                <Grid item xs={12}>
                  <FormControl fullWidth>
                    <InputLabel shrink htmlFor="ticketNumber">
                      Ticket #
                    </InputLabel>
                    <TextField
                      name="ticketNumber"
                      required={formik.values.needsTicketNumber}
                      value={
                        formik.values.ticketNumber
                          ? formik.values.ticketNumber
                          : !formik.values.needsTicketNumber
                          ? 'Auto Generated'
                          : ''
                      }
                      error={!!formik.errors.ticketNumber}
                      helperText={formik.errors.ticketNumber}
                      disabled={!auth.hasAccess || !formik.values.needsTicketNumber}
                      onChange={formik.handleChange}
                      variant="outlined"
                    />
                    <FormHelperText />
                  </FormControl>
                </Grid>
                <Grid item xs={projectMaterial && projectMaterial.units === 'Tons' ? 6 : 12}>
                  <FormControl fullWidth>
                    <InputLabel shrink htmlFor="quantity">
                      Quantity {projectMaterial?.units && `(${projectMaterial.units})`}
                    </InputLabel>
                    <TextField
                      type="number"
                      name="quantity"
                      value={formik.values.quantity || ''}
                      error={!!formik.errors.quantity}
                      helperText={formik.errors.quantity}
                      onChange={formik.handleChange}
                      variant="outlined"
                      disabled={!auth.hasAccess || manifest?.receipt?.weight?.rounded_net}
                      InputProps={{
                        endAdornment: (projectMaterial?.units === 'Tons' ||
                          projectMaterial?.units === 'Gross Tons') && (
                          <CalculateIcon
                            onClick={() => setShowCalculator(!showCalculator)}
                            sx={{ cursor: 'pointer' }}
                          />
                        ),
                      }}
                    />
                  </FormControl>
                </Grid>
                {projectMaterial && projectMaterial.units === 'Tons' && (
                  <Grid item xs={6}>
                    <FormControl fullWidth>
                      <InputLabel shrink htmlFor="volume">
                        Volume (yards)
                      </InputLabel>
                      <TextField
                        name="volume"
                        value={formik.values.volume || ''}
                        error={formik.touched.volume && !!formik.errors.volume}
                        helperText={formik.touched.volume && formik.errors.volume}
                        onChange={formik.handleChange}
                        variant="outlined"
                        disabled={!auth.hasAccess}
                      >
                        <MenuItem>No options available</MenuItem>
                      </TextField>
                    </FormControl>
                  </Grid>
                )}
                {projectMaterial && projectMaterial.profileId ? (
                  <>
                    {formik.values.manifestId ? (
                      <Grid item xs={12}>
                        <Typography variant="h6">
                          Manifest {projectMaterial.profileType && 'for'}{' '}
                          {projectMaterial.profileType}
                        </Typography>
                        <Box display="flex" alignSelf="flex-end" height="100%">
                          <Button
                            color="primary"
                            variant="contained"
                            onClick={() => {
                              setShowManifest(true);
                            }}
                          >
                            View Manifest {formik.values.manifestNumber}
                          </Button>
                          <Button
                            color="primary"
                            variant="contained"
                            onClick={() => {
                              clearManifest();
                            }}
                            disabled={!auth.hasAccess}
                          >
                            UnLink
                          </Button>
                        </Box>
                      </Grid>
                    ) : (
                      <>
                        <Grid item xs={8}>
                          <FormControl fullWidth>
                            <Typography variant="h6">
                              Manifest {projectMaterial.profileType && 'for'}{' '}
                              {projectMaterial.profileType}
                            </Typography>
                            <TextField
                              name="manifestSearchValue"
                              value={formik.values.manifestSearchValue || ''}
                              error={
                                formik.touched.manifestSearchValue &&
                                !!formik.errors.manifestSearchValue
                              }
                              helperText={
                                formik.touched.manifestSearchValue &&
                                formik.errors.manifestSearchValue
                              }
                              onChange={formik.handleChange}
                              variant="outlined"
                            />
                          </FormControl>
                        </Grid>
                        <Grid item xs={4}>
                          <Box display="flex" alignSelf="flex-end" height="100%">
                            <Button
                              color="primary"
                              variant="contained"
                              onClick={() => {
                                getManifestByNumberClick();
                              }}
                              disabled={!auth.hasAccess}
                            >
                              Link
                            </Button>
                          </Box>
                        </Grid>
                      </>
                    )}
                  </>
                ) : (
                  <Grid item xs={12}>
                    <FormControl fullWidth>
                      <InputLabel shrink htmlFor="pitTicketNumber">
                        Pit Ticket #
                      </InputLabel>
                      <TextField
                        name="pitTicketNumber"
                        value={formik.values.pitTicketNumber || ''}
                        error={!!formik.errors.pitTicketNumber}
                        helperText={formik.errors.pitTicketNumber}
                        onChange={formik.handleChange}
                        variant="outlined"
                        disabled={!auth.hasAccess}
                      />
                    </FormControl>
                  </Grid>
                )}
              </Grid>
            </Grid>
            <Grid item md={8}>
              <Grid container>
                <Grid item xs={12}>
                  <TicketPhotos
                    ticketPhotos={ticketPhotos}
                    setTicketPhotos={setTicketPhotos}
                    ticketId={selected ? selected.id : undefined}
                    pitTicketId={selected?.supplierPitTicketId || undefined}
                  />
                </Grid>
                <Grid item xs={12} marginTop={5}>
                  <FormControl fullWidth>
                    <Typography variant="h6" sx={{ fontSize: 12 }}>
                      Note
                    </Typography>
                    <TextField
                      name="note"
                      value={formik.values.note || ''}
                      error={!!formik.errors.note}
                      helperText={formik.errors.note}
                      onChange={formik.handleChange}
                      variant="outlined"
                      disabled={!auth.hasAccess}
                      multiline
                      rows={5}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="h6" sx={{ fontSize: 12 }}>
                    Signatures
                  </Typography>
                  <Tabs
                    value={tabValue}
                    indicatorColor="primary"
                    textColor="primary"
                    onChange={(event, newValue) => {
                      setTabValue(newValue);
                    }}
                  >
                    <Tab label="Site User" value="0" />
                    <Tab label="Driver" value="1" />
                  </Tabs>
                  <Box
                    height={120}
                    display="flex"
                    justifyContent="center"
                    border="0.25px solid #6D7066"
                    sx={{ objectFit: 'cover' }}
                  >
                    {tabValue === '0' && (
                      <FormControl fullWidth>
                        <Grid container height="100%">
                          <Grid
                            item
                            height="100%"
                            xs={12}
                            display="flex"
                            alignItems="center"
                            justifyContent="center"
                          >
                            {(!selected && !signature) ||
                            (selected && !selected.gateUserSignature && !signature) ? (
                              <Button
                                color="secondary"
                                onClick={() => {
                                  setSignature(!signature);
                                }}
                                disabled={!auth.hasAccess}
                              >
                                Sign
                              </Button>
                            ) : (
                              <img
                                style={{ maxWidth: 380, maxHeight: 120 }}
                                alt="gate attendant signature"
                                src={
                                  !selected
                                    ? authUser.signature
                                      ? authUser.signature.url
                                      : defaultSignature
                                    : selected.gateUserSignature
                                    ? selected.gateUserSignature
                                    : authUser.signature
                                    ? authUser.signature.url
                                    : defaultSignature
                                }
                              />
                            )}
                          </Grid>
                        </Grid>
                      </FormControl>
                    )}

                    {tabValue === '1' && (
                      <FormControl fullWidth>
                        <Grid container height="100%">
                          <Grid
                            item
                            height="100%"
                            xs={12}
                            display="flex"
                            alignItems="center"
                            justifyContent="center"
                          >
                            {selected?.driverSignature ? (
                              <img
                                style={{ maxWidth: 380, maxHeight: 120 }}
                                alt="driver signature"
                                src={!selected ? '' : selected.driverSignature}
                              />
                            ) : (
                              <Typography>No driver signature</Typography>
                            )}
                          </Grid>
                        </Grid>
                      </FormControl>
                    )}
                  </Box>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          {showCalculator && (
            <CivilCalculatorModal
              isOpen={showCalculator}
              setShowCalculator={setShowCalculator}
              unit={projectMaterial?.units!}
              grossQuantity={formik.values.grossQuantity}
              tareQuantity={formik.values.tareQuantity}
              setQuantity={(grossQuantity: number, tareQuantity: number, quantity: number) =>
                formik.setValues({
                  ...formik.values,
                  grossQuantity,
                  tareQuantity,
                  quantity: quantity,
                })
              }
            />
          )}
          {project && project.locationId ? (
            auth.hasAccess && (
              <DialogActionContainer
                style={{ marginTop: '40px' }}
                saveHandler={formik.handleSubmit}
              />
            )
          ) : (
            <Alert severity="warning">
              {`This project does not have a location. Please provide a location before creating a ticket.`}
            </Alert>
          )}
          <LoadingComponent isLoading={isLoading} />
        </>
      )}
    </>
  );
};

export default AddTicketForm;
