import React, { useState, useEffect, useContext } from 'react';
import { Grid, DialogContent, Box } from '@mui/material';
import { thunkDispatch } from 'store/store';
import ModalHeader from 'sharedComponents/ModalHeader';
import DialogActionContainer from 'sharedComponents/DialogActionContainer';
import { getDivisionsByCompanyId } from 'modules/civilAndTrucking/trucking/features/Divisions/redux/actions';
import {
  getJoinedTrucksByCompanyId,
  getJoinedTrucksByDivisionId,
} from 'modules/civilAndTrucking/trucking/features/Trucks/redux/actions';
import { ModalContext } from 'sharedComponents/ModalContext';
import { upsertLoad } from '../../../../Loads/redux/actions';
import { getLoadsByDispatchId } from 'modules/civilAndTrucking/trucking/features/Loads/redux/actions';
import { AddLoadsDispatchInfo } from './components/DispatchInfo';
import { TruckLoadsTable } from './components/TruckLoadsTable';
import { getTruckTypesByCompanyId } from 'modules/civilAndTrucking/trucking/features/TruckTypes/redux/actions';
import { Loading } from 'sharedComponents/Loading';
import ButtonToggle from 'sharedComponents/ButtonToggle';
import { TrucksInDivisionList } from './components/TrucksInDivisionList';
import { AddTrucksToBroker } from './components/AddTrucksToBroker';
import { addBrokerCompanyToDispatch } from 'modules/civilAndTrucking/trucking/features/Companies/redux/actions';
import { IJoinTruck } from 'lg-helpers/dist/trucking/interfaces/IJoinTruck';
import { IJoinTruckingDispatch } from 'lg-helpers/dist/trucking/interfaces/IJoinTruckingDispatch';
import { ITruckingLoad } from 'lg-helpers/dist/trucking/interfaces/ITruckingLoad';
import { UnitTruckCapacity } from 'lg-helpers/dist/shared/types/global-types';
import { selectJoinedTrucks } from 'modules/civilAndTrucking/trucking/features/Trucks/redux/trucksStoreSlice';
import { useSelector } from 'react-redux';
import { selectLoads } from 'modules/civilAndTrucking/trucking/features/Loads/redux/loadsStoreSlice';
import { CompanyConnectionWithCompany } from 'services/api/autogenerated';

type IDispatchFormProps = {
  joinedDispatch: IJoinTruckingDispatch;
};

export interface ILoadTruck extends IJoinTruck {
  idTruckingLoad?: number;
}

export const AddLoadsToDispatchForm = ({ joinedDispatch }: IDispatchFormProps) => {
  const [addedTrucks, setAddedTrucks] = useState<ILoadTruck[]>([]);
  const [addedBrokers, setAddedBrokers] = useState<CompanyConnectionWithCompany[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [toggleIndex, setToggleIndex] = useState<0 | 1>(0);
  const { handleModal } = useContext(ModalContext);
  const { joinedTrucksByCompanies } = useSelector(selectJoinedTrucks);
  const { loads } = useSelector(selectLoads);
  const unitOfMeasure: UnitTruckCapacity =
    joinedDispatch?.uomTruckingProjectMaterial ||
    joinedDispatch?.manualMaterialUomTruckingDispatch ||
    'Tons';
  const previouslyAddedTrucks: IJoinTruck[] = joinedTrucksByCompanies.filter(t =>
    loads.map(l => l.truckNumberTruckingLoad).includes(t.truckNumberTruckingTruck)
  );

  useEffect(() => {
    thunkDispatch(getJoinedTrucksByCompanyId());
    thunkDispatch(getDivisionsByCompanyId());
    thunkDispatch(getTruckTypesByCompanyId());
  }, []);

  const handleAddTruck = (truckToAdd: ILoadTruck) => {
    setAddedTrucks([...addedTrucks, truckToAdd]);
  };

  const handleAddBroker = (brokerToAdd: CompanyConnectionWithCompany) => {
    setAddedBrokers([...addedBrokers, brokerToAdd]);
  };

  const getTrucksInDivisionId = (divisionId: string) => {
    thunkDispatch(getJoinedTrucksByDivisionId(Number(divisionId)));
  };

  const handleRemoveTruck = (truckIndexToRemove: number) => {
    setAddedTrucks(
      addedTrucks
        .slice(0, truckIndexToRemove)
        .concat(addedTrucks.slice(truckIndexToRemove + 1, addedTrucks.length))
    );
  };

  const handleRemoveBroker = (brokerIndexToRemove: number) => {
    setAddedBrokers(
      addedBrokers
        .slice(0, brokerIndexToRemove)
        .concat(addedBrokers.slice(brokerIndexToRemove + 1, addedBrokers.length))
    );
  };

  const handleCreateLoads = async () => {
    const dispatchId = joinedDispatch?.idTruckingDispatch;
    if (!dispatchId) return;
    setIsLoading(true);

    const promises: Promise<any>[] = [];

    addedTrucks.forEach(truck => {
      const loadRequest = {
        dispatchIdTruckingLoad: dispatchId,
        truckNumberTruckingLoad: truck?.truckNumberTruckingTruck,
        dateTruckingLoad: joinedDispatch.dateTruckingDispatch,
        divisionIdTruckingLoad: truck?.divisionIdTruckingTruck,
        isBrokeredTruckingLoad: false,
        driverCompanyIdTruckingLoad: joinedDispatch.creatorCompanyIdTruckingDispatch,
        creatorCompanyIdTruckingLoad: joinedDispatch.creatorCompanyIdTruckingDispatch,
      } as ITruckingLoad;
      promises.push(thunkDispatch(upsertLoad(loadRequest)));
    });

    addedBrokers.forEach((broker: CompanyConnectionWithCompany) => {
      if (!broker.id) return;

      const brokerRequest = {
        dispatchIdTruckingLoad: dispatchId,
        dateTruckingLoad: joinedDispatch.dateTruckingDispatch,
        isBrokeredTruckingLoad: true,
        driverCompanyIdTruckingLoad: broker.connected_company_id,
        creatorCompanyIdTruckingLoad: joinedDispatch.creatorCompanyIdTruckingDispatch,
      } as ITruckingLoad;
      promises.push(thunkDispatch(upsertLoad(brokerRequest)));
    });

    const uniqueAddedBrokerCompanyIds = [
      ...new Set(addedBrokers.map(broker => broker.connected_company_id)),
    ];
    uniqueAddedBrokerCompanyIds.forEach(uniqueBrokerCompanyId => {
      thunkDispatch(
        addBrokerCompanyToDispatch({
          dispatchId,
          companyId: uniqueBrokerCompanyId,
        })
      );
    });

    await Promise.all(promises);
    setIsLoading(false);
    thunkDispatch(getLoadsByDispatchId(dispatchId));
    handleModal();
  };

  return (
    <>
      <ModalHeader title={'Assign Loads'} />
      <DialogContent sx={{ height: '100vh' }}>
        {isLoading ? (
          <Loading />
        ) : (
          <Box
            display="flex"
            flexDirection="column"
            justifyContent={'space-between'}
            sx={{ height: '100%' }}
          >
            <Grid container>
              <Grid item xs={6}>
                <AddLoadsDispatchInfo
                  joinedDispatch={joinedDispatch}
                  unitOfMeasure={unitOfMeasure}
                  addedTrucks={addedTrucks}
                  joinedTrucks={previouslyAddedTrucks}
                />
              </Grid>
              <Grid item xs={6}>
                <Box sx={{ marginBottom: 1 }}>
                  <ButtonToggle
                    firstVal={'Divisions'}
                    secondVal={'Brokers'}
                    disabled={false}
                    toggleIndex={toggleIndex}
                    setToggleIndex={setToggleIndex}
                  />
                </Box>
                {toggleIndex ? (
                  <AddTrucksToBroker
                    handleAddBroker={handleAddBroker}
                    addedBrokers={addedBrokers}
                  />
                ) : (
                  <TrucksInDivisionList
                    handleAddTruck={handleAddTruck}
                    unitOfMeasure={unitOfMeasure}
                    addedTrucks={addedTrucks}
                    getTrucksInDivisionId={getTrucksInDivisionId}
                  />
                )}
              </Grid>
              <TruckLoadsTable
                handleRemoveTruck={handleRemoveTruck}
                handleRemoveBroker={handleRemoveBroker}
                addedTrucks={addedTrucks}
                addedBrokers={addedBrokers}
                unitOfMeasure={unitOfMeasure}
              />
            </Grid>
            <DialogActionContainer saveHandler={handleCreateLoads} />
          </Box>
        )}
      </DialogContent>
    </>
  );
};
