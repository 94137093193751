import Fuse from 'fuse.js';

export const fuzzyFilterOptions =
  <T extends { [key: string]: any }>(keys: Fuse.FuseOptionKey<T>[], limit = 10) =>
  (options: T[], { inputValue }: { inputValue: string }) => {
    if (!inputValue) return options.slice(0, limit);

    const fuse = new Fuse(options, {
      keys,
      includeScore: true,
    });

    return fuse
      .search(inputValue)
      .slice(0, limit)
      .map(result => result.item);
  };

export default fuzzyFilterOptions;
