import React, { useState, useEffect } from 'react';
import { Snackbar, Fade, Alert } from '@mui/material';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import { INotification } from './INotification';
import { RootState } from 'store/store';
import { popNotification } from './redux/actions';

export const NotificationSnackbar = () => {
  const [visibleNotification, setVisibleNotification] = useState<INotification>();
  const dispatch = useDispatch();
  const notification = useSelector(
    (state: RootState) => state.civil.notifications[0],
    shallowEqual
  );

  useEffect(() => {
    setVisibleNotification(notification);
  }, [notification]);

  const handleNotificationClose = () => {
    dispatch(popNotification());
  };

  return (
    <span>
      {!!visibleNotification && (
        <Snackbar
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          open={!!visibleNotification}
          key={visibleNotification.status}
          autoHideDuration={
            visibleNotification?.duration ||
            (visibleNotification.status === 'success' ? 2000 : undefined)
          }
          TransitionComponent={Fade}
          onClose={handleNotificationClose}
        >
          <Alert severity={visibleNotification.status} onClose={handleNotificationClose}>
            {visibleNotification.message}
          </Alert>
        </Snackbar>
      )}
    </span>
  );
};
