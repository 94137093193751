import React from 'react';
import { createSvgIcon } from '@mui/material';

export const PlusTruck = createSvgIcon(
  <>
    <path d="M6.02602 16.0451c-1.91254 0-3.46079 1.5557-3.46079 3.4774C2.56523 21.4443 4.11348 23 6.02602 23s3.46079-1.5557 3.46079-3.4775c.00759-1.9217-1.54825-3.4774-3.46079-3.4774ZM15.7253 5.872h-4.2121c-.888 0-1.67728.56433-1.96568 1.40318L8.52295 10.2264c-.18974.5949-.51608.9685-1.08529 1.0906l-5.77557.938C.69823 12.4151 0 13.254 0 14.2301v6.7948h1.96567c-.33394-.8694-.44019-1.8608-.09108-2.9132.5085-1.5709 1.86701-2.7758 3.49115-3.0199 2.71702-.4041 5.04696 1.693 5.04696 4.3468 0 .5644-.1138 1.0982-.3036 1.5939h6.9444V7.19892c-.0152-.73209-.5996-1.32692-1.3282-1.32692Zm-.5388 4.4917c0 .2517-.1973.4652-.4478.4804l-3.8782.267c-.2277.0152-.4022-.1983-.3339-.4195l.8955-3.01986c.0759-.25166.3036-.41943.5616-.41943h2.755c.2429 0 .4402.19827.4402.4423v2.66909h.0076ZM42 7.73274c0-.86173-.7134-1.5557-1.571-1.52519-.8197.0305-1.4648.73209-1.4648 1.56332v7.45063H22.1081V4.16378c0-.82361-.6376-1.53283-1.4648-1.56333-.8652-.0305-1.571.66346-1.571 1.5252V16.6247c0 .9151.7362 1.6548 1.6469 1.6548h4.0528c-.1822.4728-.2657.999-.2201 1.5481.1442 1.7463 1.5937 3.119 3.3393 3.18 1.9657.061 3.5822-1.5176 3.5822-3.4774 0-.4423-.0835-.8618-.2276-1.2507h2.9826c-.1821.4728-.2656.999-.2201 1.5481.1442 1.7463 1.5938 3.119 3.3394 3.18 1.9656.061 3.5822-1.5176 3.5822-3.4774 0-.4423-.0835-.8618-.2277-1.2507.721 0 1.3054-.5872 1.3054-1.3117V7.73274H42Z" />
    <path d="M36.0728 5.28481h-3.7416V1.5252C32.3312.68634 31.6482 0 30.8133 0c-.8348 0-1.5179.68634-1.5179 1.5252v3.75961h-3.7416c-.8348 0-1.5179.68634-1.5179 1.5252 0 .83886.6831 1.5252 1.5179 1.5252h3.7416v3.75959c0 .8389.6831 1.5252 1.5179 1.5252.8349 0 1.5179-.6863 1.5179-1.5252V8.33521h3.7416c.8348 0 1.5179-.68634 1.5179-1.5252 0-.83886-.6831-1.5252-1.5179-1.5252Z" />
  </>,
  'Plus Truck'
);
