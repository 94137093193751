import React from 'react';
import { GridColDef } from '@mui/x-data-grid';
import { Button } from '@mui/material';
import { EditIcon } from 'modules/civilAndTrucking/shared/CustomIcons';
import { Visibility } from '@mui/icons-material';
import { Link } from 'react-router-dom';
import { ITruckingTruckDivision } from 'lg-helpers/dist/trucking/interfaces/ITruckingTruckDivision';

export const generateDivisionsColumns = (
  showModal: (truckType: ITruckingTruckDivision) => void
) => {
  return [
    {
      field: 'nameTruckingTruckDivision',
      headerName: 'Division',
      width: 256,
    },
    {
      field: 'trucksCountTruckingTruckDivision',
      headerName: '# of trucks',
      width: 120,
    },
    {
      field: 'show',
      headerName: ' ',
      flex: 1,
      align: 'right',
      sortable: false,
      renderCell: data => (
        <Link to={`./${data.id}`}>
          <Visibility />
        </Link>
      ),
    },
    {
      field: 'edit',
      headerName: ' ',
      flex: 0.01,
      sortable: false,
      renderCell: ({ row }) => (
        <Button color="success" onClick={() => showModal(row)}>
          <EditIcon />
        </Button>
      ),
    },
  ] as GridColDef[];
};
