import React, { useCallback, useContext, useEffect, useState } from 'react';
import { Typography, Grid, Box, Button, SvgIcon } from '@mui/material';
import { useDispatch } from 'react-redux';
import { useParams, useNavigate } from 'react-router-dom';
import { ModalContext } from 'sharedComponents/ModalContext';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { LoadingComponent } from 'sharedComponents/LoadingComponent';
import { ChevronLeft } from '@mui/icons-material';
import AddApiMappingModal from './AddApiMappingModal';
import api, { CivilProjectApiMappings } from 'services/api/autogenerated';
import { pushError } from 'modules/errors/storeSliceErrors';

const ProjectApiMappings = () => {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const { projectId } = useParams();
  const { handleModal } = useContext(ModalContext);
  const [apiMappings, setApiMappings] = useState<CivilProjectApiMappings[]>([]);

  const columns: GridColDef[] = [
    { field: 'name', headerName: 'Name', flex: 1 },
    { field: 'api', headerName: 'API', flex: 1 },
    { field: 'api_id', headerName: 'Id from API', flex: 1 },
    {
      field: '_',
      headerName: 'Unmatched Tickets',
      flex: 1,
      renderCell: (params: any) => <>{params.row._count.unmatched_incoming_tickets_from_api}</>,
    },
  ];

  const shoAddApiMapping = () =>
    handleModal(
      <AddApiMappingModal
        selected={{ project_id: Number(projectId), api: 'Connex' }}
        onRefresh={load}
      />
    );

  const load = useCallback(async () => {
    if (!projectId) return;
    try {
      setIsLoading(true);
      const { data: result } = await api.civilProjects.getApiMappingsByProject(
        parseInt(projectId, 10)
      );
      setApiMappings(result);
    } catch (err) {
      dispatch(pushError({ title: 'Error: Failed to load API Mappings' }));
    } finally {
      setIsLoading(false);
    }
  }, [projectId, dispatch]);

  useEffect(() => {
    load();
  }, [load]);

  return (
    <>
      <Grid container spacing={1} alignItems="center">
        <Grid item xs={6} display="flex" alignItems="center">
          <Box>
            <Typography
              variant="h5"
              sx={{
                color: 'neutral.main',
                typography: { textTransform: 'uppercase' },
              }}
            >
              Project API Mappings
            </Typography>
            <Box
              display="flex"
              sx={{
                '&:hover': {
                  cursor: 'pointer',
                },
              }}
            >
              <SvgIcon>
                <ChevronLeft />
              </SvgIcon>
              <Typography onClick={() => navigate(-1)}>Back</Typography>
            </Box>
          </Box>
        </Grid>
        <Grid item xs={2}>
          <Button color="primary" variant="contained" onClick={shoAddApiMapping}>
            Add API Mapping
          </Button>
        </Grid>
        <Grid item xs={12} minHeight={500} height={'80vh'}>
          <DataGrid
            columns={columns}
            rows={apiMappings}
            onRowClick={({ row }) =>
              handleModal(<AddApiMappingModal selected={row} onRefresh={load} />)
            }
          />
        </Grid>
      </Grid>
      <LoadingComponent isLoading={isLoading} />
    </>
  );
};

export default ProjectApiMappings;
