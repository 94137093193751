import React from 'react';
import './Layout.scss';
import { Outlet } from 'react-router-dom';
import TopBar from 'sharedComponents/TopBar';

const MainLayout = () => (
  <div className="Layout__wrapper--outer">
    <TopBar noControls />
    <div className="Layout__wrapper--inner">
      <div className="Layout__content-container">
        <div className="Layout__content">
          <Outlet />
        </div>
      </div>
    </div>
  </div>
);

export default MainLayout;
