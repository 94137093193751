export const mapboxBaseUrl = 'https://api.mapbox.com/geocoding/v5';

export interface Point {
  type: string;
  coordinates: Array<number>;
}
export interface IGeocoderContext {
  id: string;
  wikidata: string;
  text: string;
}

export interface IGeocoderFeature {
  id: string;
  type: 'Feature';
  place_type: Array<string>;
  relevance: number;
  properties: Object;
  address: string;
  text: string;
  place_name: string;
  bbox: Array<number>[4];
  center: Array<number>[2];
  geometry: Point;
  context: Array<IGeocoderContext>;
}

export interface IGeocoderResult {
  type: 'FeatureCollection';
  query: Array<string | number>;
  features: Array<IGeocoderFeature>;
  attribution: string;
}

export interface IAddress {
  address?: string;
  postcode?: string;
  place?: string;
  locality?: string;
  country?: string;
  region?: string;
  disctrict?: string;
  neighborhood?: string;
  poi?: string;
}

export const geocodeAddress = async (address: string) => {
  const formattedAddressForSearch = encodeURIComponent(
    address.replace(/(<|&lt;)br\s*\/*(>|&gt;)/g, ' ')
  );

  try {
    const geocodeRequest = await fetch(
      `${mapboxBaseUrl}/mapbox.places/${formattedAddressForSearch}.json?access_token=${process.env.REACT_APP_MAPBOX_TOKEN}`
    );

    if (geocodeRequest.ok) {
      const geocodeData = await geocodeRequest.json();

      if (geocodeData.features.length) {
        const found = geocodeData.features[0];

        return found.center;
      } else {
        return false;
      }
    } else {
      return false;
    }
  } catch (e) {
    console.error(e);
    return false;
  }
};

export const resolveAddress = async (address: string) => {
  const formattedAddressForSearch = encodeURIComponent(
    address.replace(/(<|&lt;)br\s*\/*(>|&gt;)/g, ' ')
  );

  try {
    const geocodeRequest = await fetch(
      `${mapboxBaseUrl}/mapbox.places/${formattedAddressForSearch}.json?access_token=${process.env.REACT_APP_MAPBOX_TOKEN}`
    );
    if (geocodeRequest.ok) {
      const geocodeData: IGeocoderResult = await geocodeRequest.json();
      const addresses = geocodeData.features.filter(
        (feature: IGeocoderFeature) => feature.place_type[0] === 'address'
      );
      if (addresses.length) {
        return addresses;
      } else {
        return;
      }
    }
  } catch (e) {
    console.error('Geolocation Error: ', e);
  }
};
