import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { thunkDispatch } from 'store/store';
import { Typography, Box, TextField } from '@mui/material';
import {
  getDeletedTicketsByCompanyId,
  renewTickets,
} from 'modules/civilAndTrucking/trucking/features/Tickets/redux/actions';
import { selectAuthUser } from 'modules/auth/storeSliceAuth';
import { selectDeletedTickets } from 'modules/civilAndTrucking/trucking/features/Tickets/redux/ticketsStoreSlice';
import { getAllJoinedDispatchesByCompanyId } from '../../../Dispatches/redux/actions';
import { selectAllJoinedDispatches } from '../../../Dispatches/redux/dispatchStoreSlice';
import { DeletedTicketsList } from './components/DeletedTicketsList';
import { ManageHeader } from 'sharedComponents/ManageHeader';
import { LoadingOverlay as Loading } from 'sharedComponents/LoadingOverlay';

export const DeletedTicketsView = () => {
  const authUser = useSelector(selectAuthUser);
  const companyId = authUser.companiesIds[0];
  const { deletedTickets } = useSelector(selectDeletedTickets);
  const [searchFilter, setSearchFilter] = useState('');
  const [idsToRenew, setIdsToRenew] = useState<number[]>([]);
  const { allJoinedDispatches: joinedDispatches, allJoinedDispatchesStatus } =
    useSelector(selectAllJoinedDispatches);
  const activeRenewButton = idsToRenew.length > 0;

  useEffect(() => {
    if (!companyId) return;

    thunkDispatch(getAllJoinedDispatchesByCompanyId());
    thunkDispatch(getDeletedTicketsByCompanyId());
  }, [companyId]);

  const ToolBarComponent = (
    <Box display="flex" flexDirection="row" alignItems="center">
      <Typography sx={{ mr: 2 }}>Search</Typography>
      <TextField
        required
        size="small"
        name="searchFilter"
        value={searchFilter}
        onChange={e => setSearchFilter(e.target.value)}
      />
    </Box>
  );

  if (allJoinedDispatchesStatus === 'pending') {
    return <Loading />;
  }

  return (
    <>
      <ManageHeader
        headerTitle={'Deleted Tickets'}
        buttonText={'Renew Entries'}
        onButtonClick={() => thunkDispatch(renewTickets(idsToRenew))}
        disabled={!activeRenewButton}
        centerComponent={ToolBarComponent}
      />
      <DeletedTicketsList
        {...{
          idsToRenew,
          setIdsToRenew,
          deletedTickets,
          joinedDispatches,
          searchFilter,
        }}
      />
    </>
  );
};
