import React, { useState } from 'react';
import { storage } from 'services/firebase';
import useShowError from 'modules/errors';
import ButtonCustomUpload from './ButtonCustomUpload';
import { Box, CircularProgress, Typography, Grid, Button } from '@mui/material';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import './UploadCompanyLogo.scss';

interface IUploadCompanyLogo {
  onUpdate: Function;
  companyId: string;
  companyLogo?: string;
  companyPrimaryColor?: string;
}

export const UploadCompanyLogo = ({
  onUpdate,
  companyId,
  companyLogo = '',
}: IUploadCompanyLogo) => {
  const [isLoadingManifestTemplate, setIsLoadingManifestTemplate] = useState(false);
  const showError = useShowError();

  const handleLogoUploadSuccess = async (filename: any) => {
    setIsLoadingManifestTemplate(false);

    try {
      const companyLogoUploadUrl = await storage
        .ref(`/company-logos/${companyId}/`)
        .child(filename)
        .getDownloadURL();

      onUpdate({
        target: {
          name: 'companyLogo',
          value: companyLogoUploadUrl,
        },
      });
    } catch (err) {
      console.error(err);
      showError({ title: 'Download logo error' });
    }
  };

  return (
    <Box>
      <Grid container alignItems="center" spacing={2}>
        <Grid item sm="auto" xs={12}></Grid>
        <Grid item sm="auto" xs={12}>
          <Button disabled={isLoadingManifestTemplate}>
            <ButtonCustomUpload
              accept="image/*"
              name={`company-logo-${companyId}`}
              storageRef={storage.ref().child(`/company-logos/${companyId}/`)}
              onUploadStart={async () => {
                setIsLoadingManifestTemplate(true);
              }}
              onUploadError={error => {
                setIsLoadingManifestTemplate(false);
                showError({ title: 'Upload logo error' });
                console.error(error);
              }}
              onUploadSuccess={handleLogoUploadSuccess}
            >
              {/* <Box display="flex" justifyContent="center" alignItems="center">
                {isLoadingManifestTemplate ? (
                  <CircularProgress color="inherit" />
                ) : (
                  <CloudUploadIcon color="inherit" />
                )}
                <Box marginLeft={2}>
                  <Typography variant="h5">
                    {isLoadingManifestTemplate ? `${logoProgressing}% ` : null}
                    Upload Company Logo
                  </Typography>
                </Box>
              </Box> */}
              <Box className="company_logo__box">
                {companyLogo ? (
                  <>
                    <img src={companyLogo} alt="" />
                    <Box className="company_logo__box_text company_logo__box__hover">
                      Change Company Logo
                    </Box>
                  </>
                ) : (
                  <Box className="company_logo__box_text" alignSelf="center">
                    Upload Company Logo
                  </Box>
                )}
              </Box>
            </ButtonCustomUpload>
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
};
