import { KeyTuple } from 'lg-helpers/dist/shared/types/KeyTuple';

export function extractFieldsByTuple<T>(item: T, keys: KeyTuple<T>): T {
  const result: T = {} as T;
  for (const k_ of keys) {
    const k = k_ as keyof T;
    result[k] = item[k];
  }
  return result;
}
