import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { DataGrid, GridColDef, GridColumnVisibilityModel } from '@mui/x-data-grid';
import { selectJoinedTrucks } from '../../../../Trucks/redux/trucksStoreSlice';
import {
  flattenCapacities,
  getColumnVisibilitiesByCapacities,
} from 'modules/civilAndTrucking/trucking/utils';
import { LoadingOverlay as Loading } from 'sharedComponents/LoadingOverlay';
import { ITruckFlattenedCapacities } from '../../../../Trucks/views/TrucksView/Trucks';
import { unitsOfMeasure } from 'lg-helpers/dist/constants/global-constants';

export const DivisionTrucksList = () => {
  const { joinedTrucksByDivisions, joinedTrucksStatusByDivisions } =
    useSelector(selectJoinedTrucks);
  const [visibleColumns, setVisibleColumns] = useState<GridColumnVisibilityModel>({});
  const [joinedTrucksWithCapacities, setJoinedTrucksWithCapacities] = useState(
    [] as ITruckFlattenedCapacities[]
  );

  useEffect(() => {
    if (!joinedTrucksByDivisions.length) return;

    const trucksWithCaps = joinedTrucksByDivisions.map(truck => {
      return {
        ...truck,
        ...flattenCapacities(truck.capacitiesTruckingTruckType),
      } as ITruckFlattenedCapacities;
    });
    const columnsRemoveVisibility = getColumnVisibilitiesByCapacities(trucksWithCaps);

    setJoinedTrucksWithCapacities(trucksWithCaps);
    setVisibleColumns(columnsRemoveVisibility);
  }, [joinedTrucksByDivisions]);

  const trucksInDivisionColumns: GridColDef[] = [
    {
      field: 'truckNumberTruckingTruck',
      headerName: 'Truck Number',
      width: 150,
    },
    ...unitsOfMeasure.map(e => ({
      field: e,
      headerName: e,
      sortable: false,
    })),
  ];

  if (joinedTrucksStatusByDivisions === 'pending') {
    return <Loading />;
  }

  return (
    <DataGrid
      columns={trucksInDivisionColumns}
      columnVisibilityModel={visibleColumns}
      rows={joinedTrucksWithCapacities || []}
      getRowId={row => row.idTruckingTruck}
      showColumnRightBorder={false}
      disableColumnMenu
      disableSelectionOnClick
      autoHeight
      components={{
        ColumnResizeIcon: () => <div></div>,
      }}
    />
  );
};
