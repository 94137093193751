import React, { useCallback, useEffect, useState } from 'react';
import { Autocomplete, Box, Grid, IconButton, TextField, Typography } from '@mui/material';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import TemplateEditor from 'modules/superAdmins/TemplateEditor';
import defaultBrokerLoadTemplatePropertiesDisplayOptions from 'services/constants/defaultBrokerLoadTemplatePropertiesDisplayOptions';
import defaultBrokerTicketTemplatePropertiesDisplayOptions from 'services/constants/defaultBrokerTicketTemplatePropertiesDisplayOptions';
import defaultTicketReportTemplatePropertiesDisplayOptions from 'services/constants/defaultTicketReportTemplatePropertiesDisplayOptions';
import { DisplayTemplates } from 'lg-helpers/dist/shared/types/DisplayTemplates';
import { v4 as uuidv4 } from 'uuid';

const hasTemplateWithEmptyName = (templates: any) => {
  const manifestTemplateWithEmptyName = (templates || []).find(({ name }: any) => !name);
  return !!manifestTemplateWithEmptyName;
};

const InputCustomTemplateBuilder = ({
  title,
  handleChange,
  company,
  propNameInCompany,
  disableAddNew = false,
  disableSelect = false,
  inputLabel = '',
  inputPlaceholder = '',
  templateSuggestions = null,
}: {
  title: string;
  handleChange: any;
  company: any;
  propNameInCompany: DisplayTemplates;
  disableAddNew?: boolean;
  disableSelect: boolean;
  inputLabel?: string;
  inputPlaceholder?: string;
  templateSuggestions?: { [key: string]: string } | null;
}) => {
  const [selectedTemplate, setSelectedTemplate] = useState<any>(null);

  useEffect(() => {
    if (
      ['brokerLoadTemplates', 'brokerTicketTemplates', 'ticketReportTemplate'].includes(
        propNameInCompany
      )
    ) {
      const propertiesDisplayOptions =
        propNameInCompany === 'brokerLoadTemplates'
          ? defaultBrokerLoadTemplatePropertiesDisplayOptions
          : propNameInCompany === 'brokerTicketTemplates'
          ? defaultBrokerTicketTemplatePropertiesDisplayOptions
          : propNameInCompany === 'ticketReportTemplate'
          ? defaultTicketReportTemplatePropertiesDisplayOptions
          : '';

      if (!company[propNameInCompany] || !company[propNameInCompany].length) {
        handleChange({
          target: {
            name: propNameInCompany,
            value: [
              {
                id: uuidv4(),
                name: 'Default template',
                notAllowDelete: true,
                dimensions: {
                  width: 2550,
                  height: 3300,
                },
                propertiesDisplayOptions,
              },
            ],
          },
        });
      }
    }

    if (company[propNameInCompany] && company[propNameInCompany].length === 1) {
      setSelectedTemplate(company[propNameInCompany][0]);
    }
    // eslint-disable-next-line
  }, [company, handleChange]);

  const addTemplate = () => {
    if (hasTemplateWithEmptyName(company[propNameInCompany])) {
      return;
    }

    const newTemplate = {
      id: uuidv4(),
      name: '',
    };

    setSelectedTemplate(newTemplate);
    const updatedTemplates = [newTemplate, ...(company[propNameInCompany] || [])];
    handleChange({
      target: {
        name: propNameInCompany,
        value: updatedTemplates,
      },
    });
  };

  const onSelectedTemplateDelete = (template: any) => { // eslint-disable-line
    const updatedTemplates = (company[propNameInCompany] || []).filter(
      (searchTemplate: any) => searchTemplate.id !== template.id
    );
    setSelectedTemplate(null);
    handleChange({
      target: {
        name: propNameInCompany,
        value: updatedTemplates,
      },
    });
  };

  const onSelectedTemplateUpdate = useCallback(
    (template: any) => {
      setSelectedTemplate(template);
      const updatedTemplates = (company[propNameInCompany] || []).map((searchTemplate: any) =>
        searchTemplate.id === template.id ? template : searchTemplate
      );

      handleChange({
        target: {
          name: propNameInCompany,
          value: updatedTemplates,
        },
      });
    },
    [company, handleChange, propNameInCompany]
  );

  return (
    <Grid item xs={12}>
      <Grid container spacing={2}>
        <Grid item sm={6} xs={12}>
          <Typography variant="h5">{title}</Typography>
        </Grid>
        <Grid item sm={6} xs={12}>
          <Box display="flex">
            <IconButton
              onClick={addTemplate}
              disabled={disableAddNew || (selectedTemplate && !selectedTemplate.name)}
              color="primary"
            >
              <AddCircleOutlineIcon />
            </IconButton>

            <Autocomplete
              fullWidth
              disabled={disableSelect || (selectedTemplate && !selectedTemplate.name)}
              options={company[propNameInCompany] || []}
              getOptionLabel={(option: any) => option.name}
              value={selectedTemplate}
              onChange={(event: any, value: any) => setSelectedTemplate(value)}
              renderInput={(params: any) => (
                <TextField
                  {...params}
                  required
                  label={inputLabel}
                  placeholder={inputPlaceholder}
                  variant="outlined"
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: <>{params.InputProps.endAdornment}</>,
                  }}
                />
              )}
            />
          </Box>
        </Grid>
        {selectedTemplate && (
          <TemplateEditor
            showUploadBackgroundButton={true}
            companyId={company.id}
            template={selectedTemplate}
            onUpdate={onSelectedTemplateUpdate}
            onDelete={onSelectedTemplateDelete}
            templateSuggestions={templateSuggestions}
          />
        )}
      </Grid>
    </Grid>
  );
};

export default InputCustomTemplateBuilder;
