import React, { HTMLAttributes, useEffect, useState } from 'react';
import { Typography, Grid } from '@mui/material';
import { thunkDispatch } from 'store/store';
import { Autocomplete, AutocompleteProps } from '@mui/material';
import { debounce } from 'lodash';
import { getLocations } from 'modules/civilAndTrucking/civil/Projects/redux/actions';
import { ILocation } from 'lg-helpers/dist/shared/interfaces/ILocation';

interface AutoCompleteFieldProps<T> extends AutocompleteProps<T, boolean, boolean, boolean> {
  handleSelect: (val?: ILocation) => void;
  searchRequirement: number;
  projectId: number;
}

const ProjectSitesAutocomplete = (props: AutoCompleteFieldProps<ILocation>) => {
  const [inputValue, setInputValue] = useState<string>(props.inputValue ? props.inputValue : '');
  const { searchRequirement, handleSelect, projectId, ...passedProps } = props;
  const [value, setValue] = useState<ILocation | null>(null);
  const [optionsLoading, setOptionsLoading] = React.useState<boolean>(false);
  const [options, setOptions] = useState<ILocation[]>([]);

  const fetch = React.useMemo(
    () =>
      debounce(async (request: { input: string }, callback: (results?: ILocation[]) => void) => {
        setOptionsLoading(true);

        const locations = await thunkDispatch(
          getLocations({ projectId: projectId, search: request.input })
        );
        if (locations.meta.requestStatus === 'fulfilled') {
          callback(locations.payload as ILocation[]);
        }
        setOptionsLoading(false);
      }, 500),
    // eslint-disable-next-line
    []
  );

  React.useEffect(() => {
    let active1 = true;
    if (inputValue === '') {
      handleSelect(undefined);
      return;
    } else if (inputValue.length >= searchRequirement) {
      fetch({ input: inputValue }, (results?: ILocation[]) => {
        if (active1) {
          let newOptions = [] as ILocation[];

          if (value) {
            newOptions = [value];
            if (handleSelect && active1) {
              handleSelect(value);
            }
          }

          if (results) {
            newOptions = [...results];
          }

          setOptions(newOptions);
        }
      });
    }
    return () => {
      active1 = false;
    };
  }, [value, inputValue, fetch, handleSelect, searchRequirement]);

  useEffect(() => {
    let active2 = true;
    if (active2) {
      if (props.value) {
        setValue(props.value as ILocation);
      } else {
        setValue(null);
        fetch({ input: '' }, (results?: ILocation[]) => {
          if (active2) {
            let newOptions = [] as ILocation[];

            if (results) {
              newOptions = [...results];
            }

            setOptions(newOptions);
          }
        });
      }
    }

    return () => {
      active2 = false;
    };
    // eslint-disable-next-line
  }, [props.value]);

  useEffect(() => {
    let active = true;
    if (active) {
      if (!props.inputValue || !props.inputValue.length) {
        setValue(null);
      }
    }
    return () => {
      active = false;
    };
  }, [props.inputValue]);

  return (
    <Autocomplete<ILocation, boolean, boolean, boolean>
      {...passedProps}
      loading={optionsLoading}
      freeSolo
      loadingText="Loading..."
      noOptionsText={
        inputValue.length > searchRequirement
          ? 'No locations found'
          : `Search requires at least ${searchRequirement} characters`
      }
      options={options}
      getOptionLabel={option => {
        const optionValue = option as ILocation;
        return optionValue.name || optionValue.addressString!;
      }}
      filterOptions={x => x}
      onInputChange={(event, newValue) => setInputValue(newValue)}
      onChange={(event, val) => {
        const newValue = val as ILocation;
        setValue(newValue);
      }}
      renderOption={(p: HTMLAttributes<HTMLLIElement>, option: ILocation) => (
        <li {...p}>
          <Grid container alignItems="center" justifyContent="space-between" wrap="nowrap">
            <Grid item xs={6}>
              <Typography noWrap>{option.name ? option.name : option.address1}</Typography>
            </Grid>
            <Grid item xs={6} display="flex" alignItems="center" justifyContent="flex-end">
              <Typography variant="caption" align="center" noWrap>
                {option.city}, {option.state}
              </Typography>
            </Grid>
          </Grid>
        </li>
      )}
    />
  );
};

export default ProjectSitesAutocomplete;
