import React from 'react';
import { BrokerDispatchesView } from './BrokerDispatchesView';
import { BrokerDispatchView } from './BrokerDispatchView';
import { Routes, Route } from 'react-router-dom';
import { DispatchMapView } from 'modules/civilAndTrucking/trucking/features/Dispatches/views/DispatchMap';

export const BrokerDispatchesViews = () => {
  return (
    <Routes>
      <Route path="/" element={<BrokerDispatchesView />} />
      <Route path="/:dispatchId" element={<BrokerDispatchView />} />
      <Route path="/:dispatchId/map" element={<DispatchMapView />} />
    </Routes>
  );
};
