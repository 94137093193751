import React, { useState, useEffect } from 'react';
import { TextField, Grid } from '@mui/material';
import PhotoCameraIcon from '@mui/icons-material/PhotoCamera';
import CommentIcon from '@mui/icons-material/Comment';
import ButtonWithLoading from 'sharedComponents/ButtonWithLoading';
import { useSelector } from 'react-redux';
import InputCameraImage from 'sharedComponents/InputCameraImage';
import { selectAuthUser } from 'modules/auth/storeSliceAuth';
import { uploadManifestNoteImageAndGetUrl } from 'api/manifest';
import useShowError from 'modules/errors';
import { Manifest } from 'lg-helpers/dist/shared/types/manifests';
import Command from 'services/firebase/Command';

const command = new Command();

const NotesAddNewOrEdit = ({
  manifest,
  isNotesOpen,
  noteId,
  noteText,
  urlImage = null,
  handleUpdatedNote,
}: {
  manifest: Manifest;
  isNotesOpen: boolean;
  noteId?: string;
  noteText?: string;
  urlImage?: string | null;
  handleUpdatedNote?: () => void;
}) => {
  const [newNoteText, setNewNoteText] = useState(noteText || '');
  const showError = useShowError();
  const [isLoadingAddNewNote, setIsLoadingAddNewNote] = useState(false);
  const [isNewNoteImageVisible, setIsNewNoteImageVisible] = useState(!!urlImage);
  const [newNoteImageBase64, setNewNoteImageBase64] = useState(urlImage);
  const [newImageTaken, setNewImageTaken] = useState(false);
  const authUser = useSelector(selectAuthUser);

  useEffect(() => {
    if (!isNotesOpen) setIsNewNoteImageVisible(false);
    // eslint-disable-next-line
  }, [isNotesOpen]);

  const onClickAddNewNote = async () => {
    setIsLoadingAddNewNote(true);
    try {
      let urlNoteImage: string = newNoteImageBase64 || '';

      if (newNoteImageBase64 && newImageTaken) {
        urlNoteImage = (await uploadManifestNoteImageAndGetUrl(
          manifest.id,
          newNoteImageBase64
        )) as string;
      }

      if (noteId) {
        await command.manifests.updateManifestNote(manifest.id, noteId, {
          noteText: newNoteText,
          urlNoteImage,
          editId: authUser.id,
          editName: `${authUser.firstName || ''} ${authUser.lastName || ''}`,
          editEmail: authUser.email,
          editType: authUser.actingAsType,
        });
        if (handleUpdatedNote) handleUpdatedNote();
      } else {
        await command.manifests.addManifestNote(manifest.id, {
          noteText: newNoteText,
          urlNoteImage,
          userId: authUser.id,
          userName: `${authUser.firstName || ''} ${authUser.lastName || ''}`,
          userEmail: authUser.email,
          userType: authUser.actingAsType,
          isBatchManifestWeight: false,
        });
      }
    } catch (err: any) {
      console.error(err);
      showError({ title: err.text || `Failed to ${noteId ? 'update' : 'create'} note` });
    }
    setNewNoteText('');
    setNewNoteImageBase64(null);
    setIsNewNoteImageVisible(false);
    setIsLoadingAddNewNote(false);
    setNewImageTaken(false);
  };

  return (
    <Grid container>
      <Grid item xs={12}>
        <TextField
          className="Notes__new-comment"
          label={noteId ? 'Edit note' : 'New note'}
          value={newNoteText}
          onChange={event => setNewNoteText(event.target.value)}
          multiline
          minRows={4}
        />
      </Grid>

      {isNewNoteImageVisible && (
        <Grid item xs={12}>
          <InputCameraImage
            title="Image Preview"
            imageBase64={newNoteImageBase64}
            onImageBase64Update={setNewNoteImageBase64}
            isLoading={false}
            disabled={isLoadingAddNewNote}
          />
        </Grid>
      )}

      <Grid item xs={12} md={6}>
        {!isNewNoteImageVisible && (
          <ButtonWithLoading
            text="Take Picture?"
            isFullWidth
            Pictogram={PhotoCameraIcon}
            onClickCallback={() => {
              setNewImageTaken(true);
              setIsNewNoteImageVisible(true);
            }}
            isLarge={!noteId}
          />
        )}
      </Grid>
      <Grid item xs={12} md={isNewNoteImageVisible ? 12 : 6}>
        <ButtonWithLoading
          text={`${noteId ? 'Update' : 'Add'} note`}
          color="primary"
          isFullWidth
          Pictogram={CommentIcon}
          disabled={!newNoteText && !newNoteImageBase64}
          onClickCallback={onClickAddNewNote}
          isLarge={!noteId}
        />
      </Grid>
    </Grid>
  );
};

export default NotesAddNewOrEdit;
