interface ErrorMessagesInterface {
  isRequired: string;
  emailNotValid: string;
}

const ErrorMessages: ErrorMessagesInterface = {
  isRequired: 'Is required',
  emailNotValid: 'Email not valid',
};

export default ErrorMessages;
