import React, { useContext, useEffect, useState } from 'react';
import { Typography, Button, Box, SvgIcon, IconButton, Grid } from '@mui/material';
import TableWrapper from 'sharedComponents/TableWrapper';
import { ModalContext } from 'sharedComponents/ModalContext';
import AddActivityType from 'modules/civilAndTrucking/civil/ActivityTypes/ActivityTypeForm';
import { useSelector } from 'react-redux';
import { RootState, thunkDispatch } from 'store/store';
import { deleteActivityType, getActivityTypes } from './redux/actions';
import { Link, useParams } from 'react-router-dom';
import { setActiveProject } from '../Projects/redux/actions';
import { ChevronLeft, Add } from '@mui/icons-material';
import { useHasAccess } from 'services/permissions';
import { LoadingComponent } from 'sharedComponents/LoadingComponent';
import { IActivityType } from 'lg-helpers/dist/shared/interfaces/IActivityType';

interface ActivityButtonProps {
  name?: string;
  href: string;
  id?: number;
  count?: number;
}

const ActivityButton = ({ href, count }: ActivityButtonProps) => {
  return (
    <Box display="flex" alignItems="center">
      <Typography color="neutral">
        {count?.toString() || 0} {count?.toString() === '1' ? 'Activity' : 'Activities'}
      </Typography>
      <Link to={href}>
        <IconButton
          sx={{
            color: '#fff',
            backgroundColor: 'secondary.main',
            marginLeft: 1,
            padding: '3px',
            '&:hover': {
              cursor: 'pointer',
            },
          }}
        >
          <SvgIcon fontSize="small">
            <Add />
          </SvgIcon>
        </IconButton>
      </Link>
    </Box>
  );
};

const ActivityTypes = () => {
  const { handleModal } = useContext(ModalContext);
  const { projectId } = useParams();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [activityTypes, setActivityTypes] = useState<IActivityType[]>([]);
  const auth = useHasAccess(['projects:create', 'projects:update']);

  const activityTypesState = useSelector((state: RootState) => state.civil.activityTypes.data);

  const projectState = useSelector((state: RootState) => state.civil.projects.activeProject);

  const showModal: React.MouseEventHandler = (event, selection?: IActivityType) => {
    handleModal(<AddActivityType selected={selection} projectId={Number(projectId)} />);
  };

  const handleDeletion = (selection: IActivityType) => {
    thunkDispatch(
      deleteActivityType({
        workCategory: selection,
        projectId: Number(projectId),
      })
    );
  };

  useEffect(() => {
    (async () => {
      try {
        setIsLoading(true);
        await thunkDispatch(getActivityTypes({ projectId: Number(projectId) }));
        await thunkDispatch(setActiveProject(Number(projectId)));
        setIsLoading(false);
      } catch (err) {
        setIsLoading(false);
        console.error(err);
      }
    })();
  }, [projectId]);

  useEffect(() => {
    setActivityTypes(
      activityTypesState.map(item => ({
        name: item.name,
        id: item.id,
        count: item.count,
        link: <ActivityButton href={`activities?type=${item.id}`} count={item.count} />,
      }))
    );
  }, [activityTypesState]);

  return (
    <>
      <Grid container>
        <Grid
          item
          xs={12}
          display="flex"
          justifyContent="space-between"
          sx={{ marginBottom: '1rem' }}
        >
          <Box>
            <Typography variant="h5" sx={{ color: 'neutral.main' }}>
              Work Categories for {projectState?.name}
            </Typography>
            <Link to={`/civil/projects/${projectId}`}>
              <Box display="flex">
                <SvgIcon sx={{ color: 'neutral.main' }}>
                  <ChevronLeft />
                </SvgIcon>
                <Typography sx={{ color: 'neutral.main' }}>Back to Project Info</Typography>
              </Box>
            </Link>
          </Box>
          <Box flexGrow={1}></Box>
          <Button
            color="primary"
            variant="contained"
            onClick={showModal}
            disabled={!auth.hasAccess}
          >
            Add Work Category
          </Button>
        </Grid>
        <TableWrapper
          data={activityTypes}
          headCells={[
            {
              label: 'Name',
              id: 'name',
            },
            {
              label: 'Manage Activities',
              id: 'link',
            },
          ]}
          canEdit={auth.hasAccess}
          canDelete={auth.hasAccess}
          onSelection={showModal}
          onDelete={handleDeletion}
          deleteConfirmation="You are about to remove a work category. This work category may still exist in active project tasks. Please delete or update those tasks as needed. Are you sure you wish to remove this work category?"
        />
      </Grid>
      <LoadingComponent isLoading={isLoading} />
    </>
  );
};

export default ActivityTypes;
