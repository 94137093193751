import React, { useState, useEffect, useMemo } from 'react';
import { Link } from 'react-router-dom';
import { UserTypesArray } from 'lg-helpers/dist/constants/user/UserTypes';
import { Box, Grid, CircularProgress, Button, Container, ButtonGroup } from '@mui/material';
import CodeIcon from '@mui/icons-material/Code';
import EmailIcon from '@mui/icons-material/Email';
import Query from 'services/firebase/Query';
import Executer from 'services/firebase/Executer';
import useShowError from 'modules/errors';
import FormatDataCSV from './FormatDataCSV';

import LivDropdownMenu, { DropdownItemProps } from 'sharedComponents/LivDropdownMenu';
import { IUser } from 'lg-helpers/dist/shared/interfaces/IUser';

const query = new Query();
const executer = new Executer();

const columnOptions = [
  {
    key: 'Names of companies',
    val: 'companies.name',
  },
  {
    key: 'Email',
    val: 'email',
  },
  {
    key: 'First Name',
    val: 'firstName',
  },
  {
    key: 'ID',
    val: 'id',
  },
  {
    key: 'Last Name',
    val: 'lastName',
  },
  {
    key: 'LicenseAgreement Accepted At',
    val: 'licenseAgreement.acceptedAt',
  },
  {
    key: 'LicenseAgreement Accepted',
    val: 'licenseAgreement.isAccepted',
  },
  {
    key: 'Region ID',
    val: 'regionId',
  },
  {
    key: 'Type',
    val: 'type',
  },
  {
    key: 'Company',
    val: 'company',
  },
  {
    key: 'Phone Number',
    val: 'phone',
  },
];

const formatOptions: DropdownItemProps[] = [
  {
    key: 'JSON',
    val: 'json',
    icon: <CodeIcon />,
  },
  {
    key: 'CSV',
    val: 'csv',
    icon: <CodeIcon />,
  },
  {
    key: 'Email w/name',
    val: 'email',
    icon: <EmailIcon />,
  },
  {
    key: 'Email Only',
    val: 'emailOnly',
    icon: <EmailIcon />,
  },
];

interface ObjProps {
  [key: string]: any;
}

const getField: (obj: ObjProps, keys: string[], i?: number) => any = (obj, keys, i = 0) => {
  const key = keys[i];
  if (i >= keys.length) {
    return obj;
  } else if (obj[key] === undefined || obj[key] === null) {
    return obj[key];
  } else if (Array.isArray(obj[key])) {
    return obj[key].map((item: ObjProps) => getField(item, keys, i + 1));
  } else {
    return getField(obj[key], keys, i + 1);
  }
};

const pick = (obj: ObjProps, keys: string[]) => {
  const newObj = {} as any;
  for (const key of keys.sort()) {
    newObj[key] = getField(obj, key.split('.'));
  }
  return newObj;
};

const ExportUsersView = () => {
  const showError = useShowError();
  const [columns, setColumns] = useState(columnOptions.map(item => item.val));
  const [isLoadingAllUsers, setIsLoadingAllUsers] = useState(false);
  const [allUsers, setAllUsers] = useState([] as IUser[]);
  const [customFormat, setCustomFormat] = useState(formatOptions[0].val);
  const [userType, setUserType] = useState('all');

  const userTypesAdjusted = [...['all'], ...UserTypesArray];

  const data = useMemo(() => allUsers.map(u => pick(u, columns)), [allUsers, columns]);

  useEffect(() => {
    setAllUsers([]);
    setIsLoadingAllUsers(true);

    const getUsersData = async () => {
      const userData =
        userType === 'all'
          ? await executer.getMultipleDocuments(query.users.getAllUsers())
          : await executer.getMultipleDocuments(
              query.users.getAllUsers({ prop: 'type', op: '==', val: userType })
            );

      if (userData.length > 0) {
        setAllUsers(userData as IUser[]);
        setIsLoadingAllUsers(false);
      } else {
        showError({ title: 'Unable to load users', duration: 10000 });
        setIsLoadingAllUsers(false);
      }
    };

    getUsersData();
    // eslint-disable-next-line
  }, [userType]);

  const renderTemplate = () => {
    switch (customFormat) {
      case 'json':
        return <pre>{JSON.stringify(data, null, 2)}</pre>;
      case 'csv':
        return <FormatDataCSV data={data} />;
      case 'emailOnly':
        return (
          <pre style={{ whiteSpace: 'break-spaces' }}>{data.map(user => `${user.email}, \n`)}</pre>
        );
      default:
        return (
          <pre style={{ whiteSpace: 'break-spaces' }}>
            {data.map(user => `${user.firstName} ${user.lastName} <${user.email}>, \n`)}
          </pre>
        );
    }
  };

  if (isLoadingAllUsers) {
    return (
      <Box padding="10px">
        <CircularProgress color="inherit" size={20} />
      </Box>
    );
  }

  return (
    <Container>
      <Box padding="10px">
        <Grid container>
          <Grid item xs={12} marginBottom={1}>
            <h3>Export Users</h3>
          </Grid>
          <ButtonGroup size="large" aria-label="large button group">
            <Button component={Link} variant="outlined" to="/super-admin/administration">
              Back
            </Button>

            <LivDropdownMenu
              val={userType}
              variant="outlined"
              helpText="User Type"
              listItems={userTypesAdjusted}
              onSelection={item => {
                setUserType(item as string);
              }}
            />

            <LivDropdownMenu
              helpText="Format"
              variant="outlined"
              val={customFormat}
              listItems={formatOptions}
              onSelection={(item: DropdownItemProps) => setCustomFormat(item.val)}
            />

            <LivDropdownMenu
              helpText="Columns"
              variant="outlined"
              val=""
              checkedItems={columns}
              listItems={columnOptions}
              onSelection={(item: DropdownItemProps) =>
                setColumns(
                  columns.includes(item.val)
                    ? columns.filter(k => k !== item.val)
                    : [...columns, item.val]
                )
              }
            />
          </ButtonGroup>
        </Grid>

        <Box padding="20px 0 10px">{renderTemplate()}</Box>
      </Box>
    </Container>
  );
};

export default ExportUsersView;
