import React from 'react';
import { TruckTypes } from './views/TruckTypesView/TruckTypes';
import { Routes, Route } from 'react-router-dom';

export const TruckTypesViews = () => {
  return (
    <Routes>
      <Route path="/" element={<TruckTypes />} />
    </Routes>
  );
};
