import ManifestCommand from 'lg-helpers/dist/firestore/command/ManifestCommand';
import { mixPanel } from 'services/mixpanel';
import QueryBase from 'lg-helpers/dist/firestore/query/QueryBase';

// Handles mixPanel so that lg-helpers can remain pure
export class ExtendedManifestCommand extends ManifestCommand {
  async signManifestsByGenerator(authUser: any, manifestsIds: string[]) {
    await super.signManifestsByGenerator(authUser, manifestsIds);

    mixPanel(authUser, `Manifests Signed by Generator`, {
      'Manifests IDs': manifestsIds.join(', ') || '',
    });
  }
  async signManifestByScaleAttendant(
    authUser: any,
    manifestId: string,
    brokerLoadId: string,
    receipt: any = null
  ) {
    await super.signManifestByScaleAttendant(authUser, manifestId, brokerLoadId, receipt);
    const manifestRef: any = this.query.base.getById(QueryBase.MANIFESTS_COLLECTION(), manifestId);

    const mixPanelOptions: any = {
      'Manifest ID': manifestId || '',
      'Manifest Number': manifestRef?.number || '',
      'Project ID': manifestRef?.project?.id || '',
    };

    if (brokerLoadId) {
      mixPanelOptions['Broker Load ID'] = brokerLoadId || '';
    }

    mixPanel(authUser, 'Scale house signatures completed', mixPanelOptions);
  }

  async allowAssignSpecificManifestToDriver(manifestId: string, allowedByUser: any) {
    await super.allowAssignSpecificManifestToDriver(manifestId, allowedByUser);
    const manifestRef: any = this.query.base.getById(QueryBase.MANIFESTS_COLLECTION(), manifestId);
    mixPanel(allowedByUser, `Specific Manifest Assigned to Driver`, {
      'Manifest Id': manifestId || '',
      'Driver Name': manifestRef?.signatureDriver?.driverName || '',
      'Truck Number': manifestRef?.signatureDriver?.truckNumber || '',
      'Scale Id': manifestRef?.signatureScale?.id || '',
      'Scale Name': manifestRef?.signatureScale?.name || '',
      timestamp: this.FieldValue.serverTimestamp(),
    });
  }
}
