import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import './VerifyManifestView.scss';
import { useNavigate } from 'react-router-dom';
import { Box, Button, CircularProgress, Container, Grid, Typography } from '@mui/material';
import BorderColorIcon from '@mui/icons-material/BorderColor';
import Page from 'sharedComponents/Page';
import Notes from 'sharedComponents/Notes.js';
import { useDispatch, useSelector } from 'react-redux';
import { selectScale, setSelectedManifest } from 'modules/scales/storeSliceScales';
import { useConfirm } from 'material-ui-confirm';

import { isProjectExpiring } from 'utils/isProjectExpiring';
import ManifestDisplayWithTemplateDataGathering from 'sharedComponents/ManifestDisplayWithTemplateDataGathering';

import moment from 'moment';
import { refreshProjectScheduledTasks } from 'api/super-admin';
import api from 'services/api/autogenerated';
import { selectAuthUser } from 'modules/auth/storeSliceAuth';
import { callV1ApiUrl } from 'services/api';
import Command from 'services/firebase/Command';
import Query from 'services/firebase/Query';
import useShowError from 'modules/errors';
import { Manifest } from 'lg-helpers/dist/shared/types/manifests';
import { VerifyManifestCurrentPage } from './VerifyManifestView';
import useDownloadManifestOpenNewPage from 'sharedComponents/useDownloadManifestOpenNewPage';
import QueryBase from 'lg-helpers/dist/firestore/query/QueryBase';
import { ModalContext } from 'sharedComponents/ModalContext';
import CreateReceiptModal from './CreateReceiptModal';
import { IManifestReceipt } from './CreateReceiptForm';
import Executer from 'services/firebase/Executer';
import IBatchManifests from 'lg-helpers/dist/shared/interfaces/IBatchManifests';

const command = new Command();
const query = new Query();
const executer = new Executer();

interface VerifyManifestProps {
  selectedManifest: Manifest;
  batchManifests: Manifest[] | undefined;
  batch?: IBatchManifests;
  onPageChange: (page: VerifyManifestCurrentPage) => void;
}

const SignManifestView = (props: VerifyManifestProps) => {
  const [selectedManifest, setSelectedManifestLocal] = useState(props.selectedManifest);
  const [selectedProject, setSelectedProject] = useState(props.selectedManifest?.project || null);
  const scale = useSelector(selectScale);
  const [isNotesOpen, setIsNotesOpen] = useState(false);
  const [isManifestSuccessfullyDisplayed, setIsManifestSuccessfullyDisplayed] = useState(false);
  const [isSigning, setIsSigning] = useState(false);
  const [wasSignPressed, setWasSignPressed] = useState(false);
  const showError = useShowError();
  const authUser = useSelector(selectAuthUser);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const confirm = useConfirm();
  const bottomRef = useRef<any>();
  const { openTabbedManifest: handleClickDownload } =
    useDownloadManifestOpenNewPage(selectedManifest);
  const { handleModal } = useContext(ModalContext);

  const goToLanesPage = useCallback(() => {
    navigate('/scales/lanes');
  }, [navigate]);

  useEffect(() => {
    if (!selectedManifest || !scale) {
      goToLanesPage();
    }
  }, [selectedManifest, scale, goToLanesPage]);

  useEffect(() => {
    const getProject = async () => {
      try {
        const project: any = await executer.getSingleDocument(
          query.base.getById(QueryBase.PROJECTS_COLLECTION(), selectedManifest.project?.id!)
        );
        setSelectedProject(project);
      } catch (err) {
        showError({ title: 'Error loading project for manifest', duration: 10000 });
      }
    };

    getProject();
  }, [selectedManifest.project, showError]);

  const processSignAndComplete = async (receipt: IManifestReceipt | null = null) => {
    try {
      if (isProjectExpiring(selectedProject)) {
        try {
          const expiryDate = selectedProject?.expiryDate?.toDate();
          await confirm({
            title: '',
            content: `This profile expires on ${expiryDate.toDateString()}.  Click Ok to continue.`,
          });
        } catch {
          return;
        }
      }
    } catch (err) {
      console.error(err);
    }
    setIsSigning(true);
    setIsManifestSuccessfullyDisplayed(false);
    const selectedManifestId = selectedManifest.id;
    try {
      await command.manifests.signManifestByScaleAttendant(
        authUser,
        selectedManifest.id,
        selectedManifest.brokerLoadId || '',
        receipt
      );
      try {
        await api.redis.deleteWMTicketCacheForManifest(selectedManifest.id);
        // We need to refresh ticket information (using WM api)
        // only if scale's sign date != driver's sign date
        const driverSignDate = selectedManifest.signatureDriver?.signedAt
          ? moment.tz(
              selectedManifest.signatureDriver.signedAt.toDate(),
              selectedManifest.project?.timeZone || 'EST'
            )
          : null;
        const nowDate = moment.tz(new Date(), selectedProject?.timeZone || 'EST');
        const differentDates = driverSignDate && Math.abs(nowDate.diff(driverSignDate, 'days')) > 0;
        if (differentDates) {
          const dayToRefresh = selectedManifest.signatureDriver?.signedAt?.toDate();
          await refreshProjectScheduledTasks(selectedProject?.id!, dayToRefresh!, dayToRefresh!);
        }
      } catch (err) {
        console.error('Reset cache error', err);
        // this error can be silenced
      }
      if (bottomRef?.current) {
        bottomRef.current.scrollIntoView({ behavior: 'smooth' });
      }
      setTimeout(() => {
        if (props.batchManifests) {
          let unsignedManifest = props.batchManifests.find(
            batchManifest =>
              !batchManifest.signatureScale.isSigned && selectedManifestId !== batchManifest.id
          );
          if (unsignedManifest) {
            dispatch(setSelectedManifest(unsignedManifest));
            props.onPageChange(VerifyManifestCurrentPage.ScanManifest);
            return;
          }
        }
        dispatch(setSelectedManifest(null));
        goToLanesPage();
      }, 3000);
    } catch (err: any) {
      console.error(err);
      showError({
        title: err.message ? err.message : 'Error signing manifest',
      });
    }
    setIsSigning(false);
    setWasSignPressed(true);
    try {
      if (selectedManifest.isV1ApiCivilAndTrucking && selectedManifest.v1ApiCompletedCallBackUrl) {
        await callV1ApiUrl(selectedManifest.v1ApiCompletedCallBackUrl);
      }
    } catch (err) {
      console.error(err);
      showError({ title: 'Error calling v1 callback url' });
    }
  };

  const handleClickSignAndComplete = async () => {
    if (
      selectedProject?.enableScaleUserReceiptCreation ||
      props.batch?.enableScaleUserReceiptCreation
    ) {
      return handleModal(
        <CreateReceiptModal
          receipt={selectedManifest?.receipt}
          callbackReceipt={processSignAndComplete}
        />
      );
    } else {
      return processSignAndComplete();
    }
  };

  return (
    <Page className="VerifyManifestView" title="Verify manifest">
      <Container maxWidth={false}>
        <Grid container>
          <Grid item sm={2} xs={6}>
            <Box display="flex" justifyContent="flex-end" marginTop={1} marginRight={1}>
              <Button
                size="large"
                fullWidth
                onClick={() => props.onPageChange(VerifyManifestCurrentPage.ScanManifest)}
                disabled={isSigning || wasSignPressed || !isManifestSuccessfullyDisplayed}
              >
                Cancel
              </Button>
            </Box>
          </Grid>
          <Grid item sm={3} xs={6}>
            <Box display="flex" justifyContent="flex-end" marginTop={1} marginRight={1}>
              {selectedManifest && (
                <Button
                  size="large"
                  variant="outlined"
                  color="secondary"
                  fullWidth
                  onClick={handleClickDownload}
                >
                  Download
                </Button>
              )}
            </Box>
          </Grid>
          <Grid item sm={3} xs={6}>
            <Box display="flex" justifyContent="flex-end" marginTop={1} marginRight={1}>
              {selectedManifest && (
                <Button
                  size="large"
                  variant="outlined"
                  color="secondary"
                  fullWidth
                  onClick={() => setIsNotesOpen(true)}
                  disabled={isSigning || wasSignPressed}
                >
                  Notes ({selectedManifest.notesCount}) / Changelog
                </Button>
              )}
            </Box>
          </Grid>
          <Grid item sm={4} xs={12}>
            <Box display="flex" justifyContent="flex-end" marginTop={1} marginRight={1}>
              <Button
                color="primary"
                variant="contained"
                size="large"
                fullWidth
                onClick={handleClickSignAndComplete}
                disabled={isSigning || wasSignPressed || !isManifestSuccessfullyDisplayed}
              >
                <Box display="flex" alignItems="center">
                  <Box display="flex" alignItems="center" marginRight={1}>
                    {isSigning ? (
                      <CircularProgress color="inherit" />
                    ) : (
                      <BorderColorIcon fontSize="small" />
                    )}
                  </Box>
                  Sign &amp; Complete
                </Box>
              </Button>
            </Box>
          </Grid>
          <Grid item xs={12} className="VerifyManifestView">
            {selectedManifest && (
              <ManifestDisplayWithTemplateDataGathering
                hideReceipt={true}
                manifestId={selectedManifest.id}
                onSucessfullDisplayCallback={setIsManifestSuccessfullyDisplayed}
                onManifestUpdateReceivedCallback={setSelectedManifestLocal}
              />
            )}
          </Grid>
          <Grid item sm={12} xs={12}>
            <Box display="flex" justifyContent="flex-end" marginTop={1} marginBottom={3}>
              <Button
                color="primary"
                variant="contained"
                size="large"
                fullWidth
                onClick={handleClickSignAndComplete}
                disabled={isSigning || wasSignPressed || !isManifestSuccessfullyDisplayed}
              >
                <Box display="flex" alignItems="center">
                  <Box display="flex" alignItems="center" marginRight={1}>
                    {isSigning ? (
                      <CircularProgress color="inherit" />
                    ) : (
                      <BorderColorIcon fontSize="small" />
                    )}
                  </Box>
                  <Typography variant="h4">Sign &amp; Complete</Typography>
                </Box>
              </Button>
            </Box>
          </Grid>
        </Grid>
        <Box ref={bottomRef} />
      </Container>
      {selectedManifest && (
        <Notes
          manifest={selectedManifest}
          isNotesOpen={isNotesOpen}
          handleCloseNotes={() => setIsNotesOpen(false)}
        />
      )}
    </Page>
  );
};

export default SignManifestView;
