import { createSlice } from '@reduxjs/toolkit';

export const notificationStoreSlice = createSlice({
  name: 'notifications',
  initialState: {
    notifications: [],
    isLoadingNotifications: true,
    notificationsError: '',
  },
  reducers: {
    setNotifications: (state, action) => {
      const notificationsSorted = action.payload.notifications.sort(
        (a, b) => b.createdAt - a.createdAt
      );
      state.notifications = notificationsSorted.slice(0, action.payload.limit || 10);
    },
    clearNotifications: state => {
      state.notifications = [];
    },
    setIsLoadingNotifications: (state, action) => {
      state.isLoadingNotifications = action.payload;
    },
    setNotificationsError: (state, action) => {
      state.notificationsError = action.payload;
    },
  },
});

export const {
  setNotifications,
  clearNotifications,
  setIsLoadingNotifications,
  setNotificationsError,
} = notificationStoreSlice.actions;

export const selectNotifications = state => state.notifications.notifications;
export const selectIsLoadingNotifications = state => state.notifications.isLoadingNotifications;
export const selectNotificationsError = state => state.notifications.notificationsError;

export default notificationStoreSlice.reducer;
