import { createAsyncThunk } from '@reduxjs/toolkit';
import ApiBase from 'services/postgres/ApiBase';
import Tables from 'services/postgres/Tables';
import { CompanyType } from 'services/constants/companyTypes';
import { pushError } from 'modules/errors/storeSliceErrors';
import { ICompany } from 'lg-helpers/dist/shared/interfaces/ICompany';
import { IDispatchBrokerCompany } from 'lg-helpers/dist/shared/interfaces/IDispatchBrokerCompany';

const apiBase = new ApiBase();
const COMPANIES_TABLE = Tables.Companies;
const DISPATCH_BROKER_COMPANY_TABLE = Tables.DispatchBrokerCompany;

export const getCompaniesByCompanyTypes = createAsyncThunk(
  'companies/get-companies',
  async (companyTypes: string[], thunkApi) => {
    try {
      const data = await apiBase.getList(`${COMPANIES_TABLE}/types/${companyTypes}`);
      return data as ICompany[];
    } catch (err) {
      thunkApi.dispatch(pushError({ title: 'Error: Unable to get companies' }));
      throw err;
    }
  }
);

export const getQueriedCompaniesByCompanyTypes = createAsyncThunk(
  'companies/get-companies',
  async (
    payload: {
      companyTypes: CompanyType[];
      query?: string;
      isStrict?: boolean;
    },
    thunkApi
  ) => {
    try {
      let apiUrl = `${COMPANIES_TABLE}/types/${payload.companyTypes}/query/${payload.query}`;

      if (payload.isStrict) {
        apiUrl = `${apiUrl}/strict`;
      }
      const data = await apiBase.getList(apiUrl);

      return data as ICompany[];
    } catch (err) {
      thunkApi.dispatch(pushError({ title: 'Error: Unable to get companies' }));
      throw err;
    }
  }
);

export const addBrokerCompanyToDispatch = createAsyncThunk(
  'trucking-brokers/add-broker-company-to-dispatch',
  async (payload: { dispatchId: number; companyId: string }, thunkApi) => { // eslint-disable-line
    if (!payload.companyId || !payload.dispatchId) return;
    try {
      const newBrokerCompanyDispatch: Partial<IDispatchBrokerCompany> = {
        dispatchIdDispatchBrokerCompany: payload.dispatchId,
        companyIdDispatchBrokerCompany: payload.companyId,
      };
      return await apiBase.insertOrUpdate(newBrokerCompanyDispatch, DISPATCH_BROKER_COMPANY_TABLE);
    } catch (err) {
      console.error('Error adding broker company to dispatch: ', err);
    }
  }
);
