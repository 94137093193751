import React from 'react';
import { Box, Button, Grid, Typography } from '@mui/material';
import UndoIcon from '@mui/icons-material/Undo';
import TrafficLightsRed from 'sharedComponents/pictograms/TrafficLightsRed';

interface SelectedLaneType {
  color: string;
  label: string;
  id: string;
  type: 'lane' | 'scale';
}

interface PleaseWaitScreenProps {
  selectedLane: SelectedLaneType;
  currentManifest: any;
  onHandleClickNotes(): void;
  onClickBackToScales(): void;
}

const PleaseWaitScreen = ({
  selectedLane,
  currentManifest,
  onHandleClickNotes,
  onClickBackToScales,
}: PleaseWaitScreenProps) => (
  <div>
    {currentManifest && !currentManifest.signatureScale.isSigned && (
      <Grid container>
        <Grid item xs={12}>
          <Box marginTop={3} display="flex" justifyContent="center" alignItems="center">
            <TrafficLightsRed />
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Box
            marginTop={3}
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
          >
            <Typography variant="h3" align="justify">
              Please WAIT on{' '}
              <Typography
                variant="h2"
                component="span"
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  textAlign: 'center',
                  alignItems: 'center',
                  color: selectedLane.color,
                }}
              >
                lane {selectedLane.label}
              </Typography>{' '}
              for the scale attendant to process your manifest number {currentManifest.number}
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Box marginTop={3} display="flex" justifyContent="space-between" alignItems="center">
            <Button onClick={onClickBackToScales} variant="outlined">
              <Box display="flex" alignItems="center">
                <Box marginRight={1}>
                  <UndoIcon fontSize="large" />
                </Box>{' '}
                Undo
              </Box>
            </Button>
            {currentManifest && (
              <Button
                size="large"
                variant="outlined"
                color="secondary"
                onClick={onHandleClickNotes}
              >
                Notes ({currentManifest.notesCount})
              </Button>
            )}
          </Box>
        </Grid>
      </Grid>
    )}
  </div>
);

export default PleaseWaitScreen;
