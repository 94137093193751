import { Grid, DialogContent, Checkbox, FormControlLabel, Typography } from '@mui/material';
import React, { useContext, useState } from 'react';
import DialogActionContainer from 'sharedComponents/DialogActionContainer';
import { ModalContext } from 'sharedComponents/ModalContext';
import ModalHeader from 'sharedComponents/ModalHeader';

type ConfirmModalProps<T> = {
  modalHeader: string;
  modalText: string;
  shouldSuppressModal: boolean;
  confirmAction: (obj: T, suppressPref: boolean) => void;
};

export const ConfirmModal = <T,>({
  modalHeader,
  modalText,
  shouldSuppressModal,
  confirmAction,
}: ConfirmModalProps<T>) => {
  const [suppressModal, setSuppressModal] = useState<boolean>(shouldSuppressModal);
  const { handleModal } = useContext(ModalContext);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSuppressModal(event.target.checked);
  };

  const handleConfirm = (e: any) => {
    confirmAction(e, suppressModal);
    handleModal();
  };

  return (
    <>
      <ModalHeader title={modalHeader} />
      <DialogContent>
        <Grid>
          <Typography variant="body1">{modalText}</Typography>
        </Grid>
        <Grid>
          <FormControlLabel
            control={
              <Checkbox
                checked={suppressModal}
                onChange={handleChange}
                inputProps={{ 'aria-label': 'controlled' }}
              />
            }
            label="Don't show this again"
          />
        </Grid>
        <DialogActionContainer saveText="OK" saveHandler={handleConfirm} />
      </DialogContent>
    </>
  );
};
