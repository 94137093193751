import React, { useEffect, useState } from 'react';
import 'mapbox-gl/dist/mapbox-gl.css';
import Mapbox from 'sharedComponents/Mapbox';
import { Grid } from '@mui/material';
import { useParams } from 'react-router-dom';
import { generateIconMarker } from 'sharedComponents/MapboxHelpers';
import Query from 'services/firebase/Query';
import Executer from 'services/firebase/Executer';
import QueryBase from 'lg-helpers/dist/firestore/query/QueryBase';

const query = new Query();
const executer = new Executer();

const ProjectMapView = () => {
  let { id } = useParams();

  const [manifests, setManifests] = useState([]);
  const [markers, setMarkers] = useState([]);
  const [siteLocation, setSiteLocation] = useState(null);
  const [scaleLocations, setScaleLocations] = useState([]);
  const [center, setCenter] = useState([0, 0]);

  useEffect(() => {
    if (!id) return;

    let unsubscribeScales = null;

    const unsubscribeProject = executer.watchSingleDocument(
      query.base.getById(QueryBase.PROJECTS_COLLECTION(), id),
      project => {
        const { coords, generatorSiteAddress, scalesIds } = project;

        if (coords?.gpsLat && coords?.gpsLng) {
          setSiteLocation({
            latitude: coords.gpsLat,
            longitude: coords.gpsLng,
            popupHTML: `<p><b>${generatorSiteAddress}</b></p>` || '',
          });
        }

        if (!scalesIds || !scalesIds.length === 0) return;

        unsubscribeScales = executer.watchMultipleDocuments(
          query.manifests.getProjectScales(scalesIds),
          scales => {
            const locations = scales
              ?.filter(scale => scale.gpsLat && scale.gpsLong)
              .map(({ gpsLat, gpsLong, address }) => ({
                latitude: gpsLat,
                longitude: gpsLong,
                popupHTML: `<p><b>${address}</b></p>` || '',
              }));

            if (locations) setScaleLocations(locations);
          },
          error => {
            console.error('Error:', error);
          }
        );
      },
      error => {
        console.error('Error:', error);
      }
    );

    return () => {
      if (unsubscribeScales) unsubscribeScales();
      unsubscribeProject();
    };
  }, [id]);

  useEffect(() => {
    if (!id) return;

    return executer.watchMultipleDocuments(
      query.manifests.getProjectManifestsOnLoad(id),
      m => {
        const results = m?.filter(manif => manif.signatureDriver?.gpsPositionLast);
        setManifests(results);
      },
      error => {
        console.error('Error:', error);
      }
    );
  }, [id]);

  useEffect(() => {
    let newMarkers = [];
    let sumLongitude = 0;
    let sumLatitude = 0;

    newMarkers = newMarkers.concat(
      scaleLocations.map(position => {
        sumLongitude += parseFloat(position.longitude);
        sumLatitude += parseFloat(position.latitude);
        return generateIconMarker(position, '/static/images/scale.png', position.popupHTML);
      })
    );

    if (scaleLocations.length > 0) {
      sumLongitude /= scaleLocations.length;
      sumLatitude /= scaleLocations.length;
    }

    if (siteLocation) {
      newMarkers = newMarkers.concat(
        generateIconMarker(siteLocation, '/static/images/excavator.png', siteLocation.popupHTML)
      );
      setCenter([siteLocation.longitude, siteLocation.latitude]);
    } else {
      setCenter([sumLongitude, sumLatitude]);
    }

    const driverMarkers = manifests.map(manifest => {
      const manifestNumber = `Manifest number: ${manifest.number}`;
      const {
        truckingCompany,
        truckNumber,
        gpsPositionLast: { longitude, latitude },
      } = manifest.signatureDriver;

      const popupHtml = `<p><b>${manifestNumber}</b></p><p>${truckingCompany}</p><p>${truckNumber}</p>`;

      return generateIconMarker(
        { longitude, latitude },
        '/static/images/icons/truck.svg',
        popupHtml
      );
    });

    newMarkers = newMarkers.concat(driverMarkers);

    setMarkers(newMarkers);
  }, [manifests, siteLocation, scaleLocations]);

  return (
    <Grid container>
      <Grid item xs={12}>
        <Mapbox zoom={12} center={center} markers={markers} />
      </Grid>
    </Grid>
  );
};

export default ProjectMapView;
