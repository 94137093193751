import React from 'react';
import { Button, SvgIcon, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { ArrowBack } from '@mui/icons-material';

interface ButtonLinkProps {
  text?: string;
  url?: string;
  Icon?: any;
  onClicked?(): void;
  isDisabled?: boolean;
  isFullWidth?: boolean;
  color?: 'inherit' | 'primary' | 'secondary' | 'success' | 'error' | 'info' | 'warning';
  variant?: 'text' | 'outlined' | 'contained';
}

const ButtonLink = ({
  text,
  url,
  Icon,
  onClicked,
  isDisabled = false,
  isFullWidth = false,
  color = 'primary',
  variant = 'outlined',
}: ButtonLinkProps) => {
  const navigate = useNavigate();

  return (
    <Button
      color={color}
      variant={variant}
      onClick={() => (onClicked ? onClicked() : url ? navigate(url) : navigate(-1))}
      disabled={isDisabled as any as boolean}
      fullWidth={isFullWidth}
    >
      {Icon ? (
        Icon
      ) : (
        <SvgIcon sx={{ pr: 1 }}>
          <ArrowBack />
        </SvgIcon>
      )}
      {text && <Typography>{text}</Typography>}
    </Button>
  );
};

export default ButtonLink;
