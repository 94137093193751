import React from 'react';
import { AppBar, Toolbar } from '@mui/material';
import { auth } from 'services/firebase';
import DesktopTopBar from './DesktopTopBar';
import MobileTopBar from './MobileTopBar';
import { useDispatch, useSelector } from 'react-redux';
import { clearNotifications } from 'sharedComponents/notificationStoreSlice';
import { clearProjectsManifestFilters } from 'redux/storeSliceManifest';
import { selectAuthUser } from 'modules/auth/storeSliceAuth';
import { handleLogoutAction } from 'sharedComponents/TopBar';
interface ITopBarProps {
  useNotificationButton?: boolean;
  settingsPath: string;
  logoComponent: JSX.Element;
  isWidthXSorSM: boolean;
}

export interface ISizedTopBarProps {
  isSwitcherVisible: boolean;
  handleLogout: () => void;
  useNotificationButton?: boolean;
  settingsPath: string;
  logoComponent: JSX.Element;
}

export const TopBar = ({
  useNotificationButton,
  isWidthXSorSM,
  settingsPath,
  logoComponent,
}: ITopBarProps) => {
  const dispatch = useDispatch();
  const authUser = useSelector(selectAuthUser);
  const hasMultipleUserTypesOrSuperadmin =
    (authUser?.allowedUserTypes || []).length > 1 || authUser.type === 'Super Admin';

  const logOut = () => {
    dispatch(clearNotifications());
    dispatch(clearProjectsManifestFilters());
    handleLogoutAction();
  };

  return (
    <AppBar
      elevation={0}
      sx={{
        height: 56,
        display: 'flex',
        justifyContent: 'space-around',
        backgroundColor: '#fff',
        color: 'primary.main',
        zIndex: 1201, //z-index of drawer is 1200 - https://mui.com/material-ui/customization/z-index/
        position: 'fixed',
      }}
    >
      <Toolbar>
        <>
          {isWidthXSorSM ? (
            <MobileTopBar
              useNotificationButton={useNotificationButton}
              isSwitcherVisible={hasMultipleUserTypesOrSuperadmin}
              handleLogout={logOut}
              settingsPath={settingsPath}
              logoComponent={logoComponent}
            />
          ) : (
            <DesktopTopBar
              useNotificationButton={useNotificationButton}
              isSwitcherVisible={hasMultipleUserTypesOrSuperadmin}
              handleLogout={logOut}
              settingsPath={settingsPath}
              logoComponent={logoComponent}
            />
          )}
        </>
      </Toolbar>
    </AppBar>
  );
};
