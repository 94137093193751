import React, { useState, useEffect, useCallback, SetStateAction, ChangeEvent } from 'react';
import CrudTable from 'sharedComponents/CrudTable';
import { useNavigate } from 'react-router-dom';
import ToolbarManifests from './ToolbarManifests';
import { UserTypes } from 'lg-helpers/dist/constants/user/UserTypes';
import { useSelector, useDispatch } from 'react-redux';
import { selectAuthUser } from 'modules/auth/storeSliceAuth';
import { sendGeneratorsNewUnsignedManifestsNotification } from 'api/manifest';
import { v4 as uuidv4 } from 'uuid';
import ManifestsTableFooter from './ManifestsTableFooter';
import Query from 'services/firebase/Query';
import Command from 'services/firebase/Command';
import Executer from 'services/firebase/Executer';
import {
  setProjectsManifestFilters,
  selectProjectsManifestFilters,
} from 'redux/storeSliceManifest';
import moment from 'moment';
import 'moment-timezone';
import useShowError from 'modules/errors';
import CompaniesApi from 'services/postgres/CompaniesApi';
import api from 'services/api/autogenerated';
import { getManifestDefaultColumns } from 'utils/manifestUtil';
import { mixPanel } from 'services/mixpanel';
import QueryBase from 'lg-helpers/dist/firestore/query/QueryBase';
import { Accordion, AccordionDetails, AccordionSummary, Grid } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const manifestColumns = getManifestDefaultColumns();

const companiesApi = new CompaniesApi();
const query = new Query();
const command = new Command();
const executer = new Executer();

const quantityAccumulator = (accumulator: any, currentValue: any) => {
  let quantityValue = 0;
  if (
    currentValue.receipt &&
    currentValue.receipt.weight &&
    currentValue.receipt.weight.rounded_net
  ) {
    quantityValue = parseFloat(currentValue.receipt.weight.rounded_net) || 0;
  }

  return accumulator + quantityValue;
};

export const getDatesForQuery = (dateRangeFilter: any, project?: any) => {
  let startDate = moment(dateRangeFilter[0]);
  let endDate = moment(dateRangeFilter[1]);

  if (project) {
    startDate = moment.tz(
      {
        year: startDate.year(),
        month: startDate.month(),
        day: startDate.date(),
      },
      project.timeZone || 'EST'
    );

    endDate = moment.tz(
      {
        year: endDate.year(),
        month: endDate.month(),
        day: endDate.date(),
      },
      project.timeZone || 'EST'
    );
  }

  return [startDate.startOf('day').toDate(), endDate.endOf('day').toDate()];
};

const ManifestsViewPerProject = ({
  project,
  defaultFilterIsSigned,
  defaultdateRangeFilter,
  setFiltersCallback,
}: {
  project: any; // TODO: find better type for this
  defaultFilterIsSigned: boolean;
  defaultdateRangeFilter?: boolean | Date[];
  // TODO: manifestColumns never used
  manifestColumns?: { [key: string]: any }[]; // TODO: find better type for this
  setFiltersCallback?: (newFilters: any) => any;
}) => {
  const navigate = useNavigate();
  const authUser = useSelector(selectAuthUser);
  const [filteredManifests, setFilteredManifests] = useState([] as any[]);
  const [recordsCount, setRecordsCount] = useState(0);
  const [indicator, setIndicator] = useState(1);
  const [manifestTemplate, setManifestTemplate] = useState(null as any);
  const [selectedManifestsIds, setSelectedManifestsIds] = useState([] as string[]);
  const showError = useShowError();
  const [manifestSearchFilter, setManifestSearchFilter] = useState(null as any);
  const [isLoadingData, setIsLoadingData] = useState(true);
  const [totalQuantity, setTotalQuantity] = useState(0);
  const [paginationInfo, setPaginationInfo] = useState({
    id: uuidv4(),
    lastVisibleDoc: null,
    isNext: null,
  } as any);

  const [wcAPIErrors, setWCAPIErrors] = useState([] as any[]);
  const [wcAPIErrorsExpanded, setWCAPIErrorsExpanded] = useState(false);

  const [filters, setFilters] = useState({
    filterIsSigned: defaultFilterIsSigned,
    filterDateRange: defaultdateRangeFilter,
    filterIsVoid: false,
    filterIsRejected: false,
  } as any);

  const [freshProjectScales, setFreshProjectScales] = useState([] as any[]);
  const dispatch = useDispatch();
  const projectsFilters = useSelector(selectProjectsManifestFilters);
  const manifestFilters = projectsFilters[project.id] || null;

  const checkTransporterCanViewAllManifests = useCallback(() => {
    const transporterCompanyId = authUser.companies[0].id;
    return project?.transporterCompanies?.some(
      (company: any) => company.id === transporterCompanyId && company.canViewAllManifests
    );
  }, [authUser, project]);

  useEffect(() => {
    if (!project.id || manifestFilters) {
      return;
    }

    dispatch(
      setProjectsManifestFilters({
        id: project.id,
        filters: {
          filterIsSigned: null,
          filterDateRange: null,
          filterIsVoid: false,
          filterSearch: '',
        },
      })
    );
  }, [project.id, dispatch, manifestFilters]);

  useEffect(() => {
    if (!filters) {
      return;
    }

    if (setFiltersCallback) setFiltersCallback(filters);
  }, [filters, setFiltersCallback]);

  useEffect(() => {
    if (!filteredManifests || !selectedManifestsIds) {
      return;
    }

    if (selectedManifestsIds.length) {
      setRecordsCount(selectedManifestsIds.length);

      setTotalQuantity(
        filteredManifests
          .filter(({ id }: { id: string }) => selectedManifestsIds.indexOf(id) !== -1)
          .reduce(quantityAccumulator, 0)
      );
    } else {
      setRecordsCount(filteredManifests.length);
      setTotalQuantity(filteredManifests.reduce(quantityAccumulator, 0));
    }
  }, [filteredManifests, selectedManifestsIds]);

  useEffect(() => {
    setFilters((prevFilters: any) => ({
      filterIsSigned:
        prevFilters.filterIsSigned === undefined
          ? defaultFilterIsSigned
          : prevFilters.filterIsSigned,
      filterDateRange:
        prevFilters.filterDateRange === null ? defaultdateRangeFilter : prevFilters.filterDateRange,
      filterIsVoid: false,
      filterRejected: false,
    }));
  }, [defaultdateRangeFilter, defaultFilterIsSigned]);

  useEffect(() => {
    if (!manifestFilters) {
      return;
    }
    const { filterIsSigned, filterIsVoid, filterDateRange, filterSearch, filterIsRejected } =
      manifestFilters;

    setFilters((prevFilters: any) => ({
      filterIsSigned: filterIsSigned !== null ? filterIsSigned : prevFilters.filterIsSigned,
      filterDateRange: filterDateRange !== null ? filterDateRange : prevFilters.filterDateRange,
      filterIsVoid: filterIsVoid !== null ? filterIsVoid : prevFilters.filterIsVoid,
      filterIsRejected: filterIsRejected,
    }));

    setManifestSearchFilter(filterSearch);
  }, [manifestFilters]);

  const matchingCompaniesIds = (usersCompanies: any, projectCompanies: any) =>
    projectCompanies.filter((company: any) => usersCompanies.includes(company.id));

  const canOnlyViewCompanyDriversManifests = () => {
    if (authUser.actingAsType !== 'Transporter Admin') {
      return false;
    }

    const userCompaniesInProject = matchingCompaniesIds(
      authUser.companiesIds,
      project.transporterCompanies
    );

    if (userCompaniesInProject.some((comp: any) => comp.canViewAllManifests)) {
      return false;
    }

    return true;
  };

  const getQueryOfManifests = (propertyToSearch?: any | undefined) => {
    const searchFilterLower = manifestSearchFilter?.trim() || '';

    return query.manifests.getManifests(
      searchFilterLower,
      propertyToSearch || null,
      canOnlyViewCompanyDriversManifests(),
      project,
      authUser,
      filters.filterIsSigned,
      filters.filterDateRange,
      filters.filterIsVoid,
      paginationInfo,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      100
    );
  };

  const getQueriesOfManifests = () => {
    const valuesToSearch = [
      'signatureDriver.driverName',
      'signatureDriver.truckNumber',
      'signatureDriver.truckingCompany',
      'signatureDriver.secondaryTruckingCompany',
      'number',
    ];
    return valuesToSearch.map(value => getQueryOfManifests(value));
  };

  useEffect(() => {
    if (!project.id || !authUser || !authUser.id) {
      return;
    }

    setIsLoadingData(true);
    let unsubscribe: any;
    let unsubscribes: any;

    const onSuccessCallback = (manifs: any) => {
      let filteredManifs = manifs;
      if (filters.filterIsRejected) {
        filteredManifs = manifs.filter((manif: any) => manif.signatureScale?.isRejected);
      }

      // filter by date after query LM-554
      if (filters.filterDateRange && filters.filterDateRange.length > 1 && manifestSearchFilter) {
        const [startDate, endDate] = getDatesForQuery(filters.filterDateRange, project);
        filteredManifs = filteredManifs.filter(
          (manif: any) =>
            manif.lastUsedAt &&
            manif.lastUsedAt.toDate() >= startDate &&
            manif.lastUsedAt.toDate() <= endDate
        );
      }

      filteredManifs = filteredManifs.sort(
        (a: any, b: any) => b.lastUsedAt.toDate() - a.lastUsedAt.toDate()
      );

      setFilteredManifests(filteredManifs);
      setIsLoadingData(false);
    };

    const onErrorCallback = (err: any) => {
      showError({ title: 'Error fetching manifests', duration: 10000 });
      setIsLoadingData(false);
      console.error('Error', err);
    };

    if (manifestSearchFilter) {
      unsubscribes = executer.watchMultipleDocumentsOnMultipleQueries(
        getQueriesOfManifests(),
        onSuccessCallback,
        onErrorCallback
      );
    } else {
      unsubscribe = executer.watchMultipleDocuments(
        getQueryOfManifests(),
        onSuccessCallback,
        onErrorCallback
      );
    }

    return () => {
      if (unsubscribe) unsubscribe();
      if (unsubscribes) unsubscribes.forEach((unsub: any) => unsub());
    };

    // eslint-disable-next-line
  }, [
    manifestSearchFilter,
    project.id,
    authUser,
    filters,
    paginationInfo.id,
    checkTransporterCanViewAllManifests,
  ]);

  useEffect(() => {
    const getManifestTemplate = async (manifestProject: any) => {
      try {
        const { id, scaleCompany, manifestTemplateId } = manifestProject;
        if (!id) {
          showError({ title: 'No project id!' });
          return;
        }

        if (!manifestTemplateId) {
          showError({ title: 'No manifest template id!' });
          return;
        }

        if (!scaleCompany || !scaleCompany.id) {
          showError({
            title: `This scale company property doesn't exist on project ${manifestProject.id}`,
          });
          return;
        }

        const scaleCompanyDoc = await companiesApi.getById(scaleCompany.id);
        if (!scaleCompanyDoc) {
          showError({
            title: `This scale company ${scaleCompany.id} doesn't exist`,
          });
          return;
        }

        const { manifestTemplates }: any = scaleCompanyDoc;
        if (!manifestTemplates || !manifestTemplates.length) {
          showError({
            title: `There are no manifest templates for company ${scaleCompany.id}`,
          });
          return;
        }

        const projectManifestTemplate = manifestTemplates.find(
          ({ id: i }: any) => i === manifestTemplateId
        );
        if (!projectManifestTemplate) {
          showError({
            title: `There are no manifest template with the id ${manifestTemplateId} for scale company ${scaleCompany.id}`,
          });
          return;
        }

        setManifestTemplate(projectManifestTemplate);
      } catch (err) {
        showError({ title: 'Error downloading the manifest template' });
        console.error(err);
      }
    };

    project && getManifestTemplate(project);
    // eslint-disable-next-line
  }, [project]);

  useEffect(() => {
    if (!project || !project.scalesIds) {
      return;
    }
    const getScales = async (scalesIds: any) => {
      try {
        const scalesRefs = scalesIds.map((id: any) =>
          query.base.getById(QueryBase.SCALES_COLLECTION(), id)
        );
        const scalesResponses = [] as any[];
        for (let i = 0; i < scalesRefs.length; i++) {
          scalesResponses.push(await scalesRefs[i].get());
        }
        setFreshProjectScales(
          scalesResponses
            .filter((scaleDoc: any) => scaleDoc.exists)
            .map((scaleDoc: any) => ({
              ...scaleDoc.data(),
              id: scaleDoc.id,
            }))
        );
      } catch (err) {
        showError({ title: 'Error loading scales', duration: 10000 });
      }
    };

    getScales(project.scalesIds);
    // eslint-disable-next-line
  }, [project]);

  const loadManifests = async (isNext: any) => {
    let lastVisibleDoc = null;
    if (filteredManifests.length) {
      const id = isNext
        ? filteredManifests[filteredManifests.length - 1].id
        : filteredManifests[0].id;
      lastVisibleDoc = await executer.getSingleDocumentSnapshot(
        query.base.getById(QueryBase.MANIFESTS_COLLECTION(), id)
      );
    }
    setPaginationInfo({
      ...paginationInfo,
      id: uuidv4(),
      isNext,
      lastVisibleDoc,
    });
  };

  const createManifests = async (numberNewDocuments: any) => {
    // HOTFIX to filter this additional information from scaleCompany object (unreliable)
    const { manifestTemplates, receiptTemplates, ...restScaleCompany } =
      project?.scaleCompany || {};

    await command.manifests.createManifests(
      authUser.id,
      numberNewDocuments,
      { ...project, scaleCompany: restScaleCompany },
      freshProjectScales,
      manifestTemplate
    );
    if (
      authUser.actingAsType === UserTypes.admin.super ||
      authUser.actingAsType === UserTypes.contractor.admin ||
      authUser.actingAsType === UserTypes.contractor.user
    ) {
      mixPanel(authUser, `Manifests Created by ${authUser.actingAsType}`, {
        'Project ID': project.id,
        'Generator Company': project.generatorCompany.id,
        'Profile Number': project.profileNr,
        'Project Name': project.name,
        'Total Manifests Created': numberNewDocuments,
      });
      // TODO: Ask about this line (kinda unexpected)
      if (authUser.preferredLoginMethod !== 'phone') {
        try {
          await sendGeneratorsNewUnsignedManifestsNotification(
            numberNewDocuments,
            project.id,
            project.name,
            project.profileNr,
            project.generatorCompany.id
          );
        } catch {
          showError({
            title: `Generators notification email failed but the ${numberNewDocuments} manifests were created successfully.`,
            duration: 10000,
          });
        }
      }
    }
  };

  const voidSelectedManifests = async (voidSelectedManifestsIds: string[]) => {
    await command.manifests.voidManifests(authUser, voidSelectedManifestsIds);
  };

  const unvoidSelectedManifests = async (ids: string[]) => {
    await command.manifests.unvoidManifests(authUser, ids);
  };

  const refreshSelectedManifests = async (manifestsIds: string[]) => {
    const res: any = await api.pubSub.staticApi.refreshWasteConnectionsTickets({
      projectId: project.id,
      numbers: filteredManifests.filter(m => manifestsIds.indexOf(m.id) >= 0).map(m => m.number),
    });

    if (res.data.error) {
      setWCAPIErrors(res.data.error);
    }
  };

  const callbackSetFilters = (value: any) => {
    dispatch(
      setProjectsManifestFilters({
        id: project.id,
        filters: { ...manifestFilters, ...value },
      })
    );

    setPaginationInfo({
      ...paginationInfo,
      isNext: null,
      lastVisibleDoc: null,
    });
  };

  const handleSetManifestSearchFilter = (text: any) => {
    setManifestSearchFilter(text);

    dispatch(
      setProjectsManifestFilters({
        id: project.id,
        filters: { ...manifestFilters, filterSearch: text },
      })
    );
  };

  const handleClickNextCall = () => {
    loadManifests(true);
    setIndicator(indicator + 1);
  };

  const handleClickBackCallback = () => {
    loadManifests(false);
    if (indicator !== 1) {
      setIndicator(1);
    }
  };

  const handleClickSign = async (signSelectedManifestsIds: any) => {
    await command.manifests.signManifestsByGenerator(authUser, signSelectedManifestsIds);

    callbackSetFilters({
      filterIsSigned: true,
      filterDateRange: false,
      filterIsVoid: false,
    });
    setSelectedManifestsIds([]);
  };

  interface WcAPIError {
    manifestNumber: string;
    error: string;
  }

  interface ErrorTableProps {
    error: WcAPIError[];
  }

  const handleAccordionStateChange = (event: React.SyntheticEvent, expanded: boolean) => {
    setWCAPIErrorsExpanded(expanded);
  };

  const ErrorTable: React.FC<ErrorTableProps> = ({ error }) => {
    if (error && error.length > 0) {
      return (
        <Accordion expanded={wcAPIErrorsExpanded} onChange={handleAccordionStateChange}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1bh-content">
            <Grid container>
              <Grid item>
                <h3 style={{ color: '#721c24' }}>Waste Connections API Errors</h3>
              </Grid>
            </Grid>
          </AccordionSummary>
          <AccordionDetails>
            <div style={{ backgroundColor: '#f8d7da', padding: '20px', borderRadius: '5px' }}>
              <table style={{ width: '100%', borderCollapse: 'collapse' }}>
                <thead>
                  <tr>
                    <th
                      style={{
                        textAlign: 'left',
                        padding: '10px',
                        borderBottom: '2px solid #721c24',
                      }}
                    >
                      Manifest Number
                    </th>
                    <th
                      style={{
                        textAlign: 'left',
                        padding: '10px',
                        borderBottom: '2px solid #721c24',
                      }}
                    >
                      Error
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {error.map((e: WcAPIError, index: number) => (
                    <tr key={index}>
                      <td style={{ padding: '10px', borderBottom: '1px solid #721c24' }}>
                        {e.manifestNumber}
                      </td>
                      <td style={{ padding: '10px', borderBottom: '1px solid #721c24' }}>
                        {e.error}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </AccordionDetails>
        </Accordion>
      );
    }
    return null;
  };

  return (
    <>
      {wcAPIErrors.length > 0 && <ErrorTable error={wcAPIErrors} />}

      <CrudTable
        rows={filteredManifests}
        columns={manifestColumns}
        isLoading={isLoadingData}
        setSelectedRowsIdsCallback={
          authUser && authUser.actingAsType === UserTypes.transporter.driver
            ? null
            : setSelectedManifestsIds
        }
        selectedRowsIds={selectedManifestsIds}
        handleRowClickCallback={manifest => navigate(`/manifests/${manifest.id}`)}
      >
        <ToolbarManifests
          recordsCount={recordsCount}
          indicator={indicator}
          selectedRowsIds={selectedManifestsIds}
          handleClickAddCallback={manifestTemplate && createManifests}
          setSelectedRowsIdsCallback={setSelectedManifestsIds}
          searchFilter={manifestSearchFilter}
          onSearchCallback={handleSetManifestSearchFilter}
          handleClickVoidCallback={voidSelectedManifests}
          handleClickUnvoidCallback={unvoidSelectedManifests}
          handleClickRefreshCallback={
            (project.fileNameActionsInCronJob || '').toLocaleLowerCase() === 'wasteconnections.ts'
              ? refreshSelectedManifests
              : undefined
          }
          handleClickSignCallback={handleClickSign}
          maxNewDocuments={200}
          isLoading={isLoadingData}
          handleNext={handleClickNextCall}
          handleBack={handleClickBackCallback}
          filters={filters}
          setFilters={callbackSetFilters}
          project={project}
        />
      </CrudTable>

      <ManifestsTableFooter
        totalQuantity={totalQuantity}
        recordsCount={recordsCount}
        indicator={indicator}
        handleNext={handleClickNextCall}
        handleBack={handleClickBackCallback}
        selectedManifestsIds={selectedManifestsIds}
        isLoading={isLoadingData}
        manifests={filteredManifests}
      />
    </>
  );
};

export default ManifestsViewPerProject;
