import { useEffect, useState } from 'react';
import { ReceiptTemplate } from 'lg-helpers/dist/shared/types/manifests';
import useShowError from 'modules/errors';
// import api from 'services/api/autogenerated';
import CompaniesApi from 'services/postgres/CompaniesApi';

const companiesApi = new CompaniesApi();

const useGetReceiptTemplate = (scaleCompanyId?: string, templateId?: string) => {
  const [template, setTemplate] = useState<ReceiptTemplate | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(true);
  const showError = useShowError();

  useEffect(() => {
    if (!scaleCompanyId || !templateId) {
      setIsLoading(false);
      return;
    }

    const load = async () => {
      setIsError(false);
      setIsLoading(true);

      try {
        const companyRes = await companiesApi.getById(scaleCompanyId);
        const foundTemplate = (companyRes.receiptTemplates || []).find(
          (t: ReceiptTemplate) => t.id === templateId
        );
        setTemplate(foundTemplate);
      } catch (err) {
        console.error('Failed to load receipt template', err);
        showError({
          title: 'Failed to load receipt template',
          duration: 10000,
        });
        setIsError(true);
      } finally {
        setIsLoading(false);
      }
    };

    load();
    // eslint-disable-next-line
  }, [templateId]);

  return {
    isLoading,
    isError,
    template,
  };
};

export default useGetReceiptTemplate;
