import * as React from 'react';
import CircularProgress, { CircularProgressProps } from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

export const CircularProgressWithLabel = (props: CircularProgressProps & { value: number }) => {
  return (
    <Box sx={{ position: 'relative', display: 'inline-flex' }}>
      <CircularProgress
        variant="determinate"
        {...props}
        style={{
          width: '100%',
          height: '100%',
          minWidth: 150,
          minHeight: 150,
          color: '#fff',
          position: 'absolute',
        }}
        value={100}
      />
      <CircularProgress
        variant="determinate"
        {...props}
        style={{ width: '100%', height: '100%', minWidth: 150, minHeight: 150 }}
      />

      <Box
        sx={{
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          minWidth: 150,
          minHeight: 150,
          position: 'absolute',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Typography variant="h4" component="div" color="text.secondary">{`${Math.round(
          props.value
        )}%`}</Typography>
      </Box>
    </Box>
  );
};

export default CircularProgressWithLabel;
