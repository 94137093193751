import React, { ReactElement } from 'react';
import './LivDropdownMenu.scss';
import Button from '@mui/material/Button';
import ArrowDropDown from '@mui/icons-material/ArrowDropDown';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';

// todo: reenable when tsx and ts is supported
export interface DropdownItemProps {
  key: string;
  val: string;
  icon?: SVGElement | ReactElement<any, any>;
}

interface LivDropdownMenuProps {
  val: DropdownItemProps | string;
  helpText: string;
  listItems: DropdownItemProps[] | string[];
  onSelection(item: DropdownItemProps | string): void;
  variant?: 'text' | 'contained' | 'outlined';
  checkedItems?: DropdownItemProps[] | string[];
}

const LivDropdownMenu = ({
  val,
  helpText,
  variant,
  listItems,
  onSelection,
  checkedItems,
}: LivDropdownMenuProps) => {
  const [anchorEl, setAnchorEl] = React.useState<any>(null);
  const open = Boolean(anchorEl);
  const handleClose = () => setAnchorEl(null);

  const isObjects = typeof listItems[0] === 'object';

  return (
    <div className="LivDropdown">
      <Button
        id="demo-customized-button"
        aria-controls="demo-customized-menu"
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        variant={variant ? variant : 'contained'}
        disableElevation
        onClick={event => setAnchorEl(event.currentTarget)}
        endIcon={<ArrowDropDown />}
        role="LivDropdownMenu--button"
      >
        {helpText}
      </Button>
      <Menu
        className="LivDropdown__dropdown-menu"
        id="demo-customized-menu"
        MenuListProps={{
          'aria-labelledby': 'demo-customized-button',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        role="LivDropdownMenu--dropdown"
      >
        {listItems.map((selectItem: any, idx) => (
          <MenuItem
            key={idx}
            role="LivDropdownMenu--dropdown__items"
            value={isObjects ? selectItem.val : selectItem}
            selected={(isObjects ? selectItem.val : selectItem) === val}
            onClick={() => {
              onSelection(selectItem);
              handleClose();
            }}
          >
            {!checkedItems ? (
              <>
                {selectItem.icon && selectItem.icon}
                {isObjects ? selectItem.key : selectItem}
              </>
            ) : checkedItems.includes(selectItem.val) ? (
              <>
                <CheckBoxIcon />
                {selectItem.key}
              </>
            ) : (
              <>
                <CheckBoxOutlineBlankIcon />
                {selectItem.key}
              </>
            )}
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
};

export default LivDropdownMenu;
