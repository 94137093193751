import React from 'react';
import CreateWorkCode from './CreateWorkCode';
import CancelWorkCode from './CancelWorkCode';
import { WorkCodes } from '../../../services/api/autogenerated-code';

interface WorkCodeTopBarProps {
  projectId: string;
  workCode: WorkCodes | undefined;
  onWorkCodeUpdate: () => void;
}

const WorkCodeTopBar: React.FC<WorkCodeTopBarProps> = ({
  projectId,
  workCode,
  onWorkCodeUpdate,
}) => {
  if (!workCode)
    return <CreateWorkCode projectId={projectId} onWorkCodeUpdate={onWorkCodeUpdate} />;
  else
    return (
      <CancelWorkCode
        projectId={projectId}
        workCode={workCode}
        onWorkCodeUpdate={onWorkCodeUpdate}
      />
    );
};

export default WorkCodeTopBar;
