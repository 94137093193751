import React from 'react';
import { useNavigate } from 'react-router-dom';
import { ListItem, ListItemButton, ListItemIcon, ListItemText } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import { IGlobalCustomInputs } from 'services/api/autogenerated-code';
import { ICompany } from 'lg-helpers/dist/shared/interfaces/ICompany';

interface CustomInputDefinitionProps {
  inputDefinition: IGlobalCustomInputs;
  setInputDefinition: (inputDefinition: IGlobalCustomInputs) => void;
  canValidate: boolean;
  setCanValidate: (canValidate: boolean) => void;
  hideCallback: (inputDefinition: IGlobalCustomInputs) => void;
  userCanEdit?: boolean;
  companies?: ICompany[];
  deleteCustomInput?: (id: number) => void;
  handleSaveSingleInput: (inputDefinition: IGlobalCustomInputs) => void;
}

export const CustomInputDefinitionGlobalList = ({
  inputDefinition,
}: CustomInputDefinitionProps) => {
  const navigate = useNavigate();
  return (
    <ListItem
      key={`custom_input__${inputDefinition.id}`}
      id={`custom_input__${inputDefinition.id}`}
      disablePadding
    >
      <ListItemButton component="a" onClick={() => navigate(`./${inputDefinition.id}`)}>
        <ListItemIcon>
          <EditIcon />
        </ListItemIcon>
        <ListItemText primary={`[${inputDefinition.type}] ${inputDefinition.name}`} />
      </ListItemButton>
    </ListItem>
  );
};
