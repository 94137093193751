import React from 'react';
import { Paper } from '@mui/material';
import { ProjectInfoForm } from './views/ProjectInfoForm';
import { ProjectsList } from './views/ProjectsList';
import { ProjectMaterials } from 'modules/civilAndTrucking/civil/ProjectMaterials';
import { ProjectSubMaterials } from '../ProjectSubMaterials';
import { Routes, Route } from 'react-router';
import WorkCategories from '../ActivityTypes';
import Activities from '../Activities';
import Collaborators from './views/Collaborators';
import ApiMappings from './ApiMappings/ApiMappings';
import VerifyProjectOwnershipWrapper from 'sharedComponents/VerifyProjectOwnershipWrapper';
import { ProjectSubMaterialForm } from '../ProjectSubMaterials/ProjectSubMaterialForm';

export const CreateProject = () => {
  return (
    <>
      <Paper elevation={0} sx={{ backgroundColor: 'rgba(0,0,0,0)' }}>
        <Routes>
          <Route path="/" element={<ProjectsList />} />
          <Route path="/add" element={<ProjectInfoForm />} />
          <Route
            path="/:projectId"
            element={
              <VerifyProjectOwnershipWrapper redirect="/civil">
                <ProjectInfoForm />
              </VerifyProjectOwnershipWrapper>
            }
          />
          <Route
            path="/:projectId/edit"
            element={
              <VerifyProjectOwnershipWrapper redirect="/civil">
                <ProjectInfoForm />
              </VerifyProjectOwnershipWrapper>
            }
          />
          <Route
            path="/:projectId/materials"
            element={
              <VerifyProjectOwnershipWrapper redirect="/civil">
                <ProjectMaterials />
              </VerifyProjectOwnershipWrapper>
            }
          />
          <Route
            path="/:projectId/sub-materials"
            element={
              <VerifyProjectOwnershipWrapper redirect="/civil">
                <ProjectSubMaterials />
              </VerifyProjectOwnershipWrapper>
            }
          />
          <Route
            path="/:projectId/sub-materials/:subMaterialId"
            element={
              <VerifyProjectOwnershipWrapper redirect="/civil">
                <ProjectSubMaterialForm />
              </VerifyProjectOwnershipWrapper>
            }
          />
          <Route
            path="/:projectId/work-categories"
            element={
              <VerifyProjectOwnershipWrapper redirect="/civil">
                <WorkCategories />
              </VerifyProjectOwnershipWrapper>
            }
          />
          <Route
            path="/:projectId/work-categories/activities"
            element={
              <VerifyProjectOwnershipWrapper redirect="/civil">
                <Activities />
              </VerifyProjectOwnershipWrapper>
            }
          />
          <Route
            path="/:projectId/collaborators"
            element={
              <VerifyProjectOwnershipWrapper redirect="/civil">
                <Collaborators />
              </VerifyProjectOwnershipWrapper>
            }
          />
          <Route
            path="/:projectId/api-mappings"
            element={
              <VerifyProjectOwnershipWrapper redirect="/civil">
                <ApiMappings />
              </VerifyProjectOwnershipWrapper>
            }
          />
        </Routes>
      </Paper>
    </>
  );
};

export default CreateProject;
