import { useEffect } from 'react';
import { usePermissionsContext } from 'services/permissions';
const currentEnv = process.env.REACT_APP_BUILD_ENV;
const isProduction = currentEnv === 'production';

interface FVIdentity {
  id: string | undefined;
  name: string;
  email?: string | undefined;
  disableReplaysForUser?: boolean | undefined;
  [customData: string]: any; // value needs to be string for added customData
}

interface ExtendWindow extends Partial<Window> {
  $fvIdentity?: FVIdentity;
}

// https://support.fullview.io/en/articles/6122361-how-to-install-fullview
export const FullViewWidgetInitialize = () => {
  useEffect(() => {
    if (document.querySelector('[src="//install.fullview.io"]')) return;
    let l = function () {
      let s = document.createElement('script');
      s.type = 'text/javascript';
      s.async = true;
      s.src = '//install.fullview.io';
      s.dataset.org = 'a5d25c84-f940-406b-8db1-872fc62d32d6';
      const x = document.getElementsByTagName('script')[0];
      if (x && x.parentNode) x.parentNode.insertBefore(s, x);
    };
    if (document.readyState === 'complete') l();
    else window.addEventListener('load', l, false);
    // eslint-disable-next-line
  }, []);

  return null;
};

export const FullViewWidgetSetUser = () => {
  const { user } = usePermissionsContext();
  const { id, firstName, lastName, email } = user;
  const w = window as ExtendWindow;

  useEffect(() => {
    w.$fvIdentity = {
      id, // Required - anything unique in your organisation
      name: `${firstName} ${lastName}` + (!isProduction ? ` (${currentEnv})` : ''), // Required - visible in Fullview dashboard
      email, // Optional - visible in Fullview dashboard
    };
    // eslint-disable-next-line
  }, []);

  return null;
};
