import React, { useState, useCallback, useEffect } from 'react';
import companiesTypes from 'services/constants/generatorCompaniesTypes.json';
import InputAddressFull from 'sharedComponents/InputAddressFull';
import { hasErrorRequired, hasErrorValidEmail } from 'services/logic/formValidation';
import { sendActivateAccountEmail } from 'api/auth';
import api from 'services/api/autogenerated';
import { UserTypes } from 'lg-helpers/dist/constants/user/UserTypes';
import {
  Grid,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Box,
  Button,
  CircularProgress,
  Typography,
  TextField,
  Autocomplete,
} from '@mui/material';
import useShowError from 'modules/errors';
import CompaniesApi from 'services/postgres/CompaniesApi';
import ErrorMessages from 'services/constants/errorMessages';
import InputMasker from 'sharedComponents/InputMasker';
const { isRequired, emailNotValid } = ErrorMessages;

const companiesApi = new CompaniesApi();

const InviteCompanyPopup = ({
  disabled,
  disabledState,
  lockCompanyTypes,
  state,
  inviteUserType = UserTypes.contractor.admin,
  popupTitle = 'Invite contractor company',
  userSectionTitle = 'Contractor admin (will be invited)',
  name = '',
}: {
  disabled?: boolean;
  disabledState?: boolean;
  lockCompanyTypes?: string[];
  state?: string;
  inviteUserType?: string;
  popupTitle?: string;
  userSectionTitle?: string;
  name?: string;
}) => {
  const [wasSubmitted, setWasSubmitted] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [isLoadingInvite, setLoadingInvite] = useState(false);
  const [isLoadingSaveDetails, setIsSavingSaveDetails] = useState(false);
  const showError = useShowError();
  const [email, setEmail] = useState('');
  const [isDisabledState, setIsDisabledState] = useState(false);

  const [company, setCompany] = useState({} as any);

  const hasErrorsCompany = () => {
    if (company.regions && company.regions.length) {
      const emptyRegion = company.regions.find((region: any) => !region.name);
      if (emptyRegion) {
        return true;
      }
    }

    return (
      hasErrorRequired(company.name) ||
      hasErrorRequired(company.types) ||
      hasErrorRequired(company.country) ||
      hasErrorRequired(company.city) ||
      hasErrorRequired(company.state)
    );
  };

  const isEmailError = hasErrorRequired(email) || hasErrorValidEmail(email);
  const isLoading = isLoadingInvite || isLoadingSaveDetails;
  const isValidationError = hasErrorsCompany() || isEmailError;

  const handleChange = useCallback(
    (event: any) => {
      setCompany({
        ...company,
        [event.target.name]: event.target.value,
      });
    },
    [company]
  );

  const handleClose = () => setIsOpen(false);

  const handleInvite = async (companyToInvite: any) => {
    try {
      setWasSubmitted(true);
      setLoadingInvite(true);
      // eslint-disable-next-line no-unused-vars
      const userData = {
        firstName: '',
        lastName: '',
        email: email.trim(),
        companies: [
          {
            id: companyToInvite.id,
            name: companyToInvite.name,
            types: companyToInvite.types,
          },
        ],
        companiesIds: [companyToInvite.id],
        type: inviteUserType,
      };
      // TODO: remove this 'any' (should be correct return type from API)
      const { data: apiResponse } = (await api.users.create(userData)) as any;
      const newId = apiResponse.id;
      await sendActivateAccountEmail(newId);
      handleClose();
    } catch (error: any) {
      console.error(error);
      if (error?.response?.data?.message) {
        showError({ title: 'Error', text: error.response.data.message });
      }
      showError({ title: 'Error' });
    } finally {
      setLoadingInvite(false);
    }
  };

  const handleSaveDetails = async () => {
    if (isValidationError) {
      showError({ title: 'Validation errros' });
      return;
    }

    setIsSavingSaveDetails(true);
    const companyToSave = {
      ...company,
    } as any;

    if (companyToSave.types) companyToSave.types.sort();

    try {
      const { id } = await companiesApi.insertOrUpdate(companyToSave);

      handleInvite({
        ...companyToSave,
        id,
      });
    } catch (error) {
      console.error(error);
      showError({ title: 'Save failed' });
    }

    setIsSavingSaveDetails(false);
  };

  useEffect(() => {
    const newCompany = { ...company };
    if (state) {
      newCompany.state = state;
      setIsDisabledState(true);
    }
    if (name) newCompany.name = name;
    if (lockCompanyTypes) newCompany.types = lockCompanyTypes.slice();
    setCompany(newCompany);
    // eslint-disable-next-line
  }, [state, disabledState, name, lockCompanyTypes]);

  return (
    <>
      <Button
        size="large"
        disabled={disabled}
        variant="outlined"
        color="primary"
        onClick={() => setIsOpen(true)}
      >
        Add
      </Button>
      <Dialog open={isOpen} onClose={handleClose}>
        <DialogTitle>
          <Typography variant="h4">{popupTitle}</Typography>
        </DialogTitle>

        <DialogContent>
          <Grid container spacing={2}>
            <Grid item sm={6} xs={12}>
              <TextField
                onChange={handleChange}
                required
                fullWidth
                label="Name"
                name="name"
                value={company.name || ''}
                variant="outlined"
                error={hasErrorRequired(company.name, wasSubmitted)}
                helperText={hasErrorRequired(company.name, wasSubmitted) ? isRequired : ''}
              />
            </Grid>
            <Grid item sm={6} xs={12}>
              <Autocomplete
                multiple
                disabled={!!lockCompanyTypes}
                options={lockCompanyTypes || companiesTypes}
                isOptionEqualToValue={(option, val) =>
                  option && val ? option.id === val.id : false
                }
                value={company.types || []}
                onChange={(event, value) =>
                  handleChange({
                    target: {
                      name: 'types',
                      value,
                    },
                  })
                }
                renderInput={params => (
                  <TextField
                    {...params}
                    variant="outlined"
                    required
                    label="Types"
                    placeholder="Select company types"
                    error={hasErrorRequired(company.types, wasSubmitted)}
                    helperText={hasErrorRequired(company.types, wasSubmitted) ? isRequired : ''}
                  />
                )}
              />
            </Grid>
            <Grid item sm={6} xs={12}>
              <TextField
                onChange={handleChange}
                fullWidth
                label="Email"
                name="email"
                value={company.email || ''}
                variant="outlined"
                error={hasErrorValidEmail(company.email, wasSubmitted)}
                helperText={hasErrorValidEmail(company.email, wasSubmitted) ? emailNotValid : ''}
              />
            </Grid>
            <Grid item sm={6} xs={12}>
              <InputMasker
                fullWidth
                name="phone"
                label="Phone Number"
                variant="outlined"
                val={company.phone || ''}
                mask="+###########"
                onChange={val =>
                  setCompany({
                    ...company,
                    phone: val,
                  })
                }
              />
            </Grid>
            <InputAddressFull
              locationData={company}
              handleChangeCallback={handleChange}
              wasSubmitted={wasSubmitted}
              addressIsOptional={true}
              isDisabledState={isDisabledState}
              returnAllStringValues
            />
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography variant="h6" marginTop={3}>
                {userSectionTitle}
              </Typography>
            </Grid>
            <Grid item md={6} xs={12}>
              <TextField
                label="Email"
                name="email"
                fullWidth
                required
                value={email}
                variant="outlined"
                onChange={e => setEmail(e.target.value)}
                error={
                  hasErrorRequired(email, wasSubmitted) || hasErrorValidEmail(email, wasSubmitted)
                }
                helperText={
                  hasErrorRequired(email, wasSubmitted)
                    ? isRequired
                    : hasErrorValidEmail(email, wasSubmitted)
                    ? emailNotValid
                    : ''
                }
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Box display="flex" justifyContent="space-between" alignItems="center" flex={1}>
            <Button
              color="primary"
              variant="contained"
              onClick={handleSaveDetails}
              disabled={isLoading || isValidationError}
            >
              Invite
              {isLoading && (
                <Box marginLeft={2}>
                  <CircularProgress size="15px" color="inherit" />
                </Box>
              )}
            </Button>
            <Button
              autoFocus
              color="inherit"
              variant="outlined"
              disabled={isLoading}
              onClick={handleClose}
            >
              Cancel
            </Button>
          </Box>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default InviteCompanyPopup;
