import React, { useEffect, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { hasErrorRequired } from 'services/logic/formValidation';
import {
  Grid,
  TextField,
  Card,
  CardHeader,
  CardContent,
  Divider,
  Box,
  Button,
  CircularProgress,
  Typography,
  Autocomplete,
} from '@mui/material';
import { selectAuthUser } from 'modules/auth/storeSliceAuth';
import useGetCustomInputs from 'services/hooks/useGetCustomInputs';
import useShowError from 'modules/errors';
import Command from 'services/firebase/Command';
import InputSignature from 'sharedComponents/InputSignature';
import { getBase64FromUrl } from 'services/logic/base64';
import TypeCustomInput from './InputTypeCustom';
import { uploadManifestCustomInputImageAndGetUrl } from 'api/manifest';
import { FieldValue } from 'services/firebase';
import ErrorMessages from 'services/constants/errorMessages';
const { isRequired } = ErrorMessages;

const command = new Command();

const getManifestCustomInputValue = (customInputs = {}, inputDefinition) =>
  (customInputs[inputDefinition.propertyPathInManifestCustomInputs] || {}).value;

const getManifestCustomInputBase64 = (customInputs, inputDefinition) =>
  ((customInputs || {})[inputDefinition.propertyPathInManifestCustomInputs] || {}).base64;

const CustomInputsRenderer = ({ manifest, section, isReadOnly, setIsValid, onSaved }) => {
  const [localCustomInputs, setLocalCustomInputs] = useState({});
  const [isLoadingLocalCustomInputs, setIsLoadingLocalCustomInputs] = useState(false);
  const [canValidate, setCanValidate] = useState(false);
  const [isLoadingSaveCustomInputs, setIsLoadingSaveCustomInputs] = useState(false);
  const showError = useShowError();
  useState([]);
  const authUser = useSelector(selectAuthUser);
  const [customInputsEditable, customInputsReadOnly, isloadingCustomInputs] = useGetCustomInputs(
    manifest?.project?.id,
    authUser?.type,
    section
  );

  const getCustomInputDefaultValue = useCallback(
    inputDefinition =>
      ((manifest?.customInputs || {})[inputDefinition?.propertyPathInManifestCustomInputs] || {})
        ?.value ||
      inputDefinition.defaultValue ||
      '',
    [manifest]
  );

  useEffect(() => {
    if (isloadingCustomInputs || !manifest) {
      return;
    }

    if (!customInputsEditable.length) {
      onSaved && onSaved();
    }

    const getLocalCustomInputs = async () => {
      setIsLoadingLocalCustomInputs(true);
      let inputs = {};
      const allCustomInputs = [...customInputsEditable, ...customInputsReadOnly];
      for (let i = 0; i < allCustomInputs.length; i++) {
        const customInput = allCustomInputs[i];
        const value = getCustomInputDefaultValue(customInput);
        let base64 = null;
        if (value && customInput.type === TypeCustomInput.SIGNATURE) {
          base64 = await getBase64FromUrl(value);
        }

        inputs[`${customInput.propertyPathInManifestCustomInputs}`] = {
          value,
          base64,
        };
      }
      setIsLoadingLocalCustomInputs(false);
      setLocalCustomInputs(inputs);
    };

    getLocalCustomInputs();
    // eslint-disable-next-line
  }, [customInputsEditable, manifest, isloadingCustomInputs]);

  useEffect(() => {
    if (!customInputsEditable.length && !isloadingCustomInputs) {
      setIsValid && setIsValid(true);
    }
  }, [customInputsEditable, isloadingCustomInputs, setIsValid]);

  const handleChange = event => {
    setLocalCustomInputs({
      ...localCustomInputs,
      [event.target.name]: {
        value: event.target.type === 'checkbox' ? event.target.checked : event.target.value,
      },
    });
    setCanValidate(false);
  };

  const handleBase64Change = useCallback(
    (inputDefinition, base64) => {
      setLocalCustomInputs({
        ...localCustomInputs,
        [inputDefinition.propertyPathInManifestCustomInputs]: { base64 },
      });
    },
    [localCustomInputs]
  );

  const saveCustomInputs = async () => {
    setIsLoadingSaveCustomInputs(true);
    setCanValidate(true);
    try {
      let isValid = true;
      const customInputsDecorated = {};
      customInputsEditable
        .filter(
          ({ type, propertyPathInManifestCustomInputs }) =>
            propertyPathInManifestCustomInputs && type !== TypeCustomInput.SIGNATURE
        )
        .forEach(inputDefinition => {
          if (inputDefinition.isRequired) {
            isValid =
              isValid &&
              !hasErrorRequired(getManifestCustomInputValue(localCustomInputs, inputDefinition));
          }

          const value = getManifestCustomInputValue(localCustomInputs, inputDefinition);

          if (value !== undefined) {
            customInputsDecorated[inputDefinition.propertyPathInManifestCustomInputs] = {
              value,
              enteredAt: FieldValue.serverTimestamp(),
              enteredByUserId: authUser.id,
              columnIndex: inputDefinition.columnIndex || null,
            };
          }
        });

      if (isValid) {
        const signatureCustomInputs = customInputsEditable.filter(
          ({ type, propertyPathInManifestCustomInputs }) =>
            propertyPathInManifestCustomInputs && type === TypeCustomInput.SIGNATURE
        );
        for (let i = 0; i < signatureCustomInputs.length; i++) {
          const inputDefinition = signatureCustomInputs[i];

          const base64 = getManifestCustomInputBase64(localCustomInputs, inputDefinition);

          if (inputDefinition.isRequired) {
            isValid = isValid && !!base64;
          }

          if (isValid) {
            if (base64) {
              try {
                const urlForBase64 = await uploadManifestCustomInputImageAndGetUrl(
                  manifest.id,
                  base64
                );
                customInputsDecorated[inputDefinition.propertyPathInManifestCustomInputs] = {
                  value: urlForBase64,
                  enteredAt: FieldValue.serverTimestamp(),
                  enteredByUserId: authUser.id,
                  columnIndex: inputDefinition.columnIndex || null,
                };
              } catch (err) {
                if (inputDefinition.isRequired) {
                  isValid = false;
                }
                console.error('Error at uploadManifestCustomInputImageAndGetUrl', err);
              }
            } else {
              customInputsDecorated[inputDefinition.propertyPathInManifestCustomInputs] = {
                value: null,
                enteredAt: FieldValue.serverTimestamp(),
                enteredByUserId: authUser.id,
                columnIndex: inputDefinition.columnIndex || null,
              };
            }
          }
        }
      }

      isValid &&
        (await command.manifests.updateManifestCustomInputs(manifest.id, customInputsDecorated));

      onSaved && onSaved();

      setIsValid && setIsValid(isValid);
    } catch (err) {
      console.error(err);
      showError({ title: 'Save error' });
      setIsValid && setIsValid(false);
    }
    setIsLoadingSaveCustomInputs(false);
  };

  return (
    <>
      {!!(customInputsEditable.length || customInputsReadOnly.length) && (
        <Card>
          <CardHeader
            title="Manifest Custom Inputs"
            action={
              <Box display="flex">
                {customInputsEditable.length && (
                  <Button
                    color="primary"
                    sx={{ minWidth: '185px' }}
                    variant="contained"
                    onClick={saveCustomInputs}
                  >
                    {isLoadingSaveCustomInputs ? (
                      <Box
                        sx={{
                          transform: 'scale(.5)',
                          height: '24px',
                          width: '24px',
                          position: 'relative',
                        }}
                        marginLeft={2}
                      >
                        <Box
                          sx={{
                            transform: 'translate(-50%, -50%)',
                            position: 'absolute',
                            left: '50%',
                            top: '50%',
                            lineHeight: 0,
                          }}
                        >
                          <CircularProgress color="inherit" />
                        </Box>
                      </Box>
                    ) : (
                      'Save Custom Inputs'
                    )}
                  </Button>
                )}
              </Box>
            }
          />
          <Divider />
          <CardContent>
            <Grid container spacing={2}>
              {customInputsEditable.map(inputDefinition => {
                switch (inputDefinition.type) {
                  case TypeCustomInput.TEXT:
                    return (
                      <Grid item sm={6} xs={12} key={inputDefinition.id}>
                        <TextField
                          fullWidth
                          disabled={isReadOnly}
                          label={inputDefinition.label}
                          name={inputDefinition.propertyPathInManifestCustomInputs}
                          onChange={handleChange}
                          value={
                            getManifestCustomInputValue(localCustomInputs, inputDefinition) || ''
                          }
                          variant="outlined"
                          error={
                            inputDefinition.isRequired
                              ? hasErrorRequired(
                                  getManifestCustomInputValue(localCustomInputs, inputDefinition),
                                  canValidate
                                )
                              : null
                          }
                          helperText={
                            inputDefinition.isRequired &&
                            hasErrorRequired(
                              getManifestCustomInputValue(localCustomInputs, inputDefinition),
                              canValidate
                            )
                              ? isRequired
                              : ''
                          }
                          required={inputDefinition.isRequired}
                        />
                      </Grid>
                    );
                  case TypeCustomInput.LIST:
                    return (
                      <Grid item sm={6} xs={12} key={inputDefinition.id}>
                        <Autocomplete
                          options={
                            inputDefinition?.items?.filter(item => item.label && item.value) || []
                          }
                          value={
                            getManifestCustomInputValue(localCustomInputs, inputDefinition) || null
                          }
                          isOptionEqualToValue={(option, value) => {
                            return value
                              ? option?.id === value?.id || option?.value === value
                              : false;
                          }}
                          getOptionLabel={option => option?.label || option || ''}
                          onChange={(event, option) => {
                            handleChange(
                              {
                                target: {
                                  name: `${inputDefinition.propertyPathInManifestCustomInputs}`,
                                  value: option?.value || option,
                                },
                              },
                              true
                            );
                          }}
                          renderInput={params => (
                            <TextField
                              {...params}
                              label={inputDefinition.label}
                              required
                              error={
                                inputDefinition.isRequired
                                  ? hasErrorRequired(
                                      getManifestCustomInputValue(
                                        localCustomInputs,
                                        inputDefinition
                                      ),
                                      canValidate
                                    )
                                  : null
                              }
                              helperText={
                                inputDefinition.isRequired &&
                                hasErrorRequired(
                                  getManifestCustomInputValue(localCustomInputs, inputDefinition),
                                  canValidate
                                )
                                  ? isRequired
                                  : ''
                              }
                              variant="outlined"
                              InputProps={{
                                ...params.InputProps,
                                endAdornment: <>{params.InputProps.endAdornment}</>,
                              }}
                            />
                          )}
                        />
                      </Grid>
                    );
                  case TypeCustomInput.SIGNATURE:
                    return (
                      <Grid item sm={6} xs={12} key={inputDefinition.id}>
                        <InputSignature
                          title={
                            inputDefinition.isRequired
                              ? `${inputDefinition.label} *`
                              : inputDefinition.label
                          }
                          signatureBase64={getManifestCustomInputBase64(
                            localCustomInputs,
                            inputDefinition
                          )}
                          onSignatureBase64Update={base64 =>
                            handleBase64Change(inputDefinition, base64)
                          }
                          isLoading={isLoadingLocalCustomInputs}
                          disabled={isLoadingLocalCustomInputs}
                        />
                      </Grid>
                    );
                  default:
                    return (
                      <Grid item sm={6} xs={12} key={inputDefinition.id}>
                        No rendering defined for {inputDefinition.type}
                      </Grid>
                    );
                }
              })}
              {!!customInputsReadOnly.length && (
                <Grid item xs={12}>
                  <Typography color="primary" variant="body1">
                    Read only
                  </Typography>
                </Grid>
              )}
              {customInputsReadOnly.map(inputDefinition => {
                switch (inputDefinition.type) {
                  case TypeCustomInput.SIGNATURE:
                    return (
                      <Grid item sm={6} xs={12} key={inputDefinition.id}>
                        <InputSignature
                          title={
                            inputDefinition.isRequired
                              ? `${inputDefinition.label} *`
                              : inputDefinition.label
                          }
                          signatureBase64={getManifestCustomInputBase64(
                            localCustomInputs,
                            inputDefinition
                          )}
                          isLoading={isLoadingLocalCustomInputs}
                          disabled={true}
                        />
                      </Grid>
                    );
                  default:
                    return (
                      <Grid item sm={6} xs={12} key={inputDefinition.id}>
                        <TextField
                          fullWidth
                          disabled={true}
                          label={inputDefinition.label}
                          value={
                            getManifestCustomInputValue(localCustomInputs, inputDefinition) || ''
                          }
                          variant="outlined"
                          required={inputDefinition.isRequired}
                        />
                      </Grid>
                    );
                }
              })}
            </Grid>
          </CardContent>
        </Card>
      )}
    </>
  );
};

CustomInputsRenderer.propTypes = {
  manifest: PropTypes.object,
  section: PropTypes.string,
  isReadOnly: PropTypes.bool,
  setIsValid: PropTypes.func,
  onSaved: PropTypes.func,
};

export default CustomInputsRenderer;
