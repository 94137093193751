import React from 'react';
import { createSvgIcon } from '@mui/material';

export const Union = createSvgIcon(
  <path
    fillRule="evenodd"
    clipRule="evenodd"
    d="M3.5 0c.55228 0 1 .44771 1 1v.5h6c.1354 0 .2694.02751.3939.08085L14.2053 3H17.5c1.1046 0 2 .89542 2 2v8.5c0 1.1046-.8954 2-2 2H14a.9992.9992 0 0 1-.3939-.0809L10.2947 14H4.5v4h1c.55228 0 1 .4477 1 1s-.44772 1-1 1h-4c-.55229 0-1-.4477-1-1s.44771-1 1-1h1V1c0-.55229.44772-1 1-1Zm1 12V3.5h5.7947l3.3114 1.41915C13.7306 4.97249 13.8646 5 14 5h3.5v8.5h-3.2947l-3.3114-1.4191A.9992.9992 0 0 0 10.5 12h-6Z"
  />,
  'Union'
);
