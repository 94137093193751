import React, { useEffect, useState } from 'react';
import Query from 'services/firebase/Query';
import Executer from 'services/firebase/Executer';
import { useSelector } from 'react-redux';
import { selectAuthUser } from 'modules/auth/storeSliceAuth';
import { FormControl, Grid, MenuItem, Select, Typography } from '@mui/material';
import ButtonDownload from 'sharedComponents/ButtonDownload';
import moment from 'moment';
import { constants } from 'lg-helpers';

const {
  pubSub: { DownloadType },
} = constants;

const query = new Query();
const executer = new Executer();

interface IDownloadDropdownProps {
  projectId?: number;
  downloadType: string;
}

const DownloadDropdown = ({ projectId, downloadType }: IDownloadDropdownProps) => {
  const authUser = useSelector(selectAuthUser);
  const [downloads, setDownloads] = useState<FirebaseFirestore.DocumentData[]>();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (!authUser || (downloadType === DownloadType.TICKETS_CIVIL() && !projectId)) {
      return;
    }
    setIsLoading(true);
    return executer.watchMultipleDocuments(
      query.users.getUserDownloadsByTypeAndProject(authUser, downloadType, projectId!),
      firestoreDownloads => {
        setIsLoading(false);
        setDownloads(firestoreDownloads);
      },
      err => {
        setIsLoading(false);
        console.error(err);
      }
    );
    // eslint-disable-next-line
  }, [authUser, projectId]);

  return (
    <>
      <FormControl fullWidth>
        <Select label="Downloads" disabled={isLoading}>
          {downloads && downloads.length > 0 ? (
            downloads.map(download => (
              <MenuItem value={download.id} key={download.id}>
                <Grid container alignItems="center">
                  <Grid item xs={9}>
                    <Typography variant="subtitle1">
                      {download.progress.countTotal
                        ? `${download.progress.countTotal} Tickets`
                        : 'New Download'}
                    </Typography>
                    <Typography variant="caption">
                      {moment(new Date(download.progress.startedAt.seconds * 1000)).format(
                        'MM-DD-YYYY hh:mm A'
                      )}
                    </Typography>
                  </Grid>
                  <Grid item xs={3}>
                    <ButtonDownload isLoading={!download.url} url={download.url} />
                  </Grid>
                </Grid>
              </MenuItem>
            ))
          ) : (
            <MenuItem value={-1}>No downloads available</MenuItem>
          )}
        </Select>
      </FormControl>
    </>
  );
};

export default DownloadDropdown;
