import React, { useState, useEffect } from 'react';
import './Note.scss';
import PropTypes from 'prop-types';
import {
  ListItem,
  ListItemAvatar,
  Avatar,
  ListItemText,
  Typography,
  Grid,
  Box,
} from '@mui/material';
import InputCameraImage from 'sharedComponents/InputCameraImage';
import { logic } from 'lg-helpers';
import { getBase64FromUrl } from 'services/logic/base64';

const { getDateStringForTimezone } = logic.dateTime;

const Note = ({ note, manifest }) => {
  const [imageBase64, setImageBase64] = useState(null);
  const [noteCreatedAtSring, setNoteCreatedAtSring] = useState(null);
  const [noteEditedString, setNoteEditedString] = useState('');
  const [isLoadingImage, setIsLoadingImage] = useState(false);

  useEffect(() => {
    if (!note || !manifest) {
      return;
    }

    const { urlImage, createdAt, editedBy } = note;
    if (urlImage) {
      setIsLoadingImage(true);
      getBase64FromUrl(urlImage).then(
        base64 => {
          setImageBase64(base64);
          setIsLoadingImage(false);
        },
        error => {
          console.error('getBase64FromUrl', error);
          setIsLoadingImage(false);
        }
      );
    }

    if (editedBy && editedBy?.date) {
      const { date: editDate = '', name: editUser = '' } = editedBy;
      setNoteEditedString(
        `edited - ${editUser} - ${getDateStringForTimezone(
          editDate.toDate(),
          manifest.project.timeZone
        )}`
      );
    }

    if (createdAt) {
      setNoteCreatedAtSring(
        getDateStringForTimezone(createdAt.toDate(), manifest.project.timeZone)
      );
    }
  }, [note, manifest]);

  return (
    <ListItem alignItems="flex-start" className="Note__item" disableGutters>
      <ListItemAvatar>
        <Avatar alt="Remy Sharp" src={note.user.avatar} />
      </ListItemAvatar>
      <ListItemText
        primary={
          <Grid container>
            <Grid item xs={12} className="Note__title">
              <Box>
                <Typography variant="body2" className="Note__text" color="textPrimary">
                  {note.user.name || ''} - {note.user.type}
                </Typography>
              </Box>
              <Box marginBottom={1}>
                <Typography variant="body4" className="Note__date--created" color="textPrimary">
                  {noteCreatedAtSring || '-'}
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Box marginBottom={1}>
                <Typography variant="h5" className="Note__text" color="textPrimary">
                  {note.text}
                </Typography>
              </Box>
            </Grid>
            {note.urlImage && (
              <Grid item xs={12}>
                <Box marginBottom={1}>
                  <InputCameraImage
                    title="Image"
                    imageBase64={imageBase64}
                    isLoading={isLoadingImage}
                    disabled={true}
                  />
                </Box>
              </Grid>
            )}
          </Grid>
        }
        secondary={noteEditedString}
      />
    </ListItem>
  );
};

Note.propTypes = {
  note: PropTypes.object,
  manifest: PropTypes.object,
};

export default Note;
