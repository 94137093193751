import React, { useContext, useState } from 'react';
import { TextField, InputLabel, Grid, FormControl } from '@mui/material';

import DialogActionContainer from 'sharedComponents/DialogActions';
import { useFormik } from 'formik';
import { companySubMaterialSchema } from './schemas';
import { ModalContext } from 'sharedComponents/ModalContext';
import api, {
  CivilCompanySubMaterials,
  UpsertCompanySubMaterial,
} from 'services/api/autogenerated';
import useShowError from 'modules/errors';
import { usePermissionsContext } from 'services/permissions';

interface CompanySubMaterialFormProps {
  selected?: CivilCompanySubMaterials;
  onAfterSave?: () => any;
}

const CompanySubMaterialForm = ({ selected, onAfterSave }: CompanySubMaterialFormProps) => {
  const { handleModal } = useContext(ModalContext);
  const { user } = usePermissionsContext();
  const showError = useShowError();
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = async (values: UpsertCompanySubMaterial) => {
    setIsLoading(true);
    try {
      await api.civilCompanies.saveCompanySubMaterial(values);
      if (onAfterSave) onAfterSave();
      handleModal();
    } catch (err) {
      console.error('Failed to save sub-material', err);
      showError({ title: 'Failed to save sub-material' });
    } finally {
      setIsLoading(false);
    }
  };

  const formik = useFormik({
    validationSchema: companySubMaterialSchema,
    initialValues: selected
      ? selected
      : {
          name: '',
          description: '',
          company_id: user.companiesIds[0],
        },
    onSubmit: handleSubmit,
  });

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <FormControl fullWidth>
            <InputLabel htmlFor="name" shrink>
              Identifier
            </InputLabel>
            <TextField
              name="name"
              disabled={isLoading}
              value={formik.values.name}
              error={formik.touched.name && !!formik.errors.name}
              helperText={formik.touched.name && formik.errors.name}
              onChange={formik.handleChange}
              variant="outlined"
              autoFocus
            />
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <FormControl fullWidth>
            <InputLabel htmlFor="sku" shrink>
              Description
            </InputLabel>
            <TextField
              name="description"
              disabled={isLoading}
              value={formik.values.description}
              error={formik.touched.description && !!formik.errors.description}
              helperText={formik.touched.description && formik.errors.description}
              onChange={formik.handleChange}
              variant="outlined"
            />
          </FormControl>
        </Grid>
      </Grid>
      <DialogActionContainer
        isSaving={isLoading}
        saveHandler={formik.handleSubmit}
        editing={selected ? true : false}
      />
    </>
  );
};

export default CompanySubMaterialForm;
