import React from 'react';
import { DialogContent } from '@mui/material';
import ModalHeader from 'sharedComponents/DialogHeader';
import CompanySubMaterialForm from './CompanySubMaterialForm';
import { CivilCompanySubMaterials } from 'services/api/autogenerated';

interface ModalContainerProps {
  selected?: CivilCompanySubMaterials;
  onAfterSave?: () => any;
}

const ModalContainer = ({ selected, onAfterSave }: ModalContainerProps) => {
  return (
    <>
      <ModalHeader title={'Sub-Material'} />
      <DialogContent>
        <CompanySubMaterialForm selected={selected} onAfterSave={onAfterSave} />
      </DialogContent>
    </>
  );
};

export default ModalContainer;
