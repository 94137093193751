import React, { forwardRef } from 'react';
import { Helmet } from 'react-helmet';

const Page = forwardRef(
  (
    {
      children,
      title = '',
      ...rest
    }: { children: React.ReactNode; title?: string; [key: string]: any },
    ref?: React.LegacyRef<HTMLDivElement>
  ) => {
    return (
      <div ref={ref} {...rest}>
        <Helmet>
          <title>Livegistics - {title}</title>
        </Helmet>
        {children}
      </div>
    );
  }
);
Page.displayName = 'Page';

export default Page;
