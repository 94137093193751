import React from 'react';
import { SvgIcon, Typography, IconButton, CircularProgress, Box } from '@mui/material';
import { DataGrid, GridColDef, GridRowModel } from '@mui/x-data-grid';
import { ArrowDropDown, ArrowDropUp, EditOutlined, Visibility } from '@mui/icons-material';
import moment from 'moment';
import { IRequest } from 'lg-helpers/dist/shared/interfaces/IRequest';

interface RequestsTableProps {
  handleSelection: Function;
  requests: IRequest[];
  isLoading: boolean;
}

export const RequestsTable = ({ handleSelection, isLoading, requests }: RequestsTableProps) => {
  const handleClick = (data: GridRowModel) => {
    handleSelection(data);
  };

  const ticketColumns: GridColDef[] = [
    {
      field: 'requestedDate',
      headerName: 'Date Requested',
      valueFormatter: data => moment(data.api.getRow(data.id!)?.requestedDate).format('MM-DD-YYYY'),
      renderCell: data => <>{moment(data.row.requestedDate).format('MM-DD-YYYY')}</>,
      flex: 1,
    },
    {
      field: 'material',
      headerName: 'Material',
      flex: 1,
    },
    {
      field: 'transporter',
      headerName: 'Transporter',
      flex: 1,
    },
    {
      field: 'quantity',
      headerName: 'Quantity',
      flex: 1,
    },
    {
      field: 'unit',
      headerName: 'Unit',
      flex: 1,
    },
    {
      field: 'type',
      headerName: 'Type',
      flex: 1,
      renderCell: data => (
        <Typography variant="subtitle2" sx={{ textTransform: 'capitalize' }}>
          {data.row.type}
        </Typography>
      ),
    },
    {
      field: 'status',
      headerName: 'Status',
      flex: 1,
      renderCell: data => (
        <Typography variant="subtitle2" sx={{ textTransform: 'capitalize' }}>
          {data.row.status}
        </Typography>
      ),
    },
    {
      field: 'details',
      headerName: 'Details',
      disableExport: true,
      renderHeader: () => <></>,
      renderCell: data => {
        const editElement = (
          <>
            <IconButton onClick={() => handleClick(data.row)}>
              <SvgIcon>
                {data.row.status !== 'canceled' && data.row.status !== 'completed' ? (
                  <EditOutlined />
                ) : (
                  <Visibility />
                )}
              </SvgIcon>
            </IconButton>
          </>
        );

        return <>{editElement}</>;
      },
    },
  ];

  return (
    <>
      {isLoading ? (
        <CircularProgress color="inherit" size={100} />
      ) : (
        <>
          <DataGrid
            columns={ticketColumns}
            rows={requests}
            showColumnRightBorder={false}
            disableColumnMenu
            components={{
              ColumnSortedAscendingIcon: ArrowDropUp,
              ColumnSortedDescendingIcon: ArrowDropDown,
              ColumnResizeIcon: () => <div></div>,
              NoRowsOverlay: () => (
                <Box display="flex" flexGrow={1} alignItems="center" justifyContent="center">
                  <Typography>Please select a project to see its requests.</Typography>
                </Box>
              ),
            }}
          ></DataGrid>
        </>
      )}
    </>
  );
};

export default RequestsTable;
