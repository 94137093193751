import { Grid, TextField } from '@mui/material';
import React, { ChangeEvent } from 'react';

interface IEditExternalApiMappingsProperties {
  propertyMapping: any;
  readOnly: boolean;
  handleChangeInExternalApiMappings: (event: ChangeEvent) => void;
  changeObject: any;
}

export const EditExternalApiMappings = ({
  propertyMapping,
  readOnly,
  handleChangeInExternalApiMappings,
  changeObject,
}: IEditExternalApiMappingsProperties) => {
  return (
    <Grid item xs={12} key={propertyMapping.id}>
      <TextField
        disabled={readOnly}
        onChange={handleChangeInExternalApiMappings}
        fullWidth
        label={propertyMapping.name}
        name={propertyMapping.name}
        value={
          changeObject.externalApiMappings
            ? changeObject.externalApiMappings[propertyMapping.name] || ''
            : ''
        }
        variant="outlined"
      />
    </Grid>
  );
};
