import React from 'react';
import { DialogContent } from '@mui/material';
import ModalHeader from 'sharedComponents/DialogHeader';
import ManageLocations from './AddLocations';

interface ModalContainerProps {
  projectId: number;
}

const ModalContainer = ({ projectId }: ModalContainerProps) => {
  return (
    <>
      <ModalHeader title={'Manage Sites'} />
      <DialogContent>
        <ManageLocations projectId={projectId} />
      </DialogContent>
    </>
  );
};

export default ModalContainer;
