import React, { useState, useEffect, useCallback, useMemo } from 'react';
import 'sass/globals.scss';
import {
  Container,
  Grid,
  Box,
  Button,
  CircularProgress,
  Typography,
  TextField,
  Card,
  Autocomplete,
  FormControlLabel,
  Switch,
} from '@mui/material';
import InputAddressFull from 'sharedComponents/InputAddressFull';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import Page from 'sharedComponents/Page';
import { useParams, useNavigate } from 'react-router-dom';
import { hasErrorRequired, hasErrorValidEmail } from 'services/logic/formValidation';
import companiesTypes from 'services/constants/companiesTypes.json';
import CrudListIdName from 'sharedComponents/CrudListIdName';
import AdministratorPanel from './AdministratorPanel';
import useShowError from 'modules/errors';
import CompaniesApi from 'services/postgres/CompaniesApi';
import ErrorMessages from 'services/constants/errorMessages';
import { useSelector } from 'react-redux';
import { selectAuthUser } from 'modules/auth/storeSliceAuth';
import MinifiedProjectList from './MinifiedProjectList';
import InputMasker from 'sharedComponents/InputMasker';
import InputCustomTemplateBuilder from 'sharedComponents/InputCustomTemplateBuilder';
import api from 'services/api/autogenerated';
import { UploadCompanyLogo } from 'sharedComponents/UploadCompanyLogo';
import Command from 'services/firebase/Command';
import Executer from 'services/firebase/Executer';
import Query from 'services/firebase/Query';
import QueryBase from 'lg-helpers/dist/firestore/query/QueryBase';

const { isRequired, emailNotValid } = ErrorMessages;

const command = new Command();
const executer = new Executer();
const query = new Query();
const companiesApi = new CompaniesApi();

const hasTemplateWithEmptyName = (templates: any) => {
  const manifestTemplateWithEmptyName = (templates || []).find(({ name }: any) => !name);
  return !!manifestTemplateWithEmptyName;
};

// todo: there is a large amount of duplication within this code. consider reducing the duplication and consolidating into a single method that uses parameters
const CompanyView = () => {
  let { id } = useParams();
  const [isLoadingSaveDetails, setIsSavingSaveDetails] = useState(false);
  const showError = useShowError();
  const [wasSubmitted, setWasSubmitted] = useState(false);
  const [isLoadingData, setIsLoadingData] = useState(false);
  const [initialCompanyLogo, setInitialCompanyLogo] = useState('');
  const [templatesSuggestions, setTemplatesSuggestions] = useState<{
    [key: string]: any;
  }>({});
  const [company, setCompany] = useState({} as any);
  const [selectedBrokerLoadTemplate, setSelectedBrokerLoadTemplate] = useState(null as any);
  const navigate = useNavigate();
  const authUser = useSelector(selectAuthUser);
  const isSuperAdmin = useMemo(() => authUser?.type === 'Super Admin', [authUser]);
  const isCompanyUser = useMemo(
    () => authUser?.companiesIds?.includes(company?.id),
    [authUser, company?.id]
  );
  const isTransporterCompany = useMemo(
    () => (company?.types || []).includes('transporter'),
    [company]
  );
  const isScaleCompany = useMemo(() => (company?.types || []).includes('scale'), [company]);
  const isBrokerCompany = useMemo(() => (company?.types || []).includes('broker'), [company]);
  const isCivilCompany = useMemo(() => (company?.types || []).includes('civil'), [company]);

  const handleChange = useCallback(
    (event: any) => {
      const { name, value } = event.target;
      setCompany({
        ...company,
        [name]: value,
      });
      setWasSubmitted(false);
    },
    [company]
  );

  useEffect(() => {
    if (company.brokerLoadTemplates && company.brokerLoadTemplates.length === 1) {
      setSelectedBrokerLoadTemplate(company.brokerLoadTemplates[0]);
    }
  }, [company.brokerLoadTemplates, handleChange]);

  useEffect(() => {
    const load = async () => {
      try {
        const { data } = await api.template.getTemplateTypes();
        setTemplatesSuggestions(data);
      } catch (err) {
        showError({ title: 'Failed to load template properties' });
      }
    };
    load();
  }, [setTemplatesSuggestions, showError]);

  useEffect(() => {
    if (!id) {
      return;
    }
    const getCompany = async () => {
      try {
        setIsLoadingData(true);
        const companyRes = await companiesApi.getById(id || '');
        if (companyRes) {
          if (companyRes?.companyLogo) setInitialCompanyLogo(companyRes?.companyLogo);
          if (['scale', 'broker'].filter(e => (companyRes?.types || []).includes(e).length)) {
            // todo: understand why this was here
            // const { regions, manifestTemplates, receiptTemplates, brokerLoadTemplates } = companyRes;
            setCompany(companyRes);
          } else {
            setCompany({
              ...companyRes,
              manifestTemplates: companyRes.manifestTemplates || [],
              receiptTemplates: companyRes.receiptTemplates || [],
              brokerLoadTemplates: companyRes.brokerLoadTemplates || [],
              brokerTicketTemplates: companyRes.brokerTicketTemplates || [],
              ticketReportTemplate: companyRes.ticketReportTemplate || [],
            });
          }
        } else {
          showError({ title: "This company doesn't exist", duration: 10000 });
        }

        setIsLoadingData(false);
      } catch (e) {
        setIsLoadingData(false);
        console.error(e);
        showError({ title: 'An expected error occurred please try again' });
      }
    };
    getCompany();
  }, [id, showError]);

  const hasErrors = () => {
    if (company.regions && company.regions.length) {
      const emptyRegion = company.regions.find((region: any) => !region.name);
      if (emptyRegion) {
        return true;
      }
    }

    if (isScaleCompany) {
      if (hasErrorRequired(company.manifestTemplates)) {
        return true;
      }

      if (hasTemplateWithEmptyName(company.manifestTemplates)) {
        return true;
      }

      if (hasTemplateWithEmptyName(company.receiptTemplates)) {
        return true;
      }
    }

    return (
      hasErrorRequired(company.name) ||
      hasErrorRequired(company.types) ||
      hasErrorRequired(company.country) ||
      hasErrorRequired(company.address) ||
      hasErrorRequired(company.city) ||
      hasErrorRequired(company.state)
    );
  };

  const handleSaveDetails = async () => {
    setWasSubmitted(true);
    if (hasErrors()) {
      showError({ title: 'Validation errors' });
      return;
    }

    setIsSavingSaveDetails(true);

    // todo: need better way to "unjoin" company custom data
    const {
      manifestTemplates, // eslint-disable-line
      receiptTemplates, // eslint-disable-line
      brokerLoadTemplates, // eslint-disable-line
      brokerTicketTemplates, // eslint-disable-line
      country, // eslint-disable-line
      fileNameActionsInCronJob, // eslint-disable-line
      customCivilReportUrl, // eslint-disable-line
      externalApiPropertyNamesForMappingsInProjects, // eslint-disable-line
      externalApiPropertyNamesForMappingsInScales, // eslint-disable-line
      regions, // eslint-disable-line
      invitedByCompany, // eslint-disable-line
      companyLogo, // eslint-disable-line
      ticketReportTemplate, // eslint-disable-line
      vulcanApiKey, // eslint-disable-line
      connexClientId, // eslint-disable-line
      connexApiKey, // eslint-disable-line
      connexTimeInterval, // eslint-disable-line
      herculesApiKey, // eslint-disable-line
      herculesTimeInterval, // eslint-disable-line
      supplierApiMethod, // eslint-disable-line
      allowMultipleManifests, // eslint-disable-line
      truckingTicketExportFTPConfig, // eslint-disable-line
      truckingTicketExportRemapConfig, // eslint-disable-line
      hideProfileQrCode, // eslint-disable-line
      nextAutoTicketNumber, // eslint-disable-line
      requireCivilTicketShippingImages, // eslint-disable-line
      requireCivilTicketQty, // eslint-disable-line
      ...companyRest
    } = company;

    const companyToSave = {
      companyData: companyRest,
      customDataArray: [],
    } as any;

    companyToSave.companyData.types && companyToSave.companyData.types.sort();

    if (companyLogo && (isCompanyUser || isSuperAdmin)) {
      if (companyLogo !== initialCompanyLogo) {
        const allProjects = await executer.getMultipleDocuments(
          query.projects.getProjectsActiveForCompany(company.id)
        );
        for (let project in allProjects || []) {
          if (
            allProjects[project]?.scaleCompany?.id &&
            allProjects[project]?.scaleCompany?.id === company.id
          ) {
            const { scaleCompany } = allProjects[project];
            await command.base.set(QueryBase.PROJECTS_COLLECTION(), allProjects[project]?.id, {
              ...allProjects[project],
              scaleCompany: { ...scaleCompany, companyLogo },
            });
          }
        }
      }
      companyToSave.customDataArray.push({
        nameCompaniesCustomData: 'companyLogo',
        valueString: companyLogo,
      });
    }

    if (isCivilCompany && isSuperAdmin) {
      if (customCivilReportUrl) {
        companyToSave.customDataArray.push({
          nameCompaniesCustomData: 'customCivilReportUrl',
          valueString: customCivilReportUrl,
        });
      }
    }

    if (isScaleCompany) {
      if (fileNameActionsInCronJob) {
        companyToSave.customDataArray.push({
          nameCompaniesCustomData: 'fileNameActionsInCronJob',
          valueString: fileNameActionsInCronJob,
        });
      }

      if (regions) {
        companyToSave.customDataArray.push({
          nameCompaniesCustomData: 'regions',
          valueArrayJsons: regions,
        });
      }

      if (externalApiPropertyNamesForMappingsInProjects) {
        companyToSave.customDataArray.push({
          nameCompaniesCustomData: 'externalApiPropertyNamesForMappingsInProjects',
          valueArrayJsons: externalApiPropertyNamesForMappingsInProjects,
        });
      }

      if (externalApiPropertyNamesForMappingsInScales) {
        companyToSave.customDataArray.push({
          nameCompaniesCustomData: 'externalApiPropertyNamesForMappingsInScales',
          valueArrayJsons: externalApiPropertyNamesForMappingsInScales,
        });
      }

      if (manifestTemplates) {
        companyToSave.customDataArray.push({
          nameCompaniesCustomData: 'manifestTemplates',
          valueArrayJsons: manifestTemplates.map(
            // eslint-disable-next-line
            ({ propertiesDisplayOptionsInitial, base64, isAlreadyDecorated, ...rest }: any) => ({
              ...rest,
            })
          ),
        });
      }

      if (receiptTemplates) {
        companyToSave.customDataArray.push({
          nameCompaniesCustomData: 'receiptTemplates',
          valueArrayJsons: receiptTemplates.map(
            // eslint-disable-next-line
            ({ propertiesDisplayOptionsInitial, base64, isAlreadyDecorated, ...rest }: any) => ({
              ...rest,
            })
          ),
        });
      }
    }

    if (brokerLoadTemplates) {
      companyToSave.customDataArray.push({
        nameCompaniesCustomData: 'brokerLoadTemplates',
        valueArrayJsons: brokerLoadTemplates.map(
          // eslint-disable-next-line
          ({ propertiesDisplayOptionsInitial, base64, isAlreadyDecorated, ...rest }: any) => ({
            ...rest,
          })
        ),
      });
    }

    if (brokerTicketTemplates) {
      companyToSave.customDataArray.push({
        nameCompaniesCustomData: 'brokerTicketTemplates',
        valueArrayJsons: brokerTicketTemplates.map(
          ({ propertiesDisplayOptionsInitial, base64, isAlreadyDecorated, ...rest }: any) => ({ // eslint-disable-line
            ...rest,
          })
        ),
      });
    }

    if (ticketReportTemplate) {
      companyToSave.customDataArray.push({
        nameCompaniesCustomData: 'ticketReportTemplate',
        valueArrayJsons: ticketReportTemplate.map(
          ({ propertiesDisplayOptionsInitial, base64, isAlreadyDecorated, ...rest }: any) => ({ // eslint-disable-line
            ...rest,
          })
        ),
      });
    }

    if (isSuperAdmin) {
      companyToSave.customDataArray.push({
        nameCompaniesCustomData: 'allowMultipleManifests',
        valueInteger: String(allowMultipleManifests) === 'true' ? 1 : 0,
        valueArrayJsons: null,
        valueString: null,
      });
    }

    try {
      if (!id) {
        const { id: insertedCompanyId } = await companiesApi.insertOrUpdate(companyToSave);
        navigate(`/super-admin/company/${insertedCompanyId}`);
      } else {
        await companiesApi.insertOrUpdate(companyToSave);
      }
    } catch (error) {
      console.error(error);
      showError({ title: 'Save failed' });
    }

    setIsSavingSaveDetails(false);
  };

  const onExternalApiPropertyNamesForMappingsInProjectsUpdate = (list: any) => {
    handleChange({
      target: {
        name: 'externalApiPropertyNamesForMappingsInProjects',
        value: list,
      },
    });
  };

  const onExternalApiPropertyNamesForMappingsInScalesUpdate = (list: any) => {
    handleChange({
      target: {
        name: 'externalApiPropertyNamesForMappingsInScales',
        value: list,
      },
    });
  };

  return (
    <Page>
      <Card>
        <Container className="min-padding-and-height">
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Box display="flex" justifyContent="space-between" alignItems="center">
                <Button onClick={() => navigate('/super-admin/companies')} variant="outlined">
                  <NavigateBeforeIcon /> Back
                </Button>

                <Button
                  color="primary"
                  variant="contained"
                  onClick={handleSaveDetails}
                  disabled={isLoadingSaveDetails}
                >
                  Save
                  {isLoadingSaveDetails && (
                    <Box marginLeft={2}>
                      <CircularProgress color="inherit" />
                    </Box>
                  )}
                </Button>
              </Box>
            </Grid>

            {isLoadingData ? (
              <Grid item xs={12}>
                <Box display="flex" justifyContent="center" alignItems="center">
                  <CircularProgress color="inherit" />
                </Box>
              </Grid>
            ) : (
              <>
                {company.id && (
                  <>
                    <>
                      <Grid item xs={12}>
                        <AdministratorPanel
                          company={company}
                          isBrokerCompany={isBrokerCompany}
                          selectedBrokerLoadTemplate={selectedBrokerLoadTemplate}
                        />
                      </Grid>
                    </>
                    <Grid item sm={6} xs={12}>
                      <TextField
                        fullWidth
                        label="Company Id"
                        disabled
                        value={company.id || ''}
                        variant="outlined"
                      />
                    </Grid>
                  </>
                )}
                <Grid item sm={6} xs={12}>
                  <TextField
                    onChange={handleChange}
                    required
                    fullWidth
                    label="Name"
                    name="name"
                    value={company.name || ''}
                    variant="outlined"
                    error={hasErrorRequired(company.name, wasSubmitted)}
                    helperText={hasErrorRequired(company.name, wasSubmitted) ? isRequired : ''}
                  />
                </Grid>
                <Grid item sm={6} xs={12}>
                  <Autocomplete
                    multiple
                    options={companiesTypes}
                    value={company.types || []}
                    getOptionLabel={option => option}
                    onChange={(event, value) =>
                      handleChange({
                        target: {
                          name: 'types',
                          value,
                        },
                      })
                    }
                    renderInput={params => (
                      <TextField
                        {...params}
                        variant="outlined"
                        required
                        label="Types"
                        placeholder="Select company types"
                        error={hasErrorRequired(company.types, wasSubmitted)}
                        helperText={hasErrorRequired(company.types, wasSubmitted) ? isRequired : ''}
                      />
                    )}
                  />
                </Grid>
                <Grid item sm={6} xs={12}>
                  <TextField
                    onChange={handleChange}
                    fullWidth
                    label="Email"
                    name="email"
                    value={company.email || ''}
                    variant="outlined"
                    error={hasErrorValidEmail(company.email, wasSubmitted)}
                    helperText={
                      hasErrorValidEmail(company.email, wasSubmitted) ? emailNotValid : ''
                    }
                  />
                </Grid>
                <Grid item sm={6} xs={12}>
                  <InputMasker
                    fullWidth
                    name="phone"
                    variant="outlined"
                    mask="+###########"
                    label="Phone Number"
                    val={company.phone || ''}
                    onChange={value =>
                      setCompany({
                        ...company,
                        phone: value,
                      })
                    }
                  />
                </Grid>
                <InputAddressFull
                  locationData={company}
                  handleChangeCallback={handleChange}
                  wasSubmitted={wasSubmitted}
                  returnAllStringValues
                />
                <Grid item xs={12}>
                  <Grid container spacing={0}>
                    <Grid item sm={6} xs={12}>
                      <UploadCompanyLogo
                        onUpdate={handleChange}
                        companyId={company.id}
                        companyLogo={company?.companyLogo}
                      />
                    </Grid>
                    {isSuperAdmin && (
                      <Grid item sm={6} xs={12}>
                        <FormControlLabel
                          control={
                            <Switch
                              checked={!!company.allowMultipleManifests}
                              onChange={event =>
                                handleChange({
                                  target: {
                                    name: 'allowMultipleManifests',
                                    value: event.target.checked,
                                  },
                                })
                              }
                              name="allowMultipleManifests"
                              color="primary"
                            />
                          }
                          label="Allow Multiple Manifests"
                        />
                      </Grid>
                    )}
                  </Grid>
                </Grid>
                {isCivilCompany && (
                  <Grid item xs={12}>
                    <TextField
                      onChange={handleChange}
                      fullWidth
                      label="Custom Civil Report URL"
                      name="customCivilReportUrl"
                      value={company?.customCivilReportUrl || ''}
                      variant="outlined"
                    />
                  </Grid>
                )}
                {isScaleCompany && (
                  <>
                    <Grid item xs={12}>
                      <CrudListIdName
                        title="Regions"
                        list={company.regions || []}
                        onListUpdate={(list: any) => {
                          handleChange({
                            target: {
                              name: 'regions',
                              value: list,
                            },
                          });
                        }}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        required
                        fullWidth
                        variant="outlined"
                        onChange={handleChange}
                        label="File name with actions in cron job"
                        name="fileNameActionsInCronJob"
                        style={{ marginBottom: 1 }}
                        value={company.fileNameActionsInCronJob || ''}
                        error={hasErrorRequired(company.fileNameActionsInCronJob, wasSubmitted)}
                        helperText={
                          hasErrorRequired(company.fileNameActionsInCronJob, wasSubmitted)
                            ? isRequired
                            : ''
                        }
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <CrudListIdName
                        title="External API property names for mappings in projects"
                        list={company.externalApiPropertyNamesForMappingsInProjects || []}
                        onListUpdate={onExternalApiPropertyNamesForMappingsInProjectsUpdate}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <CrudListIdName
                        title="External API property names for mappings in scales"
                        list={company.externalApiPropertyNamesForMappingsInScales || []}
                        onListUpdate={onExternalApiPropertyNamesForMappingsInScalesUpdate}
                      />
                    </Grid>
                    <InputCustomTemplateBuilder
                      title="Manifest templates"
                      handleChange={handleChange}
                      company={company}
                      propNameInCompany="manifestTemplates"
                      disableSelect={
                        !company.manifestTemplates || !company.manifestTemplates.length
                      }
                      inputLabel="Manifest template"
                      inputPlaceholder="Select manifest template"
                      templateSuggestions={templatesSuggestions['manifestTemplateContextFields']}
                    />
                    <InputCustomTemplateBuilder
                      title="Receipt templates"
                      handleChange={handleChange}
                      company={company}
                      propNameInCompany="receiptTemplates"
                      disableSelect={!company.receiptTemplates || !company.receiptTemplates.length}
                      inputLabel="Receipt template"
                      inputPlaceholder="Select receipt template"
                      templateSuggestions={templatesSuggestions['manifestTemplateContextFields']}
                    />
                  </>
                )}
                {(isTransporterCompany || isScaleCompany || isCivilCompany) && (
                  <InputCustomTemplateBuilder
                    title="Ticket templates (ticketReportTemplate)"
                    handleChange={handleChange}
                    company={company}
                    propNameInCompany="ticketReportTemplate"
                    disableSelect={
                      !company.ticketReportTemplate || !company.ticketReportTemplate.length
                    }
                    inputLabel="Ticket template (ticketReportTemplate)"
                    inputPlaceholder="Select Ticket template (ticketReportTemplate)"
                  />
                )}
                {(isBrokerCompany || isCivilCompany) && (
                  <InputCustomTemplateBuilder
                    title="Broker load templates"
                    handleChange={handleChange}
                    company={company}
                    propNameInCompany="brokerLoadTemplates"
                    disableSelect={
                      !company.brokerLoadTemplates || !company.brokerLoadTemplates.length
                    }
                    inputLabel="Broker load template"
                    inputPlaceholder="Select load template"
                  />
                )}
                {isBrokerCompany && (
                  <InputCustomTemplateBuilder
                    title="Broker ticket templates"
                    handleChange={handleChange}
                    company={company}
                    propNameInCompany="brokerTicketTemplates"
                    disableSelect={
                      !company.brokerTicketTemplates || !company.brokerTicketTemplates.length
                    }
                    inputLabel="Broker ticket template"
                    inputPlaceholder="Select ticket template"
                  />
                )}

                <Grid item xs={12}>
                  <Typography variant="h5">Projects</Typography>
                  <MinifiedProjectList companyId={company.id} />
                </Grid>
              </>
            )}
          </Grid>
        </Container>
      </Card>
    </Page>
  );
};

export default CompanyView;
