import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { thunkDispatch } from 'store/store';
import { getProjectMaterialsByProjectId } from 'modules/civilAndTrucking/trucking/features/ProjectMaterials/redux/actions';
import { getTruckTypesByCompanyId } from 'modules/civilAndTrucking/trucking/features/TruckTypes/redux/actions';
import { getCompanyMaterialsByCompanyId } from 'modules/civilAndTrucking/trucking/features/CompanyMaterials/redux/actions';
import { getProjectByProjectId } from 'modules/civilAndTrucking/trucking/features/Projects/redux/actions';
import { Project } from './Project';
import { selectProject } from '../../redux/projectsStoreSlice';
import { selectProjectMaterials } from '../../../ProjectMaterials/redux/projectMaterialsStoreSlice';
import { LoadingOverlay as Loading } from 'sharedComponents/LoadingOverlay';
import { ProjectMaterialsTable } from './components/ProjectMaterialsTable';

export const ProjectView = () => {
  const { projectId } = useParams();
  const [selectedContractorId, setSelectedContractorId] = useState('');
  const { project, projectStatus } = useSelector(selectProject);
  const { projectMaterials, projectMaterialsStatus } = useSelector(selectProjectMaterials);

  useEffect(() => {
    if (!projectId) return;
    thunkDispatch(getProjectByProjectId(Number(projectId)));
    thunkDispatch(getProjectMaterialsByProjectId(Number(projectId)));
  }, [projectId]);

  useEffect(() => {
    thunkDispatch(getTruckTypesByCompanyId());
    thunkDispatch(getCompanyMaterialsByCompanyId());
  }, []);

  if (projectStatus === 'pending' || projectMaterialsStatus === 'pending') {
    return <Loading />;
  }

  return (
    <>
      <Project
        existingProject={projectId ? project : undefined}
        setSelectedContractorId={setSelectedContractorId}
      />
      {projectId && (
        <ProjectMaterialsTable
          existingProjectMaterials={projectMaterials}
          selectedContractorId={selectedContractorId || project?.contractorCompanyIdTruckingProject}
          projectId={Number(projectId)}
        />
      )}
    </>
  );
};
