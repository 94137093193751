import React, { useState } from 'react';
import { Button, Grid, TextField } from '@mui/material';
import { Link } from 'react-router-dom';
import LivDropdownMenu from 'sharedComponents/LivDropdownMenu';
import Query from 'services/firebase/Query';
import Executer from 'services/firebase/Executer';
import { DataGrid } from '@mui/x-data-grid';

const query = new Query();
const executer = new Executer();

const searchMethods = [
  {
    key: 'First Name',
    val: 'firstName',
  },
  {
    key: 'Last Name',
    val: 'lastName',
  },
  {
    key: 'Email',
    val: 'email',
  },
  {
    key: 'Phone',
    val: 'phone',
  },
];

const phone10DigitFormatRules = [
  '($1)$2-$3',
  '($1) $2-$3',
  '$1$2$3',
  '$1-$2-$3',
  '$1.$2.$3',
  '$1 $2 $3',
];

const SuperAdminUserVerificationSearch = () => {
  const [searchValue, setSearchValue] = useState('');
  const [searchMethod, setSearchMethod] = useState('firstName');
  const [foundUsers, setFoundUsers] = useState<any[]>([]);

  const runSearch = async () => {
    const users: any = await executer.getMultipleDocuments(
      query.users.getUserForVerification(searchMethod, searchValue)
    );
    if (searchMethod === 'phone' && searchValue.replace(/\D/g, '').length === 10) {
      users.push(
        ...(
          await Promise.all(
            phone10DigitFormatRules.map(
              async e =>
                await executer.getMultipleDocuments(
                  query.users.getUserForVerification(
                    searchMethod,
                    searchValue.replace(/\D/g, '').replace(/^(\d{3})(\d{3})(\d+)$/, e)
                  )
                )
            )
          )
        ).flat()
      );
    }
    setFoundUsers([...new Map(users.map((e: any) => [e.id, e])).values()]);
  };

  return (
    <>
      <Grid container>
        <Grid item xs={12} marginBottom={1}>
          <h3>User Verification</h3>
        </Grid>
        <Grid item xs={12} display="flex">
          <Button
            color="inherit"
            component={Link}
            variant="outlined"
            to="/super-admin/administration"
          >
            Back
          </Button>
          <LivDropdownMenu
            variant="outlined"
            val={searchMethod}
            helpText="Search by"
            listItems={searchMethods}
            onSelection={(item: any) => {
              setSearchMethod(item.val);
            }}
          />

          <TextField
            fullWidth
            variant="outlined"
            placeholder="Search"
            defaultValue={searchValue}
            onChange={e => setSearchValue(e.target.value)}
            style={{
              fontSize: '12px',
              lineHeight: '10px',
              letterSpacing: '-0.05px',
            }}
          />

          <Button variant="outlined" color="inherit" onClick={runSearch}>
            Search
          </Button>
        </Grid>

        {foundUsers.length > 0 && (
          <div style={{ height: 400, width: '100%' }}>
            <DataGrid
              rows={foundUsers}
              columns={[
                {
                  field: 'name',
                  headerName: 'Name',
                  width: 130,
                  valueGetter: params => `${params.row.firstName} ${params.row.lastName}`,
                },
                { field: 'email', headerName: 'Email', width: 130 },
                { field: 'phone', headerName: 'Phone', width: 130 },
                {
                  field: 'companies',
                  headerName: 'Companies',
                  width: 200,
                  valueGetter: params =>
                    params.row.companies && params.row.companies.length
                      ? params.row.companies.map((company: any) => company.name).join(', ')
                      : '',
                },
                {
                  field: 'actions',
                  headerName: 'Actions',
                  renderCell: params => {
                    return (
                      <Link
                        to={`/super-admin/administration/verify-user/${params.row.id}`}
                        color="inherit"
                      >
                        View
                      </Link>
                    );
                  },
                },
              ]}
            />
          </div>
        )}
      </Grid>
    </>
  );
};

export default SuperAdminUserVerificationSearch;
