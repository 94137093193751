import React, { useContext, useMemo } from 'react';
import {
  Box,
  Grid,
  IconButton,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  SvgIcon,
} from '@mui/material';
import { EditIcon } from 'modules/civilAndTrucking/shared/CustomIcons/Edit';
import { Delete } from 'modules/civilAndTrucking/shared/CustomIcons/Delete';
import { thunkDispatch } from 'store/store';
import { deleteProjectTask, IJoinProjectTask } from './redux/actions';
import { ModalContext } from 'sharedComponents/ModalContext';
import AddProjectTask from 'modules/civilAndTrucking/civil/ProjectTask';
import { useHasAccess } from 'services/permissions';
import { ExpandMore, Check } from '@mui/icons-material';

interface IProjectTaskAccordionProps {
  category: { name: string; tasks: IJoinProjectTask[] };
}

const ProjectTaskAccordion = ({ category }: IProjectTaskAccordionProps) => {
  const { handleModal } = useContext(ModalContext);
  const auth = useHasAccess(['projects:create', 'projects:update']);
  const { innerWidth } = window;

  const handleClick = (data: IJoinProjectTask) => {
    handleModal(<AddProjectTask selected={data} />);
  };

  const handleArchive = async (data: IJoinProjectTask) => {
    await thunkDispatch(deleteProjectTask(data as IJoinProjectTask));
  };

  return (
    <Accordion sx={{ width: innerWidth * 0.8 }}>
      <AccordionSummary
        expandIcon={<ExpandMore />}
        aria-controls={`${category.name}-panel-header`}
        id="panel1a-header"
        sx={{
          display: 'flex',
          alignItems: 'center',
          backgroundColor: '#FFF',
          height: 50,
        }}
      >
        <Typography variant="h6" color="neutral">
          {category.name}
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Box width="100%" sx={{ overflowX: 'scroll' }}>
          <Grid container sx={{ backgroundColor: '#FFF' }} minWidth={700}>
            <Box
              display="flex"
              width="90%"
              justifyContent="space-between"
              alignItems="center"
              margin="auto"
              borderBottom="1px solid black"
              minWidth={700}
            >
              <Grid item xs={2}>
                Activity
              </Grid>
              <Grid item xs={2}>
                Material
              </Grid>
              <Grid item xs={3}>
                Source Supplier
              </Grid>
              <Grid item xs={1} sx={{ textAlign: 'center' }}>
                Daily
                <br />
                Target
              </Grid>
              <Grid item xs={1} sx={{ textAlign: 'center' }}>
                Total
                <br />
                Amount
              </Grid>
              <Grid item xs={1} sx={{ textAlign: 'center' }}>
                Auto
                <br />
                Ticket #
              </Grid>
              <Grid item xs={1}>
                <Box flexGrow={1} />
              </Grid>
            </Box>
            {category.tasks.map(task => (
              <Box
                display="flex"
                width="90%"
                justifyContent="space-between"
                alignItems="center"
                margin="auto"
                minWidth={700}
                key={task.id}
              >
                <Grid item xs={2}>
                  {task.activity}
                </Grid>
                <Grid item xs={2}>
                  {task.material}
                </Grid>
                <Grid item xs={3}>
                  {task.sourceSupplier}
                </Grid>
                <Grid item xs={1} sx={{ textAlign: 'center' }}>
                  {task.dailyTarget}
                </Grid>
                <Grid item xs={1} sx={{ textAlign: 'center' }}>
                  {task.totalAmount}
                </Grid>
                <Grid item xs={1} sx={{ textAlign: 'center' }}>
                  {task.autoTicketNumber ? (
                    <SvgIcon>
                      <Check />
                    </SvgIcon>
                  ) : (
                    <Box flexGrow={1} />
                  )}
                </Grid>
                {auth.hasAccess && (
                  <Grid item xs={1} display="flex" justifyContent="flex-end">
                    <IconButton onClick={() => handleClick(task)}>
                      <SvgIcon sx={{ fontSize: 24 }}>
                        <EditIcon />
                      </SvgIcon>
                    </IconButton>
                    <IconButton onClick={() => handleArchive(task)}>
                      <SvgIcon sx={{ fontSize: 24 }}>
                        <Delete />
                      </SvgIcon>
                    </IconButton>
                  </Grid>
                )}
              </Box>
            ))}
          </Grid>
        </Box>
      </AccordionDetails>
    </Accordion>
  );
};

interface IProjectTaskListProps {
  tasks: IJoinProjectTask[];
}

export const ProjectTaskList = ({ tasks }: IProjectTaskListProps) => {
  const projectTasksFull = useMemo(() => {
    const projectTasks: { name: string; tasks: IJoinProjectTask[] }[] = [];
    (tasks || []).forEach(task =>
      projectTasks.some(a => a.name === task.workCategory)
        ? projectTasks.find(a => a.name === task.workCategory)?.tasks.push(task)
        : projectTasks.push({ name: task.workCategory!, tasks: [task] })
    );
    return projectTasks as { name: string; tasks: IJoinProjectTask[] }[];
  }, [tasks]);

  return (
    <>
      {(projectTasksFull || []).map(
        (category, i) =>
          category?.name && (
            <ProjectTaskAccordion category={category} key={`${category.name}+${i}`} />
          )
      )}
    </>
  );
};

export default ProjectTaskList;
