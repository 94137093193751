import React, { useEffect } from 'react';
import useGetDispatches, { BrokerDispatch } from 'services/hooks/useGetDispatches';
import { Typography, Grid, Box, CircularProgress } from '@mui/material';
import useGetTruckNumberForUser from 'services/hooks/useGetTruckNumberForUser';
import Dispatch from './Dispatch';
import { useDispatch, useSelector } from 'react-redux';
import { setSelectedDispatch, selectSelectedDispatch } from 'modules/brokers/storeSliceBrokers';
import { useNavigate } from 'react-router-dom';

const DispatchesTable = ({ dateRangeFilter }: { dateRangeFilter: [Date, Date] }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const selectedDispatch = useSelector(selectSelectedDispatch);

  const { dispatches, isLoading: isLoadingDispatches } = useGetDispatches(dateRangeFilter, true);

  const truckNumber = useGetTruckNumberForUser();

  useEffect(() => {
    dispatch(setSelectedDispatch(null));
  }, [dispatch]);

  const onDispatchClickCallback = (dispatchSelected: BrokerDispatch) => {
    dispatch(setSelectedDispatch(dispatchSelected));
    navigate('/brokers/load');
  };

  return (
    <Grid container spacing={1}>
      {isLoadingDispatches ? (
        <Grid item xs={12}>
          <Box display="flex" justifyContent="center" alignItems="center">
            <CircularProgress color="inherit" />
          </Box>
        </Grid>
      ) : (
        <>
          {dispatches.length ? (
            <>
              {dispatches.map(dispatchObj => (
                <Grid key={dispatchObj.dispatchID} item md={6} xs={12}>
                  <Dispatch
                    dispatch={dispatchObj}
                    onClickCallback={onDispatchClickCallback}
                    isSelected={selectedDispatch && selectedDispatch.id === dispatchObj.id}
                    truckNumber={truckNumber}
                  />
                </Grid>
              ))}
            </>
          ) : (
            <Grid item xs={12}>
              <Box display="flex" justifyContent="center" alignItems="center">
                <Typography variant="h4" color="error">
                  No assigned dispatches!
                </Typography>
              </Box>
            </Grid>
          )}
        </>
      )}
    </Grid>
  );
};

export default DispatchesTable;
