import React from 'react';
import { createSvgIcon } from '@mui/material/utils';

export const EditIcon = createSvgIcon(
  <path
    fillRule="evenodd"
    clipRule="evenodd"
    d="M0 2a2 2 0 0 1 2-2h7a1 1 0 1 1 0 2H2v16h16v-7a1 1 0 1 1 2 0v7a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V2Zm15.347-2a1 1 0 0 1 .708.293l3.652 3.654a1 1 0 0 1 0 1.414L9.366 15.707a1 1 0 0 1-.707.293H5a1 1 0 0 1-1-1v-3.64a1 1 0 0 1 .292-.707L14.64.293A1 1 0 0 1 15.347 0Zm0 2.415L6 11.774V14h2.244l9.342-9.346-2.238-2.24Z"
  />,
  'Edit'
);
