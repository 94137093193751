import { createSlice } from '@reduxjs/toolkit';

export const storeSlice = createSlice({
  name: 'brokers',
  initialState: {
    selectedDispatch: null,
    dateRangeFilter: null,
  },
  reducers: {
    setSelectedDispatch: (state, action) => {
      state.selectedDispatch = action.payload;
    },
    setDateRangeFilter: (state, action) => {
      state.dateRangeFilter = action.payload;
    },
  },
});

export const { setSelectedDispatch, setDateRangeFilter } = storeSlice.actions;

export const selectSelectedDispatch = state => state.brokers.selectedDispatch;
export const selectDateRangeFilter = state => state.brokers.dateRangeFilter;

export default storeSlice.reducer;
