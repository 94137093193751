import React from 'react';
import { GridColDef } from '@mui/x-data-grid';
import { Button } from '@mui/material';
import { EditIcon } from 'modules/civilAndTrucking/shared/CustomIcons';
import { ITruckFlattenedCapacities } from '../Trucks';
import { unitsOfMeasure } from 'lg-helpers/dist/constants/global-constants';

export const generateTrucksColumns = (showModal: (truck: ITruckFlattenedCapacities) => void) => {
  return [
    {
      field: 'nameTruckingTruckDivision',
      headerName: 'Division',
      width: 150,
    },
    {
      field: 'truckNumberTruckingTruck',
      headerName: 'Truck #',
      width: 150,
    },
    {
      field: 'nameTruckingTruckType',
      headerName: 'Type',
      width: 150,
    },
    ...unitsOfMeasure.map(e => ({
      field: e,
      sortable: false,
    })),
    {
      field: 'edit',
      headerName: ' ',
      flex: 1,
      align: 'right',
      sortable: false,
      renderCell: ({ row }) => (
        <Button color="success" onClick={() => showModal(row)}>
          <EditIcon />
        </Button>
      ),
    },
  ] as GridColDef[];
};
