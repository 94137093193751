import React, { useState, useEffect } from 'react';
import 'sass/globals.scss';
import { useNavigate } from 'react-router-dom';
import { Box, Grid, Button, Container, CircularProgress } from '@mui/material';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import { useSelector } from 'react-redux';
import { selectAuthUser } from 'modules/auth/storeSliceAuth';
import useShowError from 'modules/errors';
import { IUser } from 'lg-helpers/dist/shared/interfaces/IUser';

const getReportParams = (authUser: IUser) => {
  switch (authUser?.actingAsType) {
    case 'Super Admin':
      return { ds28superAdmin: 'true' };
    case 'Customer Admin':
      return { ds28superAdmin: 'true' };
    case 'Viewer User':
      return { ds28viewerCompanyId: authUser?.companiesIds[0] };
    case 'Transporter Admin':
      return { ds28transporterCompanyId: authUser?.companiesIds[0] };
    case 'Contractor Admin':
      return { ds28contractorCompanyId: authUser?.companiesIds[0] };
    case 'Contractor User':
      return { ds28contractorCompanyId: authUser?.companiesIds[0] };
    case 'Generator Admin':
      return { ds28generatorCompanyId: authUser?.companiesIds[0] };
    case 'Generator User':
      return { ds28generatorCompanyId: authUser?.companiesIds[0] };
    case 'Scale Admin':
      return { ds28scaleId: authUser?.selectedScaleId };
    default:
      return false;
  }
};

const CompanyProjectsReportView = () => {
  const [companyReportUrl, setCompanyReportUrl] = useState('');
  const showError = useShowError();
  const navigate = useNavigate();
  const authUser = useSelector(selectAuthUser);

  useEffect(() => {
    if (!authUser) {
      return;
    }

    let baseUrl =
      process.env.REACT_APP_BUILD_ENV === 'development'
        ? 'https://datastudio.google.com/embed/u/0/reporting/f30489aa-d161-4564-ae77-99e394560462/page/xuZJC/?params='
        : 'https://datastudio.google.com/embed/reporting/91475da1-b80a-4f82-aab0-be8f1c8953d3/page/xuZJC?params=';
    const processReport = () => {
      const reportParams = getReportParams(authUser);
      if (reportParams) {
        const encodedParams = encodeURIComponent(JSON.stringify(reportParams));
        setCompanyReportUrl(baseUrl + encodedParams);
      } else {
        showError({
          title: 'User type is not authorized to view reports',
          duration: 10000,
        });
      }
    };

    processReport();
    // eslint-disable-next-line
  }, [authUser]);

  return (
    <Container className="min-padding-and-height">
      <Grid item sm={12} xs={12}>
        <Button color="inherit" variant="outlined" onClick={() => navigate(`/`)}>
          <NavigateBeforeIcon fontSize="small" /> Back
        </Button>
      </Grid>
      {companyReportUrl ? (
        <Grid item style={{ width: '100%', height: '100%' }}>
          <iframe className="lg-iframe" title="Company Report" src={companyReportUrl} />
        </Grid>
      ) : (
        <Grid item xs={12}>
          <Box display="flex" justifyContent="center" alignItems="center">
            <CircularProgress color="inherit" />
          </Box>
        </Grid>
      )}
    </Container>
  );
};

export default CompanyProjectsReportView;
