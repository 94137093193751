import React from 'react';
import { Typography, Box, Grid, Avatar, Chip } from '@mui/material';
import { IUser } from 'lg-helpers/dist/shared/interfaces/IUser';
import { ButtonOptionsMenu } from 'sharedComponents/ButtonOptionsMenu';

interface MemberProps {
  user: IUser;
  showEditUserModal: (user: IUser) => void;
  handleDelete: (user: IUser) => void;
}

export const MemberItem = ({ user, showEditUserModal, handleDelete }: MemberProps) => {
  const menuOptions = [
    {
      title: 'Edit',
      description: 'Edit the user',
      onClick: () => showEditUserModal(user),
    },
    {
      title: 'Delete',
      description: 'Delete the user',
      onClick: () => handleDelete(user),
    },
  ];

  return (
    <Grid
      container
      columnSpacing={1}
      alignItems="center"
      justifyContent="space-between"
      overflow="auto"
      sx={{
        p: 2,
        '&:nth-of-type(even)': {
          backgroundColor: '#eee',
        },
      }}
    >
      <Grid item md={2} sm={4}>
        <Avatar alt="default">
          {(user.firstName || '')[0]} {(user.lastName || '')[0]}
        </Avatar>
      </Grid>
      <Grid item md={6} sm={8}>
        <Box display="flex" alignItems="flex-end">
          <Typography variant="subtitle2">{`${user?.firstName} ${user.lastName}`}</Typography>
          {user?.generator && <Chip size="small" label="Generator" sx={{ ml: 1, color: '#fff' }} />}
        </Box>
        <Typography variant="body2" sx={{ color: 'neutral.main' }}>
          {user?.email}
        </Typography>
      </Grid>
      <Grid item md={3} sm={8}>
        <Typography variant="body2" sx={{ color: 'neutral.main' }}>
          {user?.type}
        </Typography>
      </Grid>
      <Grid item md={1} sm={4}>
        <ButtonOptionsMenu options={menuOptions} />
      </Grid>
    </Grid>
  );
};
