import React, { useCallback } from 'react';
import { Grid, Typography, Button } from '@mui/material';
import { Notification } from './NotificationItem';
import useShowError from 'modules/errors';
import { usePermissionsContext } from 'services/permissions';
import { IUser } from 'lg-helpers/dist/shared/interfaces/IUser';
import Query from 'services/firebase/Query';
import QueryBase from 'lg-helpers/dist/firestore/query/QueryBase';

const query = new Query();

function UserNotificationRequestPrompt({
  notification,
  handleHide,
}: {
  notification: Notification;
  handleHide: () => void;
}) {
  const { user } = usePermissionsContext();
  const { customData }: any = notification;
  const showError = useShowError();

  const handleApprove = useCallback(
    () =>
      (async () => {
        try {
          const {
            allowedUserTypes = [],
            companies = [],
            companiesIds = [],
            ...restUser
          } = user as IUser;

          const newAllowedUserTypes = [
            ...new Set([...allowedUserTypes, ...customData?.allowedUserTypes]),
          ];

          const newCompaniesIds = [...new Set([...companiesIds, customData?.inviterCompany?.id])];

          const newCompanies = [
            ...(companies.filter((company: any) => company.id !== customData?.inviterCompany?.id) ||
              []),
            customData?.inviterCompany || [],
          ];

          const updatedViewerAccessCompanies = [
            ...(user?.viewerAccessCompanies?.filter(
              viewerAccessCompany =>
                viewerAccessCompany?.inviterCompanyId !== customData?.inviterCompany?.id
            ) || []),
            customData?.inviterCompanyViewerAccess,
          ];

          // NOTE: This is fragile and prone to breaking. The entire design needs to be overhauled
          // viewerAccessCompanies contains an array of objects, and within those objects is another array of objects
          const updatedUser = {
            ...restUser,
            companies: newCompanies,
            companiesIds: newCompaniesIds,
            allowedUserTypes: newAllowedUserTypes as typeof allowedUserTypes,
            viewerAccessCompanies: updatedViewerAccessCompanies,
          };

          if (user?.id) {
            // TODO: need to move to API once we solve user updates permissions
            await query.base.getById(QueryBase.USERS_COLLECTION(), user?.id!).update(updatedUser);
            handleHide();
          } else {
            throw 'User not found';
          }
        } catch (error) {
          console.error(error);
          showError({
            title: 'Error accepting request.',
            duration: 10000,
          });
        }
        return null;
      })(),
    [user, customData, handleHide, showError]
  );

  const buttons = [
    {
      text: 'Accept',
      class: 'primary',
      onClick: () => handleApprove(),
    },
    {
      text: 'Decline',
      class: 'error',
      onClick: () => handleHide(),
    },
  ];

  return (
    <Grid container padding="10px 0" columnSpacing={2} alignItems="center">
      {buttons.map(b => (
        <Grid key={b.text} item>
          <Button
            className={b?.class ?? ''}
            onClick={b.onClick}
            fullWidth
            size="medium"
            variant="contained"
            type="button"
          >
            <Typography variant="body1">{b.text}</Typography>
          </Button>
        </Grid>
      ))}
    </Grid>
  );
}

export default UserNotificationRequestPrompt;
