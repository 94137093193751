import React, { useEffect, useState } from 'react';
import './Lane.scss';
import PropTypes from 'prop-types';
import Query from 'services/firebase/Query';
import LaneTaken from './LaneTaken';
import LaneEmpty from './LaneEmpty';
import Executer from 'services/firebase/Executer';

const query = new Query();
const executer = new Executer();

const Lane = ({ scaleId, lane }) => {
  const [manifest, setManifest] = useState(null);
  const [batch, setBatch] = useState(null);

  useEffect(() => {
    if (!scaleId || !lane.id) {
      return;
    }

    const unsubscribe = query.manifests.getNextManifestOnScaleLane(scaleId, lane.id).onSnapshot(
      querySnapshot => {
        if (querySnapshot.docs.length) {
          const doc = querySnapshot.docs[0];

          setManifest({
            ...doc.data(),
            id: doc.id,
          });
        } else {
          setManifest(null);
        }
      },
      error => {
        console.error('Error', error);
      }
    );

    return unsubscribe;
  }, [scaleId, lane]);

  useEffect(() => {
    if (!manifest || !manifest.batchId) return;
    const getBatch = async id => {
      console.log('trying to get batch with id', id);
      const batchManifest = await executer.getSingleDocument(query.batchManifests.getBatchById(id));

      setBatch(batchManifest);
    };

    getBatch(manifest.batchId);
  });

  if (manifest) {
    // Taken Lane
    return <LaneTaken lane={lane} manifest={manifest} batch={batch} />;
  } else {
    // Empty Lane
    return <LaneEmpty lane={lane} />;
  }
};

Lane.propTypes = {
  lane: PropTypes.object.isRequired,
  scaleId: PropTypes.string.isRequired,
};

export default Lane;
