import React, { useEffect, useState } from 'react';
import { Button, Grid } from '@mui/material';
import { Link, useParams } from 'react-router-dom';
import Query from 'services/firebase/Query';
import Executer from 'services/firebase/Executer';
import { logic } from 'lg-helpers';
import CompaniesApi from 'services/postgres/CompaniesApi';
import { ICompany } from 'lg-helpers/dist/shared/interfaces/ICompany';
import QueryBase from 'lg-helpers/dist/firestore/query/QueryBase';

const { getDateStringForTimezone } = logic.dateTime;

const query = new Query();
const executer = new Executer();
const companiesApi = new CompaniesApi();

const SuperAdminUserVerification = () => {
  let { id } = useParams();
  const [user, setUser] = useState<any>(undefined);
  const [companyResults, setCompanyResults] = useState<any[]>([]);

  const determineLicenseState = () => {
    if (user.licenseAgreement) {
      if (user.licenseAgreement.isAccepted === undefined) {
        return 'User agreement data has bad format, non firestore timestamp type';
      } else {
        return `User agreement version: ${user.licenseAgreement.version} was ${
          !user.licenseAgreement.isAccepted ? 'not' : ''
        } accepted on ${getDateStringForTimezone(
          new Date(0).setUTCSeconds(user.licenseAgreement.acceptedAt.seconds)
        )}`;
      }
    } else {
      return `User agreement hasn't been accepted`;
    }
  };

  useEffect(() => {
    const getUsersCompanies = (usersCompanyIds: string[]) =>
      Promise.all(
        usersCompanyIds.map(async companyId => {
          // TODO: create an endpoint that accepts a list of companyIds.
          try {
            const company = await companiesApi.getById(companyId);
            return { id: companyId, company };
          } catch (e) {
            return { id: companyId };
          }
        })
      );

    const getUser = async () => {
      const u: any = await executer.getSingleDocument(
        query.base.getById(QueryBase.USERS_COLLECTION(), id!)
      );
      if (u.companiesIds) {
        const userCompanies = await getUsersCompanies(u.companiesIds);
        setCompanyResults(userCompanies);
      }

      setUser(u);
    };

    getUser();
    // eslint-disable-next-line
  }, []);

  return user ? (
    <Grid container>
      <Grid item xs={12}>
        <Button
          component={Link}
          variant="contained"
          to="/super-admin/administration/verify-search-user/"
        >
          Back
        </Button>
      </Grid>
      <Grid item xs={12}>
        <h2>User Verification</h2>
      </Grid>
      <Grid container>
        <Grid item xs={6}>
          Name:
        </Grid>
        <Grid item xs={6}>
          {user.firstName} {user.lastName}
        </Grid>

        <Grid item xs={6}>
          Email:
        </Grid>
        <Grid item xs={6}>
          {user.email}
        </Grid>

        <Grid item xs={6}>
          Phone:
        </Grid>
        <Grid item xs={6}>
          {user.phone}
        </Grid>

        <Grid item xs={6}>
          Temporary Password:
        </Grid>
        <Grid item xs={6}>
          {user?.passwordInitial ?? 'N/A'}
        </Grid>

        <Grid item xs={6}>
          License:
        </Grid>
        <Grid item xs={6}>
          {determineLicenseState()}
        </Grid>

        <Grid item xs={6}>
          Region:
        </Grid>
        <Grid item xs={6}>
          {user.regionId}
        </Grid>

        <Grid item xs={6}>
          Type:
        </Grid>
        <Grid item xs={6}>
          {user.type}
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <h3>Users company info</h3>
      </Grid>
      {companyResults.map(({ id: companyId, company }) =>
        company ? (
          <CompanyDetails key={companyId} company={company} />
        ) : (
          <EmptyCompany key={companyId} id={companyId} />
        )
      )}
    </Grid>
  ) : (
    <h3>Loading...</h3>
  );
};

const CompanyDetails = ({ company }: { company: ICompany }) => (
  <Grid container>
    <Grid item xs={12}>
      <h5>{company.name}</h5>
    </Grid>

    <Grid item xs={6}>
      Address:
    </Grid>
    <Grid item xs={6}>
      {company.address}
    </Grid>

    <Grid item xs={6}>
      City:
    </Grid>
    <Grid item xs={6}>
      {company.city}
    </Grid>

    <Grid item xs={6}>
      State:
    </Grid>
    <Grid item xs={6}>
      {company.state}
    </Grid>

    <Grid item xs={6}>
      Zip:
    </Grid>
    <Grid item xs={6}>
      {company.postalCode}
    </Grid>

    <Grid item xs={6}>
      Types:
    </Grid>
    <Grid item xs={6}>
      {company.types.join(', ')}
    </Grid>
  </Grid>
);

const EmptyCompany = (
  { id }: { id: string } // eslint-disable-line
) => (
  <Grid container>
    <Grid item xs={12}>
      Company Not Found
    </Grid>
  </Grid>
);

export default SuperAdminUserVerification;
