import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Grid, Card, CardHeader, Divider, CardContent, Box } from '@mui/material';
import BorderAllIcon from '@mui/icons-material/BorderAll';
import BorderClearIcon from '@mui/icons-material/BorderClear';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import { DateRangePicker } from 'rsuite';
import { refreshManifestsProjectData, touchManifests, refreshProjectScheduledTasks } from 'api/super-admin';
import ButtonConfirmWithLoading from 'sharedComponents/ButtonConfirmWithLoading';
import LogsDisplay from 'sharedComponents/LogsDisplay';
import useShowError from 'modules/errors';
import { IProject } from 'lg-helpers/dist/shared/interfaces/IProject';
import { ValueType as DateRangeValueType } from 'rsuite/lib/DateRangePicker';
import { Receipt } from '@mui/icons-material';

const AdministratorPanel = ({ project }: { project: IProject }) => {
  const showError = useShowError();
  const [dateRange, setDateRange] = useState<DateRangeValueType>([]);
  const [countManifests, setCountManifests] = useState<number | null>(null);
  const [message, setMessage] = useState<string | null>(null);
  const [logs] = useState([]);

  useEffect(() => {
    if (countManifests !== null) {
      setMessage(`${countManifests} manifests`);
    }
  }, [countManifests]);

  const executeRefreshManifestsProjectData = async () => {
    try {
      setMessage(null);
      await refreshManifestsProjectData(project.id as string, dateRange[0]!, dateRange[1]!);
    } catch (err) {
      showError({ title: 'Error refreshing manifests.' });
    }
  };

  const executeTouchManifests = async () => {
    try {
      setMessage(null);
      await touchManifests(project.id as string, dateRange[0]!, dateRange[1]!);
    } catch (err) {
      showError({ title: 'Error refreshing receipts.' });
    }
  };

  const executeProjectScheduledTasks = async () => {
    try {
      setCountManifests(null);
      setMessage(null);
      await refreshProjectScheduledTasks(project.id as string, dateRange[0]!, dateRange[1]!);
    } catch (err) {
      console.error(err);
      showError({ title: 'Error executing project scheduled tasks.' });
    }
  };

  return (
    <Card style={{ border: '2px solid' }}>
      <CardHeader title="Administrator panel" />
      <Divider />
      <CardContent>
        <Grid container spacing={2}>
          {message !== null && (
            <Grid item xs={12}>
              <Box display="flex" justifyContent="center" alignItems="center">
                {message}
              </Box>
            </Grid>
          )}
          <Grid item md={12} xs={12}>
            <Box display="flex" alignItems="center" marginBottom={1}>
              <DateRangePicker
                appearance="default"
                placeholder="Date range"
                placement="bottomStart"
                size="lg"
                format="MM/DD/YYYY"
                value={dateRange}
                onChange={setDateRange}
                style={{ width: 260 }}
                disabled={false}
              />
            </Box>
          </Grid>
          <Grid item md={4} xs={12}>
            <ButtonConfirmWithLoading
              Pictogram={BorderAllIcon}
              disabled={!(dateRange[0] && dateRange[1])}
              text="Refresh Manifest Data"
              textConfirm="Click again to refresh manifests!"
              confirmCallback={executeRefreshManifestsProjectData}
            />
          </Grid>
          <Grid item md={4} xs={12}>
            <ButtonConfirmWithLoading
              Pictogram={Receipt}
              disabled={!(dateRange[0] && dateRange[1])}
              text="Reload Receipt Data"
              textConfirm="Click again to reload receipt data!"
              confirmCallback={executeTouchManifests}
            />
          </Grid>
          <Grid item md={4} xs={12}>
            <ButtonConfirmWithLoading
              Pictogram={AccessTimeIcon}
              disabled={!(dateRange[0] && dateRange[1])}
              text="Execute Scheduled Tasks"
              textConfirm="Click again to execute scheduled tasks!"
              confirmCallback={executeProjectScheduledTasks}
            />
          </Grid>
          <Grid item xs={12}>
            <LogsDisplay logs={logs} />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

AdministratorPanel.propTypes = {
  project: PropTypes.object,
};

export default AdministratorPanel;
