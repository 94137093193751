import React from 'react';
import { Grid, Button, Typography, Box, CircularProgress, Link } from '@mui/material';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import { useConfirm } from 'material-ui-confirm';
import PdfTypeSelectionDropdown from './PdfTypeSelectionDropdown';
import { useNavigate } from 'react-router-dom';

const ProjectViewTopBar = ({
  project,
  scaleCompanies,
  isAuthUser,
  isLoadingSaveDetails,
  tabValue,
  tab,
  handleSaveDetails,
  id,
  filters,
  goToPdfsDownloadTab,
  hasChanges,
  useNewDownloads,
  templates,
}: any) => {
  const navigate = useNavigate();
  const confirm = useConfirm();

  const handleClickBack = async () => {
    if (hasChanges) {
      try {
        await confirm({
          title: '',
          confirmationText: 'Yes',
          cancellationText: 'No',
          content: `Would you like to save your changes before leaving?`,
        });
      } catch {
        navigate(`/`);
      }
    } else {
      navigate(`/`);
    }
  };

  return (
    <>
      <Grid item md={1} sm={3} xs={12} style={{ minWidth: '120px' }}>
        <Button onClick={handleClickBack} variant="outlined" size="small" fullWidth>
          <NavigateBeforeIcon fontSize="small" /> Back
        </Button>
      </Grid>
      <Grid item md={1} sm={3} xs={12} style={{ minWidth: '120px' }}>
        {(!isAuthUser.isReadOnly ||
          ['generator', 'contractor', 'transporterAdmin', 'scaleAdmin'].some(
            e => isAuthUser[e]
          )) && (
          <Link href={`${id}/reports`} underline="none" color="inherit" target="__blank">
            <Button color="primary" variant="contained" size="small" fullWidth>
              Report
            </Button>
          </Link>
        )}
      </Grid>
      <Grid item md={1} sm={3} xs={12} style={{ minWidth: '120px' }}>
        <PdfTypeSelectionDropdown
          disabled={tabValue !== tab.MANIFESTS}
          project={project}
          filters={filters as any}
          goToPdfsTabCallback={goToPdfsDownloadTab}
          useNewDownloads={useNewDownloads}
        />
      </Grid>
      {(!isAuthUser.readOnly ||
        ['generator', 'contractor', 'transporterAdmin'].some(e => isAuthUser[e])) && (
        <Grid item md={1} sm={3} xs={12} style={{ minWidth: '120px' }}>
          <Button
            color="primary"
            variant="contained"
            size="small"
            onClick={handleSaveDetails}
            disabled={isLoadingSaveDetails || !templates.manifestTemplates?.length}
            fullWidth
          >
            Save
            {isLoadingSaveDetails && (
              <Box marginLeft={2}>
                <CircularProgress color="inherit" />
              </Box>
            )}
          </Button>
          {Object.keys(project).length > 1 && !project.manifestTemplateId && scaleCompanies && (
            <Typography variant="body1" color="error">
              Please define manifest templates for selected scale company!
            </Typography>
          )}
        </Grid>
      )}
    </>
  );
};

export default ProjectViewTopBar;
