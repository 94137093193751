import React from 'react';
import { createSvgIcon } from '@mui/material';

export const WritingFluently = createSvgIcon(
  <>
    <path
      d="M17.7929.2929c.3905-.39053 1.0237-.39053 1.4142 0l2.5 2.49999c.3905.39053.3905 1.02369 0 1.41422l-7.5 7.49999A1.0001 1.0001 0 0 1 13.5 12H11c-.5523 0-1-.4477-1-1V8.5c0-.26522.1054-.51957.2929-.70711l7.5-7.5Z"
      fillOpacity=".85"
    />
    <path
      d="M3.5 10C1.567 10 0 11.567 0 13.5S1.567 17 3.5 17h15c.8284 0 1.5.6716 1.5 1.5s-.6716 1.5-1.5 1.5H8c-.55228 0-1 .4477-1 1s.44772 1 1 1h10.5c1.933 0 3.5-1.567 3.5-3.5S20.433 15 18.5 15h-15c-.82842 0-1.5-.6716-1.5-1.5S2.67158 12 3.5 12H7c.55228 0 1-.4477 1-1s-.44772-1-1-1H3.5Z"
      fillOpacity=".85"
    />
  </>,
  'Writing Fluently'
);
