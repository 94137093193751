import { createSlice } from '@reduxjs/toolkit';
import Fuse from 'fuse.js';

export const superAdminCompaniesStoreSlice = createSlice({
  name: 'superAdminCompanies',
  initialState: {
    companies: [],
    selectedCompaniesIds: [],
    companiesSearchFilter: '',
  },
  reducers: {
    setCompanies: (state, action) => {
      state.companies = action.payload;
    },
    setSelectedCompaniesIds: (state, action) => {
      state.selectedCompaniesIds = action.payload;
    },
    setCompaniesSearchFilter: (state, action) => {
      state.companiesSearchFilter = action.payload;
    },
  },
});

export const { setCompanies, setSelectedCompaniesIds, setCompaniesSearchFilter } =
  superAdminCompaniesStoreSlice.actions;

export const selectCompanies = state => {
  const options = {
    includeScore: true,
    threshold: 0.5,
    keys: ['id', 'name', 'typesStringified', 'uniqueRegistrationCode', 'address', 'email', 'phone'],
  };
  const { companiesSearchFilter, companies } = state.superAdminCompanies;
  let fuseResults = companies;
  if (companiesSearchFilter) {
    const fuse = new Fuse(companies, options);
    fuseResults = fuse.search(companiesSearchFilter);
    fuseResults = fuseResults.map(result => result.item);
  }
  return fuseResults;
};

export const selectSelectedCompaniesIds = state => state.superAdminCompanies.selectedCompaniesIds;
export const selectCompaniesSearchFilter = state => state.superAdminCompanies.companiesSearchFilter;

export default superAdminCompaniesStoreSlice.reducer;
