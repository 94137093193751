import { Marker, Popup } from 'mapbox-gl';

const generateIconMarkerElement = imgUrl => {
  if (!imgUrl) return null;
  let el = document.createElement('img');
  el.style.width = imgUrl === '/static/images/icons/truck.svg' ? '20px' : '40px';
  el.src = imgUrl;

  return el;
};

const generateIconMarker = (gpsLocation, iconpath, html) => {
  const { longitude, latitude } = gpsLocation;
  return new Marker(generateIconMarkerElement(iconpath))
    .setPopup(new Popup({ offset: 25 }).setHTML(html))
    .setLngLat([longitude, latitude]);
};

export { generateIconMarkerElement, generateIconMarker };
