import React from 'react';
import { DialogContent } from '@mui/material';
import ModalHeader from 'sharedComponents/DialogHeader';
import ProjectTemplateForm from './ProjectTemplateForm';
import { IProjectTemplate } from 'lg-helpers/dist/shared/interfaces/IProjectTemplate';

interface ModalContainerProps {
  selected?: IProjectTemplate;
}

const ModalContainer = ({ selected }: ModalContainerProps) => {
  return (
    <>
      <ModalHeader
        title={selected ? 'Update Project Task Template' : 'Save Project Task Template'}
      />
      <DialogContent>
        <ProjectTemplateForm />
      </DialogContent>
    </>
  );
};

export default ModalContainer;
