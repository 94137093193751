import React from 'react';
import useGetDispatches, { BrokerDispatch } from 'services/hooks/useGetDispatches';
import CrudTable from 'sharedComponents/CrudTable';
import Query from 'services/firebase/Query';
import { Typography, Box } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import { TableCrudColumn } from 'sharedComponents/TableCrud';
import QueryBase from 'lg-helpers/dist/firestore/query/QueryBase';

const columns: TableCrudColumn<BrokerDispatch>[] = [
  { path: 'date', label: 'Date' },
  { path: 'projectName', label: 'Project' },
  { path: 'material', label: 'Material' },
  { path: 'contractorName', label: 'Contractor' },
  {
    label: 'Total',
    getCellValueCallback: row => {
      return <Typography>{row.aggregatedData.countLoadsTotal || 0}</Typography>;
    },
  },
  {
    label: 'Loaded',
    getCellValueCallback: row => {
      return <Typography>{row.aggregatedData.countLoadsLoaded || 0}</Typography>;
    },
  },
  {
    label: 'Dumped',
    getCellValueCallback: row => {
      return <Typography>{row.aggregatedData.countLoadsDumped || 0}</Typography>;
    },
  },
  {
    label: 'Not assigned',
    getCellValueCallback: row => {
      const notAssignedCount =
        row.aggregatedData.totalByTruckNumber[QueryBase.NULL_TRUCK_NUMBER()] || 0;
      return (
        <Typography color={notAssignedCount ? 'error' : 'inherit'}>{notAssignedCount}</Typography>
      );
    },
  },
  {
    label: 'Can repeat',
    isVerticalHeaderText: true,
    getCellValueCallback: row => (
      <Box width="100%" display="flex" alignItems="center" justifyContent="center">
        {row.canRepeat ? <CheckBoxIcon /> : <CheckBoxOutlineBlankIcon />}
      </Box>
    ),
  },
];

const DispatchesTable = ({ dateRangeFilter }: { dateRangeFilter: [Date, Date] }) => {
  const navigate = useNavigate();
  const { dispatches, isLoading } = useGetDispatches(dateRangeFilter, false);

  return (
    <CrudTable
      title="Dispatches"
      rows={dispatches}
      columns={columns}
      isLoading={isLoading}
      handleRowClickCallback={dispatch => navigate(`/brokers/loads/${dispatch.dispatchID}`)}
    />
  );
};

export default DispatchesTable;
