import React, { useState } from 'react';
import ProjectMaterialForm from 'modules/civilAndTrucking/civil/ProjectMaterials/ProjectMaterialsForm/ProjectMaterialForm';
import { Grid, Typography, Box, SvgIcon, Button } from '@mui/material';
import { ChevronLeft } from '@mui/icons-material';
import { useNavigate, useParams } from 'react-router-dom';
import ProjectMaterialsList from './ProjectMaterialsDataGrid';
import { useHasAccess } from 'services/permissions';
import { IProjectMaterial } from 'lg-helpers/dist/shared/interfaces/IProjectMaterial';

interface IFilter {
  materialId?: number;
  sourceSupplierId?: string;
}

export const ProjectMaterials = () => {
  const { projectId } = useParams();
  const navigate = useNavigate();
  const [selected, setSelected] = useState<IProjectMaterial | undefined>(undefined);
  const [showForm, setShowForm] = useState(false);
  const [filter, setFilter] = useState<IFilter | undefined>();
  const auth = useHasAccess(['projects:create', 'projects:update']);
  const [type, setType] = useState<'all' | 'active'>('active');

  const handleSelection = (selection: IProjectMaterial, showFormArg: boolean) => {
    setSelected(selection);
    setShowForm(showFormArg);
  };

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={8}>
          <Box>
            <Typography
              variant="h5"
              sx={{
                color: 'neutral.main',
                typography: { textTransform: 'uppercase' },
              }}
            >
              Project Materials
            </Typography>
            <Box
              display="flex"
              sx={{
                '&:hover': {
                  cursor: 'pointer',
                },
              }}
            >
              <SvgIcon>
                <ChevronLeft />
              </SvgIcon>
              <Typography onClick={() => navigate(-1)}>Back</Typography>
            </Box>
          </Box>
        </Grid>
        {!showForm && (
          <Grid item xs={4} display="flex" justifyContent="flex-end" alignItems="center">
            <Button onClick={() => setShowForm(!showForm)} color="primary" variant="contained">
              Create Material
            </Button>
          </Grid>
        )}
        <Grid item xs={12}>
          {auth.hasAccess && showForm && (
            <ProjectMaterialForm
              selected={selected}
              setSelected={handleSelection}
              setFilter={setFilter}
              filter={filter}
              type={type}
            />
          )}
        </Grid>
        <Grid item xs={12}>
          <ProjectMaterialsList
            handleSelection={handleSelection}
            filter={filter}
            projectId={Number(projectId)}
            type={type}
            setType={setType}
          />
        </Grid>
      </Grid>
    </>
  );
};
