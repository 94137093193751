import React from 'react';
import { createSvgIcon } from '@mui/material';

export const Avatar = createSvgIcon(
  <path
    fillRule="evenodd"
    clipRule="evenodd"
    d="M10 2C8.34315 2 7 3.34315 7 5s1.34315 3 3 3c1.6569 0 3-1.34315 3-3s-1.3431-3-3-3ZM5 5c0-2.76142 2.23858-5 5-5 2.7614 0 5 2.23858 5 5s-2.2386 5-5 5c-2.76142 0-5-2.23858-5-5ZM0 21c0-5.5228 4.47717-10 10-10 5.5228 0 10 4.4772 10 10 0 .5523-.4477 1-1 1s-1-.4477-1-1c0-4.4183-3.5817-8-8-8-4.41827 0-8 3.5817-8 8 0 .5523-.44772 1-1 1-.55229 0-1-.4477-1-1Z"
  />,
  'Avatar'
);
