import React, { useEffect, useState } from 'react';
import { selectAuthUser } from 'modules/auth/storeSliceAuth';
import { useSelector } from 'react-redux';
import 'moment-timezone';

import api, { WorkCodes } from 'services/api/autogenerated';
import { Box, Grid, IconButton, TextField } from '@mui/material';
import LoadingComponent from '../../../sharedComponents/LoadingComponent';
import WorkCodeView from './WorkCodeView';
import WorkCodeList from './WorkCodeList';
import CreateWorkCode from './CreateWorkCode';
import Fuse from 'fuse.js';
import { Refresh } from '../../civilAndTrucking/shared/CustomIcons';

interface WorkCodeViewProps {
  projectId: string;
}

const WorkCodeTab: React.FC<WorkCodeViewProps> = ({ projectId }) => {
  const authUser = useSelector(selectAuthUser);
  const [isLoading, setIsLoading] = useState(true);
  const [workCodes, setWorkCodes] = useState<WorkCodes[]>();

  const [selectedWorkCode, setSelectedWorkCode] = useState<WorkCodes>();

  const [searchTerm, setSearchTerm] = useState('');

  // Fuzzy Search options
  const fuseOptions = {
    keys: ['nickname', 'work_code_id'],
  };

  const refreshWorkCodes = async () => {
    setIsLoading(true);

    const fetchedWorkCodes: any = await api.workCode.getWorkCodeForProjectIdRequest(projectId);

    if (!fetchedWorkCodes.error) setWorkCodes(fetchedWorkCodes.data);

    setIsLoading(false);
  };

  // Fuzzy search instance
  const fuse = new Fuse(workCodes ? workCodes : [], fuseOptions);

  const getFilteredRows = () => {
    if (workCodes) {
      return searchTerm ? fuse.search(searchTerm).map(result => result.item) : workCodes;
    }
    return [];
  };

  useEffect(() => {
    if (!authUser || !projectId) {
      return undefined;
    }

    setIsLoading(true);

    refreshWorkCodes();
  }, [authUser, projectId]);

  const selectWorkCodeFromTableRow = (workCode: WorkCodes) => {
    setSelectedWorkCode(workCode);
  };

  const handleWorkCodeUpdate = async () => {
    setIsLoading(true);
    setSelectedWorkCode(undefined);
    await refreshWorkCodes();
  };

  if (isLoading) {
    return (
      <Grid xs={6} md={1}>
        <Box
          height={'100%'}
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
        >
          <LoadingComponent isLoading={isLoading} />
        </Box>
      </Grid>
    );
  }

  console.log('Workcodes: ', workCodes);

  return (
    <Grid>
      {!selectedWorkCode && (
        <CreateWorkCode
          projectId={projectId}
          onWorkCodeUpdate={handleWorkCodeUpdate}
          skipHeader={workCodes && workCodes.length > 0}
        />
      )}

      {selectedWorkCode ? (
        <WorkCodeView workCode={selectedWorkCode} handleWorkCodeUpdate={handleWorkCodeUpdate} />
      ) : (
        workCodes &&
        workCodes.length > 0 && (
          <div>
            <div
              style={{ display: 'flex', alignItems: 'center', marginBottom: '10px', width: '100%' }}
            >
              <TextField
                label="Search"
                variant="outlined"
                value={searchTerm}
                onChange={e => setSearchTerm(e.target.value)}
                style={{ marginRight: '10px', flex: 1 }}
              />

              <IconButton color="secondary" onClick={refreshWorkCodes}>
                <Refresh
                  color="secondary"
                  sx={{
                    fontSize: 24,
                    marginRight: '-2px',
                    marginBottom: '-2px',
                  }}
                />
                <div style={{ paddingLeft: 10 }}>Refresh Work Codes</div>
              </IconButton>

              <IconButton onClick={handleWorkCodeUpdate}></IconButton>
            </div>
            <WorkCodeList
              workCodes={getFilteredRows()}
              refreshWorkCodes={refreshWorkCodes}
              setSelectedWorkCode={selectWorkCodeFromTableRow}
            />
          </div>
        )
      )}
    </Grid>
  );
};

export default WorkCodeTab;
