import React, { useState, useContext, useEffect } from 'react';
import { Button, Box, List, ListItem, IconButton, Icon, Typography } from '@mui/material';
import { Add, ArrowRightAlt, Close } from '@mui/icons-material';
import { ModalContext } from './ModalContext';

import AddTicketModal from 'modules/civilAndTrucking/civil/Tickets/AddTicketModal';
import AddProjectTask from 'modules/civilAndTrucking/civil/ProjectTask/index';
import AddCompanyMaterial from 'modules/civilAndTrucking/civil/CompanyMaterials/AddMaterialModal';
import AddUserForm from 'modules/civilAndTrucking/civil/Users/AddUserForm/AddUserForm';
import AddInvoiceForm from 'modules/civilAndTrucking/civil/Invoices/AddInvoiceForm';
import { RootState } from 'store/store';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { selectAuthUser } from 'modules/auth/storeSliceAuth';

type MenuItem = {
  name: string;
  form: React.FunctionComponentElement<HTMLElement> | string;
};

const QuickAdd = () => {
  const navigate = useNavigate();
  const { handleModal } = useContext(ModalContext);
  const [expanded, setExpanded] = useState(false);
  const authUser = useSelector(selectAuthUser);
  const { actingAsType } = authUser;
  const [menuItems, setMenuItems] = useState<MenuItem[]>([]);

  const showForm = async (form: React.FunctionComponentElement<HTMLElement> | string) => {
    if (typeof form === 'string') {
      navigate(form);
    } else {
      handleModal(form);
    }
  };

  const activeProject = useSelector((state: RootState) => state.civil.projects.activeProject);

  useEffect(() => {
    switch (actingAsType) {
      case 'Civil Project Manager':
        setMenuItems([
          {
            name: 'New Project',
            form: '/civil/projects/add',
          },
          {
            name: 'New Ticket',
            form: (
              <AddTicketModal
                activeProjectId={
                  activeProject && activeProject.active ? Number(activeProject.id) : undefined
                }
              />
            ),
          },
          {
            name: 'New Project Task',
            form: (
              <AddProjectTask
                selected={{
                  projectId:
                    activeProject && activeProject.active ? Number(activeProject.id) : undefined,
                  autoTicketNumber: false,
                  deleted: false,
                }}
              />
            ),
          },
          // TODO: implement a separate page to create new company connections
          // {
          //   name: 'New Source',
          //   form: <AddCompanyWithoutUserForm type="scale" />,
          // },
          // {
          //   name: 'New Transporter',
          //   form: <AddCompanyWithoutUserForm type="transporter" />,
          // },
          {
            name: 'New Material',
            form: <AddCompanyMaterial />,
          },
          {
            name: 'New User',
            form: <AddUserForm />,
          },
        ]);
        break;
      case 'Civil Accountant':
        setMenuItems([
          {
            name: 'New Project',
            form: '/civil/projects/add',
          },
          {
            name: 'New Ticket',
            form: (
              <AddTicketModal
                activeProjectId={
                  activeProject && activeProject.active ? Number(activeProject.id) : undefined
                }
              />
            ),
          },
          {
            name: 'New Invoice',
            form: (
              <AddInvoiceForm
                selected={{
                  projectId:
                    activeProject && activeProject.active ? Number(activeProject.id) : undefined,
                }}
              />
            ),
          },
          {
            name: 'New User',
            form: <AddUserForm />,
          },
        ]);
        break;
      default:
        break;
    }
  }, [activeProject, actingAsType]);

  return authUser.actingAsType === 'Civil Project Manager' ? (
    <>
      {!expanded ? (
        <Button
          onClick={() => setExpanded(!expanded)}
          startIcon={<Add />}
          sx={{
            background:
              'linear-gradient(159deg, #90B355 12.24%, rgba(85, 145, 127, 0.75) 93.98%), #90B355',
            color: 'white',
            zIndex: 1201,
            position: 'fixed',
            borderRadius: 3,
            left: 10,
            bottom: 20,
            height: 56,
            width: 122,
            textTransform: 'none',
            fontSize: 16,
            boxShadow: '0px 2px 7px 0px #00000088',
          }}
        >
          Create
        </Button>
      ) : (
        <Box
          sx={{
            background:
              'linear-gradient(159deg, #90B355 12.24%, rgba(85, 145, 127, 0.75) 93.98%), #90B355',
            color: 'white',
            zIndex: 1201,
            position: 'fixed',
            borderRadius: 3,
            left: 10,
            bottom: 20,
            fontSize: 16,
            boxShadow: '0px 2px 7px 0px #00000088',
            maxWidth: 200,
          }}
        >
          <List>
            <IconButton onClick={() => setExpanded(!expanded)} sx={{ color: '#fff' }}>
              <Close />
            </IconButton>
            {menuItems.map((item, index) => {
              return (
                <ListItem
                  key={index}
                  sx={{
                    margin: 0,
                    '&:hover': {
                      backgroundColor: 'rgba(255, 255, 255, 0.2)',
                      cursor: 'pointer',
                    },
                  }}
                  onClick={() => {
                    (async () => {
                      await showForm(item.form);
                      setExpanded(!expanded);
                    })();
                  }}
                >
                  <Typography noWrap>{item.name}</Typography>
                  <Icon>
                    <ArrowRightAlt />
                  </Icon>
                </ListItem>
              );
            })}
          </List>
        </Box>
      )}
    </>
  ) : authUser.actingAsType === 'Civil Accountant' ? (
    <>
      {!expanded ? (
        <Button
          onClick={() => setExpanded(!expanded)}
          startIcon={<Add />}
          sx={{
            background:
              'linear-gradient(159deg, #90B355 12.24%, rgba(85, 145, 127, 0.75) 93.98%), #90B355',
            color: 'white',
            zIndex: 1201,
            position: 'fixed',
            borderRadius: 3,
            left: 10,
            bottom: 20,
            height: 56,
            width: 122,
            textTransform: 'none',
            fontSize: 16,
            boxShadow: '0px 2px 7px 0px #00000088',
          }}
        >
          Create
        </Button>
      ) : (
        <Box
          sx={{
            background:
              'linear-gradient(159deg, #90B355 12.24%, rgba(85, 145, 127, 0.75) 93.98%), #90B355',
            color: 'white',
            zIndex: 1201,
            position: 'fixed',
            borderRadius: 3,
            left: 10,
            bottom: 20,
            fontSize: 16,
            boxShadow: '0px 2px 7px 0px #00000088',
            maxWidth: 200,
          }}
        >
          <List>
            <IconButton onClick={() => setExpanded(!expanded)} sx={{ color: '#fff' }}>
              <Close />
            </IconButton>
            {menuItems.map((item, index) => {
              return (
                <ListItem
                  key={index}
                  sx={{
                    margin: 0,
                    '&:hover': {
                      backgroundColor: 'rgba(255, 255, 255, 0.2)',
                      cursor: 'pointer',
                    },
                  }}
                  onClick={() => {
                    (async () => {
                      await showForm(item.form);
                      setExpanded(!expanded);
                    })();
                  }}
                >
                  <Typography noWrap>{item.name}</Typography>
                  <Icon>
                    <ArrowRightAlt />
                  </Icon>
                </ListItem>
              );
            })}
          </List>
        </Box>
      )}
    </>
  ) : (
    <></>
  );
};

export default QuickAdd;
