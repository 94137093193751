import React, { useEffect, useState } from 'react';
import { Box } from '@mui/material';
import ModalHeader from 'sharedComponents/ModalHeader';
import ButtonToggle from 'sharedComponents/ButtonToggle';
import { ExportTickets } from './ExportTickets';
import { TicketExportCSVTemplate } from './TicketExportCSVTemplate';
import { useSelector } from 'react-redux';
import { selectTicketExportTemplate } from '../../../../redux/ticketsStoreSlice';
import { LoadingOverlay } from 'sharedComponents/LoadingOverlay';
import { getTicketExportTemplate } from '../../../../redux/actions';
import { thunkDispatch } from 'store/store';

export const ExportTicketsModal = ({
  companiesIds,
  date,
}: {
  companiesIds?: string[];
  date?: Date;
}) => {
  const [toggleIndex, setToggleIndex] = useState<0 | 1>(0);

  const { ticketsExportTemplate, ticketsExportTemplateStatus } = useSelector(
    selectTicketExportTemplate
  );

  useEffect(() => {
    thunkDispatch(getTicketExportTemplate());
  }, []);

  if (ticketsExportTemplateStatus === 'pending') {
    return <LoadingOverlay />;
  }

  return (
    <Box sx={{ p: '0px 16px' }}>
      <ModalHeader
        title={`Export Tickets`}
        optionalElement={
          <ButtonToggle
            firstVal={'Create Ticket Export'}
            secondVal={'Manage Export Templates'}
            toggleIndex={toggleIndex}
            setToggleIndex={setToggleIndex}
          />
        }
      />
      {toggleIndex ? (
        <TicketExportCSVTemplate existingExportTemplate={ticketsExportTemplate} />
      ) : (
        <ExportTickets companiesIds={companiesIds ?? []} date={date} />
      )}
    </Box>
  );
};
