import React, { useState, ChangeEvent } from 'react';
import {
  Grid,
  Card,
  CardHeader,
  CardContent,
  TextField,
  FormControlLabel,
  Switch,
  Button,
  Typography,
  Box,
  Autocomplete,
} from '@mui/material';
import { hasErrorRequired } from 'services/logic/formValidation';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import sectionsForCustomInputs from 'utils/sectionsForCustomInputs';
import { UserTypesArray } from 'lg-helpers/dist/constants/user/UserTypes';
import ErrorMessages from 'services/constants/errorMessages';
import { ICustomInputDefinitions } from 'lg-helpers/dist/shared/interfaces/ICustomInputDefinitions';
const { isRequired } = ErrorMessages;

interface CustomInputTextDefinitionProps {
  inputDefinition: ICustomInputDefinitions;
  setInputDefinition: (inputDefinition: ICustomInputDefinitions) => void;
  canValidate: boolean;
  setCanValidate: (canValidate: boolean) => void;
  hideCallback: (inputDefinition: ICustomInputDefinitions) => void;
  userCanEdit?: boolean;
}

const CustomInputTextDefinition = ({
  inputDefinition,
  setInputDefinition,
  canValidate,
  setCanValidate,
  hideCallback,
  userCanEdit = true,
}: CustomInputTextDefinitionProps) => {
  const [localInputDefinition, setLocalInputDefinition] =
    useState<ICustomInputDefinitions>(inputDefinition);

  const updateExternalInputDefinition = (newValue: ICustomInputDefinitions) => {
    let isValidCustomInputDefinition = true;
    if (!newValue.label || !newValue.propertyPathInManifestCustomInputs) {
      isValidCustomInputDefinition = false;
    }

    setInputDefinition({
      ...newValue,
      label: (newValue.label || '').trim(),
      propertyPathInManifestCustomInputs: (
        newValue.propertyPathInManifestCustomInputs || ''
      ).trim(),
      isValidCustomInputDefinition,
    });
  };

  const handleChange = (event: any, callUpdateExternal: boolean) => {
    const target = event.target as HTMLInputElement;
    const changedLocalInputDefinition: ICustomInputDefinitions = {
      ...localInputDefinition,
      [target.name]: target?.type === 'checkbox' ? target.checked : target.value || '',
    };
    setLocalInputDefinition(changedLocalInputDefinition);

    console.log('changedLocalInputDefinition: ', callUpdateExternal, changedLocalInputDefinition);

    if (callUpdateExternal) {
      updateExternalInputDefinition(changedLocalInputDefinition);
    }
    setCanValidate(false);
  };

  const handleNativeSelectChange = (name: string, value: string[]) => {
    handleChange({ target: { name, value } }, true);
  };

  const handleBlur = () => {
    updateExternalInputDefinition(localInputDefinition);
  };

  return (
    <Card sx={{ width: '100%' }}>
      <CardHeader
        title={`${localInputDefinition.order}.[${localInputDefinition.type}] ${
          localInputDefinition.label || 'New Custom Input Definition'
        }`}
        action={
          <>
            <FormControlLabel
              control={
                <Switch
                  disabled={!userCanEdit}
                  checked={!!localInputDefinition.isRequired}
                  onChange={(evt: ChangeEvent<HTMLInputElement>) => handleChange(evt, true)}
                  onBlur={handleBlur}
                  name="isRequired"
                  color="primary"
                />
              }
              label="Is Required"
            />
            <FormControlLabel
              control={
                <Switch
                  disabled={!userCanEdit}
                  checked={!!localInputDefinition.showOnQRCodeScreen}
                  onChange={(evt: ChangeEvent<HTMLInputElement>) => handleChange(evt, true)}
                  onBlur={handleBlur}
                  name="showOnQRCodeScreen"
                  color="primary"
                />
              }
              label="Show on QR Code Screen"
            />
            <Button
              disabled={!userCanEdit}
              variant="outlined"
              onClick={() => hideCallback(inputDefinition)}
            >
              {inputDefinition.isHidden ? (
                <>
                  <VisibilityOffIcon color="secondary" />
                  <Box marginLeft={1}>
                    <Typography color="secondary">Is hidden</Typography>
                  </Box>
                </>
              ) : (
                <>
                  <VisibilityIcon />
                  <Box marginLeft={1}>
                    <Typography>Is visible</Typography>
                  </Box>
                </>
              )}
            </Button>
          </>
        }
      />
      <CardContent>
        <Grid container spacing={3}>
          <Grid item sm={6} xs={12}>
            <TextField
              disabled={!userCanEdit}
              fullWidth
              label="Label"
              name="label"
              onChange={(evt: ChangeEvent<HTMLInputElement>) => handleChange(evt, true)}
              onBlur={handleBlur}
              value={localInputDefinition.label || ''}
              variant="outlined"
              error={hasErrorRequired(localInputDefinition.label, canValidate)}
              helperText={
                hasErrorRequired(localInputDefinition.label, canValidate) ? isRequired : ''
              }
              required
            />
          </Grid>
          <Grid item sm={6} xs={12}>
            <TextField
              disabled={!userCanEdit}
              fullWidth
              label={`Property Path in #.customInputs.${
                localInputDefinition.propertyPathInManifestCustomInputs || '?'
              }`}
              name="propertyPathInManifestCustomInputs"
              onChange={(evt: ChangeEvent<HTMLInputElement>) => handleChange(evt, true)}
              onBlur={handleBlur}
              value={localInputDefinition.propertyPathInManifestCustomInputs || ''}
              variant="outlined"
              error={hasErrorRequired(
                localInputDefinition.propertyPathInManifestCustomInputs,
                canValidate
              )}
              helperText={
                hasErrorRequired(
                  localInputDefinition.propertyPathInManifestCustomInputs,
                  canValidate
                )
                  ? isRequired
                  : ''
              }
              required
            />
          </Grid>
          <Grid item sm={6} xs={12}>
            <TextField
              disabled={true}
              fullWidth
              label="Column index"
              name="columnIndex"
              value={localInputDefinition.columnIndex || ''}
              variant="outlined"
            />
          </Grid>
          <Grid item sm={6} xs={12} />
          <Grid item sm={6} xs={12}>
            <Autocomplete
              multiple
              options={UserTypesArray}
              getOptionDisabled={() => !userCanEdit}
              value={localInputDefinition.userTypesCanEdit || []}
              onChange={(event, value) => handleNativeSelectChange('userTypesCanEdit', value)}
              renderInput={params => (
                <TextField
                  {...params}
                  variant="outlined"
                  label="User Types - Edit"
                  placeholder="Select user types"
                />
              )}
            />
          </Grid>
          <Grid item sm={6} xs={12}>
            <Autocomplete
              multiple
              options={sectionsForCustomInputs}
              getOptionDisabled={() => !userCanEdit}
              value={localInputDefinition.sectionsCanEdit || []}
              onChange={(event, value) => handleNativeSelectChange('sectionsCanEdit', value)}
              renderInput={params => (
                <TextField
                  {...params}
                  variant="outlined"
                  label="Edit in Sections"
                  placeholder="Select sections"
                />
              )}
            />
          </Grid>
          <Grid item sm={6} xs={12}>
            <Autocomplete
              multiple
              options={UserTypesArray}
              getOptionDisabled={() => !userCanEdit}
              value={localInputDefinition.userTypesCanOnlyRead || []}
              onChange={(event, value) => handleNativeSelectChange('userTypesCanOnlyRead', value)}
              renderInput={params => (
                <TextField
                  {...params}
                  variant="outlined"
                  label="User Types - Only Read"
                  placeholder="Select user types"
                />
              )}
            />
          </Grid>
          <Grid item sm={6} xs={12}>
            <Autocomplete
              multiple
              options={sectionsForCustomInputs}
              value={localInputDefinition.sectionsCanOnlyRead || []}
              getOptionDisabled={() => !userCanEdit}
              onChange={(event, value) => handleNativeSelectChange('sectionsCanOnlyRead', value)}
              renderInput={params => (
                <TextField
                  {...params}
                  variant="outlined"
                  label="Sections Only Read"
                  placeholder="Select sections"
                />
              )}
            />
          </Grid>
          <Grid item sm={6} xs={12}>
            <TextField
              disabled={!userCanEdit}
              fullWidth
              label="Default Value"
              name="defaultValue"
              onChange={(evt: ChangeEvent<HTMLInputElement>) => handleChange(evt, true)}
              onBlur={handleBlur}
              value={localInputDefinition.defaultValue || ''}
              variant="outlined"
            />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default CustomInputTextDefinition;
