import React from 'react';
import { Box, CircularProgress, Grid } from '@mui/material';

type HiderProps = {
  showContent: boolean;
  showLoading?: boolean;
  children: React.ReactNode;
};

const Hider = ({ showContent, showLoading = false, children }: HiderProps) => (
  <>
    {showLoading && !showContent && (
      <Grid item xs={12}>
        <Box display="flex" justifyContent="center" alignItems="center">
          <CircularProgress color="inherit" />
        </Box>
      </Grid>
    )}

    {showContent && children}
  </>
);

export default Hider;
