import { createSlice } from '@reduxjs/toolkit';
import { getRequestsBySearch, updateRequest } from './actions';
import { TFormStatus } from 'lg-helpers/dist/shared/types/TFormStatus';
import { IRequest } from 'lg-helpers/dist/shared/interfaces/IRequest';

interface IRequestState {
  data: IRequest[];
  status: TFormStatus;
  error: string | undefined;
}

const initialState: IRequestState = {
  data: [],
  status: null,
  error: undefined,
};

export const requestSlice = createSlice({
  name: 'requests',
  reducers: {},
  initialState,
  extraReducers: builder => {
    builder.addCase(getRequestsBySearch.fulfilled, (state, { payload }) => {
      state.status = 'success';
      state.data = payload!;
    });
    builder.addCase(updateRequest.fulfilled, (state, { payload }) => {
      state.status = 'success';
      const index = state.data.findIndex(a => a.id === payload?.id);
      if (index !== -1) {
        state.data[index] = payload as IRequest;
      }
    });
  },
});
