import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import InputSearch from 'sharedComponents/InputSearch';
import {
  Button,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  CircularProgress,
  Box,
  Typography,
  Grid,
  Link,
  TextField,
  Autocomplete,
} from '@mui/material';
import { debounce } from 'lodash-es';
import useShowError from 'modules/errors';
import { useHasAccess, useHasPerms, usePermissionsContext } from 'services/permissions';
import CompaniesApi from 'services/postgres/CompaniesApi';

const companiesApi = new CompaniesApi();

const CrudTableToolbar = ({
  searchFilter,
  archiveFilter,
  selectedRowsIds,
  setSelectedRowsIdsCallback,
  handleClickAddCallback,
  handleClickDeleteCallback,
  setSearchFilterCallback,
  handleClickArchivedCallback,
  title,
  regions,
  scales,
  states,
  selectedRegion,
  selectedScales,
  selectedState,
  handleSelectRegion,
  handleSelectScales,
  handleSelectState,
  showRegionSelection,
  showScaleSelection,
  showStateSelection,
  showReportButton,
  companies,
  selectedCompany,
  handleSelectCompany,
  showCompanySelection,
  showBatchedManifestButton,
}) => {
  const navigate = useNavigate();
  const { currentRole } = usePermissionsContext();
  const [isLoadingDelete, setIsLoadingDelete] = useState(false);
  const [isLoadingAdd, setIsLoadingAdd] = useState(false);

  const showError = useShowError();
  const [isOpenDeletePopup, setIsOpenDeletePopup] = useState(false);

  // Transporter admin can't create batches, but should be able to see them
  const batchedManifestButtonVisible =
    (useHasPerms(['batched_manifests:create']) || currentRole === 'transporter_admin') &&
    useHasAccess([
      async u => {
        if (u.role === 'super_admin' || u.role === 'customer_admin') return true;
        const companyRes = await companiesApi.getById(u.companies[0].id || '');
        return !!companyRes?.allowMultipleManifests;
      },
    ])?.hasAccess &&
    showBatchedManifestButton;

  const handleClickOpen = () => {
    setIsOpenDeletePopup(true);
  };

  const handleClose = () => {
    setIsOpenDeletePopup(false);
  };

  const handleAdd = async () => {
    setIsLoadingAdd(true);
    try {
      await handleClickAddCallback();
    } catch (err) {
      console.error(err);
      showError({ title: 'Add error' });
    }
    setIsLoadingAdd(false);
  };

  const handleDelete = async () => {
    setIsLoadingDelete(true);

    try {
      if (handleClickDeleteCallback) {
        await handleClickDeleteCallback(selectedRowsIds);
      }

      if (setSelectedRowsIdsCallback) {
        setSelectedRowsIdsCallback([]);
      }
      handleClose();
    } catch (err) {
      showError({ title: 'Delete error' });
    }
    setIsLoadingDelete(false);
  };

  const onSearchFilterChange = e => {
    debounce(setSearchFilterCallback, 16)(e.target.value);
    if (setSelectedRowsIdsCallback) {
      setSelectedRowsIdsCallback([]);
    }
  };

  return (
    <>
      <Grid container spacing={2} display="flex" alignItems="center" marginBottom={2}>
        {setSearchFilterCallback && (
          <Grid item sm={4} xs={12}>
            <InputSearch
              marginRight={10}
              placeholder="Search"
              value={searchFilter}
              onChange={onSearchFilterChange}
            />
          </Grid>
        )}

        {showCompanySelection && (
          <Grid item sm={3} xs={4}>
            <Autocomplete
              disabled={!companies || companies.length < 2}
              options={companies || []}
              getOptionLabel={option => option.name || ''}
              value={selectedCompany}
              onChange={(event, value) => {
                if (value) {
                  handleSelectCompany(value);
                } else {
                  handleSelectCompany({});
                }
              }}
              renderInput={params => (
                <TextField
                  {...params}
                  label="Company"
                  variant="outlined"
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: <>{params.InputProps.endAdornment}</>,
                  }}
                />
              )}
            />
          </Grid>
        )}

        {showRegionSelection && (
          <Grid item sm={3} xs={4}>
            <Autocomplete
              disabled={!regions || regions.length < 2}
              options={regions || []}
              getOptionLabel={option => option.name || ''}
              value={selectedRegion}
              isOptionEqualToValue={(option, value) => option.id === value.id}
              onChange={(event, value) => {
                if (value) {
                  handleSelectRegion(value);
                } else {
                  handleSelectRegion({});
                  handleSelectScales([]);
                }
              }}
              renderOption={(props, option) => {
                return (
                  <li {...props} key={option.id}>
                    {option.name}
                  </li>
                );
              }}
              renderInput={params => (
                <TextField
                  {...params}
                  label="Region"
                  variant="outlined"
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: <>{params.InputProps.endAdornment}</>,
                  }}
                />
              )}
            />
          </Grid>
        )}

        {showScaleSelection && (
          <Grid item sm={5} xs={8}>
            <Autocomplete
              multiple
              disabled={!scales || scales.length < 2}
              options={scales || []}
              getOptionLabel={option => option.name}
              isOptionEqualToValue={(option, value) => option.id === value.id}
              value={selectedScales}
              onChange={(event, value) => handleSelectScales(value)}
              renderInput={params => (
                <TextField
                  {...params}
                  label="Scale"
                  variant="outlined"
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: <>{params.InputProps.endAdornment}</>,
                  }}
                />
              )}
            />
          </Grid>
        )}

        {showStateSelection && (
          <Grid item sm={3} xs={12}>
            <Autocomplete
              disabled={!states || states.length < 2}
              options={states || []}
              getOptionLabel={option => option.name || ''}
              value={selectedState}
              onChange={(event, value) => {
                if (value) {
                  handleSelectState(value);
                } else {
                  handleSelectState({});
                }
              }}
              renderInput={params => (
                <TextField
                  {...params}
                  label="State"
                  variant="outlined"
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: <>{params.InputProps.endAdornment}</>,
                  }}
                />
              )}
            />
          </Grid>
        )}

        <Grid item display="flex" alignItems="center">
          {handleClickAddCallback && (
            <Box marginRight={1}>
              <Button
                variant="contained"
                color="primary"
                onClick={handleAdd}
                disabled={isLoadingAdd}
                data-intercom-target="Add Button"
              >
                <Typography variant="button">Add</Typography>
                {isLoadingAdd && (
                  <Box marginLeft={2}>
                    <CircularProgress size="15px" color="inherit" />
                  </Box>
                )}
              </Button>
            </Box>
          )}
          {handleClickArchivedCallback && (
            <Box marginRight={1}>
              <Button
                color="primary"
                variant={!archiveFilter ? 'outlined' : 'contained'}
                onClick={() => handleClickArchivedCallback()}
                data-intercom-target="Archive Button"
              >
                <Typography variant="button">
                  Show {!archiveFilter ? 'Archived' : 'Active'}
                </Typography>
              </Button>
            </Box>
          )}
          {showReportButton && (
            <Box marginRight={1}>
              <Link
                height="100%"
                component={Button}
                color="primary"
                variant="outlined"
                data-intercom-target="Report Button"
                onClick={() => navigate('/company/reports')}
                underline="none"
              >
                <Typography variant="button">
                  {currentRole === 'scale_admin' ? 'Regional' : 'Company'} Report
                </Typography>
              </Link>
            </Box>
          )}
          {batchedManifestButtonVisible && (
            <Box marginRight={1}>
              <Link
                height="100%"
                component={Button}
                color="primary"
                variant="outlined"
                data-intercom-target="Report Button"
                onClick={() => navigate('/batched-manifests')}
                underline="none"
              >
                <Typography variant="button">Batched Manifests</Typography>
              </Link>
            </Box>
          )}
          {setSelectedRowsIdsCallback && selectedRowsIds && handleClickDeleteCallback && (
            <>
              <Button
                disabled={!selectedRowsIds.length}
                variant="outlined"
                color="error"
                onClick={handleClickOpen}
                data-intercom-target="Delete Button"
              >
                Delete
              </Button>
              <Dialog open={isOpenDeletePopup} onClose={handleClose}>
                <DialogTitle>
                  Confirm delete {selectedRowsIds.length} {title ? title.toLowerCase() : 'items'}
                </DialogTitle>
                <DialogContent>Are you sure?</DialogContent>
                <DialogActions>
                  <Box
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                    width={'100%'}
                  >
                    <Button
                      onClick={handleDelete}
                      disabled={isLoadingDelete}
                      variant="contained"
                      color="primary"
                    >
                      <Typography variant="button">Yes</Typography>
                      {isLoadingDelete && (
                        <Box marginLeft={2}>
                          <CircularProgress size="15px" color="inherit" />
                        </Box>
                      )}
                    </Button>
                    <Button
                      autoFocus
                      onClick={handleClose}
                      color="inherit"
                      variant="text"
                      disabled={isLoadingDelete}
                    >
                      <Typography variant="button">Cancel</Typography>
                    </Button>
                  </Box>
                </DialogActions>
              </Dialog>
            </>
          )}
        </Grid>
      </Grid>
    </>
  );
};

CrudTableToolbar.propTypes = {
  searchFilter: PropTypes.string,
  archiveFilter: PropTypes.bool,
  title: PropTypes.string,
  handleClickAddCallback: PropTypes.func,
  handleClickArchivedCallback: PropTypes.func,
  selectedRowsIds: PropTypes.array,
  setSelectedRowsIdsCallback: PropTypes.func,
  setSearchFilterCallback: PropTypes.func,
  handleClickDeleteCallback: PropTypes.func,
  handleFilterArchivedCallback: PropTypes.func,
  regions: PropTypes.array,
  scales: PropTypes.array,
  states: PropTypes.array,
  companies: PropTypes.array,
  selectedRegion: PropTypes.object,
  selectedScales: PropTypes.array,
  selectedState: PropTypes.object,
  selectedCompany: PropTypes.object,
  handleSelectRegion: PropTypes.func,
  handleSelectScales: PropTypes.func,
  handleSelectState: PropTypes.func,
  handleSelectCompany: PropTypes.func,
  showRegionSelection: PropTypes.bool,
  showScaleSelection: PropTypes.bool,
  showStateSelection: PropTypes.bool,
  showCompanySelection: PropTypes.bool,
  showReportButton: PropTypes.bool,
  showBatchedManifestButton: PropTypes.bool,
};

export default CrudTableToolbar;
