import { ITicket } from 'lg-helpers/dist/shared/interfaces/ITicket';
import { IInvoice } from 'lg-helpers/dist/shared/interfaces/IInvoice';
import React, { Key, useContext, useState } from 'react';
import { useSelector } from 'react-redux';
import { selectAuthUser } from '../../../../auth/storeSliceAuth';
import { ITicketForm } from 'lg-helpers/dist/shared/interfaces/ITicketForm';
import { mixPanel } from '../../../../../services/mixpanel';
import { thunkDispatch } from '../../../../../store/store';
import { updateTicket } from '../../Tickets/redux/actions';
import { IconButton, SvgIcon, Tooltip } from '@mui/material';
import { Check, CheckCircle, Edit, WarningOutlined } from '@mui/icons-material';
import AddTicketModal from '../../Tickets/AddTicketModal';
import { ModalContext } from 'sharedComponents/ModalContext';

interface IAccountingButtonsProps {
  ticket: ITicket;
  invoice?: IInvoice;
  isTransporter?: boolean;
  isPrinting?: boolean;
}

type ticketStatus = 'reconciled' | 'issue';

const addTruckingInvoice = (ticket: ITicket, invoice: IInvoice, status: ticketStatus | null) => {
  ticket.transporterInvoiceStatus = status;
  ticket.transporterInvoiceId = status === null && !ticket.truckingTicketId ? null : invoice.id;
  return ticket;
};

const addSourceInvoice = (ticket: ITicket, invoice: IInvoice, status: ticketStatus | null) => {
  ticket.supplierInvoiceStatus = status;
  ticket.sourceSupplierInvoiceId = status === null && !ticket.truckingTicketId ? null : invoice.id;
  return ticket;
};

export const AccountingButtons = ({
  ticket,
  invoice,
  isPrinting,
  isTransporter,
}: IAccountingButtonsProps) => {
  const [reload, setReload] = useState<Key>(1);
  const authUser = useSelector(selectAuthUser);
  const { handleModal } = useContext(ModalContext);

  const updateStatus = async (status: ticketStatus | null) => {
    // WTF: is this dicard pattern????

    // TODO: We really really really need to reconsider how ITicket is consumed in the different applications
    // only some of the fields are available in ITIcket, and others are available in the database with no overlap
    // we need to discard some fields before we can even look at the ticket as ITicketForm
    const { createdByType, estimatedArrivalTime, ...ticketFields } = ticket as any; // eslint-disable-line

    const {
      manifestSearchValue, // eslint-disable-line
      transporterInvoiceNumber, // eslint-disable-line
      supplierInvoiceNumber, // eslint-disable-line
      addressName, // eslint-disable-line
      projectName, // eslint-disable-line
      pmMaterialId, // eslint-disable-line
      hasLocations, // eslint-disable-line
      locationString, // eslint-disable-line
      quantityWithUnit, // eslint-disable-line
      truck, // eslint-disable-line
      ...newTicketData
    } = ticketFields as ITicketForm;

    if (status === 'reconciled') {
      mixPanel(authUser, 'Ticket Reconciled', {
        'Source Supplier': ticket?.sourceSupplier || '',
        'Project ID': ticket?.projectId || '',
        Activity: ticket?.activity || '',
        'Transporter Company': ticket?.transporter || '',
        'Transporter Invoice Number': String(ticket?.transporterInvoiceNumber || ''),
        'Transporter Invoice ID': String(ticket?.transporterInvoiceId || ''),
      });
    }

    const updateTicketData: ITicket = isTransporter
      ? addTruckingInvoice(newTicketData, invoice!, status)
      : addSourceInvoice(newTicketData, invoice!, status);
    await thunkDispatch(updateTicket(updateTicketData));
  };

  const showStatus = (status: string, t: ITicket) => {
    if (invoice?.isTransporter) {
      if (t.transporterInvoiceStatus === status && status === 'rejected') return true;
      return t.transporterInvoiceStatus === status && t.transporterInvoiceId === invoice.id;
    } else if (invoice?.isTransporter === false) {
      return t.supplierInvoiceStatus === status && t.sourceSupplierInvoiceId === invoice.id;
    }
    setReload(new Date().getTime());
  };

  const handleClick = (value: ITicketForm) => {
    handleModal(<AddTicketModal selected={value} />);
  };

  const showIssueCharmColor = showStatus('issue', ticket);
  const showRejectedCharmColor = showStatus('rejected', ticket);
  const showReconCharmColor = showStatus('reconciled', ticket);
  return (
    <div key={reload}>
      <Tooltip title={showReconCharmColor ? 'Unreconcile' : 'Reconile'}>
        <IconButton
          onClick={() => {
            if (
              (invoice?.isTransporter && ticket.transporterInvoiceStatus === 'reconciled') ||
              (!invoice?.isTransporter && ticket.supplierInvoiceStatus === 'reconciled')
            ) {
              updateStatus(null);
            } else {
              updateStatus('reconciled');
            }
          }}
          disabled={!ticket.projectTaskId || !invoice || isPrinting}
        >
          <SvgIcon sx={{ color: showReconCharmColor ? 'primary.main' : 'default' }}>
            {showReconCharmColor ? <CheckCircle /> : <Check />}
          </SvgIcon>
        </IconButton>
      </Tooltip>
      <Tooltip
        title={showRejectedCharmColor ? 'Rejected' : `${showIssueCharmColor ? 'Has ' : ''}Issue`}
      >
        <IconButton
          onClick={() => {
            if (
              (invoice?.isTransporter && ticket.transporterInvoiceStatus === 'issue') ||
              (!invoice?.isTransporter && ticket.supplierInvoiceStatus === 'issue')
            ) {
              updateStatus(null);
            } else {
              updateStatus('issue');
            }
          }}
          disabled={!ticket.projectTaskId || !invoice || isPrinting}
        >
          <SvgIcon
            sx={{
              color: showIssueCharmColor || showRejectedCharmColor ? 'error.main' : 'default',
            }}
          >
            <WarningOutlined />
          </SvgIcon>
        </IconButton>
      </Tooltip>
      {!ticket.projectTaskId && (
        <Tooltip title={showRejectedCharmColor ? 'Edit Rejected Ticket' : 'Complete Ticket'}>
          <IconButton onClick={() => handleClick(ticket as ITicketForm)} disabled={isPrinting}>
            <SvgIcon sx={{ fontSize: 24 }}>
              <Edit />
            </SvgIcon>
          </IconButton>
        </Tooltip>
      )}
    </div>
  );
};
