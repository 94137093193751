import React from 'react';
import { Grid, List, ListItem, ListItemText, Box, Typography } from '@mui/material';

interface LogsDisplayProps {
  logs: string[];
}

const LogsDisplay = ({ logs }: LogsDisplayProps) => {
  // const refLastListItem = useRef(null);

  // useEffect(() => {
  //   if(logs?.length) {
  //     // todo: for unit testing this complains that `scrollIntoView()` is not a function
  //     // consider figuring out solution for this to keep it from complaining in console
  //     refLastListItem.current.scrollIntoView({ behavior: 'smooth' });
  //   }
  // }, [logs]);

  return (
    <Grid container>
      <Grid item xs={12}>
        <Box display="flex">
          <Typography variant="h5">Logs</Typography>
        </Box>
      </Grid>
      <Grid
        item
        xs={12}
        style={{
          maxHeight: '300px',
          overflow: 'auto',
          background: 'black',
          color: 'white',
        }}
      >
        {logs?.length ? (
          <List dense={true}>
            {(logs || []).map((log, index) => (
              <ListItem key={index} role="LogsDisplay--items">
                <ListItemText primary={log} secondary={null} />
              </ListItem>
            ))}
            {/*<ListItem ref={refLastListItem} />*/}
          </List>
        ) : (
          <List dense={true}>
            <ListItem>
              <ListItemText
                secondary={null}
                role="LogsDisplay--none-found"
                primary="No log entries found..."
              />
            </ListItem>
          </List>
        )}
      </Grid>
    </Grid>
  );
};

export default LogsDisplay;
