import { object, string } from 'yup';
import { IRequest } from 'lg-helpers/dist/shared/interfaces/IRequest';
const requiredMessage = 'Required';

export const requestSchema = object<IRequest>({
  targetCompanyId: string().required(requiredMessage),
  quantity: string().required(requiredMessage),
  unit: string().required(requiredMessage),
  requestedDate: string().required(requiredMessage),
  notes: string().required(requiredMessage),
  type: string().required(requiredMessage),
  status: string().required(requiredMessage),
});
