import React, { useEffect, useState, useContext, useCallback, createRef } from 'react';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import { thunkDispatch } from 'store/store';
import { TicketsToolbar } from './components/Toolbar';
import { TicketsList, TicketsListRef } from './components/TicketsList';
import {
  getCreatorTicketsByCompaniesIds,
  downloadTicketsByCompanyId,
  getContractorTicketsByRelatedCompanyId,
} from 'modules/civilAndTrucking/trucking/features/Tickets/redux/actions';
import { UpsertDispatchForm } from 'modules/civilAndTrucking/trucking/features/Dispatches/views/DispatchesView/components/UpsertDispatchForm';
import {
  getJoinedDispatchesByCompaniesIds,
  getJoinedDispatchesByContractorCompanyIds,
} from '../../../Dispatches/redux/actions';
import { IJoinTruckingDispatch } from 'lg-helpers/dist/trucking/interfaces/IJoinTruckingDispatch';
import { ModalContext } from 'sharedComponents/ModalContext';
import { toDateStr } from 'modules/civilAndTrucking/trucking/utils';
import { TicketNotesModal } from './components/TruckingTicketNotes/TicketNotesModal';
import DownloadDropdown from 'sharedComponents/DownloadDropdown';
import { constants } from 'lg-helpers';
import { ExportTicketsModal } from './components/ExportTicketsModal/ExportTicketsModal';
import { selectAuthUser } from 'modules/auth/storeSliceAuth';
import { useSelector } from 'react-redux';
import { ImportDataModal } from './components/ImportDataModal/ImportDataModal';
import { ViewerAccessCompany } from 'lg-helpers/dist/shared/interfaces/IUser';

const {
  pubSub: { DownloadType },
} = constants;

export const TicketsView = () => {
  const authUser = useSelector(selectAuthUser);
  const { handleModal } = useContext(ModalContext);
  const [date, setDate] = useState(toDateStr(new Date()));
  const contractorOptions = authUser?.viewerAccessCompanies?.map(
    (accessCompany: ViewerAccessCompany) => accessCompany.inviterCompanyAccessCompany
  );
  const [filteredContractorCompanyIds, setFilteredContractorCompanyIds] = useState<string[]>(
    contractorOptions?.map((contractorOption: any) => contractorOption.id)
  );
  const [searchFilter, setSearchFilter] = useState<any>('');
  const ticketsListRef = createRef<TicketsListRef>();

  const refreshTickets = useCallback(() => {
    if (authUser?.actingAsType === 'Trucking Viewer') {
      thunkDispatch(
        getJoinedDispatchesByContractorCompanyIds({
          date,
          companiesIds: filteredContractorCompanyIds,
        })
      );
      thunkDispatch(
        getContractorTicketsByRelatedCompanyId({ date, companiesIds: filteredContractorCompanyIds })
      );
    } else {
      thunkDispatch(
        getJoinedDispatchesByCompaniesIds({ date, companiesIds: authUser.companiesIds })
      );
      thunkDispatch(getCreatorTicketsByCompaniesIds({ date, companiesIds: authUser.companiesIds }));
    }
  }, [date, filteredContractorCompanyIds, authUser]);

  useEffect(() => {
    refreshTickets();
  }, [refreshTickets]);

  const downloadTickets = useCallback(() => {
    if (authUser?.actingAsType === 'Trucking Viewer') {
      thunkDispatch(downloadTicketsByCompanyId({ date, companiesIds: authUser.companiesIds }));
    }
    thunkDispatch(downloadTicketsByCompanyId({ date, companiesIds: authUser.companiesIds }));
    // eslint-disable-next-line
  }, [date, authUser]);

  const openUpsertDispatchModal = (joinedDispatch: IJoinTruckingDispatch) => {
    handleModal(<UpsertDispatchForm editObj={joinedDispatch} />);
  };

  const openTicketNotesModal = (ticketId: number) => {
    handleModal(<TicketNotesModal ticketId={ticketId} />);
  };

  const handleOpenExportModal = () => {
    handleModal(<ExportTicketsModal companiesIds={authUser.companiesIds} />);
  };

  const handleOpenImportModal = () => {
    handleModal(<ImportDataModal />);
  };

  return (
    <>
      <Grid container>
        <Grid item xs={9} display="flex" alignItems="center">
          <Typography variant="h5" sx={{ mb: 1 }}>
            Tickets
          </Typography>
        </Grid>
        <Grid item xs={3}>
          <DownloadDropdown downloadType={DownloadType.TICKETS_TRUCKING()} />
        </Grid>
      </Grid>
      <TicketsToolbar
        handleOpenExport={handleOpenExportModal}
        date={date}
        setDate={setDate}
        refreshTickets={refreshTickets}
        downloadTickets={downloadTickets}
        searchFilter={searchFilter}
        setSearchFilter={setSearchFilter}
        handleOpenImportModal={handleOpenImportModal}
        filteredContractorCompanyIds={filteredContractorCompanyIds}
        contractorsOptions={contractorOptions}
        setFilteredContractorCompanyIds={setFilteredContractorCompanyIds}
      />
      <TicketsList
        ref={ticketsListRef}
        openUpsertDispatchModal={openUpsertDispatchModal}
        openTicketNotesModal={openTicketNotesModal}
        searchFilter={searchFilter}
      />
    </>
  );
};
