import React from 'react';
import './Dispatch.scss';
import { Grid, Typography, Button } from '@mui/material';
import useIsUserOfType from 'services/hooks/useIsUserOfType';
import { BrokerDispatch } from 'services/hooks/useGetDispatches';

export interface DispatchProps {
  dispatch: BrokerDispatch;
  onClickCallback: (dispatch: BrokerDispatch) => any;
  isSelected?: boolean;
  truckNumber: string;
}

const Dispatch = ({ dispatch, onClickCallback, isSelected, truckNumber }: DispatchProps) => {
  const isUserBrokerAdmin = useIsUserOfType('trucking_dispatcher');

  const { aggregatedData = {} } = dispatch;

  return (
    <Button
      className={`Dispatch ${isSelected ? 'selected' : ''}`}
      disabled={
        isUserBrokerAdmin &&
        (aggregatedData.countLoadsTotal || 0) <= (aggregatedData.countLoadsLoaded || 0)
      }
      onClick={() => onClickCallback(dispatch)}
      variant="contained"
    >
      <Grid container spacing={1}>
        <Grid item xs={3}>
          <Typography variant="body1" align="right">
            Contractor:
          </Typography>
        </Grid>
        <Grid item xs={9}>
          <Typography variant="body1" align="left">
            &nbsp;{dispatch.contractorName}
          </Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography variant="body1" align="right">
            Project:
          </Typography>
        </Grid>
        <Grid item xs={9}>
          <Typography variant="body1" align="left">
            {dispatch.projectName}
          </Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography variant="body1" align="right">
            Material:
          </Typography>
        </Grid>
        <Grid item xs={9}>
          <Typography variant="body1" align="left">
            {dispatch.material}
          </Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography variant="body1" align="right">
            Source:
          </Typography>
        </Grid>
        <Grid item xs={9}>
          <Typography variant="body1" align="left">
            {dispatch.source}
          </Typography>
        </Grid>
        {dispatch.specialInstr && (
          <>
            <Grid item xs={3}>
              <Typography variant="body1" align="right">
                Sp. instr.:
              </Typography>
            </Grid>
            <Grid item xs={9}>
              <Typography variant="body1" align="left">
                {dispatch.specialInstr}
              </Typography>
            </Grid>
          </>
        )}

        {isUserBrokerAdmin ? (
          <>
            <Grid item xs={12}>
              <Typography variant="body1" align="right">
                <b>LOADS</b>
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="body1" align="right">
                <b>Available:&nbsp;</b>
                {(dispatch.aggregatedData.countLoadsTotal || 0) -
                  (dispatch.aggregatedData.countLoadsLoaded || 0)}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="body1" align="right">
                Total:&nbsp;
                {dispatch.aggregatedData.totalByTruckNumber[truckNumber] || 0}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="body1" align="right">
                Loaded:&nbsp;
                {dispatch.aggregatedData.loadedByTruckNumber[truckNumber] || 0}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="body1" align="right">
                Dumped:&nbsp;
                {dispatch.aggregatedData.dumpedByTruckNumber[truckNumber] || 0}
              </Typography>
            </Grid>
          </>
        ) : (
          <>
            <Grid item xs={12}>
              <Typography variant="body1" align="right">
                Loads available:&nbsp;
                <b>
                  {(dispatch.aggregatedData.totalByTruckNumber[truckNumber] || 0) -
                    (dispatch.aggregatedData.loadedByTruckNumber[truckNumber] || 0)}
                </b>
              </Typography>
            </Grid>
          </>
        )}
      </Grid>
    </Button>
  );
};

export default Dispatch;
