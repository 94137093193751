import React, { useState, useEffect } from 'react';
import { TextField, Box, CircularProgress, Autocomplete } from '@mui/material';
import { useSelector } from 'react-redux';
import { selectAuthUser } from 'modules/auth/storeSliceAuth';
import useIsUserOfType from 'services/hooks/useIsUserOfType';
import Query from 'services/firebase/Query';
import Command from 'services/firebase/Command';
import useShowError from 'modules/errors';
import { Load } from 'services/hooks/useGetDriverCurrentLoad';
import QueryBase from 'lg-helpers/dist/firestore/query/QueryBase';

const command = new Command();

export interface AssignTruckNumberProps {
  load: Load;
  truckNumbers: string[];
  isLoadingTruckNumbers: boolean;
}

const AssignTruckNumber = ({
  load,
  truckNumbers,
  isLoadingTruckNumbers,
}: AssignTruckNumberProps) => {
  const authUser = useSelector(selectAuthUser);
  const showError = useShowError();
  const isUserBrokerAdmin = useIsUserOfType('trucking_dispatcher');
  const [assignedTruckNumber, setAssignedTruckNumber] = useState<string | null>(null);

  const canEdit =
    !!load && !(load?.signatureDriver?.isSignedLoaded || load?.signatureDriver?.isSignedDumped);

  useEffect(() => {
    if (!load) {
      return;
    }

    const { assignedTruckNumber: assignedTruckNumberValue } = load;
    setAssignedTruckNumber(
      assignedTruckNumberValue === QueryBase.NULL_TRUCK_NUMBER() ? null : assignedTruckNumberValue
    );
  }, [load]);

  const saveLoadAssignedTruckNumber = async () => {
    try {
      await command.brokers.brokerSetLoadAssignedTruckNumber(authUser, {
        loadId: load.id,
        dispatchID: load.dispatchID,
        assignedTruckNumber,
      });
    } catch (err) {
      console.error(err);
      showError({ title: 'Error saving assigned truck number' });
    }
  };

  return (
    <>
      {isUserBrokerAdmin && (
        <>
          {canEdit ? (
            <Box marginBottom={1} style={{ width: '160px' }}>
              <Autocomplete
                fullWidth
                freeSolo
                disabled={!truckNumbers.length}
                options={truckNumbers || []}
                value={assignedTruckNumber}
                onChange={(event, value) => setAssignedTruckNumber(value)}
                onInputChange={(event, value) => setAssignedTruckNumber(value)}
                isOptionEqualToValue={(option, value) => option === value}
                renderInput={params => (
                  <TextField
                    {...params}
                    label="Assigned truck #"
                    onBlur={saveLoadAssignedTruckNumber}
                    variant="outlined"
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <>
                          {isLoadingTruckNumbers && <CircularProgress color="inherit" size={20} />}
                          {params.InputProps.endAdornment}
                        </>
                      ),
                    }}
                  />
                )}
              />
            </Box>
          ) : (
            assignedTruckNumber || '-'
          )}
        </>
      )}
    </>
  );
};

export default AssignTruckNumber;
