import { createAsyncThunk } from '@reduxjs/toolkit';
import { INotification } from 'modules/civilAndTrucking/shared/NotificationSnackbar/INotification';
import { pushNotification } from 'modules/civilAndTrucking/shared/NotificationSnackbar/redux/actions';
import { post, get, del } from 'services/api/restHelpers';
import { RootState } from 'store/store';
import { IAddress, Point } from 'services/mapbox/geocoding';
import {
  IProjectCompanyCollaborator,
  IProjectUserCollaborator,
} from 'lg-helpers/dist/shared/interfaces/IProjectCollaborator';
import { IProjectTemplate } from 'lg-helpers/dist/shared/interfaces/IProjectTemplate';
import { IProjectTemplateDetail } from 'lg-helpers/dist/shared/interfaces/IProjectTemplateDetail';
import { ILocation } from 'lg-helpers/dist/shared/interfaces/ILocation';
import { IProject } from 'lg-helpers/dist/shared/interfaces/IProject';

export const setActiveProject = createAsyncThunk(
  'projects/get-active',
  async (projectId: number) => {
    // todo - make this smarter. We should be able to check to see if we have this project's data already. If so, use that instead of getting it again.
    const data = await get(`civil/projects/${projectId}`);
    return data as IProject;
  }
);

export const addProject = createAsyncThunk('projects/add', async (payload: IProject, thunkApi) => {
  try {
    const state = thunkApi.getState() as RootState;
    const companyId = state.civil.companies.activeCompany?.id;

    const { isArchived, ...correctedPayload } = payload;
    const project = await post('civil/projects', { ...correctedPayload, companyId });
    const successNotification: INotification = {
      status: 'success',
      message: 'Successfully saved project',
    };
    thunkApi.dispatch(pushNotification(successNotification));

    return project as IProject;
  } catch (err) {
    console.error('error', err);
    const errorNotification: INotification = {
      status: 'error',
      message: 'Unable to save project',
    };
    thunkApi.dispatch(pushNotification(errorNotification));
    throw err;
  }
});

export const getProjectById = createAsyncThunk(
  'projects/get-by-id',
  async (projectId: number, thunkApi) => {
    try {
      const response = await get(`civil/projects/${projectId}`);
      return response as IProject;
    } catch (err) {
      const errorNotification: INotification = {
        status: 'error',
        message: 'Unable to retreive project data',
      };
      thunkApi.dispatch(pushNotification(errorNotification));
    }
  }
);

export const getProjects = createAsyncThunk('projects/get', async () => {
  const data = await get('civil/projects');
  return data;
});

export const getProjectTemplates = createAsyncThunk(
  'projects/templates/get',
  async (projectId: number | undefined, thunkApi) => {
    try {
      const state = thunkApi.getState() as RootState;
      const companyId = state.civil.companies.activeCompany?.id;
      const data = await get(`civil/projects/${projectId}/templates/${companyId}`);
      return data;
    } catch (err) {
      const errorNotification: INotification = {
        status: 'error',
        message: 'Unable to retreive project templates',
      };
      thunkApi.dispatch(pushNotification(errorNotification));
    }
  }
);

type ITemplateDetails = {
  template: IProjectTemplate;
  projectId: number | undefined;
};

export const getProjectTemplateDetails = createAsyncThunk(
  'projects/templates/details/get',
  async (payload: ITemplateDetails, thunkApi) => {
    try {
      const data = await get(
        `civil/projects/${payload.projectId}/templates/${payload.template.companyId}/details/${payload.template.id}`
      );
      return data;
    } catch (err) {
      const errorNotification: INotification = {
        status: 'error',
        message: 'Unable to retreive project template details',
      };
      thunkApi.dispatch(pushNotification(errorNotification));
    }
  }
);

type ILoadTemplateDetails = {
  details: IProjectTemplateDetail[];
  projectId: number | undefined;
};

export const loadProjectTemplate = createAsyncThunk(
  'projects/templates/load',
  async (payload: ILoadTemplateDetails, thunkApi) => {
    try {
      const state = thunkApi.getState() as RootState;
      const companyId = state.civil.companies.activeCompany?.id;
      const details = payload.details;
      await post(`civil/projects/${payload.projectId}/templates/${companyId}/details`, { details });
      const successNotification: INotification = {
        status: 'success',
        message: 'Successfully loaded project template',
      };
      thunkApi.dispatch(pushNotification(successNotification));
    } catch (err) {
      console.error('error', err);
      const errorNotification: INotification = {
        status: 'error',
        message: 'Unable to save project template',
      };
      thunkApi.dispatch(pushNotification(errorNotification));
      throw err;
    }
  }
);

export const addProjectTemplate = createAsyncThunk(
  'projects/templates/add',
  async (payload: IProjectTemplate, thunkApi) => {
    try {
      const project = await post(`civil/projects/${payload.projectId}/templates`, { ...payload });
      const successNotification: INotification = {
        status: 'success',
        message: 'Successfully saved project template',
      };
      thunkApi.dispatch(pushNotification(successNotification));

      return project as IProjectTemplate;
    } catch (err) {
      console.error('error', err);
      const errorNotification: INotification = {
        status: 'error',
        message: 'Unable to save project template',
      };
      thunkApi.dispatch(pushNotification(errorNotification));
      throw err;
    }
  }
);

export const getProjectCompanyCollaborators = createAsyncThunk(
  'projects/get-collaborators',
  async (payload: { projectId: number }, thunkApi) => { // eslint-disable-line
    try {
      const data = await get(`civil/projects/${payload.projectId}/collaborators`);
      return data as IProjectCompanyCollaborator[];
    } catch (err) {
      console.error('Error getting project collaborators: ', err);
    }
  }
);

export const addCompanyCollaborator = createAsyncThunk(
  'projects/add-collaborators',
  async (
    payload: {
      civilProjectId: number;
      createdBy: string;
      truckingCompanyId: string;
    },
    thunkApi
  ) => {
    const state = thunkApi.getState() as RootState;
    const companyId = state.civil.companies.activeCompany?.id;
    try {
      const data = await post(`civil/projects/${payload.civilProjectId}/collaborators`, {
        civilCompanyId: companyId,
        ...payload,
      });
      return data as IProjectCompanyCollaborator;
    } catch (err) {
      console.error('Error getting project collaborators: ', err);
    }
  }
);

export const removeCompanyCollaborator = createAsyncThunk(
  'projects/remove-collaborators',
  async (payload: { projectId: number; collaboratorId: number }, thunkApi) => { // eslint-disable-line
    try {
      const data = await del(
        `civil/projects/${payload.projectId}/collaborators/${payload.collaboratorId}`
      );
      return data;
    } catch (err) {
      console.error('Error getting project collaborators: ', err);
    }
  }
);

export const getLocations = createAsyncThunk(
  'projects/get-locations',
  async (payload: { projectId: number; search?: string }, thunkApi) => { // eslint-disable-line
    try {
      const data = await get(
        `civil/projects/${payload.projectId}/locations${
          payload.search ? '?search=' + payload.search : ''
        }`
      );
      return data as ILocation[];
    } catch (err) {
      console.error('Error getting locations: ', err);
    }
  }
);

export const getLocationById = createAsyncThunk(
  'projects/get-location-by-id',
  async (payload: { projectId: number; civilLocationId?: number }, thunkApi) => { // eslint-disable-line
    try {
      const data = await get(
        `civil/projects/${payload.projectId}/locations/${payload.civilLocationId}`
      );
      return data as ILocation;
    } catch (err) {
      console.error('Error getting locations: ', err);
    }
  }
);

export const addLocation = createAsyncThunk(
  'projects/add-locations',
  async (
    payload: {
      civilProjectId: number;
      address: string;
      geo: Point['coordinates'] | null;
      createdBy: string;
      name: string | undefined;
    },
    thunkApi // eslint-disable-line
  ) => {
    try {
      const data = await post(`civil/projects/${payload.civilProjectId}/locations`, payload);
      return data as ILocation;
    } catch (err) {
      console.error('Error adding locations: ', err);
    }
  }
);

export const updateLocation = createAsyncThunk(
  'projects/update-location',
  async (
    payload: {
      projectId: number;
      locationId: number;
      geo: Point['coordinates'];
      sender: string;
      name?: string;
    },
    thunkApi
  ) => {
    try {
      await post(`civil/projects/${payload.projectId}/locations/${payload.locationId}/update`, {
        geoData: payload.geo,
        name: payload.name,
      });
      return {
        sender: payload.sender,
        id: payload.locationId,
        geo: payload.geo,
        name: payload.name,
      };
    } catch (err) {
      console.error('Error updating location:', err);
      const errorNotification: INotification = {
        status: 'warning',
        message: 'Unable to update site details.',
      };
      thunkApi.dispatch(pushNotification(errorNotification));
    }
  }
);

export const removeLocation = createAsyncThunk(
  'projects/remove-locations',
  async (payload: { projectId: number; locationToProjectId: number }, thunkApi) => { // eslint-disable-line
    try {
      const result = await del(
        `civil/projects/${payload.projectId}/locations/${payload.locationToProjectId}`
      );
      return result?.data.id as number;
    } catch (err) {
      console.error('Error adding locations: ', err);
    }
  }
);

export const importSites = createAsyncThunk(
  'projects/import-sites',
  async (
    payload: {
      projectId: number;
      addresses: IAddress[];
      email: string;
      createdBy: string;
    },
    thunkApi
  ) => {
    const state = thunkApi.getState() as RootState;
    const companyId = state.civil.companies.activeCompany?.id;
    post(`civil/projects/${payload.projectId}/locations/import`, {
      ...payload,
      companyId: companyId,
    });
    const notification: INotification = {
      status: 'success',
      message: 'You will receive an email after import is complete.',
    };
    thunkApi.dispatch(pushNotification(notification));
  }
);

export const getProjectUsersCollaborators = createAsyncThunk(
  'projects/users',
  async (projectId: number, thunkApi) => { // eslint-disable-line
    try {
      const data = await get(`civil/projects/${projectId}/users`);
      return data;
    } catch (err) {
      console.error('Error getting users for project: ', err);
    }
  }
);

export const addUserCollaborator = createAsyncThunk(
  'projects/add-users',
  async (payload: IProjectUserCollaborator, thunkApi) => { // eslint-disable-line
    try {
      const data = await post(`civil/projects/${payload.civilProjectId}/users`, payload);
      return data;
    } catch (err) {
      console.error('Error getting users for project: ', err);
    }
  }
);

export const removeUserCollaborator = createAsyncThunk(
  'projects/remove-users',
  async (payload: { projectId: number; civilUsersToProjectId: number }, thunkApi) => { // eslint-disable-line
    try {
      const data = await del(
        `civil/projects/${payload.projectId}/users/${payload.civilUsersToProjectId}`
      );
      return data;
    } catch (err) {
      console.error('Error getting users for project: ', err);
    }
  }
);
