import { createAsyncThunk } from '@reduxjs/toolkit';
import { INotification } from 'modules/civilAndTrucking/shared/NotificationSnackbar/INotification';
import { pushNotification } from 'modules/civilAndTrucking/shared/NotificationSnackbar/redux/actions';
import ApiBase from 'services/postgres/ApiBase';
import Tables from 'services/postgres/Tables';
import { toDateStr } from 'modules/civilAndTrucking/trucking/utils';
import { RootState } from 'store/store';
import { pushError } from 'modules/errors/storeSliceErrors';
import { IJoinTruckingDispatch } from 'lg-helpers/dist/trucking/interfaces/IJoinTruckingDispatch';
import { ITruckingDispatch } from 'lg-helpers/dist/trucking/interfaces/ITruckingDispatch';
import { get } from 'services/api/restHelpers';

const apiBase = new ApiBase();
const DISPATCHES_TABLE = Tables.TruckingDispatch;

export const getAllJoinedDispatchesByCompanyId = createAsyncThunk(
  'dispatches/get-all-joined-dispatches',
  async (wtf, thunkApi) => {
    try {
      const data = await apiBase.getList(`${DISPATCHES_TABLE}/`);
      return data as IJoinTruckingDispatch[];
    } catch (err) {
      thunkApi.dispatch(pushError({ title: 'Error getting all dispatches' }));
      throw err;
    }
  }
);

export const getJoinedDispatchesByCompanyId = createAsyncThunk(
  'dispatches/get-dispatches-by-companyId',
  async (date: string, thunkApi) => {
    try {
      const data = await apiBase.getList(`${DISPATCHES_TABLE}/date/${date}`);
      return data as IJoinTruckingDispatch[];
    } catch (err) {
      thunkApi.dispatch(pushError({ title: 'Error getting dispatches' }));
      throw err;
    }
  }
);

export const getJoinedDispatchesByCompaniesIds = createAsyncThunk(
  'dispatches/get-dispatches-by-companies-ids',
  async (payload: { date: string; companiesIds: string[] }, thunkApi) => {
    try {
      const { date, companiesIds } = payload;
      const data = await apiBase.getList(
        `${DISPATCHES_TABLE}/date/${date}/companiesIds/${companiesIds}`
      );
      return data as IJoinTruckingDispatch[];
    } catch (err) {
      thunkApi.dispatch(pushError({ title: 'Error getting dispatches' }));
      throw err;
    }
  }
);

export const getJoinedDispatchesByContractorCompanyIds = createAsyncThunk(
  'dispatches/get-dispatches-by-contractor-company-id',
  async (payload: { date: string; companiesIds: string[] }, thunkApi) => {
    try {
      const { companiesIds, date } = payload;
      const data = await apiBase.getList(
        `${DISPATCHES_TABLE}/date/${date}/contractorCompaniesIds/${companiesIds}`
      );
      return data as IJoinTruckingDispatch[];
    } catch (err) {
      thunkApi.dispatch(pushError({ title: 'Error getting dispatches' }));
      throw err;
    }
  }
);

export const getJoinedBrokerDispatchesByCompanyId = createAsyncThunk(
  'dispatches/get-broker-dispatches-by-companyId',
  async (date: string, thunkApi) => {
    try {
      const data = await apiBase.getList(`${DISPATCHES_TABLE}/broker/date/${date}`);
      return data as IJoinTruckingDispatch[];
    } catch (err) {
      thunkApi.dispatch(pushError({ title: 'Error getting dispatches' }));
      throw err;
    }
  }
);

export const upsertDispatch = createAsyncThunk(
  'dispatches/upsert-dispatch',
  async (dispatch: ITruckingDispatch, thunkApi) => {
    const state = thunkApi.getState() as RootState;
    const companyId = state.civil.companies.activeCompany?.id;

    const dispatchReq = {
      ...dispatch,
      creatorCompanyIdTruckingDispatch: companyId,
    };

    try {
      await apiBase.insertOrUpdate(dispatchReq, Tables.TruckingDispatch);

      thunkApi.dispatch(getJoinedDispatchesByCompanyId(dispatch.dateTruckingDispatch!));
    } catch (err) {
      thunkApi.dispatch(pushError({ title: 'Error: Unable to create dispatch' }));
    }
  }
);

export const getJoinedDispatchByDispatchId = createAsyncThunk(
  'dispatch/getdispatch',
  async (dispatchId: number, thunkApi) => {
    try {
      const data = await get(`${DISPATCHES_TABLE}/dispatch/${dispatchId}`);
      return data as IJoinTruckingDispatch;
    } catch (err) {
      thunkApi.dispatch(pushError({ title: 'Error getting dispatch' }));
      throw err;
    }
  }
);

export const deleteDispatch = createAsyncThunk(
  'dispatch/delete-dispatch',
  async (dispatchId: number, thunkApi) => {
    try {
      await apiBase.deleteById(dispatchId, Tables.TruckingDispatch);
      const successNotification: INotification = {
        status: 'success',
        message: 'Success: Dispatch has been deleted',
      };
      thunkApi.dispatch(pushNotification(successNotification));
      thunkApi.dispatch(getJoinedDispatchesByCompanyId(toDateStr(new Date())));
    } catch (err: any) {
      thunkApi.dispatch(
        pushError({ title: err?.response?.data?.message || 'Error: Unable to delete truck type' })
      );
    }
  }
);
