import { BaseAPI } from './autogenerated-code/base';
import { Configuration } from './autogenerated-code/configuration';
import { auth } from 'services/firebase';
import axios, { AxiosRequestConfig } from 'axios';
import { addCacheItem, deleteCacheItem, getCacheItem } from 'utils/memoryCache';

const instance = axios.create({
  headers: {
    'Accept-Language': 'en-US',
    'Access-Control-Allow-Origin': '*',
    'Cache-Control': 'no-cache',
    'Content-type': 'application/json',
  },
});

instance.interceptors.request.use(async (config: AxiosRequestConfig) => {
  const token = await auth.currentUser?.getIdToken(true);

  if (token && !config.headers.Authorization) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
});

export interface MethodCacheConfig<M> {
  time?: number;
  invalidatedBy?: M[];
}

export type MethodsOf<T> = {
  [K in keyof T]: T[K] extends (...args: any[]) => any ? K : never;
}[keyof T];

export type ControllerCacheConfig<T extends typeof BaseAPI> = Partial<
  Record<MethodsOf<InstanceType<T>>, boolean | MethodCacheConfig<MethodsOf<InstanceType<T>>>>
>;

// TODO: impement garbage collection for keysByPrefix (it can grow with very slow speed because of permutations)
const keysByPrefix = new Map<string, Set<string>>();
const invalidatorsMap = new Map<string, Set<string>>();

const getPrefix = <T extends typeof BaseAPI>(Ctrl: T, methodName: MethodsOf<InstanceType<T>>) =>
  methodName.toString().indexOf('.') >= 0
    ? methodName.toString()
    : [Ctrl.name, methodName].join('.');

const requestsInProgress: Map<string, Promise<unknown>> = new Map();

const buildNewApiController = <T extends typeof BaseAPI>(
  Ctrl: T,
  baseUrl = process.env.REACT_APP_API_BASE_URL,
  cacheConfiguration?: ControllerCacheConfig<T>
) => {
  type MethodKey = MethodsOf<InstanceType<T>>;
  const controllerInstance = new Ctrl(new Configuration({}), baseUrl, instance) as InstanceType<T>;
  const invalidators: Set<MethodKey> = new Set();

  const allMethods = Object.getOwnPropertyNames(Object.getPrototypeOf(controllerInstance)).filter(
    m => m !== 'constructor'
  );
  for (const _methodName of allMethods) {
    const methodName = _methodName as MethodKey;
    const originalMethod = controllerInstance[methodName];

    (controllerInstance as any)[methodName] = async (...args: any[]) => {
      const requestKey = JSON.stringify([baseUrl, Ctrl.name, methodName, args]);
      try {
        if (requestsInProgress.has(requestKey)) return requestsInProgress.get(requestKey);
        const promise = (originalMethod as any).apply(controllerInstance, args);
        requestsInProgress.set(requestKey, promise);
        const res = await promise;
        return res;
      } catch (err) {
        throw err;
      } finally {
        requestsInProgress.delete(requestKey);
      }
    };
  }

  if (cacheConfiguration)
    for (const _methodName in cacheConfiguration) {
      const methodName = _methodName as MethodKey;
      const prefixKey = getPrefix(Ctrl, methodName);
      const _cacheConfig = cacheConfiguration[methodName]!;
      const cacheConfig =
        typeof _cacheConfig === 'boolean' ? {} : (_cacheConfig as MethodCacheConfig<MethodKey>);
      const originalMethod = controllerInstance[methodName];

      for (const invalidatorMethodName of cacheConfig.invalidatedBy || []) {
        const invalidatorPrefix: string = getPrefix(Ctrl, invalidatorMethodName); // Potentially we can implement cross-controller invalidators

        if (!invalidatorsMap.has(invalidatorPrefix)) {
          invalidatorsMap.set(invalidatorPrefix, new Set());
          invalidators.add(invalidatorMethodName);
        }
        const invalidatedSet = invalidatorsMap.get(invalidatorPrefix)!;
        invalidatedSet.add(prefixKey);
      }

      (controllerInstance as any)[methodName] = async (...args: any[]) => {
        const cacheKey = JSON.stringify([Ctrl.name, methodName, args]);

        // TODO: maybe this logic should be implemented on side of memoryCache
        if (!keysByPrefix.has(prefixKey)) keysByPrefix.set(prefixKey, new Set());
        const cacheKeysSet = keysByPrefix.get(prefixKey)!;
        cacheKeysSet.add(cacheKey);

        const fromCache = getCacheItem(cacheKey);
        if (fromCache) {
          (fromCache as any).fromCache = true;
          return fromCache;
        }

        const res = await (originalMethod as any).apply(controllerInstance, args);
        addCacheItem(cacheKey, res, cacheConfig.time);
        return res;
      };
    }

  for (const _methodName in invalidators) {
    const methodName = _methodName as MethodKey;
    const originalMethod = controllerInstance[methodName];
    (controllerInstance as any)[methodName] = async (...args: any[]) => {
      const res = await (originalMethod as any).apply(controllerInstance, args);

      const invalidatorPrefix = getPrefix(Ctrl, methodName as any);
      const cachePrefixes = invalidatorsMap.get(invalidatorPrefix);
      if (cachePrefixes)
        for (const prefix of cachePrefixes) {
          const cacheKeys = keysByPrefix.get(prefix);
          if (cacheKeys) for (const key of cacheKeys) deleteCacheItem(key);
        }

      return res;
    };
  }

  return controllerInstance;
};

export const registerController = <T extends typeof BaseAPI>(
  Ctrl: T,
  cacheConfiguration?: ControllerCacheConfig<T>
) => {
  const baseApi = buildNewApiController(Ctrl, undefined, cacheConfiguration);
  type Instance = typeof baseApi;
  (baseApi as any).staticApi = buildNewApiController(
    Ctrl,
    process.env.REACT_APP_STATIC_API_BASE_URL,
    cacheConfiguration
  );
  return baseApi as Instance & { staticApi: Instance };
};
