import React, { useCallback, useEffect } from 'react';
import { useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import { componentAllowedPerms } from 'services/permissions';
import Query from 'services/firebase/Query';
import Executer from 'services/firebase/Executer';
import { FormControl, InputLabel, MenuItem, Select, SelectChangeEvent } from '@mui/material';
import { Manifest } from 'lg-helpers/dist/shared/types/manifests';

const query = new Query();
const executer = new Executer();

export default componentAllowedPerms(['batched_manifests:read'], () => <p>Access Refused</p>)(
  function SelectBatchedManifests({ batchId = '' }) {
    const [batch, setBatch] = useState<Manifest[]>([]);
    let { id } = useParams();
    const navigate = useNavigate();

    useEffect(() => {
      let active = true;
      try {
        loadBatchManifests();
      } catch (error) {
        console.error(error);
      }
      return () => {
        active = false;
      };

      async function loadBatchManifests() {
        const batchManifests = (await executer.getMultipleDocuments(
          query.manifests.getManifestsInBatch(batchId)
        )) as Manifest[];
        if (!active) {
          return;
        }
        setBatch(batchManifests);
      }
    }, [batchId]);

    const goToManifestId = useCallback(
      (event: SelectChangeEvent) => navigate(`/manifests/${event.target.value}`),
      [navigate]
    );

    return (
      <FormControl fullWidth>
        <InputLabel id="demo-simple-select-label">Select Manifest in Batch</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={id}
          label="Select Manifest in Batch"
          onChange={goToManifestId}
        >
          {batch.map(manifest => (
            <MenuItem key={`batch_option_${manifest.id}`} value={manifest.id}>
              Manifest #: {manifest.number}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    );
  }
);
