import { createAsyncThunk } from '@reduxjs/toolkit';
import { INotification } from 'modules/civilAndTrucking/shared/NotificationSnackbar/INotification';
import { pushNotification } from 'modules/civilAndTrucking/shared/NotificationSnackbar/redux/actions';
import { IUser } from 'lg-helpers/dist/shared/interfaces/IUser';
import { sendActivateAccountEmail, deleteUser, sendSmsWithInitialPassword } from 'api/auth';
import api from 'services/api/autogenerated';
import { post, del } from 'services/api/restHelpers';

import { RootState } from 'store/store';
import { getLicenses } from 'modules/civilAndTrucking/civil/Licenses/redux/actions';
import { ILicense } from 'lg-helpers/dist/shared/interfaces/ILicense';

import Query from 'services/firebase/Query';
import Executer from 'services/firebase/Executer';

const query = new Query();
const executer = new Executer();

export const getActiveCompanyUsers = createAsyncThunk('users/get', async (payload, thunkApi) => {
  try {
    const state = thunkApi.getState() as RootState;
    const companyId = state.civil.companies.activeCompany?.id;
    if (companyId) {
      const result = await executer.getMultipleDocuments(query.users.getUsersInCompany(companyId));
      return result as IUser[];
    }
  } catch ({ message }) {
    console.error(`Could not get users: ${message}`);
  }
});

export const addUser = createAsyncThunk('users/add', async (userData: IUser, thunkApi) => {
  await thunkApi.dispatch(getLicenses());
  // const state = thunkApi.getState() as RootState;
  // const companyId = state.civil.companies.activeCompany?.id || userData.companiesIds[0];
  // const license: ILicense | undefined = state.civil.licenses.data;
  try {
    // if (license && license.licensesRemaining > 0) {
    // TODO: remove this 'any' (should be correct return type from API)
    const { data: result } = (await api.users.create(userData)) as any;
    if (result.id) {
      // TODO: why we call a separate method (we can do this on api side while creating new user)
      // await post(`civil/companies/${companyId}/licenses/${license.id}/add-user`, {
      //   userId: result.id,
      // });
      thunkApi.dispatch(getLicenses());
      thunkApi.dispatch(getActiveCompanyUsers());
      if (!!userData.email && userData.preferredLoginMethod === 'email') {
        try {
          await sendActivateAccountEmail(result.id);
        } catch (err) {
          const emailErrorNotification: INotification = {
            status: 'warning',
            message: 'Warning: User created successfully, but activation email failed to send',
          };
          thunkApi.dispatch(pushNotification(emailErrorNotification));
          return thunkApi.rejectWithValue('Unable to send email');
        }
      } else if (!!userData.phone && userData.preferredLoginMethod === 'phone') {
        try {
          await sendSmsWithInitialPassword(result.id);
        } catch (err) {
          const smsErrorNotification: INotification = {
            status: 'warning',
            message:
              'Warning: User created successfully, but activation SMS message failed to send',
          };
          thunkApi.dispatch(pushNotification(smsErrorNotification));
          return thunkApi.rejectWithValue('Unable to send SMS');
        }
      }
    }
    const successNotification: INotification = {
      status: 'success',
      message: 'User created successfully',
    };
    thunkApi.dispatch(pushNotification(successNotification));
    return thunkApi.fulfillWithValue('User created successfully');
    // } else {
    //   const errorNotification: INotification = {
    //     status: 'error',
    //     message: `Error: unable to find a valid license`,
    //   };
    //   thunkApi.dispatch(pushNotification(errorNotification));
    //   return thunkApi.rejectWithValue('Unable to find a valid license');
    // }
  } catch (err: any) {
    const errorNotification: INotification = {
      status: 'error',
      message: `Error: ${err.response.data.message || 'Unable to add user'}`,
    };
    thunkApi.dispatch(pushNotification(errorNotification));
    return thunkApi.rejectWithValue(err.response.data.message || 'Unable to add user');
  }
});

export const addInvitedUser = createAsyncThunk(
  'users/addinvited',
  async (userData: IUser, thunkApi) => {
    try {
      // TODO: remove this 'any' (should be correct return type from API)
      const { data: result } = (await api.users.create(userData)) as any;
      if (result.id) {
        if (!!userData.email && userData.preferredLoginMethod === 'email') {
          try {
            await sendActivateAccountEmail(result.id);
          } catch (err) {
            const emailErrorNotification: INotification = {
              status: 'warning',
              message: 'Warning: User created successfully, but activation email failed to send',
            };
            thunkApi.dispatch(pushNotification(emailErrorNotification));
            return thunkApi.rejectWithValue('Unable to send invite email');
          }
        }
      }
      const successNotification: INotification = {
        status: 'success',
        message: 'Admin user created successfully',
      };
      thunkApi.dispatch(pushNotification(successNotification));
      return thunkApi.fulfillWithValue('Admin user created successfully ');
    } catch ({ message }) {
      const errorNotification: INotification = {
        status: 'error',
        message: `Error: ${message || 'Unable to add admin user'}`,
      };
      thunkApi.dispatch(pushNotification(errorNotification));
      return thunkApi.rejectWithValue(message || 'Unable to add admin user');
    }
  }
);

export const reInviteUser = createAsyncThunk(
  'users/reinvite',
  async (userData: IUser, thunkApi) => {
    try {
      if (userData.id) {
        if (!!userData.email && userData.preferredLoginMethod === 'email') {
          try {
            await sendActivateAccountEmail(userData.id);
          } catch (err) {
            const emailErrorNotification: INotification = {
              status: 'warning',
              message: 'Warning: Activation email failed to send',
            };
            thunkApi.dispatch(pushNotification(emailErrorNotification));
            return thunkApi.rejectWithValue('Unable to send invite email');
          }
        }
      }
    } catch ({ message }) {
      const errorNotification: INotification = {
        status: 'error',
        message: `Error: ${message || 'Unable to re-invite admin user'}`,
      };
      thunkApi.dispatch(pushNotification(errorNotification));
      return thunkApi.rejectWithValue(message || 'Unable to add admin user');
    }
  }
);

export const editUser = createAsyncThunk('users/update', async (userData: IUser, thunkApi) => {
  try {
    const { data: result } = await api.users.update(userData.id!, userData);
    if (result) {
      thunkApi.dispatch(getLicenses());
      thunkApi.dispatch(getActiveCompanyUsers());
    }
    const successNotification: INotification = {
      status: 'success',
      message: 'User updated successfully',
    };
    thunkApi.dispatch(pushNotification(successNotification));
    return thunkApi.fulfillWithValue(userData);
  } catch ({ message }) {
    const errorNotification: INotification = {
      status: 'error',
      message: `Error: ${message || 'Unable to update user'}`,
    };
    thunkApi.dispatch(pushNotification(errorNotification));
    return thunkApi.rejectWithValue(message || 'Unable to updates user');
  }
});

export const removeUser = createAsyncThunk(
  'users/delete',
  async (userId: string | undefined, thunkApi) => {
    try {
      const state = thunkApi.getState() as RootState;
      const companyId = state.civil.companies.activeCompany?.id;

      if (userId) {
        await deleteUser(userId);
        await del(`civil/companies/${companyId}/users/delete-license/${userId}/`);
        thunkApi.dispatch(getLicenses());
        thunkApi.dispatch(getActiveCompanyUsers());
        const successNotification: INotification = {
          status: 'success',
          message: 'Success: Deleted User',
        };
        thunkApi.dispatch(pushNotification(successNotification));
        return userId;
      }
    } catch ({ message, response }) {
      console.error(`Could not delete user: ${(response as any).message || message}`);
      const errorNotification: INotification = {
        status: 'warning',
        message: 'Warning: User was not deleted.',
      };
      thunkApi.dispatch(pushNotification(errorNotification));
      thunkApi.dispatch(getLicenses());
      thunkApi.dispatch(getActiveCompanyUsers());
    }
  }
);
