import { useDispatch, useSelector } from 'react-redux';
import { selectAuthUser, setAuthUser } from 'modules/auth/storeSliceAuth';
import React, { useEffect, useRef, useState } from 'react';
import IconButton from '@mui/material/IconButton';
import PeopleIcon from '@mui/icons-material/People';
import Query from 'services/firebase/Query';
import Executer from 'services/firebase/Executer';
import { useNavigate } from 'react-router-dom';
import { UserType, UserTypes } from 'lg-helpers/dist/constants/user/UserTypes';
import CompaniesApi from 'services/postgres/CompaniesApi';
import useShowError from 'modules/errors';
import SwitcherDropdown from './SwitcherDropdown';
import SwitcherModal from './SwitcherModal';
import QueryBase from 'lg-helpers/dist/firestore/query/QueryBase';

const companiesApi = new CompaniesApi();
const query = new Query();
const executer = new Executer();

const ButtonUserTypeSwitcher = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const authUser = useSelector(selectAuthUser);
  const showError = useShowError();

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [companies, setCompanies] = useState([]);
  const [scales, setScales] = useState<any[]>([]);
  const [selectedRegion, setSelectedRegion] = useState<any>(null);
  const [selectedScale, setSelectedScale] = useState<any>(null);
  const [selectedCompany, setSelectedCompany] = useState<any>(null);
  const [isLoadingCompanies, setIsLoadingCompanies] = useState(false);
  const [isLoadingScales, setIsLoadingScales] = useState(false);
  const [desiredType, setDesiredType] = useState<UserType | ''>('');

  const isScaleUser =
    [UserTypes.scale.admin, UserTypes.scale.attendant].indexOf(desiredType as any) >= 0;

  const anchorRef = useRef<any>(null);
  const [isUsertypeDropdownOpen, setIsUsertypeDropdownOpen] = useState(false);

  const getCompanies = async () => {
    try {
      setIsLoadingCompanies(true);
      // TODO: hotfixed with excluded types, but needs fix/optimisation in future
      const companiesResult = await companiesApi.getCompaniesWithCustomDataProperties(['regions']);
      setCompanies(companiesResult);
      setIsLoadingCompanies(false);
    } catch (error) {
      console.error('Error', error);
      showError({
        title: 'Error while fetching the companies',
        duration: 10000,
      });
      setIsLoadingCompanies(false);
    }
  };

  const handleClose = (event: any) => {
    if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
      return;
    }

    setIsUsertypeDropdownOpen(false);
  };

  const dispatchUserObjectUpdate = (newData: any) =>
    new Promise(resolve => {
      dispatch(setAuthUser(newData));
      resolve(newData);
    });

  const updateUserDataInDB = async (updateData: any) => {
    return await query.base.getById(QueryBase.USERS_COLLECTION(), authUser.id).update(updateData);
  };

  const getCompanyLabel = (company: any) => {
    if (!company) {
      return '';
    }
    const typesStringified = company.types ? company.types.map((type: any) => type).join(', ') : '';
    return `${company.name} - ${typesStringified}`;
  };

  const handleSuperAdminChange = async () => {
    if (!selectedCompany && desiredType !== UserTypes.admin.super) return;
    if (isScaleUser && (!selectedRegion || !selectedScale)) return;

    const updatedData = {
      ...authUser,
      actingAsType: desiredType,
    };

    if (desiredType === UserTypes.admin.super) {
      updatedData.companiesIds = [];
      updatedData.companies = [];
    } else {
      updatedData.companiesIds = [selectedCompany.id];
      updatedData.companies = [
        {
          id: selectedCompany?.id,
          name: selectedCompany?.name,
          types: selectedCompany?.types,
        },
      ];

      if (isScaleUser) {
        updatedData.regionId = selectedRegion.id;
        updatedData.scalesIds = scales.map(s => s.id);
        updatedData.selectedScaleId = selectedScale.id;
      } else {
        // WARNING: Do we need to reset all of this?
        delete updatedData.regionId;
        delete updatedData.scalesIds;
        delete updatedData.selectedScaleId;
      }
    }

    await dispatchUserObjectUpdate(updatedData);
    await updateUserDataInDB(updatedData);

    setSelectedCompany(null);
    setIsUsertypeDropdownOpen(false);
    setIsModalOpen(false);
    navigate('/');
  };

  const handleUpdate = async (userType: string) => {
    try {
      const updatedData = {
        ...authUser,
        actingAsType: userType,
      };

      await dispatchUserObjectUpdate(updatedData);
      await updateUserDataInDB(updatedData);

      setIsUsertypeDropdownOpen(false);
      navigate('/');
    } catch (e) {
      console.error(e);
    }
  };

  const userSelectedAType = async (userType: UserType) => {
    setDesiredType(userType);
    setSelectedRegion(null);
    setSelectedScale(null);
    if (authUser.type === UserTypes.admin.super) {
      if (!companies.length) {
        await getCompanies();
      }
      setIsModalOpen(true);
    } else {
      return await handleUpdate(userType);
    }
  };

  useEffect(() => {
    const getScales = async (companyId: string, regionId: string) => {
      setIsLoadingScales(true);
      try {
        const scalesResults = await executer.getMultipleDocuments(
          query.manifests.getScalesForScaleCompanyInRegion(companyId, regionId)
        );
        setScales(scalesResults);
      } catch (error) {
        console.error('Error', error);
        showError({
          title: 'Error while fetching the scales in region',
          duration: 10000,
        });
      }
      setIsLoadingScales(false);
    };

    if (selectedCompany && selectedRegion && isScaleUser) {
      getScales(selectedCompany.id, selectedRegion.id);
    } else {
      setScales([]);
    }
  }, [showError, isScaleUser, selectedCompany, selectedRegion]);

  useEffect(() => {
    if (!selectedCompany || !isScaleUser) return;
    if (selectedCompany.regions?.length === 1) setSelectedRegion(selectedCompany.regions[0]);
  }, [isScaleUser, selectedCompany]);

  return (
    <React.Fragment>
      <IconButton
        ref={anchorRef}
        color="inherit"
        onClick={() => setIsUsertypeDropdownOpen(!isUsertypeDropdownOpen)}
      >
        <PeopleIcon />
      </IconButton>

      <SwitcherDropdown
        isOpen={isUsertypeDropdownOpen}
        anchorRef={anchorRef.current}
        onClose={handleClose}
        onSelect={(userType: any) => userSelectedAType(userType)}
      />

      <SwitcherModal
        isOpen={isModalOpen}
        onToggle={() => setIsModalOpen(false)}
        desiredType={desiredType}
        items={companies}
        getLabel={getCompanyLabel}
        selectedItem={selectedCompany}
        onSelectItem={value => {
          setSelectedCompany(value);
          setSelectedRegion(null);
        }}
        selectedRegion={selectedRegion}
        onSelectRegion={value => {
          setSelectedRegion(value);
          setSelectedScale(null);
        }}
        scales={scales}
        selectedScale={selectedScale}
        onSelectScale={setSelectedScale}
        isLoadingItems={isLoadingCompanies || isLoadingScales}
        onHandleChange={handleSuperAdminChange}
      />
    </React.Fragment>
  );
};

export { ButtonUserTypeSwitcher };
