import React, { useEffect, useState } from 'react';
import { DialogContent, Grid, FormControlLabel, Checkbox } from '@mui/material';
import { FormTextField } from 'sharedComponents/FormTextField';
import { toDateStr } from 'modules/civilAndTrucking/trucking/utils';
import { getTicketCSVBetweenDates } from '../../../../redux/actions';
import { LoadingOverlay } from 'sharedComponents/LoadingOverlay';
import { downloadBlob } from 'modules/civilAndTrucking/trucking/utils/downloadBlob';
import { thunkDispatch } from 'store/store';
import { get } from 'services/api/restHelpers';
import { useHasAccess, usePermissionsContext } from 'services/permissions';
import DialogActionContainer from 'sharedComponents/DialogActionContainer';

export const ExportTickets = ({ companiesIds, date }: { companiesIds?: string[]; date?: Date }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [hasFTPConfig, setHasFTPConfig] = useState(false);
  const [isFTPExport, setIsFTPExport] = useState(false);
  const [exportStartDate, setExportStartDate] = useState(toDateStr(!!date ? date : new Date()));
  const [exportEndDate, setExportEndDate] = useState(toDateStr(!!date ? date : new Date()));
  const [excludeColumnHeader, setExcludeColumnHeader] = useState(false);

  const { user } = usePermissionsContext();
  const { hasAccess: hasTruckingAllAccess } = useHasAccess([
    'trucking_tickets:all',
    'trucking_projects:all',
  ]);
  const companyId = user.companiesIds[0];

  useEffect(() => {
    const fetchData = async () => {
      const response = await get(`trucking_ticket/ftp/config/${companyId}`);

      setHasFTPConfig(response !== null);
    };
    fetchData();
  }, [companyId]);

  const onHandleExport = async () => {
    setIsLoading(true);

    const CSVText: any = await thunkDispatch(
      getTicketCSVBetweenDates({
        startDate: exportStartDate,
        endDate: exportEndDate,
        isFTPExport,
        excludeColumnHeader,
        companiesIds: companiesIds?.length ? companiesIds : [companyId],
      })
    );
    if (typeof CSVText.payload === 'string') {
      downloadBlob(CSVText.payload, 'export.csv', 'text/csv;charset=utf-8;');
    }

    setIsLoading(false);
  };

  if (isLoading) {
    return <LoadingOverlay />;
  }

  return (
    <DialogContent>
      <Grid container columnSpacing={2}>
        <Grid item xs={6}>
          <FormTextField
            required
            type="date"
            name="exportStartDate"
            label="Start Date"
            value={exportStartDate}
            onChange={e => setExportStartDate(e.target.value)}
          />
        </Grid>
        <Grid item xs={6}>
          <FormTextField
            required
            type="date"
            name="exportEndDate"
            label="End Date"
            value={exportEndDate}
            onChange={e => setExportEndDate(e.target.value)}
          />
        </Grid>
      </Grid>
      {hasTruckingAllAccess && (
        <FormControlLabel
          control={
            <Checkbox
              color="success"
              checked={isFTPExport}
              onChange={() => setIsFTPExport(!isFTPExport)}
              disabled={!hasFTPConfig}
            />
          }
          label="Export To FTP"
          sx={{ display: 'block' }}
        />
      )}
      <FormControlLabel
        control={
          <Checkbox
            color="success"
            checked={excludeColumnHeader}
            onChange={() => setExcludeColumnHeader(!excludeColumnHeader)}
          />
        }
        label="Exclude Column Headers"
        sx={{ display: 'block' }}
      />
      <DialogActionContainer saveHandler={onHandleExport} saveText={'Export'} />
    </DialogContent>
  );
};
