import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@mui/material';
import CrudTable from 'sharedComponents/CrudTable';
import { selectAuthUser } from 'modules/auth/storeSliceAuth';
import { useSelector } from 'react-redux';
import 'moment-timezone';
import { getDateStringForTimezone } from 'services/logic/dateTime';
import ButtonDownload from 'sharedComponents/ButtonDownload';
import Query from 'services/firebase/Query';
import Executer from 'services/firebase/Executer';
import useShowError from 'modules/errors';
import { constants } from 'lg-helpers';

const query = new Query();
const executer = new Executer();

const {
  pubSub: { DownloadType },
} = constants;

const columns = [
  {
    label: 'Name',
    getCellValueCallback: row => {
      const {
        filters: { startDate, endDate },
        perDay,
        projectTimeZone,
      } = row;

      if (!startDate || !endDate) {
        return 'N/A';
      }

      return `${perDay ? 'per-day' : 'all'} (${getDateStringForTimezone(
        startDate.toDate(),
        projectTimeZone
      )} - 
        ${getDateStringForTimezone(endDate.toDate(), projectTimeZone)})`;
    },
  },
  {
    label: 'Expires at',
    getCellValueCallback: row => {
      const { url, urlExpiresAt, projectTimeZone } = row;

      if (!url) {
        return 'N/A';
      }

      return getDateStringForTimezone(urlExpiresAt.toDate(), projectTimeZone);
    },
  },
  {
    label: 'Download ZIP',
    getCellValueCallback: row => {
      const { url, state } = row;

      if (state === 'ERROR_ZIP_ARCHIVE') {
        return (
          <Typography variant="body2" color="secondary">
            Error
          </Typography>
        );
      }

      return <ButtonDownload isLoading={!url} url={url} />;
    },
  },
];

const Downloads = ({ projectId }) => {
  const authUser = useSelector(selectAuthUser);
  const [downloads, setDownloads] = useState(null);
  const showError = useShowError();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (!authUser || !projectId) {
      return;
    }

    return executer.watchMultipleDocuments(
      query.users.getUserDownloadsByTypeAndProject(authUser, DownloadType.MANIFESTS(), projectId),
      firestoreDownloads => {
        setIsLoading(false);
        setDownloads(firestoreDownloads);
      },
      err => {
        console.error(err);
        showError({ title: 'Error loading downloads', duration: 10000 });
      }
    );
    // eslint-disable-next-line
  }, [authUser, projectId]);

  return (
    <CrudTable title="Downloads" rows={downloads} columns={columns} isLoading={isLoading}>
      <></>
    </CrudTable>
  );
};

Downloads.propTypes = {
  projectId: PropTypes.string,
};

export default Downloads;
