import React, { useState, useEffect } from 'react';
import moment from 'moment';
import 'moment-timezone';
import { Grid, Box, CircularProgress } from '@mui/material';
import PropTypes from 'prop-types';

import ManifestDisplay from 'sharedComponents/ManifestDisplay';
import {
  selectCachedTemplates,
  addCachedTemplate,
  selectCachedBase64ImagesByUrl,
  addCachedBase64ImagesByUrl,
} from 'redux/storeSliceManifest';
import { useDispatch, useSelector } from 'react-redux';
import { getBase64FromUrl } from 'services/logic/base64';
import sizeOf from 'image-size';
import Query from 'services/firebase/Query';
import useGetOrWatchDocumentById from 'services/hooks/useGetOrWatchDocumentById';
import ManifestDisplayWithTemplateDataGathering from 'sharedComponents/ManifestDisplayWithTemplateDataGathering';
import useShowError from 'modules/errors';
import QueryBase from 'lg-helpers/dist/firestore/query/QueryBase';

const LoadDisplayWithTemplateDataGathering = ({
  loadId,
  onSucessfullDisplayCallback,
  setLoadCanvasRefCallback,
}) => {
  const [, setIsLoadingData] = useState(false);
  const showError = useShowError();
  const [load] = useGetOrWatchDocumentById(QueryBase.BROKER_LOADS_COLLECTION(), loadId);
  const [loadTemplateHeavy, setLoadTemplateHeavy] = useState(null);
  const [loadHeavy, setLoadHeavy] = useState(null);
  const cachedTemplates = useSelector(selectCachedTemplates);
  const cachedBase64ImagesByUrl = useSelector(selectCachedBase64ImagesByUrl);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!load) {
      return;
    }

    if (!load.template) {
      console.log('No load template for load', load);
      return;
    }

    const { url } = load.template;
    let hashKeyTemplate = null;
    if (url) {
      hashKeyTemplate = `${load.assignedTruckingCompanyID}${url}`;
    }

    const getLoadTemplateHeavy = async () => {
      try {
        if (!load.template.url) {
          setIsLoadingData(false);
          setLoadTemplateHeavy(load.template);
          console.log('No load.template.url', load);
          setLoadTemplateHeavy(load.template);
          return;
        }
        setIsLoadingData(true);

        const base64 = await getBase64FromUrl(load.template, load.template.url);
        const img = Buffer.from(base64.split(';base64,').pop(), 'base64');
        const dimensions = sizeOf(img);

        const loadTemplate = {
          ...load.template,
          base64,
          dimensions,
        };

        setLoadTemplateHeavy(loadTemplate);

        if (hashKeyTemplate) {
          dispatch(
            addCachedTemplate({
              key: hashKeyTemplate,
              template: {
                ...loadTemplate,
              },
            })
          );
        }

        setIsLoadingData(false);
      } catch (err) {
        setIsLoadingData(false);
        showError({ title: 'Error getting the load template' });
        console.error('Error in getLoadTemplateHeavy', err);
      }
    };

    const getLoadHeavy = async () => {
      try {
        const { urlDriver, urlGateAttendant, urlPitTicket } = load.signatureDriver;

        let driverSignatureBase64 = null;
        let gateAttendantSignatureBase64 = null;
        let pitTicketBase64 = null;

        if (urlDriver) {
          if (cachedBase64ImagesByUrl[urlDriver]) {
            driverSignatureBase64 = cachedBase64ImagesByUrl[urlDriver];
          } else {
            driverSignatureBase64 = await getBase64FromUrl(urlDriver);
            dispatch(
              addCachedBase64ImagesByUrl({
                url: urlDriver,
                base64: driverSignatureBase64,
              })
            );
          }
        }

        if (urlGateAttendant) {
          gateAttendantSignatureBase64 = await getBase64FromUrl(urlGateAttendant);
        }

        if (urlPitTicket) {
          pitTicketBase64 = await getBase64FromUrl(urlPitTicket);
        }

        const dateFormat = 'MM/DD/YYYY hh:mm:ss';
        let { signedAtLoaded, signedAtDumped } = load.signatureDriver;

        if (signedAtLoaded) {
          const momentDate = moment.tz(signedAtLoaded.toDate(), load.timeZone || 'EST');
          signedAtLoaded = `${momentDate.format(dateFormat)} ${momentDate.zoneAbbr()}`;
        }

        if (signedAtDumped) {
          const momentDate = moment.tz(signedAtDumped.toDate(), load.timeZone || 'EST');
          signedAtDumped = `${momentDate.format(dateFormat)} ${momentDate.zoneAbbr()}`;
        }

        setLoadHeavy({
          ...load,
          signatureDriver: {
            ...load.signatureDriver,
            urlDriver: driverSignatureBase64,
            urlGateAttendant: gateAttendantSignatureBase64,
            urlPitTicket: pitTicketBase64,
            signedAtLoaded,
            signedAtDumped,
          },
        });
      } catch (err) {
        showError({ title: 'Error getting the load' });
        console.error('Error in getLoadHeavy', err);
      }
    };

    if (hashKeyTemplate && cachedTemplates[hashKeyTemplate]) {
      setLoadTemplateHeavy(cachedTemplates[hashKeyTemplate]);
    } else {
      getLoadTemplateHeavy();
    }

    getLoadHeavy();
  }, [load, cachedBase64ImagesByUrl, cachedTemplates, dispatch, showError]);

  useEffect(() => {
    if (onSucessfullDisplayCallback && loadHeavy && loadTemplateHeavy) {
      onSucessfullDisplayCallback(true);
    }
  }, [loadHeavy, loadTemplateHeavy, onSucessfullDisplayCallback]);

  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <Box display="flex" justifyContent="center" alignItems="center">
          {loadTemplateHeavy && loadHeavy ? (
            <ManifestDisplay
              manifest={loadHeavy}
              templateHeavy={loadTemplateHeavy}
              isPreviewTemplate={false}
              setCanvasRefCallback={setLoadCanvasRefCallback}
            />
          ) : (
            <CircularProgress color="inherit" />
          )}
        </Box>
      </Grid>
      <Grid item xs={12}>
        {load && load.manifestId && (
          <ManifestDisplayWithTemplateDataGathering manifestId={load.manifestId} />
        )}
      </Grid>
    </Grid>
  );
};

LoadDisplayWithTemplateDataGathering.propTypes = {
  loadId: PropTypes.string,
  onSucessfullDisplayCallback: PropTypes.func,
  setLoadCanvasRefCallback: PropTypes.func,
};

export default LoadDisplayWithTemplateDataGathering;
