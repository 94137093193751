import React, { useState } from 'react';
import { TextField } from '@mui/material';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';

export interface MaterialOptionType {
  label: string;
  value?: number;
  inputValue?: string;
}

const filter = createFilterOptions<MaterialOptionType>();

export type AddMaterialsProps = {
  index: number;
  companyMaterialId?: number | string;
  materialsOptions: MaterialOptionType[];
  handleChange: (name: string, value: string | number | undefined, index: number) => void;
};

const initMaterialValue = (
  materialsOptions: MaterialOptionType[],
  companyMaterialId?: number | string
) => {
  const materialOption = materialsOptions.find(m => m.value === companyMaterialId);
  return materialOption ?? null;
};

const MaterialsDropdown = ({
  index,
  materialsOptions,
  companyMaterialId,
  handleChange,
}: AddMaterialsProps) => {
  const [materialValue, setMaterialValue] = useState<MaterialOptionType | null>(
    initMaterialValue(materialsOptions, companyMaterialId)
  );

  return (
    <Autocomplete
      options={materialsOptions}
      getOptionLabel={option => {
        return option.label;
      }}
      filterOptions={(options, params) => {
        const filtered = filter(options, params);

        const { inputValue } = params;
        // Suggest the creation of a new value
        const isExisting = options.some(option => inputValue === option.label);
        if (inputValue !== '' && !isExisting) {
          filtered.push({
            inputValue,
            label: `Add "${inputValue}"`,
          });
        }

        return filtered;
      }}
      value={materialValue}
      size="small"
      onChange={(e, newValue) => {
        if (newValue && newValue.inputValue) {
          setMaterialValue({
            label: newValue.inputValue,
          });
        } else {
          setMaterialValue(newValue);
        }

        handleChange(
          newValue?.inputValue ? 'newMaterialName' : 'companyMaterialIdTruckingProjectMaterial',
          newValue?.inputValue ? newValue?.inputValue : newValue?.value,
          index
        );
      }}
      renderInput={params => (
        <TextField required {...params} name="newMaterialName" variant="outlined" />
      )}
    />
  );
};

export default MaterialsDropdown;
