import React from 'react';
import { DialogContent } from '@mui/material';
import ModalHeader from 'sharedComponents/DialogHeader';
import AddTicketForm from './AddTicketForm';
import { ITicketForm } from 'lg-helpers/dist/shared/interfaces/ITicketForm';

interface ModalContainerProps {
  selected?: ITicketForm;
  activeProjectId?: number;
}

const ModalContainer = ({ selected, activeProjectId }: ModalContainerProps) => {
  return (
    <>
      <ModalHeader title={selected ? 'Update Ticket' : 'Add Ticket'} />
      <DialogContent>
        <AddTicketForm selected={selected} activeProjectId={activeProjectId} />
      </DialogContent>
    </>
  );
};

export default ModalContainer;
