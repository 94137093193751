import React from 'react';
import './Layout.scss';
import { Outlet } from 'react-router-dom';
import { IMenuItem } from 'modules/civilAndTrucking/shared/nav';
import { AppNav } from 'modules/civilAndTrucking/shared/nav/AppNav/AppNav';
import LogoLink from 'sharedComponents/LogoLink';
import { useMediaQuery, useTheme } from '@mui/material';
import { ModalProvider } from 'sharedComponents/ModalContext';
import { UserType } from 'lg-helpers/dist/constants/user/UserTypes';
import { usePermissionsContext } from 'services/permissions';
import Modal from 'sharedComponents/Modal';

export type dashboardProps = {
  navItems: IMenuItem[];
  globalNavItems?: (userType: UserType) => IMenuItem[];
};

const DashboardLayout = ({ navItems, globalNavItems }: dashboardProps) => {
  const theme = useTheme();
  const isWidthXSorSM = useMediaQuery(theme.breakpoints.down('md'));
  const { user } = usePermissionsContext();
  return (
    <ModalProvider>
      <Modal />
      <div className="Layout__wrapper--outer">
        <AppNav
          navItems={(globalNavItems && globalNavItems(user.actingAsType)) || navItems}
          logoComponent={
            <LogoLink className="Manifest--LogoLink" width={isWidthXSorSM ? 'xs' : 'md'} />
          }
          settingsPath="/account"
        />
        <div className="Layout__wrapper--inner">
          <div className="Layout__content-container">
            <div className="Layout__content">
              <Outlet />
            </div>
          </div>
        </div>
      </div>
    </ModalProvider>
  );
};

export default DashboardLayout;
