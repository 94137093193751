import React from 'react';
import { createSvgIcon } from '@mui/material';

export const ArrowLeft = createSvgIcon(
  <path
    fillRule="evenodd"
    clipRule="evenodd"
    d="M8.60646.29289c.39053.39052.39053 1.02369.00001 1.41421L4.31361 6H19.8994c.5523 0 1 .44772 1 1s-.4477 1-1 1H4.31361l4.29286 4.2929c.39052.3905.39052 1.0237-.00001 1.4142-.39052.3905-1.02369.3905-1.41421 0l-5.99995-6c-.39052-.39052-.39052-1.02368 0-1.4142l5.99995-6c.39052-.39053 1.02369-.39053 1.41421-.00001Z"
  />,
  'Arrow Left'
);
