import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import 'firebase/compat/storage';
import 'firebase/compat/analytics';

firebase.initializeApp({
  apiKey: process.env.REACT_APP_FIREBASE_WEB_API_KEY,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  authDomain: `${process.env.REACT_APP_FIREBASE_PROJECT_ID}.firebaseapp.com`,
  databaseURL: `https://${process.env.REACT_APP_FIREBASE_PROJECT_ID}.firebaseio.com`,
  storageBucket: `gs://${process.env.REACT_APP_FIREBASE_PROJECT_ID}.appspot.com`,
  appId: process.env.REACT_APP_APP_ID,
  measurementId: process.env.REACT_APP_MEASUREMENT_ID || undefined,
});

process.env.REACT_APP_MEASUREMENT_ID && firebase.analytics();

export const firestore = firebase.firestore();
export const storage = firebase.storage();
export const auth = firebase.auth();
export const authConstructor = firebase.auth;
export const Timestamp = firebase.firestore.Timestamp;
export const FieldValue = firebase.firestore.FieldValue;
