import React from 'react';
import PropTypes from 'prop-types';
import { CircularProgress, Box, Button, Typography, Grid, Card, CardContent } from '@mui/material';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';

const ManifestsTableFooter = ({
  totalQuantity,
  recordsCount,
  isLoading,
  indicator,
  handleNext,
  handleBack,
}) => {
  return (
    <Box margin={1}>
      <Card>
        <CardContent>
          <Grid container spacing={2}>
            {isLoading ? (
              <Grid item xs={12}>
                <Box display="flex" justifyContent="center" alignItems="center">
                  <CircularProgress color="inherit" fontSize="large" />
                </Box>
              </Grid>
            ) : (
              <>
                <Grid item md={6} xs={12}>
                  <Box display="flex" justifyContent="center" alignItems="center">
                    <Typography variant="h6">Records count: {recordsCount}</Typography>
                  </Box>
                </Grid>
                <Grid item md={6} xs={12}>
                  <Box display="flex" justifyContent="center" alignItems="center">
                    <Typography variant="h6">
                      Total quantity: {totalQuantity.toFixed(2)} tons
                    </Typography>
                  </Box>
                </Grid>
              </>
            )}
          </Grid>
        </CardContent>
      </Card>

      <Box mt={4}>
        <Grid container spacing={2}>
          <Grid item md={6} sm={6} xs={12} />
          <Grid item md={6} sm={6} xs={12}>
            <Box display="flex" justifyContent="flex-end" alignItems="center" marginBottom={1}>
              <>
                <Box display="flex">
                  <Button
                    variant="contained"
                    color="inherit"
                    disabled={indicator === 1}
                    onClick={handleBack}
                  >
                    <NavigateBeforeIcon fontSize="small" />
                    Back
                  </Button>
                  <Box marginRight={1} marginLeft={1}>
                    <Button variant="contained" color="inherit" style={{ minWidth: '40px' }}>
                      {indicator}
                    </Button>
                  </Box>
                </Box>
              </>

              <>
                <Button
                  variant="contained"
                  color="inherit"
                  disabled={recordsCount < 100}
                  onClick={handleNext}
                >
                  Next
                  <NavigateNextIcon fontSize="small" />
                </Button>
              </>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

ManifestsTableFooter.propTypes = {
  authUser: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
  selectedManifestsIds: PropTypes.array,
  isLoading: PropTypes.bool,
  manifests: PropTypes.array,
  handleNext: PropTypes.func.isRequired,
  handleBack: PropTypes.func.isRequired,
};

export default ManifestsTableFooter;
