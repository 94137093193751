import React, { useState, useEffect } from 'react';
import './SuperAdminManifestReconcilliation.scss';
import Query from 'services/firebase/Query';
import Executer from 'services/firebase/Executer';
import Command from 'services/firebase/Command';
import { selectAuthUser } from 'modules/auth/storeSliceAuth';
import { DataGrid, GridColumns } from '@mui/x-data-grid';
import { Button, CircularProgress, MenuItem, Select, TextField, Autocomplete } from '@mui/material';
import useShowError from 'modules/errors';
import { useSelector } from 'react-redux';
import { formatGoogleTimestamp, formatStringTimestamp } from 'utils/timestampUtils';
import { Manifest } from 'lg-helpers/dist/shared/types/manifests';
import QueryBase, { FilterCondition } from 'lg-helpers/dist/firestore/query/QueryBase';

const query = new Query();
const executer = new Executer();
const command = new Command();

const COMPONENT_NAME = 'SuperAdminTesting';

const columns: GridColumns<Manifest> = [
  {
    width: 175,
    field: 'number',
    headerName: 'Manifest #',
  },
  {
    width: 175,
    field: 'profileNr',
    headerName: 'Profile #',
    valueGetter: params => params.row.project?.profileNr,
  },
  {
    width: 175,
    field: 'driverName',
    headerName: 'Driver Name',
    valueGetter: params => params.row.signatureDriver?.driverName,
  },
  {
    width: 50,
    field: 'driverId',
    headerName: 'Driver ID',
    valueGetter: params => params.row.signatureDriver.userId,
  },
  {
    width: 150,
    field: 'truckingCompany',
    headerName: 'Trucking Company',
    valueGetter: params => params.row.signatureDriver.truckingCompany ?? 'null',
  },
  {
    width: 150,
    field: 'truckingCompanyId',
    headerName: 'Trucking Company ID',
    valueGetter: params => params.row.signatureDriver.truckingCompanyId ?? 'null',
  },
  {
    width: 150,
    field: 'lastUsedAt',
    headerName: 'lastUsedAt',
    valueGetter: params =>
      params.row.lastUsedAt ? formatGoogleTimestamp(params.row.lastUsedAt.toDate()) : 'No Date',
  },
  {
    width: 150,
    field: 'receipt.time_in',
    headerName: 'receipt.time_in',
    valueGetter: params => formatStringTimestamp(params.row.receipt?.time_in),
  },
];

const whereConditionsByJobType: { [key: string]: FilterCondition[] } = {
  signature: [
    {
      prop: 'signatureDriver.url',
      op: '==',
      val: null,
    },
  ],
  lastUsedAt: [
    {
      prop: 'receipt',
      op: '!=',
      val: null,
    },
  ],
  company: [
    {
      prop: 'signatureDriver.isSigned',
      op: '==',
      val: true,
    },
    {
      prop: 'signatureDriver.truckingCompany',
      op: '==',
      val: null,
    },
  ],
  companyId: [
    {
      prop: 'signatureDriver.isSigned',
      op: '==',
      val: true,
    },
    {
      prop: 'signatureDriver.truckingCompanyId',
      op: '==',
      val: null,
    },
  ],
};

interface SelectedProject {
  id: string;
  name: string;
}

const SuperAdminManifestReconcilliation = () => {
  const showError = useShowError();
  const authUser = useSelector(selectAuthUser);
  const [isLoading, setIsLoading] = useState(false);
  const [results, setResults] = useState<Manifest[]>([]);
  const [projects, setProjects] = useState<{ id: string; name: string }[]>([]);
  const [selectedProject, setSelectedProject] = useState<SelectedProject | null>(null);
  const [limit, setLimit] = useState(10);
  const [jobType, setJobType] = useState('company');

  useEffect(() => {
    if (!selectedProject) return;
    setIsLoading(true);
    let conditions = [];

    switch (jobType) {
      case 'signature':
        conditions.push(whereConditionsByJobType.signature);
        break;
      case 'lastUsedAt':
        conditions.push(whereConditionsByJobType.lastUsedAt);
        break;
      case 'company':
        conditions.push(whereConditionsByJobType.company);
        break;
      case 'companyId':
        conditions.push(whereConditionsByJobType.companyId);
        break;
      default:
        console.warn('Unhandled job type', jobType);
    }

    return executer.watchMultipleDocuments(
      query.manifests.getManifestForReconcile(
        selectedProject.id as string,
        conditions.flat(),
        limit
      ),
      manifests => {
        mapManifestReturn(manifests as Manifest[]);
        setIsLoading(false);
      },
      e => {
        console.error(e);
        setIsLoading(false);
      }
    );
    // eslint-disable-next-line
  }, [selectedProject, limit, jobType]);

  useEffect(() => {
    if (!authUser) return;

    const getProjects = async () => {
      setIsLoading(true);
      try {
        const projectsResults = await executer.getMultipleDocuments(
          query.base.getAll(QueryBase.PROJECTS_COLLECTION()).orderBy('name', 'asc')
        );
        setProjects(projectsResults.map(proj => ({ id: proj.id, name: proj.name })));
      } catch (error) {
        console.error('Error', error);
        showError({
          title: 'Error while fetching the projects',
          duration: 10000,
        });
      } finally {
        setIsLoading(false);
      }
    };

    getProjects();
    // eslint-disable-next-line
  }, [authUser]);

  const mapManifestReturn = (manifests: Manifest[]) => {
    switch (jobType) {
      case 'lastUsedAt':
        const incorrectTimestamps = manifests.filter(
          manifest =>
            !manifest.lastUsedAt ||
            formatGoogleTimestamp(manifest.lastUsedAt.toDate()) !==
              formatStringTimestamp(manifest.receipt.time_in)
        );
        setResults(incorrectTimestamps);
        break;
      default:
        setResults(manifests);
    }
  };

  const runBatchManifestJob = async () => {
    try {
      setIsLoading(true);

      if (jobType === 'lastUsedAt') {
        console.log('batchUpdateManifestsLastUsedAt()');
        await command.manifests.batchUpdateManifestsLastUsedAt(results, authUser);
      } else {
        console.log('batchUpdateManifestsSignatureUrl()');
        await command.manifests.batchUpdateManifestsSignatureUrl(results, jobType, authUser);
      }
    } catch (e) {
      console.error(e);
      showError({
        title: 'Error while updating manifests',
        duration: 10000,
      });
    } finally {
      setIsLoading(false);
    }
  };

  const runBatchUserJob = async () => {
    try {
      setIsLoading(true);
      const users = await executer.getMultipleDocuments(query.users.filterUsers());
      const filteredUsers = users.filter(
        user =>
          user?.customData?.truckingCompany === null && user?.customData?.truckingCompanyId === null
      );

      await command.manifests.updateDrivers(filteredUsers);
    } catch (e) {
      console.error('Error', e);
      showError({
        title: 'Error while fetching users',
        duration: 10000,
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div
      className={COMPONENT_NAME}
      style={{
        padding: 20,
        overflowX: 'hidden',
        overflowY: 'auto',
      }}
    >
      <h2 style={{ marginBottom: 20 }}>Reconcile</h2>

      <p>What kind of reconciliation are you trying to do?</p>

      <div
        className={`${COMPONENT_NAME}__controls`}
        style={{
          display: 'flex',
          marginBottom: 40,
        }}
      >
        <Select
          value={jobType}
          variant="outlined"
          disabled={isLoading}
          onChange={e => setJobType(e.target.value)}
        >
          <MenuItem value="company">Populate company name</MenuItem>
          <MenuItem value="companyId">Populate company ID</MenuItem>
          <MenuItem value="signature">Populate drivers signature</MenuItem>
          <MenuItem value="lastUsedAt">Update manifest to scale time in</MenuItem>
        </Select>
      </div>

      <div
        className={`${COMPONENT_NAME}__controls`}
        style={{
          display: 'flex',
          marginBottom: 20,
        }}
      >
        <Select
          value={limit}
          variant="outlined"
          disabled={isLoading}
          style={{ flex: 1 }}
          onChange={e => setLimit(e.target.value as number)}
        >
          <MenuItem value={10}>10</MenuItem>
          <MenuItem value={50}>50</MenuItem>
          <MenuItem value={100}>100</MenuItem>
          <MenuItem value={500}>500</MenuItem>
          <MenuItem value={1000}>1000</MenuItem>
          <MenuItem value={5000}>5000</MenuItem>
          <MenuItem value={10000}>10000</MenuItem>
        </Select>

        <Autocomplete
          style={{ flex: 4 }}
          value={selectedProject}
          className="SuperAdminManifestReconcilliation__autocomplete"
          getOptionLabel={option => option.name || ''}
          disabled={!projects || projects.length < 2 || isLoading}
          isOptionEqualToValue={(option, value) => option.id === value.id}
          onChange={(event, value) => setSelectedProject(value)}
          options={
            projects.map(project => ({ id: project.id as string, name: project.name as string })) ||
            []
          }
          renderInput={params => (
            <TextField
              {...params}
              label="Project"
              variant="outlined"
              InputProps={{
                ...params.InputProps,
                endAdornment: <>{params.InputProps.endAdornment}</>,
              }}
            />
          )}
        />
      </div>

      {isLoading ? (
        <CircularProgress color="inherit" size={20} />
      ) : (
        <div style={{ height: '60vh', width: '100%' }}>
          <DataGrid rows={results} columns={columns} />
        </div>
      )}

      <div className={`${COMPONENT_NAME}__cta`}>
        {!!results.length && (
          <Button
            color="primary"
            variant="outlined"
            onClick={() =>
              window.confirm('Are you sure these are the right manifests?')
                ? runBatchManifestJob()
                : null
            }
            disabled={isLoading}
          >
            Run Batch
          </Button>
        )}

        <Button
          variant="outlined"
          onClick={() =>
            window.confirm('Are you sure want to run user update?') ? runBatchUserJob() : null
          }
          disabled={isLoading}
        >
          Run User Update
        </Button>
      </div>
    </div>
  );
};

export default SuperAdminManifestReconcilliation;
