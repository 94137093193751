import React, { useContext, useState } from 'react';
import { Grid, FormControl, TextField, InputLabel, Button, CircularProgress } from '@mui/material';

import { RootState, thunkDispatch } from 'store/store';
import { useFormik } from 'formik';
import { addProjectTemplate } from '../redux/actions';
import { projectTemplateSchema } from '../templateSchema';
import { useHasAccess } from 'services/permissions';
import { useSelector } from 'react-redux';
import { selectAuthUser } from 'modules/auth/storeSliceAuth';
import { IProjectTemplate } from 'lg-helpers/dist/shared/interfaces/IProjectTemplate';
import { ModalContext } from 'sharedComponents/ModalContext';
import Alert from '@mui/lab/Alert';

export const ProjectTemplateForm: React.FC = () => {
  const { handleModal } = useContext(ModalContext);
  const auth = useHasAccess(['projects:create', 'projects:update']);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const authUser = useSelector(selectAuthUser);

  const activeProject = useSelector((state: RootState) => state.civil.projects.activeProject);

  const initialValues: IProjectTemplate = {
    name: '',
    companyId: authUser.companiesIds[0],
    createdByUserId: authUser.id,
    projectId: (activeProject && activeProject.id ? activeProject.id : 0) as number,
    deleted: false,
    dateCreated: '',
  };

  const handleSubmit = async (values: IProjectTemplate) => {
    try {
      setIsLoading(true);
      await thunkDispatch(addProjectTemplate(values));
    } catch {
      // void
    } finally {
      setIsLoading(false);
      handleModal();
      formik.resetForm();
    }
  };

  const formik = useFormik({
    validationSchema: projectTemplateSchema,
    initialValues,
    onSubmit: values => handleSubmit(values),
    validateOnChange: false,
    validateOnBlur: true,
    enableReinitialize: true,
  });

  return (
    <>
      <form onSubmit={formik.handleSubmit}>
        <Grid container spacing={5} maxWidth={1300} minWidth={1000}>
          <Grid item xs={12}>
            <Alert severity="info">
              {`Choose a name and hit save to create a new template. This will save all of your current project tasks for use on a different project. `}
            </Alert>
            <FormControl fullWidth>
              <InputLabel htmlFor="name" shrink>
                Name
              </InputLabel>
              <TextField
                name="name"
                onChange={formik.handleChange}
                value={formik.values.name}
                error={!!formik.errors.name}
                helperText={formik.errors.name}
                variant="outlined"
                disabled={!auth.hasAccess}
              />
            </FormControl>

            <Grid item>
              {auth.hasAccess &&
                (isLoading ? (
                  <>
                    {' '}
                    <Alert severity="warning">
                      {`Creating the new project template. This could take a moment.`}
                    </Alert>
                    <CircularProgress />
                  </>
                ) : (
                  <Button type="submit" color="primary" variant="contained" disabled={isLoading}>
                    Save
                  </Button>
                ))}
            </Grid>
          </Grid>
        </Grid>
      </form>
    </>
  );
};

export default ProjectTemplateForm;
