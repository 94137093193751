import React, { useEffect, useContext } from 'react';
import { thunkDispatch } from 'store/store';
import { getCompanyMaterialsByCompanyId } from '../../redux/actions';
import { CompanyMaterialForm } from './CompanyMaterialForm';
import { ModalContext } from 'sharedComponents/ModalContext';
import { ManageHeader } from 'sharedComponents/ManageHeader';
import { ITruckingCompanyMaterial } from 'lg-helpers/dist/trucking/interfaces/ITruckingCompanyMaterial';
import { CompanyMaterialsList } from './components/CompanyMaterialsList';

export const CompanyMaterials = () => {
  const { handleModal } = useContext(ModalContext);

  useEffect(() => {
    thunkDispatch(getCompanyMaterialsByCompanyId());
  }, []);

  const showModal = (companyMaterial?: ITruckingCompanyMaterial) => {
    handleModal(<CompanyMaterialForm editObj={companyMaterial} />);
  };

  return (
    <>
      <ManageHeader
        headerTitle={'Manage Company Materials'}
        buttonText={'Add Company Material'}
        onButtonClick={showModal}
      />
      <CompanyMaterialsList showModal={showModal} />
    </>
  );
};
