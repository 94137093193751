import React, { useContext, useState } from 'react';
import {
  Typography,
  IconButton,
  SvgIcon,
  Box,
  CircularProgress,
  Tooltip,
  Badge,
} from '@mui/material';
import moment, { MomentInput } from 'moment';
import { ArrowDropDown, ArrowDropUp, Visibility, PrintOutlined } from '@mui/icons-material';
import { ModalContext } from 'sharedComponents/ModalContext';
import AddTicketModal from '../../Tickets/AddTicketModal';
import { thunkDispatch } from 'store/store';
import { getReceiptFromManifest, printTicketReport } from '../../Tickets/redux/actions';
import { DataGrid, GridColDef, GridColumnVisibilityModel } from '@mui/x-data-grid';
import { Refresh } from 'modules/civilAndTrucking/shared/CustomIcons/Refresh';
import { GridDetail } from 'modules/civilAndTrucking/shared/CustomIcons/GridDetail';
import { useSelector } from 'react-redux';
import { selectAuthUser } from 'modules/auth/storeSliceAuth';
import { SMS } from 'modules/civilAndTrucking/shared/CustomIcons/SMS';
import TicketMessages from 'modules/civilAndTrucking/civil/Tickets/TicketMessages';
import { IInvoice } from 'lg-helpers/dist/shared/interfaces/IInvoice';
import { ITicket } from 'lg-helpers/dist/shared/interfaces/ITicket';
import { ITicketForm } from 'lg-helpers/dist/shared/interfaces/ITicketForm';
import { CustomToolbar } from './CustomToolbar';
import { AccountingButtons } from './AccountingButtons';

interface ITableProps {
  tickets?: ITicket[];
  invoice?: IInvoice;
  isTransporter?: boolean;
  isPrinting?: boolean;
  setIsPrinting?: Function;
  isLoading: boolean;
  projectId?: number;
  refresh?: Function;
}

export const ReconciliationTable = ({
  tickets,
  invoice,
  isTransporter,
  isLoading,
  setIsPrinting,
  isPrinting,
  projectId,
  refresh,
}: ITableProps) => {
  const { handleModal } = useContext(ModalContext);
  const [columnVisibilityModel, setColumnVisibilityModel] = useState<GridColumnVisibilityModel>({
    status: !!invoice,
    transporterInvoiceNumber: !invoice,
    supplierInvoiceNumber: !invoice,
    transporter: !invoice,
    sourceSupplier: !invoice,
    locationString: false,
    addressName: false,
  });
  const [manifestLoading, setManifestLoading] = useState<boolean>(false);

  const authUser = useSelector(selectAuthUser);

  const handleClick = (value: ITicketForm) => {
    handleModal(<AddTicketModal selected={value} />);
  };

  const handlePrint = async (ticket: ITicket) => {
    if (setIsPrinting) {
      if (ticket && ticket.id) {
        try {
          setIsPrinting(true);
          const data = await thunkDispatch(
            printTicketReport({
              ticketId: ticket.id,
              userId: authUser.id,
              companyId: ticket.createdByCompanyId!,
            })
          );
          const urlObject = data.payload as { url: URL };
          const newTab = window.open(urlObject.url as URL, '_blank');
          newTab?.focus();
          setIsPrinting(false);
        } catch (err) {
          console.error('Error getting reconciliation report: ', err);
          setIsPrinting(false);
        }
      }
    }
  };

  const tryToGetReceipt = async (data: ITicket) => {
    try {
      setManifestLoading(true);
      await thunkDispatch(getReceiptFromManifest(Number(data.id)));
      setManifestLoading(false);
      if (refresh) {
        refresh();
      }
    } catch (error) {
      console.error('Trying to get manifest receipt id', error);
    } finally {
      setManifestLoading(false);
    }
  };

  const reconciliationColumns: GridColDef[] = [
    {
      field: 'status',
      headerName: 'Status',
      renderHeader: () => <></>,
      width: 130,
      valueFormatter: data =>
        invoice?.isTransporter
          ? data.api.getRow(data.id!)?.transporterInvoiceStatus
          : data.api.getRow(data.id!)?.supplierInvoiceStatus,
      renderCell: data => (
        <AccountingButtons
          ticket={data.row}
          invoice={invoice}
          isTransporter={isTransporter}
          isPrinting={isPrinting}
        />
      ),
    },
    {
      field: 'transporterInvoiceNumber',
      headerName: 'Trucking Invoice #',
      flex: 3,
    },
    {
      field: 'transporter',
      headerName: 'Transporter',
      flex: 2,
    },
    {
      field: 'supplierInvoiceNumber',
      headerName: 'Supplier Invoice #',
      flex: 1,
    },
    {
      field: 'sourceSupplier',
      headerName: 'Supplier',
      flex: 2,
    },
    {
      field: 'dateCreated',
      headerName: 'Date',
      headerAlign: 'center',
      valueFormatter: data =>
        moment(data.api.getRow(data.id!)?.dateCreated).format('MM-DD-YYYY hh:mm a'),
      renderCell: data => <>{moment(data.value as MomentInput).format('MM-DD-YYYY hh:mm A')}</>,
      width: 100,
    },
    {
      field: 'ticketNumber',
      headerName: 'Ticket #',
      headerAlign: 'center',
      align: 'center',
      flex: 1,
    },
    {
      field: 'manifestNumber',
      headerName: 'Manifest #',
      headerAlign: 'center',
      align: 'center',
      flex: 1,
    },
    {
      field: 'pitTicketNumber',
      headerName: 'Pit Ticket #',
      headerAlign: 'center',
      align: 'center',
      flex: 1,
    },
    {
      field: 'wmWeighTicketNumber',
      headerName: 'Weigh Ticket #',
      headerAlign: 'center',
      align: 'center',
      flex: 1.5,
      renderCell: data => {
        return (
          <>
            {data.row.manifestId && (
              <IconButton sx={{ marginRight: '5px' }} onClick={() => tryToGetReceipt(data.row)}>
                <SvgIcon sx={{ fontSize: 24, marginRight: '-2px', marginBottom: '-2px' }}>
                  <Refresh color="secondary" />
                </SvgIcon>
              </IconButton>
            )}
            {data.value || ''}
          </>
        );
      },
    },
    {
      field: 'material',
      headerName: 'Material',
      headerAlign: 'center',
      align: 'center',
      flex: 2,
    },
    {
      field: 'truckNumber',
      headerAlign: 'center',
      align: 'center',
      headerName: 'Truck #',
      flex: 1,
    },
    {
      field: 'quantity',
      headerAlign: 'center',
      align: 'center',
      headerName: 'Qty',
      width: 80,
      flex: 1,
    },
    {
      field: 'messages',
      headerAlign: 'center',
      align: 'center',
      headerName: 'Messages',
      width: 80,
      flex: 1,
      renderCell: data => {
        if (data.row.truckingTicketId) {
          return (
            <IconButton onClick={() => handleModal(<TicketMessages ticketId={data.row.id} />)}>
              {data.row.count ? (
                <Badge color="secondary" badgeContent={data.row.count || 0} max={9}>
                  <SvgIcon sx={{ fontSize: 24, color: 'neutral.main' }}>
                    <SMS />
                  </SvgIcon>
                </Badge>
              ) : (
                <SvgIcon sx={{ fontSize: 24, color: 'neutral.main' }}>
                  <SMS />
                </SvgIcon>
              )}
            </IconButton>
          );
        }
      },
    },
    {
      field: 'price',
      headerAlign: 'right',
      headerName: 'Price',
      align: 'right',
      renderCell: data => `$ ${data.row.price ? Number(data.row.price).toFixed(2) : undefined}`,
      flex: 1,
    },
    {
      field: 'totalPrice',
      headerAlign: 'right',
      headerName: 'Total Price',
      align: 'right',
      renderCell: data => `$ ${data.row.totalPrice ? data.row.totalPrice.toFixed(2) : undefined}`,
      flex: 2,
    },
    {
      field: 'locationString',
      headerName: 'Site',
    },
    {
      field: 'addressName',
      headerName: 'Site Name',
    },
    {
      field: 'details',
      headerName: ' ',
      disableExport: true,
      width: 150,
      renderCell: data => (
        <>
          <Tooltip title={'Print Ticket'}>
            <IconButton onClick={() => handlePrint(data.row)} disabled={isPrinting}>
              <SvgIcon sx={{ fontSize: 24 }}>
                <PrintOutlined />
              </SvgIcon>
            </IconButton>
          </Tooltip>
          {data.row.note && (
            <Tooltip title={data.row.note}>
              <IconButton onClick={() => handleClick(data.row)}>
                <SvgIcon>
                  <GridDetail />
                </SvgIcon>
              </IconButton>
            </Tooltip>
          )}
          <Tooltip title={'View Ticket'}>
            <IconButton onClick={() => handleClick(data.row)} disabled={isPrinting}>
              <SvgIcon sx={{ fontSize: 24 }}>
                <Visibility />
              </SvgIcon>
            </IconButton>
          </Tooltip>
        </>
      ),
    },
  ];

  return (
    <>
      {isLoading || manifestLoading ? (
        <CircularProgress color="inherit" size={100} />
      ) : (
        <>
          <DataGrid
            getRowHeight={() => 'auto'}
            columns={reconciliationColumns}
            columnVisibilityModel={columnVisibilityModel}
            onColumnVisibilityModelChange={newModel => setColumnVisibilityModel(newModel)}
            rows={tickets && projectId ? tickets : []}
            showColumnRightBorder={false}
            disableColumnMenu
            loading={isLoading || isPrinting}
            components={{
              ColumnSortedAscendingIcon: ArrowDropUp,
              ColumnSortedDescendingIcon: ArrowDropDown,
              Toolbar: () => (
                <CustomToolbar
                  tickets={tickets}
                  invoice={invoice}
                  isLoading={isLoading}
                  projectId={projectId}
                />
              ),
              ColumnResizeIcon: () => <div></div>,
              NoRowsOverlay: () => (
                <Box display="flex" flexGrow={1} alignItems="center" justifyContent="center">
                  <Typography>
                    {!projectId
                      ? 'Please select a project to see tickets associated with a specific project.'
                      : 'This project has no tickets with the selected search criteria.'}
                  </Typography>
                </Box>
              ),
            }}
          ></DataGrid>
        </>
      )}
    </>
  );
};
