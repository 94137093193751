import React, { ChangeEvent, useContext, useState } from 'react';
import { Button, Grid, MenuItem, TextField } from '@mui/material';
import { ModalContext } from 'sharedComponents/ModalContext';

export interface IManifestReceipt {
  quantity?: number;
  quantityuom?: string;
  [key: string]: any;
}

// setup for Republic specifically
// see https://livegistics.atlassian.net/browse/FOOT-2019?focusedCommentId=13497
export const UNITS_OF_MEASURE = ['Tons', 'Gallons', 'Drums', 'Yards', 'Loads', 'Barrels', 'Each'];
export interface CreateReceiptFormProps {
  receipt: IManifestReceipt;
  callbackReceipt: (receipt: IManifestReceipt) => void;
}

// TODO: logic with transporters was too complex to make changes, so it will be without refactoring
const CreateReceiptForm = ({ receipt, callbackReceipt }: CreateReceiptFormProps) => {
  const { handleModal } = useContext(ModalContext);
  const [manifestReceipt, setManifestReceipt] = useState<any>({
    quantityuom: 'Tons',
    manuallyEntered: true,
    ...receipt,
  });

  const handleChange = (evt: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = evt?.currentTarget || evt?.target;
    setManifestReceipt({
      ...manifestReceipt,
      [name]: value,
    });
  };

  return (
    <Grid container spacing={2}>
      <Grid item sm={6} xs={12}>
        <TextField
          autoFocus
          onChange={handleChange}
          fullWidth
          label="Quantity"
          name="quantity"
          value={manifestReceipt?.quantity || ''}
          type="number"
          variant="outlined"
        />
      </Grid>
      <Grid item sm={6} xs={12}>
        <TextField
          select
          fullWidth
          name="quantityuom"
          label="Unit of Measure"
          value={manifestReceipt?.quantityuom || ''}
          onChange={handleChange}
          variant="outlined"
        >
          {UNITS_OF_MEASURE.map(unit => (
            <MenuItem value={unit} key={unit}>
              {unit}
            </MenuItem>
          ))}
        </TextField>
      </Grid>
      <Grid item xs={12}>
        <Grid container spacing={2}>
          <Grid item>
            <Button
              color="primary"
              variant="contained"
              size="large"
              onClick={() => {
                callbackReceipt(manifestReceipt);
                handleModal();
              }}
              disabled={false}
            >
              Create Receipt & Sign Manifest
            </Button>
          </Grid>
          <Grid item>
            <Button
              color="primary"
              variant="outlined"
              size="large"
              onClick={() => {
                handleModal();
              }}
              disabled={false}
            >
              Cancel
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default CreateReceiptForm;
