import React, { useState, useEffect, useContext } from 'react';
import { Box, Grid, DialogContent, MenuItem } from '@mui/material';
import { IHelpers } from 'lg-helpers/dist/trucking/interfaces/IHelpers';
import { ITruckingTicket } from 'lg-helpers/dist/trucking/interfaces/ITruckingTicket';
import ModalHeader from 'sharedComponents/ModalHeader';
import { FormTextField } from 'sharedComponents/FormTextField';
import DialogActionContainer from 'sharedComponents/DialogActionContainer';
import { thunkDispatch } from 'store/store';
import { ModalContext } from 'sharedComponents/ModalContext';
import { updateTicket } from 'modules/civilAndTrucking/trucking/features/Tickets/redux/actions';
import { ValidationError } from 'yup';
import { ticketSchema } from 'modules/civilAndTrucking/trucking/schemas';
import { IJoinTruckingDispatch } from 'lg-helpers/dist/trucking/interfaces/IJoinTruckingDispatch';

type EditTicketProps = {
  editObj: ITruckingTicket;
  joinedDispatches: IJoinTruckingDispatch[];
};

export const EditTicketForm = ({ editObj, joinedDispatches }: EditTicketProps) => {
  const [helpers, setHelpers] = useState({} as IHelpers);
  const { handleModal } = useContext(ModalContext);
  const [selectedDispatch, setSelectedDispatch] = useState({} as IJoinTruckingDispatch);
  const [ticket, setTicket] = useState(editObj as ITruckingTicket);

  useEffect(() => {
    if (!joinedDispatches.length || !ticket.dispatchIdTruckingTicket) return;

    const dispatchToSelect = joinedDispatches.find(
      dispatch => dispatch.idTruckingDispatch === ticket.dispatchIdTruckingTicket
    ) as IJoinTruckingDispatch;

    setSelectedDispatch(dispatchToSelect);
  }, [ticket.dispatchIdTruckingTicket, joinedDispatches]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setTicket({ ...ticket, [e.target.name]: e.target.value });
  };

  const handleInvalidSubmit = () => {
    ticketSchema.validate(ticket, { abortEarly: false }).catch((valErrors: ValidationError) => {
      const help = {} as IHelpers;
      valErrors.inner.forEach(err => {
        help[err.path] = err.message;
      });
      setHelpers(help);
    });
  };

  const onSubmit = async () => {
    const isValidTicket = await ticketSchema.isValid(ticket);
    if (!isValidTicket) {
      handleInvalidSubmit();
    } else {
      await thunkDispatch(updateTicket(ticket));
      handleModal();
    }
  };

  return (
    <Box sx={{ p: '0px 16px' }}>
      <ModalHeader title={'Edit Ticket'} />
      <DialogContent>
        <Grid container columnSpacing={2}>
          <Grid item xs={6}>
            <FormTextField
              required
              type="date"
              name="dateTruckingTicket"
              label="Date"
              value={ticket?.dateTruckingTicket}
              helperField={helpers.dateTruckingTicket}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={6}>
            <FormTextField
              required
              name="materialQuantityTruckingTicket"
              label="Material Quantity"
              value={ticket?.materialQuantityTruckingTicket}
              helperField={helpers.materialQuantityTruckingTicket}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={4}>
            <FormTextField
              required
              name="ticketNumberTruckingTicket"
              label="Ticket No."
              value={ticket?.ticketNumberTruckingTicket}
              helperField={helpers.ticketNumberTruckingTicket}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={4}>
            <FormTextField
              required
              name="pitTicketNumberTruckingTicket"
              label="Pit Ticket No."
              value={ticket?.pitTicketNumberTruckingTicket}
              helperField={helpers.pitTicketNumberTruckingTicket}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={4}>
            <FormTextField
              required
              name="truckNumberTruckingTicket"
              label="Truck No."
              value={ticket?.truckNumberTruckingTicket}
              helperField={helpers.truckNumberTruckingTicket}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={3}>
            <FormTextField
              disabled
              name="contractor"
              label="Contractor"
              value={
                selectedDispatch?.name || selectedDispatch?.manualContractorNameTruckingDispatch
              }
            />
          </Grid>
          <Grid item xs={3}>
            <FormTextField
              disabled
              name="dispatchSourceSupplier"
              label="Source Supplier"
              value={
                selectedDispatch?.nameTruckingCompanySourceSupplier ||
                selectedDispatch?.manualMaterialSourceTruckingDispatch
              }
            />
          </Grid>
          <Grid item xs={3}>
            <FormTextField
              disabled
              name="dispatchMaterial"
              label="Material"
              value={
                selectedDispatch?.nameTruckingCompanyMaterial ||
                selectedDispatch?.manualMaterialNameTruckingDispatch
              }
            />
          </Grid>
          <Grid item xs={3}>
            <FormTextField
              disabled
              name="dispatchUoM"
              label="Unit"
              value={
                selectedDispatch?.uomTruckingProjectMaterial ||
                selectedDispatch?.manualMaterialUomTruckingDispatch
              }
            />
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <FormTextField
            required
            name="dispatchIdTruckingTicket"
            label={`Dispatch by Project ${'\u2014'} Material`}
            value={ticket?.dispatchIdTruckingTicket}
            helperField={helpers.dispatchIdTruckingTicket}
            onChange={handleChange}
            selectOptions={joinedDispatches.map(joinedDispatch => (
              <MenuItem
                key={joinedDispatch.idTruckingDispatch}
                value={joinedDispatch.idTruckingDispatch}
              >
                {joinedDispatch.manualProjectNameTruckingDispatch ||
                  joinedDispatch.nameTruckingProject}{' '}
                {`\u2014 `}
                {joinedDispatch?.nameTruckingCompanyMaterial ||
                  joinedDispatch.manualMaterialNameTruckingDispatch}
              </MenuItem>
            ))}
          />
        </Grid>
      </DialogContent>
      <DialogActionContainer saveHandler={onSubmit} editing={!!editObj} />
    </Box>
  );
};
