import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import { auth } from 'services/firebase';

const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;
const staticApiBaseUrl = process.env.REACT_APP_STATIC_API_BASE_URL;

axios.defaults.headers = {
  ...axios.defaults.headers,
  Accept: 'application/json',
  'Content-Type': 'application/json',
};

const getHeaders = async () => {
  const token: string | undefined = await auth?.currentUser?.getIdToken(true);
  const headers = token
    ? {
        Authorization: `Bearer ${token}`,
      }
    : {};
  return headers;
};

export const get = async <T>(path: string, params: object = {}): Promise<T | undefined> => {
  const headers = await getHeaders();
  try {
    const res: AxiosResponse<T> = await axios.get(`${apiBaseUrl}/${path}`, {
      headers,
      params,
    });
    if (res.status === 200 && res.data) {
      return res.data;
    }
  } catch (e) {
    console.error('API GET Error: ', e);
    throw e;
  }
  return;
};

export const getStatic = async <T>(
  path: string,
  params?: AxiosRequestConfig
): Promise<T | undefined> => {
  const headers = await getHeaders();
  try {
    const res: AxiosResponse<T> = await axios.get(`${staticApiBaseUrl}/${path}`, {
      headers,
      ...params,
    });
    if (res.status === 200 && res.data) {
      return res.data;
    }
  } catch (e) {
    console.error('API GET Error: ', e);
    throw e;
  }
  return;
};

export const post = async <T>(path: string, data: T): Promise<T | undefined> => {
  const headers = await getHeaders();
  try {
    const res: AxiosResponse<T> = await axios.post(`${apiBaseUrl}/${path}`, data, { headers });
    if (res.status === 200 && res.data instanceof Object) {
      return res.data;
    }
    return;
  } catch (e) {
    console.error('API POST Error: ', e);
    throw e;
  }
};

export const postStatic = async <T>(path: string, data: T): Promise<T | undefined> => {
  const headers = await getHeaders();
  try {
    const res: AxiosResponse<T> = await axios.post(`${staticApiBaseUrl}/${path}`, data, {
      headers,
    });
    if (res.status === 200 && res.data instanceof Object) {
      return res.data;
    }
    return;
  } catch (e) {
    console.error('API POST Static Error: ', e);
    throw e;
  }
};

export const del = async (path: string) => {
  try {
    const headers = await getHeaders();
    const res: AxiosResponse = await axios.delete(`${apiBaseUrl}/${path}`, {
      headers,
    });

    if (res.status === 200) {
      return res;
    }
    return;
  } catch (e) {
    console.error('API DELETE Error: ', e);
    throw e;
  }
};
