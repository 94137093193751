import React, { useState } from 'react';
import {
  Paper,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableSortLabel,
  TableCell,
  Grid,
  IconButton,
  SvgIcon,
  CircularProgress,
  Box,
} from '@mui/material';
import { EditIcon } from 'modules/civilAndTrucking/shared/CustomIcons/Edit';
import { Delete } from 'modules/civilAndTrucking/shared/CustomIcons/Delete';
import { LinkIcon } from 'modules/civilAndTrucking/shared/CustomIcons/Link';

interface IHeadCell {
  id: string;
  label: string;
}

interface ITableWrapperProps<T> {
  headCells: IHeadCell[];
  data: T[];
  FormComponent?: React.FunctionComponent<any>;
  canEdit?: boolean;
  canDelete?: boolean;
  onSelection?: (event: any, cell: T) => any;
  onActiveSelection?: (event: any, cell: T) => any;
  onDelete?: (cell: T) => any;
  deleteConfirmation?: string;
  canEditPropertyName?: string; // check this property to see if the user can edit the row
  canEditPropertyValue?: string; // check this value of the property to see if the user can edit the row
  isLoading?: boolean;
}

type Order = 'asc' | 'desc';

function TableWrapper<C>({
  headCells,
  data,
  onSelection,
  onDelete,
  onActiveSelection,
  canEdit,
  canDelete,
  deleteConfirmation,
  canEditPropertyName,
  canEditPropertyValue,
  isLoading,
}: ITableWrapperProps<C>) {
  const [tableOrder, setTableOrder] = useState<Order>('asc');
  const [tableOrderBy, setTableOrderBy] = useState<string>('');

  const handleSort = (property: string) => {
    const isAsc = tableOrderBy === property && tableOrder === 'asc';
    setTableOrder(isAsc ? 'desc' : 'asc');
    setTableOrderBy(property);
  };

  function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }

  function getComparator<Key extends keyof any>(
    order: Order,
    orderBy: Key
    // eslint-disable-next-line no-unused-vars
  ): (a: any, b: any) => number {
    return order === 'desc'
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  }

  return (
    <>
      <Grid container>
        <Grid item xs={12}>
          <Paper
            elevation={0}
            sx={{
              flexGrow: 1,
              height: '75vh',
              maxHeight: 1000,
              overflow: 'scroll',
            }}
          >
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  {headCells.map(headCell => {
                    return (
                      <TableCell
                        key={headCell.id}
                        sortDirection={tableOrderBy === headCell.id ? tableOrder : false}
                      >
                        <TableSortLabel
                          active={tableOrderBy === headCell.id}
                          direction={tableOrderBy === headCell.id ? tableOrder : 'asc'}
                          onClick={() => {
                            handleSort(headCell.id);
                          }}
                        >
                          {headCell.label}
                        </TableSortLabel>
                      </TableCell>
                    );
                  })}
                  {canEdit && (
                    <TableCell>
                      {/* This is a placeholder for column containing edit icon */}
                    </TableCell>
                  )}
                  {canDelete && (
                    <TableCell>
                      {/* This is a placeholder for column containing delete icon */}
                    </TableCell>
                  )}
                </TableRow>
              </TableHead>
              {isLoading ? (
                <Box display="flex" justifyContent="center" margin={2}>
                  <CircularProgress />
                </Box>
              ) : (
                <TableBody>
                  {data.sort(getComparator(tableOrder, tableOrderBy)).map((cell: any, index) => {
                    let isEditable = canEdit;
                    if (canEditPropertyName && canEditPropertyValue) {
                      isEditable = cell[canEditPropertyName] === canEditPropertyValue;
                    }

                    return (
                      <TableRow key={index}>
                        {headCells.map((item, i) => {
                          type cellKeys = keyof typeof cell;
                          let cellId = item.id as cellKeys;
                          if (cellId === 'state' || cellId === 'postalCode') {
                            return (
                              <TableCell width={100 / headCells.length / 2 + '%'} key={i}>
                                {cell[cellId]}
                              </TableCell>
                            );
                          }
                          return (
                            <TableCell
                              width={100 / headCells.length + '%'}
                              key={`transporters-cell-${item.id}`}
                            >
                              {cell[cellId]}
                            </TableCell>
                          );
                        })}
                        {canEdit && onSelection && (
                          <TableCell>
                            <IconButton
                              disabled={!isEditable}
                              sx={{ color: 'secondary.main' }}
                              onClick={event => onSelection && onSelection(event, cell)}
                            >
                              <SvgIcon sx={{ fontSize: 24 }} component={EditIcon} />
                            </IconButton>
                          </TableCell>
                        )}
                        {onActiveSelection && (
                          <TableCell>
                            <IconButton onClick={event => onActiveSelection!(event, cell)}>
                              <SvgIcon sx={{ fontSize: 24 }} component={LinkIcon} />
                            </IconButton>
                          </TableCell>
                        )}
                        {canDelete && onDelete && (
                          <TableCell>
                            {deleteConfirmation ? (
                              <IconButton
                                onClick={() =>
                                  window.confirm(deleteConfirmation) && onDelete
                                    ? onDelete(cell)
                                    : null
                                }
                              >
                                <SvgIcon sx={{ fontSize: 24 }} component={Delete} />
                              </IconButton>
                            ) : (
                              <IconButton onClick={() => onDelete && onDelete(cell)}>
                                <SvgIcon sx={{ fontSize: 24 }} component={Delete} />
                              </IconButton>
                            )}
                          </TableCell>
                        )}
                      </TableRow>
                    );
                  })}
                </TableBody>
              )}
            </Table>
          </Paper>
        </Grid>
      </Grid>
    </>
  );
}

export default TableWrapper;
