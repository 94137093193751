import React, { createContext, useState } from 'react';

const MenuContext = createContext<any>({
  showMenu: false,
  setShowMenu: () => console.warn('MenuContext is not initialized properly'),
});

type TProps = React.PropsWithChildren<{}>;

const MenuProvider = (props: TProps) => {
  const [showMenu, setShowMenu] = useState<boolean>(false);

  return (
    <MenuContext.Provider value={{ showMenu, setShowMenu }}>{props.children}</MenuContext.Provider>
  );
};
export { MenuContext, MenuProvider };
