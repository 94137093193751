import React, { useState, useEffect } from 'react';
import CrudTable from 'sharedComponents/CrudTable';
import { useNavigate } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import { useSelector, useDispatch } from 'react-redux';
import { Typography } from '@mui/material';
import Warning from '@mui/icons-material/Warning';
import HtmlTooltip from 'sharedComponents/HtmlTooltip';
import { useConfirm } from 'material-ui-confirm';
import ToolbarManifests from './ToolbarManifestsPerScale';
import Query from 'services/firebase/Query';
import Executer from 'services/firebase/Executer';
import { getDatesForQuery } from 'sharedComponents/ManifestsViewPerProject';
import {
  setDigitalFilter,
  selectDigitalFilter,
  setQCFilters,
  selectQCFilters,
} from 'redux/storeSliceManifest';
import { selectScale, setScale } from 'modules/scales/storeSliceScales';
import useShowError from 'modules/errors';
import Command from 'services/firebase/Command';
import { chunk } from 'lodash';
import { usePermissionsContext } from 'services/permissions';
import { ValueType as DateRangeValueType } from 'rsuite/lib/DateRangePicker';
import { mixPanel } from 'services/mixpanel';
import moment from 'moment';
import QueryBase from 'lg-helpers/dist/firestore/query/QueryBase';

const command = new Command();

const query = new Query();
const executer = new Executer();

const validateScale = (manifest: any, scale: any) => {
  if (!manifest) {
    return { valid: false };
  }

  const scaleId = scale?.id;
  const driverScaleId = manifest?.signatureDriver?.scale?.id;
  const projectScaleIds = manifest.project.scales.map((s: any) => s.id);

  if (scaleId === driverScaleId) {
    return { valid: true };
  }

  if (projectScaleIds.includes(scaleId)) {
    return {
      valid: false,
      driverScaleName: manifest?.signatureDriver?.scale?.name,
      saScaleName: scale?.name,
    };
  }

  return { valid: null };
};

export interface ManifestsViewPerScaleProps {
  scaleId: string;
  manifestColumns: any[]; // TODO: reuse type from CrudTable
  showOnlyIncoming?: boolean;
  showOnlyRejected?: boolean;
  showOnlyAttendantsScale?: boolean;
  showOnlyQCManifests?: boolean;

  handleRowClickCallback?: (manifest: any) => any; // TODO: reuse manifest type
  defaultFilterIsUsedBetween?: DateRangeValueType;
  showIncomingAndProcessed?: boolean;
  showBatchTrailerNumbers?: boolean;
  showClaimed?: boolean;
}

const ManifestsViewPerScale = ({
  scaleId,
  manifestColumns,
  showOnlyIncoming,
  showOnlyRejected,
  showOnlyAttendantsScale,
  showOnlyQCManifests,
  handleRowClickCallback,
  defaultFilterIsUsedBetween,
  showIncomingAndProcessed,
  showBatchTrailerNumbers = false,
  showClaimed = false,
}: ManifestsViewPerScaleProps) => {
  const { user } = usePermissionsContext();
  const navigate = useNavigate();
  const [manifests, setManifests] = useState([] as any[]);
  const [batches, setBatches] = useState(new Map<string, any>());
  const [manifestsWithTrailer, setManifestsWithTrailer] = useState([] as any[]);
  const showError = useShowError();
  const [manifestSearchFilter, setManifestSearchFilter] = useState(null as any);
  const [isLoadingData, setIsLoadingData] = useState(false);
  const [manifestsPaginationInfo, setManifestsPaginationInfo] = useState({
    id: uuidv4(),
    lastVisibleDoc: null,
    isNext: null,
  } as any);
  const [dateRangeFilter, setDateRangeFilter] = useState(defaultFilterIsUsedBetween);
  const [selectedManifestsIds, setSelectedManifestsIds] = useState([]);
  const [filterIsDigital, setFilterIsDigital] = useState(null);
  const [validationColumn, setValidationColumn] = useState(null as any);
  const dispatch = useDispatch();
  const digitalFilter = useSelector(selectDigitalFilter);
  const scale = useSelector(selectScale);
  const confirm = useConfirm();
  const qcFilters = useSelector(selectQCFilters);

  useEffect(() => {
    setFilterIsDigital(digitalFilter?.isDigital);
    if (showOnlyQCManifests) {
      if (qcFilters) {
        if ('filterQuery' in qcFilters) {
          setManifestSearchFilter(qcFilters.filterQuery);
        }
        if ('filterDigital' in qcFilters) {
          setFilterIsDigital(qcFilters.filterDigital);
        }
        if ('filterDateRange' in qcFilters) {
          setDateRangeFilter(qcFilters.filterDateRange);
        }
      }
    }
  }, [digitalFilter, qcFilters, showOnlyQCManifests]);

  const getQueryOfManifests = (propertyToSearch?: any, showClaimedOnly: boolean = false) => {
    const searchFilterLower = manifestSearchFilter?.trim() || '';

    return query.manifests.getManifests(
      searchFilterLower,
      propertyToSearch || null,
      false,
      null,
      user,
      true,
      dateRangeFilter || false,
      false,
      manifestsPaginationInfo,
      scaleId,
      showOnlyIncoming,
      showIncomingAndProcessed,
      showOnlyAttendantsScale,
      'all',
      showOnlyQCManifests && !searchFilterLower ? 1000 : 100,
      showClaimedOnly
    );
    // const queryManifests = (search: any, showOnlyClaimed = false) => {};

    // return showClaimed
    //   ? [...queryManifests(propertyToSearch), ...queryManifests(propertyToSearch, showClaimedOnly)]
    //   : queryManifests(propertyToSearch);
  };

  const getQueryOfBatches = (manifestIds: string[]) =>
    query.batchManifests.getBatchesByManifestIds(manifestIds);

  const getQueriesOfBatches = (manifestResults: any[]) => {
    const batchIds = manifestResults.map(m => m.batchId).filter(id => id);
    const batchIdBatches = chunk(batchIds, 10);
    const fetchedBatches: any[] = [];
    for (let i = 0; i < batchIdBatches.length; i++) {
      fetchedBatches.push(getQueryOfBatches(batchIdBatches[i]));
    }
    return fetchedBatches;
  };

  const getQueriesOfManifests = () => {
    const valuesToSearch = [];
    if (manifestSearchFilter) {
      valuesToSearch.push(
        'signatureDriver.driverName',
        'signatureDriver.truckNumber',
        'signatureDriver.truckingCompany',
        'signatureDriver.secondaryTruckingCompany'
      );
      if (manifestSearchFilter.length >= 6) {
        valuesToSearch.push('number', 'project.profileNr');
      }
    }
    if (!valuesToSearch.length) valuesToSearch.push('');
    return [
      ...valuesToSearch.map(value => getQueryOfManifests(value)),
      ...(showClaimed ? valuesToSearch.map(value => getQueryOfManifests(value, showClaimed)) : []),
    ];
  };

  useEffect(() => {
    setIsLoadingData(true);
    let unsubscribe: any;
    let unsubscribes: any;
    let batchUnsubs: any;

    const onSuccessCallback = (manifs: any) => {
      let results = manifs;
      if (showOnlyRejected) {
        results = results.filter((manif: any) => manif.signatureScale?.isRejected);
      } else if (showOnlyRejected === false) {
        results = results.filter((manif: any) => !manif.signatureScale?.isRejected);
      }

      if (filterIsDigital) {
        results = results.filter((manif: any) => manif.signatureDriver?.email);
      } else if (filterIsDigital === false) {
        results = results.filter((manif: any) => !manif.signatureDriver?.email);
      }
      if (showOnlyQCManifests) {
        if (scaleId === 'eOceQR7NRwa1qSlUWykv') { //#585 in V2 Tickets Project: this checks to see if the scale is Oxy Landfarm, which doesn't actually have a physical scale, so all will be 0, and should not be QC issues.
          results = results.filter(
            (manif: any) => !manif?.signatureScale?.isSigned
          );
        }else{
          results = results.filter(
            (manif: any) => !manif?.signatureScale?.isSigned || !manif?.receipt?.weight?.rounded_net
          );
        }
      }
      // filter by date after query LM-554
      if (dateRangeFilter && dateRangeFilter.length > 1 && manifestSearchFilter) {
        const [startDate, endDate] = getDatesForQuery(dateRangeFilter);
        results = results.filter((manif: any) => {
          return (
            (manif.lastUsedAt || manif.createdAt) &&
            ((manif.lastUsedAt.toDate() >= startDate && manif.lastUsedAt.toDate() <= endDate) ||
              (manif.createdAt.toDate() >= startDate && manif.createdAt.toDate() <= endDate)) &&
            (manif?.signatureDriver?.isSigned ||
              manif.lastUsedAt.toDate() >= manif.createdAt.toDate())
          );
        });
      }

      results = results.sort((a: any, b: any) => a.lastUsedAt.toDate() - b.lastUsedAt.toDate());

      if (results.length > 100) {
        results = results.slice(0, 100);
      }

      setManifests(results);
      setIsLoadingData(false);

      if (showBatchTrailerNumbers) {
        setIsLoadingData(true);
        batchUnsubs = executer.getMultipleDocumentsOnMultipleQueries(
          getQueriesOfBatches(results),
          onBatchSuccessCallback,
          onErrorCallback
        );
      }
    };

    const onBatchSuccessCallback = (queriedBatches: any) => {
      const batchMap = new Map<string, any>();
      queriedBatches.forEach((batch: any) => batchMap.set(batch.id, batch));
      setBatches(batchMap);
      setIsLoadingData(false);
    };

    const onErrorCallback = (err: any) => {
      showError({ title: 'Error fetching manifests', duration: 10000 });
      setIsLoadingData(false);
      console.error('Error', err);
    };

    if (manifestSearchFilter || showClaimed) {
      unsubscribes = executer.watchMultipleDocumentsOnMultipleQueries(
        getQueriesOfManifests(),
        onSuccessCallback,
        onErrorCallback
      );
    } else {
      unsubscribe = executer.watchMultipleDocuments(
        getQueryOfManifests(null, showClaimed),
        onSuccessCallback,
        onErrorCallback
      );
    }

    return () => {
      if (unsubscribe) unsubscribe();
      if (unsubscribes) unsubscribes.forEach((unsub: any) => unsub());
      if (batchUnsubs) batchUnsubs.forEach((unsub: any) => unsub());
    };

    // eslint-disable-next-line
  }, [
    manifestSearchFilter,
    user,
    scaleId,
    dateRangeFilter,
    manifestsPaginationInfo.id,
    showOnlyAttendantsScale,
    showOnlyIncoming,
    showOnlyQCManifests,
    filterIsDigital,
  ]);

  const loadNextManifests = async () => {
    let lastVisibleDoc = null;
    if (manifests.length) {
      lastVisibleDoc = await executer.getSingleDocumentSnapshot(
        query.base.getById(QueryBase.MANIFESTS_COLLECTION(), manifests[manifests.length - 1].id)
      );
    }
    setManifestsPaginationInfo({
      ...manifestsPaginationInfo,
      id: uuidv4(),
      isNext: true,
      lastVisibleDoc,
    });
  };

  useEffect(() => {
    if (!scaleId) {
      navigate('/scales/verify-manifest'); // Why verify manifest screen?
      return;
    }

    const getScaleById = async () => {
      try {
        // was watch before (not get)
        const scaleResult = await executer.getSingleDocument(
          query.base.getById(QueryBase.SCALES_COLLECTION(), scaleId)
        );
        dispatch(setScale(scaleResult));
      } catch (error) {
        console.error('Error', error);
        showError({
          title: 'Error while fetching the scale',
          duration: 10000,
        });
      }
    };
    getScaleById();
  }, [scaleId, dispatch, navigate, showError]);

  useEffect(() => {
    if (!manifestSearchFilter || !scale) {
      setValidationColumn(null);
      return;
    }

    const column = {
      path: 'id',
      label: '',
      align: 'center',
      hasCustomClickHandler: true,
      getCellValueCallback: (row: any) => {
        const result = validateScale(row, scale);

        if (result.valid) {
          return null;
        } else {
          return (
            <HtmlTooltip
              title={<Typography>Enroute to: {row.signatureDriver?.scale?.name}</Typography>}
            >
              <Warning color="error" />
            </HtmlTooltip>
          );
        }
      },
    };
    setValidationColumn(column);
  }, [manifestSearchFilter, scale]);

  const loadPreviousManifests = async () => {
    let lastVisibleDoc = null;
    if (manifests.length) {
      lastVisibleDoc = await executer.getSingleDocumentSnapshot(
        query.base.getById(QueryBase.MANIFESTS_COLLECTION(), manifests[0].id)
      );
    }
    setManifestsPaginationInfo({
      ...manifestsPaginationInfo,
      id: uuidv4(),
      isNext: false,
      lastVisibleDoc,
    });
  };

  useEffect(() => {
    const batchManifs = manifests.map(m => {
      return {
        trailerNumber: batches.get(m?.batchId)?.trailerNumber ?? null,
        ...m,
      };
    });

    setManifestsWithTrailer(batchManifs);
    // eslint-disable-next-line
  }, [batches]);

  const handleClickManifest = async (manifest: any) => {
    const result = validateScale(manifest, scale);

    if (result.valid === false) {
      try {
        await confirm({
          title: 'Warning',
          content: `This manifest was selected to go to ${result.driverScaleName}, would you like to transfer it to ${result.saScaleName}?`,
        });
        await command.manifests.updateManifestDriverScale(manifest.id, scale);
        if (handleRowClickCallback) {
          handleRowClickCallback(manifest);
        } else {
          goToManifest(manifest);
        }
      } catch (err) {
        console.error(err);
      }
    } else if (result.valid === null) {
      try {
        await confirm({
          title: '',
          content: 'This is not an approved landfill. Please contact your landfill administrator.',
          confirmationButtonProps: { hidden: true, disabled: true },
        });
      } catch (err) {
        console.error(err);
      }
    } else {
      if (handleRowClickCallback) {
        handleRowClickCallback(manifest);
      } else {
        goToManifest(manifest);
      }
    }
  };

  const goToManifest = (manifest: any) => navigate(`/manifests/${manifest.id}`);

  const callbackSetDateRangeFilter = (value: any) => {
    // Defined in [FOOT-1509]{@link https://livegistics.atlassian.net/browse/FOOT-1509}
    // Set date range to 30 days before now if cleared out.
    if (!value) value = calculate30DaysBeforeNow();

    setDateRangeFilter(value);
    if (showOnlyQCManifests) {
      dispatch(setQCFilters({ ...(qcFilters || {}), filterDateRange: value }));
    }

    setManifestsPaginationInfo({
      ...manifestsPaginationInfo,
      isNext: null,
      lastVisibleDoc: null,
    });
  };

  const calculate30DaysBeforeNow = () => {
    const currentDate = moment();
    const startDate = currentDate.clone().subtract(30, 'days').startOf('day');
    const endDate = currentDate.clone().endOf('day');

    return [startDate.toDate(), endDate.toDate()];
  };

  const handleChangeDigitalFilter = (filter: any) => {
    dispatch(setDigitalFilter(filter));
    if (showOnlyQCManifests) {
      dispatch(setQCFilters({ ...(qcFilters || {}), filterDigital: filter.isDigital }));
    }
  };

  const handleChangeSearchQuery = (value: any) => {
    setManifestSearchFilter(value);
    if (showOnlyQCManifests) {
      dispatch(setQCFilters({ ...(qcFilters || {}), filterQuery: value }));
    }
  };

  const voidSelectedManifests = async () => {
    try {
      await command.manifests.voidManifests(user, selectedManifestsIds);
      mixPanel(user, 'Manifest Voided in Scale QC', {
        'Voided Manifests': selectedManifestsIds.join(', ') || '',
        'Scale Id': scale?.id || '',
        'Scale Company Id': scale?.companyId || '',
        'Scale Address': scale?.address || '',
        'Scale City': scale?.city || '',
        'Scale State': scale?.state?.name || '',
        'Scale Region Id': scale?.regionId || '',
      });
      setSelectedManifestsIds([]);
    } catch {
      showError({ title: 'Void manifests error', duration: 10000 });
    }
  };

  return (
    <CrudTable
      rows={showBatchTrailerNumbers ? manifestsWithTrailer : manifests}
      columns={validationColumn ? [validationColumn, ...manifestColumns] : manifestColumns}
      isLoading={isLoadingData}
      handleRowClickCallback={handleClickManifest}
      rowIsDisabledCallback={row => row.isVoid}
      setSelectedRowsIdsCallback={showOnlyQCManifests ? setSelectedManifestsIds : null}
      selectedRowsIds={selectedManifestsIds}
    >
      <ToolbarManifests
        scaleId={scaleId}
        showOnlyIncoming={showOnlyIncoming}
        searchQuery={manifestSearchFilter}
        onSearchCallback={handleChangeSearchQuery}
        isLoading={isLoadingData}
        handleClickNextCallback={loadNextManifests}
        handleClickBackCallback={loadPreviousManifests}
        dateRangeFilter={dateRangeFilter}
        setDateRangeFilterCallback={callbackSetDateRangeFilter}
        filterIsDigital={filterIsDigital}
        onChangeDigitalFilter={handleChangeDigitalFilter}
        handleClickVoidCallback={showOnlyQCManifests ? voidSelectedManifests : undefined}
        selectedRowsIds={selectedManifestsIds}
      />
    </CrudTable>
  );
};

export default ManifestsViewPerScale;
