import { useState, useEffect } from 'react';
import Query from 'services/firebase/Query';
import Executer from 'services/firebase/Executer';
import useShowError from 'modules/errors';
import api, { IJoinCompany } from 'services/api/autogenerated';
import QueryBase from 'lg-helpers/dist/firestore/query/QueryBase';

const query = new Query();
const executer = new Executer();

const useGetContractorCompanyByProjectId = (projectId: string | null | undefined) => {
  const [company, setCompany] = useState<IJoinCompany | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const showError = useShowError();

  // Additional requests to our DB to gather more information for receipt template (FOOT-1823)
  useEffect(() => {
    setCompany(null);
    if (!projectId) return;

    const load = async () => {
      setIsLoading(true);
      try {
        const project = await executer.getSingleDocument(
          query.base.getById(QueryBase.PROJECTS_COLLECTION(), projectId)
        );

        if (!project) {
          console.error('Failed to find project by id', projectId);
          showError({
            title: 'Failed to find project',
            duration: 10000,
          });
          return;
        }

        if (!project.contractorCompany?.id) {
          console.warn('Contractor company is not assigned to a project', project.id);
        }

        const { data } = await api.civilCompanies.getCompanyById(project.contractorCompany.id);

        if (!data) {
          console.error(
            'Failed to find contractor company by company id',
            project.contractorCompany.id
          );
          showError({
            title: 'Failed to find contractor company',
            duration: 10000,
          });
        }

        setCompany(data);
      } catch (err) {
        console.error('Failed to fetch contractor company', err);
        // TODO: revert back in future
        // showError({
        //   title: 'Failed to fetch contractor company',
        //   duration: 10000,
        // });
      } finally {
        setIsLoading(false);
      }
    };

    load();
  }, [projectId, showError]);

  return { company, isLoading };
};

export default useGetContractorCompanyByProjectId;
