import React from 'react';
import { Link } from 'react-router-dom';
import { Container, Box, Typography, List, ListItem, ListItemIcon } from '@mui/material';
import GroupIcon from '@mui/icons-material/Group';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import SpeedIcon from '@mui/icons-material/Speed';
import { FlagCircle, SettingsInputComponent } from '@mui/icons-material';

const adminNavItems = [
  {
    id: 'export-users',
    icon: GroupIcon,
    url: '/super-admin/administration/export-users/',
    txt: 'Export Users',
  },
  {
    id: 'verify-search-users',
    icon: CheckBoxIcon,
    url: '/super-admin/administration/verify-search-user/',
    txt: 'Verify User',
  },
  {
    id: 'reconcile',
    icon: SpeedIcon,
    url: '/super-admin/administration/reconcilliation/',
    txt: 'Reconcile',
  },
  {
    id: 'company-feature-flags',
    icon: FlagCircle,
    url: '/super-admin/administration/company-feature-flag/',
    txt: 'Company Feature Flags',
  },
  {
    id: 'global-custom-inputs',
    icon: SettingsInputComponent,
    url: '/super-admin/administration/global-custom-inputs/',
    txt: 'Global Custom Inputs',
  },
];

const AdministrationView = () => (
  <Container>
    <Box padding="10px">
      <Typography variant="h3">Administration</Typography>
      <List>
        {adminNavItems.map(navItem => (
          <ListItem key={navItem.id}>
            <ListItemIcon>
              <navItem.icon />
            </ListItemIcon>
            <Link to={navItem.url} color="inherit">
              <Typography>{navItem.txt}</Typography>
            </Link>
          </ListItem>
        ))}
      </List>
    </Box>
  </Container>
);

export default AdministrationView;
