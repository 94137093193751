import * as React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { store } from './store/store';
import { BrowserRouter } from 'react-router-dom';
import * as serviceWorker from './serviceWorker';
import App from './App';
import { Buffer } from 'buffer';
import mixpanel from 'mixpanel-browser';
window.Buffer = Buffer;

mixpanel.init('a322b97529f48c5c2ac4ecfa0a2171c2', {
  persistence: 'localStorage',
});

const container = document.getElementById('root') as HTMLElement;
const root = createRoot(container);

root.render(
  <BrowserRouter>
    <Provider store={store}>
      <App />
    </Provider>
  </BrowserRouter>
);

serviceWorker.unregister();
