import React from 'react';
import { GridColDef } from '@mui/x-data-grid';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ReportIcon from '@mui/icons-material/Report';
import NotInterestedIcon from '@mui/icons-material/NotInterested';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import ButtonLink from './ButtonLink';
import { Tooltip } from '@mui/material';

export const generateProfilesColumns = () => {
  return [
    {
      field: 'profileNr',
      headerName: 'Profile #',
      width: 180,
    },
    {
      field: 'name',
      headerName: 'Name',
      flex: 4,
    },
    {
      field: 'scaleLogo',
      headerName: '',
      sortable: false,
      width: 100,
      align: 'center',
      headerAlign: 'center',
      renderCell: (params: any) =>
        params.row?.scaleCompany?.companyLogo ? (
          <Tooltip title={`Scale Company: ${params.row?.scaleCompany?.name}`}>
            <img className="profiles__company_logo" src={params.row?.scaleCompany?.companyLogo} />
          </Tooltip>
        ) : (
          ''
        ),
    },
    {
      field: 'isActive',
      headerName: 'Active',
      sortable: false,
      width: 100,
      align: 'center',
      headerAlign: 'center',
      renderCell: (params: any) =>
        !params.row?.isActiveCustomApi ? (
          <Tooltip title="Possible connection issue">
            <ReportIcon color="error" />
          </Tooltip>
        ) : params.row?.isActive ? (
          <Tooltip title="Active">
            <CheckCircleIcon color="primary" />
          </Tooltip>
        ) : (
          <Tooltip title="Not active">
            <NotInterestedIcon color="error" />
          </Tooltip>
        ),
    },
    {
      field: '',
      headerName: 'Map',
      sortable: false,
      width: 100,
      align: 'center',
      headerAlign: 'center',
      renderCell: (params: any) => (
        <ButtonLink
          url={`/projects/${params.row.id}/map`}
          Icon={<LocationOnIcon sx={{ pointerEvents: 'none' }} fontSize="small" />}
        />
      ),
    },
  ] as GridColDef[];
};
