import React, { PropsWithChildren, useContext } from 'react';
import { DialogContent } from '@mui/material';
import { IUser } from 'lg-helpers/dist/shared/interfaces/IUser';
import { componentAllowedPerms } from 'services/permissions';
import { addUser, editUser } from 'modules/civilAndTrucking/civil/Users/redux/actions';
import { thunkDispatch } from 'store/store';

import { ModalContext } from 'sharedComponents/ModalContext';
import DialogHeader from 'sharedComponents/DialogHeader';
import { IUserCreation } from 'lg-helpers/dist/shared/interfaces/IUserCreation';
import UserForm from 'sharedComponents/UserForm';

interface IAddUserFormProps {
  handleClose?: () => void;
  handleComplete?: Function;
  editObj?: IUser;
}

export const AddUserForm = componentAllowedPerms(['users:create', 'users:update'])(
  ({ handleClose, handleComplete, editObj }: PropsWithChildren<IAddUserFormProps>) => {
    const { handleModal } = useContext(ModalContext);

    const handleCloseModal = () => handleModal();

    const handleSubmit = async (values: IUserCreation) => {
      try {
        const result = values.id
          ? await thunkDispatch(editUser(values as IUser))
          : await thunkDispatch(addUser(values as IUser));
        if (result.meta.requestStatus === 'fulfilled') {
          if (handleComplete) handleComplete();
          else handleCloseModal();
          return;
        }
      } catch (err) {
        console.error(err);
      } finally {
        if (handleClose) handleClose();
        else handleCloseModal();
      }
    };

    return (
      <>
        <DialogHeader title={`${editObj ? 'Update' : 'Add'} User`} />
        <DialogContent>
          <UserForm
            userInfo={editObj as IUserCreation}
            savePosition="bottom"
            submitSuccessCallback={handleSubmit}
            handleClose={handleCloseModal}
          />
        </DialogContent>
      </>
    );
  }
);

export default AddUserForm;
