import React, { useCallback, useState, useEffect } from 'react';
import { Grid, Button, Typography, TextField, Box, CircularProgress } from '@mui/material';
import { Link } from 'react-router-dom';
import useShowError from 'modules/errors';
import { auth, authConstructor } from 'services/firebase';
import InputMasker from 'sharedComponents/InputMasker';
import firebase from 'firebase/compat/app';
import { LoginMethod } from './LoginView';

const PhoneSignInView = ({
  setPreferredLoginMethod,
}: {
  setPreferredLoginMethod: (method: LoginMethod) => any;
}) => {
  const [phoneNumber, setPhoneNumber] = useState('');
  const [phoneCodeVisibility, setPhoneCodeVisibility] = useState(false);
  const [mobileCode, setMobileCode] = useState('');
  const [isLoginInProgress, setIsLoginInProgress] = useState(false);
  const showError = useShowError();

  const [confirmationCodeResult, setConfirmationCodeResult] = useState<
    firebase.auth.ConfirmationResult | undefined
  >(undefined);

  let appRecaptchaVerifier: firebase.auth.RecaptchaVerifier;

  useEffect(() => {
    let url = new URL(window.location.href);
    const userPhone = `+${url.searchParams.get('userPhone')?.trim() || ''}`;
    if (userPhone) setPhoneNumber(userPhone);
  }, []);

  const handleGetCode = (event: React.MouseEvent<HTMLButtonElement>) => {
    event && event.preventDefault();

    setIsLoginInProgress(true);
    try {
      appRecaptchaVerifier = new authConstructor.RecaptchaVerifier('captcha-container', {
        size: 'invisible',
      });

      auth
        .signInWithPhoneNumber(phoneNumber, appRecaptchaVerifier)
        .then(confirmationResult => {
          setPhoneCodeVisibility(true);
          setConfirmationCodeResult(confirmationResult);
        })
        .catch(error => {
          console.error('signing in with phone number error', error);
          showError({ title: 'Login error', text: error.message });
        });
    } catch (err: any) {
      console.error('Login error', err);
      appRecaptchaVerifier.clear();

      showError({
        title: 'Login error',
        text: err.message,
      });
    }
    setIsLoginInProgress(false);
  };

  const handleSubmitPhoneSignIn = useCallback(
    async (event: React.MouseEvent<HTMLButtonElement>) => {
      event && event.preventDefault();
      if (!confirmationCodeResult) return;
      confirmationCodeResult
        .confirm(mobileCode)
        .then(() => {
          // Auth listener will take it from here
          setIsLoginInProgress(false);
        })
        .catch(error => {
          showError({
            title: 'Login error',
            text: error.message,
          });
        });
    },
    [confirmationCodeResult, mobileCode, showError]
  );

  return (
    <>
      <Grid item xs={12}>
        <InputMasker
          fullWidth
          val={phoneNumber}
          name="phoneNumber"
          variant="outlined"
          mask="+###########"
          label="Your phone number"
          onChange={val => setPhoneNumber(val as string)}
        />
      </Grid>
      <Grid item xs={12}>
        {phoneCodeVisibility && (
          <TextField
            fullWidth
            label="Code from SMS"
            name="phoneCode"
            onChange={e => {
              setMobileCode(e.target.value);
            }}
            type="text"
            value={mobileCode}
            variant="outlined"
          />
        )}
      </Grid>
      <Grid item xs={6}>
        <Button
          color="primary"
          onClick={phoneCodeVisibility ? handleSubmitPhoneSignIn : handleGetCode}
          fullWidth
          size="large"
          type="submit"
          variant="contained"
        >
          {phoneCodeVisibility ? 'Log in with phone' : 'Get Code'}
          {isLoginInProgress && (
            <Box marginLeft={2}>
              <CircularProgress color="inherit" size={25} />
            </Box>
          )}
        </Button>
      </Grid>
      <Grid item xs={6}>
        <Button
          fullWidth
          color="inherit"
          size="large"
          type="submit"
          variant="contained"
          onClick={() => setPreferredLoginMethod('')}
        >
          Back
        </Button>
      </Grid>
      <Grid item xs={12}>
        <Link to="/login">
          <Button
            color="inherit"
            fullWidth
            size="large"
            type="submit"
            variant="text"
            // TODO: Auth0: revert this
            // onClick={() => setPreferredLoginMethod('single_email')}
            onClick={() => setPreferredLoginMethod('email')}
          >
            <Typography variant="h5">Log in using email</Typography>
          </Button>
        </Link>
      </Grid>
      <Grid item xs={12}>
        <div id="captcha-container" />
      </Grid>
    </>
  );
};

export default PhoneSignInView;
