import { createAsyncThunk } from '@reduxjs/toolkit';
import { del, get } from 'services/api/restHelpers';
import { INotification } from 'modules/civilAndTrucking/shared/NotificationSnackbar/INotification';
import { pushNotification } from 'modules/civilAndTrucking/shared/NotificationSnackbar/redux/actions';
import {
  IProjectMaterial,
  IBaseProjectMaterial,
} from 'lg-helpers/dist/shared/interfaces/IProjectMaterial';
import api from 'services/api/autogenerated';

export const getProjectMaterials = createAsyncThunk(
  'projectMaterials/get-project-materials',
  async (search: { projectId: number; type: string }, thunkApi) => {
    try {
      const data = await get(`civil/projects/${search.projectId}/materials?type=${search.type}`);
      return data as IProjectMaterial[];
    } catch (err) {
      const errorNotifcation: INotification = {
        status: 'error',
        message: 'Error: Unable to get project materials',
      };
      thunkApi.dispatch(pushNotification(errorNotifcation));
      throw err;
    }
  }
);

type TProfileIdPayload = {
  contractorCompanyId: string;
  scaleCompanyId: string;
};

export const getProfileIds = createAsyncThunk(
  'projectMaterials/get-profile-ids',
  async (payload: TProfileIdPayload, thunkApi) => {
    try {
      const { contractorCompanyId, scaleCompanyId } = payload;
      const data = await get(
        `civil/manifest-projects/profile-numbers/contractor-company/${contractorCompanyId}/scale-company/${scaleCompanyId}`
      );
      return data;
    } catch (err) {
      const errorNotification: INotification = {
        status: 'error',
        message: 'Error: Unable to get profile IDs',
      };
      thunkApi.dispatch(pushNotification(errorNotification));
      throw err;
    }
  }
);

export const archiveAndUpdateProjectMaterial = createAsyncThunk(
  'projectMaterials/archive-and-update',
  async (payload: IBaseProjectMaterial, thunkApi) => {
    try {
      // TO DO: get dynamic project Id
      const { data } = await api.civilProjects.addProjectMaterial(payload.projectId!, payload);
      const successNotification: INotification = {
        status: 'success',
        message: 'Success: Project materials have been updated',
      };
      thunkApi.dispatch(pushNotification(successNotification));
      return data;
    } catch (err) {
      const errorNotification: INotification = {
        status: 'error',
        message: 'Error: Unable to update project materials',
      };
      thunkApi.dispatch(pushNotification(errorNotification));
      throw err;
    }
  }
);

export const deleteProjectMaterial = createAsyncThunk(
  'projectMaterials/delete-project-material',
  async (payload: IProjectMaterial, thunkApi) => {
    try {
      const result = await del(`civil/projects/${payload.projectId}/materials/${payload.id}`);
      const successNotification: INotification = {
        status: 'success',
        message: 'Success: This project material is now set to expired and can no longer be used. ',
      };
      thunkApi.dispatch(pushNotification(successNotification));
      return result;
    } catch (err) {
      const errorNotification: INotification = {
        status: 'error',
        message: 'Error: Unable to remove project material',
      };
      thunkApi.dispatch(pushNotification(errorNotification));
      throw err;
    }
  }
);
