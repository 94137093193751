import { useMediaQuery, useTheme } from '@mui/material';
import React from 'react';
import { IMenuItem, SideBar } from '../SideBar';
import { TopBar } from '../TopBar';

export interface IAppNavProperties {
  navItems: IMenuItem[];
  settingsPath: string;
  logoComponent: JSX.Element;
}

export const AppNav = ({ navItems, settingsPath, logoComponent }: IAppNavProperties) => {
  const theme = useTheme();
  const isWidthXSorSM = useMediaQuery(theme.breakpoints.down('md'));
  return (
    <>
      <SideBar navItems={navItems} drawerOpenedByDefault={!isWidthXSorSM} />
      <TopBar
        settingsPath={settingsPath}
        logoComponent={logoComponent}
        isWidthXSorSM={isWidthXSorSM}
      />
    </>
  );
};
