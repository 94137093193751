import { boolean, date, number, object, string } from 'yup';
import moment from 'moment';
import { IProjectMaterial } from 'lg-helpers/dist/shared/interfaces/IProjectMaterial';

export const projectMaterialsSchema = object<IProjectMaterial>().shape({
  id: number().nullable(),
  materialId: number().required('Required').typeError('Please select an option'),
  sourceSupplierId: string().required('Required').typeError('Please select an option'),
  requireGeneratorSignature: boolean(),
  testFrequency: number().nullable(),
  profileId: string().nullable(),
  supplierMaterialId: string().nullable(),
  profileType: string().nullable(),
  units: string().required('Required').typeError('Required'),
  splitCost: boolean(),
  materialCost: number().when('splitCost', {
    is: true,
    then: number().required('Required').typeError('Must be a number'),
    otherwise: number().nullable(),
  }),
  transportCost: number().when('splitCost', {
    is: true,
    then: number().required('Required').typeError('Must be a number'),
    otherwise: number().nullable(),
  }),
  totalCost: number().when('splitCost', {
    is: false,
    then: number().required('Required').typeError('Must be a number'),
    otherwise: number().required('Required'),
  }),
  dateActiveValidation: date().nullable(),
  dateActive: date<Date | null>()
    .typeError('Must be a valid date')
    .when(
      'dateActiveValidation',
      (dateActiveValidation: Date) =>
        dateActiveValidation &&
        date()
          .required('Required')
          .min(
            dateActiveValidation,
            `Date must greater than ${moment(new Date(dateActiveValidation.getTime())).format(
              'MM-DD-YYYY'
            )}`
          )
          .typeError('Must be a valid date')
    ),
});
