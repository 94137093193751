import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { ISuccess, pushSuccess } from './storeSliceSuccesses';

const useShowSuccess = () => {
  const dispatch = useDispatch();
  return useCallback((success: ISuccess) => dispatch(pushSuccess(success)), [dispatch]);
};

export default useShowSuccess;
