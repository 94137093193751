import React, { useEffect, useMemo, useState } from 'react';
import { SvgIcon, Typography, IconButton, Grid, Box, Switch } from '@mui/material';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { ArrowDropDown, ArrowDropUp, Check } from '@mui/icons-material';
import { EditIcon } from 'modules/civilAndTrucking/shared/CustomIcons/Edit';
import { Delete } from 'modules/civilAndTrucking/shared/CustomIcons/Delete';
import { RootState, thunkDispatch } from 'store/store';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { deleteProjectMaterial, getProjectMaterials } from './redux/actions';
import { getCompanyMaterials } from '../CompanyMaterials/redux/actions';
import { useHasAccess } from 'services/permissions';
import { IProjectMaterial } from 'lg-helpers/dist/shared/interfaces/IProjectMaterial';

export interface IFilter {
  materialId?: number;
  sourceSupplierId?: string;
  sourceSupplierName?: string;
}

interface ProjectMaterialsListProps {
  handleSelection: Function;
  filter?: IFilter;
  projectId?: number;
  type: 'active' | 'all';
  setType: React.Dispatch<React.SetStateAction<'all' | 'active'>>;
}

export const ProjectMaterialsList = ({
  handleSelection,
  filter,
  projectId,
  setType,
  type,
}: ProjectMaterialsListProps) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const auth = useHasAccess(['projects:create', 'projects:update']);

  const handleClick = (data: IProjectMaterial) => {
    const { dateActive } = data;
    handleSelection(
      {
        ...data,
        dateActive: moment(dateActive).format('YYYY-MM-DD'),
        id: data.id ? data.id : null,
        dateActiveValidation: '',
      },
      true
    );
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  const handleArchive = async (data: IProjectMaterial) => {
    const result = await thunkDispatch(deleteProjectMaterial(data));
    if (result.meta.requestStatus === 'fulfilled') {
      thunkDispatch(
        getProjectMaterials({
          projectId: Number(projectId),
          type: type,
        })
      );
    }
  };

  const projectMaterialColumns: GridColDef[] = [
    {
      field: 'material',
      headerName: 'Material',
      flex: 1,
      minWidth: 150,
    },
    {
      field: 'sourceSupplier',
      headerName: 'Supplier',
      flex: 1,
      minWidth: 150,
    },
    {
      field: 'dateActiveString',
      headerName: 'Date Active',
      flex: 1,
      sortComparator: (v1, v2) =>
        (new Date(v1).getTime() as number) - (new Date(v2).getTime() as number),
      minWidth: 150,
    },
    {
      field: 'dateExpiredString',
      headerName: 'Date Expired',
      flex: 1,
      // eslint-disable-next-line
      sortComparator: (v1, v2, param1, param2) => 
        (new Date(v1).getTime() as number) - (new Date(v2).getTime() as number),
      minWidth: 150,
    },
    {
      field: 'units',
      headerName: 'Units',
      flex: 1,
      minWidth: 150,
    },
    {
      field: 'profileId-jobNumber',
      headerName: 'Profile ID / Prod Code',
      flex: 1,
      minWidth: 120,
      renderCell: data => (
        <Typography variant="subtitle2">
          {data.row.supplierMaterialId || data.row.profileType}
        </Typography>
      ),
    },
    {
      field: 'requireGeneratorSignature',
      headerName: 'Require Generator Signature?',
      flex: 1,
      minWidth: 100,
      renderCell: data =>
        data.row?.requireGeneratorSignature ? (
          <SvgIcon>
            <Check />
          </SvgIcon>
        ) : (
          <></>
        ),
    },
    {
      field: 'splitCost',
      headerName: 'Split?',
      flex: 1,
      renderCell: data =>
        data.value ? (
          <SvgIcon>
            <Check />
          </SvgIcon>
        ) : (
          <></>
        ),
      minWidth: 100,
    },
    {
      field: 'materialCost',
      headerName: 'Material Cost',
      flex: 1,
      minWidth: 100,
    },
    {
      field: 'transportCost',
      headerName: 'Transport Cost',
      flex: 1,
      minWidth: 100,
    },
    {
      field: 'totalCost',
      headerName: 'Total Cost',
      flex: 1,
      minWidth: 100,
    },
    {
      field: 'markup',
      headerName: 'Markup',
      flex: 1,
      minWidth: 100,
    },
    {
      field: 'totalPrice',
      headerName: 'Total Price',
      minWidth: 100,
      flex: 1,
    },
    {
      field: 'options',
      headerName: ' ',
      flex: 1,
      minWidth: 150,
      renderCell: data => {
        const editElement =
          auth.hasAccess &&
          (data.row.count > 0 ? (
            <>{data.row.count + ' tickets'}</>
          ) : (
            <IconButton onClick={() => handleClick(data.row)}>
              <SvgIcon sx={{ fontSize: 24 }}>
                <EditIcon />
              </SvgIcon>
            </IconButton>
          ));
        const deleteElement = auth.hasAccess &&
          (new Date(data.row.dateExpired) > new Date(Date.now()) || !data.row.dateExpired) && (
            <>
              <IconButton onClick={() => handleArchive(data.row)}>
                <SvgIcon sx={{ fontSize: 24 }}>
                  <Delete />
                </SvgIcon>
              </IconButton>
            </>
          );
        return (
          <>
            {editElement}
            {deleteElement}
          </>
        );
      },
    },
  ];

  const [projectMaterials, setProjectMaterials] = useState<IProjectMaterial[]>([]);

  const projectMaterialState = useSelector((state: RootState) => state.civil.projectMaterials.data);

  const companyMaterialState = useSelector((state: RootState) => state.civil.companyMaterials.data);

  const companyState = useSelector((state: RootState) => state.civil.companies.activeCompany);

  useMemo(async () => {
    await thunkDispatch(
      getProjectMaterials({
        projectId: Number(projectId),
        type: type,
      })
    );
  }, [type, projectId]);

  useEffect(() => {
    (async () => {
      setIsLoading(true);
      await Promise.all([
        thunkDispatch(getCompanyMaterials('all')),
        thunkDispatch(
          getProjectMaterials({
            projectId: Number(projectId),
            type: type,
          })
        ),
      ]);
      setIsLoading(false);
    })();
    // eslint-disable-next-line
  }, [projectId, companyState]);

  useEffect(() => {
    setProjectMaterials(
      projectMaterialState.map(pm => ({
        dateActiveString: moment(pm.dateActive).format('MM-DD-YYYY'),
        dateExpiredString: pm.dateExpired ? moment(pm.dateExpired).format('MM-DD-YYYY') : '',
        ...pm,
      }))
    );
  }, [setProjectMaterials, projectMaterialState, filter]);

  return (
    <>
      <Grid container>
        {filter && (filter.materialId || filter?.sourceSupplierId) ? (
          <Grid item xs={6}>
            <Box>
              <Typography variant="caption" align="right">
                Filtered by{' '}
                {filter.materialId &&
                  companyMaterialState.find(item => item.id === filter.materialId)?.name}
                {filter.materialId && filter.sourceSupplierId && ' and '}
                {filter.sourceSupplierName || ''}
              </Typography>
            </Box>
          </Grid>
        ) : (
          <Grid item xs={6}>
            <Box flexGrow={1} />
          </Grid>
        )}
        <Grid item xs={6} display="flex" justifyContent="flex-end" alignItems="center">
          <Typography variant="caption">Show Active</Typography>
          <Switch
            value={type === 'active'}
            color="secondary"
            defaultChecked
            onChange={() => setType(type === 'active' ? 'all' : 'active')}
          />
        </Grid>
      </Grid>
      <DataGrid
        columns={projectMaterialColumns}
        rows={
          filter && (filter.materialId || filter.sourceSupplierId)
            ? projectMaterials.filter(pm => {
                if (filter.materialId && filter.sourceSupplierId) {
                  return (
                    pm.materialId === filter.materialId &&
                    pm.sourceSupplierId === filter.sourceSupplierId
                  );
                } else if (filter.materialId && !filter.sourceSupplierId) {
                  return pm.materialId === filter.materialId;
                } else if (!filter.materialId && filter.sourceSupplierId) {
                  return pm.sourceSupplierId === filter.sourceSupplierId;
                }
                return false;
              })
            : projectMaterials
        }
        loading={isLoading}
        showColumnRightBorder={false}
        autoHeight
        rowHeight={50}
        disableColumnMenu
        components={{
          ColumnSortedAscendingIcon: ArrowDropUp,
          ColumnSortedDescendingIcon: ArrowDropDown,
          ColumnResizeIcon: () => <div></div>,
          Header: () => <Typography variant="h1" />,
        }}
      />
    </>
  );
};

export default ProjectMaterialsList;
