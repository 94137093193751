import React, { useState } from 'react';

export default () => {
  const [modal, setModal] = useState<boolean>(false);
  const [modalContent, setModalContent] = useState<React.ReactNode>();

  const handleModal = (content?: React.ReactNode, isShown?: boolean) => {
    setModalContent(content || null);
    setModal(isShown ? isShown : !modal);
  };

  return {
    modal,
    setModal,
    modalContent,
    setModalContent,
    handleModal,
  };
};
