import { auth, storage } from 'services/firebase';
import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';
// eslint-disable-next-line no-undef
const baseURL = process.env.REACT_APP_API_BASE_URL;
const staticApiBaseUrl = process.env.REACT_APP_STATIC_API_BASE_URL;
axios.defaults.baseURL = baseURL;

const sendGeneratorsNewUnsignedManifestsNotification = async (
  numberOfNewManifests: number,
  projectId: string,
  projectName: string,
  projectProfileNr: string,
  projectGeneratorCompanyId: string
) => {
  const token = await auth.currentUser?.getIdToken(true);

  const response = await axios.post(
    '/email/send-notification-generators-new-unsigned-manifests',
    {
      numberOfNewManifests,
      projectId,
      projectName,
      projectProfileNr,
      projectGeneratorCompanyId,
    },
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
  return response.data;
};

const uploadManifestNoteImageAndGetUrl = (id: string, base64: any) =>
  new Promise((resolve, reject) => {
    const uploadTask = storage
      .ref()
      .child(`manifests/${id}/notes/${uuidv4()}.png`)
      .putString(base64, 'data_url');
    uploadTask
      .then(async () => {
        const url = await uploadTask.snapshot.ref.getDownloadURL();
        resolve(url);
      })
      .catch(() => reject());
  });

const uploadManifestCustomInputImageAndGetUrl = (id: string, base64: string) =>
  new Promise((resolve, reject) => {
    const uploadTask = storage
      .ref()
      .child(`manifests/${id}/custom-inputs/${uuidv4()}.png`)
      .putString(base64, 'data_url');
    uploadTask
      .then(async () => {
        const url = await uploadTask.snapshot.ref.getDownloadURL();
        resolve(url);
      })
      .catch(() => reject());
  });

const downloadManifestPdfAndGetUrl = async (id: string, notes: boolean = true) => {
  const token = await auth.currentUser?.getIdToken(true);

  const { data: downloadUrl } = await axios.get(
    `${staticApiBaseUrl}/users/download/manifest/${id}/pdf`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      params: { notes },
    }
  );

  return downloadUrl;
};

const emailManifest = async (id: string, emails: any, isCopyToMe: boolean) => {
  const token = await auth.currentUser?.getIdToken(true);

  await axios.post(
    `${staticApiBaseUrl}/email/manifest/${id}/pdf`,
    {
      emails,
      isCopyToMe,
    },
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );

  return true;
};

export {
  sendGeneratorsNewUnsignedManifestsNotification,
  uploadManifestNoteImageAndGetUrl,
  uploadManifestCustomInputImageAndGetUrl,
  downloadManifestPdfAndGetUrl,
  emailManifest,
};
