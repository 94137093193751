import React, { useEffect, useState } from 'react';
import { Grid, Typography, Box, SvgIcon } from '@mui/material';
import { ChevronLeft } from '@mui/icons-material';
import { useNavigate, useParams } from 'react-router-dom';
import { usePermissionsContext } from 'services/permissions';
import TableWrapper from 'sharedComponents/TableWrapper';
import api, { CivilCompanySubMaterials } from 'services/api/autogenerated';
import useShowError from 'modules/errors';

export const ProjectSubMaterials = () => {
  const { projectId } = useParams();
  const navigate = useNavigate();
  const showError = useShowError();
  const { user } = usePermissionsContext();
  const [isLoading, setIsLoading] = useState(false);
  const [companySubMaterials, setCompanySubMaterials] = useState<CivilCompanySubMaterials[]>([]);

  useEffect(() => {
    const load = async () => {
      try {
        setIsLoading(true);
        const { data } = await api.civilCompanies.getAllCompanySubMaterials(user.companiesIds[0]);
        setCompanySubMaterials(data);
      } catch (err) {
        console.error('Failed to load sub materials', err);
        showError({
          title: 'Failed to load sub materials',
          duration: 10000,
        });
      } finally {
        setIsLoading(false);
      }
    };

    load();
  }, []);

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={8}>
          <Box>
            <Typography
              variant="h5"
              sx={{
                color: 'neutral.main',
                typography: { textTransform: 'uppercase' },
              }}
            >
              Project Sub-Materials
            </Typography>
            <Box
              display="flex"
              sx={{
                '&:hover': {
                  cursor: 'pointer',
                },
              }}
            >
              <SvgIcon>
                <ChevronLeft />
              </SvgIcon>
              <Typography onClick={() => navigate(-1)}>Back</Typography>
            </Box>
          </Box>
        </Grid>

        <Grid item xs={12}>
          <TableWrapper
            data={companySubMaterials}
            isLoading={isLoading}
            headCells={[
              { id: 'name', label: 'Identifier' },
              { id: 'description', label: 'Description' },
            ]}
            canEdit={true}
            onSelection={(e, mat) =>
              navigate(`/civil/projects/${projectId}/sub-materials/${mat.id}`)
            }
          />
        </Grid>
      </Grid>
    </>
  );
};
