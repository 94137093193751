import React, { useContext, useEffect, useMemo, useState } from 'react';
import {
  SvgIcon,
  Typography,
  IconButton,
  CircularProgress,
  Box,
  Button,
  Tooltip,
} from '@mui/material';
import { DataGrid, GridColDef, GridColumnVisibilityModel, GridRowModel } from '@mui/x-data-grid';
import { ArrowDropDown, ArrowDropUp, PrintOutlined } from '@mui/icons-material';
import { Delete } from 'modules/civilAndTrucking/shared/CustomIcons/Delete';
import { thunkDispatch } from 'store/store';
import {
  deleteTicket,
  printTicketReport,
  getReceiptFromManifest,
} from 'modules/civilAndTrucking/civil/Tickets/redux/actions';
import moment from 'moment';
import { EditIcon } from 'modules/civilAndTrucking/shared/CustomIcons/Edit';
import { Refresh } from 'modules/civilAndTrucking/shared/CustomIcons/Refresh';

import { useHasAccess } from 'services/permissions';

import { useSelector } from 'react-redux';
import { selectAuthUser } from 'modules/auth/storeSliceAuth';
import { ITicket } from 'lg-helpers/dist/shared/interfaces/ITicket';
import { CustomToolbar } from '../Reconciliation/CustomToolbar';
import { UserTypes } from 'lg-helpers/dist/constants/user/UserTypes';
import { ModalContext } from 'sharedComponents/ModalContext';
import AcceptOrRejectTicketModal from '../../Tickets/AcceptOrRejectTicketModal';

interface TicketsTableProps {
  handleSelection: Function;
  projectId?: number;
  tickets: ITicket[];
  isLoading: boolean;
  showingDeleted?: boolean;
  showingCompleted?: boolean;
}

const isCivilAccountantOrMananger = [UserTypes.civil.projectManager, UserTypes.civil.accountant];

export const TicketsTable = ({
  handleSelection,
  isLoading,
  projectId,
  tickets,
  showingDeleted,
  showingCompleted,
}: TicketsTableProps) => {
  const auth = useHasAccess(['tickets:create', 'tickets:update']);
  const [isPrinting, setIsPrinting] = useState<boolean>(false);
  const [columnVisibilityModel, setColumnVisibilityModel] = useState<GridColumnVisibilityModel>({});
  const authUser = useSelector(selectAuthUser);
  const isManagerOrAccountant = isCivilAccountantOrMananger.includes(authUser.actingAsType);
  const { handleModal } = useContext(ModalContext);
  const showSubMaterials = useMemo(() => tickets.filter(t => t.subMaterialsCost).length, [tickets]);

  useEffect(() => {
    if (authUser.actingAsType === 'Civil Viewer') {
      setColumnVisibilityModel({
        locationString: false,
        addressName: false,
        totalCost: false,
        cost: false,
      });
    } else {
      setColumnVisibilityModel({
        locationString: false,
        addressName: false,
        totalPrice: false,
        price: false,
      });
    }
  }, [authUser.actingAsType]);

  const handleClick = (data: GridRowModel) => {
    handleSelection(data);
  };

  const handleArchive = async (data: GridRowModel) => {
    if (
      window.confirm(
        `Are you sure you want to ${data.deleted ? 'recover' : 'delete'} Ticket # ${
          data.ticketNumber
        }?`
      )
    ) {
      await thunkDispatch(deleteTicket(data as ITicket));
    }
  };

  const handlePrint = async (ticket: ITicket) => {
    if (ticket && ticket.id) {
      try {
        setIsPrinting(true);
        const data = await thunkDispatch(
          printTicketReport({
            ticketId: ticket.id,
            userId: authUser.id,
            companyId: ticket.companyId || ticket.createdByCompanyId || authUser.companiesIds[0],
          })
        );
        const urlObject = data.payload as { url: URL };
        const newTab = window.open(urlObject.url as URL, '_blank');
        newTab?.focus();
        setIsPrinting(false);
      } catch (err) {
        console.error('Error getting reconciliation report: ', err);
        setIsPrinting(false);
      }
    }
  };

  const tryToGetReceipt = async (data: ITicket) => {
    try {
      await thunkDispatch(getReceiptFromManifest(Number(data.id)));
    } catch (error) {
      console.error('Trying to get manifest receipt id', error);
    }
  };

  // Function to avoid rounding issues
  const formatNumber = (num: any) => {
    return num ? `$ ${parseFloat(num).toFixed(2)}` : '';
  };

  const ticketColumns: GridColDef[] = [
    {
      field: 'ticketNumber',
      headerName: 'Ticket #',
      width: 100,
    },
    {
      field: 'manifestNumber',
      headerName: 'Manifest #',
      headerAlign: 'center',
      align: 'center',
      flex: 1,
    },
    {
      field: 'pitTicketNumber',
      headerName: 'Pit Ticket #',
      headerAlign: 'center',
      align: 'center',
      flex: 1,
    },
    {
      field: 'dateCreated',
      headerName: 'Date Created',
      valueFormatter: data =>
        moment(data.api.getRow(data.id!)?.dateCreated).format('MM-DD-YYYY hh:mm a'),
      renderCell: data => <>{moment(data.row.dateCreated).format('MM-DD-YYYY hh:mm A')}</>,
      flex: 1,
    },
    {
      field: 'activity',
      headerName: 'Activity',
      renderCell: data => {
        if (data.value === ' - ') {
          return <></>;
        } else {
          return data.value;
        }
      },
      flex: 2,
    },
    {
      field: 'material',
      headerName: 'Material',
      flex: 1,
    },
    {
      field: 'sourceSupplier',
      headerName: 'Supplier',
      flex: 1,
    },
    {
      field: 'transporter',
      headerName: 'Transporter',
      flex: 1,
    },
    {
      field: 'truckNumber',
      headerName: 'Truck No.',
      flex: 1,
    },
    {
      field: 'quantity',
      headerName: 'Quantity',
      flex: 1,
    },
    {
      field: 'price',
      headerName: 'Price',
      renderCell: (data: any) => formatNumber(data.row.price),
      flex: 1,
    },
    {
      field: 'totalPrice',
      headerName: 'Total Price',
      renderCell: (data: any) => formatNumber(data.row.totalPrice),
    },
    {
      field: 'cost',
      headerName: 'Cost',
      renderCell: (data: any) => formatNumber(data.row.cost),
      flex: 1,
    },
    ...(showSubMaterials
      ? [
          {
            field: 'subMaterialCost',
            headerName: 'Sub Material Cost',
            renderCell: (data: any) => formatNumber(data.row.subMaterialsCost),
            flex: 1,
          },
        ]
      : []),
    {
      field: 'totalCost',
      headerName: 'Total Cost',
      renderCell: (data: any) => formatNumber(data.row.totalCost),
    },
    {
      field: 'locationString',
      headerName: 'Site',
    },
    {
      field: 'addressName',
      headerName: 'Site Name',
    },
    {
      field: 'details',
      headerName: 'Details',
      disableExport: true,
      sortable: false,
      minWidth: 200,
      renderHeader: () => <></>,
      renderCell: data => {
        const deleteElement = auth.hasAccess &&
          (!data.row.supplerPitTicketId || data.row.isAccepted) &&
          !data.row.tuckingTicketId &&
          !data.row.sourceSupplierInoivceId &&
          !data.row.transporterInvoiceId && (
            <Tooltip title="Delete/Recover Ticket">
              <IconButton onClick={() => handleArchive(data.row)}>
                {data.row.deleted ? (
                  <Refresh sx={{ fontSize: 24 }} />
                ) : (
                  <Delete sx={{ fontSize: 24 }} />
                )}
              </IconButton>
            </Tooltip>
          );

        const editElement =
          auth.hasAccess &&
          (!data.row.isAccepted ? (
            <Tooltip title="Accept Ticket">
              <Button
                color="primary"
                variant="contained"
                onClick={() =>
                  handleModal(
                    <AcceptOrRejectTicketModal
                      ticketId={data.row.id}
                      handleAccept={handleClick}
                      acceptParams={{ ...data.row, isAccepted: true }}
                    />
                  )
                }
              >
                Process
              </Button>
            </Tooltip>
          ) : (
            <Tooltip title="Edit Ticket">
              <IconButton onClick={() => handleClick(data.row)}>
                <EditIcon
                  sx={{
                    fontSize: 24,
                    marginRight: '-2px',
                    marginBottom: '-2px',
                  }}
                />
              </IconButton>
            </Tooltip>
          ));
        const printElement = data.row.isAccepted && (
          <Tooltip title="Print Ticket">
            <IconButton onClick={() => handlePrint(data.row)}>
              <PrintOutlined sx={{ fontSize: 24 }} />
            </IconButton>
          </Tooltip>
        );

        const refreshTicket = data.row.manifestId && isManagerOrAccountant && (
          <Tooltip title="Refresh Manifested Ticket">
            <IconButton disableRipple={true} onClick={() => tryToGetReceipt(data.row)}>
              <Refresh
                color="secondary"
                sx={{
                  fontSize: 24,
                  marginRight: '-2px',
                  marginBottom: '-2px',
                }}
              />
            </IconButton>
          </Tooltip>
        );
        // TODO: get final confirmation on removing this completely
        // This duplicates functionality of edit without checking for API ticket state or user perms
        // Does this need to change to something else, or get trashed?

        // const noteElement = data.row.note && (
        //   <Tooltip title={data.row.note}>
        //     <IconButton onClick={() => handleClick(data.row)}>
        //       <SvgIcon>
        //         <GridDetail />
        //       </SvgIcon>
        //     </IconButton>
        //   </Tooltip>
        // );
        return (
          <>
            {printElement}
            {/* {noteElement} */}
            {editElement}
            {refreshTicket}
            {deleteElement}
          </>
        );
      },
    },
  ];

  return (
    <>
      {isLoading ? (
        <CircularProgress color="inherit" size={100} />
      ) : (
        <>
          <DataGrid
            getRowHeight={() => 'auto'}
            columns={ticketColumns}
            loading={isPrinting}
            initialState={{
              sorting: {
                sortModel: [{ field: 'dateCreated', sort: 'asc' }],
              },
            }}
            rows={tickets.filter(
              item => !(!showingCompleted && item.isAccepted) && !(!showingDeleted && item.deleted)
            )}
            showColumnRightBorder={false}
            columnVisibilityModel={columnVisibilityModel}
            onColumnVisibilityModelChange={newModel => setColumnVisibilityModel(newModel)}
            disableColumnMenu
            components={{
              ColumnSortedAscendingIcon: ArrowDropUp,
              ColumnSortedDescendingIcon: ArrowDropDown,
              Toolbar: () => (
                <CustomToolbar tickets={tickets} isLoading={isLoading} projectId={projectId} />
              ),
              ColumnResizeIcon: () => <div></div>,
              NoRowsOverlay: () => (
                <Box display="flex" flexGrow={1} alignItems="center" justifyContent="center">
                  <Typography>
                    {projectId
                      ? 'Please select a project to see tickets associated with a specific project.'
                      : 'No tickets found with selected search criteria.'}
                  </Typography>
                </Box>
              ),
            }}
          ></DataGrid>
        </>
      )}
    </>
  );
};

export default TicketsTable;
