import { number, object, string } from 'yup';
import { ITruckingTicket } from 'lg-helpers/dist/trucking/interfaces/ITruckingTicket';

export const ticketSchema = object<Partial<ITruckingTicket>>({
  ticketNumberTruckingTicket: number().required('Required'),
  dispatchIdTruckingTicket: number().required('Required'),
  truckNumberTruckingTicket: string().required('Required'),
  materialQuantityTruckingTicket: number().required('Required'),
  dateTruckingTicket: string().required('Required'),
});
