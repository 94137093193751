import React, { useContext, useEffect, useState } from 'react';
import { Typography, Button, Grid } from '@mui/material';
import AddMaterialModal from './AddMaterialModal';
import TableWrapper from 'sharedComponents/TableWrapper';
import { ModalContext } from 'sharedComponents/ModalContext';
import { useDispatch, useSelector } from 'react-redux';
import { RootState, thunkDispatch } from 'store/store';

import { deleteCompanyMaterial, getCompanyMaterials } from './redux/actions';
import { ICompanyMaterial } from 'lg-helpers/dist/shared/interfaces/ICompanyMaterial';

const CompanyMaterials = () => {
  const dispatch = useDispatch();
  const { handleModal } = useContext(ModalContext);
  const [materials, setMaterials] = useState<ICompanyMaterial[]>([]);

  const showModal: React.MouseEventHandler = (event, selected?: ICompanyMaterial) => {
    handleModal(<AddMaterialModal selected={selected} />);
  };

  const handleDeletion = (selection: ICompanyMaterial) => {
    thunkDispatch(deleteCompanyMaterial(selection));
  };

  const materialState = useSelector((state: RootState) => state.civil.companyMaterials.data);

  useEffect(() => {
    thunkDispatch(getCompanyMaterials(''));
  }, [dispatch]);

  // TODO: why remap here?
  useEffect(() => {
    setMaterials(
      materialState.map(item => ({
        name: item.name,
        sku: item.sku,
        id: item.id,
        companyId: item.companyId,
      }))
    );
  }, [materialState]);

  return (
    <>
      <Grid container spacing={1} alignItems="center">
        <Grid item xs={10}>
          <Typography
            variant="h5"
            sx={{
              color: 'neutral.main',
              typography: { textTransform: 'uppercase' },
            }}
          >
            Materials
          </Typography>
        </Grid>
        <Grid item xs={2}>
          <Button color="primary" variant="contained" onClick={showModal}>
            Add Materials
          </Button>
        </Grid>
        <Grid item xs={12}>
          <TableWrapper
            data={materials}
            headCells={[{ id: 'name', label: 'Name' }]}
            canDelete={true}
            canEdit={true}
            onSelection={showModal}
            onDelete={handleDeletion}
            deleteConfirmation={'Are you sure you wish to remove this Material?'}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default CompanyMaterials;
