import React, { useEffect, useState, useContext, useCallback, createRef } from 'react';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import { thunkDispatch } from 'store/store';
import { BrokerTicketsToolbar } from './Toolbar';
import {
  TicketsList,
  TicketsListRef,
} from 'modules/civilAndTrucking/trucking/features/Tickets/views/TicketsView/components/TicketsList';
import { getDriverTicketsByCompanyId } from 'modules/civilAndTrucking/trucking/features/Tickets/redux/actions';
import { UpsertDispatchForm } from 'modules/civilAndTrucking/trucking/features/Dispatches/views/DispatchesView/components/UpsertDispatchForm';
import { getJoinedBrokerDispatchesByCompanyId } from 'modules/civilAndTrucking/trucking/features/Dispatches/redux/actions';
import { IJoinTruckingDispatch } from 'lg-helpers/dist/trucking/interfaces/IJoinTruckingDispatch';
import { ModalContext } from 'sharedComponents/ModalContext';
import { toDateStr } from 'modules/civilAndTrucking/trucking/utils';
import { TicketNotesModal } from 'modules/civilAndTrucking/trucking/features/Tickets/views/TicketsView/components/TruckingTicketNotes/TicketNotesModal';

export const BrokerTicketsView = () => {
  const { handleModal }: { handleModal: any } = useContext(ModalContext);
  const [date, setDate] = useState(toDateStr(new Date()));
  const [searchFilter, setSearchFilter] = useState('');
  const ticketsListRef = createRef<TicketsListRef>();

  const refreshTickets = useCallback(() => {
    thunkDispatch(getJoinedBrokerDispatchesByCompanyId(date));
    thunkDispatch(getDriverTicketsByCompanyId(date));
  }, [date]);

  useEffect(() => {
    refreshTickets();
  }, [date, refreshTickets]);

  const openUpsertDispatchModal = (joinedDispatch: IJoinTruckingDispatch) => {
    handleModal(<UpsertDispatchForm editObj={joinedDispatch} />);
  };

  const openTicketNotesModal = (ticketId: number) => {
    handleModal(<TicketNotesModal ticketId={ticketId} />);
  };

  return (
    <>
      <Grid container>
        <Grid item xs={9} display="flex" alignItems="center">
          <Typography variant="h5" sx={{ color: 'primary.dark', mb: 1 }}>
            Tickets
          </Typography>
        </Grid>
      </Grid>
      <BrokerTicketsToolbar
        date={date}
        setDate={setDate}
        refreshTickets={refreshTickets}
        searchFilter={searchFilter}
        setSearchFilter={setSearchFilter}
      />
      <TicketsList
        ref={ticketsListRef}
        openUpsertDispatchModal={openUpsertDispatchModal}
        openTicketNotesModal={openTicketNotesModal}
        searchFilter={searchFilter}
      />
    </>
  );
};
