import React, { useState } from 'react';
import { sendActivateAccountEmail, sendSmsWithInitialPassword } from 'api/auth';
import { IUserCreation } from 'lg-helpers/dist/shared/interfaces/IUserCreation';
import { Grid, Card, CardHeader, Divider, CardContent } from '@mui/material';
import ButtonConfirmWithLoading from './ButtonConfirmWithLoading';
import ButtonResetPassword from './ButtonResetPassword';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import SmsIcon from '@mui/icons-material/Sms';

function UserNotificationAdminPanel({ userData }: { userData: IUserCreation }) {
  const [isLoadingSendActivateAccountEmail, setIsLoadingSendActivateAccountEmail] = useState(false);
  const [isLoadingSendSmsWithInitialPassword, setIsLoadingSendSmsWithInitialPassword] =
    useState(false);
  const handleClickSendActivateAccountEmail = async () => {
    try {
      setIsLoadingSendActivateAccountEmail(true);
      await sendActivateAccountEmail(userData?.id!);
    } catch (err: any) {
      console.error(err);
    } finally {
      setIsLoadingSendActivateAccountEmail(false);
    }
  };

  const handleClickSendSmsWithInitialPassword = async () => {
    try {
      setIsLoadingSendSmsWithInitialPassword(true);
      await sendSmsWithInitialPassword(userData?.id!);
    } catch (err: any) {
      console.error(err);
    } finally {
      setIsLoadingSendSmsWithInitialPassword(false);
    }
  };

  return (
    <>
      {userData?.id && (
        <Grid item md={12} xs={12}>
          {userData?.passwordInitial ? (
            <Card>
              <CardHeader title="Administrator panel" />
              <Divider />
              <CardContent>
                <Grid container spacing={2}>
                  <Grid item md={6} xs={12}>
                    <ButtonConfirmWithLoading
                      Pictogram={MailOutlineIcon}
                      text="Send email activate account"
                      textConfirm="Click again to send activate account!"
                      confirmCallback={handleClickSendActivateAccountEmail}
                      color="primary"
                      variant="outlined"
                      disabled={isLoadingSendActivateAccountEmail || !userData?.email}
                    />
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <ButtonConfirmWithLoading
                      Pictogram={SmsIcon}
                      text="Send SMS initial password"
                      textConfirm="Click again to send SMS initial password!"
                      confirmCallback={handleClickSendSmsWithInitialPassword}
                      color="primary"
                      variant="outlined"
                      disabled={isLoadingSendSmsWithInitialPassword || !userData?.phone}
                      isLarge={true}
                    />
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          ) : (
            <Grid item md={6} xs={12}>
              <ButtonResetPassword email={userData?.email} />
            </Grid>
          )}
        </Grid>
      )}
    </>
  );
}

export default UserNotificationAdminPanel;
