import React from 'react';
import { TicketsView } from './views/TicketsView';
import { DeletedTicketsView } from './views/DeletedTicketsView';
import { TicketHistoricalMapView } from './views/TicketHistoricalMap';
import { Routes, Route } from 'react-router-dom';

export const TicketsViews = () => {
  return (
    <Routes>
      <Route path="/" element={<TicketsView />} />
      <Route path="/deleted" element={<DeletedTicketsView />} />
      <Route path="/:ticketId/map" element={<TicketHistoricalMapView />} />
    </Routes>
  );
};
