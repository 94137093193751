import React, { useState, useCallback, useEffect, useMemo, HTMLAttributes } from 'react';
import {
  Grid,
  Box,
  Button,
  Container,
  CircularProgress,
  Typography,
  TextField,
  Autocomplete,
  FormControlLabel,
  Switch,
} from '@mui/material';
import PerfectScrollbar from 'react-perfect-scrollbar';
import Page from 'sharedComponents/Page';
import useShowError from 'modules/errors';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';

import Query from 'services/firebase/Query';
import Command from 'services/firebase/Command';
import Executer from 'services/firebase/Executer';
import CompaniesApi from 'services/postgres/CompaniesApi';
import EditBatchManifestsManifestsTable from './EditBatchManifestsManifestsTable';
import { useNavigate } from 'react-router-dom';
import IBatchManifests from 'lg-helpers/dist/shared/interfaces/IBatchManifests';
import { useHasPerms, usePermissionsContext } from 'services/permissions';
import { ICompany } from 'lg-helpers/dist/shared/interfaces/ICompany';
import useStickyState from 'services/hooks/useStickyState';
import { mixPanel } from 'services/mixpanel';
import QueryBase from 'lg-helpers/dist/firestore/query/QueryBase';

const query = new Query();
const executer = new Executer();
const command = new Command();
const companiesApi = new CompaniesApi();

type BatchValues = Omit<IBatchManifests, 'id'> & { id?: string };

export default function EditBatchManifestsForm({
  initialValues,
}: {
  initialValues?: IBatchManifests;
}) {
  const { currentRole, user } = usePermissionsContext();
  const [isSaving, setIsSaving] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [isLoadingData, setIsLoadingData] = useState(false);
  const [scales, setScales] = useState([] as any[]);
  const [transporters, setTransporters] = useState([] as any[]);
  const [creators, setCreators] = useState([] as any[]);
  const [connectedManifests, setConnectedManifests] = useState<any[] | null>(null);
  const showError = useShowError();
  const navigate = useNavigate();
  const canEditBatch = useHasPerms(['batched_manifests:edit']);
  const isSuperAdmin = currentRole === 'super_admin';
  const [values, setValues] = useState<BatchValues>(
    initialValues || {
      number: '', // not important, will be overwritten
      notes: '',
      trailerNumber: '',
      count: 0, // not important, will be overwritten
      countGeneratorSigned: 0, // not important, will be overwritten
      countDriverSigned: 0, // not important, will be overwritten
      countScaleSigned: 0, // not important, will be overwritten
      isCompleted: false, // not important, will be overwritten
      createdAt: new Date(), // not important, will be overwritten
      lastUpdatedAt: new Date(), // not important, will be overwritten
      transporterCompanyId: '',
      creatorCompanyId: !isSuperAdmin ? user.companiesIds[0] : '',
      scaleId: '',
      driversIds: [],
      projectsIds: [],
      driverId: null,
      enableScaleUserReceiptCreation: false,
      isReleasedManually: false,
    }
  );
  const [lastUsedScales, setLastUsedScales] = useStickyState<string[]>([], 'lastUsedScales');
  const isLoading = isSaving || isLoadingData || isDeleting;
  const canRemoveBatch = connectedManifests !== null && connectedManifests.length < 1 && !isLoading;

  const sortedScales = useMemo(
    () =>
      scales
        .filter(s => lastUsedScales.indexOf(s.id) >= 0)
        .concat(scales.filter(s => lastUsedScales.indexOf(s.id) < 0)),
    // eslint-disable-next-line
    [scales, lastUsedScales]
  );

  const isChanged = useMemo(
    () =>
      !initialValues ||
      values.notes !== initialValues.notes ||
      values.creatorCompanyId !== initialValues.creatorCompanyId ||
      values.trailerNumber !== initialValues.trailerNumber ||
      values.enableScaleUserReceiptCreation !== initialValues.enableScaleUserReceiptCreation,
    // eslint-disable-next-line
    [values, initialValues]
  );

  const selectedScale = useMemo(
    () => (values.scaleId ? scales.find(s => s.id === values.scaleId) : null) || null,
    // eslint-disable-next-line
    [scales, values.scaleId]
  );

  const enableScaleUserReceiptCreation = useMemo(
    () => values.enableScaleUserReceiptCreation || false,
    // eslint-disable-next-line
    [scales, values.enableScaleUserReceiptCreation]
  );

  const selectedTransporter = useMemo(
    () =>
      (values.transporterCompanyId
        ? transporters.find(t => t.id === values.transporterCompanyId)
        : null) || null,
    // eslint-disable-next-line
    [transporters, values.transporterCompanyId]
  );

  const selectedCreator = useMemo(
    () =>
      (values.creatorCompanyId ? creators.find(t => t.id === values.creatorCompanyId) : null) ||
      null,
    // eslint-disable-next-line
    [creators, values.creatorCompanyId]
  );

  const isValid = !!selectedScale && !!selectedTransporter && !!selectedCreator;

  const handleSave = useCallback(async () => {
    try {
      setIsSaving(true);
      if (values.id) {
        // eslint-disable-next-line
        const ret = await command.manifests.updateBatchManifests(values);
      } else {
        const id = await command.batchManifests.createBatchManifests(values);
        mixPanel(user, `New Manifest Batch Created`, {
          'Batch ID': id || '',
        });
        navigate(`/batched-manifests/${id}`);
      }
    } catch (err) {
      console.error('Save error', err);
      showError({
        title: 'Failed to save batch manifests',
        duration: 10000,
      });
    } finally {
      setIsSaving(false);
    }
    // eslint-disable-next-line
  }, [values]);

  const handleRemove = useCallback(async () => {
    try {
      setIsDeleting(true);
      await command.batchManifests.deleteBatchManifests(values.id);
      navigate('/batched-manifests/');
    } catch (err) {
      console.error('Delete error', err);
      showError({
        title: 'Failed to remove batch manifests',
        duration: 10000,
      });
    } finally {
      setIsDeleting(false);
    }
    // eslint-disable-next-line
  }, [values.id]);

  // eslint-disable-next-line
  useEffect(() => setValues({ ...values, ...initialValues }), [initialValues]);

  useEffect(() => {
    const loadScales = async () => {
      try {
        const scalesResponse = await executer.getMultipleDocuments(
          query.base.getAll(QueryBase.SCALES_COLLECTION())
        );

        setScales(scalesResponse);
      } catch (error) {
        console.error('Error', error);
        showError({
          title: 'Error while fetching the scales',
          duration: 10000,
        });
      }
    };

    const loadTransporters = async () => {
      try {
        const companiesResponse: any[] = await companiesApi.getCompanies([
          'transporter',
          'contractor',
          'generator',
        ]);

        setTransporters(companiesResponse.filter(c => c.types.includes('transporter')));
        setCreators(
          companiesResponse.filter(
            c => c.types.includes('contractor') || c.types.includes('generator')
          )
        );
      } catch (error) {
        console.error('Error', error);
        showError({
          title: 'Error while fetching transporters',
          duration: 10000,
        });
      }
    };

    const load = async () => {
      try {
        setIsLoadingData(true);
        await Promise.all([loadScales(), loadTransporters()]);
      } catch (error) {
        console.error('Unhandled error', error);
        showError({
          title: 'Error while fetching information',
          duration: 10000,
        });
      } finally {
        setIsLoadingData(false);
      }
    };

    load();
    // eslint-disable-next-line
  }, [showError]);

  return (
    <Page>
      <Container className="min-padding-and-height">
        <Grid item xs={12} marginBottom={1}>
          <Box>
            <Button size="small" variant="outlined" onClick={() => navigate('/batched-manifests/')}>
              <Box display="flex" alignItems="center">
                <NavigateBeforeIcon /> Back
              </Box>
            </Button>
          </Box>
        </Grid>

        <Typography variant="h4">
          {values.id ? 'Edit' : 'Add'} batch {values.id && ` #${values.number}`}
        </Typography>

        <Grid item xs={12}>
          <Grid container spacing={2} alignItems={'center'}>
            <Grid item md={6} xs={12}>
              <Autocomplete
                disabled={!!values.id || !canEditBatch}
                options={sortedScales}
                getOptionLabel={option => option.name}
                groupBy={scale => (lastUsedScales.indexOf(scale.id!) >= 0 ? 'Last used' : 'All')}
                value={selectedScale}
                onChange={(e, value) =>
                  setValues({
                    ...values,
                    scaleId: value?.id || '',
                  })
                }
                renderInput={params => (
                  <TextField
                    {...params}
                    variant="outlined"
                    required
                    label="Scale"
                    placeholder="Select scale"
                  />
                )}
                renderOption={(props: HTMLAttributes<HTMLLIElement>, option: ICompany) => (
                  <li
                    {...props}
                    key={option.id}
                    onClick={e => {
                      if (lastUsedScales.indexOf(option.id!) < 0)
                        setLastUsedScales([...lastUsedScales, option.id!]);
                      if (props.onClick) props.onClick(e);
                    }}
                  >
                    <Box>{option.name}</Box>
                  </li>
                )}
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <Autocomplete
                disabled={!!values.id || !canEditBatch}
                options={transporters}
                getOptionLabel={option => option.name}
                value={selectedTransporter}
                onChange={(e, value) =>
                  setValues({
                    ...values,
                    transporterCompanyId: value?.id || '',
                  })
                }
                renderInput={params => (
                  <TextField
                    {...params}
                    variant="outlined"
                    required
                    label="Transporter"
                    placeholder="Select transporter"
                  />
                )}
                renderOption={(props: HTMLAttributes<HTMLLIElement>, option: ICompany) => (
                  <li {...props} key={option.id}>
                    <Box>{option.name}</Box>
                  </li>
                )}
              />
            </Grid>

            <Grid item md={6} xs={12}>
              <Autocomplete
                disabled={!isSuperAdmin}
                options={creators}
                getOptionLabel={option => option.name}
                value={selectedCreator}
                onChange={(e, value) =>
                  setValues({
                    ...values,
                    creatorCompanyId: value?.id || '',
                  })
                }
                renderInput={params => (
                  <TextField
                    {...params}
                    variant="outlined"
                    required
                    label="Creator"
                    placeholder="Select creator"
                  />
                )}
                renderOption={(props: HTMLAttributes<HTMLLIElement>, option: ICompany) => (
                  <li {...props} key={option.id}>
                    <Box>{option.name}</Box>
                  </li>
                )}
              />
            </Grid>

            <Grid item md={6} xs={12}>
              <FormControlLabel
                control={
                  <Switch
                    checked={!!enableScaleUserReceiptCreation}
                    onChange={(e, value) => {
                      setValues({
                        ...values,
                        enableScaleUserReceiptCreation: value,
                      });
                    }}
                    name="enableScaleUserReceiptCreation"
                    color="primary"
                  />
                }
                label="Allow Scale Users to Create Receipt"
              />
            </Grid>

            <Grid item md={9} xs={9}>
              <TextField
                fullWidth
                disabled={!canEditBatch}
                label="Notes"
                name="notes"
                onChange={e =>
                  setValues({
                    ...values,
                    notes: e.target.value || '',
                  })
                }
                value={values.notes}
                variant="outlined"
              />
            </Grid>

            <Grid item md={3} xs={3}>
              <TextField
                fullWidth
                disabled={!canEditBatch}
                label="Trailer #"
                name="trailerNumber"
                onChange={e =>
                  setValues({
                    ...values,
                    trailerNumber: e.target.value || '',
                  })
                }
                value={values.trailerNumber}
                variant="outlined"
              />
            </Grid>
          </Grid>
        </Grid>

        {canEditBatch && (
          <Grid item xs={12} display="flex" alignItems="center">
            <Box marginRight={1}>
              <Button
                color="primary"
                variant="contained"
                onClick={handleSave}
                disabled={!isValid || isLoading || (!!values.id && !isChanged)}
              >
                Save info
                {isSaving && (
                  <Box marginLeft={2}>
                    <CircularProgress size="15px" color="inherit" />
                  </Box>
                )}
              </Button>
            </Box>
            <Box>
              {!!values.id && (
                <Button
                  color="error"
                  variant="contained"
                  onClick={handleRemove}
                  disabled={!canRemoveBatch}
                >
                  Delete
                  {isDeleting && (
                    <Box marginLeft={2}>
                      <CircularProgress size="15px" color="inherit" />
                    </Box>
                  )}
                </Button>
              )}
            </Box>
          </Grid>
        )}
      </Container>

      {!!values.id && (
        <Container className="min-padding-and-height">
          <PerfectScrollbar>
            <EditBatchManifestsManifestsTable
              batch={values as IBatchManifests}
              onManifestsChange={setConnectedManifests}
            />
          </PerfectScrollbar>
        </Container>
      )}
    </Page>
  );
}
