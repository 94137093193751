import { createSlice } from '@reduxjs/toolkit';
import { getLicenses, addLicense, deleteLicense } from './actions';
import { ILicense } from 'lg-helpers/dist/shared/interfaces/ILicense';
import { TFormStatus } from 'lg-helpers/dist/shared/types/TFormStatus';

interface ILicenseState {
  data?: ILicense;
  status: TFormStatus;
  error?: string;
}

const initialState: ILicenseState = {
  data: undefined,
  status: null,
  error: undefined,
};

export const licenseSlice = createSlice({
  name: 'project-tasks',
  reducers: {
    add: state => state,
  },
  initialState,
  extraReducers: builder => {
    builder.addCase(getLicenses.fulfilled, (state, action) => {
      state.status = 'success';
      state.data = action.payload as ILicense;
    });
    builder.addCase(getLicenses.rejected, (state, action) => {
      console.log('Error getting licenses');
      state.status = 'failure';
      state.error = action.error?.message;
    });
    builder.addCase(addLicense.fulfilled, (state, action) => { // eslint-disable-line
      state.status = 'success';
    });
    builder.addCase(addLicense.rejected, (state, action) => {
      console.log('Error adding licenses');
      state.status = 'failure';
      state.error = action.error?.message;
    });
    builder.addCase(deleteLicense.fulfilled, state => {
      state.status = 'success';
    });
    builder.addCase(deleteLicense.rejected, (state, action) => {
      console.log('Error deleting licenses');
      state.status = 'failure';
      state.error = action.error?.message;
    });
  },
});
