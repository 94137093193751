import React, { useState, useEffect, useCallback } from 'react';
import {
  Container,
  Grid,
  Box,
  Typography,
  CircularProgress,
  TextField,
  Autocomplete,
} from '@mui/material';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import GetAppIcon from '@mui/icons-material/GetApp';
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';
import Page from 'sharedComponents/Page';
import { unitsOfMeasure } from 'lg-helpers/dist/constants/global-constants';
import { useSelector } from 'react-redux';
import { useParams, useNavigate } from 'react-router-dom';
import { selectAuthUser } from 'modules/auth/storeSliceAuth';
import { selectSelectedDispatch } from 'modules/brokers/storeSliceBrokers';
import { setSelectedProject } from 'modules/transporters/storeSliceTransporters';
import Manifest from 'sharedComponents/pictograms/svg/Manifest';
import { useDispatch } from 'react-redux';
import InputSignature from 'sharedComponents/InputSignature';
import Base64ImageInput from 'sharedComponents/InputBase64Image';
import { hasErrorRequired } from 'services/logic/formValidation';
import useGetDriverCurrentLoad, { Load } from 'services/hooks/useGetDriverCurrentLoad';
import useGetDriverCurrentRepeatedLoad from 'services/hooks/useGetDriverCurrentRepeatedLoad';
import { callV1ApiLoadCompletedCallBackUrl, uploadImageAndGetUrl } from 'api/brokers';
import { getBase64FromUrl } from 'services/logic/base64';
import Executer from 'services/firebase/Executer';
import Command from 'services/firebase/Command';
import Query from 'services/firebase/Query';
import AssignTruckNumber from './AssignTruckNumber';
import useGetTruckNumbersForUserCompany from 'services/hooks/useGetTruckNumbersForUserCompany';
import useIsUserOfType from 'services/hooks/useIsUserOfType';
import ButtonWithLoading from 'sharedComponents/ButtonWithLoading';
import useShowError from 'modules/errors';
import ErrorMessages from 'services/constants/errorMessages';
import QueryBase from 'lg-helpers/dist/firestore/query/QueryBase';
const { isRequired } = ErrorMessages;

const executer = new Executer();
const command = new Command();
const query = new Query();

const loadSeccondsToSign = 120;

const LoadView = () => {
  let { id } = useParams();
  const isUserTruckingDriver = useIsUserOfType('trucking_driver');

  const { currentLoad, isLoading: isLoadingCurrentLoad } = useGetDriverCurrentLoad();
  const { currentRepeatedLoad, isLoading: isLoadingCurrentRepeatedLoad } =
    useGetDriverCurrentRepeatedLoad();

  const { isLoadingTruckNumbers, truckNumbers } = useGetTruckNumbersForUserCompany();

  const showError = useShowError();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const authUser = useSelector(selectAuthUser);
  const selectedDispatch = useSelector(selectSelectedDispatch);
  const [load, setLoad] = useState<Omit<Load, 'id'>>({
    signatureDriver: {},
  });
  const [isLoadingLoadById, setIsLoadingLoadById] = useState(true);
  const [seccondsLeftToSign, setSeccondsLeftToSign] = useState<number | null>(null);
  const [localCustomData, setLocalCustomData] = useState<{ [key: string]: any } | null>(null);
  const [wasSubmitted, setWasSubmitted] = useState(false);
  const [gateAttendantSignatureBase64, setGateAttendantSignatureBase64] = useState('');
  const [isLoadingGateAttendantSignatureBase64, setIsLoadingGateAttendantSignatureBase64] =
    useState(false);
  const [driverSignatureBase64, setDriverSignatureBase64] = useState('');
  const [isLoadingDriverSignatureBase64, setIsLoadingDriverSignatureBase64] = useState(false);
  const [pitTicketBase64, setPitTicketBase64] = useState('');
  const [isLoadingPitTicketBase64, setIsLoadingPitTicketBase64] = useState(false);
  const [isSigning, setIsSigning] = useState(false);
  const [isLoadValid, setIsLoadValid] = useState(false);
  const [isLoadLoaded, setIsLoadLoaded] = useState(false);
  const [wasJustSuccessfullyPressedLoadButton, setWasJustSuccessfullyPressedLoadButton] =
    useState(false);

  const [wasLoadUpdated, setWasLoadUpdated] = useState(false);

  const goToDispatchPage = useCallback(() => {
    navigate('/brokers/driver/dispatches');
  }, [navigate]);

  const goToAccountPage = useCallback(() => {
    navigate('/brokers/account');
  }, [navigate]);

  const goToCurrentManifestPage = useCallback(() => {
    navigate('/drivers/current-manifest');
  }, [navigate]);

  const resetLoadRequestedAtCountdown = useCallback(async () => {
    if (!load.id) {
      return;
    }
    try {
      await command.brokers.brokerMarkLoadWasRequestedNowByDriver(load.id);
      setSeccondsLeftToSign(60);
    } catch {
      showError({ title: 'Error at load reset countdown' });
    }
    // eslint-disable-next-line
  }, [load]);

  const handleChangeGateAttendantSignatureBase64 = useCallback(
    (base64: string) => {
      setGateAttendantSignatureBase64(base64);
      if (isLoadLoaded) {
        setWasLoadUpdated(true);
      }
    },
    [setGateAttendantSignatureBase64, setWasLoadUpdated, isLoadLoaded]
  );

  const handleChangePitTicketBase64 = useCallback(
    (base64: string) => {
      setPitTicketBase64(base64);
      if (isLoadLoaded) {
        setWasLoadUpdated(true);
      }
    },
    [setPitTicketBase64, setWasLoadUpdated, isLoadLoaded]
  );

  const handleChangeSignatureDriver = useCallback(
    (event: {
      target: {
        name: string;
        value: string;
      };
    }) => {
      setLoad({
        ...load,
        signatureDriver: {
          ...load.signatureDriver,
          [event.target.name]: event.target.value,
        },
      });
      if (isLoadLoaded) {
        setWasLoadUpdated(true);
      }
      setWasSubmitted(false);
    },
    [load, isLoadLoaded, setLoad, setWasLoadUpdated, setWasSubmitted]
  );

  useEffect(() => {
    if (load.manifestProjectID) {
      dispatch(
        setSelectedProject({
          id: load.manifestProjectID,
        })
      );
    }
  }, [load.manifestProjectID, dispatch]);

  useEffect(() => {
    if (authUser) {
      let { customData } = authUser;
      customData = customData || {};
      setLocalCustomData(customData);
    }
  }, [authUser, load]);

  useEffect(() => {
    if (!authUser) {
      return;
    }

    if (currentRepeatedLoad) {
      setLoad({
        ...currentRepeatedLoad,
        signatureDriver: {
          ...currentRepeatedLoad.signatureDriver,
          ...(authUser.customData || {}),
        },
      });

      setIsLoadingLoadById(false);
    }
  }, [currentRepeatedLoad, authUser]);

  useEffect(() => {
    if (currentLoad) {
      setLoad(currentLoad);
      if (currentLoad.signatureDriver && currentLoad.signatureDriver.isSignedLoaded) {
        setIsLoadLoaded(true);
        if (wasJustSuccessfullyPressedLoadButton) {
          goToCurrentManifestPage();
        }
      } else {
        setIsLoadLoaded(false);
      }
    } else {
      setIsLoadLoaded(false);
    }
  }, [currentLoad, wasJustSuccessfullyPressedLoadButton, goToCurrentManifestPage]);

  useEffect(() => {
    if (!authUser) {
      return;
    }

    const getLoadById = async (loadId: string) => {
      try {
        const loadResponse = (await executer.getSingleDocument(
          query.base.getById(QueryBase.BROKER_LOADS_COLLECTION(), loadId)
        )) as Load;
        setLoad(loadResponse);
        setIsLoadingLoadById(false);
        if (!loadResponse) {
          showError({ title: 'Load not existing!' });
        }
      } catch (error) {
        console.error('Error', error);
        showError({ title: 'Error get load' });
        setIsLoadingLoadById(false);
      }
    };

    const getNextLoad = async () => {
      try {
        setIsLoadingLoadById(true);
        const nextLoad = await executer.getSingleDocument(
          query.brokers.brokerGetNextUnsignedLoad(authUser, selectedDispatch.id)
        );

        if (!nextLoad) {
          showError({ title: 'No loads available!' });
          setTimeout(() => {
            goToDispatchPage();
          }, 2000);
          return;
        }

        await command.brokers.brokerMarkLoadWasRequestedNowByDriver(nextLoad.id);

        setLoad({
          ...nextLoad,
          signatureDriver: {
            ...nextLoad.signatureDriver,
            ...(authUser.customData || {}),
          },
        });

        setSeccondsLeftToSign(loadSeccondsToSign);
        setIsLoadingLoadById(false);
      } catch (error) {
        console.error('Error', error);
        showError({ title: 'Error get load' });
        setIsLoadingLoadById(false);
      }
    };

    if (id) {
      getLoadById(id);
    } else {
      if (
        !currentLoad &&
        !isLoadingCurrentLoad &&
        !currentRepeatedLoad &&
        !isLoadingCurrentRepeatedLoad
      ) {
        if (!selectedDispatch) {
          goToDispatchPage();
          return;
        }
        getNextLoad();
      } else if (currentLoad) {
        getLoadById(currentLoad.id);
      }
    }
    // eslint-disable-next-line
  }, [
    id,
    authUser,
    selectedDispatch,
    isLoadingCurrentLoad,
    isLoadingCurrentRepeatedLoad,
    currentLoad,
    currentRepeatedLoad,
    goToDispatchPage,
  ]);

  useEffect(() => {
    if (!authUser || isLoadingCurrentLoad || (id && !load.id)) {
      return;
    }

    if (isUserTruckingDriver && (!authUser.signature || !authUser.signature.url)) {
      goToAccountPage();
      return;
    }
    const { signatureDriver, manifestProjectID, isManifestComplete } = load;
    let { urlDriver, urlGateAttendant, urlPitTicket } = signatureDriver;

    if (
      isUserTruckingDriver &&
      !urlDriver &&
      (!manifestProjectID || (manifestProjectID && !isManifestComplete))
    ) {
      urlDriver = authUser.signature.url;
    }

    if (urlDriver) {
      setIsLoadingDriverSignatureBase64(true);
      getBase64FromUrl(urlDriver).then(
        base64 => {
          setDriverSignatureBase64(base64);
          setIsLoadingDriverSignatureBase64(false);
        },
        error => {
          console.error('getBase64FromUrl', error);
          setIsLoadingDriverSignatureBase64(false);
        }
      );
    }

    if (urlGateAttendant) {
      setIsLoadingGateAttendantSignatureBase64(true);
      getBase64FromUrl(urlGateAttendant).then(
        base64 => {
          setGateAttendantSignatureBase64(base64);
          setIsLoadingGateAttendantSignatureBase64(false);
        },
        error => {
          console.error('getBase64FromUrl', error);
          setIsLoadingGateAttendantSignatureBase64(false);
        }
      );
    }

    if (urlPitTicket) {
      setIsLoadingPitTicketBase64(true);
      getBase64FromUrl(urlPitTicket).then(
        base64 => {
          setPitTicketBase64(base64);
          setIsLoadingPitTicketBase64(false);
        },
        error => {
          console.error('getBase64FromUrl', error);
          setIsLoadingPitTicketBase64(false);
        }
      );
    }
    // eslint-disable-next-line
  }, [
    id,
    load.id,
    authUser,
    isLoadingCurrentLoad,
    goToAccountPage,
    load.signatureDriver.urlGateAttendant,
    load.signatureDriver.urlDriver,
    load.signatureDriver.urlPitTicket,
    isUserTruckingDriver,
  ]);

  useEffect(() => {
    if (isSigning || seccondsLeftToSign === null || isLoadLoaded) {
      return;
    }
    if (seccondsLeftToSign === 15) {
      resetLoadRequestedAtCountdown();
      setSeccondsLeftToSign(loadSeccondsToSign);
      return;
    }

    const timeout = setTimeout(() => setSeccondsLeftToSign(seccondsLeftToSign - 1), 1000);
    return () => clearTimeout(timeout);
  }, [
    seccondsLeftToSign,
    isSigning,
    isLoadLoaded,
    goToDispatchPage,
    resetLoadRequestedAtCountdown,
  ]);

  useEffect(() => {
    if (!load) {
      setIsLoadValid(false);
      return;
    }

    if (!load.manifestProjectID) {
      if (!pitTicketBase64 || !load.signatureDriver.pitTicket) {
        setIsLoadValid(false);
        return;
      }
    }

    if (
      !load.signatureDriver.truckNumber ||
      !load.signatureDriver.quantity ||
      !load.signatureDriver.truckCapacity ||
      !load.signatureDriver.truckCapacityUnit
    ) {
      setIsLoadValid(false);
      return;
    }

    setIsLoadValid(true);
  }, [load, pitTicketBase64]);

  const handleChangeLocalCustomData = (event: {
    target: {
      name: string;
      value: string;
    };
  }) => {
    setLocalCustomData({
      ...(localCustomData || {}),
      [event.target.name]: event.target.value,
    });
    handleChangeSignatureDriver(event);
  };

  const handleClickBack = () => {
    navigate(-1);
  };

  const handleClickLoad = async () => {
    setIsSigning(true);

    let urlDriverSignature = authUser.signature.url;
    let urlGateAttendantSignature = null;
    let urlPitTicket = null;

    try {
      if (gateAttendantSignatureBase64) {
        urlGateAttendantSignature = await uploadImageAndGetUrl(
          authUser.id,
          load.id,
          'signature-gate-attendant',
          gateAttendantSignatureBase64
        );
      }

      if (pitTicketBase64) {
        urlPitTicket = await uploadImageAndGetUrl(
          authUser.id,
          load.id,
          'pit-ticket',
          pitTicketBase64
        );
      }

      await command.users.updateUserCustomData(authUser.id, localCustomData);

      const { quantity, pitTicket, truckNumber, truckCapacity, truckCapacityUnit } =
        load.signatureDriver;

      if (wasLoadUpdated && isLoadLoaded) {
        await command.brokers.brokerUpdateLoadedLoad({
          loadId: load.id,
          truckNumber,
          urlGateAttendantSignature,
          urlPitTicket,
          quantity,
        });
      } else {
        await command.brokers.brokerSignLoadedLoad(authUser, {
          loadId: load.id,
          dispatchID: load.dispatchID,
          urlDriverSignature,
          urlGateAttendantSignature,
          urlPitTicket,
          quantity,
          pitTicket,
          truckNumber,
          truckCapacity,
          truckCapacityUnit,
        });

        setIsLoadLoaded(true);
        setIsSigning(false);
        setWasJustSuccessfullyPressedLoadButton(true);
        setWasLoadUpdated(false);

        try {
          load.callBackURL && (await callV1ApiLoadCompletedCallBackUrl(load.callBackURL));
        } catch (error) {
          console.error('callV1ApiLoadCompletedCallBackUrl', error);
        }
      }
    } catch (err) {
      showError({ title: 'Error signing loaded' });
      setIsSigning(false);
      console.error(err);
    }
  };

  const handleClickSignDump = async () => {
    setIsSigning(true);

    try {
      await command.brokers.brokerSignDumpedLoad(authUser, {
        loadId: load.id,
        dispatchID: load.dispatchID,
      });
    } catch (err) {
      showError({ title: 'Error signing completed' });
      setIsSigning(false);
    }

    setTimeout(() => {
      goToDispatchPage();
      setIsSigning(false);
    }, 3000);

    try {
      load.callBackURL && (await callV1ApiLoadCompletedCallBackUrl(load.callBackURL));
    } catch (err) {
      // Intentionally silenced because it does not provide anything to the user and the solution is server-side
      // showError({ title: 'Error calling v1 callback url' });
    }
  };

  return (
    <Page title="Load">
      <Container>
        <Grid container spacing={1}>
          {isLoadingLoadById ? (
            <Grid item xs={12}>
              <Box display="flex" justifyContent="center" alignItems="center">
                <CircularProgress color="inherit" />
              </Box>
            </Grid>
          ) : (
            <>
              {load.id ? (
                <>
                  <Grid item xs={6}>
                    <AssignTruckNumber
                      truckNumbers={truckNumbers}
                      isLoadingTruckNumbers={isLoadingTruckNumbers}
                      load={load as Load}
                    />
                  </Grid>
                  <Grid item xs={6} />
                  <Grid item xs={6} />
                  <Grid item xs={6} />
                  <Grid item sm={6} xs={12}>
                    <Typography variant="body1">
                      <b>Customer #:</b> {load.customerNumber || ''}
                    </Typography>
                  </Grid>
                  <Grid item sm={6} xs={12}>
                    <Typography variant="body1">
                      <b>Lot #:</b> {load.lotNumber || ''}
                    </Typography>
                  </Grid>
                  <Grid item sm={6} xs={12}>
                    <Typography variant="body1">
                      <b>Contractor:</b> {load.contractorName || ''}
                    </Typography>
                  </Grid>
                  <Grid item sm={6} xs={12}>
                    <Typography variant="body1">
                      <b>Project:</b> {load.projectName || ''}
                    </Typography>
                  </Grid>
                  <Grid item sm={6} xs={12}>
                    <Typography variant="body1">
                      <b>Material:</b> {load.material || ''}
                    </Typography>
                  </Grid>
                  <Grid item sm={6} xs={12}>
                    <Typography variant="body1">
                      <b>Source:</b> {load.source || ''}
                    </Typography>
                  </Grid>
                  <Grid item sm={6} xs={12}>
                    <Typography variant="body1">
                      <b>Date:</b> {load.date || ''}
                    </Typography>
                  </Grid>
                  <Grid item sm={6} xs={12}>
                    <Typography variant="body1">
                      <b>Id:</b> {load.id || ''}
                    </Typography>
                  </Grid>
                  <Grid item sm={6} xs={12}>
                    <TextField
                      disabled={!!id || isSigning}
                      fullWidth
                      required
                      type="number"
                      label="Quantity"
                      name="quantity"
                      onChange={handleChangeSignatureDriver}
                      value={load.signatureDriver.quantity || ''}
                      variant="outlined"
                      error={hasErrorRequired(load.quantity, wasSubmitted)}
                      helperText={hasErrorRequired(load.quantity, wasSubmitted) ? isRequired : ''}
                    />
                  </Grid>
                  <Grid item sm={6} xs={12}>
                    <TextField
                      disabled={
                        !!id ||
                        isLoadLoaded ||
                        isSigning ||
                        (load.assignedTruckNumber &&
                          load.assignedTruckNumber !== QueryBase.NULL_TRUCK_NUMBER())
                      }
                      fullWidth
                      required
                      label="Truck Number"
                      name="truckNumber"
                      onChange={handleChangeLocalCustomData}
                      value={load.signatureDriver.truckNumber || ''}
                      variant="outlined"
                      error={hasErrorRequired(load.signatureDriver.truckNumber, wasSubmitted)}
                      helperText={
                        hasErrorRequired(load.signatureDriver.truckNumber, wasSubmitted)
                          ? isRequired
                          : ''
                      }
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      fullWidth
                      disabled={!!id || isLoadLoaded || isSigning}
                      label="Truck Capacity"
                      variant="outlined"
                      type="number"
                      onChange={handleChangeLocalCustomData}
                      name="truckCapacity"
                      required
                      value={load.signatureDriver.truckCapacity || ''}
                      error={hasErrorRequired(load.signatureDriver.truckCapacity, wasSubmitted)}
                      helperText={
                        hasErrorRequired(load.signatureDriver.truckCapacity, wasSubmitted)
                          ? isRequired
                          : ''
                      }
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Autocomplete
                      fullWidth
                      disabled={!!id || isLoadLoaded || isSigning}
                      options={unitsOfMeasure || []}
                      isOptionEqualToValue={(option, value) => option === value}
                      value={load.signatureDriver.truckCapacityUnit || null}
                      onChange={(event, value) =>
                        handleChangeLocalCustomData({
                          target: {
                            name: 'truckCapacityUnit',
                            value: value,
                          },
                        })
                      }
                      renderInput={params => (
                        <TextField
                          {...params}
                          label="Units"
                          required
                          variant="outlined"
                          error={hasErrorRequired(
                            load.signatureDriver.truckCapacityUnit,
                            wasSubmitted
                          )}
                          helperText={
                            hasErrorRequired(load.signatureDriver.truckCapacityUnit, wasSubmitted)
                              ? isRequired
                              : ''
                          }
                          InputProps={{
                            ...params.InputProps,
                            endAdornment: <>{params.InputProps.endAdornment}</>,
                          }}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item sm={6} xs={12}>
                    <TextField
                      disabled={!!id || isLoadLoaded || isSigning}
                      fullWidth
                      required={!load.manifestProjectID}
                      label="Pit ticket"
                      name="pitTicket"
                      onChange={handleChangeSignatureDriver}
                      value={load.signatureDriver.pitTicket || ''}
                      variant="outlined"
                      error={hasErrorRequired(load.pitTicket, wasSubmitted)}
                      helperText={hasErrorRequired(load.pitTicket, wasSubmitted) ? isRequired : ''}
                    />
                  </Grid>
                  <Grid item sm={6} xs={12}>
                    {load.manifestProjectID ? (
                      <Typography variant="body1">
                        <b>Manifest number:</b> {load.manifestNumber || 'Not assigned yet'}{' '}
                        <b>{load.isManifestComplete ? ' - Completed' : ''}</b>
                      </Typography>
                    ) : null}
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Typography variant="body1">
                      Customer Job #: {load.customerNumber || ''}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Typography variant="body1">Ticket #: {load.ticketNumber || ''}</Typography>
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    {load.specialInstr ? (
                      <Typography variant="body1">
                        <b>Special instructions:</b> {load.specialInstr || ''}
                      </Typography>
                    ) : null}
                  </Grid>
                  <Grid item sm={6} xs={12}>
                    <InputSignature
                      title="Gate attendant's signature"
                      signatureBase64={gateAttendantSignatureBase64}
                      onSignatureBase64Update={handleChangeGateAttendantSignatureBase64}
                      isLoading={isLoadingGateAttendantSignatureBase64}
                    />
                  </Grid>
                  <Grid item sm={6} xs={12}>
                    <InputSignature
                      title="Driver's signature *"
                      signatureBase64={driverSignatureBase64}
                      isLoading={isLoadingDriverSignatureBase64}
                      disabled={true}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Base64ImageInput
                      title={load.manifestProjectID ? 'Pit ticket picture' : 'Pit ticket picture *'}
                      imageBase64={pitTicketBase64}
                      onImageBase64Update={handleChangePitTicketBase64}
                      isLoading={isLoadingPitTicketBase64}
                      disabled={!!id || isSigning}
                    />
                  </Grid>

                  {load?.lastLoad && (
                    <Grid item xs={12}>
                      <Box my={2}>
                        <Typography
                          variant="h2"
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                          }}
                        >
                          <AssignmentTurnedInIcon /> Last Load
                        </Typography>
                        <Typography variant="h5">
                          This ticket has been marked as the last load of the day!
                        </Typography>
                      </Box>
                    </Grid>
                  )}

                  <Grid item sm={4} xs={12}>
                    {id || (!id && !currentLoad) ? (
                      <ButtonWithLoading
                        disabled={isSigning}
                        Pictogram={NavigateBeforeIcon}
                        onClickCallback={handleClickBack}
                        isLarge
                        variant="outlined"
                        text="Back"
                      />
                    ) : null}
                  </Grid>
                  {!id || (currentLoad && currentLoad.id === id) ? (
                    <Grid item sm={8} xs={12}>
                      {isLoadLoaded && !wasLoadUpdated ? (
                        <>
                          {!load.manifestProjectID || load.isManifestComplete ? (
                            <ButtonWithLoading
                              disabled={isSigning}
                              Pictogram={GetAppIcon}
                              onClickCallback={handleClickSignDump}
                              isLarge={true}
                              variant="contained"
                              color="primary"
                              text="Dump"
                            />
                          ) : (
                            <ButtonWithLoading
                              disabled={isSigning}
                              Pictogram={Manifest}
                              onClickCallback={goToCurrentManifestPage}
                              isLarge={true}
                              variant="outlined"
                              color="primary"
                              text={load.manifestId ? 'Go To Current Manifest' : 'Get Manifest'}
                            />
                          )}
                        </>
                      ) : (
                        <ButtonWithLoading
                          disabled={!isLoadValid || isSigning}
                          Pictogram={LocalShippingIcon}
                          onClickCallback={handleClickLoad}
                          isLarge={true}
                          variant="contained"
                          color="primary"
                          text={wasLoadUpdated && isLoadLoaded ? 'Load update' : 'Load'}
                        />
                      )}
                    </Grid>
                  ) : null}
                </>
              ) : null}
            </>
          )}
        </Grid>
      </Container>
    </Page>
  );
};

export default LoadView;
