import React, { useEffect, useMemo, useState } from 'react';
import { Autocomplete, Grid, TextField } from '@mui/material';

import InputAddressFull from 'sharedComponents/InputAddressFull';
import { hasErrorValidEmail, hasErrorRequired } from 'services/logic/formValidation';
import ErrorMessages from 'services/constants/errorMessages';
import InputMasker from './InputMasker';
import { Scale } from 'lg-helpers/dist/shared/types/scales';
import CompaniesApi from 'services/postgres/CompaniesApi';
import { ICompany } from 'services/api/autogenerated-code';

const { isRequired, emailNotValid } = ErrorMessages;
const companiesApi = new CompaniesApi();

interface ICompanyWitReceiptTemplates extends ICompany {
  receiptTemplates: any;
}

type ScaleStripped = Pick<
  Scale,
  | 'name'
  | 'email'
  | 'phone'
  | 'gpsLat'
  | 'gpsLong'
  | 'address'
  | 'city'
  | 'state'
  | 'country'
  | 'postalCode'
  | 'apiBaseUrl'
  | 'companyId'
  | 'receiptTemplateId'
>;
interface ScaleDetailsProps {
  className?: string;
  scale: ScaleStripped;
  onScaleUpdate(scaleData: any): void;
  wasSubmitted?: boolean;
}

const ScaleDetails = ({ scale, onScaleUpdate, wasSubmitted }: ScaleDetailsProps) => {
  const nameErrorMessage = hasErrorRequired(scale.name, wasSubmitted) && isRequired;
  const emailErrorMessage =
    hasErrorValidEmail(scale.email as string, wasSubmitted) && emailNotValid;
  const [company, setCompany] = useState<ICompanyWitReceiptTemplates | null>(null);

  const computedLocationData = useMemo(
    () => ({
      address: scale.address,
      city: scale.city,
      state: scale.state?.value || null,
      country: scale.country?.value || null,
      postalCode: (scale.postalCode || '') + '' || null,
    }),
    [scale]
  );

  useEffect(() => {
    let active = true;
    load();
    return () => {
      active = false;
    };

    async function load() {
      if (!scale?.companyId) return;
      const res = await companiesApi.getById(scale.companyId);
      if (!active) return;
      setCompany(res);
    }
  }, [scale]);

  const handleChange = (event: any): void =>
    onScaleUpdate({
      targetName: event.target.name,
      targetValue: event.target.value,
    });

  return (
    <>
      <Grid item md={6} xs={12}>
        <TextField
          fullWidth
          label="Scale name"
          name="name"
          onChange={handleChange}
          error={!!nameErrorMessage}
          data-testid="ScaleDetails--field__name"
          helperText={nameErrorMessage ? nameErrorMessage : ''}
          required
          value={scale.name || ''}
          variant="outlined"
        />
      </Grid>
      <InputAddressFull
        locationData={computedLocationData}
        handleChangeCallback={e => handleChange(e)}
        wasSubmitted={wasSubmitted}
      />
      <Grid item md={6} xs={12}>
        <TextField
          fullWidth
          label="Email Address"
          name="email"
          data-testid="ScaleDetails--field__email"
          onChange={handleChange}
          value={scale.email || ''}
          variant="outlined"
          error={!!emailErrorMessage}
          helperText={emailErrorMessage ? emailErrorMessage : ''}
        />
      </Grid>
      <Grid item md={6} xs={12}>
        <InputMasker
          fullWidth
          name="phone"
          variant="outlined"
          label="Phone Number"
          mask="+###########"
          val={scale.phone || ''}
          data-testid="ScaleDetails--field__phone"
          onChange={val =>
            onScaleUpdate({
              targetName: 'phone',
              targetValue: val,
            })
          }
        />
      </Grid>
      <Grid item md={6} xs={12}>
        <TextField
          fullWidth
          label="GPS Lat"
          name="gpsLat"
          data-testid="ScaleDetails--field__gpsLat"
          onChange={handleChange}
          type="number"
          value={scale.gpsLat || ''}
          variant="outlined"
        />
      </Grid>
      <Grid item md={6} xs={12}>
        <TextField
          fullWidth
          label="GPS Long"
          name="gpsLong"
          data-testid="ScaleDetails--field__gpsLong"
          onChange={handleChange}
          type="number"
          value={scale.gpsLong || ''}
          variant="outlined"
        />
      </Grid>
      <Grid item md={6} xs={12}>
        <TextField
          fullWidth
          label="API Base Url"
          name="apiBaseUrl"
          data-testid="ScaleDetails--field__apiBaseUrl"
          onChange={handleChange}
          value={scale.apiBaseUrl || ''}
          variant="outlined"
        />
      </Grid>
      <Grid item md={6} xs={12}>
        <Autocomplete
          fullWidth
          options={company?.receiptTemplates || []}
          getOptionLabel={option => option.name}
          value={
            (company?.receiptTemplates || []).find(
              (template: any) => template.id === scale.receiptTemplateId
            ) || null
          }
          onChange={(event, value) =>
            handleChange({
              target: {
                name: 'receiptTemplateId',
                value: (value && value.id) || null,
              },
            })
          }
          renderOption={(props, option) => (
            <li {...props} key={option.id || ''}>
              {option.name}
            </li>
          )}
          renderInput={params => (
            <TextField
              {...params}
              label="Receipt template"
              placeholder="Select receipt template"
              variant="outlined"
              InputProps={{
                ...params.InputProps,
                endAdornment: <>{params.InputProps.endAdornment}</>,
              }}
            />
          )}
        />
      </Grid>
    </>
  );
};

export default ScaleDetails;
