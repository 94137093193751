import React, { useContext, useState } from 'react';
import { Grid, Button, Typography } from '@mui/material';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import ImportCSVandXLSX from 'sharedComponents/ImportCSVandXLSX';
import DialogActions from 'sharedComponents/DialogActions';
import * as XLSX from 'xlsx';
import Papa from 'papaparse';
import { IAddress } from 'services/mapbox/geocoding';
import { thunkDispatch } from 'store/store';
import { importSites } from '../redux/actions';
import { useSelector } from 'react-redux';
import { selectAuthUser } from 'modules/auth/storeSliceAuth';
import { ModalContext } from 'sharedComponents/ModalContext';

interface IImportLocationProps {
  projectId: number;
}

interface IAddressLookup {
  address?: string;
  city?: string;
  state?: string;
  zip?: string;
  country?: string;
}

const columns: GridColDef[] = [
  {
    headerName: 'Address',
    field: 'address',
    flex: 3,
  },
  {
    headerName: 'City',
    field: 'city',
    flex: 2,
  },
  {
    headerName: 'State',
    field: 'state',
    flex: 1,
  },
  {
    headerName: 'ZIP',
    field: 'zip',
    flex: 1,
  },
  {
    headerName: 'Country',
    field: 'country',
    flex: 1,
  },
];

const ImportLocations = ({ projectId }: IImportLocationProps) => {
  const [parseResult, setParseResult] = useState<IAddressLookup[]>();
  const { handleModal } = useContext(ModalContext);

  const authUser = useSelector(selectAuthUser);

  const handleSubmit = () => {
    var data: IAddress[] = [];
    if (parseResult && parseResult.length) {
      data = parseResult.map(address => ({
        address: address.address,
        place: address.city,
        region: address.state,
        postcode: address.zip,
        country: address.country,
      }));

      thunkDispatch(
        importSites({
          projectId,
          addresses: data,
          email: authUser.email,
          createdBy: authUser.id,
        })
      );

      handleModal();
    }
  };

  const downloadXSLX = () => {
    const wb = XLSX.utils.book_new();
    wb.Props = {
      Title: 'siteImportTemplate',
      Subject: 'Import Template',
      Author: 'Livegistics',
      CreatedDate: new Date(),
    };

    wb.SheetNames.push('Import Template');
    const ws_data = [
      ['Address', 'City', 'State', 'ZIP', 'Country'],
      ['123 Example St', 'Test Town', 'MI', '48203', 'US'],
    ];
    var ws = XLSX.utils.aoa_to_sheet(ws_data);
    wb.Sheets['Import Template'] = ws;

    XLSX.writeFile(wb, 'importTemplate.xlsx');
  };

  const downloadCSV = () => {
    const csvData = [
      {
        address: '123 Example St',
        city: 'Test Town',
        state: 'MI',
        ZIP: '48203',
        country: 'US',
      },
    ];
    const csv = Papa.unparse(csvData);
    const blob = new Blob([csv], { type: 'text/csv;charset=utf-8' });
    const csvUrl = window.URL.createObjectURL(blob);
    const tempLink = document.createElement('a');
    tempLink.href = csvUrl;
    tempLink.download = 'importTemplate.csv';
    tempLink.click();
    tempLink.remove();
  };

  return (
    <>
      <Grid container justifyContent="space-between" spacing={2} alignItems="center">
        <Grid item display="flex" alignItems="center">
          <Typography sx={{ color: 'neutral.main' }}>
            Download a template for XLSX or CSV to get started.
          </Typography>
          <Button onClick={downloadXSLX} sx={{ color: 'neutral.main' }}>
            Download XLSX
          </Button>
          <Button onClick={downloadCSV} sx={{ color: 'neutral.main' }}>
            Download CSV
          </Button>
        </Grid>
        <Grid item>
          <ImportCSVandXLSX
            setJSON={setParseResult}
            button={
              <Button color="primary" component="div" variant="contained">
                Upload Addresses
              </Button>
            }
          />
        </Grid>

        <Grid item xs={12} height={500}>
          <DataGrid
            rows={parseResult ? parseResult : []}
            columns={columns}
            getRowId={row => `${row.address}-${row.postcode}`}
            editMode="cell"
          />
        </Grid>
        <DialogActions saveHandler={handleSubmit}></DialogActions>
      </Grid>
    </>
  );
};

export default ImportLocations;
