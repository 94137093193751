import React from 'react';
import { Table, TableHead, TableRow, TableCell, TableBody, Box, Typography } from '@mui/material';
import { useSelector } from 'react-redux';
import { selectPreprocessImportedData } from 'modules/civilAndTrucking/trucking/features/DataImport/redux/dataImportStoreSlice';

export const PreprocessDataImport = () => {
  const { preprocessImportedData } = useSelector(selectPreprocessImportedData);

  return (
    <Box>
      <Typography variant="h6" sx={{ my: 2 }}>
        Files, count of rows, and first row of file:
      </Typography>
      {preprocessImportedData?.map((csvData: any, index: number) => {
        if (!csvData?.rowsToImport) {
          return;
        }
        const columnHeaders = Object?.keys(csvData?.firstRow) || null;
        const columnValues = Object?.values(csvData?.firstRow) || null;
        return (
          <Box key={index} sx={{ mb: 4 }}>
            <Typography>
              {csvData?.fileDescription} file: {csvData?.fileName}
            </Typography>
            <Typography variant="subtitle2">
              Rows to attempt to import {csvData?.rowsToImport}
            </Typography>
            <Table>
              <TableHead>
                <TableRow>
                  {columnHeaders?.map((columnHeader: any, i: number) => (
                    <TableCell key={i}>{columnHeader}</TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  {columnValues?.map((columnValue: any, i: number) => (
                    <TableCell key={i}>{columnValue}</TableCell>
                  ))}
                </TableRow>
              </TableBody>
            </Table>
          </Box>
        );
      })}
    </Box>
  );
};
