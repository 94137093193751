import React, { useState, useEffect } from 'react';
import {
  Grid,
  Card,
  CardHeader,
  CardContent,
  TextField,
  FormControlLabel,
  Switch,
  Button,
  List,
  Typography,
  Box,
  Autocomplete,
} from '@mui/material';
import { v4 as uuidv4 } from 'uuid';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { hasErrorRequired } from 'services/logic/formValidation';
import CheckboxItemDefinition from './CheckboxItemDefinition';
import { UserTypesArray } from 'lg-helpers/dist/constants/user/UserTypes';
import sectionsForCustomInputs from 'utils/sectionsForCustomInputs';
import ErrorMessages from 'services/constants/errorMessages';
import {
  CustomInputCheckbox,
  CustomInputListItem,
} from 'lg-helpers/dist/shared/types/custom-inputs';
const { isRequired } = ErrorMessages;

export interface CustomInputCheckboxDefinitionProps {
  inputDefinition: CustomInputCheckbox;
  setInputDefinition: (newDefinition: CustomInputCheckbox) => any;
  canValidate: boolean;
  setCanValidate: (canValidate: boolean) => any;
  hideCallback: (definition: CustomInputCheckbox) => any;
  userCanEdit?: boolean;
}

const CustomInputCheckboxDefinition = ({
  inputDefinition,
  setInputDefinition,
  canValidate,
  setCanValidate,
  hideCallback,
  userCanEdit = true,
}: CustomInputCheckboxDefinitionProps) => {
  const [localInputDefinition, setLocalInputDefinition] = useState(inputDefinition);

  const updateExternalInputDefinition = (newValue: CustomInputCheckbox) => {
    let hasError =
      hasErrorRequired(newValue.label) ||
      hasErrorRequired(newValue.propertyPathInManifestCustomInputs);
    if (newValue.items && newValue.items.length) {
      newValue.items.forEach(item => {
        hasError = hasError || hasErrorRequired(item.label) || hasErrorRequired(item.value);
      });
    }

    setInputDefinition({
      ...newValue,
      propertyPathInManifestCustomInputs: (
        newValue.propertyPathInManifestCustomInputs || ''
      ).trim(),
      isValidCustomInputDefinition: !hasError,
    });
  };

  const handleChange = (
    event: { target: { name: string; value: string; type?: string; checked?: boolean } },
    callUpdateExternal?: boolean
  ) => {
    const changedLocalInputDefinition = {
      ...localInputDefinition,
      [event.target.name]:
        event.target.type === 'checkbox' ? event.target.checked : event.target.value || '',
    };
    setLocalInputDefinition(changedLocalInputDefinition);
    if (callUpdateExternal) {
      updateExternalInputDefinition(changedLocalInputDefinition);
    }
    setCanValidate(false);
  };

  const handleBlur = () => {
    updateExternalInputDefinition(localInputDefinition);
  };

  const setListItem = (newItem: CustomInputListItem) => {
    let newItems: CustomInputListItem[] = [];

    if (localInputDefinition.items && localInputDefinition.items.length > 0) {
      newItems = localInputDefinition.items.map(oldItem => {
        if (oldItem.id === newItem.id) {
          return newItem;
        }
        return oldItem;
      });
    }

    const changedLocalInputDefinition = {
      ...localInputDefinition,
      items: newItems,
    };
    setLocalInputDefinition(changedLocalInputDefinition);
    updateExternalInputDefinition(changedLocalInputDefinition);
  };

  const removeListItem = (id: string) => {
    const newItems = localInputDefinition.items.filter(item => item.id !== id);
    const changedLocalInputDefinition = {
      ...localInputDefinition,
      items: newItems,
    };
    setLocalInputDefinition(changedLocalInputDefinition);
    updateExternalInputDefinition(changedLocalInputDefinition);
  };

  const addListItem = () => {
    const newItem = {
      id: uuidv4(),
    } as CustomInputListItem;

    const changedLocalInputDefinition = {
      ...localInputDefinition,
      items: [...(localInputDefinition.items || []), newItem],
    };
    setLocalInputDefinition(changedLocalInputDefinition);
    updateExternalInputDefinition(changedLocalInputDefinition);
  };

  useEffect(() => {
    setLocalInputDefinition(inputDefinition);
  }, [inputDefinition]);

  useEffect(() => {
    if (!localInputDefinition) {
      // setLocalInputDefinition({}); // it should work without this line
      return;
    }

    if (!localInputDefinition.items || localInputDefinition.items.length === 0) {
      addListItem();
    }
    // eslint-disable-next-line
  }, [localInputDefinition]);

  return (
    <Card>
      <CardHeader
        title={`${localInputDefinition.order}.[${localInputDefinition.type}] ${
          localInputDefinition.label || 'New Custom Input Definition'
        }`}
        action={
          <>
            <FormControlLabel
              control={
                <Switch
                  disabled={!userCanEdit}
                  checked={!!localInputDefinition.isRequired}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  name="isRequired"
                  color="primary"
                />
              }
              label="Is Required"
            />
            <Button
              variant="outlined"
              onClick={() => hideCallback(inputDefinition)}
              disabled={!userCanEdit}
            >
              {inputDefinition.isHidden ? (
                <>
                  <VisibilityOffIcon color="secondary" />
                  <Box marginLeft={1}>
                    <Typography color="secondary">Is hidden</Typography>
                  </Box>
                </>
              ) : (
                <>
                  <VisibilityIcon />
                  <Box marginLeft={1}>
                    <Typography>Is visible</Typography>
                  </Box>
                </>
              )}
            </Button>
          </>
        }
      />
      <CardContent>
        <Grid container spacing={3}>
          <Grid item sm={6} xs={12}>
            <TextField
              disabled={!userCanEdit}
              fullWidth
              label="Label"
              name="label"
              onChange={handleChange}
              onBlur={handleBlur}
              value={localInputDefinition.label || ''}
              variant="outlined"
              error={hasErrorRequired(localInputDefinition.label, canValidate)}
              helperText={
                hasErrorRequired(localInputDefinition.label, canValidate) ? isRequired : ''
              }
              required
            />
          </Grid>
          <Grid item sm={6} xs={12}>
            <TextField
              disabled={!userCanEdit}
              fullWidth
              label={`Property Path in #.customInputs.${
                localInputDefinition.propertyPathInManifestCustomInputs || '?'
              }`}
              name="propertyPathInManifestCustomInputs"
              onChange={handleChange}
              onBlur={handleBlur}
              value={localInputDefinition.propertyPathInManifestCustomInputs || ''}
              variant="outlined"
              error={hasErrorRequired(
                localInputDefinition.propertyPathInManifestCustomInputs,
                canValidate
              )}
              helperText={
                hasErrorRequired(
                  localInputDefinition.propertyPathInManifestCustomInputs,
                  canValidate
                )
                  ? isRequired
                  : ''
              }
              required
            />
          </Grid>
          <Grid item sm={6} xs={12}>
            <TextField
              disabled={true}
              fullWidth
              label="Column index"
              name="columnIndex"
              value={localInputDefinition.columnIndex || ''}
              variant="outlined"
            />
          </Grid>
          <Grid item sm={6} xs={12} />
          <Grid item sm={6} xs={12}>
            <Autocomplete
              multiple
              options={UserTypesArray}
              getOptionDisabled={() => !userCanEdit}
              value={localInputDefinition.userTypesCanEdit || []}
              onChange={(event, value) =>
                handleChange(
                  {
                    target: {
                      name: 'userTypesCanEdit',
                      value: value as unknown as string,
                    },
                  },
                  true
                )
              }
              renderInput={params => (
                <TextField
                  {...params}
                  variant="outlined"
                  label="User Types - Edit"
                  placeholder="Select user types"
                />
              )}
            />
          </Grid>
          <Grid item sm={6} xs={12}>
            <Autocomplete
              multiple
              options={sectionsForCustomInputs}
              value={localInputDefinition.sectionsCanEdit || []}
              getOptionDisabled={() => !userCanEdit}
              onChange={(event, value) =>
                handleChange(
                  {
                    target: {
                      name: 'sectionsCanEdit',
                      value: value as unknown as string,
                    },
                  },
                  true
                )
              }
              renderInput={params => (
                <TextField
                  {...params}
                  variant="outlined"
                  label="Edit in Sections"
                  placeholder="Select sections"
                />
              )}
            />
          </Grid>
          <Grid item sm={6} xs={12}>
            <Autocomplete
              multiple
              options={UserTypesArray}
              getOptionDisabled={() => !userCanEdit}
              value={localInputDefinition.userTypesCanOnlyRead || []}
              onChange={(event, value) =>
                handleChange(
                  {
                    target: {
                      name: 'userTypesCanOnlyRead',
                      value: value as unknown as string,
                    },
                  },
                  true
                )
              }
              renderInput={params => (
                <TextField
                  {...params}
                  variant="outlined"
                  label="User Types - Only Read"
                  placeholder="Select user types"
                />
              )}
            />
          </Grid>
          <Grid item sm={6} xs={12}>
            <Autocomplete
              multiple
              options={sectionsForCustomInputs}
              value={localInputDefinition.sectionsCanOnlyRead || []}
              getOptionDisabled={() => !userCanEdit}
              onChange={(event, value) =>
                handleChange(
                  {
                    target: {
                      name: 'sectionsCanOnlyRead',
                      value: value as unknown as string,
                    },
                  },
                  true
                )
              }
              renderInput={params => (
                <TextField
                  {...params}
                  variant="outlined"
                  label="Sections Only Read"
                  placeholder="Select sections"
                />
              )}
            />
          </Grid>
        </Grid>
        <Box marginTop={3}>
          <Typography>Choices</Typography>
          <List>
            {localInputDefinition.items &&
              localInputDefinition.items.length > 0 &&
              localInputDefinition.items.map((item, index) => {
                return (
                  <CheckboxItemDefinition
                    key={item.id}
                    listItem={item}
                    showRemoveButton={localInputDefinition.items.length !== 1}
                    showAddButton={localInputDefinition.items.length - 1 === index}
                    onSetListItem={setListItem}
                    onAddListItem={addListItem}
                    onRemoveListItem={removeListItem}
                    canValidate={canValidate}
                    userCanEdit={userCanEdit}
                  />
                );
              })}
          </List>
        </Box>
      </CardContent>
    </Card>
  );
};

export default CustomInputCheckboxDefinition;
