import React from 'react';
import { GridColDef } from '@mui/x-data-grid';
import { Button } from '@mui/material';
import { EditIcon } from 'modules/civilAndTrucking/shared/CustomIcons';
import { ITruckTypeFlattenedCapacities } from '../TruckTypes';
import { unitsOfMeasure } from 'lg-helpers/dist/constants/global-constants';

export const generateTruckTypesColumns = (
  showModal: (truckType: ITruckTypeFlattenedCapacities) => void
) => {
  return [
    {
      field: 'nameTruckingTruckType',
      headerName: 'Truck Type',
      width: 200,
    },
    ...unitsOfMeasure.map(e => ({
      field: e,
      sortable: false,
    })),
    {
      field: 'edit',
      headerName: ' ',
      flex: 1,
      align: 'right',
      sortable: false,
      renderCell: ({ row }) => (
        <Button color="success" onClick={() => showModal(row as ITruckTypeFlattenedCapacities)}>
          <EditIcon />
        </Button>
      ),
    },
  ] as GridColDef[];
};
