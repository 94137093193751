import React, { useEffect, useState } from 'react';
import {
  Grid,
  Typography,
  Box,
  SvgIcon,
  CircularProgress,
  TextField,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  IconButton,
  Button,
} from '@mui/material';
import { ChevronLeft, AddCircle, RemoveCircle } from '@mui/icons-material';
import { useNavigate, useParams } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import api, {
  CivilCompanySubMaterials,
  CivilProjectSubMaterials,
  IProject,
} from 'services/api/autogenerated';
import useShowError from 'modules/errors';
import moment from 'moment';
import { useDispatch } from 'react-redux';
import { pushNotification } from 'modules/civilAndTrucking/shared/NotificationSnackbar/redux/actions';

const html5DateFormat = 'yyyy-MM-DD';

interface ConfigRow {
  start_date: Date;
  id: number;
  uid: string;
  price_per_unit: string;
}

export const ProjectSubMaterialForm = () => {
  const { projectId: _projectId, subMaterialId: _subMaterialId } = useParams();
  const projectId = parseInt(_projectId || '0');
  const subMaterialId = parseInt(_subMaterialId || '0');

  const navigate = useNavigate();
  const showError = useShowError();
  const dispatch = useDispatch();

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [subMaterialsRows, setSubMaterialsRows] = useState<CivilProjectSubMaterials[]>([]);
  const [configRows, setConfigRows] = useState<ConfigRow[]>([]);
  const [materialParent, setMaterialParent] = useState<CivilCompanySubMaterials>();
  const [project, setProject] = useState<IProject>();

  const setStartDate = (uid: string, value: string) => {
    const date = moment.tz(value, project?.timeZone || 'EST').toDate();
    setConfigRows(configRows.map(row => (row.uid === uid ? { ...row, start_date: date } : row)));
  };

  const setPrice = (uid: string, value: string) =>
    setConfigRows(
      configRows.map(row => (row.uid === uid ? { ...row, price_per_unit: value } : row))
    );

  const addRowAfter = (idx: number) => {
    const newRows = [...configRows];
    const today = new Date();
    const isProjectActive =
      new Date(project?.startDate || '').getTime() < today.getTime() ||
      new Date(project?.endDate || '').getTime() > today.getTime();
    const date = isProjectActive ? today : new Date(configRows[idx].start_date);
    if (!isProjectActive) date.setDate(date.getDate() + 1);

    newRows.splice(idx + 1, 0, {
      start_date: date,
      id: 0,
      uid: uuidv4(),
      price_per_unit: configRows[idx].price_per_unit,
    });

    setConfigRows(newRows);
  };

  const dropRow = (idx: number) => {
    setConfigRows(configRows.filter((row, i) => i !== idx));
  };

  const onSubmit = async () => {
    if (!project) return;
    try {
      setIsSubmitting(true);

      await api.civilProjects.saveProjectSubMaterialsConfiguration(
        projectId,
        subMaterialId,
        configRows.map(row => ({
          id: row.id,
          price_per_unit: row.price_per_unit,
          start_date: moment
            .tz(row.start_date, project.timeZone || 'EST')
            .startOf('day')
            .toISOString(),
          project_id: projectId,
          sub_material_id: subMaterialId,
        }))
      );

      dispatch(
        pushNotification({
          status: 'success',
          message: `Sub-Materials configuration saved!`,
        })
      );

      setTimeout(() => navigate(-1), 2000);
    } catch (err) {
      console.error('Failed to save sub materials configuration', err);
      showError({
        title: 'Failed to save sub materials configuration',
        duration: 10000,
      });
    } finally {
      setIsSubmitting(false);
    }
  };

  useEffect(() => {
    if (!project) return;

    const virtualRows: CivilProjectSubMaterials[] = subMaterialsRows.length
      ? subMaterialsRows
      : [
          {
            id: 0,
            start_date: null,
            price_per_unit: '0.0',
            sub_material_id: subMaterialId,
            project_id: project.id as number,
          },
        ];

    const computed: ConfigRow[] = virtualRows
      .map(row => ({
        start_date: moment
          .tz(row.start_date || project.startDate || new Date(), project.timeZone || 'EST')
          .toDate(),
        id: row.id,
        uid: uuidv4(),
        price_per_unit: row.price_per_unit || '0.0',
      }))
      .sort((a, b) => a.start_date.getTime() - b.start_date.getTime());

    // first row should always be from beginning of the project
    if (project.startDate && computed[0].start_date > new Date(project.startDate)) {
      computed[0].start_date = moment(project.startDate, project.timeZone || 'EST').toDate();
    }

    setConfigRows(computed);
  }, [subMaterialId, project, subMaterialsRows]);

  useEffect(() => {
    const load = async () => {
      try {
        setIsLoading(true);

        const [{ data: proj }, { data: parent }, { data: materialRows }] = await Promise.all([
          api.civilProjects.getProjectById(projectId),
          api.civilCompanies.getSubMaterialById(subMaterialId),
          api.civilProjects.getAllProjectSubMaterialsByProjectIdAndCompanySubMaterial(
            projectId,
            subMaterialId
          ),
        ]);

        setProject(proj);
        setMaterialParent(parent);
        setSubMaterialsRows(materialRows);
      } catch (err) {
        console.error('Failed to load sub materials configuration', err);
        showError({
          title: 'Failed to load sub materials configuration',
          duration: 10000,
        });
      } finally {
        setIsLoading(false);
      }
    };

    load();
  }, [projectId, subMaterialId, showError]);

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={8}>
          <Box>
            <Typography
              variant="h5"
              sx={{
                color: 'neutral.main',
                typography: { textTransform: 'uppercase' },
              }}
            >
              Configure Sub-Material
            </Typography>
            <Box
              display="flex"
              sx={{
                '&:hover': {
                  cursor: 'pointer',
                },
              }}
            >
              <SvgIcon>
                <ChevronLeft />
              </SvgIcon>
              <Typography onClick={() => navigate(-1)}>Back</Typography>
            </Box>
          </Box>
        </Grid>
        <Grid item xs={2}>
          <Button
            fullWidth
            disabled={isSubmitting}
            color="primary"
            variant="contained"
            onClick={onSubmit}
          >
            Save
          </Button>
        </Grid>

        <Grid item xs={12}>
          {isLoading ? (
            <Box display="flex" justifyContent="center" margin={2}>
              <CircularProgress />
            </Box>
          ) : (
            <>
              <Typography variant="h6" style={{ marginBottom: '1em' }}>
                <strong>Identifier</strong>:{' '}
                <Typography sx={{ display: 'inline-block', marginRight: '10px' }}>
                  {materialParent?.name}
                </Typography>
                <strong>Description</strong>:{' '}
                <Typography sx={{ display: 'inline-block', marginRight: '10px' }}>
                  {materialParent?.description}
                </Typography>
                <strong>Project End Date</strong>:{' '}
                <Typography sx={{ display: 'inline-block' }}>
                  {moment.tz(project?.endDate, project?.timeZone || 'EST').format('MM/DD/YYYY')}
                </Typography>
              </Typography>

              <TableContainer>
                <Table stickyHeader aria-label="sticky table">
                  <TableHead>
                    <TableRow>
                      <TableCell style={{ maxWidth: 200 }}>Start Date</TableCell>
                      <TableCell style={{ maxWidth: 100 }}>Price per unit</TableCell>
                      <TableCell style={{ maxWidth: 100 }}></TableCell>
                    </TableRow>
                  </TableHead>
                  {configRows.map((row, index) => (
                    <TableRow key={row.uid}>
                      <TableCell>
                        <TextField
                          type="date"
                          onChange={e => setStartDate(row.uid, e.target.value)}
                          value={moment
                            .tz(row.start_date, project?.timeZone || 'EST')
                            .format(html5DateFormat)}
                          name={`start_date_${row.uid}`}
                          variant="outlined"
                          disabled={!index}
                        />
                      </TableCell>
                      <TableCell>
                        <TextField
                          type="number"
                          onChange={e => setPrice(row.uid, e.target.value)}
                          value={row.price_per_unit}
                          name={`price_per_unit_${row.uid}`}
                          variant="outlined"
                        />
                      </TableCell>
                      <TableCell>
                        <IconButton disabled={!index} onClick={() => dropRow(index)}>
                          <RemoveCircle />
                        </IconButton>
                        <IconButton onClick={() => addRowAfter(index)}>
                          <AddCircle />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))}
                </Table>
              </TableContainer>
            </>
          )}
        </Grid>
      </Grid>
    </>
  );
};
