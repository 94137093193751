import React from 'react';
import {
  Autocomplete,
  AutocompleteProps,
  AutocompleteRenderInputParams,
  Grid,
  SvgIcon,
} from '@mui/material';
import { LocationOn } from '@mui/icons-material';
import { resolveAddress, IGeocoderFeature } from 'services/mapbox/geocoding';
import { throttle } from 'lodash';

export interface IGeocoderFeatureAdvanced extends IGeocoderFeature {
  city?: string;
  state?: string;
  postalCode?: string;
}

interface InputAddressAutocompleteProps<T> extends AutocompleteProps<T, boolean, boolean, boolean> {
  renderInput: (props: AutocompleteRenderInputParams) => React.ReactElement;
  handleSelect: (val: IGeocoderFeature) => void;
  searchRequirement: number;
}

/**
 * Warning: Make sure inputValue props is not undefined value
 */

export const InputAddressAutocomplete = (
  props: InputAddressAutocompleteProps<IGeocoderFeature>
): React.ReactElement => {
  const { searchRequirement, handleSelect, ...passedProps } = props;
  const [value, setValue] = React.useState<IGeocoderFeature | null>(null);
  const [inputValue, setInputValue] = React.useState('');
  const [options, setOptions] = React.useState<IGeocoderFeature[]>([]);
  const [optionsLoading, setOptionsLoading] = React.useState<boolean>(false);

  const fetch = React.useMemo(
    () =>
      throttle((request: { input: string }, callback: (results?: IGeocoderFeature[]) => void) => {
        setOptionsLoading(true);
        resolveAddress(request.input).then(locations => {
          setOptionsLoading(false);
          callback(locations);
        });
      }, 1000),
    // eslint-disable-next-line
    []
  );

  React.useEffect(() => {
    let active = true;
    if (inputValue === '') {
      setOptions(value ? [value] : []);
      return undefined;
    } else if (inputValue.length >= searchRequirement) {
      fetch({ input: inputValue }, (results?: IGeocoderFeature[]) => {
        if (active) {
          let newOptions = [] as IGeocoderFeature[];

          if (value) {
            newOptions = [value];
            if (handleSelect) {
              // Remove country from address, as it causes issues
              const newPlaceName = newOptions[0].place_name.split(',').slice(0, -1).join();
              const valueWithoutCode = {
                ...value,
                place_name: newPlaceName,
              };
              handleSelect(valueWithoutCode);
            }
          }

          if (results) {
            newOptions = [...newOptions, ...results];
          }

          setOptions(newOptions);
        }
      });
    }
    return () => {
      active = false;
    };
    // eslint-disable-next-line
  }, [value, inputValue, fetch, searchRequirement]);

  const handleNewValueChange = React.useCallback(
    (newValue: IGeocoderFeatureAdvanced) => {
      newValue?.context?.map(e => {
        const contextType = e.id.split('.')[0];
        switch (contextType) {
          case 'place':
            if (!newValue.city) newValue.city = e.text;
            break;
          case 'district':
            if (!newValue.city) newValue.city = e.text;
            break;
          case 'region':
            newValue.state = e.text;
            break;
          case 'postcode':
            newValue.postalCode = e.text;
            break;
          default:
            break;
        }
      });
      setValue(newValue as IGeocoderFeatureAdvanced);
    },
    // eslint-disable-next-line
    [setValue]
  );

  React.useEffect(() => {
    if (props.value) {
      handleNewValueChange(props.value as IGeocoderFeature);
    } else {
      setValue(null);
    }
    // eslint-disable-next-line
  }, [props.value]);

  return (
    <Autocomplete<IGeocoderFeatureAdvanced, boolean, boolean, boolean>
      {...passedProps}
      getOptionLabel={option => {
        const optionValue = option as IGeocoderFeatureAdvanced;
        return optionValue.place_name;
      }}
      filterOptions={x => x}
      options={options}
      includeInputInList
      filterSelectedOptions
      loading={optionsLoading}
      noOptionsText={
        inputValue.length > searchRequirement
          ? 'No addresses found'
          : `Search requires at least ${searchRequirement} characters`
      }
      autoHighlight={true}
      onChange={(event: React.ChangeEvent<{}>, val) => {
        const newValue = val as IGeocoderFeatureAdvanced;
        setOptions(newValue ? [newValue, ...options] : options);
        handleNewValueChange(newValue);
      }}
      onInputChange={(event, newInputValue: string) => {
        setInputValue(newInputValue);
      }}
      renderOption={(prop, option: IGeocoderFeatureAdvanced) => (
        <li {...prop}>
          <Grid container alignItems="center">
            <Grid item>
              <SvgIcon sx={{ color: 'rgba(0, 0, 0, 0.87)', marginRight: '16px' }}>
                <LocationOn />
              </SvgIcon>
            </Grid>
            <Grid item xs>
              {option.place_name}
            </Grid>
          </Grid>
        </li>
      )}
    />
  );
};

export default InputAddressAutocomplete;
