import React, { useState, useEffect } from 'react';
import { Box, Tooltip, Typography } from '@mui/material';
import {
  DataGrid,
  GridColDef,
  GridEventListener,
  GridEvents,
  GridRowModel,
  GridSelectionModel,
} from '@mui/x-data-grid';
import { RootState } from 'store/store';

import { ArrowDropDown, ArrowDropUp, Visibility, StopRounded } from '@mui/icons-material';

import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { selectAuthUser } from 'modules/auth/storeSliceAuth';
import { ITicketSummary } from 'lg-helpers/dist/shared/interfaces/ITicketSummary';

const scoreColorThreshholds = [
  {
    low: -1,
    high: 0,
    value: '#ccc',
  },
  {
    low: 0,
    high: 70,
    value: '#C61C1C',
  },
  {
    low: 70,
    high: 80,
    value: '#F4E3E3',
  },
  {
    low: 80,
    high: 90,
    value: '#BE9400',
  },
  {
    low: 90,
    high: 100,
    value: '#FFF8DB',
  },
  {
    low: 100,
    high: 110,
    value: '#EFF8DF',
  },
  {
    low: 110,
    high: 1000,
    value: '#97C06B',
  },
];

const calculateProjectStatus = (data: ITicketSummary) => {
  const score = (data.percentCompleted / (100 - data.timePercentRemaining)) * 100;
  const scoreValue =
    data.ticketCount > 0
      ? scoreColorThreshholds.find(range => score >= range.low && score < range.high) ||
        scoreColorThreshholds[0]
      : scoreColorThreshholds[0];

  return scoreValue.value;
};

const ticketSummaryColumns: GridColDef[] = [
  {
    field: 'status',
    headerName: 'Status',
    width: 100,
    renderCell: params => {
      const scoreColor = calculateProjectStatus(params.row as ITicketSummary);
      return <StopRounded style={{ color: scoreColor }} fontSize="large" />;
    },
  },
  {
    field: 'projectNumber',
    headerName: 'No.',
    width: 90,
  },
  {
    field: 'projectName',
    headerName: 'Name',
    width: 250,
    renderCell: data => (
      <Link to={`/civil/projects/${data.row.projectId}`}>{data.row.projectName}</Link>
    ),
  },
  {
    field: 'ticketCount',
    headerName: 'Tickets',
    width: 120,
  },
  {
    field: 'loadCompleted',
    headerName: 'Completed Volume',
    width: 150,
    renderCell: data => data.row.loadCompleted.toFixed(2),
  },
  {
    field: 'target',
    headerName: 'Target Volume',
    width: 150,
  },
  {
    field: 'avgLoad',
    headerName: 'Avg. Load',
    width: 130,
  },
  {
    field: 'transporter',
    headerName: 'Transporter',
    width: 130,
    renderCell: data => (
      <Tooltip
        title={
          <span>
            Open: {data.row.transporterOpen}
            <br />
            Issue: {data.row.transporterIssue}
            <br />
            Total: {data.row.transporterTotal}
          </span>
        }
      >
        <Typography>
          {data.row.transporterOpen} / {data.row.transporterIssue} / {data.row.transporterTotal}
        </Typography>
      </Tooltip>
    ),
  },
  {
    field: 'supplier',
    headerName: 'Supplier',
    width: 130,
    renderCell: data => (
      <Tooltip
        title={
          <span>
            Open: {data.row.supplierOpen}
            <br />
            Issue: {data.row.supplierIssue}
            <br />
            Total: {data.row.supplierTotal}
          </span>
        }
      >
        <Typography>
          {data.row.supplierOpen} / {data.row.supplierIssue} / {data.row.supplierTotal}
        </Typography>
      </Tooltip>
    ),
  },
  {
    field: 'percentCompleted',
    headerName: 'Completed',
    valueFormatter: ({ value }) => `${value}%`,
    width: 140,
  },
  {
    field: 'show',
    headerName: ' ',
    renderCell: data => {
      // TO DO -> Navigate to different pages based on user type
      // accounting should navigate to reconciliation page with project pre-selected
      return (
        <Link to={`/civil/projects/${data.row.projectId}`}>
          <Visibility />
        </Link>
      );
    },
  },
];

interface IProjectListProps {
  selectProject: Function;
  selectedProjectId?: number | string;
}

export const ProjectsList = ({ selectProject, selectedProjectId }: IProjectListProps) => {
  const authUser = useSelector(selectAuthUser);
  const [selectionModel, setSelectionModel] = useState<GridSelectionModel>([]);

  const activeProject = useSelector((state: RootState) => state.civil.projects.activeProject);

  useEffect(() => {
    if (selectionModel.length > 0 && !selectedProjectId) {
      setSelectionModel([]);
    }
  }, [selectedProjectId, selectionModel]);

  const { type } = authUser;
  const isCivilAccountant = type === 'Civil Accountant';

  const ticketSummaryData: ITicketSummary[] = useSelector(
    (state: RootState) => state.civil.tickets.summary
  );

  const handleRowClick: GridEventListener<GridEvents.rowClick> = (data: GridRowModel) => {
    if (data.row.projectId) {
      selectProject(data.row.projectId);
    }
  };

  return (
    <Box
      sx={{
        height: '100%',
        width: '100%',
        '& .selected': { bgcolor: 'neutral.lightest' },
      }}
    >
      <DataGrid
        columns={ticketSummaryColumns}
        columnVisibilityModel={{
          target: !isCivilAccountant,
          ticketCount: !isCivilAccountant,
          avgLoad: !isCivilAccountant,
          percentCompleted: !isCivilAccountant,
          transporter: isCivilAccountant,
          supplier: isCivilAccountant,
          loadCompleted: !isCivilAccountant,
        }}
        rows={ticketSummaryData || []}
        showColumnRightBorder={false}
        getRowId={row => row.rowNumber}
        onRowClick={handleRowClick}
        onSelectionModelChange={newSelectionModel => {
          setSelectionModel(newSelectionModel);
        }}
        selectionModel={selectionModel}
        components={{
          ColumnSortedAscendingIcon: ArrowDropUp,
          ColumnSortedDescendingIcon: ArrowDropDown,
          ColumnResizeIcon: () => <div></div>,
          Header: () => <Typography variant="h1" />,
        }}
        getRowClassName={data => {
          return data.row.projectId === activeProject?.id ? 'selected' : '';
        }}
      />
    </Box>
  );
};

export default ProjectsList;
