import React from 'react';
import { Box, Button, Typography, Grid, Container } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import Page from 'sharedComponents/Page';
import { useNavigate } from 'react-router-dom';
import TrafficLightsGreen from 'sharedComponents/pictograms/TrafficLightsGreen';
import useIsUserOfType from 'services/hooks/useIsUserOfType';
import { useDispatch } from 'react-redux';
import { setCustomData } from 'modules/transporters/storeSliceTransporters.js';

const GoGreenLightView = () => {
  const navigate = useNavigate();
  const isUserBrokerDriverOrAdmin = useIsUserOfType('trucking_driver', 'trucking_dispatcher');

  const dispatch = useDispatch();
  dispatch(setCustomData(null));

  const handleClickGo = async () => {
    if (isUserBrokerDriverOrAdmin) {
      navigate('/brokers/driver/dispatches');
    } else {
      navigate('/');
      // navigate('/drivers/custom-data'); // We don't have this screen
    }
  };

  return (
    <Page title="Green Light">
      <Container>
        <Grid container>
          <Grid item xs={12}>
            <Box marginTop={3} display="flex" justifyContent="center" alignItems="center">
              <TrafficLightsGreen />
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Box marginTop={3} display="flex" justifyContent="center" alignItems="center">
              <Typography variant="h3">Clear to proceed!</Typography>
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Box marginTop={3} display="flex" justifyContent="center" alignItems="center">
              <Button onClick={handleClickGo} variant="contained" color="primary" fullWidth>
                <Box marginRight={2}>
                  <CheckCircleIcon color="inherit" />
                </Box>
                <Typography variant="h4">Go</Typography>
              </Button>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
};

export default GoGreenLightView;
