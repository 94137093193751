import { createAsyncThunk } from '@reduxjs/toolkit';
import Tables from 'services/postgres/Tables';
import ApiBase from 'services/postgres/ApiBase';
import { INotification } from 'modules/civilAndTrucking/shared/NotificationSnackbar/INotification';
import { pushNotification } from 'modules/civilAndTrucking/shared/NotificationSnackbar/redux/actions';
import { RootState } from 'store/store';
import { pushError } from 'modules/errors/storeSliceErrors';
import { IJoinTruck } from 'lg-helpers/dist/trucking/interfaces/IJoinTruck';
import { ITruckingTruck } from 'lg-helpers/dist/trucking/interfaces/ITruckingTruck';

const apiBase = new ApiBase();
const TRUCK_TABLE = Tables.TruckingTruck;

export const getJoinedTrucksByCompanyId = createAsyncThunk(
  'trucks/get-joined-trucks-by-company-id',
  async (payload: undefined, thunkApi) => {
    try {
      const data = await apiBase.getList(`${TRUCK_TABLE}`);

      return data as IJoinTruck[];
    } catch (err) {
      thunkApi.dispatch(pushError({ title: 'Error: Unable to get trucks' }));
      throw err;
    }
  }
);

export const getJoinedTrucksByDivisionId = createAsyncThunk(
  'trucks/get-joined-trucks-by-division-id',
  async (divisionId: number, thunkApi) => {
    try {
      const data = await apiBase.getList(`${TRUCK_TABLE}/division/${divisionId}`);
      return data as IJoinTruck[];
    } catch (err) {
      thunkApi.dispatch(pushError({ title: 'Error: Unable to get trucks' }));
      throw err;
    }
  }
);

export const getJoinedTrucksExcludingDivisionId = createAsyncThunk(
  'trucks/get-joined-trucks',
  async (divisionId: number, thunkApi) => {
    try {
      const data = await apiBase.getList(`${TRUCK_TABLE}/division_exclude/${divisionId}`);

      return data as IJoinTruck[];
    } catch (err) {
      thunkApi.dispatch(pushError({ title: 'Error: Unable to get trucks' }));
      throw err;
    }
  }
);

export const upsertTruck = createAsyncThunk(
  'trucks/add-truck',
  async (payload: Partial<ITruckingTruck>, thunkApi) => {
    const state = thunkApi.getState() as RootState;
    const companyId = state.civil.companies.activeCompany?.id;

    const truckReq = {
      ...payload,
      companyIdTruckingTruck: payload.companyIdTruckingTruck || companyId,
    };

    try {
      await apiBase.insertOrUpdate(truckReq, Tables.TruckingTruck);
      thunkApi.dispatch(getJoinedTrucksByCompanyId());
    } catch (err) {
      thunkApi.dispatch(pushError({ title: 'Error: Unable to create new truck' }));
    }
  }
);

export const deleteTruck = createAsyncThunk(
  'trucks/delete-truck',
  async (truckId: number, thunkApi) => {
    try {
      await apiBase.deleteById(truckId, Tables.TruckingTruck);
      const successNotification: INotification = {
        status: 'success',
        message: 'Success: Truck has been deleted',
      };
      thunkApi.dispatch(pushNotification(successNotification));
      thunkApi.dispatch(getJoinedTrucksByCompanyId());
    } catch (err) {
      thunkApi.dispatch(pushError({ title: 'Error: Unable to delete truck' }));
    }
  }
);
