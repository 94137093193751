import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Typography, Box, CircularProgress } from '@mui/material';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import { auth } from 'services/firebase';

const ButtonResetPassword = ({ email }: { email?: string }) => {
  const [isSendingPasswordResetEmail, setIsSendingPasswordResetEmail] = useState(false);
  const handleClickResetPassword = async () => {
    setIsSendingPasswordResetEmail(true);
    try {
      const { currentUser } = auth;
      const computedEmail = email ? email : currentUser?.email;
      if (!computedEmail) throw new Error('Failed to send reset email (empty email)!');
      await auth.sendPasswordResetEmail(computedEmail);
    } catch (err) {
      console.error(err);
    }
    setIsSendingPasswordResetEmail(false);
  };
  return (
    <Button
      color="inherit"
      variant="outlined"
      onClick={handleClickResetPassword}
      disabled={isSendingPasswordResetEmail}
    >
      <Typography variant="button">
        <Box display="flex" alignItems="center">
          {isSendingPasswordResetEmail ? (
            <CircularProgress color="inherit" />
          ) : (
            <Box display="flex" alignItems="center">
              <MailOutlineIcon color="inherit" />
              &nbsp; Send reset password email
            </Box>
          )}
        </Box>
      </Typography>
    </Button>
  );
};

ButtonResetPassword.propTypes = {
  email: PropTypes.string,
};

export default ButtonResetPassword;
