import React, { HTMLAttributes, useEffect, useState } from 'react';
import { Box, Button, TextField, Autocomplete, Grid, Typography } from '@mui/material';
import AddBoxOutlinedIcon from '@mui/icons-material/AddBoxOutlined';
import { ICustomInputDefinitions } from 'lg-helpers/dist/shared/interfaces/ICustomInputDefinitions';
import { Project } from 'services/api/autogenerated';
import { usePermissionsContext } from 'services/permissions';
import { IUser } from 'lg-helpers/dist/shared/interfaces/IUser';
import Query from 'services/firebase/Query';
import QueryBase from 'lg-helpers/dist/firestore/query/QueryBase';
import Executer from 'services/firebase/Executer';
import { UserTypes } from 'lg-helpers/dist/constants/user/UserTypes';

const query = new Query();
const executer = new Executer();

interface CustomInputsDuplicateProps {
  setCustomInputsDefinitions: (customInputsDefinitions: ICustomInputDefinitions[]) => void;
  hasManifests: boolean;
}

export const CustomInputsDuplicate = ({
  setCustomInputsDefinitions,
  hasManifests,
}: CustomInputsDuplicateProps) => {
  const { user: authUser } = usePermissionsContext();
  const [projects, setProjects] = useState<Project[]>([]);
  const [projectSelected, setProjectSelected] = useState<Project | null>(null);

  useEffect(() => {
    if (!authUser) return;

    const getProjects = async (user: IUser) => {
      try {
        let projectsQuery;
        if (user.type === UserTypes.admin.customer) {
          projectsQuery = query.projects.getProjectsActiveForCustomerAdmin(authUser.id as string);
        } else {
          projectsQuery = query.base.getAll(QueryBase.PROJECTS_COLLECTION());
        }

        const projectsResults = await executer.getMultipleDocuments(projectsQuery);
        setProjects(projectsResults as Project[]);
      } catch (error) {
        console.error('Error', error);
      }
    };
    getProjects(authUser);

    // eslint-disable-next-line
  }, [authUser]);

  const duplicateFromSelectedProject = () => {
    if (!projectSelected) return;
    setCustomInputsDefinitions(
      (projectSelected?.customInputsDefinitions as ICustomInputDefinitions[]) || []
    );
    setProjectSelected(null);
  };

  if (hasManifests) return null;

  return (
    <Grid
      container
      sx={{ marginTop: 2 }}
      columnSpacing={2}
      alignItems="center"
      justifyContent="center"
    >
      <Grid item xs={12} marginBottom={3}>
        <Typography variant="h5">Duplicate Custom Inputs from another Project</Typography>
      </Grid>
      <Grid item xs={12} md={9}>
        <Autocomplete
          fullWidth
          options={projects}
          value={projectSelected}
          onChange={(event, value) => {
            setProjectSelected(value);
          }}
          getOptionLabel={option => option.name || ''}
          renderInput={params => (
            <TextField
              {...params}
              sx={{ margin: '0' }}
              label="Select project to replicate"
              required
              variant="outlined"
              InputProps={{
                ...params.InputProps,
                endAdornment: <>{params.InputProps.endAdornment}</>,
              }}
            />
          )}
          renderOption={(props: HTMLAttributes<HTMLLIElement>, option: Project) => (
            <li {...props} key={option.id as string}>
              <Grid container alignItems="center" justifyContent="flex-start" wrap="nowrap">
                <Grid item xs={6}>
                  <Typography noWrap>{option.name}</Typography>
                </Grid>
              </Grid>
            </li>
          )}
        />
      </Grid>
      <Grid item xs={12} md={3}>
        <Button
          color="primary"
          variant="contained"
          fullWidth
          onClick={duplicateFromSelectedProject}
          disabled={!projectSelected}
        >
          <AddBoxOutlinedIcon color="inherit" />
          <Box marginLeft={1}>Match Custom Inputs</Box>
        </Button>
      </Grid>
    </Grid>
  );
};
