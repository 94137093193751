import React, { useEffect, useState, useMemo } from 'react';
import { DataGrid, GridColumnVisibilityModel } from '@mui/x-data-grid';
import { useSelector } from 'react-redux';
import { selectTruckTypes } from '../../../redux/truckTypesStoreSlice';
import {
  flattenCapacities,
  getColumnVisibilitiesByCapacities,
} from 'modules/civilAndTrucking/trucking/utils';
import { LoadingOverlay as Loading } from 'sharedComponents/LoadingOverlay';
import { generateTruckTypesColumns } from './truckTypesColumns';
import { ITruckTypeFlattenedCapacities } from '../TruckTypes';

type TruckTypesListProps = {
  showModal: (truckType: ITruckTypeFlattenedCapacities) => void;
};

export const TruckTypesList = ({ showModal }: TruckTypesListProps) => {
  const { truckTypes, truckTypesStatus } = useSelector(selectTruckTypes);
  const [truckTypesWithCapacities, setTruckTypesWithCapacities] = useState(
    [] as ITruckTypeFlattenedCapacities[]
  );
  const [visibleColumns, setVisibleColumns] = useState<GridColumnVisibilityModel>({});

  useEffect(() => {
    if (!truckTypes.length) return;

    const truckTypesWithCaps = truckTypes.map(truckType => {
      return {
        ...truckType,
        ...flattenCapacities(truckType.capacitiesTruckingTruckType),
      } as ITruckTypeFlattenedCapacities;
    });
    const columnsRemoveVisibility = getColumnVisibilitiesByCapacities(truckTypesWithCaps);

    setTruckTypesWithCapacities(truckTypesWithCaps);
    setVisibleColumns(columnsRemoveVisibility);
  }, [truckTypes]);

  const columns = useMemo(() => generateTruckTypesColumns(showModal), [showModal]);

  if (truckTypesStatus === 'pending') {
    return <Loading />;
  }

  return (
    <DataGrid
      columns={columns}
      columnVisibilityModel={visibleColumns}
      getRowId={row => row.idTruckingTruckType}
      rows={truckTypesWithCapacities || []}
      showColumnRightBorder={false}
      disableColumnMenu
      disableSelectionOnClick
      autoHeight
      components={{
        ColumnResizeIcon: () => <div></div>,
      }}
    />
  );
};
