import React, { useEffect, useContext } from 'react';
import { thunkDispatch } from 'store/store';
import { getDivisionsByCompanyId } from '../../redux/actions';
import { ITruckingTruckDivision } from 'lg-helpers/dist/trucking/interfaces/ITruckingTruckDivision';
import { DivisionForm } from './DivisionForm';
import { ManageHeader } from 'sharedComponents/ManageHeader';
import { ModalContext } from 'sharedComponents/ModalContext';
import { DivisionsList } from './components/DivisionsList';

export const Divisions = () => {
  const { handleModal } = useContext(ModalContext);

  useEffect(() => {
    thunkDispatch(getDivisionsByCompanyId());
  }, []);

  const showModal = (division?: ITruckingTruckDivision) => {
    handleModal(<DivisionForm editObj={division} />);
  };

  return (
    <>
      <ManageHeader
        headerTitle={'Manage Divisions'}
        buttonText={'Add Division'}
        onButtonClick={showModal}
      />
      <DivisionsList showModal={showModal} />
    </>
  );
};
