import React, { useState, useCallback, useEffect } from 'react';
import {
  Grid,
  Button,
  Typography,
  CircularProgress,
  Box,
  TextField,
  Container,
} from '@mui/material';
import { hasErrorRequired } from 'services/logic/formValidation';
import { setUserPassword } from 'api/auth';
import InputSignature from 'sharedComponents/InputSignature';
import { getBase64FromUrl } from 'services/logic/base64';
import { useSelector } from 'react-redux';
import { selectAuthUser } from 'modules/auth/storeSliceAuth';
import { requireSignatureOnSignup, isTransporterDriver } from './logicDependentOnUserRole';
import Command from 'services/firebase/Command';
import { auth } from 'services/firebase';
import { useNavigate } from 'react-router-dom';
import useShowError from 'modules/errors';
import ErrorMessages from 'services/constants/errorMessages';
import { mixPanel } from 'services/mixpanel';
import ErrorIcon from '@mui/icons-material/Error';
import CheckIcon from '@mui/icons-material/Check';
import './SetPasswordView.scss';
import { handleLogoutAction } from 'sharedComponents/TopBar';

const { isRequired } = ErrorMessages;
const command = new Command();

interface IPasswordData {
  password: string;
  confirmPassword: string;
}

const SetPasswordView = () => {
  const [passwordData, setPasswordData] = useState<IPasswordData>({
    password: '',
    confirmPassword: '',
  });
  const [isLoginInProgress, setIsLoginInProgress] = useState(false);
  const showError = useShowError();
  const [wasSubmitted, setWasSubmitted] = useState(false);
  const [isSignatureRequired, setIsSignatureRequired] = useState(false);
  const [isModifiedSignatureBase64, setIsModifiedSignatureBase64] = useState(false);
  const [isLoadingSignatureBase64, setIsLoadingSignatureBase64] = useState(false);
  const [signatureBase64, setSignatureBase64] = useState('');
  const [passwordValidation, setPasswordValidation] = useState({
    upperCase: false,
    lowerCase: false,
    number: false,
    charLength: false,
    matching: false,
  });

  const authUser = useSelector(selectAuthUser);
  const navigate = useNavigate();

  useEffect(() => {
    if (!authUser) {
      return;
    }

    if (authUser.passwordInitial && requireSignatureOnSignup(authUser.actingAsType)) {
      setIsSignatureRequired(true);

      if (!authUser.signature || !authUser.signature.url) {
        return;
      }

      (async () => {
        try {
          setIsLoadingSignatureBase64(true);
          const base64 = await getBase64FromUrl(authUser.signature.url);
          setSignatureBase64(base64);
        } finally {
          setIsLoadingSignatureBase64(false);
        }
      })();
    }
  }, [authUser]);

  const onSignatureBase64Update = useCallback((base64: string) => {
    setSignatureBase64(base64);
    setIsModifiedSignatureBase64(true);
  }, []);

  const validatePassword = (checkPassword: IPasswordData) => {
    const { password, confirmPassword } = checkPassword;
    const passwordTests = {
      upperCase: Boolean(password.match(/[A-Z]/)),
      lowerCase: Boolean(password.match(/[a-z]/)),
      number: Boolean(password.match(/[0-9]/)),
      charLength: password.length >= 8 && password.length <= 100,
      matching: password === confirmPassword,
    };
    setPasswordValidation(passwordTests);
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const newPasswordData = {
      ...passwordData,
      [event.target.name]: event.target.value ? event.target.value.trim() : event.target.value,
    };
    setPasswordData(newPasswordData);
    validatePassword(newPasswordData);
    setWasSubmitted(false);
  };

  const handleSubmitSetPassword = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setWasSubmitted(true);
    const { password, confirmPassword } = passwordData;
    if (!password || !confirmPassword) {
      return;
    }

    if (password !== confirmPassword) {
      showError({ title: "Passwords don't match!" });
      return;
    }

    if (isSignatureRequired && !signatureBase64) {
      showError({ title: 'Signature required!' });
      return;
    }

    setIsLoginInProgress(true);

    try {
      if (isModifiedSignatureBase64) {
        try {
          if (authUser.signature && authUser.signature.url && !authUser.signature.wasUsed) {
            await command.deleteFile(authUser.signature.url);
          }
        } catch (err) {
          console.error('Delete signature error', err);
        }

        const url = await command.uploadUserSignatureAndGetUrl(authUser.id, signatureBase64);
        const signature = {
          url,
          wasUsed: false,
        };

        await command.manifests.userUpdateSignature(authUser.id, signature);

        mixPanel(authUser, `New user created new password`, { timestamp: Date() });

        setIsModifiedSignatureBase64(false);
      }
    } catch (err: any) {
      if (err && err.response && err.response.data && err.response.data.message) {
        showError({
          title: 'Error saving signature!',
          text: err.response.data.message,
        });
      } else {
        showError({ title: 'Error saving signature!' });
      }
    }

    try {
      await setUserPassword(password);
      if (isTransporterDriver(authUser.actingAsType)) {
        navigate('/app-download');
      } else {
        await handleLogoutAction();
      }
    } catch (err: any) {
      if (err && err.response && err.response.data && err.response.data.message) {
        showError({
          title: 'Error setting new password!',
          text: err.response.data.message,
        });
      } else {
        showError({ title: 'Error setting new password!' });
      }
      console.error(err);
    }
    setIsLoginInProgress(false);
  };

  return (
    <Box margin={1}>
      {isSignatureRequired && (
        <Container maxWidth="md" style={{ marginBottom: 12 }}>
          <InputSignature
            signatureBase64={signatureBase64}
            onSignatureBase64Update={onSignatureBase64Update}
            isLoading={isLoginInProgress || isLoadingSignatureBase64}
          />
        </Container>
      )}

      <form onSubmit={handleSubmitSetPassword}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography variant="h3" textAlign="center">
              Create a New Password
            </Typography>
          </Grid>
          <Grid item md={6} xs={12}>
            <TextField
              fullWidth
              label="Password"
              name="password"
              onChange={handleChange}
              type="password"
              value={passwordData.password || ''}
              variant="outlined"
              error={hasErrorRequired(passwordData.password, wasSubmitted)}
              helperText={hasErrorRequired(passwordData.password, wasSubmitted) ? isRequired : ''}
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <TextField
              fullWidth
              label="Confirm password"
              name="confirmPassword"
              type="password"
              onChange={handleChange}
              value={passwordData.confirmPassword || ''}
              variant="outlined"
              error={hasErrorRequired(passwordData.confirmPassword, wasSubmitted)}
              helperText={
                hasErrorRequired(passwordData.confirmPassword, wasSubmitted) ? isRequired : ''
              }
            />
          </Grid>
          <Grid item sm={12}>
            <Typography variant="body1">The password must contain:</Typography>
            <ul className="password_validation__list">
              <li className={passwordValidation.upperCase ? 'success' : 'error'}>
                {passwordValidation.upperCase ? <CheckIcon /> : <ErrorIcon />} at least 1 upper case
                letter
              </li>
              <li className={passwordValidation.lowerCase ? 'success' : 'error'}>
                {passwordValidation.lowerCase ? <CheckIcon /> : <ErrorIcon />} at least 1 lower case
                letter
              </li>
              <li className={passwordValidation.number ? 'success' : 'error'}>
                {passwordValidation.number ? <CheckIcon /> : <ErrorIcon />} at least 1 number
              </li>
              <li className={passwordValidation.charLength ? 'success' : 'error'}>
                {passwordValidation.charLength ? <CheckIcon /> : <ErrorIcon />} 8-100 characters in
                length
              </li>
              <li className={passwordValidation.matching ? 'success' : 'error'}>
                {passwordValidation.matching ? <CheckIcon /> : <ErrorIcon />} comfirm matching
                password
              </li>
            </ul>
          </Grid>
          <Grid item xs={12}>
            <Button
              disabled={Object.values(passwordValidation).some(e => !e)}
              color="primary"
              fullWidth
              type="submit"
              variant="contained"
            >
              <Typography variant="h6">Set password</Typography>
              {isLoginInProgress && (
                <Box marginLeft={2}>
                  <CircularProgress color="inherit" size={25} />
                </Box>
              )}
            </Button>
          </Grid>
          <Grid item sm={12}>
            <Typography variant="body1">
              After you set up the password, you will be redirected to the log in page where you
              will have to enter your email and this password.
            </Typography>
          </Grid>
        </Grid>
      </form>
    </Box>
  );
};

export default SetPasswordView;
