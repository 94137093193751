import React from 'react';
import {
  Typography,
  Grid,
  Box,
  Button,
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
} from '@mui/material';
import DateWithArrows from 'sharedComponents/DateWithArrows';
import { RestartAlt, DeleteOutlineOutlined, Download, MoveDown, MoveUp } from '@mui/icons-material';
import { isDriverTkmsOrRichmondUser } from 'modules/auth/logicDependentOnUserRole';
import { useNavigate } from 'react-router-dom';
import { selectAuthUser } from 'modules/auth/storeSliceAuth';
import { useSelector } from 'react-redux';
import { useHasAccess } from 'services/permissions';
import { TUserCompany } from 'lg-helpers/dist/shared/interfaces/IUser';
import './Toolbar.scss';
import { FormTextField } from 'sharedComponents/FormTextField';

type loadsToolBarProps = {
  handleOpenExport: () => void;
  date: string;
  setDate: (val: string) => void;
  refreshTickets: () => void;
  downloadTickets: () => void;
  searchFilter: string;
  setSearchFilter: (search: string) => void;
  handleOpenImportModal: () => void;
  filteredContractorCompanyIds?: string[];
  contractorsOptions?: TUserCompany[];
  setFilteredContractorCompanyIds?: (value: string[]) => void;
};

export const TicketsToolbar = (props: loadsToolBarProps) => {
  const {
    handleOpenExport,
    date,
    setDate,
    refreshTickets,
    downloadTickets,
    searchFilter,
    setSearchFilter,
    handleOpenImportModal,
    filteredContractorCompanyIds,
    contractorsOptions,
    setFilteredContractorCompanyIds,
  } = props;
  const { hasAccess: hasTruckingAllAccess } = useHasAccess([
    'trucking_tickets:all',
    'trucking_projects:all',
  ]);
  const navigate = useNavigate();
  const authUser = useSelector(selectAuthUser);

  const isTKMSCompany = isDriverTkmsOrRichmondUser(authUser);
  const disableDownloads = authUser?.actingAsType === 'Trucking Viewer';

  return (
    <Grid container className="toolbar__tickets" spacing={2} columns={16} mt={0} mb={2}>
      <Grid item xs={16} md="auto" lg="auto">
        <Typography>Display Date</Typography>
        <DateWithArrows setDate={(val: string) => setDate(val)} dateValue={date} />
      </Grid>
      {!!filteredContractorCompanyIds && !!setFilteredContractorCompanyIds && !!contractorsOptions && (
        <Grid item xs={16} md="auto" lg="auto">
          <FormControl fullWidth>
            <Select
              multiple
              required
              value={filteredContractorCompanyIds}
              onChange={e =>
                setFilteredContractorCompanyIds(
                  Array.isArray(e.target.value) ? e.target.value : [e.target.value]
                )
              }
            >
              {contractorsOptions.map(contractor => (
                <MenuItem key={contractor.id} value={contractor.id}>
                  {contractor?.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
      )}
      <Grid item xs={16} md="auto" lg={5} xl={6}>
        <FormTextField
          size="small"
          name="search"
          label="Search"
          value={searchFilter}
          onChange={e => setSearchFilter(e.target.value)}
        />
      </Grid>
      <Grid item xs={16} md={5} className="toolbar__tickets-buttons">
        {isTKMSCompany && hasTruckingAllAccess && (
          <Button
            onClick={() => handleOpenImportModal()}
            variant="contained"
            color="secondary"
            title="Import"
            sx={{ mr: 1 }}
          >
            <MoveDown sx={{ color: 'secondary.main' }} />
            <Typography variant="overline">IMPORT</Typography>
          </Button>
        )}
        <Button
          onClick={() => refreshTickets()}
          variant="contained"
          color="secondary"
          title="Refresh"
          sx={{ mr: 1 }}
        >
          <RestartAlt sx={{ color: 'secondary.main' }} />
          <Typography variant="overline">REFRESH</Typography>
        </Button>
        {hasTruckingAllAccess && (
          <Button
            onClick={() => navigate('/trucking/tickets/deleted')}
            variant="contained"
            color="secondary"
            title="Deleted"
            sx={{ mr: 1 }}
          >
            <DeleteOutlineOutlined sx={{ color: 'secondary.main' }} />
            <Typography variant="overline">DELETED</Typography>
          </Button>
        )}
        {!disableDownloads && (
          <>
            <Button
              onClick={() => downloadTickets()}
              variant="contained"
              color="secondary"
              title="Download"
              sx={{ mr: 1 }}
            >
              <Download sx={{ color: 'secondary.main' }} />
              <Typography variant="overline">DOWNLOAD</Typography>
            </Button>
            <Button onClick={handleOpenExport} variant="contained" title="Export" color="primary">
              <MoveUp sx={{ color: 'secondary.main' }} />
              <Typography variant="overline">Export</Typography>
            </Button>
          </>
        )}
      </Grid>
    </Grid>
  );
};
