import React from 'react';
import { Divisions } from './views/DivisionsView/Divisions';
import { DivisionView } from './views/DivisionView';
import { Routes, Route } from 'react-router-dom';

export const DivisionsViews = () => {
  return (
    <Routes>
      <Route path="/" element={<Divisions />} />
      <Route path="/:divisionId" element={<DivisionView />} />
    </Routes>
  );
};
