import React from 'react';
import PropTypes from 'prop-types';
import {
  Grid,
  TextField,
  FormControlLabel,
  Box,
  ListItem,
  Divider,
  IconButton,
  Switch,
} from '@mui/material';
import DeletePictogram from '@mui/icons-material/Delete';

const ProfileType = ({ profileType, onUpdatedProfileType, onDeleteProfileType }) => {
  if (!profileType) {
    return null;
  }

  const handleDisplayChange = event => {
    const display = event.target.value;
    if (profileType.sync) {
      onUpdatedProfileType({
        ...profileType,
        display,
        value: display,
      });
    } else {
      onUpdatedProfileType({
        ...profileType,
        display,
      });
    }
  };

  const handleChangeSync = event => {
    const sync = event.target.checked;
    onUpdatedProfileType({
      ...profileType,
      sync,
    });
  };

  const handleValueChange = event => {
    const value = event.target.value;
    if (profileType.sync) {
      onUpdatedProfileType({
        ...profileType,
        value,
        display: value,
      });
    } else {
      onUpdatedProfileType({
        ...profileType,
        value,
      });
    }
  };

  return (
    <>
      <ListItem alignItems="flex-start" padding={15}>
        <Grid container spacing={3}>
          <Grid item sm={5} xs={12}>
            <Box display="flex" justifyContent="center" alignItems="center">
              <TextField
                fullWidth
                label="Display"
                variant="outlined"
                value={profileType.display || ''}
                onChange={handleDisplayChange}
              />
            </Box>
          </Grid>
          <Grid item sm={1} xs={12}>
            <Box display="flex" justifyContent="center" alignItems="center">
              <FormControlLabel
                control={
                  <Switch
                    checked={profileType.sync || false}
                    onChange={handleChangeSync}
                    color="primary"
                  />
                }
                label="Sync"
                labelPlacement="bottom"
              />
            </Box>
          </Grid>
          <Grid item sm={5} xs={12}>
            <Box display="flex" justifyContent="center" alignItems="center">
              <TextField
                fullWidth
                label="Value"
                variant="outlined"
                value={profileType.value || ''}
                onChange={handleValueChange}
              />
            </Box>
          </Grid>
          <Grid item sm={1} xs={12}>
            <Box display="flex" justifyContent="center" alignItems="center">
              <IconButton
                edge="end"
                aria-label="delete"
                onClick={() => onDeleteProfileType(profileType.id)}
              >
                <DeletePictogram />
              </IconButton>
            </Box>
          </Grid>
        </Grid>
      </ListItem>
      <Divider />
    </>
  );
};

ProfileType.propTypes = {
  profileType: PropTypes.object.isRequired,
  onUpdatedProfileType: PropTypes.func.isRequired,
  onDeleteProfileType: PropTypes.func.isRequired,
};

export default ProfileType;
