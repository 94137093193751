import { FormControl, FormHelperText, TextField } from '@mui/material';
import React from 'react';

type FormFieldProps = {
  name?: string;
  label?: string;
  helperField?: string;
  value?: string | number | Date | null;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  selectOptions?: JSX.Element[] | null;
  size?: 'small' | 'medium';
  disabled?: boolean;
  required?: boolean;
  placeholder?: string;
  type?: string;
};

export const FormTextField = ({
  name,
  value,
  helperField,
  label,
  onChange,
  selectOptions,
  size = 'small',
  required = false,
  ...params
}: FormFieldProps) => {
  return (
    <FormControl fullWidth>
      <TextField
        label={label}
        select={!!selectOptions}
        size={size}
        name={name}
        value={value || ''}
        error={!!helperField}
        helperText={helperField}
        onChange={onChange}
        required={required}
        {...params}
      >
        {!!selectOptions && selectOptions}
      </TextField>
      <FormHelperText />
    </FormControl>
  );
};
