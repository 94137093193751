import React, { useContext, useEffect } from 'react';
import { Drawer, List, ListItem } from '@mui/material';
import { NavItem } from './NavItem';
import { usePermissionsContext } from 'services/permissions';
import { MenuContext } from './menuContext';
import './SideBar.scss';
export interface IMenuItem {
  href: string;
  title: string;
  Pictogram: React.ReactNode;
  computeVisibility?: (user: string) => boolean;
}

export interface SideBarProps {
  navItems: IMenuItem[];
  drawerOpenedByDefault: boolean;
}

export const SideBar = ({ navItems, drawerOpenedByDefault }: SideBarProps) => {
  const { user } = usePermissionsContext();
  const { showMenu, setShowMenu } = useContext(MenuContext);

  useEffect(() => {
    setShowMenu(drawerOpenedByDefault);
    // eslint-disable-next-line
  }, [drawerOpenedByDefault]);

  return (
    <>
      <Drawer
        anchor="left"
        sx={{
          position: 'absolute',
          display: 'inline-block',
          top: 'inherit',
          width: 72,
          marginTop: 56,
          border: 'none',
        }}
        open={showMenu}
        variant="persistent"
        elevation={0}
        className="sidebar"
      >
        <List
          className="Civil__sidebar"
          component="nav"
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            py: 1.5,
            px: '5px',
            mt: 7.5,
            '&& .Mui-selected, && .Mui-selected:hover': {
              '&, & .MuiListItemIcon-root': {
                background:
                  'linear-gradient(159deg, #90B355 12.24%, rgba(85, 145, 127, 0.75) 93.98%), #90B355',
                color: 'white',
              },
            },
          }}
        >
          {navItems.map(
            item =>
              (!!item.computeVisibility ? item.computeVisibility(user.actingAsType) : true) && (
                <NavItem
                  key={item.title}
                  href={item.href}
                  title={item.title}
                  Pictogram={item.Pictogram}
                />
              )
          )}
        </List>
        {process.env.REACT_APP_BUILD_ENV !== 'production' && (
          <List>
            <ListItem disableGutters className={`NavItem__wrapper`}>
              <b>Environment:</b>
            </ListItem>
            <ListItem disableGutters className={`NavItem__wrapper`}>
              {process.env.REACT_APP_BUILD_ENV}
            </ListItem>
          </List>
        )}
      </Drawer>
    </>
  );
};
