import React, { useEffect, useState } from 'react';
import { MemberItem } from './MemberItem';
import { Box, TextField, SvgIcon } from '@mui/material';
import { useSelector } from 'react-redux';
import { Loading } from 'sharedComponents/Loading';
import { RootState, thunkDispatch } from 'store/store';
import { IUser } from 'lg-helpers/dist/shared/interfaces/IUser';
import { Search } from 'modules/civilAndTrucking/shared/CustomIcons/Search';
import Fuse from 'fuse.js';
import * as R from 'ramda';
import { removeUser } from '../../../civil/Users/redux/actions';
import LoadingComponent from '../../../../../sharedComponents/LoadingComponent';

type MembersListProps = {
  showEditUserModal: (user: IUser) => void;
};

export const MembersList = ({ showEditUserModal }: MembersListProps) => {
  const [loading, setLoading] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [filteredUsers, setFilteredUsers] = useState<IUser[]>();
  const users = useSelector((state: RootState) => state.civil.users.data);
  const usersStatus = useSelector((state: RootState) => state.civil.users.status);

  useEffect(() => {
    if (users) {
      if (!searchValue) {
        setFilteredUsers(users);
        return;
      }

      const options = {
        includeScore: true,
        threshold: 0.5,
        keys: [{ name: 'name', weight: 3 }, 'email', 'type'],
      };

      const fuse = new Fuse(users, options);
      const fuseResults = fuse.search(searchValue).map(result => result);
      const sortedByScore = R.sortBy(R.prop('score'));
      const sortedResults = R.pluck('item')(R.reverse(sortedByScore(fuseResults)));

      setFilteredUsers(sortedResults);
    }
  }, [users, searchValue]);

  const handleSearchChange: React.ChangeEventHandler = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setSearchValue(event.target.value);
  };

  const handleDelete = async (member: IUser) => {
    setLoading(true);
    try {
      await thunkDispatch(removeUser(member?.id));
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  if (usersStatus === 'pending') {
    return <Loading />;
  }

  return loading ? (
    <LoadingComponent isLoading={loading} />
  ) : (
    <>
      <Box>
        <TextField
          sx={{ margin: 0 }}
          name="activityName"
          variant="outlined"
          value={searchValue}
          fullWidth
          placeholder="Search for members by name or email"
          onChange={handleSearchChange}
          InputProps={{
            startAdornment: (
              <SvgIcon sx={{ mx: 1, color: 'neutral.main' }} fontSize="small">
                <Search />
              </SvgIcon>
            ),
          }}
        />
      </Box>

      {filteredUsers?.map(user => (
        <MemberItem
          key={user.id}
          user={user}
          showEditUserModal={showEditUserModal}
          handleDelete={handleDelete}
        />
      ))}
    </>
  );
};
