import React, { useCallback, useEffect, useRef, useState } from 'react';
import './VerifyManifestView.scss';
import { useNavigate } from 'react-router-dom';
import { Box, Button, Container, Grid, TextField, Typography } from '@mui/material';
import Page from 'sharedComponents/Page';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectScale,
  selectSelectedManifest,
  setSelectedManifest,
} from 'modules/scales/storeSliceScales';
import BorderColorIcon from '@mui/icons-material/BorderColor';

import ManifestDisplayWithTemplateDataGathering from 'sharedComponents/ManifestDisplayWithTemplateDataGathering';

import { selectAuthUser } from 'modules/auth/storeSliceAuth';
import Query from 'services/firebase/Query';
import Executer from 'services/firebase/Executer';
import Command from 'services/firebase/Command';
import useShowError from 'modules/errors';
import CustomInputsRenderer from 'sharedComponents/CustomInputsRenderer';
import InputSignature from 'sharedComponents/InputSignature';
import QueryBase from 'lg-helpers/dist/firestore/query/QueryBase';

const executer = new Executer();
const query = new Query();
const command = new Command();

interface IDriverSignature {
  firstName: string;
  lastName: string;
  signature: string;
}

const DriverSignManifest = () => {
  const selectedManifest = useSelector(selectSelectedManifest);
  const scale = useSelector(selectScale);
  const [wasSignPressed, setWasSignPressed] = useState(false);
  const showError = useShowError();
  const authUser = useSelector(selectAuthUser);
  const navigate = useNavigate();
  const bottomRef = useRef<any>();
  const [isValidSectionCustomInputs, setIsValidSectionCustomInputs] = useState(false);
  const [signatureBase64, setSignatureBase64] = useState('');
  const [user, setUser] = useState<IDriverSignature>({} as IDriverSignature);
  const thisDispatch = useDispatch();

  const goToLanesPage = useCallback(() => {
    navigate('/scales/lanes');
  }, [navigate]);

  useEffect(() => {
    const getManifest = async (id: string) => {
      const manifest = await executer.getSingleDocument(
        query.base.getById(QueryBase.MANIFESTS_COLLECTION(), id)
      );
      thisDispatch(setSelectedManifest(manifest));
    };
    if (selectedManifest) getManifest(selectedManifest.id);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (!selectedManifest || !scale || selectedManifest.signatureDriver.isSigned) {
      // if manifest isSigned will happen when going back from verify manifest section
      goToLanesPage();
    }
  }, [selectedManifest, scale, goToLanesPage]);

  const CustomInputsRendererComponent = React.useMemo(
    () => (
      <CustomInputsRenderer
        section="Assign Manifest to Driver"
        manifest={selectedManifest}
        setIsValid={setIsValidSectionCustomInputs}
      />
    ),
    [selectedManifest]
  );

  const handleChange = useCallback((event: any) => {
    const { name, value } = event.target;
    setUser(prev => ({
      ...prev,
      [name]: value,
    }));
    // eslint-disable-next-line
  }, []);

  const processSignAndComplete = useCallback(async () => {
    setWasSignPressed(true);
    try {
      let driverSignatureUrl = null;
      const errorMessage: string[] = [];
      if (!isValidSectionCustomInputs) {
        setWasSignPressed(false);
        errorMessage.push('Save Custom Inputs');
      }
      if (signatureBase64) {
        driverSignatureUrl = await command.uploadUserSignatureAndGetUrl(
          authUser.id,
          signatureBase64
        );
      } else {
        setWasSignPressed(false);
        errorMessage.push('Missing Signature Required');
      }
      if (errorMessage.length)
        return showError({
          title: 'Validation error',
          text: `
            ${errorMessage.join(`, `)}
          `,
        });

      await command.manifests.signAssignManifestToDriver(
        selectedManifest.id,
        driverSignatureUrl,
        {
          ...selectedManifest.signatureDriver,
          driverFirstName: user?.firstName,
          driverLastName: user?.lastName,
          truckingCompany: {
            name: selectedManifest.signatureDriver.truckingCompany,
            id: selectedManifest.signatureDriver.truckingCompanyId,
          },
        },
        selectedManifest.signatureDriver.scale
      );
      setWasSignPressed(false);

      navigate('/scales/verify-manifest');
    } catch (err: any) {
      setWasSignPressed(false);
      showError({ title: 'Error Signing Manifest for Driver', text: `${err}` || '' });
    }
  }, [
    signatureBase64,
    selectedManifest,
    authUser.id,
    showError,
    isValidSectionCustomInputs,
    navigate,
    user,
    thisDispatch,
  ]);

  return (
    <Page className="VerifyManifestView" title="Verify manifest">
      <Container maxWidth={false}>
        <Grid className="top-bar__buttons" container spacing={2} alignItems="center">
          <Grid item md={4} xs={12}>
            <Box display="flex" justifyContent="flex-end">
              <Button
                size="large"
                variant="outlined"
                onClick={() => {
                  goToLanesPage();
                }}
                fullWidth
              >
                Cancel
              </Button>
            </Box>
          </Grid>
          <Grid item className="sign-button__top" md={4} xs={12}>
            <Box display="flex" justifyContent="flex-end">
              <Button
                size="large"
                variant="contained"
                fullWidth
                disabled={wasSignPressed}
                onClick={processSignAndComplete}
              >
                <Box display="flex" alignItems="center">
                  <Box display="flex" alignItems="center" marginRight={1}>
                    <BorderColorIcon fontSize="small" />
                  </Box>
                  Sign &amp; Continue
                </Box>
              </Button>
            </Box>
          </Grid>
          <Grid item md={4} xs={12}>
            <Box marginTop={1}>
              <Typography textAlign="center" variant="h4">
                Driver Signature
              </Typography>
            </Box>
          </Grid>
        </Grid>
        <Grid container>
          {selectedManifest && (
            <Grid item alignSelf="center" margin="0 auto" width="100%" maxWidth={1200} xs={12}>
              <ManifestDisplayWithTemplateDataGathering
                hideReceipt={true}
                manifestId={selectedManifest.id}
              />
            </Grid>
          )}
          <Grid item xs={12} marginTop={2}>
            {CustomInputsRendererComponent}
          </Grid>

          <Grid item xs={12}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <Box marginTop={1}>
                  <TextField
                    fullWidth
                    label="First name"
                    name="firstName"
                    onChange={handleChange}
                    value={user?.firstName || ''}
                    variant="outlined"
                  />
                </Box>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Box marginTop={1}>
                  <TextField
                    fullWidth
                    label="Last name"
                    name="lastName"
                    onChange={handleChange}
                    value={user?.lastName || ''}
                    variant="outlined"
                  />
                </Box>
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12} marginTop={2}>
            <InputSignature
              signatureBase64={signatureBase64}
              onSignatureBase64Update={setSignatureBase64}
            />
          </Grid>
        </Grid>
        <Grid item sm={12} xs={12}>
          <Box display="flex" justifyContent="flex-end" marginTop={2} marginBottom={3}>
            <Button
              color="primary"
              variant="contained"
              size="large"
              fullWidth
              onClick={processSignAndComplete}
              disabled={wasSignPressed}
            >
              <Box display="flex" alignItems="center">
                <Box display="flex" alignItems="center" marginRight={1}>
                  <BorderColorIcon fontSize="small" />
                </Box>
                <Typography variant="h4">Sign &amp; Continue</Typography>
              </Box>
            </Button>
          </Box>
        </Grid>
        <Box ref={bottomRef} />
      </Container>
    </Page>
  );
};

export default DriverSignManifest;
function dispatch(arg0: any) {
  throw new Error('Function not implemented.');
}
