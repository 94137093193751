import React from 'react';
import { DispatchesView } from './views/DispatchesView';
import { DispatchView } from './views/DispatchView';
import { DispatchMapView } from './views/DispatchMap';
import { Routes, Route } from 'react-router-dom';

export const DispatchesViews = () => {
  return (
    <Routes>
      <Route path="/" element={<DispatchesView />} />
      <Route path="/:dispatchId" element={<DispatchView />} />
      <Route path="/:dispatchId/map" element={<DispatchMapView />} />
    </Routes>
  );
};
