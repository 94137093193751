import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Tooltip } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { selectAuthUser } from 'modules/auth/storeSliceAuth';
import { useSelector } from 'react-redux';
import 'moment-timezone';
import Query from 'services/firebase/Query';
import Executer from 'services/firebase/Executer';
import useShowError from 'modules/errors';
import ButtonDownloadNew from './ButtonDownloadNew';
import { PdfDocumentType } from 'services/api/autogenerated';

const query = new Query();
const executer = new Executer();

const formatDate = timestamp => {
  const date = new Date(timestamp.seconds * 1000);
  return `${date.toLocaleDateString()} ${date.toLocaleTimeString()}`;
};

const columns = [
  {
    field: 'documentRequest',
    headerName: 'Download Request',
    flex: 1,
    valueGetter: ({ row }) => {
      return `${(row.document_ids || []).length} ${row.document_type} - ${formatDate(
        row.creation_time
      )}`;
    },
  },
  {
    field: 'dateRange',
    headerName: 'Date Range',
    flex: 1,
    valueGetter: ({ row }) => {
      return row.parameters.dateRange
        ? `${row.parameters.dateRange.startDate} - ${row.parameters.dateRange.endDate}`
        : '';
    },
  },
  {
    field: 'expiresAt',
    headerName: 'Expires On',
    flex: 1,
    valueGetter: ({ row }) => {
      return row.expires_at ? formatDate(row.expires_at) : '';
    },
  },
  {
    field: 'downloadZip',
    headerName: 'Download ZIP',
    width: 125,
    renderCell: cellValues => {
      return (
        <div style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
          {cellValues.row.latest_error && !cellValues.row.zip_file_url && (
            <Tooltip title={cellValues.row.latest_error}>
              <span>
                <Button disabled>Error</Button>
              </span>
            </Tooltip>
          )}
          <ButtonDownloadNew
            isLoading={!cellValues.row.zip_file_url}
            url={cellValues.row.zip_file_url}
            numberExpected={(cellValues.row.document_ids || []).length}
            numberGenerated={cellValues.row.number_generated}
          />
        </div>
      );
    },
  },
];

const DownloadsNew = ({ projectId }) => {
  const authUser = useSelector(selectAuthUser);
  const [downloads, setDownloads] = useState([]);
  const showError = useShowError();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (!authUser || !projectId) {
      return undefined;
    }

    const unsubscribe = executer.watchMultipleDocuments(
      query.users.getUserDownloadsNewByTypeAndProject(
        authUser,
        PdfDocumentType.Manifest,
        projectId
      ),
      firestoreDownloads => {
        setIsLoading(false);

        // TODO: Filter the downloads based on the expires_at property (in firebase query)
        const filteredDownloads = firestoreDownloads.filter(download => {
          const twoDayInMilliseconds = 24 * 2 * 60 * 60 * 1000;
          const adjustedCreatedDate = new Date(
            download.creation_time.toDate().getTime() + twoDayInMilliseconds
          );

          const expiresAt = download.expires_at?.toDate();

          return (
            (expiresAt === null || expiresAt === undefined || expiresAt > new Date()) &&
            adjustedCreatedDate > new Date()
          );
        });

        setDownloads(filteredDownloads);
      },
      err => {
        console.error(err);
        showError({ title: 'Error loading downloads', duration: 10000 });
      }
    );

    return unsubscribe;
  }, [authUser, projectId]);

  return (
    <div style={{ height: 600, width: '100%' }}>
      <DataGrid rows={downloads} columns={columns} pageSize={10} loading={isLoading} />
    </div>
  );
};

DownloadsNew.propTypes = {
  projectId: PropTypes.string,
};

export default DownloadsNew;
