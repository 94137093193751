import { createSlice } from '@reduxjs/toolkit';
import { importTKMSDataFromFTP, preProcessImportTKMSData } from './actions';
import { RootState } from 'store/store';
import { TFormStatus } from 'lg-helpers/dist/shared/types/TFormStatus';

export type PreprocessImport = {
  fileName: string;
  fileDescription: string;
  rowsToImport: Number;
  firstRow: [];
};

export type PreprocessImportData = PreprocessImport[];
export interface IDataImportState {
  preprocessImportedData: PreprocessImportData | null;
  preprocessImportedDataStatus: TFormStatus;
  importedTKMSDataFromFTP: null;
  importedTKMSDataFromFTPStatus: TFormStatus;
}

const initialDataImportState: IDataImportState = {
  preprocessImportedData: null,
  preprocessImportedDataStatus: null,
  importedTKMSDataFromFTP: null,
  importedTKMSDataFromFTPStatus: null,
};

export const dataImportStoreSlice = createSlice({
  name: 'dataImport',
  reducers: {},
  initialState: initialDataImportState,
  extraReducers: builder => {
    builder
      .addCase(importTKMSDataFromFTP.pending, state => {
        state.importedTKMSDataFromFTPStatus = 'pending';
      })
      .addCase(importTKMSDataFromFTP.fulfilled, (state, { payload }) => {
        state.importedTKMSDataFromFTPStatus = 'success';
        state.importedTKMSDataFromFTP = payload;
      })
      .addCase(importTKMSDataFromFTP.rejected, (state, { error }) => {
        state.importedTKMSDataFromFTPStatus = 'failure';
        state.importedTKMSDataFromFTP = null;
        console.error('importTKMSDataFromFTP error: ', error);
      });
    builder
      .addCase(preProcessImportTKMSData.pending, state => {
        state.preprocessImportedDataStatus = 'pending';
      })
      .addCase(preProcessImportTKMSData.fulfilled, (state, { payload }) => {
        state.preprocessImportedDataStatus = 'success';
        state.preprocessImportedData = payload;
      })
      .addCase(preProcessImportTKMSData.rejected, (state, { error }) => {
        state.preprocessImportedDataStatus = 'failure';
        state.preprocessImportedData = null;
        console.error('preProcessImportTKMSData error: ', error);
      });
  },
});

export const selectImportedTKMSDataFromFTP = (state: RootState) => {
  return {
    importedTKMSDataFromFTP: state.trucking.dataImport.importedTKMSDataFromFTP,
    importedTKMSDataFromFTPStatus: state.trucking.dataImport.importedTKMSDataFromFTPStatus,
  };
};

export const selectPreprocessImportedData = (state: RootState) => {
  return {
    preprocessImportedData: state.trucking.dataImport.preprocessImportedData,
    preprocessImportedDataStatus: state.trucking.dataImport.preprocessImportedDataStatus,
  };
};
