import React, { useEffect, useState } from 'react';
import {
  Grid,
  TextField,
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Divider,
  Box,
  Button,
  CircularProgress,
} from '@mui/material';
import { Autocomplete } from '@mui/material';
import { unitsOfMeasure } from 'lg-helpers/dist/constants/global-constants';
import { useSelector } from 'react-redux';
import { selectAuthUser } from 'modules/auth/storeSliceAuth';
import Executer from 'services/firebase/Executer';
import Query from 'services/firebase/Query';
import QueryBase from 'lg-helpers/dist/firestore/query/QueryBase';
import Command from '../services/firebase/Command';
const executer = new Executer();
const command = new Command();
const query = new Query();

interface ManifestProject {
  truckingCompanyId: string;
  truckingCompany: string;
  transporterCompanies: Array<any>;
  scales: Array<any>;
}

interface ManifestInput {
  id: string;
  name: string;
  value?: any;
  options?: Array<any>;
  isRequired?: boolean;
}

interface ManifestsDetailsViewProps {
  manifest: any;
  getLabel(item: any): string;
}

interface ChangeManifestDetails {
  id: string;
  e: React.ChangeEvent<any>;
  value?: any;
}

type HandleChangeManifestDetails = {
  (e: ChangeManifestDetails): any;
};

const ManifestDetailsInputs = ({
  inputs,
  manifestDetails,
  handleChangeManifestDetails,
}: {
  inputs: Array<any>;
  manifestDetails: any;
  handleChangeManifestDetails: HandleChangeManifestDetails;
}) => (
  <Grid container spacing={2}>
    {inputs &&
      inputs.map(({ id, value, options, name, isRequired }: ManifestInput) => (
        <Grid item sm={6} xs={12} key={id}>
          {!options ? (
            <TextField
              fullWidth
              label={name}
              value={value || manifestDetails[id] || ''}
              variant="outlined"
              onChange={(e: any) => handleChangeManifestDetails({ id, e })}
              required={isRequired}
            />
          ) : (
            <Autocomplete
              fullWidth
              options={options}
              value={value?.id ? value : manifestDetails[id] || ''}
              onChange={(e: any, v: any) =>
                handleChangeManifestDetails({
                  id,
                  e,
                  value: v,
                })
              }
              isOptionEqualToValue={(option: any, v: any) =>
                !v ? false : option?.id === v?.id || option === v
              }
              renderInput={(params: any) => (
                <TextField
                  fullWidth
                  {...params}
                  label={name}
                  required={isRequired}
                  variant="outlined"
                />
              )}
            />
          )}
        </Grid>
      ))}
  </Grid>
);

const ManifestsDetailsView = ({ manifest }: ManifestsDetailsViewProps) => {
  const [manifestDetails, setManifestDetails] = useState<any | null>(manifest?.signatureDriver);
  const authUser = useSelector(selectAuthUser);
  const [isLoadingManifestDetails, setIsLoadingManifestDetails] = useState(false);
  const [project, setProject] = useState({} as ManifestProject);

  const saveCustomInputs = async () => {
    setIsLoadingManifestDetails(true);
    try {
      await command.manifests.updateManifestSignatureDriver(manifest.id, manifestDetails);
    } catch (err) {
      console.error('Error updating Manifest Details: ', err);
    }

    setIsLoadingManifestDetails(false);

    const action = 'MANIFEST_DETAILS_ADJUST';

    try {
      await command.manifests.addManifestChangeLog(manifest.id, {
        userId: authUser.id,
        action,
        userName: [authUser?.firstName, authUser?.lastName].join(' '),
        isNative: false,
        isV1ApiCivilAndTrucking: false,
        platform: '',
        osVersion: '',
      });
    } catch (err) {
      console.error('ChangeLog Update Error: ', err);
    }
  };

  useEffect(() => {
    setIsLoadingManifestDetails(true);
    return executer.watchSingleDocument(
      query.base.getById(QueryBase.PROJECTS_COLLECTION(), manifest?.project?.id),
      proj => {
        if (proj) {
          setProject(proj as ManifestProject);
        }
        setIsLoadingManifestDetails(false);
      },
      error => {
        console.error('Error getting document:', error);
        setIsLoadingManifestDetails(false);
      }
    );
    // eslint-disable-next-line
  }, [manifest]);

  const inputDefinition = [
    {
      id: 'driverName',
      name: 'Driver Name',
    },
    {
      id: 'truckNumber',
      name: 'Truck Number',
    },
    {
      id: 'scale',
      name: 'Approved Landfills',
      value: {
        id: manifestDetails?.scale?.id,
        label: manifestDetails?.scale?.name,
      },
      options:
        project?.scales?.map((company: any) => ({
          id: company?.id || '',
          label: company?.name || '',
        })) || [],
    },
    {
      id: 'truckingCompany',
      name: 'Truck Company',
      value: {
        id: project?.truckingCompanyId,
        label: project?.truckingCompany,
      },
      options:
        project?.transporterCompanies?.map((company: any) => ({
          id: company?.id || '',
          label: company?.name || '',
        })) || [],
    },
    {
      id: 'secondaryTruckingCompany',
      name: 'Broker',
    },
    {
      id: 'truckCapacity',
      name: 'Truck Capacity',
    },
    {
      id: 'truckCapacityUnit',
      name: 'Capacity Units',
      options: unitsOfMeasure,
    },
  ];

  const handleChangeManifestDetails = ({ id, e, value }: ChangeManifestDetails) => {
    const updatedManifestDetails = {
      [id]:
        ['scale', 'truckingCompany'].includes(id) && value?.id
          ? id === 'scale'
            ? project?.scales.find((scale: any) => scale.id === value.id)
            : project?.transporterCompanies.find((transporter: any) => transporter.id === value.id)
                ?.name
          : value || e.target.value || null,
    };

    if (id === 'truckingCompany') {
      updatedManifestDetails.truckingCompanyId = value.id;
    }

    return (
      !(typeof value !== 'undefined' && !value) &&
      setManifestDetails({
        ...manifestDetails,
        ...updatedManifestDetails,
      })
    );
  };

  return (
    <>
      <Card>
        <CardHeader
          title="Manifest Details"
          action={
            <Box display="flex">
              <Button color="primary" variant="contained" onClick={saveCustomInputs}>
                Save Changes
                {isLoadingManifestDetails && (
                  <Box marginLeft={2}>
                    <CircularProgress color="inherit" />
                  </Box>
                )}
              </Button>
            </Box>
          }
        />
        <Divider />
        <CardContent>
          <ManifestDetailsInputs
            inputs={inputDefinition}
            manifestDetails={manifestDetails}
            handleChangeManifestDetails={handleChangeManifestDetails}
          />
        </CardContent>
        <CardActions>
          <Button onClick={() => setManifestDetails(manifest?.signatureDriver)}>Reset</Button>
        </CardActions>
      </Card>
    </>
  );
};

export default ManifestsDetailsView;
