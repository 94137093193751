import React from 'react';
import { Button, Box, CircularProgress } from '@mui/material';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';

const ButtonDownload = ({ isLoading, url }: { isLoading?: boolean; url: string }) => {
  const onDownloadClick = async () => {
    window.open(url, '_blank');
  };

  return (
    <Button
      disabled={isLoading}
      onClick={onDownloadClick}
      size="small"
      fullWidth={false}
      variant="outlined"
    >
      <Box display="flex" alignItems="center" justifyContent="center">
        {isLoading ? (
          <CircularProgress color="inherit" size="20px" />
        ) : (
          <CloudDownloadIcon color="inherit" fontSize="medium" />
        )}
      </Box>
    </Button>
  );
};

export default ButtonDownload;
