import React, { useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import useShowError from 'modules/errors';
import moment from 'moment';
import 'moment-timezone';
import { useSwitchUserRole } from 'services/permissions';
import { useHasAccess, useHasPerms } from 'services/permissions';
import { Box, Button, Grid } from '@mui/material';
import { WebStories } from '@mui/icons-material';
import { Container } from '@mui/material';
import Page from 'sharedComponents/Page';
import { generateSignatureColumn } from 'utils/manifestUtil';
import { componentAllowedPerms } from 'services/permissions';

import Query from 'services/firebase/Query';
import Executer from 'services/firebase/Executer';
import CompaniesApi from 'services/postgres/CompaniesApi';
import IBatchManifests from 'lg-helpers/dist/shared/interfaces/IBatchManifests';
import { DataGrid, GridColDef, GridValueGetterParams } from '@mui/x-data-grid';
import { DateRangePicker } from 'rsuite';

const query = new Query();
const executer = new Executer();
const companiesApi = new CompaniesApi();

const dateFormat = 'MM/DD/YYYY hh:mm:ss A';
const filterDateFormat = 'MM/DD/YYYY';

const columns: GridColDef[] = [
  {
    headerName: 'Batch #',
    field: 'number',
    sortable: true,
  },
  {
    headerName: '# of Manifests',
    field: 'count',
    sortable: true,
    flex: 1,
  },
  {
    headerName: 'Trailer #',
    sortable: true,
    flex: 1,
    field: 'trailerNumber',
  },
  {
    field: 'signatureGenerator.isSigned',
    headerName: 'Generator',
    sortable: false,
    filterable: false,
    renderCell: params =>
      generateSignatureColumn(
        params.row.count > 0 && params.row.countGeneratorSigned === params.row.count
      ),
  },
  {
    field: 'signatureDriver.isSigned',
    headerName: 'Driver',
    // isVerticalHeaderText: true,
    renderCell: params =>
      generateSignatureColumn(
        params.row.count > 0 && params.row.countDriverSigned === params.row.count
      ),
  },
  {
    field: 'signatureScale.isSigned',
    headerName: 'Scale',
    // isVerticalHeaderText: true,
    renderCell: params =>
      generateSignatureColumn(
        params.row.count > 0 && params.row.countScaleSigned === params.row.count
      ),
  },
  // {
  //   label: 'Carrier',
  //   getCellValueCallback: (row: IBatchManifests) =>
  //     row.signatureDriver?.secondaryTruckingCompany?.trim()
  //       ? row.signatureDriver?.secondaryTruckingCompany
  //       : row.signatureDriver?.truckingCompany,
  // },
  // {
  //   label: 'Transporter',
  //   getCellValueCallback: (row: IBatchManifests) => String(row.signatureDriver?.truckingCompany),
  // },
  {
    field: 'lastUpdatedAt',
    headerName: 'Last used/signed',
    sortable: true,
    flex: 1,
    valueGetter: (params: GridValueGetterParams) => {
      let lastUpdatedAt = params.row.lastUpdatedAt
        ? (params.row.lastUpdatedAt as any).toDate()
        : null;
      const momentDate = moment.tz(lastUpdatedAt, 'EST');
      return `${momentDate.format(dateFormat)} ${momentDate.zoneAbbr()}`;
    },
  },
  // {
  //   label: 'Map',
  //   hasCustomClickHandler: true,
  //   getCellValueCallback: (row: IBatchManifests) => (
  //     <ButtonLink
  //       url={`/manifests/${row.id}/map`}
  //       data={row}
  //       Icon={<LocationOnIcon fontSize="small" />}
  //     />
  //   ),
  // },
];

export default componentAllowedPerms(['batched_manifests:read'], () => <p>Access refused</p>)(
  function BatchManifestsListView() {
    const [batches, setBatches] = useState<IBatchManifests[]>([]);
    const [pageSize, setPageSize] = useState(25);
    const [isLoading, setIsLoading] = useState(true);
    const showError = useShowError();
    const navigate = useNavigate();

    const defaultFilterDateRange = useMemo((): any => {
      const startDate = moment.tz('EST').startOf('week').toDate();
      const endDate = moment.tz('EST').endOf('week').toDate();
      return [startDate, endDate];
    }, []);

    const { afterToday } = DateRangePicker;

    const [filters, setFilters] = useState({
      filterDateRange: defaultFilterDateRange,
    } as any);

    const creatorQuery = (u: any) => {
      return query.batchManifests.getAllBatchesForCreator(
        u.companiesIds[0],
        filters.filterDateRange[0],
        filters.filterDateRange[1]
      ); // TODO: fix type
    };

    const { result: batchesQuery } = useSwitchUserRole({
      generator_admin: creatorQuery,
      generator_user: creatorQuery,
      contractor_admin: creatorQuery,
      contractor_user: creatorQuery,
      transporter_admin: u =>
        query.batchManifests.getAllBatchesForTransporter(
          u.companiesIds[0],
          filters.filterDateRange[0],
          filters.filterDateRange[1]
        ),
      default: () =>
        query.batchManifests.getAllBatches(filters.filterDateRange[0], filters.filterDateRange[1]),
    });

    const allowedCreateBatch =
      useHasPerms(['batched_manifests:create']) &&
      useHasAccess([
        async user => {
          if (user.role === 'super_admin' || user.role === 'customer_admin') return true;
          const companyRes = await companiesApi.getById(user.companies[0].id || '');
          return companyRes?.allowMultipleManifests;
        },
      ])?.hasAccess;

    useEffect(() => {
      setIsLoading(true);
      return executer.watchMultipleDocuments(
        batchesQuery!,
        data => {
          setBatches(data as IBatchManifests[]);
          setIsLoading(false);
        },
        err => {
          console.error(err);
          showError({
            title: 'Failed to load batches',
            duration: 10000,
          });
          setIsLoading(false);
        }
      );
      // eslint-disable-next-line
    }, [filters]);

    const onDateRangeUsedChange = (newDateRange: any) => {
      if (newDateRange.length) {
        setFilters({
          filterDateRange: [newDateRange[0], moment(newDateRange[1]).add(1, 'days').toDate()],
        });
      } else {
        // TODO: is this appropriate? Should we allow wide open filtering?
        setFilters({ filterDateRange: defaultFilterDateRange });
      }
    };

    return (
      <Page className="min-padding-and-height" title="Incoming Manifests">
        <Grid container>
          {allowedCreateBatch ? (
            <Grid item md={3} sm={6} xs={12}>
              <Button
                size="large"
                variant="outlined"
                color="primary"
                onClick={() => navigate('/batched-manifests/new')}
              >
                New Batch &nbsp; <WebStories />
              </Button>
            </Grid>
          ) : (
            <Grid item md={3} sm={6} xs={12}></Grid>
          )}
          <Grid item md={8} sm={8} xs={12} container justifyContent="flex-end">
            {/* <Box display="flex" alignItems="center" marginBottom={1}> */}
            <DateRangePicker
              appearance="default"
              placeholder="Show used between"
              placement="bottomEnd"
              size="lg"
              format={filterDateFormat}
              onChange={onDateRangeUsedChange}
              style={{ width: 260 }}
              defaultValue={filters.filterDateRange || []}
              disabled={isLoading}
              disabledDate={afterToday()}
              cleanable={false}
            />
            {/* </Box> */}
          </Grid>
        </Grid>
        <Container maxWidth={false}>
          <Box margin={1}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <DataGrid
                  style={{ height: 1000 }}
                  rows={batches}
                  loading={isLoading}
                  columns={columns}
                  pageSize={pageSize}
                  onPageSizeChange={newPageSize => setPageSize(newPageSize)}
                  onRowClick={batch => navigate(`/batched-manifests/${batch.id}`)}
                ></DataGrid>
              </Grid>
            </Grid>
          </Box>
        </Container>
      </Page>
    );
  }
);
