import React from 'react';
import { GridColDef } from '@mui/x-data-grid';
import { Button } from '@mui/material';
import { CompanyConnectionWithCompany } from 'services/api/autogenerated';

export const generateTrucksInBrokersColumns = (
  handleAddBroker: (truck: CompanyConnectionWithCompany) => void,
  addedBrokers: CompanyConnectionWithCompany[]
) => {
  return [
    {
      field: 'connected_company.name',
      headerName: 'Broker name',
      flex: 1,
      renderCell: ({ row }) => row.connected_company?.name,
    },
    {
      field: 'loads',
      headerName: '# New Loads',
      flex: 1,
      renderCell: ({ row }) =>
        addedBrokers.filter(broker => broker.connected_company_id === row.connected_company_id)
          .length,
    },
    {
      field: 'add',
      headerName: ' ',
      flex: 1,
      sortable: false,
      renderCell: ({ row }) => (
        <Button
          variant="contained"
          color="primary"
          size="small"
          onClick={() => handleAddBroker(row)}
        >
          ADD
        </Button>
      ),
    },
  ] as GridColDef[];
};
