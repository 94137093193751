import React, { useMemo } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { useSelector } from 'react-redux';
import { selectLoads } from 'modules/civilAndTrucking/trucking/features/Loads/redux/loadsStoreSlice';
import { LoadingOverlay as Loading } from 'sharedComponents/LoadingOverlay';
import { generateDispatchLoadsColumns } from './dispatchLoadsColumns';

type DispatchLoadsListProps = {
  handleUpdateLoad: (loadId: number, loadRepeat?: boolean, lastLoad?: boolean) => void;
  handleDeleteLoad: (id: number) => void;
};

export const DispatchLoadsList = ({
  handleUpdateLoad,
  handleDeleteLoad,
}: DispatchLoadsListProps) => {
  const { loads, loadsStatus } = useSelector(selectLoads);

  const columns = useMemo(
    () => generateDispatchLoadsColumns(handleUpdateLoad, handleDeleteLoad),
    [handleUpdateLoad, handleDeleteLoad]
  );

  if (loadsStatus === 'pending') {
    return <Loading />;
  }

  return (
    <DataGrid
      columns={columns}
      rows={loads}
      getRowId={row => row.idTruckingLoad}
      showColumnRightBorder={false}
      disableColumnMenu
      disableSelectionOnClick
      autoHeight
      components={{
        ColumnResizeIcon: () => <div></div>,
      }}
    />
  );
};
