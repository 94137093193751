import { addCacheItem, getCacheItem } from 'utils/memoryCache';
import ApiBase from './ApiBase';
import Tables from './Tables';

export default class CompaniesApi extends ApiBase {
  constructor() {
    super(Tables.Companies);
  }

  async getById(id: string | number): Promise<any> {
    return super.getById(id, Tables.Companies);
  }

  async getCompanies(types?: string[] | null) {
    if (!types?.length) {
      return this.getList(`${this.table}`) as any; // TODO: drop it, never called
    } else {
      return this.getList(`${this.table}/types/${types}`) as any; // TODO: we can drop it too, called only in 1 place (easy to rewrite)
    }
  }

  // We use very simple caching mechanism here, but it should help with frequent requests
  async getCompaniesWithCustomDataProperties(props?: string[] | null, types?: string[] | null) {
    const token = await this.getUserToken();

    const key = JSON.stringify([
      'companies-with-custom-data-properties',
      {
        props,
        types,
      },
    ]);
    const fromCache = getCacheItem(key);
    if (fromCache) return fromCache;

    const response = await this.axios.post(
      `/companies/companies-with-custom-data-properties`,
      {
        props,
        types,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    addCacheItem(key, response.data);

    return response.data;
  }

  async getCompaniesRegions() {
    const resultsCompaniesRegionsCustomData = (await this.getList(
      `${Tables.CompaniesCustomData}/regions`
    )) as any[];

    let companiesRegions = [] as any[];
    resultsCompaniesRegionsCustomData.forEach(({ valueArrayJsons }: any) => {
      companiesRegions = companiesRegions.concat(valueArrayJsons || []);
    });
    return companiesRegions;
  }
}
