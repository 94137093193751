import React, { useEffect, useState } from 'react';
import 'mapbox-gl/dist/mapbox-gl.css';
import { Marker, Popup } from 'mapbox-gl';
import Mapbox from 'sharedComponents/Mapbox';
import { Grid } from '@mui/material';
import { useParams } from 'react-router-dom';
import useShowError from 'modules/errors';
import Query from 'services/firebase/Query';
import Executer from 'services/firebase/Executer';

const executer = new Executer();
const query = new Query();

const ScaleMapView = () => {
  let { id } = useParams();
  const showError = useShowError();
  const [manifests, setManifests] = useState([]);
  const [markers, setMarkers] = useState([]);
  const [center, setCenter] = useState([-91.0274176, 39.0425818]);
  const [zoomLevel, setZoomLevel] = useState(4); // 12

  useEffect(() => {
    if (!id) {
      return;
    }

    return executer.watchMultipleDocuments(
      query.manifests.getManifestsHeadingToScale(id),
      manifestsResults => {
        const manifestsWithGpsLastPosition = manifestsResults.reduce((accumulator, manif) => {
          if (manif.signatureDriver.gpsPositionLast) {
            accumulator.push(manif);
          }
          return accumulator;
        }, []);
        setManifests(manifestsWithGpsLastPosition);
      },
      error => {
        console.error('Error', error);
        showError({
          title: 'Error while fetching the manifests',
          duration: 10000,
        });
      }
    );
  }, [id, showError]);

  useEffect(() => {
    if (!manifests || !manifests.length) {
      return;
    }

    let sumLongitude = 0;
    let sumLatitude = 0;
    setMarkers(
      manifests.map(manifest => {
        const project = manifest.project.name;
        const manifestNumber = `Manifest number: ${manifest.number}`;
        const {
          truckingCompany,
          truckNumber,
          gpsPositionLast: { longitude, latitude },
        } = manifest.signatureDriver;
        sumLongitude += longitude || 0;
        sumLatitude += latitude || 0;

        return new Marker()
          .setPopup(
            new Popup({ offset: 25 }) // add popups
              .setHTML(
                '<p>' +
                  project +
                  '</p><p><b>' +
                  manifestNumber +
                  '</b></p><p>' +
                  truckingCompany +
                  '</p><p>' +
                  truckNumber +
                  '</p>'
              )
          )
          .setLngLat([longitude, latitude]);
      })
    );

    sumLongitude /= manifests.length;
    sumLatitude /= manifests.length;

    setZoomLevel(12);
    setCenter([sumLongitude, sumLatitude]);
  }, [manifests]);

  return (
    <Grid container>
      <Grid item xs={12}>
        <Mapbox zoom={zoomLevel} center={center} markers={markers} />
      </Grid>
    </Grid>
  );
};

export default ScaleMapView;
