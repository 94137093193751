import React, { useEffect, useRef, memo, useState } from 'react';
import { useSelector } from 'react-redux';
import { IFullError, selectErrors } from './storeSliceErrors';
import styled from 'styled-components';

const ErrorView = memo(({ error }: { error: IFullError }) => {
  const [hidden, setHidden] = useState(false);

  if (hidden) return null;

  return (
    <ErrorContainer onClick={() => setHidden(true)}>
      <Title>{error.title}</Title>
      <p>{error.text}</p>
      <HideMessage>Click to hide</HideMessage>
    </ErrorContainer>
  );
});
ErrorView.displayName = 'ErrorView';

function ErrorsView() {
  const ref = useRef<HTMLDivElement | null>(null);
  const errors = useSelector(selectErrors);
  // eslint-disable-next-line
  const [tick, setTick] = useState(0);

  const t = Date.now();
  const visibleErrors = errors.filter(e => e.time + e.duration > t);

  useEffect(() => {
    const timeout = setInterval(() => setTick(Date.now()), 1000);
    return () => clearInterval(timeout);
  }, []);

  return (
    <ErrorsWrapper ref={ref}>
      {visibleErrors.map((err, index) => (
        <ErrorView key={`error-message-${index}`} error={err} />
      ))}
    </ErrorsWrapper>
  );
}

const ErrorsWrapper = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  max-width: 320px;
  width: 100%;
  z-index: 10000;
  padding: 10px;
`;

const HideMessage = styled.span`
  position: absolute;
  bottom: 2px;
  right: 2px;
  font-size: 10px;
  padding: 5px 10px;
  border-radius: 5px;
  opacity: 0;
  visibility: hidden;
  background: rgba(255, 255, 255, 0.5);
  color: #333;
`;

const ErrorContainer = styled.div`
  padding: 10px 15px;
  border-radius: 5px;
  border-left: 3px solid #f44336;
  background: #fff0ef;
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.3);
  margin-bottom: 10px;
  position: relative;
  font-size: 14px;
  cursor: pointer;

  &:hover {
    ${HideMessage} {
      opacity: 1;
      visibility: visible;
    }
  }
`;

const Title = styled.p`
  font-weight: bold;
  font-size: 16px;
`;

export default memo(ErrorsView);
