import React from 'react';
import { BrokerTicketsView } from './views/BrokerTicketsView';
// import { DeletedTicketsView } from './views/DeletedTicketsView';
// import { TicketHistoricalMapView } from './views/TicketHistoricalMap';
import { Routes, Route } from 'react-router-dom';

export const BrokerTicketsViews = () => {
  return (
    <Routes>
      <Route path="/" element={<BrokerTicketsView />} />
      {/* <Route path="/deleted" element={<DeletedTicketsView />} /> */}
      {/* <Route path="/:ticketId/map" element={<TicketHistoricalMapView />} /> */}
    </Routes>
  );
};
