import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Button, Grid, TextField } from '@mui/material';
import { get, post } from '../../services/api/restHelpers';

export interface ICompanyFTPConfig {
  host: string;
  port: string;
  user: string;
  password: string;
}

const FTPConfig = ({ companyId }: { companyId: string }) => {
  const [serverFTPConfig, setServerFTPConfig] = useState<ICompanyFTPConfig | undefined>();

  const [host, setHost] = useState('');
  const [port, setPort] = useState('');
  const [user, setUser] = useState('');
  const [password, setPassword] = useState('');

  useEffect(() => {
    const fetchData = async () => {
      const response: ICompanyFTPConfig | undefined = await get(
        `trucking_ticket/ftp/config/${companyId}`
      );
      setServerFTPConfig(response);
    };
    fetchData();
  }, [companyId]);

  useEffect(() => {
    if (serverFTPConfig) {
      setHost(serverFTPConfig.host);
      setPort(serverFTPConfig.port);
      setUser(serverFTPConfig.user);
      setPassword(serverFTPConfig.password);
    }
  }, [serverFTPConfig]);

  const upsertFTPConfig = async () => {
    const config = {
      host,
      port,
      user,
      password,
      secure: 'implicit', // Required for FTPS connections
      secureOptions: { rejectUnauthorized: false },
    };
    try {
      await post(`trucking_ticket/ftp/config/${companyId}`, config);
    } catch (err) {
      console.error('Error - upsertFTPConfig:', err);
    }
  };

  return (
    <Grid item md={6} xs={12}>
      <div>
        <div style={{ fontSize: 20, display: 'inline' }}>Company FTP Config</div>
        <Button style={{ display: 'inline', float: 'right' }} onClick={upsertFTPConfig}>
          Save
        </Button>
      </div>
      <Grid container spacing={2}>
        <Grid item sm={10}>
          <TextField
            fullWidth
            label="Host"
            value={host}
            onChange={e => {
              setHost(e.target.value);
            }}
            variant="outlined"
          />
        </Grid>
        <Grid item sm={2}>
          <TextField
            fullWidth
            label="Port"
            value={port}
            onChange={e => {
              setPort(e.target.value);
            }}
            variant="outlined"
          />
        </Grid>
        <Grid item sm={6}>
          <TextField
            fullWidth
            label="Username"
            value={user}
            onChange={e => {
              setUser(e.target.value);
            }}
            variant="outlined"
          />
        </Grid>
        <Grid item sm={6}>
          <TextField
            fullWidth
            label="Password"
            value={password}
            onChange={e => {
              setPassword(e.target.value);
            }}
            variant="outlined"
          />
        </Grid>
      </Grid>
    </Grid>
  );
};
FTPConfig.propTypes = {
  companyId: PropTypes.string,
};
export default FTPConfig;
