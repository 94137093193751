import React from 'react';
import { usePermissionsContext } from 'services/permissions';

interface PermissionsWrapperProps {
  children: any;
  canAccess: string[];
  usersPermissions?: string[] | boolean;
  unauthorizedMsg?: string | boolean;
}

const PermissionsWrapper = ({
  children,
  canAccess,
  usersPermissions = false,
  unauthorizedMsg = false,
}: PermissionsWrapperProps) => {
  const { user: authUser } = usePermissionsContext();

  const userType = authUser && authUser.actingAsType;
  let userPermissionsFormatted: any = [];

  if (usersPermissions) {
    userPermissionsFormatted = [userType, ...(usersPermissions as Array<string>)];
  } else {
    userPermissionsFormatted = [userType];
  }

  const match =
    userPermissionsFormatted.length > 0
      ? userPermissionsFormatted.some((permission: string) => canAccess.includes(permission))
      : false;

  return userType && match ? (
    <>{children}</>
  ) : unauthorizedMsg ? (
    <p data-testid="permissions__unauthorized">{unauthorizedMsg}</p>
  ) : null;
};

export default PermissionsWrapper;
