import { firestore, Timestamp } from './index';
import { firebase } from 'lg-helpers';

import 'moment-timezone';

const { FirestoreQuery } = firebase;

export default class Query extends FirestoreQuery {
  constructor() {
    super(firestore as any, Timestamp);
  }
}
