/* eslint-disable no-unused-vars */
import React from 'react';
import {
  Container,
  Box,
  Typography,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Button,
  Grid,
} from '@mui/material';
import Page from 'sharedComponents/Page';
import { useSelector } from 'react-redux';
import { selectAuthUser } from 'modules/auth/storeSliceAuth';
import { auth } from 'services/firebase';
import Command from 'services/firebase/Command';
import { activateCompany } from 'services/api';
import useShowError from 'modules/errors';
import { mixPanel } from 'services/mixpanel';
import { handleLogoutAction } from './TopBar';

const command = new Command();

const LicenseAgreementView = () => {
  const authUser = useSelector(selectAuthUser);
  const showError = useShowError();

  const handleClickAccept = async () => {
    await command.manifests.userAcceptLicenseAgreement(authUser.id);
    mixPanel(authUser, 'User Agreement Accepted', { timestamp: Date() });
    try {
      for (const companyId of authUser.companiesIds || []) await activateCompany(companyId);
    } catch (err) {
      console.error('Failed to activate company', err);
      showError({ title: 'Failed to activate company', duration: 10000 });
    }
  };

  return (
    <Page title="Warning">
      <Container>
        <Card>
          <CardHeader title="LIVEGISTICS END USER LICENSE AGREEMENT" />
          <Divider />
          <CardContent>
            <Box display="flex" justifyContent="flex-start" flexDirection="column">
              <Typography color="textPrimary" variant="body1" paragraph={true} align="justify">
                This End User License Agreement (hereinafter “Agreement”) is a legal contract
                between you (hereinafter referred to as “You” or “Your”) and Livegistics Inc.
                (“Licensor”) regarding your use and access of the mobile application software or
                other software provided or made available to you by Licensor (the “Software”) in
                object code only. The term “Software” also includes any corrections, bug fixes,
                enhancements, updates or other modifications created and supplied by Licensor and
                any manuals or other documentation supplied by Licensor in conjunction with the
                Software. This Agreement covers all use of the Software by you the end user. Your
                use of the Software is further be restricted by a separate License Agreement, Master
                Subscription and Services Agreement, Service Proposal and Terms or similar
                agreement, (each an “Enterprise License”) between Licensor and subscribers of
                Licensor’s enterprise software offerings (“Enterprise Licensees”). Such Enterprise
                License limits use and access to the Software only to employees or contractors of
                the Enterprise Licensee. BY CLICKING THE “ACCEPT” BUTTON BELOW, BY SIGNING THIS
                DOCUMENT ONLINE OR BY DOWNLOADING, INSTALLING OR OTHERWISE USING THE SOFTWARE, YOU
                AGREE TO BE BOUND BY THE TERMS OF THIS AGREEMENT. IF YOU DO NOT AGREE TO THE TERMS
                OF THIS AGREEMENT, DO NOT DOWNLOAD, INSTALL, OR USE THE SOFTWARE.
              </Typography>
              <br />
              <Typography color="textPrimary" variant="body1" paragraph={true} align="justify">
                1. LICENSE TERMS
              </Typography>
              <Typography color="textPrimary" variant="body1" paragraph={true} align="justify">
                a. Registration Data. To use the Software, You may be required to complete an online
                registration form whereby You agree to (i) provide complete and correct registration
                data and (ii) maintain and update this information to keep it complete and correct.
                You may not register for the Software if you are under 18 years of age. If
                registration is required and Licensor discovers that your registration data is
                inaccurate, incomplete or not up to date, Licensor may immediately suspend or
                terminate your license to the Software. Your registration information will be used
                to provide you the Software and information regarding our services. You acknowledge
                that the Software may include real time monitoring and stored tracking of your
                location and load data. Licensor may adopt privacy policies from time to time
                regarding the Software and any such policies shall be posted on Licensor’s website.
              </Typography>
              <Typography color="textPrimary" variant="body1" paragraph={true} align="justify">
                b. Grant of License. Provided that You are in material compliance with the terms and
                conditions of this Agreement, Licensor hereby grants to You, pursuant to the terms
                and conditions of this Agreement, a nonexclusive, non-transferable license
                (hereinafter “License”) to use the Software for the term of an active Enterprise
                License between Licensor and an Enterprise Licensee (ie. Your employer or another
                third party whose subscription allows you to be a user of the Software). The
                Software is owned by Licensor and is provided to You and the Enterprise Licensee on
                a subscription basis only. In the event of termination of the Enterprise License,
                your subscription to use and access the Software shall immediately terminate.
              </Typography>
              <Typography color="textPrimary" variant="body1" paragraph={true} align="justify">
                c. Restrictions on Use. You agree to use the Software only for your personal use as
                an employee or contractor of the Enterprise Licensee and you may not transfer use or
                access to the Software to any third party. You are solely responsible for any
                transmissions made using the Software. Your use of the Software is subject to this
                Agreement and all applicable laws, rules and regulations, local, state, national and
                international. You are solely responsible for all activities under Your account and
                for maintaining the confidentiality of Your user name and password. You agree to
                immediately notify Licensor of any known or suspected unauthorized use of your
                account.
              </Typography>
              <Typography color="textPrimary" variant="body1" paragraph={true} align="justify">
                d. Modifications. Reverse Engineering. You agree that only Licensor shall have the
                right to alter, maintain, enhance or otherwise modify the Software and Licensor is
                under no obligation to notify you of any such modifications. You agree that you
                shall not disassemble, decompile or reverse engineer the Software or attempt to do
                the same. You further agree that you shall not permit or assist any third party to
                disassemble, decompile or reverse engineer the Software.
              </Typography>
              <Typography color="textPrimary" variant="body1" paragraph={true} align="justify">
                e. Reservation of Rights. Licensor hereby reserves any and all rights not expressly
                and explicitly granted in this Agreement, including, but not limited to, Licensor’s
                right to license the Software to any third party.
              </Typography>
              <br />
              <Typography color="textPrimary" variant="body1" paragraph={true} align="justify">
                2. INTELLECTUAL PROPERTY RIGHTS
              </Typography>
              <Typography color="textPrimary" variant="body1" paragraph={true} align="justify">
                a. Title. You agree that Licensor owns all proprietary rights, including patent,
                copyright, trade secret, trademark and other proprietary rights, in and to the
                Software and any corrections, bug fixes, enhancements, updates or other
                modifications, whether made by Licensor or by any third party. No title to the
                Software is transferred to You and Your rights are strictly limited as set forth in
                this Agreement.
              </Typography>
              <Typography color="textPrimary" variant="body1" paragraph={true} align="justify">
                b. Transfers. Under no circumstances shall You sell, license, publish, display,
                distribute, or otherwise transfer to a third party the Software or any copy thereof,
                in whole or in part, without Licensor’s prior written consent.
              </Typography>
              <br />
              <Typography color="textPrimary" variant="body1" paragraph={true} align="justify">
                3. DISCLAIMER OF WARRANTIES
              </Typography>
              <Typography color="textPrimary" variant="body1" paragraph={true} align="justify">
                YOU AGREE TO ACCEPT THE SOFTWARE WITHOUT REPRESENTATIONS OR WARRANTIES OF ANY KIND,
                EITHER EXPRESSED OR IMPLIED, INCLUDING, BUT NOT LIMITED TO, WARRANTIES AS TO TITLE
                OR INFRINGEMENT OF THIRD-PARTY RIGHTS, MERCHANTABILITY OR FITNESS FOR A PARTICULAR
                PURPOSE. THE SOFTWARE, DOCUMENTATION AND OTHER FILES ARE PROVIDED “AS IS.” LICENSOR
                AND ITS SUPPLIERS DO NOT AND CANNOT WARRANT THE PERFORMANCE OR RESULTS YOU MAY
                OBTAIN BY USING THE SOFTWARE. Some jurisdictions do not allow the exclusion of
                implied warranties, so the above exclusion may not apply to You. This warranty gives
                You specific legal rights, and You may also have other legal rights, which vary from
                jurisdiction to jurisdiction.
              </Typography>
              <br />
              <Typography color="textPrimary" variant="body1" paragraph={true} align="justify">
                5. LIMITATION OF LIABILITY
              </Typography>
              <Typography color="textPrimary" variant="body1" paragraph={true} align="justify">
                IN NO EVENT WILL LICENSOR BE LIABLE TO YOU OR ANY THIRD PARTY FOR INDIRECT, SPECIAL,
                INCIDENTAL, CONSEQUENTIAL, EXEMPLARY OR OTHER SIMILAR DAMAGES, INCLUDING LOST
                PROFITS OR BILLING ERRORS, ARISING FROM THE USE OF OR INABILITY TO USE THE SOFTWARE
                OR FROM ANY BREACH OF WARRANTY, EVEN IF LICENSOR HAS BEEN ADVISED OF THE POSSIBILITY
                OF SUCH DAMAGES. YOU EXPRESSLY AGREE THAT USE OF THE SOFTWARE IS AT YOUR OWN RISK.
                IN NO EVENT SHALL LICENSOR’S TOTAL LIABILITY FROM ALL DAMAGES, LOSSES OR COSTS OF
                ANY KIND OR NATURE (WHETHER IN CONTRACT, TORT OR OTHERWISE) EXCEED THE GREATER OF
                $250 OR THE AMOUNT YOU HAVE HAS PAID TO LICENSOR DURING THE 12 MONTHS IMMEDIATELY
                BEFORE THE CLAIM AROSE.
              </Typography>
              <br />
              <Typography color="textPrimary" variant="body1" paragraph={true} align="justify">
                6. GENERAL PROVISIONS
              </Typography>
              <Typography color="textPrimary" variant="body1" paragraph={true} align="justify">
                a. Modifications. Licensor may amend, alter or revise the terms of this Agreement at
                any time by (i) posting a revised Agreement on or accessible through Licensor’s web
                site; and/or (ii) sending information regarding the Agreement amendment to the email
                address you have provided to Licensor. You are responsible for regularly reviewing
                the Licensor web site to obtain timely notice of such amendments. If You do not
                agree to accept the changes, Your sole remedy shall be to discontinue Your use of
                the Software.
              </Typography>
              <Typography color="textPrimary" variant="body1" paragraph={true} align="justify">
                b. Governing Law/Venue/Jurisdiction. This Agreement shall be governed by and
                construed under the laws of the State of Michigan, without regard to choice of law
                provisions. The application of the United Nations Convention on Contracts for the
                International Sale of Goods is expressly excluded. Venue and jurisdiction shall be
                proper only in the County of Oakland, State of Michigan and each party hereby
                consents to such exclusive and personal jurisdiction and venue.
              </Typography>
              <Typography color="textPrimary" variant="body1" paragraph={true} align="justify">
                c. Termination. Without prejudice to any other rights, Licensor may terminate this
                Agreement if You fail to comply with the terms and conditions of this Agreement or
                if the Enterprise User’s subscription agreement with Licensor terminates. In such
                event, Licensor may deny access to the Software and You must discontinue use of the
                Software.
              </Typography>
              <Typography color="textPrimary" variant="body1" paragraph={true} align="justify">
                d. Severability. The provisions of this Agreement are severable, and if any one or
                more such provisions shall be determined to be invalid, illegal or unenforceable, in
                whole or in part, the validity, legality and enforceability of any of the remaining
                provisions or portions thereof shall not in any way be affected thereby and shall
                nevertheless be binding between the parties hereto. Any such invalid, illegal or
                unenforceable provision or portion thereof shall be changed and interpreted so as to
                best accomplish the objectives of such provision or portion thereof within the
                limits of applicable law.
              </Typography>
              <Typography color="textPrimary" variant="body1" paragraph={true} align="justify">
                e. Complete Agreement. The parties agree that this Agreement is the complete and
                exclusive statement of the agreement between the parties, which supersedes and
                merges all prior proposals, understandings and all other agreements, oral or
                written, between the parties relating to the subject matter.
              </Typography>
              <Typography color="textPrimary" variant="body1" paragraph={true} align="justify">
                f. Waiver. Any waiver, either expressed or implied, by either party of any default
                by the other in the observance and performance of any of the conditions and/or
                covenants of duties set forth herein shall not constitute or be construed as a
                waiver of any subsequent or other default.
              </Typography>
              <Typography color="textPrimary" variant="body1" paragraph={true} align="justify">
                g. Read and Understood. Licensee hereby acknowledges that it has read and
                understands this Agreement and agrees to be bound by its terms.
              </Typography>
              <br />
              <Grid container spacing={1}>
                <Grid item sm={8} xs={12}>
                  <Button onClick={handleClickAccept} fullWidth color="primary" variant="contained">
                    <Typography variant="h3">
                      <Box display="flex" alignItems="center">
                        Accept
                      </Box>
                    </Typography>
                  </Button>
                </Grid>
                <Grid item sm={4} xs={12}>
                  <Button onClick={handleLogoutAction} fullWidth variant="contained">
                    <Typography variant="h3" color="secondary">
                      <Box display="flex" alignItems="center">
                        Reject
                      </Box>
                    </Typography>
                  </Button>
                </Grid>
              </Grid>
            </Box>
          </CardContent>
        </Card>
      </Container>
    </Page>
  );
};

export default LicenseAgreementView;
