import React from 'react';
import { createSvgIcon } from '@mui/material';

export const Help = createSvgIcon(
  <>
    <path d="M7 8.3125c0-2.20913 1.79086-4 4-4 2.2091 0 4 1.79087 4 4 0 1.8638-1.2748 3.4299-3 3.874v1.126c0 .5523-.4477 1-1 1s-1-.4477-1-1v-2c0-.5523.4477-1 1-1 1.1046 0 2-.89543 2-2 0-1.10456-.8954-2-2-2-1.10456 0-2 .89544-2 2 0 .55229-.44771 1-1 1-.55228 0-1-.44771-1-1ZM12.25 16.5625c0 .6903-.5597 1.25-1.25 1.25s-1.25-.5597-1.25-1.25.5597-1.25 1.25-1.25 1.25.5597 1.25 1.25Z" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.22183 3.22182C5.21136 1.23232 7.96273 0 11 0c3.0373 0 5.7886 1.23231 7.7782 3.22182C20.7677 5.21135 22 7.96273 22 11c0 3.0373-1.2323 5.7886-3.2218 7.7782C16.7886 20.7677 14.0373 22 11 22c-3.03727 0-5.78864-1.2323-7.77817-3.2218C1.23232 16.7886 0 14.0373 0 11c0-3.03727 1.23233-5.78865 3.22183-7.77818ZM11 2C8.51447 2 6.26584 3.00626 4.63603 4.63604 3.00625 6.26585 2 8.51447 2 11c0 2.4855 1.00626 4.7342 2.63604 6.3639C6.26584 18.9937 8.51447 20 11 20c2.4855 0 4.7342-1.0063 6.3639-2.6361C18.9937 15.7342 20 13.4855 20 11c0-2.48553-1.0063-4.73416-2.6361-6.36396C15.7342 3.00626 13.4855 2 11 2Z"
    />
  </>,
  'Help'
);
