import React, { useContext, useState, useEffect } from 'react';
import { Typography, Box, Button, Grid, SvgIcon } from '@mui/material';
import TableWrapper from 'sharedComponents/TableWrapper';
import { ModalContext } from 'sharedComponents/ModalContext';
import { useSelector } from 'react-redux';
import { RootState, thunkDispatch } from 'store/store';
import AddActivity from './ActivitiesForm';
import { deleteActivity, getActivitiesByWorkCategory } from './redux/actions';
import { ChevronLeft } from '@mui/icons-material';
import { Link, useLocation, useParams } from 'react-router-dom';
import { useHasAccess } from 'services/permissions';
import { LoadingComponent } from 'sharedComponents/LoadingComponent';
import { IActivityJoin } from 'lg-helpers/dist/shared/interfaces/IActivityJoin';
import { IActivity } from 'lg-helpers/dist/shared/interfaces/IActivity';

const Activities = () => {
  const { search } = useLocation();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const query = new URLSearchParams(search);
  const { projectId } = useParams();
  const workCategoryId = query.get('type');

  const { handleModal } = useContext(ModalContext);

  const [activites, setActivities] = useState<IActivityJoin[]>([]);
  const [workCategory, setWorkCategory] = useState<string | undefined>('');
  const auth = useHasAccess(['projects:create', 'projects:update']);

  const showModal = (event: React.MouseEvent, selection?: IActivityJoin) => {
    handleModal(<AddActivity selected={selection as IActivity} />); // TODO: verify type conflict here
  };

  const handleDeletion = (selection: IActivityJoin) => {
    thunkDispatch(
      deleteActivity({ activity: selection as IActivity, projectId: Number(projectId) })
    ); // TODO: verify the same type conflict here
  };

  const workCategoryState = useSelector((state: RootState) => state.civil.activityTypes.data);

  const activityState = useSelector((state: RootState) => state.civil.activities.data);

  useEffect(() => {
    (async () => {
      setIsLoading(true);
      await thunkDispatch(
        getActivitiesByWorkCategory({
          workCategoryId: Number(workCategoryId),
          projectId: Number(projectId),
        })
      );
      setIsLoading(false);
    })();
  }, [workCategoryId, projectId]);

  useEffect(() => {
    setActivities(
      activityState.map(item => ({
        name: item.name,
        activityTypeId: item.activityTypeId,
        id: item.id,
      }))
    );
    setWorkCategory(workCategoryState.find(wc => wc.id === Number(workCategoryId))?.name);
    // eslint-disable-next-line
  }, [activityState, workCategoryState, workCategoryId]);

  return (
    <>
      <Grid container>
        <Grid
          item
          xs={12}
          display="flex"
          justifyContent="space-between"
          sx={{ marginBottom: '1rem' }}
        >
          <Box>
            <Typography variant="h5" sx={{ color: 'neutral.main' }}>
              Activities for {workCategory}
            </Typography>
            <Link to={`/civil/projects/${projectId}/work-categories`}>
              <Box display="flex">
                <SvgIcon sx={{ color: 'neutral.main' }}>
                  <ChevronLeft />
                </SvgIcon>
                <Typography sx={{ color: 'neutral.main' }}>Back to Work Categories</Typography>
              </Box>
            </Link>
          </Box>

          <Box flexGrow={1}></Box>
          <Button
            color="primary"
            variant="contained"
            onClick={showModal}
            disabled={!auth.hasAccess}
          >
            Add Activity
          </Button>
        </Grid>
        <TableWrapper
          data={activites}
          headCells={[
            {
              label: 'Name',
              id: 'name',
            },
          ]}
          canDelete={auth.hasAccess}
          canEdit={auth.hasAccess}
          onSelection={showModal}
          onDelete={handleDeletion}
          deleteConfirmation="You are about to remove an activity. This activity may still exist in active project tasks. Please delete or update those tasks as needed. Are you sure you wish to remove this activity?"
        />
      </Grid>
      <LoadingComponent isLoading={isLoading} />
    </>
  );
};

export default Activities;
