import React from 'react';
import { createSvgIcon } from '@mui/material';

export const Duplicate = createSvgIcon(
  <path
    fillRule="evenodd"
    clipRule="evenodd"
    d="M14 0H2C.9 0 0 .9 0 2v14h2V2h12V0Zm3 4H6c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h11c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2Zm0 16H6V6h11v14Z M7.25 13c0-.4142.33579-.75.75-.75h7.5c.4142 0 .75.3358.75.75s-.3358.75-.75.75H8c-.41421 0-.75-.3358-.75-.75Z M11.75 17.5c-.4142 0-.75-.3358-.75-.75v-7.5c0-.41421.3358-.75.75-.75s.75.33579.75.75v7.5c0 .4142-.3358.75-.75.75Z"
  />,
  'Duplicate'
);
