import React, { useMemo } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { useSelector } from 'react-redux';
import { generateDispatchColumns } from 'modules/civilAndTrucking/trucking/features/Dispatches/views/DispatchesView/components/dispatchesColumns';
import { selectJoinedDispatches } from 'modules/civilAndTrucking/trucking/features/Dispatches/redux/dispatchStoreSlice';
import { LoadingOverlay } from 'sharedComponents/LoadingOverlay';

export const BrokerDispatchesList = () => {
  const { joinedDispatches, joinedDispatchesStatus } = useSelector(selectJoinedDispatches);

  const columns = useMemo(() => generateDispatchColumns(), []);

  if (joinedDispatchesStatus === 'pending') {
    return <LoadingOverlay />;
  }

  return (
    <DataGrid
      columns={columns}
      rows={joinedDispatches || []}
      getRowId={row => row.idTruckingDispatch}
      showColumnRightBorder={false}
      disableColumnMenu
      autoHeight
      disableSelectionOnClick
      components={{
        ColumnResizeIcon: () => <div></div>,
      }}
    />
  );
};
