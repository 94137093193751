import React from 'react';
import { Button, Box, CircularProgress, Typography } from '@mui/material';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';

const ButtonDownload = ({
  isLoading,
  url,
  numberGenerated,
  numberExpected,
}: {
  isLoading?: boolean;
  url: string;
  numberGenerated?: number;
  numberExpected?: number;
}) => {
  const onDownloadClick = async () => {
    window.open(url, '_blank');
  };

  const progress = numberGenerated && numberExpected ? (numberGenerated / numberExpected) * 100 : 0;

  return (
    <Button
      disabled={isLoading}
      onClick={onDownloadClick}
      size="small"
      fullWidth={false}
      variant="outlined"
    >
      <Box display="flex" alignItems="center" justifyContent="center">
        {isLoading ? (
          <Box display="flex" alignItems="center">
            <Box position="relative" display="inline-flex">
              <CircularProgress color="inherit" size={30} value={progress} variant="determinate" />
              <Box
                top={0}
                left={0}
                bottom={0}
                right={0}
                position="absolute"
                display="flex"
                alignItems="center"
                justifyContent="center"
              >
                <Typography
                  variant="body2"
                  color="textSecondary"
                  style={{ fontSize: '75%', lineHeight: '1' }}
                >{`${Math.round(progress)}%`}</Typography>
              </Box>
            </Box>
          </Box>
        ) : (
          <CloudDownloadIcon color="inherit" fontSize="medium" />
        )}
      </Box>
    </Button>
  );
};

export default ButtonDownload;
