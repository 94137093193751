import React, { useContext, useEffect, useState } from 'react';
import {
  Grid,
  Box,
  TextField,
  InputLabel,
  FormControl,
  Typography,
  MenuItem,
  useTheme,
  useMediaQuery,
} from '@mui/material';
import { Search } from '@mui/icons-material';
import RequestsTable from './RequestsTable';
import { useDispatch, useSelector } from 'react-redux';
import { ModalContext } from 'sharedComponents/ModalContext';
import {
  getProjects,
  setActiveProject,
} from 'modules/civilAndTrucking/civil/Projects/redux/actions';
import { RootState, thunkDispatch } from 'store/store';
import moment from 'moment';
import { getUtcEndOfDay, getUtcStartOfDay } from '../../utils/time';
import { getRequestsBySearch } from '../../Requests/redux/actions';
import RequestModal from 'modules/civilAndTrucking/civil/Requests/RequestModal';
import { IRequest } from 'lg-helpers/dist/shared/interfaces/IRequest';

export interface IJoinRequest extends IRequest {
  transporter?: string;
  material?: string;
  newNote?: string;
}

interface ISearch {
  search?: string;
  startDate?: string | Date;
  endDate?: string | Date;
  projectId?: number;
}

const initialSearchValues: ISearch = {
  search: undefined,
  startDate: undefined,
  endDate: undefined,
  projectId: undefined,
} as const;

const RequestsTableContainer = () => {
  const dispatch = useDispatch();
  const { handleModal } = useContext(ModalContext);
  const [projectId, setProjectId] = useState<number>();
  const [startDate, setStartDate] = useState<string>(
    moment
      .utc(new Date(new Date().getTime() - 2 * 24 * 60 * 60 * 1000))
      .startOf('day')
      .format('YYYY-MM-DD')
  );
  const [endDate, setEndDate] = useState<string>(
    moment
      .utc(new Date(new Date().getTime() + 7 * 24 * 60 * 60 * 1000))
      .endOf('day')
      .format('YYYY-MM-DD')
  );
  const [requests, setRequests] = useState<IRequest[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [search, setSearch] = useState<ISearch>(initialSearchValues);
  const theme = useTheme();
  const isWidthXSorSM = useMediaQuery(theme.breakpoints.down('md'));
  const { innerWidth } = window;
  const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  const requestState = useSelector((state: RootState) => state.civil.requests.data);

  const activeProject = useSelector((state: RootState) => state.civil.projects.activeProject);

  const projectState = useSelector((state: RootState) => state.civil.projects.data);

  const handleChange = async (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    setSearch(prev => ({ ...prev, [event.target.name]: event.target.value }));
    return;
  };

  useEffect(() => {
    const utcStart = getUtcStartOfDay(startDate, timeZone);
    const utcEnd = getUtcEndOfDay(endDate, timeZone);
    setSearch(prev => ({
      ...prev,
      startDate: startDate ? utcStart : undefined,
      endDate: endDate ? utcEnd : undefined,
    }));
  }, [startDate, endDate, timeZone]);

  async function updateProject(id: number) {
    setIsLoading(true);
    await thunkDispatch(setActiveProject(Number(id)));
    setIsLoading(false);
  }

  useEffect(() => {
    if (activeProject) {
      setProjectId(activeProject.id as number);
      setSearch(prev => ({ ...prev, projectId: activeProject.id as number }));
    }
  }, [activeProject]);

  useEffect(() => {
    setRequests(
      requestState.map(request => ({
        ...request,
      }))
    );
  }, [setRequests, requestState]);

  const searchRequests = async () => {
    if (!projectId) {
      return;
    }
    let searchArray: Array<any> = [];
    Object.keys(search).forEach((key: string) => {
      if (
        search[key as keyof ISearch] !== undefined &&
        search[key as keyof ISearch] !== 'Invalid Date'
      ) {
        searchArray.push({
          item: key,
          value: search[key as keyof ISearch],
        });
      }
    });
    setIsLoading(true);
    const data = await thunkDispatch(getRequestsBySearch(searchArray));
    if (data.meta.requestStatus === 'fulfilled') {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    searchRequests();
    // eslint-disable-next-line
  }, [search]);

  const showModal = (value: IRequest) => {
    handleModal(<RequestModal selected={value} />);
  };

  useEffect(() => {
    thunkDispatch(getProjects());
  }, [dispatch]);

  return (
    <>
      <Grid container maxWidth={innerWidth * 0.85}>
        <Grid item xs={12}>
          <Typography
            variant="h5"
            sx={{
              color: 'neutral.main',
              typography: { textTransform: 'uppercase' },
            }}
          >
            Requests
          </Typography>
        </Grid>
        <Grid item xs={12} md={4}>
          <Box
            borderRight={!isWidthXSorSM ? '#555 solid 1px' : ''}
            sx={!isWidthXSorSM ? { pr: 2, my: 2, maxHeight: 100 } : { maxHeight: 100 }}
          >
            <FormControl fullWidth>
              <InputLabel shrink>Project</InputLabel>
              <TextField
                select
                variant="outlined"
                value={projectId || ''}
                onChange={event => {
                  updateProject(Number(event.target.value));
                }}
                name="projectId"
              >
                {projectState.map(project => (
                  <MenuItem value={project.id} key={project.id}>
                    {project.name}
                  </MenuItem>
                ))}
              </TextField>
            </FormControl>
          </Box>
        </Grid>
        <Grid item xs={12} md={8}>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-evenly"
            sx={!isWidthXSorSM ? { pl: 2, my: 2, maxHeight: 100 } : { maxHeight: 100 }}
          >
            <FormControl fullWidth>
              <InputLabel shrink>Search</InputLabel>
              <TextField
                InputProps={{ endAdornment: <Search /> }}
                variant="outlined"
                name="search"
                value={search.search || ''}
                onChange={event => {
                  event.persist();
                  handleChange(event);
                }}
              />
            </FormControl>
            <FormControl fullWidth>
              <InputLabel shrink>Start Date</InputLabel>
              <TextField
                variant="outlined"
                type="date"
                placeholder="From"
                name="startDate"
                value={startDate || ''}
                onChange={event => {
                  if (event.target.value) {
                    setStartDate(event.target.value);
                  } else {
                    setStartDate(
                      moment(
                        new Date(
                          new Date().getTime() - 7 * 24 * 60 * 60 * 1000
                        ).toLocaleDateString()
                      ).format('YYYY-MM-DD')
                    );
                  }
                }}
              />
            </FormControl>
            <FormControl fullWidth>
              <InputLabel shrink>End Date</InputLabel>
              <TextField
                variant="outlined"
                type="date"
                placeholder="From"
                name="endDate"
                value={endDate || ''}
                onChange={event => {
                  if (event.target.value) {
                    setEndDate(event.target.value);
                  } else {
                    setEndDate(moment(new Date()).format('YYYY-MM-DD'));
                  }
                }}
              />
            </FormControl>
          </Box>
        </Grid>
      </Grid>

      <RequestsTable
        isLoading={isLoading}
        handleSelection={showModal}
        requests={requests && projectId ? requests : []}
      />
    </>
  );
};

export default RequestsTableContainer;
