import React, { useContext, useEffect, useState } from 'react';
import {
  DialogContent,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  Paper,
  TextField,
} from '@mui/material';
import defaultTruckingTicketExport from 'lg-helpers/dist/constants/truckingTicketExport/defaultTruckingTicketExport';
import { createTicketExportTemplate, updateTicketExportTemplate } from '../../../../redux/actions';
import { thunkDispatch } from 'store/store';
import DialogActionContainer from 'sharedComponents/DialogActionContainer';
import { ModalContext } from 'sharedComponents/ModalContext';

export type TemplateForm = {
  [key: string]: string;
};

export type TemplateAPIForm = {
  companyIdCompaniesCustomData: string;
  idCompaniesCustomData: number;
  nameCompaniesCustomData: string;
  valueJson: TemplateForm;
};

type TicketExportCSVTemplateProps = {
  existingExportTemplate?: TemplateAPIForm;
};

export const TicketExportCSVTemplate = ({
  existingExportTemplate,
}: TicketExportCSVTemplateProps) => {
  const [exportTemplate, setExportTemplate] = useState({} as TemplateForm);
  const { handleModal } = useContext(ModalContext);

  useEffect(() => {
    const template = { ...(existingExportTemplate?.valueJson || ({} as TemplateForm)) };

    defaultTruckingTicketExport.forEach(exportField => {
      const { label, value } = exportField || '';
      if (template[value]) return;
      template[value] = label;
    });

    setExportTemplate(template);
  }, [existingExportTemplate]);

  const onHandleTemplateChange = (val: string, updateField: string) => {
    const updatedTemplate = structuredClone(exportTemplate);
    updatedTemplate[updateField] = val;
    setExportTemplate(updatedTemplate);
  };

  const onHandleSaveTemplate = async () => {
    if (existingExportTemplate) {
      await thunkDispatch(
        updateTicketExportTemplate({
          idCompaniesCustomData: existingExportTemplate.idCompaniesCustomData,
          exportTemplate: exportTemplate,
        })
      );
    } else {
      await thunkDispatch(createTicketExportTemplate(exportTemplate));
    }
    handleModal();
  };

  const getTemplateFieldLabel = (columnKey: string) =>
    defaultTruckingTicketExport?.find(
      defaultExportColumn => defaultExportColumn.value === columnKey
    )?.label || columnKey;

  return (
    <DialogContent>
      <TableContainer sx={{ mt: 2 }} component={Paper}>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell style={{ width: 100 }} align="center">
                Column No.
              </TableCell>
              <TableCell align="center">Ticket Field</TableCell>
              <TableCell align="center">Column Header</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {Object.entries(exportTemplate).map((exportTemplateColumn, index: number) => {
              const [columnKey, columnValue] = exportTemplateColumn;
              return (
                <TableRow key={index}>
                  <TableCell>{index + 1}</TableCell>
                  <TableCell>{getTemplateFieldLabel(columnKey)}</TableCell>
                  <TableCell>
                    <TextField
                      onChange={e => onHandleTemplateChange(e.target.value, columnKey)}
                      value={columnValue}
                      style={{ margin: 0 }}
                    />
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <DialogActionContainer saveHandler={onHandleSaveTemplate} />
    </DialogContent>
  );
};
