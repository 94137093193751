import { createAsyncThunk } from '@reduxjs/toolkit';
import { pushError } from 'modules/errors/storeSliceErrors';
import { postStatic } from 'services/api/restHelpers';
import { pushNotification } from 'modules/civilAndTrucking/shared/NotificationSnackbar/redux/actions';
import { INotification } from 'modules/civilAndTrucking/shared/NotificationSnackbar/INotification';

export const importTKMSDataFromFTP = createAsyncThunk(
  'tickets/import-tkms-data-from-ftp',
  async (isTestRequest: boolean, thunkApi) => {
    try {
      const data: any = isTestRequest
        ? await postStatic(`import/TKMS-ftp/test-import`, null)
        : await postStatic(`import/TKMS-ftp/import`, null);
      const successNotification: INotification = {
        status: 'success',
        message: `Successfully imported ${data.contractors.addedOrUpdated} contractors, 
        ${data.sourceSuppliers.addedOrUpdated} source suppliers, 
        ${data.projects.addedOrUpdated} projects, and 
        ${data.projectMaterials.addedOrUpdated} project materials. 
        Error importing: ${data.contractors.errors} contractors, 
        ${data.sourceSuppliers.errors} source suppliers, 
        ${data.projects.errors} projects, and 
        ${data.projectMaterials.errors} project materials.
        Ignored the import of ${data.contractors.ignored} contractors,
        ${data.sourceSuppliers.ignored} source suppliers,
        ${data.projects.ignored} projects, and
        ${data.projectMaterials.ignored} project materials.`,
        duration: 100000,
      };
      thunkApi.dispatch(pushNotification(successNotification));
      return data;
    } catch (err) {
      console.error(err);
      thunkApi.dispatch(pushError({ title: 'Error: Unable to get import from FTP' }));
      throw err;
    }
  }
);

export const preProcessImportTKMSData = createAsyncThunk(
  'tickets/preprocess-import-tkms-data',
  async (isTestRequest: boolean, thunkApi) => {
    try {
      const data: any = isTestRequest
        ? await postStatic(`import/TKMS-ftp/test-preprocess`, null)
        : await postStatic(`import/TKMS-ftp/preprocess`, null);
      return data;
    } catch (err) {
      console.error(err);
      thunkApi.dispatch(pushError({ title: 'Error: Unable to get import from FTP' }));
      throw err;
    }
  }
);
