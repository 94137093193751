import React from 'react';
import { GridColDef } from '@mui/x-data-grid';
import { Button } from '@mui/material';
import { getCapacityVolumeByUnit } from 'modules/civilAndTrucking/trucking/utils';
import { IJoinTruck } from 'lg-helpers/dist/trucking/interfaces/IJoinTruck';
import { ILoadTruck } from '../AddLoadsToDispatchForm';
import { UnitTruckCapacity } from 'lg-helpers/dist/shared/types/global-types';

export const generateTrucksInDivisionColumns = (
  handleAddTruck: (truck: ILoadTruck) => void,
  unitOfMeasure: UnitTruckCapacity,
  addedTrucks: IJoinTruck[]
) => {
  return [
    {
      field: 'truckNumberTruckingTruck',
      headerName: 'Truck no.',
      flex: 1,
    },
    {
      field: 'loads',
      headerName: '# New Loads',
      flex: 1,
      renderCell: params =>
        addedTrucks.filter(truck => truck.idTruckingTruck === params.row.idTruckingTruck).length,
    },
    {
      field: 'capacity',
      headerName: 'Capacity',
      flex: 1,
      renderCell: params =>
        `${getCapacityVolumeByUnit(
          unitOfMeasure,
          params.row?.capacitiesTruckingTruckType
        )} ${unitOfMeasure}`,
    },
    {
      field: 'add',
      headerName: ' ',
      flex: 1,
      sortable: false,
      renderCell: params => (
        <Button
          variant="contained"
          color="primary"
          size="small"
          onClick={() => handleAddTruck(params.row as ILoadTruck)}
        >
          ADD
        </Button>
      ),
    },
  ] as GridColDef[];
};
