import React, { useEffect, useState } from 'react';

import DayAtAGlance from './DayAtAGlance';
import {
  Grid,
  Typography,
  Box,
  Switch,
  useTheme,
  useMediaQuery,
  TextField,
  InputAdornment,
  SvgIcon,
} from '@mui/material';
import { thunkDispatch } from 'store/store';

import TicketSummaryTable from 'modules/civilAndTrucking/civil/Tickets/TicketSummaryTable';
import EfficienciesView from './Efficiencies';
import { getTicketSummary } from '../../Tickets/redux/actions';
import { LoadingComponent } from 'sharedComponents/LoadingComponent';
import AccountingSummary from './AccountingSummary';
import { useSwitchUserRoleRender } from 'services/permissions';
import { setActiveProject } from '../../Projects/redux/actions';
import { useSelector } from 'react-redux';
import { selectAuthUser } from 'modules/auth/storeSliceAuth';
import { Search } from 'modules/civilAndTrucking/shared/CustomIcons/Search';
import { debounce } from 'lodash';

export const ProjectManagerDashboard = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [showActiveProjects, setShowActiveProjects] = useState<boolean>(true);
  const [projectId, setProjectId] = useState<number>();
  const [search, setSearch] = useState<string>();
  const theme = useTheme();
  const isWidthXSorSM = useMediaQuery(theme.breakpoints.down('md'));
  const { innerHeight } = window;

  const authUser = useSelector(selectAuthUser);

  const toggleActive = () => {
    setShowActiveProjects(!showActiveProjects);
  };

  const updateProjects = React.useMemo(
    () =>
      debounce(async (request: { input: string; showActive: boolean }) => {
        setIsLoading(true);
        await thunkDispatch(
          getTicketSummary({
            showActive: request.showActive,
            search: request.input || '',
          })
        );
        setIsLoading(false);
      }, 500),
    // eslint-disable-next-line
    []
  );

  useEffect(() => {
    updateProjects({ input: search || '', showActive: showActiveProjects });
    // eslint-disable-next-line
  }, [search, showActiveProjects]);

  const renderedComponent = useSwitchUserRoleRender({
    default: () => <EfficienciesView />,
    civil_accountant: () => <AccountingSummary />,
  });

  useEffect(() => {
    if (!projectId) return;
    thunkDispatch(setActiveProject(Number(projectId)));
  }, [projectId]);

  const clearSelectedProject = () => setProjectId(undefined);
  return (
    <>
      <Grid container spacing={{ xs: 2, md: 10 }} minHeight="100%" height="100%">
        <Grid
          item
          xs={12}
          md={8}
          height={isWidthXSorSM ? innerHeight : '100%'}
          order={{ xs: 2, md: 1 }}
        >
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="flex-end"
            width="100%"
            marginBottom={1}
          >
            <Typography
              variant="h5"
              color="neutral.main"
              sx={{ typography: { textTransform: 'uppercase' } }}
            >
              {showActiveProjects ? 'Open' : 'Archived'} Projects
              {authUser.actingAsType !== 'Civil Viewer' && (
                <Switch
                  color="secondary"
                  size="small"
                  checked={showActiveProjects}
                  onChange={() => {
                    toggleActive();
                  }}
                />
              )}
            </Typography>
            <TextField
              className="ProjectManagerDashboard__TextField--search-projects"
              sx={{ my: 0, maxWidth: 200 }}
              placeholder="Search Projects"
              value={search || ''}
              onChange={event => setSearch(event.target.value)}
              size="small"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SvgIcon fontSize="small">
                      <Search />
                    </SvgIcon>
                  </InputAdornment>
                ),
              }}
            />
          </Box>
          <TicketSummaryTable selectProject={setProjectId} selectedProjectId={projectId} />
        </Grid>
        <Grid item xs={12} md={4} display="flex" flexDirection="column" order={{ xs: 1, md: 2 }}>
          <Grid item xs={12}>
            <Typography
              variant="h5"
              color="neutral.main"
              sx={{ typography: { textTransform: 'uppercase' } }}
            >
              At a Glance
            </Typography>
            <DayAtAGlance
              selectedProjectId={projectId}
              clearSelectedProject={clearSelectedProject}
            />
          </Grid>
          {!isWidthXSorSM && (
            <>
              <Box style={{ width: '100%', marginTop: '1rem', marginBottom: '1rem' }} />
              <Grid item xs={12} flexGrow={1} flexDirection="column">
                {renderedComponent}
              </Grid>
            </>
          )}
        </Grid>
      </Grid>
      <LoadingComponent isLoading={isLoading} />
    </>
  );
};

export default ProjectManagerDashboard;
