import { createSlice } from '@reduxjs/toolkit';

export const storeSliceManifest = createSlice({
  name: 'manifestView',
  initialState: {
    cachedTemplates: {},
    cachedBase64ImagesByUrl: {},
    projectsManifestFilters: {},
    digitalFilter: {
      isDigital: null,
    },
    qcFilters: null,
  },
  reducers: {
    addCachedTemplate: (state, action) => {
      state.cachedTemplates[action.payload.key] = action.payload.template;
    },
    addCachedBase64ImagesByUrl: (state, action) => {
      state.cachedBase64ImagesByUrl[action.payload.url] = action.payload.base64;
    },
    clearProjectsManifestFilters: state => {
      state.projectsManifestFilters = {};
    },
    setProjectsManifestFilters: (state, action) => {
      state.projectsManifestFilters[action.payload.id] = action.payload.filters;
    },
    setDigitalFilter: (state, action) => {
      state.digitalFilter = action.payload;
    },
    setQCFilters: (state, action) => {
      state.qcFilters = action.payload;
    },
  },
});

export const {
  addCachedTemplate,
  addCachedBase64ImagesByUrl,
  clearProjectsManifestFilters,
  setProjectsManifestFilters,
  setDigitalFilter,
  setQCFilters,
} = storeSliceManifest.actions;

export const selectCachedTemplates = state => state.manifestView.cachedTemplates;
export const selectCachedBase64ImagesByUrl = state => state.manifestView.cachedBase64ImagesByUrl;
export const selectProjectsManifestFilters = state => state.manifestView.projectsManifestFilters;
export const selectDigitalFilter = state => state.manifestView.digitalFilter;
export const selectQCFilters = state => state.manifestView.qcFilters;

export default storeSliceManifest.reducer;
