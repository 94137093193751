import { createAsyncThunk } from '@reduxjs/toolkit';
import { INotification } from 'modules/civilAndTrucking/shared/NotificationSnackbar/INotification';
import { pushNotification } from 'modules/civilAndTrucking/shared/NotificationSnackbar/redux/actions';
import { del, get, post, getStatic } from 'services/api/restHelpers';
import { RootState } from 'store/store';
import { IInvoice } from 'lg-helpers/dist/shared/interfaces/IInvoice';

export const getInvoicesForProject = createAsyncThunk(
  'invoices/get-all',
  async (payload: { projectId: number; search?: string }) => {
    const data = await get(
      `civil/projects/${payload.projectId}/invoices?search=${payload.search ?? ''}`
    );
    return data as IInvoice[];
  }
);

export const addInvoice = createAsyncThunk(
  'invoices/create',
  async (payload: IInvoice, thunkApi) => {
    const state = thunkApi.getState() as RootState;
    const companyId = state.civil.companies.activeCompany?.id;
    payload.targetCompanyId = companyId;
    try {
      const result = await post(`civil/invoices`, payload);
      return result as IInvoice;
    } catch (err: any) {
      const errorNotification: INotification = {
        status: 'error',
        message: err?.response?.data?.message || err?.message || 'failed to save invoice',
      };
      thunkApi.dispatch(pushNotification(errorNotification));
      throw err;
    }
  }
);

export const deleteInvoice = createAsyncThunk(
  'invoices/delete-invoice',
  async (payload: IInvoice, thunkApi) => {
    try {
      const result = await del(`civil/invoices/${payload.id}`);
      const successNotification: INotification = {
        status: 'success',
        message: `Invoice ${payload.number} has been deleted`,
      };
      thunkApi.dispatch(pushNotification(successNotification));
      return result?.data as IInvoice;
    } catch (err) {
      const errorNotification: INotification = {
        status: 'error',
        message: 'Error: Unable to remove invoice.',
      };
      thunkApi.dispatch(pushNotification(errorNotification));
      throw err;
    }
  }
);

export const printReconciliationReport = createAsyncThunk(
  'invoices/get-reconciliation-report',
  async (payload: { invoiceId: Number; userId: string }, thunkApi) => {
    try {
      const url = await getStatic(
        `civil/downloads/reports/reconciliation/${payload.invoiceId}/user/${payload.userId}`
      );
      return url as { url: URL };
    } catch (err) {
      const errorNotification: INotification = {
        status: 'error',
        message: 'Error: Unable to print Reconciliation Report.',
      };
      thunkApi.dispatch(pushNotification(errorNotification));
      throw err;
    }
  }
);
