import React, { useEffect, useContext } from 'react';
import { Typography, Button, Box } from '@mui/material';
import { MembersList } from './MembersList';
import { useSelector } from 'react-redux';
import { RootState, thunkDispatch } from 'store/store';
import { ModalContext } from 'sharedComponents/ModalContext';
import { getLicenses } from 'modules/civilAndTrucking/civil/Licenses/redux/actions';
import { getActiveCompanyUsers } from 'modules/civilAndTrucking/civil/Users/redux/actions';
import { AddUserForm as AddUserFormTrucking } from '../AddUserForm';
import { AddUserForm as AddUserFormCivil } from '../../../civil/Users/AddUserForm/AddUserForm';
import { Loading } from 'sharedComponents/Loading';
import { IUser } from 'lg-helpers/dist/shared/interfaces/IUser';
import { useHasAccess } from '../../../../../services/permissions';

const MembersSettings = ({ isCivilCompany }: { isCivilCompany: boolean }) => {
  const { handleModal } = useContext(ModalContext);

  const { hasAccess: canCreateUsers } = useHasAccess(['users:create']);
  const { hasAccess: canAddLicenses } = useHasAccess(['licenses:create']);
  const licenses = useSelector((state: RootState) => state.civil.licenses.data);

  const { innerWidth } = window;

  useEffect(() => {
    thunkDispatch(getLicenses());
    thunkDispatch(getActiveCompanyUsers());
  }, []);

  const showEditUserModal = (user?: IUser) => {
    if (isCivilCompany) {
      handleModal(<AddUserFormCivil editObj={user} />);
    } else {
      handleModal(<AddUserFormTrucking editObj={user} />);
    }
  };

  const licenseStatus = useSelector((state: RootState) => state.civil.licenses.status);

  return (
    <>
      <Typography color="neutral.main">Members</Typography>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        mb={2}
        maxWidth={innerWidth * 0.85}
      >
        {licenseStatus === 'pending' ? (
          <Loading height={20} />
        ) : (
          <Typography>
            {licenses ? (
              <>
                You currently are using{' '}
                <b>{licenses.licensesPurchased - licenses.licensesRemaining}</b> of{' '}
                <b>{licenses.licensesPurchased}</b> license.
              </>
            ) : (
              <>Company does not have a license</>
            )}
          </Typography>
        )}
        <Box>
          {canAddLicenses && (
            <></>
            /*  <Button className={clsx(classes.addLicenseButton)} disabled>
                     Add License
                   </Button> */
          )}

          {canCreateUsers && (
            <Button
              color="primary"
              sx={{
                alignSelf: 'flex-end',
              }}
              onClick={() => showEditUserModal()}
            >
              Invite Member
            </Button>
          )}
        </Box>
      </Box>
      <MembersList showEditUserModal={showEditUserModal} />
    </>
  );
};

export default MembersSettings;
