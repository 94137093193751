import { createSlice } from '@reduxjs/toolkit';
import { getJoinedTrucksByCompanyId, getJoinedTrucksByDivisionId, upsertTruck } from './actions';
import { RootState } from 'store/store';
import { IJoinTruck } from 'lg-helpers/dist/trucking/interfaces/IJoinTruck';
import { TFormStatus } from 'lg-helpers/dist/shared/types/TFormStatus';

export interface ITrucksState {
  dataByCompanies: IJoinTruck[];
  statusByCompanies: TFormStatus;
  dataByDivisions: IJoinTruck[];
  statusByDivisions: TFormStatus;
  status: TFormStatus;
}

const initialStateTrucks: ITrucksState = {
  dataByCompanies: [] as IJoinTruck[],
  statusByCompanies: null,
  dataByDivisions: [] as IJoinTruck[],
  statusByDivisions: null,
  status: null,
};

export const trucksStoreSlice = createSlice({
  name: 'trucks',
  reducers: {},
  initialState: initialStateTrucks,
  extraReducers: builder => {
    builder
      .addCase(upsertTruck.pending, state => {
        state.status = 'pending';
      })
      .addCase(upsertTruck.fulfilled, state => {
        state.status = 'success';
      })
      .addCase(upsertTruck.rejected, state => {
        state.status = 'failure';
      })
      .addCase(getJoinedTrucksByCompanyId.pending, state => {
        state.statusByCompanies = 'pending';
      })
      .addCase(getJoinedTrucksByCompanyId.fulfilled, (state, { payload }) => {
        state.statusByCompanies = 'success';
        state.dataByCompanies = payload as IJoinTruck[];
      })
      .addCase(getJoinedTrucksByCompanyId.rejected, (state, { error }) => {
        state.statusByCompanies = 'failure';
        state.dataByCompanies = [] as IJoinTruck[];
        console.error('getJoinedTrucksByCompanyId error: ', error);
      })
      .addCase(getJoinedTrucksByDivisionId.pending, state => {
        state.statusByDivisions = 'pending';
      })
      .addCase(getJoinedTrucksByDivisionId.fulfilled, (state, { payload }) => {
        state.statusByDivisions = 'success';
        state.dataByDivisions = payload as IJoinTruck[];
      })
      .addCase(getJoinedTrucksByDivisionId.rejected, (state, { error }) => {
        state.statusByDivisions = 'failure';
        state.dataByDivisions = [] as IJoinTruck[];
        console.error('getJoinedTrucksByDivisionId error: ', error);
      });
  },
});

export const selectJoinedTrucks = (state: RootState) => {
  return {
    joinedTrucksByCompanies: state.trucking.trucks.dataByCompanies,
    joinedTrucksStatusByCompanies: state.trucking.trucks.statusByCompanies,
    joinedTrucksByDivisions: state.trucking.trucks.dataByDivisions,
    joinedTrucksStatusByDivisions: state.trucking.trucks.statusByDivisions,
    joinedTrucksStatus: state.trucking.trucks.status,
  };
};
