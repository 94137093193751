import ApiBase from './ApiBase';
import Tables from './Tables';
import { GetApiLogEntriesOptions } from 'lg-helpers/dist/shared/interfaces/IManifestApiLog';

export default class ManifestApiLogsApi extends ApiBase {
  constructor() {
    super(Tables.ManifestApiLog);
  }

  async getByProjectId(props: GetApiLogEntriesOptions): Promise<any> {
    const token = await this.getUserToken();
    try {
      const response = await this.axios.post(`${Tables.ManifestApiLog}`, props, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return response.data;
    } catch (error) {
      console.error('getByProjectId ERROR', error);
    }
  }
}
