export const getOffset = (timeZone = 'UTC', date = new Date()) => {
  const utcDate = new Date(date.toLocaleString('en-US', { timeZone: 'UTC' }));
  const tzDate = new Date(date.toLocaleString('en-US', { timeZone }));
  return (tzDate.getTime() - utcDate.getTime()) / 6e4;
};

export const convertTimeToLocalDateTime = (time: string, timeZone: string): Date => {
  const currentDate = new Date();
  const [numericTime, ampm] = time.split(' ');
  const [hours, minutes] = numericTime.split(':');
  const localDate = new Date(currentDate.toLocaleString('en-US', { timeZone }));
  const localizedDateTime = new Date(
    localDate.setHours(Number(hours) + (ampm === 'PM' ? 12 : 0), Number(minutes), 0)
  );
  return localizedDateTime;
};

export const parseTimeString = (dateTimeString: Date, timeZone: string): string => { // eslint-disable-line
  const timeStringOptions: Intl.DateTimeFormatOptions = {
    // timeZone,
    hour: '2-digit',
    minute: '2-digit',
    hour12: false,
  };

  const dateString = new Date(dateTimeString).toLocaleTimeString('en-US', timeStringOptions);
  return dateString;
};

export const getUtcStartOfDay = (date: string, timeZone: string) => {
  const newStart = date + 'T00:00:00.000+00:00';
  const utcStart = new Date(
    new Date(newStart).getTime() - getOffset(timeZone, new Date(date)) * 60000
  );
  return utcStart;
};

export const getUtcEndOfDay = (date: string, timeZone: string) => {
  const newEnd = date + 'T23:59:59.000+00:00';
  const utcEnd = new Date(new Date(newEnd).getTime() - getOffset(timeZone, new Date(date)) * 60000);
  return utcEnd;
};
