import { boolean, date, number, object, string } from 'yup';
import moment from 'moment';
import { ICivilTicketFormWithDateValidation } from './AddTicketForm';

export const ticketSchema = object<ICivilTicketFormWithDateValidation>({
  projectId: number().required('Required').typeError('Required'),
  activityId: number().required('Required').typeError('Required'),
  projectMaterialId: number().required('Required').typeError('Required'),
  transporterId: string().required('Required').typeError('Required'),
  volume: number().nullable(),
  grossQuantity: number().nullable(),
  tareQuantity: number().nullable(),
  quantity: number().required('Required'),
  equipment: string().nullable(),
  needsTicketNumber: boolean(),
  ticketNumber: number().when('needsTicketNumber', {
    is: true,
    then: number().required('Required').positive().typeError('Must be a number'),
    otherwise: number().nullable(),
  }),
  brokerCarrier: string().nullable(),
  truckNumber: string().required('Required').typeError('Required'),
  manifestSearchValue: string().nullable(),
  pitTicketNumber: string().nullable(),
  isAccepted: boolean(),
  projectStart: date().notRequired(),
  projectEnd: date().notRequired(),
  dateCreated: date()
    .required()
    .typeError('Required')
    // need custom whens because date.min() and .max() are exclusive ranges
    .when('projectStart', (projectStart, schema) => {
      if (!projectStart) return schema;
      const dayBefore = moment(projectStart).subtract(1, 'days').toDate();
      return schema.min(dayBefore, 'Before Project Start Date');
    })
    .when('projectEnd', (projectEnd, schema) => {
      if (!projectEnd) return schema;
      const dayAfter = moment(projectEnd).add(1, 'days').toDate();
      return schema.max(dayAfter, 'After Project End Date');
    }),
});
