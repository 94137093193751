import {
  configureStore,
  createSerializableStateInvariantMiddleware,
  ThunkDispatch,
  AnyAction,
} from '@reduxjs/toolkit';
import thunk from 'redux-thunk';

import authStoreSlice from 'modules/auth/storeSliceAuth';
import brokersStoreSlice from 'modules/brokers/storeSliceBrokers';
import civilStoreSlice from 'modules/civilAndTrucking/civil/redux/reducer';
import driversStoreSlice from 'modules/transporters/storeSliceTransporters';
import errorsStoreSlice from 'modules/errors/storeSliceErrors';
import successesStoreSlice from 'modules/successMessage/storeSliceSuccesses';
import manifestViewStoreSlice from 'redux/storeSliceManifest';
import notificationsStoreSlice from 'sharedComponents/notificationStoreSlice';
import scalesStoreSlice from 'modules/scales/storeSliceScales';
import superAdminCompaniesStoreSlice from 'modules/superAdmins/superAdminCompaniesStoreSlice';
import superAdminUsersStoreSlice from 'modules/superAdmins/superAdminUsersStoreSlice';
import truckingStoreSlice from 'modules/civilAndTrucking/trucking/redux/reducer';

const serializableMiddleware = createSerializableStateInvariantMiddleware({
  isSerializable: () => true,
  getEntries: value => Object.entries(value),
});

export const store = configureStore({
  middleware: [serializableMiddleware, thunk],
  reducer: {
    auth: authStoreSlice,
    brokers: brokersStoreSlice,
    civil: civilStoreSlice,
    drivers: driversStoreSlice,
    errors: errorsStoreSlice,
    successes: successesStoreSlice,
    manifestView: manifestViewStoreSlice,
    notifications: notificationsStoreSlice,
    scales: scalesStoreSlice,
    superAdminCompanies: superAdminCompaniesStoreSlice,
    superAdminUsers: superAdminUsersStoreSlice,
    trucking: truckingStoreSlice,
  },
});

type MyThunkDispatch = ThunkDispatch<{}, {}, AnyAction>;
export const thunkDispatch = store.dispatch as MyThunkDispatch;

export type RootState = ReturnType<typeof store.getState>;

export default store;
