import { useState, useEffect } from 'react';
import Query from 'services/firebase/Query';
import Executer from 'services/firebase/Executer';
import useIsUserOfType from 'services/hooks/useIsUserOfType';
import useShowError from 'modules/errors';
import { usePermissionsContext } from 'services/permissions';

const query = new Query();
const executer = new Executer();

export interface Load {
  id: string;
  [key: string]: any;
} // TODO: define this type (located in firebase)

const useGetDriverCurrentLoad = () => {
  const { user } = usePermissionsContext();
  const isUserBrokerDriverOrAdmin = useIsUserOfType('trucking_driver', 'trucking_dispatcher');
  const [currentLoad, setCurrentLoad] = useState<Load | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const showError = useShowError();

  useEffect(() => {
    if (!isUserBrokerDriverOrAdmin) {
      return;
    }

    return executer.watchSingleDocument(
      query.brokers.brokerGetDriverCurrentLoad(user),
      load => {
        setCurrentLoad(load as Load);
        setIsLoading(false);
      },
      error => {
        console.error('Error', error);
        setIsLoading(false);
        showError({ title: 'Error loading current Load', duration: 10000 });
      }
    );
    // eslint-disable-next-line
  }, [user, isUserBrokerDriverOrAdmin, setIsLoading, setCurrentLoad]);

  return { currentLoad, isLoading };
};

export default useGetDriverCurrentLoad;
