import moment from 'moment';

function checkValidDateStr(str: string) {
  return str.match(/^\d{4}-\d{2}-\d{2}$/) !== null;
}

export const truckingDateFormat = 'YYYY-MM-DD';

export function toDateStr(date: Date | moment.Moment | string): string {
  if (typeof date === 'string') {
    if (checkValidDateStr(date)) {
      return date;
    } else {
      throw new Error(`Invalid date string: ${date}`);
    }
  } else {
    const dateString = moment(date).format('YYYY-MM-DD');
    if (checkValidDateStr(dateString)) {
      return dateString;
    }
  }
  console.error('Invalid date provided');
  throw new Error(`Shouldn't get here (invalid toDateStr provided): ${date}`);
}
