import React, { useState, useContext, useMemo } from 'react';
import {
  DialogContent,
  Grid,
  FormControl,
  InputLabel,
  TextField,
  FormHelperText,
  RadioGroup,
  FormControlLabel,
  Radio,
  MenuItem,
  Select,
  SelectChangeEvent,
} from '@mui/material';
import ModalHeader from 'sharedComponents/ModalHeader';
import { ModalContext } from 'sharedComponents/ModalContext';
import { useSelector } from 'react-redux';
import { thunkDispatch } from 'store/store';
import { IHelpers } from 'lg-helpers/dist/trucking/interfaces/IHelpers';
import { addUser, editUser } from 'modules/civilAndTrucking/civil/Users/redux/actions';
import DialogActionContainer from 'sharedComponents/DialogActionContainer';
import { RootState } from 'store/store';
import { ValidationError } from 'yup';
import userSchema from 'modules/civilAndTrucking/trucking/schemas/UserSchema';
import InputMasker from 'sharedComponents/InputMasker';
import { UserTypes } from 'lg-helpers/dist/constants/user/UserTypes';
import { ICompany } from 'lg-helpers/dist/shared/interfaces/ICompany';
import { IUser } from 'lg-helpers/dist/shared/interfaces/IUser';
import Query from 'services/firebase/Query';
import Executer from 'services/firebase/Executer';
import Command from 'services/firebase/Command';
import { INotification } from 'modules/civilAndTrucking/shared/NotificationSnackbar/INotification';
import { pushNotification } from 'modules/civilAndTrucking/shared/NotificationSnackbar/redux/actions';
import { ViewerAccessCompany } from 'lg-helpers/dist/shared/interfaces/IUser';
import { IUserCreation } from 'lg-helpers/dist/shared/interfaces/IUserCreation';
import UserNotificationAdminPanel from 'sharedComponents/UserNotificationAdminPanel';
import useApiGetRequest from 'services/api/useApiGetRequest';
import api, { ConnectedCompanyType } from 'services/api/autogenerated';

const executer = new Executer();
const query = new Query();
const command = new Command();
interface ModalContainerProps {
  editObj?: IUserCreation;
}

async function checkUserEmail(email: string) {
  const userEmail = (email ?? '').trim().toLowerCase();
  if (userEmail) {
    try {
      const userExists = (await executer.getSingleDocument(
        query.users.getUserByEmail(userEmail)
      )) as IUserCreation;
      return userExists;
    } catch (error) {
      console.error('Error', error);
      return null;
    }
  }
}

// TODO: Make only 1 user form
export const AddUserForm = ({ editObj }: ModalContainerProps) => {
  const [member, setMember] = useState(editObj || ({} as IUserCreation));
  const [helpers, setHelpers] = useState<IHelpers>({});
  const [loading, setLoading] = useState(false);
  const { handleModal } = useContext(ModalContext);
  const activeCompany: ICompany | undefined = useSelector(
    (state: RootState) => state.civil.companies.activeCompany
  );

  // TODO: this list could be large (optimize in future)
  // I don't want to implement autocomplete search here
  // because we need to get rid of this component
  const [contractorOptions] = useApiGetRequest(
    () =>
      api.companiesConnections.getConnectedCompaniesByTypes([
        ConnectedCompanyType.Contractor,
        ConnectedCompanyType.TruckingContractor,
      ]),
    () => ({ title: 'Failed to load contractors' })
  );
  const contractorCompanies = useMemo(
    () => (contractorOptions || []).map(contractor => contractor.connected_company),
    [contractorOptions]
  );

  const onSubmit = async () => {
    const { id, name, types } = activeCompany || {};
    const existingUser = await checkUserEmail(member?.email ?? '');

    const userReq =
      existingUser ??
      ({
        ...member,
        generator: false,
        companies: [{ id, name, types }],
        companiesIds: [activeCompany?.id],
        actingAsType: member.type,
        allowedUserTypes: [member.type],
        viewerAccessCompanies: member.viewerAccessCompanies,
      } as IUser);

    const isValid = await userSchema.isValid(userReq);
    if (!isValid) {
      handleInvalidSubmit();
    } else {
      try {
        setLoading(true);

        const res =
          (await member.type) === 'Trucking Viewer' && existingUser && activeCompany
            ? command.createViewerUserRequestNotification(
                existingUser?.id as string,
                member.viewerAccessCompanies,
                activeCompany
              )
            : editObj || existingUser
            ? thunkDispatch(editUser(userReq as IUser))
            : thunkDispatch(addUser(userReq as IUser));
        if ((res as any).error) return;
        if (member.type === 'Trucking Viewer' && existingUser && activeCompany) {
          const successNotification: INotification = {
            status: 'success',
            message: 'User has been notified about the request',
          };
          thunkDispatch(pushNotification(successNotification));
        }
        handleModal();
      } catch (err) {
        console.error('Submitting err', err);
      } finally {
        setLoading(false);
      }
    }
  };

  const handleInvalidSubmit = () => {
    userSchema.validate(member, { abortEarly: false }).catch((valErrors: ValidationError) => {
      const help = {} as IHelpers;
      valErrors.inner.forEach(err => {
        help[err.path] = err.message;
      });
      setHelpers(help);
    });
  };

  const generateUserTypes = useMemo(
    () =>
      activeCompany?.types.includes('broker')
        ? [
            UserTypes.broker.trucking.driver,
            UserTypes.broker.trucking.dispatcher,
            UserTypes.broker.trucking.viewer,
          ]
        : [
            UserTypes.truckingBroker.truckingBrokerDriver,
            UserTypes.truckingBroker.truckingBrokerDispatcher,
          ],
    [activeCompany]
  );

  const handleChangeType = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setMember({
      ...member,
      [name]: value,
      viewerAccessCompanies: member?.allowedUserTypes?.includes('Trucking Viewer')
        ? member?.viewerAccessCompanies || []
        : [],
    });
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setMember({ ...member, [name]: value });
  };

  const handleChangeAccessCompany = (e: SelectChangeEvent<string>) => {
    const updatedInviterCompanyAccessCompany = contractorCompanies.find(
      contractor => e.target.value === contractor?.id
    );

    const updatedInviterContractorCompanyAccess = {
      inviterCompanyId: activeCompany?.id,
      inviterCompanyAccessCompany: updatedInviterCompanyAccessCompany,
      inviterCompanyAccessCompanyId: updatedInviterCompanyAccessCompany?.id,
    } as ViewerAccessCompany;

    const updatedViewerCompanyAccess = [
      ...(member?.viewerAccessCompanies?.filter(
        viewerAccessCompany => viewerAccessCompany?.inviterCompanyId !== activeCompany?.id
      ) || []),
      updatedInviterContractorCompanyAccess,
    ];

    setMember({
      ...member,
      viewerAccessCompanies: updatedViewerCompanyAccess,
    });
  };

  return (
    <>
      <ModalHeader title={`${editObj ? 'Update' : 'Add'} User`} />
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item md={6} xs={12}>
            <FormControl fullWidth>
              <InputLabel htmlFor="email" shrink>
                Email
              </InputLabel>
              <TextField
                disabled={!!member.id}
                required
                name="email"
                value={member?.email || ''}
                error={!!helpers.email}
                helperText={helpers.email}
                onChange={handleChange}
              />
              <FormHelperText />
            </FormControl>
          </Grid>
          <Grid item md={6} xs={12}>
            <FormControl fullWidth>
              <InputLabel htmlFor="phone" shrink>
                Phone
              </InputLabel>
              <InputMasker
                mask="+############"
                variant="outlined"
                val={member?.phone || ''}
                error={!!helpers.phone}
                helperText={helpers.phone}
                onChange={(number: any) => setMember({ ...member, phone: String(number) })}
              />
              <FormHelperText />
            </FormControl>
          </Grid>
          <Grid item md={12} xs={12}>
            <FormControl error={!!helpers.preferredLoginMethod}>
              <InputLabel htmlFor="preferredLoginMethod" shrink>
                Preferred login method
              </InputLabel>
              <RadioGroup
                row
                aria-label="preferred login method"
                name="preferredLoginMethod"
                value={member?.preferredLoginMethod || ''}
                onChange={handleChange}
              >
                <FormControlLabel value="email" control={<Radio color="success" />} label="Email" />
                <FormControlLabel value="phone" control={<Radio color="success" />} label="Phone" />
              </RadioGroup>
            </FormControl>
          </Grid>

          <Grid item md={member.type === 'Trucking Viewer' ? 6 : 12} xs={12}>
            <FormControl fullWidth>
              <InputLabel htmlFor="type" shrink>
                Type
              </InputLabel>
              <TextField
                required
                select
                name="type"
                value={member?.type || ''}
                error={!!helpers.type}
                helperText={helpers.type}
                onChange={handleChangeType}
              >
                {generateUserTypes.map((type, i) => (
                  <MenuItem key={`${type}_${i}`} value={type}>
                    {type}
                  </MenuItem>
                ))}
              </TextField>
              <FormHelperText />
            </FormControl>
          </Grid>
          {member.type === 'Trucking Viewer' && (
            <Grid item md={6} xs={12}>
              <FormControl fullWidth>
                <InputLabel htmlFor="contractorCompanyId" sx={{ mb: 5 }}>
                  Contractor
                </InputLabel>
                <Select
                  sx={{ top: 16 }}
                  required
                  value={
                    member?.viewerAccessCompanies?.find(
                      (accessCompany: ViewerAccessCompany) =>
                        accessCompany?.inviterCompanyId === activeCompany?.id
                    )?.inviterCompanyAccessCompanyId || ''
                  }
                  onChange={handleChangeAccessCompany}
                >
                  {contractorCompanies.map((contractor, index) => (
                    <MenuItem key={index} value={contractor.id}>
                      {contractor?.name}
                    </MenuItem>
                  ))}
                </Select>
                <FormHelperText />
              </FormControl>
            </Grid>
          )}
          <Grid item md={6} xs={12}>
            <FormControl fullWidth>
              <InputLabel htmlFor="firstName" shrink>
                First Name
              </InputLabel>
              <TextField
                required
                name="firstName"
                value={member?.firstName || ''}
                error={!!helpers.firstName}
                helperText={helpers.firstName}
                onChange={handleChange}
              />
              <FormHelperText />
            </FormControl>
          </Grid>
          <Grid item md={6} xs={12}>
            <FormControl fullWidth>
              <InputLabel htmlFor="lastName" shrink>
                Last Name
              </InputLabel>
              <TextField
                required
                name="lastName"
                value={member?.lastName || ''}
                error={!!helpers.lastName}
                helperText={helpers.lastName}
                onChange={handleChange}
              />
              <FormHelperText />
            </FormControl>
          </Grid>
          <Grid item md={12} xs={12}>
            <FormControl fullWidth>
              <InputLabel htmlFor="customId" shrink>
                Driver Id
              </InputLabel>
              <TextField
                required
                name="customId"
                value={member?.customId || ''}
                error={!!helpers.customId}
                helperText={helpers.customId}
                onChange={handleChange}
              />
              <FormHelperText />
            </FormControl>
          </Grid>
          <UserNotificationAdminPanel userData={member} />
        </Grid>
        <DialogActionContainer isSaving={loading} saveHandler={onSubmit} editing={!!editObj} />
      </DialogContent>
    </>
  );
};
