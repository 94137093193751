import React from 'react';
import { createSvgIcon } from '@mui/material';

export const Action = createSvgIcon(
  <path
    fillRule="evenodd"
    clipRule="evenodd"
    d="M9.99992.16663c-4.16667 0-7.725 2.59166-9.16667 6.25 1.44167 3.65837 5 6.24997 9.16667 6.24997 4.16668 0 7.72498-2.5916 9.16668-6.24997-1.4417-3.65834-5-6.25-9.16668-6.25Zm0 10.41667c-2.3 0-4.16667-1.86667-4.16667-4.16667s1.86667-4.16667 4.16667-4.16667c2.29998 0 4.16668 1.86667 4.16668 4.16667s-1.8667 4.16667-4.16668 4.16667Zm0-6.66667c-1.38333 0-2.5 1.11666-2.5 2.5 0 1.38333 1.11667 2.5 2.5 2.5 1.38338 0 2.49998-1.11667 2.49998-2.5 0-1.38334-1.1166-2.5-2.49998-2.5Z"
  />,
  'Action'
);
