import { locationSlice } from '../features/Location/redux/locationStoreSlice';
import { combineReducers } from '@reduxjs/toolkit';
import { dispatchesSlice } from '../features/Dispatches/redux/dispatchStoreSlice';
import { dispatchToolbarSlice } from '../features/Dispatches/redux/dispatchesToolbarStoreSlice';
import { projectMaterialsSlice } from '../features/ProjectMaterials/redux/projectMaterialsStoreSlice';
import { companyMaterialsSlice } from '../features/CompanyMaterials/redux/companyMaterialsStoreSlice';
import { projectsStoreSlice } from '../features/Projects/redux/projectsStoreSlice';
import { loadsStoreSlice } from '../features/Loads/redux/loadsStoreSlice';
import { ticketsStoreSlice } from '../features/Tickets/redux/ticketsStoreSlice';
import { trucksStoreSlice } from '../features/Trucks/redux/trucksStoreSlice';
import { divisionsStoreSlice } from '../features/Divisions/redux/divisionsStoreSlice';
import { truckTypesSlice } from '../features/TruckTypes/redux/truckTypesStoreSlice';
import { companiesSlice } from '../features/Companies/redux/companiesStoreSlice';
import { dataImportStoreSlice } from '../features/DataImport/redux/dataImportStoreSlice';

const truckingReducer = combineReducers({
  dispatchesToolbar: dispatchToolbarSlice.reducer,
  dispatches: dispatchesSlice.reducer,
  projectMaterials: projectMaterialsSlice.reducer,
  projects: projectsStoreSlice.reducer,
  truckTypes: truckTypesSlice.reducer,
  companyMaterials: companyMaterialsSlice.reducer,
  tickets: ticketsStoreSlice.reducer,
  trucks: trucksStoreSlice.reducer,
  divisions: divisionsStoreSlice.reducer,
  loads: loadsStoreSlice.reducer,
  companies: companiesSlice.reducer,
  location: locationSlice.reducer,
  dataImport: dataImportStoreSlice.reducer,
});

export default truckingReducer;
