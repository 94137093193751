import React, { useState, useEffect } from 'react';
import Fuse from 'fuse.js';
import CrudTable from 'sharedComponents/CrudTable';
import { useNavigate } from 'react-router-dom';
import ButtonLink from 'sharedComponents/ButtonLink';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import NotInterestedIcon from '@mui/icons-material/NotInterested';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ReportIcon from '@mui/icons-material/Report';
import useShowError from 'modules/errors';
import { componentAllowedPerms, usePermissionsContext } from 'services/permissions';
import Query from 'services/firebase/Query';
import Executer from 'services/firebase/Executer';

const executer = new Executer();
const query = new Query();

const projectColumns = [
  {
    path: 'profileNr',
    label: 'Profile #',
  },
  { path: 'name', label: 'Name' },
  {
    path: 'isActive',
    label: 'Active',
    getCellValueCallback: row => {
      if (!row.isActiveCustomApi) {
        return <ReportIcon color="error" />;
      }

      if (row.isActive) {
        return <CheckCircleIcon color="primary" />;
      }

      return <NotInterestedIcon color="error" />;
    },
  },
  {
    path: '',
    label: 'Map',
    hasCustomClickHandler: true,
    getCellValueCallback: row => (
      <ButtonLink
        url={`/projects/${row.id}/map`}
        data={row}
        Icon={<LocationOnIcon fontSize="small" />}
      />
    ),
  },
];

const ProjectsViewViewer = componentAllowedPerms(['projects:read'])(() => {
  const { user } = usePermissionsContext();
  const navigate = useNavigate();
  const showError = useShowError();
  const [searchfilter, setSearchFilter] = useState('');
  const [archiveFilter, setArchiveFilter] = useState(false);
  const [projects, setProjects] = useState([]);
  const [filteredProjects, setFilteredProjects] = useState([]);
  const [isLoadingData, setIsLoadingData] = useState(true);
  const [errorLoadingData, setErrorLoadingData] = useState(null);

  useEffect(() => {
    if (!user) {
      return;
    }

    const getProjects = async companyId => {
      setIsLoadingData(true);
      try {
        const projectsResults = await executer.getMultipleDocuments(
          query.projects.getProjectsActiveForViewerCompany(companyId)
        );
        setProjects(projectsResults);
      } catch (error) {
        console.error('Error', error);
        const errorText = 'Error while fetching the projects';
        setErrorLoadingData(errorText);
        showError({
          title: errorText,
          duration: 10000,
        });
      }
      setIsLoadingData(false);
    };
    getProjects(user.companies[0].id);
    // eslint-disable-next-line
  }, [user]);

  useEffect(() => {
    const archiveFilteredProjects = projects.filter(
      proj => (archiveFilter && proj.isArchived) || (!archiveFilter && !proj.isArchived)
    );

    if (!searchfilter) {
      setFilteredProjects(archiveFilteredProjects);
      return;
    }

    const fuse = new Fuse(archiveFilteredProjects, {
      includeScore: true,
      threshold: 0.5,
      keys: ['name', 'profileNr'],
    });
    const fuseResults = fuse.search(searchfilter).map(result => result.item);
    setFilteredProjects(fuseResults);
  }, [projects, searchfilter, archiveFilter]);

  return (
    <CrudTable
      rows={filteredProjects}
      columns={projectColumns}
      isLoading={isLoadingData}
      errorText={errorLoadingData}
      handleRowClickCallback={project => navigate(`/projects/${project.id}`)}
      searchFilter={searchfilter}
      setSearchFilterCallback={setSearchFilter}
      handleClickArchivedCallback={() => setArchiveFilter(!archiveFilter)}
      archiveFilter={archiveFilter}
      showReportButton
      showBatchedManifestButton
    ></CrudTable>
  );
});

export default ProjectsViewViewer;
