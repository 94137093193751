import React, { Fragment, useState, useEffect } from 'react';
import 'sass/globals.scss';
import { Container, Box, Typography } from '@mui/material';
import Page from 'sharedComponents/Page';
import { useSelector, useDispatch } from 'react-redux';
import { selectAuthUser } from 'modules/auth/storeSliceAuth';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import ManifestsViewPerScale from 'sharedComponents/ManifestsViewPerScale';
import { setSelectedManifest, selectScale } from 'modules/scales/storeSliceScales';
import { getManifestDefaultColumns } from 'utils/manifestUtil';
// import AvTimerIcon from '@mui/icons-material/AvTimer';
import { UserTypes } from 'lg-helpers/dist/constants/user/UserTypes';
import HtmlTooltip from 'sharedComponents/HtmlTooltip';
import RejectionNote from './RejectionNote';

import Command from 'services/firebase/Command';
import PropTypes from 'prop-types';
const command = new Command();

let manifestColumns = getManifestDefaultColumns(true, true, false, true, true);

// const formatRemainingTime = seconds => {
//   let minutes = Math.floor(seconds / 60);
//   let hours = Math.floor(minutes / 60);
//   let days = Math.floor(hours / 24);

//   minutes -= hours * 60;
//   hours -= days * 24;

//   let formatted = '';
//   if (days > 0) {
//     formatted = `${formatted} ${days}${days > 1 ? 'days' : 'day'}`;
//   }
//   if (hours > 0) {
//     formatted = `${formatted} ${hours}${hours > 1 ? 'hrs' : 'hr'}`;
//   }
//   if (minutes > 0) {
//     formatted = `${formatted} ${minutes}min`;
//   }

//   return formatted;
// };

const manifColumn = {
  path: 'number',
  label: 'Manifest#',
  isFilterable: false,
  hasCustomClickHandler: true,
  getCellValueCallback: row => {
    if (row?.signatureDriver?.isReleasedManually && !row?.signatureScale?.isSigned) {
      const releaseDate = row?.signatureDriver?.releasedManuallyAt
        ? row.signatureDriver.releasedManuallyAt.toDate()
        : '';
      const passcode = row?.signatureDriver?.releasePasscode || '';
      return (
        <HtmlTooltip
          placement="top"
          title={
            <Fragment>
              <Typography align="center">
                {`Released manually with passcode ${passcode} at ${releaseDate}`}
              </Typography>
            </Fragment>
          }
        >
          <Typography variant="body2" align="center" color="secondary">
            {row.number}
          </Typography>
        </HtmlTooltip>
      );
    } else {
      return row.number;
    }
  },
};

// Comment for now in case we gonna have to return eta to table

// const eta = {
//   path: 'signatureDriver.eta',
//   label: 'ETA',
//   align: 'center',
//   getCellValueCallback: row => {
//     let distanceRemaining = null,
//       durationRemaining = null,
//       lastDistanceDurationTime = null;
//     if (row.signatureDriver?.eta) {
//       if (row.signatureDriver.eta?.driverDistanceToScale) {
//         distanceRemaining = row.signatureDriver.eta.driverDistanceToScale;
//         distanceRemaining = (distanceRemaining / 1609.34).toFixed(1);
//       }
//       if (row.signatureDriver.eta?.driverDurationToScale) {
//         durationRemaining = row.signatureDriver.eta.driverDurationToScale;
//         durationRemaining = formatRemainingTime(durationRemaining);
//       }
//       if (row.signatureDriver.eta?.driverLastDistanceDurationTime) {
//         lastDistanceDurationTime =
//           row.signatureDriver.eta.driverLastDistanceDurationTime.toDate();
//         lastDistanceDurationTime = moment.tz(
//           lastDistanceDurationTime,
//           row.project.timeZone || 'EST'
//         );
//       }
//     }
//     return (
//       <HtmlTooltip
//         placement="top"
//         title={
//           !distanceRemaining &&
//           !durationRemaining &&
//           !lastDistanceDurationTime ? (
//             <Fragment>
//               <Typography align="center">N/A</Typography>
//             </Fragment>
//           ) : (
//             <Fragment>
//               <Typography align="center">
//                 {distanceRemaining
//                   ? `Distance: ${distanceRemaining}miles`
//                   : null}
//               </Typography>
//               <Typography align="center">
//                 {durationRemaining ? `ETA: ${durationRemaining}` : null}
//               </Typography>
//               <Typography align="center">
//                 {lastDistanceDurationTime
//                   ? `UpdatedAt: ${lastDistanceDurationTime}`
//                   : null}
//               </Typography>
//             </Fragment>
//           )
//         }
//       >
//         <AvTimerIcon
//           fontSize="large"
//           style={{ color: '#3a6079', marginLeft: '12px' }}
//         />
//       </HtmlTooltip>
//     );
//   },
// };

const IncomingManifestsView = () => {
  const authUser = useSelector(selectAuthUser);
  const scale = useSelector(selectScale);
  const [columns, setColumns] = useState(manifestColumns);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [manifestIdForRejection, setManifestIdForRejection] = useState(null);

  const handleReject = note => {
    if (!note || !authUser) {
      return;
    }
    command.manifests.rejectManifest(authUser, manifestIdForRejection, note);
    setManifestIdForRejection(null);
  };

  // const rejectColumn = {
  //   path: 'id',
  //   label: 'Reject',
  //   align: 'center',
  //   hasCustomClickHandler: true,
  //   getCellValueCallback: row => {
  //     return (
  //       <Button
  //         variant="outlined"
  //         color="secondary"
  //         onClick={() => setManifestIdForRejection(row.id)}
  //       >
  //         Reject
  //       </Button>
  //     );
  //   },
  // };

  useEffect(() => {
    if (!authUser) {
      return;
    }

    // todo: Not added until feature complete
    // if (false) manifestColumns.push(rejectColumn);
    if (
      authUser.actingAsType === UserTypes.scale.attendant ||
      authUser.actingAsType === UserTypes.scale.admin
    ) {
      let col = [...manifestColumns];
      const idx = col.findIndex(c => c.path === 'number');
      if (idx >= 0) col[idx] = manifColumn; // replace default manifest number column
      setColumns(col);
    } else {
      setColumns([...manifestColumns]);
    }

    if (!authUser.selectedScaleId) {
      navigate('/scales/account');
    }
    // eslint-disable-next-line
  }, [authUser, navigate]);

  return (
    <Page className="min-padding-and-height" title="Incoming Manifests">
      <Container maxWidth={false}>
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          marginBottom={4}
          position="relative"
        >
          <Typography variant="h3">Incoming Manifests</Typography>
          {scale?.isOffHours && (
            <Box
              position="absolute"
              top={0}
              right={0}
              borderRadius="4px"
              borderColor="red"
              border="1px solid"
              paddingX="4px"
              paddingY="2px"
            >
              <Typography color="error">Unattended loads allowed!</Typography>
            </Box>
          )}
        </Box>
        {authUser && authUser.selectedScaleId ? (
          <ManifestsViewPerScale
            showOnlyAttendantsScale={false}
            manifestColumns={columns}
            showOnlyIncoming={true}
            showClaimed={true}
            showOnlyRejected={false}
            authUser={authUser}
            scaleId={authUser.selectedScaleId}
            defaultFilterIsSigned={true}
            defaultFilterIsUsedBetween={[
              moment().startOf('day').toDate(),
              moment().endOf('day').toDate(),
            ]}
            handleRowClickCallback={manifest => {
              dispatch(setSelectedManifest(manifest));
              if (!manifest.signatureDriver?.isSigned) {
                navigate('/scales/driver-sign');
              } else {
                navigate('/scales/verify-manifest');
              }
            }}
            showBatchTrailerNumbers={true}
          />
        ) : (
          <div />
        )}
      </Container>
      <RejectionNote
        visible={manifestIdForRejection !== null}
        onClose={() => {
          setManifestIdForRejection(null);
        }}
        onReject={handleReject}
      />
    </Page>
  );
};

IncomingManifestsView.propTypes = {
  showOnlyAttendantsScale: PropTypes.bool,
};

export default IncomingManifestsView;
