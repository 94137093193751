import React, { useEffect, useState, useCallback } from 'react';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import Fuse from 'fuse.js';
import { UserType, UserTypes } from 'lg-helpers/dist/constants/user/UserTypes';
import { selectAuthUser } from 'modules/auth/storeSliceAuth';
import { useSelector } from 'react-redux';
import useShowError from 'modules/errors';
import Query from 'services/firebase/Query';
import Executer from 'services/firebase/Executer';
import { Grid } from '@mui/material';
import { IUser } from 'lg-helpers/dist/shared/interfaces/IUser';
import { ICompany } from 'services/api/autogenerated-code';
import InputSearch from './InputSearch';

const executer = new Executer();
const query = new Query();

const columns: GridColDef[] = [
  {
    field: 'firstName',
    headerName: 'First Name',
    minWidth: 120,
    flex: 1,
  },
  {
    field: 'lastName',
    headerName: 'Last Name',
    minWidth: 120,
    flex: 1,
  },
  { field: 'email', headerName: 'Email', minWidth: 120, flex: 1 },
  { field: 'phone', headerName: 'Phone', minWidth: 120, flex: 1 },
];

export interface DriversAssignToProject {
  setSelectedUsersIdsCallback: (assignedIds: string[], driverUserIdsInCompany: string[]) => void;
  selectedUsersIds: string[];
}
// push
const DriversAssignToProject = ({
  selectedUsersIds,
  setSelectedUsersIdsCallback,
}: DriversAssignToProject) => {
  const authUser = useSelector(selectAuthUser);
  const [company, setCompany] = useState<ICompany | null>(null);
  const showError = useShowError();
  const [users, setUsers] = useState<IUser[]>([]);
  const [filteredUsers, setFilteredUsers] = useState<IUser[]>([]);
  const [usersSearchFilter, setUsersSearchFilter] = useState('');
  const [selectionModel, setSelectionModel] = useState<string[]>(selectedUsersIds);
  const [hasConfirmZeroDrivers, setHasConfirmZeroDrivers] = useState(false);

  useEffect(() => {
    if (!authUser || !authUser.companies) {
      return;
    }
    setCompany(authUser.companies[0]);
  }, [authUser]);

  useEffect(() => {
    setSelectionModel(selectedUsersIds);
  }, [selectedUsersIds]);

  useEffect(() => {
    if (!company) {
      return;
    }

    const getUsers = async (companyId: string, types: UserType[]) => {
      try {
        const usersResults = (await executer.getMultipleDocuments(
          query.users.getUsersInCompanyOfAnyTypes(companyId, types)
        )) as IUser[];
        setUsers(usersResults as IUser[]);
      } catch (error) {
        console.error(error);
        showError({
          title: 'Error while fetching the users',
          duration: 10000,
        });
      }
    };

    setUsers([]);
    getUsers(company?.id as string, [UserTypes.transporter.driver]);
  }, [company, showError]);

  useEffect(() => {
    if (!usersSearchFilter) {
      setFilteredUsers(users);
      return;
    }

    const options = {
      includeScore: true,
      threshold: 0.5,
      keys: ['firstName', 'lastName', 'email', 'type'],
    };

    const fuse = new Fuse(users, options);
    const fuseResults = fuse.search(usersSearchFilter).map(result => result.item);
    setFilteredUsers(fuseResults);
  }, [users, usersSearchFilter]);

  const handleSetSelectedUserIds = useCallback(
    (assignedDriversIds: string[]) => {
      const driverUserIdsInCompany = users.map(user => user.id);
      if (!assignedDriversIds.length && !hasConfirmZeroDrivers) {
        const confirmZeroDrivers = window.confirm('Are you sure you want uncheck all?');
        setHasConfirmZeroDrivers(confirmZeroDrivers);
        if (!confirmZeroDrivers) {
          return;
        }
      }
      if (assignedDriversIds.length) {
        setHasConfirmZeroDrivers(false);
      }
      setSelectionModel(assignedDriversIds);
      setSelectedUsersIdsCallback(assignedDriversIds, driverUserIdsInCompany as string[]);
    },
    [
      setSelectedUsersIdsCallback,
      setSelectionModel,
      users,
      setHasConfirmZeroDrivers,
      hasConfirmZeroDrivers,
    ]
  );

  const onSearchFilterChange = useCallback(
    (event: any) => {
      setUsersSearchFilter(event.target.value);
    },
    [setUsersSearchFilter]
  );

  return (
    <Grid container spacing={2}>
      <Grid item sm={4} xs={12}>
        <InputSearch
          placeholder="Search"
          value={usersSearchFilter}
          onChange={onSearchFilterChange}
        />
      </Grid>
      <Grid item xs={12}>
        <DataGrid
          checkboxSelection
          columns={columns}
          rows={filteredUsers || []}
          showColumnRightBorder={false}
          disableColumnMenu
          autoHeight
          components={{
            ColumnResizeIcon: () => <div></div>,
          }}
          onSelectionModelChange={(newSelectionModel: any) => {
            handleSetSelectedUserIds(newSelectionModel);
          }}
          selectionModel={selectionModel}
          keepNonExistentRowsSelected
        />
      </Grid>
    </Grid>
  );
};

export default DriversAssignToProject;
