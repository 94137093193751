import React, { useState, useEffect } from 'react';
import api, { CompanyFeatureFlagDefinitions } from 'services/api/autogenerated';
import { DataGrid, GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import { Box, Button, TextField } from '@mui/material';

const FeatureFlagDefinition = () => {
  const [definitions, setDefinitions] = useState<CompanyFeatureFlagDefinitions[]>([]);
  const [newFeatureName, setNewFeatureName] = useState('');

  useEffect(() => {
    api.featureFlags.getFeatureFlagDefinitions().then(features => setDefinitions(features.data));
  }, []);

  const cols: GridColDef[] = [
    {
      headerName: 'ID',
      field: 'id',
      flex: 1,
    },
    {
      headerName: 'Feature Name',
      field: 'featureName',
      flex: 1,
    },
    {
      headerName: 'Action',
      field: 'action',
      flex: 1,
      renderCell: (params: GridRenderCellParams) => (
        <button onClick={() => api.featureFlags.deleteFeatureFlagDefinition(params.row.id)}>
          Delete
        </button>
      ),
    },
  ];

  const rows = definitions.map(feature => ({
    id: feature.id,
    featureName: feature.feature_name,
  }));

  return (
    <div style={{ width: 500 }}>
      <h3>Company Feature Flag Definitions</h3>
      <DataGrid
        rows={rows}
        columns={cols}
        pageSize={5}
        autoHeight={true}
        disableSelectionOnClick={true}
      />
      <Box sx={{ padding: 2, display: 'flex', alignItems: 'center', gap: 1 }}>
        <TextField
          value={newFeatureName}
          onChange={e => setNewFeatureName(e.target.value)}
          placeholder="New Feature Name"
        />
        <Button
          variant="contained"
          color="primary"
          onClick={() =>
            api.featureFlags.addFeatureFlagDefinition({ feature_name: newFeatureName })
          }
        >
          Add Feature
        </Button>
      </Box>
    </div>
  );
};

export default FeatureFlagDefinition;
