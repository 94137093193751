import React, { useState } from 'react';
import {
  Button,
  TextField,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from '@mui/material';
import PropTypes from 'prop-types';

const RejectionNote = ({ visible, onClose, onReject }) => {
  const [note, setNote] = useState('');

  const handleChangeNote = value => {
    setNote(value);
  };

  const handleReject = () => {
    if (!note || note === '') {
      return;
    }

    onReject(note);
  };
  return (
    <Dialog
      open={visible}
      onClose={onClose}
      scroll="paper"
      aria-labelledby="scroll-dialog-title"
      aria-describedby="scroll-dialog-description"
      fullWidth={true}
    >
      <DialogTitle id="scroll-dialog-title">Rejection Notes</DialogTitle>
      <DialogContent dividers={true}>
        <TextField
          label="Note"
          value={note}
          onChange={event => {
            handleChangeNote(event.target.value);
          }}
          multiline
          fullWidth
          rows={4}
        />
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={onClose}>
          Cancel
        </Button>
        <Button variant="outlined" color="primary" onClick={handleReject}>
          Add Note and Reject
        </Button>
      </DialogActions>
    </Dialog>
  );
};

RejectionNote.prototype = {
  visible: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onReject: PropTypes.func.isRequired,
};

export default RejectionNote;
