import React, { useState } from 'react';
import { DialogContent, Button, Box, Typography } from '@mui/material';
import ModalHeader from 'sharedComponents/ModalHeader';
import { useHasAccess } from 'services/permissions';
import { isDriverTkmsOrRichmondUser } from 'modules/auth/logicDependentOnUserRole';
import { selectAuthUser } from 'modules/auth/storeSliceAuth';
import { useSelector } from 'react-redux';
import { thunkDispatch } from 'store/store';
import {
  importTKMSDataFromFTP,
  preProcessImportTKMSData,
} from 'modules/civilAndTrucking/trucking/features/DataImport/redux/actions';
import {
  selectImportedTKMSDataFromFTP,
  selectPreprocessImportedData,
} from 'modules/civilAndTrucking/trucking/features/DataImport/redux/dataImportStoreSlice';
import { LoadingOverlay } from 'sharedComponents/LoadingOverlay';
import { PreprocessDataImport } from './PreprocessDataImport';

export const ImportDataModal = () => {
  const authUser = useSelector(selectAuthUser);
  const [hasPreprocessed, setHasPreprocessed] = useState(false);
  const { importedTKMSDataFromFTPStatus } = useSelector(selectImportedTKMSDataFromFTP);
  const { preprocessImportedDataStatus, preprocessImportedData } = useSelector(
    selectPreprocessImportedData
  );
  const { hasAccess: hasTruckingAllAccess } = useHasAccess([
    'trucking_tickets:all',
    'trucking_projects:all',
  ]);
  const isTKMSCompany = isDriverTkmsOrRichmondUser(authUser);

  const handleTKMSImportFromFTP = (isTestRequest: boolean) => {
    thunkDispatch(importTKMSDataFromFTP(isTestRequest));
  };

  const handlePreprocessTKMSImport = (isTestRequest: boolean) => {
    setHasPreprocessed(true);
    thunkDispatch(preProcessImportTKMSData(isTestRequest));
  };

  if (importedTKMSDataFromFTPStatus === 'pending' || preprocessImportedDataStatus === 'pending') {
    return <LoadingOverlay />;
  }

  return (
    <Box sx={{ p: '0px 16px' }}>
      <ModalHeader title={`Import Data`} />
      <DialogContent>
        {isTKMSCompany && hasTruckingAllAccess && (
          <>
            <Box display={'flex'} justifyContent={'space-between'}>
              <Button
                variant="contained"
                color="primary"
                onClick={() => handlePreprocessTKMSImport(false)}
              >
                Preprocess Import
              </Button>
              <Button
                variant="contained"
                color="primary"
                onClick={() => handleTKMSImportFromFTP(false)}
                disabled={!hasPreprocessed || !preprocessImportedData?.length}
              >
                Import from FTP
              </Button>
            </Box>
            {authUser?.type === 'Super Admin' && (
              <Box sx={{ my: 4 }}>
                <Typography variant="h5">Importing Test Data</Typography>
                <Typography variant="subtitle2">
                  Attempt to import mock data from CSV files in the same format as those from
                  TKMS&apos; FTP server.
                </Typography>
                <Box display={'flex'} justifyContent={'space-between'}>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => handlePreprocessTKMSImport(true)}
                  >
                    Preprocess Import (TEST DATA)
                  </Button>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => handleTKMSImportFromFTP(true)}
                    disabled={!hasPreprocessed || !preprocessImportedData?.length}
                  >
                    Import from FTP (TEST DATA)
                  </Button>
                </Box>
              </Box>
            )}

            <PreprocessDataImport />
          </>
        )}
      </DialogContent>
    </Box>
  );
};
