import React, { useEffect, useState } from 'react';
import 'mapbox-gl/dist/mapbox-gl.css';
import Mapbox from 'sharedComponents/Mapbox';
import { Grid } from '@mui/material';
import { useParams } from 'react-router-dom';
import { generateIconMarker } from 'sharedComponents/MapboxHelpers';
import Query from 'services/firebase/Query';
import Executer from 'services/firebase/Executer';
import ButtonLink from 'sharedComponents/ButtonLink';
import useShowError from 'modules/errors';
import { LoadingOverlay as Loading } from 'sharedComponents/LoadingOverlay';
import {
  FirestoreTruckingTicket,
  TicketDriverGpsPositions,
} from 'lg-helpers/dist/shared/types/trucking-tickets';

const query = new Query();
const executer = new Executer();

export const TicketHistoricalMapView = () => {
  const { ticketId } = useParams();
  const [ticket, setTicket] = useState<FirestoreTruckingTicket>();
  const [driverMarkers, setDriverMarkers] = useState([] as any[]);
  const [siteMarker, setSiteMarker] = useState<any>([]);
  const showError = useShowError();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (!ticketId) return;

    try {
      executer
        .getSingleDocument(query.truckingTickets.getFirestoreTicketByPostgresTicketId(ticketId))
        .then(data => {
          const ticketRes = data as FirestoreTruckingTicket;
          if (ticketRes?.id) {
            setTicket(ticketRes);
            setIsLoading(false);
          } else {
            showError({
              title: 'Error fetching ticket, please try again',
              duration: 10000,
            });
          }
        });
    } catch (e) {
      showError({ title: 'Error fetching ticket', duration: 10000 });
    }
  }, [ticketId, showError]);

  useEffect(() => {
    if (!ticket?.id) return;

    const generateAndSetSiteMarker = () => {
      const { destinationLong, destinationLat } = ticket;
      const marker = generateIconMarker(
        { longitude: destinationLong || 42, latitude: destinationLat || -83 },
        '/static/images/excavator.png',
        `<p><b>Address: 'n/a'}</b></p>` || ''
      );

      setSiteMarker([marker]);
    };

    const generateAndWatchTruckmarkers = () => {
      return executer.watchMultipleDocuments(
        query.truckingTickets.getTruckingTicketGPSPositions(ticket.id),
        data => {
          const gpsPositions = data as TicketDriverGpsPositions[];
          const ticketMarkers = gpsPositions.map(gpsPosition => {
            const { ticketNumber, userName } = ticket;
            const { longitude, latitude } = gpsPosition;
            const popupHtml = `<p><b>Ticket: ${ticketNumber}</b></p><p>${userName}</p>`;

            return generateIconMarker(
              { longitude, latitude },
              '/static/images/icons/truck.svg',
              popupHtml
            );
          });
          setDriverMarkers(ticketMarkers);
        },
        e => {
          console.error('Error fetching gps coords in ticket: ', e);
        }
      );
    };

    generateAndSetSiteMarker();
    const unsubscriber = generateAndWatchTruckmarkers();

    return unsubscriber;
  }, [ticket]);

  if (isLoading) {
    return <Loading />;
  }

  return (
    <Grid container>
      <ButtonLink />
      <Grid item xs={12}>
        <Mapbox
          zoom={12}
          center={[
            ((ticket?.startingLong || -83) + (ticket?.destinationLong || -83)) / 2,
            ((ticket?.startingLat || 42) + (ticket?.destinationLat || 42)) / 2,
          ]}
          markers={driverMarkers?.concat(siteMarker)}
        />
      </Grid>
    </Grid>
  );
};
