import React from 'react';
import { Avatar, Box, Button, Card, CardActions, CardContent, Divider } from '@mui/material';
import Typography from '@mui/material/Typography';

const UserImage = ({
  className,
  user,
  ...rest
}: {
  className?: string;
  user: any;
  [key: string]: any;
}) => (
  <Card className={className} {...rest}>
    <CardContent>
      <Box alignItems="center" display="flex" flexDirection="column">
        <Avatar sx={{ width: 100, height: 100 }} src={user.picture} />
        <Typography
          color="textPrimary"
          gutterBottom
          sx={{ typography: { xs: 'h5' } }}
          align="center"
        >
          {`${user.firstName || ''} ${user.lastName || ''}`}
        </Typography>
        <Typography color="textSecondary" variant="body1">
          {user.type}
        </Typography>
      </Box>
    </CardContent>
    <Divider />
    <CardActions>
      <Button color="primary" fullWidth variant="text" disabled={true}>
        Upload picture
      </Button>
    </CardActions>
  </Card>
);

export default UserImage;
