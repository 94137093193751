import React, { useState, useContext } from 'react';
import { thunkDispatch } from 'store/store';
import { DialogContent } from '@mui/material';
import ModalHeader from 'sharedComponents/ModalHeader';
import DialogActionContainer from 'sharedComponents/DialogActionContainer';
import { ModalContext } from 'sharedComponents/ModalContext';
import { upsertCompanyMaterial, deleteCompanyMaterial } from '../../redux/actions';
import { IHelpers } from 'lg-helpers/dist/trucking/interfaces/IHelpers';
import { ITruckingCompanyMaterial } from 'lg-helpers/dist/trucking/interfaces/ITruckingCompanyMaterial';
import { FormTextField } from 'sharedComponents/FormTextField';

type companyMaterialsProps = {
  editObj?: ITruckingCompanyMaterial;
};

export const CompanyMaterialForm = ({ editObj }: companyMaterialsProps) => {
  const { handleModal } = useContext(ModalContext);
  const [companyMaterial, setCompanyMaterial] = useState(
    editObj || ({} as ITruckingCompanyMaterial)
  );
  const [helpers, setHelpers] = useState<IHelpers>({});

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setCompanyMaterial({ ...companyMaterial, [e.target.name]: e.target.value });
  };

  const handleDeleteCompanyMaterial = () => {
    if (editObj?.idTruckingCompanyMaterial) {
      thunkDispatch(deleteCompanyMaterial(editObj.idTruckingCompanyMaterial));
      handleModal();
    }
  };

  const onSubmit = async () => {
    if (!companyMaterial?.nameTruckingCompanyMaterial) {
      setHelpers({ nameTruckingCompanyMaterial: 'Name is required!' });
    } else {
      thunkDispatch(upsertCompanyMaterial(companyMaterial));
      handleModal();
    }
  };

  return (
    <>
      <ModalHeader title={`${editObj ? 'Edit' : 'Add'} Material`} />
      <DialogContent>
        <FormTextField
          name="nameTruckingCompanyMaterial"
          label="Material Name"
          value={companyMaterial?.nameTruckingCompanyMaterial}
          helperField={helpers.nameTruckingCompanyMaterial}
          onChange={handleChange}
          required
        />
        <FormTextField
          name="skuTruckingCompanyMaterial"
          label="Material ID/SKU"
          value={companyMaterial?.skuTruckingCompanyMaterial}
          helperField={helpers.skuTruckingCompanyMaterial}
          onChange={handleChange}
        />
        <DialogActionContainer
          saveHandler={onSubmit}
          deleteHandler={handleDeleteCompanyMaterial}
          editing={!!editObj}
        />
      </DialogContent>
    </>
  );
};
