import { createSlice, isAnyOf } from '@reduxjs/toolkit';
import { getBrokerLoadsByDispatchId, getLoadsByDispatchId, upsertLoad } from './actions';
import { RootState } from 'store/store';
import { TFormStatus } from 'lg-helpers/dist/shared/types/TFormStatus';
import { ITruckingLoad } from 'lg-helpers/dist/trucking/interfaces/ITruckingLoad';

export interface ILoadsState {
  data: ITruckingLoad[];
  upsertLoadId: number | null;
  status: TFormStatus;
}

const initialLoadsState: ILoadsState = {
  data: [] as ITruckingLoad[],
  upsertLoadId: null,
  status: null,
};

export const loadsStoreSlice = createSlice({
  name: 'loads',
  reducers: {},
  initialState: initialLoadsState,
  extraReducers: builder => {
    builder
      .addCase(upsertLoad.pending, state => {
        state.status = 'pending';
      })
      .addCase(upsertLoad.fulfilled, (state, { payload }) => {
        state.status = 'success';
        state.upsertLoadId = payload;
      })
      .addCase(upsertLoad.rejected, (state, { error }) => {
        state.status = 'failure';
        state.upsertLoadId = null;
        console.error('upsertLoad error: ', error);
      })
      .addMatcher(
        isAnyOf(getLoadsByDispatchId.pending, getBrokerLoadsByDispatchId.pending),
        (state, {}) => {
          state.status = 'pending';
        }
      )
      .addMatcher(
        isAnyOf(getLoadsByDispatchId.fulfilled, getBrokerLoadsByDispatchId.fulfilled),
        (state, { payload }) => {
          state.status = 'success';
          state.data = payload;
        }
      )
      .addMatcher(
        isAnyOf(getLoadsByDispatchId.rejected, getBrokerLoadsByDispatchId.rejected),
        (state, { error }) => {
          state.status = 'failure';
          state.data = [] as ITruckingLoad[];
          console.error('getLoadsByDispatchId error: ', error);
        }
      );
  },
});

export const selectLoads = (state: RootState) => {
  return {
    loads: state.trucking.loads.data,
    loadsStatus: state.trucking.loads.status,
  };
};
