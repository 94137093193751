import React, { useEffect } from 'react';
import './trucking.scss';
import { useHasAccess, usePermissionsContext } from 'services/permissions';
import { useSelector } from 'react-redux';
import { thunkDispatch } from 'store/store';
import { Box } from '@mui/material';
import { Routes, Route } from 'react-router-dom';
import { IMenuItem } from 'modules/civilAndTrucking/shared/nav';
import {
  HomeView,
  DispatchesViews,
  TicketsViews,
  TruckingReportsView,
  SettingsView,
  SourceSuppliersViews,
  TruckTypesViews,
  ProjectsViews,
  CompanyMaterialsViews,
  TrucksViews,
  DivisionsViews,
  BrokersViews,
  ContractorViews,
} from './features';
import { NotificationSnackbar } from 'modules/civilAndTrucking/shared/NotificationSnackbar';
import { ModalProvider } from 'sharedComponents/ModalContext';
import Modal from 'sharedComponents/Modal';
import { LoadingOverlay as Loading } from 'sharedComponents/LoadingOverlay';
import { RootState } from 'store/store';
import { setActiveCompany } from 'modules/civilAndTrucking/civil/Companies/redux/actions';
import ErrorHandler, { ActionButton } from 'sharedComponents/ErrorHandler';
import { HomeOutlined, LocalShippingOutlined, Settings, SpeedOutlined } from '@mui/icons-material';
import NotFoundView from '../../../sharedComponents/pictograms/NotFoundView';
import { AppNav } from '../shared/nav/AppNav/AppNav';
import { UserTypeGenerated } from 'services/api/autogenerated-code';
import { IUser, ViewerAccessCompany } from 'lg-helpers/dist/shared/interfaces/IUser';

const TruckingDashboardView = () => {
  const { user } = usePermissionsContext();
  const { hasAccess: hasTruckingAllAccess } = useHasAccess([
    'trucking_tickets:all',
    'trucking_projects:all',
  ]);
  const { hasAccess: hasTruckingReadAccess } = useHasAccess([
    'trucking_tickets:read',
    'trucking_projects:read',
  ]);

  const navBarItems: IMenuItem[] = [
    {
      href: '/trucking/dispatches',
      title: 'Dispatch',
      Pictogram: <HomeOutlined />,
      computeVisibility: () => hasTruckingAllAccess,
    },
    {
      href: '/trucking/tickets',
      title: 'Tickets',
      Pictogram: <LocalShippingOutlined />,
      computeVisibility: () => hasTruckingReadAccess,
    },
    {
      href: '/trucking/reports',
      title: 'Reports',
      Pictogram: <SpeedOutlined />,
      computeVisibility: () => hasTruckingAllAccess,
    },
    {
      href: '/trucking/setup',
      title: 'Set Up',
      Pictogram: <Settings />,
      computeVisibility: () => hasTruckingAllAccess,
    },
  ];

  useEffect(() => {
    if (user.companiesIds[0]) {
      thunkDispatch(setActiveCompany(user.companiesIds[0]));
    }
  }, [user]);

  const activeCompany = useSelector((state: RootState) => state.civil.companies.activeCompany);
  const activeCompanyStatus = useSelector(
    (state: RootState) => state.civil.companies.activeCompanyStatus
  );

  if (!user) throw new Error('No user. Something wrong');

  if (!user.companiesIds || user.companiesIds.length < 1 || !user.companiesIds[0])
    throw new Error('Your user not assigned to any company');

  if (activeCompanyStatus === 'failure')
    throw new Error('Failed to load information about your company.');

  console.log(activeCompanyStatus, activeCompany, user);
  if (activeCompanyStatus === 'pending' || activeCompanyStatus === null) {
    return <Loading />;
  }

  if (!activeCompany) {
    console.warn('Wrong frame', { activeCompanyStatus, activeCompany });
    return null;
  }
  const correctUserType =
    ['broker', 'trucking', 'truckingContractor'].some(
      type =>
        activeCompany.types.includes(type as UserTypeGenerated) ||
        (((user as IUser)?.viewerAccessCompanies as ViewerAccessCompany[]) || [])?.some(c =>
          c?.inviterCompanyAccessCompany?.types?.includes(type as UserTypeGenerated)
        )
    ) && ['trucking_dispatcher', 'super_admin', 'trucking_viewer'].includes(user.role);

  if (!correctUserType)
    return (
      <ErrorHandler
        error={new Error('Your user type can not use this application.')}
        buttons={
          <>
            <ActionButton onClick={() => (window.location.pathname = '/')}>
              Go to homepage
            </ActionButton>
            {/* We need some redirect URL from login page to enable this feature (or get rid of /login page, we can render login form by any URL) */}
            {/* <ActionButton onClick={logOut}>Login as other user</ActionButton> */}
          </>
        }
      />
    );

  return (
    <ModalProvider>
      <Modal />
      <AppNav
        settingsPath="/brokers/account"
        logoComponent={<img src="/static/images/logo-trucking-main.svg" alt="LTS Trucking" />}
        navItems={navBarItems}
      />
      <Box sx={{ p: '5rem 3rem 0 6rem', maxWidth: '2400px' }} className="Trucking">
        <Routes>
          <Route path="/" element={<HomeView />} />
          <Route path="/dispatches/*" element={<DispatchesViews />} />
          <Route path="/tickets/*" element={<TicketsViews />} />
          <Route path="/reports" element={<TruckingReportsView />} />
          <Route path="/setup" element={<SettingsView />} />
          <Route path="/setup/trucks/*" element={<TrucksViews />} />
          <Route path="/setup/truck-types/*" element={<TruckTypesViews />} />
          <Route path="/setup/divisions/*" element={<DivisionsViews />} />
          <Route path="/setup/source-suppliers/*" element={<SourceSuppliersViews />} />
          <Route path="/setup/company-materials" element={<CompanyMaterialsViews />} />
          <Route path="/setup/projects/*" element={<ProjectsViews />} />
          <Route path="/setup/brokers/*" element={<BrokersViews />} />
          <Route path="/setup/contractors/*" element={<ContractorViews />} />
          <Route path="*" element={<NotFoundView />} />
        </Routes>
      </Box>
      <NotificationSnackbar />
    </ModalProvider>
  );
};

export default TruckingDashboardView;
