import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment';

import { Box, Tabs, Tab, Grid, Checkbox } from '@mui/material';
import Replay from '@mui/icons-material/Replay';
import TodayIcon from '@mui/icons-material/Today';
import PersonOffIcon from '@mui/icons-material/PersonOff';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';

import { UserTypes } from 'lg-helpers/dist/constants/user/UserTypes';
import CrudTable from 'sharedComponents/CrudTable';
import { selectAuthUser } from 'modules/auth/storeSliceAuth';
import { useParams, useNavigate } from 'react-router-dom';
import ToolbarLoads from './ToolbarLoads';
import Executer from 'services/firebase/Executer';
import Query from 'services/firebase/Query';
import useIsUserOfType from 'services/hooks/useIsUserOfType';
import AssignTruckNumber from './AssignTruckNumber';
import TruckLoadedSmall from 'sharedComponents/pictograms/TruckLoadedSmall';
import useGetTruckNumbersForUserCompany from 'services/hooks/useGetTruckNumbersForUserCompany';
import useGetTruckNumberForUser from 'services/hooks/useGetTruckNumberForUser';
import { datesAreInTheSameDay } from 'services/logic/dateTime';
import useDateRangeFilter from 'services/hooks/useDateRangeFilter';
import { selectDateRangeFilter } from 'modules/brokers/storeSliceBrokers';
import ButtonWithLoading from 'sharedComponents/ButtonWithLoading';
import useGetDriverCurrentLoad, { Load } from 'services/hooks/useGetDriverCurrentLoad';
import useGetDriverCurrentRepeatedLoad from 'services/hooks/useGetDriverCurrentRepeatedLoad';
import { getOnlyDateString } from 'services/logic/dateTime';
import Downloads from './Downloads';
import { createDownloadBrokerLoads } from 'api/brokers';
import { getDateStringWithoutTimezone } from 'services/logic/dateTime';
import useShowError from 'modules/errors';
import { TableCrudColumn } from 'sharedComponents/TableCrud';
import {
  brokerLoadsShowOnly,
  ShowOnlyValue,
} from 'lg-helpers/dist/firestore/query/BrokerFirebaseQuery';
import QueryBase, { PaginationInfo } from 'lg-helpers/dist/firestore/query/QueryBase';
import Command from '../../../services/firebase/Command';

const executer = new Executer();
const query = new Query();
const command = new Command();

const columnsDefault: TableCrudColumn<Load>[] = [
  {
    label: 'Driver',
    isVerticalHeaderText: true,
    getCellValueCallback: row => {
      let CheckBoxComponent = <CheckBoxOutlineBlankIcon fontSize="large" color="error" />;
      if (row.signatureDriver) {
        if (row.signatureDriver.isSignedDumped) {
          CheckBoxComponent = <CheckBoxIcon fontSize="large" color="primary" />;
        } else if (row.signatureDriver.isSignedLoaded) {
          CheckBoxComponent = <TruckLoadedSmall />;
        }
      }

      return CheckBoxComponent;
    },
  },
  { path: 'projectName', label: 'Project' },
  { path: 'customerNumber', label: 'Job #' },
  { path: 'contractorName', label: 'Contractor' },
  { path: 'material', label: 'Material' },
  { path: 'source', label: 'Source' },
  { path: 'signatureDriver.truckNumber', label: 'Truck number' },
  { path: 'signatureDriver.quantity', label: 'Quantity' },
  { path: 'signatureDriver.pitTicket', label: 'Pit ticket' },
  {
    path: 'date',
    label: 'Date',
    getCellValueCallback: row => {
      return getOnlyDateString(row.date, row.timeZone);
    },
  },
];

const LoadsView = () => {
  const navigate = useNavigate();
  let { dispatchId } = useParams();
  const authUser = useSelector(selectAuthUser);
  const dateRangeFilterStore = useSelector(selectDateRangeFilter);
  const { dateRangeFilter, setDateRangeFilter, onDateRangeFilterChange } = useDateRangeFilter();
  const { currentLoad } = useGetDriverCurrentLoad();
  const { currentRepeatedLoad } = useGetDriverCurrentRepeatedLoad();

  const isAuthUserBrokerAdmin = useIsUserOfType('trucking_dispatcher');
  const truckNumber = useGetTruckNumberForUser();

  const { isLoadingTruckNumbers, truckNumbers } = useGetTruckNumbersForUserCompany();

  const [loads, setLoads] = useState<Load[]>([]);
  const [isLoadingData, setIsLoadingData] = useState(true);
  const showError = useShowError();
  const [filterDispatchId, setFilterDispatchId] = useState(dispatchId || undefined);
  const [filterSelectedUserId, setFilterSelectedUserId] = useState<string | undefined>(undefined);

  const [filterShowOnly, setFilterShowOnly] = useState<ShowOnlyValue>(
    isAuthUserBrokerAdmin ? brokerLoadsShowOnly.all : brokerLoadsShowOnly.used
  );
  const [paginationInfo, setPaginationInfo] = useState<PaginationInfo | null>(null);
  const [tabValue, setTabValue] = useState('0');

  const repeatLoad = useCallback(
    async (load: Load) => {
      try {
        await command.brokers.brokerRepeatLoad(authUser.id, load.loadID, load.dispatchID);
        console.log('repeatLoad successful');
        navigate('/brokers/driver/dispatches');
      } catch (err) {
        console.error('repeatLoad', err);
      }
    },
    [navigate, authUser]
  );

  const columnsForBroker: TableCrudColumn<Load>[] = useMemo(
    () => [
      ...columnsDefault,
      {
        label: 'Can repeat',
        isVerticalHeaderText: true,
        getCellValueCallback: row => (
          <Box width="100%" display="flex" alignItems="center" justifyContent="center">
            {row.canRepeat ? <CheckBoxIcon /> : <CheckBoxOutlineBlankIcon />}
          </Box>
        ),
      },
      {
        label: 'Last load',
        isVerticalHeaderText: true,
        hasCustomClickHandler: true,
        getCellValueCallback: row => (
          <Checkbox
            checked={row?.lastLoad}
            onChange={() => handleLastLoadChange(row)}
            color="primary"
          />
        ),
      },
      {
        hasCustomClickHandler: true,
        label: 'Assigned Truck #',
        getCellValueCallback: row => (
          <AssignTruckNumber
            truckNumbers={truckNumbers}
            isLoadingTruckNumbers={isLoadingTruckNumbers}
            load={row}
          />
        ),
      },
      {
        hasCustomClickHandler: true,
        label: 'Clear Driver',
        getCellValueCallback: load => (
          <ButtonWithLoading
            text="" // TODO: find out text
            Pictogram={PersonOffIcon}
            onClickCallback={() => {
              command.brokers.brokerClearSignatureDriver(load.id);
            }}
            disabled={!!load.signatureDriver || load.signatureDriver.isSignedDumped}
          />
        ),
      },
    ],
    // eslint-disable-next-line
    [isLoadingTruckNumbers, truckNumbers]
  );
  const columnsOtherwise: TableCrudColumn<Load>[] = useMemo(
    () => [
      ...columnsDefault,
      {
        label: 'Repeat',
        isVerticalHeaderText: true,
        hasCustomClickHandler: true,
        getCellValueCallback: load => {
          let driverHasDifferentTruckNumberThanLoad = false;
          const { truckNumberCustom } = authUser.customData || {};
          const { assignedTruckNumber } = load;
          if (assignedTruckNumber && assignedTruckNumber !== truckNumberCustom) {
            driverHasDifferentTruckNumberThanLoad = true;
          }

          if (
            load.canRepeat &&
            load.signatureDriver &&
            load.signatureDriver.isSignedDumped &&
            datesAreInTheSameDay(moment().toDate(), load.availableAt.toDate(), load.timeZone)
          ) {
            return (
              <Box width="100%" display="flex" alignItems="center" justifyContent="center">
                <ButtonWithLoading
                  text="" // TODO: find out text
                  disabled={
                    !!currentLoad || !!currentRepeatedLoad || driverHasDifferentTruckNumberThanLoad
                  }
                  Pictogram={Replay}
                  onClickCallback={() => repeatLoad(load)}
                />
              </Box>
            );
          }
        },
      },
    ],
    [authUser, currentLoad, currentRepeatedLoad, repeatLoad]
  );

  const columns = useMemo(
    () => (isAuthUserBrokerAdmin ? columnsForBroker : columnsOtherwise),
    [isAuthUserBrokerAdmin, columnsForBroker, columnsOtherwise]
  );

  useEffect(() => {
    if (dateRangeFilterStore) {
      setDateRangeFilter(dateRangeFilterStore);
    }
  }, [dateRangeFilterStore, setDateRangeFilter]);

  useEffect(() => {
    setPaginationInfo({
      lastVisibleDoc: null,
      isNext: null,
    });
  }, [dateRangeFilter, filterDispatchId, filterShowOnly]);

  const handleLastLoadChange = async (load: Load) => {
    try {
      await command.brokers.brokerMarkLoadAsLastLoad(load.id, load?.lastLoad);
    } catch (err) {
      console.error(err);
      showError({ title: 'Error setting load as last load' });
    }
  };

  useEffect(() => {
    if (!authUser) {
      return;
    }

    const { type } = authUser;
    if (type !== UserTypes.broker.trucking.dispatcher) {
      setFilterSelectedUserId(authUser.id);
    }

    setPaginationInfo({
      lastVisibleDoc: null,
      isNext: null,
    });
  }, [authUser]);

  useEffect(() => {
    if (
      !authUser ||
      paginationInfo === null ||
      isAuthUserBrokerAdmin === null ||
      dateRangeFilter === null ||
      filterShowOnly === null
    ) {
      return;
    }

    if (!isAuthUserBrokerAdmin && !filterSelectedUserId) {
      return;
    }

    setIsLoadingData(true);
    let truckNumberFilter = {
      filterByTruckNumber: false,
      truckNumber,
      includeNoTruckNumber: false,
    };

    return executer.watchMultipleDocuments(
      query.brokers.brokerGetLoads(
        authUser.companiesIds,
        paginationInfo,
        filterDispatchId,
        dateRangeFilter,
        filterSelectedUserId,
        filterShowOnly,
        truckNumberFilter
      ),
      loadsResults => {
        setLoads(loadsResults as Load[]);
        setIsLoadingData(false);
      },
      error => {
        console.error('Error', error);
        showError({ title: 'Error while fetching the loads' });
        setIsLoadingData(false);
      }
    );
    // eslint-disable-next-line
  }, [
    authUser,
    paginationInfo,
    dateRangeFilter,
    filterDispatchId,
    filterSelectedUserId,
    filterShowOnly,
    isAuthUserBrokerAdmin,
    truckNumber,
  ]);

  const handleRowClick = useCallback(
    (load: Load) => {
      navigate(`/brokers/load/${load.id}`);
    },
    [navigate]
  );

  const loadLoads = async (isNext: boolean) => {
    let lastVisibleDoc = null;
    if (loads.length) {
      const id = isNext ? loads[loads.length - 1].id : loads[0].id;
      lastVisibleDoc = await executer.getSingleDocumentSnapshot(
        query.base.getById(QueryBase.BROKER_LOADS_COLLECTION(), id)
      );
    }

    setPaginationInfo({
      ...paginationInfo,
      isNext,
      lastVisibleDoc,
    });
  };

  const callbacksetDateRangeFilter = (value: [Date, Date]) => {
    onDateRangeFilterChange(value);

    setPaginationInfo({
      ...paginationInfo,
      isNext: null,
      lastVisibleDoc: null,
    });
  };

  const onBrokerLoadsPdfsClickCallback = async () => {
    if (!dateRangeFilter) return;

    await createDownloadBrokerLoads({
      startDate: getDateStringWithoutTimezone(dateRangeFilter[0]),
      endDate: getDateStringWithoutTimezone(dateRangeFilter[1]),
      companyId: authUser.companiesIds[0],
    });
  };

  if (!authUser) {
    return null;
  }

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Box width="100%" display="flex" justifyContent="center" alignItems="center">
            <ButtonWithLoading
              Pictogram={TodayIcon}
              text="PDFs/Day"
              onClickCallback={onBrokerLoadsPdfsClickCallback}
            />
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Tabs
            value={tabValue}
            indicatorColor="primary"
            textColor="primary"
            onChange={(event, newValue) => {
              setTabValue(newValue);
            }}
          >
            <Tab label="Broker Loads" value="0" />
            {isAuthUserBrokerAdmin && <Tab label="PDFs downloads" value="1" />}
          </Tabs>
        </Grid>
        {tabValue === '0' && (
          <Grid item xs={12}>
            <CrudTable
              title="Loads"
              rows={loads}
              columns={columns}
              isLoading={isLoadingData}
              handleRowClickCallback={handleRowClick}
            >
              <ToolbarLoads
                companyId={authUser.companiesIds[0]}
                dateRangeFilter={dateRangeFilter}
                setDateRangeFilterCallback={callbacksetDateRangeFilter}
                filterDispatchId={filterDispatchId}
                setFilterDispatchId={setFilterDispatchId}
                filterShowOnly={filterShowOnly}
                setFilterShowOnly={setFilterShowOnly}
                handleClickBackCallback={() => loadLoads(false)}
                handleClickNextCallback={() => loadLoads(true)}
                isLoading={isLoadingData}
              />
            </CrudTable>
          </Grid>
        )}

        {tabValue === '1' && (
          <Grid item xs={12}>
            <Downloads companyId={authUser.companiesIds[0]} />
          </Grid>
        )}
      </Grid>
    </>
  );
};

export default LoadsView;
