import React, { useMemo } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { generateTrucksInBrokersColumns } from './trucksInBrokersColumns';
import { CompanyConnectionWithCompany } from 'services/api/autogenerated';
import api, { ConnectedCompanyType } from 'services/api/autogenerated';
import useApiGetRequest from 'services/api/useApiGetRequest';

type AddTrucksToBrokerProps = {
  handleAddBroker: (broker: CompanyConnectionWithCompany) => void;
  addedBrokers: CompanyConnectionWithCompany[];
};

export const AddTrucksToBroker = ({ handleAddBroker, addedBrokers }: AddTrucksToBrokerProps) => {
  const columns = useMemo(
    () => generateTrucksInBrokersColumns(handleAddBroker, addedBrokers),
    [handleAddBroker, addedBrokers]
  );

  // TODO: implement pagination here in future
  // It's not required right now because TKMS do not import brokers
  const [brokers] = useApiGetRequest(
    () =>
      api.companiesConnections.getConnectedCompaniesByTypes([
        ConnectedCompanyType.Broker,
        ConnectedCompanyType.TruckingBroker,
      ]),
    () => ({ title: 'Failed to load brokers' })
  );

  return (
    <DataGrid
      columns={columns}
      rows={brokers || []}
      getRowId={row => row.id}
      showColumnRightBorder={false}
      disableColumnMenu
      disableSelectionOnClick
      hideFooterPagination
      autoHeight
      components={{
        ColumnResizeIcon: () => <div></div>,
      }}
    />
  );
};
