import React from 'react';
import { DialogContent } from '@mui/material';
import ModalHeader from 'sharedComponents/DialogHeader';

import ProjectTaskForm from './ProjectTaskForm';
import { IProjectTask } from 'lg-helpers/dist/shared/interfaces/IProjectTask';

interface ProjectTaskModalProps {
  selected?: IProjectTask;
}

const ModalContainer = ({ selected }: ProjectTaskModalProps) => {
  return (
    <>
      <ModalHeader title="Project Task" />
      <DialogContent>
        <ProjectTaskForm selected={selected} />
      </DialogContent>
    </>
  );
};

export default ModalContainer;
