import React from 'react';
import PropTypes from 'prop-types';
import { Button, Box, Card, CardContent, CardHeader, Divider, Grid } from '@mui/material';
import Lane from './Lane';
import { selectScaleForEdit, setScaleForEdit } from 'modules/scales/storeSliceScales';
import { useSelector, useDispatch } from 'react-redux';

const LanesDetails = ({ className }) => {
  const dispatch = useDispatch();
  const scaleForEdit = useSelector(selectScaleForEdit);
  if (!scaleForEdit) {
    return null;
  }
  const isEnabledDeleteLastLane = !!(scaleForEdit.lanes && scaleForEdit.lanes.length);

  const addLane = () => {
    if ((scaleForEdit.lanes || []).length < 7) {
      dispatch(
        setScaleForEdit({
          ...scaleForEdit,
          lanes: [
            ...(scaleForEdit.lanes || []),
            {
              id: `${(scaleForEdit.lanes || []).length + 1}`,
              color: 'black',
              type: 'lane',
              label: '',
            },
          ],
        })
      );
    }
  };

  const deleteLastLane = () => {
    const withoutLastLane = [...scaleForEdit.lanes];
    withoutLastLane.pop();
    dispatch(
      setScaleForEdit({
        ...scaleForEdit,
        lanes: withoutLastLane,
      })
    );
  };

  return (
    <Card className={className}>
      <CardHeader title="Lane Configuration" />
      <Divider />
      <CardContent>
        <p>
          <b>Instructions:</b> To add an item (lane or scale) click the <b>`Add`</b> button at the
          bottom right, this will add a new item to the configuration. From there you can determine
          if you would like for it to be a <b>`lane`</b> or <b>`scale`</b> by using the dropdown
          menu and selecting what you would like the item to be. For lanes, you can also designate
          what color you would like by select the <b>`Lane Color`</b> dropdown and choosing from the
          list of available colors. You can also customize what number the lane is by entering the
          number of the lane. Once you&apos;ve added items you can always change them and you can
          change a lane to a scale and a scale to a lane at any point as well.
        </p>
        <br />
        <Grid container spacing={3}>
          <Grid item lg={12} md={12} xs={12}>
            {scaleForEdit ? (
              <Box
                display="flex"
                justifyContent="flex-end"
                style={{
                  width: '100%',
                  display: 'flex',
                }}
              >
                {(scaleForEdit.lanes || []).map((lane, idx) => (
                  <Lane key={idx} lane={lane} />
                ))}
              </Box>
            ) : undefined}
          </Grid>
          <Grid item lg={12} md={12} xs={12}>
            <Box display="flex" justifyContent="flex-end">
              <Button disabled={!isEnabledDeleteLastLane} onClick={deleteLastLane} marginRight={10}>
                Delete last
              </Button>
              <Button
                color="primary"
                variant="outlined"
                onClick={addLane}
                disabled={(scaleForEdit.lanes || []).length === 6}
              >
                Add
              </Button>
            </Box>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

LanesDetails.propTypes = {
  className: PropTypes.string,
};

export default LanesDetails;
