import React from 'react';
import InputSearchWithButton, {
  InputSearchWithButtonProps,
} from 'sharedComponents/InputSearchWithButton';
import { Button, Box, Grid } from '@mui/material';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { DateRangePicker } from 'rsuite';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import { useNavigate } from 'react-router-dom';
import ButtonConfirmWithLoading from 'sharedComponents/ButtonConfirmWithLoading';
import NotInterestedIcon from '@mui/icons-material/NotInterested';
import { ValueType as DateRangeValueType } from 'rsuite/lib/DateRangePicker';

const { afterToday } = DateRangePicker;

export interface ToolbarManifestsPerScaleProps {
  scaleId: string;
  showOnlyIncoming?: boolean;
  searchQuery: string;
  onSearchCallback: InputSearchWithButtonProps['onSearchCallback'];
  isLoading?: boolean;
  handleClickNextCallback?: React.MouseEventHandler<HTMLButtonElement>;
  handleClickBackCallback?: React.MouseEventHandler<HTMLButtonElement>;
  handleClickVoidCallback?: () => any;
  dateRangeFilter?: DateRangeValueType;
  setDateRangeFilterCallback: (newRange?: DateRangeValueType) => any;
  filterIsDigital: boolean | null;
  onChangeDigitalFilter?: (v: { isDigital: null | boolean }) => any; // TODO: simplify this callback value
  selectedRowsIds: string[];
}
const ToolbarManifestsPerScale = ({
  scaleId,
  showOnlyIncoming,
  searchQuery,
  onSearchCallback,
  isLoading,
  handleClickNextCallback,
  handleClickBackCallback,
  handleClickVoidCallback,
  dateRangeFilter,
  setDateRangeFilterCallback,
  filterIsDigital,
  onChangeDigitalFilter,
  selectedRowsIds,
}: ToolbarManifestsPerScaleProps) => {
  const navigate = useNavigate();

  const onDateRangeUsedChange = (newDateRange: DateRangeValueType) => {
    if (newDateRange.length) {
      setDateRangeFilterCallback(newDateRange);
    } else {
      setDateRangeFilterCallback(undefined);
    }
  };

  const handleClickScaleMap = () => {
    navigate(`/scales/${scaleId}/map`);
  };

  const toggleFilter = (value: null | boolean) => {
    if (onChangeDigitalFilter) {
      onChangeDigitalFilter({
        isDigital: value,
      });
    }
  };

  const getFilterButtonColor = (filter: null | boolean, primaryFilterValue: null | boolean) => {
    if (
      filter === primaryFilterValue ||
      JSON.stringify(filter) === JSON.stringify(primaryFilterValue)
    ) {
      return 'secondary';
    } else {
      return 'inherit';
    }
  };

  return (
    <Grid container spacing={2} alignItems="center">
      {showOnlyIncoming && (
        <Grid item md={handleClickVoidCallback ? 6 : 8} sm={6} xs={12}>
          <Box display="flex" justifyContent="flex-start" alignItems="center">
            <Box>
              <Button
                disabled={isLoading}
                variant="contained"
                color={getFilterButtonColor(filterIsDigital, null)}
                onClick={() => {
                  toggleFilter(null);
                }}
              >
                All
              </Button>
            </Box>
            <Box marginLeft={1}>
              <Button
                disabled={isLoading}
                variant="contained"
                color={getFilterButtonColor(filterIsDigital, false)}
                onClick={() => {
                  toggleFilter(false);
                }}
              >
                Manual
              </Button>
            </Box>
            <Box marginLeft={1}>
              <Button
                disabled={isLoading}
                variant="contained"
                color={getFilterButtonColor(filterIsDigital, true)}
                onClick={() => {
                  toggleFilter(true);
                }}
              >
                Digital
              </Button>
            </Box>
          </Box>
        </Grid>
      )}
      <Grid item md={4} sm={handleClickVoidCallback ? 4 : 6} xs={12}>
        <Box>
          <InputSearchWithButton
            onSearchCallback={onSearchCallback}
            disabled={isLoading}
            placeholder="Profile #, Manifest #, Truck #, Carrier..."
            searchFilter={searchQuery}
          />
        </Box>
      </Grid>
      {handleClickVoidCallback && (
        <Grid item sm={2} xs={12}>
          <Box>
            <ButtonConfirmWithLoading
              Pictogram={NotInterestedIcon}
              text="Void"
              textConfirm="Click again to void!"
              confirmCallback={handleClickVoidCallback}
              color="error"
              disabled={!selectedRowsIds.length}
            />
          </Box>
        </Grid>
      )}
      <Grid item sm={4} xs={12}>
        <Box display="flex" justifyContent="flex-start" alignItems="center">
          <Box marginRight={1}>
            <Button
              variant="contained"
              color="inherit"
              disabled={isLoading}
              onClick={handleClickBackCallback}
            >
              <Box display="flex" alignItems="center">
                <NavigateBeforeIcon fontSize="small" />
                Back
              </Box>
            </Button>
          </Box>
          <Button
            variant="contained"
            color="inherit"
            disabled={isLoading}
            onClick={handleClickNextCallback}
          >
            <Box display="flex" alignItems="center">
              Next
              <NavigateNextIcon fontSize="small" />
            </Box>
          </Button>
        </Box>
      </Grid>
      <Grid item sm={6} xs={9}>
        <Box display="flex" alignItems="center">
          <DateRangePicker
            appearance="default"
            placeholder="Show used between"
            placement="bottomStart"
            size="lg"
            value={dateRangeFilter || []}
            onChange={onDateRangeUsedChange}
            style={{ width: 260 }}
            disabled={isLoading}
            disabledDate={afterToday()}
          />
        </Box>
      </Grid>
      <Grid item sm={2} xs={3}>
        {showOnlyIncoming && (
          <Button variant="outlined" onClick={handleClickScaleMap} fullWidth>
            <LocationOnIcon fontSize="small" /> <Box marginLeft={1}>Map</Box>
          </Button>
        )}
      </Grid>
    </Grid>
  );
};

export default ToolbarManifestsPerScale;
