import { createSlice } from '@reduxjs/toolkit';

export const storeSlice = createSlice({
  name: 'drivers',
  initialState: {
    selectedProject: null,
    customData: null,
  },
  reducers: {
    setSelectedProject: (state, action) => {
      state.selectedProject = action.payload;
    },
    setCustomData: (state, action) => {
      state.customData = action.payload;
    },
  },
});

export const { setSelectedProject, setCustomData } = storeSlice.actions;

export const selectSelectedProject = state => state.drivers.selectedProject;
export const selectCustomData = state => state.drivers.customData;

export default storeSlice.reducer;
