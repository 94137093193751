import React from 'react';
import { Box, SvgIcon, TextField } from '@mui/material';
import { ArrowBackIosNew, ArrowForwardIos } from '@mui/icons-material';
import moment from 'moment';

interface props {
  setDate: (date: string) => void;
  dateValue: string;
}

const DateWithArrows = ({ setDate, dateValue }: props) => {
  const handleNextDayClick = () => {
    setDate(moment(dateValue).add(1, 'd').format('YYYY-MM-DD'));
  };

  const handlePreviousDayClick = () => {
    setDate(moment(dateValue).subtract(1, 'd').format('YYYY-MM-DD'));
  };

  return (
    <Box
      display="flex"
      flexDirection="row"
      alignItems="center"
      justifyContent="center"
      sx={{ backgroundColor: '#BFC8C4', borderRadius: '5px', mx: 1 }}
    >
      <SvgIcon
        fontSize="small"
        sx={{ mx: 0.5, cursor: 'pointer' }}
        onClick={handlePreviousDayClick}
      >
        <ArrowBackIosNew sx={{ color: 'neutral.main' }} />
      </SvgIcon>
      <TextField
        type="date"
        value={dateValue || []}
        onChange={e => setDate(e.target.value)}
        sx={{
          my: 0.5,
          '.MuiOutlinedInput-input': {
            height: '4px',
          },
          '.MuiOutlinedInput-root': {
            borderRadius: 0,
            fontSize: '14px',
            fontWeight: '600',
            color: '#97C06B',
          },
        }}
      />
      <SvgIcon fontSize="small" sx={{ mx: 0.5, cursor: 'pointer' }} onClick={handleNextDayClick}>
        <ArrowForwardIos sx={{ color: 'neutral.main' }} />
      </SvgIcon>
    </Box>
  );
};

export default DateWithArrows;
