import React, { useState, useEffect, useMemo } from 'react';
import 'sass/globals.scss';
import {
  Container,
  Grid,
  Box,
  Button,
  CircularProgress,
  Typography,
  Card,
  Autocomplete,
  TextField,
} from '@mui/material';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import Page from 'sharedComponents/Page';
import { useParams, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import ScaleDetails from 'sharedComponents/ScaleDetails';
import LanesDetails from '../scales/admins/LanesDetails';
import { selectScaleForEdit, setScaleForEdit } from 'modules/scales/storeSliceScales';

import { hasErrorRequired } from 'services/logic/formValidation';
import useShowError from 'modules/errors';
import CompaniesApi from 'services/postgres/CompaniesApi';
import Query from 'services/firebase/Query';
import Command from 'services/firebase/Command';
import Executer from 'services/firebase/Executer';
import ErrorMessages from 'services/constants/errorMessages';
import { EditExternalApiMappings } from 'sharedComponents/EditExternalApiMappings';
import QueryBase from 'lg-helpers/dist/firestore/query/QueryBase';

const { isRequired } = ErrorMessages;

const query = new Query();
const command = new Command();
const executer = new Executer();
const companiesApi = new CompaniesApi();

const ScaleView = () => {
  let { id } = useParams();
  const dispatch = useDispatch();
  const [isDisabledSaveDetails, setIsDisabledSaveDetails] = useState(true);
  const [isLoadingSaveDetails, setIsSavingSaveDetails] = useState(false);
  const [isSaveFailed, setIsSaveFailed] = useState(false);
  const [wasSubmitted, setWasSubmitted] = useState(false);
  const [isLoadingData, setIsLoadingData] = useState(false);
  const showError = useShowError();
  const [scale, setScale] = useState({});
  const [companies, setCompanies] = useState([]);
  const [isLoadingCompanies, setIsLoadingCompanies] = useState(false);
  const scaleForEdit = useSelector(selectScaleForEdit);
  const navigate = useNavigate();

  const selectedCompany = useMemo(
    () => companies.find(company => company.id === scale?.companyId) || null,
    [companies, scale?.companyId]
  );

  useEffect(() => {
    const getScaleCompanies = async () => {
      try {
        setIsLoadingCompanies(true);
        const scaleCompaniesResult = await companiesApi.getCompaniesWithCustomDataProperties(
          ['regions', 'externalApiPropertyNamesForMappingsInScales'],
          ['scale']
        );
        setCompanies(scaleCompaniesResult);
        setIsLoadingCompanies(false);
      } catch (error) {
        console.error('Error', error);
        showError({
          title: 'Error while fetching the companies',
          duration: 10000,
        });
        setIsLoadingCompanies(false);
      }
    };
    getScaleCompanies();
  }, [showError]);

  useEffect(() => {
    if (!id) {
      return;
    }

    const getScale = async scaleId => {
      setIsLoadingData(true);
      try {
        const scaleResult = await executer.getSingleDocument(
          query.base.getById(QueryBase.SCALES_COLLECTION(), scaleId)
        );
        setScale(scaleResult);
        dispatch(setScaleForEdit(scaleResult));
        if (!scaleResult) {
          showError({ title: "This scale doesn't exist", duration: 10000 });
        }
      } catch (error) {
        console.error('Error', error);
        showError({
          title: 'Error while fetching the scale',
          duration: 10000,
        });
      }
      setIsLoadingData(false);
    };

    getScale(id);
    // eslint-disable-next-line
  }, [id, dispatch]);

  const handleSaveDetails = async () => {
    setIsSavingSaveDetails(true);
    setIsSaveFailed(false);

    if (!hasErrors()) {
      try {
        let scaleObj = scale;
        if (scaleForEdit) {
          scaleObj = {
            ...scale,
            lanes: scaleForEdit.lanes,
          };
        }

        if (id) {
          await command.base.set(QueryBase.SCALES_COLLECTION(), id, scaleObj);
        } else {
          const newScaleRef = await command.base.add(QueryBase.SCALES_COLLECTION(), scaleObj);
          navigate(`/super-admin/scale/${newScaleRef.id}`);
        }

        setIsDisabledSaveDetails(true);
      } catch (error) {
        console.error(error);
        setIsSaveFailed(true);
      }
    }

    setIsSavingSaveDetails(false);
    setWasSubmitted(true);
  };

  const onScaleDetailsUpdate = s => {
    setScale({
      ...scale,
      [s.targetName]: s.targetValue,
    });
    setIsDisabledSaveDetails(false);
    setWasSubmitted(false);
  };

  const handleClickBack = () => {
    navigate('/super-admin/scales');
  };

  const handleChange = event => {
    setScale({
      ...scale,
      [event.target.name]: event.target.value,
    });
    setIsDisabledSaveDetails(false);
    setWasSubmitted(false);
  };

  const handleChangeInExternalApiMappings = e => {
    setScale({
      ...scale,
      externalApiMappings: {
        ...scale.externalApiMappings,
        [e.target.name]: e.target.value,
      },
    });
  };

  const hasErrors = () => {
    return (
      hasErrorRequired(scale.companyId) ||
      hasErrorRequired(scale.regionId) ||
      hasErrorRequired(scale.name) ||
      hasErrorRequired(scale.country) ||
      hasErrorRequired(scale.address) ||
      hasErrorRequired(scale.city) ||
      hasErrorRequired(scale.state)
    );
  };

  return (
    <Page>
      <Card>
        <Container className="min-padding-and-height">
          <Grid container spacing={3}>
            <Grid item lg={12} md={12} xs={12}>
              <Box display="flex" justifyContent="space-between" alignItems="center">
                <Button onClick={handleClickBack} variant="outlined">
                  <NavigateBeforeIcon /> Back
                </Button>
                <Button
                  color="primary"
                  variant="contained"
                  onClick={handleSaveDetails}
                  disabled={isDisabledSaveDetails}
                >
                  Save
                  {isLoadingSaveDetails && (
                    <Box marginLeft={2}>
                      <CircularProgress color="inherit" />
                    </Box>
                  )}
                </Button>
                {isSaveFailed && (
                  <Typography variant="body1" color="error" marginLeft={10}>
                    Save failed!
                  </Typography>
                )}
              </Box>
            </Grid>

            {isLoadingData ? (
              <Grid item xs={12}>
                <Box display="flex" justifyContent="center" alignItems="center">
                  <CircularProgress color="inherit" />
                </Box>
              </Grid>
            ) : (
              <>
                <Grid item md={6} xs={12}>
                  <Autocomplete
                    fullWidth
                    required
                    options={companies || []}
                    getOptionLabel={option => option.name}
                    value={selectedCompany}
                    onChange={(event, value) =>
                      handleChange({
                        target: {
                          name: 'companyId',
                          value: value && value.id,
                        },
                      })
                    }
                    renderOption={(props, option) => (
                      <li {...props} key={option.id || ''}>
                        {option.name}
                      </li>
                    )}
                    renderInput={params => (
                      <TextField
                        {...params}
                        label="Company"
                        required
                        variant="outlined"
                        error={hasErrorRequired(scale.companyId, wasSubmitted)}
                        helperText={
                          hasErrorRequired(scale.companyId, wasSubmitted) ? isRequired : ''
                        }
                        InputProps={{
                          ...params.InputProps,
                          endAdornment: (
                            <>
                              {isLoadingCompanies && <CircularProgress color="inherit" size={20} />}
                              {params.InputProps.endAdornment}
                            </>
                          ),
                        }}
                      />
                    )}
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  {selectedCompany && (
                    <Autocomplete
                      fullWidth
                      required
                      disabled={!selectedCompany.regions}
                      options={selectedCompany.regions || []}
                      getOptionLabel={option => option.name}
                      value={
                        (selectedCompany.regions || []).find(
                          region => region.id === scale.regionId
                        ) || null
                      }
                      onChange={(event, value) =>
                        handleChange({
                          target: {
                            name: 'regionId',
                            value: value && value.id,
                          },
                        })
                      }
                      renderOption={(props, option) => (
                        <li {...props} key={option.id || ''}>
                          {option.name}
                        </li>
                      )}
                      renderInput={params => (
                        <TextField
                          {...params}
                          label="Region"
                          required
                          variant="outlined"
                          error={hasErrorRequired(scale.regionId, wasSubmitted)}
                          helperText={
                            hasErrorRequired(scale.regionId, wasSubmitted) ? isRequired : ''
                          }
                        />
                      )}
                    />
                  )}
                </Grid>
                {scale.id && (
                  <Grid item md={6} xs={12}>
                    <TextField
                      fullWidth
                      label="Scale Id"
                      disabled
                      value={scale.id || ''}
                      variant="outlined"
                    />
                  </Grid>
                )}
                <ScaleDetails
                  scale={scale}
                  onScaleUpdate={onScaleDetailsUpdate}
                  wasSubmitted={wasSubmitted}
                  regions={companies}
                  companies={companies}
                />
                {!!selectedCompany?.externalApiPropertyNamesForMappingsInScales?.length && (
                  <Grid item md={12} xs={12}>
                    <Grid container spacing={3}>
                      <Grid item xs={12}>
                        <Typography variant="h5">External API properties</Typography>
                        {selectedCompany.externalApiPropertyNamesForMappingsInScales.map(
                          property => (
                            <EditExternalApiMappings
                              readOnly={false}
                              propertyMapping={property}
                              changeObject={scale}
                              key={property.id}
                              handleChangeInExternalApiMappings={handleChangeInExternalApiMappings}
                            />
                          )
                        )}
                      </Grid>
                    </Grid>
                  </Grid>
                )}
              </>
            )}
          </Grid>
          <Grid marginTop={3}>
            <LanesDetails />
          </Grid>
        </Container>
      </Card>
    </Page>
  );
};

export default ScaleView;
