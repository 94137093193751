import { getPropertyValueByPath } from './object';

const ascendingComparator = (a, b, orderBy, caseInsensitive) => {
  let valueA, valueB;
  if (Array.isArray(orderBy)) {
    valueA = orderBy.filter(e => getPropertyValueByPath(a, e)).join('') || '';
    valueB = orderBy.filter(e => getPropertyValueByPath(b, e)).join('') || '';
  } else {
    valueA = getPropertyValueByPath(a, orderBy) || '';
    valueB = getPropertyValueByPath(b, orderBy) || '';
  }

  if (caseInsensitive) {
    if (valueA === null || valueA === '') {
      valueA = '';
    }

    if (valueB === null || valueB === '') {
      valueB = '';
    }

    valueA = valueA.toLowerCase();
    valueB = valueB.toLowerCase();
  }

  if (valueB > valueA) {
    return -1;
  }

  if (valueB < valueA) {
    return 1;
  }
  return 0;
};

const getComparator = (order, orderBy, caseInsensitive) => {
  return order === 'asc'
    ? (a, b) => ascendingComparator(a, b, orderBy, caseInsensitive)
    : (a, b) => -ascendingComparator(a, b, orderBy, caseInsensitive);
};

const stableSort = (array, comparator) => {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map(el => el[0]);
};

const addOrRemoveSelectedIdIntoSelectedIdsArray = (id, selectedIdsArray) => {
  const selectedIndex = selectedIdsArray.indexOf(id);
  let newlySelectedIdsArray = [];

  if (selectedIndex === -1) {
    newlySelectedIdsArray = newlySelectedIdsArray.concat(selectedIdsArray, id);
  } else if (selectedIndex === 0) {
    newlySelectedIdsArray = newlySelectedIdsArray.concat(selectedIdsArray.slice(1));
  } else if (selectedIndex === selectedIdsArray.length - 1) {
    newlySelectedIdsArray = newlySelectedIdsArray.concat(selectedIdsArray.slice(0, -1));
  } else if (selectedIndex > 0) {
    newlySelectedIdsArray = newlySelectedIdsArray.concat(
      selectedIdsArray.slice(0, selectedIndex),
      selectedIdsArray.slice(selectedIndex + 1)
    );
  }

  return newlySelectedIdsArray;
};

const reduceToNameIdCommaSeparatedHtmlString = array => {
  if (!array || !array.length) {
    return '-';
  }
  return array.reduce((accumulator, currentValue) => {
    if (accumulator) {
      return `${accumulator},<br/>${currentValue.name} - ${currentValue.id}`;
    } else {
      return `${currentValue.name} - ${currentValue.id}`;
    }
  }, '');
};

export {
  getComparator,
  stableSort,
  addOrRemoveSelectedIdIntoSelectedIdsArray,
  reduceToNameIdCommaSeparatedHtmlString,
};
