import React from 'react';
import { DialogContent } from '@mui/material';

import AcitivtyTypeForm from './ActivityTypeForm';
import ModalHeader from 'sharedComponents/DialogHeader';
import { IActivityType } from 'lg-helpers/dist/shared/interfaces/IActivityType';

interface ModalContainerProps {
  selected?: IActivityType;
  projectId?: number;
}

const ModalContainer = ({ selected, projectId }: ModalContainerProps) => {
  return (
    <>
      <ModalHeader title={'Work Category'} />
      <DialogContent>
        <AcitivtyTypeForm selected={selected} projectId={projectId} />
      </DialogContent>
    </>
  );
};

export default ModalContainer;
