import { UserTypeGenerated } from 'modules/auth/logicDependentOnUserRole';
import useShowError from 'modules/errors';
import { useEffect, useMemo, useState } from 'react';
import CompaniesApi from 'services/postgres/CompaniesApi';

const companiesApi = new CompaniesApi();

type Company = any;

type CompaniesByTypes = Partial<Record<UserTypeGenerated, Company[]>>;

const useCompaniesByTypes = (
  types?: UserTypeGenerated[]
): CompaniesByTypes & { loading: boolean; companies: Company[] } => {
  const showError = useShowError();
  const [companies, setCompanies] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState(true);

  const byType = useMemo(() => {
    const _byType: CompaniesByTypes = {};
    for (const company of companies) {
      for (const cType of company.types || []) {
        const type = cType as UserTypeGenerated;
        _byType[type] = _byType[type] || [];
        _byType[type]!.push(company);
      }
    }
    return _byType;
  }, [companies]);

  useEffect(() => {
    const getAllCompanies = async () => {
      try {
        setIsLoading(true);
        // TODO: hotfixed with excluded types, but needs fix/optimisation in future
        const companiesResult = await companiesApi.getCompaniesWithCustomDataProperties(
          null,
          types
        );
        setCompanies(companiesResult);
      } catch (error) {
        console.error('Error', error);
        showError({
          title: 'Error while fetching companies',
          duration: 10000,
        });
      } finally {
        setIsLoading(false);
      }
    };
    getAllCompanies();
  }, [showError, types]);

  return { ...byType, loading: isLoading, companies };
};

export default useCompaniesByTypes;
