import { createSlice } from '@reduxjs/toolkit';
import {
  addProject,
  getProjects,
  getProjectTemplates,
  setActiveProject,
  getProjectTemplateDetails,
  getProjectCompanyCollaborators,
  addCompanyCollaborator,
  removeCompanyCollaborator,
  getLocations,
  addLocation,
  removeLocation,
  getProjectUsersCollaborators,
  addUserCollaborator,
  updateLocation,
} from './actions';
import { TFormStatus } from 'lg-helpers/dist/shared/types/TFormStatus';
import { ILocation } from 'lg-helpers/dist/shared/interfaces/ILocation';
import { ITicketSummary } from 'lg-helpers/dist/shared/interfaces/ITicketSummary';
import { IProjectTemplate } from 'lg-helpers/dist/shared/interfaces/IProjectTemplate';
import {
  IProjectCompanyCollaborator,
  IProjectUserCollaborator,
} from 'lg-helpers/dist/shared/interfaces/IProjectCollaborator';
import { IProjectTemplateDetail } from 'lg-helpers/dist/shared/interfaces/IProjectTemplateDetail';
import { IProject } from 'lg-helpers/dist/shared/interfaces/IProject';

interface IProjectState {
  data: IProject[];
  status: TFormStatus;
  ticketSummaries: ITicketSummary[];
  activeProject?: IProject;
  templates: IProjectTemplate[];
  templateDetails: IProjectTemplateDetail[];
  companyCollaborators: IProjectCompanyCollaborator[];
  userCollaborators: IProjectUserCollaborator[];
  locations: ILocation[];
}

const initialState: IProjectState = {
  data: [],
  status: null,
  ticketSummaries: [],
  activeProject: undefined,
  templates: [],
  templateDetails: [],
  companyCollaborators: [],
  userCollaborators: [],
  locations: [],
};

export const projectsSlice = createSlice({
  name: 'projects',
  reducers: {
    add: state => state,
    get: state => state,
  },
  initialState,
  extraReducers: builder => {
    builder.addCase(setActiveProject.fulfilled, (state, { payload }) => {
      state.status = 'success';
      state.activeProject = payload as IProject;
    });
    builder.addCase(getProjects.pending, () => {});
    builder.addCase(getProjects.fulfilled, (state, action) => {
      state.data = action.payload as IProject[];
    });
    builder.addCase(addProject.fulfilled, (state, { payload }) => {
      state.data.push(payload as IProject);
      state.activeProject = payload;
    });
    builder.addCase(getProjectTemplates.pending, () => {});
    builder.addCase(getProjectTemplates.fulfilled, (state, action) => {
      state.templates = action.payload as IProjectTemplate[];
    });
    builder.addCase(getProjectTemplateDetails.fulfilled, (state, action) => {
      state.templateDetails = action.payload as IProjectTemplateDetail[];
    });
    builder.addCase(getProjectCompanyCollaborators.fulfilled, (state, action) => {
      state.companyCollaborators = action.payload as IProjectCompanyCollaborator[];
    });
    builder.addCase(addCompanyCollaborator.fulfilled, (state, action) => {
      state.companyCollaborators.push(action.payload as IProjectCompanyCollaborator);
    });
    builder.addCase(removeCompanyCollaborator.fulfilled, (state, action) => { // eslint-disable-line
      state.status = 'success';
    });
    builder.addCase(getLocations.fulfilled, (state, action) => {
      state.status = 'success';
      state.locations = action.payload as ILocation[];
    });
    builder.addCase(addLocation.fulfilled, (state, action) => {
      state.locations.push(action.payload as ILocation);
    });
    builder.addCase(removeLocation.fulfilled, (state, action) => {
      state.status = 'success';
      state.locations = state.locations.filter(
        item => item.civilLocationToProjectId !== action.payload
      );
    });
    builder.addCase(getProjectUsersCollaborators.fulfilled, (state, action) => {
      state.status = 'success';
      state.userCollaborators = action.payload as IProjectUserCollaborator[];
    });
    builder.addCase(addUserCollaborator.fulfilled, (state, action) => {
      state.userCollaborators.push(action.payload as IProjectUserCollaborator);
    });
    builder.addCase(updateLocation.fulfilled, (state, action) => {
      state.status = 'success';
      const { sender, id, geo, name } = action.payload!;
      if (sender === 'primary') {
        state.activeProject = {
          ...state.activeProject!,
          geo: geo,
          addressName: name,
        };
      }
      if (sender === 'locations') {
        const index = state.locations.findIndex(a => a.id === id);
        state.locations[index] = {
          ...state.locations[index],
          geo: geo,
          name,
        };
      }
    });
  },
});
