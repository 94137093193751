const debug = false;

const cacheTable: Map<
  string,
  {
    time: number;
    expireTimeDelta: number;
    data: unknown;
  }
> = new Map();

export const addCacheItem = (
  key: string,
  data: unknown,
  expireTimeDelta = 60000 // 1 minute cache
) => {
  if (debug) console.log('add to cache', key);
  cacheTable.set(key, { data, time: Date.now(), expireTimeDelta });
};

export const getCacheItem = <T extends any>(key: string): T | undefined => {
  const currentItem = cacheTable.get(key);

  if (!currentItem) return undefined;

  if (currentItem.time + currentItem.expireTimeDelta < Date.now()) {
    // cache expired
    deleteCacheItem(key);
    return undefined;
  }

  if (debug) console.log('return from cache', key);

  return currentItem.data as T;
};

export const deleteCacheItem = (key: string) => {
  if (debug) console.log('delete cache item', key, cacheTable.has(key));
  cacheTable.delete(key);
};
