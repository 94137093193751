import React, { useState } from 'react';
import {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TextField,
  Typography,
  Paper,
  Button,
  MenuItem,
  Autocomplete,
  Grid,
} from '@mui/material';
import { useSelector } from 'react-redux';
import { selectCompanyMaterials } from 'modules/civilAndTrucking/trucking/features/CompanyMaterials/redux/companyMaterialsStoreSlice';
import { selectTruckTypes } from 'modules/civilAndTrucking/trucking/features/TruckTypes/redux/truckTypesStoreSlice';
import { Loading } from 'sharedComponents/Loading';
import MaterialsDropdown from './MaterialsDropdown';
import { AddCircle } from '@mui/icons-material';
import { thunkDispatch } from 'store/store';
import { unitsOfMeasure } from 'lg-helpers/dist/constants/global-constants';
import Query from 'services/firebase/Query';
import Executer from 'services/firebase/Executer';
import { ButtonOptionsMenu } from 'sharedComponents/ButtonOptionsMenu';
import {
  upsertProjectMaterial,
  deleteProjectMaterial,
} from 'modules/civilAndTrucking/trucking/features/ProjectMaterials/redux/actions';
import { upsertCompanyMaterial } from 'modules/civilAndTrucking/trucking/features/CompanyMaterials/redux/actions';
import { InputAdornment } from '@mui/material';
import { ITruckingProjectMaterial } from 'lg-helpers/dist/trucking/interfaces/ITruckingProjectMaterial';
import api, {
  CompanyConnectionWithCompany,
  ConnectedCompanyType,
} from 'services/api/autogenerated';
import useApiGetRequest from 'services/api/useApiGetRequest';
import fuzzyFilterOptions from 'utils/fuzzyFilterOptions';

const query = new Query();
const executer = new Executer();

interface TableProjectMaterial extends Partial<ITruckingProjectMaterial> {
  editing?: boolean;
  newMaterialName?: string;
}

interface MaterialOptionType {
  label: string;
  value?: number;
  inputValue?: string;
}

type ProjectMaterialsProps = {
  existingProjectMaterials: ITruckingProjectMaterial[];
  selectedContractorId: string;
  projectId: number;
};

const supplierFilterOptions = fuzzyFilterOptions<CompanyConnectionWithCompany>([
  'connected_company.name',
]);

export const ProjectMaterialsTable = ({
  existingProjectMaterials,
  selectedContractorId,
  projectId,
}: ProjectMaterialsProps) => {
  const [projectMaterials, setProjectMaterials] = useState(
    existingProjectMaterials as TableProjectMaterial[]
  );
  const [profileNumbers, setProfileNumbers] = useState<string[]>([]);

  const { truckTypes, truckTypesStatus } = useSelector(selectTruckTypes);
  const { companyMaterials, companyMaterialsStatus } = useSelector(selectCompanyMaterials);

  // TODO: this list could be large (optimize in future)
  const [_sourceSuppliers, { status: sourceSuppliersStatus }] = useApiGetRequest(
    () =>
      api.companiesConnections.getConnectedCompaniesByTypes([
        ConnectedCompanyType.SourceSupplier,
        ConnectedCompanyType.TruckingSourceSupplier,
      ]),
    () => ({ title: 'Failed to load source suppliers' })
  );
  const sourceSuppliers = _sourceSuppliers || [];

  if (
    sourceSuppliersStatus === 'pending' ||
    companyMaterialsStatus === 'pending' ||
    truckTypesStatus === 'pending'
  ) {
    return <Loading />;
  }

  const addNewProjectMaterial = () => {
    setProjectMaterials([...projectMaterials, { editing: true }]);
  };

  const materialsOptions: MaterialOptionType[] = companyMaterials.map(material => ({
    label: material.nameTruckingCompanyMaterial,
    value: material.idTruckingCompanyMaterial,
  }));

  const calculateSellPrice = (materialRate = 0, markupPercent = 0, truckRate = 0) => {
    const sellPrice = materialRate * (1 + markupPercent / 100) + truckRate;
    return Math.floor(sellPrice * 100) / 100;
  };

  const handleChange = (
    name: string,
    value: string | number | boolean | undefined,
    index: number
  ) => {
    let updateMaterial = {
      ...projectMaterials[index],
      [name]: value,
    };

    updateMaterial.sellPriceTruckingProjectMaterial = calculateSellPrice(
      Number(updateMaterial.materialRateTruckingProjectMaterial),
      Number(updateMaterial.materialMarkupTruckingProjectMaterial),
      Number(updateMaterial.truckRateTruckingProjectMaterial)
    );

    setProjectMaterials([
      ...projectMaterials.slice(0, index),
      updateMaterial,
      ...projectMaterials.slice(index + 1),
    ]);
  };

  const handleDeleteProjectMaterial = (projectMaterial: TableProjectMaterial) => {
    const { projectIdTruckingProjectMaterial, idTruckingProjectMaterial } = projectMaterial;
    if (!projectIdTruckingProjectMaterial || !idTruckingProjectMaterial) return;
    thunkDispatch(
      deleteProjectMaterial({
        projectMaterialId: idTruckingProjectMaterial,
        projectId: projectIdTruckingProjectMaterial,
      })
    );
  };

  const generateMenuOptions = (projectMaterial: TableProjectMaterial, index: number) => [
    {
      title: 'Edit',
      description: 'Edit this project material',
      onClick: () => handleChange('editing', true, index),
    },
    {
      divider: true,
    },
    {
      title: 'Delete',
      description: `Delete this project material`,
      color: '#EF0048',
      onClick: () => handleDeleteProjectMaterial(projectMaterial),
    },
  ];

  const fetchProfileNumbers = async (selectedSourceId: string) => {
    const selectedSource = sourceSuppliers.find(source => source.id === Number(selectedSourceId));
    if (selectedSource?.connected_company_id) {
      const scaleId = selectedSource.connected_company_id;
      const projects = await executer.getMultipleDocuments(
        query.projects.getProjectsByContractorAndScale(selectedContractorId, scaleId)
      );
      if (projects.length) {
        setProfileNumbers(projects.map((project: any) => project?.profileNr) as string[]);
      } else {
        setProfileNumbers([]);
      }
    } else {
      setProfileNumbers([]);
    }
  };

  const saveProjectMaterial = async (projectMaterial: TableProjectMaterial, i: number) => {
    const { editing, newMaterialName, ...projectMaterialReq } = projectMaterial;
    if (!editing) return;

    try {
      if (newMaterialName) {
        const res = await thunkDispatch(
          upsertCompanyMaterial({
            nameTruckingCompanyMaterial: newMaterialName,
          })
        );
        const companyMaterialId = Number(res.payload.id);
        projectMaterialReq.companyMaterialIdTruckingProjectMaterial = companyMaterialId;
      }

      projectMaterialReq.projectIdTruckingProjectMaterial = String(projectId);
      await thunkDispatch(
        upsertProjectMaterial({
          ...projectMaterialReq,
          // Fixes FOOT-1454 (but maybe it's better to make these fields required)
          materialRateTruckingProjectMaterial:
            projectMaterialReq.materialRateTruckingProjectMaterial || 0,
          materialMarkupTruckingProjectMaterial:
            projectMaterialReq.materialMarkupTruckingProjectMaterial || 0,
          truckRateTruckingProjectMaterial:
            projectMaterialReq.truckRateTruckingProjectMaterial || 0,
          sellPriceTruckingProjectMaterial:
            projectMaterialReq.sellPriceTruckingProjectMaterial || 0,
        })
      );
      setProjectMaterials([
        ...projectMaterials.slice(0, i),
        projectMaterialReq,
        ...projectMaterials.slice(i + 1),
      ]);
      // handleChange('editing', false, i);
    } catch (err) {
      console.error('Error creating project material', err);
    }
  };

  return (
    <>
      <Typography variant="h5">Create Project Materials</Typography>
      <TableContainer sx={{ mt: 2 }} component={Paper}>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell align="center">Material</TableCell>
              <TableCell align="center">Source Supplier</TableCell>
              <TableCell align="center">Profile No.</TableCell>
              <TableCell align="center">Truck Type</TableCell>
              <TableCell align="center">Unit</TableCell>
              <TableCell align="center">Material Rate</TableCell>
              <TableCell align="center">Mark Up</TableCell>
              <TableCell align="center">Truck rate</TableCell>
              <TableCell align="center">Sell price</TableCell>
              <TableCell align="center"></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {projectMaterials.map((projectMaterial, i) => {
              return (
                <TableRow key={i} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                  <TableCell align="center" sx={{ width: 150, px: 0.5 }}>
                    {projectMaterial.editing ? (
                      <MaterialsDropdown
                        index={i}
                        companyMaterialId={
                          projectMaterial?.companyMaterialIdTruckingProjectMaterial
                        }
                        materialsOptions={materialsOptions}
                        handleChange={handleChange}
                      />
                    ) : (
                      companyMaterials.find(
                        material =>
                          material.idTruckingCompanyMaterial ===
                          projectMaterial.companyMaterialIdTruckingProjectMaterial
                      )?.nameTruckingCompanyMaterial
                    )}
                  </TableCell>
                  <TableCell align="center" sx={{ width: 150, px: 0.5 }}>
                    {(() => {
                      const supplier = sourceSuppliers.find(
                        s =>
                          s.id ===
                          Number(projectMaterial.companySourceSupplierIdTruckingProjectMaterial)
                      );

                      return projectMaterial.editing ? (
                        <Autocomplete
                          freeSolo
                          size="small"
                          value={supplier}
                          options={sourceSuppliers}
                          isOptionEqualToValue={(option, value: any) =>
                            option.connected_company_id === value.connected_company_id
                          }
                          filterOptions={supplierFilterOptions}
                          getOptionLabel={option =>
                            typeof option === 'string' ? option : option.connected_company.name!
                          }
                          onChange={(event, val: any) => {
                            const value = val?.id || '';
                            handleChange(
                              'companySourceSupplierIdTruckingProjectMaterial',
                              value,
                              i
                            );
                            fetchProfileNumbers(value);
                          }}
                          renderInput={params => (
                            <TextField
                              {...params}
                              placeholder="Search company"
                              variant="outlined"
                              inputProps={{
                                ...params.inputProps,
                                onKeyDown: e => {
                                  if (e.key === 'Enter') {
                                    e.stopPropagation();
                                  }
                                },
                              }}
                            />
                          )}
                          renderOption={(props, option) => (
                            <li {...props} key={option.id}>
                              <Grid
                                container
                                alignItems="center"
                                justifyContent="flex-start"
                                wrap="nowrap"
                                title={option.connected_company.name}
                              >
                                <Grid item xs={6}>
                                  <Typography noWrap>{option.connected_company.name}</Typography>
                                </Grid>
                              </Grid>
                            </li>
                          )}
                        />
                      ) : (
                        supplier?.connected_company?.name
                      );
                    })()}
                  </TableCell>
                  <TableCell align="center" sx={{ width: 120, px: 0.5 }}>
                    {projectMaterial.editing ? (
                      <TextField
                        required
                        select
                        size="small"
                        name="profileNumberTruckingProjectMaterial"
                        value={projectMaterials[i].profileNumberTruckingProjectMaterial || ''}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                          handleChange('profileNumberTruckingProjectMaterial', e.target.value, i)
                        }
                      >
                        {profileNumbers.map(profileNumber => (
                          <MenuItem key={profileNumber} value={profileNumber}>
                            {profileNumber}
                          </MenuItem>
                        ))}
                      </TextField>
                    ) : (
                      projectMaterial.profileNumberTruckingProjectMaterial
                    )}
                  </TableCell>
                  <TableCell align="center" sx={{ width: 120, px: 0.5 }}>
                    {projectMaterial.editing ? (
                      <TextField
                        required
                        select
                        size="small"
                        name="truckTypeIdTruckingProjectMaterial"
                        value={projectMaterials[i].truckTypeIdTruckingProjectMaterial || ''}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                          handleChange('truckTypeIdTruckingProjectMaterial', e.target.value, i)
                        }
                      >
                        {truckTypes.map(truckType => (
                          <MenuItem
                            key={truckType.idTruckingTruckType}
                            value={truckType.idTruckingTruckType}
                          >
                            {truckType.nameTruckingTruckType}
                          </MenuItem>
                        ))}
                      </TextField>
                    ) : (
                      truckTypes.find(
                        truckType =>
                          truckType.idTruckingTruckType ===
                          projectMaterial.truckTypeIdTruckingProjectMaterial
                      )?.nameTruckingTruckType
                    )}
                  </TableCell>
                  <TableCell align="center" sx={{ width: 100, px: 0.5 }}>
                    {projectMaterial.editing ? (
                      <TextField
                        required
                        select
                        size="small"
                        name="uomTruckingProjectMaterial"
                        value={projectMaterials[i].uomTruckingProjectMaterial || ''}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                          handleChange('uomTruckingProjectMaterial', e.target.value, i)
                        }
                      >
                        {unitsOfMeasure.map(unit => (
                          <MenuItem key={unit} value={unit}>
                            {unit}
                          </MenuItem>
                        ))}
                      </TextField>
                    ) : (
                      projectMaterial.uomTruckingProjectMaterial
                    )}
                  </TableCell>
                  <TableCell align="center" sx={{ width: 80, px: 0.5 }}>
                    {projectMaterial.editing ? (
                      <TextField
                        required
                        type="number"
                        size="small"
                        name="materialRateTruckingProjectMaterial"
                        InputProps={{
                          startAdornment: <InputAdornment position="start">$</InputAdornment>,
                        }}
                        value={projectMaterials[i].materialRateTruckingProjectMaterial || ''}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                          handleChange(
                            'materialRateTruckingProjectMaterial',
                            Number(e.target.value),
                            i
                          )
                        }
                      />
                    ) : (
                      projectMaterial.materialRateTruckingProjectMaterial
                    )}
                  </TableCell>
                  <TableCell align="center" sx={{ width: 80, px: 0.5 }}>
                    {projectMaterial.editing ? (
                      <TextField
                        required
                        type="number"
                        size="small"
                        name="materialMarkupTruckingProjectMaterial"
                        InputProps={{
                          endAdornment: <InputAdornment position="start">%</InputAdornment>,
                        }}
                        value={projectMaterials[i].materialMarkupTruckingProjectMaterial || ''}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                          handleChange(
                            'materialMarkupTruckingProjectMaterial',
                            Number(e.target.value),
                            i
                          )
                        }
                      />
                    ) : (
                      projectMaterial.materialMarkupTruckingProjectMaterial
                    )}
                  </TableCell>
                  <TableCell align="center" sx={{ width: 80, px: 0.5 }}>
                    {projectMaterial.editing ? (
                      <TextField
                        required
                        type="number"
                        size="small"
                        name="truckRateTruckingProjectMaterial"
                        InputProps={{
                          startAdornment: <InputAdornment position="start">$</InputAdornment>,
                        }}
                        value={projectMaterials[i].truckRateTruckingProjectMaterial || ''}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                          handleChange(
                            'truckRateTruckingProjectMaterial',
                            Number(e.target.value),
                            i
                          )
                        }
                      />
                    ) : (
                      projectMaterial.truckRateTruckingProjectMaterial
                    )}
                  </TableCell>
                  <TableCell align="center" sx={{ width: 80, px: 0.5 }}>
                    <TextField
                      disabled
                      type="number"
                      size="small"
                      name="sellPriceTruckingProjectMaterial"
                      InputProps={{
                        startAdornment: <InputAdornment position="start">$</InputAdornment>,
                      }}
                      value={projectMaterials[i].sellPriceTruckingProjectMaterial || ''}
                    />
                  </TableCell>
                  <TableCell align="center" sx={{ width: 80, px: 0.5 }}>
                    {projectMaterial.editing ? (
                      <Button
                        color="primary"
                        variant="contained"
                        onClick={() => saveProjectMaterial(projectMaterial, i)}
                      >
                        Save
                      </Button>
                    ) : (
                      <ButtonOptionsMenu options={generateMenuOptions(projectMaterial, i)} />
                    )}
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
        <Button
          onClick={addNewProjectMaterial}
          color="primary"
          // variant="outlined"
          sx={{
            display: 'flex',
            ml: 2,
            py: 2,
            flexDirection: 'row',
            alignItems: 'center',
          }}
        >
          <Typography sx={{ mr: 1 }}>Add New Material</Typography>
          <AddCircle />
        </Button>
      </TableContainer>
    </>
  );
};
