import React, { useEffect, useRef } from 'react';
import mapboxgl, { MapboxEvent } from 'mapbox-gl';
import { Grid, SvgIcon } from '@mui/material';
import LocationSearchingIcon from '@mui/icons-material/LocationSearching';

interface IMapboxProps {
  zoom: number;
  center: mapboxgl.LngLatLike;
  markers: mapboxgl.Marker[];
  onMove: (ev: MapboxEvent<MouseEvent>) => void;
  style: object;
}

const Mapbox = ({ zoom, center, markers, onMove, style }: IMapboxProps) => {
  const mapRef = useRef<mapboxgl.Map>();
  mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_TOKEN!;

  useEffect(() => {
    mapRef.current = new mapboxgl.Map({
      container: 'mapContainer',
      style: 'mapbox://styles/mapbox/streets-v11',
      center,
      zoom,
    });

    (markers || []).forEach(marker => marker.addTo(mapRef.current!));
    // eslint-disable-next-line
  }, [markers, zoom]);

  useEffect(() => {
    if (!onMove) return;

    mapRef.current?.on('move', onMove);

    return function () {
      mapRef.current?.off('move', () => {});
    };
  }, [onMove]);

  return (
    <>
      <Grid container>
        <Grid item xs={12} display="flex" justifyContent="center" alignItems="center">
          <div id="mapContainer" style={style} />
          <SvgIcon sx={{ position: 'absolute' }}>
            <LocationSearchingIcon
              sx={{
                position: 'absolute',
                top: '50vh',
                right: '50vw',
              }}
            />
          </SvgIcon>
        </Grid>
      </Grid>
    </>
  );
};

export default Mapbox;
