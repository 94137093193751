import React, { useEffect, useState } from 'react';
import './TableCrud.scss';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  Card,
  CardActions,
  CardContent,
  Checkbox,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
  Box,
  Grid,
  CircularProgress,
} from '@mui/material';
import { getComparator, stableSort } from 'services/logic/array';
import { getPropertyValueByPath } from 'services/logic/object';
import { addOrRemoveSelectedIdIntoSelectedIdsArray } from 'services/logic/array';

export interface BaseRow {
  id: string;
}

export interface TableCrudColumn<T extends BaseRow> {
  path?: string;
  label: string;
  isSortable?: boolean;
  isVerticalHeaderText?: boolean;
  hasCustomClickHandler?: boolean;
  getCellValueCallback?: (row: T) => any;
}

export interface TableCrudProps<T extends BaseRow> {
  rows: T[];
  isDisabledSelectAll?: boolean;
  isLoading?: boolean;
  selectedRowsIds?: string[];
  setSelectedRowsIdsCallback?: (ids: string[]) => any;
  handleRowClickCallback: (row: T) => any;
  columns: TableCrudColumn<T>[];
  orderBy?: string; // TODO: it's possible to use key/path from T
  rowIsDisabledCallback?: (row: T) => boolean;
  checkboxColor: 'primary' | 'inherit';
  disabledIds?: string[];
  handleSortOrder?: (path: string) => any;
  orderDirection?: 'asc' | 'desc';
}

const TableCrud = <T extends BaseRow>({
  rows,
  isDisabledSelectAll,
  isLoading,
  selectedRowsIds,
  handleRowClickCallback,
  setSelectedRowsIdsCallback,
  columns,
  orderBy,
  rowIsDisabledCallback,
  checkboxColor,
  disabledIds = [],
  handleSortOrder,
  orderDirection,
}: TableCrudProps<T>) => {
  const [order, setOrder] = useState<'asc' | 'desc'>('desc');
  const [orderByInternal, setOrderByInternal] = useState<string | null>(null);
  const [rowsSorted, setRowsSorted] = useState<T[]>([]);

  useEffect(() => {
    setOrderByInternal(orderBy || null);
  }, [orderBy]);

  useEffect(() => {
    setOrder(orderDirection || 'asc');
  }, [orderDirection]);

  // TODO: rewrite with useMemo
  useEffect(() => {
    if (!rows) {
      return;
    }

    if (!orderByInternal) {
      setRowsSorted(rows);
      return;
    }
    if (!orderByInternal) {
      setRowsSorted(rows);
      return;
    }

    setRowsSorted(stableSort(rows, getComparator(order, orderByInternal)));
  }, [rows, order, orderByInternal]);

  const createSortHandler = (property: string) => () => {
    const isAsc = orderByInternal === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderByInternal(property);
  };

  const handleSelectOne = (id: string) =>
    setSelectedRowsIdsCallback!(
      addOrRemoveSelectedIdIntoSelectedIdsArray(id, selectedRowsIds || [])
    );

  const handleSelectAll = (e: any, checked: boolean) => {
    const rowIds = checked ? (rowsSorted || []).map(row => row.id) : [];
    setSelectedRowsIdsCallback!(rowIds);
  };

  return (
    <Card className="TableCrud">
      <CardContent>
        {isLoading ? (
          <Grid item lg={12} md={12} xs={12}>
            <Box display="flex" justifyContent="center" alignItems="center">
              <CircularProgress color="inherit" />
            </Box>
          </Grid>
        ) : (
          <PerfectScrollbar>
            <Table>
              <TableHead>
                <TableRow>
                  {setSelectedRowsIdsCallback && (
                    <TableCell padding="none">
                      <Checkbox
                        checked={
                          selectedRowsIds &&
                          !!selectedRowsIds.length &&
                          selectedRowsIds.length === rowsSorted.length
                        }
                        disabled={isDisabledSelectAll || !rowsSorted || !rowsSorted.length}
                        // TODO: understand why we need this color
                        // color={checkboxColor}
                        indeterminate={
                          selectedRowsIds &&
                          selectedRowsIds.length > 0 &&
                          selectedRowsIds.length < rowsSorted.length
                        }
                        onChange={handleSelectAll}
                      />
                    </TableCell>
                  )}
                  {(columns || []).map((column, index) => (
                    <TableCell
                      padding="none"
                      key={index}
                      sortDirection={orderByInternal === column.path ? order : false}
                    >
                      <Box className="TableCrud__container--centered">
                        <TableSortLabel
                          hideSortIcon={!column.isSortable}
                          active={orderByInternal === column.path}
                          direction={orderByInternal === column.path ? order : 'asc'}
                          onClick={() =>
                            handleSortOrder
                              ? handleSortOrder(column.path!) // TODO: we need warning if path is empty
                              : column.isSortable && createSortHandler(column.path!)
                          }
                          className={column.isVerticalHeaderText ? 'TableCrud__vertical-text' : ''}
                        >
                          {column.label}
                          {orderByInternal === column.path && (
                            <span className="TableCrud__visually-hidden">
                              {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                            </span>
                          )}
                        </TableSortLabel>
                      </Box>
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {(rowsSorted || []).map(row => (
                  <TableRow
                    className={
                      disabledIds.indexOf(row.id) !== -1
                        ? 'TableCrud__table-row--disabled'
                        : checkboxColor === 'primary'
                        ? 'TableCrud__table-row--primary'
                        : 'TableCrud__table-row--secondary'
                    }
                    hover
                    key={row.id}
                    selected={(selectedRowsIds || []).indexOf(row.id) !== -1}
                  >
                    {setSelectedRowsIdsCallback && (
                      <TableCell padding="none">
                        <Checkbox
                          checked={(selectedRowsIds || []).indexOf(row.id) !== -1}
                          // TODO: understand why we need this color
                          // color={checkboxColor}
                          disabled={rowIsDisabledCallback && rowIsDisabledCallback(row)}
                          onChange={() => handleSelectOne(row.id)}
                        />
                      </TableCell>
                    )}
                    {(columns || []).map((column, index) => (
                      <TableCell
                        key={index}
                        onClick={() =>
                          !column.hasCustomClickHandler &&
                          handleRowClickCallback &&
                          handleRowClickCallback(row)
                        }
                        padding="none"
                      >
                        <Box className="TableCrud__container--centered" p={0.5}>
                          {column.getCellValueCallback ? (
                            column.getCellValueCallback(row)
                          ) : (
                            <Typography variant="body1">
                              {getPropertyValueByPath(row, column.path)}
                            </Typography>
                          )}
                        </Box>
                      </TableCell>
                    ))}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </PerfectScrollbar>
        )}
      </CardContent>
      <CardActions className="TableCrud__actions" />
    </Card>
  );
};

export default TableCrud;
