import React, { useContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import {
  InputLabel,
  Grid,
  TextField,
  Checkbox,
  FormControlLabel,
  FormControl,
  MenuItem,
  Button,
  Typography,
} from '@mui/material';
import DialogActionContainer from 'sharedComponents/DialogActions';
import { useFormik } from 'formik';
import { projectTaskSchema } from './schemas';
import { RootState, thunkDispatch } from 'store/store';
import { addActivity, getActivities } from '../Activities/redux/actions';
import { getCompanyMaterials } from '../CompanyMaterials/redux/actions';
import { getProjectMaterials } from '../ProjectMaterials/redux/actions';
import { addProjectTask, IJoinProjectTask } from './redux/actions';
import { getActivityTypes } from '../ActivityTypes/redux/actions';
import { subFormSchema } from './subFormSchema';
import { getProjects } from '../Projects/redux/actions';
import { ModalContext } from 'sharedComponents/ModalContext';
import { LoadingComponent } from 'sharedComponents/LoadingComponent';
import { IActivityJoin } from 'lg-helpers/dist/shared/interfaces/IActivityJoin';
import { IProjectTask } from 'lg-helpers/dist/shared/interfaces/IProjectTask';
import { IActivity } from 'lg-helpers/dist/shared/interfaces/IActivity';
import { IProject } from 'lg-helpers/dist/shared/interfaces/IProject';

interface ProjectTaskFormProps {
  selected?: IProjectTask;
}

const ProjectTaskForm = ({ selected }: ProjectTaskFormProps) => {
  const { handleModal } = useContext(ModalContext);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [projects, setProjects] = useState<IProject[]>();
  const [projectMaterialError, setProjectMaterialError] = useState<boolean>(false);

  const projectState: IProject[] = useSelector((state: RootState) => state.civil.projects.data);

  const activityState: IActivityJoin[] = useSelector(
    (state: RootState) => state.civil.activities.data
  );

  const projectMaterialsState = useSelector(
    (state: RootState) => state.civil.projectMaterials.data
  );

  const activityTypesState = useSelector((state: RootState) => state.civil.activityTypes.data);

  const handleSubmit = async (values: IJoinProjectTask) => {
    // eslint-disable-next-line
    const { activity, material, sourceSupplier, projectMaterialId, workCategory, ...data } = values; 
    try {
      setIsSaving(true);
      const result = await thunkDispatch(addProjectTask(data));
      if (result.meta.requestStatus === 'fulfilled') {
        formik.resetForm();
        handleModal();
        setIsSaving(false);
        return;
      }
      setIsSaving(false);
    } catch (err) {
      setIsSaving(false);
      console.error('Unable to create project task: ', err);
    }
  };

  useEffect(() => {
    const arrayForSorting = [...projectState];
    setProjects(
      arrayForSorting.sort((a, b) => {
        if (!a || !a.name || !b || !b.name) {
          return 0;
        }
        return a.name?.localeCompare(b.name!);
      })
    );
  }, [projectState]);

  useEffect(() => {
    (async () => {
      setIsLoading(true);
      await thunkDispatch(getProjects());
      setIsLoading(false);
    })();
  }, [selected]);

  const formik = useFormik({
    validationSchema: projectTaskSchema,
    initialValues: selected
      ? {
          projectMaterialId: JSON.stringify({
            materialId: selected.materialId,
            sourceSupplierId: selected.sourceSupplierId,
          }),
          ...selected,
        }
      : ({
          autoTicketNumber: false,
        } as IProjectTask),
    onSubmit: handleSubmit,
  });

  useEffect(() => {
    (async () => {
      if (formik.values.projectId) {
        setIsLoading(true);
        await Promise.all([
          thunkDispatch(
            getProjectMaterials({
              projectId: formik.values.projectId,
              type: 'active',
            })
          ),
          thunkDispatch(
            getActivities({
              type: 'active',
              projectId: Number(formik.values.projectId),
            })
          ),
          thunkDispatch(getActivityTypes({ projectId: Number(formik.values.projectId) })),
        ]);
        setIsLoading(false);
      }
    })();
  }, [formik.values.projectId]);

  useEffect(() => {
    setProjectMaterialError(
      !projectMaterialsState.some(
        item =>
          JSON.stringify({
            materialId: item.materialId,
            sourceSupplierId: item.sourceSupplierId,
          }) === formik.values.projectMaterialId
      )
    );
  }, [formik.values.projectMaterialId, projectMaterialsState]);

  const submitActivity = async (values: IActivity) => {
    // TO DO: add a new activity based on current input and load it into activity field
    try {
      setIsSaving(true);
      const result = await thunkDispatch(
        addActivity({
          activity: values,
          projectId: Number(formik.values.projectId),
        })
      );
      if (result.meta.requestStatus === 'fulfilled') {
        const newActivity = result.payload as IActivity;
        await thunkDispatch(
          getActivities({
            type: 'active',
            projectId: Number(formik.values.projectId),
          })
        );
        subFormik.resetForm();
        formik.values.activityId = newActivity.id;
        formik.errors.activityId = undefined;
      }
      setIsSaving(false);
    } catch (err) {
      setIsSaving(false);
      console.error('Unable to create project task: ', err);
    }
  };

  const subFormik = useFormik({
    initialValues: {
      activityTypeId: undefined,
      name: '',
    } as IActivity,
    validationSchema: subFormSchema,
    onSubmit: submitActivity,
    enableReinitialize: true,
  });

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <FormControl fullWidth>
            <InputLabel shrink htmlFor="projectName">
              Project
            </InputLabel>
            <TextField
              select
              variant="outlined"
              name="projectId"
              value={formik.values.projectId || ''}
              error={!!formik.errors.projectId}
              helperText={formik.errors.projectId}
              onChange={event => formik.handleChange(event)}
            >
              {projects && projects.length ? (
                projects.map(project => (
                  <MenuItem value={project.id} key={project.id}>
                    {project.name}
                  </MenuItem>
                ))
              ) : (
                <MenuItem>No options available</MenuItem>
              )}
            </TextField>
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <FormControl fullWidth>
            <InputLabel shrink htmlFor="workActivity">
              Activity
            </InputLabel>
            <TextField
              select
              variant="outlined"
              name="activityId"
              value={formik.values.activityId || ''}
              error={!!formik.errors.activityId}
              helperText={formik.errors.activityId}
              onChange={formik.handleChange}
            >
              <MenuItem value={-1} key={-1}>
                {'>>>'} Create New Activity
              </MenuItem>
              {activityState.map(activity => (
                <MenuItem value={activity.id} key={activity.id}>
                  {activity.activityTypeName} - {activity.name}
                </MenuItem>
              ))}
            </TextField>
          </FormControl>
        </Grid>
        {formik.values.activityId! === -1 && (
          <>
            <Grid item xs={5}>
              <FormControl fullWidth>
                <InputLabel shrink htmlFor="activityTypeId">
                  Work Category
                </InputLabel>
                <TextField
                  select
                  variant="outlined"
                  name="activityTypeId"
                  value={subFormik.values.activityTypeId || ''}
                  error={!!subFormik.errors.activityTypeId}
                  helperText={subFormik.errors.activityTypeId}
                  onChange={subFormik.handleChange}
                >
                  {activityTypesState.map(activityType => (
                    <MenuItem value={activityType.id} key={activityType.id}>
                      {activityType.name}
                    </MenuItem>
                  ))}
                </TextField>
              </FormControl>
            </Grid>
            <Grid item xs={5}>
              <FormControl fullWidth>
                <InputLabel shrink htmlFor="name">
                  Activity Name
                </InputLabel>
                <TextField
                  variant="outlined"
                  name="name"
                  value={subFormik.values.name || ''}
                  error={!!subFormik.errors.name}
                  helperText={subFormik.errors.name}
                  onChange={subFormik.handleChange}
                />
              </FormControl>
            </Grid>
            <Grid item xs={2} display="flex" alignItems="center" justifyContent="center">
              <Button onClick={subFormik.handleSubmit as React.FormEventHandler} color="primary">
                Create
              </Button>
            </Grid>
          </>
        )}
        <Grid item xs={12}>
          <FormControl fullWidth>
            <InputLabel shrink htmlFor="projectMaterialId">
              Project Material
            </InputLabel>
            <TextField
              select
              variant="outlined"
              disabled={!formik.values.projectId}
              value={formik.values.projectMaterialId || ''}
              error={!!formik.errors.materialId}
              helperText={formik.errors.materialId}
              name="projectMaterialId"
              onChange={event => {
                const data = JSON.parse(event.target.value);
                formik.values.projectMaterialId = event.target.value;
                formik.values.materialId = data.materialId;
                formik.values.sourceSupplierId = data.sourceSupplierId;
                formik.handleChange(event);
              }}
            >
              {projectMaterialsState.length ? (
                projectMaterialsState
                  .filter(
                    (v, i, a) =>
                      a.findIndex(
                        t =>
                          t.materialId === v.materialId && t.sourceSupplierId === v.sourceSupplierId
                      ) === i
                  )
                  .map(material => (
                    <MenuItem
                      value={JSON.stringify({
                        materialId: material.materialId!,
                        sourceSupplierId: material.sourceSupplierId,
                      })}
                      key={material.materialId}
                    >
                      {(() => {
                        return `${material.material} - ${material.sourceSupplier}`;
                      })()}
                    </MenuItem>
                  ))
              ) : (
                <MenuItem>No project materials exist</MenuItem>
              )}
            </TextField>
          </FormControl>
        </Grid>
        {projectMaterialError && selected?.id && (
          <Grid item xs={12}>
            <Typography>
              There is no longer an active project material for selected material. Please create a
              new project material before editing the project task.
            </Typography>
          </Grid>
        )}
        <Grid item xs={4}>
          <FormControl fullWidth>
            <InputLabel shrink htmlFor="required">
              Total Amount Required
            </InputLabel>
            <TextField
              variant="outlined"
              value={formik.values.totalAmount || ''}
              error={!!formik.errors.totalAmount}
              helperText={formik.errors.totalAmount}
              name="totalAmount"
              onChange={formik.handleChange}
            />
          </FormControl>
        </Grid>
        <Grid item xs={4}>
          <FormControl fullWidth>
            <InputLabel shrink htmlFor="dailyTarget">
              Daily Target
            </InputLabel>
            <TextField
              variant="outlined"
              name="dailyTarget"
              value={formik.values.dailyTarget || ''}
              error={!!formik.errors.dailyTarget}
              helperText={formik.errors.dailyTarget}
              onChange={formik.handleChange}
            />
          </FormControl>
        </Grid>
        <Grid item xs={3}>
          <FormControlLabel
            value={formik.values.autoTicketNumber}
            label="Auto Ticket #"
            name="autoTicketNumber"
            labelPlacement="top"
            control={
              <Checkbox
                checked={formik.values.autoTicketNumber}
                onChange={formik.handleChange}
                color="secondary"
              />
            }
          />
        </Grid>
      </Grid>
      <LoadingComponent isLoading={isLoading} />
      <DialogActionContainer saveHandler={formik.handleSubmit} isSaving={isSaving} />
    </>
  );
};

export default ProjectTaskForm;
