import React from 'react';
import { GridColDef } from '@mui/x-data-grid';

export const generateCompaniesColumns = () => {
  return [
    {
      field: 'name',
      headerName: 'Name',
      width: 250,
    },
    {
      field: 'types',
      headerName: 'Types',
      width: 350,
    },
    {
      field: 'address',
      headerName: 'Address',
      width: 250,
    },
    {
      field: 'city',
      headerName: 'City',
      width: 150,
    },
    {
      field: 'state',
      headerName: 'State',
      width: 150,
    },
  ] as GridColDef[];
};
