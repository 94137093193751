import React, { useState, useEffect, useContext } from 'react';
import { thunkDispatch } from 'store/store';
import { Box, DialogContent } from '@mui/material';
import ModalHeader from 'sharedComponents/ModalHeader';
import DialogActionContainer from 'sharedComponents/DialogActionContainer';
import {
  upsertTruck,
  getJoinedTrucksExcludingDivisionId,
} from 'modules/civilAndTrucking/trucking/features/Trucks/redux/actions';
import { ModalContext } from 'sharedComponents/ModalContext';
import { TrucksOutsideDivisionList } from './TrucksOutsideDivisionList';

type AddTrucksToDivisionProps = {
  divisionId: number;
};

export const UpsertTrucksToDivisionForm = ({ divisionId }: AddTrucksToDivisionProps) => {
  const [truckIdsToAdd, setTruckIdsToAdd] = useState<number[]>([]);
  const { handleModal } = useContext(ModalContext);

  useEffect(() => {
    thunkDispatch(getJoinedTrucksExcludingDivisionId(divisionId));
  }, [divisionId]);

  const onSubmit = () => {
    if (!truckIdsToAdd.length) return;
    truckIdsToAdd.forEach(id => {
      thunkDispatch(
        upsertTruck({
          idTruckingTruck: id || undefined,
          divisionIdTruckingTruck: divisionId,
        })
      );
    });
    handleModal();
  };

  const removeTruckFromAddingToDivision = (id: number) => {
    setTruckIdsToAdd(truckIdsToAdd.filter(truckId => truckId !== id));
  };

  const addTruckToAddingToDivision = (id: number) => {
    setTruckIdsToAdd([...truckIdsToAdd, id]);
  };

  return (
    <Box sx={{ p: '0px 16px' }}>
      <ModalHeader title={`ADD TRUCK(S) TO DIVISION`} />
      <DialogContent>
        <TrucksOutsideDivisionList
          removeTruck={removeTruckFromAddingToDivision}
          addTruck={addTruckToAddingToDivision}
          truckIds={truckIdsToAdd}
        />
        <DialogActionContainer saveHandler={onSubmit} />
      </DialogContent>
    </Box>
  );
};
