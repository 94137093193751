import { useState, useEffect } from 'react';
import moment from 'moment';
import 'moment-timezone';
import { usePermissionsContext } from 'services/permissions';
import { ValueType as DateRangeValueType } from 'rsuite/lib/DateRangePicker';

const useDateRangeFilter = () => {
  const { user } = usePermissionsContext();
  const [dateRangeFilter, setDateRangeFilter] = useState<[Date, Date] | null>(null);
  const { timeZone } = user as any; // TODO: do we have timeZone on user object?

  useEffect(() => {
    const startDate = moment
      .tz(timeZone || 'EST')
      .startOf('day')
      .toDate();

    const endDate = moment
      .tz(timeZone || 'EST')
      .endOf('day')
      .toDate();

    setDateRangeFilter([startDate, endDate]);
  }, [timeZone]);

  const onDateRangeFilterChange = (newDateRange: [Date, Date] | null | DateRangeValueType) => {
    if (newDateRange && newDateRange.length) {
      const startDate = moment(newDateRange[0])
        .tz(timeZone || 'EST')
        .startOf('day')
        .toDate();

      const endDate = moment(newDateRange[1])
        .tz(timeZone || 'EST')
        .endOf('day')
        .toDate();

      setDateRangeFilter([startDate, endDate]);
    } else {
      setDateRangeFilter(null);
    }
  };

  return { dateRangeFilter, setDateRangeFilter, onDateRangeFilterChange };
};

export default useDateRangeFilter;
