import React, { SetStateAction } from 'react';
import 'mapbox-gl/dist/mapbox-gl.css';
import LocateOnDrag from './LocateOnDrag';
import '@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css';
import { Grid, Menu, MenuItem, Button } from '@mui/material';

export interface Coordinates {
  lat: number;
  lon: number;
}

export interface GeocodingAdressInputProps {
  anchorEl: Element;
  showMap: boolean;
  setShowMap: React.Dispatch<SetStateAction<boolean>>;
  center?: Coordinates;
  onSave: (geo: Coordinates) => Promise<void>;
}

export const GeocodingAdressInput = ({
  showMap,
  anchorEl,
  setShowMap,
  center,
  onSave,
}: GeocodingAdressInputProps) => {
  const currentCenter = React.useRef<Coordinates>();
  const handleSave = async () => {
    if (!currentCenter.current) {
      return;
    }
    try {
      await onSave(currentCenter.current);
      setShowMap(false);
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <Menu open={showMap} anchorEl={anchorEl}>
      <MenuItem
        sx={{
          backgroundColor: '#FFF',
          cursor: 'default',
          '&:hover': { backgroundColor: '#FFF', cursor: 'default' },
        }}
      >
        <Grid container display="flex" flexDirection="column">
          <Grid item display="flex" justifyContent="center" alignItems="center">
            {center && showMap && (
              <LocateOnDrag
                zoom={15}
                center={center ? center : { lon: -83.045, lat: 42.331 }}
                onMove={(e: any) => {
                  const { lat, lng } = e.target.getCenter();
                  currentCenter.current = { lat, lon: lng };
                }}
                markers={[]}
                style={{
                  width: '400px',
                  maxWidth: '100%',
                  height: '250px',
                  maxHeight: '70vh',
                }}
              />
            )}
          </Grid>
          <Grid item display="flex" justifyContent="flex-start" sx={{ padding: 2 }}>
            <Button color="primary" variant="contained" onClick={handleSave}>
              Save
            </Button>
            <Button color="primary" onClick={() => setShowMap(false)} sx={{ ml: 2 }}>
              Cancel
            </Button>
          </Grid>
        </Grid>
      </MenuItem>
    </Menu>
  );
};

export default GeocodingAdressInput;
