import React from 'react';

const useToggle = ({ onChange, initialState = false }) => {
  const [isChecked, setState] = React.useState(() => initialState);
  const wasChecked = React.useRef(initialState);
  React.useEffect(() => {
    if (onChange && isChecked !== wasChecked.current) {
      onChange(isChecked);
      wasChecked.current = isChecked;
    }
  }, [onChange, isChecked]);

  const actions = React.useMemo(
    () => ({
      toggle: () => setState(_checked => !_checked),
      setTrue: () => setState(true),
      setFalse: () => setState(false),
      setState,
    }),
    [setState]
  );
  return [isChecked, actions];
};

export default useToggle;
