import React from 'react';
import './pictograms.scss';
import { Grid } from '@mui/material';
import Page from '../Page';

const NotFoundView = () => (
  <Page className="NotFoundView" title="404">
    <Grid container spacing={0} direction="column" alignItems="center" justifyContent="center">
      <img
        style={{ width: 500 }}
        alt="Under development"
        className="PageNotFound__image"
        src="/static/images/not_found_text_background.png"
      />
      <img
        style={{ width: 500 }}
        alt="Under development"
        className="PageNotFound__image"
        src="/static/images/not_found_dozer.png"
      />
    </Grid>
  </Page>
);

export default NotFoundView;
