import React, { useMemo } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { useSelector } from 'react-redux';
import { selectTickets } from 'modules/civilAndTrucking/trucking/features/Tickets/redux/ticketsStoreSlice';
import { LoadingOverlay as Loading } from 'sharedComponents/LoadingOverlay';
import { generateDispatchTicketsColumns } from './dispatchTicketsColumns';

export const DispatchTicketsList = () => {
  const { tickets, ticketsStatus } = useSelector(selectTickets);

  // eslint-disable-next-line
  const columns = useMemo(() => generateDispatchTicketsColumns(), []);

  if (ticketsStatus === 'pending') {
    return <Loading />;
  }

  return (
    <DataGrid
      columns={columns}
      rows={tickets}
      getRowId={row => row.idTruckingTicket}
      showColumnRightBorder={false}
      disableColumnMenu
      disableSelectionOnClick
      autoHeight
      components={{
        ColumnResizeIcon: () => <div></div>,
      }}
    />
  );
};
