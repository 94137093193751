import React, { useEffect } from 'react';
import { Container, Grid, Box, CircularProgress } from '@mui/material';
import Page from 'sharedComponents/Page';
import { useNavigate } from 'react-router-dom';
import DispatchesList from './DispatchesList';
import useGetDriverCurrentLoad from 'services/hooks/useGetDriverCurrentLoad';
import useGetDriverCurrentRepeatedLoad from 'services/hooks/useGetDriverCurrentRepeatedLoad';
import useDateRangeFilter from 'services/hooks/useDateRangeFilter';

const DispatchesView = () => {
  const { dateRangeFilter } = useDateRangeFilter();

  const { currentLoad, isLoading } = useGetDriverCurrentLoad();
  const { currentRepeatedLoad } = useGetDriverCurrentRepeatedLoad();

  const navigate = useNavigate();

  useEffect(() => {
    if (currentLoad || currentRepeatedLoad) {
      navigate('/brokers/load');
    }
  }, [currentLoad, currentRepeatedLoad, navigate]);

  return (
    <Page title="Dispatches">
      <Container>
        <Grid container spacing={1}>
          {isLoading || !dateRangeFilter ? (
            <Grid item xs={12}>
              <Box display="flex" justifyContent="center" alignItems="center">
                <CircularProgress color="inherit" />
              </Box>
            </Grid>
          ) : (
            <DispatchesList dateRangeFilter={dateRangeFilter} />
          )}
        </Grid>
      </Container>
    </Page>
  );
};

export default DispatchesView;
