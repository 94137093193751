import { createSlice } from '@reduxjs/toolkit';
import { getTruckTypesByCompanyId } from './actions';
import { RootState } from 'store/store';
import { ITruckingTruckType } from 'lg-helpers/dist/trucking/interfaces/ITruckingTruckType';
import { TFormStatus } from 'lg-helpers/dist/shared/types/TFormStatus';

export interface ITruckTypesState {
  data: ITruckingTruckType[];
  status: TFormStatus;
}

const initialStateTruckTypes: ITruckTypesState = {
  data: [] as ITruckingTruckType[],
  status: null,
};

export const truckTypesSlice = createSlice({
  name: 'truckTypes',
  reducers: {},
  initialState: initialStateTruckTypes,
  extraReducers: builder => {
    builder
      .addCase(getTruckTypesByCompanyId.pending, (state, {}) => {
        state.status = 'pending';
      })
      .addCase(getTruckTypesByCompanyId.fulfilled, (state, { payload }) => {
        state.status = 'success';
        state.data = payload;
      })
      .addCase(getTruckTypesByCompanyId.rejected, (state, { error }) => {
        state.status = 'failure';
        state.data = [] as ITruckingTruckType[];
        console.error('getTruckTypesByCompanyId error: ', error);
      });
  },
});

export const selectTruckTypes = (state: RootState) => {
  return {
    truckTypes: state.trucking.truckTypes.data,
    truckTypesStatus: state.trucking.truckTypes.status,
  };
};
