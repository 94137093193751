import { createSlice } from '@reduxjs/toolkit';
import {
  updateTicket,
  addTicket,
  deleteTicket,
  searchTickets,
  getTicketById,
  getAccountingSummary,
  getTicketSummary,
  getTicketsForProject,
  getTicketPhotos,
  addTicketPhoto,
  rejectTicket,
  resetTicketPhotoState,
  saveTicketMessage,
  removeTicketMessage,
  getTicketMessagesByTicketId,
  getReceiptFromManifest,
} from './actions';
import { TFormStatus } from 'lg-helpers/dist/shared/types/TFormStatus';
import { ITicket } from 'lg-helpers/dist/shared/interfaces/ITicket';
import { ITicketSummary } from 'lg-helpers/dist/shared/interfaces/ITicketSummary';
import { IAccountingSummary } from 'lg-helpers/dist/shared/interfaces/IAccountingSummary';
import { IPhoto } from 'lg-helpers/dist/shared/interfaces/IPhoto';
import { ITicketMessage } from 'lg-helpers/dist/shared/interfaces/ITicketMessage';

interface ITicketState {
  data: ITicket[];
  ticket?: ITicket;
  status: TFormStatus;
  error: string | undefined;
  summary: ITicketSummary[];
  accountingSummary: IAccountingSummary[];
  ticketPhotos: IPhoto[];
  ticketMessages: ITicketMessage[];
  getTicketReceiptStatus: TFormStatus;
}

const initialState: ITicketState = {
  data: [],
  ticket: undefined,
  status: null,
  error: undefined,
  summary: [],
  ticketPhotos: [],
  accountingSummary: [],
  ticketMessages: [],
  getTicketReceiptStatus: null,
};

export const ticketSlice = createSlice({
  name: 'tickets',
  reducers: {
    add: state => state,
    getCompanies: state => state,
    getCompanyTypes: state => state,
  },
  initialState,
  extraReducers: builder => {
    builder.addCase(getTicketsForProject.fulfilled, (state, { payload }) => {
      state.status = 'success';
      state.data = payload as ITicket[];
    });

    builder.addCase(getTicketsForProject.rejected, (state, payload) => {
      console.error('Error getting companies: ', payload.error);
      state.status = 'failure';
      state.error = payload.error?.message;
    });

    builder.addCase(searchTickets.fulfilled, (state, { payload }) => {
      state.status = 'success';
      state.data = payload as ITicket[];
    });

    builder.addCase(updateTicket.fulfilled, (state, { payload }) => {
      state.status = 'success';
      const index = state.data.findIndex(item => item.id === payload?.id);
      state.data[index] = payload as ITicket;
    });

    builder.addCase(getTicketById.fulfilled, (state, { payload }) => {
      state.status = 'success';
      state.ticket = payload;
    });

    builder.addCase(addTicket.fulfilled, (state, { payload }) => {
      state.status = 'success';
      if (payload) state.data.push(payload as ITicket); // TODO: should be normal refetch from API
    });
    builder.addCase(addTicketPhoto.fulfilled, (state, {}) => {
      state.status = 'success';
      state.ticketPhotos = [];
    });

    builder.addCase(rejectTicket.fulfilled, (state, { payload }) => {
      state.status = 'success';
      const index = state.data.findIndex(item => item.id === payload?.data?.id);
      state.data[index] = payload?.data as ITicket;
    });

    builder.addCase(resetTicketPhotoState.fulfilled, (state, { payload }) => {
      state.status = 'success';
      state.ticketPhotos = payload as IPhoto[];
    });

    builder.addCase(deleteTicket.fulfilled, (state, { payload }) => {
      state.status = 'success';
      const index = state.data.findIndex(item => item.id === payload?.id);
      state.data[index].deleted = payload?.deleted;
    });

    builder.addCase(getTicketSummary.fulfilled, (state, { payload }) => {
      state.status = 'success';
      if (payload) {
        state.summary = payload;
      }
    });

    builder.addCase(getAccountingSummary.fulfilled, (state, { payload }) => {
      state.status = 'success';
      state.accountingSummary = payload;
    });

    builder.addCase(getTicketPhotos.fulfilled, (state, { payload }) => {
      state.status = 'success';
      state.ticketPhotos = payload as IPhoto[];
    });

    builder.addCase(getTicketMessagesByTicketId.fulfilled, (state, { payload }) => {
      state.status = 'success';
      state.ticketMessages = payload as ITicketMessage[];
    });

    builder.addCase(getReceiptFromManifest.fulfilled, (state, {}) => {
      state.getTicketReceiptStatus = 'success';
    });
    builder.addCase(getReceiptFromManifest.pending, (state, {}) => {
      state.getTicketReceiptStatus = 'pending';
    });
    builder.addCase(getReceiptFromManifest.rejected, (state, {}) => {
      state.getTicketReceiptStatus = 'failure';
    });

    builder.addCase(saveTicketMessage.fulfilled, (state, { payload }) => {
      state.status = 'success';
      const index = state.ticketMessages.findIndex(a => a.id === payload?.id);
      const ticketIndex = state.data.findIndex(a => a.id === payload?.civilTicketId);
      const prevCount = state.data[ticketIndex].count;
      if (index === -1) {
        state.ticketMessages.unshift(payload as ITicketMessage);
        state.data[ticketIndex] = {
          ...state.data[ticketIndex],
          count: Number(prevCount) + 1,
        };
      } else {
        state.ticketMessages[index] = payload as ITicketMessage;
      }
    });

    builder.addCase(removeTicketMessage.fulfilled, (state, { payload }) => {
      state.status = 'success';
      const ticketIndex = state.data.findIndex(a => a.id === payload?.ticketId);
      const prevCount = state.data[ticketIndex].count;
      state.ticketMessages = state.ticketMessages.filter(a => a.id !== payload?.ticketMessageId);
      state.data[ticketIndex] = {
        ...state.data[ticketIndex],
        count: Number(prevCount) - 1,
      };
    });
  },
});
