import { createSlice } from '@reduxjs/toolkit';
import { getActiveCompanyUsers, addUser, removeUser } from './actions';
import { IUser } from 'lg-helpers/dist/shared/interfaces/IUser';
import { TFormStatus } from 'lg-helpers/dist/shared/types/TFormStatus';

interface IUserState {
  data?: IUser[];
  status: TFormStatus;
  error?: string;
}

const initialState: IUserState = {
  data: undefined,
  status: null,
  error: undefined,
};

export const userSlice = createSlice({
  name: 'project-tasks',
  reducers: {
    add: state => state,
  },
  initialState,
  extraReducers: builder => {
    builder.addCase(getActiveCompanyUsers.fulfilled, (state, action) => {
      state.status = 'success';
      state.data = action.payload as IUser[];
    });
    builder.addCase(getActiveCompanyUsers.rejected, (state, action) => {
      console.log('Error getting users');
      state.status = 'failure';
      state.error = action.error?.message;
    });
    builder.addCase(addUser.fulfilled, state => {
      state.status = 'success';
    });
    builder.addCase(addUser.rejected, (state, action) => {
      console.log('Error adding users');
      state.status = 'failure';
      state.error = action.error?.message;
    });
    builder.addCase(removeUser.fulfilled, (state, { payload }) => {
      state.status = 'success';
      const newUsers: IUser[] | undefined = state.data?.filter(user => user.id !== payload);
      state.data = newUsers;
    });
  },
});
