import React, { useEffect, useState } from 'react';
import { Grid, Box, Typography, IconButton, Button } from '@mui/material';
import { getTicketPhotos, removeTicketPhoto, resetTicketPhotoState } from './redux/actions';
import InputCivilImage from 'sharedComponents/InputCivilImage';
import { ITicketPhoto } from './AddTicketForm';
import { Delete, Fullscreen } from '@mui/icons-material';
import { RootState, thunkDispatch } from 'store/store';
import { useSelector } from 'react-redux';
import { LoadingComponent } from 'sharedComponents/LoadingComponent';
import useApiGetRequest from 'services/api/useApiGetRequest';
import api from 'services/api/autogenerated';

interface ITicketPhotoProps {
  ticketId?: number;
  pitTicketId?: number;
  ticketPhotos?: ITicketPhoto[];
  setTicketPhotos: React.Dispatch<React.SetStateAction<ITicketPhoto[]>>;
}

type ImagesTab = 'shipping' | 'inspection' | 'supplier';

export const TicketPhotos = ({
  ticketPhotos,
  setTicketPhotos,
  ticketId,
  pitTicketId,
}: ITicketPhotoProps) => {
  const [imageBase64, setImageBase64] = useState<string | undefined>();
  const [imageBlob, setImageBlob] = useState<File>();
  const [imagesTab, setImagesTab] = useState<ImagesTab>('shipping');
  const [imageShown, setImageShown] = useState<string>();
  const [currentPhotos, setCurrentPhotos] = useState<ITicketPhoto[]>([]);
  const [showFullScreen, setShowFullScreen] = useState<boolean>(false);
  const [isRemoving, setIsRemoving] = useState<boolean>(false);

  const ticketPhotoState = useSelector((state: RootState) => state.civil.tickets.ticketPhotos);

  const [pitTicketHtml] = useApiGetRequest(
    () => (pitTicketId ? api.template.getCivilPitTicketTemplateHTML(pitTicketId) : null),
    () => ({ title: 'Failed to get supplier pit ticket', duration: 3000 }),
    [pitTicketId]
  );

  useEffect(() => {
    if (ticketId) {
      thunkDispatch(getTicketPhotos({ ticketId: Number(ticketId) }));
    } else {
      thunkDispatch(resetTicketPhotoState());
    }
  }, [ticketId]);

  useEffect(() => {
    if (ticketPhotos && ticketPhotos.length) {
      const photos = ticketPhotos?.filter(a => a.category === imagesTab);
      setCurrentPhotos(photos || []);
      if (!photos?.some(a => a.photoUrl === imageShown) || !imageShown) {
        setImageShown((photos && photos.length && photos[0].photoUrl) || '');
      } else {
        setImageShown((photos && photos.length && photos[photos.length - 1].photoUrl) || '');
      }
    } else {
      setCurrentPhotos([]);
      setImageShown('');
    }
    // eslint-disable-next-line
  }, [imagesTab, ticketPhotos]);

  useEffect(() => {
    setTicketPhotos(prev => prev.concat(ticketPhotoState));
    return () => {
      setTicketPhotos([]);
      setImageShown('');
    };
  }, [ticketPhotoState, setTicketPhotos]);

  const updatePhotos = (base64: string) => {
    if (imagesTab === 'supplier') return;
    setTicketPhotos(prev => [
      ...prev,
      {
        category: imagesTab,
        photoUrl: base64, //initially setting url to Base64
        isNew: true,
      },
    ]);
    setImageBase64(undefined);
  };

  const removeImage = async () => {
    const image = currentPhotos.find(ticketPhoto => ticketPhoto.photoUrl === imageShown);
    if (!image?.id) {
      setTicketPhotos(prev => prev.filter(ticketPhoto => ticketPhoto !== image));
    } else {
      try {
        setIsRemoving(true);
        await thunkDispatch(removeTicketPhoto(image));
        await thunkDispatch(getTicketPhotos({ ticketId: Number(ticketId) }));
        setIsRemoving(false);
      } catch (err) {
        console.error(err);
      }
    }
  };

  useEffect(() => {
    if (imageBase64) {
      updatePhotos(imageBase64);
    }
    // eslint-disable-next-line
  }, [imageBase64]);

  const handleShowFullScreen = () => {
    setShowFullScreen(!showFullScreen);
  };

  return (
    <>
      {showFullScreen ? (
        <Box
          maxHeight={800}
          width="100%"
          display="flex"
          alignItems="center"
          flexDirection="column"
          paddingTop={5}
          sx={{ objectFit: 'cover', overflowY: 'scroll' }}
        >
          <Button
            onClick={handleShowFullScreen}
            color="primary"
            variant="contained"
            sx={{ position: 'fixed', marginTop: '-40px' }}
          >
            Close Image
          </Button>
          <Box
            component="img"
            src={imageShown}
            sx={{ maxHeight: '100%', maxWidth: '100%' }}
            alt=""
          />
        </Box>
      ) : (
        <Grid container>
          <Grid item xs={4} display="flex" alignItems="center">
            <Typography variant="h6" sx={{ fontSize: 12 }}>
              Ticket Images
            </Typography>
          </Grid>
          <Grid
            item
            xs={8}
            display="flex"
            bgcolor="#BFC8C4"
            maxWidth={400}
            borderRadius={100}
            alignItems="center"
            justifyContent="space-evenly"
            padding={1}
          >
            {pitTicketId && (
              <Box
                width="100%"
                sx={
                  imagesTab === 'supplier'
                    ? { color: '#FFF', backgroundColor: 'secondary.main' }
                    : { color: '#989898', backgroundColor: '#EFEFEF' }
                }
                marginY={0.02}
                paddingY={1}
                paddingX={0.8}
                borderRadius={100}
                onClick={() => setImagesTab('supplier')}
              >
                <Typography variant="body2" align="center">
                  Supplier Pit Ticket
                </Typography>
              </Box>
            )}
            <Box
              width="100%"
              sx={
                imagesTab === 'shipping'
                  ? { color: '#FFF', backgroundColor: 'secondary.main' }
                  : { color: '#989898', backgroundColor: '#EFEFEF' }
              }
              marginY={0.02}
              paddingY={1}
              paddingX={0.8}
              borderRadius={100}
              onClick={() => setImagesTab('shipping')}
            >
              <Typography variant="body2" align="center">
                Shipping Images
              </Typography>
            </Box>
            <Box
              width="100%"
              sx={
                imagesTab === 'inspection'
                  ? { color: '#FFF', backgroundColor: 'secondary.main' }
                  : { color: '#9C9E98', backgroundColor: '#F0F4F6' }
              }
              marginY={0.02}
              paddingY={1}
              paddingX={0.8}
              borderRadius={100}
              onClick={() => setImagesTab('inspection')}
            >
              <Typography variant="body2" align="center">
                Inspection Images
              </Typography>
            </Box>
          </Grid>

          {imagesTab !== 'supplier' && (
            <>
              {currentPhotos && currentPhotos.length ? (
                <Grid item xs={10} width="100%" marginTop={2} border="0.25px solid #6D7066">
                  <Box display="flex" flexDirection="column" sx={{ float: 'right' }}>
                    <IconButton onClick={handleShowFullScreen}>
                      <Fullscreen />
                    </IconButton>
                    <IconButton onClick={removeImage}>
                      <Delete />
                    </IconButton>
                  </Box>
                  <Box
                    height={310}
                    display="flex"
                    justifyContent="center"
                    paddingTop={5}
                    sx={{ objectFit: 'cover' }}
                  >
                    <Box
                      component="img"
                      src={imageShown}
                      sx={{ maxHeight: '100%', maxWidth: '100%' }}
                      alt=""
                    />
                  </Box>

                  <Box
                    display="flex"
                    padding={2}
                    maxWidth="100%"
                    sx={{ objectFit: 'cover', overflowX: 'scroll' }}
                  >
                    {currentPhotos.map((image, index) => (
                      <Box
                        component="img"
                        src={image.photoUrl}
                        alt=""
                        onClick={() => setImageShown(image.photoUrl as string)}
                        key={index}
                        sx={
                          image.photoUrl === imageShown
                            ? {
                                maxHeight: '100px',
                                maxWidth: '100px',
                                marginRight: 1,
                                marginLeft: 1,
                                borderColor: 'secondary.main',
                                borderWidth: '2px',
                                borderStyle: 'solid',
                              }
                            : {
                                maxHeight: '100px',
                                maxWidth: '100px',
                                marginRight: 1,
                                marginLeft: 1,
                              }
                        }
                      />
                    ))}
                  </Box>
                </Grid>
              ) : (
                <Grid item xs={10} width="100%" marginTop={2} border="0.25px solid #6D7066">
                  <Box height={410} display="flex" justifyContent="center" alignItems="center">
                    <Typography variant="body1">
                      No {imagesTab} images exist for this ticket.
                    </Typography>
                  </Box>
                </Grid>
              )}

              <Grid item xs={2}>
                <InputCivilImage
                  buttonText="Upload Image"
                  imageBase64={imageBase64}
                  setImageBase64={setImageBase64}
                  setImageBlob={setImageBlob}
                  imageBlob={imageBlob}
                  sx={{ marginTop: 1 }}
                />
              </Grid>
            </>
          )}

          {imagesTab === 'supplier' &&
            (pitTicketHtml ? (
              <iframe style={{ width: '100%', height: 450 }} srcDoc={pitTicketHtml} />
            ) : (
              <p>Loading</p>
            ))}
        </Grid>
      )}
      <LoadingComponent isLoading={isRemoving} />
    </>
  );
};
