import { createAsyncThunk } from '@reduxjs/toolkit';
import { INotification } from 'modules/civilAndTrucking/shared/NotificationSnackbar/INotification';
import { pushNotification } from 'modules/civilAndTrucking/shared/NotificationSnackbar/redux/actions';
import { RootState } from 'store/store';
import { del, get, post, postStatic, getStatic } from 'services/api/restHelpers';
import Command from 'services/firebase/Command';
import { ITicket } from 'lg-helpers/dist/shared/interfaces/ITicket';
import { IAddTicketCompanyReference } from 'lg-helpers/dist/civil/interfaces/IAddTicketCompanyReference';
import { IPhoto } from 'lg-helpers/dist/shared/interfaces/IPhoto';
import { ITicketSummary } from 'lg-helpers/dist/shared/interfaces/ITicketSummary';
import { IJoinCompany } from 'lg-helpers/dist/shared/interfaces/IJoinCompany';
import { IAccountingSummary } from 'lg-helpers/dist/shared/interfaces/IAccountingSummary';
import { ITicketMessage } from 'lg-helpers/dist/shared/interfaces/ITicketMessage';
import api from 'services/api/autogenerated';

export interface ITicketSearch {
  item: string;
  value: string | number | boolean | undefined | Date;
}

const command = new Command();

export const getTicketsForProject = createAsyncThunk(
  'tickets/get-all',
  async (projectId: number, thunkApi) => {
    try {
      const data = await get(`civil/tickets?projectId=${projectId}`);
      return data as ITicket[];
    } catch (err) {
      const errorNotification: INotification = {
        status: 'error',
        message: 'Error: Unable to get tickets',
      };
      thunkApi.dispatch(pushNotification(errorNotification));
    }
  }
);

export const getTicketById = createAsyncThunk(
  'tickets/get-by-id',
  async (ticketId: number, thunkApi) => {
    try {
      const state = thunkApi.getState() as RootState;
      const companyId = state.civil.companies.activeCompany?.id;
      const data = await get(`civil/companies/${companyId}/tickets/${ticketId}`);
      return data as ITicket;
    } catch (err) {
      const errorNotification: INotification = {
        status: 'error',
        message: 'Error: Unable to get tickets',
      };
      thunkApi.dispatch(pushNotification(errorNotification));
    }
  }
);

export const searchTickets = createAsyncThunk(
  'tickets/search',
  async (search: ITicketSearch[], thunkApi) => {
    try {
      let searchString = '';
      search.forEach((item, index) => {
        const itemHasValue = item.value != undefined;
        searchString +=
          (itemHasValue ? `${item.item}=${item.value}` : ``) +
          `${index !== search.length - 1 && itemHasValue ? '&' : ''}`;
      });
      const data = await get(`civil/tickets?${searchString}`);
      return data as ITicket[];
    } catch (err) {
      const errorNotification: INotification = {
        status: 'error',
        message: 'Error: Unable to search tickets',
      };
      thunkApi.dispatch(pushNotification(errorNotification));
    }
  }
);

export const addTicket = createAsyncThunk(
  'tickets/create',
  async (payload: { ticketData?: ITicket; company?: IAddTicketCompanyReference }, thunkApi) => {
    try {
      const result = await post(`civil/tickets`, payload);
      const successNotification: INotification = {
        status: 'success',
        message: 'Success: Created a new ticket',
      };
      thunkApi.dispatch(pushNotification(successNotification));
      return result as ITicket;
    } catch (err) {
      const errorNotification: INotification = {
        status: 'error',
        message: 'Error: Unable to create ticket',
      };
      thunkApi.dispatch(pushNotification(errorNotification));
    }
  }
);

export const getTicketPhotos = createAsyncThunk(
  'ticket/get-photos',
  async (payload: { ticketId: number }, thunkApi) => {
    try {
      const result = await get(`civil/tickets/${payload.ticketId}/photos`);
      return result as IPhoto[];
    } catch (err) {
      console.error('Unable to get ticket photos: ', err);
      const errorNotification: INotification = {
        status: 'error',
        message: 'Error: Unable to get ticket photos',
      };
      thunkApi.dispatch(pushNotification(errorNotification));
    }
  }
);

export const resetTicketPhotoState = createAsyncThunk('ticket/reset-photo', async () => {
  return [];
});

export const addTicketPhoto = createAsyncThunk(
  'ticket/photo/add',
  async (payload: { newPhotoData: IPhoto; civilTicketId: number }) => {
    try {
      const result = await post(
        `civil/tickets/${payload.civilTicketId}/photos`,
        payload.newPhotoData
      );
      return result as IPhoto;
    } catch (err) {
      // notification happens downstream
    }
  }
);

export const removeTicketPhoto = createAsyncThunk(
  'tickets/photo/remove',
  async (ticketPhoto: IPhoto, thunkApi) => {
    try {
      await command.deleteFile(ticketPhoto.photoUrl); // remove from firestore
      const result = await del(
        `civil/tickets/${ticketPhoto.civilTicketId}/photo/${ticketPhoto.id}`
      );
      return result as IPhoto;
    } catch (err) {
      const errorNotification: INotification = {
        status: 'error',
        message: 'Error: Unable to remove photos from ticket',
      };
      thunkApi.dispatch(pushNotification(errorNotification));
    }
  }
);

export const updateTicket = createAsyncThunk(
  'tickets/update',
  async (payload: ITicket, thunkApi) => {
    try {
      const result = await post(`civil/tickets/update`, payload);
      const successNotification: INotification = {
        status: 'success',
        message: 'Success: Ticket has been updated',
      };
      thunkApi.dispatch(pushNotification(successNotification));
      return result as ITicket;
    } catch (err) {
      const errorNotification: INotification = {
        status: 'error',
        message: 'Error: Unable to update ticket',
      };
      thunkApi.dispatch(pushNotification(errorNotification));
    }
  }
);

export const rejectTicket = createAsyncThunk(
  'tickets/reject/:ticketId',
  async (payload: { ticketId: number; message: string }, thunkApi) => {
    try {
      const result = await api.civilTickets.rejectTicket(payload.ticketId, {
        message: payload.message,
      });
      const successNotification: INotification = {
        status: 'success',
        message: 'Success: Ticket has been rejected',
      };
      thunkApi.dispatch(pushNotification(successNotification));
      return result;
    } catch (err) {
      const errorNotification: INotification = {
        status: 'error',
        message: 'Error: Unable to reject ticket',
      };
      thunkApi.dispatch(pushNotification(errorNotification));
    }
  }
);

export const deleteTicket = createAsyncThunk(
  'tickets/delete-ticket',
  async (payload: ITicket, thunkApi) => {
    try {
      const result = await del(`civil/tickets/${payload.id}`);
      const successNotification: INotification = {
        status: 'success',
        message: 'Success: Updated ticket status.',
      };
      thunkApi.dispatch(pushNotification(successNotification));
      return result?.data.rows[0] as ITicket;
    } catch (err) {
      const errorNotification: INotification = {
        status: 'error',
        message: 'Error: Unable to update ticket status.',
      };
      thunkApi.dispatch(pushNotification(errorNotification));
    }
  }
);

export const getTicketSummary = createAsyncThunk(
  'tickets/get-summary',
  async (payload: { showActive: boolean; search: string }, thunkApi) => {
    try {
      const response = await get(
        `civil/tickets/summary?showActive=${payload.showActive}&search=${payload.search}`
      );
      return response as ITicketSummary[];
    } catch (err) {
      const errorNotification: INotification = {
        status: 'error',
        message: 'Error: Unable to get project summary',
      };
      thunkApi.dispatch(pushNotification(errorNotification));
    }
  }
);

type TManifestPayload = {
  profileId: string | null | undefined;
  searchText: string | null | undefined;
};

export const getNextUnsignedManifest = createAsyncThunk(
  'tickets/get-next-unsigned-manifest',
  async (payload: TManifestPayload, thunkApi) => {
    try {
      const { profileId } = payload;
      const data = await get(
        `civil/manifest-projects/${profileId}/manifests/next-unsigned-manifest`
      );
      return data;
    } catch (err) {
      const errorNotification: INotification = {
        status: 'error',
        message: 'Error: Unable to get next unsigned manifest',
      };
      thunkApi.dispatch(pushNotification(errorNotification));
      throw err;
    }
  }
);

export const getManifestByNumber = createAsyncThunk(
  'tickets/get-manifest-by-number',
  async (payload: TManifestPayload, thunkApi) => {
    try {
      const { profileId, searchText } = payload;
      const data = await get(`civil/manifest-projects/${profileId}/manifest-number/${searchText}`);
      if (!data) {
        const notFound: INotification = {
          status: 'error',
          message: `${searchText} manifest was not found`,
        };
        thunkApi.dispatch(pushNotification(notFound));
        return data;
      }
      return data;
    } catch (err) {
      const errorNotification: INotification = {
        status: 'error',
        message: 'Error: Unable to get manifest by number',
      };
      thunkApi.dispatch(pushNotification(errorNotification));
      throw err;
    }
  }
);

export const getReceiptFromManifest = createAsyncThunk(
  'tickets/get-receipt-from-manifest',
  async (ticketId: number, thunkApi) => {
    try {
      const data = await get(`civil/tickets/${ticketId}/weigh-ticket-check`);
      if (!data) {
        const notFound: INotification = {
          status: 'error',
          message: `There was no weigh ticket number on the manifest`,
        };
        thunkApi.dispatch(pushNotification(notFound));
        return data;
      }
      return;
    } catch (err) {
      const errorNotification: INotification = {
        status: 'error',
        message: 'Error: Unable to check the weigh ticket number',
      };
      thunkApi.dispatch(pushNotification(errorNotification));
      throw err;
    }
  }
);

export const getManifestExists = createAsyncThunk(
  'tickets/get-manifest-exists',
  async (payload: TManifestPayload, thunkApi) => {
    try {
      const { searchText } = payload;
      // Check to see if this Manifest has already been linked to a Ticket in the system
      const exist = await get(`civil/tickets/manifestExistsOnTicket?manifestId=${searchText}`);
      if (exist) {
        const existsAlready: INotification = {
          status: 'error',
          message: `This manifest already exists on a ticket. Are you sure you want to use it?`,
        };
        thunkApi.dispatch(pushNotification(existsAlready));
      }
      return exist;
    } catch (err) {
      console.error(err);
      const errorNotification: INotification = {
        status: 'error',
        message: 'Error: Unable to determine if manifest exists.',
      };
      thunkApi.dispatch(pushNotification(errorNotification));
      throw err;
    }
  }
);

export const getTransportersForProfile = createAsyncThunk(
  'tickets/get-transporters-for-profile',
  async (payload: TManifestPayload, thunkApi) => {
    try {
      const { profileId } = payload;
      // Check to see what Transporters can move this profile ID
      const data = await get(`civil/manifest-projects/${profileId}/transporters`);
      return data as IJoinCompany[];
    } catch (err) {
      console.error(err);
      const errorNotification: INotification = {
        status: 'error',
        message: 'Error: Unable to find Transporters for this Profile ID.',
      };
      thunkApi.dispatch(pushNotification(errorNotification));
      throw err;
    }
  }
);

export const getAccountingSummary = createAsyncThunk(
  'tickets/get-accounting-summary',
  async (payload: { projectId: number; type: string }, thunkApi) => {
    try {
      const { projectId, type } = payload;
      const data = await get(`civil/projects/${projectId}/accounting/ticket-summary?type=${type}`);
      return data as IAccountingSummary[];
    } catch (err) {
      console.error(err);
      const errorNotification: INotification = {
        status: 'error',
        message: 'Error: Unable to get accounting ticket summary.',
      };
      thunkApi.dispatch(pushNotification(errorNotification));
      throw err;
    }
  }
);

export const printTicketReport = createAsyncThunk(
  'tickets/get-ticket-report',
  async (payload: { ticketId: Number; userId: string; companyId: string }, thunkApi) => {
    try {
      const url = await getStatic(
        `civil/downloads/reports/ticket/${payload.ticketId}/user/${payload.userId}/company/${payload.companyId}`
      );
      return url as { url: URL };
    } catch (err) {
      const errorNotification: INotification = {
        status: 'error',
        message: 'Error: Unable to print Reconciliation Report.',
      };
      thunkApi.dispatch(pushNotification(errorNotification));
      throw err;
    }
  }
);

export const downloadTickets = createAsyncThunk(
  `tickets/download-tickets`,
  async (
    payload: {
      ids?: number[];
      projectId?: number;
      startDate?: Date;
      endDate?: Date;
      userId?: string;
    },
    thunkApi
  ) => {
    try {
      await postStatic(`civil/downloads/civil-pub-sub/download-tickets`, {
        ids: payload.ids,
        projectId: payload.projectId,
        startDate: payload.startDate,
        endDate: payload.endDate,
        userId: payload.userId,
      });
      const successNotification: INotification = {
        status: 'warning',
        message: 'Generating ticket report, check downloads momentarily',
      };
      thunkApi.dispatch(pushNotification(successNotification));
      return;
    } catch (err) {
      const errorNotification: INotification = {
        status: 'error',
        message: 'Error: Unable to get ticket download report.',
      };
      thunkApi.dispatch(pushNotification(errorNotification));
      throw err;
    }
  }
);

export const getTicketMessagesByTicketId = createAsyncThunk(
  'tickets/get-messages',
  async (payload: { ticketId: number }, thunkApi) => { // eslint-disable-line
    try {
      const data = await get(`civil/tickets/${payload.ticketId}/messages`);
      return data as ITicketMessage[];
    } catch (err) {
      console.error(err);
    }
  }
);

export const saveTicketMessage = createAsyncThunk(
  'tickets/save-message',
  async (ticketMessageData: ITicketMessage, thunkApi) => {
    try {
      const result = await post(
        `civil/tickets/${ticketMessageData.civilTicketId}/messages`,
        ticketMessageData
      );
      const notification: INotification = {
        status: 'success',
        message: `Ticket comment successfully ${ticketMessageData.id ? 'updated.' : 'created.'}`,
      };
      thunkApi.dispatch(pushNotification(notification));
      return result as ITicketMessage;
    } catch (err) {
      const notification: INotification = {
        status: 'error',
        message: `Unable to ${ticketMessageData.id ? 'update' : 'create'} ticket comment.`,
      };
      thunkApi.dispatch(pushNotification(notification));
      console.error(err);
    }
  }
);

export const removeTicketMessage = createAsyncThunk(
  'tickets/remove-messages',
  async (payload: { ticketId: number; ticketMessageId: number }, thunkApi) => {
    try {
      await del(`civil/tickets/${payload.ticketId}/messages/${payload.ticketMessageId}`);
      const notification: INotification = {
        status: 'success',
        message: `Ticket comment successfully deleted.`,
      };
      thunkApi.dispatch(pushNotification(notification));
      return payload;
    } catch (err) {
      const notification: INotification = {
        status: 'error',
        message: `Unable to delete ticket comment.`,
      };
      thunkApi.dispatch(pushNotification(notification));
      console.error(err);
    }
  }
);
