import React, { useState, useRef, useCallback, useEffect } from 'react';
import Webcam from 'react-webcam';
import PropTypes from 'prop-types';
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Divider,
  CircularProgress,
  Typography,
  Grid,
} from '@mui/material';
import PhotoCameraIcon from '@mui/icons-material/PhotoCamera';
import FlipCameraIosIcon from '@mui/icons-material/FlipCameraIos';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';

const InputCameraImage = ({ imageBase64, isLoading, onImageBase64Update, title, disabled }) => {
  const cameraRef = useRef(null);
  const [numberOfCameras, setNumberOfCameras] = useState(0);
  const [videoConstraints, setVideoConstraints] = useState({
    width: 1280,
    height: 720,
    facingMode: 'environment',
  });
  const [cameraPermission, setCameraPermission] = useState(1);

  const handleDevices = useCallback(
    mediaDevices => {
      try {
        setNumberOfCameras(mediaDevices.filter(({ kind }) => kind === 'videoinput').length);
      } catch {
        // void
      }
    },
    [setNumberOfCameras]
  );

  const takeCameraImage = useCallback(() => {
    const base64 = cameraRef.current.getScreenshot();
    onImageBase64Update(base64);
  }, [cameraRef, onImageBase64Update]);

  const switchCamera = useCallback(() => {
    if (cameraRef.current) {
      try {
        setVideoConstraints({
          ...videoConstraints,
          facingMode: videoConstraints.facingMode === 'environment' ? 'user' : 'environment',
        });
      } catch (error) {
        console.error('camera switch error', error);
      }
    }
  }, [cameraRef, videoConstraints, setVideoConstraints]);

  const handleCameraIssues = () => {
    setCameraPermission(0);
  };

  const convertToBase64 = file => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = () => {
        resolve(fileReader.result);
      };

      fileReader.onerror = error => {
        reject(error);
      };
    });
  };

  const handleImageUpload = e => {
    if (e.target?.files.length > 0) {
      convertToBase64(e.target.files[0])
        .then(data => {
          onImageBase64Update(data);
        })
        .catch(err => {
          console.error(err);
        });
    }
  };

  useEffect(() => {
    try {
      navigator.mediaDevices.enumerateDevices().then(handleDevices);
    } catch {
      // void
    }
  }, [handleDevices]);

  const onClearClick = () => {
    onImageBase64Update(null);
  };

  return (
    <>
      <Card>
        <CardHeader
          title={
            <Box alignItems="center" display="flex">
              <Typography variant="body1">{title ? title : 'Image from Camera'}</Typography>
              {isLoading && (
                <Box marginLeft={2}>
                  <CircularProgress size="15px" color="inherit" />
                </Box>
              )}
            </Box>
          }
        />
        <CardContent>
          {cameraPermission !== 0 && (
            <Box alignItems="center" display="flex" flexDirection="column">
              {imageBase64 || disabled ? (
                <img width="100%" src={imageBase64} alt="" />
              ) : (
                <Webcam
                  width="100%"
                  audio={false}
                  ref={cameraRef}
                  screenshotFormat="image/jpeg"
                  videoConstraints={videoConstraints}
                  onUserMediaError={handleCameraIssues}
                />
              )}
            </Box>
          )}
          {cameraPermission === 0 && (
            <div>
              Livegistics would like access to your camera. Please allow camera access in your
              browser&apos;s preferences or settings profile.
            </div>
          )}
        </CardContent>
        {!disabled && (
          <>
            <Divider />
            <CardActions>
              {imageBase64 ? (
                <Button
                  color="primary"
                  fullWidth
                  variant="text"
                  disabled={!cameraRef || isLoading || disabled}
                  onClick={onClearClick}
                >
                  Clear
                </Button>
              ) : (
                <Grid container>
                  {numberOfCameras > 1 && (
                    <Grid item xs={2}>
                      <Button
                        color="primary"
                        variant="text"
                        size="large"
                        fullWidth
                        disabled={!cameraRef || isLoading || disabled}
                        onClick={switchCamera}
                      >
                        <Box alignItems="center" display="flex">
                          <FlipCameraIosIcon />
                        </Box>
                      </Button>
                    </Grid>
                  )}
                  <Grid item xs={numberOfCameras > 1 ? 10 : 12}>
                    {numberOfCameras > 0 ? (
                      <Button
                        color="primary"
                        variant="contained"
                        size="large"
                        fullWidth
                        disabled={!cameraRef || isLoading || disabled}
                        onClick={takeCameraImage}
                        style={{ marginBottom: 3 }}
                      >
                        <Box alignItems="center" display="flex">
                          <Box marginRight={1} alignItems="center" display="flex">
                            <PhotoCameraIcon />
                          </Box>
                          Capture?
                        </Box>
                      </Button>
                    ) : (
                      <Box>
                        <input
                          accept="image/*"
                          id="file-upload"
                          type="file"
                          hidden
                          onChange={handleImageUpload}
                        />
                        <label htmlFor="file-upload">
                          <Button
                            color="primary"
                            variant="outlined"
                            size="large"
                            fullWidth
                            component="span"
                            disabled={isLoading || disabled}
                          >
                            <Box alignItems="center" display="flex">
                              <Box marginRight={1} alignItems="center" display="flex">
                                <CloudUploadIcon />
                              </Box>
                              Upload picture!
                            </Box>
                          </Button>
                        </label>
                      </Box>
                    )}
                  </Grid>
                </Grid>
              )}
            </CardActions>
          </>
        )}
      </Card>
    </>
  );
};

InputCameraImage.propTypes = {
  onImageBase64Update: PropTypes.func,
  imageBase64: PropTypes.string,
  isLoading: PropTypes.bool,
  title: PropTypes.string,
  disabled: PropTypes.bool,
};

export default InputCameraImage;
