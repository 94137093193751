import React, { useMemo, useState } from 'react';
import { thunkDispatch } from 'store/store';
import { useNavigate } from 'react-router-dom';
import { ManageHeader } from 'sharedComponents/ManageHeader';
import { ValidationError } from 'yup';
import {
  Typography,
  TextField,
  Grid,
  Switch,
  AutocompleteRenderInputParams,
  MenuItem,
  Autocomplete,
} from '@mui/material';
import timezoneOptions from 'services/constants/momentJsTimeZones';
import { FormTextField } from 'sharedComponents/FormTextField';
import { FormCustomField } from 'sharedComponents/FormCustomField';
import { IGeocoderFeature } from 'services/mapbox/geocoding';
import InputAddressAutocomplete from 'sharedComponents/InputAddressAutocomplete';
import { projectSchema } from 'modules/civilAndTrucking/trucking/schemas';
import { upsertCompanyProject } from 'modules/civilAndTrucking/trucking/features/Projects/redux/actions';
import { LoadingOverlay } from 'sharedComponents/LoadingOverlay';
import { ITruckingProject } from 'lg-helpers/dist/trucking/interfaces/ITruckingProject';
import { IHelpers } from 'lg-helpers/dist/trucking/interfaces/IHelpers';
import useApiGetRequest from 'services/api/useApiGetRequest';
import api, {
  CompanyConnectionWithCompany,
  ConnectedCompanyType,
} from 'services/api/autogenerated';
import fuzzyFilterOptions from 'utils/fuzzyFilterOptions';

type ProjectProps = {
  setSelectedContractorId: (id: string) => void;
  existingProject?: ITruckingProject;
};

const initialProject = {
  activeTruckingProject: true,
  timeZone: '' || Intl.DateTimeFormat().resolvedOptions().timeZone,
  projectNumberTruckingProject: '',
  contractorProjectNumberTruckingProject: '',
} as ITruckingProject;

const companiesFilterOptions = fuzzyFilterOptions<CompanyConnectionWithCompany>([
  'connected_company.name',
]);

export const Project = ({ existingProject, setSelectedContractorId }: ProjectProps) => {
  const [project, setProject] = useState(existingProject || (initialProject as ITruckingProject));
  const [helpers, setHelpers] = useState({} as IHelpers);
  const [isSaved, setIsSaved] = useState(!!existingProject);
  const navigate = useNavigate();

  // TODO: this list could be large (optimize in future)
  const [companies, { status: companiesStatus }] = useApiGetRequest(
    () =>
      api.companiesConnections.getConnectedCompaniesByTypes([
        ConnectedCompanyType.Contractor,
        ConnectedCompanyType.TruckingContractor,
      ]),
    () => ({ title: 'Failed to load contractors' })
  );

  const targetCompany = useMemo(
    () =>
      (companies || []).find(
        c => c.connected_company_id === project?.contractorCompanyIdTruckingProject
      ),
    [project?.contractorCompanyIdTruckingProject, companies]
  );

  const handleChange = (e: React.ChangeEvent<HTMLInputElement> | any) => {
    const { type, name, value, checked } = e.target;
    const changeValue = type === 'checkbox' ? checked : value;

    const updatedProject = { ...project, [name]: changeValue };
    if (Object.keys(helpers).length) {
      setHelpers({});
    }

    setProject(updatedProject);
  };

  const handleInvalidSubmit = async () => {
    let submitHelpers = {} as IHelpers;
    await projectSchema
      .validate(project, { abortEarly: false })
      .catch((valErrors: ValidationError) => {
        valErrors.inner.forEach(err => {
          submitHelpers[err.path] = err.message;
        });
      });
    setHelpers(submitHelpers);
  };

  const handleSave = async () => {
    try {
      const isValidProject = await projectSchema.isValid(project);
      if (!isValidProject) {
        handleInvalidSubmit();
      } else {
        const res = await thunkDispatch(upsertCompanyProject(project));
        setIsSaved(true);
        if (!existingProject) {
          navigate(`/trucking/setup/projects/project/${res.payload.idTruckingProject}`);
        }
      }
    } catch (e) {
      console.error(e);
    }
  };

  const handleAddressSelected = async (geocoderValue: IGeocoderFeature) => {
    if (project.addressString !== geocoderValue.place_name) {
      setProject({
        ...project,
        addressString: geocoderValue.place_name,
        geo: geocoderValue.geometry.coordinates,
      });
    }
  };

  const handleSetIsEditing = () => {
    setIsSaved(false);
  };

  if (companiesStatus === 'pending') {
    return <LoadingOverlay />;
  }

  return (
    <>
      <ManageHeader
        headerTitle={`${existingProject ? 'Manage' : 'Create'} Project`}
        buttonText={isSaved ? `Edit Project ` : 'Save Project'}
        onButtonClick={isSaved ? handleSetIsEditing : handleSave}
      />
      <Grid container columnSpacing={2}>
        <Grid item xs={6}>
          <FormTextField
            name="nameTruckingProject"
            label="Project Name"
            helperField={helpers.nameTruckingProject}
            value={project?.nameTruckingProject}
            disabled={isSaved}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={6}>
          <FormTextField
            name="projectNumberTruckingProject"
            label="Project Number"
            helperField={helpers.projectNumberTruckingProject}
            value={project?.projectNumberTruckingProject || ''}
            disabled={isSaved}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={6}>
          <FormCustomField
            name="address"
            label="Location"
            component={
              <InputAddressAutocomplete
                searchRequirement={5}
                size="small"
                disabled={isSaved}
                options={[]}
                handleSelect={handleAddressSelected}
                inputValue={project?.addressString || ''}
                renderInput={(params: AutocompleteRenderInputParams) => (
                  <TextField
                    {...params}
                    name="addressString"
                    error={!!helpers.addressString}
                    helperText={helpers.addressString}
                    onChange={handleChange}
                    variant="outlined"
                    value={project?.addressString || ''}
                  />
                )}
              />
            }
          />
        </Grid>
        <Grid item xs={6}>
          <Autocomplete
            freeSolo
            size="small"
            disabled={isSaved}
            value={targetCompany || ''}
            options={companies || []}
            isOptionEqualToValue={(option, value: any) =>
              option.connected_company_id === value.connected_company_id
            }
            filterOptions={companiesFilterOptions}
            getOptionLabel={option =>
              typeof option === 'string' ? option : option.connected_company.name!
            }
            onChange={(e, val: any) => {
              const value = val?.connected_company_id || '';
              handleChange({
                target: {
                  name: 'contractorCompanyIdTruckingProject',
                  value,
                },
              });
              setSelectedContractorId(value);
            }}
            renderInput={params => (
              <TextField
                {...params}
                placeholder="Search company"
                variant="outlined"
                label="Contracting Company"
                inputProps={{
                  ...params.inputProps,
                  onKeyDown: e => {
                    if (e.key === 'Enter') {
                      e.stopPropagation();
                    }
                  },
                }}
              />
            )}
            renderOption={(props, option) => (
              <li {...props} key={option.id}>
                <Grid container alignItems="center" justifyContent="flex-start" wrap="nowrap">
                  <Grid item xs={6}>
                    <Typography noWrap>{option.connected_company.name}</Typography>
                  </Grid>
                </Grid>
              </li>
            )}
          />
        </Grid>
        <Grid item xs={6}>
          <FormTextField
            name="contractorProjectNumberTruckingProject"
            label="Contractor Project No."
            helperField={helpers.contractorProjectNumberTruckingProject}
            disabled={isSaved}
            value={project?.contractorProjectNumberTruckingProject || ''}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={6}>
          <FormTextField
            name="contactEmail"
            label="Contractor Email"
            helperField={helpers.contactEmail}
            disabled={isSaved}
            value={project?.contactEmail || ''}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={6}>
          <FormTextField
            name="contactPhone"
            label="Contractor Phone"
            helperField={helpers.contractorPhoneNumberTruckingProjects}
            disabled={isSaved}
            value={project?.contactPhone || ''}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={3}>
          <FormTextField
            name="timeZone"
            label="Timezone"
            helperField={helpers.timeZone}
            value={project?.timeZone}
            disabled={isSaved}
            onChange={handleChange}
            selectOptions={timezoneOptions.map((zone, index: number) => (
              <MenuItem key={index} value={zone}>
                {zone}
              </MenuItem>
            ))}
          />
        </Grid>
        <Grid item xs={3}>
          <Typography>Active Project?</Typography>
          <Switch
            name="activeTruckingProject"
            checked={project.activeTruckingProject}
            disabled={isSaved}
            inputProps={{ 'aria-label': 'controlled' }}
            onChange={handleChange}
          />
        </Grid>
      </Grid>
    </>
  );
};
