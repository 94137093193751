import React from 'react';
import styled from 'styled-components';

export default function ErrorHandler({
  error,
  buttons,
}: {
  error: Error;
  buttons?: React.ReactNode;
}) {
  buttons = buttons || (
    <>
      <ActionButton onClick={() => window.location.reload()}>Reload page</ActionButton>
      <ActionButton onClick={() => (window.location.pathname = '/')}>Go to homepage</ActionButton>
    </>
  );

  newrelic.noticeError(error);

  return (
    <CenteredErrorWrapper>
      <h1>Application error:</h1>
      <p>{error.message}</p>
      <ButtonsWrapper>{buttons}</ButtonsWrapper>
    </CenteredErrorWrapper>
  );
}

const CenteredErrorWrapper = styled.div`
  text-align: center;
  padding: 40px 20px;

  h1 {
    font-size: 30px;
  }

  p {
    font-size: 24px;
  }
`;

const ButtonsWrapper = styled.div`
  margin-top: 10px;
`;

export const ActionButton = styled.button`
  border: 1px solid #41793a;
  border-radius: 10px;
  padding: 10px 20px;
  font-size: 20px;
  background: #fff;
  color: #41793a;
  margin: 5px;

  &:hover {
    background: #41793a;
    color: #fff;
  }
`;
