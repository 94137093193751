import React from 'react';
import { GridColDef } from '@mui/x-data-grid';
import { Link } from 'react-router-dom';
import { Visibility } from '@mui/icons-material';

export const generateProjectsColumns = () => {
  return [
    {
      field: 'projectNumberTruckingProject',
      headerName: 'Project #',
      width: 180,
    },
    {
      field: 'nameTruckingProject',
      headerName: 'Project Name',
      width: 180,
    },
    {
      field: 'name',
      headerName: 'Contractor',
      width: 180,
    },
    {
      field: 'addressString',
      headerName: 'Address',
      flex: 9,
    },
    {
      field: 'edit',
      headerName: ' ',
      flex: 1,
      align: 'right',
      sortable: false,
      renderCell: params => (
        <Link to={`./project/${params.id}`}>
          <Visibility />
        </Link>
      ),
    },
  ] as GridColDef[];
};
