import { createSlice } from '@reduxjs/toolkit';
import { getDivisionsByCompanyId } from './actions';
import { RootState } from 'store/store';
import { TFormStatus } from 'lg-helpers/dist/shared/types/TFormStatus';
import { ITruckingTruckDivision } from 'lg-helpers/dist/trucking/interfaces/ITruckingTruckDivision';

export interface IDivisionsState {
  data: ITruckingTruckDivision[];
  status: TFormStatus;
}

const initialStateDivisions: IDivisionsState = {
  data: [],
  status: null,
};

export const divisionsStoreSlice = createSlice({
  name: 'divisions',
  reducers: {},
  initialState: initialStateDivisions,
  extraReducers: builder => {
    builder
      .addCase(getDivisionsByCompanyId.pending, state => {
        state.status = 'pending';
      })
      .addCase(getDivisionsByCompanyId.fulfilled, (state, { payload }) => {
        state.status = 'success';
        state.data = payload;
      })
      .addCase(getDivisionsByCompanyId.rejected, (state, { error }) => {
        state.status = 'failure';
        state.data = [] as ITruckingTruckDivision[];
        console.error('getDivisionsByCompanyId error: ', error);
      });
  },
});

export const selectDivisions = (state: RootState) => {
  return {
    divisions: state.trucking.divisions.data,
    divisionsStatus: state.trucking.divisions.status,
  };
};
