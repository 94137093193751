import React, { useEffect, useState } from 'react';
import {
  Typography,
  Grid,
  TextField,
  CircularProgress,
  Autocomplete,
  Box,
  Button,
} from '@mui/material';
import statesUSA from 'utils/statesUSA.json';
import InviteCompanyPopup from 'modules/common/companies/InviteCompanyPopup';
import { hasErrorRequired } from 'services/logic/formValidation';
import { IStateValue } from './InputAddressFull';
import PermissionsWrapper from 'sharedComponents/PermissionsWrapper';
import { UserTypes } from 'lg-helpers/dist/constants/user/UserTypes';
import { usePermissionsContext } from 'services/permissions';

interface statesUSAProps {
  name: string;
  value: string;
}

const InputContractorCompany = ({
  isAuthUser,
  isRequired,
  wasSubmitted,
  getStateLabel,
  project,
  handleChange,
  isLoadingCompanies,
  contractors,
  handleOpenCompanyContactModal,
}: {
  isAuthUser: { [key: string]: boolean };
  isRequired?: string;
  wasSubmitted?: boolean;
  getStateLabel?: (state: IStateValue) => string;
  project: any; // TODO: needs better type
  isLoadingCompanies?: boolean;
  contractors: any[]; // TODO: needs better type
  handleOpenCompanyContactModal: (
    companyType: 'Generator' | 'Contractor' | 'Transporter',
    allowChange: boolean
  ) => void;
  handleChange: (e: {
    target: {
      value: {
        id: string;
        name: string;
      } | null;
      name: 'contractorCompany';
    };
  }) => any;
}) => {
  const { user: authUser } = usePermissionsContext();
  const [contractorStateFilter, setContractorStateFilter] = useState<IStateValue | null>(null);
  const [currentContractorCompanyName, setCurrentContractorCompanyName] = useState('');

  useEffect(() => {
    setCurrentContractorCompanyName('');
  }, [project.contractorCompany, contractorStateFilter]);

  const filteredContractors = contractorStateFilter
    ? (contractors || []).filter(contractor => contractor?.state === contractorStateFilter.value)
    : contractors || [];
  useEffect(() => {
    if (
      !contractors ||
      contractors.length < 1 ||
      !project.contractorCompany ||
      !project.contractorCompany?.id
    )
      return;
    const contractor = contractors.find((c: any) => c.id === project.contractorCompany?.id);
    if (!contractor) {
      console.warn(
        'Contractor company not found in list of contractors',
        project.contractorCompany
      );
      return;
    }
    if (!contractor.state) {
      setContractorStateFilter(null);
      console.warn('State field is not defined for contractor', contractor);
      return;
    }
    setContractorStateFilter(
      statesUSA.find((state: statesUSAProps) => state.value === contractor.state) || null
    );
  }, [contractors, project.contractorCompany]);

  useEffect(() => {
    if (
      contractorStateFilter &&
      contractors &&
      contractors.length > 0 &&
      project.contractorCompany
    ) {
      // we need this to reset contractorCompany field if state changed
      const value = contractors.find(
        (c: any) =>
          c.id === project.contractorCompany?.id &&
          c.state &&
          c.state === contractorStateFilter.value
      );
      if (value && value.id === project.contractorCompany?.id) return;
      handleChange({
        target: {
          name: 'contractorCompany',
          value: value
            ? {
                id: value.id,
                name: value.name,
              }
            : null,
        },
      });
    }
    // eslint-disable-next-line
  }, [contractors, contractorStateFilter]);

  return (
    <Grid item xs={12}>
      <Grid container alignItems="center" spacing={2}>
        <Grid item xs={12}>
          <Box display="flex" justifyContent={'space-between'}>
            <Typography variant="h5">Contractor company</Typography>
            <PermissionsWrapper
              canAccess={[UserTypes.admin.super, UserTypes.contractor.admin, UserTypes.scale.admin]}
            >
              <Button
                color="inherit"
                variant="outlined"
                onClick={() =>
                  handleOpenCompanyContactModal(
                    'Contractor',
                    ([UserTypes.admin.super, UserTypes.contractor.admin] as string[]).includes(
                      authUser.actingAsType
                    )
                  )
                }
              >
                Contractor Contacts
              </Button>
            </PermissionsWrapper>
          </Box>
        </Grid>

        <Grid item xs={12} sm={12} md={3}>
          <Autocomplete
            disableClearable
            disabled={isAuthUser.readOnly && !isAuthUser.generatorAdmin}
            options={statesUSA}
            value={contractorStateFilter || undefined}
            isOptionEqualToValue={(option, value) =>
              option && value ? option.value === value?.value : false
            }
            getOptionLabel={getStateLabel}
            onChange={(event, value) => setContractorStateFilter(value)}
            renderInput={params => (
              <TextField
                {...params}
                variant="outlined"
                label="Filter Contractors by State"
                InputProps={{
                  ...params.InputProps,
                  endAdornment: <>{params.InputProps.endAdornment}</>,
                }}
              />
            )}
          />
        </Grid>
        <Grid item xs={9} md>
          <Autocomplete
            disabled={(isAuthUser.readOnly && !isAuthUser.generator) || !contractorStateFilter}
            options={filteredContractors}
            value={project.contractorCompany || null}
            isOptionEqualToValue={(option, value) =>
              value && option ? option.id === value.id : false
            }
            getOptionLabel={option => option.name}
            onChange={(event, value) =>
              handleChange({
                target: {
                  name: 'contractorCompany',
                  value: value
                    ? {
                        id: value.id,
                        name: value.name,
                      }
                    : null,
                },
              })
            }
            renderOption={(props, option) => (
              <li {...props} key={option.id || ''}>
                {option.name}
              </li>
            )}
            renderInput={(params: any) => (
              <TextField
                {...params}
                variant="outlined"
                required
                label="Contractor"
                placeholder="Select contractor"
                error={hasErrorRequired(project.contractorCompany, wasSubmitted)}
                helperText={
                  hasErrorRequired(project.contractorCompany, wasSubmitted) ? isRequired : ''
                }
                onChange={e => setCurrentContractorCompanyName(e.target.value)}
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <>
                      {isLoadingCompanies && <CircularProgress color="inherit" size={20} />}
                      {params.InputProps.endAdornment}
                    </>
                  ),
                }}
              />
            )}
          />
        </Grid>
        <Grid item xs={3} md="auto">
          <InviteCompanyPopup
            disabled={!contractorStateFilter || (isAuthUser.readOnly && !isAuthUser.generatorAdmin)}
            disabledState={true}
            state={contractorStateFilter?.value}
            name={currentContractorCompanyName}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default InputContractorCompany;
