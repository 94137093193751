import React, { useMemo } from 'react';
import { Paper, Typography, Grid } from '@mui/material';
import Manage from './components/Manage';
import Members from './components/Members';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../store/store';

export const SettingsView = () => {
  const company = useSelector((state: RootState) => state.civil.companies.activeCompany);
  const isCivilCompany = useMemo(() => (company?.types || []).includes('civil'), [company]);

  return (
    <Paper elevation={0} sx={{ backgroundColor: 'rgba(0,0,0,0)' }}>
      <Typography variant="h5" sx={{ mb: 2 }}>
        SETUP
      </Typography>
      <Grid container spacing={10} direction={{ xs: 'column-reverse', md: 'row' }}>
        <Grid item sm={7} xs={12}>
          <Members isCivilCompany={isCivilCompany} />
        </Grid>
        <Grid item sm={5} xs={12}>
          <Manage isCivilCompany={isCivilCompany} />
        </Grid>
      </Grid>
    </Paper>
  );
};
