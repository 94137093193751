import React from 'react';
import 'sass/globals.scss';
import { Typography, Container, Box, CircularProgress, List } from '@mui/material';
import Page from 'sharedComponents/Page';
import { useSelector } from 'react-redux';
import {
  selectNotifications,
  selectIsLoadingNotifications,
  selectNotificationsError,
} from './notificationStoreSlice';
import NotificationItem from './NotificationItem';

const NotificationsView = () => {
  const notifications = useSelector(selectNotifications);
  const notificationsError = useSelector(selectNotificationsError);
  const isLoadingNotifications = useSelector(selectIsLoadingNotifications);

  return (
    <Page>
      <Container className="min-padding-and-height">
        <Box my={3}>
          <Typography variant="h4">Notifications</Typography>
        </Box>
        <List>
          {isLoadingNotifications ? (
            <Box
              data-testid="Notifications--loader"
              display="flex"
              justifyContent="center"
              alignItems="center"
            >
              <CircularProgress color="inherit" />
            </Box>
          ) : (
            <>
              {notificationsError ? (
                <Typography variant="h2" color="error" data-testid="Notifications--error-msg">
                  {notificationsError}
                </Typography>
              ) : notifications.length ? (
                notifications.map((notification: any) => (
                  <NotificationItem key={notification.id} notification={notification} />
                ))
              ) : (
                <Typography data-testid="Notifications--none" variant="body1">
                  You have no notifications...
                </Typography>
              )}
            </>
          )}
        </List>
      </Container>
    </Page>
  );
};

export default NotificationsView;
