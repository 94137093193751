import React from 'react';
import { Box, Button } from '@mui/material';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';

interface ToolbarManifestsPaginationProps {
  indicator: number;
  recordsCount: number;
  handleBack(): void;
  handleNext(): void;
}

const ToolbarManifestsPagination = ({
  indicator,
  handleBack,
  recordsCount,
  handleNext,
}: ToolbarManifestsPaginationProps) => {
  return (
    <Box display="flex" justifyContent="flex-end" alignItems="center" marginBottom={1}>
      <Box display="flex">
        <Button variant="contained" color="inherit" disabled={indicator === 1} onClick={handleBack}>
          <NavigateBeforeIcon fontSize="small" />
          Back
        </Button>
        <Box marginRight={1} marginLeft={1}>
          <Button variant="contained" color="inherit" style={{ minWidth: '40px' }}>
            {indicator}
          </Button>
        </Box>
      </Box>

      <Button
        variant="contained"
        color="inherit"
        disabled={recordsCount < 100}
        onClick={handleNext}
      >
        Next
        <NavigateNextIcon fontSize="small" />
      </Button>
    </Box>
  );
};

export default ToolbarManifestsPagination;
