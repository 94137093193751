import React, { useContext, useEffect, useMemo, useState } from 'react';
import {
  Autocomplete,
  Box,
  Button,
  DialogActions,
  DialogContent,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  TextField,
  Typography,
} from '@mui/material';
import ModalHeader from 'sharedComponents/DialogHeader';
import moment from 'moment';
import { IJoinRequest } from '../views/Requests';
import { ModalContext } from 'sharedComponents/ModalContext';
import { useFormik } from 'formik';
import { requestSchema } from './schema';
import { RootState, thunkDispatch } from 'store/store';
import { getCompanyMaterials } from '../CompanyMaterials/redux/actions';
import { useSelector } from 'react-redux';
import { unitsOfMeasure } from 'lg-helpers/dist/constants/global-constants';
import { updateRequest } from './redux/actions';
import { RequestTypes } from 'modules/civilAndTrucking/civil/Requests/constants';
import { LoadingComponent } from 'sharedComponents/LoadingComponent';
import useApiGetRequest from 'services/api/useApiGetRequest';
import api, {
  CompanyConnectionWithCompany,
  ConnectedCompanyType,
} from 'services/api/autogenerated';
import fuzzyFilterOptions from 'utils/fuzzyFilterOptions';

interface ModalContainerProps {
  selected?: IJoinRequest;
}

const companiesFilterOptions = fuzzyFilterOptions<CompanyConnectionWithCompany>([
  'connected_company.name',
]);

const ModalContainer = ({ selected }: ModalContainerProps) => {
  const { handleModal } = useContext(ModalContext);
  const [isLoadingMaterials, setIsLoadingMaterials] = useState(false);
  const [isUpdateLoading, setIsUpdateLoading] = useState(false);
  const canEdit = selected?.status !== 'canceled' && selected?.status !== 'completed';

  const materialState = useSelector((state: RootState) => state.civil.companyMaterials.data);

  // TODO: this list could be large (optimize in future)
  const [transporterState, { loading: isLoadingTransporters }] = useApiGetRequest(
    () => api.companiesConnections.getConnectedCompaniesByTypes([ConnectedCompanyType.Transporter]),
    () => ({ title: 'Failed to load transporters' })
  );
  const isLoading = isLoadingMaterials || isLoadingTransporters || isUpdateLoading;

  const saveRequest = async (values: IJoinRequest) => {
    const { transporter, material, newNote, ...data } = values; // eslint-disable-line
    if (!newNote || !newNote.length) {
      formik.setErrors({ newNote: 'A new note is required.' });
      return;
    }

    if (newNote && newNote.length) {
      data.notes = values.notes?.concat(newNote);
    }
    try {
      setIsUpdateLoading(true);
      const result = await thunkDispatch(updateRequest({ request: data }));
      if (result.meta.requestStatus === 'fulfilled') {
        handleModal();
      }
    } catch (err) {
      console.error(err);
    } finally {
      setIsUpdateLoading(false);
    }
  };

  useEffect(() => {
    (async () => {
      setIsLoadingMaterials(true);
      await thunkDispatch(getCompanyMaterials(''));
      setIsLoadingMaterials(false);
    })();
  }, []);

  const formik = useFormik({
    validationSchema: requestSchema,
    initialValues: selected
      ? {
          ...selected,
          requestedDate: moment(new Date(selected.requestedDate as string)).format('YYYY-MM-DD'),
          status: selected.status?.toLowerCase(),
          type: selected.type?.toLowerCase(),
        }
      : ({} as IJoinRequest),
    onSubmit: saveRequest,
  });

  const targetCompany = useMemo(
    () =>
      (transporterState || []).find(c => c.connected_company_id === formik.values.targetCompanyId),
    [formik.values.targetCompanyId, transporterState]
  );

  return (
    <>
      <ModalHeader title="Request Details" />
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <FormControl fullWidth>
              <InputLabel htmlFor="requestedDate" shrink>
                Requested Date
              </InputLabel>
              <TextField
                type="date"
                name="requestedDate"
                value={formik.values.requestedDate || ''}
                error={formik.touched.requestedDate && !!formik.errors.requestedDate}
                helperText={formik.touched.requestedDate && formik.errors.requestedDate}
                onChange={formik.handleChange}
                variant="outlined"
                disabled={!canEdit}
              />
              <FormHelperText />
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <FormControl fullWidth>
              <InputLabel htmlFor="targetCompanyId" shrink>
                Target Company
              </InputLabel>
              <Autocomplete
                freeSolo
                disabled={!canEdit}
                value={targetCompany || ''}
                options={transporterState || []}
                isOptionEqualToValue={(option, value: any) =>
                  option.connected_company_id === value.connected_company_id
                }
                filterOptions={companiesFilterOptions}
                getOptionLabel={option =>
                  typeof option === 'string' ? option : option.connected_company.name!
                }
                onChange={(event, val: any) => {
                  formik.setFieldValue('targetCompanyId', val?.connected_company_id || '');
                }}
                renderInput={params => (
                  <TextField
                    {...params}
                    placeholder="Search company"
                    variant="outlined"
                    error={
                      (formik.touched?.targetCompanyId || !!formik.submitCount) &&
                      !!formik.errors.targetCompanyId
                    }
                    helperText={
                      (formik.touched?.targetCompanyId || !!formik.submitCount) &&
                      formik.errors.targetCompanyId
                    }
                    inputProps={{
                      ...params.inputProps,
                      onKeyDown: e => {
                        if (e.key === 'Enter') {
                          e.stopPropagation();
                        }
                      },
                    }}
                  />
                )}
                renderOption={(props, option) => (
                  <li {...props} key={option.id}>
                    <Grid container alignItems="center" justifyContent="flex-start" wrap="nowrap">
                      <Grid item xs={6}>
                        <Typography noWrap>{option.connected_company.name}</Typography>
                      </Grid>
                    </Grid>
                  </li>
                )}
              />
              <FormHelperText />
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <FormControl fullWidth>
              <InputLabel htmlFor="materialId" shrink>
                Material
              </InputLabel>
              <TextField
                select
                name="materialId"
                value={formik.values.materialId || ''}
                error={formik.touched.materialId && !!formik.errors.materialId}
                helperText={formik.touched.materialId && formik.errors.materialId}
                onChange={formik.handleChange}
                variant="outlined"
                disabled={!canEdit}
              >
                {materialState.map(material => (
                  <MenuItem value={material.id} key={material.id}>
                    {material.name}
                  </MenuItem>
                ))}
              </TextField>
              <FormHelperText />
            </FormControl>
          </Grid>
          <Grid item xs={6}>
            <FormControl fullWidth>
              <InputLabel htmlFor="quantity" shrink>
                Quantity
              </InputLabel>
              <TextField
                name="quantity"
                value={formik.values.quantity || ''}
                error={formik.touched.quantity && !!formik.errors.quantity}
                helperText={formik.touched.quantity && formik.errors.quantity}
                onChange={formik.handleChange}
                variant="outlined"
                disabled={!canEdit}
              ></TextField>
              <FormHelperText />
            </FormControl>
          </Grid>
          <Grid item xs={6}>
            <FormControl fullWidth>
              <InputLabel htmlFor="unit" shrink>
                Unit
              </InputLabel>
              <TextField
                select
                name="unit"
                value={formik.values.unit || ''}
                error={formik.touched.unit && !!formik.errors.unit}
                helperText={formik.touched.unit && formik.errors.unit}
                onChange={formik.handleChange}
                variant="outlined"
                disabled={!canEdit}
              >
                {unitsOfMeasure.map(unit => (
                  <MenuItem value={unit} key={unit}>
                    {unit}
                  </MenuItem>
                ))}
              </TextField>
              <FormHelperText />
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <FormControl fullWidth>
              <InputLabel htmlFor="type" shrink>
                Type
              </InputLabel>
              <TextField
                select
                name="type"
                value={formik.values.type || ''}
                error={formik.touched.type && !!formik.errors.type}
                helperText={formik.touched.type && formik.errors.type}
                onChange={formik.handleChange}
                variant="outlined"
                disabled={!canEdit}
              >
                {RequestTypes.map(type => (
                  <MenuItem value={type.toLowerCase()} key={type}>
                    {type}
                  </MenuItem>
                ))}
              </TextField>
              <FormHelperText />
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            {canEdit && (
              <FormControl fullWidth>
                <InputLabel htmlFor="newNote" shrink>
                  Add Note
                </InputLabel>
                <TextField
                  multiline
                  name="newNote"
                  value={formik.values.newNote || ''}
                  error={!!formik.errors.newNote}
                  helperText={formik.errors.newNote}
                  onChange={formik.handleChange}
                  variant="outlined"
                  disabled={!canEdit}
                ></TextField>
                <FormHelperText />
              </FormControl>
            )}
            {formik.values.notes && (
              <>
                <Typography
                  sx={{
                    my: 1,
                    typography: { textTransform: 'upperCase', fontWeight: 300 },
                  }}
                >
                  Previous Notes
                </Typography>
                <div dangerouslySetInnerHTML={{ __html: formik.values.notes }} />
              </>
            )}
          </Grid>
        </Grid>
        {canEdit && (
          <DialogActions>
            <Button
              disabled={isLoading}
              onClick={() => {
                formik.setValues({ ...formik.values, status: 'pending' });
                formik.handleSubmit();
              }}
              type="submit"
              color="primary"
              variant="contained"
            >
              Update
            </Button>
            <Button
              color="primary"
              onClick={() => {
                handleModal();
              }}
            >
              Return to list
            </Button>
            <Box flexGrow={1}></Box>
            <Button
              color="error"
              variant="outlined"
              onClick={() => {
                formik.setValues({ ...formik.values, status: 'canceled' });
                formik.handleSubmit();
              }}
            >
              Cancel Request
            </Button>
          </DialogActions>
        )}
        <LoadingComponent isLoading={isLoading} />
      </DialogContent>
    </>
  );
};

export default ModalContainer;
