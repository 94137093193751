import React, { SetStateAction, useEffect, useState } from 'react';
import { Grid, Box, Button } from '@mui/material';
import InputCivilImage from 'sharedComponents/InputCivilImage';
import { ICompanyLogo } from '.';
import { useSelector } from 'react-redux';
import { RootState } from 'store/store';

interface ICompanyLogoUploadProps {
  companyLogo?: ICompanyLogo;
  setCompanyLogo: React.Dispatch<SetStateAction<ICompanyLogo | undefined>>;
}

const CompanyLogoUpload = ({ setCompanyLogo, companyLogo }: ICompanyLogoUploadProps) => {
  const [imageBase64, setImageBase64] = useState<string | undefined>(undefined);
  const [imageBlob, setImageBlob] = useState<File>();

  const companyState = useSelector((state: RootState) => state.civil.companies.activeCompany);

  useEffect(() => {
    if (imageBase64) {
      const img = new Image();
      img.src = imageBase64;
      img.onload = () => {
        setCompanyLogo({
          url: imageBase64,
          width: img.width,
          height: img.height,
        });
      };
    } else {
      setCompanyLogo(companyState?.companyLogo);
    }
    // eslint-disable-next-line
  }, [imageBase64]);

  return (
    <>
      <Grid item xs={6}>
        <Box
          sx={{
            maxHeight: 200,
            maxWidth: 200,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            margin: 'auto',
            objectFit: 'cover',
          }}
        >
          {(imageBase64 || (companyLogo && companyLogo.url)) && (
            <img
              src={imageBase64 || companyLogo?.url}
              style={{ maxHeight: '100%', maxWidth: '100%' }}
              alt=""
            />
          )}
        </Box>
      </Grid>
      <Grid item xs={6}>
        <Box flexGrow={1}></Box>
      </Grid>
      <Grid item xs={6}>
        <Grid container>
          <Grid item xs={6} display="flex" alignItems="center" justifyContent="center">
            <InputCivilImage
              buttonText={companyLogo?.url || imageBase64 ? 'Change Logo' : 'Add Logo'}
              isLogo={true}
              setImageBase64={setImageBase64}
              setImageBlob={setImageBlob}
              imageBlob={imageBlob}
              sx={{
                backgroundColor: 'rgba(0,0,0,0)',
                width: '100%',
                alignItems: 'center',
              }}
            ></InputCivilImage>
          </Grid>
          {imageBase64 && (
            <Grid item xs={6} display="flex" alignItems="center" justifyContent="center">
              <Button onClick={() => setImageBase64(undefined)} sx={{ color: 'neutral.main' }}>
                Cancel
              </Button>
            </Grid>
          )}
        </Grid>
      </Grid>
    </>
  );
};

export default CompanyLogoUpload;
