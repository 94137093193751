import React from 'react';
import { DialogContent } from '@mui/material';
import ModalHeader from 'sharedComponents/DialogHeader';
import ImportProjectLocations from './ImportLocations';

interface ModalContainerProps {
  projectId: number;
}

const ModalContainer = ({ projectId }: ModalContainerProps) => {
  return (
    <>
      <ModalHeader title={'Import Sites'} />
      <DialogContent>
        <ImportProjectLocations projectId={projectId} />
      </DialogContent>
    </>
  );
};

export default ModalContainer;
