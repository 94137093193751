import React, { useEffect, useState } from 'react';
import { Box, Button, Container, Grid } from '@mui/material';
import { Link } from 'react-router-dom';
import { componentAllowedFor, usePermissionsContext } from 'services/permissions';
import PermissionsWrapper from 'sharedComponents/PermissionsWrapper';
import { UserTypes } from 'lg-helpers/dist/constants/user/UserTypes';
import api, { IGlobalCustomInputs } from 'services/api/autogenerated';
import CustomInputsDefinitionsCrudGlobal from 'sharedComponents/CustomInputsDefinitionsCrudGlobal';
import { ICompany } from 'lg-helpers/dist/shared/interfaces/ICompany';
import CompaniesApi from 'services/postgres/CompaniesApi';
import useShowError from 'modules/errors';

const companiesApi = new CompaniesApi();

export interface ICustomInputsFilterOptions {
  companyIds?: string[];
  count?: 10 | 20 | 50 | 100;
  name?: string;
  types?: ('List' | 'Text' | 'Signature' | 'Checkbox' | null)[];
}

export default componentAllowedFor(['super_admin'], () => <p>Access Refused</p>)(
  function GlobalCustomInputAdmin() {
    const { user: authUser } = usePermissionsContext();
    const [wasSubmitted, setWasSubmitted] = useState<boolean>(false);
    const [customInputs, setCustomInputs] = useState<IGlobalCustomInputs[]>([]);
    const [filterOptions, setFilterOptions] = useState<ICustomInputsFilterOptions>({});
    const [companies, setCompanies] = useState<ICompany[]>([]);
    const showError = useShowError();

    useEffect(() => {
      const getCompanies = async () => {
        try {
          // TODO: hotfixed with excluded types, but needs fix/optimisation in future
          const companiesResult = await companiesApi.getCompaniesWithCustomDataProperties([
            'regions',
          ]);
          setCompanies(companiesResult);
        } catch (error) {
          console.error('Error', error);
          showError({ title: 'Companies Error', text: 'Error finding companies' });
        }
      };
      getCompanies();
      // eslint-disable-next-line
    }, []);

    const fetchCustomInputs = async () => {
      try {
        const { name, companyIds = [], types = [] } = filterOptions;
        const resultsCustomInputs = await api.customInputs.getCustomInputs(
          name,
          types.join(','),
          companyIds.join(','),
          20
        );
        setCustomInputs(
          resultsCustomInputs.data.map((input, i) => {
            return { ...input, columnIndex: i, order: i };
          }) as IGlobalCustomInputs[]
        );
      } catch (error) {
        console.error(error);
        showError({ title: 'Custom Inputs Error', text: 'Error finding custom inputs' });
      }
    };

    useEffect(() => {
      fetchCustomInputs();
      // eslint-disable-next-line
    }, [filterOptions]);

    const handleCustomInputsDefinitionsChange = async (
      customInputsDefinitions: IGlobalCustomInputs[]
    ) => {
      setCustomInputs(customInputsDefinitions);
      setWasSubmitted(false);
    };

    const handleSave = async (input: IGlobalCustomInputs) => {
      try {
        const { id, items, ...restInput } = input;
        await api.customInputs.upsertCustomInput({
          ...(typeof id === 'number' ? { id: Number(id) } : {}),
          ...(items !== null ? { items: items } : {}),
          ...restInput,
          defaultValue: (input.defaultValue as any)?.id || input.defaultValue,
        });
      } catch (error) {
        console.error('Error saving global custom inputs', error);
        showError({ title: 'Save Error', text: 'Error saving custom inputs' });
      }
    };

    const deleteCustomInput = async (id: number) => {
      if (id === 0) return setCustomInputs(customInputs.slice(1));
      try {
        await api.customInputs.deleteCustomInputs(id);
        await fetchCustomInputs();
      } catch (error) {
        console.error('Error deleting global custom inputs', error);
        showError({ title: 'Delete Error', text: 'Error deleting custom input' });
      }
    };

    return (
      <Container>
        <Box padding="10px">
          <Grid container>
            <Grid item xs={12} marginBottom={1}>
              <h3>Global Custom Inputs</h3>
            </Grid>
            <Grid item xs={12} md={6} display="flex">
              <Button
                color="inherit"
                component={Link}
                variant="outlined"
                to="/super-admin/administration"
              >
                Back
              </Button>
            </Grid>
            <PermissionsWrapper canAccess={[UserTypes.admin.super]}>
              <Grid item xs={12}>
                <CustomInputsDefinitionsCrudGlobal
                  customInputsDefinitions={customInputs}
                  setCustomInputsDefinitions={handleCustomInputsDefinitionsChange}
                  wasSubmitted={wasSubmitted}
                  userCanEdit={!authUser.generator}
                  companies={companies}
                  deleteCustomInput={deleteCustomInput}
                  handleSaveSingleInput={handleSave}
                  filterOptions={filterOptions}
                  setFilterOptions={setFilterOptions}
                />
              </Grid>
            </PermissionsWrapper>
          </Grid>
        </Box>
      </Container>
    );
  }
);
