import React, { useEffect, useState } from 'react';
import { Typography } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import NotInterestedIcon from '@mui/icons-material/NotInterested';
import CrudTable from 'sharedComponents/CrudTable';
import { selectAuthUser } from 'modules/auth/storeSliceAuth';
import { useSelector } from 'react-redux';
import moment from 'moment';
import { v4 as uuidv4 } from 'uuid';
import ApiLogToolbar from './ApiLogToolbar';
import ManifestApiLogsApi from 'services/postgres/ManifestApiLogsApi';
import { IProject } from 'lg-helpers/dist/shared/interfaces/IProject';
import {
  IManifestApiLog,
  GetApiLogEntriesOptions,
  FiltersApiLog,
} from 'lg-helpers/dist/shared/interfaces/IManifestApiLog';
import { IUser } from 'lg-helpers/dist/shared/interfaces/IUser';

const manifestApiLogsApi = new ManifestApiLogsApi();

const dateFormat = 'MM/DD/YYYY hh:mm:ss A';

const ApiLog = ({ project }: { project: IProject }) => {
  const authUser: IUser = useSelector(selectAuthUser);
  const [filteredManifests, setFilteredManifests] = useState<IManifestApiLog[]>([]);
  const [indicator, setIndicator] = useState<number>(1);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [manifestSearchFilter, setManifestSearchFilter] = useState<string>('');
  const [paginationInfo, setPaginationInfo] = useState({
    id: uuidv4(),
    isNext: false,
  });

  const [filters, setFilters] = useState<FiltersApiLog>({
    filterWithTicketID: 'all',
    filterDateRange: [moment().startOf('day').toDate(), moment().endOf('day').toDate()],
    sortColumn: { column: 'manifest_id', isAsc: true },
  });

  const columns = [
    {
      path: 'matched',
      label: 'Matched',
      isSortable: true,
      getCellValueCallback: ({ matched }: IManifestApiLog) =>
        matched ? <CheckCircleIcon color="primary" /> : <NotInterestedIcon color="error" />,
    },
    {
      path: 'manifestId',
      label: 'Manifest#',
      isFilterable: false,
      isSortable: true,
      getCellValueCallback: ({ manifestId, matched }: { manifestId: string; matched: boolean }) => (
        <Typography align="center" color={!matched ? 'error' : ''}>
          {manifestId}
        </Typography>
      ),
    },
    {
      path: 'ticketId',
      label: 'Ticket ID',
      getCellValueCallback: ({ ticketId }: { ticketId: string }) => ticketId || '-',
    },
    {
      path: 'siteName',
      label: 'Landfill',
      getCellValueCallback: ({ siteName }: { siteName: string }) => siteName || '-',
    },
    // {
    //   label: 'Landfill ID',
    //   getCellValueCallback: ({ siteId }) => siteId || '-',
    // },
    {
      path: 'quantity',
      label: 'Qty',
      getCellValueCallback: ({ quantity, rateUom }: { quantity: number; rateUom: string }) => {
        return !quantity ? '-' : `${quantity} ${rateUom || '-'}`;
      },
    },
    {
      label: 'Truck#/Driver',
      getCellValueCallback: ({ truckNumber, driver }: { truckNumber: string; driver: string }) => {
        return !truckNumber && !driver ? '-' : `${truckNumber || '-'}/${driver || '-'}`;
      },
    },
    {
      path: 'carrier',
      label: 'Carrier',
      getCellValueCallback: ({ carrier }: IManifestApiLog) => carrier || '-',
    },
    {
      path: 'date',
      label: 'Scale in',
      getCellValueCallback: ({ date }: IManifestApiLog) => {
        if (date) {
          const momentDate = moment.tz(new Date(date), project?.timeZone || 'EST');
          return `${momentDate.format(dateFormat)} ${momentDate.zoneAbbr()}`;
        }
      },
    },
    {
      path: 'status',
      label: 'Status',
      getCellValueCallback: ({ status }: IManifestApiLog) => status || '-',
    },
  ];

  useEffect(() => {
    if (!project.id || !authUser || !authUser.id) {
      return;
    }
    (async (project_id: number | null) => {
      if (project_id) {
        setIsLoading(true);
        try {
          const manifestLogs = await manifestApiLogsApi.getByProjectId({
            project_id: String(project_id),
            limit: 100,
            offset: 100 * (indicator - 1 || 0),
            search: String(manifestSearchFilter),
            filters,
          } as GetApiLogEntriesOptions);
          setFilteredManifests(manifestLogs);
          setIsLoading(false);
        } catch (error) {
          console.error('Manifest API Log ERROR:', error);
          setIsLoading(false);
        }
      }
    })(project.id as number);
    return () => {};
  }, [manifestSearchFilter, project.id, authUser, indicator, filters]);

  const loadManifests = async (isNext: boolean) => {
    setPaginationInfo({
      ...paginationInfo,
      id: uuidv4(),
      isNext,
    });
  };

  const callbackSetFilters = (value: Partial<FiltersApiLog>) => {
    setFilters(prevState => ({
      ...prevState,
      ...value,
    }));

    setPaginationInfo({
      ...paginationInfo,
      isNext: false,
    });
  };

  // TODO: use empty string instead of null
  const handleSetManifestSearchFilter = (text: string | null) =>
    setManifestSearchFilter(text || '');

  const handleClickNextCall = () => {
    loadManifests(true);
    setIndicator(indicator + 1);
  };

  const handleClickBackCallback = () => {
    loadManifests(false);
    setIndicator(indicator - 1 || 1);
  };

  const handleSortOrder = (property: any) => {
    const { column, isAsc } = filters.sortColumn;
    setFilters({
      ...filters,
      sortColumn: { column: property, isAsc: !!column && column === property ? !isAsc : true },
    });
  };

  return (
    <CrudTable
      rows={filteredManifests}
      orderBy={String(filters.sortColumn.column || '')}
      orderDirection={filters.sortColumn.isAsc ? 'asc' : 'desc'}
      columns={columns}
      isLoading={isLoading}
      handleSortOrder={handleSortOrder}
    >
      <ApiLogToolbar
        indicator={indicator}
        searchFilter={manifestSearchFilter}
        onSearchCallback={handleSetManifestSearchFilter}
        isLoading={isLoading}
        handleNext={handleClickNextCall}
        handleBack={handleClickBackCallback}
        filters={filters}
        setFilters={callbackSetFilters}
        recordsCount={filteredManifests?.length || 0}
      />
    </CrudTable>
  );
};

export default ApiLog;
