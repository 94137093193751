import { combineReducers } from '@reduxjs/toolkit';
import { activitiesSlice } from 'modules/civilAndTrucking/civil/Activities/redux/activitiesSlice';
import { projectsSlice } from 'modules/civilAndTrucking/civil/Projects/redux/projectsSlice';
import { companiesSlice } from 'modules/civilAndTrucking/civil/Companies/redux/storeSlice';
import { companyMaterialsSlice } from 'modules/civilAndTrucking/civil/CompanyMaterials/redux/companyMaterialsSlice';
import { activityTypeSlice } from 'modules/civilAndTrucking/civil/ActivityTypes/redux/activityTypesSlice';
import { projectTaskSlice } from 'modules/civilAndTrucking/civil/ProjectTask/redux/projectTaskSlice';
import { projectMaterialSlice } from 'modules/civilAndTrucking/civil/ProjectMaterials/redux/projectMaterialsSlice';
import { notificationsReducer } from 'modules/civilAndTrucking/shared/NotificationSnackbar/redux/reducer';
import { ticketSlice } from 'modules/civilAndTrucking/civil/Tickets/redux/ticketsSlice';
import { invoiceSlice } from 'modules/civilAndTrucking/civil/Invoices/redux/invoicesSlice';
import { licenseSlice } from 'modules/civilAndTrucking/civil/Licenses/redux/licenseSlice';
import { userSlice } from 'modules/civilAndTrucking/civil/Users/redux/usersSlice';
import { requestSlice } from 'modules/civilAndTrucking/civil/Requests/redux/requestsSlice';

export const civilReducer = combineReducers({
  activities: activitiesSlice.reducer,
  projects: projectsSlice.reducer,
  projectTask: projectTaskSlice.reducer,
  projectMaterials: projectMaterialSlice.reducer,
  companies: companiesSlice.reducer,
  companyMaterials: companyMaterialsSlice.reducer,
  activityTypes: activityTypeSlice.reducer,
  notifications: notificationsReducer,
  tickets: ticketSlice.reducer,
  invoices: invoiceSlice.reducer,
  licenses: licenseSlice.reducer,
  users: userSlice.reducer,
  requests: requestSlice.reducer,
});

export default civilReducer;
