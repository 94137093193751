import React, { useContext } from 'react';
import { Box, Grid, DialogTitle, SvgIcon, IconButton } from '@mui/material';
import { ModalContext } from 'sharedComponents/ModalContext';
import { Close } from '@mui/icons-material';

interface ModalHeaderProps {
  title: string;
}

const ModalHeader = ({ title }: ModalHeaderProps) => {
  const { handleModal } = useContext(ModalContext);
  return (
    <>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        sx={{
          borderBottom: 1,
          borderColor: 'neutral.lighter',
        }}
      >
        <Grid container alignItems="center">
          <Grid item xs={10}>
            <DialogTitle
              sx={{
                typography: {
                  textTransform: 'uppercase',
                  fontWeight: 400,
                },
                paddingLeft: '50px',
                color: 'secondary.main',
              }}
            >
              {title}
            </DialogTitle>
          </Grid>
          <Grid item xs={2} display="flex" justifyContent="flex-end" sx={{ paddingRight: 3 }}>
            <IconButton
              onClick={() => {
                handleModal();
              }}
            >
              <SvgIcon component={Close} sx={{ fontSize: 24 }} />
            </IconButton>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default ModalHeader;
