import React, { useState, useEffect } from 'react';
import { Box, Button, CircularProgress, Badge } from '@mui/material';
import { SvgIconComponent } from '@mui/icons-material';

type ButtonProps = React.ComponentProps<typeof Button>;

export interface ButtonConfirmWithLoadingProps {
  text: string;
  textConfirm?: string;
  confirmCallback: () => any;
  disabled?: boolean;
  Pictogram?: SvgIconComponent;
  color?: ButtonProps['color'];
  variant?: ButtonProps['variant'];
  secondsLeftToCompleteAction?: number;
  isLarge?: boolean;
}

const ButtonConfirmWithLoading = ({
  text,
  textConfirm,
  confirmCallback,
  disabled,
  Pictogram,
  color,
  variant,
  secondsLeftToCompleteAction,
  isLarge,
}: ButtonConfirmWithLoadingProps) => {
  const [isConfirmVisible, setIsConfirmVisible] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [secondsLeftToConfirm, setSecondsLeftToConfirm] = useState<number | null>(null);

  useEffect(() => {
    if (!isConfirmVisible) {
      return;
    }

    if (isLoading) {
      setSecondsLeftToConfirm(null);
      return;
    }

    if (secondsLeftToConfirm != null && secondsLeftToConfirm < 0) {
      setIsConfirmVisible(false);
    }

    const timeout = setTimeout(
      () => setSecondsLeftToConfirm((secondsLeftToConfirm || 0) - 1),
      1000
    );
    return () => clearTimeout(timeout);
  }, [
    secondsLeftToConfirm,
    setSecondsLeftToConfirm,
    isConfirmVisible,
    setIsConfirmVisible,
    isLoading,
  ]);

  const onClickInitial = () => {
    setIsConfirmVisible(true);
    if (secondsLeftToCompleteAction && secondsLeftToCompleteAction < 12) {
      setSecondsLeftToConfirm(secondsLeftToCompleteAction);
    } else {
      setSecondsLeftToConfirm(12);
    }
  };

  const onClickConfirm = async () => {
    setIsLoading(true);
    try {
      await confirmCallback();
    } finally {
      setIsLoading(false);
      setIsConfirmVisible(false);
    }
  };

  return (
    <>
      {isConfirmVisible ? (
        <Button
          disabled={disabled || isLoading}
          onClick={onClickConfirm}
          variant="contained"
          color="secondary"
          size={isLarge ? 'large' : 'medium'}
          fullWidth
        >
          <Badge
            color="primary"
            badgeContent={
              secondsLeftToConfirm ? `${secondsLeftToConfirm} seconds left to confirm!` : null
            }
            anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
            max={1000}
          >
            <Box display="flex" alignItems="center" justifyContent="center" marginRight={1}>
              {isLoading ? (
                <CircularProgress color="inherit" size={isLarge ? '40px' : '30px'} />
              ) : (
                <>
                  {Pictogram && (
                    <Pictogram color="inherit" fontSize={isLarge ? 'large' : 'medium'} />
                  )}
                </>
              )}
              <Box marginLeft={1}>
                {isLoading ? text : textConfirm || 'Click again to confirm!'}
              </Box>
            </Box>
          </Badge>
        </Button>
      ) : (
        <Button
          disabled={disabled}
          onClick={onClickInitial}
          variant={variant || 'outlined'}
          color={color || 'secondary'}
          fullWidth
        >
          <Badge
            overlap="rectangular"
            color="secondary"
            invisible={!secondsLeftToCompleteAction}
            badgeContent={secondsLeftToCompleteAction}
            max={1000}
          >
            <Box display="flex" alignItems="center" justifyContent="center" marginRight={1}>
              {Pictogram && <Pictogram color="inherit" />}

              <Box marginLeft={1}>{text}</Box>
            </Box>
          </Badge>
        </Button>
      )}
    </>
  );
};

export default ButtonConfirmWithLoading;
