import React from 'react';
import { Trucks } from './views/TrucksView/Trucks';
import { Routes, Route } from 'react-router-dom';

export const TrucksViews = () => {
  return (
    <Routes>
      <Route path="/" element={<Trucks />} />
    </Routes>
  );
};
