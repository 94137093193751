import React, { useState, useEffect } from 'react';
import { Box } from '@mui/material';
import { selectAuthUser } from 'modules/auth/storeSliceAuth';
import { useSelector } from 'react-redux';
import CompaniesApi from 'services/postgres/CompaniesApi';

const companiesApi = new CompaniesApi();

export const TruckingReportsView = () => {
  const authUser = useSelector(selectAuthUser);
  const [companyCustomReportUrl, setCompanyCustomReportUrl] = useState(
    'https://datastudio.google.com/embed/reporting/2f5baf80-8739-4296-9f7d-88f1f3e72010/page/5H3TC'
  );

  useEffect(() => {
    if (!authUser?.companiesIds[0]) {
      return;
    }
    const getCompany = async () => {
      try {
        const companyRes = await companiesApi.getById(authUser?.companiesIds[0] || '');
        const { customCivilReportUrl } = companyRes;
        if (customCivilReportUrl) {
          setCompanyCustomReportUrl(customCivilReportUrl);
        }
      } catch (e) {
        console.error(e);
      }
    };
    getCompany();
  }, [authUser]);

  return (
    <Box>
      <iframe
        className="lg-iframe"
        title="Trucking Reports"
        style={{ height: '800px', width: '1400px' }}
        src={companyCustomReportUrl}
        frameBorder="0"
        allowFullScreen
      />
    </Box>
  );
};
