import React, { useEffect, useState, useCallback } from 'react';
import 'sass/globals.scss';
import {
  Box,
  Button,
  Container,
  Grid,
  CircularProgress,
  TextField,
  Autocomplete,
} from '@mui/material';
import Page from 'sharedComponents/Page';
import LanesDetails from './LanesDetails';
import ScaleDetails from 'sharedComponents/ScaleDetails';
import ProfileTypes from './ProfileTypes';
import UnattendedLoad from './UnattendedLoad';
import { hasErrorRequired } from 'services/logic/formValidation';
import { useSelector, useDispatch } from 'react-redux';
import { selectScaleForEdit, setScaleForEdit } from 'modules/scales/storeSliceScales';
import { selectAuthUser } from 'modules/auth/storeSliceAuth';
import { getComparator, stableSort } from 'services/logic/array';
import useShowError from 'modules/errors';
import CompaniesApi from 'services/postgres/CompaniesApi';
import Query from 'services/firebase/Query';
import Command from 'services/firebase/Command';
import Executer from 'services/firebase/Executer';
import QueryBase from 'lg-helpers/dist/firestore/query/QueryBase';

const query = new Query();
const command = new Command();
const executer = new Executer();
const companiesApi = new CompaniesApi();

const AdminView = () => {
  const dispatch = useDispatch();
  const scaleForEdit = useSelector(selectScaleForEdit);
  const [isLoadingSaveDetails, setIsSavingSaveDetails] = useState(false);
  const showError = useShowError();
  const [scaleCompany, setScaleCompany] = useState(null);
  const [isLoadingRegions, setIsLoadingRegions] = useState(false);
  const [selectedRegion, setSelectedRegion] = useState(null);
  const [isLoadingScales, setIsLoadingScales] = useState(false);
  const [scales, setScales] = useState([]);
  const [selectedScale, setSelectedScale] = useState(null);
  const [isDisabledSave, setIsDisabledSave] = useState(true);

  const authUser = useSelector(selectAuthUser);

  useEffect(() => {
    if (!authUser || !authUser.companiesIds) {
      return;
    }
    const getUserCompanyRegions = async () => {
      try {
        setIsLoadingRegions(true);
        const companyRes = await companiesApi.getById(authUser.companiesIds[0]);
        setIsLoadingRegions(false);
        if (companyRes) {
          companyRes.regions = companyRes.regions.filter(r => r.id === authUser.regionId);
          setScaleCompany({
            ...companyRes,
            regions: stableSort(companyRes.regions, getComparator('asc', 'name')),
          });
          if (companyRes.regions && companyRes.regions.length === 1) {
            setSelectedRegion(companyRes.regions[0]);
          }
        } else {
          showError({
            title: `The company ${authUser.companiesIds[0]} doesn't exist`,
            duration: 10000,
          });
        }
      } catch (error) {
        console.error('Error', error);
        setIsLoadingRegions(false);
        showError({
          title: 'Error while fetching the company',
          duration: 10000,
        });
      }
    };
    getUserCompanyRegions();
    // eslint-disable-next-line
  }, [authUser, showError]);

  useEffect(() => {
    if (!selectedRegion) {
      return;
    }

    const getScales = async (companyId, regionId) => {
      setIsLoadingScales(true);
      try {
        const scalesResults = await executer.getMultipleDocuments(
          query.manifests.getScalesForScaleCompanyInRegion(companyId, regionId)
        );
        setScales(scalesResults);

        if (scalesResults.length === 1) {
          setSelectedScale(scalesResults[0]);
        }
      } catch (error) {
        console.error('Error', error);
        showError({
          title: 'Error while fetching the scales',
          duration: 10000,
        });
      }
      setIsLoadingScales(false);
    };

    setScales([]);
    setSelectedScale(null);
    getScales(scaleCompany.id, selectedRegion.id);
  }, [selectedRegion, scaleCompany, showError]);

  useEffect(() => {
    dispatch(setScaleForEdit(selectedScale));
  }, [selectedScale, dispatch]);

  const hasErrors = useCallback(() => {
    return (
      hasErrorRequired(scaleForEdit.companyId) ||
      hasErrorRequired(scaleForEdit.regionId) ||
      hasErrorRequired(scaleForEdit.name) ||
      hasErrorRequired(scaleForEdit.country) ||
      hasErrorRequired(scaleForEdit.address) ||
      hasErrorRequired(scaleForEdit.city) ||
      hasErrorRequired(scaleForEdit.state) ||
      hasErrorRequired(scaleForEdit.zipcode)
    );
  }, [scaleForEdit]);

  useEffect(() => {
    if (!scaleForEdit) {
      return;
    }

    if (hasErrors()) {
      setIsDisabledSave(true);
    } else {
      setIsDisabledSave(false);
    }
  }, [scaleForEdit, hasErrors]);

  const handleSaveDetails = async () => {
    setIsSavingSaveDetails(true);
    try {
      if (scaleForEdit.id) {
        await command.base.set(QueryBase.SCALES_COLLECTION(), scaleForEdit.id, scaleForEdit);
      }
    } catch (error) {
      showError({ title: 'Save Failed!' });
    } finally {
      setIsSavingSaveDetails(false);
    }
  };

  const onScaleDetailsUpdate = e => {
    dispatch(
      setScaleForEdit({
        ...scaleForEdit,
        [e.targetName]: e.targetValue,
      })
    );
  };

  return (
    <Page className="min-padding-and-height" title="Admin">
      <Container>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Button
              color="primary"
              variant="contained"
              onClick={handleSaveDetails}
              disabled={isDisabledSave}
            >
              Save
              {isLoadingSaveDetails && (
                <Box marginLeft={2}>
                  <CircularProgress color="inherit" />
                </Box>
              )}
            </Button>
          </Grid>
          <Grid item md={6} xs={12}>
            {scaleCompany && (
              <Autocomplete
                fullWidth
                required
                disabled={!scaleCompany.regions.length || scaleCompany.regions.length < 2}
                options={scaleCompany.regions || []}
                getOptionLabel={option => option.name}
                value={
                  (scaleCompany.regions || []).find(
                    region => selectedRegion && region.id === selectedRegion.id
                  ) || null
                }
                onChange={(event, value) => setSelectedRegion(value)}
                renderInput={params => (
                  <TextField
                    {...params}
                    label="Region"
                    required
                    variant="outlined"
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <>
                          {isLoadingRegions && <CircularProgress color="inherit" size={20} />}
                          {params.InputProps.endAdornment}
                        </>
                      ),
                    }}
                  />
                )}
              />
            )}
          </Grid>
          <Grid item md={6} xs={12}>
            {selectedRegion && (
              <Autocomplete
                fullWidth
                required
                disabled={!scales.length}
                options={scales || []}
                getOptionLabel={option => option.name}
                value={
                  (scales || []).find(scale => selectedScale && scale.id === selectedScale.id) ||
                  null
                }
                onChange={(event, value) => setSelectedScale(value)}
                renderInput={params => (
                  <TextField
                    {...params}
                    label="Scale"
                    required
                    variant="outlined"
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <>
                          {isLoadingScales && <CircularProgress color="inherit" size={20} />}
                          {params.InputProps.endAdornment}
                        </>
                      ),
                    }}
                  />
                )}
              />
            )}
          </Grid>
          <Grid item md={12} xs={12}>
            <Grid container spacing={2}>
              <Grid item md={12} xs={12}>
                <Grid container spacing={2}>
                  {scaleForEdit && (
                    <ScaleDetails scale={scaleForEdit} onScaleUpdate={onScaleDetailsUpdate} />
                  )}
                </Grid>
              </Grid>
              <Grid item md={12} xs={12}>
                <ProfileTypes />
              </Grid>
              <Grid item md={12} xs={12}>
                <LanesDetails />
              </Grid>
              <Grid item md={12} xs={12}>
                <UnattendedLoad />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
};

export default AdminView;
