import { createAsyncThunk } from '@reduxjs/toolkit';
import { ICompanyMaterial } from 'lg-helpers/dist/shared/interfaces/ICompanyMaterial';
import { INotification } from 'modules/civilAndTrucking/shared/NotificationSnackbar/INotification';
import { pushNotification } from 'modules/civilAndTrucking/shared/NotificationSnackbar/redux/actions';
import { post, del } from 'services/api/restHelpers';
import api from 'services/api/autogenerated';
import { RootState } from 'store/store';

export const getCompanyMaterials = createAsyncThunk(
  'company-materials/get-materials',
  async (payload: string, thunkApi) => {
    try {
      const state = thunkApi.getState() as RootState;
      const companyId = state.civil.companies.activeCompany?.id!;
      const { data } = await api.civilCompanies.getAllCompanyMaterials(companyId, payload);
      return data;
    } catch (err) {
      const errorNotification: INotification = {
        status: 'error',
        message: `Error: Unable to get company materials`,
      };
      thunkApi.dispatch(pushNotification(errorNotification));
      throw err;
    }
  }
);

export const addCompanyMaterial = createAsyncThunk(
  'company-materials/add',
  async (payload: ICompanyMaterial, thunkApi) => {
    try {
      const state = thunkApi.getState() as RootState;
      const companyId = state.civil.companies.activeCompany?.id;
      const payloadWithCompany: ICompanyMaterial = {
        ...payload,
        companyId: companyId,
      };
      const newData = await post(`civil/companies/${companyId}/materials`, payloadWithCompany);
      const successNotification: INotification = {
        status: 'success',
        message: `Success: ${payload.id ? 'Updated ' : 'Added'} ${payload.name}`,
      };
      thunkApi.dispatch(pushNotification(successNotification));
      return newData as ICompanyMaterial;
    } catch (err) {
      const errorNotification: INotification = {
        status: 'error',
        message: `Error: unable to ${payload.id ? 'update' : 'add'} ${payload.name}`,
      };
      thunkApi.dispatch(pushNotification(errorNotification));
      throw err;
    }
  }
);

export const deleteCompanyMaterial = createAsyncThunk(
  'company-materials/delete',
  async (payload: ICompanyMaterial, thunkApi) => {
    try {
      const state = thunkApi.getState() as RootState;
      const companyId = state.civil.companies.activeCompany?.id;
      await del(`civil/companies/${companyId}/materials/${payload.id}`);
      const successNotification: INotification = {
        status: 'success',
        message: `Success: Removed ${payload.name}`,
      };
      thunkApi.dispatch(pushNotification(successNotification));
      return payload;
    } catch (err) {
      const errorNotification: INotification = {
        status: 'error',
        message: `Error: Removing ${payload.name}`,
      };
      thunkApi.dispatch(pushNotification(errorNotification));
      throw err;
    }
  }
);
