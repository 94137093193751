import React, { useMemo } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { useSelector } from 'react-redux';
import { selectLoads } from 'modules/civilAndTrucking/trucking/features/Loads/redux/loadsStoreSlice';
import { LoadingOverlay } from 'sharedComponents/LoadingOverlay';
import { generateBrokerLoadsColumns } from './BrokerLoadsColumns';
import { selectJoinedTrucks } from 'modules/civilAndTrucking/trucking/features/Trucks/redux/trucksStoreSlice';

type BrokerLoadsListProps = {
  handleUpdateLoad: (
    loadId: number,
    loadRepeat?: boolean,
    lastLoad?: boolean,
    truckNumber?: string
  ) => void;
  handleDeleteLoad: (id: number) => void;
};

export const BrokerLoadsList = ({ handleUpdateLoad, handleDeleteLoad }: BrokerLoadsListProps) => {
  const { loads, loadsStatus } = useSelector(selectLoads);
  const { joinedTrucksByCompanies, joinedTrucksStatusByCompanies } =
    useSelector(selectJoinedTrucks);

  const columns = useMemo(
    () => generateBrokerLoadsColumns(handleUpdateLoad, handleDeleteLoad, joinedTrucksByCompanies),
    [handleUpdateLoad, handleDeleteLoad, joinedTrucksByCompanies]
  );

  if (loadsStatus === 'pending' || joinedTrucksStatusByCompanies === 'pending') {
    return <LoadingOverlay />;
  }

  return (
    <DataGrid
      columns={columns}
      rows={loads}
      getRowId={row => row.idTruckingLoad}
      showColumnRightBorder={false}
      disableColumnMenu
      disableSelectionOnClick
      autoHeight
      components={{
        ColumnResizeIcon: () => <div></div>,
      }}
    />
  );
};
