import moment from 'moment';
import 'moment-timezone';
import React from 'react';
import Typography from '@mui/material/Typography';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import IndeterminateCheckBoxIcon from '@mui/icons-material/IndeterminateCheckBox';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import { WebStories } from '@mui/icons-material';
import ButtonLink from 'sharedComponents/ButtonLink';
import SignatureTooltip from 'sharedComponents/SignatureTooltip';

import Query from 'services/firebase/Query';
import Executer from 'services/firebase/Executer';
import QueryBase from 'lg-helpers/dist/firestore/query/QueryBase';

const query = new Query();
const executer = new Executer();

const getScreenSizeFullWidth = (windowSize, widthPixelsUnavailable) => {
  if (!windowSize) {
    return {
      height: 0,
      width: 0,
    };
  }

  let width = windowSize.width - widthPixelsUnavailable;
  let height = width / 0.77;

  return {
    height,
    width,
  };
};

const getScreenSizeFullHeight = (windowSize, heightPixelsUnavailable, widthPixelsUnavailable) => {
  if (!windowSize) {
    return {
      height: 0,
      width: 0,
    };
  }

  let height = windowSize.height - heightPixelsUnavailable;
  let width = height * 0.77;
  const availableWidth = windowSize.width - widthPixelsUnavailable;
  if (width > availableWidth) {
    return getScreenSizeFullWidth(windowSize, widthPixelsUnavailable);
  }

  return {
    height,
    width,
  };
};

export const generateSignatureColumn = (isSigned, path, data, isClaimed = false) => {
  return isSigned ? (
    <SignatureTooltip manifest={data} path={path}>
      <CheckBoxIcon fontSize="large" color="success" />
    </SignatureTooltip>
  ) : isClaimed ? (
    <SignatureTooltip manifest={data} path={path} customMessage="Claimed but not signed">
      <IndeterminateCheckBoxIcon fontSize="large" color="success" />
    </SignatureTooltip>
  ) : (
    <CheckBoxOutlineBlankIcon fontSize="large" color="error" variant="dark" />
  );
};

const dateFormat = 'MM/DD/YYYY hh:mm:ss A';

const getManifestDefaultColumns = (
  includeProjectNr = false,
  includeProjectName = false,
  highlightRejected = true,
  showBatchesSymbol = true,
  showBatchesTrailerNumber = false
) => {
  let columns = [];
  if (includeProjectNr) {
    columns.push({ path: 'project.profileNr', label: 'Profile#' });
  }

  if (includeProjectName) {
    columns.push({ path: 'project.name', label: 'Project Name' });
  }

  if (showBatchesTrailerNumber) {
    columns.push({
      path: 'trailerNumber',
      label: 'Trailer#',
      isFilterable: false,
      getCellValueCallback: row => {
        return row?.trailerNumber ?? '-';
      },
    });
  }

  if (showBatchesSymbol) {
    columns.push({
      path: 'batchId',
      label: '',
      isFilterable: false,
      hasCustomClickHandler: true,
      getCellValueCallback: row => {
        if (row.batchId) {
          return (
            <ButtonLink
              url={`/batched-manifests-details/${row.batchId}`}
              data={row}
              Icon={<WebStories />}
            ></ButtonLink>
          );
        }
        return '';
      },
    });
  }

  if (highlightRejected) {
    columns.push({
      path: 'number',
      label: 'Manifest#',
      isFilterable: false,
      getCellValueCallback: row => {
        if (row?.signatureScale.isRejected) {
          return (
            <Typography align="center" color="error">
              {row.number}
            </Typography>
          );
        } else {
          return row.number;
        }
      },
    });
  } else {
    columns.push({ path: 'number', label: 'Manifest#', isFilterable: false });
  }

  columns = columns.concat([
    {
      path: 'signatureGenerator.isSigned',
      label: 'Generator',
      isVerticalHeaderText: true,
      hasCustomClickHandler: true,
      getCellValueCallback: row =>
        generateSignatureColumn(
          row.signatureGenerator?.isSigned,
          'signatureGenerator.ownerName',
          row
        ),
    },
    {
      path: 'signatureDriver.isSigned',
      label: 'Driver',
      isVerticalHeaderText: true,
      hasCustomClickHandler: true,
      getCellValueCallback: row =>
        generateSignatureColumn(
          row.signatureDriver?.isSigned,
          'signatureDriver.driverName',
          row,
          row.signatureDriver?.isClaimed
        ),
    },
    {
      path: 'signatureScale.isSigned',
      label: 'Scale',
      isVerticalHeaderText: true,
      hasCustomClickHandler: true,
      getCellValueCallback: row =>
        generateSignatureColumn(
          row.signatureScale?.isSigned,
          'signatureScale.scaleAttendantName',
          row
        ),
    },
    {
      label: 'Carrier',
      getCellValueCallback: row =>
        row.signatureDriver?.secondaryTruckingCompany?.trim()
          ? row.signatureDriver?.secondaryTruckingCompany
          : row.signatureDriver?.truckingCompany,
    },
    {
      label: 'Transporter',
      getCellValueCallback: row => String(row.signatureDriver?.truckingCompany),
    },
    {
      label: 'Truck#/Driver',
      getCellValueCallback: row => {
        if (!row.signatureDriver?.truckNumber && !row.signatureDriver?.driverName) {
          return '-';
        }
        return `${row.signatureDriver?.truckNumber || '-'}/${
          row.signatureDriver?.driverName || '-'
        }`;
      },
    },
    {
      label: 'Qty',
      getCellValueCallback: row => {
        const quantity = row?.receipt?.weight?.rounded_net || row?.receipt?.quantity;
        const unit_of_measure =
          row?.receipt?.weight?.rounded_net_uom || row?.receipt?.quantityuom || '-';
        if (!quantity) {
          return '-';
        }
        return `${quantity} ${unit_of_measure}`;
      },
    },
    {
      path: 'lastUsedAt',
      label: 'Last used/signed',
      getCellValueCallback: row => {
        let lastUsedAt = row.lastUsedAt ? row.lastUsedAt.toDate() : null;
        let generatorSignedAt = row.signatureGenerator?.signedAt
          ? row.signatureGenerator.signedAt.toDate()
          : null;
        if (lastUsedAt > generatorSignedAt || row?.receipt?.time_in) {
          if (lastUsedAt) {
            const momentDate = moment.tz(lastUsedAt, row.project?.timeZone || 'EST');
            return `${momentDate.format(dateFormat)} ${momentDate.zoneAbbr()}`;
          }
        } else {
          if (generatorSignedAt) {
            const momentDate = moment.tz(generatorSignedAt, row.project?.timeZone || 'EST');
            return `${momentDate.format(dateFormat)} ${momentDate.zoneAbbr()}`;
          }
        }
        return '-';
      },
    },
    {
      label: 'Map',
      hasCustomClickHandler: true,
      getCellValueCallback: row => (
        <ButtonLink
          url={`/manifests/${row.id}/map`}
          data={row}
          Icon={<LocationOnIcon fontSize="small" />}
        />
      ),
    },
  ]);

  return columns;
};

// TODO: lol, this is so wrong!!!
const getManifestVisibleCustomInputsDefinitions = (manifest, userType, section, canEdit) => {
  return new Promise((resolve, reject) => {
    executer.watchSingleDocument(
      query.base.getById(QueryBase.PROJECTS_COLLECTION(), manifest?.project?.id),
      proj => {
        if (proj?.customInputsDefinitions && proj.customInputsDefinitions.length) {
          const results = proj.customInputsDefinitions.filter(inputDefinition => {
            const isCorrectUserType =
              (
                (canEdit
                  ? inputDefinition.userTypesCanEdit
                  : inputDefinition.userTypesCanOnlyRead) || []
              ).indexOf(userType) !== -1;
            const isCorrectSection =
              (
                (canEdit ? inputDefinition.sectionsCanEdit : inputDefinition.sectionsCanOnlyRead) ||
                []
              ).indexOf(section) !== -1;
            return isCorrectUserType && isCorrectSection;
          });

          resolve(results);
        }
        resolve([]);
      },
      err => {
        reject(err);
      }
    );
  });
};

const getManifestVisibleCustomInputsDefinitionsCanOnlyRead = async (manifest, userType, section) =>
  await getManifestVisibleCustomInputsDefinitions(manifest, userType, section, false);
const getManifestVisibleCustomInputsDefinitionsCanEdit = async (manifest, userType, section) =>
  await getManifestVisibleCustomInputsDefinitions(manifest, userType, section, true);

const generateSignedAt = (signedOn, timeZone) => {
  let signedAt = null;
  if (signedOn) {
    const momentDate = moment.tz(signedOn.toDate(), timeZone || 'EST');
    signedAt = `${momentDate.format(dateFormat)} ${momentDate.zoneAbbr()}`;
  }

  return signedAt;
};

export {
  getScreenSizeFullHeight,
  getScreenSizeFullWidth,
  getManifestDefaultColumns,
  getManifestVisibleCustomInputsDefinitions,
  getManifestVisibleCustomInputsDefinitionsCanEdit,
  getManifestVisibleCustomInputsDefinitionsCanOnlyRead,
  generateSignedAt,
};
