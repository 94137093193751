import React from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import { ListItem, Typography, SvgIcon } from '@mui/material';

export interface INavItemProps {
  className?: string;
  href: string;
  Pictogram?: React.ReactNode;
  title: string;
  [key: string]: any;
}

export const NavItem = ({ href, Pictogram, title }: INavItemProps) => {
  const location = useLocation();
  const selected = !!href.match(`^${location.pathname}$`);

  return (
    <ListItem
      sx={{
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: 'rgba(65, 121, 58, 0.025)',
        borderRadius: '4px',
        width: '80px',
        height: '100%`',
        my: 1,
      }}
      selected={selected}
      component={NavLink}
      to={href}
      disableGutters
      button
    >
      <SvgIcon sx={{ fontSize: 24 }}>{Pictogram}</SvgIcon>
      <Typography sx={{ lineHeight: 1.6, paddingTop: '5px' }} variant="overline" align="center">
        {title}
      </Typography>
    </ListItem>
  );
};
