import React, { useEffect, useState } from 'react';
import { thunkDispatch } from 'store/store';
import { useNavigate } from 'react-router-dom';
import { Box, Typography, TextField } from '@mui/material';
import { getProjectsByCompanyId } from '../../redux/actions';
import { ProjectsList } from './components/ProjectsList';
import { ManageHeader } from 'sharedComponents/ManageHeader';
import ButtonToggle from 'sharedComponents/ButtonToggle';

export const Projects = () => {
  const navigate = useNavigate();
  const [searchFilter, setSearchFilter] = useState('');
  const [toggleIndex, setToggleIndex] = useState<0 | 1>(0);

  useEffect(() => {
    const activeOrInactive = toggleIndex ? 'inactive' : 'active';
    thunkDispatch(getProjectsByCompanyId(activeOrInactive));
  }, [toggleIndex]);

  const ToolBarComponent = (
    <Box display="flex" alignItems="center">
      <Box width="100%" sx={{ mr: 1 }}>
        <ButtonToggle
          firstVal={'Active'}
          secondVal={'Inactive'}
          toggleIndex={toggleIndex}
          setToggleIndex={setToggleIndex}
        />
      </Box>
      <Typography sx={{ mr: 1 }}>Search</Typography>
      <TextField
        name="searchFilter"
        size="small"
        value={searchFilter}
        onChange={e => setSearchFilter(e.target.value)}
      />
    </Box>
  );

  return (
    <>
      <ManageHeader
        headerTitle={'Manage Projects'}
        buttonText={'Add Project'}
        onButtonClick={() => navigate('/trucking/setup/projects/project')}
        centerComponent={ToolBarComponent}
      />
      <ProjectsList filterLowerCase={searchFilter.toLowerCase()} />
    </>
  );
};
