import React, { useContext } from 'react';
import { Box, DialogTitle, SvgIcon, IconButton } from '@mui/material';
import { ModalContext } from './ModalContext';
import { Close } from '@mui/icons-material';

interface ModalHeaderProps {
  title: string;
  optionalElement?: JSX.Element;
}

const ModalHeader = ({ title, optionalElement }: ModalHeaderProps) => {
  const { setModal } = useContext(ModalContext);
  return (
    <Box
      sx={{
        borderColor: 'rgba(109, 112, 102, 0.25)',
        borderBottomStyle: 'solid',
        borderWidth: '0.5px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
      }}
    >
      <DialogTitle sx={{ px: 1, fontWeight: 400 }}>{title}</DialogTitle>
      <Box sx={{ marginRight: '1em' }}>
        {optionalElement && optionalElement}
        <IconButton onClick={() => setModal(false)}>
          <SvgIcon sx={{ fontSize: 14 }}>
            <Close />
          </SvgIcon>
        </IconButton>
      </Box>
    </Box>
  );
};

export default ModalHeader;
