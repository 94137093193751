import { ReceiptTemplate } from 'lg-helpers/dist/shared/types/manifests';
import { Manifest } from 'lg-helpers/dist/shared/types/manifests';
import useGetReceiptTemplate from './useGetReceiptTemplate';

const useGetReceiptTemplateWithFallback = (manifest?: Manifest | null) => {
  const { isLoading, isError, template } = useGetReceiptTemplate(
    manifest?.project?.scaleCompany?.id,
    manifest?.project?.receiptTemplateId
  );

  return {
    isLoading,
    isError,
    template: (isError ? (manifest as any)?.receiptTemplate : template) as ReceiptTemplate | null,
  };
};

export default useGetReceiptTemplateWithFallback;
