import React from 'react';
import { GridColDef } from '@mui/x-data-grid';
import { Button, MenuItem, Select } from '@mui/material';
import { CheckBoxOutlineBlank, CheckBox } from '@mui/icons-material';
import { Delete } from '@mui/icons-material';
import { IJoinTruck } from 'lg-helpers/dist/trucking/interfaces/IJoinTruck';

export const generateBrokerLoadsColumns = (
  handleUpdateLoad: (
    loadId: number,
    loadRepeat?: boolean,
    lastLoad?: boolean,
    truckNumber?: string
  ) => void,
  handleDeleteLoad: (id: number) => void,
  joinedTrucks: IJoinTruck[]
) => {
  return [
    {
      field: 'truckNumberTruckingLoad',
      headerName: 'Truck No.',
      width: 150,
      renderCell: ({ row }) =>
        row?.truckNumberTruckingLoad || (
          <Select
            label="Assign Truck"
            onChange={(e: any) =>
              handleUpdateLoad(row.idTruckingLoad, undefined, undefined, e.target.value)
            }
          >
            {joinedTrucks.map(joinedTruck => (
              <MenuItem
                key={joinedTruck?.idTruckingTruck}
                value={joinedTruck?.truckNumberTruckingTruck}
              >
                {joinedTruck?.truckNumberTruckingTruck}
              </MenuItem>
            ))}
          </Select>
        ),
    },
    {
      field: 'isLastLoad',
      headerName: 'Last Load?',
      width: 150,
      renderCell: params => (
        <Button
          onClick={() =>
            handleUpdateLoad(params.row.idTruckingLoad, undefined, !params.row.isLastLoad)
          }
        >
          {params.row?.isLastLoad ? <CheckBox /> : <CheckBoxOutlineBlank />}
        </Button>
      ),
    },
    {
      field: 'repeat',
      headerName: 'Can repeat?',
      flex: 1,
      sortable: false,
      renderCell: params => (
        <Button
          onClick={() =>
            handleUpdateLoad(params.row.idTruckingLoad, !params.row.canRepeatTruckingLoad)
          }
        >
          {params.row?.canRepeatTruckingLoad ? <CheckBox /> : <CheckBoxOutlineBlank />}
        </Button>
      ),
    },
    {
      field: 'delete',
      headerName: ' ',
      flex: 0.01,
      align: 'right',
      sortable: false,
      renderCell: ({ row }) => (
        <Button sx={{ color: '#777' }} onClick={() => handleDeleteLoad(row.idTruckingLoad)}>
          <Delete />
        </Button>
      ),
    },
  ] as GridColDef[];
};
