import { createSlice, isAnyOf } from '@reduxjs/toolkit';
import { RootState } from 'store/store';
import {
  getJoinedDispatchesByCompanyId,
  getJoinedDispatchByDispatchId,
  getAllJoinedDispatchesByCompanyId,
  getJoinedBrokerDispatchesByCompanyId,
  getJoinedDispatchesByCompaniesIds,
  getJoinedDispatchesByContractorCompanyIds,
} from './actions';
import { IJoinTruckingDispatch } from 'lg-helpers/dist/trucking/interfaces/IJoinTruckingDispatch';
import { TFormStatus } from 'lg-helpers/dist/shared/types/TFormStatus';

interface IDispatchesState {
  data: IJoinTruckingDispatch[];
  dataAll: IJoinTruckingDispatch[];
  dispatch: IJoinTruckingDispatch;
  status: TFormStatus;
  statusAll: TFormStatus;
}

const initialDispatchesState: IDispatchesState = {
  data: [] as IJoinTruckingDispatch[],
  dataAll: [] as IJoinTruckingDispatch[],
  dispatch: {} as IJoinTruckingDispatch,
  status: null,
  statusAll: null,
};
export const dispatchesSlice = createSlice({
  name: 'dispatches',
  reducers: {},
  initialState: initialDispatchesState,
  extraReducers: builder => {
    builder
      .addCase(getJoinedDispatchByDispatchId.pending, state => {
        state.status = 'pending';
      })
      .addCase(getJoinedDispatchByDispatchId.fulfilled, (state, { payload }) => {
        state.status = 'success';
        state.dispatch = payload;
      })
      .addCase(getJoinedDispatchByDispatchId.rejected, (state, { error }) => {
        state.status = 'failure';
        state.dispatch = {} as IJoinTruckingDispatch;
        console.error('getJoinedDispatchByDispatchId error: ', error);
      })
      .addCase(getAllJoinedDispatchesByCompanyId.pending, state => {
        state.statusAll = 'pending';
      })
      .addCase(getAllJoinedDispatchesByCompanyId.fulfilled, (state, { payload }) => {
        state.statusAll = 'success';
        state.dataAll = payload;
      })
      .addCase(getAllJoinedDispatchesByCompanyId.rejected, (state, { error }) => {
        state.statusAll = 'failure';
        state.dataAll = [] as IJoinTruckingDispatch[];
        console.error('getJoinedDispatchByDispatchId error: ', error);
      })
      .addMatcher(
        isAnyOf(
          getJoinedDispatchesByCompanyId.fulfilled,
          getJoinedDispatchesByCompaniesIds.fulfilled,
          getJoinedDispatchesByContractorCompanyIds.fulfilled,
          getJoinedBrokerDispatchesByCompanyId.fulfilled
        ),
        (state, { payload }) => {
          state.status = 'success';
          state.data = payload;
        }
      )
      .addMatcher(
        isAnyOf(
          getJoinedDispatchesByCompanyId.pending,
          getJoinedDispatchesByCompaniesIds.pending,
          getJoinedDispatchesByContractorCompanyIds.pending,
          getJoinedBrokerDispatchesByCompanyId.pending
        ),
        (state, {}) => {
          state.status = 'pending';
        }
      )
      .addMatcher(
        isAnyOf(
          getJoinedDispatchesByCompanyId.rejected,
          getJoinedDispatchesByCompaniesIds.rejected,
          getJoinedDispatchesByContractorCompanyIds.rejected,
          getJoinedBrokerDispatchesByCompanyId.rejected
        ),
        (state, { error }) => {
          state.status = 'failure';
          state.data = [];
          console.error('getJoinedDispatchesByCompanyId error: ', error);
        }
      );
  },
});

export const selectJoinedDispatches = (state: RootState) => {
  return {
    joinedDispatches: state.trucking.dispatches.data,
    joinedDispatchesStatus: state.trucking.dispatches.status,
  };
};

export const selectJoinedDispatch = (state: RootState) => {
  return {
    joinedDispatch: state.trucking.dispatches.dispatch,
    joinedDispatchStatus: state.trucking.dispatches.status,
  };
};

export const selectAllJoinedDispatches = (state: RootState) => {
  return {
    allJoinedDispatches: state.trucking.dispatches.dataAll,
    allJoinedDispatchesStatus: state.trucking.dispatches.statusAll,
  };
};
