import React from 'react';
import { createSvgIcon } from '@mui/material';

export const Picture = createSvgIcon(
  <>
    <path d="M17.8551 5.55207c-.1127.45288-.2012 1.03996-.3944 1.57671-.3381.93093-.322 1.81993 0 2.75086.2093.59546.33 1.23286.4749 1.85346.2093.8639-.1288 1.5348-.9821 1.6774-.9257.1509-1.4651.5787-1.5215 1.5851-.008.1761-.0805.3522-.1288.52-.2012.6457-.7808 1.0064-1.4168.8386-1.1511-.3019-2.3023-.6374-3.4535-.9393-.169-.0419-.3703-.0503-.53931 0-1.85152.5032-3.69499 1.0232-5.54651 1.5264-.70841.1929-1.32022-.1593-1.52147-.8974-.2415-.889-.4669-1.7696-.68426-2.667-.05635-.2348-.15295-.369-.3864-.4025-.2093-.0336-.41861-.1091-.61986-.1678-.7889-.218-1.14311-.8638-.9338-1.6941.1932-.7548.4025-1.50123.5796-2.25604.05635-.22645.0483-.49482 0-.72127-.21735-.93093-.4669-1.86186-.70036-2.78441-.2415-.97286.0644-1.59349.99821-1.76961.88551-.15935 1.42487-.55352 1.46512-1.53478.00805-.19289.08855-.3774.1449-.56191C2.89001.8387 3.46962.47806 4.10557.6458c1.15117.30192 2.30233.62901 3.45349.93932.16906.04193.37031.05032.53936 0C9.94994 1.08191 11.7934.56193 13.6449.05872c.7165-.20128 1.3202.15935 1.5215.8974.2415.88899.4669 1.7696.6843 2.66699.0563.23483.1529.36063.3864.40256.2737.05871.5474.14258.813.23483.4911.16774.805.62901.805 1.29157ZM16.5349 11.188c-.008-.0587-.0322-.2265-.0724-.3858-.3703-1.4677-.7487-2.93538-1.1271-4.40307-.33-1.27479-.652-2.55797-.9821-3.83276-.2173-.8219-.7889-1.18253-1.5778-.98125-.2093.05032-.4105.10903-.6198.16773-3.21202.89739-6.41596 1.79478-9.61989 2.69216-.91771.25999-1.23167.83868-.99016 1.79477.6923 2.69216 1.37656 5.38432 2.07692 8.06812.26565 1.0148.80501 1.3335 1.76297 1.0651 1.81932-.5032 3.63864-1.0232 5.45796-1.5264 1.5456-.4361 3.0993-.8555 4.653-1.2916.6842-.2013 1.0304-.629 1.0384-1.367ZM3.65477 2.83475c.58766-.16774 1.15116-.32708 1.81127-.51998-.96601-.3187-1.48927-.15096-1.81127.51998ZM12.5421 14.6685c.9096.3522 1.5295.1593 1.771-.4948-.5635.1593-1.119.3103-1.771.4948Z" />
    <path d="M4.86228 14.182c-.05635-1.0818-.0483-2.1218.31396-3.1282.33005-.9058.96601-1.30835 1.96422-1.22448.42665.04193.86135.08386 1.26386.23478 1.27191.4697 2.53578.5703 3.85598.2097.4991-.1342 1.0626-.0084 1.5939.0168.1047 0 .2093.0503.3059.1006.2898.1342.483.3439.4508.7129-.0322.3355-.2656.4613-.5393.5368-1.1753.327-2.3506.6541-3.526.9812-1.77903.4948-3.56615.9896-5.34521 1.4845-.10466.0335-.20931.0503-.33811.0754ZM9.58765 5.63603c0-.89739.62795-1.55156 1.48925-1.55156.8372 0 1.5054.69611 1.5054 1.55995 0 .85545-.6843 1.55994-1.5134 1.55994-.8533 0-1.48125-.67095-1.48125-1.56833Z" />
  </>,
  'Picture'
);
