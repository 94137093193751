import React from 'react';
import { createSvgIcon } from '@mui/material';

export const Home = createSvgIcon(
  <path
    fillRule="evenodd"
    clipRule="evenodd"
    d="M10.3753.21913c.3653-.29217.8842-.29217 1.2494 0l7.4998 5.99984c.0001.00005 0-.00006 0 0l2.5002 2.00016c.4313.34501.5012.9743.1562 1.40556-.345.43131-.9743.50121-1.4056.15618L19.5 9.08062V19c0 .5523-.4477 1-1 1H3.50004c-.55229 0-1-.4477-1-1V9.08062l-.87531.70025c-.43126.34503-1.06055.27513-1.40556-.15618-.34501-.43126-.27509-1.06055.15617-1.40556l2.5-2c.0001-.00008-.0001.00008 0 0l7.49996-6ZM4.50004 7.48062 11 2.28062l6.5 5.2V18h-3v-5.5c0-.5523-.4477-1-1-1H8.50004c-.55229 0-1 .4477-1 1V18h-3V7.48062Zm5 10.51938H12.5v-4.5H9.50004V18Z"
  />,
  'Home'
);
