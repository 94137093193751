import { useState, useEffect } from 'react';
import Query from 'services/firebase/Query';
import Executer from 'services/firebase/Executer';
import useIsUserOfType from 'services/hooks/useIsUserOfType';
import useShowError from 'modules/errors';
import { usePermissionsContext } from 'services/permissions';

const query = new Query();
const executer = new Executer();

export interface BrokerDispatch {
  id: string;
  [key: string]: any;
} // TODO: define this type (located in firebase)

const useGetDispatches = (dateRangeFilter: [Date, Date], showOnlyActive: boolean) => {
  const { user } = usePermissionsContext();
  const isUserBrokerDriver = useIsUserOfType('trucking_driver');
  const isUserBrokerAdmin = useIsUserOfType('trucking_dispatcher');

  const [isLoading, setIsLoading] = useState(true);
  const [dispatches, setDispatches] = useState<BrokerDispatch[]>([]);
  const showError = useShowError();

  useEffect(() => {
    const { customData } = user as any;
    const { truckNumber = '' } = customData || {};

    setIsLoading(true);

    return executer.watchMultipleDocuments(
      query.brokers.brokerGetDispatches(user.companiesIds, dateRangeFilter, showOnlyActive),
      data => {
        const foundDispatches = data as BrokerDispatch[];
        if (isUserBrokerAdmin) {
          setDispatches(foundDispatches);
        } else if (isUserBrokerDriver) {
          if (showOnlyActive) {
            setDispatches(
              foundDispatches.filter(
                ({ aggregatedData: { totalByTruckNumber, loadedByTruckNumber } }) => {
                  return (
                    (totalByTruckNumber[truckNumber] || 0) > (loadedByTruckNumber[truckNumber] || 0)
                  );
                }
              )
            );
          } else {
            setDispatches(
              foundDispatches.filter(({ aggregatedData: { loadedByTruckNumber } }) => {
                return !!loadedByTruckNumber[truckNumber];
              })
            );
          }
        }

        setIsLoading(false);
      },
      error => {
        console.error('Error', error);
        showError({
          title: 'Error while fetching the dispatches',
          duration: 10000,
        });
        setIsLoading(false);
      }
    );
    // eslint-disable-next-line
  }, [user, dateRangeFilter, showOnlyActive, isUserBrokerDriver, isUserBrokerAdmin]);

  return { dispatches, isLoading };
};

export default useGetDispatches;
