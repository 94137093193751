import React, { createContext } from 'react';
import useModal from './useModal';

const ModalContext = createContext<any>(null);

type TProps = React.PropsWithChildren<{}>;

const ModalProvider = (props: TProps) => {
  const { modal, modalContent, setModal, setModalContent, handleModal } = useModal();

  return (
    <ModalContext.Provider value={{ modal, modalContent, setModal, setModalContent, handleModal }}>
      {props.children}
    </ModalContext.Provider>
  );
};
export { ModalContext, ModalProvider };
