import React from 'react';
import PropTypes from 'prop-types';
import { SvgIcon } from '@mui/material';
import { ReactComponent as Svg } from './raw/Manifest.svg';

const Manifest = props => {
  return (
    <SvgIcon {...props} width={!props ? '100%' : props.width}>
      <Svg />
    </SvgIcon>
  );
};

Manifest.propTypes = {
  width: PropTypes.any,
};

export default Manifest;
