import React from 'react';
import { Modal, Box, DialogContent, Typography, DialogActions, Button } from '@mui/material';
import api from '../../../services/api/autogenerated';

interface DeleteConfirmationModalProps {
  workCodeId: string;
  open: boolean;
  onClose: () => void;
  onWorkCodeUpdate: () => void;
}

const modalStyle = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

const DeleteConfirmationModal: React.FC<DeleteConfirmationModalProps> = ({
  workCodeId,
  open,
  onClose,
  onWorkCodeUpdate,
}) => {
  const cancelWorkCode = async () => {
    await api.workCode.cancelWorkCodeRequest({ workCodeId });

    onClose();
    onWorkCodeUpdate();
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={modalStyle}>
        <DialogContent>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Are you sure?
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            Deleting this work code will remove all active drivers for this project!
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose}>Cancel</Button>
          <Button onClick={cancelWorkCode} autoFocus>
            Confirm
          </Button>
        </DialogActions>
      </Box>
    </Modal>
  );
};

export default DeleteConfirmationModal;
