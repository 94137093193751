import { createSlice } from '@reduxjs/toolkit';
import {
  addCompany,
  deleteApiMapping,
  getApiMappingDetails,
  getCompanyByType,
  saveApiMapping,
  setActiveCompany,
} from './actions';
import { ICompany } from 'lg-helpers/dist/shared/interfaces/ICompany';
import { IJoinCompany } from 'lg-helpers/dist/shared/interfaces/IJoinCompany';
import { ICompanyApiMapping } from 'lg-helpers/dist/shared/interfaces/ICompanyApiMapping';
import { TFormStatus } from 'lg-helpers/dist/shared/types/TFormStatus';

interface ICompanyState {
  data: ICompany[];
  companyOptions: IJoinCompany[];
  status: TFormStatus;
  error: string | undefined;
  activeCompany?: IJoinCompany;
  activeCompanyStatus: TFormStatus;
  apiMapping: ICompanyApiMapping[];
}

const initialState: ICompanyState = {
  data: [],
  companyOptions: [],
  status: null,
  activeCompanyStatus: null,
  error: undefined,
  activeCompany: undefined,
  apiMapping: [],
};

export const companiesSlice = createSlice({
  name: 'companies',
  reducers: {
    add: state => state,
    getCompanies: state => state,
    getCompanyTypes: state => state,
  },
  initialState,
  extraReducers: builder => {
    builder.addCase(setActiveCompany.fulfilled, (state, { payload }) => {
      state.activeCompanyStatus = 'success';
      state.activeCompany = payload as IJoinCompany;
    });

    builder.addCase(setActiveCompany.rejected, (state, payload) => {
      console.error('Error getting active company: ', payload.error);
      state.activeCompanyStatus = 'failure';
      state.error = payload.error?.message;
    });

    builder.addCase(getCompanyByType.fulfilled, (state, { payload }) => {
      state.status = 'success';
      state.companyOptions = payload;
    });

    builder.addCase(addCompany.fulfilled, (state, { payload }) => {
      state.data.push(payload as IJoinCompany);
    });

    builder.addCase(getApiMappingDetails.fulfilled, (state, { payload }) => {
      state.status = 'success';
      state.apiMapping = payload as ICompanyApiMapping[];
    });

    builder.addCase(saveApiMapping.fulfilled, (state, { payload }) => {
      state.status = 'success';
      const index = state.apiMapping.findIndex(a => a.id === payload?.id);
      if (index === -1) {
        state.apiMapping.push(payload as ICompanyApiMapping);
      } else {
        state.apiMapping[index] = payload as ICompanyApiMapping;
      }
    });

    builder.addCase(deleteApiMapping.fulfilled, (state, { payload }) => {
      state.status = 'success';
      state.apiMapping = state.apiMapping.filter(a => a.id !== payload);
    });
  },
});
