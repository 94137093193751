import { createSlice } from '@reduxjs/toolkit';
import {
  getCompanyMaterials,
  addCompanyMaterial,
  deleteCompanyMaterial,
} from 'modules/civilAndTrucking/civil/CompanyMaterials/redux/actions';
import { ICompanyMaterial } from 'lg-helpers/dist/shared/interfaces/ICompanyMaterial';
import { TFormStatus } from 'lg-helpers/dist/shared/types/TFormStatus';

interface ICompanyMaterialState {
  data: any[]; // TODO: this type should be on API side (but old knex code there)
  status: TFormStatus;
  error: string | undefined;
}

const initialState: ICompanyMaterialState = {
  data: [],
  status: null,
  error: undefined,
};

export const companyMaterialsSlice = createSlice({
  name: 'company-materials',
  reducers: {
    add: state => state,
    getCompanyMaterials: state => state,
  },
  initialState,
  extraReducers: builder => {
    builder.addCase(getCompanyMaterials.fulfilled, (state, { payload }) => {
      state.status = 'success';
      state.data = payload;
    });

    builder.addCase(getCompanyMaterials.rejected, (state, payload) => {
      state.status = 'failure';
      state.error = payload.error?.message;
    });

    builder.addCase(addCompanyMaterial.fulfilled, (state, { payload }) => {
      state.status = 'success';
      const newData = !state.data.some(item => item.id === payload?.id);
      if (newData) {
        state.data.push(payload as ICompanyMaterial);
      } else {
        const index = state.data.findIndex(a => a.id === payload?.id);
        state.data[index] = payload!;
      }
    });

    builder.addCase(deleteCompanyMaterial.fulfilled, (state, { payload }) => {
      state.status = 'success';
      state.data = state.data.filter(item => item.id !== payload.id);
    });
  },
});
