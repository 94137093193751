import React, { useCallback, useEffect, useState, useMemo } from 'react';
import {
  Autocomplete,
  Box,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  Grid,
  TextField,
} from '@mui/material';
import Fuse from 'fuse.js';
import { useNavigate } from 'react-router-dom';

import { useDispatch, useSelector } from 'react-redux';
import { deleteUser } from 'api/auth';
import CrudTable from 'sharedComponents/CrudTable';
import useShowError from 'modules/errors';
import Query from 'services/firebase/Query';
import Executer from 'services/firebase/Executer';
import { UserTypes } from 'lg-helpers/dist/constants/user/UserTypes';
import { ICompany } from 'lg-helpers/dist/shared/interfaces/ICompany';
import { IUser } from 'lg-helpers/dist/shared/interfaces/IUser';
import {
  selectUsersSearchFilters,
  setUsersSearchFilters,
} from '../modules/superAdmins/superAdminUsersStoreSlice';
import CompaniesApi from '../services/postgres/CompaniesApi';
import { usePermissionsContext } from 'services/permissions';

const executer = new Executer();
const query = new Query();
const companiesApi = new CompaniesApi();

const columnsGeneral = [
  { path: 'type', label: 'Type' },
  { path: 'email', label: 'Email' },
  { path: 'phone', label: 'Phone' },
  {
    label: 'Name',
    getCellValueCallback: (row: any) => `${row.firstName || ''} ${row.lastName || ''}`,
  },
];

const columnsAdmin = [
  {
    path: 'type',
    label: 'Type',
    getCellValueCallback: (row: any) => row.type,
  },
  { path: 'email', label: 'Email' },
  { path: 'phone', label: 'Phone' },
  {
    label: 'Name',
    getCellValueCallback: (row: any) => `${row.firstName || ''} ${row.lastName || ''}`,
  },
];

const UsersList = () => {
  const navigate = useNavigate();
  const { user: authUser } = usePermissionsContext();

  // Super Admin Variables
  const dispatch = useDispatch();
  const isSuperAdmin = authUser.role === 'super_admin';
  const [showOnlyAdmins, setShowOnlyAdmins] = useState(false);
  const [companies, setCompanies] = useState([]);
  const [isLoadingCompanies, setIsLoadingCompanies] = useState(false);
  const usersFilters = useSelector(selectUsersSearchFilters);
  const [usersSearchFilter, setUsersSearchFilter] = useState(
    isSuperAdmin ? usersFilters?.userSearchFilter : ''
  );

  const columns = isSuperAdmin ? columnsAdmin : columnsGeneral;

  const [selectedCompany, setSelectedCompany] = useState<Partial<ICompany> | null>(null);
  const [isLoadingUsers, setIsLoadingUsers] = useState(false);
  const showError = useShowError();

  const [users, setUsers] = useState<IUser[]>([]);
  const [selectedUsersIds, setSelectedUsersIds] = useState<string[]>([]);

  // If super admin, get all companies, otherwise set selected company
  useEffect(() => {
    if (isSuperAdmin) {
      const getCompanies = async () => {
        try {
          setIsLoadingCompanies(true);
          // TODO: hotfixed with excluded types, but needs fix/optimisation in future
          const companiesResult = await companiesApi.getCompaniesWithCustomDataProperties([
            'regions',
          ]);
          setCompanies(companiesResult);
          setIsLoadingCompanies(false);
        } catch (error) {
          console.error('Error', error);
          showError({
            title: 'Error while fetching the companies',
            duration: 10000,
          });
          setIsLoadingCompanies(false);
        }
      };

      getCompanies();
    } else {
      if (!authUser || !authUser.companies[0]) {
        return;
      }
      setSelectedCompany(authUser.companies[0] as any);
    }
    // eslint-disable-next-line
  }, [authUser, isSuperAdmin]);

  // Load users by current company
  const loadUsers = useCallback(async () => {
    if (!selectedCompany?.id && !showOnlyAdmins) {
      setUsers([]);
      return;
    }

    setUsers([]);
    setIsLoadingUsers(true);

    try {
      let queryUsers;
      if (showOnlyAdmins) {
        queryUsers = query.users.getUsersOfAnyTypes([
          UserTypes.admin.super,
          UserTypes.admin.customer,
        ]);
      } else {
        queryUsers = query.users.getUsersInCompany(selectedCompany?.id!);
      }
      const usersResults = (await executer.getMultipleDocuments(queryUsers)) as IUser[];

      // filter out all 'isAnonymousLogin' users (client-side filter, might be removed in the future)
      const filteredUsers = usersResults.filter(user => !user.isAnonymousLogin);
      setUsers(filteredUsers);
    } catch (error) {
      console.error('Error', error);
      showError({
        title: 'Error while fetching the users',
        duration: 10000,
      });
    }
    setIsLoadingUsers(false);
    // eslint-disable-next-line
  }, [selectedCompany, showOnlyAdmins]);

  // every time the usersFilter is updated, super admin only
  useEffect(() => {
    const { userSearchFilter, selectedCompanyFilter } = usersFilters;

    setUsersSearchFilter(userSearchFilter);
    if (isSuperAdmin) setSelectedCompany(selectedCompanyFilter);
  }, [usersFilters, isSuperAdmin]);

  // Filter out all super admin user types
  // If there is a user filter FUZZY search for matching users
  const filteredUsers = useMemo(() => {
    // Filter out all the super admins
    let filtered = isSuperAdmin
      ? users
      : users.filter((user: IUser) => user.type !== UserTypes.admin.super);

    if (!usersSearchFilter) {
      return filtered;
    }

    const options = {
      includeScore: true,
      threshold: 0.5,
      keys: ['name', 'email', 'type'],
    };

    const fuse = new Fuse(filtered, options);
    filtered = fuse.search(usersSearchFilter).map(result => result.item);

    return filtered;
    // eslint-disable-next-line
  }, [users, usersSearchFilter]);

  // Load all available users whenever company is updated
  useEffect(() => {
    loadUsers();
    // eslint-disable-next-line
  }, [selectedCompany, showOnlyAdmins]);

  // Delete user by ROW
  const handleClickDelete = async () => {
    try {
      for (let i = 0; i < selectedUsersIds.length; i++) {
        await deleteUser(selectedUsersIds[i]);
      }
      await loadUsers();
    } catch (err) {
      console.error(err);
      showError({ title: 'Delete error' });
    }
  };

  const onShowAdminsChange = (event: any) => {
    setShowOnlyAdmins(event.target.checked);
    if (event.target.checked) {
      setSelectedCompany(null);
    }
  };

  const getCompanyLabel = (company: ICompany) => {
    if (!company) {
      return '';
    }
    const typesStringified = company.types ? company.types.map(type => type).join(', ') : '';
    return `${company.name} - ${typesStringified}`;
  };

  const onCompanyChange = (event: any, value: any) => {
    setSelectedCompany(value);
    setShowOnlyAdmins(false);
  };

  const currentPath = '/' + window.location.pathname.split('/')[1];
  const addUserRoute = currentPath + '/user';
  const editUserRoute = currentPath + '/user/';

  const handleRowClick = (user: IUser) => {
    if (isSuperAdmin) {
      dispatch(
        setUsersSearchFilters({
          userSearchFilter: usersSearchFilter,
          selectedCompanyFilter: selectedCompany,
        })
      );
    }
    navigate(editUserRoute + user.id);
  };

  const handleClickAdd = () => {
    navigate(addUserRoute);
  };

  return (
    <Box margin={1}>
      <Grid container>
        {isSuperAdmin && (
          <>
            <Grid item sm={8} xs={12}>
              <Autocomplete
                fullWidth
                options={companies || []}
                getOptionLabel={getCompanyLabel}
                renderOption={(props, option) => (
                  <li {...props} key={option.id || ''}>
                    {option.name}
                  </li>
                )}
                value={(selectedCompany || null) as any}
                onChange={onCompanyChange}
                renderInput={params => (
                  <TextField
                    {...params}
                    label="Company"
                    required
                    variant="outlined"
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <>
                          {isLoadingCompanies && <CircularProgress color="inherit" size={20} />}
                          {params.InputProps.endAdornment}
                        </>
                      ),
                    }}
                  />
                )}
              />
            </Grid>
            <Grid item sm={4} xs={12} style={{ display: 'flex' }}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={showOnlyAdmins}
                    onChange={onShowAdminsChange}
                    color="primary"
                  />
                }
                label="Show Super/Customer Admins"
              />
            </Grid>
          </>
        )}

        <CrudTable
          title="Users"
          rows={filteredUsers}
          columns={columns}
          isLoading={isLoadingUsers}
          handleRowClickCallback={handleRowClick}
          searchFilter={usersSearchFilter}
          setSearchFilterCallback={setUsersSearchFilter}
          handleClickAddCallback={handleClickAdd}
          handleClickDeleteCallback={handleClickDelete}
          setSelectedRowsIdsCallback={setSelectedUsersIds}
          selectedRowsIds={selectedUsersIds}
        />
      </Grid>
    </Box>
  );
};

export default UsersList;
