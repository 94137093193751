import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { selectAuthUser } from 'modules/auth/storeSliceAuth';
import InputSearchWithButton, {
  InputSearchWithButtonProps,
} from 'sharedComponents/InputSearchWithButton';
import { Button, Box, Grid, Menu, MenuItem } from '@mui/material';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { DateRangePicker } from 'rsuite';
import { ValueType as DateRangeValueType } from 'rsuite/lib/DateRangePicker';
import { FiltersApiLog } from 'lg-helpers/dist/shared/interfaces/IManifestApiLog';

const { afterToday } = DateRangePicker;

export interface ApiLogToolbarProps {
  indicator: number;
  handleNext: () => any;
  recordsCount: number;
  handleBack: () => any;
  searchFilter: string;
  onSearchCallback: InputSearchWithButtonProps['onSearchCallback'];
  isLoading: boolean;
  filters: FiltersApiLog;
  setFilters: (newFilters: Partial<FiltersApiLog>) => any;
}

const ApiLogToolbar = ({
  indicator,
  handleNext,
  recordsCount,
  handleBack,
  searchFilter,
  onSearchCallback,
  isLoading,
  filters,
  setFilters,
}: ApiLogToolbarProps) => {
  const authUser = useSelector(selectAuthUser);
  const { filterDateRange, filterWithTicketID } = filters;
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const onDateRangeChange = (newDateRange: DateRangeValueType) => {
    if (newDateRange.length) {
      setFilters({ filterDateRange: newDateRange as [Date, Date] });
    } else {
      setFilters({ filterDateRange: undefined });
    }
  };

  const onTicketIDFilterChange = (newValue: string) => {
    if (newValue) {
      setFilters({ filterWithTicketID: newValue });
    } else {
      setFilters({ filterWithTicketID: 'all' });
    }
  };

  if (!authUser) {
    return null;
  }

  return (
    <>
      <Grid container alignItems="center" spacing={2}>
        <Grid item md={3} sm={3} xs={12}>
          <InputSearchWithButton
            searchFilter={searchFilter}
            onSearchCallback={onSearchCallback}
            disabled={isLoading}
            placeholder="Search by Ticket ID, Landfill, Truck #, Driver, Transporter, Carrier or Manifest #"
          />
        </Grid>

        <Grid item md={3} sm={12} xs={12}>
          <Box display="flex" alignItems="center" marginBottom={1}>
            <DateRangePicker
              appearance="default"
              placeholder="Show scale in between"
              placement="bottomStart"
              size="lg"
              format="MM/DD/YYYY"
              onChange={onDateRangeChange}
              style={{ width: 260 }}
              defaultValue={[(filterDateRange || [])[0], (filterDateRange || [])[1]]}
              disabled={isLoading || filterWithTicketID !== 'all'}
              disabledDate={afterToday()}
            />
          </Box>
        </Grid>

        <Grid item md={3} sm={4} xs={6}>
          <Box display="flex" justifyContent="flex-start" alignItems="center" marginBottom={1}>
            <Button
              variant="contained"
              disabled={isLoading}
              onClick={e => setAnchorEl(e.currentTarget)}
            >
              Filter By Matched &#9660;
            </Button>
            <Menu
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              keepMounted
              onClose={() => setAnchorEl(null)}
            >
              <MenuItem disabled={isLoading} onClick={() => onTicketIDFilterChange('all')}>
                All
              </MenuItem>

              <MenuItem onClick={() => onTicketIDFilterChange('yes')} disabled={isLoading}>
                Matched
              </MenuItem>

              <MenuItem onClick={() => onTicketIDFilterChange('no')} disabled={isLoading}>
                Not matched
              </MenuItem>
            </Menu>
          </Box>
        </Grid>

        <Grid item md={3} sm={4} xs={12}>
          <Box display="flex" justifyContent="flex-end" alignItems="center" marginBottom={1}>
            <>
              <Box display="flex">
                <Button
                  variant="contained"
                  color="inherit"
                  disabled={indicator === 1}
                  onClick={handleBack}
                >
                  <NavigateBeforeIcon fontSize="small" />
                  Back
                </Button>
                <Box marginRight={1} marginLeft={1}>
                  <Button variant="contained" color="inherit" style={{ minWidth: '40px' }}>
                    {indicator}
                  </Button>
                </Box>
              </Box>
            </>

            <>
              <Button
                variant="contained"
                color="inherit"
                disabled={recordsCount < 100}
                onClick={handleNext}
              >
                Next
                <NavigateNextIcon fontSize="small" />
              </Button>
            </>
          </Box>
        </Grid>
      </Grid>
    </>
  );
};

export default ApiLogToolbar;
