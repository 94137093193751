import { boolean, date, number, object, string } from 'yup';
import { IInvoice } from 'lg-helpers/dist/shared/interfaces/IInvoice';

export const invoiceSchema = object<IInvoice>({
  number: string().required('Required'),
  isTransporter: boolean(),
  projectId: number().required('Required'),
  invoiceDate: date().required('Required'),
  originatingCompanyId: string().required('Required'),
});
