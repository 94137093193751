import React, { useState, useEffect, useCallback } from 'react';
import { Grid, Button, CircularProgress, Box, TextField } from '@mui/material';
import { hasErrorRequired, hasErrorValidEmail } from 'services/logic/formValidation';
import { Link } from 'react-router-dom';
import useShowError from 'modules/errors';
import { auth } from 'services/firebase';
import ErrorMessages from 'services/constants/errorMessages';
import { mixPanel } from 'services/mixpanel';
import { LoginMethod } from './LoginView';

const { isRequired, emailNotValid } = ErrorMessages;

const EmailSignInView = ({
  setPreferredLoginMethod,
  email: injectedEmail,
}: {
  setPreferredLoginMethod: (method: LoginMethod) => any;
  email: string;
}) => {
  const [loginData, setLoginData] = useState<{
    email: string;
    password: string;
    passwordInitial: string;
  }>({
    email: injectedEmail,
    password: '',
    passwordInitial: '',
  });
  const [isLoginInProgress, setIsLoginInProgress] = useState(false);
  const showError = useShowError();
  const [wasSubmitted, setWasSubmitted] = useState(false);

  useEffect(() => {
    let url = new URL(window.location.href);
    const userEmail = url.searchParams.get('userEmail') || '';
    const passwordInitial = url.searchParams.get('passwordInitial') || '';
    setLoginData({
      email: userEmail,
      password: passwordInitial,
      passwordInitial,
    });
  }, []);

  useEffect(() => setLoginData(state => ({ ...state, email: injectedEmail })), [injectedEmail]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setLoginData({
      ...loginData,
      [event.target.name]: event.target.value ? event.target.value.trim() : event.target.value,
    });
    setWasSubmitted(false);
  };

  const handleSubmitLogIn = useCallback(
    async () => {
      const { email, password } = loginData;
      setIsLoginInProgress(true);
      // eslint-disable-next-line
      const authSign = await auth
        .signInWithEmailAndPassword(email, password)
        .then(function (userCredential) {
          // Signed in
          const user = userCredential.user;
          if (user) {
            mixPanel(
              {
                email: user?.email || '',
              },
              'User Login',
              {
                uid: user?.uid || '',
                timestamp: Date(),
              }
            );
          }
        })
        .catch(function (err) {
          console.error('Login error', err);
          showError({
            title: 'Login error',
            text: err.message,
          });

          setIsLoginInProgress(false);
          setWasSubmitted(false);
        });
    },
    // eslint-disable-next-line
    [loginData]
  );

  useEffect(() => {
    if (loginData.passwordInitial) {
      handleSubmitLogIn();
    }
  }, [loginData, handleSubmitLogIn]);

  return (
    <>
      <Grid item xs={12}>
        <TextField
          fullWidth
          label="Email address"
          name="email"
          onChange={handleChange}
          type="text"
          value={loginData.email || ''}
          variant="outlined"
          error={
            hasErrorRequired(loginData.email, wasSubmitted) ||
            hasErrorValidEmail(loginData.email, wasSubmitted)
          }
          helperText={
            hasErrorRequired(loginData.email, wasSubmitted)
              ? isRequired
              : hasErrorValidEmail(loginData.email, wasSubmitted)
              ? emailNotValid
              : ''
          }
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          fullWidth
          label="Password"
          name="password"
          onChange={handleChange}
          type="password"
          value={loginData.password || ''}
          variant="outlined"
          error={hasErrorRequired(loginData.password, wasSubmitted)}
          helperText={hasErrorRequired(loginData.password, wasSubmitted) ? isRequired : ''}
        />
      </Grid>
      <Grid item xs={6}>
        <Button
          fullWidth
          color="primary"
          size="large"
          type="submit"
          variant="contained"
          onClick={() => handleSubmitLogIn()}
        >
          Log in
          {isLoginInProgress && (
            <Box marginLeft={2}>
              <CircularProgress color="inherit" size={25} />
            </Box>
          )}
        </Button>
      </Grid>
      <Grid item xs={6}>
        <Button
          fullWidth
          color="inherit"
          size="large"
          type="submit"
          variant="contained"
          onClick={() => setPreferredLoginMethod('')}
        >
          Back
        </Button>
      </Grid>
      <Grid item xs={12}>
        <Link to="/forgot-password">
          <Button color="inherit" fullWidth size="small" type="button" variant="text">
            Forgot password?
          </Button>
        </Link>
      </Grid>
    </>
  );
};

export default EmailSignInView;
