import React, { useContext, useState } from 'react';
import { Autocomplete, Checkbox, DialogContent, FormControlLabel } from '@mui/material';
import ModalHeader from 'sharedComponents/DialogHeader';
import { useFormik } from 'formik';
import { ModalContext } from 'sharedComponents/ModalContext';
import { apiMappingSchema } from './schemas';
import { TextField, InputLabel, Grid, FormControl } from '@mui/material';
import DialogActionContainer from 'sharedComponents/DialogActionContainer';
import api, {
  CivilApiProvider,
  CivilProjectApiMappings,
  SaveProjectApiMapping,
} from 'services/api/autogenerated';
import { pushError } from 'modules/errors/storeSliceErrors';
import { useDispatch } from 'react-redux';

interface ModalContainerProps {
  selected: Partial<CivilProjectApiMappings>;
  onRefresh?: Function;
}

const ModalContainer = ({ selected, onRefresh }: ModalContainerProps) => {
  const dispatch = useDispatch();
  const { handleModal } = useContext(ModalContext);
  const [isSaving, setIsSaving] = useState(false);

  const handleSubmit = async (values: SaveProjectApiMapping) => {
    setIsSaving(true);
    try {
      await api.civilProjects.saveApiMappingByProject(values.project_id, {
        ...values,
        default_source_supplier_as_transporter:
          values.default_source_supplier_as_transporter || false,
      });
      handleModal();
    } catch (err) {
      dispatch(pushError({ title: 'Error: Failed to save API Mapping' }));
    } finally {
      setIsSaving(false);
      if (onRefresh) onRefresh();
    }
  };

  const handleDelete = async () => {
    if (!selected || !selected.id) return;
    setIsSaving(true);
    try {
      await api.civilProjects.deleteApiMapping(selected.project_id!, selected.id);
      handleModal();
    } catch (err) {
      dispatch(pushError({ title: 'Error: Failed to save API Mapping' }));
    } finally {
      setIsSaving(false);
      if (onRefresh) onRefresh();
    }
  };

  const formik = useFormik({
    validationSchema: apiMappingSchema,
    initialValues: selected
      ? (selected as CivilProjectApiMappings)
      : ({} as CivilProjectApiMappings),
    onSubmit: handleSubmit,
  });

  return (
    <>
      <ModalHeader title={'Api Mapping'} />
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <FormControl fullWidth>
              <InputLabel htmlFor="name" shrink>
                Name
              </InputLabel>
              <TextField
                name="name"
                value={formik.values.name || ''}
                error={formik.touched.name && !!formik.errors.name}
                helperText={formik.touched.name && formik.errors.name}
                onChange={formik.handleChange}
                variant="outlined"
                autoFocus
              />
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <FormControl fullWidth>
              <InputLabel htmlFor="api" shrink>
                API
              </InputLabel>
              <Autocomplete
                options={Object.values(CivilApiProvider)}
                value={formik.values.api || ''}
                onChange={(e, value) => formik.setFieldValue('api', value as string)}
                isOptionEqualToValue={(option, value) => option === value}
                renderInput={p => (
                  <TextField
                    {...p}
                    name="api"
                    variant="outlined"
                    value={formik.values.api || ''}
                    error={!!formik.errors.api}
                    helperText={formik.errors.api}
                  />
                )}
              />
            </FormControl>
          </Grid>
          {formik.values.api === 'Hercules' && (
            <Grid item xs={12}>
              <FormControlLabel
                label="Default Source Supplier as Transporter?"
                name="default_source_supplier_as_transporter"
                control={
                  <Checkbox
                    color="success"
                    checked={formik.values.default_source_supplier_as_transporter || false}
                    onChange={() =>
                      formik.setFieldValue(
                        'default_source_supplier_as_transporter',
                        !formik.values.default_source_supplier_as_transporter
                      )
                    }
                  />
                }
              />
            </Grid>
          )}
          <Grid item xs={12}>
            <FormControl fullWidth>
              <InputLabel htmlFor="api_id" shrink>
                Id from API
              </InputLabel>
              <TextField
                name="api_id"
                value={formik.values.api_id || ''}
                error={formik.touched.api_id && !!formik.errors.api_id}
                helperText={formik.touched.api_id && formik.errors.api_id}
                onChange={formik.handleChange}
                variant="outlined"
              />
            </FormControl>
          </Grid>
        </Grid>
        <DialogActionContainer
          isSaving={isSaving}
          saveHandler={formik.handleSubmit}
          deleteHandler={handleDelete}
          editing={!!formik.values.id}
        />
      </DialogContent>
    </>
  );
};

export default ModalContainer;
