import React from 'react';
import './AppDownloadView.scss';
import { Typography, Box, Container, Link } from '@mui/material';

const AppDownloadView = () => {
  return (
    <Box margin={1} paddingTop={8} paddingBottom={8}>
      <Container>
        <Typography variant="h3" align="center" className="AppDownloadView__header--text">
          The day has arrived!
        </Typography>
        <Typography variant="h4" align="center" className="AppDownloadView__header--text">
          November 7th, 2022
        </Typography>
        <Typography className="AppDownloadView__text">
          We&apos;re no longer supporting the web version of the &quot;Drivers&quot; experience,
          have no fear though you can now download the native app which will provide a much
          intuitive and seamless experience than the web offering great new features with the added
          support of native functionality. Below are some links to download the native app, simply
          download the app and use the same credentials you use here to login! It&apos;s that easy!
        </Typography>
        <Box display="flex" justifyContent="center" className="AppDownloadView__wrapper">
          <Link
            href="https://apps.apple.com/us/app/lts-digital/id1563342241"
            target="_blank"
            rel="noopener"
            className="AppDownloadView__link"
          >
            <img
              src="static/images/apple-app-badge.jpg"
              alt="App Store Link"
              className="AppDownloadView__img"
            />
          </Link>
          <Link
            href="https://play.google.com/store/apps/details?id=com.ltstracker"
            target="_blank"
            rel="noopener"
            className="AppDownloadView__link"
          >
            <img
              src="static/images/google-play-badge.jpg"
              alt="Google Play Link"
              className="AppDownloadView__img"
            />
          </Link>
        </Box>
      </Container>
    </Box>
  );
};

export default AppDownloadView;
