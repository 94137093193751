import React from 'react';
import { DialogContent } from '@mui/material';
import ModalHeader from 'sharedComponents/DialogHeader';
import CreateReceiptForm, { CreateReceiptFormProps } from './CreateReceiptForm';

const ModalContainer = ({ receipt, callbackReceipt }: CreateReceiptFormProps) => {
  return (
    <>
      <ModalHeader title="Enter Receipt Information" />
      <DialogContent>
        <CreateReceiptForm receipt={receipt} callbackReceipt={callbackReceipt} />
      </DialogContent>
    </>
  );
};

export default ModalContainer;
