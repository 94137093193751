import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';
import { drawCanvasImageFromBase64 } from 'services/logic/base64';
import textToImage from 'text-to-image';
import moment from 'moment';

const previewImageDefaultBckgroundColor = '#0000cc';
const displayOptionsDefaultPropertyPath = '__displayOptionsDefault';

const ManifestDisplay = ({
  onReady,
  manifest,
  templateHeavy,
  isPreviewTemplate,
  setCanvasRefCallback,
}) => {
  const canvasRef = useRef(null);
  const [context, setContext] = useState(null);

  useEffect(() => {
    if (!canvasRef) {
      return;
    }
    if (setCanvasRefCallback) setCanvasRefCallback(canvasRef);

    canvasRef.current.width = templateHeavy.dimensions.width;
    canvasRef.current.height = templateHeavy.dimensions.height;

    const c = canvasRef.current.getContext('2d');
    setContext(c);
  }, [canvasRef, setCanvasRefCallback, templateHeavy]);

  useEffect(() => {
    if (!context) {
      return;
    }

    const redraw = async () => {
      context.clearRect(0, 0, canvasRef.current.width, canvasRef.current.height);
      context.fillStyle = 'white';
      context.fillRect(0, 0, canvasRef.current.width, canvasRef.current.height);

      try {
        if (templateHeavy.base64)
          await drawCanvasImageFromBase64(
            context,
            templateHeavy.base64,
            canvasRef.current.width,
            canvasRef.current.height,
            0,
            0
          );
      } catch (err) {
        console.error('background base64', err);
      }

      const propertiesDisplayOptions = templateHeavy.propertiesDisplayOptions || {};
      for (const propertyPath in propertiesDisplayOptions) {
        if (propertyPath !== displayOptionsDefaultPropertyPath) {
          await drawManifestProperty(propertyPath, propertiesDisplayOptions);
        }
      }
    };

    const drawAtPosition = async ({
      x,
      y,
      manifestPropertyValue,
      displayOptions,
      displayOptionsDefault,
    }) => {
      let { color, font, fontSize, isImage, isDate, timeZone } = displayOptions;

      if (!font) {
        if (displayOptionsDefault && displayOptionsDefault.font) {
          font = displayOptionsDefault.font;
        } else {
          font = 'Arial';
        }
      }

      if (!fontSize) {
        if (displayOptionsDefault && displayOptionsDefault.fontSize) {
          fontSize = displayOptionsDefault.fontSize;
        } else {
          fontSize = 40;
        }
      }

      if (isImage) {
        let { width, height, isStatic } = displayOptions;
        if (isPreviewTemplate && !isStatic) {
          manifestPropertyValue = await textToImage.generate(manifestPropertyValue || '-', {
            bgColor: previewImageDefaultBckgroundColor,
            textColor: 'white',
            maxWidth: width || 1,
            customHeight: height || 1,
            fontSize,
          });
        }

        if (manifestPropertyValue === 'logo') {
          manifestPropertyValue = displayOptions.url;
        }

        try {
          if (manifestPropertyValue)
            await drawCanvasImageFromBase64(
              context,
              manifestPropertyValue,
              width || 1,
              height || 1,
              x,
              y
            );
        } catch (err) {
          console.error(err);
        }
      } else {
        if (color) {
          context.fillStyle = color;
        } else {
          if (displayOptionsDefault && displayOptionsDefault.color) {
            context.fillStyle = displayOptionsDefault.color;
          } else {
            context.fillStyle = 'black';
          }
        }

        context.font = `bold ${fontSize}px ${font}`;

        if (isDate && manifestPropertyValue) {
          const dateFormat = 'MM/DD/YYYY hh:mm:ssA';
          const momentDate = moment.tz(
            new Date(manifestPropertyValue),
            timeZone || displayOptionsDefault?.timeZone || 'EST'
          );
          manifestPropertyValue = `${momentDate.format(dateFormat)} ${momentDate.zoneAbbr()}`;
        }

        if (!manifestPropertyValue) {
          manifestPropertyValue = '-';
        }

        const lines = `${manifestPropertyValue}`.split('<br/>');
        for (let i = 0; i < lines.length; i++) {
          context.fillText(lines[i], x, y + i * fontSize);
        }
      }
    };

    const drawManifestProperty = async (propertyPath, propertiesDisplayOptions) => {
      const displayOptions = propertiesDisplayOptions[propertyPath];
      const displayOptionsDefault = {
        ...propertiesDisplayOptions[displayOptionsDefaultPropertyPath],
        ...(displayOptions?.isDate && {
          timeZone: manifest?.project?.timeZone,
        }),
      };

      let manifestPropertyValue = `#.${propertyPath}`;
      let { isStatic } = displayOptions;
      if (isStatic) {
        manifestPropertyValue = propertyPath;
      } else if (!isPreviewTemplate) {
        const propertyPathParts = propertyPath.split('.');
        manifestPropertyValue = manifest[propertyPathParts[0]];
        for (let i = 1; i < propertyPathParts.length; i++) {
          if (manifestPropertyValue) {
            if (
              propertyPathParts[i] === 'truckingCompany' &&
              manifestPropertyValue['secondaryTruckingCompany']
            ) {
              manifestPropertyValue = manifestPropertyValue['secondaryTruckingCompany'];
            } else {
              manifestPropertyValue = manifestPropertyValue[propertyPathParts[i]];
            }
          } else {
            break;
          }
        }
      }

      let { x, y, positions } = displayOptions;
      if ((x === 0 || x) && (y === 0 || y)) {
        drawAtPosition({
          x,
          y,
          manifestPropertyValue,
          displayOptions,
          displayOptionsDefault,
        });
      }

      if (positions && positions.length) {
        positions.forEach(({ v1, v2 }) =>
          drawAtPosition({
            v1,
            v2,
            manifestPropertyValue,
            displayOptions,
            displayOptionsDefault,
          })
        );
      }
    };

    redraw();
  }, [context, manifest, templateHeavy, isPreviewTemplate]);

  // todo: remove this once we figure out what is going on
  // eslint-disable-next-line
  useEffect(() => onReady && onReady(), []);

  return (
    <Box display="flex" justifyContent="center">
      <canvas
        ref={canvasRef}
        style={{
          width: '100%',
          height: '100%',
          border: '1px solid black',
        }}
      >
        {' '}
        Your browser does not support the HTML5 canvas tag.{' '}
      </canvas>
    </Box>
  );
};

ManifestDisplay.propTypes = {
  onReady: PropTypes.func,
  templateHeavy: PropTypes.object,
  manifest: PropTypes.object,
  isPreviewTemplate: PropTypes.bool,
  setCanvasRefCallback: PropTypes.func,
};

export default ManifestDisplay;
