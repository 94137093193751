import mixpanel from 'mixpanel-browser';
import { IUser } from 'lg-helpers/dist/shared/interfaces/IUser';

// Blocking Companies using Civil v2 in Production
const blockedCompanyIds = ['y2YTtrKUK9llcZ21GjmC'];

export interface MixPanelOptions {
  [any: string]: string;
}

export const mixPanel = (user: Partial<IUser>, message: string, options = {}) => {
  if (blockedCompanyIds.every(id => !(user?.companiesIds || []).includes(id))) {
    const mixPanelOptions: MixPanelOptions = {
      'User Id': user?.id || '',
      'User Email': user?.email || '',
      'User Type': user?.type || '',
      'User Acting As Type': user?.actingAsType || '',
    };

    if (user?.companiesIds && user?.companiesIds[0]) {
      mixPanelOptions['Company ID'] = user?.companiesIds[0] || '';
    }

    if (process.env.REACT_APP_BUILD_ENV === 'local') {
      console.log('MIXPANEL EVENT: ', message, {
        ...mixPanelOptions,
        ...options,
      });
    }

    if (process.env.REACT_APP_BUILD_ENV !== 'production') return null;

    try {
      mixpanel.track(message, {
        ...mixPanelOptions,
        ...options,
      });
    } catch (err) {
      // TODO: hotfix, should be handled
      console.error('Mixpanel error', err);
    }
  }

  return null;
};
