import { ITruckingCompanyMaterial } from 'lg-helpers/dist/trucking/interfaces/ITruckingCompanyMaterial';
import { createAsyncThunk } from '@reduxjs/toolkit';
import Tables from 'services/postgres/Tables';
import ApiBase from 'services/postgres/ApiBase';
import { INotification } from 'modules/civilAndTrucking/shared/NotificationSnackbar/INotification';
import { pushNotification } from 'modules/civilAndTrucking/shared/NotificationSnackbar/redux/actions';
import { RootState } from 'store/store';
import { pushError } from 'modules/errors/storeSliceErrors';

const apiBase = new ApiBase();
const COMPANY_MATERIALS_TABLE = Tables.TruckingCompanyMaterial;

export const getCompanyMaterialsByCompanyId = createAsyncThunk(
  'company-materials/get-company-materials',
  async (payload: undefined, thunkApi) => {
    try {
      const data = await apiBase.getList(`${COMPANY_MATERIALS_TABLE}`);
      return data as ITruckingCompanyMaterial[];
    } catch (err) {
      thunkApi.dispatch(pushError({ title: 'Error: Unable to get company materials' }));
      throw err;
    }
  }
);

export const upsertCompanyMaterial = createAsyncThunk(
  'company-materials/add-company-materials',
  async (payload: Partial<ITruckingCompanyMaterial>, thunkApi) => {
    const state = thunkApi.getState() as RootState;
    const companyId = state.civil.companies.activeCompany?.id;

    const companyMaterialReq = {
      ...payload,
      companyIdTruckingCompanyMaterial: companyId,
    };
    try {
      const material = await apiBase.insertOrUpdate(companyMaterialReq, COMPANY_MATERIALS_TABLE);
      thunkApi.dispatch(getCompanyMaterialsByCompanyId());
      return material;
    } catch (err) {
      thunkApi.dispatch(pushError({ title: 'Error: Unable to create new company material' }));
    }
  }
);

export const deleteCompanyMaterial = createAsyncThunk(
  'company-materials/delete-company-materials',
  async (materialId: number, thunkApi) => {
    try {
      await apiBase.deleteById(materialId, COMPANY_MATERIALS_TABLE);
      const successNotification: INotification = {
        status: 'success',
        message: 'Success: Material has been deleted',
      };
      thunkApi.dispatch(pushNotification(successNotification));
      thunkApi.dispatch(getCompanyMaterialsByCompanyId());
    } catch (err) {
      thunkApi.dispatch(pushError({ title: 'Error: Unable to delete company material' }));
    }
  }
);
