import React, { useMemo } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { useSelector } from 'react-redux';
import { selectJoinedDispatches } from '../../../redux/dispatchStoreSlice';
import { LoadingOverlay as Loading } from 'sharedComponents/LoadingOverlay';
import { generateDispatchColumns } from './dispatchesColumns';
import { selectCompleted } from '../../../redux/dispatchesToolbarStoreSlice';
import { IJoinTruckingDispatch } from 'lg-helpers/dist/trucking/interfaces/IJoinTruckingDispatch';

export type DispatchListProps = {
  showModal: (joinedDispatch: IJoinTruckingDispatch) => void;
  doneDispach: (joinedDispatch: IJoinTruckingDispatch, isDone: boolean) => void;
  handleDeleteDispatch: (dispatchId: number) => void;
  showCopyModal: (joinedDispatch: IJoinTruckingDispatch) => void;
};

export const DispatchesList = ({
  showModal,
  doneDispach,
  handleDeleteDispatch,
  showCopyModal,
}: DispatchListProps) => {
  const { joinedDispatches, joinedDispatchesStatus } = useSelector(selectJoinedDispatches);
  const isShowCompletedChecked = useSelector(selectCompleted);

  const columns = useMemo(
    () => generateDispatchColumns(handleDeleteDispatch, showModal, doneDispach, showCopyModal),
    [showModal, doneDispach, handleDeleteDispatch, showCopyModal]
  );

  const filteredDispatches = useMemo(
    () =>
      joinedDispatches.filter(
        dispatch => !!dispatch.isDoneTruckingDispatch === isShowCompletedChecked
      ),
    [joinedDispatches, isShowCompletedChecked]
  );

  if (joinedDispatchesStatus === 'pending') {
    return <Loading />;
  }

  return (
    <DataGrid
      columns={columns}
      rows={filteredDispatches || []}
      getRowId={row => row.idTruckingDispatch}
      showColumnRightBorder={false}
      disableColumnMenu
      autoHeight
      disableSelectionOnClick
      components={{
        ColumnResizeIcon: () => <div></div>,
      }}
    />
  );
};
