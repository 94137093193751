import React from 'react';
import { Typography, Grid, Button, Box } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { manageButton } from './Manage';
import { usePermissionsContext } from 'services/permissions';

interface ManageCardProps {
  button: manageButton;
}

function ManageCard({ button }: ManageCardProps) {
  const { user } = usePermissionsContext();

  const disable = button.dispatcherOnly && user?.type !== 'Trucking Dispatcher'; // eslint-disable-line

  const navigate = useNavigate();
  return (
    <Grid item xs={6}>
      <Button
        onClick={() => navigate(button.href)}
        variant="contained"
        color="secondary"
        sx={{ width: '100%', height: '100px' }}
      >
        <Box display="flex" flexDirection="column" alignItems="center">
          {button.pictogram}
          <Typography variant="caption" sx={{ textTransform: 'none' }}>
            {button.label}
          </Typography>
        </Box>
      </Button>
    </Grid>
  );
}

export default ManageCard;
