import React, { useCallback, useEffect, useState } from 'react';
import { Grid, Card, CardHeader, CardContent, Divider, TextField, Button } from '@mui/material';
import api from 'services/api/autogenerated';
import { useConfirm } from 'material-ui-confirm';

const UserAppTokenReveal = () => {
  const [appToken, setAppToken] = useState('');
  const confirm = useConfirm();

  const getAppToken = useCallback(async () => {
    try {
      const data = await api.applicationTokens.getApplicationToken();
      if (data?.data?.token) {
        const { token } = data?.data;
        setAppToken(token);
      }
    } catch (error) {
      console.log('user APP Token has not been created');
    }
  }, []);

  const generateNewAppToken = useCallback(async () => {
    try {
      if (appToken) {
        await confirm({
          title: '',
          confirmationText: 'Yes',
          cancellationText: 'No',
          content: `Generating new APP Token will invalidate old token. Are you sure you would like to generate new token?`,
        });
      }
      const { data } = await api.applicationTokens.generateApplicationToken();
      const { token } = data;
      setAppToken(token);
    } catch {
      console.log('APP Token generation cancelled');
    }
  }, [appToken, confirm]);

  useEffect(() => {
    getAppToken();
  }, [getAppToken]);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Card>
          <CardHeader title="User APP Token" />
          <Divider />
          <CardContent>
            <Grid container alignItems={'center'} alignContent={'center'} spacing={2}>
              <Grid item sm={9} xs={12}>
                <TextField
                  type={'text'}
                  fullWidth
                  id="AppToken"
                  disabled={true}
                  label="App Token"
                  value={appToken}
                />
              </Grid>
              <Grid item sm={3} xs={12}>
                <Button
                  fullWidth
                  color="inherit"
                  size="large"
                  variant="contained"
                  disabled={!appToken}
                  onClick={() => {
                    navigator.clipboard.writeText(appToken);
                  }}
                >
                  Copy
                </Button>
              </Grid>
              <Grid item xs={12}>
                <Button
                  fullWidth
                  color="inherit"
                  size="large"
                  variant="contained"
                  onClick={generateNewAppToken}
                >
                  Generate New Token
                </Button>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
};

export default UserAppTokenReveal;
