import React, { HTMLAttributes, useContext, useEffect, useState } from 'react';
import {
  Grid,
  Box,
  Typography,
  TextField,
  SvgIcon,
  FormControl,
  Autocomplete,
  useTheme,
  useMediaQuery,
  Button,
} from '@mui/material';
import { EditIcon } from 'modules/civilAndTrucking/shared/CustomIcons/Edit';
import { PrintOutlined } from '@mui/icons-material';
import { ReconciliationTable } from 'modules/civilAndTrucking/civil/views/Reconciliation/ReconciliationTable';
import { useSelector } from 'react-redux';
import { RootState, thunkDispatch } from 'store/store';
import AddInvoiceForm from 'modules/civilAndTrucking/civil/Invoices/AddInvoiceForm';
import { getInvoicesForProject, printReconciliationReport } from '../../Invoices/redux/actions';
import { ModalContext } from 'sharedComponents/ModalContext';
import moment from 'moment';
import { LoadingComponent } from 'sharedComponents/LoadingComponent';
import { selectAuthUser } from 'modules/auth/storeSliceAuth';
import { useLocation } from 'react-router-dom';
import TicketSearch from 'sharedComponents/TicketSearch';
import { debounce } from 'lodash';
import { IInvoice } from 'lg-helpers/dist/shared/interfaces/IInvoice';

interface IState {
  payload: IInvoice;
}

interface IInvoiceInput extends IInvoice {
  company?: string;
}

const AccountingReconciliation = () => {
  const location = useLocation();
  const state = location.state as IState;
  const [projectId, setProjectId] = useState<number>();
  const [inputValue, setInputValue] = useState<string>('');
  const [value, setValue] = useState<IInvoiceInput>();
  const [invoice, setInvoice] = useState<IInvoiceInput>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { handleModal } = useContext(ModalContext);
  const [optionsLoading, setOptionsLoading] = useState(false);
  const [isPrinting, setIsPrinting] = useState(false);
  const { innerWidth } = window;
  const authUser = useSelector(selectAuthUser);
  const theme = useTheme();
  const isWidthXSorSM = useMediaQuery(theme.breakpoints.down('md'));

  const tickets = useSelector((s: RootState) => s.civil.tickets.data);

  const invoiceState = useSelector((s: RootState) => s.civil.invoices.data);

  const activeProject = useSelector((s: RootState) => s.civil.projects.activeProject);

  const fetch = React.useMemo(
    () =>
      debounce(async (request: { input: string; projectId: number }) => {
        if (!request.projectId) {
          return;
        }
        setOptionsLoading(true);

        await thunkDispatch(
          getInvoicesForProject({
            projectId: request.projectId,
            search: request.input,
          })
        );
        setOptionsLoading(false);
      }, 1000),
    // eslint-disable-next-line
    []
  );

  useEffect(() => {
    if (activeProject) {
      setProjectId(activeProject.id as number);
    }
  }, [activeProject]);

  useEffect(() => {
    let active1 = true;
    if (active1) {
      if ((0 < inputValue.length && inputValue.length < 3) || !projectId) {
        return;
      } else if (inputValue.length >= 3 || inputValue.length === 0) {
        fetch({ input: inputValue, projectId });
      }
    }

    return () => {
      active1 = false;
    };
    // eslint-disable-next-line
  }, [projectId, inputValue]);

  useEffect(() => {
    if (state && state.payload) {
      setValue(state.payload);
    }
    // eslint-disable-next-line
  }, []);

  const filterTickets = () => {
    return invoice && invoice.isTransporter
      ? tickets?.filter(
          // only show tickets that use the transporter who sent the invoice
          ticket => ticket.transporterId === invoice?.originatingCompanyId
        )
      : tickets?.filter(ticket => ticket.sourceSupplierId === invoice?.originatingCompanyId);
  };

  const handlePrint = async () => {
    if (invoice && invoice.id) {
      try {
        setIsPrinting(true);
        const data = await thunkDispatch(
          printReconciliationReport({
            invoiceId: invoice.id,
            userId: authUser.id,
          })
        );
        const urlObject = data.payload as { url: URL };
        const newTab = window.open(urlObject.url as URL, '_blank');
        newTab?.focus();
        setIsPrinting(false);
      } catch (err) {
        console.error('Error getting reconciliation report: ', err);
        setIsPrinting(false);
      }
    }
  };

  useEffect(() => {
    if (value) {
      const { ...newInvoiceData } = value;
      if (newInvoiceData?.id === 0) {
        handleModal(
          <AddInvoiceForm
            setInvoice={setValue}
            selected={{
              number: newInvoiceData.number?.slice(17, newInvoiceData.number.length),
              projectId: projectId,
              isTransporter: true,
            }}
          />
        );
        setInputValue('');
      } else {
        setInvoice(value);
        return;
      }
    } else {
      setInvoice(undefined);
    }
    // eslint-disable-next-line
  }, [value]);

  const handleEditInvoice = () => {
    handleModal(<AddInvoiceForm selected={invoice} setInvoice={setValue} />);
  };

  return (
    <>
      <Grid container alignItems="center" width="100%" maxWidth={innerWidth * 0.85}>
        <Grid
          item
          xs={12}
          md={8}
          display="flex"
          alignItems="center"
          justifyContent="flex-end"
          sx={{ backgroundColor: 'neutral.light' }}
          borderRadius={1}
          mb={isWidthXSorSM ? 2 : 0}
        >
          <Typography
            align="center"
            variant="h6"
            sx={{
              color: 'black',
              marginRight: 2,
              fontSize: 18,
              width: '150px',
            }}
          >
            Invoice #
          </Typography>
          <FormControl fullWidth sx={{ marginRight: 3 }}>
            <Autocomplete
              freeSolo
              sx={{ padding: 0 }}
              disabled={!projectId}
              loading={optionsLoading}
              value={invoice || value || ''}
              inputValue={inputValue || ''}
              options={invoiceState}
              filterOptions={(options, params) => {
                const filtered = options;

                // Suggest the creation of a new value
                if (params.inputValue !== '') {
                  filtered.unshift({
                    id: 0,
                    number: `>>> Add Invoice: ${params.inputValue.slice(
                      0,
                      params.inputValue.length
                    )}`,
                    projectId: projectId,
                  });
                }

                return filtered;
              }}
              getOptionLabel={(option: string | IInvoiceInput) =>
                typeof option === 'string'
                  ? value
                    ? option
                    : ''
                  : `${option.number} - ${
                      option.company ? option.company : option.transporter
                    } - ${moment(option.invoiceDate).format('MM-DD-YYYY')}`
              }
              onInputChange={(event, newValue) => setInputValue(newValue)}
              onChange={(event, val) => {
                event.persist();
                const newValue = val as IInvoice;
                setValue(newValue);
              }}
              renderInput={params => (
                <TextField
                  {...params}
                  placeholder="Search Invoices"
                  variant="outlined"
                  name="invoice"
                  sx={{ padding: 0 }}
                  InputProps={{
                    ...params.InputProps,
                  }}
                />
              )}
              renderOption={(props: HTMLAttributes<HTMLLIElement>, option: IInvoice) => (
                <li {...props}>
                  <Grid container alignItems="center" justifyContent="flex-start" wrap="nowrap">
                    {option.id !== 0 && (
                      <>
                        <Grid item xs={5}>
                          <Typography noWrap>{option.number}</Typography>
                        </Grid>
                        <Grid item xs={5}>
                          <Typography noWrap>{option.transporter}</Typography>
                        </Grid>
                        <Grid item xs={2}>
                          <Typography noWrap>
                            {moment(option.invoiceDate).format('MM-DD-YYYY')}
                          </Typography>
                        </Grid>
                      </>
                    )}
                    {option.id === 0 && (
                      <Grid item xs={12}>
                        <Typography noWrap>{option.number}</Typography>
                      </Grid>
                    )}
                  </Grid>
                </li>
              )}
            />
          </FormControl>
        </Grid>
        <Grid
          item
          xs={12}
          md={2}
          display="flex"
          alignItems="center"
          justifyContent="flex-start"
          sx={{ backgroundColor: 'neutral.light' }}
          borderRadius={1}
          mb={isWidthXSorSM ? 2 : 0}
        >
          <Button
            color="primary"
            variant="contained"
            onClick={() => {
              setValue({
                id: 0,
                number: `>>> Add Invoice: ${invoice ? '' : inputValue}`,
                projectId,
              });
            }}
          >
            New Invoice
          </Button>
        </Grid>
        {invoice && (
          <>
            <Grid item xs={6} md={1}>
              <Box
                height={'100%'}
                display="flex"
                flexDirection="column"
                justifyContent="center"
                alignItems="center"
                onClick={handleEditInvoice}
                sx={{
                  '&:hover': {
                    cursor: 'pointer',
                  },
                }}
              >
                <EditIcon color="secondary" />
                <Typography>Edit</Typography>
              </Box>
            </Grid>
            {isPrinting ? (
              <Grid xs={6} md={1}>
                <Box
                  height={'100%'}
                  display="flex"
                  flexDirection="column"
                  justifyContent="center"
                  alignItems="center"
                >
                  <LoadingComponent isLoading={isPrinting} />
                </Box>
              </Grid>
            ) : (
              <Grid item xs={6} md={1}>
                <Box
                  height={'100%'}
                  display="flex"
                  flexDirection="column"
                  justifyContent="center"
                  alignItems="center"
                  onClick={handlePrint}
                  sx={{
                    '&:hover': {
                      cursor: 'pointer',
                    },
                  }}
                >
                  <SvgIcon color="secondary">
                    <PrintOutlined />
                  </SvgIcon>
                  <Typography>Print</Typography>
                </Box>
              </Grid>
            )}
          </>
        )}
        {!invoice && (
          <Grid>
            <Box flexGrow={2} />
          </Grid>
        )}

        {/* <Grid item xs={2} className={clsx(classes.inputContainer)}>
          <Box width={270} paddingRight={1}>
            <Typography align="right" variant="body1">
              Import
            </Typography>
          </Box>
          <FormControl fullWidth>
            <TextField
              select
              fullWidth
              className={clsx(classes.input)}
              name="ticketNumber"
              variant="outlined"
              value={''}
            >
              <MenuItem value={1} key={1}>
                TEST
              </MenuItem>
            </TextField>
            <FormHelperText />
          </FormControl>
          <Box
            bgcolor={civilTheme.palette.secondary.main}
            display="flex"
            justifyContent="center"
            alignItems="center"
            padding={1}
            marginLeft={1}
          >
            <SvgIcon htmlColor="white">
              <ImportExportOutlined />
            </SvgIcon>
          </Box>
        </Grid> */}
        <Grid item xs={12}>
          <TicketSearch
            setIsLoading={setIsLoading}
            setInvoice={setValue}
            isAccepted={true}
            invoiceId={invoice?.id}
            isTransporter={invoice?.isTransporter}
            isFromTrucking={invoice?.isFromTrucking}
          />
        </Grid>
      </Grid>

      <ReconciliationTable
        tickets={projectId && invoice ? filterTickets() : projectId ? tickets : []}
        invoice={invoice}
        key={invoice?.id}
        isTransporter={invoice?.isTransporter}
        isLoading={isLoading}
        isPrinting={isPrinting}
        setIsPrinting={setIsPrinting}
        projectId={projectId}
      />
      <LoadingComponent isLoading={isLoading} />
    </>
  );
};

export default AccountingReconciliation;
