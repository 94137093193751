import React from 'react';
import { env } from 'process';
import { useEffect, useState } from 'react';
import api from 'services/api/autogenerated';

// import { componentAllowedPerms } from 'services/permissions';

// TODO: Implement reports entity
// export const EmbeddedSigma = componentAllowedPerms(['reports:read'])(() => {
const SigmaEmbedded = () => {
  interface WorkbookVariables {
    workbookId?: string;
  }

  const [embedUrl, setEmbedUrl] = useState<string>('');
  const [workbookVariables, setWorkbookVariables] = useState<WorkbookVariables>({});
  const [isDataLoaded, setIsDataLoaded] = useState<boolean>(false);
  const [error, setIsError] = useState<
    | {
        type: string;
        code: string;
        message: string;
      }
    | undefined
  >(undefined);

  useEffect(() => {
    let active = true;
    initSigmaMember();
    getSigmaEmbed();
    return () => {
      active = false;
    };

    async function initSigmaMember() {
      try {
        if (!active) return;
        await api.reports.createMember();
      } catch (initSigmaMemberError) {
        console.error('Error creating Sigma member:', initSigmaMemberError);
      }
    }

    async function getSigmaEmbed() {
      try {
        if (!active) return;
        const {
          data: { url },
        } = await api.reports.getSigmaReportURLFromUser();
        setEmbedUrl(url);
      } catch (getSigmaEmbedError) {
        console.error('Error getting Sigma embed:', getSigmaEmbedError);
      }
    }
  }, []);

  useEffect(() => {
    const handleMessage = (event: MessageEvent) => {
      const sigmaWorkbook = document.getElementById('sigmaWorkbook') as HTMLIFrameElement;
      if (
        event.origin === 'https://app.sigmacomputing.com' &&
        event.source === sigmaWorkbook.contentWindow
      ) {
        const sigmaMessage = event.data;
        if (sigmaMessage.type === 'workbook:variables:onchange') {
          setWorkbookVariables({ ...workbookVariables, ...sigmaMessage.workbook.variables });
        }
        if (sigmaMessage.type === 'workbook:error') {
          setIsError(sigmaMessage);
        }

        if (sigmaMessage.type === 'workbook:dataloaded') {
          setIsDataLoaded(true);
          setIsError(undefined);
        }

        // If loading the embed for the first time, changing workbooks, or saving a new one,
        // a new workbook:id:onchange event will be triggered.
        if (sigmaMessage.type === 'workbook:id:onchange') {
          setWorkbookVariables({ ...workbookVariables, ...{ workbookId: sigmaMessage.id } });

          // We only want to ping the Sigma API to create/find an embed if the workbookId changes,
          // not on every new page load.
          if (
            sigmaMessage.id != env.NEXT_PUBLIC_SIGMA_DEFAULT_WORKBOOK_ID &&
            workbookVariables.workbookId &&
            workbookVariables.workbookId != sigmaMessage.id
          ) {
            try {
              api.reports.onboardWorkbook(sigmaMessage.id);
            } catch (onboardWorkbookError) {
              console.error('Error onboarding workbook:', onboardWorkbookError);
            }
          }
        }
      }
    };

    window.addEventListener('message', handleMessage);
    return () => {
      window.removeEventListener('message', handleMessage);
    };
  }, [error, workbookVariables]);

  return (
    <div style={{ height: '98%', width: '98%' }}>
      <iframe id="sigmaWorkbook" src={embedUrl} style={{ height: '100%', width: '100%' }} />
    </div>
  );
};

export default SigmaEmbedded;
