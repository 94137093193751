import React, { useEffect, useState } from 'react';
import { Box, Typography, Switch } from '@mui/material';
import { DataGrid, GridEventListener, GridEvents, GridRowModel } from '@mui/x-data-grid';
import { ArrowDropDown, ArrowDropUp } from '@mui/icons-material';
import { RootState, thunkDispatch } from 'store/store';
import { getAccountingSummary } from '../../Tickets/redux/actions';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

const companyColumns = [
  {
    field: 'type',
    headerName: 'Company Type',
    renderHeader: () => <></>,
    renderCell: (data: any) => {
      if (data.row.type === 'transporter') {
        return (
          <Box
            height={20}
            width={20}
            borderRadius={20}
            color="white"
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            T
          </Box>
        );
      } else {
        return (
          <Box
            height={20}
            width={20}
            borderRadius={20}
            bgcolor={'#48ABB1'}
            color="white"
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            S
          </Box>
        );
      }
    },
    width: 50,
  },
  {
    field: 'company',
    headerName: 'Company',
    width: 150,
  },
  {
    field: 'openTotal',
    headerName: 'Open',
    flex: 1,
    minWidth: 50,
  },
  {
    field: 'issueTotal',
    headerName: 'Issue',
    flex: 1,
    minWidth: 50,
  },
  {
    field: 'ticketTotal',
    headerName: 'Total',
    flex: 1,
    minWidth: 50,
  },
];

const invoiceColumns = [
  {
    field: 'number',
    headerName: '#',
    width: 100,
  },
  {
    field: 'company',
    headerName: 'Company',
    width: 120,
  },
  {
    field: 'pendingTotal',
    headerName: 'Pending',
    flex: 1,
    renderCell: (data: any) => {
      if (data.row.isTransporter) {
        return <Typography>{data.row.transporterPendingTotal}</Typography>;
      } else {
        return <Typography>{data.row.supplierPendingTotal}</Typography>;
      }
    },
    minWidth: 75,
  },
  {
    field: 'reconciledTotal',
    headerName: 'Done',
    flex: 1,
    renderCell: (data: any) => {
      if (data.row.isTransporter) {
        return <Typography>{data.row.transporterReconciledTotal}</Typography>;
      } else {
        return <Typography>{data.row.supplierReconciledTotal}</Typography>;
      }
    },
    minWidth: 75,
  },
  {
    field: 'invoiceIssues',
    headerName: 'Issue',
    flex: 1,
    renderCell: (data: any) => {
      if (data.row.isTransporter) {
        return <Typography>{data.row.transporterIssueTotal}</Typography>;
      } else {
        return <Typography>{data.row.supplierIssueTotal}</Typography>;
      }
    },
    minWidth: 75,
  },
  {
    field: 'ticketTotal',
    headerName: 'Total',
    flex: 1,
  },
];

const AccountingSummary = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const navigate = useNavigate();
  const [summaryType, setSummaryType] = useState<string>('Company');

  const accountingSummaryData = useSelector(
    (state: RootState) => state.civil.tickets.accountingSummary
  );

  const activeProject = useSelector((state: RootState) => state.civil.projects.activeProject);

  const handleRowClick: GridEventListener<GridEvents.rowClick> = async (data: GridRowModel) => {
    if (summaryType === 'Company') {
      navigate(`/civil/accounting/reconciliation`);
    } else if (summaryType === 'Invoice') {
      navigate('/civil/accounting/reconciliation', {
        state: { payload: data.row },
      });
    }
  };

  useEffect(() => {
    (async () => {
      if (activeProject && summaryType) {
        try {
          setIsLoading(true);
          await thunkDispatch(
            getAccountingSummary({
              projectId: activeProject.id! as number,
              type: summaryType,
            })
          );
          setIsLoading(false);
        } catch (err) {
          console.error('Unable to get accounting ticket summary: ', err);
          setIsLoading(false);
        }
      }
    })();
  }, [activeProject, summaryType]);

  const changeTable = () => {
    setSummaryType(summaryType === 'Company' ? 'Invoice' : 'Company');
  };

  return (
    <>
      <Box display="flex" justifyContent="space-between" alignItems="flex-end">
        <Typography
          variant="h5"
          margin="1rem"
          sx={{
            typography: { textTransform: 'uppercase', color: 'neutral.main' },
          }}
        >
          Reconciliation
        </Typography>
        <Box>
          <Typography>{summaryType}</Typography>
          <Switch
            color="secondary"
            onChange={() => {
              changeTable();
            }}
          />
        </Box>
      </Box>
      <DataGrid
        style={{ minWidth: 400, backgroundColor: '#fff' }}
        rows={activeProject ? accountingSummaryData : []}
        onRowClick={handleRowClick}
        columns={summaryType === 'Company' ? companyColumns : invoiceColumns}
        disableColumnMenu={true}
        loading={isLoading}
        components={{
          ColumnSortedAscendingIcon: ArrowDropUp,
          ColumnSortedDescendingIcon: ArrowDropDown,
          ColumnResizeIcon: () => <div></div>,
          Pagination: () => <div></div>,
          NoRowsOverlay: () => (
            <Box display="flex" flexGrow={1} alignItems="center" justifyContent="center">
              <Typography>No data available</Typography>
            </Box>
          ),
        }}
      />
    </>
  );
};

export default AccountingSummary;
