import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { selectAuthUser } from 'modules/auth/storeSliceAuth';
import InputSearchWithButton from 'sharedComponents/InputSearchWithButton';
import {
  Button,
  CircularProgress,
  Box,
  Typography,
  TextField,
  Grid,
  Menu,
  MenuItem,
} from '@mui/material';
import BorderColorOutlinedIcon from '@mui/icons-material/BorderColorOutlined';
import AddBoxOutlinedIcon from '@mui/icons-material/AddBoxOutlined';
import NotInterestedIcon from '@mui/icons-material/NotInterested';
import { UserTypes } from 'lg-helpers/dist/constants/user/UserTypes';
import { DateRangePicker } from 'rsuite';
import ButtonConfirmWithLoading from 'sharedComponents/ButtonConfirmWithLoading';
import PermissionsWrapper from 'sharedComponents/PermissionsWrapper';
import ToolbarManifestsPagination from 'sharedComponents/ToolbarManifestsPagination';
import useShowError from 'modules/errors';
import { useSwitchUserRoleRender } from 'services/permissions';
import { IProject } from 'services/api/autogenerated-code';
import { auth } from 'services/firebase';

const { afterToday } = DateRangePicker;

const isEqual = (a: any, b: any) => a === b || JSON.stringify(a) === JSON.stringify(b);

export interface IFilters {
  filterIsSigned: boolean | null;
  filterIsVoid: boolean;
  filterDateRange: Date[] | boolean;
  filterIsRejected: boolean;
}

export interface IToolbarManifestsProps {
  indicator: number;
  handleNext: () => void;
  recordsCount: number;
  handleBack: () => void;
  selectedRowsIds: string[];
  setSelectedRowsIdsCallback: (newIds: string[]) => any;
  handleClickAddCallback?: (count: number) => any;
  handleClickVoidCallback: (ids: string[]) => any;
  handleClickUnvoidCallback: (ids: string[]) => any;
  handleClickRefreshCallback?: (ids: string[]) => any;
  handleClickSignCallback: (ids: string[]) => any;
  searchFilter: string;
  onSearchCallback: (newSearch: string | null) => any;
  isLoading: boolean;
  filters: IFilters;
  setFilters: (newFilters: Partial<IFilters>) => any;
  maxNewDocuments: number;
  project: IProject;
}

const ToolbarManifests = ({
  indicator,
  handleNext,
  recordsCount,
  handleBack,
  selectedRowsIds,
  setSelectedRowsIdsCallback,
  handleClickAddCallback,
  handleClickVoidCallback,
  handleClickUnvoidCallback,
  handleClickRefreshCallback,
  handleClickSignCallback,
  searchFilter,
  onSearchCallback,
  isLoading,
  filters,
  setFilters,
  maxNewDocuments,
  project,
}: IToolbarManifestsProps) => {
  const authUser = useSelector(selectAuthUser);
  const [numberNewDocuments, setNumberNewDocuments] = useState(1);
  const showError = useShowError();
  const [isLoadingAdd, setIsLoadingAdd] = useState(false);
  const [isLoadingSign, setIsLoadingSign] = useState(false);
  const [isLoadingRefresh, setIsLoadingRefresh] = useState(false);
  const { filterIsSigned, filterIsVoid, filterDateRange, filterIsRejected } = filters;
  const [signatureAnchorEl, setSignatureAnchorEl] = useState<null | HTMLElement>(null);
  const [statusAnchorEl, setStatusAnchorEl] = useState<null | HTMLElement>(null);
  const isGeneratorUserOnProject = project?.generatorCompany?.id === authUser.companiesIds[0];
  const isContractorUserOnProject = project?.contractorCompany?.id === authUser.companiesIds[0];
  const isScaleUserOnProject = project?.scaleCompany?.id === authUser.companiesIds[0];
  const isSuperAdmin = authUser.actingAsType === UserTypes.admin.super;

  const handleAdd = async () => {
    setIsLoadingAdd(true);
    try {
      handleClickAddCallback &&
        (await handleClickAddCallback(getValidatedNumberNewDocuments(numberNewDocuments)));
      toggleFilters(false, [], false, []);
    } catch (err) {
      console.error(err);
      showError({ title: 'Add manifests error' });
    }
    setIsLoadingAdd(false);
  };

  const handleVoid = async () => {
    try {
      await handleClickVoidCallback(selectedRowsIds);
      setSelectedRowsIdsCallback([]);
    } catch (err) {
      console.error(err);
      showError({ title: 'Void manifests error' });
    }
  };

  const handleUnvoid = async () => {
    try {
      await handleClickUnvoidCallback(selectedRowsIds);
      setSelectedRowsIdsCallback([]);
    } catch (err) {
      console.error(err);
      showError({ title: 'Unvoid manifests error' });
    }
  };

  const handleRefresh = handleClickRefreshCallback
    ? async () => {
        setIsLoadingRefresh(true);
        try {
          await handleClickRefreshCallback(selectedRowsIds);
          setSelectedRowsIdsCallback([]);
        } catch (err) {
          console.error(err);
          showError({ title: 'Refresh manifests error' });
        } finally {
          setIsLoadingRefresh(false);
        }
      }
    : null;

  const handleSign = async () => {
    setIsLoadingSign(true);
    try {
      await handleClickSignCallback(selectedRowsIds);
      setSelectedRowsIdsCallback([]);
    } catch (err) {
      console.error(err);
      showError({ title: 'Sign error' });
    }
    setIsLoadingSign(false);
  };

  const onSearchFilterChange = (filter: string | null) => {
    onSearchCallback(filter);
    setSelectedRowsIdsCallback([]);
  };

  const getValidatedNumberNewDocuments = (numberNewDocsStr: string | number) => {
    const numberNewDocs = parseInt(numberNewDocsStr.toString());
    if (!numberNewDocs || numberNewDocs < 1) {
      return 1;
    }

    if (maxNewDocuments) {
      if (numberNewDocs > maxNewDocuments) {
        return maxNewDocuments;
      }
    }

    return numberNewDocs;
  };

  const onNumberNewDocumentsBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    let newValue = e.target.value;
    setNumberNewDocuments(getValidatedNumberNewDocuments(newValue));
  };

  const onNumberNewDocumentsChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let newValue = e.target.value;
    setNumberNewDocuments(parseInt(newValue));
  };

  const onDateRangeUsedChange = (newDateRange?: (Date | undefined)[]) => {
    if (newDateRange && newDateRange[0] && newDateRange[1]) {
      setFilters({ filterDateRange: [newDateRange[0], newDateRange[1]] });
    } else {
      setFilters({ filterDateRange: [], filterIsRejected: false });
    }
    setSelectedRowsIdsCallback([]);
  };

  const toggleFilters = (
    isSigned: boolean | null,
    dateRange: Date[] | boolean,
    isVoid: boolean,
    selectedRows: string[],
    isRejected?: boolean
  ) => {
    setFilters({
      filterIsSigned: isSigned,
      filterDateRange: dateRange,
      filterIsVoid: isVoid,
      filterIsRejected: isRejected,
    });
    setSelectedRowsIdsCallback(selectedRows);
    setSignatureAnchorEl(null);
    setStatusAnchorEl(null);
  };

  if (!authUser) {
    return null;
  }

  const toolbarSignatureFilters = [
    {
      onClick: () => toggleFilters(false, [], false, [], false),
      text: 'Unsigned',
      isActive: !filterIsSigned,
    },
    {
      onClick: () => toggleFilters(true, [], false, [], false),
      text: 'Signed',
      isActive: filterIsSigned,
    },
    {
      onClick: () => toggleFilters(null, [], false, [], false),
      text: 'All',
      isActive: !filterIsVoid && isEqual(filterIsSigned, null),
    },
    {
      onClick: () => toggleFilters(null, [], true, [], false),
      text: 'Void',
      isActive: filterIsVoid,
    },
    {
      onClick: () => toggleFilters(true, [], false, [], true),
      text: 'Rejected',
      isActive: filterIsRejected,
    },
  ];

  const unvoidButtonForSuperAdmin = useSwitchUserRoleRender({
    super_admin: () => (
      <Box>
        <ButtonConfirmWithLoading
          text="Unvoid"
          color="secondary"
          confirmCallback={handleUnvoid}
          textConfirm="Click again to unvoid!"
          disabled={!selectedRowsIds.length}
        />
      </Box>
    ),
  });

  return (
    <>
      <Grid container alignItems="center" spacing={2}>
        <Grid item md={3} sm={3} xs={12}>
          <InputSearchWithButton
            searchFilter={searchFilter}
            onSearchCallback={onSearchFilterChange}
            disabled={isLoading}
            placeholder="Search by Truck #, Driver, Transporter, Carrier or Manifest #"
          />
        </Grid>
        <Grid item md={5} sm={5} xs={12}>
          <Box display="flex" justifyContent="center" alignItems="center">
            {(isGeneratorUserOnProject || isContractorUserOnProject || isSuperAdmin) && (
              <PermissionsWrapper
                canAccess={[
                  UserTypes.admin.super,
                  UserTypes.generator.admin,
                  UserTypes.generator.user,
                  UserTypes.contractor.admin,
                  UserTypes.contractor.user,
                ]}
              >
                <TextField
                  label="Number of manifests"
                  type="number"
                  variant="outlined"
                  value={numberNewDocuments}
                  onBlur={onNumberNewDocumentsBlur}
                  onChange={onNumberNewDocumentsChange}
                  fullWidth
                />
                <Box marginRight={1}>
                  <Button
                    size="large"
                    onClick={handleAdd}
                    variant="outlined"
                    color="primary"
                    disabled={!handleClickAddCallback || isLoadingAdd || isLoading}
                  >
                    {isLoadingAdd ? (
                      <CircularProgress size="25px" color="inherit" />
                    ) : (
                      <AddBoxOutlinedIcon />
                    )}
                    Create
                  </Button>
                </Box>
              </PermissionsWrapper>
            )}
          </Box>
        </Grid>
        <Grid item md={4} sm={4} xs={12}>
          <Box display="flex" justifyContent="flex-end" alignItems="center" height="100%">
            {[UserTypes.generator.user, UserTypes.generator.admin].includes(
              authUser.actingAsType
            ) &&
              isGeneratorUserOnProject &&
              !filterIsSigned &&
              !filterIsVoid && (
                <Box marginRight={1}>
                  <Button
                    onClick={handleSign}
                    variant="outlined"
                    color="primary"
                    disabled={isLoadingSign || !selectedRowsIds.length}
                  >
                    <Typography variant="h5">
                      <Box display="flex" alignItems="center">
                        <Box marginRight={1} display="flex" alignItems="center">
                          {isLoadingSign ? (
                            <CircularProgress size="25px" color="inherit" />
                          ) : (
                            <BorderColorOutlinedIcon />
                          )}
                        </Box>
                        Sign
                      </Box>
                    </Typography>
                  </Button>
                </Box>
              )}

            {!filterIsVoid &&
              (isGeneratorUserOnProject ||
                isScaleUserOnProject ||
                isContractorUserOnProject ||
                authUser.actingAsType === UserTypes.admin.super) && (
                <PermissionsWrapper
                  canAccess={[
                    UserTypes.admin.super,
                    UserTypes.admin.customer,
                    UserTypes.generator.admin,
                    UserTypes.generator.user,
                    UserTypes.contractor.admin,
                    UserTypes.scale.attendant,
                    UserTypes.scale.admin,
                    UserTypes.contractor.user,
                  ]}
                >
                  <Box marginRight={1}>
                    {handleRefresh && (
                      <Button
                        variant="contained"
                        color="secondary"
                        onClick={handleRefresh}
                        disabled={!selectedRowsIds.length}
                      >
                        {isLoadingRefresh && <CircularProgress size="25px" color="inherit" />}
                        Refresh
                      </Button>
                    )}
                  </Box>
                  <Box>
                    <ButtonConfirmWithLoading
                      text="Void"
                      color="error"
                      confirmCallback={handleVoid}
                      Pictogram={NotInterestedIcon}
                      textConfirm="Click again to void!"
                      disabled={!selectedRowsIds.length}
                    />
                  </Box>
                </PermissionsWrapper>
              )}

            {filterIsVoid && unvoidButtonForSuperAdmin}
          </Box>
        </Grid>

        <Grid item md={3} sm={12} xs={12}>
          <Box display="flex" alignItems="center" marginBottom={1}>
            <DateRangePicker
              appearance="default"
              placeholder="Show used between"
              placement="bottomStart"
              size="lg"
              format="MM/DD/YYYY"
              onChange={onDateRangeUsedChange}
              style={{ width: 260 }}
              defaultValue={(filterDateRange as any) || []}
              disabled={isLoading || !filterIsSigned}
              disabledDate={afterToday()}
            />
          </Box>
        </Grid>
        <Grid item md={3} sm={4} xs={6}>
          <Box display="flex" justifyContent="flex-start" alignItems="center" marginBottom={1}>
            {authUser.actingAsType !== UserTypes.transporter.driver && (
              <>
                <Button
                  variant="contained"
                  disabled={isLoading}
                  onClick={e => setStatusAnchorEl(e.currentTarget)}
                >
                  Filter By Unused &#9660;
                </Button>
                <Menu
                  anchorEl={statusAnchorEl}
                  open={Boolean(statusAnchorEl)}
                  keepMounted
                  onClose={() => setStatusAnchorEl(null)}
                >
                  <MenuItem
                    selected={isEqual(filterIsSigned, true)}
                    onClick={() => toggleFilters(true, [], filters.filterIsVoid, [])}
                    disabled={isLoading}
                  >
                    All
                  </MenuItem>

                  {authUser.actingAsType !== UserTypes.transporter.admin &&
                    authUser.actingAsType !== UserTypes.transporter.driver && (
                      <MenuItem
                        disabled={isLoading}
                        selected={isEqual(filterIsSigned, false)}
                        onClick={() =>
                          toggleFilters(filters.filterIsSigned, false, filters.filterIsVoid, [])
                        }
                      >
                        Unused
                      </MenuItem>
                    )}
                </Menu>
              </>
            )}
          </Box>
        </Grid>
        <Grid item md={3} sm={4} xs={6}>
          <Box display="flex" justifyContent="flex-start" alignItems="center" marginBottom={1}>
            <PermissionsWrapper
              canAccess={[
                UserTypes.admin.super,
                UserTypes.generator.admin,
                UserTypes.generator.user,
                UserTypes.contractor.admin,
                UserTypes.contractor.user,
              ]}
            >
              <Button
                variant="contained"
                disabled={isLoading}
                onClick={e => setSignatureAnchorEl(e.currentTarget)}
              >
                Filter by Signature &#9660;
              </Button>
              <Menu
                anchorEl={signatureAnchorEl}
                open={Boolean(signatureAnchorEl)}
                keepMounted
                onClose={() => setSignatureAnchorEl(null)}
              >
                {toolbarSignatureFilters.map((item, i) => (
                  <MenuItem
                    key={i}
                    disabled={isLoading}
                    onClick={item.onClick}
                    selected={!!item.isActive}
                  >
                    {item.text}
                  </MenuItem>
                ))}
              </Menu>
            </PermissionsWrapper>
          </Box>
        </Grid>
        <Grid item md={3} sm={4} xs={12}>
          <ToolbarManifestsPagination
            indicator={indicator}
            handleBack={handleBack}
            handleNext={handleNext}
            recordsCount={recordsCount}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default ToolbarManifests;
