import React from 'react';
import { createSvgIcon } from '@mui/material';

export const GridDetail = createSvgIcon(
  <path
    fillRule="evenodd"
    clipRule="evenodd"
    d="M2.5 2c-.27614 0-.5.22386-.5.5v17c0 .2761.22386.5.5.5h17c.2761 0 .5-.2239.5-.5v-17c0-.27614-.2239-.5-.5-.5h-17ZM0 2.5C0 1.11929 1.11929 0 2.5 0h17C20.8807 0 22 1.11929 22 2.5v17c0 1.3807-1.1193 2.5-2.5 2.5h-17C1.11929 22 0 20.8807 0 19.5v-17ZM4 5c0-.55228.44772-1 1-1h4c.55229 0 1 .44772 1 1v4c0 .55229-.44771 1-1 1H5c-.55228 0-1-.44771-1-1V5Zm2 1v2h2V6H6Zm6-1c0-.55228.4477-1 1-1h4c.5523 0 1 .44772 1 1s-.4477 1-1 1h-4c-.5523 0-1-.44772-1-1Zm0 4c0-.55228.4477-1 1-1h4c.5523 0 1 .44772 1 1 0 .55229-.4477 1-1 1h-4c-.5523 0-1-.44771-1-1Zm-8 4c0-.5523.44772-1 1-1h12c.5523 0 1 .4477 1 1s-.4477 1-1 1H5c-.55228 0-1-.4477-1-1Zm0 4c0-.5523.44772-1 1-1h12c.5523 0 1 .4477 1 1s-.4477 1-1 1H5c-.55228 0-1-.4477-1-1Z"
  />,
  'Grid Detail'
);
