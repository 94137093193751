import React from 'react';
import { createSvgIcon } from '@mui/material';

export const CalendarIcon = createSvgIcon(
  <path
    fillRule="evenodd"
    clipRule="evenodd"
    d="M7.5 0c.55228 0 1 .44771 1 1v1h5V1c0-.55229.4477-1 1-1s1 .44771 1 1v1H20c1.1046 0 2 .89543 2 2v16c0 1.1046-.8954 2-2 2H2c-1.10457 0-2-.8954-2-2V4c0-1.10457.89543-2 2-2h4.5V1c0-.55229.44772-1 1-1Zm-1 4H2v4h18V4h-4.5v1c0 .55228-.4477 1-1 1s-1-.44772-1-1V4h-5v1c0 .55228-.44772 1-1 1s-1-.44772-1-1V4ZM20 10h-4.5v4H20v-4Zm0 6h-4.5v4H20v-4Zm-6.5 4v-4h-5v4h5Zm-7 0v-4H2v4h4.5ZM2 14h4.5v-4H2v4Zm6.5-4v4h5v-4h-5Z"
  />,
  'Calendar'
);
