import React, { useCallback, useContext, useEffect, useState } from 'react';
import { Button, Typography, Switch, Box } from '@mui/material';
import { UpsertDispatchForm } from './components/UpsertDispatchForm';
import { ModalContext } from 'sharedComponents/ModalContext';
import { useSelector } from 'react-redux';
import { thunkDispatch } from 'store/store';
import { setCompleted, setDate } from '../../redux/dispatchesToolbarStoreSlice';
import { selectDate, selectCompleted } from '../../redux/dispatchesToolbarStoreSlice';
import DateWithArrows from 'sharedComponents/DateWithArrows';
import {
  getJoinedDispatchesByCompanyId,
  upsertDispatch,
  deleteDispatch,
} from '../../redux/actions';
import { DispatchesList } from './components/DispatchesList';
import ButtonToggle from 'sharedComponents/ButtonToggle';
import { selectAuthUser } from 'modules/auth/storeSliceAuth';
import Executer from 'services/firebase/Executer';
import Query from 'services/firebase/Query';
import { extractFieldsByTuple } from 'modules/civilAndTrucking/trucking/utils';
import {
  FormTruckingDispatch,
  KTruckingDispatch,
} from 'lg-helpers/dist/trucking/interfaces/ITruckingDispatch';
import { IJoinTruckingDispatch } from 'lg-helpers/dist/trucking/interfaces/IJoinTruckingDispatch';
import { RestartAlt } from '@mui/icons-material';
import { ConfirmModal } from 'modules/civilAndTrucking/trucking/sharedComponents/ConfirmModalWithPref';
import useStickyState from 'services/hooks/useStickyState';
import moment from 'moment';

const executer = new Executer();
const query = new Query();

const COPY_DISPATCH_SUPRESS_PREFERENCE = 'SUPPRESS_COPY_DISPATCH_MODAL';
const DISPATCH_DATE_FORMAT = 'YYYY-MM-DD';

export const DispatchesView = () => {
  const [requestedDispatchCount, setRequestedDispatchCount] = useState(0);
  const isShowCompletedChecked = useSelector(selectCompleted);
  const dispatchesDateFilter = useSelector(selectDate);
  const { handleModal } = useContext(ModalContext);
  const [dispatchType, setDispatchType] = useState<0 | 1>(0);
  const authUser = useSelector(selectAuthUser);
  const companyId = authUser.companiesIds[0];
  const [suppressCopyModal, setSuppressCopyModal] = useStickyState<boolean>(
    false,
    COPY_DISPATCH_SUPRESS_PREFERENCE
  );

  const fetchDispatches = useCallback(() => {
    thunkDispatch(getJoinedDispatchesByCompanyId(dispatchesDateFilter));
  }, [dispatchesDateFilter]);

  useEffect(() => {
    fetchDispatches();
  }, [fetchDispatches]);

  useEffect(() => {
    return executer.watchSingleDocument(
      query.truckingTickets.getRequestedDispatch(companyId),
      res => {
        if (res) {
          setRequestedDispatchCount(res.requestedDispatchesCount || 0);
        }
      },
      () => {}
    );
  }, [companyId]);

  const copyDispatch = (joinedDispatch: IJoinTruckingDispatch, suppressModalPref: boolean) => {
    setSuppressCopyModal(suppressModalPref);
    const dispatch = {
      ...extractFieldsByTuple<FormTruckingDispatch>(joinedDispatch, KTruckingDispatch),
    };
    dispatch.dateTruckingDispatch = moment().format(DISPATCH_DATE_FORMAT);
    // strip ID to force row insert
    dispatch.idTruckingDispatch = undefined;

    thunkDispatch(upsertDispatch(dispatch));
  };

  const showModal = (joinedDispatch?: IJoinTruckingDispatch) => {
    handleModal(<UpsertDispatchForm editObj={joinedDispatch} />);
  };

  const showCopyModal = (row: IJoinTruckingDispatch) => {
    if (suppressCopyModal) copyDispatch(row, suppressCopyModal);
    else
      handleModal(
        <ConfirmModal
          modalHeader="Copy Dispatch"
          modalText={`This action will create a copy of this dispatch, defaulted to today (${moment().format(
            DISPATCH_DATE_FORMAT
          )}).`}
          shouldSuppressModal={suppressCopyModal}
          confirmAction={(_, suppressModalPref) => copyDispatch(row, suppressModalPref)}
        />
      );
  };

  const doneDispach = async (joinedDispatch: IJoinTruckingDispatch, isDone: boolean) => {
    const dispatch = extractFieldsByTuple<FormTruckingDispatch>(joinedDispatch, KTruckingDispatch);
    await thunkDispatch(
      upsertDispatch({
        ...dispatch,
        isDoneTruckingDispatch: isDone,
      })
    );

    thunkDispatch(getJoinedDispatchesByCompanyId(dispatchesDateFilter));
  };

  const handleDeleteDispatch = (dispatchId: number) => {
    if (window.confirm(`Are you sure you want to delete this dispatch?`)) {
      thunkDispatch(deleteDispatch(dispatchId));
    }
  };

  return (
    <>
      <Box display="flex" flexWrap="wrap" alignItems="center" justifyContent="space-between" mb={2}>
        <Box sx={{ flex: 1 }}>
          <ButtonToggle
            firstVal={'YOUR DISPATCHES'}
            secondVal={'REQUESTED'}
            toggleIndex={dispatchType}
            setToggleIndex={num => setDispatchType(num)}
            badgeCount={requestedDispatchCount}
          />
        </Box>
        <Box display="flex" flexDirection="row" alignItems="center" sx={{ flex: 1 }}>
          <Typography>Display Date</Typography>
          <DateWithArrows
            setDate={(date: string) => thunkDispatch(setDate(date))}
            dateValue={dispatchesDateFilter}
          />
        </Box>
        <Box
          display="flex"
          flexDirection="row"
          alignItems="center"
          justifyContent="flex-end"
          sx={{ flex: 1 }}
        >
          {dispatchType === 0 && (
            <>
              <Button
                onClick={() => fetchDispatches()}
                variant="contained"
                color="secondary"
                sx={{ mr: 1 }}
              >
                <Box display="flex" alignItems="center" flexDirection="column">
                  <RestartAlt sx={{ color: 'secondary.main' }} />
                  <Typography variant="overline">REFRESH</Typography>
                </Box>
              </Button>
              <Typography variant="body2">Show Completed</Typography>
              <Switch
                checked={isShowCompletedChecked}
                onChange={() => thunkDispatch(setCompleted())}
                name="isActive"
              />
              <Button
                onClick={() => showModal()}
                variant="contained"
                color="primary"
                sx={{ ml: 1 }}
              >
                Add
              </Button>
            </>
          )}
        </Box>
      </Box>
      <DispatchesList
        showModal={showModal}
        doneDispach={doneDispach}
        handleDeleteDispatch={handleDeleteDispatch}
        showCopyModal={showCopyModal}
      />
    </>
  );
};
