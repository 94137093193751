import React, {
  ReactElement,
  ReactEventHandler,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import { Button, Box, Grid, Typography, Radio, SvgIcon, CircularProgress } from '@mui/material';
import { CloudDownloadOutlined } from '@mui/icons-material';
import AddProjectTask from 'modules/civilAndTrucking/civil/ProjectTask';
import { ModalContext } from 'sharedComponents/ModalContext';
import { useParams } from 'react-router-dom';
import { TProjectForm } from './ProjectInfoForm';
import { useHasAccess } from 'services/permissions';
import { LinkIcon } from 'modules/civilAndTrucking/shared/CustomIcons/Link';
import { LocationSearching, ApiOutlined } from '@mui/icons-material';
import { UploadLogs } from 'modules/civilAndTrucking/shared/CustomIcons/UploadLogs';
import { SourceSupplier } from 'modules/civilAndTrucking/shared/CustomIcons/SourceSupplier';
import { MaterialSource } from 'modules/civilAndTrucking/shared/CustomIcons/MaterialSource';
import AddTemplateModal from './AddTemplateModal';
import LoadTemplateModal from './LoadTemplateModal';
import ManageLocations from '../AddLocations';
import ImportLocations from '../ImportLocations';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RootState, thunkDispatch } from 'store/store';
import { IJoinProjectTask } from '../../ProjectTask/redux/actions';
import { getActivities } from '../../Activities/redux/actions';
import { getProjectMaterials } from '../../ProjectMaterials/redux/actions';

interface IFormButtonsProps {
  data?: TProjectForm;
  setTasks?: Function;
  tasks?: IJoinProjectTask[];
}

interface IButtonData {
  graphic: ReactElement;
  handleClick: ReactEventHandler;
  label: string;
}

export const FormButtons = ({ data, setTasks, tasks = [] }: IFormButtonsProps) => {
  const { handleModal } = useContext(ModalContext);
  const { projectId } = useParams();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);

  const auth = useHasAccess(['projects:create', 'projects:update']);

  const activeProject = useSelector((state: RootState) => state.civil.projects.activeProject);

  const activitiesState = useSelector((state: RootState) => state.civil.activities.data);

  const projectMaterialState = useSelector((state: RootState) => state.civil.projectMaterials.data);

  const fetchData = useCallback(async () => {
    if (!activeProject || !activeProject.id) {
      return;
    }
    setIsLoading(true);
    try {
      await Promise.all([
        thunkDispatch(getActivities({ projectId: activeProject?.id as number, type: 'active' })),
        thunkDispatch(
          getProjectMaterials({
            projectId: Number(activeProject.id),
            type: 'active',
          })
        ),
      ]);
    } catch (err) {
      console.error('Error getting project info:', err);
    } finally {
      setIsLoading(false);
    }
  }, [activeProject]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const showForm = async (form: React.FunctionComponentElement<any>) => {
    handleModal(form);
  };

  const templateButtons: IButtonData[] = [
    {
      graphic: (
        <SvgIcon color="secondary">
          <LinkIcon />
        </SvgIcon>
      ),
      label: 'Save as Template',
      handleClick: () => showForm(<AddTemplateModal />),
    },
    {
      label: 'Load Template',
      graphic: (
        <SvgIcon color="secondary">
          <CloudDownloadOutlined />
        </SvgIcon>
      ),
      handleClick: () => showForm(<LoadTemplateModal setTasks={setTasks} />),
    },
  ];

  const collaboratorsButtons: IButtonData[] = [
    {
      label: 'Collaborators',
      graphic: (
        <SvgIcon color="secondary">
          <SourceSupplier viewBox="0 0 30 35" />
        </SvgIcon>
      ),
      handleClick: () => navigate(`/civil/projects/${projectId || data?.id!}/collaborators`),
    },
    {
      handleClick: () => handleModal(<ManageLocations projectId={Number(projectId)} />),
      graphic: (
        <SvgIcon color="secondary">
          <LocationSearching />
        </SvgIcon>
      ),
      label: 'Manage Locations',
    },
    {
      handleClick: () => handleModal(<ImportLocations projectId={Number(projectId)} />),
      graphic: (
        <SvgIcon color="secondary">
          <UploadLogs />
        </SvgIcon>
      ),
      label: 'Import Locations',
    },
  ];

  const projectSetUpButtons: IButtonData[] = [
    {
      handleClick: () => navigate(`/civil/projects/${projectId || data?.id!}/work-categories`),
      graphic: (
        <Radio
          name="workCategories"
          color="secondary"
          checked={activitiesState?.length > 0}
        ></Radio>
      ),
      label: 'Work Categories',
    },
    {
      handleClick: () => navigate(`/civil/projects/${projectId || data?.id!}/materials`),
      graphic: (
        <Radio
          name="projectMaterials"
          color="secondary"
          checked={projectMaterialState?.length > 0}
        ></Radio>
      ),
      label: 'Project Materials',
    },
    {
      label: 'Add Task',
      graphic: <Radio name="projectTasks" color="secondary" checked={tasks?.length > 0}></Radio>,
      handleClick: () =>
        handleModal(
          <AddProjectTask
            selected={{
              projectId: Number(projectId),
              autoTicketNumber: false,
              deleted: false,
            }}
          />
        ),
    },
    {
      handleClick: () => navigate(`/civil/projects/${projectId || data?.id!}/sub-materials`),
      graphic: (
        <SvgIcon color="secondary">
          <MaterialSource viewBox="0 0 25 30" />,
        </SvgIcon>
      ),
      label: 'Project Sub-Materials',
    },
  ];

  return auth.hasAccess ? (
    <>
      <Grid
        container
        width={300}
        display="flex"
        justifyContent="flex-start"
        alignItems="center"
        spacing={2}
        marginTop="0.4rem"
      >
        {templateButtons.map((button, index) => (
          <Grid item xs={4} display="flex" justifyContent="flex-start" key={index}>
            <Button
              sx={{
                backgroundColor: '#FFF',
                display: 'flex',
                height: 75,
                width: 75,
                flexDirection: 'row',
                alignContent: 'center',
              }}
              onClick={button.handleClick}
            >
              <Box display="flex" flexDirection="column" alignItems="center">
                {button.graphic}
                <Typography
                  className="Civil__small-card--buttons"
                  sx={{
                    fontSize: 8,
                    textTransform: 'none',
                  }}
                >
                  {button.label}
                </Typography>
              </Box>
            </Button>
          </Grid>
        ))}

        <Grid item xs={4} display="flex" justifyContent="flex-start">
          <Button
            sx={{
              backgroundColor: '#FFF',
              display: 'flex',
              height: 75,
              width: 75,
              flexDirection: 'row',
              alignContent: 'center',
            }}
            onClick={() => navigate(`/civil/projects/${projectId || data?.id!}/api-mappings`)}
          >
            <Box display="flex" flexDirection="column" alignItems="center">
              <SvgIcon color="secondary">
                <ApiOutlined />
              </SvgIcon>
              <Typography
                className="Civil__small-card--buttons"
                sx={{
                  fontSize: 8,
                  textTransform: 'none',
                }}
              >
                Manage API mappings
              </Typography>
            </Box>
          </Button>
        </Grid>

        {collaboratorsButtons.map((button, index) => (
          <Grid item xs={4} display="flex" justifyContent="flex-start" key={index}>
            <Button
              sx={{
                backgroundColor: '#FFF',
                display: 'flex',
                height: 75,
                width: 75,
                flexDirection: 'row',
                alignContent: 'center',
              }}
              onClick={button.handleClick}
            >
              <Box display="flex" flexDirection="column" alignItems="center">
                {button.graphic}
                <Typography
                  className="Civil__small-card--buttons"
                  sx={{
                    fontSize: 8,
                    textTransform: 'none',
                    color: 'neutral.main',
                  }}
                >
                  {button.label}
                </Typography>
              </Box>
            </Button>
          </Grid>
        ))}
        {projectSetUpButtons.map((button, index) => (
          <Grid item xs={4} display="flex" justifyContent="flex-start" key={index}>
            <Button
              sx={{
                backgroundColor: '#FFF',
                display: 'flex',
                height: 75,
                width: 75,
                flexDirection: 'row',
                alignContent: 'center',
              }}
              onClick={button.handleClick}
            >
              <Box display="flex" flexDirection="column" alignItems="center">
                {isLoading ? <CircularProgress size={20} color="secondary" /> : button.graphic}
                <Typography
                  className="Civil__small-card--buttons"
                  sx={{
                    fontSize: 8,
                    textTransform: 'none',
                    color: 'neutral.main',
                  }}
                >
                  {button.label}
                </Typography>
              </Box>
            </Button>
          </Grid>
        ))}
      </Grid>
    </>
  ) : (
    <></>
  );
};

export default FormButtons;
