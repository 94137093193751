import { createSlice } from '@reduxjs/toolkit';
import { addActivity, deleteActivity, getActivities, getActivitiesByWorkCategory } from './actions';
import { IActivityJoin } from 'lg-helpers/dist/shared/interfaces/IActivityJoin';
import { TFormStatus } from 'lg-helpers/dist/shared/types/TFormStatus';

interface IActivityState {
  data: IActivityJoin[];
  status: TFormStatus;
  error: string | undefined;
}

const initialState: IActivityState = {
  data: [],
  status: null,
  error: undefined,
};

export const activitiesSlice = createSlice({
  name: 'activities',
  reducers: {
    add: state => state,
    addActivity: state => state,
    getActivities: state => state,
    getActivitiesByWorkCategory: state => state,
  },
  initialState,
  extraReducers: builder => {
    builder.addCase(getActivities.fulfilled, (state, { payload }) => {
      state.status = 'success';
      state.data = payload!;
    });

    builder.addCase(getActivities.rejected, (state, action) => {
      console.error('Error getting activities: ', action.error);
      state.status = 'failure';
      state.error = action.error?.message;
    });

    builder.addCase(getActivitiesByWorkCategory.fulfilled, (state, { payload }) => {
      state.status = 'success';
      state.data = payload;
    });

    builder.addCase(addActivity.fulfilled, state => {
      state.status = 'success';
    });

    builder.addCase(deleteActivity.fulfilled, (state, { payload }) => {
      state.status = 'success';
      state.data = state.data.filter(item => item.id !== payload?.activity.id);
    });
  },
});
