import React from 'react';
import WarningIcon from '@mui/icons-material/Warning';
import HelpIcon from '@mui/icons-material/Help';
import ReceiptOutlinedIcon from '@mui/icons-material/ReceiptOutlined';
import UpdateIcon from '@mui/icons-material/Update';

import LocalShippingOutlinedIcon from '@mui/icons-material/LocalShippingOutlined';

const iconStyles = {
  fontSize: '32px',
  color: '#48ABB1',
};

export type NotificationIconIdentifier =
  | 'DRIVER_OUT_OF_GEOFENCE'
  | 'LIVEGISTICS_UPDATE'
  | 'WARNING'
  | 'MANIFEST_RECEIPT'
  | 'HELP';

function NotificationIcon({ icon }: { icon: NotificationIconIdentifier | string }) {
  switch (icon) {
    case 'DRIVER_OUT_OF_GEOFENCE':
      return <LocalShippingOutlinedIcon style={iconStyles} />;
    case 'LIVEGISTICS_UPDATE':
      return <UpdateIcon style={iconStyles} />;
    case 'WARNING':
      return <WarningIcon style={iconStyles} />;
    case 'MANIFEST_RECEIPT':
      return <ReceiptOutlinedIcon style={iconStyles} />;
    default:
      return <HelpIcon style={iconStyles} />;
  }
}

export default NotificationIcon;
