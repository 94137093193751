import React from 'react';
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
} from '@mui/material';
import { UserType, UserTypes } from 'lg-helpers/dist/constants/user/UserTypes';
import { Autocomplete } from '@mui/lab';

interface SwitcherModalProps {
  isOpen: boolean;
  onToggle(open: boolean): void;
  desiredType: UserType | '';
  items: any;
  getLabel(item: any): string;
  selectedItem: any;
  onSelectItem(val: any): void;
  selectedRegion: any;
  onSelectRegion: (val: any) => any;
  scales: any[];
  selectedScale: any;
  onSelectScale: (val: any) => any;
  isLoadingItems: boolean;
  onHandleChange(): void;
}

const SwitcherModal = ({
  isOpen,
  onToggle,
  desiredType,
  items,
  getLabel,
  selectedItem,
  onSelectItem,
  selectedRegion,
  onSelectRegion,
  scales,
  selectedScale,
  onSelectScale,
  isLoadingItems,
  onHandleChange,
}: SwitcherModalProps) => {
  const isScaleUser =
    [UserTypes.scale.admin, UserTypes.scale.attendant].indexOf(desiredType as any) >= 0;

  const isSuperAdmin = desiredType === UserTypes.admin.super;
  const isCompleted =
    isSuperAdmin || ((isScaleUser ? selectedRegion && selectedScale : true) && selectedItem);

  return (
    <Dialog fullWidth open={isOpen} onClose={() => onToggle(false)}>
      <DialogTitle>
        {desiredType === UserTypes.admin.super ? (
          <>
            You are about to change to a <b>{desiredType}</b>.
          </>
        ) : (
          <>Select a company to emulate:</>
        )}
      </DialogTitle>
      <DialogContent>
        {!isSuperAdmin && (
          <Autocomplete
            fullWidth
            // todo: says required is allowed via typescript
            // required
            options={items || []}
            getOptionLabel={item => getLabel(item)}
            value={selectedItem || null}
            onChange={(event: any, value: any) => onSelectItem(value)}
            renderOption={(props, option) => {
              return (
                <li {...props} key={option.id}>
                  {`${option.name} - ${option.types.join(', ')}`}
                </li>
              );
            }}
            renderInput={(params: any) => (
              <TextField
                {...params}
                label="Company"
                required
                variant="outlined"
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <>
                      {isLoadingItems && <CircularProgress color="inherit" size={20} />}
                      {params.InputProps.endAdornment}
                    </>
                  ),
                }}
              />
            )}
          />
        )}

        {isScaleUser && (
          <>
            <br />
            <Autocomplete
              fullWidth
              disabled={!selectedItem}
              options={selectedItem?.regions || []}
              getOptionLabel={item => item.name}
              value={selectedRegion || null}
              onChange={(event: any, value: any) => onSelectRegion(value)}
              renderInput={(params: any) => (
                <TextField
                  {...params}
                  label="Region"
                  required
                  variant="outlined"
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <>
                        {isLoadingItems && <CircularProgress color="inherit" size={20} />}
                        {params.InputProps.endAdornment}
                      </>
                    ),
                  }}
                />
              )}
            />

            <br />
            <Autocomplete
              fullWidth
              disabled={!selectedRegion}
              options={scales}
              getOptionLabel={item => item.name}
              value={selectedScale || null}
              onChange={(event: any, value: any) => onSelectScale(value)}
              renderInput={(params: any) => (
                <TextField
                  {...params}
                  label="Scale"
                  required
                  variant="outlined"
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <>
                        {isLoadingItems && <CircularProgress color="inherit" size={20} />}
                        {params.InputProps.endAdornment}
                      </>
                    ),
                  }}
                />
              )}
            />
          </>
        )}

        {desiredType !== UserTypes.admin.super && (
          <DialogContentText style={{ marginTop: 20 }}>
            You are about to change to a <b>{desiredType}</b> with{' '}
            <b>{selectedItem ? selectedItem.name : '_________'}</b> company
            {isScaleUser && (
              <>
                , in region <b>{selectedRegion ? selectedRegion.name : '_________'}</b> and with
                scale <b>{selectedScale ? selectedScale.name : '_________'}</b>
              </>
            )}
          </DialogContentText>
        )}
      </DialogContent>
      <DialogActions>
        <Button disabled={!isCompleted} onClick={onHandleChange} autoFocus>
          Emulate
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default SwitcherModal;
