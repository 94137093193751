import React, { useState, useEffect, useRef, useCallback } from 'react';
import {
  Container,
  Grid,
  Box,
  Typography,
  CircularProgress,
  Button,
  Badge,
  TextField,
  Autocomplete,
} from '@mui/material';
import Page from 'sharedComponents/Page';
import Notes from 'sharedComponents/Notes';
import { useSelector, useDispatch } from 'react-redux';
import {
  selectSelectedProject,
  selectCustomData,
  setSelectedProject,
} from 'modules/transporters/storeSliceTransporters';
import { useNavigate } from 'react-router-dom';
import ManifestDisplayWithTemplateDataGathering from 'sharedComponents/ManifestDisplayWithTemplateDataGathering';
import BorderColorIcon from '@mui/icons-material/BorderColor';
import { selectAuthUser } from 'modules/auth/storeSliceAuth';
import TruckFrontWhite from 'sharedComponents/pictograms/TruckFrontWhite';
import { hasErrorRequired } from 'services/logic/formValidation';
import {
  getManifestVisibleCustomInputsDefinitionsCanEdit,
  getManifestVisibleCustomInputsDefinitionsCanOnlyRead,
} from 'utils/manifestUtil';
import useGetDriverCurrentLoad from 'services/hooks/useGetDriverCurrentLoad';
import useGetDriverCurrentManifest from 'services/hooks/useGetDriverCurrentManifest';
import useIsUserOfType from 'services/hooks/useIsUserOfType';
import useWatchManifestIfSignedByScaleReleaseDriver from 'services/hooks/useWatchManifestIfSignedByScaleReleaseDriver';
import Command from 'services/firebase/Command';
import Executer from 'services/firebase/Executer';
import Query from 'services/firebase/Query';
import useShowError from 'modules/errors';
import QueryBase from 'lg-helpers/dist/firestore/query/QueryBase';

const query = new Query();
const command = new Command();
const executer = new Executer();

const CurrentManifestView = () => {
  const dispatch = useDispatch();
  const { isLoadingCurrentManifest, currentManifest } = useGetDriverCurrentManifest();
  const { currentLoad, isLoading: isLoadingCurrentLoad } = useGetDriverCurrentLoad();

  useWatchManifestIfSignedByScaleReleaseDriver();

  const isUserBrokerDriverOrAdmin = useIsUserOfType('trucking_driver', 'trucking_dispatcher');
  const [isLoadingData, setIsLoadingData] = useState(false);
  const [isSigning, setIsSigning] = useState(false);
  const [isManifestSuccessfullyDisplayed, setIsManifestSuccessfullyDisplayed] = useState(false);
  const showError = useShowError();
  const [isLoadingProject, setIsLoadingProject] = useState(false);
  const [project, setProject] = useState(null);
  const [manifest, setManifest] = useState(null);
  const [isSignVisible, setIsSignVisible] = useState(false);
  const [seccondsLeftToSign, setSeccondsLeftToSign] = useState(null);
  const [selectedScale, setSelectedScale] = useState(null);
  const [isNotesOpen, setIsNotesOpen] = useState(false);
  const [customInputsCanEditCount, setCustomInputsCanEditCount] = useState(0);
  const [customInputsReadOnlyCount, setCustomInputsReadOnlyCount] = useState(0);
  const selectedProject = useSelector(selectSelectedProject);

  const authUser = useSelector(selectAuthUser);
  const customData = useSelector(selectCustomData);
  const navigate = useNavigate();

  const projectId = manifest?.project?.id;

  const isMounted = useRef(false);
  useEffect(() => {
    isMounted.current = true;
    return () => (isMounted.current = false);
  }, []);

  const getManifest = useCallback(
    async manifestProjectId => {
      if (!authUser || !manifestProjectId) {
        return;
      }
      setIsLoadingData(true);
      try {
        let queryToGetManifest;
        if (authUser.customData && authUser.customData.assignSpecificManifestId) {
          queryToGetManifest = query.base.getById(
            QueryBase.MANIFESTS_COLLECTION(),
            authUser.customData.assignSpecificManifestId
          );
        } else {
          queryToGetManifest = query.manifests.getNextUnsignedManifestForDriver(manifestProjectId);
        }

        const nextUnsignedManifest = await executer.getSingleDocument(queryToGetManifest);
        setManifest(nextUnsignedManifest);
        if (nextUnsignedManifest) {
          await command.manifests.markManifestWasRequestedNowByDriver(
            nextUnsignedManifest.id,
            authUser.id
          );
          setIsSignVisible(true);
          setSeccondsLeftToSign(60);
        } else {
          showError({ title: 'No manifest available!', duration: 10000 });
        }

        return nextUnsignedManifest;
      } catch (error) {
        console.error('Error', error);
        showError({ title: 'Error get manifest', duration: 10000 });
        setIsSignVisible(false);
      } finally {
        setIsLoadingData(false);
      }
    },
    // eslint-disable-next-line
    [authUser]
  );

  useEffect(() => {
    if (!projectId) return;

    setProject(null);

    const load = async () => {
      setIsLoadingProject(true);

      try {
        const proj = await executer.getSingleDocument(
          query.base.getById(QueryBase.PROJECTS_COLLECTION(), projectId)
        );
        setProject(proj);
      } catch (err) {
        console.error(err);
        showError({ title: 'Failed to load project', duration: 10000 });
      } finally {
        setIsLoadingProject(false);
      }
    };

    load();
    // eslint-disable-next-line
  }, [projectId]);

  useEffect(() => {
    if (isLoadingCurrentLoad || isLoadingCurrentManifest || isUserBrokerDriverOrAdmin === null) {
      return;
    }

    if (isUserBrokerDriverOrAdmin) {
      if (!currentLoad || !currentLoad.manifestProjectID || currentLoad.isManifestComplete) {
        navigate('/brokers/driver/dispatches');
        return;
      }

      if (currentLoad && currentLoad.manifestProjectID) {
        dispatch(
          setSelectedProject({
            id: currentLoad.manifestProjectID,
          })
        );
      }
    } else {
      if (!currentManifest) {
        if (
          !customData ||
          (customData &&
            (!customData.truckingCompany ||
              !customData.truckNumber ||
              !customData.truckCapacity ||
              !customData.truckCapacityUnit))
        ) {
          navigate('/drivers/custom-data');
        }
      }
    }
  }, [
    isUserBrokerDriverOrAdmin,
    customData,
    currentLoad,
    isLoadingCurrentLoad,
    dispatch,
    currentManifest,
    isLoadingCurrentManifest,
    navigate,
  ]);

  useEffect(() => {
    if (isLoadingCurrentManifest || isUserBrokerDriverOrAdmin === null) {
      return;
    }

    if (isUserBrokerDriverOrAdmin === false && !selectedProject && !currentManifest) {
      navigate('/drivers/projects');
      return;
    }

    if (currentManifest) {
      setManifest(currentManifest);
      setIsSignVisible(false);
    } else if (!manifest && selectedProject) {
      if (
        selectedProject.canAssignSpecificManifestToDriver &&
        !authUser.customData.assignSpecificManifestId
      ) {
        navigate('/drivers/custom-data');
      } else {
        getManifest(selectedProject.id);
      }
    }
  }, [
    authUser,
    getManifest,
    manifest,
    selectedProject,
    isLoadingCurrentManifest,
    currentManifest,
    isUserBrokerDriverOrAdmin,
    navigate,
  ]);

  useEffect(() => {
    if (!isSignVisible) {
      return;
    }

    if (seccondsLeftToSign === null) {
      return;
    }
    if (seccondsLeftToSign <= 0) {
      navigate('/drivers/warning-sign-manifest-time-expired');
      return;
    }

    const timeout = setTimeout(() => setSeccondsLeftToSign(seccondsLeftToSign - 1), 1000);
    return () => clearTimeout(timeout);
  }, [seccondsLeftToSign, isSignVisible, navigate]);

  useEffect(() => {
    if (!authUser || !manifest) {
      return;
    }

    getManifestVisibleCustomInputsDefinitionsCanEdit(manifest, authUser.actingAsType).then(
      canEdit => {
        if (canEdit?.length) {
          setCustomInputsCanEditCount(canEdit.length);
        }
      }
    );
    getManifestVisibleCustomInputsDefinitionsCanOnlyRead(manifest, authUser.actingAsType).then(
      readOnly => {
        if (readOnly?.length) {
          setCustomInputsReadOnlyCount(readOnly.length);
        }
      }
    );
  }, [authUser, manifest]);

  useEffect(() => {
    if (project && project.scales && project.scales.length === 1) {
      setSelectedScale(project.scales[0]);
    }
  }, [project]);

  const handleClickSign = async () => {
    setIsSigning(true);
    try {
      let brokerLoadId;
      let truckingCompanyId;
      let truckingCompany;
      let truckNumber;
      let truckCapacity;
      let truckCapacityUnit;
      let secondaryTruckingCompany = null;
      if (isUserBrokerDriverOrAdmin) {
        const { id, signatureDriver } = currentLoad;
        brokerLoadId = id;
        truckingCompanyId = authUser.companies[0].id;
        truckingCompany = authUser.companies[0].name;
        truckNumber = signatureDriver.truckNumber;
        truckCapacity = signatureDriver.quantity;
        truckCapacityUnit = 'Tons';
      } else {
        brokerLoadId = null;
        truckingCompanyId = authUser.companies[0].id;
        truckingCompany = customData.truckingCompany;
        truckNumber = customData.truckNumber;
        truckCapacity = customData.truckCapacity;
        truckCapacityUnit = customData.truckCapacityUnit;
        secondaryTruckingCompany = customData.secondaryTruckingCompany || null;
      }

      await command.manifests.signManifestByDriver({
        brokerLoadId,
        assignSpecificManifestId:
          (authUser.customData && authUser.customData.assignSpecificManifestId) || null,
        manifestId: manifest.id,
        signatureUrl: authUser.signature.url,
        userId: authUser.id,
        email: authUser.email || '',
        driverName: `${authUser.firstName} ${authUser.lastName}`,
        truckingCompanyId,
        truckingCompany,
        truckNumber,
        truckCapacity,
        truckCapacityUnit,
        secondaryTruckingCompany,
        selectedScale,
      });
      setIsSignVisible(false);
    } catch (err) {
      console.error(err);
      showError({ title: err.text || 'Error signing manifest' });
    }

    setIsSigning(false);
  };

  const isSignDisabled = () => {
    return (
      !manifest ||
      !authUser ||
      !authUser.signature ||
      !authUser.signature.url ||
      !seccondsLeftToSign ||
      !selectedScale
    );
  };

  const goToScaleLane = () => {
    navigate('/drivers/select-scale-lane');
  };

  const handleClickNotes = () => {
    setIsNotesOpen(true);
  };

  const handleCloseNotes = () => {
    setIsNotesOpen(false);
  };

  return (
    <Page title="Current manifest">
      <Container>
        <Grid container spacing={1}>
          {isLoadingData || isLoadingProject ? (
            <Grid item xs={12}>
              <Box display="flex" justifyContent="center" alignItems="center">
                <CircularProgress color="inherit" />
              </Box>
            </Grid>
          ) : (
            <Grid item xs={12}>
              {manifest && (
                <ManifestDisplayWithTemplateDataGathering
                  hideReceipt={true}
                  manifestId={manifest.id}
                  onSucessfullDisplayCallback={setIsManifestSuccessfullyDisplayed}
                  onManifestUpdateReceivedCallback={setManifest}
                />
              )}
            </Grid>
          )}

          {isManifestSuccessfullyDisplayed && (
            <>
              {isSignVisible ? (
                <>
                  {manifest && project && project.scales && project.scales.length > 1 && (
                    <Grid item xs={12}>
                      <Autocomplete
                        options={project.scales}
                        disabled={!!manifest.signatureDriver.scale}
                        value={manifest.signatureDriver.scale || selectedScale}
                        getOptionSelected={(option, value) =>
                          value ? option.id === value.id : false
                        }
                        getOptionLabel={option => option.name}
                        onChange={(event, value) =>
                          setSelectedScale({
                            ...value,
                            selectedLaneId: null,
                          })
                        }
                        renderInput={params => (
                          <TextField
                            {...params}
                            variant="outlined"
                            required
                            label="Scale"
                            placeholder="Select scale"
                            error={hasErrorRequired(selectedScale)}
                            InputProps={{
                              ...params.InputProps,
                              endAdornment: <>{params.InputProps.endAdornment}</>,
                            }}
                          />
                        )}
                      />
                    </Grid>
                  )}
                  <Grid item xs={12}>
                    <Box display="flex" justifyContent="flex-end" alignItems="center">
                      <Button
                        onClick={handleClickSign}
                        variant="contained"
                        color="primary"
                        disabled={isSignDisabled()}
                        fullWidth
                      >
                        <Badge
                          color="secondary"
                          overlap="rectangular"
                          badgeContent={seccondsLeftToSign}
                        >
                          {isSigning ? (
                            <CircularProgress color="inherit" />
                          ) : (
                            <BorderColorIcon fontSize="large" />
                          )}
                          Sign
                        </Badge>
                      </Button>
                    </Box>
                  </Grid>
                </>
              ) : (
                <>
                  <Grid item xs={12}>
                    <Box display="flex" justifyContent="flex-end" alignItems="center">
                      <Button onClick={goToScaleLane} variant="contained" color="primary" fullWidth>
                        <Grid container spacing={3}>
                          <Grid item xs={2}>
                            <Box
                              display="flex"
                              flexDirection="column"
                              justifyContent="center"
                              style={{ height: '100%' }}
                            >
                              <TruckFrontWhite />
                            </Box>
                          </Grid>
                          <Grid item xs={10} display="flex" flexDirection="column">
                            <Typography variant="button">On the Scale</Typography>
                            <Typography variant="button">En la Escala</Typography>
                          </Grid>
                        </Grid>
                      </Button>
                    </Box>
                  </Grid>
                  <Grid item xs={12}>
                    <Box display="flex" justifyContent="flex-end">
                      {manifest && (
                        <Button
                          size="large"
                          variant="outlined"
                          color="secondary"
                          fullWidth
                          onClick={handleClickNotes}
                        >
                          <Typography variant="h4">
                            {manifest.notesCount} Notes
                            {customInputsCanEditCount + customInputsReadOnlyCount
                              ? `, ${customInputsCanEditCount + customInputsReadOnlyCount} C.I.`
                              : null}
                          </Typography>
                        </Button>
                      )}
                    </Box>
                  </Grid>
                </>
              )}
            </>
          )}
        </Grid>
        {manifest && (
          <Notes
            manifest={manifest}
            isNotesOpen={isNotesOpen}
            handleCloseNotes={handleCloseNotes}
          />
        )}
      </Container>
    </Page>
  );
};

export default CurrentManifestView;
