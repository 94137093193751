import React from 'react';
import { RelatedCompanies } from '../../sharedComponents/RelatedCompanies';
import { Routes, Route } from 'react-router-dom';
import { ConnectedCompanyType } from 'services/api/autogenerated-code';

export const ContractorViews = () => {
  return (
    <Routes>
      <Route
        path="/"
        element={
          <RelatedCompanies
            globalCompanyType={ConnectedCompanyType.Contractor}
            localCompanyType={ConnectedCompanyType.TruckingContractor}
            companyTypeName={'Contractor'}
            companyTypeNamePlural={'Contractors'}
          />
        }
      />
    </Routes>
  );
};
