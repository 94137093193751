import React from 'react';

import { useSwitchUserRoleRender } from 'services/permissions';
import ProjectManagerDashboard from './ProjectManagerDashboard';
import AccountView from 'sharedComponents/AccountView';
// import AccountingDashboard from './AccountingDashboard';

export const HomeView: React.FC = () => {
  const renderedComponent = useSwitchUserRoleRender({
    default: () => <ProjectManagerDashboard />,
    super_admin: () => <ProjectManagerDashboard />,
    civil_site_user: () => <AccountView />,
  });

  return renderedComponent;
};

export default HomeView;
