import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { DataGrid } from '@mui/x-data-grid';
import { selectDivisions } from '../../../redux/divisionsStoreSlice';
import { ITruckingTruckDivision } from 'lg-helpers/dist/trucking/interfaces/ITruckingTruckDivision';
import { LoadingOverlay as Loading } from 'sharedComponents/LoadingOverlay';
import { generateDivisionsColumns } from './divisionsColumns';

type DivisionsListProps = {
  showModal: (truckType: ITruckingTruckDivision) => void;
};

export const DivisionsList = ({ showModal }: DivisionsListProps) => {
  const { divisions, divisionsStatus } = useSelector(selectDivisions);

  const columns = useMemo(() => generateDivisionsColumns(showModal), [showModal]);

  if (divisionsStatus === 'pending') {
    return <Loading />;
  }

  return (
    <DataGrid
      columns={columns}
      rows={divisions || []}
      getRowId={row => row.idTruckingTruckDivision}
      showColumnRightBorder={false}
      disableColumnMenu
      autoHeight
      disableSelectionOnClick
      components={{
        ColumnResizeIcon: () => <div></div>,
      }}
    />
  );
};
