import React from 'react';
import { createSvgIcon } from '@mui/material';

export const MaterialSource = createSvgIcon(
  <path
    fillRule="evenodd"
    clipRule="evenodd"
    d="M20.6844 23.706h-.0204c-.2515 0-.4995.0239-.7408.0683-.0578.0103-.1155.0137-.1699.0137-.4349 0-.8223-.2937-.9345-.7307-.4723-1.878-2.1646-3.2677-4.1797-3.2677-2.0151 0-3.7107 1.3897-4.1831 3.2711-.0985.3995-.4519.6692-.85629.6692-.03058 0-.05777 0-.08835-.0034a4.52898 4.52898 0 0 0-.43156-.0205h-.10874c-1.93014.0478-3.55105 1.3863-4.04717 3.179l-1.78402-.0444C1.40682 26.8406 0 28.2542 0 29.999h24.5345c.3058-.6044.4757-1.2907.4655-2.018-.034-2.3594-1.9675-4.275-4.3156-4.275ZM6.48363 4.67008l1.88256-.93558 5.23651 10.6499-4.11513 2.0419 1.44423 2.9399c.5165-.4063 1.0874-.7239 1.7058-.9424.6423-.2288 1.3185-.3449 2.0049-.3449.6865 0 1.3593.1161 2.0049.3449.6219.2219 1.1996.5395 1.7195.9526.8631.6863 1.5291 1.6049 1.9097 2.6326.1292-.0102.2583-.0136.384-.0136h.0272c.768 0 1.519.1468 2.2292.4336.4077-1.6697.248-3.5647-.4724-5.0262l-2.9292-5.9549-4.1151 2.0419-5.2365-10.64649 1.8792-.93217c.4961-.24926.7-.85363.4553-1.35215-.2447-.49852-.8461-.70339-1.3422-.45754L5.59672 2.8638c-.49613.24584-.70002.85021-.45535 1.34873.24466.49852.84613.7034 1.34226.45755Z"
  />,
  'Source Material'
);
