import React from 'react';
import PropTypes from 'prop-types';
import truckFrontWhite from './png/truck-front-white.png';

const TruckFrontWhite = ({ className }) => (
  <img className={className} alt="truck-front" src={truckFrontWhite} style={{ width: 30 }} />
);

TruckFrontWhite.propTypes = {
  className: PropTypes.string,
};

export default TruckFrontWhite;
