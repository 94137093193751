const getPropertyValueByPath = (object, path) => {
  if (!path) {
    return null;
  }
  return path.split('.').reduce((accumulator, currentValue) => {
    return accumulator ? accumulator[currentValue] : null;
  }, object);
};

const getObjectWithSortedProperties = obj => {
  const objWithPropertiesSorted = {},
    properties = [];

  for (let key in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, key)) {
      properties.push(key);
    }
  }

  properties.sort((a, b) => {
    const aLower = a.toLocaleLowerCase();
    const bLower = b.toLocaleLowerCase();
    if (aLower === bLower) {
      return 0;
    }

    if (aLower > bLower) {
      return 1;
    } else {
      return -1;
    }
  });

  for (let key = 0; key < properties.length; key++) {
    objWithPropertiesSorted[properties[key]] = obj[properties[key]];
  }
  return objWithPropertiesSorted;
};

export { getPropertyValueByPath, getObjectWithSortedProperties };
