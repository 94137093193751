import React from 'react';
import { Box, CircularProgress, Fade } from '@mui/material';

type LoadingProps = {
  height?: number;
};

export const LoadingOverlay = ({ height = 400 }: LoadingProps) => { // eslint-disable-line
  return (
    <Fade appear={false} in={true} unmountOnExit>
      <Box
        sx={{
          position: 'absolute',
          top: 0,
          bottom: 0,
          left: 0,
          right: 0,
          backgroundColor: 'transparent',
          width: '100%',
          height: '100%',
          justifyContent: 'center',
          alignItems: 'center',
          display: 'flex',
        }}
      >
        <Box
          sx={{
            backgroundColor: '#222',
            opacity: 0.3,
            width: '100%',
            height: '100%',
            position: 'absolute',
          }}
        />
        <CircularProgress />
      </Box>
    </Fade>
  );
};
