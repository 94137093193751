import React from 'react';
import './Lane.scss';
import PropTypes from 'prop-types';
import {
  Card,
  CardContent,
  FormControl,
  InputLabel,
  Select,
  TextField,
  MenuItem,
} from '@mui/material';
import { updateLaneForEdit } from 'modules/scales/storeSliceScales';
import { useDispatch } from 'react-redux';

const LanesDetails = ({ lane }) => {
  const dispatch = useDispatch();

  const handleCompleteChangeColor = color => {
    dispatch(
      updateLaneForEdit({
        ...lane,
        color: color.target.value,
      })
    );
  };

  const handleCompleteChangeType = type => {
    dispatch(
      updateLaneForEdit({
        ...lane,
        type: type.target.value,
        color: 'black',
      })
    );
  };

  const handleLabelChange = text => {
    dispatch(
      updateLaneForEdit({
        ...lane,
        label: text.target.value,
      })
    );
  };

  return (
    <Card style={{ flex: 1, textAlign: 'center' }}>
      <CardContent className="LaneInput__wrapper">
        <FormControl className="LaneInput__form-control">
          <InputLabel>Item Type:</InputLabel>
          <Select value={lane.type} onChange={handleCompleteChangeType}>
            <MenuItem value={'lane'}>Lane</MenuItem>
            <MenuItem value={'scale'}>Scale</MenuItem>
          </Select>
        </FormControl>

        {lane.type === 'lane' ? (
          <FormControl className="LaneInput__form-control">
            <InputLabel>Lane Color:</InputLabel>
            <Select value={lane.color} onChange={handleCompleteChangeColor}>
              <MenuItem value={'black'}>Black</MenuItem>
              <MenuItem value={'blue'}>Blue</MenuItem>
              <MenuItem value={'green'}>Green</MenuItem>
              <MenuItem value={'orange'}>Orange</MenuItem>
              <MenuItem value={'red'}>Red</MenuItem>
            </Select>
          </FormControl>
        ) : undefined}

        {lane.type === 'lane' ? (
          <img
            className="LaneInput__image"
            src={`/static/images/lanes/lane-${lane.color}.png`}
            alt={`Lane ${lane.id}`}
          />
        ) : (
          <img className="LaneInput__image" src={`/static/images/lanes/scale.png`} alt={'Scale'} />
        )}

        {lane.type === 'lane' ? (
          <TextField
            id={`input-${lane.id}`}
            variant="outlined"
            label="Lane Number"
            value={lane.label}
            className="LaneInput__field--text"
            onChange={handleLabelChange}
          />
        ) : (
          <h3>Scale</h3>
        )}
      </CardContent>
    </Card>
  );
};

LanesDetails.propTypes = {
  className: PropTypes.string,
  lane: PropTypes.object,
};

export default LanesDetails;
