import React, { useState, useEffect } from 'react';

import { Typography, Grid, Box, useTheme, useMediaQuery, Button } from '@mui/material';
import { CircularProgressWithLabel } from 'sharedComponents/CircularProgressWithLabel';
import { CircularProgress } from '@mui/material';
import { useDate } from 'utils/useDate';
import { useSelector } from 'react-redux';
import { RootState } from 'store/store';
import * as R from 'ramda';
import { ITicketSummary } from 'lg-helpers/dist/shared/interfaces/ITicketSummary';

const getSum = (prop: string, data: ITicketSummary[]) => {
  return R.compose(R.sum, R.map(R.prop(prop)))(data);
};

interface IScoreStatus {
  low: number;
  high: number;
  value: string;
  name: string;
}

const scoreStatuses = [
  {
    low: -1,
    high: 0,
    value: '#333',
    name: 'Not started',
  },
  {
    low: 0,
    high: 70,
    value: '#C61C1C',
    name: 'Behind schedule',
  },
  {
    low: 70,
    high: 80,
    value: '#C61C1C',
    name: 'Behind schedule',
  },
  {
    low: 80,
    high: 90,
    value: '#BE9400',
    name: 'Behind schedule',
  },
  {
    low: 90,
    high: 100,
    value: '#BE9400',
    name: 'Behind schedule',
  },
  {
    low: 100,
    high: 110,
    value: '#97C06B',
    name: 'On time',
  },
  {
    low: 110,
    high: 1000,
    value: '#97C06B',
    name: 'On time',
  },
];

interface IDayAtAGlanceProps {
  selectedProjectId?: number | string;
  clearSelectedProject?: () => void;
}

const DayAtAGlance = ({ selectedProjectId, clearSelectedProject }: IDayAtAGlanceProps) => {
  const { time } = useDate();
  const [timeRemainingPercent, setTimeRemainingPercent] = useState(100);
  const [overallStatus, setOverallStatus] = useState<IScoreStatus>();
  const [hasActiveTickets, setHasActiveTickets] = useState<boolean>();
  const [percentCompleted, setPercentCompleted] = useState<number>();
  const theme = useTheme();
  const isWidthXSorSM = useMediaQuery(theme.breakpoints.down('md'));
  const [selectedProjectName, setSelectedProjectName] = useState<string>();
  const [numericTime, ampm] = time.split(' ');
  const ticketSummaryData: ITicketSummary[] = useSelector(
    (state: RootState) => state.civil.tickets.summary
  );
  const getStatusForScore = (score: number, percentRemaining: number) =>
    (score || percentRemaining < 100) && !isNaN(score)
      ? scoreStatuses.find(status => status.low <= score && score < status.high)
      : scoreStatuses[0];

  useEffect(() => {
    if (ticketSummaryData.length > 0) {
      const activeTicketSummaryData = ticketSummaryData.filter(summary => summary.ticketCount > 0);
      const activeTickets = selectedProjectId
        ? activeTicketSummaryData.filter(summary => summary.projectId === selectedProjectId)
        : activeTicketSummaryData;
      const totalWorkTimeRemaining = getSum('timeRemaining', activeTickets);
      const totalWorkTime = getSum('duration', activeTickets);
      const percentRemaining = totalWorkTime ? (totalWorkTimeRemaining / totalWorkTime) * 100 : 0;
      const totalLoadCompleted = getSum('loadCompleted', activeTickets);
      const totalGoal = getSum('target', activeTickets);
      const newScore = Math.min(
        (((Number(totalLoadCompleted) / Number(totalGoal)) * 100) / (100 - percentRemaining)) * 100,
        100
      );
      if (selectedProjectId) {
        const { projectName, percentCompleted: percent } = ticketSummaryData.find(
          summary => summary.projectId === selectedProjectId
        ) as ITicketSummary;
        setSelectedProjectName(projectName);
        setPercentCompleted(percent);
      } else {
        const totalPercentCompleted =
          Math.round(Number(totalLoadCompleted) * 100) / Number(totalGoal);
        setPercentCompleted(totalPercentCompleted);
      }
      setHasActiveTickets(activeTickets?.length > 0);
      setTimeRemainingPercent(percentRemaining > 0 ? percentRemaining : 100);
      setOverallStatus(getStatusForScore(newScore, percentRemaining));
    }
  }, [ticketSummaryData, selectedProjectId]);

  const handleClearSelectedClick: React.MouseEventHandler = () =>
    !!clearSelectedProject && clearSelectedProject();
  return (
    <Grid
      container
      spacing={{ xs: 0, md: 6 }}
      minWidth={300}
      width="100%"
      display="flex"
      flexDirection={isWidthXSorSM ? 'column' : 'row'}
      alignItems="center"
      justifyContent="space-evenly"
    >
      <Grid
        item
        xs={12}
        marginBottom="1em"
        marginTop={isWidthXSorSM ? '1em' : '0em'}
        display="flex"
        justifyContent={isWidthXSorSM ? 'flex-end' : 'flex-start'}
      >
        <Typography variant="h4">{numericTime}</Typography>
        <Typography variant="h4" sx={{ marginLeft: '0.4rem', color: 'neutral.light' }}>
          {ampm}
        </Typography>
      </Grid>
      <Grid item xs={12} padding={0}>
        <Box width="100%" height={30}>
          {selectedProjectId ? (
            <>
              <Typography variant="button" noWrap>
                {selectedProjectName}
              </Typography>
              <Box width="100%" height={30}>
                <Typography variant="subtitle1">
                  {hasActiveTickets && `${percentCompleted || 0}% of target completed`}
                </Typography>
              </Box>
              <Box width="100%" height={40}>
                <Button variant="text" onClick={handleClearSelectedClick} sx={{ padding: 0 }}>
                  Clear Selection
                </Button>
              </Box>
            </>
          ) : (
            <Typography variant="button">All projects</Typography>
          )}
        </Box>
      </Grid>
      <Grid
        item
        xs={5}
        display="flex"
        justifyContent="center"
        alignItems="center"
        flexDirection="column"
        width="100%"
        flex={1}
        paddingBottom="1em"
        minWidth={150}
      >
        <Box textAlign="center" width="100%">
          <Typography variant="overline" width="100%">
            {`${
              hasActiveTickets && timeRemainingPercent >= 100
                ? 100
                : Math.round(100 - timeRemainingPercent)
            }% Work Day Passed`}
          </Typography>
        </Box>
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          flexDirection="column"
          flex={1}
          paddingBottom="1em"
          minWidth={150}
        >
          <Box width="100%" alignItems="center" justifyContent="center" display="flex">
            <CircularProgress
              variant="determinate"
              value={hasActiveTickets ? Math.min(Number(percentCompleted), 100) : 0}
              thickness={1.5}
              sx={{
                color: overallStatus?.value,
                position: 'absolute',
                minWidth: 150,
                minHeight: 150,
                zIndex: 1,
              }}
            />
            <CircularProgressWithLabel
              color="info"
              variant="determinate"
              value={
                hasActiveTickets && timeRemainingPercent >= 100
                  ? 100
                  : Math.round(100 - timeRemainingPercent)
              }
            />
          </Box>
        </Box>
      </Grid>

      <Grid item xs={7} width="100%" display="flex" flexDirection="column" justifyContent="center">
        <Box width="100%" textAlign="center">
          <Typography variant="overline">Work Day Status</Typography>
        </Box>
        <Box
          display="flex"
          width="100%"
          justifyContent="center"
          alignItems="center"
          flexDirection="column"
          flex={1}
          padding="1em"
          minWidth={150}
        >
          <Box
            padding="1em"
            textAlign="center"
            boxShadow="0px 4px 5px rgba(0, 0, 0, 0.14)"
            alignItems="center"
            justifyContent="center"
            color="#fff"
            borderRadius={4}
            minWidth={200}
            style={{
              color: overallStatus?.value,
            }}
          >
            <Typography variant="h6">
              {hasActiveTickets ? overallStatus?.name : 'No tickets'}
            </Typography>
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
};

export default DayAtAGlance;
