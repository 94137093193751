import React from 'react';
import PropTypes from 'prop-types';
import truckLoadedSmall from './png/truck-loaded-small.png';

const TruckLoadedSmall = ({ className }) => (
  <img className={className} alt="truck-loaded" src={truckLoadedSmall} />
);

TruckLoadedSmall.propTypes = {
  className: PropTypes.string,
};

export default TruckLoadedSmall;
