import { ITicket } from 'lg-helpers/dist/shared/interfaces/ITicket';
import { IInvoice } from 'lg-helpers/dist/shared/interfaces/IInvoice';
import {
  GridCsvExportOptions,
  GridCsvGetRowsToExportParams,
  GridRowId,
  GridRowModel,
  GridToolbarContainer,
  useGridApiContext,
} from '@mui/x-data-grid';
import React, { useEffect, useState } from 'react';
import { Button, Grid, SvgIcon, Typography, useMediaQuery, useTheme } from '@mui/material';
import { useSelector } from 'react-redux';
import { selectAuthUser } from '../../../../auth/storeSliceAuth';
import { thunkDispatch } from '../../../../../store/store';
import { downloadTickets } from '../../Tickets/redux/actions';
import moment from 'moment';
import { Download, PrintOutlined } from '@mui/icons-material';

interface IToolbarProps {
  tickets?: ITicket[];
  invoice?: IInvoice;
  isLoading?: boolean;
  projectId?: number;
}

export const CustomToolbar = ({ tickets, invoice, projectId }: IToolbarProps) => {
  const apiRef = useGridApiContext();
  const [totalInvoiceQuantity, setTotalInvoiceQuantity] = useState<number>();
  const [totalPrice, setTotalPrice] = useState<number>();
  const [totalIssuePrice, setTotalIssuePrice] = useState<number>();

  const theme = useTheme();
  const isWidthXSorSM = useMediaQuery(theme.breakpoints.down('md'));

  const authUser = useSelector(selectAuthUser);

  useEffect(() => {
    setTotalPrice(
      tickets
        ?.filter(item =>
          invoice?.isTransporter
            ? item.transporterInvoiceId === invoice.id
            : item.sourceSupplierInvoiceId === invoice?.id
        )
        .reduce((a, b) => a + Number(b.totalPrice), 0)
    );
    setTotalInvoiceQuantity(
      tickets
        ?.filter(item =>
          invoice?.isTransporter
            ? item.transporterInvoiceId === invoice.id
            : item.sourceSupplierInvoiceId === invoice?.id
        )
        .reduce((a, b) => a + Number(b.quantity), 0)
    );
    setTotalIssuePrice(
      tickets
        ?.filter(item =>
          invoice?.isTransporter
            ? item.transporterInvoiceStatus === 'issue'
            : item.supplierInvoiceStatus === 'issue'
        )
        .reduce((a, b) => a + Number(b.totalPrice), 0)
    );
  }, [tickets, invoice]);

  const getAllTickets = ({ apiRef: api }: GridCsvGetRowsToExportParams) => {
    if (tickets && invoice) {
      const data = tickets
        .filter(
          a => a.transporterInvoiceId === invoice.id || a.sourceSupplierInvoiceId === invoice.id
        )
        .map(b => b.id);
      return data as GridRowId[];
    }
    if (tickets && !invoice) {
      const data = api.current.getAllRowIds();
      return data as GridRowId[];
    }
    return [] as GridRowId[];
  };

  const handleExport = (options: GridCsvExportOptions) => apiRef.current.exportDataAsCsv(options);

  const handleBatchPrint = async (data: GridRowModel) => {
    // eslint-disable-line
    const ticketIds = tickets?.map(a => a.id!);
    await thunkDispatch(
      downloadTickets({
        ids: ticketIds,
        userId: authUser.id,
        projectId: projectId,
      })
    );
  };

  return (
    <GridToolbarContainer>
      {tickets && tickets.length && (
        <Grid container alignItems="center" width="100%">
          <Grid item xs={6} md={2}>
            <Button
              onClick={() =>
                handleExport({
                  allColumns: true,
                  getRowsToExport: getAllTickets,
                  fileName: invoice
                    ? `Invoice${invoice?.number}`
                    : `Tickets${moment(new Date()).format('MM-DD-YYYY hh:mm a')}`,
                })
              }
            >
              <Typography sx={{ color: 'neutral.main' }}>Export Tickets</Typography>
              <SvgIcon sx={{ marginLeft: 1, fontSize: 24, color: 'neutral.main' }}>
                <Download />
              </SvgIcon>
            </Button>
          </Grid>
          <Grid item xs={6} md={2}>
            <Button onClick={() => handleBatchPrint(getAllTickets)}>
              <Typography sx={{ color: 'neutral.main' }}>Print Tickets</Typography>
              <SvgIcon sx={{ marginLeft: 1, frontSize: 24, color: 'neutral.main' }}>
                <PrintOutlined />
              </SvgIcon>
            </Button>
          </Grid>
          {invoice && (
            <>
              <Grid
                item
                xs={12}
                md={4}
                display="flex"
                justifyContent={isWidthXSorSM ? 'flex-start' : 'flex-end'}
              >
                <Typography sx={{ color: 'neutral.main', fontSize: 14 }}>
                  Invoice Quantity: {totalInvoiceQuantity}
                </Typography>
              </Grid>
              <Grid
                item
                xs={12}
                md={4}
                display="flex"
                justifyContent={isWidthXSorSM ? 'flex-start' : 'flex-end'}
                paddingRight={10}
              >
                <Typography sx={{ color: 'neutral.main', fontSize: 14 }}>
                  Invoice Price: $ {totalPrice?.toFixed(2)}
                </Typography>
                <Typography color="error" sx={{ fontSize: 14, marginLeft: 1 }}>
                  ($ {totalIssuePrice?.toFixed(2)})
                </Typography>
              </Grid>
            </>
          )}
        </Grid>
      )}
    </GridToolbarContainer>
  );
};
