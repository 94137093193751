import React, { useContext, useEffect, useState } from 'react';
import { Button, DialogContent, Grid, TextField } from '@mui/material';
import ModalHeader from 'sharedComponents/DialogHeader';
import { GridRowModel } from '@mui/x-data-grid';
import { rejectTicket } from './redux/actions';
import { thunkDispatch } from '../../../../store/store';
import { ModalContext } from 'sharedComponents/ModalContext';

interface ModalContainerProps {
  ticketId?: string;
  handleAccept: Function;
  acceptParams: GridRowModel;
}

const ModalContainer = ({ ticketId, handleAccept, acceptParams }: ModalContainerProps) => {
  const [isToBeRejected, setIsToBeRejected] = useState<boolean>(false);
  const [modalTitle, setModalTitle] = useState<string>(`Processing Ticket # ${ticketId}`);

  useEffect(() => {
    setModalTitle(isToBeRejected ? 'Rejection Notes' : `Processing Ticket # ${ticketId}`);
  }, [isToBeRejected, ticketId]);

  return (
    <>
      <ModalHeader title={modalTitle} />
      <DialogContent>
        {isToBeRejected ? (
          <RejectionNotes ticketId={ticketId!} setIsToBeRejected={setIsToBeRejected} />
        ) : (
          <AcceptOrRejectButtons
            handleAccept={handleAccept}
            acceptParams={acceptParams}
            setIsToBeRejected={setIsToBeRejected}
          />
        )}
      </DialogContent>
    </>
  );
};

const RejectionNotes = ({
  ticketId,
  setIsToBeRejected,
}: {
  ticketId: string;
  setIsToBeRejected: (reject: boolean) => void;
}) => {
  const [message, setMessage] = useState<string>('');
  const { handleModal } = useContext(ModalContext);

  const handleRejection = async () => {
    await thunkDispatch(rejectTicket({ ticketId: Number(ticketId), message }));
    handleModal();
  };

  return (
    <Grid
      container
      spacing={1}
      direction="row"
      justifyContent="flex-start"
      alignItems="flex-start"
      alignContent="stretch"
      wrap="wrap"
    >
      <Grid item xs={12}>
        <TextField
          name="note"
          value={message}
          onChange={event => setMessage(event.target.value)}
          variant="outlined"
          fullWidth
          multiline
          rows={5}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <Button fullWidth color="error" variant="contained" onClick={handleRejection}>
          Reject
        </Button>
      </Grid>
      <Grid item xs={12} md={6}>
        <Button fullWidth variant="contained" onClick={() => setIsToBeRejected(false)}>
          Cancel
        </Button>
      </Grid>
    </Grid>
  );
};

interface AcceptOrRejectButtonsProps {
  handleAccept: Function;
  acceptParams: GridRowModel;
  setIsToBeRejected: (reject: boolean) => void;
}

const AcceptOrRejectButtons = ({
  handleAccept,
  acceptParams,
  setIsToBeRejected,
}: AcceptOrRejectButtonsProps) => (
  <Grid
    container
    spacing={1}
    direction="row"
    justifyContent="flex-start"
    alignItems="flex-start"
    alignContent="stretch"
    wrap="wrap"
  >
    <Grid item xs={12} md={6}>
      <Button
        fullWidth
        color="primary"
        variant="contained"
        onClick={() => handleAccept(acceptParams)}
      >
        Accept
      </Button>
    </Grid>
    <Grid item xs={12} md={6}>
      <Button fullWidth color="error" variant="contained" onClick={() => setIsToBeRejected(true)}>
        Reject
      </Button>
    </Grid>
  </Grid>
);

export default ModalContainer;
