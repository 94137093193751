import { unitsOfMeasure } from 'lg-helpers/dist/constants/global-constants';
import { GridColumnVisibilityModel } from '@mui/x-data-grid';
import { ITruckingTruckType } from 'lg-helpers/dist/trucking/interfaces/ITruckingTruckType';
import { IJoinTruck } from 'lg-helpers/dist/trucking/interfaces/IJoinTruck';
import { IFlattenedCapacities } from 'lg-helpers/dist/shared/interfaces/IFlattenedCapabilities';

type TruckTypeFlattenedCapacities = ITruckingTruckType & Partial<IFlattenedCapacities>;
type TruckFlattenedCapacities = IJoinTruck & Partial<IFlattenedCapacities>;

export const getColumnVisibilitiesByCapacities = (
  objArray: TruckTypeFlattenedCapacities[] | TruckFlattenedCapacities[]
) => {
  const columnsRemoveVisibility: GridColumnVisibilityModel = {};

  unitsOfMeasure?.forEach(unit => {
    columnsRemoveVisibility[unit] = objArray.some(obj => obj[unit]);
  });

  return columnsRemoveVisibility;
};
