import React from 'react';
import { createSvgIcon } from '@mui/material';

export const PaperMoney = createSvgIcon(
  <path
    fillRule="evenodd"
    clipRule="evenodd"
    d="M0 1.5c0-.55229.44771-1 1-1h20c.5523 0 1 .44771 1 1v12c0 .5523-.4477 1-1 1H1c-.55229 0-1-.4477-1-1v-12Zm2 4.89998v2.20004C3.95913 8.9977 5.5023 10.5409 5.89998 12.5H16.1c.3977-1.9591 1.9409-3.5023 3.9-3.89998V6.39998C18.0409 6.0023 16.4977 4.45914 16.1 2.5H5.89998C5.5023 4.45914 3.95913 6.0023 2 6.39998ZM3.8293 2.5H2v1.8293c.85242-.30129 1.52801-.97688 1.8293-1.8293Zm14.3414 0c.3013.85242.9769 1.52801 1.8293 1.8293V2.5h-1.8293ZM20 10.6707c-.8524.3013-1.528.9769-1.8293 1.8293H20v-1.8293ZM3.8293 12.5c-.30129-.8524-.97688-1.528-1.8293-1.8293V12.5h1.8293ZM7.5 7.5c0-2.03068 1.40405-4 3.5-4 2.096 0 3.5 1.96932 3.5 4 0 2.03068-1.404 4-3.5 4-2.09595 0-3.5-1.96932-3.5-4Zm3.5-2c-.6654 0-1.5.71698-1.5 2s.8346 2 1.5 2 1.5-.71698 1.5-2-.8346-2-1.5-2Z"
  />,
  'PaperMoney'
);
