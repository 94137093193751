import React from 'react';
import { Container, Grid } from '@mui/material';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import Page from 'sharedComponents/Page';
import { useParams, useNavigate } from 'react-router-dom';
import ButtonWithLoading from 'sharedComponents/ButtonWithLoading';
import LoadDisplayWithTemplateDataGathering from './LoadDisplayWithTemplateDataGathering';

const LoadView = () => {
  let { id } = useParams();
  const navigate = useNavigate();

  const handleClickBack = () => {
    navigate(-1);
  };

  return (
    <Page title="Load">
      <Container>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <ButtonWithLoading
              Pictogram={NavigateBeforeIcon}
              onClickCallback={handleClickBack}
              isLarge={true}
              notFullWith={true}
              variant="outlined"
              size="large"
              text="Back"
            />
          </Grid>
          <Grid item xs={12}>
            <LoadDisplayWithTemplateDataGathering loadId={id} />
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
};

export default LoadView;
