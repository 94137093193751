// TODO: drop this component in future
import React, { useContext, useState, Key, useMemo, useEffect, HTMLAttributes } from 'react';
import { Grid, TextField, Typography, SvgIcon, Button } from '@mui/material';

import { getCompanyByType } from 'modules/civilAndTrucking/civil/Companies/redux/actions';
import { IJoinCompany } from 'lg-helpers/dist/shared/interfaces/IJoinCompany';
import { RootState, thunkDispatch } from 'store/store';
import { ModalContext } from 'sharedComponents/ModalContext';
import { Autocomplete, FilterOptionsState } from '@mui/material';
import { useSelector } from 'react-redux';
import { debounce } from 'lodash';
import { Search } from '@mui/icons-material';
import { AddCompanyWithoutUserForm } from 'modules/civilAndTrucking/trucking/sharedComponents/AddCompanyWithoutUser';
import api, {
  CompanyConnectionWithCompany,
  ConnectedCompanyType,
  UserTypeGenerated,
} from 'services/api/autogenerated';
import { usePermissionsContext } from 'services/permissions';

export type PartialCompany = { name: string; types: UserTypeGenerated[] };

interface AddSourceFormProps {
  selected?: PartialCompany;
  setSource?: (source: CompanyConnectionWithCompany) => any;
}

const SearchSources = ({ selected, setSource }: AddSourceFormProps) => {
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const { user } = usePermissionsContext();
  const { handleModal } = useContext(ModalContext);
  const [inputValue, setInputValue] = useState<string>('');
  const [value, setValue] = useState<PartialCompany | undefined>(selected || undefined);
  const [optionsLoading, setOptionsLoading] = React.useState<boolean>(false);
  const [companies, setCompanies] = useState<IJoinCompany[]>([]);
  const [resetAutoComplete, setResetAutocomplete] = useState<Key>(1);

  const companyState = useSelector((state: RootState) => state.civil.companies.companyOptions);

  const handleAddSource = async () => {
    setIsSaving(true);
    const addedSource = !companies.some(option => option.name === inputValue);
    if (addedSource) {
      handleModal(
        <AddCompanyWithoutUserForm
          initialValues={{ connected_company: { name: inputValue!, types: ['scale'] } }}
          onAfterSave={setSource}
          companyType={ConnectedCompanyType.SourceSupplier}
        />,
        true
      );
    } else {
      const source = companies.find(option => option.name === inputValue);
      const { data: addedConnection } = await api.companiesConnections.createCompanyConnection({
        connected_company_id: source?.id!,
        parent_company_id: user.companiesIds[0],
        company_type: ConnectedCompanyType.SourceSupplier,
        custom_id: '',
      });
      if (addedConnection && setSource) {
        setSource(addedConnection);
        handleModal();
      }
    }
    setIsSaving(false);
    setInputValue('');
    setValue(undefined);
    setCompanies([]);
    setResetAutocomplete(new Date().toISOString());
  };

  useEffect(() => {
    setCompanies(
      companyState.map(company => ({
        name: company.name,
        email: company.email,
        address: company.address,
        sourceSupplierId: company.id,
        phone: company.phone,
        city: company.city,
        state: company.state,
        types: company.types,
        id: company.id,
      }))
    );
  }, [companyState]);

  const updateOptions = React.useMemo(
    () =>
      debounce(async (request: { input: string }) => {
        await thunkDispatch(getCompanyByType({ path: 'scale', query: request.input }));
        setOptionsLoading(false);
      }, 500),
    []
  );

  const filterOptions = (
    options: IJoinCompany[],
    state: FilterOptionsState<IJoinCompany> // eslint-disable-line
  ) => {
    return options.filter(option => option);
  };

  useMemo(() => {
    if (!inputValue || inputValue.length < 3) {
      setCompanies([]);
    }
    if (inputValue && inputValue.length >= 3) {
      setOptionsLoading(true);
      updateOptions({ input: inputValue });
    }
    // eslint-disable-next-line
  }, [inputValue]);

  return (
    <>
      <Grid container spacing={2} alignItems="center">
        <Grid item xs={10}>
          <Autocomplete
            key={resetAutoComplete}
            size="small"
            freeSolo
            value={value || ''}
            inputValue={inputValue || ''}
            loading={optionsLoading}
            loadingText="Loading..."
            options={companies}
            getOptionLabel={option => (typeof option === 'string' ? option : option.name)}
            filterOptions={filterOptions}
            onInputChange={(event, newValue) => setInputValue(newValue)}
            onChange={(event, val) => {
              const newValue = val as IJoinCompany;
              setValue(newValue);
            }}
            renderInput={params => (
              <TextField
                {...params}
                placeholder="Search Sources"
                variant="outlined"
                InputProps={{
                  ...params.InputProps,
                  startAdornment: (
                    <SvgIcon>
                      <Search />
                    </SvgIcon>
                  ),
                }}
              />
            )}
            renderOption={(props: HTMLAttributes<HTMLLIElement>, option: IJoinCompany) => (
              <li {...props}>
                <Grid container alignItems="center" justifyContent="space-between" wrap="nowrap">
                  <Grid item xs={6}>
                    <Typography noWrap>{option.name}</Typography>
                  </Grid>
                  <Grid
                    item
                    xs={6}
                    display="flex"
                    alignSelf="center"
                    alignItems="center"
                    justifyContent="flex-end"
                  >
                    <Typography variant="caption" align="center" noWrap>
                      {option.city}, {option.state}
                    </Typography>
                  </Grid>
                </Grid>
              </li>
            )}
          />
        </Grid>
        <Grid item xs={2}>
          <Button
            onClick={handleAddSource}
            disabled={isSaving}
            color="primary"
            variant="contained"
            sx={{
              minWidth: 150,
              width: '100%',
              maxWidth: 300,
              maxHeight: 50,
              alignSelf: 'center',
            }}
          >
            Add Source
          </Button>
        </Grid>
      </Grid>
    </>
  );
};

export default SearchSources;
