import React from 'react';
import { Badge, Box, IconButton } from '@mui/material';
import UserDropDown from './UserDropDown';
import { ButtonUserTypeSwitcher } from 'sharedComponents/ButtonUserTypeSwitcherIcon';
import { ISizedTopBarProps } from '.';
import { NotificationsNoneOutlined } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import { selectNotifications } from 'sharedComponents/notificationStoreSlice';
import { useSelector } from 'react-redux';

const DesktopTopBar = ({
  isSwitcherVisible,
  settingsPath,
  handleLogout,
  logoComponent,
}: ISizedTopBarProps) => {
  const navigate = useNavigate();
  const notifications = useSelector(selectNotifications);
  return (
    <>
      <Box width="72px" />
      {logoComponent}
      <Box flexGrow={1} />
      {isSwitcherVisible && <ButtonUserTypeSwitcher />}
      <Badge badgeContent={notifications.length || ' '} overlap="circular">
        <IconButton color="primary" onClick={() => navigate('/notifications')}>
          <NotificationsNoneOutlined />
        </IconButton>
      </Badge>
      <UserDropDown handleLogout={handleLogout} settingsPath={settingsPath} />
    </>
  );
};

export default DesktopTopBar;
