import { createAsyncThunk } from '@reduxjs/toolkit';
import { RootState } from 'store/store';
import { get, post, del } from 'services/api/restHelpers';
import { INotification } from 'modules/civilAndTrucking/shared/NotificationSnackbar/INotification';
import { pushNotification } from 'modules/civilAndTrucking/shared/NotificationSnackbar/redux/actions';
import { ICompany } from 'lg-helpers/dist/shared/interfaces/ICompany';
import { IJoinCompany } from 'lg-helpers/dist/shared/interfaces/IJoinCompany';
import { ICompanyApiMapping } from 'lg-helpers/dist/shared/interfaces/ICompanyApiMapping';

type TCompanySearch = {
  item: string;
  value: string | number | boolean;
};

export const setActiveCompany = createAsyncThunk(
  'companies/get-active',
  async (companyId: string = 'test') => {
    const data = await get(`civil/companies/${companyId}`);
    return data as IJoinCompany;
  }
);

export const getCompanyById = createAsyncThunk(
  'companies/get-by-id',
  async (companyId: string, thunkApi) => {
    try {
      const data = await get(`civil/companies/${companyId}`);
      return data as ICompany;
    } catch (err) {
      const errorNotification: INotification = {
        status: 'error',
        message: 'Error: Unable to get company details.',
      };
      thunkApi.dispatch(pushNotification(errorNotification));
      throw err;
    }
  }
);

// here source of evil
export const getCompanyByType = createAsyncThunk(
  'companies/get-by-type',
  async (request: { path: string; query?: string }, thunkApi) => {
    const state = thunkApi.getState() as RootState;
    const companyId = state.civil.companies.activeCompany?.id;
    const data = await get(
      `civil/companies/${companyId}/affiliates?type=${request.path}&query=${request.query}`
    );
    return data as IJoinCompany[];
  }
);

export const addCompany = createAsyncThunk(
  'companies/create',
  async (payload: ICompany, thunkApi) => {
    const state = thunkApi.getState() as RootState;
    const companyId = state.civil.companies.activeCompany?.id;
    try {
      const data = await post(`civil/companies/${companyId}`, payload);
      // TODO: we had some refresh calls here (for transporters and sources), but this way is cursed
      if (payload.id === companyId) {
        await thunkApi.dispatch(setActiveCompany(companyId || ''));
      }

      return data as IJoinCompany;
    } catch (err) {
      console.error(err);
      // this is very important, error should not be silenced
      throw err;
    }
  }
);

export const validateCompany = createAsyncThunk(
  'companies/validate',
  async (search: TCompanySearch[]) => {
    try {
      let searchString = '';
      search.forEach((item, index) => {
        searchString += `${item.item}=${item.value}` + `${index !== search.length - 1 ? '&' : ''}`;
      });
      const data = await get(`civil/companies/validate?${searchString}`);

      return data;
    } catch (err) {
      console.error('Error validating company: ', err);
      throw err;
    }
  }
);

export const getApiMappingDetails = createAsyncThunk(
  'companies/get-api-mapping',
  async (payload: { companyId: string; parentContractorCompanyId: string }, thunkApi) => { // eslint-disable-line
    try {
      const data = await get(
        `civil/companies/${payload.companyId}/${payload.parentContractorCompanyId}/api-mapping`
      );
      return data;
    } catch (err) {
      console.error('Error validating company: ', err);
      throw err;
    }
  }
);

export const saveApiMapping = createAsyncThunk(
  'companies/save-api-mapping',
  async (newMapping: ICompanyApiMapping, thunkApi) => {
    try {
      const result = await post(
        `civil/companies/${newMapping.company_id}/${newMapping.parent_contractor_company_id}/api-mapping`,
        newMapping
      );
      const successNotification: INotification = {
        status: 'success',
        message: `${newMapping.id ? 'Updated' : 'Saved'} details for api mapping.`,
      };
      thunkApi.dispatch(pushNotification(successNotification));
      return result;
    } catch (err: any) {
      console.error('Error saving api mapping: ', err);
      const errorNotification: INotification = {
        status: 'error',
        message: err?.response?.data?.message || err?.message || `Unable to save api mapping.`,
      };
      thunkApi.dispatch(pushNotification(errorNotification));
      throw err;
    }
  }
);

export const deleteApiMapping = createAsyncThunk(
  'companies/remove-api-mapping',
  async (apiMapping: ICompanyApiMapping, thunkApi) => {
    try {
      const result = await del(
        `civil/companies/${apiMapping.company_id}/api-mapping/${apiMapping.id}`
      );
      const successNotification: INotification = {
        status: 'success',
        message: `Deleted api mapping.`,
      };
      thunkApi.dispatch(pushNotification(successNotification));
      return result?.data;
    } catch (err: any) {
      console.error('Error deleting api mapping: ', err);
      const errorNotification: INotification = {
        status: 'error',
        message: `Unable to delete api mapping.`,
      };
      thunkApi.dispatch(pushNotification(errorNotification));
      throw err;
    }
  }
);
