import { storage } from 'services/firebase';

const civilUploadImageAndGetUrl = (base64: string, url?: string) =>
  new Promise<string>((resolve, reject) => {
    const metadata = {
      contentType: 'image/jpeg',
    };

    const uploadTask = storage.ref().child(`${url}.jpg`).putString(base64, 'data_url', metadata);

    uploadTask
      .then(async () => {
        resolve(await uploadTask.snapshot.ref.getDownloadURL());
      })
      .catch(() => reject());
  });

export default civilUploadImageAndGetUrl;
