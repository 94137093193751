import React, { useCallback, useContext, useMemo, useState } from 'react';
import { ModalContext } from 'sharedComponents/ModalContext';
import { ManageHeader } from 'sharedComponents/ManageHeader';
import { SearchAndAddCompany } from './SearchAndAddRelatedCompany';
import { AddCompanyWithoutUserForm } from './AddCompanyWithoutUser';
import useApiCall from 'services/api/useApiCall';
import api, {
  ConnectedCompanyType,
  CompanyConnectionWithCompany,
} from 'services/api/autogenerated';
import useApiGetRequest from 'services/api/useApiGetRequest';
import { usePermissionsContext } from 'services/permissions';
import debounce from 'lodash/debounce';
import { Grid, TextField } from '@mui/material';
import { RelatedCompaniesColumns } from './RelatedCompaniesColumns';
import { DataGrid } from '@mui/x-data-grid';

type RelatedCompaniesProps = {
  globalCompanyType: ConnectedCompanyType;
  localCompanyType: ConnectedCompanyType;
  companyTypeName: string;
  companyTypeNamePlural: string;
};

export const RelatedCompanies = ({
  globalCompanyType,
  localCompanyType,
  companyTypeName,
  companyTypeNamePlural,
}: RelatedCompaniesProps) => {
  const { handleModal } = useContext(ModalContext);
  const { user: authUser } = usePermissionsContext();
  const [searchFilter, setSearchFilter] = useState('');
  const [pageSize, setPageSize] = useState<number>(50);
  const [skip, setSkip] = useState<number>(0);

  const [data, { trigger: refreshCompanies, loading }] = useApiGetRequest(
    () =>
      api.companiesConnections.searchConnectedCompanies(
        authUser.companiesIds[0],
        [globalCompanyType, localCompanyType],
        searchFilter,
        pageSize,
        skip
      ),
    () => ({ title: 'Failed to get connected companies', duration: 3000 }),
    [searchFilter, globalCompanyType, localCompanyType, pageSize, skip]
  );

  const {
    data: companies,
    pagination: { total: rowCount },
  } = data || { pagination: { total: 0 } };

  const [handleDelete] = useApiCall(
    async (relationId: number) => {
      const result = await api.companiesConnections.deleteCompanyConnection(relationId);
      refreshCompanies();
      return result;
    },
    () => ({
      title: 'Error: Unable to delete related company',
      duration: 10000,
    }),
    [refreshCompanies]
  );

  const handleEdit = useCallback(
    (company: CompanyConnectionWithCompany) => {
      handleModal(
        <AddCompanyWithoutUserForm
          initialValues={company}
          companyType={localCompanyType}
          onAfterSave={refreshCompanies}
        />
      );
    },
    [localCompanyType, refreshCompanies, handleModal]
  );

  const columns = useMemo(
    () => RelatedCompaniesColumns(handleEdit, handleDelete),
    [handleEdit, handleDelete]
  );

  const showModal = () => {
    handleModal(
      <AddCompanyWithoutUserForm companyType={localCompanyType} onAfterSave={refreshCompanies} />
    );
  };

  const debouncedSetSearchFilter = useCallback(
    debounce(value => setSearchFilter(value), 200),
    []
  );

  return (
    <>
      <ManageHeader
        headerTitle={`Manage ${companyTypeNamePlural}`}
        buttonText={`Add New ${companyTypeName}`}
        onButtonClick={showModal}
        centerComponent={
          <>
            <Grid item sm={3} xs={12}>
              <TextField
                defaultValue={searchFilter}
                variant="outlined"
                id="companiesSearchFilter"
                onChange={e => debouncedSetSearchFilter(e?.currentTarget?.value)}
                fullWidth
                placeholder={'Search'}
                style={{
                  fontSize: '12px',
                  lineHeight: '10px',
                  letterSpacing: '-0.05px',
                }}
              />
            </Grid>
            <SearchAndAddCompany
              refreshConnectedCompanies={refreshCompanies}
              globalCompanyType={globalCompanyType}
              localCompanyType={localCompanyType}
              companyTypeName={companyTypeName}
              companyTypeNamePlural={companyTypeNamePlural}
            />
          </>
        }
      />

      <DataGrid
        loading={loading}
        columns={columns}
        rows={companies || []}
        rowCount={rowCount}
        pageSize={pageSize}
        getRowId={row => row.id}
        showColumnRightBorder={false}
        page={skip}
        onPageChange={page => setSkip(page)}
        onPageSizeChange={size => setPageSize(size)}
        paginationMode="server"
        disableSelectionOnClick
        disableColumnMenu
        autoHeight
        components={{
          ColumnResizeIcon: () => <div></div>,
        }}
      />
    </>
  );
};
