import { useState, useEffect } from 'react';
import Query from 'services/firebase/Query';
import Executer from 'services/firebase/Executer';
import useShowError from 'modules/errors';

const query = new Query();
const executer = new Executer();

const useGetOrWatchDocumentById = (collection, id, watch) => {
  const [document, setDocument] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const showError = useShowError();

  useEffect(() => {
    if (watch) {
      return;
    }

    const fetchDocument = async () => {
      try {
        const doc = await executer.getSingleDocument(query.base.getById(collection, id));
        setDocument(doc);
        setIsLoading(false);
      } catch (err) {
        console.error('Error', err);
        setIsLoading(false);
        showError({
          title: `Error loading ${collection} with id ${id}`,
          duration: 10000,
        });
      }
    };

    fetchDocument();
    // eslint-disable-next-line
  }, [collection, id, watch]);

  useEffect(() => {
    if (!watch) {
      return;
    }

    return executer.watchSingleDocument(
      query.base.getById(collection, id),
      doc => {
        setDocument(doc);
        setIsLoading(false);
      },
      err => {
        console.error('Error', err);
        setIsLoading(false);
        showError({
          title: `Error loading ${collection} with id ${id}`,
          duration: 10000,
        });
      }
    );
    // eslint-disable-next-line
  }, [collection, id, watch]);

  return [document, isLoading];
};

export default useGetOrWatchDocumentById;
