import React from 'react';
import { createSvgIcon } from '@mui/material';

export const Sign = createSvgIcon(
  <path
    fillRule="evenodd"
    clipRule="evenodd"
    d="M16.5116 1.24394c.3905-.39052 1.0237-.39052 1.4142 0l4.2427 4.24263c.1875.18754.2928.44189.2928.70711s-.1053.51957-.2928.70711l-4.2421 4.24211c-.0002.0002-.0004.0003-.0006.0005l-.0006.0006L6.61211 22.4571a.99981.99981 0 0 1-.70713.2929l-4.24252-.0001c-.55226 0-.99996-.4477-.99998-.9999l-.00013-4.2426c0-.2652.10535-.5196.2929-.7071L12.2657 5.48985a.09779.09779 0 0 0 .0032-.00328l.0033-.00326 4.2394-4.23937ZM12.9761 7.6079 2.66237 17.9216l.00008 2.8284h2.82834L15.8045 10.4363l-2.8284-2.8284Zm4.2426 1.41421-2.8284-2.82842 2.8284-2.82843 2.8284 2.82842-2.8284 2.82843Z"
  />,
  'Sign'
);
