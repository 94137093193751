import React, { useCallback, useContext, useEffect, useState } from 'react';
import { Typography, Button, Grid } from '@mui/material';
import AddSubMaterialModal from './AddSubMaterialModal';
import TableWrapper from 'sharedComponents/TableWrapper';
import { ModalContext } from 'sharedComponents/ModalContext';
import api, { CivilCompanySubMaterials } from 'services/api/autogenerated';
import { usePermissionsContext } from 'services/permissions';
import useShowError from 'modules/errors';

export const CompanySubMaterials = () => {
  const { handleModal } = useContext(ModalContext);
  const { user } = usePermissionsContext();
  const [materials, setMaterials] = useState<CivilCompanySubMaterials[]>([]);
  const showError = useShowError();
  const [isLoading, setIsLoading] = useState(false);

  const load = useCallback(async () => {
    setIsLoading(true);
    try {
      const { data: mats } = await api.civilCompanies.getAllCompanySubMaterials(
        user.companiesIds[0]
      );
      setMaterials(mats);
    } catch (err) {
      console.error('Failed to load sub-materials', err);
      showError({
        title: 'Failed to load sub-materials',
      });
    } finally {
      setIsLoading(false);
    }
  }, [showError, user]);

  const handleDeletion = async (selection: CivilCompanySubMaterials) => {
    setIsLoading(true);
    await api.civilCompanies.removeCompanySubMaterial(selection.id);
    load(); // will setIsLoading(false)
  };

  const showModal: React.MouseEventHandler = (event, selected?: CivilCompanySubMaterials) => {
    handleModal(<AddSubMaterialModal selected={selected} onAfterSave={load} />);
  };

  useEffect(() => {
    load();
  }, [load]);

  return (
    <>
      <Grid container spacing={1} alignItems="center">
        <Grid item xs={10}>
          <Typography
            variant="h5"
            sx={{
              color: 'neutral.main',
              typography: { textTransform: 'uppercase' },
            }}
          >
            Sub-Materials
          </Typography>
        </Grid>
        <Grid item xs={2}>
          <Button color="primary" variant="contained" onClick={showModal}>
            Add Sub-Material
          </Button>
        </Grid>
        <Grid item xs={12}>
          <TableWrapper
            data={materials}
            isLoading={isLoading}
            headCells={[
              { id: 'name', label: 'Identifier' },
              { id: 'description', label: 'Description' },
            ]}
            canDelete={true}
            canEdit={true}
            onSelection={showModal}
            onDelete={handleDeletion}
            deleteConfirmation={'Are you sure you wish to remove this Sub-Material?'}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default CompanySubMaterials;
