import React, { useEffect, useState } from 'react';
import ReportIcon from '@mui/icons-material/Report';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import NotInterestedIcon from '@mui/icons-material/NotInterested';
import { useSelector } from 'react-redux';
import { selectAuthUser } from 'modules/auth/storeSliceAuth';
import useShowError from '../errors';
import { useNavigate } from 'react-router-dom';
import Executer from 'services/firebase/Executer';
import Query from 'services/firebase/Query';
import ButtonLink from 'sharedComponents/ButtonLink';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import CrudTable from 'sharedComponents/CrudTable';

const projectColumns = [
  {
    path: 'profileNr',
    label: 'Profile #',
  },
  { path: 'name', label: 'Name' },
  {
    path: 'isActive',
    label: 'Active',
    getCellValueCallback: (row: any) => {
      if (!row.isActiveCustomApi) {
        return <ReportIcon color="error" />;
      }

      if (row.isActive) {
        return <CheckCircleIcon color="primary" />;
      }

      return <NotInterestedIcon color="error" />;
    },
  },
  {
    path: '',
    label: 'Map',
    hasCustomClickHandler: true,
    getCellValueCallback: (row: any) => (
      <ButtonLink
        url={`/projects/${row.id}/map`}
        isDisabled={!row || !row.id}
        Icon={<LocationOnIcon fontSize="small" />}
      />
    ),
  },
];

const MinifiedProjectList = ({ companyId }: { companyId: string }) => {
  const authUser = useSelector(selectAuthUser);
  const [isLoadingData, setIsLoadingData] = useState(false);
  const [projects, setProjects] = useState<any[]>([]);
  const showError = useShowError();
  const navigate = useNavigate();

  const executer = new Executer();
  const query = new Query();

  const goToProject = (project: any) => {
    navigate(`/projects/${project.id}`);
  };

  useEffect(() => {
    if (!authUser || !companyId) return;

    const getProjects = async () => {
      setIsLoadingData(true);
      try {
        const projectsResults = await executer.getMultipleDocuments(
          query.projects.getProjectsActiveForCompany(companyId)
        );
        setProjects(projectsResults);
      } catch (error) {
        console.error('Error', error);
        showError({
          title: 'Error while fetching the projects',
          duration: 10000,
        });
      }
      setIsLoadingData(false);
    };
    getProjects();

    // eslint-disable-next-line
  }, [authUser]);

  return (
    <CrudTable
      rows={projects}
      columns={projectColumns}
      isLoading={isLoadingData}
      handleRowClickCallback={goToProject}
      title="Projects"
    />
  );
};

export default MinifiedProjectList;
