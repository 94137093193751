import { createSlice } from '@reduxjs/toolkit';
import { RootState } from 'store/store';
import { toDateStr } from 'modules/civilAndTrucking/trucking/utils';

interface IToolbarState {
  date: string;
  showCompleted: boolean;
}

const initialToolbarState: IToolbarState = {
  date: toDateStr(new Date()),
  showCompleted: false,
};

export const dispatchToolbarSlice = createSlice({
  name: 'dispatchToolbar',
  initialState: initialToolbarState,
  reducers: {
    setDate: (state, action) => {
      state.date = action.payload;
    },
    setCompleted: state => {
      state.showCompleted = !state.showCompleted;
    },
  },
});

export const { setDate, setCompleted } = dispatchToolbarSlice.actions;

export const selectDate = (state: RootState) =>
  state.trucking.dispatchesToolbar.date as IToolbarState['date'];
export const selectCompleted = (state: RootState) =>
  state.trucking.dispatchesToolbar.showCompleted as IToolbarState['showCompleted'];
