import React from 'react';
import { createSvgIcon } from '@mui/material';

export const Printer = createSvgIcon(
  <path
    fillRule="evenodd"
    clipRule="evenodd"
    d="M3 1c0-.55229.44772-1 1-1h14c.5523 0 1 .44771 1 1v7h2c.5523 0 1 .44772 1 1v9c0 .5523-.4477 1-1 1h-2.5v2c0 .5523-.4477 1-1 1h-13c-.55228 0-1-.4477-1-1v-2H1c-.55229 0-1-.4477-1-1V9c0-.55228.44771-1 1-1h2V1Zm2 7h12V2H5v6Zm-3 2v7h1.49025v-2c0-.5523.44772-1 1-1H17.5086c.5523 0 1 .4477 1 1v2H20v-7H2Zm3.5 6v4h11v-4h-11Z"
  />,
  'Printer'
);
