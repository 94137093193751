import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Box,
  Grid,
  TextField,
  CircularProgress,
  RadioGroup,
  Radio,
  FormControl,
  FormControlLabel,
  Autocomplete,
} from '@mui/material';
import { useSelector } from 'react-redux';
import { selectAuthUser } from 'modules/auth/storeSliceAuth';
import useGetDispatches from 'services/hooks/useGetDispatches';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import moment from 'moment';
import 'moment-timezone';
import useIsUserOfType from 'services/hooks/useIsUserOfType';
import { DateRangePicker } from 'rsuite';
import { brokerLoadsShowOnly } from 'lg-helpers/dist/firestore/query/BrokerFirebaseQuery';

const ToolbarLoads = ({
  dateRangeFilter,
  setDateRangeFilterCallback,
  filterDispatchId,
  setFilterDispatchId,
  filterShowOnly,
  setFilterShowOnly,
  handleClickBackCallback,
  handleClickNextCallback,
  isLoading,
}) => {
  const authUser = useSelector(selectAuthUser);
  const [timeZone, setTimeZone] = useState(null);
  const isUserBrokerAdmin = useIsUserOfType('trucking_dispatcher');

  useEffect(() => {
    if (!authUser) {
      return;
    }

    const { timeZoneUser } = authUser;
    setTimeZone(timeZoneUser);
  }, [authUser]);

  const { dispatches, isLoading: isLoadingDispatches } = useGetDispatches(dateRangeFilter, false);

  const onDateRangeFilterChange = newDateRange => {
    if (newDateRange.length) {
      let startDate = moment(newDateRange[0]);
      let endDate = moment(newDateRange[1]);

      startDate = moment.tz(
        {
          year: startDate.year(),
          month: startDate.month(),
          day: startDate.date(),
        },
        timeZone || 'EST'
      );

      endDate = moment.tz(
        {
          year: endDate.year(),
          month: endDate.month(),
          day: endDate.date(),
        },
        timeZone || 'EST'
      );
      setDateRangeFilterCallback([startDate.toDate(), endDate.toDate()]);
    } else {
      setDateRangeFilterCallback(null);
    }
  };

  return (
    <>
      <Grid container spacing={2}>
        <Grid item sm={3} xs={12}>
          <DateRangePicker
            appearance="default"
            placeholder="Show between"
            placement="bottomStart"
            size="lg"
            format="MM/DD/YYYY"
            value={dateRangeFilter || []}
            onChange={onDateRangeFilterChange}
            style={{ width: 260 }}
            disabled={isLoading}
          />
        </Grid>
        <Grid item sm={3} xs={12}>
          {isUserBrokerAdmin && (
            <FormControl component="fieldset">
              <RadioGroup
                row
                aria-label="position"
                name="position"
                defaultValue="all"
                value={filterShowOnly}
                onChange={event => setFilterShowOnly(event.target.value)}
              >
                <FormControlLabel
                  value={brokerLoadsShowOnly.all}
                  control={<Radio color="primary" />}
                  label={brokerLoadsShowOnly.all}
                  labelPlacement="bottom"
                />
                <FormControlLabel
                  value={brokerLoadsShowOnly.unused}
                  control={<Radio color="primary" />}
                  label={brokerLoadsShowOnly.unused}
                  labelPlacement="bottom"
                />
                <FormControlLabel
                  value={brokerLoadsShowOnly.used}
                  control={<Radio color="primary" />}
                  label={brokerLoadsShowOnly.used}
                  labelPlacement="bottom"
                />
              </RadioGroup>
            </FormControl>
          )}
        </Grid>
        <Grid item sm={3} xs={12}>
          <Box display="flex" justifyContent="center" alignItems="center">
            <Autocomplete
              fullWidth
              required
              disabled={!dispatches.length}
              options={dispatches || []}
              isOptionEqualToValue={(option, value) => option === value}
              getOptionLabel={option => `${option.projectName} - ${option.material}`}
              value={
                (dispatches || []).find(
                  dispatch => filterDispatchId && dispatch.dispatchID === filterDispatchId
                ) || null
              }
              onChange={(event, value) => setFilterDispatchId(value ? value.dispatchID : null)}
              renderInput={params => (
                <TextField
                  {...params}
                  label="Dispatch"
                  required
                  variant="outlined"
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <>
                        {isLoadingDispatches && <CircularProgress color="inherit" size={20} />}
                        {params.InputProps.endAdornment}
                      </>
                    ),
                  }}
                />
              )}
            />
          </Box>
        </Grid>
        <Grid item sm={3} xs={12}>
          <Box display="flex" justifyContent="flex-end" alignItems="center">
            <Box marginRight={1}>
              <Button
                variant="contained"
                color="inherit"
                disabled={isLoading}
                onClick={handleClickBackCallback}
              >
                <NavigateBeforeIcon fontSize="small" />
                Back
              </Button>
            </Box>
            <Box>
              <Button
                variant="contained"
                color="inherit"
                disabled={isLoading}
                onClick={handleClickNextCallback}
              >
                Next
                <NavigateNextIcon fontSize="small" />
              </Button>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </>
  );
};

ToolbarLoads.propTypes = {
  companyId: PropTypes.string,
  dateRangeFilter: PropTypes.oneOfType([PropTypes.bool, PropTypes.array]),
  setDateRangeFilterCallback: PropTypes.func.isRequired,
  filterDispatchId: PropTypes.string,
  setFilterDispatchId: PropTypes.func.isRequired,
  filterShowOnly: PropTypes.string,
  setFilterShowOnly: PropTypes.func.isRequired,
  handleClickBackCallback: PropTypes.func.isRequired,
  handleClickNextCallback: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
};

export default ToolbarLoads;
