import { object, string, boolean } from 'yup';
import { ICompanyMaterial } from 'lg-helpers/dist/shared/interfaces/ICompanyMaterial';

export const companyMaterialSchema = object<ICompanyMaterial>({
  name: string().required('Name is required'),
  sku: string(),
  companyId: string(),
  deleted: boolean(),
  id: string(),
});
