import React, { ChangeEvent, useState } from 'react';
import { Autocomplete, Box, Button, CardHeader, Grid, TextField, Typography } from '@mui/material';
import AddBoxOutlinedIcon from '@mui/icons-material/AddBoxOutlined';
import { inputDefinitionTypes } from 'sharedComponents/CustomInputsDefinitionsCrudGlobal';
import { ICompany } from 'lg-helpers/dist/shared/interfaces/ICompany';
import { ICustomInputsFilterOptions } from './GlobalCustomInputAdmin';
import { useNavigate } from 'react-router-dom';

interface GlobalCustomInputToolbarProps {
  filterOptions: ICustomInputsFilterOptions;
  setFilterOptions: (filterOptions: ICustomInputsFilterOptions) => void;
  companies: ICompany[];
}

export const GlobalCustomInputToolbar = ({
  filterOptions,
  setFilterOptions,
  companies,
}: GlobalCustomInputToolbarProps) => {
  const [selectedCompanies, setSelectedCompanies] = useState<ICompany[]>([]);
  const navigate = useNavigate();

  const handleFilterChange = (evt: any) => {
    const target = evt.currentTarget as HTMLInputElement;
    const changedFilters: ICustomInputsFilterOptions = {
      ...filterOptions,
      [target.name]: target?.type === 'checkbox' ? target.checked : target.value,
    };
    setFilterOptions(changedFilters);
  };

  const handleNativeSelectChangeFilter = (name: string, value: any) => {
    handleFilterChange({ currentTarget: { name, value } });
  };

  const handleNativeSelectChangeFilterCompanies = (value: any) => {
    setSelectedCompanies(value);
    handleFilterChange({
      currentTarget: { name: 'companyIds', value: value.map((e: any) => e.id) },
    });
  };

  const getCompanyLabel = (company: ICompany) => {
    if (!company) {
      return '';
    }
    const typesStringified = company.types
      ? company?.types?.map((type: string) => type).join(', ')
      : '';
    return `${company.name} - ${typesStringified}`;
  };

  return (
    <CardHeader
      title="Manifest Custom Inputs Definitions"
      action={
        <Button
          sx={{ minWidth: '100px' }}
          color="primary"
          variant="contained"
          fullWidth
          onClick={() => navigate('./0')}
        >
          <AddBoxOutlinedIcon color="inherit" />
          <Box marginLeft={1}>Add</Box>
        </Button>
      }
      subheader={
        <Grid item xs={12}>
          <hr />
          <Grid container columnSpacing={2} alignItems="center">
            <Grid item xs={12} md={1}>
              <Typography variant="h6" color="initial">
                Filters
              </Typography>
            </Grid>
            <Grid item xs={12} md={3}>
              <Box>
                <TextField
                  fullWidth
                  label="Search by name"
                  name="name"
                  onChange={(evt: ChangeEvent<HTMLInputElement>) => handleFilterChange(evt)}
                  value={filterOptions.name || ''}
                  variant="outlined"
                />
              </Box>
            </Grid>
            <Grid item xs={12} md={3}>
              <Autocomplete
                multiple
                options={inputDefinitionTypes}
                value={filterOptions.types || []}
                isOptionEqualToValue={(option, val) => option === val}
                onChange={(evt, value) => handleNativeSelectChangeFilter('types', value)}
                renderInput={params => (
                  <TextField
                    {...params}
                    variant="outlined"
                    label="Type"
                    placeholder="Select sections"
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={5}>
              <Autocomplete
                fullWidth
                multiple
                options={companies || []}
                getOptionLabel={getCompanyLabel}
                value={selectedCompanies || []}
                onChange={(evt, value) => handleNativeSelectChangeFilterCompanies(value)}
                renderOption={(props, option) => (
                  <li {...props} key={option.id || ''}>
                    {option.name} - {option.types.join(', ')}
                  </li>
                )}
                renderInput={params => (
                  <TextField
                    {...params}
                    label="Companies"
                    variant="outlined"
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: <>{params.InputProps.endAdornment}</>,
                    }}
                  />
                )}
              />
            </Grid>
          </Grid>
        </Grid>
      }
    />
  );
};
