import React, { useState } from 'react';
import './Lane.scss';
import PropTypes from 'prop-types';
import {
  Avatar,
  Card,
  CardContent,
  CardActions,
  Typography,
  Box,
  CircularProgress,
  Button,
  Grid,
} from '@mui/material';
import { WebStories } from '@mui/icons-material';
import Command from 'services/firebase/Command';
import { setSelectedManifest } from 'modules/scales/storeSliceScales';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import useShowError from 'modules/errors';

const command = new Command();

const LaneTaken = ({ lane, manifest, batch }) => {
  const [isLoadingClearLane, setIsLoadingClearLane] = useState(false);
  const showError = useShowError();

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const onClearLaneClick = async () => {
    setIsLoadingClearLane(true);
    try {
      await command.manifests.setManifestLane(manifest.id, null);
    } catch (err) {
      console.error(err);
      showError({ title: 'Error trying to clear lane.' });
    }
    setIsLoadingClearLane(false);
  };

  const handleLaneClick = () => {
    if (!manifest) {
      return;
    }

    dispatch(setSelectedManifest(manifest));
    navigate('/scales/verify-manifest');
  };

  const TrailerNumber = batch && (
    <>
      <span className="Lane__break" />
      Trailer # {batch.trailerNumber}
    </>
  );

  return (
    <Card raised={true}>
      <CardContent
        className={manifest ? 'Lane__pointer' : null}
        style={{ backgroundColor: manifest ? lane.color : '' }}
        onClick={handleLaneClick}
      >
        <Grid container spacing={2}>
          <Grid item xs={4}>
            {manifest.batchId && <WebStories style={{ color: 'white', height: 40, width: 40 }} />}
          </Grid>
          <Grid item xs={4}>
            <Box display="flex" justifyContent="center" alignItems="center" marginBottom={1}>
              <Avatar
                style={{
                  backgroundColor: manifest ? 'white' : lane.color,
                }}
              >
                <Typography variant="h5" style={{ color: manifest ? lane.color : 'white' }}>
                  {lane.label}
                </Typography>
              </Avatar>
            </Box>
          </Grid>
          <Grid item xs={4}>
            {/* Needed to keep everything in line */}
          </Grid>
        </Grid>

        {manifest && (
          <Typography className="Lane__text--info" variant="h5">
            Profile {manifest.project.profileNr}
            <span className="Lane__break" />
            Manifest {manifest.number}
            <span className="Lane__break" />
            {manifest.signatureDriver.truckingCompany}
            {TrailerNumber}
            <span className="Lane__break" />
            Truck # {manifest.signatureDriver.truckNumber}
          </Typography>
        )}
      </CardContent>
      {manifest && (
        <CardActions>
          <Button
            color="primary"
            fullWidth
            variant="text"
            disabled={isLoadingClearLane}
            onClick={onClearLaneClick}
          >
            <Typography variant="h3">Clear</Typography>
            {isLoadingClearLane && (
              <Box marginLeft={2}>
                <CircularProgress color="inherit" />
              </Box>
            )}
          </Button>
        </CardActions>
      )}
    </Card>
  );
};

LaneTaken.propTypes = {
  lane: PropTypes.object.isRequired,
  manifest: PropTypes.object.isRequired,
};

export default LaneTaken;
