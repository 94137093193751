import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Card,
  CardHeader,
  CardContent,
  Grid,
  TextField,
  Box,
  Divider,
  Button,
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  CircularProgress,
} from '@mui/material';
import { selectScaleForEdit, setScaleForEdit } from 'modules/scales/storeSliceScales';
import { useSelector, useDispatch } from 'react-redux';
import useShowError from 'modules/errors';
import Command from 'services/firebase/Command';

const command = new Command();

const ProfileTypes = () => {
  const dispatch = useDispatch();
  const scaleForEdit = useSelector(selectScaleForEdit);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isUpdatingPasscode, setIsUpdatingPasscode] = useState(false);
  const [passcode, setPasscode] = useState('');
  const [visible, setVisible] = useState(false);
  const showError = useShowError();

  const currentPasscode = scaleForEdit?.offHoursPasscode || '';
  const isOffHours = scaleForEdit?.isOffHours || false;

  const handleClose = () => {
    setVisible(false);
  };

  const handleUpdatePasscode = async () => {
    if (passcode?.length < 4) {
      showError({ title: 'Minimum 4 digits' });
    } else {
      handleClose();
      setIsUpdatingPasscode(true);

      try {
        await command.manifests.updateScaleOffHoursPasscode(scaleForEdit.id, passcode);
        dispatch(
          setScaleForEdit({
            ...scaleForEdit,
            offHoursPasscode: passcode,
          })
        );
      } catch (err) {
        console.error(err);
      }
      handleClose();
      setIsUpdatingPasscode(false);
    }
  };

  const handleChangeOffHours = async () => {
    if (!isOffHours && !currentPasscode) {
      return;
    }

    setIsSubmitting(true);
    try {
      await command.manifests.setScaleOffHours(scaleForEdit.id, !isOffHours);
      dispatch(
        setScaleForEdit({
          ...scaleForEdit,
          isOffHours: !isOffHours,
        })
      );
    } catch (err) {
      console.error(err);
    }
    setIsSubmitting(false);
  };

  if (!scaleForEdit) {
    return null;
  }
  return (
    <Card width="100%">
      <CardHeader title="Unattended Loads" />
      <Divider />
      <CardContent>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <Box display="flex" flexDirection="row" justifyContent="flex-start" alignItems="center">
              <Typography variant="body1">{'Current Passcode : '} </Typography>
              <Typography variant="h4">{currentPasscode || ' Not set'}</Typography>
              <Button
                color="primary"
                variant="outlined"
                onClick={() => {
                  setPasscode(currentPasscode);
                  setVisible(true);
                }}
                marginLeft={16}
                disabled={isUpdatingPasscode}
              >
                Update Passcode
                {isUpdatingPasscode && (
                  <Box marginLeft={2}>
                    <CircularProgress color="inherit" size={12} />
                  </Box>
                )}
              </Button>
            </Box>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Box display="flex" flexDirection="row" justifyContent="flex-end" alignItems="center">
              <Typography variant="h4" color="error">
                {isOffHours ? 'Unattended loads allowed' : ''}{' '}
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Box display="flex" justifyContent="flex-end">
              <Button
                color="primary"
                variant={isOffHours ? 'outlined' : 'contained'}
                onClick={handleChangeOffHours}
                disabled={isSubmitting}
              >
                {isOffHours ? 'Disable Unattended Loads' : 'Allow Unattended Loads'}
                {isSubmitting && (
                  <Box marginLeft={2}>
                    <CircularProgress color="inherit" size={12} />
                  </Box>
                )}
              </Button>
            </Box>
          </Grid>
        </Grid>
      </CardContent>
      <Dialog open={visible} onClose={handleClose} fullWidth>
        <DialogTitle>Update Passcode</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            id="passcode"
            name="passcode"
            label="Passcode"
            fullWidth
            value={passcode}
            onChange={e => setPasscode(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} variant="text" color="primary">
            Cancel
          </Button>
          <Button onClick={handleUpdatePasscode} variant="contained" color="primary">
            Update
          </Button>
        </DialogActions>
      </Dialog>
    </Card>
  );
};

ProfileTypes.propTypes = {
  className: PropTypes.string,
  lane: PropTypes.object,
};

export default ProfileTypes;
