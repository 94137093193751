import IncomingManifests from 'sharedComponents/pictograms/svg/IncomingManifests';
import Lanes from 'sharedComponents/pictograms/svg/Lanes';
import Manifest from 'sharedComponents/pictograms/svg/Manifest';

import { UserTypes, UserType } from 'lg-helpers/dist/constants/user/UserTypes';
import { IMenuItem } from 'modules/civilAndTrucking/shared/nav';

import {
  FormatListNumbered,
  SourceOutlined,
  Tune,
  People,
  ManageAccounts,
  Business,
  LocalShippingOutlined,
  TableChart,
} from '@mui/icons-material';
import React from 'react';

const tkmsCompaniesIds: string[] = [
  'IZjeW6lGlkZ2iqet7tEc',
  'yTYQ50QouQyas3TbcgIL',
  'IZjeW6lGlkZ2iqet7tEc',
]; //development, staging, production
const richmondCompaniesIds: string[] = ['qFxaXHp9BOX8SUCeyzsC', '', 'pv66CcTaqelhfPHQDePw']; //development, staging, production
const lousCompaniesIds: string[] = ['GqlvnJbdwgn0eMBbd2Mr', '', 'GqlvnJbdwgn0eMBbd2Mr']; //development, staging, production

const isDriverTkmsOrRichmondUser = (user: any) => {
  if (!user) {
    return false;
  }
  const companyId = user.companiesIds[0];
  return !![...tkmsCompaniesIds, ...richmondCompaniesIds].find(
    tkmsCompanyId => tkmsCompanyId === companyId
  );
};

const isDriverTkmsOrRichmondOrLousUser = (user: any) => {
  if (!user) {
    return false;
  }
  const companyId = user.companiesIds[0];
  return !![...tkmsCompaniesIds, ...richmondCompaniesIds, ...lousCompaniesIds].find(
    tkmsCompanyId => tkmsCompanyId === companyId
  );
};

const getLoginDefaultContinueUrl = (user: any) => {
  const isBrokerUIAllowed = isDriverTkmsOrRichmondOrLousUser(user);
  switch (user.actingAsType) {
    case UserTypes.admin.super:
      return '/super-admin/projects';
    case UserTypes.admin.customer:
      return '/customer-admin/projects';
    case UserTypes.scale.admin:
      return '/scales/projects';
    case UserTypes.scale.attendant:
      return '/scales/lanes';
    case UserTypes.transporter.admin:
      return '/transporters/projects';
    case UserTypes.generator.admin:
    case UserTypes.generator.user:
      return '/generators/projects';
    case UserTypes.viewer.user:
      return '/viewer/projects';
    case UserTypes.contractor.admin:
    case UserTypes.contractor.user:
      return '/contractors/projects';
    case UserTypes.civil.projectManager:
    case UserTypes.civil.accountant:
    case UserTypes.civil.siteUser:
    case UserTypes.civil.viewer:
      return '/civil';
    // driver types
    case UserTypes.transporter.driver:
    case UserTypes.truckingBroker.truckingBrokerDriver:
      return '/app-download';
    case UserTypes.broker.trucking.dispatcher:
      return isBrokerUIAllowed ? '/brokers/driver/dispatches' : '/trucking';
    case UserTypes.broker.trucking.driver:
      return isBrokerUIAllowed ? '/brokers/driver/dispatches' : '/app-download';
    case UserTypes.broker.trucking.viewer:
      return '/trucking/tickets';
    case UserTypes.truckingBroker.truckingBrokerDispatcher:
      return '/trucking-broker';
    default:
      return '/login';
  }
};

const generateSuperAdminMenuItems = () => {
  return [
    {
      href: '/super-admin/administration',
      title: 'Admin',
      Pictogram: <Tune />,
    },
    {
      href: '/super-admin/projects',
      title: 'Projects',
      Pictogram: <SourceOutlined />,
    },
    {
      href: '/super-admin/users',
      title: 'Users',
      Pictogram: <People />,
    },
    {
      href: '/super-admin/companies',
      title: 'Companies',
      Pictogram: <Business />,
    },
    {
      href: '/super-admin/scales',
      title: 'Scales',
      Pictogram: <SourceOutlined />,
    },
    {
      href: '/super-admin/reports',
      title: 'Reports',
      Pictogram: <TableChart />,
    },
    {
      href: '/super-admin/account',
      title: 'Account',
      Pictogram: <ManageAccounts />,
    },
  ] as IMenuItem[];
};

const generateCustomerAdminMenuItems = () => {
  return [
    {
      href: '/customer-admin/projects',
      title: 'Projects',
      Pictogram: <SourceOutlined />,
    },
    {
      href: '/customer-admin/users',
      title: 'Users',
      Pictogram: <People />,
    },
    {
      href: '/customer-admin/companies',
      title: 'Companies',
      Pictogram: <Business />,
    },
    {
      href: '/customer-admin/account',
      title: 'Account',
      Pictogram: <ManageAccounts />,
    },
  ] as IMenuItem[];
};

// lanes, incoming-manifests, quality-control, processed-manifests, account
const generateScaleItems = (): IMenuItem[] => {
  return [
    {
      href: '/scales/projects',
      title: 'Projects',
      Pictogram: <SourceOutlined />,
      //Projects,
      computeVisibility: user => user === UserTypes.scale.admin,
    },
    {
      href: '/scales/lanes',
      title: 'Lanes',
      Pictogram: <Lanes />,
    },
    {
      href: '/scales/incoming-manifests',
      title: 'Incoming Manifests',
      Pictogram: <IncomingManifests />,
    },
    {
      href: '/scales/quality-control',
      title: 'Quality Control',
      Pictogram: <SourceOutlined />,
    },
    {
      href: '/scales/processed-manifests',
      title: 'Processed Manifests',
      Pictogram: <FormatListNumbered />,
    },
    {
      href: '/scales/settings',
      title: 'Scales Settings',
      Pictogram: <Tune />,
      computeVisibility: user => user === UserTypes.scale.admin,
    },
    {
      href: '/scales/users',
      title: 'Users',
      Pictogram: <People />,
      computeVisibility: user => user === UserTypes.scale.admin,
    },
    {
      href: '/scales/account',
      title: 'Account',
      Pictogram: <ManageAccounts />,
    },
  ];
};

const generateTransporterAdminMenuItems = () => {
  return [
    {
      href: '/transporters/projects',
      title: 'Projects',
      Pictogram: <SourceOutlined />,
    },
    {
      href: '/transporters/account',
      title: 'Account',
      Pictogram: <ManageAccounts />,
    },
    {
      href: '/transporters/company',
      title: 'Company',
      Pictogram: <Business />,
    },
    {
      href: '/transporters/users',
      title: 'Users',
      Pictogram: <People />,
    },
  ] as IMenuItem[];
};

// projects, current-manifest, select-scale-lane, manifests, account
// TODO: should this be removed?
const generateTransporterDriversMenuItems = () => {
  return [
    {
      href: '/drivers/projects',
      title: 'Projects',
      Pictogram: <SourceOutlined />,
    },
    {
      href: '/drivers/current-manifest',
      title: 'Current Manifest',
      Pictogram: Manifest,
    },
    {
      href: '/drivers/select-scale-lane',
      title: 'Scale Lane',
      Pictogram: <Lanes />,
    },
    {
      href: '/drivers/manifests',
      title: 'Manifests',
      Pictogram: <FormatListNumbered />,
    },
    {
      href: '/drivers/account',
      title: 'Account',
      Pictogram: <ManageAccounts />,
    },
  ] as IMenuItem[];
};

const generateGeneratorItems = () => {
  return [
    {
      href: '/generators/projects',
      title: 'Projects',
      Pictogram: <SourceOutlined />,
    },
    {
      href: '/generators/account',
      title: 'Account',
      Pictogram: <ManageAccounts />,
    },
    {
      href: '/generators/users',
      title: 'Users',
      Pictogram: <People />,
      computeVisibility: user => user === UserTypes.generator.admin,
    },
  ] as IMenuItem[];
};

const generateViewerUserMenuItems = () => {
  return [
    {
      href: '/viewer/projects',
      title: 'Projects',
      Pictogram: <SourceOutlined />,
    },
    {
      href: '/viewer/account',
      title: 'Account',
      Pictogram: <ManageAccounts />,
    },
  ] as IMenuItem[];
};

const generateContractorItems = () => {
  return [
    {
      href: '/contractors/projects',
      title: 'Projects',
      Pictogram: <SourceOutlined />,
    },
    {
      href: '/contractors/account',
      title: 'Account',
      Pictogram: <ManageAccounts />,
    },
    {
      href: '/contractors/company',
      title: 'Company',
      Pictogram: <Business />,
      computeVisibility: user => user === UserTypes.contractor.admin,
    },
    {
      href: '/contractors/users',
      title: 'Users',
      Pictogram: <People />,
      computeVisibility: user => user === UserTypes.contractor.admin,
    },
  ] as IMenuItem[];
};

const generateBrokerItems = () => {
  return [
    {
      href: '/brokers/admin/dispatches',
      title: 'Dispatches',
      Pictogram: <SourceOutlined />,
      computeVisibility: user => user === UserTypes.broker.trucking.dispatcher,
    },
    {
      // TODO: should this even be rendered? drivers have been removed from the web application (?)
      href: '/brokers/driver/dispatches',
      title: 'Dispatches',
      Pictogram: <SourceOutlined />,
      computeVisibility: user => user === UserTypes.broker.trucking.driver,
    },
    {
      href: '/drivers/current-manifest',
      title: 'Current Manifest',
      Pictogram: Manifest,
      computeVisibility: user => user === UserTypes.broker.trucking.driver,
    },
    {
      href: '/drivers/select-scale-lane',
      title: 'Scale Lane',
      Pictogram: <Lanes />,
      computeVisibility: user => user === UserTypes.broker.trucking.driver,
    },
    {
      href: '/brokers/loads',
      title: 'Loads',
      Pictogram: <FormatListNumbered />,
    },
    {
      href: '/brokers/users',
      title: 'Users',
      Pictogram: <People />,
      computeVisibility: user => user === UserTypes.broker.trucking.dispatcher,
    },
    {
      href: '/brokers/account',
      title: 'Account',
      Pictogram: <ManageAccounts />,
    },
  ] as IMenuItem[];
};

const generateTruckingViewerItems = () => {
  return [
    {
      href: '/trucking/tickets',
      title: 'Tickets',
      Pictogram: <LocalShippingOutlined />,
    },
  ];
};

// this is a bodge until we can figure out the global routing stuff
const generateGlobalRouteMenuItem = (userType: UserType): IMenuItem[] => {
  switch (userType) {
    case UserTypes.admin.super:
      return generateSuperAdminMenuItems();
    case UserTypes.admin.customer:
      return generateCustomerAdminMenuItems();
    case UserTypes.scale.admin:
    case UserTypes.scale.attendant:
      return generateScaleItems();
    case UserTypes.transporter.admin:
      return generateTransporterAdminMenuItems();
    case UserTypes.transporter.driver:
      return generateTransporterDriversMenuItems();
    case UserTypes.generator.admin:
    case UserTypes.generator.user:
      return generateGeneratorItems();
    case UserTypes.viewer.user:
      return generateViewerUserMenuItems();
    case UserTypes.contractor.admin:
    case UserTypes.contractor.user:
      return generateContractorItems();
    case UserTypes.broker.trucking.dispatcher:
    case UserTypes.broker.trucking.driver:
    case UserTypes.truckingBroker.truckingBrokerDriver:
      return generateBrokerItems();
    case UserTypes.broker.trucking.viewer:
      return generateTruckingViewerItems();
    default:
      return [];
  }
};

export type UserTypeGenerated =
  | 'scale'
  | 'transporter'
  | 'generator'
  | 'contractor'
  | 'broker'
  | 'truckingBroker'
  | 'truckingContractor'
  | 'truckingSourceSupplier'
  | 'viewer'
  | 'civil'
  | 'trucking';

const userTypesGenerated: { type: UserTypeGenerated; roles: UserType[] }[] = [
  {
    type: 'scale',
    roles: [UserTypes.scale.attendant, UserTypes.scale.admin],
  },
  {
    type: 'transporter',
    roles: [UserTypes.transporter.driver, UserTypes.transporter.admin],
  },
  {
    type: 'generator',
    roles: [UserTypes.generator.admin, UserTypes.generator.user, UserTypes.viewer.user],
  },
  {
    type: 'viewer',
    roles: [UserTypes.viewer.user],
  },
  {
    type: 'contractor',
    roles: [UserTypes.contractor.admin, UserTypes.contractor.user, UserTypes.viewer.user],
  },
  {
    type: 'broker',
    roles: [UserTypes.broker.trucking.dispatcher, UserTypes.broker.trucking.driver],
  },
  {
    type: 'trucking',
    roles: [UserTypes.broker.trucking.dispatcher, UserTypes.broker.trucking.driver],
  },
  {
    type: 'truckingBroker',
    roles: [
      UserTypes.truckingBroker.truckingBrokerDispatcher,
      UserTypes.truckingBroker.truckingBrokerDriver,
    ],
  },
  {
    type: 'civil',
    roles: [
      UserTypes.civil.projectManager,
      UserTypes.civil.siteUser,
      UserTypes.civil.accountant,
      UserTypes.civil.viewer,
      UserTypes.broker.trucking.driver,
    ],
  },
];

const generateCreateOrUpdateUserTypeOptions = (
  authUserType: UserType,
  userCompanies: { types: UserTypeGenerated[]; [key: string]: any }[]
) => {
  let userTypeOptions = userTypesGenerated
    .map(
      ({ type, roles }) =>
        !!userCompanies.filter(({ types }) => types.includes(type)).length && roles
    )
    .filter(e => e)
    .flat() as UserType[];

  if (!userCompanies || !userCompanies.length) {
    switch (authUserType) {
      case UserTypes.admin.super:
        userTypeOptions.push(
          UserTypes.admin.super,
          UserTypes.admin.customer,
          UserTypes.viewer.user,
          UserTypes.civil.accountant
        );
        break;
      case UserTypes.admin.customer:
        userTypeOptions.push(UserTypes.admin.customer, UserTypes.viewer.user);
        break;
      case UserTypes.scale.admin:
      case UserTypes.transporter.admin:
      case UserTypes.generator.admin:
      case UserTypes.contractor.admin:
        userTypeOptions.push(UserTypes.contractor.admin, UserTypes.transporter.admin);
        break;
      case UserTypes.broker.trucking.dispatcher:
        userTypeOptions.push(
          UserTypes.broker.trucking.driver,
          UserTypes.broker.trucking.dispatcher
        );
        break;
      case UserTypes.truckingBroker.truckingBrokerDispatcher:
        userTypeOptions.push(UserTypes.truckingBroker.truckingBrokerDriver);
        userTypeOptions.push(UserTypes.truckingBroker.truckingBrokerDispatcher);
        break;
      case UserTypes.civil.projectManager:
        userTypeOptions.push(
          UserTypes.civil.projectManager,
          UserTypes.civil.siteUser,
          UserTypes.civil.viewer
        );
        break;
      case UserTypes.civil.accountant:
        userTypeOptions.push(
          UserTypes.civil.accountant,
          UserTypes.civil.projectManager,
          UserTypes.civil.siteUser,
          UserTypes.civil.viewer
        );
        break;
      default:
        return [];
    }
  }

  return [...new Set(userTypeOptions.flat())];
};

const requireSignatureOnSignup = (userType: UserType) => {
  switch (userType) {
    case UserTypes.scale.attendant:
    case UserTypes.generator.admin:
    case UserTypes.generator.user:
    case UserTypes.transporter.driver:
    case UserTypes.broker.trucking.driver:
    case UserTypes.truckingBroker.truckingBrokerDriver:
      return true;
    default:
      return false;
  }
};

const isTransporterDriver = (authUserType: UserType) => {
  return authUserType === UserTypes.transporter.driver;
};

const hasAccessToCustomInputs = (userType: UserType) => {
  switch (userType) {
    case UserTypes.admin.super:
    case UserTypes.generator.admin:
    case UserTypes.generator.user:
    case UserTypes.contractor.admin:
    case UserTypes.contractor.user:
      return true;
    default:
      return false;
  }
};

export {
  isDriverTkmsOrRichmondUser,
  isDriverTkmsOrRichmondOrLousUser,
  getLoginDefaultContinueUrl,
  generateCreateOrUpdateUserTypeOptions,
  requireSignatureOnSignup,
  isTransporterDriver,
  hasAccessToCustomInputs,
  generateBrokerItems,
  generateGeneratorItems,
  generateScaleItems,
  generateSuperAdminMenuItems,
  generateCustomerAdminMenuItems,
  generateTransporterAdminMenuItems,
  generateTransporterDriversMenuItems,
  generateContractorItems,
  generateViewerUserMenuItems,
  generateGlobalRouteMenuItem,
};
