import React from 'react';
import { Routes, Route } from 'react-router-dom';
import { ConnectedCompanyType } from 'services/api/autogenerated-code';
import { RelatedCompanies } from '../../sharedComponents/RelatedCompanies';

export const SourceSuppliersViews = () => {
  return (
    <Routes>
      <Route
        path="/"
        element={
          <RelatedCompanies
            globalCompanyType={ConnectedCompanyType.SourceSupplier}
            localCompanyType={ConnectedCompanyType.TruckingSourceSupplier}
            companyTypeName={'Source Supplier'}
            companyTypeNamePlural={'Source Suppliers'}
          />
        }
      />
    </Routes>
  );
};
