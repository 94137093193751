import React from 'react';
import { GridColDef } from '@mui/x-data-grid';
import { Button, Box, Tooltip, Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import { CheckBoxOutlineBlank, CheckBox, Edit, Delete, ContentCopy } from '@mui/icons-material';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import { IJoinTruckingDispatch } from 'lg-helpers/dist/trucking/interfaces/IJoinTruckingDispatch';

export const generateDispatchColumns = (
  handleDeleteDispatch?: (dispatchId: number) => void,
  showModal?: (joinedDispatch: IJoinTruckingDispatch) => void,
  doneDispach?: (joinedDispatch: IJoinTruckingDispatch, isDone: boolean) => void,
  showCopyModal?: (joinedDispatch: IJoinTruckingDispatch) => void
) => {
  return [
    {
      field: 'nameTruckingDispatch',
      headerName: 'Project \u2014 Material',
      width: 250,
      renderCell: ({ row, id }) => (
        <Tooltip
          title={`${row.nameTruckingProject || row.manualProjectNameTruckingDispatch} \u2014 ${
            row.nameTruckingCompanyMaterial || row.manualMaterialNameTruckingDispatch
          }`}
        >
          <Typography maxWidth="250px">
            <Link to={`${id}`}>
              {`${row.nameTruckingProject || row.manualProjectNameTruckingDispatch} \u2014 ${
                row.nameTruckingCompanyMaterial || row.manualMaterialNameTruckingDispatch
              }`}
            </Link>
          </Typography>
        </Tooltip>
      ),
    },
    {
      field: 'name',
      headerName: 'Contractor',
      width: 150,
      renderCell: ({ row }) => row?.name || row?.manualContractorNameTruckingDispatch || '',
    },
    {
      field: 'source supplier',
      headerName: 'Source Supplier',
      width: 150,
      renderCell: ({ row }) =>
        `${row.nameTruckingCompanySourceSupplier || row.manualMaterialSourceTruckingDispatch}`,
    },
    {
      field: 'special instructions',
      headerName: 'Special Instructions',
      width: 150,
      renderCell: ({ row }) => (
        <Tooltip title={row.specialInstructionsTruckingDispatch}>
          <Typography maxWidth="150px">{row.specialInstructionsTruckingDispatch}</Typography>
        </Tooltip>
      ),
    },
    {
      field: 'loads assigned',
      headerName: 'Loads Assigned',
      width: 130,
      valueGetter: ({ row }) => (row ? `${row.completedLoadsCount}/${row.allLoadsCount}` : '-'),
    },
    {
      field: 'loadedQtyTruckingDispatch',
      headerName: 'Loaded Qty',
      width: 100,
      valueGetter: ({ row }) =>
        `${row?.loadedQtyTruckingDispatch ? row.loadedQtyTruckingDispatch : 0} ${
          row?.uomTruckingProjectMaterial || row?.manualMaterialUomTruckingDispatch
        }`,
    },
    {
      field: 'dumpedQtyTruckingDispatch',
      headerName: 'Dumped Qty',
      width: 100,
      valueGetter: ({ row }) =>
        `${row?.dumpedQtyTruckingDispatch ? row.dumpedQtyTruckingDispatch : 0} ${
          row?.uomTruckingProjectMaterial || row?.manualMaterialUomTruckingDispatch
        }`,
    },
    {
      field: 'materialQuantityTruckingDispatch',
      headerName: 'Requested Qty',
      width: 120,
      renderCell: ({ row }) =>
        `${row?.materialQuantityTruckingDispatch} ${
          row?.uomTruckingProjectMaterial || row.manualMaterialUomTruckingDispatch
        }`,
    },
    {
      field: 'isDoneTruckingDispatch',
      headerName: 'Done?',
      width: 100,
      renderCell: ({ row }) =>
        row.isDoneTruckingDispatch ? (
          <CheckBox onClick={() => (doneDispach ? doneDispach(row, false) : null)} />
        ) : (
          <CheckBoxOutlineBlank onClick={() => (doneDispach ? doneDispach(row, true) : null)} />
        ),
    },
    {
      field: 'map',
      headerName: 'Map',
      align: 'right',
      headerAlign: 'right',
      flex: 1,
      sortable: false,
      renderCell: data => (
        <Link to={`${data.id}/map`}>
          <Box display="flex" alignItems="center">
            <LocationOnIcon fontSize="medium" />
          </Box>
        </Link>
      ),
    },
    {
      field: 'edit',
      headerName: 'Edit',
      headerAlign: 'center',
      align: 'center',
      width: 80,
      sortable: false,
      renderCell: ({ row }) => (
        <Button
          color="success"
          onClick={() => (showModal ? showModal(row) : null)}
          disabled={!showModal}
          sx={{ minWidth: 0 }}
        >
          <Edit />
        </Button>
      ),
    },
    {
      field: 'delete',
      headerName: 'Delete',
      headerAlign: 'center',
      align: 'center',
      width: 80,
      sortable: false,
      renderCell: ({ row }) =>
        !row?.loadedQtyTruckingDispatch &&
        !row?.dumpedQtyTruckingDispatch &&
        handleDeleteDispatch && (
          <Button
            color="success"
            onClick={() => handleDeleteDispatch(row?.idTruckingDispatch)}
            sx={{ minWidth: 0 }}
          >
            <Delete />
          </Button>
        ),
    },
    {
      field: 'copy',
      headerName: 'Copy',
      headerAlign: 'center',
      align: 'center',
      width: 80,
      sortable: false,
      renderCell: ({ row }) =>
        showCopyModal && (
          <Button color="success" onClick={() => showCopyModal(row)} sx={{ minWidth: 0 }}>
            <ContentCopy />
          </Button>
        ),
    },
  ] as GridColDef[];
};
