import React, { useState, useEffect } from 'react';
import { Box, TextField, Button, CircularProgress } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import CloseIcon from '@mui/icons-material/Close';
import './InputSearchWithButton.scss';
export interface InputSearchWithButtonProps {
  onSearchCallback: (q: string | null) => any; // TODO: use empty string instead of null
  disabled?: boolean;
  placeholder?: string;
  searchFilter: string | null; // TODO: use empty string instead of null
}

const InputSearchWithButton = ({
  onSearchCallback,
  disabled,
  placeholder,
  searchFilter,
}: InputSearchWithButtonProps) => {
  const [searchText, setSearchText] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isClearVisible, setIsClearVisible] = useState(false);
  const [searchClicked, setSearchClicked] = useState(false);
  const onSearch = async () => {
    setIsLoading(true);
    try {
      await onSearchCallback(searchText);
    } catch (err) {
      console.error(err);
    }
    setIsLoading(false);
    setIsClearVisible(true);
  };

  const onClear = async () => {
    setSearchText(null);
    setIsLoading(true);
    try {
      await onSearchCallback(null);
    } catch (err) {
      console.error(err);
    }
    setIsLoading(false);
    setIsClearVisible(false);
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (isClearVisible) {
      onClear();
    } else {
      onSearch();
    }
  };

  useEffect(() => {
    setSearchText(searchFilter);
    if (searchFilter && !searchClicked) {
      setIsClearVisible(true);
      setSearchClicked(true);
    }
  }, [searchFilter, searchClicked]);

  return (
    <form onSubmit={handleSubmit}>
      <Box
        display="flex"
        justifyContent="flex-end"
        alignItems="center"
        className="search-input__with-button"
      >
        <TextField
          variant="outlined"
          fullWidth
          title={placeholder || 'Search'}
          placeholder={placeholder || 'Search'}
          disabled={disabled || isLoading || isClearVisible}
          value={searchText || ''}
          onChange={e => setSearchText(e.target.value)}
          style={{
            height: '56px',
          }}
        />

        {isClearVisible ? (
          <Button
            color="secondary"
            variant="contained"
            type="submit"
            disabled={disabled || isLoading}
            style={{
              height: '56px',
              borderRadius: '0 5px 5px 0',
            }}
          >
            {isLoading ? <CircularProgress size="24px" /> : <CloseIcon />}
          </Button>
        ) : (
          <Button
            color="primary"
            variant="outlined"
            type="submit"
            disabled={disabled || isLoading || !searchText}
            style={{
              height: '56px',
              borderRadius: '0 5px 5px 0',
            }}
          >
            {isLoading ? <CircularProgress size="24px" /> : <SearchIcon />}
          </Button>
        )}
      </Box>
    </form>
  );
};

export default InputSearchWithButton;
