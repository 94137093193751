import React, { useState, useEffect, useContext, useMemo } from 'react';
import {
  Box,
  Grid,
  FormControl,
  DialogContent,
  TextField,
  MenuItem,
  RadioGroup,
  FormControlLabel,
  Radio,
  AutocompleteRenderInputParams,
  Autocomplete,
  Switch,
} from '@mui/material';
import { useSelector } from 'react-redux';
import { thunkDispatch } from 'store/store';
import ModalHeader from 'sharedComponents/ModalHeader';
import { dispatchSchema } from 'modules/civilAndTrucking/trucking/schemas';
import DialogActionContainer from 'sharedComponents/DialogActionContainer';
import { ValidationError } from 'yup';
import ButtonToggle from 'sharedComponents/ButtonToggle';
import { ModalContext } from 'sharedComponents/ModalContext';
import { upsertDispatch } from '../../../redux/actions';
import { getJoinedProjectMaterialsByProjectId } from 'modules/civilAndTrucking/trucking/features/ProjectMaterials/redux/actions';
import { selectJoinedProjectMaterials } from 'modules/civilAndTrucking/trucking/features/ProjectMaterials/redux/projectMaterialsStoreSlice';
import { unitsOfMeasure } from 'lg-helpers/dist/constants/global-constants';
import { toDateStr } from 'modules/civilAndTrucking/trucking/utils';
import { IGeocoderFeature } from 'services/mapbox/geocoding';
import InputAddressAutocomplete from 'sharedComponents/InputAddressAutocomplete';
import { FormTextField } from 'sharedComponents/FormTextField';
import { FormCustomField } from 'sharedComponents/FormCustomField';
import { extractFieldsByTuple } from 'modules/civilAndTrucking/trucking/utils';
import {
  DispatchType,
  FormTruckingDispatch,
  ITruckingDispatch,
  KTruckingDispatch,
} from 'lg-helpers/dist/trucking/interfaces/ITruckingDispatch';
import { IHelpers } from 'lg-helpers/dist/trucking/interfaces/IHelpers';
import { IJoinProjectMaterial } from 'lg-helpers/dist/shared/interfaces/IJoinProjectMaterial';
import { IJoinTruckingDispatch } from 'lg-helpers/dist/trucking/interfaces/IJoinTruckingDispatch';
import { selectJoinedProjects } from 'modules/civilAndTrucking/trucking/features/Projects/redux/projectsStoreSlice';
import { getProjectsByCompanyId } from 'modules/civilAndTrucking/trucking/features/Projects/redux/actions';
import api, { ConnectedCompanyType } from 'services/api/autogenerated';
import useApiGetRequest from 'services/api/useApiGetRequest';
import fuzzyFilterOptions from 'utils/fuzzyFilterOptions';
import { ITruckingProject } from 'lg-helpers/dist/trucking/interfaces/ITruckingProject';
import { selectTruckTypes } from 'modules/civilAndTrucking/trucking/features/TruckTypes/redux/truckTypesStoreSlice';
import { getTruckTypesByCompanyId } from 'modules/civilAndTrucking/trucking/features/TruckTypes/redux/actions';

const initialDispatchState = {
  dateTruckingDispatch: toDateStr(new Date()),
  typeTruckingDispatch: 'Import',
} as ITruckingDispatch;

type UpsertDispatchFormProps = {
  editObj?: ITruckingDispatch;
};

interface DropDownRecordProps<TId> {
  id: TId;
  name: string;
}

interface IMaterialsWithTruckType extends IJoinProjectMaterial {
  truckTypeName: string;
}

const extractFormDispatchFields = (editObj?: ITruckingDispatch) => {
  if (editObj) {
    return {
      ...initialDispatchState,
      ...extractFieldsByTuple<FormTruckingDispatch>(editObj, KTruckingDispatch),
    };
  } else {
    return initialDispatchState as FormTruckingDispatch;
  }
};

const companiesFilterOptions = fuzzyFilterOptions<DropDownRecordProps<string>>(['name']);

export const UpsertDispatchForm = ({ editObj }: UpsertDispatchFormProps) => {
  const [dispatch, setDispatch] = useState(extractFormDispatchFields(editObj));
  const [helpers, setHelpers] = useState({} as IHelpers);
  const [filteredProjectMaterials, setFilteredProjectMaterials] = useState<
    IMaterialsWithTruckType[]
  >([]);
  const { handleModal } = useContext(ModalContext);
  const { joinedProjectMaterials } = useSelector(selectJoinedProjectMaterials);
  const { joinedProjects: projects } = useSelector(selectJoinedProjects);
  const { truckTypes } = useSelector(selectTruckTypes);

  useEffect(() => {
    thunkDispatch(getTruckTypesByCompanyId());
  }, []);

  const [filteredSourceSuppliers] = useApiGetRequest(
    () =>
      api.companiesConnections.getCompanyConnectionsByProjectMaterialIds(
        joinedProjectMaterials.map(m => Number(m.idTruckingProjectMaterial))
      ),
    () => ({ title: 'Failed to load source suppliers' }),
    [joinedProjectMaterials]
  );

  // TODO: this list could be large (optimize in future)
  const [availableContractors] = useApiGetRequest(
    () =>
      api.companiesConnections.getConnectedCompaniesByTypes([
        ConnectedCompanyType.Contractor,
        ConnectedCompanyType.TruckingContractor,
      ]),
    () => ({ title: 'Failed to load contractors' })
  );

  // selectors for autocomplete boxes
  const [selectedContractorCompany, setSelectedContractorCompany] =
    useState<DropDownRecordProps<string> | null>(null);
  const [selectedProject, setSelectedProject] = useState<DropDownRecordProps<number> | null>(null);

  const mapDropDownRecord = <TId,>(id: TId, name: string) => {
    return {
      id: id,
      name: name,
    } as DropDownRecordProps<TId>;
  };

  const IS_MANUAL = dispatch?.isManualTruckingDispatch;
  const project = useMemo(
    () => projects.find(proj => proj?.idTruckingProject === selectedProject?.id) || null,
    [projects, selectedProject]
  );
  const alphabeticSort = (a: string, b: string) => {
    if (a < b) return -1;
    if (a > b) return 1;
    return 0;
  };

  const projectOptions = useMemo(
    () =>
      projects
        .filter(
          proj =>
            proj.activeTruckingProject &&
            (selectedContractorCompany?.id
              ? proj.contractorCompanyIdTruckingProject === selectedContractorCompany?.id
              : true)
        )
        .sort((a, b) => alphabeticSort(a.nameTruckingProject, b.nameTruckingProject))
        .map(proj =>
          mapDropDownRecord<number>(
            proj?.idTruckingProject ?? 0,
            `${proj.nameTruckingProject} - #${proj.projectNumberTruckingProject}`
          )
        ),
    [projects, selectedContractorCompany]
  );

  const contractorOptions = useMemo(() => {
    const orderedContractors = (availableContractors || [])
      .sort((a, b) => alphabeticSort(a.connected_company?.name, b.connected_company?.name))
      .map(
        ac =>
          ({
            id: ac.connected_company?.id,
            name: ac.connected_company?.name,
          } as DropDownRecordProps<string>)
      );
    return [...new Map(orderedContractors.map(contractor => [contractor.id, contractor])).values()];
  }, [availableContractors]);

  useEffect(() => {
    thunkDispatch(getProjectsByCompanyId('all'));
  }, []);

  useEffect(() => {
    if (!editObj) return;

    thunkDispatch(getJoinedProjectMaterialsByProjectId(Number(editObj?.projectIdTruckingDispatch)));

    const proj = projects.find(jp => jp.idTruckingProject === editObj.projectIdTruckingDispatch);
    const contractor = (availableContractors || []).find(
      ac => ac.connected_company_id === proj?.contractorCompanyIdTruckingProject
    );

    setSelectedContractorCompany(
      mapDropDownRecord<string>(
        contractor?.connected_company?.id ?? '',
        contractor?.connected_company?.name ?? ''
      )
    );
    setSelectedProject(
      mapDropDownRecord<number>(
        proj?.idTruckingProject ?? 0,
        `${proj?.nameTruckingProject} - #${proj?.projectNumberTruckingProject}`
      )
    );
    //eslint-disable-next-line
  }, []);

  /**
   * This is to filter the materials
   * depending on selected material source.
   */
  useEffect(() => {
    const selectedSourceSupplierId = dispatch?.truckingCompanySourceSupplierIdTruckingDispatch;
    const newFilteredProjectMaterials = joinedProjectMaterials
      .filter(
        pm =>
          String(pm.companySourceSupplierIdTruckingProjectMaterial) ===
          String(selectedSourceSupplierId)
      )
      .map(pm => {
        return {
          ...pm,
          truckTypeName: truckTypes.find(
            types =>
              String(types.idTruckingTruckType) === String(pm.truckTypeIdTruckingProjectMaterial)
          )?.nameTruckingTruckType as string,
        };
      });
    setFilteredProjectMaterials(newFilteredProjectMaterials);
    //eslint-disable-next-line
  }, [dispatch, joinedProjectMaterials]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setDispatch({ ...dispatch, [e.target.name]: e.target.value });
  };

  const handleInvalidSubmit = () => {
    dispatchSchema.validate(dispatch, { abortEarly: false }).catch((valErrors: ValidationError) => {
      const help = {} as IHelpers;
      valErrors.inner.forEach(err => {
        help[err.path] = err.message;
      });
      setHelpers(help);
    });
  };

  const onSubmit = async () => {
    const isValidDispatch = await dispatchSchema.isValid(dispatch);
    if (!isValidDispatch) {
      handleInvalidSubmit();
    } else {
      thunkDispatch(upsertDispatch(dispatch));
      handleModal();
    }
  };

  const handleAddressSelected = async (geocoderValue: IGeocoderFeature) => {
    if (dispatch.addressString !== geocoderValue.place_name) {
      setDispatch({
        ...dispatch,
        addressString: geocoderValue.place_name,
        geo: geocoderValue.geometry.coordinates,
      });
    }
  };

  const assignContractorFromProjectId = (id: number) => {
    const contractorId = ((projects as ITruckingProject[]) || []).find(
      p => p?.idTruckingProject === id
    )?.contractorCompanyIdTruckingProject;
    const newSelectedContractorCompany = contractorOptions.find(c => c.id === contractorId);
    if (newSelectedContractorCompany)
      setSelectedContractorCompany(
        mapDropDownRecord<string>(
          newSelectedContractorCompany?.id ?? '',
          newSelectedContractorCompany?.name ?? ''
        )
      );
  };

  const changeProjectHandler = (value: DropDownRecordProps<number> | null) => {
    if (!value) {
      setSelectedProject(null);
      setDispatch({
        ...dispatch,
        truckingCompanySourceSupplierIdTruckingDispatch: undefined,
        projectMaterialIdTruckingDispatch: undefined,
        projectIdTruckingDispatch: undefined,
      });
      return;
    }

    assignContractorFromProjectId(value.id);
    thunkDispatch(getJoinedProjectMaterialsByProjectId(value.id));
    setSelectedProject(value);

    setDispatch({
      ...dispatch,
      truckingCompanySourceSupplierIdTruckingDispatch: undefined,
      projectMaterialIdTruckingDispatch: undefined,
      projectIdTruckingDispatch: value.id,
    });
  };

  const changeContractorHandler = (value: DropDownRecordProps<string> | null) => {
    if (!value) {
      setSelectedContractorCompany(null);
      setSelectedProject(null);
      return;
    }

    setSelectedContractorCompany(value);
    setSelectedProject(null);
    setDispatch({
      ...dispatch,
      truckingCompanySourceSupplierIdTruckingDispatch: undefined,
      projectMaterialIdTruckingDispatch: undefined,
    });
  };

  const handleChangeMaterial = (e: React.ChangeEvent<HTMLInputElement>) => {
    const selectedMaterialId = Number(e.target.value);
    const updatedDispatch = {
      ...dispatch,
      [e.target.name]: e.target.value,
    };

    if (!updatedDispatch?.isManualTruckingDispatch) {
      const projectMaterial = filteredProjectMaterials?.find(
        projMaterial => projMaterial.idTruckingProjectMaterial === selectedMaterialId
      );

      if (projectMaterial?.profileNumberTruckingProjectMaterial) {
        updatedDispatch.typeTruckingDispatch = DispatchType.Landfill;
      } else {
        updatedDispatch.typeTruckingDispatch = DispatchType.Import;
      }
    }

    setDispatch(updatedDispatch);
  };

  const handleSwitchChange = (name: string, value: boolean) =>
    setDispatch(prevState => ({ ...prevState, [name]: value }));

  const toggleDispatchType = (toggleIndex: number) => {
    // When switching from manual to project, clear manual dispatch state
    const filteredEditObj = {
      ...extractFormDispatchFields(editObj),
      manualLandfillProfileNumberTruckingDispatch: null,
      manualContractorNameTruckingDispatch: null,
      manualContractorProjectNumberTruckingDispatch: null,
      manualMaterialSourceTruckingDispatch: null,
      manualProjectNameTruckingDispatch: null,
      manualLocationIdTruckingDispatch: null,
      manualMaterialNameTruckingDispatch: null,
      manualMaterialUomTruckingDispatch: null,
    } as unknown as IJoinTruckingDispatch;
    setDispatch({
      ...filteredEditObj,
      isManualTruckingDispatch: !!toggleIndex,
    });
  };

  const locationValue = IS_MANUAL ? dispatch?.addressString : project?.addressString;

  return (
    <Box sx={{ p: '0px 16px' }}>
      <ModalHeader
        title={`${dispatch?.idTruckingDispatch ? 'Edit' : 'Add'} Dispatch`}
        optionalElement={
          <ButtonToggle
            firstVal={'PROJECT'}
            secondVal={'MANUAL'}
            disabled={!!editObj?.idTruckingDispatch && !dispatch?.isManualTruckingDispatch}
            toggleIndex={dispatch.isManualTruckingDispatch ? 1 : 0}
            setToggleIndex={num => toggleDispatchType(num)}
          />
        }
      />
      <form id="edit-dispatch" onSubmit={onSubmit}>
        <DialogContent>
          <Grid container columnSpacing={2}>
            <Grid item xs={6}>
              <FormTextField
                required
                type="date"
                name="dateTruckingDispatch"
                label="Date"
                value={dispatch?.dateTruckingDispatch}
                helperField={helpers.dateTruckingDispatch}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={6}>
              <FormTextField
                name="lotTruckingDispatch"
                label="Lot No."
                value={dispatch?.lotTruckingDispatch}
                helperField={helpers.lotTruckingDispatch}
                onChange={handleChange}
              />
            </Grid>

            <Grid item xs={6}>
              {IS_MANUAL ? (
                <FormTextField
                  required
                  disabled={!IS_MANUAL}
                  name="manualContractorNameTruckingDispatch"
                  label="Contractor"
                  value={dispatch?.manualContractorNameTruckingDispatch}
                  onChange={handleChange}
                  helperField={helpers?.manualContractorNameTruckingDispatch}
                />
              ) : (
                <Autocomplete
                  size="small"
                  value={selectedContractorCompany}
                  options={contractorOptions}
                  getOptionLabel={(option: DropDownRecordProps<string>) => option.name}
                  onChange={(event, value) => changeContractorHandler(value)}
                  filterOptions={companiesFilterOptions}
                  renderOption={(props, option) => {
                    return (
                      <li {...props} key={`contractor__${option.id}`}>
                        {option.name}
                      </li>
                    );
                  }}
                  renderInput={params => (
                    <TextField
                      required
                      {...params}
                      label="Contractor"
                      placeholder="Select Contractor"
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: <>{params.InputProps.endAdornment}</>,
                      }}
                      helperText={helpers?.contractorCompanyIdTruckingProject}
                    />
                  )}
                ></Autocomplete>
              )}
            </Grid>
            <Grid item xs={6}>
              <FormTextField
                disabled={!IS_MANUAL}
                name="manualContractorProjectNumberTruckingDispatch"
                label="Contractor Project No."
                value={
                  IS_MANUAL
                    ? dispatch?.manualContractorProjectNumberTruckingDispatch
                    : project?.contractorProjectNumberTruckingProject || ''
                }
                onChange={handleChange}
                helperField={helpers?.manualContractorProjectNumberTruckingDispatch}
              />
            </Grid>

            <Grid item xs={6}>
              {IS_MANUAL ? (
                <FormTextField
                  required
                  name={'manualProjectNameTruckingDispatch'}
                  label="Project"
                  value={dispatch?.manualProjectNameTruckingDispatch}
                  onChange={handleChange}
                  helperField={helpers?.manualProjectNameTruckingDispatch}
                />
              ) : (
                <Autocomplete
                  size="small"
                  value={selectedProject}
                  options={projectOptions}
                  getOptionLabel={(option: DropDownRecordProps<number>) => option?.name ?? ''}
                  onChange={(event, value) => changeProjectHandler(value)}
                  renderOption={(props, option) => {
                    return (
                      <li {...props} key={`contractor__${option.id}`}>
                        {option.name}
                      </li>
                    );
                  }}
                  renderInput={params => (
                    <TextField
                      {...params}
                      required
                      label="Project"
                      placeholder="Select Project"
                      helperText={helpers?.projectIdTruckingDispatch}
                      error={!!helpers.projectIdTruckingDispatch}
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: <>{params.InputProps.endAdornment}</>,
                      }}
                    />
                  )}
                ></Autocomplete>
              )}
            </Grid>
            <Grid item xs={6}>
              <FormTextField
                name="poNumberTruckingDispatch"
                label="PO No."
                value={dispatch?.poNumberTruckingDispatch}
                onChange={handleChange}
                helperField={helpers?.poNumberTruckingDispatch}
              />
            </Grid>

            <Grid item xs={12}>
              <FormCustomField
                name="address"
                label="Location"
                component={
                  <InputAddressAutocomplete
                    disabled={!IS_MANUAL}
                    searchRequirement={5}
                    size="small"
                    options={[]}
                    handleSelect={handleAddressSelected}
                    inputValue={locationValue || ''}
                    renderInput={(params: AutocompleteRenderInputParams) => (
                      <TextField
                        {...params}
                        name="addressString"
                        error={!!helpers.addressString}
                        helperText={helpers.addressString}
                        onChange={handleChange}
                        variant="outlined"
                        value={locationValue || ''}
                      />
                    )}
                  />
                }
              />
            </Grid>
            {/* TODO: remove below condition when timesheet is production ready */}
            {process.env.REACT_APP_BUILD_ENV !== 'production' && (
              <Grid item xs={12}>
                <FormControlLabel
                  control={
                    <Switch
                      inputProps={{ 'aria-label': 'Require Driver Timesheet' }}
                      color="secondary"
                      size="small"
                      name="isTimesheetEnabledTruckingDispatch"
                      checked={dispatch?.isTimesheetEnabledTruckingDispatch ?? false}
                      onChange={e =>
                        handleSwitchChange(
                          e.target.name,
                          !dispatch?.isTimesheetEnabledTruckingDispatch
                        )
                      }
                    />
                  }
                  label="Require Driver Timesheet"
                />
              </Grid>
            )}
            <Grid item xs={6}>
              <FormTextField
                required
                name={
                  IS_MANUAL
                    ? 'manualMaterialSourceTruckingDispatch'
                    : 'truckingCompanySourceSupplierIdTruckingDispatch'
                }
                label="Material Source"
                value={
                  IS_MANUAL
                    ? dispatch?.manualMaterialSourceTruckingDispatch
                    : dispatch?.truckingCompanySourceSupplierIdTruckingDispatch
                }
                onChange={handleChange}
                helperField={
                  IS_MANUAL
                    ? helpers?.manualMaterialSourceTruckingDispatch
                    : helpers?.truckingCompanySourceSupplierIdTruckingDispatch
                }
                selectOptions={
                  IS_MANUAL
                    ? null
                    : (filteredSourceSuppliers || []).map(source => (
                        <MenuItem key={source.id} value={source.id}>
                          {source.connected_company?.name}
                        </MenuItem>
                      ))
                }
              />
            </Grid>
            <Grid item xs={6}>
              <FormTextField
                required
                name={
                  IS_MANUAL
                    ? 'manualMaterialNameTruckingDispatch'
                    : 'projectMaterialIdTruckingDispatch'
                }
                label="Material"
                value={
                  IS_MANUAL
                    ? dispatch?.manualMaterialNameTruckingDispatch
                    : dispatch?.projectMaterialIdTruckingDispatch
                }
                onChange={handleChangeMaterial}
                helperField={
                  IS_MANUAL
                    ? helpers?.manualMaterialNameTruckingDispatch
                    : helpers?.projectMaterialIdTruckingDispatch
                }
                selectOptions={
                  IS_MANUAL
                    ? null
                    : filteredProjectMaterials.map(material => (
                        <MenuItem
                          key={material.idTruckingProjectMaterial}
                          value={material.idTruckingProjectMaterial}
                        >
                          {`${material.nameTruckingCompanyMaterial} - ${material?.truckTypeName}`}
                        </MenuItem>
                      ))
                }
              />
            </Grid>
            <Grid item xs={4} display="flex" alignItems="center" justifyContent="center">
              <FormControl fullWidth>
                <RadioGroup
                  aria-label="Dispatch type"
                  name="typeTruckingDispatch"
                  onChange={handleChange}
                  value={dispatch?.typeTruckingDispatch}
                  row
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-around',
                  }}
                >
                  <FormControlLabel
                    value="Import"
                    control={<Radio color="success" />}
                    label="Import"
                    labelPlacement="bottom"
                    disabled={
                      !IS_MANUAL && dispatch?.typeTruckingDispatch === DispatchType.Landfill
                    }
                  />
                  <FormControlLabel
                    value="Haul Off"
                    control={<Radio color="success" />}
                    label="Haul-off"
                    labelPlacement="bottom"
                    disabled={
                      !IS_MANUAL && dispatch?.typeTruckingDispatch === DispatchType.Landfill
                    }
                  />
                  <FormControlLabel
                    value="Landfill"
                    control={<Radio color="success" />}
                    label="Landfill"
                    labelPlacement="bottom"
                    disabled={!IS_MANUAL}
                  />
                </RadioGroup>
              </FormControl>
            </Grid>
            <Grid item xs={2}>
              {dispatch?.typeTruckingDispatch === 'Landfill' && (
                <FormTextField
                  disabled={!IS_MANUAL}
                  name="manualLandfillProfileNumberTruckingDispatch"
                  label="Profile No."
                  value={
                    IS_MANUAL
                      ? dispatch?.manualLandfillProfileNumberTruckingDispatch
                      : filteredProjectMaterials.find(
                          material =>
                            material.idTruckingProjectMaterial ===
                            dispatch?.projectMaterialIdTruckingDispatch
                        )?.profileNumberTruckingProjectMaterial || ''
                  }
                  onChange={handleChange}
                  helperField={helpers?.manualLandfillProfileNumberTruckingDispatch}
                />
              )}
            </Grid>
            <Grid item xs={6}>
              <FormTextField
                name="billToTruckingDispatch"
                label="Bill to"
                value={dispatch?.billToTruckingDispatch}
                onChange={handleChange}
                helperField={helpers.billToTruckingDispatch}
              />
            </Grid>
            <Grid item xs={4}>
              <FormTextField
                required
                name="materialQuantityTruckingDispatch"
                label="Material Quantity"
                value={dispatch?.materialQuantityTruckingDispatch}
                onChange={handleChange}
                helperField={helpers.materialQuantityTruckingDispatch}
              />
            </Grid>
            <Grid item xs={2}>
              <FormTextField
                required
                disabled={!IS_MANUAL}
                name="manualMaterialUomTruckingDispatch"
                label="Unit of Measure"
                value={
                  IS_MANUAL
                    ? dispatch?.manualMaterialUomTruckingDispatch
                    : joinedProjectMaterials.find(
                        material =>
                          material.idTruckingProjectMaterial ===
                          dispatch?.projectMaterialIdTruckingDispatch
                      )?.uomTruckingProjectMaterial || ''
                }
                onChange={handleChange}
                helperField={helpers?.manualMaterialUomTruckingDispatch}
                selectOptions={
                  IS_MANUAL
                    ? unitsOfMeasure.map(unit => (
                        <MenuItem key={unit} value={unit}>
                          {unit}
                        </MenuItem>
                      ))
                    : null
                }
              />
            </Grid>
            <Grid item xs={6}>
              <FormTextField
                name="specialInstructionsTruckingDispatch"
                label="Special Instructions"
                value={dispatch?.specialInstructionsTruckingDispatch}
                onChange={handleChange}
                helperField={helpers.specialInstructionsTruckingDispatch}
              />
            </Grid>
          </Grid>
          <DialogActionContainer
            // deleteHandler={handleDeleteDispatch}
            editing={!!editObj}
          />
        </DialogContent>
      </form>
    </Box>
  );
};
