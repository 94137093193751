import React from 'react';
import { Link } from 'react-router-dom';

import LivegisticsLogoSmall from 'sharedComponents/pictograms/LivegisticsLogoSmall';
import LivegisticsLogoLarge from 'sharedComponents/pictograms/LivegisticsLogoLarge';

interface LogoImageProps {
  width: 'lg' | 'md' | 'sm' | 'xl' | 'xs';
}

const LogoImage = ({ width }: LogoImageProps): JSX.Element => {
  if (width === 'xs') {
    return <LivegisticsLogoSmall />;
  }
  return <LivegisticsLogoLarge />;
};

interface LogoLinkProps {
  className?: string;
  alternate?: boolean;
  width: 'lg' | 'md' | 'sm' | 'xl' | 'xs';
}

const LogoLink = ({ className = 'logo', ...props }: LogoLinkProps): JSX.Element => (
  <Link to="/" className={className} role="Logo">
    <LogoImage {...props} />
  </Link>
);

export default LogoLink;
