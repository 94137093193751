import { createAsyncThunk } from '@reduxjs/toolkit';
import { get, post, del } from 'services/api/restHelpers';
import { INotification } from 'modules/civilAndTrucking/shared/NotificationSnackbar/INotification';
import { pushNotification } from 'modules/civilAndTrucking/shared/NotificationSnackbar/redux/actions';
import { IActivityType } from 'lg-helpers/dist/shared/interfaces/IActivityType';

export const getActivityTypes = createAsyncThunk(
  'activity-types/get-types',
  async (payload: { projectId: number }, thunkApi) => {
    try {
      const data = await get(`civil/projects/${payload.projectId}/activities/types`);
      return data;
    } catch (err) {
      const errorNotification: INotification = {
        status: 'error',
        message: `Error: Unable to get activity type`,
      };
      thunkApi.dispatch(pushNotification(errorNotification));
      throw err;
    }
  }
);

export const addActivityType = createAsyncThunk(
  'activity-types/add-type',
  async (payload: { workCategory: IActivityType; projectId: number }, thunkApi) => {
    try {
      let newPayload = {
        name: payload.workCategory.name,
        id: payload.workCategory.id,
        projectId: payload.projectId,
      };
      const newData = await post(
        `civil/projects/${payload.projectId}/activities/types`,
        newPayload
      );
      const successNotification: INotification = {
        status: 'success',
        message: `Success: ${payload.workCategory.id ? 'Updated' : 'Added'} ${
          payload.workCategory.name
        }`,
      };
      thunkApi.dispatch(pushNotification(successNotification));
      const returnData = { ...newData!, count: payload.workCategory.count };
      return returnData;
    } catch (err) {
      const errorNotification: INotification = {
        status: 'error',
        message: `Error: Unable to ${payload.workCategory.id ? 'update' : 'add'} ${
          payload.workCategory.name
        }`,
      };
      thunkApi.dispatch(pushNotification(errorNotification));
      throw err;
    }
  }
);

export const deleteActivityType = createAsyncThunk(
  'acitivity-types/delete-type',
  async (payload: { workCategory: IActivityType; projectId: number }, thunkApi) => {
    try {
      await del(`civil/projects/${payload.projectId}/activities/types/${payload.workCategory.id}`);
      const successNotification: INotification = {
        status: 'success',
        message: `Success: Removed ${payload.workCategory.name}`,
      };
      thunkApi.dispatch(pushNotification(successNotification));
      return payload;
    } catch (err) {
      const errorNotification: INotification = {
        status: 'error',
        message: `Error: Unable to remove activity type`,
      };
      thunkApi.dispatch(pushNotification(errorNotification));
      throw err;
    }
  }
);
