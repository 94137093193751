import { useCallback, useState } from 'react';
import useShowError from 'modules/errors';
import { downloadManifestPdfAndGetUrl } from 'api/manifest';
import { Manifest } from 'lg-helpers/dist/shared/types/manifests';

export default function useDownloadManifestOpenNewPage(manifest: Manifest) {
  const showError = useShowError();
  const [hasOpen, setHasOpened] = useState(false);

  const openTabbedManifest = useCallback(async () => {
    let w;
    try {
      w = window.open('', '_blank');
      w!.document.write(`<!DOCTYPE html>
      <html>
        <head>
          <title>Title of the document</title>
          <style>
            body {
              margin-top: 100px;
              background-color: #41793a;
              color: #fff;
              text-align: center;
            }
            h1 {
              font: 1.5em 'Roboto', sans-serif;
              margin-bottom: 30px;
            }
            .spin {
              display: inline-block;
              width: 50px;
              height: 50px;
              border: 3px solid rgba(255, 255, 255, .3);
              border-radius: 50%;
              border-top-color: #fff;
              animation: spin 1s ease-in-out infinite;
              -webkit-animation: spin 1s ease-in-out infinite;
            }
            @keyframes spin {
              to {
                -webkit-transform: rotate(360deg);
              }
            }
            @-webkit-keyframes spin {
              to {
                -webkit-transform: rotate(360deg);
              }
            }
          </style>
        </head>
        <body>
          <h1>Please wait and do not close this tab!</h1>
          <h1>Manifest ${manifest.number} download is in progress...</h1>
          <div class="spin"></div>
        </body>
      </html>`);
      w!.document.close();
      const url = await downloadManifestPdfAndGetUrl(manifest.id);
      w!.location = url;
      setHasOpened(true);
    } catch (err: any) {
      console.error(err);
      if (w) w.close();
      showError({
        title: 'Download manifest failed',
        text: err.message,
        duration: 10000,
      });
    }
  }, [manifest, setHasOpened, showError]);

  return {
    openTabbedManifest,
    hasOpen,
  };
}
