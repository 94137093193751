import React from 'react';
import { Grid, Button, Typography, TextField } from '@mui/material';
import { DatePicker, LocalizationProvider, TimePicker } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import api from '../../../services/api/autogenerated';
import moment from 'moment';

interface CreateWorkCodeProps {
  projectId: string;
  onWorkCodeUpdate: () => void;
  skipHeader?: boolean;
}

const CreateWorkCode: React.FC<CreateWorkCodeProps> = ({
  projectId,
  onWorkCodeUpdate,
  skipHeader,
}) => {
  const [expiryDate, setExpiryDate] = React.useState<Date>(new Date());

  // Sets the time to 11:59 PM of the current day
  const defaultExpiryTime = moment().endOf('day');
  const [expiryTime, setExpiryTime] = React.useState<Date>(defaultExpiryTime.toDate());

  const [nickname, setNickname] = React.useState<string>('');

  const combineDateTime = (date: Date, time: Date) => {
    const year = date.getFullYear();
    const month = date.getMonth();
    const day = date.getDate();
    const hours = time.getHours();
    const minutes = time.getMinutes();

    const combinedDateTime = moment([year, month, day, hours, minutes]);
    return combinedDateTime.toDate().toISOString();
  };

  const createWorkCode = async () => {
    const expirationDate = combineDateTime(expiryDate, expiryTime);
    await api.workCode.createWorkCodeRequest({ projectId, expirationDate, nickname });
    onWorkCodeUpdate();
  };

  return (
    <>
      {!skipHeader && <Typography variant="h6">First, create a work code:</Typography>}
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <Grid container spacing={2} alignItems="center">
          <Grid item>
            <DatePicker
              label="Expiration Date"
              value={expiryDate}
              onChange={(newValue: Date | null) => {
                newValue && setExpiryDate(newValue);
              }}
            />
          </Grid>
          <Grid item>
            <TimePicker
              label="Expiration Time"
              value={expiryTime}
              onChange={(newValue: Date | null) => {
                newValue && setExpiryTime(newValue);
              }}
            />
          </Grid>
          <Grid item>
            <TextField
              placeholder="Nickname (optional)"
              name="startTime"
              variant="outlined"
              value={nickname}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                setNickname(event.target.value);
              }}
            />
          </Grid>
          <Grid item>
            <Button variant="contained" onClick={() => createWorkCode()}>
              Create Code
            </Button>
          </Grid>
        </Grid>
      </LocalizationProvider>
    </>
  );
};

export default CreateWorkCode;
