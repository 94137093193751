import React from 'react';
import './pictograms.scss';

const LivegisticsLogoSmall = () => (
  <div className="Logo__small">
    <img className="Logo__small--image" alt="Logo" src="/static/images/logo-small.png" />
  </div>
);

export default LivegisticsLogoSmall;
