import React from 'react';
import { createSvgIcon } from '@mui/material';

export const SourceSupplier = createSvgIcon(
  <path
    fillRule="evenodd"
    clipRule="evenodd"
    d="M0 32.1168h4.57153v-8.3613C1.78886 25.6769 0 28.7113 0 32.1168Zm15.9805-7.447c-2.7694 0-5.1281-1.2058-6.08213-2.9152-.71555.0928-1.40459.2385-2.06713.4505V32.13H24.143v-9.9249c-.6625-.1987-1.3516-.3577-2.0671-.4505-.9673 1.7094-3.326 2.9152-6.0954 2.9152Zm-2.6369-3.0212c.8215.3578 1.7093.5698 2.6236.5698.9276 0 1.8022-.1988 2.637-.5698 1.3913-.6228 2.6104-1.7226 3.5247-3.1404 1.0203-1.5901 1.6563-3.5778 1.7226-5.7509-2.6104.3578-5.2473.5035-7.8843.5035-2.6369 0-5.2605-.1457-7.87095-.5035.07951 2.1731.71555 4.1608 1.72261 5.7509.91434 1.4178 2.13334 2.5176 3.52474 3.1404ZM24.567 9.10007c0-3.3657-1.9346-6.26763-4.7305-7.67222l-.3578 2.04062-.424 2.41166c-.053.30476-.3445.51678-.6493.46377-.3048-.053-.5168-.34452-.4638-.64929l.4903-2.74292.3843-2.22614c-.1325-.41077-.5168-.71554-.9806-.71554h-3.697c-.4638 0-.848.30477-.9805.71554l.3842 2.22614.4903 2.74292c.053.30477-.159.59629-.4638.64929-.3047.05301-.5962-.15901-.6492-.46377l-.4241-2.41166-.3577-2.04062C9.32858 2.83244 7.39396 5.73437 7.39396 9.10007c-.62279.05301-1.11307.58304-1.11307 1.21913 0 .6625.55653 1.113 1.21908 1.219.19876.0265.39752.0663.60953.0928 2.5309.3578 5.1546.5168 7.871.5168s5.3401-.159 7.871-.5036c.1987-.0265.4108-.0662.6095-.0927.6626-.106 1.2191-.5433 1.2191-1.2191-.0133-.64929-.4903-1.17932-1.1131-1.23233Zm2.8092 14.65543v8.3613h4.5715c.0133-3.4055-1.7888-6.4399-4.5715-8.3613Z"
  />,
  'Source Supplier'
);
