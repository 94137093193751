import React, { useContext, useState } from 'react';
import { TextField, FormControl, FormHelperText, InputLabel, Grid } from '@mui/material';
import { useSelector } from 'react-redux';

import { useFormik } from 'formik';
import { activityTypeSchema } from 'modules/civilAndTrucking/civil/ActivityTypes/ActivityTypeForm/schemas';
import DialogActionContainer from 'sharedComponents/DialogActions';
import { addActivityType } from 'modules/civilAndTrucking/civil/ActivityTypes/redux/actions';
import { RootState, thunkDispatch } from 'store/store';
import { ModalContext } from 'sharedComponents/ModalContext';
import { IActivityType } from 'lg-helpers/dist/shared/interfaces/IActivityType';

interface AcitivtyTypeFormProps {
  selected?: IActivityType;
  projectId?: number;
}

const AcitivtyTypeForm = ({ selected, projectId }: AcitivtyTypeFormProps) => {
  const { handleModal } = useContext(ModalContext);
  const [isSaving, setIsSaving] = useState<boolean>(false);

  const workCategoryState = useSelector((state: RootState) => state.civil.activityTypes.data);

  const handleSubmit = async (values: IActivityType) => {
    if (workCategoryState.some(item => item.name === values.name)) {
      return;
    }
    try {
      setIsSaving(true);
      const newActivityTypeData: IActivityType = {
        name: values.name,
        id: values.id,
        count: values.count,
      };
      const result = await thunkDispatch(
        addActivityType({
          workCategory: newActivityTypeData,
          projectId: Number(projectId),
        })
      );
      if (result.meta.requestStatus === 'fulfilled') {
        formik.resetForm();
        handleModal();
        setIsSaving(false);
        return;
      }
      setIsSaving(false);
    } catch (err) {
      setIsSaving(true);
      console.error('Unable to create work category: ', err);
    }
  };

  const formik = useFormik({
    validationSchema: activityTypeSchema,
    initialValues: selected
      ? selected
      : ({
          name: '',
        } as IActivityType),
    onSubmit: handleSubmit,
  });

  return (
    <>
      <Grid container>
        <Grid item xs={12}>
          <FormControl fullWidth>
            <InputLabel htmlFor="name" shrink>
              Name
            </InputLabel>
            <TextField
              name="name"
              value={formik.values.name}
              error={formik.touched.name && !!formik.errors.name}
              helperText={formik.touched.name && formik.errors.name}
              onChange={formik.handleChange}
              variant="outlined"
              autoFocus
            />
            <FormHelperText />
          </FormControl>
        </Grid>
      </Grid>
      <DialogActionContainer
        saveHandler={formik.handleSubmit}
        editing={!!selected}
        isSaving={isSaving}
      />
    </>
  );
};

export default AcitivtyTypeForm;
