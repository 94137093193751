import React, { useContext, useState } from 'react';
import { InputLabel, FormControl, FormHelperText, TextField, Grid } from '@mui/material';

import { useFormik } from 'formik';
import { activitySchema } from './schemas';
import DialogActionContainer from 'sharedComponents/DialogActions';
import { addActivity, getActivitiesByWorkCategory } from '../redux/actions';
import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RootState, thunkDispatch } from 'store/store';
import { ModalContext } from 'sharedComponents/ModalContext';
import { IActivity } from 'lg-helpers/dist/shared/interfaces/IActivity';

interface IActivityFormProps {
  selected?: IActivity;
}

const ActivitiesForm = ({ selected }: IActivityFormProps) => {
  const { search } = useLocation();
  const { handleModal } = useContext(ModalContext);
  const query = new URLSearchParams(search);
  const activityTypeId = query.get('type');
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const activityState = useSelector((state: RootState) => state.civil.activities.data);

  const activeProject = useSelector((state: RootState) => state.civil.projects.activeProject);

  const handleSubmit = async (values: IActivity) => {
    if (activityState.some(item => item.name === values.name)) {
      return;
    }
    try {
      setIsSaving(true);
      const newActivityData: IActivity = {
        ...values,
        activityTypeId: Number(activityTypeId)!,
      };

      await thunkDispatch(
        addActivity({
          activity: newActivityData,
          projectId: Number(activeProject?.id),
        })
      );
      const result = await thunkDispatch(
        getActivitiesByWorkCategory({
          workCategoryId: Number(activityTypeId),
          projectId: Number(activeProject?.id),
        })
      );
      if (result.meta.requestStatus === 'fulfilled') {
        formik.resetForm();
        handleModal();
        setIsSaving(false);
        return;
      }
      setIsSaving(false);
    } catch (err) {
      setIsSaving(false);
      console.error('Unable to create activity: ', err);
    }
  };

  const formik = useFormik({
    validationSchema: activitySchema,
    initialValues: selected ? selected : ({ name: '' } as IActivity),
    onSubmit: handleSubmit,
  });

  return (
    <>
      <Grid container>
        <Grid item xs={12}>
          <FormControl fullWidth>
            <InputLabel htmlFor="name" shrink>
              Name
            </InputLabel>
            <TextField
              name="name"
              value={formik.values.name}
              error={formik.touched.name && !!formik.errors.name}
              helperText={formik.touched.name && formik.errors.name}
              onChange={formik.handleChange}
              variant="outlined"
              autoFocus
            />
            <FormHelperText />
          </FormControl>
        </Grid>
      </Grid>
      <DialogActionContainer saveHandler={formik.handleSubmit} isSaving={isSaving} />
    </>
  );
};

export default ActivitiesForm;
