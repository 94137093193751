import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { UserTypes } from 'lg-helpers/dist/constants/user/UserTypes';
import useShowError from 'modules/errors';
import { componentAllowedPerms, usePermissionsContext } from 'services/permissions';
import api, {
  Companies,
  PrismaCompaniesOrderByRelevanceFieldEnum,
  UserTypeGenerated,
} from 'services/api/autogenerated';
import { generateCompaniesColumns } from './generateCompaniesColumns';
import {
  DataGrid,
  GridEventListener,
  GridRowId,
  GridSortDirection,
  GridSortItem,
  GridSortModel,
} from '@mui/x-data-grid';
import Grid from '@mui/material/Grid';
import { Box } from '@mui/material';
import { Loading } from 'sharedComponents/Loading';
import debounce from 'lodash/debounce';
import companiesTypes from 'services/constants/allCompaniesTypes.json';
import CompaniesTableToolbar from './CompaniesTableToolbar';
import statesUSA from 'utils/statesUSA.json';

const NON_GENERATED_COMPANY_TYPES: UserTypeGenerated[] = [
  'scale',
  'transporter',
  'generator',
  'contractor',
  'broker',
  'viewer',
  'civil',
  'trucking',
  'truckingBroker',
];

export const getRoutingKeyByUserType = (userType: string) => {
  switch (userType) {
    case UserTypes.contractor.admin:
      return 'contractors';
    case UserTypes.admin.super:
      return 'super-admin';
    case UserTypes.admin.customer:
      return 'customer-admin';
    default:
      return '';
  }
};

export const isUserCanEditCompany = (userType: string) => {
  return [UserTypes.contractor.admin as string, UserTypes.admin.super as string].includes(userType);
};

interface IPageParams {
  [k: string]: string;
}

export default componentAllowedPerms(['companies:all'], () => <p>Access refused</p>)(
  function CompaniesTable() {
    const [searchParams] = useSearchParams({});
    const pageParams: IPageParams = Object.fromEntries(searchParams.entries());
    const { user: authUser } = usePermissionsContext();
    const navigate = useNavigate();
    const [companies, setCompanies] = useState([] as Companies[]);
    const [initialLoadingData, setInitialLoadingData] = useState(true);
    const showError = useShowError();
    const [triggerDeleted, setTriggerDeleted] = useState<boolean>(false); // used to rerender companies
    const [selectedRowsIds, setSelectedRowsIds] = useState<GridRowId[]>([]);
    const [searchFilter, setSearchFilter] = useState(pageParams?.search);
    const [selectedState, setSelectedState] = useState<{ name?: string; value?: string }>(
      pageParams?.state
        ? statesUSA.find(state => state.value === pageParams?.state.toUpperCase()) || {}
        : {}
    );
    const [selectedTypes, setSelectedTypes] = useState<UserTypeGenerated[]>(
      pageParams?.type
        ? (pageParams?.type
            ?.split(',')
            .filter((e: string) => e && companiesTypes.includes(e)) as UserTypeGenerated[])
        : NON_GENERATED_COMPANY_TYPES
    );
    const [pageSize, setPageSize] = useState<number>(
      pageParams?.size ? parseInt(pageParams?.size) : 50
    );
    const [skip, setSkip] = useState<number>(pageParams?.p ? parseInt(pageParams?.p) : 0);
    const [rowCount, setRowCount] = useState<number>(0);
    const [order, setOrder] = useState<GridSortItem>({
      field: pageParams?.field || 'name',
      sort: (pageParams?.sort || 'asc') as GridSortDirection,
    });

    const routingKey = authUser ? getRoutingKeyByUserType(authUser.actingAsType) : '';
    const canEditCompany = authUser && isUserCanEditCompany(authUser.actingAsType);
    const [isOpenDeletePopup, setIsOpenDeletePopup] = useState(false);

    const handleRowClick: GridEventListener<'rowClick'> | undefined = (company: any) =>
      canEditCompany ? navigate(`/${routingKey}/company/${company.id}`) : void 0;

    const handleAdd = () => navigate(`/${routingKey}/company`);

    const handleDelete = async () => {
      if (!canEditCompany || !selectedRowsIds.length) return;
      try {
        for (const companyId of selectedRowsIds) {
          await api.companies.deleteCompanyById(companyId as string);
        }
        setTriggerDeleted(!triggerDeleted);
        handleClose();
      } catch (err) {
        console.error(err);
        showError({ title: 'Delete error' });
      }
    };

    useEffect(() => {
      let ignore = false;

      async function getCompanies() {
        try {
          const comps = await api.companies.getCompaniesBySearch(
            searchFilter,
            selectedTypes,
            selectedState?.value,
            pageSize,
            skip,
            order?.field
              ? ([order.field] as PrismaCompaniesOrderByRelevanceFieldEnum[])
              : undefined,
            order?.sort || undefined
          );
          if (!ignore) {
            setCompanies(comps.data.data as Companies[]);
            setRowCount(comps.data.pagination.total);
            setInitialLoadingData(false);
          }
        } catch (error) {
          console.error('Error', error);
          showError({
            title: 'Error while fetching the companies',
            duration: 10000,
          });
          setInitialLoadingData(false);
        }
      }

      getCompanies();
      return () => {
        ignore = true;
      };
    }, [
      showError,
      skip,
      pageSize,
      searchFilter,
      selectedState,
      order,
      selectedTypes,
      triggerDeleted,
    ]);

    const handleClickOpen = () => {
      setIsOpenDeletePopup(true);
    };

    const handleClose = () => {
      setIsOpenDeletePopup(false);
    };

    const columns = useMemo(() => generateCompaniesColumns(), []);

    const handleSelectedCompanies = (selectionModel: GridRowId[]) => {
      setSelectedRowsIds(selectionModel as GridRowId[]);
    };

    const handleSortChange = (model: GridSortModel) => {
      setOrder(model[0]);
    };

    const debouncedSetSearchFilter = useCallback(
      debounce(value => setSearchFilter(value), 200),
      []
    );

    return (
      <Box margin={1} marginBottom={10}>
        <CompaniesTableToolbar
          searchFilter={searchFilter}
          selectedState={selectedState}
          setSelectedState={setSelectedState}
          selectedTypes={selectedTypes}
          setSelectedTypes={setSelectedTypes}
          onSearchFilterChange={(e: any) => debouncedSetSearchFilter(e?.currentTarget?.value)}
          handleAdd={handleAdd}
          selectedRowsIds={selectedRowsIds}
          handleClickOpen={handleClickOpen}
          isOpenDeletePopup={isOpenDeletePopup}
          handleClose={handleClose}
          handleDelete={handleDelete}
        />
        {initialLoadingData ? (
          <Loading />
        ) : (
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <DataGrid
                getRowHeight={() => 'auto'}
                columns={columns}
                rows={companies || []}
                rowCount={rowCount}
                getRowId={row => row.id}
                disableSelectionOnClick
                disableColumnMenu
                autoHeight
                pageSize={pageSize}
                rowsPerPageOptions={[10, 25, 50, 100]}
                checkboxSelection
                onRowClick={handleRowClick}
                onPageChange={page => setSkip(page)}
                onSelectionModelChange={handleSelectedCompanies}
                onSortModelChange={handleSortChange}
                sortModel={[order || { field: 'name' }]}
                onPageSizeChange={size => setPageSize(size)}
                paginationMode="server"
                components={{
                  ColumnResizeIcon: () => <div></div>,
                }}
              />
            </Grid>
          </Grid>
        )}
      </Box>
    );
  }
);
