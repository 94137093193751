import React from 'react';
import { Grid, Typography } from '@mui/material';
import { ProgressWithPercentage } from 'sharedComponents/ProgressWithPercentage';
import { getCapacityVolumeByUnit } from 'modules/civilAndTrucking/trucking/utils';
import { ILoadTruck } from '../AddLoadsToDispatchForm';
import { IJoinTruckingDispatch } from 'lg-helpers/dist/trucking/interfaces/IJoinTruckingDispatch';
import { UnitTruckCapacity } from 'lg-helpers/dist/shared/types/global-types';
import { IJoinTruck } from 'lg-helpers/dist/trucking/interfaces/IJoinTruck';

type AddLoadsDispatchInfoProps = {
  joinedDispatch: IJoinTruckingDispatch;
  unitOfMeasure: UnitTruckCapacity;
  addedTrucks: ILoadTruck[];
  joinedTrucks: IJoinTruck[];
};

const progressViewDisabledBodge: boolean = true;

export const AddLoadsDispatchInfo = ({
  joinedDispatch,
  unitOfMeasure,
  addedTrucks,
  joinedTrucks,
}: AddLoadsDispatchInfoProps) => {
  const sumTruckCapacity = (volume: number, truck: IJoinTruck): number => {
    return volume + getCapacityVolumeByUnit(unitOfMeasure, truck.capacitiesTruckingTruckType);
  };

  return (
    <Grid container spacing={1}>
      <Grid item xs={6}>
        <Typography variant="body2" color="green">
          CUSTOMER
        </Typography>
        <Typography variant="body1">
          {joinedDispatch?.name || joinedDispatch?.manualContractorNameTruckingDispatch}
        </Typography>
        <Typography variant="body1">{joinedDispatch?.addressString}</Typography>
      </Grid>
      <Grid item xs={6}>
        <Typography variant="body2" color="green">
          Material Quantity
        </Typography>
        <Typography variant="body1">
          {joinedDispatch?.materialQuantityTruckingDispatch} {unitOfMeasure}
        </Typography>
      </Grid>
      <Grid item xs={6}>
        <Typography variant="body2" color="green">
          Material
        </Typography>
        <Typography variant="body1">
          {joinedDispatch?.nameTruckingCompanyMaterial ||
            joinedDispatch?.manualMaterialNameTruckingDispatch}
        </Typography>
      </Grid>
      {!progressViewDisabledBodge && (
        <Grid item xs={6}>
          <Typography variant="body2" color="green">
            Assigned
          </Typography>
          <ProgressWithPercentage
            size={'medium'}
            percent={
              (addedTrucks.reduce(sumTruckCapacity, 0) + joinedTrucks.reduce(sumTruckCapacity, 0)) /
              (joinedDispatch?.materialQuantityTruckingDispatch || 1)
            }
          />
        </Grid>
      )}
    </Grid>
  );
};
