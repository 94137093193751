import { createAsyncThunk } from '@reduxjs/toolkit';
import { INotification } from 'modules/civilAndTrucking/shared/NotificationSnackbar/INotification';
import { pushNotification } from 'modules/civilAndTrucking/shared/NotificationSnackbar/redux/actions';
import ApiBase from 'services/postgres/ApiBase';
import Tables from 'services/postgres/Tables';
import { ITruckingTruckType } from 'lg-helpers/dist/trucking/interfaces/ITruckingTruckType';
import { RootState } from 'store/store';
import { pushError } from 'modules/errors/storeSliceErrors';

const apiBase = new ApiBase();
const TRUCK_TYPE_TABLE = Tables.TruckingTruckType;

export const getTruckTypesByCompanyId = createAsyncThunk(
  'truck-types/get-truck-types',
  async (payload: undefined, thunkApi) => {
    try {
      const data = await apiBase.getList(`${TRUCK_TYPE_TABLE}`);
      return data as ITruckingTruckType[];
    } catch (err) {
      thunkApi.dispatch(pushError({ title: 'Error: Unable to get truck types' }));
      throw err;
    }
  }
);

export const upsertTruckType = createAsyncThunk(
  'truck-types/add-truck-type',
  async (payload: ITruckingTruckType, thunkApi) => {
    const state = thunkApi.getState() as RootState;
    const companyId = state.civil.companies.activeCompany?.id;

    const truckTypeReq = {
      ...payload,
      companyIdTruckingTruckType: companyId,
    };

    try {
      await apiBase.insertOrUpdate(truckTypeReq, TRUCK_TYPE_TABLE);

      thunkApi.dispatch(getTruckTypesByCompanyId());
    } catch (err) {
      thunkApi.dispatch(pushError({ title: 'Error: Unable to create new truck type' }));
    }
  }
);

export const deleteTruckType = createAsyncThunk(
  'truck-types/delete-truck-type',
  async (truckTypeId: number, thunkApi) => {
    try {
      await apiBase.deleteById(truckTypeId, TRUCK_TYPE_TABLE);
      const successNotification: INotification = {
        status: 'success',
        message: 'Success: Truck type has been deleted',
      };
      thunkApi.dispatch(pushNotification(successNotification));
      thunkApi.dispatch(getTruckTypesByCompanyId());
    } catch (err) {
      thunkApi.dispatch(pushError({ title: 'Error: Unable to delete truck type' }));
    }
  }
);
