import React from 'react';
import styled from 'styled-components';
import Portal from '@mui/material/Portal';
import { CircularProgress } from '@mui/material';

interface OverlayWithMessageProps {
  title: string;
  visible?: boolean;
  subtitle?: string;
  loader?: boolean;
}

export default function OverlayWithMessage({
  visible,
  title,
  subtitle,
  loader,
}: OverlayWithMessageProps) {
  if (!visible) return null;

  return (
    <Portal>
      <Background>
        {loader && (
          <CircularProgress data-testid="OverlayWithMessage--loader" color="inherit" size={50} />
        )}
        <Title data-testid="OverlayWithMessage--title">{title}</Title>
        {subtitle && <SubTitle data-testid="OverlayWithMessage--subtitle">{subtitle}</SubTitle>}
      </Background>
    </Portal>
  );
}

const Background = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background: rgba(0, 0, 0, 0.7);
  color: white;
  z-index: 1000000;
`;

const Title = styled.p`
  font-size: 28px;
  text-align: center;
  margin-top: 20px;
`;

const SubTitle = styled(Title)`
  font-size: 24px;
`;
