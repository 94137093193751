import React from 'react';
import { Box, Fade } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';

interface ILoadingComponentProps {
  isLoading: boolean;
}

export const LoadingComponent = ({ isLoading }: ILoadingComponentProps) => {
  return (
    <Fade appear={false} in={isLoading} unmountOnExit>
      <Box
        sx={{
          position: 'absolute',
          top: 0,
          bottom: 0,
          left: 0,
          right: 0,
          backgroundColor: 'transparent',
          width: '100%',
          height: '100%',
          justifyContent: 'center',
          alignItems: 'center',
          display: 'flex',
        }}
      >
        <Box
          sx={{
            backgroundColor: '#222',
            opacity: 0.3,
            width: '100%',
            height: '100%',
            position: 'absolute',
          }}
        />
        <CircularProgress />
      </Box>
    </Fade>
  );
};

export default LoadingComponent;
