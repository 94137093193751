import ApiBase from 'services/postgres/ApiBase';
const apiBase = new ApiBase();

export const downloadTicketId = async (ticketId: number) => {
  try {
    const { url } = await apiBase.postStatic(
      {
        ticketId,
      },
      'trucking/download/ticket/'
    );
    return url as string;
  } catch (err) {
    console.log();
    throw err;
  }
};
