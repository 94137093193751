import React, { Fragment, Key, useEffect, useMemo, useState } from 'react';
import { RootState, thunkDispatch } from 'store/store';
import { getLocations, addLocation, removeLocation } from '../redux/actions';
import {
  AutocompleteRenderInputParams,
  Grid,
  Typography,
  TextField,
  Button,
  InputLabel,
  FormControl,
  InputAdornment,
  Icon,
  Tooltip,
  Box,
  IconButton,
} from '@mui/material';
import { useSelector } from 'react-redux';
import { LoadingComponent } from 'sharedComponents/LoadingComponent';
import InputAddressAutocomplete from 'sharedComponents/InputAddressAutocomplete';
import { IGeocoderFeature } from 'services/mapbox/geocoding';
import { selectAuthUser } from 'modules/auth/storeSliceAuth';
import { Check, Close, Delete, Search } from '@mui/icons-material';
import { debounce } from 'lodash';
import { INotification } from 'modules/civilAndTrucking/shared/NotificationSnackbar/INotification';
import { pushNotification } from 'modules/civilAndTrucking/shared/NotificationSnackbar/redux/actions';
import LocationSearching from '@mui/icons-material/LocationSearching';
import CivilGeocoding, { Coordinates } from 'sharedComponents/InputCivilGeocodingAddress';
import { updateLocation } from 'modules/civilAndTrucking/civil/Projects/redux/actions';
import { EditIcon } from 'modules/civilAndTrucking/shared/CustomIcons/Edit';
import { ILocation } from 'lg-helpers/dist/shared/interfaces/ILocation';

interface IManageLocationProps {
  projectId: number;
}

const ManageLocations = ({ projectId }: IManageLocationProps) => {
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [address, setAddress] = useState<string>();
  const [geo, setGeo] = useState<number[]>();
  const [search, setSearch] = useState<string>();
  const [resetAutoComplete, setResetAutocomplete] = useState<Key>(1);
  const [anchorEl, setAnchorEl] = useState<Element>();
  const [showMap, setShowMap] = useState<boolean>(false);
  const [geoPoint, setGeoPoint] = useState<Coordinates | undefined>();
  const [locationId, setLocationId] = useState<number>();
  const [newName, setNewName] = useState<string>();
  const [name, setName] = useState<string>();
  const [editingId, setEditingId] = useState<number>();

  const authUser = useSelector(selectAuthUser);

  const locations: ILocation[] = useSelector((state: RootState) => state.civil.projects.locations);

  const handleSearch = React.useMemo(
    () =>
      debounce(async (request: { input: string }) => {
        setIsLoading(true);
        await thunkDispatch(getLocations({ projectId: projectId, search: request.input }));
        setIsLoading(false);
      }, 500),
    [projectId]
  );

  useMemo(() => {
    if (!search) {
      handleSearch({ input: '' });
    }
    if (search && search.length < 3) {
      return;
    }
    if (search && search.length >= 3) {
      handleSearch({ input: search });
    }
    // eslint-disable-next-line
  }, [search]);

  useEffect(() => {
    handleSearch({ input: '' });
    // eslint-disable-next-line
  }, [projectId]);

  const handleAddressSelected = async (geocoderValue: IGeocoderFeature) => {
    if (address !== geocoderValue.place_name) {
      setAddress(geocoderValue.place_name);
      setGeo(geocoderValue.geometry.coordinates);
    }
  };

  const addNewLocation = async () => {
    if (projectId && address && geo) {
      try {
        setIsSaving(true);
        await thunkDispatch(
          addLocation({
            civilProjectId: projectId,
            address,
            geo,
            createdBy: authUser?.id,
            name: newName,
          })
        );
        setNewName(undefined);
        setResetAutocomplete(new Date().toISOString());
        setAddress(undefined);
        setGeo(undefined);
        setLocationId(undefined);
        setIsSaving(false);
      } catch (err) {
        console.error('Error saving new location: ', err);
        setIsSaving(false);
      }
    } else {
      const warningNotification: INotification = {
        status: 'warning',
        message: 'Please select valid address.',
      };
      thunkDispatch(pushNotification(warningNotification));
    }
  };

  const removeLocationHandler = async (location: ILocation) => {
    if (projectId && location.civilLocationToProjectId) {
      try {
        setIsLoading(true);
        await thunkDispatch(
          removeLocation({
            projectId,
            locationToProjectId: location.civilLocationToProjectId,
          })
        );
        setIsLoading(false);
      } catch (err) {
        console.error('Error removing location: ', err);
        setIsLoading(false);
      }
    }
  };

  const updateMapData = (location: ILocation) => {
    setEditingId(undefined);
    setLocationId(location.id);
    setGeoPoint(location.geo ? { lon: location.geo[0], lat: location.geo[1] } : undefined);
  };

  const toggleMap: React.MouseEventHandler = async event => {
    setAnchorEl(event.currentTarget);
    setShowMap(true);
  };

  const handleUpdateLocation = async (geoData: Coordinates | undefined) => {
    if (!projectId || (!locationId && !editingId) || !geoData || !geoData.lat || !geoData.lon) {
      return;
    }
    try {
      setIsSaving(true);
      await thunkDispatch(
        updateLocation({
          projectId: Number(projectId),
          locationId: (locationId || editingId)!,
          geo: [geoData.lon, geoData.lat],
          sender: 'locations',
          name: name,
        })
      );
      setEditingId(undefined);
      setLocationId(undefined);
    } catch (err) {
      console.error('Error updating location: ', err);
    } finally {
      setIsSaving(false);
    }
  };

  return (
    <>
      <Grid container alignItems="center" justifyContent="space-between" spacing={2}>
        <Grid item xs={3}>
          <FormControl fullWidth>
            <InputLabel shrink htmlFor="newName">
              Location Name
            </InputLabel>
            <TextField
              key={resetAutoComplete}
              name="newName"
              onChange={event => {
                setNewName(event.target.value);
              }}
              variant="outlined"
              value={newName || ''}
            />
          </FormControl>
        </Grid>
        <Grid item xs={7}>
          <FormControl fullWidth>
            <InputLabel shrink htmlFor="address">
              Find New Locations
            </InputLabel>
            <InputAddressAutocomplete
              key={resetAutoComplete}
              searchRequirement={5}
              disableClearable
              options={[]}
              handleSelect={handleAddressSelected}
              inputValue={address || ''}
              renderInput={(params: AutocompleteRenderInputParams) => (
                <TextField
                  {...params}
                  name="address"
                  onChange={event => {
                    setAddress(event.target.value);
                  }}
                  variant="outlined"
                  value={address}
                />
              )}
            />
          </FormControl>
        </Grid>
        <Grid item xs={2}>
          <Button onClick={addNewLocation} color="primary" variant="contained" fullWidth>
            Add
          </Button>
        </Grid>
      </Grid>
      <Grid container alignItems="center" justifyContent="space-between">
        <Grid item xs={8}>
          <Typography
            variant="h6"
            sx={{
              textTransform: 'uppercase',
              color: 'secondary.main',
              fontWeight: 400,
            }}
          >
            Existing Sites
          </Typography>
        </Grid>
        <Grid item xs={4}>
          <TextField
            variant="outlined"
            placeholder="Search requires at least 3 characters"
            value={search || ''}
            onChange={event => setSearch(event.target.value)}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Icon>
                    <Search />
                  </Icon>
                </InputAdornment>
              ),
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    onClick={() => {
                      setSearch('');
                      handleSearch({ input: '' });
                    }}
                  >
                    <Close />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </Grid>
      </Grid>
      <Box
        sx={{
          height: '50vh',
          maxHeight: 300,
          width: '100%',
          overflowY: 'scroll',
          overflowX: 'scroll',
        }}
      >
        <Grid
          container
          spacing={2}
          sx={{
            minWidth: 400,
          }}
        >
          <Grid item xs={3}>
            <Typography variant="subtitle2">Name</Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography variant="subtitle2">Street Address</Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography variant="subtitle2">City, State</Typography>
          </Grid>
          <Grid item xs={2}>
            <Box flex={1} />
          </Grid>
          {locations && locations.length > 0 && locations[0].projectId === projectId ? (
            locations.map(location => (
              <Fragment key={location.id}>
                <Grid item xs={3}>
                  {editingId !== location.id ? (
                    <Typography>{location.name}</Typography>
                  ) : (
                    <TextField
                      size="small"
                      sx={{ my: 0 }}
                      value={name || ''}
                      onChange={event => setName(event.target.value)}
                    />
                  )}
                </Grid>
                <Grid item xs={3}>
                  <Typography>{location.address1}</Typography>
                </Grid>
                <Grid item xs={4}>
                  <Typography>
                    {location.city}, {location.state}
                  </Typography>
                </Grid>
                {editingId === location.id ? (
                  <Grid item xs={2}>
                    <IconButton
                      onClick={() => {
                        handleUpdateLocation(
                          location.geo
                            ? {
                                lon: location.geo[0],
                                lat: location.geo[1],
                              }
                            : undefined
                        );
                        setEditingId(undefined);
                      }}
                    >
                      <Check />
                    </IconButton>
                    <IconButton
                      onClick={() => {
                        setEditingId(undefined);
                      }}
                    >
                      <Close />
                    </IconButton>
                  </Grid>
                ) : (
                  <Grid item xs={2}>
                    <Tooltip title="Edit location name">
                      <IconButton
                        onClick={() => {
                          setEditingId(location.id);
                          setName(location.name);
                        }}
                      >
                        <EditIcon />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title="Update location coordinates">
                      <IconButton
                        onClick={event => {
                          setName(location.name);
                          toggleMap(event);
                          updateMapData(location);
                        }}
                      >
                        <LocationSearching />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title="Delete location from project">
                      <IconButton
                        onClick={() => {
                          removeLocationHandler(location);
                        }}
                      >
                        <Delete />
                      </IconButton>
                    </Tooltip>
                  </Grid>
                )}
              </Fragment>
            ))
          ) : (
            <Grid item xs={12}>
              <Typography>No locations found.</Typography>
            </Grid>
          )}
        </Grid>
      </Box>
      {anchorEl && (
        <CivilGeocoding
          setShowMap={setShowMap}
          center={geoPoint}
          anchorEl={anchorEl}
          showMap={showMap}
          onSave={handleUpdateLocation}
        />
      )}
      <LoadingComponent isLoading={isLoading || isSaving} />
    </>
  );
};

export default ManageLocations;
