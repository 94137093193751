import { createSlice } from '@reduxjs/toolkit';
import { RootState } from 'store/store';
import { getCompaniesByCompanyTypes } from './actions';
import { TFormStatus } from 'lg-helpers/dist/shared/types/TFormStatus';
import { ICompany } from 'lg-helpers/dist/shared/interfaces/ICompany';

interface ICompaniesState {
  data: ICompany[];
  status: TFormStatus;
}

const initialCompaniesState: ICompaniesState = {
  data: [],
  status: null,
};

export const companiesSlice = createSlice({
  name: 'companies',
  reducers: {},
  initialState: initialCompaniesState,
  extraReducers: builder => {
    builder
      .addCase(getCompaniesByCompanyTypes.fulfilled, (state, { payload }) => {
        state.status = 'success';
        state.data = payload;
      })
      .addCase(getCompaniesByCompanyTypes.rejected, (state, { error }) => {
        state.status = 'failure';
        state.data = [] as ICompany[];
        console.error('getCompaniesByCompanyTypes error: ', error);
      });
  },
});

export const selectCompanies = (state: RootState) => {
  return {
    companies: state.trucking.companies.data,
    companiesStatus: state.trucking.companies.status,
  };
};
