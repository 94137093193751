import React from 'react';
import { DialogContent } from '@mui/material';
import ModalHeader from 'sharedComponents/DialogHeader';
import ActivitiesForm from './ActivitiesForm';
import { IActivity } from 'lg-helpers/dist/shared/interfaces/IActivity';

interface ModalContainerProps {
  selected?: IActivity;
}

const ModalContainer = ({ selected }: ModalContainerProps) => {
  return (
    <>
      <ModalHeader title={'Activity'} />
      <DialogContent>
        <ActivitiesForm selected={selected} />
      </DialogContent>
    </>
  );
};

export default ModalContainer;
