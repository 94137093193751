import React, { useState, useEffect, useRef } from 'react';
import 'moment-timezone';
import PropTypes from 'prop-types';
import useShowError from 'modules/errors';
import Query from 'services/firebase/Query';
import Executer from 'services/firebase/Executer';
import { fetchManifestTemplateHTML } from 'services/api';
import Hider from './Hider';
import { Container } from '@mui/system';
import QueryBase from 'lg-helpers/dist/firestore/query/QueryBase';

const query = new Query();
const executer = new Executer();

const ManifestDisplayWithTemplateDataGathering = ({
  manifestId,
  onSucessfullDisplayCallback,
  onManifestUpdateReceivedCallback,
  hideReceipt,
}) => {
  const [isLoading, setIsLoadingData] = useState(false);
  const showError = useShowError();
  const [manifestTemplateHtml, setManifestTemplateHtml] = useState('');
  const [iFrameHeight, setiFrameHeight] = useState('0px');
  const ref = useRef();

  const onLoad = () => {
    setiFrameHeight(ref.current.contentWindow.document.body.scrollHeight + 'px');
  };

  useEffect(() => {
    if (!manifestId) {
      return;
    }

    const fetchUpdatedManifestTemplate = () => {
      fetchManifestTemplateHTML(manifestId, { notes: false, hideReceipt })
        .then(html => {
          if (onSucessfullDisplayCallback) onSucessfullDisplayCallback(true);
          setIsLoadingData(false);
          setManifestTemplateHtml(html);
        })
        .catch(err => {
          console.error(err);
          showError({
            title: err,
            duration: 10000,
          });
        });
    };

    setIsLoadingData(true);

    return executer.watchSingleDocument(
      query.base.getById(QueryBase.MANIFESTS_COLLECTION(), manifestId),
      manif => {
        if (manif) {
          fetchUpdatedManifestTemplate();
          onManifestUpdateReceivedCallback && onManifestUpdateReceivedCallback(manif);
        } else {
          showError({
            title: "This manifest doesn't exist",
            duration: 10000,
          });
        }
      },
      error => {
        console.error('Error getting manifest:', manifestId, error);
        showError({
          title: 'Error while fetching the manifest',
          duration: 10000,
        });
        setIsLoadingData(false);
      }
    );
  }, [manifestId, onManifestUpdateReceivedCallback, showError]);

  return (
    <Container className="min-padding-and-height">
      <Hider showContent={manifestTemplateHtml} showLoading={isLoading}>
        <iframe
          ref={ref}
          onLoad={onLoad}
          className="lg-iframe"
          srcDoc={manifestTemplateHtml}
          style={{ height: iFrameHeight }}
        />
      </Hider>
    </Container>
  );
};

ManifestDisplayWithTemplateDataGathering.propTypes = {
  manifestId: PropTypes.string,
  hideReceipt: PropTypes.bool,
  onSucessfullDisplayCallback: PropTypes.func,
  onManifestUpdateReceivedCallback: PropTypes.func,
};

export default ManifestDisplayWithTemplateDataGathering;
