import React, { useEffect, useState, useCallback } from 'react';
import { v4 as uuidv4 } from 'uuid';
import {
  AutocompleteRenderInputParams,
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  TextField,
  Typography,
} from '@mui/material';
import { useSelector } from 'react-redux';
import { useFormik } from 'formik';
import { companyDetailsSchema } from './schema';
import { RootState, thunkDispatch } from 'store/store';
import { useNavigate } from 'react-router-dom';
import { addCompany } from './redux/actions';
import { useHasPerms } from 'services/permissions';
import CompanyLogoUpload from './CompanyLogo';
import { selectAuthUser } from 'modules/auth/storeSliceAuth';
import civilUploadImageAndGetUrl from 'utils/civilUploadImageAndGetUrl';
import { INotification } from 'modules/civilAndTrucking/shared/NotificationSnackbar/INotification';
import { pushNotification } from 'modules/civilAndTrucking/shared/NotificationSnackbar/redux/actions';
import { debounce } from 'lodash';
import InputAddressAutocomplete from 'sharedComponents/InputAddressAutocomplete';
import { resolveAddress, IGeocoderFeature } from 'services/mapbox/geocoding';
import InputMasker from '../../../../sharedComponents/InputMasker';
import { IJoinCompany } from 'lg-helpers/dist/shared/interfaces/IJoinCompany';

const stateOptions = require('utils/statesUSA.json');

interface IStateOption {
  name: string;
  value: string;
}

export interface ICompanyLogo {
  url?: string;
  width?: number;
  height?: number;
}

const EditCompanyDetails = () => {
  const navigate = useNavigate();
  const canUpdate = useHasPerms(['companies:update']);
  const [companyLogo, setCompanyLogo] = useState<ICompanyLogo>();
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [updateAddress, setUpdateAddress] = useState<boolean>(false);
  const [useOldForm, setUseOldForm] = useState(false);

  const companyState = useSelector((state: RootState) => state.civil.companies.activeCompany);

  const authUser = useSelector(selectAuthUser);

  const saveNewPhoto = async () => {
    if (companyLogo && companyLogo.url) {
      const photoUrl = await civilUploadImageAndGetUrl(
        companyLogo.url, //for new images, this will be base64
        `civil/users/${authUser.id}/company/${companyState?.id}/logo/${uuidv4()}`
      );

      return photoUrl;
    }
  };

  const handleSubmit = async (values: IJoinCompany) => {
    const { reportUrl, ...newData } = values; // eslint-disable-line

    // TODO: Temporary solution to handle IJoinCompany server validation
    // @ts-ignore
    delete newData?.deleted;

    try {
      if (companyLogo?.url !== companyState?.companyLogo?.url) {
        const photoUrl = await saveNewPhoto();
        newData.companyLogo = { ...companyLogo, url: photoUrl || '' };
      }
      setIsSaving(true);
      await thunkDispatch(addCompany(newData));
      setIsSaving(false);
      navigate('/civil/settings');
      return;
    } catch (err) {
      setIsSaving(false);
      console.error(err);
    }
  };

  const formik = useFormik({
    validationSchema: companyDetailsSchema,
    initialValues: {
      name: '',
      types: [],
      nextAutoTicketNumber: 1000,
      addressOne: '',
      addressTwo: '',
      city: '',
      state: '',
      postalCode: '',
      phone: '',
      email: '',
      requireCivilTicketQty: true,
      requireCivilTicketShippingImages: false,
      ...companyState,
    },
    onSubmit: handleSubmit,
    enableReinitialize: true,
  });

  const { values, setValues } = formik;

  useEffect(() => {
    formik.setFieldValue('companyLogo', companyLogo);
    // eslint-disable-next-line
  }, [companyLogo]);

  useEffect(() => {
    setCompanyLogo(companyState?.companyLogo);
  }, [companyState]);

  // eslint-disable-next-line
  const debouncedAutoTicketHandler = useCallback(
    debounce((event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      const newAutoTicketNumber = Number(event.target.value);
      const oldAutoTicketNumber = companyState?.nextAutoTicketNumber;
      if (oldAutoTicketNumber && newAutoTicketNumber < oldAutoTicketNumber) {
        const errorNotification: INotification = {
          status: 'warning',
          message:
            'Warning:  Changing the next ticket number to a number lower than the current number could result in duplicate gate ticket numbers in the system.',
        };
        thunkDispatch(pushNotification(errorNotification));
      }
    }, 500),
    // eslint-disable-next-line
    []
  );

  const handleAutoTicketNumber = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    debouncedAutoTicketHandler(event);
  };

  const handleSelect = (value: IGeocoderFeature) => {
    setValues({
      ...values,
      address: value.place_name || 'test',
      geo: value.geometry.coordinates,
      locationId: undefined,
    });
  };

  useEffect(() => {
    if (!companyState?.id || companyState?.locationId) {
      return;
    }
    (async () => {
      if (companyState && !companyState?.locationId) {
        const { address, addressOne, city, state } = companyState;
        const addressString = `${address || addressOne}, ${city}, ${state}`;
        const value = await resolveAddress(addressString);
        if (value && value.length === 1) {
          const newPlaceName = value[0].place_name.split(',').slice(0, -1).join();
          setValues({
            ...values,
            address: newPlaceName,
            geo: value[0].geometry.coordinates,
            locationId: undefined,
          });
        } else {
          setUpdateAddress(true);
        }
      }
    })();
    // eslint-disable-next-line
  }, [companyState]);

  return (
    <>
      <Grid margin={5} container spacing={2} maxWidth={800}>
        <Grid item xs={12}>
          <Typography
            variant="h5"
            sx={{
              typography: { textTransform: 'uppercase', color: 'secondary' },
            }}
          >
            Company Details
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <CompanyLogoUpload setCompanyLogo={setCompanyLogo} companyLogo={companyLogo} />
        </Grid>
        <Grid item xs={9}>
          <FormControl fullWidth>
            <InputLabel shrink htmlFor="name">
              Name
            </InputLabel>
            <TextField
              variant="outlined"
              name="name"
              value={formik.values.name}
              error={formik.touched.name && !!formik.errors.name}
              helperText={formik.touched.name && formik.errors.name}
              onChange={formik.handleChange}
              disabled={!canUpdate}
            />
            <FormHelperText />
          </FormControl>
        </Grid>
        <Grid item xs={3}>
          <FormControl fullWidth>
            <InputLabel shrink htmlFor="nextAutoTicketNumber">
              Auto Ticket #
            </InputLabel>
            <TextField
              variant="outlined"
              name="nextAutoTicketNumber"
              value={formik.values.nextAutoTicketNumber}
              error={formik.touched.nextAutoTicketNumber && !!formik.errors.nextAutoTicketNumber}
              helperText={formik.touched.nextAutoTicketNumber && formik.errors.nextAutoTicketNumber}
              onChange={event => {
                formik.handleChange(event);
                handleAutoTicketNumber(event);
              }}
            />
            <FormHelperText />
          </FormControl>
        </Grid>
        {!useOldForm && (
          <>
            {updateAddress && (
              <Grid item xs={12}>
                <Typography>Please update the address. Previous data: </Typography>
                <Box>
                  <Typography>
                    Address: {companyState?.address || companyState?.addressOne}
                  </Typography>
                  <Typography>City: {companyState?.city}</Typography>
                  <Typography>State: {companyState?.state}</Typography>
                </Box>
              </Grid>
            )}
            <Grid item xs={12}>
              <FormControl fullWidth>
                <InputLabel shrink htmlFor="address">
                  <Typography>Address</Typography>
                </InputLabel>

                <InputAddressAutocomplete
                  searchRequirement={5}
                  options={[]}
                  className="addressInput"
                  handleSelect={handleSelect}
                  inputValue={formik.values.address || ''}
                  renderInput={(params: AutocompleteRenderInputParams) => (
                    <TextField
                      {...params}
                      name="address"
                      error={!!formik.errors.address}
                      helperText={formik.errors.address}
                      onChange={formik.handleChange}
                      variant="outlined"
                      value={formik.values.address || ''}
                    />
                  )}
                />
              </FormControl>
              {updateAddress && (
                <Typography>
                  Or you can{' '}
                  <Typography
                    onClick={() => setUseOldForm(true)}
                    sx={{
                      display: 'inline',
                      typography: { textDecoration: 'underline' },
                      '&:hover': {
                        cursor: 'pointer',
                      },
                    }}
                  >
                    use the old form.
                  </Typography>
                </Typography>
              )}
            </Grid>
          </>
        )}
        {useOldForm && (
          <>
            <Grid item xs={12}>
              <FormControl fullWidth>
                <InputLabel shrink htmlFor="address">
                  Address
                </InputLabel>
                <TextField
                  variant="outlined"
                  name="addressOne"
                  value={formik.values.addressOne}
                  error={formik.touched.addressOne && !!formik.errors.addressOne}
                  helperText={formik.touched.addressOne && formik.errors.addressOne}
                  onChange={formik.handleChange}
                />
                <FormHelperText />
              </FormControl>
              <FormControl fullWidth>
                <TextField
                  variant="outlined"
                  name="addressTwo"
                  value={formik.values.addressTwo || ''}
                  error={formik.touched.addressTwo && !!formik.errors.addressTwo}
                  helperText={formik.touched.addressTwo && formik.errors.addressTwo}
                  onChange={formik.handleChange}
                />
                <FormHelperText />
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <Grid container spacing={2}>
                <Grid item xs={4}>
                  <FormControl fullWidth>
                    <InputLabel shrink htmlFor="city">
                      City
                    </InputLabel>
                    <TextField
                      variant="outlined"
                      name="city"
                      value={formik.values.city}
                      error={formik.touched.city && !!formik.errors.city}
                      helperText={formik.touched.city && formik.errors.city}
                      onChange={formik.handleChange}
                    />
                    <FormHelperText />
                  </FormControl>
                </Grid>
                <Grid item xs={4}>
                  <FormControl fullWidth>
                    <InputLabel shrink htmlFor="state">
                      State
                    </InputLabel>
                    <TextField
                      select
                      variant="outlined"
                      name="state"
                      value={formik.values.state}
                      error={formik.touched.state && !!formik.errors.state}
                      helperText={formik.touched.state && formik.errors.state}
                      onChange={formik.handleChange}
                    >
                      {stateOptions.map((stateOption: IStateOption) => (
                        <MenuItem
                          key={`state-select-option-${stateOption.value}`}
                          value={stateOption.value}
                        >
                          {stateOption.name}
                        </MenuItem>
                      ))}
                    </TextField>
                    <FormHelperText />
                  </FormControl>
                </Grid>
                <Grid item xs={4}>
                  <FormControl fullWidth>
                    <InputLabel shrink htmlFor="postalCode">
                      Zip
                    </InputLabel>
                    <TextField
                      variant="outlined"
                      name="postalCode"
                      value={formik.values.postalCode}
                      error={formik.touched.postalCode && !!formik.errors.postalCode}
                      helperText={formik.touched.postalCode && formik.errors.postalCode}
                      onChange={formik.handleChange}
                    />
                    <FormHelperText />
                  </FormControl>
                </Grid>
              </Grid>
              <Typography>
                Or you can{' '}
                <Typography
                  onClick={() => setUseOldForm(false)}
                  sx={{
                    display: 'inline',
                    typography: { textDecoration: 'underline' },
                    '&:hover': {
                      cursor: 'pointer',
                    },
                  }}
                >
                  use the new form.
                </Typography>
              </Typography>
            </Grid>
          </>
        )}
        <Grid item xs={12} sx={{ mt: 2 }}>
          <FormControl fullWidth>
            <InputLabel shrink htmlFor="phone">
              Phone
            </InputLabel>
            <InputMasker
              name="phone"
              variant="outlined"
              mask="+###########"
              val={formik.values.phone}
              error={formik.touched.phone && !!formik.errors.phone}
              helperText={formik.touched.phone && formik.errors.phone}
              onChange={(val: any) => formik.setFieldValue('phone', val)}
            />
            <TextField
              error={formik.touched.phone && !!formik.errors.phone}
              helperText={formik.touched.phone && formik.errors.phone}
              onChange={formik.handleChange}
            />
            <FormHelperText />
          </FormControl>
        </Grid>
        <Grid item xs={12} sx={{ mt: 2 }}>
          <FormControl fullWidth>
            <InputLabel shrink htmlFor="email">
              Email
            </InputLabel>
            <TextField
              variant="outlined"
              name="email"
              value={formik.values.email}
              error={formik.touched.email && !!formik.errors.email}
              helperText={formik.touched.email && formik.errors.email}
              onChange={formik.handleChange}
            />
            <FormHelperText />
          </FormControl>
        </Grid>

        <Grid item xs={8}>
          <Grid item xs={12}>
            <FormControlLabel
              label="Require images for ticket creation?"
              name="requireTicketImages"
              control={
                <Checkbox
                  color="success"
                  checked={formik.values.requireCivilTicketShippingImages}
                  onChange={() =>
                    formik.setFieldValue(
                      'requireCivilTicketShippingImages',
                      !formik.values.requireCivilTicketShippingImages
                    )
                  }
                />
              }
            />
          </Grid>

          <Grid item xs={12}>
            <FormControlLabel
              label="Require weight for ticket creation?"
              name="requireTicketQty"
              control={
                <Checkbox
                  color="success"
                  checked={formik.values.requireCivilTicketQty}
                  onChange={() =>
                    formik.setFieldValue(
                      'requireCivilTicketQty',
                      !formik.values.requireCivilTicketQty
                    )
                  }
                />
              }
            />
          </Grid>
        </Grid>

        <Grid item xs={4} sx={{ mt: 2 }}>
          <Button
            color="primary"
            variant="contained"
            disabled={isSaving}
            type="submit"
            onClick={formik.handleSubmit as React.MouseEventHandler | React.FormEventHandler}
          >
            Update
          </Button>
        </Grid>
      </Grid>
    </>
  );
};

export default EditCompanyDetails;
