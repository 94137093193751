import React, { useState } from 'react';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { selectAuthUser } from 'modules/auth/storeSliceAuth';
import { useSelector } from 'react-redux';
import Fuse from 'fuse.js';
import 'moment-timezone';
import WorkCodeTopBar from './WorkCodeTopBar';

import { Refresh } from 'modules/civilAndTrucking/shared/CustomIcons/Refresh';

import api from 'services/api/autogenerated';
import { Box, Button, Grid, IconButton, TextField } from '@mui/material';
import LoadingComponent from '../../../sharedComponents/LoadingComponent';
import { ArrowBack } from '@mui/icons-material';

interface WorkCodeViewProps {
  workCode: any;
  handleWorkCodeUpdate: () => void;
}

const WorkCodeView: React.FC<WorkCodeViewProps> = ({ workCode, handleWorkCodeUpdate }) => {
  const authUser = useSelector(selectAuthUser);
  const [searchTerm, setSearchTerm] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const [workCodeDrivers, setWorkCodeDrivers] = useState<any[]>(workCode.drivers);

  // Fuzzy Search options
  const fuseOptions = {
    keys: ['name', 'transporterName', 'carrier', 'truck_number'],
  };

  const refreshWorkCodeDrivers = async () => {
    setIsLoading(true);

    const fetchedWorkCodeDrivers: any = await api.workCode.getWorkCodeDrivers(
      workCode.work_code_id
    );

    if (!fetchedWorkCodeDrivers.error) setWorkCodeDrivers(fetchedWorkCodeDrivers.data.data);

    setIsLoading(false);
  };

  // Fuzzy search instance
  const fuse = new Fuse(workCode ? workCode.drivers : [], fuseOptions);

  const getFilteredRows = () => {
    if (workCodeDrivers) {
      return searchTerm ? fuse.search(searchTerm).map(result => result.item) : workCodeDrivers;
    }
    return [];
  };

  const columns: GridColDef[] = [
    {
      field: 'name',
      headerName: 'Name',
      flex: 1,
    },
    {
      field: 'transporterName',
      headerName: 'Transporter',
      flex: 1,
    },
    {
      field: 'carrier',
      headerName: 'Carrier',
      flex: 1,
    },
    {
      field: 'truck_number',
      headerName: 'Truck Number',
      flex: 1,
    },
    {
      field: 'actions',
      headerName: '',
      renderCell: params => {
        const onClick = () => {
          const apiCall = async () => {
            const driverId = params.row.uid;
            await api.workCode.workCodeRemoveDriverRequest(driverId, workCode.project_id);
            await refreshWorkCodeDrivers();
          };

          // Confirm before deleting
          if (window.confirm('Are you sure you want to remove this driver?')) {
            apiCall();
          }
        };

        return (
          <Button onClick={onClick} color="error">
            Remove
          </Button>
        );
      },
    },
  ];

  if (isLoading) {
    return (
      <Grid xs={6} md={1}>
        <Box
          height={'100%'}
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
        >
          <LoadingComponent isLoading={isLoading} />
        </Box>
      </Grid>
    );
  }

  return (
    <div style={{ display: 'flex', flexDirection: 'column', height: '80vh' }}>
      <div style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
        <Button onClick={handleWorkCodeUpdate} style={{ marginRight: '10px' }} color="primary">
          <ArrowBack style={{ marginRight: '10px' }} />
          Back to Work Code Table
        </Button>
      </div>

      <WorkCodeTopBar
        projectId={workCode.project_id}
        workCode={workCode}
        onWorkCodeUpdate={handleWorkCodeUpdate}
      />

      {workCode && workCode.drivers && (
        <>
          <hr style={{ width: '100%', borderTop: '1px solid #ccc', margin: '20px' }} />
          <div
            style={{ display: 'flex', alignItems: 'center', marginBottom: '10px', width: '100%' }}
          >
            <TextField
              label="Search"
              variant="outlined"
              value={searchTerm}
              onChange={e => setSearchTerm(e.target.value)}
              style={{ marginRight: '10px', flex: 1 }}
            />

            <IconButton color="secondary" onClick={refreshWorkCodeDrivers}>
              <Refresh
                color="secondary"
                sx={{
                  fontSize: 24,
                  marginRight: '-2px',
                  marginBottom: '-2px',
                }}
              />
              <div style={{ paddingLeft: 10 }}>Refresh Drivers</div>
            </IconButton>

            <IconButton onClick={handleWorkCodeUpdate}></IconButton>
          </div>

          <div style={{ flexGrow: 1 }}>
            <DataGrid
              getRowId={row => row.uid}
              rows={getFilteredRows()}
              columns={columns}
              pageSize={25}
              style={{ minHeight: '0', height: '100%' }} // Ensure DataGrid expands
            />
          </div>
        </>
      )}
    </div>
  );
};

export default WorkCodeView;
