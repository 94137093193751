import { createAsyncThunk } from '@reduxjs/toolkit';
import { INotification } from 'modules/civilAndTrucking/shared/NotificationSnackbar/INotification';
import { pushNotification } from 'modules/civilAndTrucking/shared/NotificationSnackbar/redux/actions';
import { get, post } from 'services/api/restHelpers';
import { IRequest } from 'lg-helpers/dist/shared/interfaces/IRequest';

type TRequestSearch = {
  item: string;
  value: string | number | boolean;
};

export const getRequestsBySearch = createAsyncThunk(
  'requests/get',
  async (search: TRequestSearch[], thunkApi) => {
    try {
      let searchString = '';
      search.forEach((item, index) => {
        searchString += `${item.item}=${item.value}` + `${index !== search.length - 1 ? '&' : ''}`;
      });
      const data = await get(`civil/requests?${searchString}`);
      return data as IRequest[];
    } catch (err) {
      const errorNotification: INotification = {
        status: 'error',
        message: `Error: Unable to get requests`,
      };
      thunkApi.dispatch(pushNotification(errorNotification));
      throw err;
    }
  }
);

export const updateRequest = createAsyncThunk(
  'requests/update',
  async (payload: { request: IRequest }, thunkApi) => {
    try {
      const data = await post(`civil/requests`, payload.request);
      const successNotification: INotification = {
        status: 'success',
        message: 'Site request updated.',
      };
      thunkApi.dispatch(pushNotification(successNotification));
      return data;
    } catch (err) {
      const errorNotification: INotification = {
        status: 'error',
        message: 'Unable to update site request.',
      };
      thunkApi.dispatch(pushNotification(errorNotification));
      console.error(err);
    }
  }
);
