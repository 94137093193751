import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Box } from '@mui/material';
import CrudTableToolbar from './CrudTableToolbar';
import TableCrud from './TableCrud';

const GenericCrudTable = ({
  children,
  title,
  rows,
  isLoading,
  selectedRowsIds,
  handleClickAddCallback,
  handleClickArchivedCallback,
  handleRowClickCallback,
  handleSortOrder,
  setSelectedRowsIdsCallback,
  setSearchFilterCallback,
  searchFilter,
  archiveFilter,
  handleClickDeleteCallback,
  maxNewDocuments,
  columns,
  orderBy,
  orderDirection,
  rowIsDisabledCallback,
  isDisabledSelectAll,
  regions,
  scales,
  states,
  companies,
  selectedRegion,
  selectedScales,
  selectedState,
  selectedCompany,
  handleSelectRegion,
  handleSelectScales,
  handleSelectState,
  handleSelectCompany,
  showRegionSelection,
  showScaleSelection,
  showStateSelection,
  showCompanySelection,
  showReportButton,
  showBatchedManifestButton,
  disabledIds = [],
}) => {
  return (
    <Box margin={1}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          {children ? (
            <>{children}</>
          ) : (
            <CrudTableToolbar
              searchFilter={searchFilter}
              archiveFilter={archiveFilter}
              selectedRowsIds={selectedRowsIds}
              handleClickAddCallback={handleClickAddCallback}
              handleClickArchivedCallback={handleClickArchivedCallback}
              setSelectedRowsIdsCallback={setSelectedRowsIdsCallback}
              setSearchFilterCallback={setSearchFilterCallback}
              handleClickDeleteCallback={handleClickDeleteCallback}
              maxNewDocuments={maxNewDocuments}
              title={title}
              regions={regions}
              scales={scales}
              states={states}
              companies={companies}
              selectedRegion={selectedRegion}
              selectedScales={selectedScales}
              selectedState={selectedState}
              selectedCompany={selectedCompany}
              handleSelectRegion={handleSelectRegion}
              handleSelectScales={handleSelectScales}
              handleSelectState={handleSelectState}
              handleSelectCompany={handleSelectCompany}
              showRegionSelection={showRegionSelection}
              showScaleSelection={showScaleSelection}
              showStateSelection={showStateSelection}
              showCompanySelection={showCompanySelection}
              showReportButton={showReportButton}
              showBatchedManifestButton={showBatchedManifestButton}
            />
          )}
        </Grid>
        <Grid item xs={12}>
          <TableCrud
            checkboxColor={children ? 'primary' : 'inherit'}
            rows={rows}
            columns={columns}
            selectedRowsIds={selectedRowsIds}
            setSelectedRowsIdsCallback={setSelectedRowsIdsCallback}
            handleRowClickCallback={handleRowClickCallback}
            isLoading={isLoading}
            orderBy={orderBy}
            orderDirection={orderDirection}
            rowIsDisabledCallback={rowIsDisabledCallback}
            isDisabledSelectAll={isDisabledSelectAll}
            disabledIds={disabledIds}
            handleSortOrder={handleSortOrder}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

GenericCrudTable.propTypes = {
  children: PropTypes.object,
  title: PropTypes.string,
  rows: PropTypes.array,
  columns: PropTypes.array,
  isLoading: PropTypes.bool,
  orderBy: PropTypes.string,
  orderDirection: PropTypes.string,
  setSelectedRowsIdsCallback: PropTypes.func,
  selectedRowsIds: PropTypes.array,
  handleClickAddCallback: PropTypes.func,
  handleClickDeleteCallback: PropTypes.func,
  handleClickArchivedCallback: PropTypes.func,
  handleRowClickCallback: PropTypes.func,
  handleSortOrder: PropTypes.func,
  setSearchFilterCallback: PropTypes.func,
  searchFilter: PropTypes.string,
  archiveFilter: PropTypes.bool,
  maxNewDocuments: PropTypes.number,
  rowIsDisabledCallback: PropTypes.func,
  isDisabledSelectAll: PropTypes.bool,
  regions: PropTypes.array,
  scales: PropTypes.array,
  states: PropTypes.array,
  companies: PropTypes.array,
  selectedRegion: PropTypes.object,
  selectedScales: PropTypes.array,
  selectedState: PropTypes.object,
  selectedCompany: PropTypes.object,
  handleSelectRegion: PropTypes.func,
  handleSelectScales: PropTypes.func,
  handleSelectState: PropTypes.func,
  handleSelectCompany: PropTypes.func,
  showRegionSelection: PropTypes.bool,
  showScaleSelection: PropTypes.bool,
  showStateSelection: PropTypes.bool,
  showCompanySelection: PropTypes.bool,
  showReportButton: PropTypes.bool,
  showBatchedManifestButton: PropTypes.bool,
  disabledIds: PropTypes.array,
};

export default GenericCrudTable;
