import React from 'react';
import { createSvgIcon } from '@mui/material';

export const Weight = createSvgIcon(
  <>
    <path
      d="M5.76902 9.16608C7.26564 7.36559 8.99549 6.52686 11 6.52686c2.0045 0 3.7344.83873 5.231 2.63922.353.42472.9835.48283 1.4082.12979.4247-.35303.4829-.98352.1298-1.40824C15.9407 5.68812 13.6706 4.52686 11 4.52686c-2.67059 0-4.94074 1.16126-6.76902 3.36077-.35303.42472-.29492 1.05521.12979 1.40824.42472.35304 1.05521.29493 1.40825-.12979Z"
      fillOpacity=".65"
    />
    <path
      d="M11 14.5c.8285 0 1.5-.6715 1.5-1.5 0-.772-.5831-1.4077-1.3329-1.4908L9.31328 8.91812c-.32136-.44916-.946-.55276-1.39516-.2314-.44916.32136-.55276.946-.2314 1.39518l1.85018 2.5859A1.50626 1.50626 0 0 0 9.5 13c0 .8285.6715 1.5 1.5 1.5Z"
      fillOpacity=".65"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.5 0C1.11929 0 0 1.11929 0 2.5v17C0 20.8807 1.1193 22 2.5 22h17c1.3807 0 2.5-1.1193 2.5-2.5v-17C22 1.1193 20.8807 0 19.5 0h-17ZM2 2.5c0-.27614.22386-.5.5-.5h17c.2762 0 .5.22385.5.5v17c0 .2762-.2238.5-.5.5h-17c-.27615 0-.5-.2238-.5-.5v-17Z"
      fillOpacity=".65"
    />
  </>,
  'Weight'
);
