import React from 'react';
import firebase from 'firebase/compat/app';

interface ButtonCustomUploadProps {
  storageRef: any;
  onUploadStart?: (file: Object, task: Object) => void;
  onProgress?: (progress: number, task: Object) => void;
  onUploadSuccess?: (filename: string, task: Object) => void;
  onUploadError?: (error: Object, task: Object) => void;
  filename?: string | ((file: File) => string);
  style?: Object;
  // default input props;
  id?: string;
  accept?: string;
  disabled?: boolean;
  form?: string;
  formNoValidate?: boolean;
  name?: string;
  readOnly?: boolean;
  required?: boolean;
  value?: string;
  multiple?: boolean;
  children?: JSX.Element;
}

const ButtonCustomUpload = ({ ...props }: ButtonCustomUploadProps) => {
  const {
    storageRef,
    onUploadStart,
    onUploadError,
    onUploadSuccess,
    onProgress,
    children,
    ...fileUploaderProps
  }: ButtonCustomUploadProps = props;

  fileUploaderProps.id = props?.id || props?.name || '';

  const startUpload = (file: File): void => {
    const { type: contentType } = file;
    //1667248660519-filename.jpg
    const uploadTask = storageRef
      .child(`${new Date().getTime()}-${file.name}`)
      .put(file, { contentType });

    if (onUploadStart) onUploadStart(file, uploadTask);

    uploadTask.on(
      'state_changed',
      (snapshot: firebase.storage.UploadTaskSnapshot) =>
        onProgress &&
        onProgress(Math.round((100 * snapshot.bytesTransferred) / snapshot.totalBytes), uploadTask),
      (error: firebase.storage.FirebaseStorageError) =>
        onUploadError && onUploadError(error, uploadTask),
      () => onUploadSuccess && onUploadSuccess(uploadTask.snapshot.metadata.name, uploadTask)
    );
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const {
      target: { files },
    } = event;
    for (let file of files || []) {
      startUpload(file);
    }
  };

  return (
    <label htmlFor={props.name || ''} style={{ cursor: 'pointer' }}>
      <input
        type="file"
        style={{
          width: '0.1px',
          height: '0.1px',
          opacity: 0,
          overflow: 'hidden',
          position: 'absolute',
          zIndex: -1,
        }}
        onChange={handleChange}
        {...fileUploaderProps}
      />
      {children}
    </label>
  );
};

export default ButtonCustomUpload;
