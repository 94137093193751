import { useState, useEffect } from 'react';
import Query from 'services/firebase/Query';
import Executer from 'services/firebase/Executer';
import useIsUserOfType from 'services/hooks/useIsUserOfType';
import useShowError from 'modules/errors';
import { usePermissionsContext } from 'services/permissions';
import { Manifest } from 'lg-helpers/dist/shared/types/manifests';

const query = new Query();
const executer = new Executer();

interface GetDriverCurrentManifestReturn {
  isLoadingCurrentManifest: boolean;
  currentManifest: Manifest;
}

const useGetDriverCurrentManifest = (): GetDriverCurrentManifestReturn => {
  const { user } = usePermissionsContext();
  const isUserTypeCorrect = useIsUserOfType('driver', 'trucking_driver', 'trucking_dispatcher');
  const [currentManifest, setCurrentManifest] = useState<any>(null);
  const [isLoadingCurrentManifest, setIsLoadingCurrentManifest] = useState(true);
  const showError = useShowError();

  useEffect(() => {
    if (!isUserTypeCorrect) {
      return;
    }

    return executer.watchSingleDocument(
      query.manifests.getDriverCurrentManifest(user.id!),
      manif => {
        if (
          !manif?.signatureDriver?.isAcceptedRejection &&
          !manif?.signatureDriver?.isReleasedManually
        ) {
          setCurrentManifest(manif);
        }

        setIsLoadingCurrentManifest(false);
      },
      error => {
        console.error('Error', error);
        setIsLoadingCurrentManifest(false);
        showError({ title: 'Error loading current manifest', duration: 10000 });
      }
    );
    // eslint-disable-next-line
  }, [user.id, isUserTypeCorrect]);

  return {
    isLoadingCurrentManifest,
    currentManifest,
  };
};

export default useGetDriverCurrentManifest;
