import { useState, useEffect } from 'react';

export default function useStickyState<T>(defaultValue: T | (() => T), key: string) {
  const [value, setValue] = useState<T>(() => {
    const stickyValue = window.localStorage.getItem(key);
    if (stickyValue !== null) return JSON.parse(stickyValue);

    return defaultValue instanceof Function ? defaultValue() : defaultValue;
  });

  useEffect(() => {
    window.localStorage.setItem(key, JSON.stringify(value));
  }, [key, value]);

  return [value, setValue] as [T, typeof setValue];
}
