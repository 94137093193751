import React from 'react';
import { DialogContent } from '@mui/material';
import ModalHeader from 'sharedComponents/DialogHeader';
import SearchSources, { PartialCompany } from './SearchSources';
import { CompanyConnectionWithCompany } from 'services/api/autogenerated';

interface ModalContainerProps {
  selected?: PartialCompany | undefined;
  setSource?: (source: CompanyConnectionWithCompany) => any;
}

const ModalContainer = ({ selected, setSource }: ModalContainerProps) => {
  return (
    <>
      <ModalHeader title={'Add Source'} />
      <DialogContent>
        <SearchSources selected={selected} setSource={setSource} />
      </DialogContent>
    </>
  );
};

export default ModalContainer;
