import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import mapboxgl from 'mapbox-gl';
// eslint-disable-next-line no-undef
mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_TOKEN;

const Mapbox = ({ zoom, center, markers, style, onMove }) => {
  const mapRef = useRef();

  useEffect(() => {
    mapRef.current = new mapboxgl.Map({
      container: 'mapContainer',
      style: 'mapbox://styles/mapbox/streets-v11',
      center,
      zoom,
    });

    (markers || []).forEach(marker => marker.addTo(mapRef.current));
  }, [markers, center, zoom]);

  useEffect(() => {
    if (!onMove || !mapRef.current) return;

    mapRef.current.on('move', onMove);

    return () => mapRef.current.off('move');
  }, [onMove]);

  return (
    <div
      id="mapContainer"
      style={{
        ...style,
        width: '100vw',
        height: '100vh',
      }}
    />
  );
};

Mapbox.propTypes = {
  zoom: PropTypes.number.isRequired,
  center: PropTypes.array.isRequired,
  markers: PropTypes.array,
  style: PropTypes.object,
  onMove: PropTypes.func,
};

export default Mapbox;
