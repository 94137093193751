import { object, string, array, boolean } from 'yup';
import { IUser } from 'lg-helpers/dist/shared/interfaces/IUser';
import { ICompany } from 'lg-helpers/dist/shared/interfaces/ICompany';

type loginTypes = 'email' | 'phone';

export interface FixedUser extends Omit<IUser, 'type' | 'actingAsType' | 'allowedUserTypes'> {
  type: string;
  actingAsType: string;
  allowedUserTypes: string[];
}

export default object<FixedUser>({
  firstName: string().required('First name is required'),
  lastName: string().required('Last name is required'),
  preferredLoginMethod: string<loginTypes>().required('Login method is required'),
  companies: array<ICompany>().required('companies is required'),
  companiesIds: array<string>().required('CompaniesIds is required'),
  type: string().required('Type is required'),
  actingAsType: string().required('Acting as type is required'),
  allowedUserTypes: array<string>().required('Allowed user types is required'),
  generator: boolean().required('Generator is required'),
});
