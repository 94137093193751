import React, { useState, useEffect, useCallback, useMemo } from 'react';
import {
  Typography,
  Grid,
  CircularProgress,
  Dialog,
  AppBar,
  Toolbar,
  Box,
  Button,
  IconButton,
  TextField,
} from '@mui/material';
import PropTypes from 'prop-types';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import ButtonCustomUpload from 'sharedComponents/ButtonCustomUpload';
import { getBase64FromUrl } from 'services/logic/base64';
import sizeOf from 'image-size';
import { getObjectWithSortedProperties } from 'services/logic/object';
import TemplateDisplay from 'sharedComponents/TemplateDisplay';
import { hasErrorRequired } from 'services/logic/formValidation';
import { storage } from 'services/firebase';
import useShowError from 'modules/errors';
import ErrorMessages from 'services/constants/errorMessages';
import { v4 as uuidv4 } from 'uuid';
const { isRequired } = ErrorMessages;
import { JsonEditor as Editor } from 'jsoneditor-react';
import ace from 'brace';
import 'brace/mode/json';
import 'brace/theme/monokai';
import 'jsoneditor-react/es/editor.min.css';
import './TemplateEditor.scss';

export const UPLOAD_IMAGE_TYPE = {
  BACKGROUND: 'BACKGROUND',
  LOGO: 'LOGO',
};

const displayOptionsDefaultPropertyPath = '__displayOptionsDefault';
const _propertiesDisplayOptionsDefault = {
  [displayOptionsDefaultPropertyPath]: {
    font: 'Arial',
    fontSize: 50,
    color: '#000000',
  },
  'path.to.text.property': { x: 50, y: 50 },
  'path.to.big.red.text': { x: 400, y: 400, color: '#990000', fontSize: 150 },
  'path.to.imageBase64': {
    x: 500,
    y: 500,
    isImage: true,
    width: 900,
    height: 200,
  },
  'A static text': { x: 900, y: 900, isStatic: true, fontSize: 100 },
};

const defaultDimensions = {
  width: 2550,
  height: 3300,
};

const jsonInputHeight = 800;
const TemplateEditor = ({
  companyId,
  template,
  onUpdate,
  onDelete,
  showUploadBackgroundButton,
  uploadButtonType = UPLOAD_IMAGE_TYPE.BACKGROUND,
  templateSuggestions,
}) => {
  const [propertiesDisplayOptionsDefault] = useState(_propertiesDisplayOptionsDefault);
  const [isLoadingManifestTemplate, setIsLoadingManifestTemplate] = useState(false);
  const [manifestTemplateProgress, setManifestTemplateProgress] = useState(0);
  const showError = useShowError();
  const [name, setName] = useState('');
  const [showEditPopup, setShowEditPopup] = useState(false);
  const [isDeleteRequested, setIsDeleteRequested] = useState(false);
  const templateCurr = useMemo(
    () =>
      template.propertiesDisplayOptions ||
      template.propertiesDisplayOptionsInitial || // TODO: why do we need this 'initial' property?
      propertiesDisplayOptionsDefault,
    [template, propertiesDisplayOptionsDefault]
  );

  useEffect(() => {
    if (!template) {
      return;
    }
    const { name: templateName, url, base64, dimensions, isAlreadyDecorated } = template;
    setName(templateName);

    if (isAlreadyDecorated) {
      return;
    }
    let { propertiesDisplayOptionsInitial, propertiesDisplayOptions } = template;

    propertiesDisplayOptions = propertiesDisplayOptions
      ? propertiesDisplayOptions
      : propertiesDisplayOptionsDefault;
    propertiesDisplayOptions = getObjectWithSortedProperties(propertiesDisplayOptions);
    for (let propertyPath in propertiesDisplayOptions) {
      propertiesDisplayOptions[propertyPath] = getObjectWithSortedProperties(
        propertiesDisplayOptions[propertyPath]
      );
    }
    if (!propertiesDisplayOptionsInitial) {
      propertiesDisplayOptionsInitial = { ...propertiesDisplayOptions };
    }

    let updatedTemplate = {
      ...template,
      propertiesDisplayOptionsInitial,
      propertiesDisplayOptions,
      dimensions: dimensions ? dimensions : defaultDimensions,
      isAlreadyDecorated: true,
    };

    if (!base64 && url && showUploadBackgroundButton) {
      setIsLoadingManifestTemplate(true);

      getBase64FromUrl(url)
        .then(base64Data => {
          // eslint-disable-next-line no-undef
          const img = Buffer.from(base64Data.split(';base64,').pop(), 'base64');
          const dimens = sizeOf(img);
          onUpdate({
            ...updatedTemplate,
            base64: base64Data,
            dimensions: dimens,
          });
          setIsLoadingManifestTemplate(false);
        })
        .catch(err => {
          console.error(err);
          showError({
            title: 'Error downloading manifest template',
            duration: 10000,
          });
          setIsLoadingManifestTemplate(false);
        });
    } else {
      onUpdate(updatedTemplate);
    }
    // eslint-disable-next-line
  }, [template, onUpdate, propertiesDisplayOptionsDefault, showUploadBackgroundButton]);

  const handleManifestTemplateUploadSuccess = async filename => {
    setManifestTemplateProgress(100);
    setIsLoadingManifestTemplate(false);

    try {
      const manifestTemplateUploadUrl = await storage
        .ref(`manifest-templates/companies/${companyId}/`)
        .child(filename)
        .getDownloadURL();

      if (uploadButtonType === UPLOAD_IMAGE_TYPE.BACKGROUND) {
        onUpdate({
          ...template,
          url: manifestTemplateUploadUrl,
          isAlreadyDecorated: false,
        });
      } else {
        const logo = {
          url: manifestTemplateUploadUrl,
          height: 200,
          isImage: true,
          isStatic: true,
          width: 800,
          x: 100,
          y: 100,
        };

        onUpdate({
          ...template,
          propertiesDisplayOptions: {
            ...template.propertiesDisplayOptions,
            logo,
          },
          propertiesDisplayOptionsInitial: {
            ...template.propertiesDisplayOptionsInitial,
            logo,
          },
        });
      }
    } catch (err) {
      console.error(err);
      showError({ title: 'Download manifest error' });
    }
  };

  const setManifestTemplatePropertiesDisplayOptions = propertiesDisplayOptions => {
    onUpdate({
      ...template,
      propertiesDisplayOptions,
    });
  };

  const onNameChange = event => {
    setName(event.target.value);
  };

  const onNameBlur = event => {
    onUpdate({
      ...template,
      name: event.target.value,
    });
  };

  const deleteBackgroundImage = () => {
    if (uploadButtonType === UPLOAD_IMAGE_TYPE.BACKGROUND) {
      onUpdate({
        ...template,
        url: null,
        base64: null,
        dimensions: defaultDimensions,
      });
    } else {
      onUpdate({
        ...template,
        propertiesDisplayOptions: {
          ...template.propertiesDisplayOptions,
          logo: {
            url: null,
          },
        },
        propertiesDisplayOptionsInitial: {
          ...template.propertiesDisplayOptionsInitial,
          logo: { url: null },
        },
      });
    }
  };

  const handleClickEdit = useCallback(() => setShowEditPopup(true), [setShowEditPopup]);

  const handleCloseEdit = useCallback(() => setShowEditPopup(false), [setShowEditPopup]);

  return (
    <>
      <Grid item sm={6} xs={12}>
        <TextField
          onChange={onNameChange}
          onBlur={onNameBlur}
          required
          fullWidth
          label="Name"
          name="name"
          value={name || ''}
          variant="outlined"
          error={hasErrorRequired(name)}
          helperText={hasErrorRequired(name) ? isRequired : ''}
        />
      </Grid>
      <Grid item sm={6} xs={12}>
        {isDeleteRequested ? (
          <Button
            variant="contained"
            color="secondary"
            onClick={() => {
              onDelete(template);
              setIsDeleteRequested(false);
            }}
          >
            Confirm delete template
          </Button>
        ) : (
          // Hidden for https://livegistics.atlassian.net/browse/FOOT-2650
          // <IconButton
          //   color="secondary"
          //   variant="contained"
          //   disabled={!!template.notAllowDelete}
          //   onClick={() => setIsDeleteRequested(true)}
          // >
          //   <RemoveCircleOutlineIcon />
          // </IconButton>

          <div />
        )}
      </Grid>

      {name ? (
        <>
          <Grid item sm={6} xs={12}>
            {showUploadBackgroundButton && (
              <>
                <Button variant="contained" color="primary" disabled={isLoadingManifestTemplate}>
                  <ButtonCustomUpload
                    accept="image/*"
                    name={`avatar-${uuidv4()}`}
                    storageRef={storage.ref().child(`manifest-templates/companies/${companyId}/`)}
                    onUploadStart={async () => {
                      setIsLoadingManifestTemplate(true);
                      setManifestTemplateProgress(0);
                    }}
                    onUploadError={error => {
                      setIsLoadingManifestTemplate(false);
                      showError({ title: 'Upload manifest error' });
                      console.error(error);
                    }}
                    onUploadSuccess={handleManifestTemplateUploadSuccess}
                    onProgress={() => progress => setManifestTemplateProgress(progress)}
                  >
                    <Box display="flex" justifyContent="center" alignItems="center">
                      {isLoadingManifestTemplate ? (
                        <CircularProgress color="inherit" />
                      ) : (
                        <CloudUploadIcon color="inherit" />
                      )}
                      <Box marginLeft={2}>
                        <Typography variant="h5">
                          {isLoadingManifestTemplate ? `${manifestTemplateProgress}% ` : null}
                          {uploadButtonType === UPLOAD_IMAGE_TYPE.BACKGROUND
                            ? 'Upload background image'
                            : 'Upload logo image'}
                        </Typography>
                      </Box>
                    </Box>
                  </ButtonCustomUpload>
                </Button>
                {/* Hidden for https://livegistics.atlassian.net/browse/FOOT-2650*/}
                {/*<IconButton*/}
                {/*  color="secondary"*/}
                {/*  variant="contained"*/}
                {/*  disabled={*/}
                {/*    uploadButtonType === UPLOAD_IMAGE_TYPE.BACKGROUND*/}
                {/*      ? !template.url*/}
                {/*      : !template.propertiesDisplayOptions?.logo?.url*/}
                {/*  }*/}
                {/*  onClick={deleteBackgroundImage}*/}
                {/*>*/}
                {/*  <RemoveCircleOutlineIcon />*/}
                {/*</IconButton>*/}
              </>
            )}
          </Grid>
          <Grid item sm={6} xs={12}>
            {template.dimensions && (
              <>
                <Typography variant="h5">Width: {template.dimensions.width}</Typography>
                <Typography variant="h5">Height: {template.dimensions.height}</Typography>
              </>
            )}
            {(!template.url || (template.url && template.base64)) && (
              <Button variant="outlined" onClick={handleClickEdit}>
                Edit template
              </Button>
            )}
          </Grid>
          {!template.url || (template.url && template.base64) ? (
            <>
              <Grid item sm={6} xs={12}>
                {template.dimensions && <TemplateDisplay template={template} />}
              </Grid>
              <Grid item sm={6} xs={12}>
                {jsonInputHeight && (
                  <Editor
                    key={JSON.stringify(templateCurr)}
                    value={templateCurr}
                    mode="view"
                    search={true}
                  />
                )}
              </Grid>
            </>
          ) : isLoadingManifestTemplate ? (
            <CircularProgress color="inherit" />
          ) : null}
        </>
      ) : null}

      <Dialog fullScreen open={showEditPopup} onClose={handleCloseEdit}>
        <AppBar sx={{ position: 'relative' }}>
          <Toolbar>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              Edit template
            </Typography>
            <Button autoFocus color="inherit" onClick={handleCloseEdit}>
              Close
            </Button>
          </Toolbar>
        </AppBar>
        <Box display="flex">
          <Grid item sm={templateSuggestions ? 4 : 6} xs={12}>
            Preview:
            {template.dimensions && <TemplateDisplay template={template} />}
          </Grid>
          <Grid item sm={templateSuggestions ? 4 : 6} xs={12}>
            Template code:
            <Editor
              value={templateCurr}
              ace={ace}
              mode="code"
              theme="ace/theme/monokai"
              onChange={jsObject =>
                jsObject && setManifestTemplatePropertiesDisplayOptions(jsObject)
              }
              schema={{ $schema: 'http://json-schema.org/draft-04/schema#' }}
              history={true}
              search={true}
            />
          </Grid>
          {templateSuggestions && (
            <Grid item sm={templateSuggestions ? 4 : 6} xs={12}>
              List of properties:
              <Editor
                search={true}
                value={templateSuggestions}
                theme="ace/theme/monokai"
                mode="view"
                ace={ace}
                height={jsonInputHeight}
              />
            </Grid>
          )}
        </Box>
      </Dialog>
    </>
  );
};

TemplateEditor.propTypes = {
  title: PropTypes.string,
  template: PropTypes.object,
  companyId: PropTypes.string,
  onUpdate: PropTypes.func,
  onDelete: PropTypes.func,
  showUploadBackgroundButton: PropTypes.bool,
  templateSuggestions: PropTypes.any,
};

export default TemplateEditor;
