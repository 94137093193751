import { createSlice, isAnyOf } from '@reduxjs/toolkit';
import {
  getDriverTicketsByCompanyId,
  getDeletedTicketsByCompanyId,
  getTicketsByDispatchId,
  getTruckingTicketNotesByTicketId,
  getCreatorTicketsByCompanyId,
  getCreatorTicketsByCompaniesIds,
  getTicketExportTemplate,
  getContractorTicketsByRelatedCompanyId,
} from './actions';
import { RootState } from 'store/store';
import { TFormStatus } from 'lg-helpers/dist/shared/types/TFormStatus';
import { ITruckingTicketNote } from 'lg-helpers/dist/trucking/interfaces/ITruckingTicketNote';
import { ITruckingTicket } from 'lg-helpers/dist/trucking/interfaces/ITruckingTicket';
import { TemplateAPIForm } from 'modules/civilAndTrucking/trucking/features/Tickets/views/TicketsView/components/ExportTicketsModal/TicketExportCSVTemplate';

export interface ITicketState {
  data: ITruckingTicket[];
  deletedData: ITruckingTicket[];
  ticketNotes: ITruckingTicketNote[];
  ticketsExportTemplate: TemplateAPIForm;
  status: TFormStatus;
  statusDeleted: TFormStatus;
  statusTicketNotes: TFormStatus;
  ticketsExportTemplateStatus: TFormStatus;
}

const initialTicketState: ITicketState = {
  data: [] as ITruckingTicket[],
  deletedData: [] as ITruckingTicket[],
  ticketNotes: [] as ITruckingTicketNote[],
  ticketsExportTemplate: {} as TemplateAPIForm,
  status: null,
  statusDeleted: null,
  statusTicketNotes: null,
  ticketsExportTemplateStatus: null,
};

export const ticketsStoreSlice = createSlice({
  name: 'tickets',
  reducers: {},
  initialState: initialTicketState,
  extraReducers: builder => {
    builder
      .addCase(getTruckingTicketNotesByTicketId.pending, state => {
        state.statusTicketNotes = 'pending';
      })
      .addCase(getTruckingTicketNotesByTicketId.fulfilled, (state, { payload }) => {
        state.statusTicketNotes = 'success';
        state.ticketNotes = payload as ITruckingTicketNote[];
      })
      .addCase(getTruckingTicketNotesByTicketId.rejected, (state, { error }) => {
        state.statusTicketNotes = 'failure';
        state.ticketNotes = [] as ITruckingTicketNote[];
        console.error('getTruckingTicketNotesByTicketId error: ', error);
      })
      .addCase(getDeletedTicketsByCompanyId.pending, state => {
        state.statusDeleted = 'pending';
      })
      .addCase(getDeletedTicketsByCompanyId.fulfilled, (state, { payload }) => {
        state.statusDeleted = 'success';
        state.deletedData = payload;
      })
      .addCase(getDeletedTicketsByCompanyId.rejected, (state, { error }) => {
        state.statusDeleted = 'failure';
        state.deletedData = [] as ITruckingTicket[];
        console.error('getDeletedTicketsByCompanyId error: ', error);
      })
      .addCase(getTicketExportTemplate.pending, state => {
        state.ticketsExportTemplateStatus = 'pending';
      })
      .addCase(getTicketExportTemplate.fulfilled, (state, { payload }) => {
        state.ticketsExportTemplateStatus = 'success';
        state.ticketsExportTemplate = payload;
      })
      .addCase(getTicketExportTemplate.rejected, (state, { error }) => {
        state.ticketsExportTemplateStatus = 'failure';
        state.ticketsExportTemplate = {} as TemplateAPIForm;
        console.error('getTicketExportTemplate error: ', error);
      })
      .addMatcher(
        isAnyOf(
          getDriverTicketsByCompanyId.pending,
          getCreatorTicketsByCompanyId.pending,
          getCreatorTicketsByCompaniesIds.pending,
          getContractorTicketsByRelatedCompanyId.pending,
          getTicketsByDispatchId.pending
        ),
        state => {
          state.status = 'pending';
        }
      )
      .addMatcher(
        isAnyOf(
          getDriverTicketsByCompanyId.fulfilled,
          getCreatorTicketsByCompanyId.fulfilled,
          getCreatorTicketsByCompaniesIds.fulfilled,
          getContractorTicketsByRelatedCompanyId.fulfilled,
          getTicketsByDispatchId.fulfilled
        ),
        (state, { payload }) => {
          state.status = 'success';
          state.data = payload;
        }
      )
      .addMatcher(
        isAnyOf(
          getDriverTicketsByCompanyId.rejected,
          getCreatorTicketsByCompanyId.rejected,
          getCreatorTicketsByCompaniesIds.rejected,
          getContractorTicketsByRelatedCompanyId.rejected,
          getTicketsByDispatchId.rejected
        ),
        (state, { error }) => {
          state.status = 'failure';
          state.data = [] as ITruckingTicket[];
          console.error('getDriverTicketsByCompanyId error: ', error);
        }
      );
  },
});

export const selectTickets = (state: RootState) => {
  return {
    tickets: state.trucking.tickets.data,
    ticketsStatus: state.trucking.tickets.status,
  };
};

export const selectDeletedTickets = (state: RootState) => {
  return {
    deletedTickets: state.trucking.tickets.deletedData,
    deletedTicketsStatus: state.trucking.tickets.statusDeleted,
  };
};

export const selectTicketNotes = (state: RootState) => {
  return {
    ticketNotes: state.trucking.tickets.ticketNotes,
    ticketsNotesStatus: state.trucking.tickets.statusTicketNotes,
  };
};

export const selectTicketExportTemplate = (state: RootState) => {
  return {
    ticketsExportTemplate: state.trucking.tickets.ticketsExportTemplate,
    ticketsExportTemplateStatus: state.trucking.tickets.ticketsExportTemplateStatus,
  };
};
