import { firebase } from 'lg-helpers';
import { v4 as uuidv4 } from 'uuid';
import { firestore, storage, Timestamp, FieldValue } from './index';
import { ExtendedManifestCommand } from './ExtendedManifestCommand';
import QueryBase from 'lg-helpers/dist/firestore/query/QueryBase';

const { FirestoreCommand } = firebase;

class Command extends FirestoreCommand {
  constructor() {
    super(firestore, storage, Timestamp, FieldValue);

    this.manifests = new ExtendedManifestCommand(firestore, storage, Timestamp, FieldValue);
  }

  uploadUserSignatureAndGetUrl(authUserId, base64) {
    return new Promise((resolve, reject) => {
      const uploadTask = this.storage
        .ref()
        .child(`users/${authUserId}/signatures/${uuidv4()}.png`)
        .putString(base64, 'data_url');
      uploadTask
        .then(async () => {
          const url = await uploadTask.snapshot.ref.getDownloadURL();
          resolve(url);
        })
        .catch(() => reject());
    });
  }

  deleteFile(fileUrl) {
    return new Promise((resolve, reject) => {
      this.storage
        .refFromURL(fileUrl)
        .delete()
        .then(() => resolve())
        .catch(err => {
          if (err && err.code === 'storage/object-not-found') {
            resolve();
          } else {
            reject();
          }
        });
    });
  }

  async createViewerUserRequestNotification(userId, viewerAccessCompanies, inviterCompany) {
    const inviterCompanyViewerAccess = viewerAccessCompanies?.find(
      viewerAccessCompany => viewerAccessCompany.inviterCompanyId === inviterCompany.id
    );

    const notification = {
      createdAt: FieldValue.serverTimestamp(),
      details: `${inviterCompany.name} has requested you to become a Trucking Viewer for contractors: ${inviterCompanyViewerAccess?.inviterCompanyAccessCompany?.name}`,
      headline: 'Viewer Access Request',
      notificationType: 'VIEWER_ACCESS_REQUEST',
      targetId: String(userId),
      targetType: 'USERS',
      customData: {
        allowedUserTypes: ['Trucking Viewer'],
        inviterCompanyViewerAccess,
        inviterCompany: {
          id: inviterCompany.id,
          name: inviterCompany.name,
          types: inviterCompany.types,
        },
      },
      isHidden: false,
    };
    try {
      await this.add(QueryBase.NOTIFICATIONS_COLLECTION(), notification);
    } catch (error) {
      console.error('Error adding user notification', error);
    }
  }
}

export default Command;
