export default {
  __displayOptionsDefault: {
    color: '#000000',
    font: 'Arial',
    fontSize: 50,
  },
  'Capacity unit:': {
    fontSize: 55,
    isStatic: true,
    x: 1400,
    y: 650,
  },
  'Contractor:': {
    isStatic: true,
    x: 40,
    y: 200,
  },
  contractorName: {
    x: 470,
    y: 200,
  },
  'Customer #:': {
    isStatic: true,
    x: 40,
    y: 100,
  },
  'Customer job #:': {
    fontSize: 55,
    isStatic: true,
    x: 40,
    y: 850,
  },
  customerNumber: {
    fontSize: 55,
    x: 470,
    y: 850,
  },
  date: {
    x: 470,
    y: 400,
  },
  'Date:': {
    isStatic: true,
    x: 40,
    y: 400,
  },
  "Driver's signature:": {
    fontSize: 55,
    isStatic: true,
    x: 1400,
    y: 1100,
  },
  'Dumped at:': {
    fontSize: 55,
    isStatic: true,
    x: 1400,
    y: 1510,
  },
  "Gate attendant's signature:": {
    fontSize: 55,
    isStatic: true,
    x: 40,
    y: 1100,
  },
  'Loaded at:': {
    fontSize: 55,
    isStatic: true,
    x: 40,
    y: 1510,
  },
  'Lot #:': {
    isStatic: true,
    x: 1400,
    y: 100,
  },
  lotNumber: {
    x: 1800,
    y: 100,
  },
  'Manifest #:': {
    fontSize: 55,
    isStatic: true,
    x: 1400,
    y: 750,
  },
  manifestNumber: {
    fontSize: 55,
    x: 1800,
    y: 750,
  },
  material: {
    x: 470,
    y: 300,
  },
  'Material:': {
    isStatic: true,
    x: 40,
    y: 300,
  },
  'Pit ticket picture:': {
    fontSize: 55,
    isStatic: true,
    x: 40,
    y: 1600,
  },
  'Pit ticket:': {
    fontSize: 55,
    isStatic: true,
    x: 40,
    y: 750,
  },
  'Project:': {
    isStatic: true,
    x: 1400,
    y: 200,
  },
  projectNumber: {
    x: 1800,
    y: 200,
  },
  quantity: {
    fontSize: 55,
    x: 470,
    y: 550,
  },
  'Quantity:': {
    fontSize: 55,
    isStatic: true,
    x: 40,
    y: 550,
  },
  'signatureDriver.pitTicket': {
    fontSize: 55,
    x: 470,
    y: 750,
  },
  'signatureDriver.signedAtDumped': {
    fontSize: 55,
    isDate: true,
    x: 1720,
    y: 1510,
  },
  'signatureDriver.signedAtLoaded': {
    fontSize: 55,
    isDate: true,
    x: 340,
    y: 1510,
  },
  'signatureDriver.truckCapacity': {
    fontSize: 55,
    x: 470,
    y: 650,
  },
  'signatureDriver.truckCapacityUnit': {
    fontSize: 55,
    x: 1800,
    y: 650,
  },
  'signatureDriver.truckNumber': {
    fontSize: 55,
    x: 1800,
    y: 550,
  },
  'signatureDriver.urlDriver': {
    height: 300,
    isImage: true,
    width: 900,
    x: 1400,
    y: 1130,
  },
  'signatureDriver.urlGateAttendant': {
    height: 300,
    isImage: true,
    width: 900,
    x: 40,
    y: 1130,
  },
  'signatureDriver.urlPitTicket': {
    height: 1600,
    isImage: true,
    width: 2450,
    x: 40,
    y: 1650,
  },
  source: {
    x: 1800,
    y: 300,
  },
  'Source:': {
    isStatic: true,
    x: 1400,
    y: 300,
  },
  'Special instructions:': {
    fontSize: 55,
    isStatic: true,
    x: 40,
    y: 950,
  },
  specialInstr: {
    fontSize: 55,
    x: 600,
    y: 950,
  },
  'Ticket #:': {
    fontSize: 55,
    isStatic: true,
    x: 1400,
    y: 850,
  },
  ticketNumber: {
    fontSize: 55,
    x: 1800,
    y: 850,
  },
  'Truck #:': {
    fontSize: 55,
    isStatic: true,
    x: 1400,
    y: 550,
  },
  'Truck capacity:': {
    fontSize: 55,
    isStatic: true,
    x: 40,
    y: 650,
  },
};
