import { createSlice } from '@reduxjs/toolkit';
import { getActivityTypes, addActivityType, deleteActivityType } from './actions';
import { IActivityType } from 'lg-helpers/dist/shared/interfaces/IActivityType';
import { TFormStatus } from 'lg-helpers/dist/shared/types/TFormStatus';

interface IActivityTypeState {
  data: IActivityType[];
  status: TFormStatus;
  error: string | undefined;
}

const initialState: IActivityTypeState = {
  data: [],
  status: null,
  error: undefined,
};

export const activityTypeSlice = createSlice({
  name: 'activity-types',
  reducers: {
    add: state => state,
    addActivityType: state => state,
    deleteActivityType: state => state,
    getActivityTypes: state => state,
  },
  initialState,
  extraReducers: builder => {
    builder.addCase(getActivityTypes.fulfilled, (state, { payload }) => {
      state.status = 'success';
      state.data = payload as IActivityType[];
    });

    builder.addCase(getActivityTypes.rejected, (state, action) => {
      state.status = 'failure';
      state.error = action.error?.message;
    });

    builder.addCase(addActivityType.fulfilled, (state, { payload }) => {
      state.status = 'success';
      const newData = !state.data.some(item => item.id === payload?.id);
      if (newData) {
        state.data.push(payload as IActivityType);
      } else {
        const index = state.data.findIndex(a => a.id === payload?.id);
        state.data[index] = payload!;
      }
    });

    builder.addCase(addActivityType.rejected, (state, action) => {
      state.status = 'failure';
      state.error = action.error?.message;
    });

    builder.addCase(deleteActivityType.fulfilled, (state, { payload }) => {
      state.status = 'success';
      state.data = state.data.filter(item => item.id !== payload?.workCategory.id);
    });
  },
});
