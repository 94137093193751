import React, { useCallback, useEffect, useMemo, useState } from 'react';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import {
  Checkbox,
  Button,
  Grid,
  TextField,
  Box,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
  ListItem,
  List,
} from '@mui/material';
import { v4 as uuid } from 'uuid';

interface CsvHeaders {
  selected: boolean;
  order: number;
  text: string;
  display: string;
}

interface ReorderArray {
  array: Array<any>;
  begin: number;
  end: number;
}

const FormatDataCSV = ({ data }: { data: any }) => {
  const [dragId, setDragId] = useState<string>('');
  const initialHeaders = useMemo(
    () => [
      ...new Set(
        [].concat.apply(
          [],
          (data || []).map((e: any) => Object.keys(e))
        )
      ),
    ],
    [data]
  );
  const listId = useMemo(() => uuid(), []);

  const processHeaders = (headers: Array<CsvHeaders>, column: 'text' | 'display' = 'text') =>
    (headers || [])
      .map((header: CsvHeaders) => (header?.selected && header[column]) || null)
      .filter((e: any) => e);

  const processColumnData = (columnData: any) => {
    if (!columnData && !columnData?.length) return '';
    let value = '';
    if (columnData?.seconds) {
      return new Date(new Date(0).setUTCSeconds(columnData.seconds));
    }
    switch (typeof columnData) {
      case 'undefined':
        break;
      case 'string':
        value = columnData;
        break;
      case 'number':
        value = String(columnData);
        break;
      case 'object':
        value = Array.isArray(columnData)
          ? columnData?.join('\n')
          : (Object.entries(columnData) || [])?.map(v => `${v[0]}: ${v[1]}`).join('\n');
        break;
    }
    return value.search(/("|,|\n)/g) >= 0 ? `"${value}"` : value;
  };

  const [csvHeaders, setCsvHeaders] = useState<any[]>(
    (initialHeaders || []).map((text, order) => ({
      order,
      text,
      selected: true,
      display: text,
    }))
  );

  const csvRows = useCallback(
    () =>
      (data || []).map((users: any) =>
        (processHeaders(csvHeaders) || []).map((column: any) => processColumnData(users[column]))
      ),
    [csvHeaders, data]
  );

  const csvTable = useCallback(
    () => [processHeaders(csvHeaders, 'display'), ...csvRows()],
    [csvHeaders, csvRows]
  );

  const processOutputCSV = useCallback(
    () => `data:text/csv;charset=utf-8,${(csvTable() || []).map(e => e.join(',')).join('\r\n')}`,
    [csvTable]
  );

  const [csvOutput, setCsvOutput] = useState<string>(processOutputCSV());

  const deselectHeader = (text: string) =>
    setCsvHeaders(() =>
      csvHeaders.map((header: CsvHeaders) => {
        if (header.text === text) header.selected = !header.selected;
        return header;
      })
    );

  const reorder = ({ array, begin, end }: ReorderArray) => {
    const smallerIndex = Math.min(begin, end);
    const largerIndex = Math.max(begin, end);

    return [
      ...array.slice(0, smallerIndex),
      ...(begin < end ? array.slice(smallerIndex + 1, largerIndex + 1) : []),
      array[begin],
      ...(begin > end ? array.slice(smallerIndex, largerIndex) : []),
      ...array.slice(largerIndex + 1),
    ];
  };

  const indexOfHeader = (text: string | undefined) =>
    csvHeaders.indexOf(csvHeaders.find((header: CsvHeaders) => header.text === text));

  const handleDisplayChange = (text: string | undefined, value: string) => {
    let newHeaders = csvHeaders;
    newHeaders[indexOfHeader(text)].display = value;
    setCsvHeaders([...csvHeaders, ...newHeaders]);
    return setCsvHeaders(() =>
      csvHeaders.map((header: CsvHeaders) => {
        if (header.text === text) header.display = value;
        return header;
      })
    );
  };

  const handleDrop = (event: React.DragEvent) => {
    const target = event.target as Element;
    const reposition = {
      array: csvHeaders,
      begin: indexOfHeader(dragId),
      end: indexOfHeader(target?.id || target.closest('li')?.id),
    };
    if (!target || reposition.end < 0) return;
    setCsvHeaders(() => reorder(reposition));
  };

  useEffect(() => {
    setCsvOutput(() => processOutputCSV());
  }, [csvHeaders, processOutputCSV]);

  return (
    <>
      <Grid container spacing={2} alignItems="top">
        <Grid item xs={12} md={'auto'}>
          <Button
            color="primary"
            variant="outlined"
            onClick={() => window.open(encodeURI(csvOutput))}
          >
            Download CSV
          </Button>
        </Grid>
        <Grid item xs={12} md>
          <Accordion>
            <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1bh-content">
              <Typography variant="h5"> CSV Columns </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <List id={listId} style={{ width: '100%' }}>
                {(csvHeaders || []).map(({ text, selected, display }: CsvHeaders) => {
                  return (
                    <ListItem
                      id={text}
                      key={text}
                      style={{
                        width: '100%',
                        cursor: 'grab',
                      }}
                      draggable={true}
                      onDragOver={et => et.preventDefault()}
                      onDragEnter={handleDrop}
                      onDragStart={() => setDragId(text)}
                      onDrop={handleDrop}
                    >
                      <DragIndicatorIcon />
                      <Checkbox
                        icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                        checkedIcon={<CheckBoxIcon fontSize="small" />}
                        checked={selected}
                        onChange={() => deselectHeader(text)}
                        color="primary"
                      />
                      <Box display="flex" width="100%">
                        <TextField
                          fullWidth
                          name={`${text}-input`}
                          onChange={event => handleDisplayChange(text, event.target.value)}
                          placeholder={text}
                          type="text"
                          value={display}
                        />
                      </Box>
                    </ListItem>
                  );
                })}
              </List>
            </AccordionDetails>
          </Accordion>
        </Grid>
      </Grid>
      <pre>{csvOutput}</pre>
    </>
  );
};

export default FormatDataCSV;
