import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Grid, Card, CardHeader, Divider, CardContent, Box, Typography } from '@mui/material';
import { DateRangePicker } from 'rsuite';
import BorderClearIcon from '@mui/icons-material/BorderClear';
import { setCompanyBrokerLoadsTheSelectedTemplate } from 'api/super-admin';
import ButtonWithLoading from 'sharedComponents/ButtonWithLoading';
import useShowError from 'modules/errors';
import FTPConfig from './FTPConfig';

import CompanyFeatureFlagList from './CompanyFeatureFlagList';
import { ICompany } from 'services/api/autogenerated-code';

interface AdministratorPanelProps {
  company: ICompany;
  isBrokerCompany: boolean;
  selectedBrokerLoadTemplate: { id: string; [key: string]: any };
}

const AdministratorPanel = ({
  company,
  isBrokerCompany,
  selectedBrokerLoadTemplate,
}: AdministratorPanelProps) => {
  const showError = useShowError();
  const [dateRange, setDateRange] = useState([] as [Date?, Date?]);
  const [countBrokerLoads, setCountBrokerLoads] = useState<number | null>(null);
  const [message, setMessage] = useState<string | null>(null);

  useEffect(() => {
    if (countBrokerLoads !== null) {
      setMessage(`${countBrokerLoads} broker loads`);
    }
  }, [countBrokerLoads]);

  const executeSetCompanyBrokerLoadsTheSelectedTemplate = async () => {
    try {
      setCountBrokerLoads(null);
      setMessage(null);
      const { count } = await setCompanyBrokerLoadsTheSelectedTemplate(
        company?.id || '',
        selectedBrokerLoadTemplate.id,
        dateRange[0] as Date,
        dateRange[1] as Date
      );
      setCountBrokerLoads(count);
    } catch (err) {
      console.error(err);
      showError({ title: 'Error set template' });
    }
  };

  return (
    <Card style={{ border: '2px solid' }}>
      <CardHeader title="Administrator panel" />
      <Divider />
      <CardContent>
        <Grid container spacing={2}>
          {message !== null && (
            <Grid item xs={12}>
              <Box display="flex" justifyContent="center" alignItems="center">
                <Typography variant="h3">{message}</Typography>
              </Box>
            </Grid>
          )}
          {isBrokerCompany && (
            <Grid item md={6} xs={12}>
              <div>
                <div style={{ fontSize: 20, marginBottom: 10 }}>Broker Actions</div>
              </div>
              <Grid item xs={12}>
                <Box display="flex" alignItems="center" marginBottom={1}>
                  <DateRangePicker
                    appearance="default"
                    placeholder="Date range"
                    placement="bottomStart"
                    size="lg"
                    format="MM/DD/YYYY"
                    value={dateRange}
                    onChange={setDateRange}
                    style={{ width: 260 }}
                    disabled={false}
                  />
                </Box>
              </Grid>
              <Grid item xs={12}>
                <ButtonWithLoading
                  Pictogram={BorderClearIcon}
                  disabled={
                    !company ||
                    !company ||
                    !(dateRange[0] && dateRange[1]) ||
                    !selectedBrokerLoadTemplate
                  }
                  text="Set broker loads the selected template"
                  onClickCallback={executeSetCompanyBrokerLoadsTheSelectedTemplate}
                />
              </Grid>
            </Grid>
          )}

          <FTPConfig companyId={company.id || ''} />

          <CompanyFeatureFlagList companyId={company.id || ''} />
        </Grid>
      </CardContent>
    </Card>
  );
};

AdministratorPanel.propTypes = {
  company: PropTypes.object,
  selectedBrokerLoadTemplate: PropTypes.object,
};

export default AdministratorPanel;
