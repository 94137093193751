import { IProjectTemplate } from 'lg-helpers/dist/shared/interfaces/IProjectTemplate';
import { object, string, boolean, number } from 'yup';

export const projectTemplateSchema = object<IProjectTemplate>({
  name: string().required('Template Name is Required'),
  projectId: number(),
  companyId: string(),
  deleted: boolean(),
  dateCreated: string(),
  createdByUserId: string(),
});
