import React, { useContext } from 'react';
import { DialogActions, Button } from '@mui/material';
import { ModalContext } from './ModalContext';

interface IDialogActionContainerProps {
  deleteHandler?: () => void;
  cancelHandler?: () => void;
  saveHandler?: (e: any) => void;
  saveText?: string;
  editing?: boolean;
  isSaving?: boolean;
}

const DialogActionContainer = ({
  saveHandler,
  deleteHandler,
  cancelHandler,
  saveText = 'Save',
  editing,
  isSaving,
}: IDialogActionContainerProps) => {
  const { setModal } = useContext(ModalContext);

  const saveCallback = async (e: any) => {
    saveHandler && (await saveHandler(e));
  };

  const cancelCallback = async () => {
    if (cancelHandler) {
      cancelHandler();
    } else {
      setModal(false);
    }
  };

  return (
    <>
      <DialogActions sx={{ my: 1, flexWrap: 'wrap' }}>
        <Button
          onClick={saveCallback}
          disabled={isSaving ? isSaving : false}
          variant="contained"
          type="submit"
          color="primary"
          sx={{
            flex: 1,
            mx: 1,
            px: 1,
          }}
        >
          {saveText}
        </Button>
        <Button
          variant="text"
          color="primary"
          onClick={cancelCallback}
          sx={{
            flex: 1,
            mx: 1,
            px: 1,
          }}
        >
          Cancel
        </Button>
        {editing && deleteHandler && (
          <Button
            onClick={deleteHandler}
            sx={{
              borderColor: 'error.main',
              borderWidth: '2px',
              borderStyle: 'solid',
              color: 'error.main',
              flex: 1,
              mx: 1,
              px: 1,
            }}
          >
            Delete
          </Button>
        )}
      </DialogActions>
    </>
  );
};

export default DialogActionContainer;
