import React from 'react';
import {
  Button,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from '@mui/material';
import { getCapacityVolumeByUnit } from 'modules/civilAndTrucking/trucking/utils';
import { ILoadTruck } from '../AddLoadsToDispatchForm';
import { UnitTruckCapacity } from 'lg-helpers/dist/shared/types/global-types';
import { CompanyConnectionWithCompany } from 'services/api/autogenerated';

type TruckLoadsTableProps = {
  handleRemoveTruck: (index: number) => void;
  handleRemoveBroker: (index: number) => void;
  unitOfMeasure: UnitTruckCapacity;
  addedTrucks: ILoadTruck[];
  addedBrokers: CompanyConnectionWithCompany[];
};

export const TruckLoadsTable = ({
  handleRemoveTruck,
  handleRemoveBroker,
  unitOfMeasure,
  addedTrucks,
  addedBrokers,
}: TruckLoadsTableProps) => {
  return (
    <TableContainer sx={{ mt: 2 }}>
      <Table aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell align="center">Truck</TableCell>
            <TableCell align="center">Division</TableCell>
            <TableCell align="center">Quantity</TableCell>
            <TableCell align="center"></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {addedTrucks.map((addedTruck, i) => (
            <TableRow key={i} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
              <TableCell align="center" component="th" scope="row">
                {addedTruck.truckNumberTruckingTruck}
              </TableCell>
              <TableCell align="center">{addedTruck.nameTruckingTruckDivision}</TableCell>
              <TableCell align="center">
                {getCapacityVolumeByUnit(unitOfMeasure, addedTruck.capacitiesTruckingTruckType)}{' '}
                {unitOfMeasure}
              </TableCell>
              <TableCell align="center">
                <Button
                  variant="contained"
                  color="error"
                  size="small"
                  onClick={() => handleRemoveTruck(i)}
                >
                  REMOVE
                </Button>
              </TableCell>
            </TableRow>
          ))}
          {addedBrokers.map((addedBroker, i) => (
            <TableRow key={i} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
              <TableCell align="center" component="th" scope="row">
                Brokered by: {addedBroker.connected_company?.name}
              </TableCell>
              <TableCell align="center"></TableCell>
              <TableCell align="center"></TableCell>
              <TableCell align="center">
                <Button
                  variant="contained"
                  color="error"
                  size="small"
                  onClick={() => handleRemoveBroker(i)}
                >
                  REMOVE
                </Button>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
