import React, { useState, useEffect } from 'react';

import api, { CompanyFeatureFlag, CompanyFeatureFlagDefinitions } from 'services/api/autogenerated';
import { Grid, IconButton, SvgIcon, Tooltip } from '@mui/material';
import { DataGrid, GridColDef, GridRenderCellParams } from '@mui/x-data-grid';

const CompanyFeatureFlagList = ({ companyId }: { companyId: string }) => {
  // List of all possibilities
  const [featureDefinitions, setFeatureDefinitions] = useState<CompanyFeatureFlagDefinitions[]>([]);

  // Actual flags (for company ID)
  const [featureFlags, setFeatureFlags] = useState<CompanyFeatureFlag[]>([]);

  useEffect(() => {
    api.featureFlags.getFeatureFlagDefinitions().then(async definitions => {
      setFeatureDefinitions(definitions.data);

      const { data: flags } = await api.featureFlags.getCompanyFeatureFlag(companyId);
      setFeatureFlags(flags);
    });
  }, [companyId]);

  const handleCheckBoxChange = async (featureName: string) => {
    const featureFlag = featureFlags?.find(flag => flag.feature_name === featureName);

    const isEnabled = featureFlag ? !featureFlag.is_enabled : false;

    const updatedFlag = await api.featureFlags.upsertCompanyFeatureFlag({
      company_id: companyId,
      feature_name: featureName,
      is_enabled: isEnabled,
    });

    setFeatureFlags(prevFlags =>
      prevFlags.map(flag => (flag.feature_name === featureName ? updatedFlag.data : flag))
    );
  };

  const cols: GridColDef[] = [
    {
      headerName: 'Feature Name',
      field: 'featureName',
      flex: 1,
    },
    {
      headerName: 'Status',
      field: 'status',
      flex: 1,
      renderCell: (params: GridRenderCellParams) => {
        const featureName = params.row.featureName;

        return (
          <input
            type="checkbox"
            checked={
              !!featureFlags?.find(flag => flag.feature_name === featureName && flag.is_enabled)
            }
            onChange={() => handleCheckBoxChange(featureName)}
          />
        );
      },
    },
  ];

  const rows = featureDefinitions.map(def => ({
    id: def.id,
    featureName: def.feature_name,
    status: !!(featureFlags || []).find(
      flag => flag.feature_name === def.feature_name && flag.is_enabled
    ),
  }));

  return (
    <Grid item md={6} xs={12}>
      <div style={{ fontSize: 20, display: 'inline' }}>Company Feature Flags</div>
      <DataGrid
        rows={rows}
        columns={cols}
        pageSize={5}
        autoHeight={true}
        disableSelectionOnClick={true}
      />
    </Grid>
  );
};

export default CompanyFeatureFlagList;
