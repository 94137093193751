// https://github.com/thread-pond/signature-pad/blob/main/documentation.md
import React, { useRef, useState, useEffect, useCallback } from 'react';
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Divider,
  CircularProgress,
  Typography,
} from '@mui/material';
import SignaturePad from 'signature_pad';

export interface InputSignatureProps {
  onSignatureBase64Update?: (val: string) => any;
  signatureBase64: string;
  isLoading?: boolean;
  title?: string;
  disabled?: boolean;
}

const InputSignature = ({
  signatureBase64,
  isLoading,
  onSignatureBase64Update,
  title,
  disabled,
}: InputSignatureProps) => {
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const [signaturePad, setSignaturePad] = useState<SignaturePad | null>(null);

  const onClearClick = useCallback(() => {
    if (onSignatureBase64Update) onSignatureBase64Update('');
  }, [onSignatureBase64Update]);

  useEffect(() => {
    if (!canvasRef || !canvasRef.current) {
      return;
    }

    setSignaturePad(
      new SignaturePad(canvasRef.current, {
        penColor: 'black',
      })
    );
  }, [canvasRef]);

  useEffect(() => {
    if (!signaturePad) {
      return;
    }

    const endStrokeCallback = () => {
      const base64 = signaturePad.toDataURL();
      if (onSignatureBase64Update) onSignatureBase64Update(base64);
    };

    signaturePad.addEventListener('endStroke', endStrokeCallback);

    return () => {
      signaturePad.removeEventListener('endStroke', endStrokeCallback);
    };
  }, [signaturePad, onSignatureBase64Update]);

  useEffect(() => {
    if (!signaturePad) {
      return;
    }
    const resizeCanvas = () => {
      if (!canvasRef.current) return;
      var ratio = Math.max(window.devicePixelRatio || 1, 1);
      canvasRef.current.width = canvasRef.current.offsetWidth * ratio;
      canvasRef.current.height = canvasRef.current.width / 3;
      //canvasRef.current.offsetHeight * ratio;
      canvasRef.current.getContext('2d')!.scale(ratio, ratio);
      if (signaturePad) signaturePad.clear();
      if (signatureBase64) signaturePad.fromDataURL(signatureBase64);
    };
    resizeCanvas();

    window.addEventListener('resize', resizeCanvas);
    return () => {
      window.removeEventListener('resize', resizeCanvas);
    };
  }, [signaturePad, signatureBase64]);

  useEffect(() => {
    if (!signaturePad) {
      return;
    }

    if (isLoading || disabled) {
      signaturePad.off();
    } else {
      signaturePad.on();
    }
  }, [signaturePad, isLoading, disabled]);

  return (
    <>
      <Card>
        <CardHeader
          title={
            <Box alignItems="center" display="flex">
              <Typography variant="body1">{title ? title : 'Signature *'}</Typography>
              {isLoading && (
                <Box marginLeft={2}>
                  <CircularProgress size="15px" color="inherit" />
                </Box>
              )}
            </Box>
          }
        />
        <CardContent>
          <Box alignItems="center" display="flex" flexDirection="column">
            <canvas
              ref={canvasRef}
              style={{
                width: '100%',
                height: '100%',
                border: '1px solid #000000',
                backgroundColor: 'white',
              }}
            />
          </Box>
        </CardContent>
        {!disabled && (
          <>
            <Divider />
            <CardActions>
              <Button
                color="primary"
                fullWidth
                variant="text"
                disabled={!signaturePad || isLoading || disabled}
                onClick={onClearClick}
              >
                Clear
              </Button>
            </CardActions>
          </>
        )}
      </Card>
    </>
  );
};

export default InputSignature;
