import React, { useCallback, useEffect, useState } from 'react';
import { Typography, Grid, Box, Checkbox, SvgIcon } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { RootState, thunkDispatch } from 'store/store';
import { getActiveCompanyUsers } from '../../Users/redux/actions';
import { useParams, useNavigate } from 'react-router-dom';
import {
  addCompanyCollaborator,
  addUserCollaborator,
  getProjectCompanyCollaborators,
  getProjectUsersCollaborators,
  removeCompanyCollaborator,
  removeUserCollaborator,
} from '../redux/actions';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { LoadingComponent } from 'sharedComponents/LoadingComponent';
import { IUser } from 'lg-helpers/dist/shared/interfaces/IUser';
import { ChevronLeft } from '@mui/icons-material';
import useApiGetRequest from 'services/api/useApiGetRequest';
import api, {
  ConnectedCompanyType,
  CompanyConnectionWithCompany,
} from 'services/api/autogenerated';
import { usePermissionsContext } from 'services/permissions';
import debounce from 'lodash/debounce';

const ProjectCollaborators = () => {
  const dispatch = useDispatch();
  const [type, setType] = useState<'company' | 'user'>('company');
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const { projectId } = useParams();
  const { user: authUser } = usePermissionsContext();
  const [searchFilter, setSearchFilter] = useState('');
  const [pageSize, setPageSize] = useState<number>(50);
  const [skip, setSkip] = useState<number>(0);
  const [eligibleUsers, setEligibleUsers] = useState<IUser[]>();

  const activeCompanyId = useSelector(
    (state: RootState) => state.civil.companies.activeCompany?.id
  );

  const userState = useSelector((state: RootState) => state.civil.users.data);

  const companyCollaborators = useSelector(
    (state: RootState) => state.civil.projects.companyCollaborators
  );

  const userCollaborators = useSelector(
    (state: RootState) => state.civil.projects.userCollaborators
  );

  const [data, { loading }] = useApiGetRequest(
    () =>
      api.companiesConnections.searchConnectedCompanies(
        authUser.companiesIds[0],
        [ConnectedCompanyType.Transporter],
        searchFilter,
        pageSize,
        skip
      ),
    () => ({ title: 'Failed to load transporters', duration: 3000 }),
    [searchFilter, pageSize, skip]
  );

  const {
    data: truckersState,
    pagination: { total: rowCount },
  } = data || { pagination: { total: 0 } };

  // TODO: implement search on this page too?
  const debouncedSetSearchFilter = useCallback(
    debounce(value => setSearchFilter(value), 200),
    []
  );

  useEffect(() => {
    const users = userState?.filter(user =>
      user?.allowedUserTypes?.some(userType =>
        ['Civil Viewer', 'Civil Site User'].includes(userType)
      )
    );
    setEligibleUsers(users);
  }, [userState]);

  useEffect(() => {
    (async () => {
      try {
        if (activeCompanyId) {
          setIsLoading(true);
          await Promise.all([
            thunkDispatch(getActiveCompanyUsers()),
            thunkDispatch(getProjectCompanyCollaborators({ projectId: Number(projectId) })),
            thunkDispatch(getProjectUsersCollaborators(Number(projectId))),
          ]);
        }
      } catch (err) {
        console.error(err);
      } finally {
        setIsLoading(false);
      }
    })();
  }, [dispatch, activeCompanyId, projectId]);

  const handleToggle = () => {
    setType(prev => (prev === 'company' ? 'user' : 'company'));
  };

  const isCompanyCollaborator = (trucker: CompanyConnectionWithCompany) => {
    return companyCollaborators && companyCollaborators.length
      ? companyCollaborators?.some(
          collab => collab.truckingCompanyId === trucker.connected_company_id
        )
      : false;
  };

  const updateCompanyCollaborators = async (trucker: CompanyConnectionWithCompany) => {
    const isAssociated = isCompanyCollaborator(trucker);
    try {
      setIsLoading(true);
      if (!isAssociated && projectId && trucker.id) {
        await thunkDispatch(
          addCompanyCollaborator({
            civilProjectId: Number(projectId),
            truckingCompanyId: trucker?.connected_company_id,
            createdBy: authUser?.id!,
          })
        );
      } else if (isAssociated && projectId && trucker.id) {
        const collaboratorRecord = companyCollaborators.find(
          a => a.truckingCompanyId === trucker.connected_company_id
        );
        await thunkDispatch(
          removeCompanyCollaborator({
            projectId: Number(projectId),
            collaboratorId: collaboratorRecord?.id!,
          })
        );
        await thunkDispatch(getProjectCompanyCollaborators({ projectId: Number(projectId) }));
      }
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
      console.error('Unable to save collaborator: ', err);
    }
  };

  const isUserCollaborator = (user: IUser) => {
    return userCollaborators && userCollaborators.length
      ? userCollaborators?.some(collab => collab.userId === user.id)
      : false;
  };

  const updateUserCollaborators = async (user: IUser) => {
    const isAssociated = isUserCollaborator(user);
    try {
      setIsLoading(true);
      if (!isAssociated && projectId && user.id) {
        await thunkDispatch(
          addUserCollaborator({
            civilProjectId: Number(projectId),
            userId: user?.id,
            createdBy: authUser?.id,
          })
        );
      } else if (isAssociated && projectId && user.id) {
        const collaboratorRecord = userCollaborators.find(a => a.userId === user.id);
        await thunkDispatch(
          removeUserCollaborator({
            projectId: Number(projectId),
            civilUsersToProjectId: collaboratorRecord?.id!,
          })
        );
        await thunkDispatch(getProjectUsersCollaborators(Number(projectId)));
      }
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
      console.error('Unable to save collaborator: ', err);
    }
  };

  const companyColumns: GridColDef<CompanyConnectionWithCompany>[] = [
    {
      field: 'selected',
      headerName: ' ',
      renderCell: ({ row }) => (
        <Checkbox
          color="secondary"
          checked={isCompanyCollaborator(row)}
          onChange={event => updateCompanyCollaborators(row)} // eslint-disable-line
        />
      ),
    },
    {
      field: 'connected_company.name',
      headerName: 'Company Name',
      flex: 1,
      valueGetter: params => params.row.connected_company.name,
    },
    {
      field: 'connected_company.city',
      headerName: 'City',
      flex: 1,
      valueGetter: params => params.row.connected_company.city,
    },
    {
      field: 'connected_company.state',
      headerName: 'State',
      flex: 1,
      valueGetter: params => params.row.connected_company.state,
    },
    {
      field: 'connected_company.phone',
      headerName: 'Phone',
      flex: 1,
      valueGetter: params => params.row.connected_company.phone,
    },
    {
      field: 'connected_company.email',
      headerName: 'Email',
      flex: 1,
      valueGetter: params => params.row.connected_company.email,
    },
  ];

  const userColumns: GridColDef[] = [
    {
      field: 'selected',
      headerName: ' ',
      renderCell: ({ row }) => (
        <Checkbox
          color="secondary"
          checked={isUserCollaborator(row)}
          onChange={event => updateUserCollaborators(row)} // eslint-disable-line
        />
      ),
    },
    { field: 'firstName', headerName: 'First Name', flex: 1 },
    { field: 'lastName', headerName: 'Last Name', flex: 1 },
    { field: 'email', headerName: 'Email', flex: 1 },
    { field: 'type', headerName: 'Type', flex: 1 },
  ];

  return (
    <>
      <Grid container spacing={1} alignItems="center">
        <Grid item xs={6} display="flex" alignItems="center">
          <Box>
            <Typography
              variant="h5"
              sx={{
                color: 'neutral.main',
                typography: { textTransform: 'uppercase' },
              }}
            >
              Collaborators
            </Typography>
            <Box
              display="flex"
              sx={{
                '&:hover': {
                  cursor: 'pointer',
                },
              }}
            >
              <SvgIcon>
                <ChevronLeft />
              </SvgIcon>
              <Typography onClick={() => navigate(-1)}>Back</Typography>
            </Box>
          </Box>
        </Grid>
        <Grid item xs={6} display="flex" alignItems="center" justifyContent="flex-end">
          <Box
            display="flex"
            bgcolor="#BFC8C4"
            width={270}
            borderRadius={100}
            alignItems="center"
            alignSelf="center"
            justifyContent="space-evenly"
            paddingY={1}
            paddingX={1}
            marginX={1}
          >
            <Box
              width="100%"
              color={type === 'company' ? '#FFF' : '#989898'}
              bgcolor={type === 'company' ? 'secondary.main' : '#efefef'}
              marginY={0.02}
              paddingY={1}
              paddingX={0.8}
              borderRadius={100}
              onClick={handleToggle}
            >
              <Typography variant="body2" align="center">
                Company
              </Typography>
            </Box>
            <Box
              width="100%"
              color={type === 'user' ? '#FFF' : '#9C9E98'}
              bgcolor={type === 'user' ? 'secondary.main' : '#F0F4F6'}
              marginY={0.02}
              paddingY={1}
              paddingX={0.8}
              borderRadius={100}
              onClick={handleToggle}
            >
              <Typography variant="body2" align="center">
                User
              </Typography>
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12} minHeight={500} height={'80vh'}>
          {type === 'company' ? (
            <DataGrid
              loading={loading}
              columns={companyColumns}
              rows={truckersState || []}
              rowCount={rowCount}
              pageSize={pageSize}
              getRowId={row => row.id}
              page={skip}
              onPageChange={page => setSkip(page)}
              onPageSizeChange={size => setPageSize(size)}
              paginationMode="server"
            />
          ) : (
            <DataGrid columns={userColumns} rows={eligibleUsers || []} />
          )}
        </Grid>
      </Grid>
      <LoadingComponent isLoading={isLoading} />
    </>
  );
};

export default ProjectCollaborators;
