import React, { useState, useEffect, useMemo } from 'react';
import Fuse from 'fuse.js';
import { useNavigate, useSearchParams } from 'react-router-dom';
import useShowError from 'modules/errors';
import { UserTypes } from 'lg-helpers/dist/constants/user/UserTypes';
import { selectAuthUser } from 'modules/auth/storeSliceAuth';
import { useSelector } from 'react-redux';
import CompaniesApi from 'services/postgres/CompaniesApi';
import Query from 'services/firebase/Query';
import Executer from 'services/firebase/Executer';
import QueryBase from 'lg-helpers/dist/firestore/query/QueryBase';
import { DataGrid } from '@mui/x-data-grid';
import { IUser } from 'lg-helpers/dist/shared/interfaces/IUser';
import { generateProfilesColumns } from 'sharedComponents/generateProfilesColumns';
import { Grid } from '@mui/material';
import ProjectsTableToolbar from 'sharedComponents/ProjectsTableToolbar';
import { componentAllowedPerms } from 'services/permissions';
import { IProject } from 'services/api/autogenerated-code';

const executer = new Executer();
const query = new Query();
const companiesApi = new CompaniesApi();

interface IPageParams {
  [k: string]: string;
}

// This needs replacing after we have moved scales and regions into postgres
interface IAnyPlaceHolder {
  [k: string]: any;
}

export default componentAllowedPerms(['projects:read'], () => <p>Access refused</p>)(
  function ProjectsTable() {
    const navigate = useNavigate();
    const [searchParams] = useSearchParams({});
    const pageParams: IPageParams = Object.fromEntries(searchParams.entries());

    const authUser = useSelector(selectAuthUser);
    const showError = useShowError();
    const [projects, setProjects] = useState<IProject[]>([]);
    const [filteredProjects, setFilteredProjects] = useState<IProject[]>([]);
    const [searchfilter, setSearchfilter] = useState(pageParams?.search);
    const [archiveFilter, setArchiveFilter] = useState(false);
    const [regions, setRegions] = useState<IAnyPlaceHolder[]>([]);
    const [scales, setScales] = useState<IAnyPlaceHolder[]>([]);
    const [selectedRegion, setSelectedRegion] = useState<IAnyPlaceHolder>({});
    const [selectedScales, setSelectedScales] = useState<IAnyPlaceHolder[]>([]);
    const columns = useMemo(() => generateProfilesColumns(), []);

    const userTypeGenerator = [...Object.values(UserTypes.generator)].some(
      type => authUser.actingAsType === type
    );

    const userTypeTransporter = [...Object.values(UserTypes.transporter)].some(
      type => authUser.actingAsType === type
    );

    const userTypeScale = [...Object.values(UserTypes.scale)].some(
      type => authUser.actingAsType === type
    );

    const canFilterRegion = [...Object.values(UserTypes.admin)].some(
      type => authUser.actingAsType === type
    );

    const canFilterScale = [...Object.values(UserTypes.admin), UserTypes.scale.admin].some(
      type => authUser.actingAsType === type
    );

    useEffect(() => {
      if (!authUser) return;

      const getProjects = async (user: IUser) => {
        try {
          let projectsQuery;
          if (user.type === UserTypes.admin.customer) {
            projectsQuery = query.projects.getProjectsActiveForCustomerAdmin(authUser.id);
          } else if (userTypeGenerator) {
            projectsQuery = query.projects.getProjectsActiveForGeneratorCompany(
              authUser.companies[0].id
            );
          } else if (userTypeTransporter) {
            projectsQuery = query.projects.getProjectsActiveForTransporterCompany(
              authUser.companies[0].id
            );
          } else if (userTypeScale) {
            projectsQuery = query.projects.getProjectsActiveForScaleCompanyAndRegion(
              authUser.companies[0].id,
              authUser.regionId
            );
          } else if (user.actingAsType === UserTypes.admin.super) {
            projectsQuery = query.base.getAll(QueryBase.PROJECTS_COLLECTION());
          } else {
            projectsQuery = query.projects.getProjectsActiveForCompany(authUser.companies[0].id);
          }

          const projectsResults = await executer.getMultipleDocuments(projectsQuery);
          setProjects(projectsResults as IProject[]);
        } catch (error) {
          console.error('Error', error);
          showError({
            title: 'Error while fetching the projects',
            duration: 10000,
          });
        }
      };
      getProjects(authUser);

      // eslint-disable-next-line
    }, [authUser]);

    useEffect(() => {
      let filteringProjects = projects;

      filteringProjects = filteringProjects.filter(
        (proj: IProject) =>
          (archiveFilter && proj.isArchived) || (!archiveFilter && !proj.isArchived)
      );

      if (selectedRegion && selectedRegion.id) {
        filteringProjects = filteringProjects.filter(
          (proj: IProject) => proj.regionId === selectedRegion.id
        );
      }

      if (selectedScales.length) {
        const selectedScalesIds = selectedScales.map((scale: IAnyPlaceHolder) => scale.id);
        filteringProjects = filteringProjects.filter((proj: IProject) =>
          (proj?.scalesIds || []).some((id: string) => selectedScalesIds.includes(id))
        );
      }

      if (!searchfilter) {
        setFilteredProjects(filteringProjects);
        return;
      }

      const options = {
        // includeScore: true,
        threshold: 0.6,
        keys: ['name', 'profileNr'],
      };

      const fuse = new Fuse(filteringProjects, options);
      const fuseResults = fuse.search(searchfilter).map(result => result.item);
      setFilteredProjects(fuseResults);
    }, [projects, searchfilter, archiveFilter, selectedRegion, selectedScales]);

    useEffect(() => {
      if (!canFilterRegion) return;
      const getCompaniesRegions = async () => {
        try {
          const companiesRegionsResult = await companiesApi.getCompaniesRegions();
          setRegions([...new Map(companiesRegionsResult.map(v => [v.id, v])).values()]);
        } catch (error) {
          console.error('Error', error);
          showError({
            title: 'Error while fetching the companies',
            duration: 10000,
          });
        }
      };
      getCompaniesRegions();
    }, [showError, canFilterRegion]);

    useEffect(() => {
      if (!canFilterScale) return;
      if (!Object.keys(selectedRegion).length && !(userTypeScale && authUser.regionId)) {
        if (selectedScales.length || scales.length) {
          setSelectedScales([]);
          setScales([]);
        }
        return;
      }

      const getScales = async (regionId: string) => {
        try {
          const scalesResults = await executer.getMultipleDocuments(
            query.manifests.getScalesInRegion(regionId)
          );
          setScales(scalesResults);
        } catch (error) {
          console.error('Error', error);
          showError({
            title: 'Error while fetching the scales in region',
            duration: 10000,
          });
        }
      };
      getScales(selectedRegion?.id || authUser.regionId);

      // eslint-disable-next-line
    }, [selectedRegion, selectedScales, showError]);

    return (
      <Grid container columnSpacing={2} marginBottom={8}>
        <Grid item xs={12}>
          <ProjectsTableToolbar
            searchFilter={searchfilter}
            regions={regions}
            selectedRegion={selectedRegion}
            setSelectedRegion={setSelectedRegion}
            scales={scales}
            selectedScales={selectedScales}
            setSelectedScales={setSelectedScales}
            archiveFilter={archiveFilter}
            setArchiveFilter={setArchiveFilter}
            onSearchFilterChange={(e: React.FormEvent<HTMLFormElement>) => {
              setSearchfilter(e?.currentTarget?.value);
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <DataGrid
            columns={columns}
            rows={filteredProjects || []}
            getRowId={row => row.id}
            disableSelectionOnClick
            disableColumnMenu
            initialState={{
              sorting: {
                sortModel: [{ field: 'profileNr', sort: 'asc' }],
              },
            }}
            autoHeight
            onRowClick={(project, event) => {
              if ((event.target as Element).tagName === 'BUTTON') return;
              navigate(`/projects/${project.id}`);
            }}
            components={{
              ColumnResizeIcon: () => <div></div>,
            }}
          />
        </Grid>
      </Grid>
    );
  }
);
