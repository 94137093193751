import { ConnectedCompanyType } from 'services/api/autogenerated-code';

export type CompanyType =
  | 'scale'
  | 'transporter'
  | 'contractor'
  | 'generator'
  | 'broker'
  | 'truckingBroker'
  | 'truckingContractor'
  | 'truckingSourceSupplier'
  | 'viewer';

export const MapTruckingCompanyToCompanyType = (company: ConnectedCompanyType): CompanyType => {
  switch (company) {
    case ConnectedCompanyType.Transporter:
      return 'transporter';
    case ConnectedCompanyType.Contractor:
      return 'contractor';
    case ConnectedCompanyType.TruckingContractor:
      return 'truckingContractor';
    case ConnectedCompanyType.SourceSupplier:
      return 'scale';
    case ConnectedCompanyType.TruckingSourceSupplier:
      return 'truckingSourceSupplier';
    case ConnectedCompanyType.Broker:
      return 'broker';
    case ConnectedCompanyType.TruckingBroker:
      return 'truckingBroker';
    case ConnectedCompanyType.Generator:
      return 'generator';
  }
};
