import { createSlice } from '@reduxjs/toolkit';
import Fuse from 'fuse.js';

export const storeSlice = createSlice({
  name: 'scale',
  initialState: {
    scale: null,
    scaleForEdit: null,
    selectedManifest: null,
    incomingLoads: [],
    selectedIncomingLoadsIds: [],
    searchIncomingFilter: '',
  },
  reducers: {
    setScale: (state, action) => {
      state.scale = action.payload;
    },
    setScaleForEdit: (state, action) => {
      state.scaleForEdit = action.payload;
    },
    setSelectedManifest: (state, action) => {
      state.selectedManifest = action.payload;
    },
    updateLaneForEdit: (state, action) => {
      state.scaleForEdit.lanes = state.scaleForEdit.lanes.map(lane => {
        if (lane.id !== action.payload.id) {
          return lane;
        }
        return action.payload;
      });
    },
    setIncomingLoads: (state, action) => {
      state.incomingLoads = action.payload;
    },
    setSelectedIncomingLoadsIds: (state, action) => {
      state.selectedIncomingLoadsIds = action.payload;
    },
    setSearchIncomingFilter: (state, action) => {
      state.searchIncomingFilter = action.payload;
    },
  },
});

export const {
  setScale,
  setScaleForEdit,
  setSelectedManifest,
  updateLaneForEdit,
  setIncomingLoads,
  setSelectedIncomingLoadsIds,
  setSearchIncomingFilter,
} = storeSlice.actions;

// The function below is called a thunk and allows us to perform async logic. It
// can be dispatched like a regular action: `dispatch(incrementAsync(10))`. This
// will call the thunk with the `dispatch` function as the first argument. Async
// code can then be executed and other actions can be dispatched
// eslint-disable-next-line
export const actionAsync = amount => dispatch => {
  setTimeout(() => {}, 1000);
};

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state) => state.counter.value)`
export const selectIncomingLoads = state => {
  const options = {
    includeScore: true,
    threshold: 0.5,
    keys: ['profile', 'manifest', 'truck'],
  };
  let fuseResults = state.scales.incomingLoads;
  const { searchIncomingFilter } = state.scales;
  if (searchIncomingFilter) {
    const fuse = new Fuse(state.scales.incomingLoads, options);
    fuseResults = fuse.search(searchIncomingFilter);
    fuseResults = fuseResults.map(result => result.item);
  }
  return fuseResults;
};

export const selectSelectedIncomingLoadsIds = state => state.scales.selectedIncomingLoadsIds;
export const selectSearchIncomingFilter = state => state.scales.searchIncomingFilter;
export const selectScale = state => state.scales.scale;
export const selectScaleForEdit = state => state.scales.scaleForEdit;
export const selectSelectedManifest = state => state.scales.selectedManifest;

export default storeSlice.reducer;
