// FIXME: rewrite this component with correct types
import React, { useState, useEffect } from 'react';
import moment, { Moment } from 'moment';
import 'moment-timezone';
import { Box, CircularProgress, Grid, Typography } from '@mui/material';

import ManifestDisplay from './ManifestDisplay';
import {
  selectCachedTemplates,
  addCachedTemplate,
  selectCachedBase64ImagesByUrl,
  addCachedBase64ImagesByUrl,
} from 'redux/storeSliceManifest';
import { useDispatch, useSelector } from 'react-redux';
import { getBase64FromUrl } from 'services/logic/base64';
import sizeOf from 'image-size';
import { useParams } from 'react-router';
import useShowError from 'modules/errors';
import Query from 'services/firebase/Query';
import Executer from 'services/firebase/Executer';
import useGetReceiptTemplateWithFallback from 'services/hooks/useGetReceiptTemplateWithFallback';
import { Manifest } from 'lg-helpers/dist/shared/types/manifests';
import useGetContractorCompanyByProjectId from 'services/hooks/useGetContractorCompanyByProjectId';
import QueryBase from 'lg-helpers/dist/firestore/query/QueryBase';

const executer = new Executer();
const query = new Query();

const dateFormat = 'MM/DD/YYYY hh:mm:ss';

const generateSignedAt = (signedAt: Moment, timeZone: string) => {
  const momentDate = moment.tz(signedAt.toDate(), timeZone || 'EST');
  return `${momentDate.format(dateFormat)} ${momentDate.zoneAbbr()}`;
};

const ManifestOnlyView = ({
  manifestPropId,
  onReady,
}: {
  manifestPropId?: string;
  onReady?: () => any;
}) => {
  let { manifestId } = useParams();

  if (manifestPropId !== undefined) {
    manifestId = manifestPropId;
  }

  const [isLoadingManifest, setIsLoadingData] = useState(false);
  const showError = useShowError();
  const [manifestTemplateHeavy, setManifestTemplateHeavy] = useState(null);
  const [manifest, setManifest] = useState<{ [key: string]: any } | null>(null);
  const [project, setProject] = useState<any>();
  const [projectLoading, setProjectLoading] = useState(true);
  const { company: contractorCompany, isLoading: isLoadingContractor } =
    useGetContractorCompanyByProjectId(manifest?.project?.id);
  const { template: receiptTemplate } = useGetReceiptTemplateWithFallback(manifest as Manifest);
  const [manifestHeavy, setManifestHeavy] = useState<{
    [key: string]: any;
  } | null>(null);
  const cachedTemplates = useSelector(selectCachedTemplates);
  const cachedBase64ImagesByUrl = useSelector(selectCachedBase64ImagesByUrl);
  const dispatch = useDispatch();

  const loadingData = isLoadingManifest || isLoadingContractor || projectLoading;

  useEffect(() => {
    if (manifestTemplateHeavy && manifestHeavy && onReady) {
      onReady();
    }

    const body = document.querySelector('body')!;
    body.style.backgroundColor = 'transparent';
  }, [manifestTemplateHeavy, manifestHeavy, onReady]);

  useEffect(() => {
    if (!manifestId) {
      return;
    }
    setIsLoadingData(true);

    return executer.watchSingleDocument(
      query.base.getById(QueryBase.MANIFESTS_COLLECTION(), manifestId),
      manif => {
        setManifest(manif);
        if (!manif) {
          showError({ title: "This manifest doesn't exist", duration: 10000 });
        }
        setIsLoadingData(false);
      },
      error => {
        console.error('Error getting manifest:', manifestId, error);
        showError({
          title: 'Error while fetching the manifest',
          duration: 10000,
        });
        setIsLoadingData(false);
      }
    );
  }, [manifestId, showError]);

  useEffect(() => {
    if (!manifest || !manifest.template || !manifest.project || !manifest.project.id) {
      return;
    }

    const { url } = manifest.template;
    let hashKeyTemplate = '';
    if (url) {
      hashKeyTemplate = `${manifest.project.id}${url}`;
    }

    const getManifestTemplateHeavy = async () => {
      try {
        const { url: templateUrl } = manifest.template;
        if (!templateUrl) {
          setIsLoadingData(false);
          setManifestTemplateHeavy(manifest.template);
          return;
        }
        setIsLoadingData(true);
        const base64 = await getBase64FromUrl(templateUrl);
        // eslint-disable-next-line no-undef
        const img = Buffer.from(base64.split(';base64,').pop(), 'base64');
        const dimensions = sizeOf(img);

        const manifestTemplateDecorated = {
          ...manifest.template,
          base64,
          dimensions,
        };

        setManifestTemplateHeavy(manifestTemplateDecorated);

        if (hashKeyTemplate) {
          dispatch(
            addCachedTemplate({
              key: hashKeyTemplate,
              template: {
                ...manifestTemplateDecorated,
              },
            })
          );
        }

        setIsLoadingData(false);
      } catch (err) {
        setIsLoadingData(false);
        showError({ title: 'Error downloading the manifest template' });
        console.error(err);
      }
    };

    const getSignature = async (signatureUrl: string) => {
      if (cachedBase64ImagesByUrl[signatureUrl]) {
        return cachedBase64ImagesByUrl[signatureUrl];
      } else {
        const image = await getBase64FromUrl(signatureUrl);
        dispatch(
          addCachedBase64ImagesByUrl({
            url: signatureUrl,
            base64: image,
          })
        );

        return image;
      }
    };

    const extractSignatureData = async (key: string) => {
      const signatureUrl = 'url' in manifest[key] ? await getSignature(manifest[key].url) : null;
      const signedAt = manifest?.[key]?.signedAt
        ? generateSignedAt(manifest[key].signedAt, manifest.project.timeZone || 'EST')
        : null;

      return {
        ...manifest[key],
        signatureUrl,
        signedAt,
      };
    };

    const getManifestHeavy = async () => {
      setManifestHeavy({
        ...manifest,
        signatureDriver: await extractSignatureData('signatureDriver'),
        signatureGenerator: await extractSignatureData('signatureGenerator'),
        signatureScale: await extractSignatureData('signatureScale'),
      });
    };

    if (hashKeyTemplate && cachedTemplates[hashKeyTemplate]) {
      setManifestTemplateHeavy(cachedTemplates[hashKeyTemplate]);
    } else {
      getManifestTemplateHeavy();
    }

    getManifestHeavy();
    // eslint-disable-next-line
  }, [manifest, cachedBase64ImagesByUrl, cachedTemplates, dispatch]);

  useEffect(() => {
    if (!manifest || !manifest.project || !manifest.project.id) {
      return;
    }

    const getProject = async () => {
      setProjectLoading(true);
      try {
        const proj = await executer.getSingleDocument(
          query.base.getById(QueryBase.PROJECTS_COLLECTION(), manifest.project.id)
        );
        setProject(proj);
      } catch (err) {
        console.error('Failed to load project for manifest', manifest.id, manifest.project.id);
        showError({
          title: 'Failed to load project information',
        });
      } finally {
        setProjectLoading(false);
      }
    };

    getProject();
    // eslint-disable-next-line
  }, [manifest?.project?.id]);

  return (
    <div>
      <Box display="flex" justifyContent="center" alignItems="center">
        {manifestTemplateHeavy && manifestHeavy ? (
          <ManifestDisplay
            manifest={manifestHeavy}
            templateHeavy={manifestTemplateHeavy}
            isPreviewTemplate={false}
          />
        ) : (
          <CircularProgress color="inherit" />
        )}
      </Box>
      <Box>
        <Grid item xs={12}>
          {(manifestHeavy || {})?.receipt && receiptTemplate ? (
            <Box display="flex" justifyContent="center" alignItems="center">
              {manifestHeavy && !loadingData ? (
                <ManifestDisplay
                  manifest={{
                    ...manifestHeavy,
                    ...manifestHeavy.receipt,
                    project,
                    signatureDriver: manifestHeavy.signatureDriver,
                    contractorCompany,
                  }}
                  templateHeavy={receiptTemplate}
                  isPreviewTemplate={false}
                />
              ) : (
                <CircularProgress color="inherit" />
              )}
            </Box>
          ) : receiptTemplate ? (
            <Typography> No receipt </Typography>
          ) : null}
        </Grid>
      </Box>
    </div>
  );
};

export default ManifestOnlyView;
