import React from 'react';
import './ButtonToggle.scss';
import { Button, Badge, Box } from '@mui/material';

type ButtonToggleProps = {
  badgeCount?: number;
  firstVal: string;
  secondVal: string;
  toggleIndex: 0 | 1;
  setToggleIndex: (toggle: 0 | 1) => void;
  disabled?: boolean;
};

const ButtonToggle = (props: ButtonToggleProps) => {
  const { badgeCount, firstVal, secondVal, toggleIndex, setToggleIndex, disabled } = props;

  return (
    <Box
      display="flex"
      className="button-toggle-container"
      sx={{
        backgroundColor: '#E8E8E8',
        borderRadius: 60,
        padding: '5px 12px',
        display: 'inline-block',
      }}
    >
      <Button
        value={firstVal}
        variant="contained"
        size="small"
        color="primary"
        onClick={() => setToggleIndex(0)}
        className={!toggleIndex ? 'button-toggle-active' : ''}
        disabled={disabled || false}
        sx={{
          borderRadius: '60px',
          mr: 1,
        }}
      >
        {firstVal}
      </Button>
      <Button
        value={secondVal}
        size="small"
        variant="contained"
        color="secondary"
        className={toggleIndex ? 'button-toggle-active' : ''}
        onClick={() => setToggleIndex(1)}
        disabled={disabled || false}
        sx={{
          borderRadius: '60px',
        }}
      >
        {secondVal}
      </Button>
      {!!badgeCount && (
        <Badge
          badgeContent={badgeCount}
          overlap="circular"
          color="error"
          sx={{
            transform: 'translate(0, -15px)',
          }}
        />
      )}
    </Box>
  );
};

export default ButtonToggle;
