import React, { useState, useContext } from 'react';
import { thunkDispatch } from 'store/store';
import { Grid, DialogContent, Typography, Box } from '@mui/material';
import ModalHeader from 'sharedComponents/ModalHeader';
import DialogActionContainer from 'sharedComponents/DialogActionContainer';
import { unitsOfMeasure } from 'lg-helpers/dist/constants/global-constants';
import { cloneDeep } from 'lodash';
import { upsertTruckType, deleteTruckType } from '../../redux/actions';
import { ModalContext } from 'sharedComponents/ModalContext';
import { FormTextField } from 'sharedComponents/FormTextField';
import { extractFieldsByTuple } from 'modules/civilAndTrucking/trucking/utils';
import { UnitTruckCapacity } from 'lg-helpers/dist/shared/types/global-types';
import { IHelpers } from 'lg-helpers/dist/trucking/interfaces/IHelpers';
import { ITruckingTruckType } from 'lg-helpers/dist/trucking/interfaces/ITruckingTruckType';
import { KTruckingTruckType } from 'lg-helpers/dist/trucking/interfaces/KTruckingTruckType';

type TruckTypeFormProps = {
  editObj?: ITruckingTruckType;
};

export const TruckTypeForm = ({ editObj }: TruckTypeFormProps) => {
  const [truckType, setTruckType] = useState(
    editObj
      ? extractFieldsByTuple<ITruckingTruckType>(editObj, KTruckingTruckType)
      : ({} as ITruckingTruckType)
  );
  const [helpers, setHelpers] = useState<IHelpers>({});
  const { handleModal } = useContext(ModalContext);

  const handleDeleteTruckType = () => {
    if (editObj?.idTruckingTruckType) {
      thunkDispatch(deleteTruckType(editObj.idTruckingTruckType));
      handleModal();
    }
  };

  const handleCapacityChange =
    (unitType: UnitTruckCapacity) => (e: React.ChangeEvent<HTMLInputElement>) => {
      const updatedCapacities = cloneDeep(truckType?.capacitiesTruckingTruckType) || [];
      const capIndex = updatedCapacities?.findIndex(cap => cap.unitType === unitType);
      if (capIndex > -1) {
        if (e.target.value === '') {
          updatedCapacities.splice(capIndex, 1);
        } else {
          updatedCapacities[capIndex].maxVolume = parseInt(e.target.value);
        }
      } else {
        updatedCapacities.push({
          unitType: unitType,
          maxVolume: parseInt(e.target.value),
        });
      }

      setTruckType({
        ...truckType,
        capacitiesTruckingTruckType: updatedCapacities,
      });
    };

  const onSubmit = async () => {
    if (!truckType.nameTruckingTruckType) {
      setHelpers({ nameTruckingTruckType: 'Name is required!' });
    } else {
      thunkDispatch(upsertTruckType(truckType));
      handleModal();
    }
  };

  return (
    <Box sx={{ p: '0px 16px' }}>
      <ModalHeader title={`${editObj ? 'Edit' : 'Add'} Truck Type`} />
      <DialogContent>
        <FormTextField
          placeholder="Flatbed M5, Semi, etc"
          required
          name="nameTruckingTruckType"
          label="Truck Type"
          helperField={helpers.nameTruckingTruckType}
          value={truckType?.nameTruckingTruckType}
          onChange={e =>
            setTruckType({
              ...truckType,
              nameTruckingTruckType: e.target.value,
            })
          }
        />
        <Typography variant="h6">Capacities</Typography>
        <Grid container columnSpacing={2}>
          {unitsOfMeasure.map(unit => (
            <Grid item sm={3} xs={6} key={unit}>
              <FormTextField
                name={unit}
                label={unit}
                value={
                  truckType?.capacitiesTruckingTruckType?.find(cap => cap.unitType === unit)
                    ?.maxVolume || ''
                }
                onChange={handleCapacityChange(unit)}
              />
            </Grid>
          ))}
        </Grid>
        <DialogActionContainer
          saveHandler={onSubmit}
          deleteHandler={handleDeleteTruckType}
          editing={!!editObj}
        />
      </DialogContent>
    </Box>
  );
};
