import React, { useEffect, useState, useContext } from 'react';
import {
  Grid,
  FormControl,
  FormControlLabel,
  TextField,
  InputLabel,
  Switch,
  Typography,
  Button,
  CircularProgress,
  SvgIcon,
  Tooltip,
  Box,
} from '@mui/material';

import { Autocomplete, AutocompleteRenderInputParams } from '@mui/material';
import { RootState, thunkDispatch } from 'store/store';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import { useFormik } from 'formik';

import { addProject, setActiveProject, updateLocation } from '../redux/actions';

import FormButtons from './FormButtons';
import { projectSchema } from '../schemas';
import { IGeocoderFeature } from 'services/mapbox/geocoding';
import timezoneOptions from 'services/constants/momentJsTimeZones';
import moment from 'moment';
import ProjectTaskList from 'modules/civilAndTrucking/civil/ProjectTask/ProjectTasksDataGrid';
import InputAddressAutocomplete from 'sharedComponents/InputAddressAutocomplete';
import {
  convertTimeToLocalDateTime,
  parseTimeString,
} from 'modules/civilAndTrucking/civil/utils/time';
import { componentHasAccess, useHasAccess } from 'services/permissions';
import { useSelector } from 'react-redux';
import { selectAuthUser } from 'modules/auth/storeSliceAuth';
import { INotification } from 'modules/civilAndTrucking/shared/NotificationSnackbar/INotification';
import { pushNotification } from 'modules/civilAndTrucking/shared/NotificationSnackbar/redux/actions';
import CivilGeocoding, { Coordinates } from 'sharedComponents/InputCivilGeocodingAddress';
import { LocationSearching } from '@mui/icons-material';
import { EditIcon } from 'modules/civilAndTrucking/shared/CustomIcons';
import { ModalContext } from 'sharedComponents/ModalContext';
import AddressNameForm from '../AddLocations/AddressNameForm';
import { getProjectTaskGrid, IJoinProjectTask } from '../../ProjectTask/redux/actions';
import LoadingComponent from 'sharedComponents/LoadingComponent';
import { IProject } from 'lg-helpers/dist/shared/interfaces/IProject';
import TOverwrite from 'lg-helpers/dist/shared/types/TOverwrite';

const html5DateFormat = 'yyyy-MM-DD';

export type TProjectForm = TOverwrite<
  IProject,
  {
    startTime: string;
    endTime: string;
    startDate: string;
    endDate: string;
  }
>;

export const ProjectInfoForm: React.FC = () => {
  const navigate = useNavigate();
  const { handleModal } = useContext(ModalContext);
  const params = useParams();
  const auth = useHasAccess(['projects:create', 'projects:update']);
  const location = useLocation();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [showMap, setShowMap] = useState(false);
  const [reload, setReload] = useState<string>('');
  const addressInputRef = React.useRef<HTMLDivElement>(null);
  const [anchorEl, setAnchorEl] = useState<Element>();
  const [locationId, setLocationId] = useState<number>();
  const [geoPoint, setGeoPoint] = useState<Coordinates>();
  const { innerWidth } = window;

  useEffect(() => {
    setAnchorEl(addressInputRef?.current!);
  }, [addressInputRef]);

  const authUser = useSelector(selectAuthUser);

  const activeProject = useSelector((state: RootState) => state.civil.projects.activeProject);

  const projectTaskState: IJoinProjectTask[] = useSelector(
    (state: RootState) => state.civil.projectTask.data
  );

  const locationState = location.state ? (location.state as IProject) : undefined;

  const initialValues: TProjectForm = {
    name: '',
    address: '',
    active: true,
    companyId: authUser.companiesIds[0],
    id: undefined,
    geo: undefined,
    projectNumber: '',
    isArchived: false,
    timeZone: '' || Intl.DateTimeFormat().resolvedOptions().timeZone,
    ...locationState,
    startTime: locationState?.startTime
      ? parseTimeString(locationState?.startTime, locationState.timeZone!)
      : '07:00',
    endTime: locationState?.endTime
      ? parseTimeString(locationState.endTime, locationState.timeZone!)
      : '16:00',
    startDate: locationState?.startDate
      ? moment(new Date(locationState.startDate)).format(html5DateFormat)
      : moment(new Date()).format(html5DateFormat),
    endDate: locationState?.endDate
      ? moment(new Date(locationState.endDate)).format(html5DateFormat)
      : moment(new Date()).format(html5DateFormat),
    projectContacts: {
      generatorUserIds: [],
      contractorUserIds: [],
      transporterUserIds: [],
    },
  };

  console.log(initialValues.startDate);

  const handleSubmit = async (values: TProjectForm) => {
    try {
      setIsLoading(true);
      const newValues = {
        ...values,
        startTime: convertTimeToLocalDateTime(values.startTime, values.timeZone || ''),
        endTime: convertTimeToLocalDateTime(values.endTime, values.timeZone || ''),
        startDate: new Date(values.startDate + ' 12:00:00 PM'),
        endDate: new Date(values.endDate + ' 12:00:00 PM'),
      };

      if (newValues.endTime.getTime() <= newValues.startTime.getTime()) {
        const errorNotification: INotification = {
          status: 'warning',
          message: 'Daily End time must be after the start time.',
        };
        thunkDispatch(pushNotification(errorNotification));
        setIsLoading(false);
        return;
      }

      if (newValues.endDate.getTime() <= newValues.startDate.getTime()) {
        const errorNotification: INotification = {
          status: 'warning',
          message: 'Project End date must be after the start date.',
        };
        thunkDispatch(pushNotification(errorNotification));
        setIsLoading(false);
        return;
      }
      delete newValues.addressName;
      const result = await thunkDispatch(addProject(newValues));
      setIsLoading(false);
      if (result.meta.requestStatus === 'fulfilled') {
        const payload = result.payload as IProject;
        if (!newValues.id && payload?.id) {
          // if new project - update the url
          navigate(`/civil/projects/${payload?.id}`, {
            state: { ...newValues, ...payload },
          });
        } else {
          // if not a new project - update locationId and geopoint for center of map
          const coords = payload.geo;
          if (coords && payload.locationId) {
            setGeoPoint({ lon: coords[0], lat: coords[1] });
            setLocationId(payload.locationId);
          }
        }
      }
    } catch {
      console.error('Error saving project');
    } finally {
      setIsLoading(false);
    }
  };

  const formik = useFormik({
    validationSchema: projectSchema,
    initialValues,
    onSubmit: values => handleSubmit(values),
    validateOnChange: false,
    validateOnBlur: true,
    enableReinitialize: true,
  });

  // prevent having to add formik to the dependencies of the useEffect
  const { setValues, values, resetForm } = formik;

  // reset form if user uses quick create 'New Project' while already on project info screen
  useEffect(() => {
    if (location.pathname === '/civil/projects/add') {
      resetForm();
      setLocationId(undefined);
    }
  }, [location, resetForm]);

  useEffect(() => {
    if (!params.projectId) {
      return;
    }

    const getProjectData = async () => {
      setIsLoading(true);
      const result = await thunkDispatch(setActiveProject(Number(params.projectId)));
      await thunkDispatch(getProjectTaskGrid(Number(params.projectId)));
      setIsLoading(false);
      if (result.meta.requestStatus === 'fulfilled') {
        const payload = result.payload as IProject;
        if (payload) {
          const { startTime, endTime, startDate, endDate, geo, ...otherPayloadValues } = payload;
          setLocationId(otherPayloadValues.locationId);
          if (geo && geo[0] && geo[1]) {
            setGeoPoint({ lon: geo[0], lat: geo[1] });
          }
          setValues({
            ...values,
            ...otherPayloadValues,
            startTime: parseTimeString(startTime!, payload.timeZone!),
            endTime: parseTimeString(endTime!, payload.timeZone!),
            startDate: moment(new Date(startDate!)).format(html5DateFormat),
            endDate: moment(new Date(endDate!)).format(html5DateFormat),
          });
        }
      }
    };

    getProjectData();
    // eslint-disable-next-line
  }, [params.projectId]);

  const handleAddressSelected = async (geocoderValue: IGeocoderFeature) => {
    if (values.address !== geocoderValue.place_name) {
      setValues({
        ...values,
        address: geocoderValue.place_name,
        geo: geocoderValue.geometry.coordinates,
        locationId: undefined,
      });
      const coords = geocoderValue.geometry.coordinates;
      setGeoPoint({ lat: coords[1], lon: coords[0] });
      setLocationId(undefined);
    }
  };

  const toggleMap = () => {
    setShowMap(!showMap);
  };

  const setTasks = () => {
    setReload(new Date().toISOString());
  };

  const handleUpdateLocation = async (geoData: Coordinates) => {
    if (!params.projectId || !locationId) {
      return;
    }
    const data = await thunkDispatch(
      updateLocation({
        projectId: Number(params.projectId),
        locationId,
        geo: [geoData.lon, geoData.lat],
        sender: 'primary',
        name: activeProject?.addressName,
      })
    );
    if (data.meta.requestStatus === 'fulfilled') {
      setGeoPoint(geoData);
    }
  };

  const toggleNameForm = () => {
    handleModal(<AddressNameForm selected={activeProject as IProject} />);
  };

  return (
    <>
      <form onSubmit={formik.handleSubmit}>
        <Typography variant="h5" color="neutral">
          PROJECT INFO
        </Typography>
        <Grid
          container
          spacing={5}
          marginTop="1rem"
          maxWidth={1300}
          minWidth={innerWidth * 0.9}
          width="100%"
        >
          <Grid item xs={12} md={4} justifyContent={{ xs: 'center', md: 'space-between' }}>
            <FormControl fullWidth>
              <InputLabel htmlFor="name" shrink>
                Name
              </InputLabel>
              <TextField
                name="name"
                onChange={formik.handleChange}
                value={formik.values.name}
                error={!!formik.errors.name}
                helperText={formik.errors.name}
                variant="outlined"
                disabled={!auth.hasAccess}
              />
            </FormControl>
            <FormControl fullWidth>
              <InputLabel shrink htmlFor="address" sx={{ width: 'calc(133% - 24px)' }}>
                <Box display="flex" justifyContent="space-between" alignItems="center" width="100%">
                  <div>
                    <Typography>
                      Address
                      {activeProject?.addressName &&
                        locationId &&
                        ` - ${activeProject.addressName}`}
                    </Typography>
                  </div>
                  <div>
                    {geoPoint && locationId && (
                      <Tooltip title="Update coordinates">
                        <LocationSearching
                          sx={{ fontSize: 20, mx: 2, cursor: 'pointer' }}
                          onClick={toggleMap}
                        />
                      </Tooltip>
                    )}
                    {locationId && (
                      <Tooltip title="Edit site name">
                        <EditIcon
                          sx={{ fontSize: 22, marginTop: '4px', cursor: 'pointer' }}
                          onClick={toggleNameForm}
                        />
                      </Tooltip>
                    )}
                  </div>
                </Box>
              </InputLabel>

              <InputAddressAutocomplete
                key={location.pathname}
                searchRequirement={5}
                options={[]}
                className="addressInput"
                handleSelect={handleAddressSelected}
                inputValue={formik.values.address! || ''}
                renderInput={(p: AutocompleteRenderInputParams) => (
                  <TextField
                    {...p}
                    name="address"
                    error={!!formik.errors.address}
                    helperText={formik.errors.address}
                    onChange={formik.handleChange}
                    variant="outlined"
                    value={formik.values.address}
                    disabled={!auth.hasAccess}
                    ref={addressInputRef}
                  />
                )}
              />
            </FormControl>
            <FormControl fullWidth>
              <InputLabel htmlFor="startDate" shrink>
                Project Start Date
              </InputLabel>
              <TextField
                type="date"
                onChange={formik.handleChange}
                value={formik.values.startDate}
                name="startDate"
                error={!!formik.errors.startDate}
                helperText={formik.errors.startDate}
                variant="outlined"
                disabled={!auth.hasAccess}
              />
            </FormControl>
            <FormControl fullWidth>
              <InputLabel htmlFor="startTime" shrink>
                Daily Start Time
              </InputLabel>
              <TextField
                type="time"
                onChange={formik.handleChange}
                value={formik.values.startTime}
                name="startTime"
                error={!!formik.errors.startTime}
                helperText={formik.errors.startTime}
                variant="outlined"
                disabled={!auth.hasAccess}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} md={3}>
            <FormControl fullWidth>
              <InputLabel htmlFor="projectNumber" shrink>
                Project Number
              </InputLabel>
              <TextField
                onChange={formik.handleChange}
                value={formik.values.projectNumber}
                name="projectNumber"
                error={!!formik.errors.projectNumber}
                helperText={formik.errors.projectNumber}
                variant="outlined"
                disabled={!auth.hasAccess}
              />
            </FormControl>
            <FormControl fullWidth>
              <InputLabel htmlFor="timeZone" shrink>
                Time Zone
              </InputLabel>
              <Autocomplete
                options={timezoneOptions}
                value={formik.values.timeZone}
                onChange={(e, value) => formik.setFieldValue('timeZone', value as string)}
                disabled={!auth.hasAccess}
                isOptionEqualToValue={(option, value) => option === value}
                renderInput={p => (
                  <TextField
                    {...p}
                    name="timeZone"
                    variant="outlined"
                    value={formik.values.timeZone}
                    error={!!formik.errors.timeZone}
                    helperText={formik.errors.timeZone}
                    disabled={!auth.hasAccess}
                  />
                )}
              />
            </FormControl>
            <FormControl fullWidth>
              <InputLabel htmlFor="endDate" shrink>
                Project End Date
              </InputLabel>
              <TextField
                type="date"
                onChange={formik.handleChange}
                value={formik.values.endDate}
                name="endDate"
                error={!!formik.errors.endDate}
                helperText={formik.errors.endDate}
                variant="outlined"
                disabled={!auth.hasAccess}
              />
            </FormControl>

            <FormControl fullWidth>
              <InputLabel htmlFor="endTime" shrink>
                Daily End Time
              </InputLabel>
              <TextField
                type="time"
                onChange={formik.handleChange}
                value={formik.values.endTime}
                name="endTime"
                error={!!formik.errors.endTime}
                helperText={formik.errors.endTime}
                variant="outlined"
                disabled={!auth.hasAccess}
              />
            </FormControl>
          </Grid>
          {auth.hasAccess && !!formik.values.id && activeProject?.active && (
            <Grid item xs={12} md={5}>
              <FormButtons data={formik.values} setTasks={setTasks} tasks={projectTaskState} />
            </Grid>
          )}

          <Grid item>
            {auth.hasAccess && (
              <Button type="submit" variant="contained" color="primary" disabled={isLoading}>
                Save {isLoading && <CircularProgress />}
              </Button>
            )}
            <FormControlLabel
              value={formik.values.active}
              label="Active"
              name="active"
              labelPlacement="top"
              control={
                <Switch
                  checked={formik.values.active}
                  onChange={formik.handleChange}
                  disabled={!auth.hasAccess}
                  color="secondary"
                />
              }
            />
          </Grid>
          {auth.hasAccess && !!formik.values.id && (
            <Grid item xs={12} marginBottom={100}>
              <Typography variant="h5" color="neutral">
                PROJECT TASKS
              </Typography>
              <ProjectTaskList key={reload} tasks={projectTaskState} />
            </Grid>
          )}
        </Grid>
      </form>
      {anchorEl && (
        <CivilGeocoding
          setShowMap={setShowMap}
          center={geoPoint}
          anchorEl={anchorEl}
          showMap={showMap}
          onSave={handleUpdateLocation}
        />
      )}
      <LoadingComponent isLoading={isLoading} />
    </>
  );
};

export default componentHasAccess(['projects:all'], ProjectInfoForm);
