import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { DataGrid } from '@mui/x-data-grid';
import { selectCompanyMaterials } from '../../../redux/companyMaterialsStoreSlice';
import { ITruckingCompanyMaterial } from 'lg-helpers/dist/trucking/interfaces/ITruckingCompanyMaterial';
import { LoadingOverlay as Loading } from 'sharedComponents/LoadingOverlay';
import { generateCompanyMaterialsColumns } from './companyMaterialsColumns';

type CompanyMaterialsListProps = {
  showModal: (companyMaterial: ITruckingCompanyMaterial) => void;
};

export const CompanyMaterialsList = ({ showModal }: CompanyMaterialsListProps) => {
  const { companyMaterials, companyMaterialsStatus } = useSelector(selectCompanyMaterials);

  const columns = useMemo(() => generateCompanyMaterialsColumns(showModal), [showModal]);

  if (companyMaterialsStatus === 'pending') {
    return <Loading />;
  }

  return (
    <DataGrid
      columns={columns}
      rows={companyMaterials || []}
      getRowId={row => row.idTruckingCompanyMaterial}
      showColumnRightBorder={false}
      disableSelectionOnClick
      disableColumnMenu
      autoHeight
      components={{
        ColumnResizeIcon: () => <div></div>,
      }}
    />
  );
};
