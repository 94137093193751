import { useState, useEffect } from 'react';
import Query from 'services/firebase/Query';
import Executer from 'services/firebase/Executer';
import QueryBase from 'lg-helpers/dist/firestore/query/QueryBase';

const query = new Query();
const executer = new Executer();

const filterCustomInputs = (customInputs, userType, section, canEdit) => {
  return customInputs.filter(inputDefinition => {
    if (inputDefinition.isHidden) {
      return false;
    }

    const isCorrectUserType =
      (
        (canEdit ? inputDefinition.userTypesCanEdit : inputDefinition.userTypesCanOnlyRead) || []
      ).indexOf(userType) !== -1;
    const isCorrectSection =
      (
        (canEdit ? inputDefinition.sectionsCanEdit : inputDefinition.sectionsCanOnlyRead) || []
      ).indexOf(section) !== -1;

    return isCorrectUserType && isCorrectSection;
  });
};

const useGetCustomInputs = (projectId, userType, section) => {
  const [customInputsEditable, setCustomInputsEditable] = useState([]);
  const [customInputsReadOnly, setCustomInputsReadOnly] = useState([]);
  const [isloading, setIsloading] = useState(true);

  useEffect(() => {
    const retrieveCustomInputs = async () => {
      try {
        const project = await executer.getSingleDocument(
          query.base.getById(QueryBase.PROJECTS_COLLECTION(), projectId)
        );
        const { customInputsDefinitions } = project;

        setCustomInputsEditable(
          filterCustomInputs(customInputsDefinitions || [], userType, section, true)
        );
        setCustomInputsReadOnly(
          filterCustomInputs(customInputsDefinitions || [], userType, section, false)
        );
      } catch (e) {
        console.error('useGetCustomInputs() useEffect() ERROR', e);
      }
      setIsloading(false);
    };

    if (projectId) {
      setIsloading(true);
      retrieveCustomInputs();
    }
  }, [projectId, section, userType]);

  return [customInputsEditable, customInputsReadOnly, isloading];
};

export default useGetCustomInputs;
