import React, { useState } from 'react';
import { ITruckingTicketNote } from 'lg-helpers/dist/trucking/interfaces/ITruckingTicketNote';
import InputCameraImage from 'sharedComponents/InputCameraImage';
import { Box, Grid, Tooltip, IconButton, Button } from '@mui/material';
import { SaveAs, Delete } from '@mui/icons-material';
import { FormTextArea } from 'sharedComponents/FormTextArea';
import InputTruckingImage from 'sharedComponents/InputTruckingImage';
import { uploadNotesImageAndGetUrl } from 'api/brokers';
import useShowError from 'modules/errors';
import { usePermissionsContext } from 'services/permissions';

type TicketNoteAddNewProps = {
  ticketId: number;
  handleSaveTicketNote?: (note: ITruckingTicketNote) => void;
};

export const TicketNoteAddNew = ({ ticketId, handleSaveTicketNote }: TicketNoteAddNewProps) => {
  const [imageBase64, setImageBase64] = useState<string | undefined>();
  const [newImageUploaded, setNewImageUploaded] = useState<boolean>(false);
  const [isLoadingImage, setIsLoadingImage] = useState(false);
  const [noteText, setNoteText] = useState('');
  const [photoUrl, setPhotoUrl] = useState('');
  const showError = useShowError();
  const { user: authUser } = usePermissionsContext();

  const handleDeleteImage = () => {
    setNewImageUploaded(false);
    setImageBase64(undefined);
    setPhotoUrl('');
  };

  const handleSaveNote = async () => {
    try {
      let photoUrlTruckingTicketNotes = photoUrl;
      if (!noteText && !photoUrlTruckingTicketNotes)
        throw new Error('Note must contain text or image');
      if (newImageUploaded && imageBase64 && authUser) {
        photoUrlTruckingTicketNotes = (await uploadNotesImageAndGetUrl(
          String(authUser.id) as string,
          String(ticketId),
          imageBase64
        )) as string;
      }
      if (handleSaveTicketNote)
        handleSaveTicketNote({
          truckingTicketIdTruckingTicketNotes: ticketId,
          textTruckingTicketNotes: noteText,
          photoUrlTruckingTicketNotes,
          userIdTruckingTicketNotes: authUser.id || '',
          userNameTruckingTicketNotes: `${authUser.firstName} ${authUser.lastName}`,
          createdAtTruckingTicketNotes: new Date(),
        });
    } catch (err: any) {
      console.error(err);
      showError({
        title:
          (err?.message as string) ||
          `Failed to ${newImageUploaded && imageBase64 ? 'update' : 'create'} note`,
      });
    }
  };

  const handleNotesChange = (event: any) => {
    const { value } = event.target;
    setNoteText(value);
  };

  return (
    <Grid
      container
      columnSpacing={1}
      alignItems="start"
      justifyContent="space-between"
      overflow="auto"
      sx={{
        p: 2,
        '&:nth-of-type(even)': {
          backgroundColor: '#eee',
        },
      }}
    >
      <Grid item md={12} sm={12}>
        <Box>
          <Box>
            <FormTextArea
              value={noteText}
              placeholder="Add new ticket note"
              onChange={handleNotesChange}
              minRows={2}
              style={{ fontSize: '20px' }}
            />
          </Box>
        </Box>
        {imageBase64 ? (
          <Box>
            <Box sx={{ position: 'relative' }} marginBottom={1}>
              <InputCameraImage
                title="Image"
                imageBase64={imageBase64}
                isLoading={isLoadingImage}
                disabled={true}
              />
              <Tooltip title="Delete">
                <IconButton
                  onClick={handleDeleteImage}
                  sx={{
                    position: 'absolute',
                    top: '1rem',
                    right: '1rem',
                    fontSize: 18,
                  }}
                >
                  <Delete />
                </IconButton>
              </Tooltip>
            </Box>
          </Box>
        ) : (
          <InputTruckingImage
            buttonText="Upload Image"
            imageBase64={imageBase64}
            setImageBase64={setImageBase64}
            setNewImageUploaded={setNewImageUploaded}
            key={`new_note_image_${ticketId}`}
            sx={{ marginTop: 1, justifyContent: 'center' }}
          />
        )}
      </Grid>
      <Grid item md={12} sm={12}>
        <Button
          color="primary"
          variant="contained"
          fullWidth
          onClick={handleSaveNote}
          sx={{ fontSize: 18 }}
        >
          Save New Note
        </Button>
      </Grid>
    </Grid>
  );
};
