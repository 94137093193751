import React from 'react';
import { Card, CardActions, Button, Grid } from '@mui/material';
import { useHasAccess } from 'services/permissions';
import { CardProps } from '@mui/material';
import { v4 as uuidv4 } from 'uuid';

const max_width = 3000;
const max_height = 3000;

interface InputTruckingImageProps extends CardProps {
  isLoading?: boolean;
  imageBase64?: string;
  setImageBase64: Function;
  setNewImageUploaded: Function;
  buttonText: string;
  isLogo?: boolean;
}

const InputTruckingImage = (props: InputTruckingImageProps) => {
  const fileReader = new FileReader();
  const auth = useHasAccess(['trucking_tickets:create', 'trucking_tickets:update']);
  const {
    isLoading, // eslint-disable-line
    imageBase64, // eslint-disable-line
    setImageBase64, // eslint-disable-line
    setNewImageUploaded, // eslint-disable-line
    buttonText,
    isLogo,
    ...cardProps
  } = props;
  const uuid = uuidv4();
  const id = `image-upload-button_${uuid}`;

  function compressImage(img: HTMLImageElement) {
    var canvas = document.createElement('canvas');
    var width = img.width;
    var height = img.height;

    if (width > height) {
      if (width > max_width) {
        height = Math.round((height *= max_width / width));
        width = max_width;
      }
    } else {
      if (height > max_height) {
        width = Math.round((width *= max_height / height));
        height = max_height;
      }
    }

    // resize the canvas and draw the image onto it
    canvas.width = width;
    canvas.height = height;
    var ctx = canvas.getContext('2d');
    if (ctx) {
      ctx.fillStyle = '#FFF';
      ctx.fillRect(0, 0, width, height);
      ctx.drawImage(img, 0, 0, width, height);
    }

    return canvas.toDataURL('image/jpeg', 0.1);
  }

  const handleImage = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const target = event.target as HTMLInputElement;
    if (target.files) {
      const file = target.files[0];
      fileReader.readAsDataURL(file);

      fileReader.onload = e => {
        if (isLogo) {
          setImageBase64(fileReader.result);
          setNewImageUploaded(true);
          return;
        }
        const image = new Image();
        image.src = e.target?.result as string;
        image.onload = () => {
          const resizedDataURL = compressImage(image);
          setImageBase64(resizedDataURL);
          setNewImageUploaded(true);
          return;
        };
      };
    }
  };

  return (
    <>
      <Card elevation={0} {...cardProps}>
        <CardActions>
          <Grid container>
            <input
              type="file"
              hidden
              accept="image/*"
              id={id}
              className="form-control"
              onChange={event => handleImage(event)}
              disabled={!auth.hasAccess}
            />
            <label htmlFor={id}>
              <Button
                // color="primary"
                component="span"
                // variant="contained"
                sx={{ textAlign: 'center' }}
              >
                {buttonText}
              </Button>
            </label>
          </Grid>
        </CardActions>
      </Card>
    </>
  );
};

export default InputTruckingImage;
