import { object, number, boolean, string } from 'yup';
import { IProjectTask } from 'lg-helpers/dist/shared/interfaces/IProjectTask';

export const projectTaskSchema = object<IProjectTask>({
  projectId: number().required('Required'),
  activityId: number().required('Required').min(1, 'Please select a valid activity'),
  sourceSupplierId: string().required('Required'),
  materialId: number().required('Required'),
  dailyTarget: number().required('Required'),
  totalAmount: number().required('Required'),
  autoTicketNumber: boolean(),
  deleted: boolean(),
});
