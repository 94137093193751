import React from 'react';
import { createSvgIcon } from '@mui/material';

export const Refresh = createSvgIcon(
  <path
    fillRule="evenodd"
    clipRule="evenodd"
    d="M18.75 10c0 .5523.4477 1 1 1s1-.4477 1-1V2c0-.55228-.4477-1-1-1s-1 .44772-1 1v1.99902C16.9255 1.57068 14.0212 0 10.75 0 9.396 0 8.10245.26965 6.922.75913a9.97611 9.97611 0 0 0-1.72865.92568 10.04371 10.04371 0 0 0-1.51442 1.24412 10.04371 10.04371 0 0 0-1.24412 1.51442c-.30724.45895-.18425 1.08005.27468 1.38725.45894.30725 1.08005.1843 1.38726-.27465a8.04442 8.04442 0 0 1 .9964-1.2128 8.04442 8.04442 0 0 1 1.2128-.9964A7.97643 7.97643 0 0 1 7.6881 2.6066C8.62995 2.21605 9.6635 2 10.75 2c4.4183 0 8 3.5817 8 8ZM2.75 10c0-.5523-.44772-1-1-1-.55229 0-1 .4477-1 1v8c0 .5523.44772 1 1 1s1-.4477 1-1v-1.999C4.57446 18.4293 7.4788 20 10.75 20c1.354 0 2.6476-.2696 3.828-.7591a9.97462 9.97462 0 0 0 1.7287-.9257c.5452-.365 1.0524-.7821 1.5144-1.2441.462-.462.8791-.9692 1.2441-1.5144.3072-.459.1843-1.0801-.2747-1.3873-.4589-.3073-1.0801-.1843-1.3873.2746a8.0366 8.0366 0 0 1-.9963 1.2129 8.0366 8.0366 0 0 1-1.2129.9963c-.4329.2899-.8955.5385-1.3821.7402-.9418.3906-1.9754.6066-3.0619.6066-4.4183 0-8-3.5817-8-8Z"
  />,
  'Refresh'
);
