import qrcode from 'qrcode';
const generateQRcodeBase64 = async text => {
  try {
    return await qrcode.toDataURL(text, {
      errorCorrectionLevel: 'H',
      type: 'image/jpeg',
      quality: 1,
      margin: 1,
    });
  } catch (err) {
    console.error(err);
  }
};

export { generateQRcodeBase64 };
