import React, { useEffect, useState } from 'react';
import { Typography, Box, Container } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { auth } from 'services/firebase';
import { useQueryString } from 'utils';

const TokenAuth = () => {
  const queryString = useQueryString();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  useEffect(() => {
    const customTokenLogin = async () => {
      const token = queryString.get('token') || '';
      const next = queryString.get('next') || '';
      setIsLoading(true);
      try {
        await auth.signInWithCustomToken(token);
        setIsAuthenticated(true);
        if (next) {
          navigate(next);
        }
      } catch (error) {
        console.error('customTokenLogin() failed', error);
        setIsAuthenticated(false);
      }
      setIsLoading(false);
    };

    if (queryString.has('token')) {
      customTokenLogin();
    }
  }, [queryString, navigate]);

  return (
    <Box margin={1} paddingTop={8} paddingBottom={8}>
      <Container>
        <Typography variant="h3" align="center" marginBottom={'1.5rem'}>
          {isLoading
            ? 'Loading...'
            : isAuthenticated
            ? 'Login succeeded'
            : 'Oh no, something went wrong! Please try again or contact us.'}
        </Typography>

        {isLoading ? (
          <Typography align="center" marginBottom={'3rem'}>
            Loading...
          </Typography>
        ) : (
          <Typography align="center" marginBottom={'3rem'}>
            {isAuthenticated && 'You were successfully logged in, but there was not a next url'}
          </Typography>
        )}
      </Container>
    </Box>
  );
};

export default TokenAuth;
