import React from 'react';
import { createSvgIcon } from '@mui/material';

export const Camera = createSvgIcon(
  <path
    fillRule="evenodd"
    clipRule="evenodd"
    d="M7.10557.55279C7.27496.214 7.62123 0 8 0h6c.3788 0 .725.214.8944.55279L16.118 3H19.5C20.8807 3 22 4.11929 22 5.5v12c0 1.3807-1.1193 2.5-2.5 2.5h-17C1.11929 20 0 18.8807 0 17.5v-12C0 4.11929 1.11929 3 2.5 3h3.38197L7.10557.55279ZM8.11803 3H13.882l-.5-1H8.61803l-.5 1ZM2.5 5c-.27614 0-.5.22386-.5.5v12c0 .2761.22386.5.5.5h17c.2761 0 .5-.2239.5-.5v-12c0-.27614-.2239-.5-.5-.5h-17ZM6 11.5c0-2.76143 2.23857-5 5-5 2.7614 0 5 2.23857 5 5 0 2.7614-2.2386 5-5 5-2.76143 0-5-2.2386-5-5Zm5-3c-1.65687 0-3 1.34313-3 3 0 1.6569 1.34313 3 3 3 1.6569 0 3-1.3431 3-3 0-1.65687-1.3431-3-3-3Z"
  />,
  'Camera'
);
