import React from 'react';
import { GridColDef } from '@mui/x-data-grid';
import { Button } from '@mui/material';
import { Delete } from '@mui/icons-material';
import { EditIcon } from 'modules/civilAndTrucking/shared/CustomIcons/Edit';
import { CompanyConnectionWithCompany } from 'services/api/autogenerated-code';

export const RelatedCompaniesColumns = (
  showModal: (row: CompanyConnectionWithCompany) => any,
  handleDelete: (id: number) => void
) => {
  return [
    {
      field: 'connected_company.name',
      headerName: 'Company Name',
      width: 250,
      renderCell: ({ row }) => row.connected_company?.name,
    },
    {
      field: 'custom_id',
      headerName: 'Custom Id',
      width: 150,
    },
    {
      field: 'connected_company.city',
      headerName: 'City',
      width: 150,
      renderCell: ({ row }) => row.connected_company?.city || '',
    },
    {
      field: 'connected_company.state',
      headerName: 'State',
      width: 150,
      renderCell: ({ row }) => row.connected_company?.state || '',
      flex: 1,
    },
    {
      field: 'connected_company.phone',
      headerName: 'Phone',
      width: 150,
    },
    {
      field: 'connected_company.email',
      headerName: 'Email',
      width: 150,
      flex: 1,
    },
    {
      field: 'edit',
      headerName: ' ',
      flex: 0.01,
      align: 'right',
      sortable: false,
      renderCell: ({ row }) => (
        <Button color="success" onClick={() => showModal(row)}>
          <EditIcon />
        </Button>
      ),
    },
    {
      field: 'delete',
      headerName: ' ',
      flex: 0.01,
      align: 'right',
      sortable: false,
      renderCell: ({ row }) => (
        <Button sx={{ color: '#555' }} onClick={() => handleDelete(row.id)}>
          <Delete />
        </Button>
      ),
    },
  ] as GridColDef<CompanyConnectionWithCompany>[];
};
