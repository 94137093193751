import React from 'react';
import './Layout.scss';
import { Outlet } from 'react-router-dom';

const NoHeaderLayout = () => (
  <div className="Layout__wrapper--outer">
    <div className="Layout__content-container">
      <div className="Layout__content">
        <Outlet />
      </div>
    </div>
  </div>
);

export default NoHeaderLayout;
