import React from 'react';
import { Grid, Button, Typography } from '@mui/material';
import { LoginMethod } from './LoginView';

const DefaultSignInView = ({
  setPreferredLoginMethod,
}: {
  setPreferredLoginMethod: (method: LoginMethod) => any;
}) => {
  return (
    <>
      <Grid item xs={12}>
        <Typography align="center" variant="h6" gutterBottom>
          Please select your preferred login method
        </Typography>
      </Grid>
      <Grid container xs={12} spacing={2}>
        <Grid item xs={6}>
          <Button
            onClick={() => setPreferredLoginMethod('phone')}
            color="inherit"
            fullWidth
            size="large"
            type="button"
            variant="outlined"
            sx={{
              height: '100%',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
            }}
          >
            <Typography variant="body1">Phone number</Typography>
            <Typography variant="caption">(Drivers only)</Typography>
          </Button>
        </Grid>
        <Grid item xs={6}>
          <Button
            sx={{ height: '100%' }}
            // TODO: Auth0: revert this
            // onClick={() => setPreferredLoginMethod('single_email')}
            onClick={() => setPreferredLoginMethod('email')}
            color="inherit"
            fullWidth
            size="large"
            type="button"
            variant="outlined"
          >
            <Typography variant="body1">Email</Typography>
          </Button>
        </Grid>
      </Grid>
    </>
  );
};

export default DefaultSignInView;
