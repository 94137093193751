import React, { useState, useEffect, useMemo, useCallback, PropsWithChildren } from 'react';
import 'sass/globals.scss';
import {
  Grid,
  Box,
  Button,
  CircularProgress,
  Typography,
  TextField,
  FormLabel,
  RadioGroup,
  Radio,
  FormControlLabel,
  Autocomplete,
  DialogActions,
} from '@mui/material';
import InputMasker from 'sharedComponents/InputMasker';
import { hasErrorRequired, hasErrorValidEmail } from 'services/logic/formValidation';
import { generateCreateOrUpdateUserTypeOptions } from 'modules/auth/logicDependentOnUserRole';
import { UserType, UserTypes } from 'lg-helpers/dist/constants/user/UserTypes';

import useShowError from 'modules/errors';
import Query from 'services/firebase/Query';
import Executer from 'services/firebase/Executer';
import ErrorMessages from 'services/constants/errorMessages';
import { IJoinCompany } from 'lg-helpers/dist/shared/interfaces/IJoinCompany';
import { IUserCreation } from 'lg-helpers/dist/shared/interfaces/IUserCreation';
import CompaniesApi from '../services/postgres/CompaniesApi';

import { useSelector } from 'react-redux';
import { selectAuthUser } from '../modules/auth/storeSliceAuth';
import UserNotificationAdminPanel from './UserNotificationAdminPanel';
import DialogActionContainer from './DialogActions';
import { componentAllowedPerms } from 'services/permissions';
import { TUserCompany } from 'lg-helpers/dist/shared/interfaces/IUser';

const { isRequired, emailNotValid } = ErrorMessages;

const executer = new Executer();
const query = new Query();

const driverUserTypes = [
  UserTypes.transporter.driver,
  UserTypes.broker.trucking.driver,
  UserTypes.truckingBroker.truckingBrokerDriver,
];

const userDefaultValues: IUserCreation = {
  id: undefined,
  preferredLoginMethod: 'email',
  firstName: '',
  lastName: '',
  email: '',
  phone: '',
  companies: [],
  companiesIds: [],
  type: undefined,
  actingAsType: undefined,
  allowedUserTypes: [],
  generator: false,
};

interface IAddUserFormProps {
  userInfo?: IUserCreation | null;
  savePosition?: string;
  submitSuccessCallback?: (user: IUserCreation | null) => void;
  handleClose?: () => void;
}

const adminUserTypes = [UserTypes.admin.customer, UserTypes.admin.super];

const companiesApi = new CompaniesApi();

export const getCompanyLabel = (company: IJoinCompany) => {
  if (!company) {
    return '';
  }
  const typesStringified = company.types ? company?.types?.join(', ') : '';
  return `${company.name} - ${typesStringified}`;
};

const UserForm = componentAllowedPerms(['users:create', 'users:update'])(
  ({
    userInfo = userDefaultValues,
    submitSuccessCallback,
    savePosition = 'top',
    handleClose,
  }: PropsWithChildren<IAddUserFormProps>) => {
    const authUser = useSelector(selectAuthUser);

    const [isLoadingSaveDetails, setIsLoadingSaveDetails] = useState<boolean>(false);
    const [wasSubmitted, setWasSubmitted] = useState<boolean>(false);
    const [companies, setCompanies] = useState<IJoinCompany[]>([]);
    const [selectedCompany, setSelectedCompany] = useState<IJoinCompany | null>(null);
    const [isLoadingCompanies, setIsLoadingCompanies] = useState(false);
    const showError = useShowError();
    const [user, setUser] = useState<IUserCreation | null>(userInfo);
    const userCompanies = useMemo(
      () =>
        selectedCompany
          ? [selectedCompany]
          : user?.companies?.length
          ? user?.companies
          : authUser?.companies?.length
          ? authUser?.companies
          : [],
      [selectedCompany, user?.companies, authUser]
    );
    const [isLoadingScales, setIsLoadingScales] = useState<boolean>(false);
    const [scalesInRegion, setScalesInRegion] = useState<IJoinCompany[]>([]);
    const [showPhone, setShowPhone] = useState<boolean>(false);
    const [requirePhone, setRequirePhone] = useState<boolean>(true);
    const [requireCompany, setRequireCompany] = useState<boolean>(true);

    const isUserScale =
      !!user &&
      (user?.actingAsType === UserTypes.scale.attendant ||
        user?.actingAsType === UserTypes.scale.admin);
    const isAuthUserSuperAdmin = authUser && authUser.actingAsType === UserTypes.admin.super;
    const isAuthUserCustomerAdmin = authUser && authUser.actingAsType === UserTypes.admin.customer;

    const showRegionsSelector = !!selectedCompany && isUserScale;

    const filteredCompanies =
      user?.type === UserTypes.transporter.driver
        ? companies.filter(company => company.types.indexOf('transporter') !== -1)
        : companies;

    const allowedRegions = useMemo(() => {
      if (!authUser || !selectedCompany || !showRegionsSelector) return [];
      const region = (selectedCompany.regions || []).find(r => r.id === authUser?.regionId);
      return authUser.actingAsType === UserTypes.scale.admin
        ? region
          ? [region]
          : []
        : selectedCompany.regions;
      // eslint-disable-next-line
    }, [authUser, selectedCompany, showRegionsSelector]);

    const userTypeOptions = useMemo(() => {
      if (!authUser || !user?.companies || !(companies?.length > 0) || !userCompanies.length) {
        if (authUser && authUser?.type === UserTypes.admin.super) return [UserTypes.admin.super];
        return [];
      }

      const companiesToConsiderTypes = userCompanies?.filter((uCompany: TUserCompany) =>
        companies.find(company => company.id === uCompany?.id)
      );

      return generateCreateOrUpdateUserTypeOptions(authUser.actingAsType, companiesToConsiderTypes);
      // eslint-disable-next-line
    }, [authUser.actingAsType, companies, user?.companies, userCompanies, selectedCompany]);

    const handleChange = useCallback((event: any) => {
      const { name, value } = event.target;
      setUser(prev => ({
        ...prev,
        [name]: value,
      }));
      if (name === 'companies') {
        setSelectedCompany(value[0]);
      }
      setWasSubmitted(false);
      // eslint-disable-next-line
    }, []);

    // Get all companies (only if super admin??)
    useEffect(() => {
      const getCompanies = async () => {
        try {
          setIsLoadingCompanies(true);
          // TODO: hotfixed with excluded types, but needs fix/optimisation in future
          const companiesResult = await companiesApi.getCompaniesWithCustomDataProperties([
            'regions',
          ]);
          setCompanies(companiesResult);
          setIsLoadingCompanies(false);
        } catch (error) {
          console.error('Error', error);
          showError({
            title: 'Error while fetching the companies',
            duration: 10000,
          });
          setIsLoadingCompanies(false);
        }
      };
      getCompanies();
      // eslint-disable-next-line
    }, []);

    // Set to current logged in user's company, from list of all fetched companies
    useEffect(() => {
      if (user) {
        const currentCompanyId =
          user?.companiesIds && user.companiesIds.length
            ? user.companiesIds[user.companiesIds.length - 1]
            : user?.companies && user.companies.length
            ? user.companies[user.companies.length - 1].id
            : null;
        const currentCompany = currentCompanyId
          ? companies.find(c => c.id === currentCompanyId)
          : null;

        if (!currentCompany) return;

        setSelectedCompany(currentCompany);
      } else {
        if (!authUser) {
          return;
        }

        if (companies && companies.length) {
          const foundCompany =
            companies.find(company => company.id === authUser.companiesIds[0]) || null;
          setSelectedCompany(foundCompany);
        }
      }
      // eslint-disable-next-line
    }, [companies]);

    useEffect(() => {
      if (JSON.stringify(userCompanies) === JSON.stringify(user?.companies)) return;
      setUser((prev: IUserCreation | null) => ({
        ...prev,
        companies: userCompanies,
      }));
      // eslint-disable-next-line
    }, [userCompanies]);

    useEffect(() => {
      if (allowedRegions && allowedRegions.length === 1) {
        handleChange({
          target: {
            name: 'regionId',
            value: allowedRegions[0].id,
          },
        });
      }
      // eslint-disable-next-line
    }, [allowedRegions]);

    useEffect(() => {
      if (!selectedCompany || !user?.regionId || !isUserScale) {
        return;
      }

      const getScales = async (companyId: string, regionId: string) => {
        setIsLoadingScales(true);
        try {
          const scalesResults = await executer.getMultipleDocuments(
            query.manifests.getScalesForScaleCompanyInRegion(companyId, regionId)
          );
          setScalesInRegion(scalesResults as IJoinCompany[]);
        } catch (error) {
          console.error('Error', error);
          showError({
            title: 'Error while fetching the scales in region',
            duration: 10000,
          });
        }
        setIsLoadingScales(false);
      };
      setScalesInRegion([]);
      getScales(selectedCompany.id!, user.regionId);
    }, [selectedCompany, user, isUserScale, showError]);

    useEffect(() => {
      setRequirePhone(
        showPhone &&
          user?.preferredLoginMethod === 'phone' &&
          driverUserTypes.includes(user?.type as any)
      );
      // eslint-disable-next-line
    }, [user]);

    useEffect(() => {
      setUser(prev => ({
        ...prev,
        customData: showPhone
          ? {
              truckingCompany: selectedCompany?.name,
              truckingCompanyId: selectedCompany?.id,
            }
          : {},
      }));
      // eslint-disable-next-line
    }, [selectedCompany, showPhone]);

    const handleSaveDetails = async () => {
      setWasSubmitted(true);
      if (hasErrors()) {
        showError({ title: 'Validation errors' });
        return;
      }

      setIsLoadingSaveDetails(true);

      const companiesIds = user?.companies?.map(c => c.id as string);
      const userData: IUserCreation = {
        ...user,
        // eslint-disable-next-line no-unused-vars
        companies: user?.companies?.map(({ id: i, name, types }) => ({
          id: i,
          name,
          types,
        })),
        companiesIds,
        email: user?.email?.trim(),
      };

      if (submitSuccessCallback) submitSuccessCallback(userData);
      setIsLoadingSaveDetails(false);
    };

    const handleEmailBlur = () => {
      const email = user?.email ? user?.email.trim() : '';
      setUser(prev => ({
        ...prev,
        email,
      }));
    };

    const hasErrors = () => {
      let result = hasErrorRequired(user?.type) || hasErrorRequired(user?.firstName);
      if (user?.type !== UserTypes.admin.super && user?.type !== UserTypes.admin.customer) {
        result = result || hasErrorRequired(user?.companies);
      }

      if (showRegionsSelector) {
        result = result || hasErrorRequired(user?.regionId);
      }

      if (isUserScale) {
        result = result || hasErrorRequired(user?.scalesIds);
      }

      if (user?.preferredLoginMethod === 'email') {
        result =
          result || hasErrorRequired(user?.email) || hasErrorValidEmail(user?.email?.trim() || '');
      }

      if (requirePhone) {
        result = result || hasErrorRequired(user?.phone);
      }

      return result;
    };

    const getSelectedCompanyErrorText = () => {
      if (!requireCompany) return '';
      if (hasErrorRequired(user?.companies, wasSubmitted)) return isRequired;
      return '';
    };

    const getSelectedScalesErrorText = () => {
      if (!isUserScale) {
        return '';
      }
      if (hasErrorRequired(user?.scalesIds, wasSubmitted)) {
        return isRequired;
      }
      return '';
    };

    if (isLoadingCompanies) return <CircularProgress color="inherit" size={25} />;

    return (
      <>
        {savePosition === 'top' && (
          <Grid item sx={{ textAlign: 'right' }} lg={6} md={6} xs={12}>
            <Button
              size="small"
              color="primary"
              variant="contained"
              onClick={handleSaveDetails}
              disabled={isLoadingSaveDetails}
            >
              Save
              {isLoadingSaveDetails && (
                <Box marginLeft={2}>
                  <CircularProgress color="inherit" />
                </Box>
              )}
            </Button>
          </Grid>
        )}
        <Grid item lg={12} md={12} xs={12}>
          <Grid container spacing={2}>
            {user && (
              <>
                {user?.id && (
                  <Grid item md={6} xs={12}>
                    <TextField
                      disabled
                      label="User Id"
                      fullWidth
                      required
                      value={user?.id || ''}
                      variant="outlined"
                    />
                  </Grid>
                )}
                <Grid item xs={12}>
                  <Typography align="center" variant="h5">
                    You should fill at least one of the following two fields(email, phone) based on
                    preferred login method you&apos;re choosing
                  </Typography>
                </Grid>
                {isAuthUserSuperAdmin || isAuthUserCustomerAdmin ? (
                  <Grid item md={12} xs={12}>
                    <Autocomplete
                      fullWidth
                      options={filteredCompanies || []}
                      getOptionLabel={getCompanyLabel}
                      value={selectedCompany || null}
                      onChange={(event, company) => {
                        handleChange({
                          target: {
                            name: 'companies',
                            value: company
                              ? [
                                  {
                                    id: company.id,
                                    name: company.name,
                                    types: company.types,
                                    regions: company.regions,
                                  },
                                ]
                              : [],
                          },
                        });
                        // setSelectedCompany(company);
                      }}
                      renderOption={(props, option) => (
                        <li {...props} key={option.id || ''}>
                          {option.name} - {option.types.join(', ')}
                        </li>
                      )}
                      renderInput={params => (
                        <TextField
                          {...params}
                          label="Company"
                          required={requireCompany}
                          variant="outlined"
                          error={!!getSelectedCompanyErrorText()}
                          helperText={getSelectedCompanyErrorText()}
                          InputProps={{
                            ...params.InputProps,
                            endAdornment: (
                              <>
                                {isLoadingCompanies && (
                                  <CircularProgress color="inherit" size={20} />
                                )}
                                {params.InputProps.endAdornment}
                              </>
                            ),
                          }}
                        />
                      )}
                    />
                  </Grid>
                ) : (
                  <Grid item md={12} xs={12}>
                    <TextField
                      label="Company"
                      disabled
                      variant="outlined"
                      error={!!getSelectedCompanyErrorText()}
                      helperText={getSelectedCompanyErrorText()}
                      required={requireCompany}
                      fullWidth
                      value={selectedCompany ? selectedCompany.name : ''}
                    />
                  </Grid>
                )}

                <Grid item md={6} xs={12}>
                  <Autocomplete
                    fullWidth
                    disabled={!userTypeOptions?.length}
                    options={userTypeOptions || []}
                    value={user?.type || null}
                    getOptionLabel={option => option}
                    onChange={(event, _value) => {
                      const value = _value as UserType;
                      setUser((prevState: IUserCreation | null) => ({
                        ...prevState,
                        type: value as UserType,
                        actingAsType: value as UserType,
                        allowedUserTypes: (prevState?.allowedUserTypes || []).includes(value)
                          ? prevState?.allowedUserTypes
                          : ([...(prevState?.allowedUserTypes || []), value].filter(
                              e => e && e !== prevState?.type
                            ) as UserType[]),
                        preferredLoginMethod:
                          value === null || !driverUserTypes.includes(value as any)
                            ? 'email'
                            : user?.preferredLoginMethod,
                      }));

                      setShowPhone(driverUserTypes.includes(value as any));
                      setRequireCompany(!adminUserTypes.includes(value as any));
                    }}
                    renderInput={params => (
                      <TextField
                        {...params}
                        label="Type"
                        required
                        variant="outlined"
                        error={hasErrorRequired(user?.type, wasSubmitted)}
                        helperText={hasErrorRequired(user?.type, wasSubmitted) ? isRequired : ''}
                        InputProps={{
                          ...params.InputProps,
                          endAdornment: <>{params.InputProps.endAdornment}</>,
                        }}
                      />
                    )}
                  />
                </Grid>

                <Grid item md={6} xs={12}>
                  <Autocomplete
                    fullWidth
                    multiple
                    disabled={!userTypeOptions?.length}
                    options={userTypeOptions || []}
                    value={user?.allowedUserTypes || []}
                    getOptionLabel={option => option}
                    onChange={(event, value) =>
                      setUser(prev => ({
                        ...prev,
                        allowedUserTypes: value,
                      }))
                    }
                    renderInput={params => (
                      <TextField
                        {...params}
                        label="Allowed Types"
                        required
                        variant="outlined"
                        error={hasErrorRequired(user?.allowedUserTypes, wasSubmitted)}
                        helperText={
                          hasErrorRequired(user?.allowedUserTypes, wasSubmitted) ? isRequired : ''
                        }
                        InputProps={{
                          ...params.InputProps,
                          endAdornment: <>{params.InputProps.endAdornment}</>,
                        }}
                      />
                    )}
                  />
                </Grid>

                <Grid item md={6} xs={12}>
                  <TextField
                    fullWidth
                    label="First name"
                    name="firstName"
                    onChange={handleChange}
                    value={user?.firstName || ''}
                    variant="outlined"
                    required
                    error={hasErrorRequired(user?.firstName, wasSubmitted)}
                    helperText={hasErrorRequired(user?.firstName, wasSubmitted) ? isRequired : ''}
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <TextField
                    fullWidth
                    label="Last name"
                    name="lastName"
                    onChange={handleChange}
                    value={user?.lastName || ''}
                    variant="outlined"
                  />
                </Grid>

                {showPhone && (
                  <Grid item md={12} xs={12}>
                    <FormLabel component="legend">Preferred login Method</FormLabel>
                    <RadioGroup
                      row
                      aria-label="preferred login method"
                      name="preferredLoginMethod"
                      value={user?.preferredLoginMethod}
                      onChange={handleChange}
                    >
                      <FormControlLabel value="email" control={<Radio />} label="Email" />
                      <FormControlLabel value="phone" control={<Radio />} label="Phone" />
                    </RadioGroup>
                  </Grid>
                )}

                <Grid item md={6} xs={12}>
                  <TextField
                    disabled={!!user?.id}
                    label="Email"
                    name="email"
                    fullWidth
                    required={user?.preferredLoginMethod === 'email'}
                    value={user?.email || ''}
                    variant="outlined"
                    onChange={handleChange}
                    onBlur={handleEmailBlur}
                    error={
                      user?.preferredLoginMethod === 'email' &&
                      (hasErrorRequired(user?.email, wasSubmitted) ||
                        hasErrorValidEmail(user?.email || '', wasSubmitted))
                    }
                    helperText={
                      hasErrorRequired(user?.email, wasSubmitted) &&
                      user?.preferredLoginMethod === 'email'
                        ? isRequired
                        : hasErrorValidEmail(user?.email || '', wasSubmitted) &&
                          user?.preferredLoginMethod === 'email'
                        ? emailNotValid
                        : ''
                    }
                  />
                </Grid>

                <Grid item md={6} xs={12}>
                  <InputMasker
                    fullWidth
                    label="Phone Number"
                    mask="+############"
                    variant="outlined"
                    val={user?.phone || ''}
                    required={requirePhone}
                    error={requirePhone && hasErrorRequired(user?.phone, wasSubmitted)}
                    helperText={
                      hasErrorRequired(user?.phone, wasSubmitted) && requirePhone ? isRequired : ''
                    }
                    onChange={(val: any) => {
                      setUser({
                        ...user,
                        phone: val,
                      });
                    }}
                  />
                </Grid>

                {showRegionsSelector && allowedRegions && (
                  <Grid item md={6} xs={12}>
                    <Autocomplete
                      fullWidth
                      disabled={!allowedRegions || allowedRegions.length < 2}
                      options={allowedRegions || []}
                      getOptionLabel={option => option.name}
                      value={
                        (allowedRegions || []).find(region => region.id === user?.regionId) || null
                      }
                      onChange={(event, value) =>
                        handleChange({
                          target: {
                            name: 'regionId',
                            value: value && value.id,
                          },
                        })
                      }
                      renderInput={params => (
                        <TextField
                          {...params}
                          label="Region"
                          required
                          variant="outlined"
                          error={hasErrorRequired(user?.regionId, wasSubmitted)}
                          helperText={
                            hasErrorRequired(user?.regionId, wasSubmitted) ? isRequired : ''
                          }
                          InputProps={{
                            ...params.InputProps,
                            endAdornment: <>{params.InputProps.endAdornment}</>,
                          }}
                        />
                      )}
                    />
                  </Grid>
                )}

                {isUserScale && (
                  <Grid item md={6} xs={12}>
                    <Autocomplete
                      multiple
                      disabled={!scalesInRegion.length}
                      options={scalesInRegion}
                      getOptionLabel={option => option.name}
                      value={
                        scalesInRegion.filter(
                          scale => (user?.scalesIds || []).indexOf(scale.id || '') !== -1
                        ) || null
                      }
                      onChange={(event, selectedValues) =>
                        handleChange({
                          target: {
                            name: 'scalesIds',
                            value: selectedValues.map(value => value.id),
                          },
                        })
                      }
                      renderInput={params => (
                        <TextField
                          {...params}
                          variant="outlined"
                          required
                          label="Scales"
                          placeholder="Select scales"
                          error={!!getSelectedScalesErrorText()}
                          helperText={getSelectedScalesErrorText()}
                          InputProps={{
                            ...params.InputProps,
                            endAdornment: (
                              <>
                                {isLoadingScales && <CircularProgress color="inherit" size={20} />}
                                {params.InputProps.endAdornment}
                              </>
                            ),
                          }}
                        />
                      )}
                    />
                  </Grid>
                )}

                <Grid item md={12} xs={12}>
                  <TextField
                    fullWidth
                    label={`${
                      user?.type === UserTypes.broker.trucking.driver ? 'Driver' : 'Custom'
                    } Id`}
                    name="customId"
                    onChange={handleChange}
                    value={user?.customId || ''}
                    variant="outlined"
                  />
                </Grid>
                <UserNotificationAdminPanel userData={user} />
              </>
            )}
          </Grid>
        </Grid>
        {savePosition === 'bottom' && (
          <Grid container spacing={2}>
            <Grid item xs={12}>
              {isLoadingSaveDetails ? (
                <DialogActions sx={{ justifyContent: 'start' }}>
                  <CircularProgress />
                </DialogActions>
              ) : (
                <DialogActionContainer
                  saveHandler={handleSaveDetails}
                  closeHandler={handleClose}
                  editing={!!user?.id ? true : false}
                />
              )}
            </Grid>
          </Grid>
        )}
      </>
    );
  }
);

export default UserForm;
