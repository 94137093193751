import React from 'react';
import PropTypes from 'prop-types';

const TrafficLightsGreen = ({ className }) => (
  <img className={className} alt="truck-front" src="/static/images/traffic-lights-green.gif" />
);

TrafficLightsGreen.propTypes = {
  className: PropTypes.string,
};

export default TrafficLightsGreen;
