import React, { useEffect, useState } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { IJoinTruckingDispatch } from 'lg-helpers/dist/trucking/interfaces/IJoinTruckingDispatch';
import { ITruckingTicket } from 'lg-helpers/dist/trucking/interfaces/ITruckingTicket';
import { mergeTicketsAndDispatches } from '../../TicketsView/components/TicketsList';
import { generateDeletedTicketsColumns } from './deletedTicketsColumns';

type DeletedLoadsViewParams = {
  deletedTickets: ITruckingTicket[];
  joinedDispatches: IJoinTruckingDispatch[];
  idsToRenew: number[];
  setIdsToRenew: (ids: number[]) => any;
  searchFilter?: string;
};

type TicketAndDispatch = ITruckingTicket & IJoinTruckingDispatch;

export const DeletedTicketsList = ({
  deletedTickets,
  joinedDispatches,
  idsToRenew,
  setIdsToRenew,
  searchFilter,
}: DeletedLoadsViewParams) => {
  const [ticketsAndDispatches, setTicketsAndDispatches] = useState([] as TicketAndDispatch[]);

  useEffect(() => {
    const ticketsWithJoinedDispatches = mergeTicketsAndDispatches(deletedTickets, joinedDispatches);

    setTicketsAndDispatches(ticketsWithJoinedDispatches);
  }, [deletedTickets, joinedDispatches]);

  const removeTruckFromAddingToDivision = (id: number) => {
    setIdsToRenew(idsToRenew.filter(_id => _id !== id));
  };

  const addTruckToAddingToDivision = (id: number) => {
    setIdsToRenew([...idsToRenew, id]);
  };

  const columns = generateDeletedTicketsColumns(
    addTruckToAddingToDivision,
    removeTruckFromAddingToDivision,
    idsToRenew
  );

  const filterRowsBySearch = (rows: TicketAndDispatch[]) => {
    if (!searchFilter) {
      return rows;
    } else {
      const lowercaseFilter = searchFilter.toLowerCase();

      const filteredRows = rows.filter(
        ticket =>
          String(ticket?.ticketNumberTruckingTicket)?.includes(searchFilter) ||
          ticket?.manualProjectNameTruckingDispatch?.toLowerCase()?.includes(lowercaseFilter) ||
          ticket?.nameTruckingProject?.toLowerCase()?.includes(lowercaseFilter) ||
          String(ticket?.pitTicketNumberTruckingTicket)?.includes(lowercaseFilter) ||
          String(ticket?.manifestNumberTruckingTicket)?.includes(lowercaseFilter)
      );
      return filteredRows;
    }
  };

  return (
    <DataGrid
      columns={columns}
      rows={filterRowsBySearch(ticketsAndDispatches) || []}
      getRowId={row => row.idTruckingTicket}
      showColumnRightBorder={false}
      disableColumnMenu
      disableSelectionOnClick
      autoHeight
      components={{
        ColumnResizeIcon: () => <div></div>,
      }}
    />
  );
};
