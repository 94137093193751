import React from 'react';
import { createSvgIcon } from '@mui/material';

export const DumpTruck = createSvgIcon(
  <path
    fillRule="evenodd"
    clipRule="evenodd"
    d="m26.4017 1.69041 5.6687 3.65494.4179.269h7.8241V18.7392L37.9 20.2827H22.2576V2.70759h-7.5053V1.69041h11.6509-.0015ZM16.987 7.75014V20.2863H7.66033v-.0051H1.68908v-4.0643l5.09734-3.5484.38849-.2727.19093-.4365 1.8433-4.20916H16.987ZM26.7168 0H13.6705a.60847.60847 0 0 0-.4302.1782.60937.60937 0 0 0-.1786.43035V3.7887c0 .16159.0642.31657.1783.43083a.60859.60859 0 0 0 .4305.17846h6.8966v16.9643c0 .1615.064.3163.178.4306a.60898.60898 0 0 0 .4301.1787h17.0376a.6028.6028 0 0 0 .3275-.0963l3.1777-2.0299a.60736.60736 0 0 0 .2068-.221c.0492-.09.0748-.191.0745-.2935V4.53323a.60962.60962 0 0 0-.1781-.43057.60855.60855 0 0 0-.43-.17871H32.984L27.0495.09702A.61074.61074 0 0 0 26.7197 0h-.0029ZM17.75 6.05974H8.71123a.92188.92188 0 0 0-.50491.15031.92294.92294 0 0 0-.34036.40238L5.82218 11.2809.39583 15.0578A.92484.92484 0 0 0 0 15.8163v5.2315c0 .245.09726.48.27038.6532a.92257.92257 0 0 0 .65274.2706H6.7563l.35324.0044H18.6761V6.98284a.92343.92343 0 0 0-.0706-.3541.92397.92397 0 0 0-.2009-.29993.92218.92218 0 0 0-.6546-.26907Z"
  />,
  'DumpTruck'
);
