import React, { useState, useEffect, ChangeEvent } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Grid,
  Card,
  CardHeader,
  CardContent,
  TextField,
  FormControlLabel,
  Switch,
  Button,
  List,
  Typography,
  Box,
  Autocomplete,
  Container,
} from '@mui/material';
import { v4 as uuidv4 } from 'uuid';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { hasErrorRequired } from 'services/logic/formValidation';
import ListItemDefinition from './ListItemDefinition';
import sectionsForCustomInputs from 'utils/sectionsForCustomInputs';
import PermissionsWrapper from 'sharedComponents/PermissionsWrapper';
import { UserTypes } from 'lg-helpers/dist/constants/user/UserTypes';
import { UserTypesArray } from 'lg-helpers/dist/constants/user/UserTypes';
import ErrorMessages from 'services/constants/errorMessages';
import { ICustomInputListItem, IGlobalCustomInputs } from 'services/api/autogenerated-code';
import { ICompany } from 'lg-helpers/dist/shared/interfaces/ICompany';
import { usePermissionsContext } from 'services/permissions';
import { useParams } from 'react-router';
import api from 'services/api/autogenerated';
import CompaniesApi from 'services/postgres/CompaniesApi';
import useShowError from 'modules/errors';
import { Link } from 'react-router-dom';
import ButtonConfirmWithLoading from './ButtonConfirmWithLoading';

export const inputDefinitionTypes = ['Text', 'List', 'Checkbox', 'Signature'];

const companiesApi = new CompaniesApi();

const { isRequired } = ErrorMessages;

export const CustomInputDefinitionGlobalEdit = () => {
  const { user } = usePermissionsContext();
  const { id } = useParams();
  const [isUserSuperAdmin, setIsUserSuperAdmin] = useState(false);
  const [customInput, setCustomInput] = useState<IGlobalCustomInputs>({
    name: '',
    type: 'Text' as string,
    isValid: false,
    isRequired: true,
    isHidden: false,
    defaultValue: '',
    columnIndex: 0,
    isValidCustomInputDefinition: false,
    label: '',
    propertyPathInManifestCustomInputs: '',
    userTypesCanEdit: [] as string[],
    userTypesCanOnlyRead: [] as string[],
    sectionsCanOnlyRead: [] as string[],
    sectionsCanEdit: [] as string[],
    companiesIds: [],
    order: 0,
  });
  const [companies, setCompanies] = useState<ICompany[]>([]);
  const [selectedCompanies, setSelectedCompanies] = useState<ICompany[]>([]);
  const showError = useShowError();
  const priorPage = '/super-admin/administration/global-custom-inputs/';
  const navigate = useNavigate();

  const handleSave = async (evt: any) => {
    evt.preventDefault();
    try {
      const { id: inputId, items, ...restInput } = customInput;
      const results = await api.customInputs.upsertCustomInput({
        ...(typeof inputId === 'number' ? { id: Number(inputId) } : {}),
        ...(items !== null ? { items: items } : {}),
        ...restInput,
        defaultValue: (customInput.defaultValue as any)?.value || customInput.defaultValue,
        order: 0,
        columnIndex: 0,
      });
      if (results.data.id) navigate(priorPage + results.data.id);
    } catch (error) {
      console.error('Error saving global custom inputs', error);
      showError({ title: 'Save Error', text: 'Error saving custom inputs' });
    }
  };

  const deleteCustomInput = async (deleteId: number) => {
    if (deleteId !== 0) {
      try {
        await api.customInputs.deleteCustomInputs(deleteId);
      } catch (error) {
        console.error('Error deleting global custom inputs', error);
        showError({ title: 'Delete Error', text: 'Error deleting custom input' });
      }
    }
    navigate(priorPage);
  };

  useEffect(() => {
    const getCompanies = async () => {
      try {
        // TODO: hotfixed with excluded types, but needs fix/optimisation in future
        const companiesResult = await companiesApi.getCompaniesWithCustomDataProperties([
          'regions',
        ]);
        setCompanies(companiesResult);
      } catch (error) {
        console.error('Error', error);
        showError({ title: 'Companies Error', text: 'Error finding companies' });
      }
    };
    getCompanies();
    // eslint-disable-next-line
    }, []);

  const fetchCustomInput = async () => {
    if (id === '0') return;
    try {
      if (!id) return;
      const resultsCustomInput = await api.customInputs.getCustomInputById(+id);
      setCustomInput(resultsCustomInput.data);
    } catch (error) {
      console.error(error);
      showError({ title: 'Custom Inputs Error', text: `Error finding custom input ${id}` });
    }
  };

  useEffect(() => {
    fetchCustomInput();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    setIsUserSuperAdmin(user?.type === UserTypes.admin.super);
  }, [user]);

  const handleChange = (event: any) => {
    const target = event.target as HTMLInputElement;
    const changedCustomInput: IGlobalCustomInputs = {
      ...customInput,
      [target.name]: target?.type === 'checkbox' ? target.checked : target.value || '',
      ...(target.name === 'type' ? { defaultValue: '', items: [] } : {}),
    };
    setCustomInput(changedCustomInput);
  };

  const handleNativeSelectChange = (name: string, value: any) => {
    handleChange({ target: { name, value } });
  };

  const handleNativeSelectChangeCompanies = (value: any) => {
    setSelectedCompanies(value);
    handleChange({
      target: { name: 'companiesIds', value: value.map((e: any) => e.id) },
    });
  };

  const setListItem = (newItem: ICustomInputListItem) => {
    if (!customInput) return;
    let newItems: ICustomInputListItem[] = [];

    if (customInput?.items && customInput?.items.length > 0) {
      newItems = customInput?.items.map((oldItem: any) => {
        if (oldItem.id === newItem.id) {
          return newItem;
        }
        return oldItem;
      });
    }

    const changedLocalInputDefinition: IGlobalCustomInputs = {
      ...customInput,
      items: newItems,
    };
    setCustomInput(changedLocalInputDefinition);
  };

  const hideItem = (hiddenItem: IGlobalCustomInputs) => {
    setCustomInput({
      ...hiddenItem,
      isHidden: !hiddenItem.isHidden,
    });
  };

  const removeListItem = (removeId: string) => {
    const newItems = customInput?.items?.filter(item => item.id !== removeId);
    const changedCustomInput = {
      ...customInput,
      items: newItems,
    };
    setCustomInput(changedCustomInput);
  };

  const addListItem = () => {
    const newItem = {
      id: uuidv4(),
    };
    let newItems;
    if (customInput?.items) {
      newItems = [...customInput.items, newItem];
    } else {
      newItems = [newItem];
    }
    const changedCustomInput: IGlobalCustomInputs = {
      ...customInput,
      items: newItems as { id: string; label: string; value: string }[],
    };
    setCustomInput(changedCustomInput);
  };

  useEffect(() => {
    if (
      ['List', 'Checkbox'].includes(customInput?.type) &&
      (!customInput?.items || customInput?.items?.length === 0)
    ) {
      addListItem();
    }
    // eslint-disable-next-line
  }, [customInput]);

  const getCompanyLabel = (company: ICompany) => {
    if (!company) {
      return '';
    }
    const typesStringified = company.types
      ? company?.types?.map((type: string) => type).join(', ')
      : '';
    return `${company.name} - ${typesStringified}`;
  };

  useEffect(() => {
    if (!customInput?.companiesIds && !companies) return;
    const newSelectedCompany = (customInput?.companiesIds || []).map(companyId =>
      (companies || []).find(company => company.id === companyId)
    );
    if (!newSelectedCompany.length) return;
    setSelectedCompanies(newSelectedCompany as ICompany[]);
  }, [customInput, companies]);

  return (
    <Container>
      <Box padding="10px">
        <Button color="inherit" component={Link} variant="outlined" to={priorPage}>
          Back
        </Button>
        <Card style={{ width: '100%', marginTop: '20px' }}>
          <form onSubmit={handleSave}>
            <CardHeader
              title={`[${customInput?.type}] ${customInput?.name || 'New Custom Input Definition'}`}
              action={
                <PermissionsWrapper canAccess={[UserTypes.admin.super]}>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={!!customInput?.isRequired}
                        onChange={handleChange}
                        name="isRequired"
                        color="primary"
                        disabled={!isUserSuperAdmin}
                      />
                    }
                    label="Is Required"
                  />
                  <Button
                    variant="outlined"
                    disabled={!isUserSuperAdmin}
                    onClick={() => hideItem(customInput)}
                  >
                    {customInput?.isHidden ? (
                      <>
                        <VisibilityOffIcon color="secondary" />
                        <Box marginLeft={1}>
                          <Typography color="secondary">Is hidden</Typography>
                        </Box>
                      </>
                    ) : (
                      <>
                        <VisibilityIcon />
                        <Box marginLeft={1}>
                          <Typography>Is visible</Typography>
                        </Box>
                      </>
                    )}
                  </Button>
                </PermissionsWrapper>
              }
            />
            <CardContent>
              <Grid container spacing={3}>
                <Grid item sm={6} xs={12}>
                  <Autocomplete
                    fullWidth
                    options={inputDefinitionTypes}
                    value={customInput.type}
                    onChange={(event, value) => handleNativeSelectChange('type', value)}
                    renderInput={params => (
                      <TextField
                        {...params}
                        label="Type"
                        required
                        variant="outlined"
                        InputProps={{
                          ...params.InputProps,
                          endAdornment: <>{params.InputProps.endAdornment}</>,
                        }}
                      />
                    )}
                  />
                </Grid>
                <Grid item sm={6} xs={12}>
                  <TextField
                    fullWidth
                    label="Name"
                    name="name"
                    disabled={!isUserSuperAdmin}
                    onChange={(evt: ChangeEvent<HTMLInputElement>) => handleChange(evt)}
                    value={customInput?.name || ''}
                    variant="outlined"
                    error={hasErrorRequired(customInput?.name)}
                    helperText={hasErrorRequired(customInput?.name) ? isRequired : ''}
                    required
                  />
                </Grid>
                <Grid item sm={6} xs={12}>
                  <TextField
                    fullWidth
                    label="Label"
                    name="label"
                    disabled={!isUserSuperAdmin}
                    onChange={(evt: ChangeEvent<HTMLInputElement>) => handleChange(evt)}
                    value={customInput?.label || ''}
                    variant="outlined"
                    error={hasErrorRequired(customInput?.label)}
                    helperText={hasErrorRequired(customInput?.label) ? isRequired : ''}
                    required
                  />
                </Grid>
                <PermissionsWrapper canAccess={[UserTypes.admin.super]}>
                  <Grid item sm={6} xs={12}>
                    <TextField
                      fullWidth
                      label={`Property Path in #.customInputs.${
                        customInput?.propertyPathInManifestCustomInputs || '?'
                      }`}
                      name="propertyPathInManifestCustomInputs"
                      onChange={(evt: ChangeEvent<HTMLInputElement>) => handleChange(evt)}
                      value={customInput?.propertyPathInManifestCustomInputs || ''}
                      variant="outlined"
                      error={hasErrorRequired(customInput?.propertyPathInManifestCustomInputs)}
                      helperText={
                        hasErrorRequired(customInput?.propertyPathInManifestCustomInputs)
                          ? isRequired
                          : ''
                      }
                      required
                    />
                  </Grid>
                </PermissionsWrapper>
                <PermissionsWrapper canAccess={[UserTypes.admin.super]}>
                  <Grid item sm={6} xs={12}>
                    <Autocomplete
                      multiple
                      options={UserTypesArray}
                      isOptionEqualToValue={(option, val) => option === val}
                      value={customInput?.userTypesCanEdit || []}
                      id="userTypesCanEdit"
                      onChange={(event, value) =>
                        handleNativeSelectChange('userTypesCanEdit', value)
                      }
                      renderInput={params => (
                        <TextField
                          {...params}
                          variant="outlined"
                          label="User Types - Edit"
                          placeholder="Select user types"
                        />
                      )}
                    />
                  </Grid>
                </PermissionsWrapper>
                <PermissionsWrapper canAccess={[UserTypes.admin.super]}>
                  <Grid item sm={6} xs={12}>
                    <Autocomplete
                      multiple
                      getOptionDisabled={() => false}
                      options={sectionsForCustomInputs}
                      value={customInput?.sectionsCanEdit || []}
                      isOptionEqualToValue={(option, val) => option === val}
                      onChange={(event, value) =>
                        handleNativeSelectChange('sectionsCanEdit', value)
                      }
                      renderInput={params => (
                        <TextField
                          {...params}
                          variant="outlined"
                          label="Edit in Sections"
                          placeholder="Select sections"
                        />
                      )}
                    />
                  </Grid>
                </PermissionsWrapper>
                <PermissionsWrapper canAccess={[UserTypes.admin.super]}>
                  <Grid item sm={6} xs={12}>
                    <Autocomplete
                      multiple
                      options={UserTypesArray}
                      value={customInput?.userTypesCanOnlyRead || []}
                      isOptionEqualToValue={(option, val) => option === val}
                      onChange={(event, value) =>
                        handleNativeSelectChange('userTypesCanOnlyRead', value)
                      }
                      renderInput={params => (
                        <TextField
                          {...params}
                          variant="outlined"
                          label="User Types - Only Read"
                          placeholder="Select user types"
                        />
                      )}
                    />
                  </Grid>
                </PermissionsWrapper>
                <PermissionsWrapper canAccess={[UserTypes.admin.super]}>
                  <Grid item sm={6} xs={12}>
                    <Autocomplete
                      multiple
                      getOptionDisabled={() => false}
                      options={sectionsForCustomInputs}
                      value={customInput?.sectionsCanOnlyRead || []}
                      isOptionEqualToValue={(option, val) => option === val}
                      onChange={(event, value) =>
                        handleNativeSelectChange('sectionsCanOnlyRead', value)
                      }
                      renderInput={params => (
                        <TextField
                          {...params}
                          variant="outlined"
                          label="Sections Only Read"
                          placeholder="Select sections"
                        />
                      )}
                    />
                  </Grid>
                </PermissionsWrapper>
                {['List'].includes(customInput?.type || '') ? (
                  <Grid item sm={6} xs={12}>
                    <Autocomplete
                      options={customInput?.items?.filter(item => item.label && item.value) || []}
                      value={customInput?.items?.find(
                        item => item.value === customInput?.defaultValue
                      )}
                      isOptionEqualToValue={(option, value) =>
                        value ? option.id === value.id : false
                      }
                      getOptionLabel={option => option.label}
                      onChange={(event, value) => handleNativeSelectChange('defaultValue', value)}
                      renderInput={params => (
                        <TextField
                          {...params}
                          label="Default Value"
                          required
                          variant="outlined"
                          InputProps={{
                            ...params.InputProps,
                            endAdornment: <>{params.InputProps.endAdornment}</>,
                          }}
                        />
                      )}
                    />
                  </Grid>
                ) : (
                  ['Signature', 'Text'].includes(customInput?.type || '') && (
                    <Grid item sm={6} xs={12}>
                      <TextField
                        disabled={!isUserSuperAdmin}
                        fullWidth
                        label="Default Value"
                        name="defaultValue"
                        onChange={(evt: ChangeEvent<HTMLInputElement>) => handleChange(evt)}
                        value={customInput?.defaultValue || ''}
                        variant="outlined"
                      />
                    </Grid>
                  )
                )}
                {['List', 'Checkbox'].includes(customInput?.type || '') &&
                  customInput?.items &&
                  customInput?.items?.length > 0 && (
                    <Grid item md={12} xs={12} sx={{ marginTop: 3 }}>
                      <Typography>Items</Typography>
                      <List>
                        {customInput?.items?.map((item, index) => {
                          return (
                            <ListItemDefinition
                              key={item.id}
                              listItem={item}
                              showRemoveButton={customInput?.items?.length !== 1}
                              showAddButton={(customInput?.items || [])?.length - 1 === index}
                              onSetListItem={setListItem}
                              onAddListItem={addListItem}
                              onRemoveListItem={removeListItem}
                              canValidate={false}
                              userCanEdit={user.generator}
                            />
                          );
                        })}
                      </List>
                    </Grid>
                  )}
                <Grid item md={12} xs={12}>
                  <Autocomplete
                    fullWidth
                    multiple
                    options={companies || []}
                    getOptionLabel={getCompanyLabel}
                    value={selectedCompanies || []}
                    onChange={(evt, value) => handleNativeSelectChangeCompanies(value)}
                    renderOption={(props, option) => (
                      <li {...props} key={option.id || ''}>
                        {option.name} - {option.types.join(', ')}
                      </li>
                    )}
                    renderInput={params => (
                      <TextField
                        {...params}
                        label="Companies"
                        variant="outlined"
                        InputProps={{
                          ...params.InputProps,
                          endAdornment: <>{params.InputProps.endAdornment}</>,
                        }}
                      />
                    )}
                  />
                </Grid>
              </Grid>
              <Grid container sx={{ marginTop: 2 }} spacing={2}>
                <Grid item xs={12} sm={!!deleteCustomInput ? 6 : 12}>
                  <Button fullWidth type="submit" color="primary" variant="outlined">
                    Save
                  </Button>
                </Grid>
                {!!deleteCustomInput && (
                  <Grid item xs={12} sm={6}>
                    <ButtonConfirmWithLoading
                      color="error"
                      text="Delete Custom Input"
                      textConfirm="Click again to confirm!"
                      confirmCallback={() => deleteCustomInput(customInput?.id || 0)}
                    />
                  </Grid>
                )}
              </Grid>
            </CardContent>
          </form>
        </Card>
      </Box>
    </Container>
  );
};
