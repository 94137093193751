import React from 'react';
import { Typography, Box, Button, TextField } from '@mui/material';
import DateWithArrows from 'sharedComponents/DateWithArrows';
import { RestartAlt } from '@mui/icons-material';

type loadsToolBarProps = {
  date: string;
  setDate: (val: string) => void;
  refreshTickets: () => void;
  searchFilter: string;
  setSearchFilter: (search: string) => void;
};

export const BrokerTicketsToolbar = (props: loadsToolBarProps) => {
  const { date, setDate, refreshTickets, searchFilter, setSearchFilter } = props;

  return (
    <Box display="flex" alignItems="center" justifyContent="space-between" mb={2}>
      <Box display="flex" flexDirection="row" alignItems="center">
        <Typography>Display Date</Typography>
        <DateWithArrows setDate={(val: string) => setDate(val)} dateValue={date} />
      </Box>
      <Box display="flex" flexDirection="row" alignItems="center">
        <Typography sx={{ mr: 1 }}>Search</Typography>
        <TextField
          required
          size="small"
          name="search"
          value={searchFilter}
          onChange={e => setSearchFilter(e.target.value)}
        />
      </Box>
      <Box display="flex" alignItems="center" justifyContent="space-around">
        <Button
          onClick={() => refreshTickets()}
          variant="contained"
          color="secondary"
          sx={{ mr: 1 }}
        >
          <Box display="flex" alignItems="center" flexDirection="column">
            <RestartAlt sx={{ color: 'secondary.main' }} />
            <Typography variant="overline">REFRESH</Typography>
          </Box>
        </Button>
      </Box>
    </Box>
  );
};
