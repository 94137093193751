import React, { useState } from 'react';
import styled from 'styled-components';
import platform from 'platform';
import { ActionButton } from './ErrorHandler';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import Icon from '@mui/icons-material/BugReport';

export default function ReportBug({ authUser }: { authUser?: any }) {
  const [isVisible, setIsVisible] = useState(false);
  const isEnabled =
    !!localStorage.debug ||
    process.env.REACT_APP_BUILD_ENV === 'staging' ||
    process.env.REACT_APP_BUILD_ENV === 'development';
  const time = new Date();
  const info = [
    {
      name: 'Environment',
      value: process.env.REACT_APP_BUILD_ENV,
    },
    {
      name: 'Firebase project',
      value: process.env.REACT_APP_FIREBASE_PROJECT_ID,
    },
    {
      name: 'App version',
      value: process.env.REACT_APP_MANIFEST_APP_VERSION,
    },
    {
      name: 'Browser',
      value: platform.description,
    },
    {
      name: 'Time',
      value: `${time.toISOString()} (${time.getTimezoneOffset()})`,
    },
    {
      name: 'API URL',
      value: process.env.REACT_APP_API_BASE_URL,
    },
    {
      name: 'User',
      value: authUser
        ? [
            `# ${authUser.id} [${authUser.actingAsType}/${authUser.type}]`,
            `${authUser.firstName} ${authUser.lastName} ${authUser.email}`,
            `--company ${((authUser.companies || [])[0] || { name: '_No Company_' }).name} (${(
              authUser.companiesIds || []
            ).join(',')})`,
            `--scale ${authUser.selectedScaleId}`,
            `--region ${authUser.regionId}`,
          ].join(' ')
        : 'No user',
    },
    {
      name: 'Current URL',
      value: window.location.href,
    },
  ]
    .map(({ name, value }) => `${name}: ${value}`)
    .join('\n');

  if (!isEnabled) return null;

  return (
    <>
      <ReportBugButton onClick={() => setIsVisible(true)}>
        <Icon />
      </ReportBugButton>
      {isVisible && (
        <CenteredErrorWrapper>
          <h1>Copy this information in your ticket:</h1>
          <pre>{info}</pre>
          <ButtonsWrapper>
            <CopyToClipboard text={info}>
              <ActionButton>Copy</ActionButton>
            </CopyToClipboard>
            <ActionButton onClick={() => setIsVisible(false)}>Close</ActionButton>
          </ButtonsWrapper>
        </CenteredErrorWrapper>
      )}
    </>
  );
}

const CenteredErrorWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 40px 20px;
  z-index: 1000000;
  background: white;

  h1 {
    font-size: 30px;
  }

  p {
    font-size: 24px;
  }
`;

const ButtonsWrapper = styled.div`
  margin-top: 10px;
`;

const ReportBugButton = styled.button`
  position: fixed;
  z-index: 100000;
  bottom: 0;
  left: 0;
  width: 40px;
  height: 40px;
  opacity: 0.5;
  background: #41793a;
  color: white;

  &:hover {
    opacity: 1;
  }
`;
