import { FormControl, TextareaAutosize } from '@mui/material';
import React from 'react';

type FormFieldProps = {
  name?: string;
  label?: string;
  value?: string | null;
  onChange?: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
  selectOptions?: JSX.Element[] | null;
  disabled?: boolean;
  required?: boolean;
  placeholder?: string;
  type?: string;
  maxRows?: number;
  minRows?: number;
  style?: React.CSSProperties;
};

export const FormTextArea = ({
  name,
  value,
  label,
  onChange,
  selectOptions,
  maxRows,
  minRows,
  required = false,
  style,
  ...params
}: FormFieldProps) => {
  return (
    <FormControl fullWidth>
      <TextareaAutosize
        maxRows={maxRows}
        minRows={minRows}
        aria-label={label}
        placeholder={label}
        name={name}
        value={value || ''}
        onChange={onChange}
        required={required}
        style={{
          padding: '5px',
          ...style,
        }}
        {...params}
      />
    </FormControl>
  );
};
