import React, { Fragment, useEffect, useMemo } from 'react';
import 'sass/globals.scss';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import CancelIcon from '@mui/icons-material/Cancel';
import { Container, Box, Button, Typography } from '@mui/material';
import Page from 'sharedComponents/Page';
import { useSelector, useDispatch } from 'react-redux';
import { selectAuthUser } from 'modules/auth/storeSliceAuth';
import { useNavigate, useLocation } from 'react-router-dom';
import moment from 'moment';
import ManifestsViewPerScale from 'sharedComponents/ManifestsViewPerScale';
import { setSelectedManifest } from 'modules/scales/storeSliceScales';
import { getManifestDefaultColumns } from 'utils/manifestUtil';
import HtmlTooltip from 'sharedComponents/HtmlTooltip';

let manifestColumns: any[] = getManifestDefaultColumns(false, false, false);

const manifColumn = {
  path: 'number',
  label: 'Manifest#',
  isFilterable: false,
  getCellValueCallback: (row: any) => {
    if (row?.signatureDriver?.isReleasedManually && !row?.signatureScale?.isSigned) {
      const releaseDate = row?.signatureDriver?.releasedManuallyAt
        ? row.signatureDriver.releasedManuallyAt.toDate()
        : '';
      const passcode = row?.signatureDriver?.releasePasscode || '';
      return (
        <HtmlTooltip
          placement="top"
          title={
            <Fragment>
              <Typography align="center">
                {`Released manually with passcode ${passcode} at ${releaseDate}`}
              </Typography>
            </Fragment>
          }
        >
          <Typography variant="body2" align="center" color="secondary">
            {row.number}
          </Typography>
        </HtmlTooltip>
      );
    } else {
      return row.number;
    }
  },
};

const wmIDColumn = {
  path: 'receipt.id',
  label: 'Rec. ID',
  isFilterable: false,
  getCellValueCallback: (row: any) => {
    if (row?.receipt?.id) {
      return row.receipt.id;
    }
    return (
      <Box display="flex" justifyContent="center" alignItems="center">
        <CancelIcon color="secondary" />
      </Box>
    );
  },
};

const quantityColumn = {
  path: 'receipt.weight.rounded_net',
  label: 'Qty',
  isFilterable: false,
  getCellValueCallback: (row: any) => {
    const quantity = row?.receipt?.weight?.rounded_net || row?.receipt?.receipt?.quantity;
    if (quantity) {
      return quantity;
    }
    return (
      <Box display="flex" justifyContent="center" alignItems="center">
        <CancelIcon color="secondary" />
      </Box>
    );
  },
};

manifestColumns = [
  { path: 'project.profileNr', label: 'Profile#' },
  manifColumn,
  wmIDColumn,
  { path: 'project.name', label: 'Project Name' },
  manifestColumns[manifestColumns.findIndex(col => col.path === 'signatureGenerator.isSigned')],
  manifestColumns[manifestColumns.findIndex(col => col.path === 'signatureDriver.isSigned')],
  manifestColumns[manifestColumns.findIndex(col => col.path === 'signatureScale.isSigned')],
  manifestColumns[manifestColumns.findIndex(col => col.label === 'Carrier')],
  manifestColumns[manifestColumns.findIndex(col => col.label === 'Truck#/Driver')],
  {
    label: 'Scale/Operator',
    getCellValueCallback: (row: any) => {
      if (row?.receipt?.scale_in && row?.receipt?.details?.operator_in_id) {
        // This is specific for WM, it will only work if a manifest has a receipt from WM API
        return `${row?.receipt?.scale_in}/${row?.receipt?.details?.operator_in_id}`;
      } else {
        return row?.project?.scaleCompany?.name || '-';
      }
    },
  },
  manifestColumns[manifestColumns.findIndex(col => col.path === 'lastUsedAt')],
  quantityColumn,
];

const QualityControlView = () => {
  const authUser = useSelector(selectAuthUser);
  const navigate = useNavigate();
  const { search } = useLocation();
  const query = new URLSearchParams(search);
  const daySearchParam = query.get('day');
  const dispatch = useDispatch();

  const dateRange = useMemo(() => {
    const day = daySearchParam ? new Date(daySearchParam) : new Date();
    return [moment(day).startOf('day').toDate(), moment(day).endOf('day').toDate()];
  }, [daySearchParam]);

  useEffect(() => {
    if (!authUser) {
      return;
    }

    if (!authUser.selectedScaleId) {
      navigate('/scales/account');
    }
  }, [authUser, navigate]);

  return (
    <Page className="min-padding-and-height" title="Incoming Manifests">
      <Container>
        <Button onClick={() => navigate(`/`)} variant="outlined">
          <NavigateBeforeIcon /> Back
        </Button>
        <Box display="flex" justifyContent="center" alignItems="center" marginBottom={4}>
          <Typography variant="h3">Livegistics QC</Typography>
        </Box>
        {authUser && authUser.selectedScaleId && (
          <ManifestsViewPerScale
            manifestColumns={manifestColumns}
            showOnlyAttendantsScale={true}
            showOnlyIncoming={true}
            showIncomingAndProcessed={true}
            showOnlyQCManifests={true}
            scaleId={authUser.selectedScaleId}
            defaultFilterIsUsedBetween={dateRange as [Date, Date]}
            handleRowClickCallback={manifest => {
              dispatch(setSelectedManifest(manifest));
              navigate('/scales/verify-manifest');
            }}
          />
        )}
      </Container>
    </Page>
  );
};

export default QualityControlView;
