import React from 'react';
import { FormControl, FormHelperText, InputLabel } from '@mui/material';

type FormFieldProps = {
  name: string;
  label: string;
  component: JSX.Element;
  required?: boolean;
};

export const FormCustomField = ({ name, label, component, required }: FormFieldProps) => {
  return (
    <FormControl fullWidth>
      <InputLabel shrink required={required} htmlFor={name}>
        {label}
      </InputLabel>
      {component}
      <FormHelperText />
    </FormControl>
  );
};
