import React from 'react';
import { createSvgIcon } from '@mui/material';

export const ListCheckbox = createSvgIcon(
  <>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1 0C.44771 0 0 .44771 0 1v4c0 .55228.44771 1 1 1h4c.55228 0 1-.44772 1-1V1c0-.55229-.44772-1-1-1H1Zm1 4V2h2v2H2Z"
    />
    <path d="M8 3c0-.55228.44771-1 1-1h12c.5523 0 1 .44772 1 1s-.4477 1-1 1H9c-.55229 0-1-.44772-1-1ZM9 9c-.55229 0-1 .44771-1 1 0 .5523.44771 1 1 1h12c.5523 0 1-.4477 1-1 0-.55229-.4477-1-1-1H9ZM9 16c-.55229 0-1 .4477-1 1s.44771 1 1 1h12c.5523 0 1-.4477 1-1s-.4477-1-1-1H9Z" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1 14c-.55229 0-1 .4477-1 1v4c0 .5523.44771 1 1 1h4c.55228 0 1-.4477 1-1v-4c0-.5523-.44772-1-1-1H1Zm1 4v-2h2v2H2ZM0 8c0-.55228.44771-1 1-1h4c.55228 0 1 .44772 1 1v4c0 .5523-.44772 1-1 1H1c-.55229 0-1-.4477-1-1V8Zm2 1v2h2V9H2Z"
    />
  </>,
  'List Checkbox'
);
