import React, { useState, useEffect, useCallback, useMemo } from 'react';
import 'sass/globals.scss';
import {
  Container,
  Grid,
  Box,
  Button,
  CircularProgress,
  TextField,
  Card,
  Autocomplete,
  FormControlLabel,
  Switch,
} from '@mui/material';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import Page from 'sharedComponents/Page';
import { useParams, useNavigate } from 'react-router-dom';
import { hasErrorRequired, hasErrorValidEmail } from 'services/logic/formValidation';
import contractorCompaniesTypes from 'services/constants/contractorCompaniesTypes.json';
import { getRoutingKeyByUserType } from './CompaniesTable';
import useShowError from 'modules/errors';
import PermissionsWrapper from 'sharedComponents/PermissionsWrapper';
import { UserTypes } from 'lg-helpers/dist/constants/user/UserTypes';
import CompaniesApi from 'services/postgres/CompaniesApi';
import ErrorMessages from 'services/constants/errorMessages';
import { usePermissionsContext } from 'services/permissions';
const { isRequired, emailNotValid } = ErrorMessages;

const companiesApi = new CompaniesApi();

const CompanyView = () => {
  let { id } = useParams();
  const { user: authUser } = usePermissionsContext();
  const userHasCompanyAccess =
    !id ||
    authUser.role === 'super_admin' ||
    authUser.role === 'customer_admin' ||
    authUser.companiesIds.includes(id as string);
  const isGeneratorAdmin = useMemo(() => authUser?.actingAsType === 'Generator Admin', [authUser]);
  const isContractorAdmin = useMemo(
    () => authUser?.actingAsType === 'Contractor Admin',
    [authUser]
  );
  const [isDisabledSaveDetails, setIsDisabledSaveDetails] = useState(true);
  const [isLoadingSaveDetails, setIsSavingSaveDetails] = useState(false);
  const showError = useShowError();
  const [wasSubmitted, setWasSubmitted] = useState(false);
  const [isLoadingData, setIsLoadingData] = useState(false);

  const routingKey = authUser ? getRoutingKeyByUserType(authUser.actingAsType) : '';
  const companiesTypes = authUser
    ? authUser.actingAsType === UserTypes.contractor.admin // this component used only for contractor admin
      ? contractorCompaniesTypes
      : []
    : [];

  const [company, setCompany] = useState({} as any);

  const navigate = useNavigate();

  useEffect(() => {
    if (!id && (!authUser || !authUser.companiesIds)) {
      return;
    }

    const companyId = id || authUser.companiesIds[0];

    const getCompany = async () => {
      try {
        setIsLoadingData(true);
        const companyRes = await companiesApi.getById(companyId);

        if (companyRes) {
          setCompany(companyRes);
        } else {
          showError({ title: "This company doesn't exist", duration: 10000 });
        }

        setIsLoadingData(false);
      } catch (e) {
        setIsLoadingData(false);
        showError({ title: 'An expected error occurred please try again' });
      }
    };

    getCompany();
  }, [id, authUser, showError]);

  useEffect(() => {
    if (!company.id) {
      return;
    }
  }, [company.id]);

  const handleSaveDetails = async () => {
    setWasSubmitted(true);
    if (hasErrors()) {
      showError({ title: 'Validation errors' });
      return;
    }

    const { allowMultipleManifests, ...companyRest } = company;

    setIsSavingSaveDetails(true);
    const companyToSave = {
      companyData: companyRest,
      customDataArray: [],
    } as any;

    companyToSave.types && companyToSave.types.sort();

    if (isContractorAdmin || isGeneratorAdmin) {
      companyToSave.customDataArray.push({
        nameCompaniesCustomData: 'allowMultipleManifests',
        valueInteger: String(allowMultipleManifests) === 'true' ? 1 : 0,
        valueArrayJsons: null,
        valueString: null,
      });
    }

    try {
      if (!id) {
        const { id: insertedId } = await companiesApi.insertOrUpdate(companyToSave);

        navigate(`/${routingKey}/company/${insertedId}`);
      } else {
        await companiesApi.insertOrUpdate(companyToSave);
      }
      setIsDisabledSaveDetails(true);
    } catch (error) {
      console.error(error);
      showError({ title: 'Save failed' });
    }

    setIsSavingSaveDetails(false);
  };

  const handleChange = useCallback(
    (event: any) => {
      setCompany({
        ...company,
        [event.target.name]: event.target.value,
      });
      setIsDisabledSaveDetails(false);
      setWasSubmitted(false);
    },
    [company]
  );

  const hasErrors = () => {
    if (company.regions && company.regions.length) {
      const emptyRegion = company.regions.find((region: any) => !region.name);
      if (emptyRegion) {
        return true;
      }
    }

    return company.email && hasErrorValidEmail(company.email);
  };

  return !userHasCompanyAccess ? (
    <p>Access refused</p>
  ) : (
    <Page>
      <Card>
        <Container className="min-padding-and-height">
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Box display="flex" justifyContent="space-between" alignItems="center">
                <PermissionsWrapper canAccess={[UserTypes.admin.super]}>
                  <Button onClick={() => navigate(`/${routingKey}/companies`)} variant="outlined">
                    <Box display="flex" alignItems="center">
                      <NavigateBeforeIcon fontSize="large" /> Back
                    </Box>
                  </Button>
                </PermissionsWrapper>

                <Button
                  color="primary"
                  variant="contained"
                  onClick={handleSaveDetails}
                  disabled={isDisabledSaveDetails}
                >
                  Save
                  {isLoadingSaveDetails && (
                    <Box marginLeft={2}>
                      <CircularProgress color="inherit" />
                    </Box>
                  )}
                </Button>
              </Box>
            </Grid>

            {isLoadingData ? (
              <Grid item xs={12}>
                <Box display="flex" justifyContent="center" alignItems="center">
                  <CircularProgress color="inherit" />
                </Box>
              </Grid>
            ) : (
              <>
                <Grid item md={6} xs={12}>
                  <TextField
                    fullWidth
                    label="Company Id"
                    disabled
                    value={company.id || ''}
                    variant="outlined"
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <Autocomplete
                    disabled
                    multiple
                    options={companiesTypes}
                    value={company.types || []}
                    onChange={(event, value) =>
                      handleChange({
                        target: {
                          name: 'types',
                          value,
                        },
                      })
                    }
                    getOptionLabel={option => option}
                    renderInput={params => (
                      <TextField
                        {...params}
                        variant="outlined"
                        required
                        label="Types"
                        placeholder="Select company types"
                        error={hasErrorRequired(company.types, wasSubmitted)}
                        helperText={hasErrorRequired(company.types, wasSubmitted) ? isRequired : ''}
                      />
                    )}
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <TextField
                    disabled
                    onChange={handleChange}
                    required
                    fullWidth
                    label="Name"
                    name="name"
                    value={company.name || ''}
                    variant="outlined"
                    error={hasErrorRequired(company.name, wasSubmitted)}
                    helperText={hasErrorRequired(company.name, wasSubmitted) ? isRequired : ''}
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <TextField
                    onChange={handleChange}
                    fullWidth
                    label="Email"
                    name="email"
                    value={company.email || ''}
                    variant="outlined"
                    error={hasErrorValidEmail(company.email, wasSubmitted)}
                    helperText={
                      hasErrorValidEmail(company.email, wasSubmitted) ? emailNotValid : ''
                    }
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <TextField
                    fullWidth
                    label="Phone Number"
                    name="phone"
                    onChange={handleChange}
                    value={company.phone || ''}
                    variant="outlined"
                  />
                </Grid>
                {(isContractorAdmin || isGeneratorAdmin) && (
                  <Grid item md={6} xs={12}>
                    <FormControlLabel
                      control={
                        <Switch
                          checked={!!company.allowMultipleManifests}
                          onChange={event =>
                            handleChange({
                              target: {
                                name: 'allowMultipleManifests',
                                value: event.target.checked,
                              },
                            })
                          }
                          name="allowMultipleManifests"
                          color="primary"
                        />
                      }
                      label="Allow Multiple Manifests"
                    />
                  </Grid>
                )}
              </>
            )}
          </Grid>
        </Container>
      </Card>
    </Page>
  );
};

export default CompanyView;
