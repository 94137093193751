import { createSlice } from '@reduxjs/toolkit';
import {
  getProjectTasks,
  addProjectTask,
  deleteProjectTask,
  getProjectTaskGrid,
  IJoinProjectTask,
} from './actions';
import { IProjectTask } from 'lg-helpers/dist/shared/interfaces/IProjectTask';
import { TFormStatus } from 'lg-helpers/dist/shared/types/TFormStatus';

interface IProjectTaskState {
  data: IProjectTask[] | IJoinProjectTask[];
  status: TFormStatus;
  error: string | undefined;
}

const initialState: IProjectTaskState = {
  data: [],
  status: null,
  error: undefined,
};

export const projectTaskSlice = createSlice({
  name: 'project-tasks',
  reducers: {
    add: state => state,
  },
  initialState,
  extraReducers: builder => {
    builder.addCase(getProjectTasks.fulfilled, (state, action) => {
      state.status = 'success';
      state.data = action.payload as IProjectTask[];
    });
    builder.addCase(getProjectTasks.rejected, (state, action) => {
      console.log('Error getting project tasks');
      state.status = 'failure';
      state.error = action.error?.message;
    });
    builder.addCase(addProjectTask.fulfilled, (state, action) => {
      state.status = 'success';
      const index = state.data.findIndex(a => a.id === action.payload?.id);
      if (index !== -1 && action.payload) {
        state.data[index] = action.payload;
      } else {
        state.data.push(action.payload as IProjectTask);
      }
    });
    builder.addCase(addProjectTask.rejected, (state, action) => {
      console.log('Error adding project tasks');
      state.status = 'failure';
      state.error = action.error?.message;
    });
    builder.addCase(deleteProjectTask.fulfilled, (state, action) => {
      state.status = 'success';
      state.data = state.data.filter(a => a.id !== action.payload.id);
    });
    builder.addCase(deleteProjectTask.rejected, (state, action) => {
      console.log('Error deleting project tasks');
      state.status = 'failure';
      state.error = action.error?.message;
    });
    builder.addCase(getProjectTaskGrid.fulfilled, (state, action) => {
      state.status = 'success';
      state.data = action.payload as IJoinProjectTask[];
    });
  },
});
