import React, { useEffect, useState } from 'react';
import 'mapbox-gl/dist/mapbox-gl.css';
import Mapbox from 'sharedComponents/Mapbox';
import { Grid, Button } from '@mui/material';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import { useParams } from 'react-router-dom';
import { generateIconMarker } from 'sharedComponents/MapboxHelpers';
import { useNavigate } from 'react-router-dom';
import Query from 'services/firebase/Query';
import Executer from 'services/firebase/Executer';
import QueryBase from 'lg-helpers/dist/firestore/query/QueryBase';

const query = new Query();
const executer = new Executer();

const ManifestMap = () => {
  let { id } = useParams();
  const navigate = useNavigate();

  const [manifest, setManifest] = useState(null);
  const [scaleLocations, setScaleLocations] = useState([]);
  const [siteLocation, setSiteLocation] = useState(null);
  const [markers, setMarkers] = useState([]);
  const [center, setCenter] = useState([0, 0]);
  const [driverGps, setDriverGps] = useState([]);

  useEffect(() => {
    if (!id) return;

    return executer.watchMultipleDocuments(
      query.manifests.getManifestDriverGpsPositions(id),
      gpsPositions => {
        if (gpsPositions.length) {
          setDriverGps(
            gpsPositions.filter(
              (gps, index, self) =>
                index ===
                self.findIndex(
                  data => data?.latitude === gps?.latitude && data?.longitude === gps?.longitude
                )
            )
          );
        }
      },
      error => {
        console.error('Error getting document:', error);
      }
    );
  }, [id]);

  useEffect(() => {
    if (!id) return;

    return executer.watchSingleDocument(
      query.base.getById(QueryBase.MANIFESTS_COLLECTION(), id),
      m => {
        if (m) {
          setManifest(m);
        }
      },
      error => {
        console.error('Error getting document:', error);
      }
    );
  }, [id]);

  useEffect(() => {
    if (!manifest) {
      return;
    }

    let unsubscribeScales = null;

    const unsubscribeProject = executer.watchSingleDocument(
      query.base.getById(QueryBase.PROJECTS_COLLECTION(), manifest.project?.id),
      project => {
        if (!project) return;

        const { coords } = project;

        if (coords?.gpsLat && coords?.gpsLng) {
          setSiteLocation({
            latitude: coords.gpsLat,
            longitude: coords.gpsLng,
            popupHTML: `<p><b>Project site:</b></p>` || '',
          });
        }

        let scalesIds = project.scalesIds || [];
        if (manifest.signatureDriver?.isSigned) {
          scalesIds = manifest.signatureDriver.scale?.id
            ? [manifest.signatureDriver.scale?.id]
            : [];
        }

        if (scalesIds.length === 0) return;

        unsubscribeScales = executer.watchMultipleDocuments(
          query.manifests.getProjectScales(scalesIds),
          scales => {
            const locations = scales
              ?.filter(scale => scale.gpsLat && scale.gpsLong)
              .map(({ gpsLat, gpsLong, address }) => ({
                latitude: gpsLat,
                longitude: gpsLong,
                popupHTML: `<p><b>Scale: <br/>${address}</b></p>` || '',
              }));

            if (locations) setScaleLocations(locations);
          },
          error => {
            console.error('Error:', error);
          }
        );
      },
      error => {
        console.error('Error:', error);
      }
    );

    return () => {
      if (unsubscribeScales) unsubscribeScales();
      unsubscribeProject();
    };
  }, [manifest]);

  useEffect(() => {
    let newMarkers = [];
    let sumLongitude = 0;
    let sumLatitude = 0;

    newMarkers = newMarkers.concat(
      scaleLocations.map(position => {
        sumLongitude += parseFloat(position.longitude);
        sumLatitude += parseFloat(position.latitude);

        return generateIconMarker(position, '/static/images/scale.png', position.popupHTML);
      })
    );

    if (scaleLocations.length > 0) {
      sumLongitude /= scaleLocations.length;
      sumLatitude /= scaleLocations.length;
    }

    if (siteLocation) {
      newMarkers = newMarkers.concat(
        generateIconMarker(siteLocation, '/static/images/excavator.png', siteLocation.popupHTML)
      );

      setCenter([siteLocation.longitude, siteLocation.latitude]);
    } else {
      setCenter([sumLongitude, sumLatitude]);
    }

    if (manifest?.signatureDriver?.isSigned) {
      const driverMarkers = driverGps
        ?.filter(gps => gps.longitude && gps.latitude)
        .map(({ longitude, latitude }) => {
          const manifestNumber = `Manifest number: ${manifest.number}`;
          const { truckingCompany, truckNumber } = manifest.signatureDriver;
          const popupHTML = `<p><b>${manifestNumber}</b></p><p>${truckingCompany}</p><p>${truckNumber}</p>`;

          return generateIconMarker(
            { longitude, latitude },
            '/static/images/icons/truck.svg',
            popupHTML
          );
        });

      if (driverMarkers) {
        newMarkers = newMarkers.concat(driverMarkers);
      }
    }

    setMarkers(newMarkers);
  }, [manifest, siteLocation, scaleLocations, driverGps]);

  return (
    <Grid container>
      <Grid item xs={12}>
        <Button
          onClick={() => navigate(-1)}
          variant="outlined"
          style={{
            position: 'absolute',
            zIndex: 1,
            background: '#fff',
            margin: '1rem 0 0 1rem',
          }}
        >
          <NavigateBeforeIcon fontSize="small" /> Back
        </Button>
        <Mapbox zoom={10} center={center} markers={markers} />
      </Grid>
    </Grid>
  );
};

export default ManifestMap;
