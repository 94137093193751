import React from 'react';
import { createSvgIcon } from '@mui/material';

export const LinkIcon = createSvgIcon(
  <path
    fillRule="evenodd"
    clipRule="evenodd"
    d="M19.5 0c1.3255 0 2.41 1.03153 2.4947 2.33562L22 2.5v13c0 1.3255-1.0315 2.41-2.3356 2.4947L19.5 18H19v1.5c0 1.3255-1.0315 2.41-2.3356 2.4947L16.5 22h-14C1.11929 22 0 20.8807 0 19.5v-14C0 4.11929 1.11929 3 2.5 3H4v-.5C4 1.17451 5.03152.08996 6.33562.00532L6.5 0h13Zm-3 5h-14c-.27614 0-.5.22386-.5.5v14c0 .2761.22386.5.5.5h14c.2761 0 .5-.2239.5-.5v-14c0-.27614-.2239-.5-.5-.5Zm-5.632 6.2234c1.1319 1.1319 1.1564 2.9577.0357 4.0784l-.0137.0102-.0213.0231-2.54714 2.3109c-1.12068 1.1206-2.94647 1.0962-4.07837-.0357-1.13094-1.131-1.12159-3.0577.01248-4.1916l.63639-.6196.13381-.1327c.39049-.3906 1.02365-.3906 1.41422-.0001.39056.3905.39062 1.0236.00014 1.4142l-.78282.7647c-.3436.3436-.34681 1.004 0 1.3508.32927.3293.82703.3631 1.18958.0858l.09529-.0833L9.494 13.881l.07119-.0807c.22875-.3051.22166-.7442-.02891-1.0689l-.16568-.188c-.30502-.3922-.27729-.9595.08319-1.32.39053-.3905 1.02371-.3905 1.41421 0ZM19.5 2h-13c-.24547 0-.44961.17687-.49194.41012L6 2.5V3h10.5c1.3255 0 2.41 1.03154 2.4947 2.33562L19 5.5V16h.5c.2455 0 .4496-.1769.4919-.4101L20 15.5v-13c0-.24547-.1769-.44961-.4101-.49194L19.5 2Zm-5.2627 5.12874c1.0866 1.08661 1.1527 2.81271.1824 3.92246L13.338 12.198c-.3792.4015-1.0121.4196-1.4137.0405-.3706-.35-.4146-.9162-.1209-1.3171l.0804-.0966.975-1.03188c.331-.33102.3235-.89075-.0357-1.24996-.3293-.32928-.827-.36302-1.1518-.12245l-.0841.0724-2.45754 2.55939a.97624.97624 0 0 1-.04353.0605l-.08318.0943c-.17115.1711-.25589.4053-.23918.6527.01196.1768.07505.3473.18433.4929l.09056.1043c.39052.3906.39052 1.0237 0 1.4143-.39053.3905-1.02369.3905-1.41422 0-.50937-.5094-.80869-1.1758-.85612-1.8766-.04674-.6922.15517-1.3741.57557-1.9226a.97658.97658 0 0 1 .07346-.1141l.08128-.09586 2.66027-2.7691c1.1207-1.12067 2.9465-1.0962 4.0784.0357Z"
  />,
  'Link'
);
