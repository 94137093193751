import React from 'react';
import { Grid } from '@mui/material';
import { logic } from 'lg-helpers';

const { getDateStringForTimezone } = logic.dateTime;

export interface ChangelogItem {
  id: string;
  action: string;
  isNative: boolean;
  manifestId: string;
  manifestNumber: string;
  occuredAt: any;
  osVersion: string;
  platform: string;
  userId: string;
  userName: string;
}

interface ChangelogProps {
  changelogs: ChangelogItem[];
  timezone: string;
}

const Changelog = ({ changelogs, timezone }: ChangelogProps) => (
  <Grid container>
    {changelogs.map((changelogItem, changelogIndex) => (
      <Grid container key={changelogIndex}>
        <Grid item xs={3}>
          {changelogItem.userName}
        </Grid>
        <Grid sx={{ overflowWrap: 'anywhere' }} item xs={6}>
          {changelogItem.action}
        </Grid>
        <Grid sx={{ paddingLeft: '5px' }} item xs={3}>
          {getDateStringForTimezone(changelogItem?.occuredAt?.toDate(), timezone, 'YYYY-MM-DD')}
        </Grid>
      </Grid>
    ))}
  </Grid>
);

export default Changelog;
