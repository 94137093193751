import React from 'react';
import { RelatedCompanies } from '../../sharedComponents/RelatedCompanies';
import { Routes, Route } from 'react-router-dom';
import { ConnectedCompanyType } from 'services/api/autogenerated';

export const BrokersViews = () => {
  return (
    <Routes>
      <Route
        path="/"
        element={
          <RelatedCompanies
            globalCompanyType={ConnectedCompanyType.Broker}
            localCompanyType={ConnectedCompanyType.TruckingBroker}
            companyTypeName={'Broker'}
            companyTypeNamePlural={'Brokers'}
            // TODO: implement other AddCompanyForm popup
          />
        }
      />
    </Routes>
  );
};
