import React from 'react';
import { DialogContent } from '@mui/material';

import AddInvoiceForm from './AddInvoiceForm';
import ModalHeader from 'sharedComponents/DialogHeader';
import { IInvoice } from 'lg-helpers/dist/shared/interfaces/IInvoice';

interface ModalContainerProps {
  selected?: IInvoice;
  setInvoice?: Function;
}

const ModalContainer = ({ selected, setInvoice }: ModalContainerProps) => {
  return (
    <>
      <ModalHeader title={'Invoice'} />
      <DialogContent>
        <AddInvoiceForm selected={selected} setInvoice={setInvoice} />
      </DialogContent>
    </>
  );
};

export default ModalContainer;
