import React, { useCallback, useEffect, useState } from 'react';
import { Grid, Button, TextField, Avatar, Box, Typography } from '@mui/material';
import { RootState, thunkDispatch } from 'store/store';
import {
  getTicketMessagesByTicketId,
  saveTicketMessage,
  removeTicketMessage,
} from '../redux/actions';
import { useSelector } from 'react-redux';
import { selectAuthUser } from 'modules/auth/storeSliceAuth';
import moment from 'moment';
import { ITicketMessage } from 'lg-helpers/dist/shared/interfaces/ITicketMessage';

interface TicketMessagesProps {
  ticketId: number;
}

const CompanyMaterialForm = ({ ticketId }: TicketMessagesProps) => {
  const [message, setMessage] = useState('');
  const [editMessageId, setEditMessageId] = useState<number>();
  const [editMessage, setEditMessage] = useState('');

  const authUser = useSelector(selectAuthUser);

  const ticketMessageState = useSelector((state: RootState) => state.civil.tickets.ticketMessages);

  const fetchMessages = useCallback(async () => {
    await thunkDispatch(getTicketMessagesByTicketId({ ticketId }));
  }, [ticketId]);

  useEffect(() => {
    fetchMessages();
  }, [fetchMessages]);

  const handleSubmit = async (type: 'new' | 'edit') => {
    const ticketMessageData: ITicketMessage = {
      civilTicketId: ticketId,
      message: type === 'edit' ? editMessage : message,
      id: type === 'edit' ? editMessageId : undefined,
    };
    const result = await thunkDispatch(saveTicketMessage(ticketMessageData));
    if (result.meta.requestStatus === 'fulfilled') {
      setEditMessageId(undefined);
      setEditMessage('');
      setMessage('');
    }
  };

  const handleDelete = async (deleteTicketId: number, ticketMessageId: number) => {
    const result = await thunkDispatch(
      removeTicketMessage({ ticketId: deleteTicketId, ticketMessageId })
    );
    if (result.meta.requestStatus === 'fulfilled') {
      setEditMessageId(undefined);
      setEditMessage('');
    }
  };

  const stringAvatar = (firstName: string, lastName: string) => {
    return `${firstName[0]}${lastName[0]}`;
  };

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} display="flex" alignItems="flex-start">
          <Avatar>{stringAvatar(authUser.firstName, authUser.lastName)}</Avatar>
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="flex-start"
            sx={{ marginLeft: 2 }}
            width="100%"
          >
            <Box>
              <TextField
                value={message}
                variant="outlined"
                multiline
                onChange={event => setMessage(event.target.value)}
                placeholder="Add a comment..."
                sx={{ mt: 0 }}
              ></TextField>
            </Box>
            {message.length > 0 && (
              <Box>
                <Button color="primary" variant="contained" onClick={event => handleSubmit('new')}>
                  Save
                </Button>
                <Button
                  onClick={() => setMessage('')}
                  sx={{ color: 'neutral.main', marginLeft: 1 }}
                >
                  Cancel
                </Button>
              </Box>
            )}
          </Box>
        </Grid>
        {ticketMessageState.length ? (
          ticketMessageState.map(tm => (
            <Grid item key={tm.id} display="flex" xs={12}>
              <Avatar>{stringAvatar(tm.firstName || '', tm.lastName || '')}</Avatar>
              <Box
                display="flex"
                flexDirection="column"
                justifyContent="flex-start"
                sx={{ marginLeft: 2 }}
                width="100%"
              >
                <Box display="flex" alignItems="center">
                  <Typography
                    sx={{
                      typography: { fontSize: 18, fontWeight: 600 },
                    }}
                  >
                    {tm.firstName} {tm.lastName}
                  </Typography>
                  <Typography
                    sx={{
                      marginLeft: 1,
                      typography: { fontSize: 16, fontWeight: 200 },
                    }}
                  >
                    {moment(tm.dateCreated).format('MM-DD-YYYY hh:mm a')}
                  </Typography>
                </Box>
                {editMessageId !== tm.id ? (
                  <Typography sx={{ marginY: 1, typography: { fontSize: 14 } }}>
                    {tm.message}
                  </Typography>
                ) : (
                  <TextField
                    fullWidth
                    multiline
                    value={editMessage}
                    onChange={event => setEditMessage(event.target.value)}
                  />
                )}
                {editMessageId === tm.id && (
                  <Box>
                    <Button
                      color="primary"
                      variant="contained"
                      onClick={event => handleSubmit('edit')}
                    >
                      Save
                    </Button>
                    <Button
                      onClick={() => {
                        setEditMessageId(undefined);
                        setEditMessage('');
                      }}
                      sx={{ color: 'neutral.main', marginLeft: 1 }}
                    >
                      Cancel
                    </Button>
                  </Box>
                )}
                {tm.createdBy === authUser.id && editMessageId !== tm.id && (
                  <Box display="flex">
                    <Typography
                      sx={{
                        typography: {
                          fontSize: 12,
                          textDecoration: 'underline',
                        },
                        '&:hover': { cursor: 'pointer' },
                      }}
                      component="a"
                      onClick={() => {
                        setEditMessage(tm.message || '');
                        setEditMessageId(tm.id!);
                      }}
                    >
                      Edit
                    </Typography>
                    <Typography
                      sx={{
                        marginLeft: 1,
                        typography: {
                          fontSize: 12,
                          textDecoration: 'underline',
                        },
                        '&:hover': { cursor: 'pointer' },
                      }}
                      component="a"
                      onClick={() => handleDelete(tm.civilTicketId!, tm.id!)}
                    >
                      Delete
                    </Typography>
                  </Box>
                )}
              </Box>
            </Grid>
          ))
        ) : (
          <Grid item xs={12} display="flex">
            <Typography sx={{ ml: 8 }}>No previous comments found.</Typography>
          </Grid>
        )}
      </Grid>
    </>
  );
};

export default CompanyMaterialForm;
