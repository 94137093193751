import { useEffect } from 'react';
import Query from 'services/firebase/Query';
import Executer from 'services/firebase/Executer';
import { Manifest } from 'lg-helpers/dist/shared/types/manifests';
import QueryBase from 'lg-helpers/dist/firestore/query/QueryBase';

const query = new Query();
const executer = new Executer();

const useWatchManifest = ({
  manifest,
  callbackOnManifestChange,
}: {
  manifest: Manifest;
  callbackOnManifestChange: (manifest: Manifest | null) => any;
}) => {
  useEffect(() => {
    if (!manifest) {
      return;
    }

    return executer.watchSingleDocument(
      query.base.getById(QueryBase.MANIFESTS_COLLECTION(), manifest.id),
      man => callbackOnManifestChange(man as Manifest),
      err => {
        console.error('Failed to watch manifest', err);
        callbackOnManifestChange(null);
      }
    );
  }, [manifest, callbackOnManifestChange]);
};

export default useWatchManifest;
