import React, { useEffect } from 'react';
import 'sass/globals.scss';
import { Container, Box, Typography } from '@mui/material';
import Page from 'sharedComponents/Page';
import { useSelector } from 'react-redux';
import { selectAuthUser } from 'modules/auth/storeSliceAuth';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import ManifestsViewPerScale from 'sharedComponents/ManifestsViewPerScale';
import { getManifestDefaultColumns } from 'utils/manifestUtil';
let manifestColumns = getManifestDefaultColumns(true, true, false);

const ProcessedManifestsView = () => {
  const authUser = useSelector(selectAuthUser);
  const navigate = useNavigate();

  useEffect(() => {
    if (!authUser) {
      return;
    }

    if (!authUser.selectedScaleId) {
      navigate('/scales/account');
    }
  }, [authUser, navigate]);

  return (
    <Page className="min-padding-and-height" title="Processed Manifests">
      <Container>
        <Box display="flex" justifyContent="center" alignItems="center" marginBottom={4}>
          <Typography variant="h3">Processed Manifests</Typography>
        </Box>
        {authUser && authUser.selectedScaleId && (
          <ManifestsViewPerScale
            showOnlyAttendantsScale={true}
            isProcessedManifests={true}
            manifestColumns={manifestColumns}
            showOnlyIncoming={false}
            authUser={authUser}
            scaleId={authUser.selectedScaleId}
            defaultFilterIsSigned={true}
            defaultFilterIsUsedBetween={[
              moment().startOf('day').toDate(),
              moment().endOf('day').toDate(),
            ]}
          />
        )}
      </Container>
    </Page>
  );
};

export default ProcessedManifestsView;
