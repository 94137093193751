import emailRegex from 'email-regex';

const hasErrors = (data: any, fieldsToCheck: string[]): boolean =>
  data && fieldsToCheck.some(field => hasErrorRequired(data[field]));

interface FormFieldErrorCheck {
  property: string;
  msg: string;
}

const checkKeysForErrors = (data: any, fieldsToCheck: FormFieldErrorCheck[]): string[] => {
  const errors: string[] = [];
  fieldsToCheck.every(field => {
    if (hasErrorRequired(data[field.property])) {
      errors.push(field.msg);
    }
  });

  return errors;
};

const hasErrorRequired = (value: any, wasSubmitted?: boolean) => {
  let result = !value || value.length === 0;
  if (wasSubmitted !== undefined) {
    result = wasSubmitted && result;
  }
  return result;
};

const hasErrorValidEmail = (email: string, wasSubmitted?: boolean): boolean => {
  let result = email && !emailRegex({ exact: true }).test(email);
  if (wasSubmitted !== undefined) {
    result = wasSubmitted && result;
  }
  return result as boolean;
};

export { hasErrors, checkKeysForErrors, hasErrorRequired, hasErrorValidEmail };
