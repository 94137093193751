import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { selectJoinedProjects } from '../../../redux/projectsStoreSlice';
import { DataGrid } from '@mui/x-data-grid';
import { LoadingOverlay as Loading } from 'sharedComponents/LoadingOverlay';
import { generateProjectsColumns } from './projectsColumns';

type ProjectListProps = {
  filterLowerCase: string;
};

export const ProjectsList = ({ filterLowerCase }: ProjectListProps) => {
  const { joinedProjects, joinedProjectsStatus } = useSelector(selectJoinedProjects);

  const columns = useMemo(() => generateProjectsColumns(), []);

  if (joinedProjectsStatus === 'pending') {
    return <Loading />;
  }

  return (
    <DataGrid
      columns={columns}
      rows={
        joinedProjects.filter(
          project =>
            (project.projectNumberTruckingProject || '').toLowerCase().includes(filterLowerCase) ||
            project.nameTruckingProject.toLowerCase().includes(filterLowerCase) ||
            project.addressString?.toLowerCase().includes(filterLowerCase) ||
            project.name?.toLowerCase().includes(filterLowerCase)
        ) || []
      }
      getRowId={row => row.idTruckingProject}
      showColumnRightBorder={false}
      disableColumnMenu
      disableSelectionOnClick
      autoHeight
      components={{
        ColumnResizeIcon: () => <div></div>,
      }}
    />
  );
};
