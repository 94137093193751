import { CivilApiProvider, SaveProjectApiMapping } from 'services/api/autogenerated-code';
import { object, string, mixed, number, boolean } from 'yup';

export const apiMappingSchema = object<SaveProjectApiMapping>({
  name: string().required('Name is required'),
  api: mixed<CivilApiProvider>()
    .oneOf(Object.values(CivilApiProvider), 'API type is not valid')
    .required('API type is required'),
  api_id: string().required('Id from API is required'),
  project_id: number(),
  default_source_supplier_as_transporter: boolean(),
  id: number(),
});
