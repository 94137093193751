import React, { useEffect, useCallback } from 'react';
import { Container, Grid, Box } from '@mui/material';
import Page from 'sharedComponents/Page';
import DispatchesTable from './DispatchesTable';
import { useNavigate } from 'react-router-dom';
import useIsUserOfType from 'services/hooks/useIsUserOfType';
import useDateRangeFilter from 'services/hooks/useDateRangeFilter';
import { DateRangePicker } from 'rsuite';
import { setDateRangeFilter as setDateRangeFilterStore } from 'modules/brokers/storeSliceBrokers';
import { useDispatch } from 'react-redux';

const DispatchesView = () => {
  const dispatch = useDispatch();
  const { dateRangeFilter, onDateRangeFilterChange } = useDateRangeFilter();
  const navigate = useNavigate();
  const isUserBrokerAdmin = useIsUserOfType('trucking_dispatcher');

  const goToLoginPage = useCallback(() => {
    navigate('/login');
  }, [navigate]);

  useEffect(() => {
    dispatch(setDateRangeFilterStore(dateRangeFilter));
  }, [dateRangeFilter, dispatch]);

  useEffect(() => {
    if (!isUserBrokerAdmin) {
      goToLoginPage();
    }
  }, [isUserBrokerAdmin, goToLoginPage]);

  return (
    <Page title="Dispatches">
      <Container>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Box marginLeft={1}>
              <DateRangePicker
                appearance="default"
                placeholder="Show between"
                placement="bottomStart"
                size="lg"
                format="MM/DD/YYYY"
                value={dateRangeFilter || []}
                onChange={onDateRangeFilterChange}
                style={{ width: 260 }}
              />
            </Box>
          </Grid>
          <Grid item xs={12}>
            {dateRangeFilter && <DispatchesTable dateRangeFilter={dateRangeFilter} />}
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
};

export default DispatchesView;
