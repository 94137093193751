import React, { useState, useCallback, useEffect, useMemo } from 'react';
import IBatchManifests from 'lg-helpers/dist/shared/interfaces/IBatchManifests';
import useShowError from 'modules/errors';
import {
  Autocomplete,
  Button,
  Grid,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
} from '@mui/material';
import CommentIcon from '@mui/icons-material/Comment';
import ButtonWithLoading from 'sharedComponents/ButtonWithLoading';
import { useHasPerms, usePermissionsContext } from 'services/permissions';
import Notes from './Notes';

import Query from 'services/firebase/Query';
import Command from 'services/firebase/Command';
import Executer from 'services/firebase/Executer';

const query = new Query();
const executer = new Executer();
const command = new Command();

interface IManifestFilters {
  projectId: string;
  notes: string;
}

// This is purposely declared outside lg-helpers because it will only be used here
const tempUnitsOfMeasurement = ['Lbs', 'Tons'];

export default function EditBatchManifestsManifestsTable({
  batch,
  onManifestsChange,
}: {
  batch: IBatchManifests;
  onManifestsChange?: (manifests: any[]) => any; // bad practice :( (but for optimization)
}) {
  const [isLoadingNewManifest, setIsLoadingNewManifest] = useState(false);
  const [isLoadingManifests, setIsLoadingManifests] = useState(false);
  const [isLoadingProjects, setIsLoadingProjects] = useState(false);

  const [isAddingNewManifest, setIsAddingNewManifest] = useState(false);
  const [isRemovingManifest, setIsRemovingManifest] = useState(false);

  const [connectedManifests, setConnectedManifests] = useState<any[]>([]);
  const [projects, setProjects] = useState<any[]>([]);
  const [newManifestFilters, setNewManifestFilters] = useState<IManifestFilters | null>(null);
  const [manifestWeight, setManifestWeight] = useState('');
  const [manifestWeightUnit, setManifestWeightUnit] = useState(tempUnitsOfMeasurement[0]);

  const [isNotesOpen, setIsNotesOpen] = useState(false);

  const [newManifest, setNewManifest] = useState<any>(null);
  const { user } = usePermissionsContext();

  const canEditBatch = useHasPerms(['batched_manifests:edit']);
  const showError = useShowError();
  const selectedProject = useMemo(
    () =>
      (newManifestFilters ? projects.find(p => p.id === newManifestFilters.projectId) : null) ||
      null,
    // eslint-disable-next-line
    [newManifestFilters?.projectId]
  );

  const isLoading =
    isLoadingManifests || isLoadingProjects || isAddingNewManifest || isRemovingManifest;

  const loadConnectedManifests = async () => {
    setIsLoadingManifests(true);
    try {
      const manifests = await executer.getMultipleDocuments(
        query.manifests.getManifestsInBatch(batch.id)
      );
      setConnectedManifests(manifests);
    } catch (err) {
      console.error(err);
      showError({
        title: 'Failed to load manifests for batch',
        duration: 10000,
      });
    } finally {
      setIsLoadingManifests(false);
    }
  };

  const handleAddNewManifest = useCallback(async () => {
    if (!newManifest?.id) return;
    try {
      setIsAddingNewManifest(true);
      await command.manifests.addManifestToBatch(
        batch.id,
        newManifest.id,
        newManifestFilters?.notes || '',
        user,
        manifestWeight ? `${manifestWeight} ${manifestWeightUnit}` : ''
      );
    } catch (err) {
      console.error(err);
      showError({
        title: 'Failed to add new manifest to batch',
        duration: 10000,
      });
    } finally {
      setNewManifest(null);
      setNewManifestFilters(null);
      setIsAddingNewManifest(false);
      setManifestWeight('');
    }
    await loadConnectedManifests();
    // eslint-disable-next-line
  }, [newManifest, manifestWeight, manifestWeightUnit, newManifestFilters?.notes, user]);

  const handleRemoveManifest = async (manifestId: string) => {
    try {
      setIsRemovingManifest(true);
      await command.manifests.dropManifestFromBatch(batch.id, manifestId);
    } catch (err) {
      console.error(err);
      showError({
        title: 'Failed to remove manifest from batch',
        duration: 10000,
      });
    }
    setIsRemovingManifest(false);
    await loadConnectedManifests();
  };

  useEffect(() => {
    loadConnectedManifests();
    // eslint-disable-next-line
  }, [batch.id]);

  useEffect(() => {
    if (!batch.transporterCompanyId) return;

    const load = async () => {
      setIsLoadingProjects(true);
      try {
        // Unfortunately we can't filter by transporter and scale at the same time (firebase)
        const data = await executer.getMultipleDocuments(
          query.projects.getProjectsByTransporter(batch.transporterCompanyId)
        );
        const filtered = data.filter(p => p.scalesIds && p.scalesIds.indexOf(batch.scaleId) >= 0);
        setProjects(filtered);
      } catch (err) {
        console.error(err);
        showError({
          title: 'Failed to load projects for batch',
          duration: 10000,
        });
      } finally {
        setIsLoadingProjects(false);
      }
    };

    load();
    // eslint-disable-next-line
  }, [batch.transporterCompanyId]);

  useEffect(() => {
    if (!newManifestFilters?.projectId) {
      setNewManifest(null);
      return;
    }

    const load = async () => {
      try {
        setIsLoadingNewManifest(true);
        const manifest = await executer.getSingleDocument(
          query.manifests.getNextUnsignedManifestForDriver(newManifestFilters?.projectId)
        );
        setNewManifest(manifest);
      } catch (err) {
        console.error(err);
        showError({
          title: 'Failed to load manifest by project',
          duration: 10000,
        });
      } finally {
        setIsLoadingNewManifest(false);
      }
    };

    load();
    // eslint-disable-next-line
  }, [newManifestFilters?.projectId]);

  useEffect(() => {
    if (onManifestsChange) onManifestsChange(connectedManifests);
    // eslint-disable-next-line
  }, [connectedManifests]);

  if (isLoading) return <p>Loading...</p>;

  return (
    <Table>
      <Notes
        manifest={newManifest}
        isNotesOpen={isNotesOpen}
        handleCloseNotes={() => setIsNotesOpen(false)}
      />

      <TableHead>
        <TableRow>
          <TableCell style={{ width: '35%' }}>Profile #</TableCell>
          <TableCell>Manifest weight</TableCell>
          <TableCell>Manifest #</TableCell>
          <TableCell style={{ width: '35%' }}></TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {connectedManifests.map(manifest => (
          <ManifestRow
            manifest={manifest}
            key={manifest.id}
            onRemove={() => handleRemoveManifest(manifest.id)}
            canEdit={canEditBatch && batch.countDriverSigned < 1}
          />
        ))}

        {canEditBatch && newManifestFilters !== null && (
          <TableRow>
            <TableCell>
              <Autocomplete
                fullWidth
                disabled={!!newManifestFilters.projectId}
                options={projects}
                size="small"
                value={selectedProject}
                getOptionLabel={p => `${p.profileNr} | ${p.name}`}
                onChange={(event, value) =>
                  setNewManifestFilters({ ...newManifestFilters, projectId: value.id })
                }
                renderInput={params => <TextField {...params} hiddenLabel />}
              />
            </TableCell>
            <TableCell>
              <Grid container>
                <Grid item sm={5} style={{ marginRight: 10 }}>
                  <TextField
                    hiddenLabel
                    variant="filled"
                    disabled={!selectedProject}
                    value={manifestWeight}
                    onChange={e => setManifestWeight(e.target.value)}
                    size="small"
                  />
                </Grid>

                <Grid item sm={6}>
                  <>
                    <Select
                      style={{ marginTop: 16, width: 80, height: 40 }}
                      value={manifestWeightUnit}
                      disabled={!selectedProject}
                      onChange={e => setManifestWeightUnit(e.target.value)}
                    >
                      {tempUnitsOfMeasurement.map(unit => (
                        <MenuItem key={unit} value={unit}>
                          {unit}
                        </MenuItem>
                      ))}
                    </Select>
                  </>
                </Grid>
              </Grid>
            </TableCell>
            <TableCell>
              {isLoadingNewManifest ? 'Loading...' : newManifest?.number || '[No manifests]'}
            </TableCell>
            <TableCell>
              <ButtonWithLoading
                Pictogram={CommentIcon}
                disabled={newManifest?.id === undefined}
                onClickCallback={() => setIsNotesOpen(true)}
                color="secondary"
                text={`${newManifest?.notesCount || 0} Notes`}
              />
              <Button
                disabled={!newManifestFilters.projectId || !newManifest}
                onClick={handleAddNewManifest}
              >
                Save
              </Button>
              <Button
                color="error"
                onClick={() => {
                  setNewManifestFilters(null);
                  setManifestWeight('');
                }}
              >
                Remove
              </Button>
            </TableCell>
          </TableRow>
        )}

        {canEditBatch && newManifestFilters === null && connectedManifests.length < 30 && (
          <TableRow>
            <TableCell colSpan={4}>
              <Button
                onClick={() =>
                  setNewManifestFilters({
                    projectId: '',
                    notes: '',
                  })
                }
              >
                Add manifest
              </Button>
            </TableCell>
          </TableRow>
        )}
      </TableBody>
    </Table>
  );
}

const ManifestRow = ({
  manifest,
  onRemove,
  canEdit,
}: {
  manifest: any;
  onRemove: () => any;
  canEdit?: boolean;
}) => {
  const [isNotesOpen, setIsNotesOpen] = useState(false);
  return (
    <TableRow>
      <TableCell>{manifest.project.profileNr}</TableCell>
      <TableCell>{manifest.weight}</TableCell>
      <TableCell>{manifest.number}</TableCell>
      <TableCell>
        {canEdit && (
          <ButtonWithLoading
            Pictogram={CommentIcon}
            onClickCallback={() => setIsNotesOpen(true)}
            color="secondary"
            text={`${manifest.notesCount} Notes`}
          />
        )}
        {canEdit && (
          <Button color="error" onClick={onRemove}>
            Remove
          </Button>
        )}
      </TableCell>

      <Notes
        manifest={manifest}
        isNotesOpen={isNotesOpen}
        handleCloseNotes={() => setIsNotesOpen(false)}
      />
    </TableRow>
  );
};
