import React, { useState, useEffect } from 'react';
import {
  Grid,
  Box,
  Button,
  Card,
  CardHeader,
  CardContent,
  List,
  ListItem,
  Divider,
  TextField,
  IconButton,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import PropTypes from 'prop-types';
import { v4 as uuidv4 } from 'uuid';
import { hasErrorRequired } from 'services/logic/formValidation';
import {
  getComparator,
  stableSort,
  reduceToNameIdCommaSeparatedHtmlString,
} from 'services/logic/array';
import ErrorMessages from 'services/constants/errorMessages';
const { isRequired } = ErrorMessages;

const CrudListIdName = ({ title, list, onListUpdate }) => {
  const [wasSubmitted, setWasSubmitted] = useState(false);
  const [isDisabledAdd, setIsDisabledAdd] = useState(false);
  const [focusedItem, setFocusedItem] = useState(null);

  useEffect(() => {
    if (!list) {
      return;
    }
    const orderedList = stableSort(list, getComparator('asc', 'name', true));
    if (
      reduceToNameIdCommaSeparatedHtmlString(orderedList) !==
      reduceToNameIdCommaSeparatedHtmlString(list)
    ) {
      onListUpdate(orderedList);
    }
  }, [list, onListUpdate]);

  const addItem = () => {
    const emptyItem = list.find(region => !region.name);
    if (!emptyItem) {
      onListUpdate([
        {
          id: uuidv4(),
          name: '',
        },
        ...list,
      ]);
      setWasSubmitted(false);
      setIsDisabledAdd(true);
    } else {
      setIsDisabledAdd(true);
    }
  };

  const removeItem = removedItem => {
    onListUpdate(list.filter(item => item.id !== removedItem.id));
    setWasSubmitted(true);
    setFocusedItem(null);

    if (!removedItem.name) {
      setIsDisabledAdd(false);
    }
  };

  const onItemBlur = () => {
    onListUpdate(
      list.map(item => {
        if (item.id !== focusedItem.id) {
          return item;
        } else {
          return focusedItem;
        }
      })
    );
    setWasSubmitted(true);
    setFocusedItem(null);
  };

  const onItemChange = e => {
    const name = e.target.value;
    setFocusedItem({
      ...focusedItem,
      name,
    });

    if (name) {
      setIsDisabledAdd(false);
    }
  };

  return (
    <Card>
      <CardHeader
        title={title}
        action={
          <Button color="primary" variant="outlined" onClick={addItem} disabled={isDisabledAdd}>
            Add
          </Button>
        }
      />
      <Divider />
      <CardContent>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Box>
              <List>
                {list.map(item => (
                  <ListItem key={item.id}>
                    <TextField
                      variant="outlined"
                      required
                      fullWidth
                      placeholder="Name"
                      value={
                        (focusedItem && focusedItem.id === item.id
                          ? focusedItem.name
                          : item.name) || ''
                      }
                      onFocus={() => setFocusedItem(item)}
                      onChange={onItemChange}
                      onBlur={onItemBlur}
                      error={hasErrorRequired(item.name, wasSubmitted)}
                      helperText={hasErrorRequired(item.name, wasSubmitted) ? isRequired : ''}
                      InputProps={{
                        endAdornment: (
                          <>
                            {
                              <IconButton onClick={() => removeItem(item)}>
                                <CloseIcon style={{ cursor: 'pointer' }} color="inherit" />
                              </IconButton>
                            }{' '}
                          </>
                        ),
                      }}
                    />
                  </ListItem>
                ))}
              </List>
            </Box>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

CrudListIdName.propTypes = {
  title: PropTypes.string.isRequired,
  list: PropTypes.array.isRequired,
  onListUpdate: PropTypes.func.isRequired,
};

export default CrudListIdName;
