import React, {
  useEffect,
  useState,
  useContext,
  forwardRef,
  useImperativeHandle,
  createRef,
} from 'react';
import { DataGrid, GridCellParams, MuiEvent, useGridApiContext } from '@mui/x-data-grid';
import { useSelector } from 'react-redux';
import useShowError from 'modules/errors';
import { IJoinTruckingDispatch } from 'lg-helpers/dist/trucking/interfaces/IJoinTruckingDispatch';
import { ITruckingTicket } from 'lg-helpers/dist/trucking/interfaces/ITruckingTicket';
import { selectTickets } from 'modules/civilAndTrucking/trucking/features/Tickets/redux/ticketsStoreSlice';
import { selectImportedTKMSDataFromFTP } from 'modules/civilAndTrucking/trucking/features/DataImport/redux/dataImportStoreSlice';
import { selectJoinedDispatches } from '../../../../Dispatches/redux/dispatchStoreSlice';
import { LoadingOverlay as Loading } from 'sharedComponents/LoadingOverlay';
import moment from 'moment';
import { ModalContext } from 'sharedComponents/ModalContext';
import { EditTicketForm } from './EditTicketForm';
import { thunkDispatch } from 'store/store';
import { updateTicket } from 'modules/civilAndTrucking/trucking/features/Tickets/redux/actions';
import { downloadTicketId } from 'modules/civilAndTrucking/trucking/api/download';
import { generateTicketsColumns } from './ticketColumns';
import { useNavigate } from 'react-router-dom';
import { useHasAccess } from 'services/permissions';

interface TicketsListProps {
  openUpsertDispatchModal: (joinedDispatch: IJoinTruckingDispatch) => void;
  openTicketNotesModal: (ticketId: number) => void;
  searchFilter: string;
}

export interface TicketsListRef {
  handleExport(): void;
}

type TicketAndDispatch = ITruckingTicket & IJoinTruckingDispatch;

// note: this should PRODUCE a JoinTruckingDispatch.  Joining an already joined TruckingDsipatch back against a ticket
// causes strange issues with property overwites, hence reordering of the return.
export const mergeTicketsAndDispatches = (
  tickets: ITruckingTicket[],
  dispatches: IJoinTruckingDispatch[]
) =>
  tickets.map(ticket => {
    let ticketJoinedDispatch = Object.assign(
      {},
      dispatches.find(dispatch => dispatch.idTruckingDispatch === ticket.dispatchIdTruckingTicket)
    );

    return {
      ...ticketJoinedDispatch,
      ...ticket,
    };
  });

interface CustomColumnResizeIconProps {}

interface CustomColumnResizeIconRef {
  handleExport(): void;
}

const CustomColumnResizeIcon = forwardRef<CustomColumnResizeIconRef, CustomColumnResizeIconProps>(
  (props, ref) => {
    const apiRef = useGridApiContext();
    const handleExport = () => {
      apiRef.current.exportDataAsCsv({
        allColumns: true,
        getRowsToExport: () => apiRef.current.getAllRowIds(),
        fileName: `Tickets${moment().format('MM-DD-YYYY hh:mm a')}`,
      });
    };

    useImperativeHandle(ref, () => ({ handleExport }));

    return <div />;
  }
);
CustomColumnResizeIcon.displayName = 'CustomColumnResizeIcon';

const filterRowsBySearch = (rows: TicketAndDispatch[], searchFilter: string) => {
  if (!searchFilter) {
    return rows;
  }

  const lowercaseFilter = searchFilter.toLowerCase();

  const filteredRows = rows.filter(
    ticket =>
      String(ticket?.ticketNumberTruckingTicket)?.includes(searchFilter) ||
      ticket?.manualProjectNameTruckingDispatch?.toLowerCase()?.includes(lowercaseFilter) ||
      ticket?.nameTruckingProject?.toLowerCase()?.includes(lowercaseFilter) ||
      String(ticket?.pitTicketNumberTruckingTicket)?.includes(lowercaseFilter) ||
      String(ticket?.manifestNumberTruckingTicket)?.includes(lowercaseFilter)
  );
  return filteredRows;
};

export const TicketsList = forwardRef<TicketsListRef, TicketsListProps>(
  ({ openUpsertDispatchModal, openTicketNotesModal, searchFilter }, ref) => {
    const [ticketsAndDispatches, setTicketsAndDispatches] = useState([] as any[]);
    const { handleModal } = useContext(ModalContext);
    const showError = useShowError();
    const { tickets, ticketsStatus } = useSelector(selectTickets);
    const { joinedDispatches, joinedDispatchesStatus } = useSelector(selectJoinedDispatches);
    const { importedTKMSDataFromFTPStatus } = useSelector(selectImportedTKMSDataFromFTP);
    const columnResizeIconRef = createRef<CustomColumnResizeIconRef>();
    const navigate = useNavigate();
    const { hasAccess: hasTruckingAllAccess } = useHasAccess([
      'trucking_tickets:all',
      'trucking_projects:all',
    ]);
    const { hasAccess: hasTruckingReadAccess } = useHasAccess([
      'trucking_tickets:read',
      'trucking_projects:read',
    ]);

    useEffect(() => {
      const ticketsWithJoinedDispatches = mergeTicketsAndDispatches(tickets, joinedDispatches);

      setTicketsAndDispatches(ticketsWithJoinedDispatches);
    }, [tickets, joinedDispatches]);

    const handleExport = () => {
      columnResizeIconRef.current?.handleExport();
    };

    useImperativeHandle(ref, () => ({ handleExport }));

    const handleEditTicket = (ticketAndDispatch: TicketAndDispatch) => {
      const ticketToEdit = tickets.find(
        ticket => ticket.idTruckingTicket === ticketAndDispatch.idTruckingTicket
      );
      if (!ticketToEdit) return;
      handleModal(<EditTicketForm editObj={ticketToEdit} joinedDispatches={joinedDispatches} />);
    };

    const handleDeleteTicket = async (ticketId: number) => {
      const ticketToUpdate = tickets.find(ticket => ticket.idTruckingTicket === ticketId);
      if (!ticketToUpdate) return;
      const updated = { ...ticketToUpdate, deletedTruckingTicket: true };
      await thunkDispatch(updateTicket(updated));
    };

    const handlePrintTicket = async (ticketId: number) => {
      let w;
      try {
        w = window.open('', '_blank');
        w!.document.write(`<!DOCTYPE html>
        <html>
          <head>
            <title>Title of the document</title>
            <style>
              body {
                margin-top: 100px;
                background-color: #41793a;
                color: #fff;
                text-align: center;
              }
              h1 {
                font: 1.5em 'Roboto', sans-serif;
                margin-bottom: 30px;
              }
              .spin {
                display: inline-block;
                width: 50px;
                height: 50px;
                border: 3px solid rgba(255, 255, 255, .3);
                border-radius: 50%;
                border-top-color: #fff;
                animation: spin 1s ease-in-out infinite;
                -webkit-animation: spin 1s ease-in-out infinite;
              }
              @keyframes spin {
                to {
                  -webkit-transform: rotate(360deg);
                }
              }
              @-webkit-keyframes spin {
                to {
                  -webkit-transform: rotate(360deg);
                }
              }
            </style>
          </head>
          <body>
            <h1>Please wait and do not close this tab!</h1>
            <h1>Trucking ticket download is in progress...</h1>
            <div class="spin"></div>
          </body>
        </html>`);
        const url = await downloadTicketId(ticketId);
        w!.document.close();
        w!.location = url;
      } catch (err: any) {
        console.error(err);
        if (w) w.close();
        showError({
          title: 'Download ticket failed',
          text: err.message,
          duration: 10000,
        });
      }
    };

    const generateMenuOptions = (ticketAndDispatch: TicketAndDispatch) => [
      {
        title: 'Print Ticket',
        description: 'Print a copy of this ticket',
        onClick: () => handlePrintTicket(ticketAndDispatch.idTruckingTicket!),
        hide: !hasTruckingReadAccess,
      },
      {
        title: 'Edit Ticket',
        description: `Edit this ticket`,
        onClick: () => handleEditTicket(ticketAndDispatch),
        hide: !hasTruckingAllAccess,
      },
      {
        title: 'Edit Ticket Notes',
        description: `Edit this tickets' notes and images`,
        onClick: () => openTicketNotesModal(ticketAndDispatch.idTruckingTicket!),
        hide: !hasTruckingReadAccess,
      },
      {
        title: 'Edit Dispatch',
        description: `Edit this dispatch`,
        onClick: () => openUpsertDispatchModal(ticketAndDispatch),
        hide: !hasTruckingAllAccess,
      },
      {
        title: 'View Map',
        description: `Map of the drivers' path to the site`,
        onClick: () => navigate(`/trucking/tickets/${ticketAndDispatch?.idTruckingTicket}/map`),
        hide: !hasTruckingAllAccess,
      },
      {
        divider: true,
        hide: !hasTruckingAllAccess,
      },
      {
        title: 'Delete Ticket',
        color: '#EF0048',
        onClick: () => handleDeleteTicket(ticketAndDispatch.idTruckingTicket!),
        hide: !hasTruckingAllAccess,
      },
    ];

    const updateDescription = (dispatch: IJoinTruckingDispatch, description: string) => {
      const ticketToUpdate = tickets.find(
        ticket => ticket.idTruckingTicket === dispatch.idTruckingTicket
      );

      if (!ticketToUpdate) return;

      const updated = {
        ...ticketToUpdate,
        descriptionTruckingTicket: description,
      };
      thunkDispatch(updateTicket(updated));
    };

    // This's needed for entering a space in the input of the datagrid's cell
    const handleCellKeyDown = (
      params: GridCellParams,
      event: MuiEvent<React.KeyboardEvent<HTMLElement>>
    ) => {
      event.stopPropagation();
    };

    const columns = generateTicketsColumns(generateMenuOptions, updateDescription);

    if (
      ticketsStatus === 'pending' ||
      joinedDispatchesStatus === 'pending' ||
      importedTKMSDataFromFTPStatus === 'pending'
    ) {
      return <Loading />;
    }

    return (
      <DataGrid
        columns={columns}
        rows={filterRowsBySearch(ticketsAndDispatches, searchFilter) || []}
        getRowId={row => row.idTruckingTicket}
        showColumnRightBorder={false}
        disableColumnMenu
        disableSelectionOnClick
        autoHeight
        components={{
          ColumnResizeIcon: () => <CustomColumnResizeIcon ref={columnResizeIconRef} />,
        }}
        onCellKeyDown={handleCellKeyDown}
      />
    );
  }
);
TicketsList.displayName = 'TicketsList';
