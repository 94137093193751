import React, { useState, useContext } from 'react';
import { thunkDispatch } from 'store/store';
import { Box, Grid, DialogContent, AutocompleteRenderInputParams, TextField } from '@mui/material';
import ModalHeader from 'sharedComponents/ModalHeader';
import DialogActionContainer from 'sharedComponents/DialogActionContainer';
import { IHelpers } from 'lg-helpers/dist/trucking/interfaces/IHelpers';
import { ModalContext } from 'sharedComponents/ModalContext';
import InputAddressAutocomplete, {
  IGeocoderFeatureAdvanced,
} from 'sharedComponents/InputAddressAutocomplete';
import { FormTextField } from 'sharedComponents/FormTextField';
import { FormCustomField } from 'sharedComponents/FormCustomField';
import { getQueriedCompaniesByCompanyTypes } from 'modules/civilAndTrucking/trucking/features/Companies/redux/actions';
import { MapTruckingCompanyToCompanyType } from 'services/constants/companyTypes';
import api, {
  ConnectedCompanyType,
  CompanyConnectionWithCompany,
} from 'services/api/autogenerated';
import { usePermissionsContext } from 'services/permissions';
import useShowError from 'modules/errors';

type AddCompanyWithoutUserFormProps = {
  initialValues?: Omit<Partial<CompanyConnectionWithCompany>, 'connected_company'> & {
    connected_company: Partial<CompanyConnectionWithCompany['connected_company']>;
  };
  companyType: ConnectedCompanyType;
  onAfterSave?: (source: CompanyConnectionWithCompany) => any;
};

export const AddCompanyWithoutUserForm = ({
  initialValues,
  companyType,
  onAfterSave,
}: AddCompanyWithoutUserFormProps) => {
  const [customId, setCustomId] = useState(initialValues?.custom_id || '');
  const { user } = usePermissionsContext();
  const [company, setCompany] = useState(
    (initialValues?.connected_company || {}) as Partial<
      CompanyConnectionWithCompany['connected_company']
    >
  );
  const [helpers, setHelpers] = useState({} as IHelpers);
  const [isProcessing, setIsProcessing] = useState(false);
  const { handleModal } = useContext(ModalContext);
  const isEdit = !!initialValues?.id;
  const showError = useShowError();

  const handleCompanyChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (Object.keys(helpers).length) {
      setHelpers({});
    }
    setCompany({ ...company, [e.target.name]: e.target.value });
  };

  const checkCompanyExistanceByNameAndType = async () => {
    const res = await thunkDispatch(
      getQueriedCompaniesByCompanyTypes({
        companyTypes: [MapTruckingCompanyToCompanyType(companyType)],
        query: company.name,
        isStrict: true,
      })
    );

    if (res.payload && (res.payload as any[]).length > 0) {
      setHelpers({
        name: 'The company name already exists, please add existing company or use a different name.',
      });
      return true;
    }

    return false;
  };

  const onSubmit = async () => {
    if (!company.name) {
      setHelpers({
        name: 'Name is required',
      });
      return;
    }

    if (isEdit) {
      // we don't need to validate name here
    } else {
      const existingCompany = await checkCompanyExistanceByNameAndType();
      if (existingCompany) {
        setIsProcessing(false);
        return;
      }
    }

    try {
      setIsProcessing(true);

      let result: CompanyConnectionWithCompany;
      if (isEdit)
        result = (
          await api.companiesConnections.updateCompanyConnection({
            custom_id: customId,
            id: initialValues.id!,
          })
        ).data;
      else
        result = (
          await api.companiesConnections.createNewConnectedCompany({
            custom_id: customId,
            company_type: companyType,
            parent_company_id: user.companiesIds[0],
            new_company: { ...company, types: [companyType] },
          })
        ).data;

      setIsProcessing(false);
      if (onAfterSave) onAfterSave(result);
      handleModal();
    } catch (err) {
      console.error('Error submitting company', err);
      showError({
        title: 'Error submitting company',
      });
    }
  };

  const handleAddressSelected = async (geocoderValue: IGeocoderFeatureAdvanced) => {
    if (company.address !== geocoderValue.place_name) {
      const { address, text, city, state, postalCode, place_name } = geocoderValue;
      setCompany({
        ...company,
        address: place_name,
        address_one: [address, text].filter(e => e).join(' '),
        city,
        state,
        postal_code: postalCode,
      });
    }
  };

  return (
    <Box sx={{ p: '0px 16px' }}>
      <ModalHeader title={isEdit ? `Edit Company` : `Add Company`} />
      <DialogContent>
        <Grid container columnSpacing={2}>
          <Grid item xs={12}>
            <FormTextField
              required
              name="name"
              disabled={isEdit}
              label="Company name"
              helperField={helpers.name}
              value={company?.name}
              onChange={handleCompanyChange}
            />
          </Grid>
          {!isEdit && (
            <Grid item xs={12}>
              <FormCustomField
                name="address"
                label="Location"
                required
                component={
                  <InputAddressAutocomplete
                    searchRequirement={5}
                    size="small"
                    options={[]}
                    handleSelect={handleAddressSelected}
                    inputValue={company?.address || ''}
                    renderInput={(params: AutocompleteRenderInputParams) => (
                      <TextField
                        {...params}
                        name="address"
                        error={!!helpers.address}
                        helperText={helpers.address}
                        onChange={handleCompanyChange}
                        variant="outlined"
                        value={company?.address || ''}
                      />
                    )}
                  />
                }
              />
            </Grid>
          )}
          <Grid item xs={12}>
            <FormTextField
              name="customId"
              label="Custom ID"
              helperField={helpers.customIdTruckingCompanyCompanies}
              value={customId}
              onChange={e => setCustomId(e.target.value)}
            />
          </Grid>
        </Grid>
        <DialogActionContainer saveHandler={onSubmit} isSaving={isProcessing} />
      </DialogContent>
    </Box>
  );
};
