import { useNavigate } from 'react-router-dom';
import useWatchManifest from './useWatchManifest';
import useGetDriverCurrentManifest from './useGetDriverCurrentManifest';
import useIsUserOfType from 'services/hooks/useIsUserOfType';

const useWatchManifestIfSignedByScaleReleaseDriver = () => {
  const navigate = useNavigate();
  const isUserTypeCorrect = useIsUserOfType('driver', 'trucking_driver', 'trucking_dispatcher');
  const { currentManifest } = useGetDriverCurrentManifest();
  useWatchManifest({
    manifest: currentManifest,
    callbackOnManifestChange: manifest => {
      if (!isUserTypeCorrect || !manifest) {
        return;
      }

      if (
        (manifest.signatureScale && manifest.signatureScale.isSigned) ||
        manifest.signatureDriver?.isReleasedManually
      ) {
        navigate('/drivers/go');
        return;
      }

      if (
        currentManifest?.signatureScale?.isRejected &&
        !currentManifest?.signatureDriver?.isAcceptedRejection
      ) {
        navigate('/drivers/rejected');
      }
    },
  });
};

export default useWatchManifestIfSignedByScaleReleaseDriver;
