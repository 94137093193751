import React from 'react';
import { createSvgIcon } from '@mui/material';

export const GPS = createSvgIcon(
  <path
    fillRule="evenodd"
    clipRule="evenodd"
    d="M0 11C0 4.92487 4.92487 0 11 0c6.0751 0 11 4.92487 11 11 0 6.0751-4.9249 11-11 11-6.07513 0-11-4.9249-11-11Zm11-9c-4.97057 0-9 4.02943-9 9 0 4.9706 4.02943 9 9 9 4.9706 0 9-4.0294 9-9 0-4.97057-4.0294-9-9-9Zm0 2.5c.4304 0 .8126.27543.9487.68377l3.5 10.50003c.137.4109-.0062.8633-.3546 1.1206-.3484.2573-.8229.2611-1.1753.0093L11 14.7289l-2.91876 2.0848c-.35246.2518-.82693.248-1.17534-.0093s-.49155-.7097-.35458-1.1206L10.0513 5.18377C10.1874 4.77543 10.5696 4.5 11 4.5Zm-1.57886 8.8989.99766-.7126c.3477-.2484.8147-.2484 1.1624 0l.9977.7126L11 8.66228 9.42114 13.3989Z"
  />,
  'GPS'
);
