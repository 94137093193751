import React, { SetStateAction, useEffect, useState } from 'react';
import 'sass/globals.scss';
import { Container, Grid, Typography, Box } from '@mui/material';
import Page from 'sharedComponents/Page';
import Lane from './Lane';
import QCScaleScreen from './QCScaleScreen';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { selectAuthUser } from 'modules/auth/storeSliceAuth';
import { setScale, selectScale } from 'modules/scales/storeSliceScales';
import IncomingManifestsView from './IncomingManifestsView';
import useShowError from 'modules/errors';
import Query from 'services/firebase/Query';
import Executer from 'services/firebase/Executer';
import QueryBase from 'lg-helpers/dist/firestore/query/QueryBase';

const query = new Query();
const executer = new Executer();

const LanesView = () => {
  const authUser = useSelector(selectAuthUser);
  const scale = useSelector(selectScale);
  const showError = useShowError();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    if (!authUser) {
      return;
    }

    if (!authUser.selectedScaleId) {
      navigate('/scales/account');
      return;
    }

    const getScaleById = async scaleId => {
      try {
        // was watch before (not get)
        const scaleResult = await executer.getSingleDocument(
          query.base.getById(QueryBase.SCALES_COLLECTION(), scaleId)
        );
        dispatch(setScale(scaleResult));
      } catch (error) {
        console.error('Error', error);
        showError({
          title: 'Error while fetching the scale',
          duration: 10000,
        });
      }
    };
    getScaleById(authUser.selectedScaleId);
  }, [authUser, dispatch, navigate, showError]);

  if (!scale) {
    return null;
  }

  return (
    <Page className="min-padding-and-height" title="Lanes">
      <Container maxWidth={false}>
        <Grid container spacing={2} justifyContent="center">
          {!scale?.lanes?.length && (
            <Grid item sm={12}>
              <Box display="flex" justifyContent="center" alignItems="center">
                <Typography variant="h2" color="error">
                  No lanes defined for scale {scale.name}
                </Typography>
              </Box>
            </Grid>
          )}
          {scale?.lanes &&
            scale?.lanes
              .filter(lane => lane.type === 'lane')
              .map(lane => (
                <Grid
                  key={lane.id}
                  item
                  sm={3}
                  xs={12}
                  data-ids={`id-${lane.id}__label-${lane.label}`}
                >
                  <Lane lane={lane} scaleId={scale.id} />
                </Grid>
              ))}
          <IncomingManifestsView />
          {authUser && scale.companyId && <QCScaleScreen scaleId={authUser.selectedScaleId} />}
        </Grid>
      </Container>
    </Page>
  );
};

export default LanesView;
