import React, { useState, useEffect } from 'react';
import 'sass/globals.scss';
import { useParams, useNavigate } from 'react-router-dom';
import { Box, Grid, Button, Container, CircularProgress } from '@mui/material';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import { selectAuthUser } from 'modules/auth/storeSliceAuth';
import { useSelector } from 'react-redux';
import Page from 'sharedComponents/Page';
import Query from 'services/firebase/Query';
import Executer from 'services/firebase/Executer';
import useShowError from 'modules/errors';
import QueryBase from 'lg-helpers/dist/firestore/query/QueryBase';

const query = new Query();
const executer = new Executer();

const ProjectReportView = () => {
  const authUser = useSelector(selectAuthUser);
  const [project, setProject] = useState<any>({}); // TODO: fix this type
  const [projectReportParams, setProjectReportParams] = useState('');
  const showError = useShowError();
  const [isLoadingData, setIsLoadingData] = useState(false);

  const [reportUrl, setReportUrl] = useState('');

  const { id } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    if (!id) {
      return;
    }
    setIsLoadingData(true);

    setProjectReportParams(
      encodeURIComponent(
        JSON.stringify({
          projectId: id,
          transporterCompanyId:
            authUser.actingAsType === 'Transporter Admin' ? authUser.companiesIds[0] : undefined,
        })
      )
    );

    return executer.watchSingleDocument(
      query.base.getById(QueryBase.PROJECTS_COLLECTION(), id),
      proj => {
        if (proj) {
          setProject(proj);
        } else {
          showError({ title: "This project doesn't exist", duration: 10000 });
        }
        setIsLoadingData(false);
      },
      error => {
        console.error('Error getting document:', error);
        showError({
          title: 'Error while fetching the project',
          duration: 10000,
        });
        setIsLoadingData(false);
      }
    );
    // eslint-disable-next-line
  }, [id, authUser]);

  useEffect(() => {
    const reportUrlFirstPart =
      process.env.NODE_ENV === 'development'
        ? 'https://datastudio.google.com/embed/u/0/reporting/04ad09e3-4373-4122-9d9e-0e40040c70f4/page/xuZJC'
        : 'https://datastudio.google.com/embed/u/0/reporting/550ac104-ab61-4bfe-993a-49e20412808b/page/xuZJC';

    // determine if there is a custom report URL in the project object
    const customReportUrl = project.reports && project.reports[0] ? project.reports[0].name : null;

    let url = '';

    if (customReportUrl) {
      // check if the custom URL already contains parameters
      const hasParams = customReportUrl.includes('?params=');

      if (hasParams) {
        // custom URL already contains parameters, decode params and add in the projectReportParams
        let params = JSON.parse(decodeURIComponent(customReportUrl.split('?params=')[1]));
        if (authUser.actingAsType === 'Transporter Admin')
          params = {
            ...params,
            projectId: id,
            transporterCompanyId: authUser.companiesIds[0],
          };

        // Encode the params object
        const encodedParams = encodeURIComponent(JSON.stringify(params));
        url = `${customReportUrl.split('?params=')[0]}?params=${encodedParams}`;
      } else {
        // custom URL does not contain parameters, add projectReportParams
        url = `${customReportUrl}?params=${projectReportParams}`;
      }
    } else {
      // no custom URL, use the default URL with projectReportParams
      url = `${reportUrlFirstPart}?params=${projectReportParams}`;
    }

    setReportUrl(url);
  }, [authUser, id, project, projectReportParams]);

  return (
    <Page>
      <Container className="min-padding-and-height">
        <Grid container spacing={1}>
          <Grid item sm={12} xs={12}>
            <Button
              onClick={() => navigate(`/projects/${id}`)}
              variant="outlined"
              style={{ maxWidth: 120 }}
            >
              <NavigateBeforeIcon fontSize="small" /> Back
            </Button>
          </Grid>
          {isLoadingData ? (
            <Grid item xs={12}>
              <Box display="flex" justifyContent="center" alignItems="center">
                <CircularProgress color="inherit" />
              </Box>
            </Grid>
          ) : (
            <Grid item sm={12} xs={12}>
              <iframe
                className="lg-iframe"
                title="Inline Frame Example"
                style={{ height: `calc(100vh - 170px)`, width: '100%' }}
                src={reportUrl}
                allowFullScreen
              />
            </Grid>
          )}
        </Grid>
      </Container>
    </Page>
  );
};

export default ProjectReportView;
