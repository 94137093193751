import { createAsyncThunk } from '@reduxjs/toolkit';
import ApiBase from 'services/postgres/ApiBase';
import Tables from 'services/postgres/Tables';
import { pushError } from 'modules/errors/storeSliceErrors';
import { ILocationWithCoords } from 'lg-helpers/dist/shared/interfaces/ILocationWithCoords';

const apiBase = new ApiBase();

export const getLocationByLocationId = createAsyncThunk(
  'trucking-location/get-location-by-location-id',
  async (locationId: number, thunkApi) => {
    try {
      const data = await apiBase.getById(locationId, Tables.Locations);
      return data as ILocationWithCoords;
    } catch (err) {
      thunkApi.dispatch(pushError({ title: 'Error: Unable to get location' }));
      throw err;
    }
  }
);
