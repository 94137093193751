import React, { useState, useEffect, useCallback, useMemo, SetStateAction } from 'react';
import {
  Container,
  Grid,
  Box,
  CircularProgress,
  Card,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
} from '@mui/material';
import ManifestsViewPerProject from 'sharedComponents/ManifestsViewPerProject';
import Page from 'sharedComponents/Page';
import { useParams, useNavigate } from 'react-router-dom';
import { hasErrorRequired } from 'services/logic/formValidation';
import moment from 'moment';

import { UserTypes } from 'lg-helpers/dist/constants/user/UserTypes';
import DriversAssignToProject from './DriversAssignToProject';
import AssignManifestToDriver from './AssignManifestToDriver';
import AssignSpecificManifestToDriver from './AssignSpecificManifestToDriver';
import Downloads from './Downloads';
import CustomInputsManifestListDefinitionsCrud from './CustomInputsManifestListDefinitionsCrud';

import { Timestamp } from 'services/firebase';
import Command from 'services/firebase/Command';
import Query from 'services/firebase/Query';
import Executer from 'services/firebase/Executer';
import useShowError from 'modules/errors';
import ApiLog from './ApiLog';
import CompaniesApi from 'services/postgres/CompaniesApi';
import useCompaniesByTypes from 'utils/useCompaniesByTypes';
import ProjectViewTabs from './ProjectViewTabs';
import ProjectViewTopBar from './ProjectViewTopBar';
import ProjectDetailsEdit, { getProjectScaleCompanyProperty } from './ProjectDetailsEdit';
import { IJoinCompany } from 'lg-helpers/dist/shared/interfaces/IJoinCompany';
import { IProject } from 'lg-helpers/dist/shared/interfaces/IProject';
import { ICanProjectViewAllManifests } from 'lg-helpers/dist/shared/interfaces/ICanProjectViewAllManifests';
import { IisAuthUser } from 'lg-helpers/dist/shared/interfaces/IisAuthUser';
import { ICustomInputDefinitions } from 'lg-helpers/dist/shared/interfaces/ICustomInputDefinitions';

import DownloadsNew from './DownloadsNew';
import { usePermissionsContext } from '../services/permissions';
import { GlobalFeatureFlags } from 'lg-helpers/dist/constants/feature-flags/GlobalFeatureFlags';
import QueryBase from 'lg-helpers/dist/firestore/query/QueryBase';
import './ProjectView.scss';
import WorkCodeView from '../modules/contractors/workCodes/WorkCodeView';
import WorkCodeTab from '../modules/contractors/workCodes/WorkCodeTab';

const command = new Command();
const query = new Query();
const executer = new Executer();
const companiesApi = new CompaniesApi();

type ChangeEvent = React.ChangeEvent<HTMLFormElement | HTMLInputElement>;

interface ManifestCustomInputs {
  [key: string]: {
    value: string;
    enteredByUserId: string;
  };
}
interface Templates {
  [key: string]: any;
}

const hasIntersection = (arr1?: any[], arr2?: any[]) =>
  (arr1 || []).filter(value => (arr2 || []).includes(value)).length > 0;

const tab = {
  MANIFESTS: '0',
  DRIVERS: '1',
  ASSIGN_MANIFEST_TO_DRIVER: '2',
  ASSIGN_SPECIFIC_MANIFEST_TO_DRIVER: '3',
  MANIFEST_CUSTOM_INPUTS: '4',
  WORK_CODES: '5',
  PDFS_DOWNLOADS: '6',
  API_LOG: '7',
};

const ProjectView = () => {
  let { id } = useParams();
  const { user: authUser } = usePermissionsContext();
  const navigate = useNavigate();
  const showError = useShowError();

  const [isLoadingSaveDetails, setIsSavingSaveDetails] = useState(false);
  const [wasSubmitted, setWasSubmitted] = useState(false);
  const [isLoadingData, setIsLoadingData] = useState(false);
  const [initialProject, setInitialProject] = useState<IProject | any>({}); // dirty fix, because of bad IProject interface
  const [project, setProject] = useState<IProject>({
    isArchived: false,
  });

  const [scales, setScales] = useState<IJoinCompany[]>([]);
  const [manifestTemplates, setManifestTemplates] = useState<Templates[]>([]);
  const [receiptTemplates, setReceiptTemplates] = useState<Templates[]>([]);
  const [isLoadingScales, setIsLoadingScales] = useState(false);
  const [isLoadedScaleCompanies, setIsLoadedScaleCompanies] = useState(false);
  const [openBackfill, setOpenBackfill] = useState(false);
  const [assignedDriversIds, setAssignedDriversIds] = useState<string[]>([]);

  const useNewDownloads = GlobalFeatureFlags.DOWNLOADS_v2;

  const [isProjectDetailsExpanded, setIsProjectDetailsExpanded] = useState(false);
  const [filters, setFilters] = useState({});
  const [tabValue, setTabValue] = useState('0');
  const [hasChanges, setHasChanges] = useState(false);
  const [canProjectViewAllManifests, setCanProjectViewAllManifests] =
    useState<ICanProjectViewAllManifests>({});

  const {
    loading: isLoadingCompanies,
    generator: generators,
    scale: scaleCompanies,
    companies: viewers,
    transporter: cTransporters,
    contractor: contractors,
    broker: brokers,
  } = useCompaniesByTypes();

  useEffect(() => {
    if (!project?.scaleCompany?.id) return;

    const updateTemplates = async () => {
      const companyWithCustomData = await companiesApi.getById(project.scaleCompany!.id as string);
      setManifestTemplates(companyWithCustomData?.manifestTemplates || []);
      setReceiptTemplates(companyWithCustomData?.receiptTemplates || []);
    };
    updateTemplates();
  }, [project?.scaleCompany]);

  const { companiesIds: authUserCompaniesIds, actingAsType, id: authUserId } = authUser;
  const isAuthUser: IisAuthUser = {
    superAdmin: actingAsType === UserTypes.admin.super,
    transporterAdmin:
      actingAsType === UserTypes.transporter.admin &&
      hasIntersection(authUserCompaniesIds, project?.transporterCompaniesIds),
    scaleAdmin:
      !!project?.scaleCompany &&
      actingAsType === UserTypes.scale.admin &&
      hasIntersection(authUserCompaniesIds, [project?.scaleCompany?.id]),
    customerAdmin:
      actingAsType === UserTypes.admin.customer &&
      hasIntersection([authUserId], project?.customerAdminIds),
    generatorAdmin:
      !!project?.generatorCompany &&
      actingAsType === UserTypes.generator.admin &&
      hasIntersection(authUserCompaniesIds, [project?.generatorCompany?.id]),
    generator: false,
    contractor:
      !!project?.contractorCompany &&
      (actingAsType === UserTypes.contractor.admin || actingAsType === UserTypes.contractor.user) &&
      hasIntersection(authUserCompaniesIds, [project?.contractorCompany?.id]),
    readOnly: false,
    canAddViewers: false,
  };
  isAuthUser.generator =
    !!project?.generatorCompany &&
    (!!isAuthUser.generatorAdmin ||
      (actingAsType === UserTypes.generator.user &&
        hasIntersection(authUserCompaniesIds, [project?.generatorCompany?.id])));
  isAuthUser.readOnly = !isAuthUser.superAdmin && !isAuthUser.customerAdmin;
  isAuthUser.canAddViewers = ['generator', 'contractor', 'superAdmin', 'customerAdmin'].some(
    e => isAuthUser[e as keyof IisAuthUser] as Boolean
  );

  const isPredefinedFilterDateRange = [
    'superAdmin',
    'contractor',
    'transporterAdmin',
    'customerAdmin',
    'scaleAdmin',
  ].some(e => isAuthUser[e as keyof IisAuthUser] as Boolean);

  const defaultFilterDateRange = useMemo(() => {
    if (!isPredefinedFilterDateRange) return false;
    const startDate = moment
      .tz(project.timeZone || 'EST')
      .startOf('day')
      .toDate();
    const endDate = moment
      .tz(project.timeZone || 'EST')
      .endOf('day')
      .toDate();
    return [startDate, endDate];
  }, [project, isPredefinedFilterDateRange]);

  const newCustomInputs = useMemo(() => {
    const originalCustomInputIds = (initialProject?.customInputsDefinitions || []).map(
      (input: ICustomInputDefinitions) => input.id
    );

    const customInputsDecorated = {} as ManifestCustomInputs;

    (project?.customInputsDefinitions || [])
      .filter((customInput: ICustomInputDefinitions) => {
        const matchingInitialCustomInput = (initialProject?.customInputsDefinitions || []).find(
          (ci: any) => ci.id === customInput.id
        );
        const isCustomInputNewList =
          customInput?.items &&
          JSON.stringify(matchingInitialCustomInput?.items || []) !==
            JSON.stringify(customInput?.items);
        const differentDefaultValue =
          matchingInitialCustomInput?.defaultValue !== customInput?.defaultValue;
        return (
          differentDefaultValue ||
          isCustomInputNewList ||
          !originalCustomInputIds.includes(customInput.id)
        );
      })
      .map((customInput: ICustomInputDefinitions) => {
        customInputsDecorated[customInput.propertyPathInManifestCustomInputs] = {
          value: customInput.defaultValue,
          enteredByUserId: authUser.id as string,
        };
      });
    return customInputsDecorated;
    // eslint-disable-next-line
  }, [project, initialProject]);

  const goToPdfsDownloadTab = useCallback(() => {
    setTabValue(tab.PDFS_DOWNLOADS);
  }, [setTabValue]);

  useEffect(() => {
    if (!id) {
      setIsProjectDetailsExpanded(true);
      return;
    }

    setIsLoadingData(true);
    return executer.watchSingleDocument(
      query.base.getById(QueryBase.PROJECTS_COLLECTION(), id),
      proj => {
        if (proj) {
          if (!proj.externalApiMappings) {
            proj.externalApiMappings = {};
          }
          setProject(proj as IProject);
          setInitialProject(proj as IProject);
        } else {
          showError({ title: "This project doesn't exist", duration: 10000 });
        }
        setIsLoadingData(false);
      },
      error => {
        console.error('Error getting document:', error);
        showError({
          title: 'Error while fetching the project',
          duration: 10000,
        });
        setIsLoadingData(false);
      }
    );
    // eslint-disable-next-line
  }, [id, showError]);

  useEffect(() => {
    if (
      isLoadingCompanies ||
      isLoadedScaleCompanies ||
      !scaleCompanies ||
      !project?.scaleCompany?.id
    ) {
      return;
    }
    scaleCompanies.forEach(async (comp: IJoinCompany) => {
      if (project.scaleCompany && comp.id === project.scaleCompany?.id) {
        const companyWithCustomData = await companiesApi.getById(project.scaleCompany.id as string);

        setProject({
          ...project,
          scaleCompany: getProjectScaleCompanyProperty(companyWithCustomData) as IJoinCompany,
        });
      }
    });
    setIsLoadedScaleCompanies(true);
  }, [
    project,
    scaleCompanies,
    isLoadingCompanies,
    isLoadedScaleCompanies,
    setIsLoadedScaleCompanies,
    setProject,
    isAuthUser.readOnly,
  ]);

  useEffect(() => {
    const lCanViewAllManifests: ICanProjectViewAllManifests = {};
    if (project.transporterCompanies && project.transporterCompanies.length > 0) {
      for (const transporterCom of project.transporterCompanies) {
        lCanViewAllManifests[transporterCom.id as string] = transporterCom.canViewAllManifests;
      }
    }
    setCanProjectViewAllManifests(lCanViewAllManifests);
  }, [project.transporterCompanies]);

  useEffect(() => {
    if (!project.scaleCompany) {
      return;
    }

    const getScales = async () => {
      try {
        setIsLoadingScales(true);
        const scaleCompanyId = project.scaleCompany?.id;
        if (!scaleCompanyId) throw new Error('Scale company is not selected on project');
        const scls = await executer.getMultipleDocuments(
          query.manifests.getScalesForScaleCompany(scaleCompanyId)
        );
        setScales(scls as IJoinCompany[]);
        setIsLoadingScales(false);
      } catch (error) {
        console.error('getScales() Error', error);
        showError({
          title: 'Error while fetching the scales',
          duration: 10000,
        });
        setIsLoadingScales(false);
      }
    };
    getScales();
    // eslint-disable-next-line
  }, [project.scaleCompany, showError]);

  const handleBackfillManifests = async () => {
    let errors = checkValidationErrors();
    if (errors.length) {
      errors.forEach(err => showError({ title: err }));
    } else {
      try {
        if (Object.keys(newCustomInputs).length) {
          await command.manifests.appendManifestCustomInputsForEntireProject(
            project.id,
            newCustomInputs
          );
        }
      } catch (error) {
        console.error('handleBackfillManifests()', error);
        showError({ title: 'Error backfilling custom inputs on manifests' });
      }
    }
  };

  useEffect(() => {
    if (!project?.transporterCompanies?.length || !isAuthUser.superAdmin) return;
    const findAvailableDrivers = () => {
      const drivers: any = (project?.transporterCompanies || []).map((transporter: any) => {
        const userResults: any = query.users.getUsersInCompanyOfAnyTypes(transporter.id, [
          UserTypes.transporter.driver,
        ]);
        return userResults;
      });

      return drivers;
    };

    const filterAssignedDrivers = (results: any) => {
      const couldBeDriversIds = results.map((user: any) => user?.id);
      const filteredDrivers = (project?.assignedDriversIds || []).filter((driverId: string) =>
        couldBeDriversIds.includes(driverId)
      );
      setAssignedDriversIds(filteredDrivers);
    };

    const unsubscribe = executer.getMultipleDocumentsOnMultipleQueries(
      findAvailableDrivers(),
      filterAssignedDrivers,
      (error: any) => console.error(error)
    );
    return () => {
      unsubscribe;
    };
  }, [project?.transporterCompanies, project?.assignedDriversIds, isAuthUser.superAdmin]);

  const handleSaveDetails = async () => {
    setIsSavingSaveDetails(true);

    let errors = checkValidationErrors();
    if (errors.length) {
      errors.forEach(err => showError({ title: err }));
    } else {
      try {
        const transporterCompaniesIds: (string | undefined)[] = (
          project.transporterCompanies || []
        ).map((c: IJoinCompany) => c.id);

        const viewersCompaniesIds: (string | undefined)[] = (project.viewers || []).map(
          (c: any) => c.id
        );

        const transporterCompanies: IJoinCompany[] = (project.transporterCompanies || []).map(
          (c: IJoinCompany) => ({
            ...c,
            canViewAllManifests: canProjectViewAllManifests[c.id as string] || false,
          })
        );

        const scalesIds: (string | undefined)[] = (project?.scales || []).map(
          (s: IJoinCompany) => s.id
        );

        const projData: IProject = {
          ...project,
          assignedDriversIds: isAuthUser.superAdmin
            ? assignedDriversIds
            : project.assignedDriversIds,
          profileNr: (project.profileNr || '').trim(),
          receiptTemplateId: project.receiptTemplateId || null,
          isActive: !!project.isActive,
          companiesIds: [
            project.generatorCompany?.id,
            project.contractorCompany?.id,
            project.scaleCompany?.id,
            ...transporterCompaniesIds,
            ...viewersCompaniesIds,
          ] as (string | undefined)[],
          viewersCompaniesIds,
          scalesIds,
          transporterCompaniesIds,
          transporterCompanies,
        };

        if (isAuthUser.readOnly) {
          projData.scaleCompany = initialProject.scaleCompany;
        }

        if (actingAsType === UserTypes.admin.super) {
          projData.isActiveCustomApi = true;
        }

        if (!id) {
          const projDataWithCustomerAdmin = {
            ...projData,
            customerAdminIds: actingAsType === UserTypes.admin.customer ? [authUserId] : [],
          };
          const { id: i } = await command.base.add(
            QueryBase.PROJECTS_COLLECTION(),
            projDataWithCustomerAdmin
          );
          navigate(`/projects/${i}`);
        } else {
          // Fix to optimize projects size
          const {
            receiptTemplates: _receiptTemplates,
            manifestTemplates: _manifestTemplates,
            ...scaleCompanyDetails
          } = projData.scaleCompany as any;
          projData.scaleCompany = scaleCompanyDetails;

          await command.base.set(QueryBase.PROJECTS_COLLECTION(), id, projData);
        }
        setHasChanges(false);
      } catch (error) {
        console.error('handleSaveDetails()', error);
        showError({ title: 'Save error' });
      }
    }

    setIsSavingSaveDetails(false);
    setWasSubmitted(true);
  };

  const handleSaveButton = () => {
    console.log('handleSaveButton: ', newCustomInputs);

    if (Object.keys(newCustomInputs).length) return handleClickOpen();
    handleSaveDetails();
  };

  const handleClickOpen = () => {
    setOpenBackfill(true);
  };

  const handleClose = () => {
    setOpenBackfill(false);
  };

  const handleChangeExpiryDate = (value: Date | null) => {
    const firestoreTime = !!value ? Timestamp.fromDate(value as Date) : null;

    setProject({
      ...project,
      expiryDate: firestoreTime,
    });
    setHasChanges(true);
  };

  const handleChange = useCallback(
    (event: ChangeEvent) => {
      const { name, value, checked, type } = event.target;
      const computedType = type === 'checkbox' ? checked : value;

      if (name === 'scaleCompany') {
        let { fileNameActionsInCronJob } = project;
        if (!fileNameActionsInCronJob) {
          fileNameActionsInCronJob = value.fileNameActionsInCronJob;
        }

        setProject({
          ...project,
          fileNameActionsInCronJob,
          [name]: computedType,
          scales: [],
        });
      } else {
        setProject({
          ...project,
          [name]: computedType,
        });
      }

      setHasChanges(true);
      setWasSubmitted(false);
    },
    [project, setProject, setHasChanges, setWasSubmitted]
  );

  const onSiteAddressChange = useCallback(
    (newSiteAddr: string) =>
      setProject((curProject: IProject) => ({
        ...curProject,
        generatorSiteAddress: newSiteAddr,
      })),
    [setProject]
  );

  const onCoordinatesChange = useCallback(
    ({ lat, lon }: { lat: number; lon: number }) =>
      setProject((curProject: IProject) => ({
        ...curProject,
        coords: {
          gpsLat: lat,
          gpsLng: lon,
        },
      })),
    [setProject]
  );

  const handleChangeCanViewCheckbox = (transporter: IJoinCompany, canViewAllManifests: boolean) => {
    setCanProjectViewAllManifests({
      ...canProjectViewAllManifests,
      [transporter.id as string]: canViewAllManifests,
    });
    setHasChanges(true);
  };

  const handleAccordionStateChange = (event: ChangeEvent, value: SetStateAction<boolean>) =>
    setIsProjectDetailsExpanded(value as SetStateAction<boolean>);

  const handleChangeInExternalApiMappings = (event: ChangeEvent) => {
    setProject({
      ...project,
      externalApiMappings: {
        ...project.externalApiMappings,
        [event.target.name]: event.target.value,
      },
    });

    setHasChanges(true);
    setWasSubmitted(false);
  };

  const handleCustomInputsDefinitionsChange = (customInputsDefinitions: any) => {
    setProject({
      ...project,
      customInputsDefinitions,
    });

    setHasChanges(true);
    setWasSubmitted(false);
  };

  const checkValidationErrors = () => {
    let errors = [] as string[];

    const errorChecks = [
      [project.name, 'Project name required'],
      [project.timeZone, 'Timezone required'],
      [project.profileNr, 'Profile No. required'],
      [project.generatorCompany, 'Generator Co. required'],
      [project.contractorCompany, 'Contractor Co. required'],
      [project.transporterCompanies, 'Transporter Co. required'],
      [project.scaleCompany, 'Scale Co. required'],
      [project.manifestTemplateId, 'Manifest Template required'],
      [project.receiptTemplateId, 'Receipt Template required'],
      [project.generatorMailingAddress, 'Generator Mailing Addr. required'],
      [project.generatorPhone, 'Generator Phone No. required'],
      [project.additionalDescriptions, 'Addit. Descriptions required'],
      [project.regionId, 'Region required'],
      [project.billTo, 'Biller required'],
      [project.scales, 'Scales required'],
    ];

    errorChecks.map(check => hasErrorRequired(check[0]) && errors.push(check[1] as string));

    if (project.customInputsDefinitions) {
      project.customInputsDefinitions
        .filter(
          ({ isValidCustomInputDefinition }: ICustomInputDefinitions) =>
            !isValidCustomInputDefinition
        )
        .map(({ order }) => errors.push(`Custom Input #${order} needs attention`));
    }

    return errors;
  };

  const setProjectAssignedDriversIds = (
    assignedDriversIdsLocal: string[],
    driverIdsInCompany: string[]
  ) => {
    const filteredProjectDriverIds = [...(project.assignedDriversIds || [])].filter(
      i => !driverIdsInCompany.includes(i)
    );

    const uniqueDriverids = [...new Set([...filteredProjectDriverIds, ...assignedDriversIdsLocal])];

    setProject({
      ...project,
      assignedDriversIds: uniqueDriverids,
    });

    setHasChanges(true);
    setWasSubmitted(false);
  };

  const handleTabChangeCallback = (event: ChangeEvent, newValue: string) => setTabValue(newValue);

  return (
    <Page>
      <Card>
        <Container>
          <Grid container alignItems="center" spacing={1} justifyContent="space-between">
            <ProjectViewTopBar
              project={project}
              templates={{ manifestTemplates, receiptTemplates }}
              scaleCompanies={scaleCompanies}
              isAuthUser={isAuthUser}
              isLoadingSaveDetails={isLoadingSaveDetails}
              tabValue={tabValue}
              tab={tab}
              handleSaveDetails={handleSaveButton}
              id={id}
              filters={filters}
              goToPdfsDownloadTab={goToPdfsDownloadTab}
              hasChanges={hasChanges}
              useNewDownloads={useNewDownloads}
            />

            {isLoadingData ? (
              <Grid item xs={12}>
                <Box display="flex" justifyContent="center" alignItems="center">
                  <CircularProgress color="inherit" />
                </Box>
              </Grid>
            ) : (
              <ProjectDetailsEdit
                project={project}
                isProjectDetailsExpanded={isProjectDetailsExpanded}
                isAuthUser={isAuthUser}
                isLoadingCompanies={isLoadingCompanies}
                isLoadingScales={isLoadingScales}
                cTransporters={cTransporters}
                brokers={brokers}
                wasSubmitted={wasSubmitted}
                generators={generators}
                viewers={viewers}
                contractors={contractors}
                scales={scales}
                canProjectViewAllManifests={canProjectViewAllManifests}
                scaleCompanies={scaleCompanies}
                hasErrorRequired={hasErrorRequired}
                handleChangeCanViewCheckbox={handleChangeCanViewCheckbox}
                handleChange={handleChange}
                handleAccordionStateChange={handleAccordionStateChange}
                onSiteAdressChange={onSiteAddressChange}
                onCoordinatesChange={onCoordinatesChange}
                handleChangeExpiryDate={handleChangeExpiryDate}
                handleChangeInExternalApiMappings={handleChangeInExternalApiMappings}
                handleCustomInputsDefinitionsChange={handleCustomInputsDefinitionsChange}
                templates={{ manifestTemplates, receiptTemplates }}
              />
            )}

            {project.id && !isProjectDetailsExpanded && (
              <>
                <ProjectViewTabs
                  project={project}
                  tabValue={tabValue}
                  tab={tab}
                  isAuthUser={isAuthUser}
                  handleTabChangeCallback={handleTabChangeCallback}
                />

                {tabValue === tab.MANIFESTS ? (
                  <Grid item xs={12}>
                    <ManifestsViewPerProject
                      project={project}
                      defaultFilterIsSigned={!isAuthUser.generator}
                      defaultdateRangeFilter={defaultFilterDateRange}
                      setFiltersCallback={setFilters}
                    />
                  </Grid>
                ) : null}

                {tabValue === tab.DRIVERS && (
                  <Grid item xs={12}>
                    <DriversAssignToProject
                      selectedUsersIds={project.assignedDriversIds || []}
                      setSelectedUsersIdsCallback={setProjectAssignedDriversIds}
                    />
                  </Grid>
                )}

                {tabValue === tab.ASSIGN_MANIFEST_TO_DRIVER && (
                  <Grid item xs={12}>
                    <AssignManifestToDriver project={project} />
                  </Grid>
                )}

                {tabValue === tab.ASSIGN_SPECIFIC_MANIFEST_TO_DRIVER && (
                  <Grid item xs={12}>
                    <AssignSpecificManifestToDriver project={project} />
                  </Grid>
                )}

                {tabValue === tab.MANIFEST_CUSTOM_INPUTS && (
                  <Grid item xs={12}>
                    <CustomInputsManifestListDefinitionsCrud
                      projectId={(project.id as any as string) ?? ''}
                    />
                  </Grid>
                )}

                {/* TODO: Add table here if multiple work codes */}
                {tabValue === tab.WORK_CODES && (
                  <Grid item xs={12}>
                    <WorkCodeTab projectId={id!} />
                  </Grid>
                )}

                {tabValue === tab.PDFS_DOWNLOADS && (
                  <Grid item xs={12}>
                    {useNewDownloads ? (
                      <DownloadsNew projectId={id} />
                    ) : (
                      <Downloads projectId={id} />
                    )}
                  </Grid>
                )}

                {tabValue === tab.API_LOG && (
                  <Grid item xs={12}>
                    <ApiLog project={project} />
                  </Grid>
                )}
              </>
            )}
          </Grid>
        </Container>
      </Card>
      <Dialog
        open={openBackfill}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {'Backfill Previously Generated Manifests?'}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Your custom inputs have been created. They will be added to any new manifests you
            generate. Would you like to backfill your previously generated manifests with these
            custom inputs?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button
            onClick={() => {
              handleSaveDetails();
              handleClose();
            }}
          >
            Save without backfill
          </Button>
          <Button
            onClick={() => {
              handleBackfillManifests();
              handleSaveDetails();
              handleClose();
            }}
            autoFocus
          >
            Save and backfill manifests
          </Button>
        </DialogActions>
      </Dialog>
    </Page>
  );
};

export default ProjectView;
