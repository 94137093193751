import { createAsyncThunk } from '@reduxjs/toolkit';
import Tables from 'services/postgres/Tables';
import ApiBase from 'services/postgres/ApiBase';
import { INotification } from 'modules/civilAndTrucking/shared/NotificationSnackbar/INotification';
import { pushNotification } from 'modules/civilAndTrucking/shared/NotificationSnackbar/redux/actions';
import { RootState } from 'store/store';
import { pushError } from 'modules/errors/storeSliceErrors';
import { ITruckingTruckDivision } from 'lg-helpers/dist/trucking/interfaces/ITruckingTruckDivision';

const apiBase = new ApiBase();
const DIVISION_TABLE = Tables.TruckingTruckDivision;

export const getDivisionsByCompanyId = createAsyncThunk(
  'divisions/get-trucking-divisions',
  async (payload: undefined, thunkApi) => {
    try {
      const data = await apiBase.getList(`${DIVISION_TABLE}`);
      return data as ITruckingTruckDivision[];
    } catch (err) {
      thunkApi.dispatch(pushError({ title: 'Error: Unable to get divisions' }));
      throw err;
    }
  }
);

export const upsertDivision = createAsyncThunk(
  'divisions/add-trucking-divisions',
  async (payload: ITruckingTruckDivision, thunkApi) => {
    const state = thunkApi.getState() as RootState;
    const companyId = state.civil.companies.activeCompany?.id;

    const divisionReq = {
      ...payload,
      companyIdTruckingTruckDivision: companyId,
    };

    try {
      await apiBase.insertOrUpdate(divisionReq, DIVISION_TABLE);
      thunkApi.dispatch(getDivisionsByCompanyId());
    } catch (err) {
      thunkApi.dispatch(pushError({ title: 'Error: Unable to create new division' }));
    }
  }
);

export const deleteDivision = createAsyncThunk(
  'divisions/delete-truck',
  async (divisionId: number, thunkApi) => {
    try {
      await apiBase.deleteById(divisionId, DIVISION_TABLE);
      const successNotification: INotification = {
        status: 'success',
        message: 'Success: Division has been deleted',
      };
      thunkApi.dispatch(pushNotification(successNotification));
      thunkApi.dispatch(getDivisionsByCompanyId());
    } catch (err) {
      thunkApi.dispatch(pushError({ title: 'Error: Unable to delete division' }));
    }
  }
);
