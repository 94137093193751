import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

export default function InternetConnectionAlert() {
  const [isOnline, setIsOnline] = useState(true);

  useEffect(() => {
    const onOffline = () => setIsOnline(false);
    const onOnline = () => setIsOnline(true);

    window.addEventListener('offline', onOffline);
    window.addEventListener('online', onOnline);

    return () => {
      window.removeEventListener('offline', onOffline);
      window.removeEventListener('online', onOnline);
    };
  });

  if (isOnline) return null;

  return <Wrapper>No internet connection!</Wrapper>;
}

const Wrapper = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  background: #f44336;
  color: white;
  font-size: 16px;
  padding: 5px 10px;
  text-align: center;
  z-index: 1000000;
`;
