import React from 'react';
import Papa from 'papaparse';
import { Box, Typography } from '@mui/material';
import { GridValidRowModel } from '@mui/x-data-grid';
import * as XLSX from 'xlsx';

interface ImportCVSandXLSXProps {
  setJSON: React.Dispatch<React.SetStateAction<any>>;
  button?: any;
}

const ImportCSVandXLSX = ({ setJSON, button }: ImportCVSandXLSXProps) => {
  const parseImport = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const fileReader = new FileReader();
    if (event.target.files) {
      const file = event?.target?.files[0];
      if (file.type === 'text/csv') {
        Papa.parse(file, {
          header: true,
          transformHeader: s => s.toLowerCase(),
          complete: (results: Papa.ParseResult<GridValidRowModel>) => setJSON(results.data),
        });
      } else if (
        file.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' ||
        file.type === 'application/vnd.ms-excel'
      ) {
        fileReader.readAsArrayBuffer(file);
        fileReader.onload = FRevent => {
          const data = FRevent.target?.result;
          const workbook = XLSX.read(data, { type: 'array' });
          const sheetName = workbook.SheetNames[0];
          const worksheet = workbook.Sheets[sheetName];
          const json = XLSX.utils.sheet_to_json(worksheet, {
            range: 1,
            header: ['address', 'city', 'state', 'zip', 'country'],
          });
          setJSON(json);
        };
      }
    }
  };

  return (
    <>
      <input
        type="file"
        hidden
        accept=".csv,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
        id="image-upload-button"
        className="form-control"
        onChange={event => {
          event.persist();
          parseImport(event);
        }}
        // onClick clears input so uploading the same file will re-run parseImport
        onClick={(event: React.MouseEvent<HTMLInputElement, MouseEvent>) => {
          const element = event.target as HTMLInputElement;
          element.value = '';
        }}
      />
      <label htmlFor="image-upload-button">
        {button ? (
          <Box>{button}</Box>
        ) : (
          <Box>
            <Typography align="center">Import</Typography>
          </Box>
        )}
      </label>
    </>
  );
};

export default ImportCSVandXLSX;
