import React, { useEffect } from 'react';
import './civil.scss';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Grid } from '@mui/material';
import { Routes, Route } from 'react-router-dom';

import { RootState, thunkDispatch } from 'store/store';
import { IMenuItem } from 'modules/civilAndTrucking/shared/nav';
import HomeView from './views/Home';
import TicketsView from './views/Tickets';
import RequestsView from './views/Requests';
import ReportsView from './views/Reports';
import QuickAdd from 'sharedComponents/QuickAdd';
import ProjectsView from './Projects';
import Reconciliation from 'modules/civilAndTrucking/civil/views/Reconciliation/AccountingReconciliation';
import CompanyMaterials from './CompanyMaterials';
import ActivityTypes from './ActivityTypes';
import CompanyDetails from './Companies';
import Activities from './Activities';
import UserSettings from 'modules/civilAndTrucking/civil/views/User';
import {
  DivisionsViews,
  SettingsView,
  TrucksViews,
  TruckTypesViews,
} from 'modules/civilAndTrucking/trucking/features';
import { TransportersViews } from './Transporters';
import { SourceSuppliersViews } from './SourceSuppliers';

import { NotificationSnackbar } from 'modules/civilAndTrucking/shared/NotificationSnackbar';
import { ModalProvider } from 'sharedComponents/ModalContext';
import Modal from 'sharedComponents/Modal';
import { setActiveCompany } from './Companies/redux/actions';
import { usePermissionsContext } from 'services/permissions';
import {
  FlagOutlined,
  HomeOutlined,
  LibraryBooks,
  LocalAtmOutlined,
  Settings,
  SpeedOutlined,
} from '@mui/icons-material';
import NotFoundView from '../../../sharedComponents/pictograms/NotFoundView';
import { AppNav } from '../shared/nav/AppNav/AppNav';
import { IJoinCompany } from 'lg-helpers/dist/shared/interfaces/IJoinCompany';
import CompanySubMaterials from './CompanySubMaterials';
import SigmaEmbedded from 'sharedComponents/SigmaEmbedded';
import SigmaReportSettings from 'sharedComponents/SigmaReportSettings';

const navBarItems: IMenuItem[] = [
  {
    href: '/civil',
    title: 'Home',
    Pictogram: <HomeOutlined />,
    computeVisibility: user => user !== 'Civil Site User',
  },
  {
    href: '/civil/tickets',
    title: 'Tickets',
    Pictogram: <LibraryBooks />,
    computeVisibility: user => user !== 'Civil Site User',
  },
  {
    href: '/civil/requests',
    title: 'Requests',
    Pictogram: <FlagOutlined />,
    computeVisibility: user => ['Civil Accountant', 'Civil Project Manager'].includes(user),
  },
  {
    href: '/civil/accounting/reconciliation',
    title: 'Reconcile',
    Pictogram: <LocalAtmOutlined />,
    computeVisibility: user => user === 'Civil Accountant',
  },
  {
    href: '/civil/reports',
    title: 'Reports',
    Pictogram: <SpeedOutlined />,
    computeVisibility: user => ['Civil Accountant', 'Civil Project Manager'].includes(user),
  },
  {
    href: '/civil/settings',
    title: 'Settings',
    Pictogram: <Settings />,
    computeVisibility: user => ['Civil Accountant', 'Civil Project Manager'].includes(user),
  },
];

const CivilDashboardView = () => {
  const dispatch = useDispatch();
  const { user } = usePermissionsContext();

  const activeCompany: IJoinCompany | undefined = useSelector(
    (state: RootState) => state.civil.companies.activeCompany as IJoinCompany
  );

  useEffect(() => {
    const companyId = user.companiesIds[0];

    if (companyId) {
      thunkDispatch(setActiveCompany(companyId));
    }
  }, [dispatch, user]);

  return user && activeCompany ? (
    <ModalProvider>
      <Modal />
      <Grid
        container
        height="98vh"
        display="flex"
        className="Civil"
        direction="column"
        position="relative"
      >
        <AppNav
          settingsPath="user/settings"
          logoComponent={<img src="/static/images/logo-civil-main.svg" alt="LTS Civil" />}
          navItems={navBarItems}
        />
        <Box
          sx={
            user.type === 'Civil Site User'
              ? {
                  flexGrow: 1,
                  paddingTop: '5rem',
                  paddingLeft: '8rem',
                  paddingRight: '2rem',
                }
              : {
                  flexGrow: 1,
                  paddingTop: '5rem',
                  paddingLeft: '7rem',
                  paddingRight: '3rem',
                }
          }
        >
          <Routes>
            <Route path="/" element={<HomeView />} />
            <Route path="/tickets" element={<TicketsView />} />
            <Route path="/requests" element={<RequestsView />} />
            <Route path="/reports" element={<SigmaEmbedded />} />
            <Route path="/projects/*" element={<ProjectsView />} />
            <Route path="/settings/transporters" element={<TransportersViews />} />
            <Route path="/settings/sources" element={<SourceSuppliersViews />} />

            <Route path="/settings/work-categories" element={<ActivityTypes />} />
            <Route path="/settings/work-categories/activities" element={<Activities />} />
            <Route path="/accounting/reconciliation" element={<Reconciliation />} />
            <Route path="/settings/company-details" element={<CompanyDetails />} />
            <Route path="/user/settings" element={<UserSettings />} />
            <Route path="/settings" element={<SettingsView />} />
            <Route path="/settings/trucks/*" element={<TrucksViews />} />
            <Route path="/settings/company-materials" element={<CompanyMaterials />} />
            <Route path="/settings/company-sub-materials" element={<CompanySubMaterials />} />
            <Route path="/settings/truck-types/*" element={<TruckTypesViews />} />
            <Route path="/settings/divisions/*" element={<DivisionsViews />} />
            <Route path="/settings/reports" element={<SigmaReportSettings />} />
            <Route path="*" element={<NotFoundView />} />
          </Routes>
        </Box>
      </Grid>
      <NotificationSnackbar />
      <QuickAdd />
    </ModalProvider>
  ) : null;
};

export default CivilDashboardView;
