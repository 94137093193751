import { createSlice } from '@reduxjs/toolkit';
import { getLocationByLocationId } from './actions';
import { RootState } from 'store/store';
import { TFormStatus } from 'lg-helpers/dist/shared/types/TFormStatus';
import { ILocationWithCoords } from 'lg-helpers/dist/shared/interfaces/ILocationWithCoords';

export interface ILocationState {
  data: ILocationWithCoords;
  status: TFormStatus;
}

const initialLocationState: ILocationState = {
  data: {} as ILocationWithCoords,
  status: null,
};

export const locationSlice = createSlice({
  name: 'location',
  reducers: {},
  initialState: initialLocationState,
  extraReducers: builder => {
    builder.addCase(getLocationByLocationId.pending, state => {
      state.status = 'pending';
    });
    builder.addCase(getLocationByLocationId.fulfilled, (state, { payload }) => {
      state.status = 'success';
      state.data = payload;
    });
    builder.addCase(getLocationByLocationId.rejected, (state, { error }) => {
      state.status = 'failure';
      state.data = {} as ILocationWithCoords;
      console.error('getLocationByLocationId error: ', error);
    });
  },
});

export const selectLocation = (state: RootState) => {
  return {
    location: state.trucking.location.data,
    locationStatus: state.trucking.location.data,
  };
};
