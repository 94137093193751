import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { v4 as uuidv4 } from 'uuid';

export interface IError {
  title: string;
  duration?: number;
  text?: string;
}

export interface IFullError extends IError {
  id: string;
  duration: number;
  time: number;
}

export const storeSlice = createSlice({
  name: 'errors',
  initialState: {
    errorsList: [] as IFullError[],
  },
  reducers: {
    pushError: (state, action: PayloadAction<IError>) => {
      const time = Date.now();
      const id = uuidv4();
      state.errorsList = [
        ...state.errorsList.slice(-20), // limit size of array
        { duration: 10000, ...action.payload, time, id },
      ];
      console.error(state.errorsList[0]);
    },
  },
});

export const { pushError } = storeSlice.actions;

export const selectErrors = (state: any) => state.errors.errorsList as IFullError[];

export default storeSlice.reducer;
