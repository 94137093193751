import React from 'react';
import { useParams } from 'react-router';
import { Link } from 'react-router-dom';
import { thunkDispatch } from 'store/store';
import { useHasAccess } from 'services/permissions';
import { IProject } from 'lg-helpers/dist/shared/interfaces/IProject';
import { setActiveProject } from 'modules/civilAndTrucking/civil/Projects/redux/actions';
import { Box, Container, Typography } from '@mui/material';

export const VerifyProjectOwnershipWrapper = ({
  children,
  message = "You don't have permissions!",
  redirect = '/',
  linkText = 'Go home',
}: {
  children: JSX.Element;
  message?: string;
  redirect?: string;
  linkText?: string;
}): JSX.Element | null => {
  const { projectId } = useParams();

  const { hasAccess, loading } = useHasAccess([
    async user => {
      if (!projectId) return false;
      const result = await thunkDispatch(setActiveProject(parseInt(projectId, 10)));
      const payload = result.payload as IProject;
      return payload.companyId === user.companiesIds[0];
    },
  ]);

  if (loading) return null;

  if (!hasAccess)
    return (
      <Box margin={1} paddingTop={8} paddingBottom={8}>
        <Container>
          <Typography variant="h4" align="center">
            {message}
          </Typography>
          <Typography variant="h5" align="center">
            <Link to={redirect}>{linkText}</Link>
          </Typography>
        </Container>
      </Box>
    );

  return children;
};

export default VerifyProjectOwnershipWrapper;
