import React, { useContext } from 'react';
import { IconButton, Box, Badge } from '@mui/material';
import { Close, Menu, NotificationsNoneOutlined } from '@mui/icons-material';
import UserDropDown from './UserDropDown';
import { ButtonUserTypeSwitcher } from 'sharedComponents/ButtonUserTypeSwitcherIcon';
import { MenuContext } from '../SideBar/menuContext';
import { NotificationButton } from '../../NotificationButton';
import { ISizedTopBarProps } from '.';
import { useNavigate } from 'react-router-dom';
import { selectNotifications } from 'sharedComponents/notificationStoreSlice';
import { useSelector } from 'react-redux';

const MobileTopBar = ({
  isSwitcherVisible,
  settingsPath,
  handleLogout,
  useNotificationButton,
  logoComponent,
}: ISizedTopBarProps) => {
  const navigate = useNavigate();
  const notifications = useSelector(selectNotifications);
  const { showMenu, setShowMenu } = useContext(MenuContext);
  return (
    <Box display="flex" width="100%" justifyContent="space-between">
      <Box display="flex">
        <IconButton onClick={() => setShowMenu(!showMenu)} sx={{ mr: 2 }}>
          {showMenu ? <Close /> : <Menu />}
        </IconButton>
        {logoComponent}
      </Box>
      <Box display="flex">
        {isSwitcherVisible && <ButtonUserTypeSwitcher />}
        {useNotificationButton && <NotificationButton />}
        <Badge badgeContent={notifications.length || ' '} overlap="circular">
          <IconButton color="primary" onClick={() => navigate('/notifications')}>
            <NotificationsNoneOutlined />
          </IconButton>
        </Badge>
        <UserDropDown handleLogout={handleLogout} settingsPath={settingsPath} />
      </Box>
    </Box>
  );
};

export default MobileTopBar;
