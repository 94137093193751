import { createSlice } from '@reduxjs/toolkit';
import { getCompanyMaterialsByCompanyId } from './actions';
import { RootState } from 'store/store';
import { TFormStatus } from 'lg-helpers/dist/shared/types/TFormStatus';
import { ITruckingCompanyMaterial } from 'lg-helpers/dist/trucking/interfaces/ITruckingCompanyMaterial';

export interface ICompanyMaterialsState {
  data: ITruckingCompanyMaterial[];
  status: TFormStatus;
}

const initialStateCompanyMaterials: ICompanyMaterialsState = {
  data: [],
  status: null,
};

export const companyMaterialsSlice = createSlice({
  name: 'companyMaterials',
  reducers: {},
  initialState: initialStateCompanyMaterials,
  extraReducers: builder => {
    builder
      .addCase(getCompanyMaterialsByCompanyId.pending, (state, {}) => {
        state.status = 'pending';
      })
      .addCase(getCompanyMaterialsByCompanyId.fulfilled, (state, { payload }) => {
        state.status = 'success';
        state.data = payload;
      })
      .addCase(getCompanyMaterialsByCompanyId.rejected, (state, { error }) => {
        state.status = 'failure';
        state.data = [] as ITruckingCompanyMaterial[];
        console.error('getCompanyMaterialsByCompanyId error: ', error);
      });
  },
});

export const selectCompanyMaterials = (state: RootState) => {
  return {
    companyMaterials: state.trucking.companyMaterials.data,
    companyMaterialsStatus: state.trucking.companyMaterials.status,
  };
};
