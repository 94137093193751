export default {
  __displayOptionsDefault: {
    color: '#000000',
    font: 'Arial',
    fontSize: 40,
    fontWeight: 'normal',
  },
  assignedAtTruckingTicket: {
    fontSize: 30,
    isDate: true,
    x: 445,
    y: 1515,
  },
  contractorProjectNumberTruckingProject: {
    maxWidth: 500,
    textAlign: 'right',
    x: 1170,
    y: 120,
  },
  dateTruckingTicket: {
    dateFormat: 'MM/DD/YYYY',
    dateZoneAbbr: false,
    fontSize: 30,
    isDate: true,
    maxWidth: 330,
    textAlign: 'center',
    x: 1025,
    y: 695,
  },
  deliveredAtTruckingTicket: {
    fontSize: 30,
    isDate: true,
    x: 445,
    y: 1570,
  },
  materialQuantityTruckingTicket: {
    conditions: {
      uomTruckingProjectMaterial: {
        Tons: {
          maxWidth: 500,
          textAlign: 'center',
          x: 400,
          y: 1120,
        },
        Yards: {
          maxWidth: 500,
          textAlign: 'center',
          x: 910,
          y: 1120,
        },
      },
    },
  },
  nameTruckingCompanyMaterial: { x: 80, y: 530 },
  nameTruckingProject: {
    conditions: {
      typeTruckingDispatch: { '!Import': { x: 470, y: 950 } },
    },
  },
  address: {
    conditions: {
      typeTruckingDispatch: { '!Import': { x: 470, y: 1000 } },
    },
  },
  pitTicketNumberTruckingTicket: { x: 660, y: 530 },
  projectNumberTruckingProject: {
    fontSize: 30,
    maxWidth: 330,
    textAlign: 'center',
    x: 645,
    y: 695,
  },
  'signatureDriver.url': {
    height: 160,
    isImage: true,
    width: 600,
    x: 150,
    y: 1730,
  },
  'signatureGenerator.url': {
    height: 160,
    isImage: true,
    width: 600,
    x: 150,
    y: 1840,
  },
  supplierAddress: {
    conditions: {
      typeTruckingDispatch: {
        '!Import': { x: 470, y: 900 },
        Import: { x: 470, y: 1000 },
      },
    },
  },
  supplierName: {
    conditions: {
      typeTruckingDispatch: {
        '!Import': { x: 470, y: 850 },
        Import: { x: 470, y: 950 },
      },
    },
  },
  truckNumberTruckingTicket: { fontSize: 30, x: 150, y: 695 },
  projectAddress: {
    conditions: {
      typeTruckingDispatch: {
        Import: { x: 470, y: 900 },
        '!Import': { x: 470, y: 1000 },
      },
    },
  },
  dispatchCompanyName: { x: 150, y: 800 },
};
