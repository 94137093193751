import React, { useMemo, useState } from 'react';
import {
  Typography,
  Grid,
  TextField,
  CircularProgress,
  Checkbox,
  Box,
  FormControlLabel,
  Autocomplete,
  Button,
} from '@mui/material';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import statesUSA from 'utils/statesUSA.json';
import { hasErrorRequired } from 'services/logic/formValidation';
import { UserTypes } from 'lg-helpers/dist/constants/user/UserTypes';
import PermissionsWrapper from 'sharedComponents/PermissionsWrapper';
import { usePermissionsContext } from 'services/permissions';

const InputTransporterCompany = ({
  isAuthUser,
  isRequired,
  canProjectViewAllManifests,
  wasSubmitted,
  getStateLabel,
  project,
  handleChange,
  handleChangeCanViewCheckbox,
  isLoadingCompanies,
  cTransporters,
  brokers,
  handleOpenCompanyContactModal,
}: any) => {
  const { user: authUser } = usePermissionsContext();
  const [transporterStateFilter, setTransporterStateFilter] = useState<any | null>(null);

  const transporters = useMemo(() => {
    const ids = new Set(brokers?.map((b: any) => b.id));
    return [...(cTransporters || []).filter((t: any) => !ids.has(t.id)), ...(brokers || [])];
  }, [cTransporters, brokers]);

  const filteredTransporters = transporterStateFilter
    ? transporters.filter((transporter: any) => transporter?.state === transporterStateFilter.value)
    : transporters;

  return (
    <Grid item xs={12}>
      <Grid container alignItems="center" spacing={2}>
        <Grid item xs={12}>
          <Box display="flex" justifyContent={'space-between'}>
            <Typography variant="h5">Transporter companies</Typography>
            <PermissionsWrapper
              canAccess={[
                UserTypes.admin.super,
                UserTypes.transporter.admin,
                UserTypes.scale.admin,
              ]}
            >
              <Button
                color="inherit"
                variant="outlined"
                onClick={() =>
                  handleOpenCompanyContactModal(
                    'Transporter',
                    ([UserTypes.admin.super, UserTypes.transporter.admin] as string[]).includes(
                      authUser.actingAsType
                    )
                  )
                }
              >
                Transporter Contacts
              </Button>
            </PermissionsWrapper>
          </Box>
        </Grid>

        <Grid item xs={12} md={3} sm={12}>
          <Autocomplete
            disabled={!isAuthUser.contractor && isAuthUser.readOnly}
            options={statesUSA}
            value={transporterStateFilter}
            isOptionEqualToValue={(option: any, value: any) =>
              option && value ? option.id === value.id : false
            }
            getOptionLabel={getStateLabel}
            onChange={(event: any, selectedValue: any) => setTransporterStateFilter(selectedValue)}
            renderInput={(params: any) => (
              <TextField
                {...params}
                variant="outlined"
                label="Filter Transporters by State"
                InputProps={{
                  ...params.InputProps,
                  endAdornment: <>{params.InputProps.endAdornment}</>,
                }}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} md={9}>
          <Autocomplete
            disabled={!isAuthUser.contractor && isAuthUser.readOnly}
            multiple
            options={filteredTransporters}
            value={project.transporterCompanies || []}
            isOptionEqualToValue={(option, value) =>
              value && option ? option.id === value.id : false
            }
            getOptionLabel={option => `${option.name}`}
            renderOption={(props, option, { selected }) => {
              return (
                <li {...props} key={option.id || ''}>
                  <Box
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                      width: '100%',
                    }}
                  >
                    <Box>
                      <Checkbox
                        icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                        checkedIcon={<CheckBoxIcon fontSize="small" />}
                        style={{ marginRight: 8 }}
                        checked={selected}
                        color="primary"
                      />
                      {option.name}
                    </Box>
                    <PermissionsWrapper
                      canAccess={[
                        UserTypes.admin.super,
                        UserTypes.contractor.user,
                        UserTypes.contractor.admin,
                      ]}
                    >
                      <Box marginRight={2}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                              checkedIcon={<CheckBoxIcon fontSize="small" />}
                              style={{ marginRight: 8 }}
                              checked={canProjectViewAllManifests[option.id] || false}
                              onClick={(e: any) => {
                                handleChangeCanViewCheckbox(option, e.target.checked);
                                e.stopPropagation();
                              }}
                              color="primary"
                            />
                          }
                          label="Can View All Manifests?"
                        />
                      </Box>
                    </PermissionsWrapper>
                  </Box>
                </li>
              );
            }}
            onChange={(event: any, value: any) =>
              handleChange({
                target: {
                  name: 'transporterCompanies',
                  value: value.map((x: any) => ({
                    id: x.id,
                    name: x.name,
                    canViewAllManifests: x.canViewAllManifests,
                  })),
                },
              })
            }
            renderInput={(params: any) => (
              <TextField
                {...params}
                variant="outlined"
                required
                label="Transporter Companies"
                placeholder="Select transporter companies"
                error={hasErrorRequired(project.transporterCompanies, wasSubmitted)}
                helperText={
                  hasErrorRequired(project.transporterCompanies, wasSubmitted) ? isRequired : ''
                }
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <>
                      {isLoadingCompanies && <CircularProgress color="inherit" size={20} />}
                      {params.InputProps.endAdornment}
                    </>
                  ),
                }}
              />
            )}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default InputTransporterCompany;
