import { createSlice } from '@reduxjs/toolkit';

export const superAdminUsersStoreSlice = createSlice({
  name: 'superAdminUsers',
  initialState: {
    usersSearchFilters: {},
  },
  reducers: {
    setUsersSearchFilters: (state, action) => {
      state.usersSearchFilters = action.payload;
    },
  },
});

export const { setUsersSearchFilters } = superAdminUsersStoreSlice.actions;

export const selectUsersSearchFilters = state => state.superAdminUsers.usersSearchFilters;

export default superAdminUsersStoreSlice.reducer;
