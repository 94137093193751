import { createSlice } from '@reduxjs/toolkit';
import {
  getInvoicesForProject,
  addInvoice,
  deleteInvoice,
  printReconciliationReport,
} from './actions';
import { IInvoice } from 'lg-helpers/dist/shared/interfaces/IInvoice';
import { TFormStatus } from 'lg-helpers/dist/shared/types/TFormStatus';

interface IInvoiceState {
  data: IInvoice[];
  reportUrl?: URL;
  ticket?: IInvoice;
  status: TFormStatus;
  error: string | undefined;
}

const initialState: IInvoiceState = {
  data: [],
  reportUrl: undefined,
  ticket: undefined,
  status: null,
  error: undefined,
};

export const invoiceSlice = createSlice({
  name: 'invoices',
  reducers: {
    add: state => state,
  },
  initialState,
  extraReducers: builder => {
    builder.addCase(getInvoicesForProject.fulfilled, (state, { payload }) => {
      state.status = 'success';
      state.data = payload;
    });

    builder.addCase(getInvoicesForProject.rejected, (state, payload) => {
      console.error('Error getting companies: ', payload.error);
      state.status = 'failure';
      state.error = payload.error?.message;
    });

    builder.addCase(addInvoice.fulfilled, (state, { payload }) => {
      state.status = 'success';
      const index = state.data.findIndex(item => item.id === payload?.id);
      index !== -1
        ? (state.data[index] = payload as IInvoice)
        : state.data.push(payload as IInvoice);
    });

    builder.addCase(deleteInvoice.fulfilled, (state, { payload }) => {
      state.status = 'success';
      const oldState = state.data;
      const newState = oldState.filter(item => item.id !== payload.id);
      state.data = newState;
    });

    builder.addCase(printReconciliationReport.fulfilled, (state, { payload }) => {
      state.status = 'success';
      state.reportUrl = payload.url;
    });
  },
});
