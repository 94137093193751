import React, { MouseEventHandler, useEffect, useState } from 'react';
import {
  Box,
  Button,
  CardHeader,
  TextField,
  Autocomplete,
  Grid,
  CircularProgress,
} from '@mui/material';
import AddBoxOutlinedIcon from '@mui/icons-material/AddBoxOutlined';
import { v4 as uuidv4 } from 'uuid';
import { ICustomInputDefinitions } from 'lg-helpers/dist/shared/interfaces/ICustomInputDefinitions';
import PermissionsWrapper from 'sharedComponents/PermissionsWrapper';
import api, { IGlobalCustomInputs } from 'services/api/autogenerated';

const inputDefinitionTypes = ['Text', 'List', 'Checkbox', 'Signature'];

interface CustomInputsDefinitionsCrudCardHeaderProps {
  customInputsDefinitions: ICustomInputDefinitions[];
  setCustomInputsDefinitions: (customInputsDefinitions: ICustomInputDefinitions[]) => void;
  setCurrentInputDefinitionId: (id: string) => void;
  setCanValidate: (canValidate: boolean) => void;
  isTypesSelectDisabled: boolean;
  setIsTypesSelectDisabled: (isTypesSelectDisabled: boolean) => void;
  generatorCompanyId?: string | null;
  handleSave?: MouseEventHandler<HTMLButtonElement> | null;
  isSaving?: boolean;
}

export const CustomInputsDefinitionsCrudCardHeader = ({
  customInputsDefinitions,
  setCustomInputsDefinitions,
  setCurrentInputDefinitionId,
  setCanValidate,
  isTypesSelectDisabled,
  setIsTypesSelectDisabled,
  generatorCompanyId,
  handleSave,
  isSaving,
}: CustomInputsDefinitionsCrudCardHeaderProps) => {
  const [customNewInputType, setCustomNewInputType] = useState<string | null>(null);
  const [globalCustomInputs, setGlobalCustomInputs] = useState<IGlobalCustomInputs[]>([]);
  const [selectedGlobalCustomInputs, setSelectedGlobalCustomInputs] =
    useState<IGlobalCustomInputs | null>(null);

  useEffect(() => {
    if (!generatorCompanyId) return;
    const fetchCustomInputs = async () => {
      try {
        const resultsCustomInputs = await api.customInputs.getCustomInputsByCompanyId(
          generatorCompanyId
        );
        setGlobalCustomInputs(
          resultsCustomInputs.data.map((input, i) => {
            return { ...input, columnIndex: i, order: i };
          }) as IGlobalCustomInputs[]
        );
      } catch (error) {
        console.error(error);
      }
    };
    fetchCustomInputs();
  }, [generatorCompanyId]);

  const addNewInputDefinition = () => {
    setIsTypesSelectDisabled(false);
    const id = uuidv4();
    setCurrentInputDefinitionId(id);
    let defaultCustomInput = {
      id,
      type: customNewInputType as string,
      isValid: false,
      isRequired: true,
      isHidden: false,
      defaultValue: '',
      columnIndex: customInputsDefinitions.length + 1,
      isValidCustomInputDefinition: false,
      label: '',
      propertyPathInManifestCustomInputs: '',
      userTypesCanEdit: [] as string[],
      userTypesCanOnlyRead: [] as string[],
      sectionsCanOnlyRead: [] as string[],
      sectionsCanEdit: [] as string[],
      order: 0,
    };

    if (selectedGlobalCustomInputs) {
      const {
        name,
        companiesIds,
        id: removedId,
        ...restSelectedGlobalCustomInputs
      } = selectedGlobalCustomInputs as IGlobalCustomInputs;
      defaultCustomInput = {
        ...restSelectedGlobalCustomInputs,
        columnIndex: customInputsDefinitions.length + 1,
        order: 0,
        id,
      } as ICustomInputDefinitions;
    }

    const newInputDefifitions = [defaultCustomInput, ...customInputsDefinitions].map(
      (inputDefinition: ICustomInputDefinitions, index) => ({
        ...inputDefinition,
        order: index + 1,
      })
    );

    setCustomInputsDefinitions(newInputDefifitions);

    setCustomNewInputType(null);
    setCanValidate(true);
  };

  return (
    <CardHeader
      title="Manifest Custom Inputs Definitions"
      action={
        handleSave ? (
          <Button color="primary" disabled={isSaving} variant="contained" onClick={handleSave}>
            Save Custom Inputs
            {isSaving && (
              <Box marginLeft={2}>
                <CircularProgress color="inherit" />
              </Box>
            )}
          </Button>
        ) : (
          ''
        )
      }
      subheader={
        <PermissionsWrapper canAccess={['Super Admin']}>
          <Grid
            container
            sx={{ marginTop: 2 }}
            columnSpacing={2}
            alignItems="center"
            justifyContent="center"
          >
            <Grid item xs={12} md={!!globalCustomInputs.length ? 4 : 8}>
              <Autocomplete
                fullWidth
                disabled={isTypesSelectDisabled}
                options={inputDefinitionTypes}
                value={customNewInputType}
                onChange={(event, value) => {
                  setSelectedGlobalCustomInputs(null);
                  setCustomNewInputType(value);
                }}
                renderInput={params => (
                  <TextField
                    {...params}
                    sx={{ margin: '0' }}
                    label="Add New Type"
                    required
                    variant="outlined"
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: <>{params.InputProps.endAdornment}</>,
                    }}
                  />
                )}
              />
            </Grid>
            {!!globalCustomInputs.length && (
              <>
                <Grid item xs={12} md={1} sx={{ textAlign: 'center' }}>
                  - OR -
                </Grid>
                <Grid item xs={12} md={4}>
                  <Autocomplete
                    fullWidth
                    disabled={isTypesSelectDisabled}
                    options={globalCustomInputs}
                    getOptionLabel={option =>
                      `${option?.name} - [${option?.type}] ${option?.label}`
                    }
                    value={selectedGlobalCustomInputs || null}
                    onChange={(event, value) => {
                      setCustomNewInputType(null);
                      setSelectedGlobalCustomInputs(value);
                    }}
                    renderOption={(props, option) => (
                      <li {...props} key={option.id || ''}>
                        {option.name} - [{option.type}] {option.label}
                      </li>
                    )}
                    renderInput={params => (
                      <TextField
                        {...params}
                        sx={{ margin: '0' }}
                        label="Global Custom Input Templates"
                        required
                        variant="outlined"
                        InputProps={{
                          ...params.InputProps,
                          endAdornment: <>{params.InputProps.endAdornment}</>,
                        }}
                      />
                    )}
                  />
                </Grid>
              </>
            )}
            <Grid item xs={12} md={3}>
              <Button
                color="primary"
                variant="contained"
                fullWidth
                onClick={addNewInputDefinition}
                disabled={!customNewInputType && !selectedGlobalCustomInputs}
              >
                <AddBoxOutlinedIcon color="inherit" />
                <Box marginLeft={1}>Add</Box>
              </Button>
            </Grid>
          </Grid>
        </PermissionsWrapper>
      }
    />
  );
};
