import React, { useState, useEffect, useMemo } from 'react';
import 'sass/globals.scss';
import { Container, Grid, Box, Button, CircularProgress, Card } from '@mui/material';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import Page from 'sharedComponents/Page';
import useShowError from 'modules/errors';
import Query from 'services/firebase/Query';
import Executer from 'services/firebase/Executer';
import { IUserCreation } from 'lg-helpers/dist/shared/interfaces/IUserCreation';
import api from 'services/api/autogenerated';
import { mixPanel, MixPanelOptions } from 'services/mixpanel';

import { sendActivateAccountEmail, sendActivateAccountSms } from 'api/auth';
import { useNavigate, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { selectAuthUser } from '../modules/auth/storeSliceAuth';
import UserForm from './UserForm';
import QueryBase from 'lg-helpers/dist/firestore/query/QueryBase';

const executer = new Executer();
const query = new Query();

const UserView = () => {
  let { id } = useParams();

  const navigate = useNavigate();
  const authUser = useSelector(selectAuthUser);

  const currentPath = '/' + window.location.pathname.split('/')[1];
  const authUserEditRoute = currentPath + '/user/';
  const authUserRoute = currentPath + '/users';
  const [isLoadingData, setIsLoadingData] = useState<boolean>(false);
  const showError = useShowError();
  const userCompanies = useMemo(() => (!id && authUser?.companies) || [], [id, authUser]);

  const [user, setUser] = useState<IUserCreation | null>({
    preferredLoginMethod: 'email',
    actingAsType: undefined,
    allowedUserTypes: [],
    companies: userCompanies,
    firstName: '',
    lastName: '',
    type: undefined,
    companiesIds: [],
    generator: false,
  });

  useEffect(() => {
    if (!id) {
      return;
    }

    const getUser = async (userId: string) => {
      setIsLoadingData(true);
      try {
        const userResult = (await executer.getSingleDocument(
          query.base.getById(QueryBase.USERS_COLLECTION(), userId)
        )) as IUserCreation;
        userResult.preferredLoginMethod =
          userResult?.preferredLoginMethod ||
          (!!userResult?.email && 'email') ||
          (!!userResult?.phone && 'phone') ||
          'email';
        setUser(prev => ({
          ...prev,
          ...userResult,
        }));
        if (!userResult) {
          showError({ title: "This user doesn't exist", duration: 10000 });
        }
      } catch (error) {
        console.error('Error', error);
        showError({
          title: 'Error while fetching the user',
          duration: 10000,
        });
      }
      setIsLoadingData(false);
    };

    setUser(null);
    getUser(id);
    // eslint-disable-next-line
  }, [id]);

  const submitSuccessCallback = async (newUser: IUserCreation) => {
    let newId: string | null = null;
    try {
      if (newUser?.id) {
        await api.users.update(newUser?.id, newUser);
      } else {
        const apiResponse = await api.users.create(newUser);
        const mixPanelOptions: MixPanelOptions = {
          'New User Id': apiResponse?.data?.id || '',
          'New User First Name': newUser?.firstName || '',
          'New User Last Name': newUser?.lastName || '',
          'New User Email': newUser?.email || '',
          'New User Type': newUser?.type || '',
          'New User Allowed Types': newUser?.allowedUserTypes?.join(', ') || '',
        };

        if (newUser?.companies && newUser?.companies[0]) {
          mixPanelOptions['New User Company Name'] = newUser?.companies[0]?.name || '';
          mixPanelOptions['New User Company Id'] = newUser?.companies[0]?.id || '';
        }

        mixPanel(authUser, `New user created`, mixPanelOptions);
        newId = apiResponse?.data?.id;
      }
      if (newId) {
        if (newUser?.email && newUser?.preferredLoginMethod === 'email') {
          try {
            await sendActivateAccountEmail(newId);
          } catch {
            showError({
              title: 'Send Activate email failed but the user was created successfully',
              duration: 10000,
            });
          }
          setTimeout(() => navigate(authUserEditRoute + newId!), 1500);
        }
        if (newUser?.phone && newUser?.preferredLoginMethod === 'phone') {
          try {
            await sendActivateAccountSms(newId);
          } catch {
            showError({
              title: 'Send Activate sms failed but the user was created successfully',
              duration: 10000,
            });
          }
          setTimeout(() => navigate(authUserEditRoute + newId!), 1500);
        }
      }
    } catch (error: any) {
      if (error?.response?.data?.message) {
        let text = error.response.data.message;
        switch (error.response.data?.code) {
          case 'auth/invalid-phone-number':
            text =
              'The phone number must use the following format [+] [country code] [subscriber number including area code]. 1 is the country code for the United States.';
            break;

          default:
            break;
        }
        showError({
          title: 'Error',
          text,
          duration: 10000,
        });
      }
      showError({ title: 'Save user error', duration: 10000 });
    }
  };

  return (
    <Page>
      <Card>
        <Container className="min-padding-and-height">
          <Grid container spacing={3}>
            <Grid item lg={6} md={6} xs={12}>
              <Button size="small" variant="outlined" onClick={() => navigate(authUserRoute)}>
                <Box display="flex" alignItems="center">
                  <NavigateBeforeIcon /> Back
                </Box>
              </Button>
            </Grid>
            {isLoadingData ? (
              <Grid item lg={12} md={12} xs={12}>
                <Box display="flex" justifyContent="center" alignItems="center">
                  <CircularProgress color="inherit" />
                </Box>
              </Grid>
            ) : (
              <UserForm userInfo={user} submitSuccessCallback={submitSuccessCallback} />
            )}
          </Grid>
        </Container>
      </Card>
    </Page>
  );
};

export default UserView;
