import React, { useEffect, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import {
  Grid,
  CircularProgress,
  Typography,
  Box,
  Button,
  Card,
  CardHeader,
  CardActions,
  CardContent,
  Divider,
} from '@mui/material';
import { selectAuthUser } from 'modules/auth/storeSliceAuth';
import { useSelector } from 'react-redux';
import ManifestDisplayWithTemplateDataGathering from 'sharedComponents/ManifestDisplayWithTemplateDataGathering';
import Query from 'services/firebase/Query';
import Command from 'services/firebase/Command';
import Executer from 'services/firebase/Executer';
import CustomInputsRenderer from 'sharedComponents/CustomInputsRenderer';
import { callV1ApiUrl } from 'services/api';
import { useConfirm } from 'material-ui-confirm';
import useShowError from 'modules/errors';
import OverlayWithMessage from 'sharedComponents/OverlayWithMessage';
import QueryBase from 'lg-helpers/dist/firestore/query/QueryBase';

const query = new Query();
const command = new Command();
const executer = new Executer();

const AssignSpecificManifestToDriver = ({ project }) => {
  const authUser = useSelector(selectAuthUser);
  const [isLoadingManifest, setIsLoadingManifest] = useState(false);
  const [manifest, setManifest] = useState(null);
  const showError = useShowError();
  const [secondsLeftToSign, setSecondsLeftToSign] = useState(null);
  const [isValidSectionCustomInputs, setIsValidSectionCustomInputs] = useState(false);
  const [driverData, setRequestingDriverData] = useState(null);
  const [isLoadingAllowDriver, setIsLoadingAllowDriver] = useState(false);
  const [isLoadingDenyDriver, setIsLoadingDenyDriver] = useState(false);
  useState(null);
  const [needReleaseUnsignedManifest, setNeedReleaseUnsignedManifest] = useState(false);
  const confirm = useConfirm();

  const getNextAvailableManifest = useCallback(async () => {
    setIsLoadingManifest(true);
    try {
      const unsignedManif = await executer.getSingleDocument(
        query.manifests.getNextUnsignedManifestForDriver(project.id)
      );
      if (!unsignedManif) {
        setManifest(null);
        setIsValidSectionCustomInputs(false);
        showError({ title: 'No available manifests!', duration: 10000 });
        setIsLoadingManifest(false);
      } else {
        try {
          await command.manifests.reserveAssignSpecificManifestToDriver(unsignedManif.id, authUser);
          setIsLoadingManifest(false);
          setNeedReleaseUnsignedManifest(false);
          setSecondsLeftToSign(60);
        } catch (error) {
          console.error('Error', error);
          showError({
            title: 'Error update requested manifest',
            duration: 10000,
          });
          setIsLoadingManifest(false);
        }

        setManifest(unsignedManif);
      }
    } catch (error) {
      console.error('Error', error);
      showError({ title: 'Error get manifest', duration: 10000 });
      setIsLoadingManifest(false);
    }
  }, [project, authUser, setIsLoadingManifest, setManifest, setSecondsLeftToSign, showError]);

  const resetManifestRequestedAtCountdown = useCallback(async () => {
    if (!authUser || !manifest) {
      return;
    }
    try {
      await command.manifests.markManifestWasRequestedNowByDriver(manifest.id);
      setSecondsLeftToSign(60);
    } catch {
      showError({ title: 'Error at manifest reset countdown' });
    }
  }, [manifest, setSecondsLeftToSign, showError, authUser]);

  useEffect(() => {
    if (project && authUser && !manifest) getNextAvailableManifest();
  }, [project, authUser, manifest, getNextAvailableManifest]);

  const releaseUnsignedManifest = async manifestId => {
    try {
      await command.manifests.releaseUnsignedManifestAssignSpecificManifestToDriver(manifestId);
      setManifest(null);
      setIsValidSectionCustomInputs(false);
    } catch (error) {
      console.error('Error', error);
      showError({ title: 'Error release manifest' });
    }
  };

  useEffect(() => {
    if (!manifest) {
      return;
    }

    return executer.watchSingleDocument(
      query.base.getById(QueryBase.MANIFESTS_COLLECTION(), manifest.id),
      manif => {
        const { assignSpecificManifestToDriver, signatureDriver } = manif;
        if (signatureDriver && signatureDriver.isSigned) {
          setManifest(null);
          setIsValidSectionCustomInputs(false);
          return;
        }

        if (assignSpecificManifestToDriver) {
          const { driverData: specificManifestDriverData } = assignSpecificManifestToDriver;
          setRequestingDriverData(specificManifestDriverData);
        }
      },
      error => {
        console.error('Error', error);
        showError({
          title: 'Error while fetching the manifest',
          duration: 10000,
        });
      }
    );
  }, [manifest, showError]);

  const openAlertUnsignedManifest = async manifestId => {
    try {
      await confirm({
        title: '',
        content: 'Waiting on Driver to sign the manifests.',
        confirmationText: 'Cancel',
        cancellationText: null,
        cancellationButtonProps: {
          disabled: true,
        },
      });
    } catch (e) {
      console.error('openAlertUnsignedManifest() Error:', e);
    }
    releaseUnsignedManifest(manifestId);
  };

  useEffect(() => {
    if (secondsLeftToSign === null) {
      return;
    }

    if (needReleaseUnsignedManifest && secondsLeftToSign === 45 && manifest) {
      openAlertUnsignedManifest(manifest.id);
    }

    if (secondsLeftToSign === 15) {
      resetManifestRequestedAtCountdown();
      setSecondsLeftToSign(60);
      if (driverData && manifest?.assignSpecificManifestToDriver?.assignedByUserName) {
        setNeedReleaseUnsignedManifest(true);
      }

      return;
    }

    const timeout = setTimeout(() => setSecondsLeftToSign(secondsLeftToSign - 1), 1000);
    return () => clearTimeout(timeout);
    // eslint-disable-next-line
  }, [
    secondsLeftToSign,
    resetManifestRequestedAtCountdown,
    driverData,
    manifest,
    needReleaseUnsignedManifest,
  ]);

  const setIsValidSectionCustomInputsCallback = value => {
    setIsValidSectionCustomInputs(value);
  };

  const CustomInputsRendererComponent = React.useMemo(
    () => (
      <CustomInputsRenderer
        section="Assign Specific Manifest to Driver"
        manifest={manifest}
        setIsValid={setIsValidSectionCustomInputsCallback}
      />
    ),
    [manifest]
  );

  const handleAllowDriver = async () => {
    setIsLoadingAllowDriver(true);
    try {
      await command.manifests.allowAssignSpecificManifestToDriver(manifest.id, authUser);
    } catch {
      showError({ title: 'Error allowing driver' });
    } finally {
      setIsLoadingAllowDriver(false);
    }

    try {
      if (
        manifest.assignSpecificManifestToDriver &&
        manifest.assignSpecificManifestToDriver.isV1ApiCivilAndTrucking &&
        manifest.assignSpecificManifestToDriver.v1ApiCompletedCallBackUrl
      ) {
        await callV1ApiUrl(manifest.assignSpecificManifestToDriver.v1ApiCompletedCallBackUrl);
      }
    } catch (err) {
      console.error(err);
      showError({ title: 'Error calling v1 callback url' });
    }
  };

  const handleDenyDriver = async () => {
    setIsLoadingDenyDriver(true);
    try {
      await command.manifests.denyAssignSpecificManifestToDriver(manifest.id, authUser);
    } catch (err) {
      console.error(err);
      showError({ title: 'Error denying driver' });
    } finally {
      setIsLoadingDenyDriver(false);
    }
  };

  return (
    <Grid container spacing={3}>
      <OverlayWithMessage
        title="Please wait! Waiting for driver signature..."
        subtitle="Do not leave this page"
        visible={manifest?.assignSpecificManifestToDriver?.allowedAt}
        loader
      />

      {isLoadingManifest ? (
        <Grid item xs={12}>
          <Box display="flex" justifyContent="center" alignItems="center">
            <CircularProgress color="inherit" />
          </Box>
        </Grid>
      ) : (
        <>
          {manifest && (
            <>
              {!driverData && (
                <Grid item xs={12}>
                  <Card>
                    <CardHeader title="Waiting for driver to enter specific manifest number" />
                  </Card>
                </Grid>
              )}

              {driverData && (
                <Grid item xs={12}>
                  <Card>
                    <CardHeader title="A driver requests to take this manifest" />
                    <Divider />
                    <CardContent>
                      <Grid container spacing={1}>
                        <Grid item xs={6}>
                          <Typography align="right">Transporter:</Typography>
                        </Grid>
                        <Grid item xs={6}>
                          <Typography align="left">
                            <Box fontWeight="fontWeightBold" marginLeft={1}>
                              {driverData.truckingCompany}
                            </Box>
                          </Typography>
                        </Grid>
                        <Grid item xs={6}>
                          <Typography align="right">Truck#:</Typography>
                        </Grid>
                        <Grid item xs={6}>
                          <Typography align="left">
                            <Box fontWeight="fontWeightBold" marginLeft={1}>
                              {driverData.truckNumber}
                            </Box>
                          </Typography>
                        </Grid>
                        <Grid item xs={6}>
                          <Typography align="right">Driver:</Typography>
                        </Grid>
                        <Grid item xs={6}>
                          <Typography align="left">
                            <Box fontWeight="fontWeightBold" marginLeft={1}>
                              {driverData.driverName}
                            </Box>
                          </Typography>
                        </Grid>
                        <Grid item xs={6}>
                          <Typography align="right">Manifest#:</Typography>
                        </Grid>
                        <Grid item xs={6}>
                          <Typography align="left">
                            <Box fontWeight="fontWeightBold" marginLeft={1}>
                              {manifest?.number}
                            </Box>
                          </Typography>
                        </Grid>
                      </Grid>
                    </CardContent>
                    <CardActions>
                      <Box display="flex" justifyContent="space-between" alignItems="center">
                        <Button
                          onClick={handleDenyDriver}
                          disabled={isLoadingDenyDriver || isLoadingAllowDriver}
                          variant="outlined"
                          color="secondary"
                        >
                          Deny
                          {isLoadingDenyDriver && (
                            <Box marginLeft={2}>
                              <CircularProgress size="15px" color="inherit" />
                            </Box>
                          )}
                        </Button>
                        <Button
                          autoFocus
                          onClick={handleAllowDriver}
                          color="primary"
                          variant="contained"
                          disabled={isLoadingAllowDriver || isLoadingDenyDriver}
                        >
                          Allow
                          {isLoadingAllowDriver && (
                            <Box marginLeft={2}>
                              <CircularProgress size="15px" color="inherit" />
                            </Box>
                          )}
                        </Button>
                      </Box>
                    </CardActions>
                  </Card>
                </Grid>
              )}
              <Grid item xs={12}>
                {CustomInputsRendererComponent}
              </Grid>
              {isValidSectionCustomInputs && (
                <Grid item xs={12}>
                  <ManifestDisplayWithTemplateDataGathering
                    hideReceipt={true}
                    manifestId={manifest.id}
                    onManifestUpdateReceivedCallback={setManifest}
                  />
                </Grid>
              )}
            </>
          )}
        </>
      )}
    </Grid>
  );
};

AssignSpecificManifestToDriver.propTypes = {
  project: PropTypes.object,
};

export default AssignSpecificManifestToDriver;
