import axios from 'axios';
import { auth } from 'services/firebase';

axios.defaults.baseURL = process.env.REACT_APP_API_BASE_URL;

const setUserPassword = async (password: string) => {
  try {
    const token = await auth.currentUser!.getIdToken(true);
    const response = await axios.post(
      '/users/set-user-password',
      {
        password,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (err) {
    console.error(err);
    throw err;
  }
};

const deleteUser = async (id: string) => {
  try {
    const token = await auth.currentUser!.getIdToken(true);
    const response = await axios.post(`users/delete/${id}`, null, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (err) {
    console.error(err);
  }
};

const sendActivateAccountSms = async (userId: string) => {
  try {
    const token = await auth.currentUser!.getIdToken(true);
    const response = await axios.post(
      '/sms/send-notification-activate-account',
      {
        userId,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (err) {
    console.error(err);
  }
};

const sendActivateAccountEmail = async (userId: string) => {
  try {
    const token = await auth.currentUser!.getIdToken(true);
    const response = await axios.post(
      '/email/send-notification-activate-account',
      {
        userId,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (err) {
    console.error(err);
  }
};

const sendSmsWithInitialPassword = async (userId: string) => {
  try {
    const token = await auth.currentUser!.getIdToken(true);
    const response = await axios.post(
      '/sms/send-initial-password',
      {
        userId,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (err) {
    console.error(err);
  }
};

export {
  deleteUser,
  sendActivateAccountEmail,
  sendActivateAccountSms,
  setUserPassword,
  sendSmsWithInitialPassword,
};
