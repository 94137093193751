import React from 'react';
/* import clsx from 'clsx';
import { Typography, Box, makeStyles } from '@material-ui/core';
import { DataGrid } from '@mui/x-data-grid';
import { ArrowDropDown, ArrowDropUp } from '@material-ui/icons'; */

/*
const useStyles = makeStyles(theme => ({
  neutralText: {
    textTransform: 'uppercase',
    color: theme.palette.primary.dark,
    marginBottom: '1rem',
  },
 dataGrid: {
    backgroundColor: '#FFF',
  },
}));
*/

const EfficienciesView = () => {
  // const classes = useStyles();
  return (
    <>
      {/* <Box
        display="flex"
        justifyContent="space-between"
        alignContent="center"
      >
        <Typography variant="h5" className={clsx(classes.neutralText)}>
          Efficiencies
        </Typography>

      </Box>
      <DataGrid
        style={{ minWidth: 400 }}
        rows={[]}
        columns={[
          {
            field: 'companyName',
            headerName: 'Company',
            width: 250,
          },
          {
            field: 'efficiency',
            headerName: 'Travel Efficiency',
            width: 200,
          },
        ]}
        disableColumnMenu={true}
        components={{
          Header: () => <Typography variant="h1" />,
          ColumnSortedAscendingIcon: ArrowDropUp,
          ColumnSortedDescendingIcon: ArrowDropDown,
          ColumnResizeIcon: () => <div></div>,
          Pagination: () => <div></div>,
        }}
      /> */}
    </>
  );
};

export default EfficienciesView;
