import React, { useEffect, useState } from 'react';
import 'mapbox-gl/dist/mapbox-gl.css';
import Mapbox from 'sharedComponents/Mapbox';
import { Grid } from '@mui/material';
import { useParams } from 'react-router-dom';
import { generateIconMarker } from 'sharedComponents/MapboxHelpers';
import Query from 'services/firebase/Query';
import Executer from 'services/firebase/Executer';
import { useSelector } from 'react-redux';
import { thunkDispatch } from 'store/store';
import { selectJoinedDispatch } from '../../redux/dispatchStoreSlice';
import { getJoinedDispatchByDispatchId } from '../../redux/actions';
import { getLocationByLocationId } from '../../../Location/redux/actions';
import { selectLocation } from '../../../Location/redux/locationStoreSlice';
import ButtonLink from 'sharedComponents/ButtonLink';
import mapboxgl from 'mapbox-gl';
import { FirestoreTruckingTicket } from 'lg-helpers/dist/shared/types/trucking-tickets';

const query = new Query();
const executer = new Executer();

export const DispatchMapView = () => {
  let { dispatchId } = useParams();
  const [driverMarkers, setDriverMarkers] = useState<mapboxgl.Marker[]>([]);
  const [siteMarker, setSiteMarker] = useState<mapboxgl.Marker[]>([]);
  const { joinedDispatch } = useSelector(selectJoinedDispatch);
  const { location } = useSelector(selectLocation);

  useEffect(() => {
    if (!dispatchId) return;
    thunkDispatch(getJoinedDispatchByDispatchId(Number(dispatchId)));

    return executer.watchMultipleDocuments(
      query.truckingTickets.getTruckTicketsInDispatch(dispatchId),
      ticketsRes => {
        const tickets = ticketsRes as FirestoreTruckingTicket[];
        const ticketMarkers = tickets.map(ticket => {
          const { ticketNumber, userName, lastGPSLong, lastGPSLat } = ticket;
          const popupHtml = `<p><b>Ticket: ${ticketNumber}</b></p><p>${userName}</p>`;

          return generateIconMarker(
            { longitude: lastGPSLong, latitude: lastGPSLat },
            '/static/images/icons/truck.svg',
            popupHtml
          );
        });

        setDriverMarkers(ticketMarkers);
      },
      e => {
        console.error('Error fetching tickets in dispatch: ', e);
      }
    );
  }, [dispatchId]);

  useEffect(() => {
    const siteLocationId =
      joinedDispatch.locationIdTruckingProject || joinedDispatch.manualLocationIdTruckingDispatch;
    if (!siteLocationId) return;
    thunkDispatch(getLocationByLocationId(siteLocationId));
  }, [joinedDispatch.locationIdTruckingProject, joinedDispatch.manualLocationIdTruckingDispatch]);

  useEffect(() => {
    if (!location.id) return;

    const marker = generateIconMarker(
      {
        longitude: location.stY || 50,
        latitude: location.stX || -55,
      },
      '/static/images/excavator.png',
      `<p><b>Address: ${location.addressString || 'n/a'}</b></p>` || ''
    );

    setSiteMarker([marker]);
  }, [location]);

  return (
    <Grid container>
      <ButtonLink />
      <Grid item xs={12}>
        <Mapbox
          zoom={12}
          center={[location.stY || 50, location.stX || -55]}
          markers={driverMarkers?.concat(siteMarker)}
        />
      </Grid>
    </Grid>
  );
};
