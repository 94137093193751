const getBase64FromUrl = async imageUrl => {
  const res = await fetch(imageUrl);
  const blob = await res.blob();

  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.addEventListener(
      'load',
      function () {
        resolve(reader.result);
      },
      false
    );

    reader.onerror = () => {
      return reject(this);
    };
    reader.readAsDataURL(blob);
  });
};

const drawCanvasImageFromBase64 = (context, imgBase64, width, height, x, y) => {
  return new Promise((resolve, reject) => {
    try {
      const img = new Image();
      img.onload = function () {
        const originalWidth = img.width;
        const originalHeight = img.height;
        if (originalWidth && width && !height) {
          height = (width * originalHeight) / originalWidth;
        }
        if (originalHeight && height && !width) {
          width = (height * originalWidth) / originalHeight;
        }
        context.drawImage(img, x, y, width, height);
        resolve(true);
      };
      img.src = imgBase64;
    } catch (err) {
      reject(err);
    }
  });
};

export { getBase64FromUrl, drawCanvasImageFromBase64 };
