import { createAsyncThunk } from '@reduxjs/toolkit';
import { INotification } from 'modules/civilAndTrucking/shared/NotificationSnackbar/INotification';
import { pushNotification } from 'modules/civilAndTrucking/shared/NotificationSnackbar/redux/actions';
import ApiBase from 'services/postgres/ApiBase';
import Tables from 'services/postgres/Tables';
import { pushError } from 'modules/errors/storeSliceErrors';
import { IJoinProjectMaterial } from 'lg-helpers/dist/shared/interfaces/IJoinProjectMaterial';
import { ITruckingProjectMaterial } from 'lg-helpers/dist/trucking/interfaces/ITruckingProjectMaterial';

const apiBase = new ApiBase();
const PROJECT_MATERIAL_TABLE = Tables.TruckingProjectMaterial;

export const getJoinedProjectMaterialsByProjectId = createAsyncThunk(
  'project-materials/get-joined-project-materials',
  async (projectId: number, thunkApi) => {
    try {
      const data = await apiBase.getList(
        `${PROJECT_MATERIAL_TABLE}/project/joined-materials/${projectId}`
      );
      return data as IJoinProjectMaterial[];
    } catch (err) {
      thunkApi.dispatch(pushError({ title: 'Error: Unable to get project materials' }));
      throw err;
    }
  }
);

export const getProjectMaterialsByProjectId = createAsyncThunk(
  'project-materials/get-project-materials',
  async (projectId: string | number, thunkApi) => {
    try {
      const data = await apiBase.getList(`${PROJECT_MATERIAL_TABLE}/project/${projectId}`);
      return data as ITruckingProjectMaterial[];
    } catch (err) {
      const errorNotification: INotification = {
        status: 'error',
        message: 'Error: Unable to get project materials',
      };
      thunkApi.dispatch(pushNotification(errorNotification));
      throw err;
    }
  }
);

export const upsertProjectMaterial = createAsyncThunk(
  'project-materials/set-project-materials',
  async (projectMaterial: Partial<ITruckingProjectMaterial>, thunkApi) => {
    try {
      await apiBase.insertOrUpdate(
        {
          ...projectMaterial,
        },
        PROJECT_MATERIAL_TABLE
      );
      const successNotification: INotification = {
        status: 'success',
        message: 'Success: Project material has been created or updated',
      };
      thunkApi.dispatch(pushNotification(successNotification));
    } catch (err) {
      thunkApi.dispatch(pushError({ title: 'Error: Unable to create new project material' }));
      throw err;
    }
  }
);

export const deleteProjectMaterial = createAsyncThunk(
  'project-material/delete-project-material',
  async (payload: { projectMaterialId: number; projectId: number | string }, thunkApi) => {
    try {
      await apiBase.deleteById(payload.projectMaterialId, PROJECT_MATERIAL_TABLE);
      const successNotification: INotification = {
        status: 'success',
        message: 'Success: Project material has been deleted',
      };
      thunkApi.dispatch(pushNotification(successNotification));
      thunkApi.dispatch(getProjectMaterialsByProjectId(payload.projectId));
    } catch (err) {
      const errorNotification: INotification = {
        status: 'error',
        message: 'Error: Unable to delete truck type',
      };
      thunkApi.dispatch(pushNotification(errorNotification));
    }
  }
);
