import React, { HTMLAttributes, useState, useMemo } from 'react';
import { useSelector } from 'react-redux';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import debounce from 'lodash/debounce';
import { ICompany } from 'lg-helpers/dist/shared/interfaces/ICompany';
import { getQueriedCompaniesByCompanyTypes } from 'modules/civilAndTrucking/trucking/features/Companies/redux/actions';
import { selectCompanies } from 'modules/civilAndTrucking/trucking/features/Companies/redux/companiesStoreSlice';
import { thunkDispatch } from 'store/store';
import { MapTruckingCompanyToCompanyType } from 'services/constants/companyTypes';
import api, { ConnectedCompanyType } from 'services/api/autogenerated';
import { usePermissionsContext } from 'services/permissions';
import useShowError from 'modules/errors';

type CompanySearchProps = {
  globalCompanyType: ConnectedCompanyType;
  localCompanyType: ConnectedCompanyType;
  companyTypeName: string;
  companyTypeNamePlural: string;
  refreshConnectedCompanies: () => any;
};

export const SearchAndAddCompany = ({
  globalCompanyType,
  localCompanyType,
  companyTypeName,
  companyTypeNamePlural,
  refreshConnectedCompanies,
}: CompanySearchProps) => {
  const [inputValue, setInputValue] = useState<string>('');
  const [value, setValue] = useState<ICompany>();
  const [optionsLoading, setOptionsLoading] = React.useState<boolean>(false);
  const { companies } = useSelector(selectCompanies);
  const { user } = usePermissionsContext();
  const showError = useShowError();

  const handleAddContractor = async () => {
    try {
      if (!value?.id) return;
      await api.companiesConnections.createCompanyConnection({
        custom_id: '',
        company_type: globalCompanyType,
        connected_company_id: value.id,
        parent_company_id: user.companiesIds[0],
      });
      await refreshConnectedCompanies();
      setValue(undefined);
      setInputValue('');
    } catch (err) {
      console.error('Failed to add connection', err);
      showError({
        title: 'Failed to add connection',
        text: 'Maybe this connection already exists',
      });
    }
  };

  const updateOptions = React.useMemo(
    () =>
      debounce(async (request: { input: string }) => {
        await thunkDispatch(
          getQueriedCompaniesByCompanyTypes({
            companyTypes: [
              MapTruckingCompanyToCompanyType(localCompanyType),
              MapTruckingCompanyToCompanyType(globalCompanyType),
            ],
            query: request.input,
          })
        );
        setOptionsLoading(false);
      }, 500),
    // eslint-disable-next-line
    []
  );

  useMemo(() => {
    if (inputValue?.length >= 3 && !value) {
      setOptionsLoading(true);
      updateOptions({ input: inputValue });
    }
  }, [inputValue, value, updateOptions]);

  return (
    <Box display="flex" alignItems="center" justifyContent="center" flexDirection="row">
      <Autocomplete
        freeSolo
        value={value || ''}
        size="small"
        inputValue={inputValue || ''}
        sx={{ width: 300 }}
        loading={optionsLoading}
        loadingText="Loading..."
        options={inputValue.length > 3 ? companies : []}
        getOptionLabel={option => (typeof option === 'string' ? option : option.name)}
        onInputChange={(event, newValue) => {
          setInputValue(newValue);
          value && setValue(undefined);
        }}
        onChange={(event, val) => {
          const newValue = val as ICompany;
          setValue(newValue);
        }}
        renderInput={params => (
          <TextField {...params} placeholder={`Add Existing ${companyTypeNamePlural}`} />
        )}
        renderOption={(props: HTMLAttributes<HTMLLIElement>, option: ICompany) => (
          <li {...props} key={option.id}>
            <Box>
              <Typography noWrap sx={{ flex: 1 }}>
                {option.name}
              </Typography>
              <Typography noWrap sx={{ flex: 1 }}>
                {option.city}, {option.state}
              </Typography>
            </Box>
          </li>
        )}
      />
      <Button
        disabled={!value}
        color="primary"
        variant="contained"
        sx={{ ml: 1 }}
        onClick={handleAddContractor}
      >
        Add Existing {companyTypeName}
      </Button>
    </Box>
  );
};
