import React, { useContext, useEffect } from 'react';
import { thunkDispatch } from 'store/store';
import { ModalContext } from 'sharedComponents/ModalContext';
import { useParams } from 'react-router-dom';
import { getJoinedTrucksByDivisionId } from 'modules/civilAndTrucking/trucking/features/Trucks/redux/actions';
import { UpsertTrucksToDivisionForm } from './components/UpsertTrucksToDivisionForm';
import { ManageHeader } from 'sharedComponents/ManageHeader';
import { DivisionTrucksList } from './components/DivisionTrucksList';

export const DivisionView = () => {
  const { divisionId } = useParams();
  const { handleModal } = useContext(ModalContext);

  useEffect(() => {
    thunkDispatch(getJoinedTrucksByDivisionId(Number(divisionId)));
  }, [divisionId]);

  const showModal = () => {
    handleModal(<UpsertTrucksToDivisionForm divisionId={Number(divisionId)} />);
  };

  return (
    <>
      <ManageHeader
        headerTitle={'Manage Division'}
        buttonText={'Add Trucks to Division'}
        onButtonClick={showModal}
      />
      <DivisionTrucksList />
    </>
  );
};
