import React, { useState, useEffect, useMemo } from 'react';
import { Box } from '@mui/material';
import useShowError from 'modules/errors';
import { fetchTemplateDisplayHTML } from 'services/api';
import Hider from './Hider';

const TemplateDisplay = ({
  template,
}: {
  template: { [any: string]: any };
  isPreviewTemplate: boolean;
}) => {
  const showError = useShowError();
  const [isLoading, setIsLoadingData] = useState(false);
  const [templateHtml, setManifestTemplateHtml] = useState('');

  useMemo(
    () =>
      fetchTemplateDisplayHTML(template)
        .then((html: string) => {
          setIsLoadingData(false);
          if (typeof html === 'string') setManifestTemplateHtml(html);
        })
        .catch((err: any) => {
          console.error(err);
          showError({
            title: err,
            duration: 10000,
          });
        }),
    [template]
  );

  return (
    <Box display="flex" justifyContent="center">
      <Hider showContent={!!templateHtml} showLoading={isLoading}>
        <iframe className="lg-iframe" srcDoc={templateHtml} />
      </Hider>
    </Box>
  );
};

export default TemplateDisplay;
