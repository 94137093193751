import React, { useCallback } from 'react';
import {
  Grid,
  Typography,
  ListItem,
  ListItemAvatar,
  ListItemText,
  IconButton,
} from '@mui/material';
import NotificationIcon from './NotificationIcon';
import moment from 'moment';
import UserNotificationRequestPrompt from './UserNotificationRequestPrompt';
import Command from 'services/firebase/Command';
import ClearIcon from '@mui/icons-material/Clear';
import QueryBase from 'lg-helpers/dist/firestore/query/QueryBase';

const command = new Command();

interface DateTimeStamp {
  seconds: number;
  nanoseconds: number;
}

interface CustomData {
  [any: string]: any;
}

export interface Notification {
  id?: string | number;
  createdAt: DateTimeStamp;
  headline: string;
  details: string;
  notificationType: string;
  customData?: CustomData;
  isHidden?: boolean;
}

export interface NotificationItemInterface {
  notification: Notification;
}

function NotificationItem({ notification }: NotificationItemInterface) {
  const { createdAt, headline, details, notificationType } = notification;
  const formattedTime = moment(createdAt?.seconds * 1000)?.format('MMM DD, YYYY [at] h:mma');

  const handleHide = useCallback(
    () =>
      (async () => {
        try {
          const { id } = notification;
          await command.base.update(QueryBase.NOTIFICATIONS_COLLECTION(), String(id ?? ''), {
            ...notification,
            isHidden: true,
          });
        } catch (error) {
          console.error(error);
        }
        return null;
      })(),
    [notification]
  );

  return (
    <ListItem alignItems="flex-start" className="Notification--item" disableGutters>
      <ListItemAvatar>
        <NotificationIcon icon={notificationType} />
      </ListItemAvatar>
      <ListItemText
        primary={
          <Grid container alignItems="center">
            <Grid xs={11} item>
              <Typography color="primary" variant="h4" role="Notification--title">
                {headline}
              </Typography>
              <Typography variant="body2" role="Notification--details">
                {details}
              </Typography>
              {notificationType === 'VIEWER_ACCESS_REQUEST' && (
                <UserNotificationRequestPrompt
                  notification={notification}
                  handleHide={handleHide}
                />
              )}
            </Grid>
            <Grid xs={1} alignSelf="flex-start" item>
              <IconButton color="error" onClick={() => handleHide()}>
                <ClearIcon />
              </IconButton>
            </Grid>
          </Grid>
        }
        secondary={formattedTime}
      />
    </ListItem>
  );
}

export default NotificationItem;
