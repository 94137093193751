import React from 'react';
import PropTypes from 'prop-types';

const TrafficLightsRed = ({ className }) => (
  <img
    width={220}
    className={className}
    alt="truck-front"
    src="/static/images/traffic-lights-red.gif"
  />
);

TrafficLightsRed.propTypes = {
  className: PropTypes.string,
};

export default TrafficLightsRed;
