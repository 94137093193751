import React, { HTMLAttributes, useContext, useEffect, useState } from 'react';
import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  MenuItem,
  TextField,
  Typography,
  Autocomplete,
  useTheme,
  useMediaQuery,
  Switch,
} from '@mui/material';
import { useSelector } from 'react-redux';
import { RootState, thunkDispatch } from 'store/store';
import { useFormik } from 'formik';
import { projectMaterialsSchema } from './schema';
import {
  archiveAndUpdateProjectMaterial,
  getProfileIds,
  getProjectMaterials,
} from '../redux/actions';
import moment from 'moment';
import { useParams } from 'react-router-dom';
import AddMaterialsModal from 'modules/civilAndTrucking/civil/CompanyMaterials/AddMaterialModal';
import { ModalContext } from 'sharedComponents/ModalContext';
import SearchSources from 'modules/civilAndTrucking/civil/Sources/SearchSources';
import { TProfileNumber } from '../redux/projectMaterialsSlice';
import { getUtcStartOfDay, getUtcEndOfDay } from '../../utils/time';
import { unitsOfMeasure } from 'lg-helpers/dist/constants/global-constants';
import { LoadingComponent } from 'sharedComponents/LoadingComponent';
import { getCompanyById } from '../../Companies/redux/actions';
import { IJoinCivilCompanyWithData } from 'lg-helpers/dist/civil/interfaces/IJoinCivilLocation';
import { IProjectMaterial } from 'lg-helpers/dist/shared/interfaces/IProjectMaterial';
import { ICompanyMaterial } from 'lg-helpers/dist/shared/interfaces/ICompanyMaterial';
import { CompanyConnectionWithCompany } from 'services/api/autogenerated';
import { IFilter } from '../ProjectMaterialsDataGrid';
import api, { ConnectedCompanyType } from 'services/api/autogenerated';
import useApiGetRequest from 'services/api/useApiGetRequest';
import fuzzyFilterOptions from 'utils/fuzzyFilterOptions';

interface ProjectMaterialFormProps {
  selected?: IProjectMaterial;
  setSelected: Function;
  setFilter: React.Dispatch<React.SetStateAction<IFilter | undefined>>;
  filter?: IFilter | undefined;
  type: 'active' | 'all';
}

const materialFilterOptions = fuzzyFilterOptions<ICompanyMaterial>(['name']);

const sourcesFilterOptions = fuzzyFilterOptions<CompanyConnectionWithCompany>([
  'connected_company.name',
]);

const ProjectMaterialForm = ({
  selected,
  setSelected,
  setFilter,
  filter,
  type,
}: ProjectMaterialFormProps) => {
  const { projectId } = useParams();
  const [createNewRecord, setCreateNewRecord] = useState<boolean>(true);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [materialValue, setMaterialValue] = useState<ICompanyMaterial>();
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [materialInputValue, setMaterialInputValue] = useState<string>();
  const [sourceValue, setSourceValue] = useState<CompanyConnectionWithCompany>();
  const [sourceInputValue, setSourceInputValue] = useState<string>();
  const [dateActive, setDateActive] = useState<Date | string>(
    moment(new Date()).format('YYYY-MM-DD')
  );
  const { handleModal } = useContext(ModalContext);
  const [profileIds, setProfileIds] = useState<TProfileNumber[]>();
  const [activeMaterials, setActiveMaterials] = useState<ICompanyMaterial[]>([]);
  const [showJobNumber, setShowJobNumber] = useState<boolean>(false);
  const theme = useTheme();
  const isWidthXSorSM = useMediaQuery(theme.breakpoints.down('md'));

  const [timeZone, setTimeZone] = useState<string>(
    Intl.DateTimeFormat().resolvedOptions().timeZone
  );

  const materialsState = useSelector((state: RootState) => state.civil.companyMaterials.data);
  const projectMaterialsState = useSelector(
    (state: RootState) => state.civil.projectMaterials.data
  );
  const companyState = useSelector((state: RootState) => state.civil.companies.activeCompany);
  const profileIdsState = useSelector(
    (state: RootState) => state.civil.projectMaterials.profileIds
  );

  const activeProject = useSelector((state: RootState) => state.civil.projects.activeProject);

  // TODO: this list could be large (optimize in future)
  const [sourcesState] = useApiGetRequest(
    () =>
      api.companiesConnections.getConnectedCompaniesByTypes([
        ConnectedCompanyType.SourceSupplier,
        ConnectedCompanyType.TruckingSourceSupplier,
      ]),
    () => ({ title: 'Failed to load source suppliers' })
  );

  const formik = useFormik({
    initialValues: selected
      ? selected
      : ({
          dateActive: moment(new Date()).format('YYYY-MM-DD'),
        } as IProjectMaterial),
    validationSchema: projectMaterialsSchema,
    enableReinitialize: true,
    onSubmit: async () => {
      delete values.dateActiveValidation;
      if (!values.id && createNewRecord) {
        // TODO: strange formik mutation
        values.dateActive = getUtcStartOfDay(moment(new Date()).format('YYYY-MM-DD'), timeZone);
      }
      try {
        setIsSaving(true);

        const {
          count, // eslint-disable-line
          dateActiveString, // eslint-disable-line
          dateExpiredString, // eslint-disable-line
          material, // eslint-disable-line
          sourceSupplier, // eslint-disable-line
          ...valuesRest
        } = values;

        const result = await thunkDispatch(
          archiveAndUpdateProjectMaterial({
            ...valuesRest,
            projectId: Number(projectId),
          })
        );
        if (result.meta.requestStatus === 'fulfilled') {
          thunkDispatch(getProjectMaterials({ projectId: Number(projectId), type: type }));
        }
        setIsSaving(false);
        setCreateNewRecord(true);
        setSelected(undefined, false);
        setDateActive('');
        setMaterialValue(undefined);
        setSourceValue(undefined);
        setFilter(undefined);
        formik.resetForm();
      } catch (err) {
        setIsSaving(false);
        console.error('Unable to create project material: ', err);
      }
    },
  });

  const { values, errors } = formik;

  useEffect(() => {
    if (activeProject && activeProject.timeZone) {
      setTimeZone(activeProject?.timeZone);
    }
  }, [activeProject]);

  useEffect(() => {
    setProfileIds([...profileIdsState]);
  }, [profileIdsState]);

  useEffect(() => {
    if (selected) {
      setDateActive(
        moment(getUtcStartOfDay(selected.dateActive as string, timeZone)).format('YYYY-MM-DD')
      );
    }
  }, [selected, timeZone]);

  useEffect(() => {
    setActiveMaterials(materialsState.filter(material => !material.deleted));
  }, [materialsState]);

  useEffect(() => {
    (async () => {
      if (values.sourceSupplierId && companyState?.id && !showJobNumber) {
        setIsLoading(true);
        try {
          // GET PROFILE IDS
          await thunkDispatch(
            getProfileIds({
              contractorCompanyId: companyState?.id,
              scaleCompanyId: values.sourceSupplierId,
            })
          );

          // setProfilesIds(data.payload as string[]);
        } catch (err) {
          console.error('Error getting profileIds:', err);
        } finally {
          setIsLoading(false);
        }
      }
    })();
  }, [values.sourceSupplierId, companyState, showJobNumber]);

  useEffect(() => {
    // clear previous date active
    if (!values.id) {
      values.dateActive = moment(new Date()).format('YYYY-MM-DD');
    }
    // Find most recent project material (dateExpired = null) with supplier and material combination
    const combinationExists = projectMaterialsState.find(
      (pm: IProjectMaterial) =>
        pm.sourceSupplierId === values.sourceSupplierId &&
        pm.materialId === values.materialId &&
        pm.dateExpired === null
    );

    // If combo doesn't exist add a new project material
    if (!combinationExists) {
      setCreateNewRecord(true);
      values.dateActiveValidation = '';
    }

    if (combinationExists && !values.id) {
      setCreateNewRecord(false);
      // Get date the most recent edit will be active
      const activeDate = moment(new Date(combinationExists.dateActive as string)).format(
        'YYYY-MM-DD'
      );
      formik.setFieldValue('dateActiveValidation', getUtcEndOfDay(activeDate, timeZone));
    }
    // eslint-disable-next-line
  }, [values.materialId, values.sourceSupplierId, setCreateNewRecord, projectMaterialsState]);

  useEffect(() => {
    if (values.profileId) {
      const profile = profileIds?.find(x => x.id === values.profileId);
      formik.setFieldValue('profileType', profile?.profileNr);
    }

    // eslint-disable-next-line
  }, [values.profileId]);

  useEffect(() => {
    if (values.materialCost && values.transportCost) {
      formik.setFieldValue('totalCost', Number(values.materialCost) + Number(values.transportCost));
    }

    // eslint-disable-next-line
  }, [values.materialCost, values.transportCost]);

  useEffect(() => {
    if (selected) {
      const x = materialsState.find(a => a.id === selected.materialId);
      setMaterialValue(x);
      setSourceValue(
        (sourcesState || []).find(s => s.connected_company_id === selected.sourceSupplierId)
      );
    }
  }, [selected, setFilter, materialsState, sourcesState]); // TODO: is it missing setFilter?

  useEffect(() => {
    if (materialValue) {
      const { ...newMaterialData } = materialValue;
      if (newMaterialData?.id === 0) {
        handleModal(
          <AddMaterialsModal
            selected={{
              name: newMaterialData.name?.slice(9, newMaterialData.name.length),
            }}
            setMaterial={setMaterialValue}
          />
        );
        setMaterialInputValue(undefined);
      } else {
        formik.setFieldValue('materialId', materialValue?.id);
        setFilter(f => ({ ...f, materialId: materialValue?.id }));
        return;
      }
    } else {
      formik.setFieldValue('materialId', undefined);
      // setFilter({ sourceSupplierId: filter?.sourceSupplierId }); // TODO: WTF? (it will not change anything)
    }
    // eslint-disable-next-line
  }, [values.materialId, materialValue]);

  useEffect(() => {
    (async () => {
      if (sourceValue) {
        // whenever a new supplier is selected, clear profileId and supplierMaterialId to ensure hidden field is undefined
        // only do so when creating a new material though
        if (!values.id) {
          formik.setFieldValue('profileId', undefined);
          formik.setFieldValue('sourceSupplierId', undefined);
          formik.setFieldValue('requireGeneratorSignature', false);
        }
        const { ...newSourceData } = sourceValue;
        if (newSourceData?.connected_company_id === '0') {
          handleModal(
            <SearchSources // TODO: this component creates too many problems, better to drop
              selected={{
                name: newSourceData.connected_company.name?.slice(
                  9,
                  newSourceData.connected_company.name.length
                ),
                types: ['scale'],
              }}
              setSource={setSourceValue}
            />
          );
          setSourceInputValue(undefined);
        } else {
          if (newSourceData.connected_company_id) {
            setIsLoading(true);
            try {
              const company = await thunkDispatch(
                getCompanyById(newSourceData.connected_company_id)
              );
              if (company.meta.requestStatus === 'fulfilled') {
                const companyData = company.payload as IJoinCivilCompanyWithData;
                const isVulcanSupplier = companyData.supplierApiMethod === 'vulcan';
                const isConnexSupplier = companyData.supplierApiMethod === 'connex';
                const isHerculesSupplier = companyData.supplierApiMethod === 'hercules';
                setShowJobNumber(isVulcanSupplier || isConnexSupplier || isHerculesSupplier);
              }
            } catch (err) {
              console.error('Error getting supplier custom data:', err);
            } finally {
              setIsLoading(false);
            }
          }
          formik.setFieldValue('sourceSupplierId', sourceValue?.connected_company_id);
          setFilter(f => ({
            ...f,
            sourceSupplierId: sourceValue?.connected_company_id,
            sourceSupplierName: sourceValue?.connected_company.name,
          }));
          return;
        }
      } else {
        formik.setFieldValue('sourceSupplierId', undefined);
        setFilter({ materialId: filter?.materialId });
      }
    })();
    // eslint-disable-next-line
  }, [sourceValue]);

  useEffect(() => {
    if (dateActive) {
      formik.setFieldValue('dateActive', getUtcStartOfDay(dateActive as string, timeZone));
    }
    // eslint-disable-next-line
  }, [dateActive, timeZone]);

  useEffect(() => {
    formik.setFieldValue(
      'totalPrice',
      Number(Number(values.totalCost || 0) * (Number(values.markup || 0) / 100 + 1)).toFixed(2)
    );
    // eslint-disable-next-line
  }, [values.markup, values.totalCost]);

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} md={4}>
          <FormControl fullWidth>
            <InputLabel htmlFor="materialId" shrink>
              Material
            </InputLabel>
            <Autocomplete
              freeSolo
              disabled={!!selected}
              value={materialValue || ''}
              inputValue={materialInputValue || ''}
              options={activeMaterials || []}
              isOptionEqualToValue={(option, value) => option === value}
              filterOptions={(options, params) => {
                const filtered = materialFilterOptions(options, params);

                // Suggest the creation of a new value
                if (params.inputValue !== '') {
                  filtered.unshift({
                    id: 0,
                    name: `>>> Add: ${params.inputValue}`,
                  });
                }

                return filtered;
              }}
              getOptionLabel={option => (typeof option === 'string' ? option : option.name!)}
              onInputChange={(event, newValue) => setMaterialInputValue(newValue)}
              onChange={(event, val) => {
                const newValue = val as ICompanyMaterial;
                setMaterialValue(newValue);
              }}
              renderInput={params => (
                <TextField
                  {...params}
                  placeholder="Search Materials"
                  variant="outlined"
                  error={!!errors.materialId && !materialValue}
                  helperText={errors.materialId}
                  inputProps={{
                    ...params.inputProps,
                    onKeyDown: e => {
                      if (e.key === 'Enter') {
                        e.stopPropagation();
                      }
                    },
                  }}
                />
              )}
              renderOption={(props: HTMLAttributes<HTMLLIElement>, option: ICompanyMaterial) => (
                <li {...props} key={option.id}>
                  <Grid container alignItems="center" justifyContent="flex-start" wrap="nowrap">
                    <Grid item xs={6}>
                      <Typography noWrap>{option.name}</Typography>
                    </Grid>
                  </Grid>
                </li>
              )}
            />
          </FormControl>
        </Grid>
        <Grid item xs={12} md={4}>
          <FormControl fullWidth>
            <InputLabel htmlFor="sourceSupplierId" shrink>
              Source Supplier
            </InputLabel>
            <Autocomplete
              freeSolo
              disabled={!!selected}
              value={sourceValue || ''}
              inputValue={sourceInputValue || ''}
              options={sourcesState || []}
              isOptionEqualToValue={(option, value) => option === value}
              filterOptions={(options, params) => {
                const filtered = sourcesFilterOptions(options, params);

                // Suggest the creation of a new value
                if (params.inputValue !== '') {
                  // TODO: bad think, but works just to render
                  filtered.unshift({
                    connected_company_id: '0',
                    connected_company: {
                      name: `>>> Add: ${params.inputValue}`,
                      types: ['scale'],
                    },
                  } as any);
                }

                return filtered;
              }}
              getOptionLabel={option =>
                typeof option === 'string' ? option : option.connected_company.name!
              }
              onInputChange={(event, newValue) => setSourceInputValue(newValue)}
              onChange={(event, val) => {
                setSourceValue(val as any); // TODO: WTF?????
              }}
              renderInput={params => (
                <TextField
                  {...params}
                  placeholder="Search Sources"
                  variant="outlined"
                  error={!!errors.sourceSupplierId && !sourceValue}
                  helperText={errors.sourceSupplierId}
                  inputProps={{
                    ...params.inputProps,
                    onKeyDown: e => {
                      if (e.key === 'Enter') {
                        e.stopPropagation();
                      }
                    },
                  }}
                />
              )}
              renderOption={(props: HTMLAttributes<HTMLLIElement>, option) => (
                <li {...props} key={option.id}>
                  <Grid container alignItems="center" justifyContent="flex-start" wrap="nowrap">
                    <Grid item xs={6}>
                      <Typography noWrap>{option.connected_company.name}</Typography>
                    </Grid>
                  </Grid>
                </li>
              )}
            />
          </FormControl>
        </Grid>
        {!showJobNumber ? (
          <Grid item xs={12} md={4}>
            <FormControl fullWidth>
              <InputLabel shrink htmlFor="profileId">
                Profile ID
              </InputLabel>
              <TextField
                select
                disabled={!profileIds || !profileIds.length}
                variant="outlined"
                name="profileId"
                value={values.profileId || ''}
                error={!!errors.profileId}
                helperText={errors.profileId}
                onChange={formik.handleChange}
              >
                {profileIds && profileIds.length ? (
                  profileIds
                    .sort((a, b) => {
                      if (!a || !b) {
                        return 1;
                      }
                      return a.name.localeCompare(b.name);
                    })
                    .map(profile => (
                      <MenuItem value={profile.id} key={profile.id}>
                        {profile.name} - {profile.profileNr}
                      </MenuItem>
                    ))
                ) : (
                  <MenuItem value={0}>No Profile IDs found</MenuItem>
                )}
              </TextField>
              <Box textAlign="right">
                <FormControlLabel
                  control={
                    <Switch
                      size="small"
                      name="requireGeneratorSignature"
                      checked={!!values.requireGeneratorSignature}
                      onChange={formik.handleChange}
                    />
                  }
                  sx={{ fontSize: '.7rem' }}
                  label="Require Generator’s Signature?"
                />
              </Box>
            </FormControl>
          </Grid>
        ) : (
          <Grid item xs={12} md={4}>
            <FormControl fullWidth>
              <InputLabel shrink htmlFor="supplierMaterialId">
                Product Code
              </InputLabel>
              <TextField
                variant="outlined"
                name="supplierMaterialId"
                value={values.supplierMaterialId || ''}
                helperText={'API integration requires a product code.'}
                onChange={formik.handleChange}
              />
            </FormControl>
          </Grid>
        )}
        <Grid item xs={12} md={2}>
          <FormControl fullWidth>
            <InputLabel shrink htmlFor="testFrequency">
              Test Frequency
            </InputLabel>
            <TextField
              variant="outlined"
              name="testFrequency"
              value={values.testFrequency || ''}
              error={!!errors.testFrequency}
              helperText={errors.testFrequency}
              onChange={formik.handleChange}
            />
          </FormControl>
        </Grid>
        <Grid item xs={12} md={2}>
          <FormControl fullWidth>
            <InputLabel shrink htmlFor="units">
              Units
            </InputLabel>
            <TextField
              select
              variant="outlined"
              name="units"
              value={values.units || ''}
              error={!!errors.units}
              helperText={errors.units}
              onChange={formik.handleChange}
            >
              {unitsOfMeasure.map((unit, index) => (
                <MenuItem key={index} value={unit}>
                  {unit}
                </MenuItem>
              ))}
            </TextField>
          </FormControl>
        </Grid>
        <Grid item xs={2} display="flex" justifyContent="center" alignItems="center" height="100%">
          <FormControlLabel
            label="Split Cost?"
            name="splitCost"
            labelPlacement="top"
            value={values.splitCost || false}
            control={
              <Checkbox
                checked={values.splitCost || false}
                onChange={formik.handleChange}
                color="secondary"
              />
            }
          />
        </Grid>
        <Grid item xs={5} md={2}>
          <FormControl fullWidth>
            <InputLabel shrink htmlFor="materialCost">
              Material Cost
            </InputLabel>
            <TextField
              variant="outlined"
              name="materialCost"
              disabled={!values.splitCost}
              value={values.materialCost || ''}
              error={!!errors.materialCost && !!values.splitCost}
              helperText={errors.materialCost}
              onChange={formik.handleChange}
            />
          </FormControl>
        </Grid>
        <Grid item xs={5} md={2}>
          <FormControl fullWidth>
            <InputLabel shrink htmlFor="transportCost">
              Transport Cost
            </InputLabel>
            <TextField
              variant="outlined"
              name="transportCost"
              disabled={!values.splitCost}
              value={values.transportCost || ''}
              error={!!errors.transportCost && !!values.splitCost}
              helperText={errors.transportCost}
              onChange={formik.handleChange}
            />
          </FormControl>
        </Grid>
        <Grid item xs={12} md={2}>
          <FormControl fullWidth>
            <InputLabel shrink htmlFor="totalCost">
              Total Cost
            </InputLabel>
            <TextField
              variant="outlined"
              name="totalCost"
              disabled={values.splitCost}
              value={values.totalCost || ''}
              error={!!errors.totalCost && !values.splitCost}
              helperText={errors.totalCost}
              onChange={formik.handleChange}
            />
          </FormControl>
        </Grid>
        {!isWidthXSorSM && (
          <Grid item xs={8}>
            <Box flexGrow={1} />
          </Grid>
        )}
        <Grid item xs={6} md={2}>
          <FormControl fullWidth>
            <InputLabel shrink htmlFor="markup">
              Markup
            </InputLabel>
            <TextField
              variant="outlined"
              name="markup"
              value={values.markup || ''}
              error={!!errors.markup && !values.markup}
              helperText={errors.markup}
              onChange={formik.handleChange}
            />
          </FormControl>
        </Grid>
        <Grid item xs={6} md={2}>
          <FormControl fullWidth>
            <InputLabel shrink htmlFor="totalPrice">
              Total Price
            </InputLabel>
            <TextField
              variant="outlined"
              name="totalPrice"
              disabled
              value={values.totalPrice || ''}
              error={!!errors.totalPrice && !values.totalPrice}
              helperText={errors.totalPrice}
              onChange={formik.handleChange}
            />
          </FormControl>
        </Grid>
        {!isWidthXSorSM && (
          <Grid item xs={8}>
            <Box flexGrow={1} />
          </Grid>
        )}
        {!isWidthXSorSM && filter && (filter.sourceSupplierId || filter.materialId) ? (
          <Grid item xs={12} md={4} display="flex" alignItems="center" justifyContent="flex-start">
            <Button
              sx={{ color: 'neutral.main' }}
              onClick={() => {
                setSelected(undefined, true);
                setMaterialValue(undefined);
                setSourceValue(undefined);
                setFilter(undefined);
                setDateActive(moment(new Date()).format('YYYY-MM-DD'));
              }}
            >
              Reset Filter
            </Button>
          </Grid>
        ) : (
          !isWidthXSorSM && (
            <Grid item xs={4}>
              <Box flexGrow={1} />
            </Grid>
          )
        )}

        {(!!values.dateActiveValidation && !createNewRecord) || values.id ? (
          <>
            {!isWidthXSorSM && (
              <Grid
                item
                xs={12}
                md={2}
                display="flex"
                alignItems="center"
                justifyContent="flex-end"
              >
                <Typography align="right" variant="subtitle1" sx={{ color: 'neutral.main' }}>
                  Date Active:
                </Typography>
              </Grid>
            )}
            <Grid item xs={12} md={3}>
              <FormControl fullWidth>
                {isWidthXSorSM && (
                  <InputLabel shrink htmlFor="dateActive">
                    Date Active
                  </InputLabel>
                )}
                <TextField
                  type="date"
                  // Ensure user picks date in the future to prevent past
                  // tickets from changing price.
                  InputProps={{
                    inputProps: {
                      min: moment(new Date()).format('YYYY-MM-DD'),
                    },
                  }}
                  disabled={!values.dateActiveValidation && !values.id}
                  onChange={event => {
                    setDateActive(event?.target.value);
                  }}
                  value={dateActive || ''}
                  name="dateActive"
                  error={!!errors.dateActive}
                  helperText={errors.dateActive}
                  variant="outlined"
                />
              </FormControl>
            </Grid>
          </>
        ) : !isWidthXSorSM ? (
          <Grid item xs={5}>
            <Box flexGrow={1} />
          </Grid>
        ) : (
          <></>
        )}
        <Grid item xs={6} md={2} display="flex" alignItems="center" justifyContent="center">
          <Button
            fullWidth
            disabled={isSaving}
            color="primary"
            variant="contained"
            onClick={() => formik.handleSubmit()}
          >
            {values.id ? 'Update' : 'Create'}
          </Button>
        </Grid>
        <Grid item xs={6} md={1} display="flex" alignItems="center" justifyContent="center">
          <Button
            sx={{ color: 'neutral.main' }}
            onClick={() => {
              setSelected(undefined, false);
              setMaterialValue(undefined);
              setSourceValue(undefined);
              setFilter(undefined);
            }}
          >
            Cancel
          </Button>
        </Grid>
        {isWidthXSorSM && filter && (filter.sourceSupplierId || filter.materialId) ? (
          <Grid item xs={12} md={4} display="flex" alignItems="center" justifyContent="flex-start">
            <Button
              sx={{ color: 'neutral.main' }}
              onClick={() => {
                setSelected(undefined, true);
                setMaterialValue(undefined);
                setSourceValue(undefined);
                setFilter(undefined);
                setDateActive(moment(new Date()).format('YYYY-MM-DD'));
              }}
            >
              Reset Filter
            </Button>
          </Grid>
        ) : (
          <Grid item xs={4}>
            <Box flexGrow={1} />
          </Grid>
        )}
      </Grid>
      <LoadingComponent isLoading={isLoading} />
    </>
  );
};

export default ProjectMaterialForm;
