import { useState, useEffect } from 'react';
import Query from 'services/firebase/Query';
import Executer from 'services/firebase/Executer';
import useIsUserOfType from 'services/hooks/useIsUserOfType';
import useShowError from 'modules/errors';
import { usePermissionsContext } from 'services/permissions';
import { Load } from './useGetDriverCurrentLoad';
import QueryBase from 'lg-helpers/dist/firestore/query/QueryBase';

const query = new Query();
const executer = new Executer();

const useGetDriverCurrentRepeatedLoad = () => {
  const { user } = usePermissionsContext();
  const isUserBrokerDriverOrAdmin = useIsUserOfType('trucking_driver', 'trucking_dispatcher');
  const [currentRepeatedLoad, setCurrentRepeatedLoad] = useState<Load | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const showError = useShowError();

  useEffect(() => {
    if (!isUserBrokerDriverOrAdmin) {
      return;
    }

    return executer.watchSingleDocument(
      query.brokers.brokerGetDriverCurrentRepeatedLoad(user),
      data => {
        const load = data as Load;
        const { truckNumber } = user.customData || {};
        const { assignedTruckNumber } = load || {};
        if (
          assignedTruckNumber !== QueryBase.NULL_TRUCK_NUMBER() &&
          assignedTruckNumber !== truckNumber
        ) {
          setIsLoading(false);
          return;
        }
        setCurrentRepeatedLoad(load);
        setIsLoading(false);
      },
      error => {
        console.error('Error', error);
        setIsLoading(false);
        showError({ title: 'Error loading current Load', duration: 10000 });
      }
    );
    // eslint-disable-next-line
  }, [user, isUserBrokerDriverOrAdmin, setIsLoading, setCurrentRepeatedLoad]);

  return { currentRepeatedLoad, isLoading };
};

export default useGetDriverCurrentRepeatedLoad;
