import React, { useEffect } from 'react';
import { Box } from '@mui/material';
import { useSelector } from 'react-redux';
import { thunkDispatch } from 'store/store';
import { selectDate } from 'modules/civilAndTrucking/trucking/features/Dispatches/redux/dispatchesToolbarStoreSlice';
import { getJoinedBrokerDispatchesByCompanyId } from 'modules/civilAndTrucking/trucking/features/Dispatches/redux/actions';
import { BrokerDispatchesList } from './BrokerDispatchesList';

export const BrokerDispatchesView = () => {
  const dispatchesDateFilter = useSelector(selectDate);

  useEffect(() => {
    thunkDispatch(getJoinedBrokerDispatchesByCompanyId(dispatchesDateFilter));
  }, [dispatchesDateFilter]);

  return (
    <>
      <Box display="flex" flexWrap="wrap" alignItems="center" justifyContent="space-between" mb={2}>
        <BrokerDispatchesList />
      </Box>
    </>
  );
};
