import React, { useContext } from 'react';
import { TextField, InputLabel, Grid, FormControl } from '@mui/material';

import DialogActionContainer from 'sharedComponents/DialogActions';
import { useFormik } from 'formik';
import { companyMaterialSchema } from './schemas';
import { addCompanyMaterial } from 'modules/civilAndTrucking/civil/CompanyMaterials/redux/actions';
import { ModalContext } from 'sharedComponents/ModalContext';
import { thunkDispatch } from 'store/store';
import { ICompanyMaterial } from 'lg-helpers/dist/shared/interfaces/ICompanyMaterial';

interface CompanyMaterialFormProps {
  selected?: ICompanyMaterial;
  setMaterial?: Function;
}

const CompanyMaterialForm = ({ selected, setMaterial }: CompanyMaterialFormProps) => {
  const { handleModal } = useContext(ModalContext);

  const handleSubmit = async (values: ICompanyMaterial) => {
    const result = await thunkDispatch(addCompanyMaterial(values));
    if (result.meta.requestStatus === 'fulfilled') {
      if (setMaterial) {
        setMaterial(result.payload);
      }
      handleModal();
    }
  };

  const formik = useFormik({
    validationSchema: companyMaterialSchema,
    initialValues: selected
      ? selected
      : ({
          name: '',
          sku: '',
        } as ICompanyMaterial),
    onSubmit: handleSubmit,
  });

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <FormControl fullWidth>
            <InputLabel htmlFor="name" shrink>
              Name
            </InputLabel>
            <TextField
              name="name"
              value={formik.values.name}
              error={formik.touched.name && !!formik.errors.name}
              helperText={formik.touched.name && formik.errors.name}
              onChange={formik.handleChange}
              variant="outlined"
              autoFocus
            />
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <FormControl fullWidth>
            <InputLabel htmlFor="sku" shrink>
              Material ID/SKU
            </InputLabel>
            <TextField
              name="sku"
              value={formik.values.sku}
              error={formik.touched.sku && !!formik.errors.sku}
              helperText={formik.touched.sku && formik.errors.sku}
              onChange={formik.handleChange}
              variant="outlined"
            />
          </FormControl>
        </Grid>
      </Grid>
      <DialogActionContainer saveHandler={formik.handleSubmit} editing={selected ? true : false} />
    </>
  );
};

export default CompanyMaterialForm;
