import { createAsyncThunk } from '@reduxjs/toolkit';
import { INotification } from 'modules/civilAndTrucking/shared/NotificationSnackbar/INotification';
import { pushNotification } from 'modules/civilAndTrucking/shared/NotificationSnackbar/redux/actions';
import { post, get, del } from 'services/api/restHelpers';
import { IProjectTask } from 'lg-helpers/dist/shared/interfaces/IProjectTask';

export interface IJoinProjectTask extends IProjectTask {
  material?: string;
  sourceSupplier?: string;
  activity?: string;
  workCategory?: string;
}

export const getProjectTasks = createAsyncThunk(
  'project-tasks/get-project-tasks',
  async (projectId: number, thunkApi) => {
    try {
      const data = await get(`civil/projects/${projectId}/tasks`);
      return data as IJoinProjectTask[];
    } catch (err) {
      const errorNotification: INotification = {
        status: 'error',
        message: 'Error: Unable to load project tasks',
      };
      thunkApi.dispatch(pushNotification(errorNotification));
    }
  }
);

export const getProjectTaskGrid = createAsyncThunk(
  'project-tasks/get-project-task-grid',
  async (projectId: number, thunkApi) => {
    try {
      const data = await get(`civil/projects/${projectId}/tasks?type=grid`);
      return data as IJoinProjectTask[];
    } catch (err) {
      const errorNotification: INotification = {
        status: 'error',
        message: 'Error: Unable to load project tasks',
      };
      thunkApi.dispatch(pushNotification(errorNotification));
    }
  }
);

export const addProjectTask = createAsyncThunk(
  'project-tasks/add-project-task',
  async (payload: IProjectTask, thunkApi) => {
    try {
      const data = await post('civil/projects/tasks', payload);
      const successNotification: INotification = {
        status: 'success',
        message: 'Success: Project tasks have been updated',
      };
      thunkApi.dispatch(pushNotification(successNotification));
      return data;
    } catch (err) {
      const errorNotification: INotification = {
        status: 'error',
        message: 'Error: Unable to update project tasks',
      };
      thunkApi.dispatch(pushNotification(errorNotification));
      throw err;
    }
  }
);

export const deleteProjectTask = createAsyncThunk(
  'company-materials/delete',
  async (payload: IProjectTask, thunkApi) => {
    try {
      await del(`civil/projects/tasks/${payload.id}`);
      const successNotification: INotification = {
        status: 'success',
        message: `Success: Removed project task`,
      };
      thunkApi.dispatch(pushNotification(successNotification));
      return payload;
    } catch (err) {
      const errorNotification: INotification = {
        status: 'error',
        message: `Error: Removing project task`,
      };
      thunkApi.dispatch(pushNotification(errorNotification));
      throw err;
    }
  }
);
