import React from 'react';
import './TopBar.scss';
import { AppBar, Box, IconButton, Toolbar } from '@mui/material';
import withWidth from '@mui/material/Hidden/withWidth';
import MenuIcon from '@mui/icons-material/Menu';
import InputIcon from '@mui/icons-material/Input';
import NotificationsNoneOutlinedIcon from '@mui/icons-material/NotificationsNoneOutlined';
import LogoLink from 'sharedComponents/LogoLink';
import { auth } from 'services/firebase';
import { clearNotifications } from 'sharedComponents/notificationStoreSlice';
import { clearProjectsManifestFilters } from 'redux/storeSliceManifest';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { ButtonUserTypeSwitcher } from './ButtonUserTypeSwitcherIcon';
import { selectAuthUser } from 'modules/auth/storeSliceAuth';

export const handleLogoutAction = async () => {
  await auth.signOut().catch(console.error);

  // TODO: Auth0: revert this
  // window.location.href = `https://${
  //   process.env.REACT_APP_AUTH0_DOMAIN
  // }/v2/logout?returnTo=${encodeURIComponent(process.env.REACT_APP_SITE_BASE_URL || '')}`;
};

const BurgerButton = ({ onMobileNavOpen }: { onMobileNavOpen: any }) => {
  return (
    <IconButton color="inherit" onClick={onMobileNavOpen} id="mobile-nav_trigger">
      <MenuIcon className="Manifest--burger-btn" />
    </IconButton>
  );
};

const Spacer = () => <Box flexGrow={1} />;

interface TopBarProps {
  width: 'lg' | 'md' | 'sm' | 'xl' | 'xs';
  alternateLogo?: boolean;
  noControls?: boolean;
  onMobileNavOpen?: any;
}

const TopBar: React.FC<TopBarProps> = ({
  width,
  alternateLogo = false,
  noControls = false, // eslint-disable-line
  onMobileNavOpen,
  ...rest
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const authUser = useSelector(selectAuthUser);

  const LogoutButton = () => {
    return (
      <IconButton color="inherit" onClick={logOut}>
        <InputIcon className="Manifest--icon__logout" />
      </IconButton>
    );
  };

  const logOut = () => {
    dispatch(clearNotifications());
    dispatch(clearProjectsManifestFilters());

    handleLogoutAction();
  };

  const NotificationButton = () => (
    <IconButton color="inherit" onClick={() => navigate('/notifications')}>
      <NotificationsNoneOutlinedIcon className="Manifest--icon__notifications" />
    </IconButton>
  );

  const hasMultipleUserTypesOrSuperadmin =
    (authUser?.allowedUserTypes || []).length > 1 || authUser?.type === 'Super Admin';

  const accountGroup = (
    <>
      <NotificationButton />
      {hasMultipleUserTypesOrSuperadmin && <ButtonUserTypeSwitcher />}
      <LogoutButton />
    </>
  );

  return (
    <AppBar elevation={0} {...rest} position="fixed" className="Manifest--topbar">
      <Toolbar>
        <>
          {['xs', 'sm', 'md'].includes(width) ? (
            <>
              <BurgerButton onMobileNavOpen={onMobileNavOpen} />
              <Spacer />
              <LogoLink width={width} alternate={alternateLogo} className="Manifest--LogoLink" />
              <Spacer />
              {accountGroup}
            </>
          ) : (
            <>
              <LogoLink width={width} alternate={alternateLogo} className="Manifest--LogoLink" />
              <Spacer />
              {accountGroup}
            </>
          )}
        </>
      </Toolbar>
    </AppBar>
  );
};

export default withWidth()(TopBar);
