import axios, { AxiosStatic, AxiosResponse } from 'axios';
import { auth } from 'services/firebase';
import Tables from './Tables';
axios.defaults.baseURL = process.env.REACT_APP_API_BASE_URL;
const staticApiBaseUrl = process.env.REACT_APP_STATIC_API_BASE_URL;

export interface Where {
  whereType?: 'where' | 'whereRaw' | 'whereIn' | 'whereBetween' | 'whereNot';
  whereColumn1: string;
  whereColumn2: string | number | boolean | number[] | string[];
  whereColumn3?: string;
  orWheres?: Where[];
}

export default class ApiBase {
  public table: Tables | null;
  public axios: AxiosStatic;

  constructor(table?: Tables) {
    this.table = table || null;
    this.axios = axios;
  }

  async getUserToken() {
    const currentUser = await auth.currentUser;
    const token = currentUser ? await currentUser.getIdToken(true) : null;
    return token;
  }

  async post(data: any, path: string) {
    const token = await this.getUserToken();
    const response = await axios.post(path, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  }

  async postStatic(data: any, path: string) {
    const token = await this.getUserToken();
    const response = await axios.post(`${staticApiBaseUrl}/${path}`, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  }

  async put(data: any, path: string) {
    const token = await this.getUserToken();
    const response = await axios.put(path, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  }

  async insertOrUpdate(newEntityOrUpdatePartialObj: any, tableName?: Tables) {
    const token = await this.getUserToken();
    const response = await axios.post(
      `/${tableName || this.table}/upsert`,
      newEntityOrUpdatePartialObj,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  }

  async getById(id: number | string, tableName?: Tables | null) {
    const token = await this.getUserToken();
    tableName = tableName || this.table;
    const response = await axios.get(`/${tableName}/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  }

  async getList<T>(path?: string, params: object = {}): Promise<T | undefined> {
    try {
      const token = await this.getUserToken();
      const res: AxiosResponse<T> = await axios.get(`${path}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params,
      });
      return res.data;
    } catch (e) {
      console.error('API GET Error: ', e);
      throw e;
    }
  }

  async deleteById(id: number | string, tableName?: Tables | null) {
    try {
      const token = await this.getUserToken();
      const response = await axios.delete(`/${tableName || this.table}/delete/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return response.data;
    } catch (e) {
      console.error('API DELETE Error: ', e);
      throw e;
    }
  }
}
