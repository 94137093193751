import React, { useState } from 'react';
import { Typography, Button, CircularProgress } from '@mui/material';

type ButtonProps = React.ComponentProps<typeof Button>;

export interface ButtonWithLoadingProps {
  text: string;
  Pictogram?: any; // sorry, but It's wrong way to pass icons
  onClickCallback: () => any;
  disabled?: boolean;
  color?: ButtonProps['color'];
  variant?: ButtonProps['variant'];
  isLarge?: boolean;
  isFullWidth?: boolean;
}

const ButtonWithLoading = ({
  text,
  Pictogram,
  onClickCallback,
  disabled = false,
  color = 'inherit',
  variant = 'outlined',
  isLarge = false,
  isFullWidth = false,
}: ButtonWithLoadingProps) => {
  const [isLoading, setIsLoading] = useState(false);

  const onClickConfirm = async () => {
    setIsLoading(true);
    try {
      const p = onClickCallback();
      if (p?.then) await p;
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Button
      color={color}
      variant={variant}
      fullWidth={isFullWidth}
      onClick={onClickConfirm}
      role="ButtonWithLoading__btn"
      disabled={disabled || isLoading}
      size={isLarge ? 'large' : 'small'}
    >
      {isLoading ? (
        <CircularProgress
          color="inherit"
          role="ButtonWithLoading__loading"
          size={isLarge ? '30px' : '25px'}
        />
      ) : (
        <>
          {Pictogram && (
            <Pictogram
              color="inherit"
              role="ButtonWithLoading__pictogram"
              fontSize={isLarge ? 'large' : 'medium'}
            />
          )}
        </>
      )}
      <Typography role="ButtonWithLoading__text" variant={isLarge ? 'h5' : 'body1'}>
        {text}
      </Typography>
    </Button>
  );
};

export default ButtonWithLoading;
