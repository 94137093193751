import React from 'react';
import { TextField, TextFieldProps } from '@mui/material';

// todo: Add unit tests
// todo: make this more robust to handle other things

export type InputMaskerProps = React.HTMLProps<HTMLInputElement> &
  TextFieldProps & {
    mask: string;
    val: string;
    onChange: (value: string) => void;
    fullWidth?: boolean;
    label?: string;
  };

const formatVal = (value: string, mask: string): string => {
  let i = 0;
  let lastReplaceIndex = -1;

  const filledMask = mask.replace(/#/g, (_, j) => {
    if (i >= value.length) {
      return '#';
    }
    lastReplaceIndex = j;

    return value[i++];
  });

  return filledMask.substring(0, lastReplaceIndex + 1);
};

export const InputMasker = ({
  val,
  mask,
  onChange,
  fullWidth = false,
  label,
  ...otherProps
}: InputMaskerProps) => (
  <TextField
    {...otherProps}
    label={label}
    fullWidth={fullWidth}
    value={val}
    placeholder={mask}
    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
      const { value } = e.currentTarget;
      const cleanVal = value.replace(/[^\d]/g, '');
      onChange(formatVal(cleanVal, mask));
    }}
  />
);

export default InputMasker;
