import React, { useEffect } from 'react';
import { useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import { componentAllowedPerms } from 'services/permissions';
import { getManifestDefaultColumns } from 'utils/manifestUtil';
import CrudTable from './CrudTable';
import Query from 'services/firebase/Query';
import Executer from 'services/firebase/Executer';
import Page from './Page';
import Container from '@mui/material/Container';
import { Box, Button, Grid, Typography } from '@mui/material';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import IBatchManifests from 'lg-helpers/dist/shared/interfaces/IBatchManifests';
import { Manifest } from 'lg-helpers/dist/shared/types/manifests';

const query = new Query();
const executer = new Executer();

const columns = getManifestDefaultColumns(true, true, false, false);

export default componentAllowedPerms(['batched_manifests:read'], () => <p>Access Refused</p>)(
  function BatchManifestDetailsListView() {
    const [manifests, setManifests] = useState<Manifest[]>([]);
    const [batch, setBatch] = useState<IBatchManifests>();
    const [isLoading, setIsLoading] = useState(true);
    let { id } = useParams();
    const navigate = useNavigate();

    useEffect(() => {
      const getManifests = async () => {
        const manifs = (await executer.getMultipleDocuments(
          query.manifests.getManifestsInBatch(id)
        )) as Manifest[];

        setManifests(manifs);
      };

      const getBatch = async () => {
        const batchManifest = (await executer.getSingleDocument(
          query.batchManifests.getBatchById(id!)
        )) as IBatchManifests;

        setBatch(batchManifest);
      };

      try {
        setIsLoading(true);
        getBatch();
        getManifests();
      } catch (err) {
        // TODO: real logging here sometime
        console.error(err);
      } finally {
        setIsLoading(false);
      }
      // eslint-disable-next-line
    }, []);

    const goToManifest = (manifest: any) => navigate(`/manifests/${manifest.id}`); // eslint-disable-line

    const handleRowClickCallback = async (manifest: any) => {}; // eslint-disable-line

    return (
      <>
        <Page className="min-padding-and-height" title="Processed Manifests">
          <Container>
            <Box display="flex" justifyContent="center" alignItems="center" marginBottom={4}>
              <Typography variant="h3">Manifests for Batch # {batch?.number}</Typography>
            </Box>
            <Grid container alignItems="center" spacing={1} justifyContent="space-between">
              <Grid item md={1} sm={3} xs={12} style={{ minWidth: '120px' }}>
                <Button
                  onClick={() => {
                    navigate(-1);
                  }}
                  variant="outlined"
                  size="small"
                  fullWidth
                >
                  <NavigateBeforeIcon fontSize="small" /> Back
                </Button>
              </Grid>
            </Grid>
            <CrudTable
              rows={manifests}
              columns={columns}
              isLoading={isLoading}
              handleRowClickCallback={handleRowClickCallback}
            ></CrudTable>
          </Container>
        </Page>
      </>
    );
  }
);
