import store from 'store/store';
import { buildHelpersForPermissions } from 'permissions/dist/src/build-helpers';
import {
  defaultPermissionsByRole,
  userTypeToRole,
  RoleLabels as _RoleLabels,
  EntityLabels as _EntityLabels,
  ActionLabels as _ActionLabels,
} from 'permissions/dist/manifest-permissions-configuration';
import { IUser } from 'lg-helpers/dist/shared/interfaces/IUser';

export type RoleLabels = _RoleLabels;
export type EntityLabels = _EntityLabels;
export type ActionLabels = _ActionLabels;
export type UserWithRole = IUser & { role: RoleLabels[number] };

// disabled part of async API (rewrite auth system to enable)
export const {
  // async stuff
  // Context,
  ContextProvider,
  useAsyncPermissionsContext,
  // useAsyncSwitchUserRole,
  // useAsyncSwitchUserRoleRender,
  // componentAsyncAllowedFor,
  // componentAsyncAllowedPerms,
  // useAsyncHasPerms,
  useHasAccess,
  componentHasAccess,

  PermissionContextSynchronization,

  // synchronized helpers
  usePermissionsContext,
  useSwitchUserRole,
  useSwitchUserRoleRender,
  componentAllowedFor,
  componentAllowedPerms,
  useHasPerms,
} = buildHelpersForPermissions<RoleLabels, EntityLabels, ActionLabels, UserWithRole>({
  rolesPermissions: defaultPermissionsByRole,
  // bad solution, but works with current implementation of auth
  fetchUser: () =>
    new Promise((resolve, reject) => {
      const state = store.getState();
      const user = state.auth.authUser as IUser;
      if (user) {
        resolve({ ...user, role: userTypeToRole(user.actingAsType || user.type) });
      } else {
        reject(new Error('User not found in store'));
      }
    }),
});
