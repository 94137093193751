import React, { useEffect } from 'react';
import { usePermissionsContext } from 'services/permissions';
import { useSelector } from 'react-redux';
import { thunkDispatch } from 'store/store';
import { Box } from '@mui/material';
import { Routes, Route } from 'react-router-dom';
import { IMenuItem } from 'modules/civilAndTrucking/shared/nav';
import {
  SettingsView,
  TruckTypesViews,
  TruckingReportsView,
  TrucksViews,
  DivisionsViews,
} from '../trucking/features';
import { HomeView } from './features/misc/HomeView';
import { NotificationSnackbar } from 'modules/civilAndTrucking/shared/NotificationSnackbar';
import { ModalProvider } from 'sharedComponents/ModalContext';
import Modal from 'sharedComponents/Modal';
import { LoadingOverlay as Loading } from 'sharedComponents/LoadingOverlay';
import { RootState } from 'store/store';
import { setActiveCompany } from 'modules/civilAndTrucking/civil/Companies/redux/actions';
import ErrorHandler, { ActionButton } from 'sharedComponents/ErrorHandler';
import { BrokerDispatchesViews } from './features/Dispatch';
import { BrokerTicketsViews } from './features/Tickets';
import { HomeOutlined, LocalShippingOutlined, Settings, SpeedOutlined } from '@mui/icons-material';
import NotFoundView from '../../../sharedComponents/pictograms/NotFoundView';
import { AppNav } from '../shared/nav/AppNav/AppNav';

const navBarItems: IMenuItem[] = [
  {
    href: '/trucking-broker/dispatches',
    title: 'Dispatch',
    Pictogram: <HomeOutlined />,
  },
  {
    href: '/trucking-broker/tickets',
    title: 'Tickets',
    Pictogram: <LocalShippingOutlined />,
  },
  {
    href: '/trucking-broker/reports',
    title: 'Reports',
    Pictogram: <SpeedOutlined />,
  },
  {
    href: '/trucking-broker/setup',
    title: 'Set Up',
    Pictogram: <Settings />,
  },
];

const TruckingBrokerDashboardView = () => {
  const { user } = usePermissionsContext();

  useEffect(() => {
    if (user.companiesIds[0]) {
      thunkDispatch(setActiveCompany(user.companiesIds[0]));
    }
  }, [user]);

  const activeCompany = useSelector((state: RootState) => state.civil.companies.activeCompany);
  const activeCompanyStatus = useSelector(
    (state: RootState) => state.civil.companies.activeCompanyStatus
  );

  if (!user) throw new Error('No user. Something wrong');

  if (!user.companiesIds || user.companiesIds.length < 1 || !user.companiesIds[0])
    throw new Error('Your user not assigned to any company');

  if (activeCompanyStatus === 'failure')
    throw new Error('Failed to load information about your company.');

  console.log(activeCompanyStatus, activeCompany, user);
  if (activeCompanyStatus === 'pending' || activeCompanyStatus === null) {
    return <Loading />;
  }

  if (!activeCompany) {
    console.warn('Wrong frame', { activeCompanyStatus, activeCompany });
    return null;
  }

  const correctUserType = activeCompany.types.includes('truckingBroker');

  if (!correctUserType)
    return (
      <ErrorHandler
        error={new Error('Your user type can not use this application.')}
        buttons={
          <>
            <ActionButton onClick={() => (window.location.pathname = '/')}>
              Go to homepage
            </ActionButton>
            {/* We need some redirect URL from login page to enable this feature (or get rid of /login page, we can render login form by any URL) */}
            {/* <ActionButton onClick={logOut}>Login as other user</ActionButton> */}
          </>
        }
      />
    );

  return (
    <ModalProvider>
      <Modal />
      {/* <SideBar navItems={navBarItems} />
      <TopBar
        settingsPath="/brokers/account"
        bannerImageSrc="/static/images/logo-trucking-main.svg"
        bannerImageAlt="LTS Trucking"
      /> */}
      <AppNav
        settingsPath="/brokers/account"
        logoComponent={<img src="/static/images/logo-trucking-main.svg" alt="LTS Trucking" />}
        navItems={navBarItems}
      />
      <Box sx={{ p: '5rem 3rem 0 6rem', maxWidth: '2400px' }} className="Trucking">
        <Routes>
          <Route path="/" element={<HomeView />} />
          <Route path="/dispatches/*" element={<BrokerDispatchesViews />} />
          <Route path="/tickets/*" element={<BrokerTicketsViews />} />
          <Route path="/reports" element={<TruckingReportsView />} />
          <Route path="/setup" element={<SettingsView />} />
          <Route path="/setup/trucks/*" element={<TrucksViews />} />
          <Route path="/setup/truck-types/*" element={<TruckTypesViews />} />
          <Route path="/setup/divisions/*" element={<DivisionsViews />} />
          <Route path="*" element={<NotFoundView />} />
        </Routes>
      </Box>
      <NotificationSnackbar />
    </ModalProvider>
  );
};

export default TruckingBrokerDashboardView;
