import React, { useEffect } from 'react';
import { Box, DialogContent } from '@mui/material';
import { thunkDispatch } from 'store/store';
import { ITruckingTicketNote } from 'lg-helpers/dist/trucking/interfaces/ITruckingTicketNote';
import ModalHeader from 'sharedComponents/ModalHeader';
import {
  getTruckingTicketNotesByTicketId,
  updateTruckingTicketNote,
} from '../../../../redux/actions';
import { LoadingOverlay as Loading } from 'sharedComponents/LoadingOverlay';
import { useSelector } from 'react-redux';
import { selectTicketNotes } from 'modules/civilAndTrucking/trucking/features/Tickets/redux/ticketsStoreSlice';
import { TicketNote } from './TicketNote';
import { TicketNoteAddNew } from './TicketNoteAddNew';

type EditTicketProps = {
  ticketId: number;
};

export const TicketNotesModal = ({ ticketId }: EditTicketProps) => {
  const { ticketNotes, ticketsNotesStatus } = useSelector(selectTicketNotes);

  useEffect(() => {
    if (!ticketId) return;
    thunkDispatch(getTruckingTicketNotesByTicketId(ticketId));
  }, [ticketId]);

  const handleSaveTicketNote = async (note: ITruckingTicketNote) => {
    await thunkDispatch(updateTruckingTicketNote(note));
    await thunkDispatch(getTruckingTicketNotesByTicketId(ticketId));
  };

  if (ticketsNotesStatus === 'pending') {
    return <Loading />;
  }

  return (
    <Box sx={{ p: '0px 16px' }}>
      <ModalHeader title={'Ticket Notes'} />
      <DialogContent>
        <TicketNoteAddNew
          ticketId={ticketId}
          key="new_trucking_ticket_note"
          handleSaveTicketNote={handleSaveTicketNote}
        />
        {ticketNotes.map((ticketNote: ITruckingTicketNote) => (
          <TicketNote
            ticketNote={ticketNote}
            key={ticketNote.idTruckingTicketNotes}
            handleSaveTicketNote={handleSaveTicketNote}
          />
        ))}
      </DialogContent>
    </Box>
  );
};
