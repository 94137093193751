import React, { useContext } from 'react';
import { TextField, InputLabel, Grid, FormControl, DialogContent } from '@mui/material';

import DialogActionContainer from 'sharedComponents/DialogActions';
import { useFormik } from 'formik';
import { ModalContext } from 'sharedComponents/ModalContext';
import { thunkDispatch } from 'store/store';
import ModalHeader from 'sharedComponents/DialogHeader';
import { updateLocation } from '../redux/actions';
import { addressNameSchema } from './schema';
import { ILocation } from 'lg-helpers/dist/shared/interfaces/ILocation';
import { IProject } from 'lg-helpers/dist/shared/interfaces/IProject';

interface AddressNameFormProps {
  selected: IProject;
}

const AddressNameForm = ({ selected }: AddressNameFormProps) => {
  const { handleModal } = useContext(ModalContext);

  const handleSubmit = async (values: ILocation) => {
    await thunkDispatch(
      updateLocation({
        locationId: selected.locationId!,
        projectId: selected.id! as number,
        geo: selected.geo!,
        name: values.name,
        sender: 'primary',
      })
    );
    handleModal();
  };

  const formik = useFormik({
    validationSchema: addressNameSchema,
    initialValues:
      selected && selected.addressName
        ? { name: selected.addressName }
        : ({
            name: '',
          } as ILocation),
    onSubmit: handleSubmit,
  });

  return (
    <>
      <ModalHeader title={'Address Name'} />
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <FormControl fullWidth>
              <InputLabel htmlFor="name" shrink>
                Name
              </InputLabel>
              <TextField
                name="name"
                value={formik.values.name}
                error={formik.touched.name && !!formik.errors.name}
                helperText={formik.touched.name && formik.errors.name}
                onChange={formik.handleChange}
                variant="outlined"
                autoFocus
              />
            </FormControl>
          </Grid>
        </Grid>

        <DialogActionContainer saveHandler={formik.handleSubmit} editing={!!selected} />
      </DialogContent>
    </>
  );
};

export default AddressNameForm;
