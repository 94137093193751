import React, { useEffect, useState, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { DataGrid, GridColumnVisibilityModel } from '@mui/x-data-grid';
import { selectJoinedTrucks } from '../../../redux/trucksStoreSlice';
import {
  flattenCapacities,
  getColumnVisibilitiesByCapacities,
} from 'modules/civilAndTrucking/trucking/utils';
import { LoadingOverlay as Loading } from 'sharedComponents/LoadingOverlay';
import { ITruckFlattenedCapacities } from '../Trucks';
import { generateTrucksColumns } from './trucksColumns';

type TrucksListProps = {
  showModal: (truck: ITruckFlattenedCapacities) => void;
};

export const TrucksList = ({ showModal }: TrucksListProps) => {
  const { joinedTrucksByCompanies, joinedTrucksStatusByCompanies } =
    useSelector(selectJoinedTrucks);
  const [visibleColumns, setVisibleColumns] = useState<GridColumnVisibilityModel>({});
  const [joinedTrucksWithCapacities, setJoinedTrucksWithCapacities] = useState(
    [] as ITruckFlattenedCapacities[]
  );

  useEffect(() => {
    if (!joinedTrucksByCompanies.length) return;

    const trucksWithCaps = joinedTrucksByCompanies.map(truck => {
      return {
        ...truck,
        ...flattenCapacities(truck.capacitiesTruckingTruckType),
      } as ITruckFlattenedCapacities;
    });
    const columnsRemoveVisibility = getColumnVisibilitiesByCapacities(trucksWithCaps);

    setJoinedTrucksWithCapacities(trucksWithCaps);
    setVisibleColumns(columnsRemoveVisibility);
  }, [joinedTrucksByCompanies]);

  const columns = useMemo(() => generateTrucksColumns(showModal), [showModal]);

  if (joinedTrucksStatusByCompanies === 'pending') {
    return <Loading />;
  }

  return (
    <DataGrid
      columns={columns}
      columnVisibilityModel={visibleColumns}
      rows={joinedTrucksWithCapacities || []}
      getRowId={row => row.idTruckingTruck}
      hideFooterPagination={true}
      disableSelectionOnClick
      disableColumnMenu
      autoHeight
      components={{
        ColumnResizeIcon: () => <div></div>,
      }}
    />
  );
};
