import React from 'react';
import { createSvgIcon } from '@mui/material';

export const AlertCircle = createSvgIcon(
  <>
    <path
      d="M17.5 33.7132c8.9543 0 16.2132-7.2589 16.2132-16.2132 0-8.95432-7.2589-16.21324-16.2132-16.21324-8.95432 0-16.21324 7.25892-16.21324 16.21324 0 8.9543 7.25892 16.2132 16.21324 16.2132Z"
      fill="#fff"
    />
    <path
      d="M17.5 35c-2.3676 0-4.6324-.4632-6.7941-1.3897-2.05884-.875-3.96325-2.1618-5.55884-3.7574-1.59559-1.5955-2.88235-3.5-3.75735-5.5588C.46324 22.1324 0 19.8676 0 17.5c0-2.3676.46324-4.6324 1.38971-6.7941.875-2.05884 2.16176-3.96325 3.75735-5.55884s3.5-2.88235 5.55884-3.75735C12.8676.46324 15.1324 0 17.5 0c2.3676 0 4.6324.46324 6.7941 1.38971 2.0588.875 3.9633 2.16176 5.5588 3.75735 1.5956 1.59559 2.8824 3.5 3.7574 5.55884C34.5368 12.8676 35 15.1324 35 17.5c0 2.3676-.4632 4.6324-1.3897 6.7941-.875 2.0588-2.1618 3.9633-3.7574 5.5588-1.5955 1.5956-3.5 2.8824-5.5588 3.7574C22.1324 34.5368 19.8676 35 17.5 35Zm0-32.47794c-2.0074 0-3.9632.41176-5.8162 1.18382-1.80145.77206-3.39703 1.85294-4.73527 3.19118-1.38971 1.38971-2.47059 2.98529-3.19118 4.73534-.82353 1.9044-1.23529 3.8602-1.23529 5.8676 0 2.0074.41176 3.9632 1.18382 5.8162.77206 1.8014 1.85294 3.397 3.19118 4.7353 1.38971 1.3897 2.98529 2.4706 4.73534 3.1911 1.8529.7721 3.8088 1.1839 5.8161 1.1839 2.0074 0 3.9633-.4118 5.8162-1.1839 1.8015-.772 3.3971-1.8529 4.7353-3.1911 1.3897-1.3897 2.4706-2.9853 3.1912-4.7353.772-1.853 1.1838-3.8088 1.1838-5.8162s-.4118-3.9632-1.1838-5.8162C30.4191 9.88235 29.3382 8.28677 28 6.94853c-1.3897-1.38971-2.9853-2.47059-4.7353-3.19118-1.8015-.82353-3.7573-1.23529-5.7647-1.23529Z"
      fill="#BE9400"
    />
    <path
      d="M17.5516 27.2795c1.3929 0 2.5221-1.1292 2.5221-2.5221s-1.1292-2.522-2.5221-2.522-2.5221 1.1291-2.5221 2.522c0 1.3929 1.1292 2.5221 2.5221 2.5221ZM15.6471 20.9485h3.8088l.5147-11.42644C20.0221 8.13235 18.9412 7 17.5515 7c-1.3897 0-2.4706 1.13235-2.4191 2.52206l.5147 11.42644Z"
      fill="#414042"
    />
  </>,
  'Alert Circle'
);
