import React from 'react';
import { createSvgIcon } from '@mui/material';

export const SMS = createSvgIcon(
  <path
    fillRule="evenodd"
    clipRule="evenodd"
    d="M18 0H2C.9 0 0 .9 0 2v18l4-4h14c1.1 0 2-.9 2-2V2c0-1.1-.9-2-2-2Zm0 14H3.17L2 15.17V2h16v12ZM5 7h2v2H5V7Zm8 0h2v2h-2V7ZM9 7h2v2H9V7Z"
  />,
  'SMS'
);
