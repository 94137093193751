import { createAsyncThunk } from '@reduxjs/toolkit';
import { INotification } from 'modules/civilAndTrucking/shared/NotificationSnackbar/INotification';
import { pushNotification } from 'modules/civilAndTrucking/shared/NotificationSnackbar/redux/actions';
import { get } from 'services/api/restHelpers';
import { RootState } from 'store/store';
import Query from 'services/firebase/Query';
import Executer from 'services/firebase/Executer';
import { ILicense } from 'lg-helpers/dist/shared/interfaces/ILicense';
// import { IUser } from 'lg-helpers/dist/shared/interfaces/IUser';
// import { CivilUserLicenseTypes } from 'lg-helpers/dist/civil/types/CivilUserTypes';

const executer = new Executer();
const query = new Query();

export const getLicenses = createAsyncThunk('licenses/get-licenses', async (payload, thunkApi) => {
  try {
    const state = thunkApi.getState() as RootState;
    const companyId = state.civil.companies.activeCompany?.id;
    const data = (await get(`civil/companies/${companyId}/licenses`)) as ILicense;
    const queryUsers = query.users.getUsersInCompany(companyId!);
    const usersResults = await executer.getMultipleDocuments(queryUsers);

    // We need to render count of all users
    // const licensedUsers = ((usersResults as IUser[]) || []).filter(
    //   (user: IUser) => CivilUserLicenseTypes.includes(user?.type as any) // unreliable, because technically user can have 2 allowed types
    // );

    data.licensesRemaining = data.licensesPurchased - usersResults.length;
    return data;
  } catch (err) {
    const errorNotification: INotification = {
      status: 'error',
      message: 'Error: Unable to load licenses',
    };
    thunkApi.dispatch(pushNotification(errorNotification));
  }
});

export const addLicense = createAsyncThunk('licenses/add-license', async () => {});

export const deleteLicense = createAsyncThunk('company-materials/delete', async () => {});
