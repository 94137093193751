import React, { useState } from 'react';
import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import Tooltip, { tooltipClasses, TooltipProps } from '@mui/material/Tooltip';

interface HtmlTooltipProps extends TooltipProps {
  wrapperClass?: string;
}

const HtmlTooltip = styled(({ children, className, wrapperClass, ...props }: HtmlTooltipProps) => {
  const [showTooltip, setShowTooltip] = useState(false);
  return (
    <Tooltip
      {...props}
      open={showTooltip}
      onOpen={() => setShowTooltip(true)}
      onClose={() => setShowTooltip(false)}
      classes={{ popper: className }}
      className={wrapperClass}
    >
      <Box onClick={() => setShowTooltip(!showTooltip)}>{children}</Box>
    </Tooltip>
  );
})(() => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#fbbb00',
    color: 'black',
    padding: 12,
    maxWidth: 220,
    boxShadow: '2px 2px 1px #515151',
  },
}));

export default HtmlTooltip;
