import React, { useLayoutEffect } from 'react';
import { useEffect, useState } from 'react';
import api, { UserObject, WorkbookEntry } from 'services/api/autogenerated';
import { selectAuthUser } from 'modules/auth/storeSliceAuth';
import { useSelector } from 'react-redux';
import {
  Autocomplete,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  Select,
  TextField,
} from '@mui/material';
import { Header } from 'rsuite';

// import { componentAllowedPerms } from 'services/permissions';

// TODO: Implement reports entity
// export const EmbeddedSigma = componentAllowedPerms(['reports:read'])(() => {
const SigmaReportSettings = () => {
  const currentUser: UserObject = useSelector(selectAuthUser);

  const [workbooks, setWorkbooks] = useState<WorkbookEntry[]>([]);
  const [curWorkbook, setCurWorkbook] = useState<WorkbookEntry | undefined>(undefined);

  async function handleDefaultWorkbookChange(workbookId: string) {
    setCurWorkbook(workbooks?.find(workbook => workbook.urlId === workbookId) || undefined);

    await api.users.update(currentUser.id, {
      ...currentUser,
      sigma: {
        ...currentUser.sigma,
        defaultWorkbookId: workbookId,
      },
    });
  }

  useEffect(() => {
    let active = true;
    getWorkbooks();
    return () => {
      active = false;
    };

    async function getWorkbooks() {
      try {
        if (!active) return;
        const { data } = await api.reports.getWorkbooks();
        const workbooksData = data.workbooks;

        if (workbooksData) {
          const workbooksStaging = workbooksData.filter((workbook: WorkbookEntry) => {
            // Use any avenue to find V2-specific files.
            const regex =
              process.env.REACT_APP_BUILD_ENV === 'staging'
                ? /^V2 Public Staging$|^v2-company-staging-.*/i
                : /^V2 Public$|(v2-company-.*)(?!-staging)/i; // Exclude staging files
            const description =
              process.env.REACT_APP_BUILD_ENV === 'staging' ? '[V2 Staging]' : '[V2]';
            return regex.test(workbook.path) || workbook?.description?.includes(description);
          });

          setWorkbooks(workbooksStaging);
        }
      } catch (getSigmaEmbedError) {
        console.error('Error getting Sigma workbooks:', getSigmaEmbedError);
      }
    }
  }, []);

  useEffect(() => {
    if (currentUser?.sigma?.defaultWorkbookId) {
      setCurWorkbook(
        workbooks?.find(
          workbook => workbook.urlId === currentUser?.sigma?.defaultWorkbookId || undefined
        )
      );
    } else {
      setCurWorkbook(
        workbooks?.find(
          workbook =>
            workbook.urlId === process.env.REACT_APP_SIGMA_DEFAULT_WORKBOOK_ID || undefined
        )
      );
    }
  }, [workbooks, currentUser?.sigma?.defaultWorkbookId]);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Card>
          <CardHeader title="Reports Default Workbook" />
          <Divider />
          <CardContent>
            <Grid container alignItems={'center'} alignContent={'center'} spacing={2}>
              <Grid item xs={12}>
                <Header>Select default workbook for the reports page.</Header>
                <Header style={{ marginTop: '0.125rem' }}>
                  {curWorkbook ? (
                    <span>
                      Current default workbook: <strong>{curWorkbook.name}</strong>
                    </span>
                  ) : (
                    <span>No default workbook selected</span>
                  )}
                </Header>
                <Grid item xs={12}>
                  <Autocomplete
                    options={workbooks}
                    getOptionLabel={option => option.name}
                    groupBy={option =>
                      option.createdBy === currentUser?.sigma?.memberId ? 'Created by me' : 'Shared'
                    }
                    onChange={(event, newValue) => {
                      if (newValue) {
                        handleDefaultWorkbookChange(newValue.urlId);
                      }
                    }}
                    renderInput={params => (
                      <TextField
                        {...params}
                        label="Workbooks"
                        variant="outlined"
                        fullWidth
                        InputProps={{
                          ...params.InputProps,
                          endAdornment: <>{params.InputProps.endAdornment}</>,
                        }}
                      />
                    )}
                  />
                </Grid>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
};

export default SigmaReportSettings;
