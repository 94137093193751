import { AxiosResponse } from 'axios';
import useShowError from 'modules/errors';
import { IError } from 'modules/errors/storeSliceErrors';
import { useCallback, useState } from 'react';

export enum LoadingStatus {
  error = 'error',
  pending = 'pending',
  not_loaded = 'not_loaded',
  success = 'success',
}

export default function useApiCall<T, P extends unknown[]>(
  call: (...params: P) => Promise<AxiosResponse<T>> | null,
  errorBuilder?: (err: unknown) => IError,
  deps: any[] = []
) {
  const [status, setStatus] = useState(LoadingStatus.not_loaded);
  const [result, setResult] = useState<AxiosResponse<T> | null>();
  const [error, setError] = useState<unknown>();
  const showError = useShowError();

  const trigger = useCallback(async (...params: P) => {
    try {
      setStatus(LoadingStatus.pending);
      setResult(undefined);
      const r = await call(...params);
      setResult(r);
      setStatus(LoadingStatus.success);
      return r;
    } catch (err) {
      // TODO: implement better default message
      console.error('API error:', err);
      setError(err);
      const errorObj = errorBuilder ? errorBuilder(err) : { title: 'API error' };
      showError(errorObj);
      setStatus(LoadingStatus.error);
    }
    // eslint-disable-next-line
  }, deps);

  const additionalContext = {
    result,
    data: result?.data,
    status,
    error,
    trigger,
    loading: status === LoadingStatus.pending || status === LoadingStatus.not_loaded,
    fromCache: !!(result as any)?.fromCache,
  };
  return [trigger, additionalContext] as [typeof trigger, typeof additionalContext];
}
