import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { IError, pushError } from './storeSliceErrors';

const useShowError = () => {
  const dispatch = useDispatch();
  return useCallback((error: IError) => dispatch(pushError(error)), [dispatch]);
};

export default useShowError;
