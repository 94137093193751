export default {
  companyLogo: { isImage: true, x: 1950, y: 150, width: 400, height: 400 },
  __displayOptionsDefault: {
    font: 'Arial',
    color: '#000000',
    fontSize: 50,
    fontWeight: 'bold',
  },
  projectName: { x: 200, y: 200, fontSize: 64 },
  'Ticket #': { x: 200, y: 270, isStatic: true },
  ticketNumber: { x: 400, y: 270 },
  'Date Created': { x: 200, y: 340, isStatic: true },
  dateCreated: { x: 550, y: 340, isDate: true },
  'Site Name': { x: 200, y: 410, isStatic: true },
  addressName: { x: 470, y: 410 },
  Site: { x: 200, y: 480, isStatic: true },
  locationString: { x: 320, y: 480 },
  'Source Supplier': { x: 200, y: 600, isStatic: true },
  sourceSupplier: { x: 200, y: 670, fontWeight: 'normal' },
  Material: { x: 200, y: 800, isStatic: true },
  material: { x: 200, y: 870, fontWeight: 'normal' },
  'Pit Ticket #': { x: 200, y: 1000, isStatic: true },
  pitTicketNumber: { x: 200, y: 1070, fontWeight: 'normal' },
  'Truck #': { x: 200, y: 1200, isStatic: true },
  truckNumber: { x: 200, y: 1270, fontWeight: 'normal' },
  Quantity: { x: 1400, y: 1000, isStatic: true },
  quantityWithUnit: { x: 1400, y: 1070, fontWeight: 'normal' },
  Activity: { x: 1400, y: 1200, isStatic: true },
  activity: { x: 1400, y: 1270, fontWeight: 'normal' },
  'Broker/Carrier': { x: 1400, y: 1400, isStatic: true },
  brokerCarrier: { x: 1400, y: 1470, fontWeight: 'normal' },
  Transporter: { x: 200, y: 1400, isStatic: true },
  transporter: { x: 200, y: 1470, fontWeight: 'normal' },
  'Weigh Ticket #': { x: 1400, y: 600, isStatic: true },
  wmWeighTicketNumber: { x: 1400, y: 670, fontWeight: 'normal' },
  'Manifest #': { x: 1400, y: 800, isStatic: true },
  manifestNumber: { x: 1400, y: 870, fontWeight: 'normal' },
  'Driver Signature': { x: 200, y: 1600, isStatic: true },
  driverSignature: {
    x: 200,
    y: 1670,
    isImage: true,
    width: 900,
    height: 300,
  },
  'Gate Attendant Signature': { x: 1400, y: 1600, isStatic: true },
  gateUserSignature: {
    x: 1400,
    y: 1670,
    isImage: true,
    width: 900,
    height: 300,
  },
  Note: { x: 200, y: 2200, isStatic: true },
  note: { x: 200, y: 2270, fontWeight: 'normal' },
};
