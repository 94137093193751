import { createSlice } from '@reduxjs/toolkit';
// import { IUser } from 'lg-helpers/dist/shared/interfaces/IUser';
import { RootState } from 'store/store';

export const storeSliceAuth = createSlice({
  name: 'auth',
  initialState: {
    authUser: false as any,
  },
  reducers: {
    setAuthUser: (state, action) => {
      state.authUser = action.payload;
    },
  },
});

export const { setAuthUser } = storeSliceAuth.actions;

export const selectAuthUser = (state: RootState) => state.auth.authUser;

export default storeSliceAuth.reducer;
