import React from 'react';
import { createSvgIcon } from '@mui/material';

export const ExitIcon = createSvgIcon(
  <path
    fillRule="evenodd"
    clipRule="evenodd"
    d="M.2929.2929c.39052-.39053 1.02368-.39053 1.41421 0L9 7.58578l7.2929-7.2929c.3905-.39052 1.0237-.39052 1.4142 0 .3905.39053.3905 1.02369 0 1.41422L10.4142 9l7.2929 7.2929c.3905.3905.3905 1.0237 0 1.4142-.3905.3905-1.0237.3905-1.4142 0L9 10.4142l-7.29289 7.2929c-.39053.3905-1.0237.3905-1.41422 0s-.39052-1.0237 0-1.4142L7.5858 9 .29289 1.70711c-.39052-.39053-.39052-1.0237 0-1.41422Z"
  />,
  'Exit'
);
