import React from 'react';
import './Lane.scss';
import PropTypes from 'prop-types';
import { Avatar, Card, CardContent, Typography, Box } from '@mui/material';

const LaneEmpty = ({ lane }) => {
  return (
    <Card raised={true}>
      <CardContent>
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          marginBottom={1}
          style={{ minHeight: 200 }}
        >
          <Avatar
            style={{
              backgroundColor: lane.color,
            }}
          >
            <Typography variant="h5" style={{ color: 'white' }}>
              {lane.label}
            </Typography>
          </Avatar>
        </Box>
      </CardContent>
    </Card>
  );
};

LaneEmpty.propTypes = {
  lane: PropTypes.object.isRequired,
};

export default LaneEmpty;
