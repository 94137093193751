import React, { useState, useEffect } from 'react';
import { Grid, Box, Button, CircularProgress, Typography } from '@mui/material';
import CustomInputsDefinitionsCrud from './CustomInputsDefinitionsCrud';
import Command from 'services/firebase/Command';
import Query from 'services/firebase/Query';
import Executer from 'services/firebase/Executer';
import useShowError from 'modules/errors';
import { ICustomInputDefinitions } from 'lg-helpers/dist/shared/interfaces/ICustomInputDefinitions';
import QueryBase from 'lg-helpers/dist/firestore/query/QueryBase';
const command = new Command();
const query = new Query();
const executer = new Executer();

const CustomInputsManifestListDefinitionsCrud = ({ projectId }: { projectId: string }) => {
  const [isLoadingData, setIsLoadingData] = useState<boolean>(false);
  const showError = useShowError();
  const [localCustomInputsDefinitions, setLocalCustomInputsDefinitions] = useState<
    ICustomInputDefinitions[]
  >([]);

  const [wasSubmitted, setWasSubmitted] = useState<boolean>(false);
  const [hasChanges, setHasChanges] = useState<boolean>(false);
  const [isSaving, setIsSaving] = useState<boolean>(false);

  useEffect(() => {
    if (!projectId) {
      return;
    }

    setIsLoadingData(true);
    return executer.watchSingleDocument(
      query.base.getById(QueryBase.PROJECTS_COLLECTION(), projectId),
      proj => {
        if (proj) {
          setLocalCustomInputsDefinitions(proj.customInputsDefinitions || []);
        } else {
          showError({ title: "This project doesn't exist", duration: 10000 });
        }
        setIsLoadingData(false);
      },
      error => {
        console.error('Error getting document:', error);
        showError({
          title: 'Error while fetching the project',
          duration: 10000,
        });
        setIsLoadingData(false);
      }
    );
    // eslint-disable-next-line
  }, [projectId]);

  const handleListCustomInputsDefinitionsChange = (
    customInputsDefinitions: ICustomInputDefinitions[]
  ) => {
    setHasChanges(true);
    const updateCustomInputsDefinitions = localCustomInputsDefinitions.map(localDefinition => {
      const updatedListInputsDefinition = customInputsDefinitions.find(
        inputsDefinition => inputsDefinition.id === localDefinition.id
      );
      return updatedListInputsDefinition || localDefinition;
    });

    setLocalCustomInputsDefinitions(updateCustomInputsDefinitions);
    setWasSubmitted(false);
  };

  const hasErrors = () =>
    !!(localCustomInputsDefinitions || []).filter(
      ({ isValidCustomInputDefinition }) => !isValidCustomInputDefinition
    ).length;

  const handleSave = async () => {
    setIsSaving(true);

    if (hasErrors()) {
      showError({ title: 'Validation errors' });
      setHasChanges(false);
    } else {
      try {
        await command.projects.updateProjectCustomInputsDefinitions(
          projectId,
          localCustomInputsDefinitions
        );
        setHasChanges(false);
      } catch (error) {
        console.error(error);
        showError({ title: 'Save error' });
      }
    }

    setIsSaving(false);
    setWasSubmitted(true);
  };

  return (
    <Box marginTop={3}>
      {isLoadingData ? (
        <Grid item xs={12}>
          <Box display="flex" justifyContent="center" alignItems="center">
            <CircularProgress color="inherit" />
          </Box>
        </Grid>
      ) : (
        <CustomInputsDefinitionsCrud
          customInputsDefinitions={
            localCustomInputsDefinitions.filter(definition => definition.type === 'List') || []
          }
          setCustomInputsDefinitions={handleListCustomInputsDefinitionsChange}
          wasSubmitted={wasSubmitted}
          handleSave={handleSave}
          isSaving={isSaving}
        />
      )}
    </Box>
  );
};

export default CustomInputsManifestListDefinitionsCrud;
