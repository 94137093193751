import React, { useEffect, useState } from 'react';
import { ITruckingTicketNote } from 'lg-helpers/dist/trucking/interfaces/ITruckingTicketNote';
import InputCameraImage from 'sharedComponents/InputCameraImage';
import { Typography, Box, Avatar, Grid, Tooltip, IconButton } from '@mui/material';
import { getBase64FromUrl } from 'services/logic/base64';
import { logic } from 'lg-helpers';
import { EditIcon, ExitIcon } from 'modules/civilAndTrucking/shared/CustomIcons';
import { SaveAs, Delete } from '@mui/icons-material';
import { FormTextArea } from 'sharedComponents/FormTextArea';
import InputTruckingImage from 'sharedComponents/InputTruckingImage';
import { uploadNotesImageAndGetUrl } from 'api/brokers';
import useShowError from 'modules/errors';
import { usePermissionsContext } from 'services/permissions';

const { getDateStringForTimezone } = logic.dateTime;

type TicketNoteProps = {
  ticketNote: ITruckingTicketNote;
  handleSaveTicketNote?: (note: ITruckingTicketNote) => void;
};

export const TicketNote = ({ ticketNote, handleSaveTicketNote }: TicketNoteProps) => {
  const [imageBase64, setImageBase64] = useState<string | undefined>();
  const [newImageUploaded, setNewImageUploaded] = useState<boolean>(false);
  const [isLoadingImage, setIsLoadingImage] = useState(false);
  const [isBeingEdited, setIsBeingEdited] = useState(false);
  const [noteText, setNoteText] = useState(ticketNote.textTruckingTicketNotes);
  const [photoUrl, setPhotoUrl] = useState(ticketNote.photoUrlTruckingTicketNotes);
  const showError = useShowError();
  const { user: authUser } = usePermissionsContext();

  const getNoteImageBase64 = () => {
    const { photoUrlTruckingTicketNotes } = ticketNote;
    if (photoUrlTruckingTicketNotes) {
      setIsLoadingImage(true);
      getBase64FromUrl(photoUrlTruckingTicketNotes).then(
        base64 => {
          setImageBase64(base64);
          setIsLoadingImage(false);
        },
        error => {
          console.error('getBase64FromUrl', error);
          setIsLoadingImage(false);
        }
      );
    }
  };

  useEffect(() => {
    if (!ticketNote) return;
    getNoteImageBase64();
  }, [ticketNote]);

  const handleEditTicketNote = () => {
    setIsBeingEdited(!isBeingEdited);
    setNoteText(ticketNote.textTruckingTicketNotes);
    setNewImageUploaded(false);
    if (isBeingEdited) getNoteImageBase64();
  };

  const handleDeleteImage = () => {
    setNewImageUploaded(false);
    setImageBase64(undefined);
    setPhotoUrl('');
  };

  const handleSaveNote = async () => {
    try {
      let photoUrlTruckingTicketNotes = photoUrl;
      if (newImageUploaded && imageBase64 && authUser) {
        photoUrlTruckingTicketNotes = (await uploadNotesImageAndGetUrl(
          String(authUser.id) as string,
          String(ticketNote.truckingTicketIdTruckingTicketNotes),
          imageBase64
        )) as string;
      }
      if (handleSaveTicketNote)
        handleSaveTicketNote({
          ...ticketNote,
          textTruckingTicketNotes: noteText,
          photoUrlTruckingTicketNotes,
        });
      setIsBeingEdited(!isBeingEdited);
    } catch (err) {
      console.error(err);
      showError({
        title: `Failed to ${newImageUploaded && imageBase64 ? 'update' : 'create'} note`,
      });
    }
  };

  const handleNotesChange = (event: any) => {
    const { value } = event.target;
    setNoteText(value);
  };

  return !isBeingEdited && !noteText && !photoUrl ? null : (
    <Grid
      container
      columnSpacing={1}
      alignItems="start"
      justifyContent="space-between"
      overflow="auto"
      sx={{
        p: 2,
        '&:nth-of-type(even)': {
          backgroundColor: '#eee',
        },
      }}
    >
      <Grid item alignSelf="center" md={2} sm={3}>
        <Avatar alt="default">{ticketNote.userNameTruckingTicketNotes[0]}</Avatar>
      </Grid>
      <Grid item md={9} sm={7}>
        <Box display="flex" justifyContent="space-between">
          <Typography variant="subtitle2">
            {ticketNote.userNameTruckingTicketNotes || ''}
          </Typography>
          <Typography variant="subtitle2">
            {getDateStringForTimezone(ticketNote.createdAtTruckingTicketNotes, 'EST') || ''}
          </Typography>
        </Box>
        <Box>
          <Box>
            {isBeingEdited ? (
              <FormTextArea
                value={noteText}
                placeholder="Add text to note"
                onChange={handleNotesChange}
                minRows={2}
                style={{ fontSize: '20px' }}
              />
            ) : (
              <Typography variant="h4" color="textPrimary">
                {noteText}
              </Typography>
            )}
          </Box>
        </Box>
        {imageBase64 ? (
          <Box>
            <Box sx={{ position: 'relative' }} marginBottom={1}>
              <InputCameraImage
                title="Image"
                imageBase64={imageBase64}
                isLoading={isLoadingImage}
                key={`note_image_${ticketNote.idTruckingTicketNotes}`}
                disabled={true}
              />
              {isBeingEdited && (
                <Tooltip title="Delete">
                  <IconButton
                    onClick={handleDeleteImage}
                    sx={{
                      position: 'absolute',
                      top: '1rem',
                      right: '1rem',
                      fontSize: 18,
                    }}
                  >
                    <Delete />
                  </IconButton>
                </Tooltip>
              )}
            </Box>
          </Box>
        ) : (
          isBeingEdited && (
            <InputTruckingImage
              buttonText="Upload Image"
              imageBase64={imageBase64}
              setImageBase64={setImageBase64}
              setNewImageUploaded={setNewImageUploaded}
              sx={{ marginTop: 1, justifyContent: 'center' }}
            />
          )
        )}
      </Grid>
      <Grid item md={1} sm={2}>
        <Tooltip title={isBeingEdited ? 'Cancel' : 'Edit note'}>
          <IconButton onClick={handleEditTicketNote} sx={{ fontSize: 18 }}>
            {isBeingEdited ? (
              <ExitIcon viewBox="-5 -5 27 27" />
            ) : (
              <EditIcon viewBox="-5 -5 29 29" />
            )}
          </IconButton>
        </Tooltip>
        {isBeingEdited && (
          <Tooltip title="Save as is">
            <IconButton onClick={handleSaveNote} sx={{ fontSize: 18 }}>
              <SaveAs />
            </IconButton>
          </Tooltip>
        )}
      </Grid>
    </Grid>
  );
};
