import React, { useEffect, useCallback, useMemo } from 'react';
import statesUSA from 'utils/statesUSA.json';
import { hasErrorRequired } from 'services/logic/formValidation';
import { Grid, TextField, Autocomplete } from '@mui/material';
import ErrorMessages from 'services/constants/errorMessages';

const { isRequired } = ErrorMessages;

export interface IStateValue {
  name: string;
  value: string;
}

type PossibleReturnValue =
  | {
      name: string;
      value: string;
    }
  | string
  | null;

export const addressKeys = ['country', 'address', 'state', 'postalCode', 'city'] as const;
type AddressKeys = typeof addressKeys[number];

interface IChangeEvent {
  target: {
    name: AddressKeys;
    value: PossibleReturnValue;
  };
}

const statesAll = {
  USA: statesUSA,
} as { [key: string]: IStateValue[] };

const countries = [
  {
    name: 'The United States of America (USA)',
    value: 'USA',
  },
];

const InputAddressFull = ({
  locationData,
  handleChangeCallback,
  wasSubmitted,
  addressIsOptional = false,
  isDisabledState = false,
  returnAllStringValues = false,
}: {
  locationData: {
    country?: string | null;
    state?: string | null;
    postalCode?: string | null;
    address?: string | null;
    city?: string | null;
  };
  handleChangeCallback: (event: IChangeEvent) => any;
  wasSubmitted?: boolean;
  addressIsOptional?: boolean;
  isDisabledState?: boolean;
  returnAllStringValues?: boolean;
}) => {
  const getStateLabel = useCallback(
    (stateObj: any) => (stateObj ? `${stateObj.name} (${stateObj.value})` : ''),
    []
  );
  const selectedCountry = useMemo(
    () => countries.find(({ value }) => value === locationData?.country) || null,
    [locationData?.country]
  );
  const states = useMemo(() => statesAll[selectedCountry?.value || 'USA'], [selectedCountry]);
  const selectedState = useMemo(
    () => states.find(({ value }) => value === locationData?.state) || null,
    [locationData?.state, states]
  );

  useEffect(() => {
    if (!locationData) {
      return;
    }

    if (!locationData.country && countries.length === 1) {
      handleChangeCallback({
        target: {
          name: 'country',
          value: countries[0],
        },
      });
    }
    // eslint-disable-next-line
  }, [locationData]);

  return (
    <>
      <Grid item md={6} xs={12}>
        <Autocomplete
          fullWidth
          options={countries}
          disabled={countries.length === 1}
          value={selectedCountry || null}
          getOptionLabel={option => option?.name || 'USA'}
          isOptionEqualToValue={(option, val) => option.value === val.value}
          onChange={(event, value) =>
            handleChangeCallback({
              target: {
                name: 'country',
                value: (returnAllStringValues ? value?.value : value) || null,
              },
            })
          }
          renderInput={params => (
            <TextField
              {...params}
              role="AddressFullInput--field__country"
              label="Country"
              variant="outlined"
              error={hasErrorRequired(locationData.country, wasSubmitted)}
              helperText={hasErrorRequired(locationData.country, wasSubmitted) ? isRequired : ''}
              required
            />
          )}
        />
      </Grid>
      <Grid item md={6} xs={12}>
        <TextField
          fullWidth
          label="Address"
          name="address"
          onChange={e => handleChangeCallback(e as IChangeEvent)}
          value={locationData.address || ''}
          variant="outlined"
          role="AddressFullInput--field__address"
          error={
            addressIsOptional ? undefined : hasErrorRequired(locationData.address, wasSubmitted)
          }
          helperText={
            addressIsOptional
              ? ''
              : hasErrorRequired(locationData.address, wasSubmitted)
              ? isRequired
              : ''
          }
          required={!addressIsOptional}
        />
      </Grid>
      <Grid item md={6} xs={12}>
        <TextField
          required
          fullWidth
          label="City"
          name="city"
          variant="outlined"
          value={locationData.city || ''}
          role="AddressFullInput--field__city"
          onChange={e => handleChangeCallback(e as IChangeEvent)}
          error={hasErrorRequired(locationData.city, wasSubmitted)}
          helperText={hasErrorRequired(locationData.city, wasSubmitted) ? isRequired : ''}
        />
      </Grid>
      <Grid item md={6} xs={12}>
        <Autocomplete
          fullWidth
          options={states}
          value={selectedState}
          getOptionLabel={getStateLabel}
          disabled={!locationData.country || isDisabledState}
          isOptionEqualToValue={(option, value) =>
            value && option ? option.value === value.value : false
          }
          onChange={(event, value) => {
            handleChangeCallback({
              target: {
                name: 'state',
                value: (returnAllStringValues ? value?.value : value) || null,
              },
            });
          }}
          renderInput={params => (
            <TextField
              {...params}
              required
              label="State"
              variant="outlined"
              value={getStateLabel}
              role="AddressFullInput--field__states"
              error={hasErrorRequired(locationData.state, wasSubmitted)}
              helperText={hasErrorRequired(locationData.state, wasSubmitted) ? isRequired : ''}
            />
          )}
        />
      </Grid>
      <Grid item md={6} xs={12}>
        <TextField
          fullWidth
          label="ZIP Code"
          name="postalCode"
          variant="outlined"
          role="AddressFullInput--field__zipCode"
          onChange={e => handleChangeCallback(e as IChangeEvent)}
          value={locationData.postalCode || ''}
        />
      </Grid>
    </>
  );
};

export default InputAddressFull;
