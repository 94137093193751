import React, { useState, useEffect } from 'react';
import { Grid, Box, Card, CardContent, List, Divider } from '@mui/material';
import { CustomInputDefinitionGlobalList } from './CustomInputDefinitionGlobalList';
import { IGlobalCustomInputs } from 'services/api/autogenerated-code';
import { ICompany } from 'lg-helpers/dist/shared/interfaces/ICompany';
import { GlobalCustomInputToolbar } from 'modules/superAdmins/GlobalCustomInputToolbar';
import { ICustomInputsFilterOptions } from 'modules/superAdmins/GlobalCustomInputAdmin';

export const inputDefinitionTypes = ['Text', 'List', 'Checkbox', 'Signature'];

interface CustomInputsDefinitionsCrudProps {
  customInputsDefinitions: IGlobalCustomInputs[];
  setCustomInputsDefinitions: (customInputsDefinitions: IGlobalCustomInputs[]) => void;
  wasSubmitted: boolean;
  userCanEdit?: boolean;
  companies: ICompany[];
  deleteCustomInput?: (id: number) => void;
  handleSaveSingleInput: (inputDefinition: IGlobalCustomInputs) => void;
  filterOptions: ICustomInputsFilterOptions;
  setFilterOptions: (filterOptions: ICustomInputsFilterOptions) => void;
}

const CustomInputsDefinitionsCrudGlobal = ({
  customInputsDefinitions,
  setCustomInputsDefinitions,
  wasSubmitted,
  userCanEdit = true,
  companies,
  deleteCustomInput,
  handleSaveSingleInput,
  filterOptions,
  setFilterOptions,
}: CustomInputsDefinitionsCrudProps) => {
  const [canValidate, setCanValidate] = useState(false);

  const hideItem = (hiddenItem: IGlobalCustomInputs) => {
    setInputDefinition({
      ...hiddenItem,
      isHidden: !hiddenItem.isHidden,
    });
  };

  const setInputDefinition = (inputDefinition: IGlobalCustomInputs) => {
    setCustomInputsDefinitions(
      customInputsDefinitions.map(oldInputDefinition => {
        if (oldInputDefinition.id === inputDefinition.id) {
          return inputDefinition;
        }
        return oldInputDefinition;
      })
    );
  };

  return (
    <Box marginTop={3}>
      <Card>
        <GlobalCustomInputToolbar
          filterOptions={filterOptions}
          setFilterOptions={setFilterOptions}
          companies={companies}
        />
        <Divider />
        <CardContent>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <List>
                {customInputsDefinitions
                  .sort((a, b) => a.order - b.order)
                  .map(inputDefinition => (
                    <CustomInputDefinitionGlobalList
                      key={inputDefinition.id}
                      userCanEdit={userCanEdit}
                      inputDefinition={inputDefinition}
                      setInputDefinition={setInputDefinition}
                      companies={companies}
                      canValidate={wasSubmitted || !!(canValidate && inputDefinition?.id)}
                      setCanValidate={setCanValidate}
                      hideCallback={hideItem}
                      deleteCustomInput={id => {
                        if (!deleteCustomInput) return;
                        return deleteCustomInput(id);
                      }}
                      handleSaveSingleInput={handleSaveSingleInput}
                    />
                  ))}
              </List>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </Box>
  );
};

export default CustomInputsDefinitionsCrudGlobal;
