import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import useShowError from 'modules/errors';

import Query from 'services/firebase/Query';
import Executer from 'services/firebase/Executer';
import { componentAllowedPerms } from 'services/permissions';
import EditBatchManifestsForm from './EditBatchManifestsForm';
import IBatchManifests from 'lg-helpers/dist/shared/interfaces/IBatchManifests';
import QueryBase from 'lg-helpers/dist/firestore/query/QueryBase';

const query = new Query();
const executer = new Executer();

export default componentAllowedPerms(['batched_manifests:read'], () => <p>Access refused</p>)(
  function EditBatchManifestsView() {
    const { id } = useParams();
    const [batch, setBatch] = useState<IBatchManifests | undefined>(undefined);
    const [isLoading, setIsLoading] = useState(true);
    const showError = useShowError();

    useEffect(() => {
      if (id === 'new' || !id) return setIsLoading(false);

      setIsLoading(true);

      return executer.watchSingleDocument(
        query.base.getById(QueryBase.BATCHED_MANIFESTS_COLLECTION(), id),
        data => {
          setBatch(data as IBatchManifests);
          setIsLoading(false);
        },
        err => {
          console.error(err);
          showError({
            title: `Failed to load batch ${id}`,
            duration: 10000,
          });
          setIsLoading(false);
        }
      );
      // eslint-disable-next-line
    }, [id]);

    return <>{!isLoading && <EditBatchManifestsForm initialValues={batch} />}</>;
  }
);
