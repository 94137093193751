import React from 'react';
import { createSvgIcon } from '@mui/material';

export const ThumbsUp = createSvgIcon(
  <path
    fillRule="evenodd"
    clipRule="evenodd"
    d="M35 15.1838c0-2.1618-1.75-3.8603-3.8603-3.8603h-6.1765c.1544-.5662.2574-1.2868.3089-2.11033.2573-3.80882-.4118-6.48529-2.0589-7.97794-1.4411-1.33824-3.0367-1.2353-3.7058-1.13235-.5662.10294-1.0809.41176-1.4412.92647-.8236 1.13235-.5147 2.98529-.4118 3.55147.2574 2.16176-.772 3.34559-2.2132 4.99264-.3603.3603-.6691.77204-1.0294 1.23534-.4633.6176-2.5221 1.4926-4.3236 1.9558-.15438-.3603-.51467-.6176-.97791-.6176H1.02941C.46324 12.147 0 12.6102 0 13.1764v19.6103c0 .5662.46324 1.0294 1.02941 1.0294h8.13235c.46324 0 .87504-.3088.97794-.7206 1.1324.0515 2.0588.2574 3.1912.5147.2573.0515.4632.103.7206.1544 1.647.3603 3.2941.7206 7 .7206h7.3603c2.1617 0 3.8603-1.75 3.8603-3.8603 0-.7206-.2059-1.4411-.5662-2.0588.9265-.7206 1.4926-1.8529 1.4926-3.0368 0-.6691-.1544-1.3382-.5147-1.9044 1.0294-.7206 1.6986-1.9044 1.6986-3.1911 0-.8236-.2574-1.5956-.6692-2.2133.8236-.875 1.2868-1.9044 1.2868-3.0367ZM8.13235 31.397c0 .1029-.05147.1544-.05147.2573H2.05882V14.1029h6.02206v.1029c0 .103 0 .2059.05147.2574V31.397ZM31.7059 16.8823c-.3603.1029-.6691.4632-.7206.875-.0515.4118.1544.772.4632 1.0294.5147.3088.875.9265.875 1.5441 0 .875-.6691 1.6471-1.4926 1.8015-.4118.0515-.7721.3603-.8235.772-.1545.3603 0 .7721.3602 1.0295.4633.3603.7721.875.7721 1.4411 0 .8236-.5662 1.5442-1.3897 1.75-.4118.103-.7206.4118-.7721.8236-.0514.4117.103.8235.4633 1.0294.5147.3603.772.875.772 1.4926 0 .978-.8235 1.8015-1.8014 1.8015h-7.3603c-3.4486 0-5.0441-.3603-6.5368-.6691-.2573-.0515-.5147-.103-.7721-.1544-1.1323-.2574-2.2647-.4633-3.5514-.5147V14.8235c1.3897-.3603 4.7353-1.3897 5.8676-2.7794.3088-.4118.6691-.7721.978-1.1324 1.4926-1.69853 3.0367-3.5 2.6764-6.63971v-.10294c-.2058-.92647-.1029-1.75.103-2.00735l.0514-.05147c.3603-.05147 1.1839-.10294 1.9559.61765 1.1324 1.02941 1.5956 3.19117 1.3897 6.27941-.1029 1.54411-.4117 2.47061-.6176 2.88231-.1544.3089-.103.6692.1029.978.2059.3088.5147.4632.875.4632h7.5662c.9779 0 1.8015.8235 1.8015 1.8015 0 .8235-.5147 1.4926-1.2353 1.75Z"
  />,
  'Thumbs Up'
);
