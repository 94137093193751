import React from 'react';
import { DialogContent } from '@mui/material';
import TicketMessages from './TicketMessages';
import ModalHeader from 'sharedComponents/DialogHeader';

interface ITicketMessagesProps {
  ticketId: number;
}

const TicketMessagesContainer = ({ ticketId }: ITicketMessagesProps) => {
  return (
    <>
      <ModalHeader title="Ticket Messages" />
      <DialogContent>
        <TicketMessages ticketId={ticketId} />
      </DialogContent>
    </>
  );
};

export default TicketMessagesContainer;
