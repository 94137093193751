import React from 'react';
import { createSvgIcon } from '@mui/material';

export const UploadLogs = createSvgIcon(
  <path
    fillRule="evenodd"
    clipRule="evenodd"
    d="M0 .99988c0-.55229.44771-1 1-1h16c.5523 0 1 .44771 1 1V10.9999c0 .5523-.4477 1-1 1s-1-.4477-1-1V1.99988H2V19.9999h7c.55229 0 1 .4477 1 1s-.44771 1-1 1H1c-.55229 0-1-.4477-1-1V.99988Zm4 6.00001c0-.55228.44772-1 1-1h8c.5523 0 1 .44772 1 1 0 .55229-.4477 1-1 1H5c-.55228 0-1-.44771-1-1Zm0 4.00001c0-.5523.44772-1.00001 1-1.00001h4c.55229 0 1 .44771 1 1.00001s-.44771 1-1 1H5c-.55228 0-1-.4477-1-1Zm10.75 2c.2652 0 .5196.1054.7071.2929l2.25 2.25c.3905.3905.3905 1.0237 0 1.4142-.3905.3905-1.0237.3905-1.4142 0l-.5429-.5429v4.5858c0 .5523-.4477 1-1 1s-1-.4477-1-1v-4.5858l-.5429.5429c-.3905.3905-1.0237.3905-1.4142 0-.3905-.3905-.3905-1.0237 0-1.4142l2.25-2.25a1.0001 1.0001 0 0 1 .7071-.2929Z"
  />,
  'Upload Logs'
);
