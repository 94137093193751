import { object, array, string, boolean } from 'yup';
import { TProjectForm } from './views/ProjectInfoForm';

export const projectSchema = object<TProjectForm>({
  name: string().required('Required'),
  address: string().min(5, 'Please enter a valid address').nullable(),
  geo: array<number>().nullable(),
  startDate: string().required('Required'),
  endDate: string().required('Required'),
  startTime: string().required('Required'),
  endTime: string().required('Required'),
  active: boolean().required('Required'),
  companyId: string(),
  projectNumber: string(),
  timeZone: string(),
  isArchived: boolean(),
});
