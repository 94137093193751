import React from 'react';
import { DialogContent } from '@mui/material';
import ModalHeader from 'sharedComponents/DialogHeader';
import CompanyMaterialForm from './CompanyMaterialsForm';
import { ICompanyMaterial } from 'lg-helpers/dist/shared/interfaces/ICompanyMaterial';

interface ModalContainerProps {
  selected?: ICompanyMaterial;
  setMaterial?: Function;
}

const ModalContainer = ({ selected, setMaterial }: ModalContainerProps) => {
  return (
    <>
      <ModalHeader title={'Material'} />
      <DialogContent>
        <CompanyMaterialForm selected={selected} setMaterial={setMaterial} />
      </DialogContent>
    </>
  );
};

export default ModalContainer;
