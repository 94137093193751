import { createSlice } from '@reduxjs/toolkit';
import {
  getProjectMaterials,
  deleteProjectMaterial,
  archiveAndUpdateProjectMaterial,
  getProfileIds,
} from './actions';
import { IProjectMaterial } from 'lg-helpers/dist/shared/interfaces/IProjectMaterial';
import { TFormStatus } from 'lg-helpers/dist/shared/types/TFormStatus';

export type TProfileNumber = {
  id: string;
  profileNr: string;
  name: string;
};

interface IProjectMaterialState {
  data: IProjectMaterial[];
  profileIds: TProfileNumber[];
  status: TFormStatus;
  error: string | undefined;
}

const initialState: IProjectMaterialState = {
  data: [],
  profileIds: [],
  status: null,
  error: undefined,
};

export const projectMaterialSlice = createSlice({
  name: 'project-materials',
  reducers: {
    add: state => state,
  },
  initialState,
  extraReducers: builder => {
    builder.addCase(getProjectMaterials.fulfilled, (state, { payload }) => {
      state.status = 'success';
      state.data = payload as IProjectMaterial[];
    });

    builder.addCase(getProjectMaterials.rejected, (state, action) => {
      state.status = 'failure';
      state.error = action.error?.message;
    });

    builder.addCase(archiveAndUpdateProjectMaterial.rejected, (state, action) => {
      state.status = 'failure';
      state.error = action.error?.message;
    });

    builder.addCase(deleteProjectMaterial.fulfilled, state => {
      state.status = 'success';
    });

    builder.addCase(deleteProjectMaterial.rejected, (state, action) => {
      state.status = 'failure';
      state.error = action.error?.message;
    });

    builder.addCase(getProfileIds.fulfilled, (state, action) => {
      state.profileIds = action.payload as TProfileNumber[];
    });

    builder.addCase(getProfileIds.rejected, (state, action) => {
      state.status = 'failure';
      state.error = action.error?.message;
    });
  },
});
