import React from 'react';
import { Routes, Route } from 'react-router-dom';
import { ConnectedCompanyType } from 'services/api/autogenerated-code';
import { RelatedCompanies } from 'modules/civilAndTrucking/trucking/sharedComponents/RelatedCompanies';

export const TransportersViews = () => {
  return (
    <Routes>
      <Route
        path="/"
        element={
          <RelatedCompanies
            globalCompanyType={ConnectedCompanyType.Transporter}
            localCompanyType={ConnectedCompanyType.Transporter}
            companyTypeName={'Transporter'}
            companyTypeNamePlural={'Transporters'}
          />
        }
      />
    </Routes>
  );
};
