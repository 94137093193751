import React, { useEffect, useState } from 'react';
import { DialogContent, Box, Grid } from '@mui/material';
import ModalHeader from 'sharedComponents/ModalHeader';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import Executer from 'services/firebase/Executer';
import Query from 'services/firebase/Query';
import { IUser } from 'lg-helpers/dist/shared/interfaces/IUser';
import { IProject } from 'lg-helpers/dist/shared/interfaces/IProject';
import InputSearch from 'sharedComponents/InputSearch';

const executer = new Executer();
const query = new Query();

type IManifestProjectContacts = {
  companyType: 'Generator' | 'Contractor' | 'Transporter';
  project: IProject;
  handleChange: (e: { target: { name: string; value: any } }) => void;
  allowChange: boolean;
};

export const ManifestProjectContacts = ({
  companyType,
  project,
  handleChange,
  allowChange,
}: IManifestProjectContacts) => {
  const [usersOfCompanyType, setUsersOfCompanyType] = useState<IUser[]>([]);
  const [userSearchFilter, setUserSearchFilter] = useState('');
  // Because this component is stored as a value in a Context Provider it does not re-render on prop updates.
  // Therefore (without refactoring) contactUserIds state must be stored in the component and on the project.
  const [contactUserIds, setContactUserIds] = useState<string[]>([]);
  const projectContactFieldName =
    companyType === 'Generator'
      ? 'generatorUserIds'
      : companyType === 'Contractor'
      ? 'contractorUserIds'
      : 'transporterUserIds';

  const onSelectionModelChange = (rowsSelected: string[]) => {
    // State is updated for BOTH the project and the local contactUserIds useState.
    handleChange({
      target: {
        name: 'projectContacts',
        value: {
          ...project?.projectContacts,
          [projectContactFieldName]: rowsSelected,
        },
      },
    });
    setContactUserIds(rowsSelected);
  };

  const usersInProjectCompanyTypeColumns: GridColDef[] = [
    { field: 'firstName', headerName: 'First Name', flex: 1 },
    { field: 'lastName', headerName: 'Last Name', flex: 1 },
    { field: 'email', headerName: 'Email', flex: 1 },
    { field: 'phone', headerName: 'Phone', flex: 1 },
    { field: 'type', headerName: 'User Type', flex: 1 },
  ];

  useEffect(() => {
    (async () => {
      const projectCompaniesIdsOfType =
        companyType === 'Generator'
          ? [project?.generatorCompany?.id]
          : companyType === 'Transporter'
          ? project?.transporterCompaniesIds
          : [project?.contractorCompany?.id];

      const filteredProjectCompaniesIds = projectCompaniesIdsOfType?.filter(
        (companyId: string | undefined) => companyId !== undefined
      ) as string[];

      const usersInProjectCompanies = (await executer.getMultipleDocuments(
        query.users.getUsersInCompanies(filteredProjectCompaniesIds)
      )) as IUser[];

      const existingContactUsers: string[] =
        project?.projectContacts?.[projectContactFieldName] || [];
      const filteredContactUsers = existingContactUsers?.filter(contactUser =>
        usersInProjectCompanies
          ?.map(userInProjectCompanies => userInProjectCompanies.id)
          ?.includes(contactUser)
      );

      // Sort the users so that already selected users appear first
      usersInProjectCompanies.sort(
        (a, b) =>
          Number(existingContactUsers.includes(b.id!)) -
          Number(existingContactUsers.includes(a.id!))
      );
      setUsersOfCompanyType(usersInProjectCompanies || []);

      setContactUserIds(filteredContactUsers);
    })();
    // eslint-disable-next-line
  }, []);

  const filterUsers = () => {
    if (!userSearchFilter || !usersOfCompanyType?.length) {
      return usersOfCompanyType || [];
    } else {
      const lowercaseSearchFilter = userSearchFilter.toLowerCase();
      return usersOfCompanyType.filter(
        users =>
          users.firstName.toLowerCase().includes(lowercaseSearchFilter) ||
          users.lastName.toLowerCase().includes(lowercaseSearchFilter) ||
          users.email?.toLowerCase().includes(lowercaseSearchFilter)
      );
    }
  };

  return (
    <Box sx={{ p: '0px 16px' }}>
      <ModalHeader title={`${companyType} Project Contacts`} />
      <DialogContent>
        <InputSearch
          disabled={false}
          value={userSearchFilter}
          onChange={e => setUserSearchFilter(e.target.value)}
        />
        <Grid item xs={12} minHeight={500} height={'80vh'}>
          <DataGrid
            columns={usersInProjectCompanyTypeColumns}
            rows={filterUsers()}
            checkboxSelection={allowChange}
            onSelectionModelChange={(newRowSelectionModel: any[]) => {
              onSelectionModelChange(newRowSelectionModel);
            }}
            selectionModel={contactUserIds}
          />
        </Grid>
      </DialogContent>
    </Box>
  );
};
