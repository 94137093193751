import React from 'react';
import { createSvgIcon } from '@mui/material';

export const NewspaperFolding = createSvgIcon(
  <path
    fillRule="evenodd"
    clipRule="evenodd"
    d="M0 1c0-.55229.44771-1 1-1h10c.5043 0 .9297.37554.9923.87596L12.3828 4H21c.5523 0 1 .44772 1 1v16c0 .5523-.4477 1-1 1H9.5c-.50432 0-.92972-.3755-.99228-.876L8.11722 18H1c-.55229 0-1-.4477-1-1V1Zm10.1328 17 .0829.6634.5805-.6634h-.6634Zm1.7344-2H2V2h8.1172l1.75 14Zm-.1634 4H20V6h-7.3672l.25 2H16.5c.5523 0 1 .44772 1 1 0 .55229-.4477 1-1 1h-3.3672l.25 2H16.5c.5523 0 1 .4477 1 1s-.4477 1-1 1h-2.8672l.3595 2.876c.0354.2831-.0519.5678-.2397.7825L11.7038 20ZM3 5c0-.55228.44772-1 1-1h3.5c.55228 0 1 .44772 1 1s-.44772 1-1 1H4c-.55228 0-1-.44772-1-1Zm0 4c0-.55228.44772-1 1-1h4c.55228 0 1 .44772 1 1 0 .55229-.44772 1-1 1H4c-.55228 0-1-.44771-1-1Zm0 4c0-.5523.44772-1 1-1h4.5c.55229 0 1 .4477 1 1s-.44771 1-1 1H4c-.55228 0-1-.4477-1-1Z"
  />,
  'Newspaper Folding'
);
