import React, { useState, useEffect, useMemo } from 'react';
import Fuse from 'fuse.js';
import CrudTable from 'sharedComponents/CrudTable';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { setScaleForEdit } from 'modules/scales/storeSliceScales';
import useShowError from 'modules/errors';

import Query from 'services/firebase/Query';
import Executer from 'services/firebase/Executer';
import CompaniesApi from 'services/postgres/CompaniesApi';
import QueryBase from 'lg-helpers/dist/firestore/query/QueryBase';

const query = new Query();
const executer = new Executer();
const companiesApi = new CompaniesApi();

const columns = [
  { path: 'name', label: 'Name' },
  { path: 'companyName', label: 'Company' },
  { path: 'regionName', label: 'Region' },
  { path: 'address', label: 'Address' },
  { path: 'city', label: 'City' },
  { path: 'state.value', label: 'State' },
];

const ScalesView = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [scales, setScales] = useState([] as any[]);
  const [scaleCompanies, setScaleCompanies] = useState([]);
  const [selectedCompany, setSelectedCompany] = useState({} as any);
  const [isLoadingData, setIsLoadingData] = useState(true);
  const showError = useShowError();
  const [searchfilter, setSearchFilter] = useState(null);
  const [selectedRowsIds, setSelectedRowsIds] = useState([]);

  const filteredScales = useMemo(() => {
    let filtered = scales;

    if (selectedCompany && selectedCompany.id) {
      filtered = filtered.filter((scale: any) => scale.companyId === selectedCompany.id);
    }

    if (searchfilter) {
      const options = {
        includeScore: true,
        threshold: 0.5,
        keys: ['name', 'companyName', 'regionName', 'address', 'city', 'state.name'],
      };

      const fuse = new Fuse(filtered, options);
      filtered = fuse.search(searchfilter).map(result => result.item);
    }

    return filtered;
  }, [scales, searchfilter, selectedCompany]);

  useEffect(() => {
    const getCompanies = async () => {
      try {
        setIsLoadingData(true);
        const companiesResult = await companiesApi.getCompaniesWithCustomDataProperties(null, [
          'scale',
        ]);
        setScaleCompanies(companiesResult);
        setIsLoadingData(false);
      } catch (error) {
        console.error('Error', error);
        showError({
          title: 'Error while fetching the companies',
          duration: 10000,
        });
        setIsLoadingData(false);
      }
    };
    getCompanies();
  }, [showError]);

  useEffect(() => {
    const getScales = async () => {
      if (!scaleCompanies.length) {
        return;
      }
      try {
        setIsLoadingData(true);
        const scalesResponse = await executer.getMultipleDocuments(
          query.base.getAll(QueryBase.SCALES_COLLECTION())
        );
        const scalesJoined = [];

        for (let i = 0; i < scalesResponse.length; i++) {
          const scale = scalesResponse[i];
          const company: any = scaleCompanies.find((c: any) => c.id === scale.companyId) || {};

          const region = (company?.regions || []).find((r: any) => r.id === scale.regionId) || {};

          scalesJoined.push({
            ...scale,
            companyName: company?.name,
            regionName: region?.name,
          });
        }
        setScales(scalesJoined);
        setIsLoadingData(false);
      } catch (error) {
        console.error('Error', error);
        showError({
          title: 'Error while fetching the scales',
          duration: 10000,
        });
        setIsLoadingData(false);
      }
    };
    getScales();
  }, [scaleCompanies, showError]);

  const handleRowClick = (scale: any) => {
    navigate(`/super-admin/scale/${scale.id}`);
  };

  const handleClickAdd = () => {
    dispatch(setScaleForEdit(null));
    navigate('/super-admin/scale');
  };

  const handleClickDelete = async () => {
    try {
      for (let i = 0; i < selectedRowsIds.length; i++) {
        await executer.delete(
          query.base.getById(QueryBase.SCALES_COLLECTION(), selectedRowsIds[i])
        );
      }
    } catch (err) {
      console.error(err);
      showError({ title: 'Delete error' });
    }
  };

  return (
    <CrudTable
      title="Scales"
      rows={filteredScales}
      columns={columns}
      isLoading={isLoadingData}
      handleRowClickCallback={handleRowClick}
      searchFilter={searchfilter}
      setSearchFilterCallback={setSearchFilter}
      handleClickAddCallback={handleClickAdd}
      handleClickDeleteCallback={handleClickDelete}
      setSelectedRowsIdsCallback={setSelectedRowsIds}
      selectedRowsIds={selectedRowsIds}
      companies={scaleCompanies}
      handleSelectCompany={setSelectedCompany}
      selectedCompany={selectedCompany}
      showCompanySelection
    ></CrudTable>
  );
};

export default ScalesView;
