import { AxiosResponse } from 'axios';
import { IError } from 'modules/errors/storeSliceErrors';
import { useEffect } from 'react';
import useApiCall from './useApiCall';

export default function useApiGetRequest<T>(
  call: () => Promise<AxiosResponse<T>> | null,
  errorBuilder?: (err: unknown) => IError,
  deps: any[] = []
) {
  const [trigger, additionalContext] = useApiCall(call, errorBuilder, deps);

  useEffect(() => {
    trigger();
  }, [trigger]);

  return [additionalContext.data, additionalContext] as [T | undefined, typeof additionalContext];
}
