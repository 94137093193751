import { createAsyncThunk } from '@reduxjs/toolkit';
import { INotification } from 'modules/civilAndTrucking/shared/NotificationSnackbar/INotification';
import { pushNotification } from 'modules/civilAndTrucking/shared/NotificationSnackbar/redux/actions';
import ApiBase from 'services/postgres/ApiBase';
import Tables from 'services/postgres/Tables';
import { ITruckingTicketNote } from 'lg-helpers/dist/trucking/interfaces/ITruckingTicketNote';
import { pushError } from 'modules/errors/storeSliceErrors';
import { RootState } from 'store/store';
import { post, postStatic } from 'services/api/restHelpers';
import {
  TemplateAPIForm,
  TemplateForm,
} from 'modules/civilAndTrucking/trucking/features/Tickets/views/TicketsView/components/ExportTicketsModal/TicketExportCSVTemplate';
import { ITruckingTicket } from 'lg-helpers/dist/trucking/interfaces/ITruckingTicket';

const apiBase = new ApiBase();
const TICKET_TABLE = Tables.TruckingTicket;

export const getDriverTicketsByCompanyId = createAsyncThunk(
  'tickets/get-tickets',
  async (date: string, thunkApi) => {
    try {
      const data = await apiBase.getList(`${TICKET_TABLE}/date/${date}`);
      return data as ITruckingTicket[];
    } catch (err) {
      thunkApi.dispatch(pushError({ title: 'Error: Unable to get ticket loads by date' }));
      throw err;
    }
  }
);

export const getCreatorTicketsByCompanyId = createAsyncThunk(
  'tickets/get-creator-tickets',
  async (date: string, thunkApi) => {
    try {
      const data = await apiBase.getList(`${TICKET_TABLE}/creator/date/${date}`);
      return data as ITruckingTicket[];
    } catch (err) {
      thunkApi.dispatch(pushError({ title: 'Error: Unable to get tickets by date' }));
      throw err;
    }
  }
);

export const getCreatorTicketsByCompaniesIds = createAsyncThunk(
  'tickets/get-creator-tickets-companies-ids',
  async (payload: { date: string; companiesIds: string[] }, thunkApi) => {
    try {
      const { date, companiesIds } = payload;
      const data = await apiBase.getList(
        `${TICKET_TABLE}/creator/date/${date}/companiesIds/${companiesIds}`
      );
      return data as ITruckingTicket[];
    } catch (err) {
      thunkApi.dispatch(pushError({ title: 'Error: Unable to get tickets by date' }));
      throw err;
    }
  }
);

export const getContractorTicketsByRelatedCompanyId = createAsyncThunk(
  'tickets/get-contractor-tickets-by-contractor-companies-ids',
  async (payload: { date: string; companiesIds: string[] }, thunkApi) => {
    try {
      const { date, companiesIds } = payload;
      const data = await apiBase.getList(
        `${TICKET_TABLE}/contractor/date/${date}/contractorCompaniesIds/${companiesIds}`
      );
      return data as ITruckingTicket[];
    } catch (err) {
      thunkApi.dispatch(pushError({ title: 'Error: Unable to get tickets by date' }));
      throw err;
    }
  }
);

export const downloadTicketsByCompanyId = createAsyncThunk(
  'tickets/download',
  async (payload: { date: string; companiesIds: string[] }, thunkApi) => {
    try {
      const { date, companiesIds } = payload;
      await apiBase.postStatic(
        {
          date,
          companiesIds,
        },
        'trucking/download/tickets-by-date'
      );
      return true;
    } catch (err) {
      thunkApi.dispatch(pushError({ title: 'Error: Unable to download tickets by date' }));
      throw err;
    }
  }
);

export const getTicketsByDispatchId = createAsyncThunk(
  'tickets/get-tickets-by-dispatch',
  async (dispatchId: number, thunkApi) => {
    try {
      const data = await apiBase.getList(`${TICKET_TABLE}/dispatch/${dispatchId}`);
      return data as ITruckingTicket[];
    } catch (err) {
      const errorNotification: INotification = {
        status: 'error',
        message: 'Error: Unable to get ticket loads by date',
      };
      thunkApi.dispatch(pushNotification(errorNotification));
      throw err;
    }
  }
);

export const getDeletedTicketsByCompanyId = createAsyncThunk(
  'tickets/get-deleted-tickets',
  async (wtf, thunkApi) => {
    try {
      const data = await apiBase.getList(`${TICKET_TABLE}/deleted`);
      return data as ITruckingTicket[];
    } catch (err) {
      thunkApi.dispatch(pushError({ title: 'Error: Unable to get deleted ticket loads' }));
      throw err;
    }
  }
);

export const renewTickets = createAsyncThunk(
  'dispatch/renew-tickets-by-ids',
  async (ids: number[], thunkApi) => {
    try {
      await apiBase.post({ ids }, `${TICKET_TABLE}/renew`);
      const successNotification: INotification = {
        status: 'success',
        message: 'Success: Tickets renewed',
      };
      thunkApi.dispatch(pushNotification(successNotification));
      // I don't understand why this doesn't update list in view
      thunkApi.dispatch(getDeletedTicketsByCompanyId());
    } catch (err) {
      thunkApi.dispatch(pushError({ title: 'Error: Unable to renew tickets' }));
    }
  }
);

export const updateTicket = createAsyncThunk(
  'tickets/upsert-ticket',
  async (ticket: Partial<ITruckingTicket>, thunkApi) => {
    try {
      await apiBase.insertOrUpdate(ticket, Tables.TruckingTicket);

      thunkApi.dispatch(getTicketsByDispatchId(ticket.dispatchIdTruckingTicket!));
    } catch (err) {
      thunkApi.dispatch(pushError({ title: 'Error: Unable to update ticket' }));
    }
  }
);

export const getTruckingTicketNotesByTicketId = createAsyncThunk(
  'tickets/get-ticket-notes',
  async (ticketId: number, thunkApi) => {
    try {
      const ticketNotes = await apiBase.getList(
        `${Tables.TruckingTicketNotes}/ticketId/${ticketId}`
      );
      return ticketNotes as ITruckingTicketNote[];
    } catch (err) {
      thunkApi.dispatch(pushError({ title: 'Error: Unable to get trucking ticket notes' }));
      throw err;
    }
  }
);

export const updateTruckingTicketNote = createAsyncThunk(
  'tickets/upsert-ticket-note',
  async (note: ITruckingTicketNote, thunkApi) => {
    try {
      await apiBase.post(note, `${Tables.TruckingTicketNotes}/upsert`);

      const successNotification: INotification = {
        status: 'success',
        message: `Success: ticket note has been updated`,
      };

      thunkApi.dispatch(pushNotification(successNotification));
    } catch (err) {
      thunkApi.dispatch(pushError({ title: 'Error: Unable to update ticket note' }));
      throw err;
    }
  }
);

export const getTicketCSVBetweenDates = createAsyncThunk(
  'tickets/get-tickets-between-dates',
  async (
    payload: {
      startDate: string;
      endDate: string;
      isFTPExport: boolean;
      excludeColumnHeader: boolean;
      companiesIds?: string[];
    },
    thunkApi
  ) => {
    const state: RootState = thunkApi.getState() as RootState;
    const companyId = state.civil.companies.activeCompany?.id;
    const { startDate, endDate, isFTPExport, excludeColumnHeader, companiesIds = [] } = payload;
    try {
      const ticketReq = {
        companyId,
        companiesIds,
        startDate,
        endDate,
        isFTPExport,
        excludeColumnHeader,
      };
      const postMethod = isFTPExport ? postStatic : post;
      const CSVRes: any = await postMethod('trucking_ticket/export', ticketReq);
      if (CSVRes.csv) {
        return CSVRes.csv as string;
      } else {
        throw CSVRes.message;
      }
    } catch (err) {
      const errorNotification: INotification = {
        status: 'error',
        message: `${err}`,
      };
      thunkApi.dispatch(pushNotification(errorNotification));
    }
  }
);

export const getTicketExportTemplate = createAsyncThunk(
  'tickets/get-ticket-export-template',
  async (wtf, thunkApi) => {
    try {
      const data = await apiBase.getList(`${TICKET_TABLE}/export/custom_remap`);
      return data as TemplateAPIForm;
    } catch (err) {
      thunkApi.dispatch(pushError({ title: 'Error: Unable to get export template' }));
      throw err;
    }
  }
);

export const createTicketExportTemplate = createAsyncThunk(
  'tickets/create-ticket-export-template',
  async (payload: TemplateForm, thunkApi) => {
    try {
      const data = await apiBase.post(payload, `${TICKET_TABLE}/export/custom_remap`);
      const successNotification: INotification = {
        status: 'success',
        message: `Success: Ticket export template has been created`,
      };
      thunkApi.dispatch(pushNotification(successNotification));
      return data as TemplateAPIForm;
    } catch (err) {
      thunkApi.dispatch(pushError({ title: 'Error: Unable to create export template' }));
      throw err;
    }
  }
);

export const updateTicketExportTemplate = createAsyncThunk(
  'tickets/update-ticket-export-template',
  async (payload: { idCompaniesCustomData: number; exportTemplate: TemplateForm }, thunkApi) => {
    try {
      const { idCompaniesCustomData, exportTemplate } = payload;
      const data = await apiBase.put(
        exportTemplate,
        `${TICKET_TABLE}/export/custom_remap/${idCompaniesCustomData}`
      );
      const successNotification: INotification = {
        status: 'success',
        message: `Success: Ticket export template has been updated`,
      };
      thunkApi.dispatch(pushNotification(successNotification));
      return data as TemplateAPIForm;
    } catch (err) {
      thunkApi.dispatch(pushError({ title: 'Error: Unable to update export template' }));
      throw err;
    }
  }
);
