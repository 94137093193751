import React from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Divider,
  CircularProgress,
  Typography,
  Grid,
} from '@mui/material';

const InputBase64Image = ({ imageBase64, isLoading, onImageBase64Update, title, disabled }) => {
  const convertBase64 = file => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = () => {
        resolve(fileReader.result);
      };

      fileReader.onerror = error => {
        reject(error);
      };
    });
  };

  const handleImage = e => {
    const file = e.target.files[0];
    convertBase64(file)
      .then(data => {
        onImageBase64Update(data);
      })
      .catch(err => {
        console.error(err);
      });
  };

  const onClearClick = () => {
    onImageBase64Update(null);
  };

  return (
    <>
      <Card>
        <CardHeader
          title={
            <Box alignItems="center" display="flex">
              <Typography variant="body1">{title ? title : 'Image from Camera'}</Typography>
              {isLoading && (
                <Box marginLeft={2}>
                  <CircularProgress size="15px" color="inherit" />
                </Box>
              )}
            </Box>
          }
        />
        <CardContent>
          {(imageBase64 || disabled) && <img width="100%" src={imageBase64} alt="" />}
        </CardContent>
        {!disabled && (
          <>
            <Divider />
            <CardActions>
              {imageBase64 ? (
                <Button
                  color="primary"
                  fullWidth
                  variant="text"
                  disabled={isLoading || disabled}
                  onClick={onClearClick}
                >
                  Clear
                </Button>
              ) : (
                <Grid container>
                  <input
                    type="file"
                    hidden
                    accept="image/*"
                    id="image-upload-button"
                    className="form-control"
                    onChange={handleImage}
                  />
                  <label
                    htmlFor="image-upload-button"
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      width: '100%',
                      padding: 8,
                      borderRadius: 4,
                      '&:hover': {
                        backgroundColor: 'rgba(38, 50, 56, 0.04)',
                      },
                    }}
                  >
                    <Box>
                      <Typography
                        align="center"
                        style={{
                          width: '100%',
                          color: '#41793a',
                        }}
                      >
                        Upload Image
                      </Typography>
                    </Box>
                  </label>
                </Grid>
              )}
            </CardActions>
          </>
        )}
      </Card>
    </>
  );
};

InputBase64Image.propTypes = {
  onImageBase64Update: PropTypes.func,
  imageBase64: PropTypes.string,
  isLoading: PropTypes.bool,
  title: PropTypes.string,
  disabled: PropTypes.bool,
};

export default InputBase64Image;
