import React from 'react';
import PropTypes from 'prop-types';
import { SvgIcon } from '@mui/material';
import { ReactComponent as Svg } from './raw/LanesNoBackground.svg';

// const Lanes = props => {
//   return (
//     <SvgIcon {...props} width={!props ? '100%' : props.width}>
//       <Svg />
//     </SvgIcon>
//   );
// };

const Lanes = () => {
  return (
    <SvgIcon width={'100%'}>
      <Svg />
    </SvgIcon>
  );
};

Lanes.propTypes = {
  width: PropTypes.any,
};

export default Lanes;
