import React from 'react';
import { createSvgIcon } from '@mui/material';

export const Search = createSvgIcon(
  <path
    fillRule="evenodd"
    clipRule="evenodd"
    d="M13.1075 11h-.77l-.2729-.27c.9552-1.14 1.5302-2.62 1.5302-4.23 0-3.59-2.8363-6.5-6.33534-6.5C3.76038 0 .92407 2.91.92407 6.5S3.76038 13 7.25946 13c1.56923 0 3.01174-.59 4.12284-1.57l.2632.28v.79l4.8734 4.99L17.9711 16l-4.8636-5Zm-5.84804 0c-2.42694 0-4.38604-2.01-4.38604-4.5S4.83252 2 7.25946 2s4.38604 2.01 4.38604 4.5S9.6864 11 7.25946 11Z"
  />,
  'Search'
);
