import { createAsyncThunk } from '@reduxjs/toolkit';
import { INotification } from 'modules/civilAndTrucking/shared/NotificationSnackbar/INotification';
import { pushNotification } from 'modules/civilAndTrucking/shared/NotificationSnackbar/redux/actions';
import { get, post, del } from 'services/api/restHelpers';
import { IActivity } from 'lg-helpers/dist/shared/interfaces/IActivity';
import { IActivityJoin } from 'lg-helpers/dist/shared/interfaces/IActivityJoin';

export const getActivitiesByWorkCategory = createAsyncThunk(
  'activities/get',
  async (payload: { workCategoryId: number; projectId: number }, thunkApi) => {
    try {
      const data = await get(
        `civil/projects/${payload.projectId}/activities?workCategoryId=${payload.workCategoryId}`
      );
      return data as IActivity[];
    } catch (err) {
      const errorNotification: INotification = {
        status: 'error',
        message: `Error: Unable to get activities`,
      };
      thunkApi.dispatch(pushNotification(errorNotification));
      throw err;
    }
  }
);

export const getActivities = createAsyncThunk(
  'activities/get-all',
  async (payload: { type: string; projectId: number }, thunkApi) => {
    try {
      if (payload.type === 'all') {
        const newData: IActivityJoin[] | undefined = await get(
          `civil/projects/${payload.projectId}/activities?type=all`
        );
        return newData;
      } else if (payload.type === 'active') {
        const newData: IActivityJoin[] | undefined = await get(
          `civil/projects/${payload.projectId}/activities?type=active`
        );
        return newData;
      }
    } catch (err) {
      const errorNotification: INotification = {
        status: 'error',
        message: `Error: Unable to get activities`,
      };
      thunkApi.dispatch(pushNotification(errorNotification));
      throw err;
    }
  }
);

export const addActivity = createAsyncThunk(
  'activities/add',
  async (payload: { activity: IActivity; projectId: number }, thunkApi) => {
    try {
      const newData = await post(
        `civil/projects/${payload.projectId}/activities`,
        payload.activity
      );
      const successNotification: INotification = {
        status: 'success',
        message: `Success: ${payload.activity.id ? 'Updated ' : 'Added'} ${payload.activity.name}`,
      };
      thunkApi.dispatch(pushNotification(successNotification));
      return newData as IActivity;
    } catch (err) {
      const errorNotification: INotification = {
        status: 'error',
        message: `Error: ${payload.activity.id ? 'Updated' : 'Added'} ${payload.activity.name}`,
      };
      thunkApi.dispatch(pushNotification(errorNotification));
      throw err;
    }
  }
);

export const deleteActivity = createAsyncThunk(
  'activities/delete',
  async (payload: { activity: IActivity; projectId: number }, thunkApi) => {
    try {
      await del(`civil/projects/${payload.projectId}/activities/${payload.activity.id}`);
      const successNotification: INotification = {
        status: 'success',
        message: `Success: Deleted ${payload.activity.name}`,
      };
      thunkApi.dispatch(pushNotification(successNotification));
      return payload;
    } catch (err) {
      console.error('Error deleting activity', payload);
    }
  }
);
