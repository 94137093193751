import React, { useContext, useEffect } from 'react';
import { TruckTypeForm } from './TruckTypeForm';
import { TruckTypesList } from './components/TruckTypesList';
import { thunkDispatch } from 'store/store';
import { getTruckTypesByCompanyId } from '../../redux/actions';
import { ModalContext } from 'sharedComponents/ModalContext';
import { ManageHeader } from 'sharedComponents/ManageHeader';
import { ITruckingTruckType } from 'lg-helpers/dist/trucking/interfaces/ITruckingTruckType';
import { IFlattenedCapacities } from 'lg-helpers/dist/shared/interfaces/IFlattenedCapabilities';

export type ITruckTypeFlattenedCapacities = ITruckingTruckType & Partial<IFlattenedCapacities>;

export const TruckTypes = () => {
  const { handleModal } = useContext(ModalContext);

  useEffect(() => {
    thunkDispatch(getTruckTypesByCompanyId());
  }, []);

  const showModal = (truckType?: ITruckTypeFlattenedCapacities) => {
    handleModal(<TruckTypeForm editObj={truckType} />);
  };

  return (
    <>
      <ManageHeader
        headerTitle={'Manage Truck Types'}
        buttonText={'Add Truck Type'}
        onButtonClick={showModal}
      />
      <TruckTypesList showModal={showModal} />
    </>
  );
};
